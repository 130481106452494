import { Component, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiService } from 'src/app/services/api.service';
import { UserserviceService } from 'src/app/services/userservice.service';

@Component({
  selector: 'app-lead-folder-view',
  templateUrl: './lead-folder-view.component.html',
  styleUrls: ['./lead-folder-view.component.css']
})
export class LeadFolderViewComponent implements OnInit {
  public notesflag: boolean = false;

  constructor(
        public apiservice: ApiService,
        public activatedRoute: ActivatedRoute,
        public snackBar: MatSnackBar,
        public router: Router, 
        public userservice: UserserviceService

  ) { }
  notes(){
    this.notesflag = true;
  }
  ngOnInit() {
  }

  notesubmit(){

  }
  addnotes(){}

}
