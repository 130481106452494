import { HttpClient } from "@angular/common/http";
import { Component, OnInit } from "@angular/core";
import { MatBottomSheet, MatSnackBar } from "@angular/material";
import { ActivatedRoute, Router } from "@angular/router";
import { CookieService } from "ngx-cookie-service";
import { ApiService } from "src/app/services/api.service";
import { UserserviceService } from "src/app/services/userservice.service";

@Component({
  selector: "app-addleadcontract",
  templateUrl: "./addleadcontract.component.html",
  styleUrls: ["./addleadcontract.component.css"],
})
export class AddleadcontractComponent implements OnInit {
  public formdata3: any;
  public formfieldrefresh:any;
  public formfieldrefreshdata:any;
  public surname_status: any = [
    { val: "Mr", name: "Mr" },
    { val: "Ms", name: "Ms" },
    { val: "Mrs", name: "Mrs" },
    { val: "Dr", name: "Dr" },
    { val: "Prof", name: "Prof" },
  ];
  public resolvedata: any = [];
  constructor(
    public apiservice: ApiService,
    public http: HttpClient,
    public activatedRoute: ActivatedRoute,
    public cookies: CookieService,
    public router: Router,
    public userservice: UserserviceService,
    private snackBar: MatSnackBar,
    private _bottomSheet: MatBottomSheet
  ) {
    console.log(this.activatedRoute.snapshot.params._id, "++++++");
    // if (this.activatedRoute.snapshot.params._id != null) {
    // this.flag = true;
    let data = {
      secret: 'secret',
      token: 'token',
      id: '_id',
      condition: {}
    }
    this.apiservice
      .getDataWithoutToken(data, 'api/getonepracticecontact')
      .subscribe((res: any) => {
        this.resolvedata = res.resolvedata.result[0]
        this.formdata3 = {
          successmessage: "Contact Edit Successfully Submitted",
          redirectpath:
            "user-details/lead/" + this.activatedRoute.snapshot.params._id,
          submitactive: true, // optional, default true
          submittext: "Save",
          // resettext: "Reset",
          canceltext: "Cancel",
          apiUrl: this.apiservice.api_url,
          endpoint: "api/add-practice-contracts",
          jwttoken: this.cookies.get("jwtToken"),
          cancelroute:
            "user-details/lead/" + this.activatedRoute.snapshot.params._id,

          fields: [
            {
              label: "",
              name: "Salutation",
              value: this.resolvedata.Salutation,
              val: this.surname_status,
              type: "select",
              // validations: [
              //   { rule: "required", message: "HPS Representative is Required" },
              // ],
            },

            {
              label: "First Name",
              name: "First_Name",
              value: this.resolvedata.First_Name,
              // multiple:true,

              type: "text",
              validations: [
                { rule: "required", message: "First Name is Required" },
              ],
            },
            {
              label: "Last Name",
              name: "Last_Name",
              value: this.resolvedata.Last_Name,
              type: "text",
              // validations: [
              //   { rule: "required", message: "Last Name is Required" },
              // ],
            },
            {
              label: "Phone",
              name: "Phone",
              value: this.resolvedata.Phone,
              type: "number",
              // validations: [
              //   { rule: "required", message: "Sales Person is Required" },
              // ],
            },
            {
              label: "Other Phone",
              name: "Other_Phone",
              value: this.resolvedata.Other_Phone,
              type: "number",
              validations: [
                // { rule: "required", message: "Practice Name is Required" },
              ],
            },

            {
              label: "Email",
              name: "Email",
              value: this.resolvedata.Email,
              val: "",
              type: "text",
              // validations: [
              //   { rule: "required", message: "Currency is Required" },
              // ],
            },
            {
              label: "Secondary Email",
              name: "Secondary_Email",
              value: this.resolvedata.Secondary_Email,
              type: "text",
              // validations: [
              //   { rule: "required", message: "Fax is Required" },
              // ],
            },
            {
              label: "Fax",
              name: "fax",
              value: this.resolvedata.fax,
              type: "text",
              validations: [
                // {
                //   rule: 'maxLength',
                //   value: 9,
                //   message: 'Please enter the valid 9 digit EIN Number of ',
                // },
              ],
            },
            {
              label: "Contact Notes",
              name: "Contact_Notes",
              value: this.resolvedata.Contact_Notes,
              type: "text",
              validations: [
                // {
                //   rule: 'maxLength',
                //   value: 9,
                //   message: 'Please enter the valid 9 digit EIN Number of ',
                // },
              ],
            },
            {
              heading: "Address Information",
              // name: "statusgroup",
              hint: ",0000",
              type: "group",
              fields: [
                // {
                //   label: "Age1",
                //   name: "age1",
                //   type: 'number',
                //   hint: 'Age ??',
                //   validations: [
                //       // { rule: 'required' },
                //       // { rule: 'min', value: 5 },
                //       // { rule: 'max', value: 50, message: "Age can't be more than 50 " }
                //   ]
                // },
              ],
            },
            {
              label: "Mailing Street",
              name: "Mailing_Street",
              value: this.resolvedata.Mailing_Street,

              type: "text",
              // validations: [
              //   { rule: "required", message: "Practice Type is Required" },
              // ],
            },
            {
              label: "Mailing City",
              name: "Mailing_City",
              value: this.resolvedata.Mailing_City,
              // hint:'None',
              // val: this.pecepracticestatus,
              type: "text",
            },
            {
              label: "Mailing State",
              name: "Mailing_State",
              value: this.resolvedata.Mailing_State,

              type: "text",
              // validations: [
              //  g { rule: "required", message: "Exchange Rate is Required" },
              // ],
            },
            {
              label: "Mailing Zip",
              name: "Mailing_Zip",
              value: this.resolvedata.Mailing_Zip,
              type: "number",
              // validations: [
              //   { rule: "required", message: "Practice EMR is Required" },
              // ],
            },
            {
              label: "Mailing Country",
              name: "Mailing_Country",
              value: this.resolvedata.Mailing_Country,
              type: "text",
              // validations: [
              //   { rule: "required", message: "Practice Email is Required" },
              // ],
            },
            {
              label: "Other Street",
              name: "Other_Street",
              value: this.resolvedata.Other_Street,
              type: "text",
              validations: [
                // { rule: "required", message: "Website is Required" },
              ],
            },
            {
              label: "Other City",
              name: "Other_City",
              value: this.resolvedata.Other_City,
              type: "textarea",
              labelPosition: "after",
              // validations: [
              //   { rule: "required", message: "Practice Notes is Required" },
              // ],
            },

            {
              label: "Other State",
              name: "Other_State",
              value: this.resolvedata.Other_State,
              type: "text",

              // validations: [
              //   { rule: "required", message: "Practice Notes is Required" },
              // ],
            },
            {
              label: "Other Zip",
              name: "Other_Zip",
              value: this.resolvedata.Other_Zip,
              type: "text",
            },
            {
              label: "Other Country",
              name: "Other_Country",
              value: this.resolvedata.Other_Country,
              type: "text",
            },
            {
              label: "isSignee",
              name: "isSignee",
              value: false,
              type: "hidden",
            },
            {
              label: "",
              name: "leadID",
              value: this.activatedRoute.snapshot.params._id,
              type: "hidden",
            },
          ],
        };
      });
    // this.activatedRoute.data.subscribe((res: any) => {
    //   console.log(res, 'practicecontract')
    //   this.resolvedata = res.resolvedata.result
    //   this.formdata3 = {
    //     successmessage: "Contact Edit Successfully Submitted",
    //     redirectpath:
    //       "user-details/lead/" + this.activatedRoute.snapshot.params._id,
    //     submitactive: true, // optional, default true
    //     submittext: "Save",
    //     // resettext: "Reset",
    //     canceltext: "Cancel",
    //     apiUrl: this.apiservice.api_url,
    //     endpoint: "api/add-practice-contracts",
    //     jwttoken: this.cookies.get("jwtToken"),
    //     cancelroute:
    //       "user-details/lead/" + this.activatedRoute.snapshot.params._id,

    //     fields: [
    //       {
    //         label: "",
    //         name: "Salutation",
    //         value: this.resolvedata.Salutation,
    //         val: this.surname_status,
    //         type: "select",
    //         // validations: [
    //         //   { rule: "required", message: "HPS Representative is Required" },
    //         // ],
    //       },

    //       {
    //         label: "First Name",
    //         name: "First_Name",
    //         value: this.resolvedata.First_Name,
    //         // multiple:true,

    //         type: "text",
    //         validations: [
    //           { rule: "required", message: "First Name is Required" },
    //         ],
    //       },
    //       {
    //         label: "Last Name",
    //         name: "Last_Name",
    //         value: this.resolvedata.Last_Name,
    //         type: "text",
    //         // validations: [
    //         //   { rule: "required", message: "Last Name is Required" },
    //         // ],
    //       },
    //       {
    //         label: "Phone",
    //         name: "Phone",
    //         value: this.resolvedata.Phone,
    //         type: "number",
    //         // validations: [
    //         //   { rule: "required", message: "Sales Person is Required" },
    //         // ],
    //       },
    //       {
    //         label: "Other Phone",
    //         name: "Other_Phone",
    //         value: this.resolvedata.Other_Phone,
    //         type: "number",
    //         validations: [
    //           // { rule: "required", message: "Practice Name is Required" },
    //         ],
    //       },

    //       {
    //         label: "Email",
    //         name: "Email",
    //         value: this.resolvedata.Email,
    //         val: "",
    //         type: "text",
    //         // validations: [
    //         //   { rule: "required", message: "Currency is Required" },
    //         // ],
    //       },
    //       {
    //         label: "Secondary Email",
    //         name: "Secondary_Email",
    //         value: this.resolvedata.Secondary_Email,
    //         type: "text",
    //         // validations: [
    //         //   { rule: "required", message: "Fax is Required" },
    //         // ],
    //       },
    //       {
    //         label: "Fax",
    //         name: "fax",
    //         value: this.resolvedata.fax,
    //         type: "text",
    //         validations: [
    //           // {
    //           //   rule: 'maxLength',
    //           //   value: 9,
    //           //   message: 'Please enter the valid 9 digit EIN Number of ',
    //           // },
    //         ],
    //       },
    //       {
    //         label: "Contact Notes",
    //         name: "Contact_Notes",
    //         value: this.resolvedata.Contact_Notes,
    //         type: "text",
    //         validations: [
    //           // {
    //           //   rule: 'maxLength',
    //           //   value: 9,
    //           //   message: 'Please enter the valid 9 digit EIN Number of ',
    //           // },
    //         ],
    //       },
    //       {
    //         heading: "Address Information",
    //         // name: "statusgroup",
    //         hint: ",0000",
    //         type: "group",
    //         fields: [
    //           // {
    //           //   label: "Age1",
    //           //   name: "age1",
    //           //   type: 'number',
    //           //   hint: 'Age ??',
    //           //   validations: [
    //           //       // { rule: 'required' },
    //           //       // { rule: 'min', value: 5 },
    //           //       // { rule: 'max', value: 50, message: "Age can't be more than 50 " }
    //           //   ]
    //           // },
    //         ],
    //       },
    //       {
    //         label: "Mailing Street",
    //         name: "Mailing_Street",
    //         value: this.resolvedata.Mailing_Street,

    //         type: "text",
    //         // validations: [
    //         //   { rule: "required", message: "Practice Type is Required" },
    //         // ],
    //       },
    //       {
    //         label: "Mailing City",
    //         name: "Mailing_City",
    //         value: this.resolvedata.Mailing_City,
    //         // hint:'None',
    //         // val: this.pecepracticestatus,
    //         type: "text",
    //       },
    //       {
    //         label: "Mailing State",
    //         name: "Mailing_State",
    //         value: this.resolvedata.Mailing_State,

    //         type: "text",
    //         // validations: [
    //         //  g { rule: "required", message: "Exchange Rate is Required" },
    //         // ],
    //       },
    //       {
    //         label: "Mailing Zip",
    //         name: "Mailing_Zip",
    //         value: this.resolvedata.Mailing_Zip,
    //         type: "number",
    //         // validations: [
    //         //   { rule: "required", message: "Practice EMR is Required" },
    //         // ],
    //       },
    //       {
    //         label: "Mailing Country",
    //         name: "Mailing_Country",
    //         value: this.resolvedata.Mailing_Country,
    //         type: "text",
    //         // validations: [
    //         //   { rule: "required", message: "Practice Email is Required" },
    //         // ],
    //       },
    //       {
    //         label: "Other Street",
    //         name: "Other_Street",
    //         value: this.resolvedata.Other_Street,
    //         type: "text",
    //         validations: [
    //           // { rule: "required", message: "Website is Required" },
    //         ],
    //       },
    //       {
    //         label: "Other City",
    //         name: "Other_City",
    //         value: this.resolvedata.Other_City,
    //         type: "textarea",
    //         labelPosition: "after",
    //         // validations: [
    //         //   { rule: "required", message: "Practice Notes is Required" },
    //         // ],
    //       },

    //       {
    //         label: "Other State",
    //         name: "Other_State",
    //         value: this.resolvedata.Other_State,
    //         type: "text",

    //         // validations: [
    //         //   { rule: "required", message: "Practice Notes is Required" },
    //         // ],
    //       },
    //       {
    //         label: "Other Zip",
    //         name: "Other_Zip",
    //         value: this.resolvedata.Other_Zip,
    //         type: "text",
    //       },
    //       {
    //         label: "Other Country",
    //         name: "Other_Country",
    //         value: this.resolvedata.Other_Country,
    //         type: "text",
    //       },
    //       {
    //         label: "isSignee",
    //         name: "isSignee",
    //         value: false,
    //         type: "hidden",
    //       },
    //       {
    //         label: "",
    //         name: "leadID",
    //         value: this.activatedRoute.snapshot.params._id,
    //         type: "hidden",
    //       },
    //     ],
    //   };
    // });
    // }

  }

  ngOnInit() { }
  listenFormFieldChanges(val: any) { }
}
