import { Component, OnInit } from '@angular/core';
import { UserserviceService } from 'src/app/services/userservice.service';
import { ApiService } from 'src/app/services/api.service';
import { environment } from 'src/environments/environment';
import { MatSnackBar } from '@angular/material';
import { CookieService } from "ngx-cookie-service";

@Component({
  selector: 'app-communication-with-email',
  templateUrl: './communication-with-email.component.html',
  styleUrls: ['./communication-with-email.component.css']

})
export class CommunicationWithEmailComponent implements OnInit {

  public formdata: any;
  public formfieldrefresh: boolean = false;
  public formfieldrefreshdata: any = null;

  public userdetails: any = [];
  public replyFlag:any = false

  public productVal: any = [];
  public usertype: any;

  public progressSpinner: any = {
    mode: 'indeterminate',
    loading: false,
    bookingStatus: 'Sending request'
  };
  public ckeConfig: any = {
    toolbar: [
      {
        name: "editing",
        items: ["Scayt", "Find", "Replace", "SelectAll"]
      },
      {
        name: "clipboard",
        items: [
          "Cut",
        ]
      },
      {
        name: "links",
        items: [
          "Link",
        ]
      },
      { name: "document", items: ["Source"] },
      {
        name: "insert",
        items: [
          "Image",
        ]
      },
      "/",
      {
        name: "basicstyles",
        items: [
          "Bold",
          "Italic",
          "Underline",
        ]
      },

      {
        name: "styles",
        items: ["Styles", "Format", "FontSize", "-", "TextColor", "BGColor"]
      }
    ]
  }

  public emaildata: any = '';
  public emailrecord: any;
  constructor(public apiservice: ApiService, public userservice: UserserviceService, public snackBar: MatSnackBar,    
    public cookieservice: CookieService
    ) {
    let form_data = { id: "607fda0296a1b10008fe8303" };
    this.usertype = JSON.parse(this.cookieservice.get("type"));

    this.apiservice.customRequest(form_data, 'api/replybylead', environment['api_url']).subscribe((response: any) => {
      console.log(response, 'success++++')
      if (response.status == 'success') {
        this.emaildata = response.res[0];
        console.log(this.emaildata);
        this.formdata = {                                        // from start
          successmessage: 'Added Successfully !!', // success message
          submittext: 'Send',
          apiUrl: this.apiservice.api_url,
          // endpoint: '',
          // resettext: 'Reset',
          // canceltext: 'Cancel',
          jwttoken: '',
          // cancelroute: '', // use for redirect after cancel the form
          fields: [
            {
              label: 'Send To',
              name: 'sent_to',
              value: [this.emaildata.added_by_email],
              type: 'text',
              disabled: true,
              // multiple: true,
              validations: [
                { rule: 'required', message: ' Send to is required' },
              ]
            },
            {
              label: 'Subject',
              name: 'subject',
              value: this.emaildata.subject,
              type: 'text',
              disabled: true,
              validations: [
                { rule: 'required', message: ' Subject is required' },
              ]
            },
            {
              label: 'Description',
              name: 'template',
              value: '',
              type: 'editor',
              validations: [
                { rule: 'required', message: ' Template is required' },
              ],
              ckeConfig: this.ckeConfig
            },
            {
              label: 'Products',
              name: 'productsarray',
              value: this.emaildata.product_name,
              type: 'text',
              disabled: true,
              // multiple: true,
              // validations: [
              //   { rule: 'required', message: 'Products  is Required' },
              // ],
            },
            {
              label: 'Priority',
              name: 'priority',
              value: 1,
              type: 'hidden',
              validations: [
    
              ]
            },
          ]
        };
      } else {
        // this.openSnackBar('Something went wrong. Please try again.', null);
      }
    })


    

  }

  listenFormFieldChange(val: any) {
    console.log(val, '++=++')
    // return;
    if (val.field == 'fromsubmit' && val.fieldval == 'success') {
      this.progressSpinner.loading = true;

      //   this.data.flag = 'submit';
      //   this.data.val = val;
      val.fromval.sent_to = [this.emaildata.added_by_email];
      val.fromval.user_name = this.emaildata.lead_firstname

      let form_data = {
        email_data: val.fromval,
        user_id: this.emaildata.user_id
      }
      // console.log(this.formdata.fields[0].value = '', '+++++');
      // this.formfieldrefresh = true;
      // return;
      this.apiservice.customRequest(form_data, 'api/send-communication-email', environment['api_url']).subscribe((response: any) => {
        console.log(response, 'success++++')
        if (response == 'success') {
          this.progressSpinner.loading = false;
          this.openSnackBar('Email Send Successfully.', null);
          console.log(this.formdata.fields, '+++++')
          // this.formdata.reset();
        } else {
          this.openSnackBar('Something went wrong. Please try again.', null);
        }
      })
    }
  }

  openSnackBar(message: string, action: string = null) {
    this.snackBar.open(message, action, {
      duration: 3000,
    });
  }


  replybtn() {
    // this is used replybutton
    this.replyFlag = true;
    //this.endcommFlag = false;
  }

  ngOnInit() {
  }

}
