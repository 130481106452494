import { Component, OnInit, HostListener } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material';
import { DetectdomainService } from 'src/app/services/detectdomain.service';


@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})
export class FooterComponent implements OnInit {
  windowScrolled: boolean;


  public hostName: any;
  public medigradeflag: any=false;

  constructor(public dialog: MatDialog,  public domainService:DetectdomainService) {
      this.hostName = this.domainService.getdomain();
      if (this.hostName == "medigrade") {
              this.medigradeflag=true
      }


     }

  ngOnInit() {
  }

  @HostListener("window:scroll", [])

  onWindowScroll() {
    if (window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop > 100) {
        this.windowScrolled = true;
    }
    else if (this.windowScrolled && window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop < 10) {
        this.windowScrolled = false;
    }
}

scrollToTop() {
    (function smoothscroll() {

        var currentScroll = document.documentElement.scrollTop || document.body.scrollTop;

        if (currentScroll > 0) {
            window.requestAnimationFrame(smoothscroll);
            window.scrollTo(0, currentScroll - (currentScroll / 8));
        }

    })();
}


privacy(){
    const dialogRef = this.dialog.open(privacypolicy, {
        // width: '250px',
        // height:'500px',
        panelClass: 'footermodalcss'
      });
  
      dialogRef.afterClosed().subscribe(result => {
        //  // console.log('The dialog was closed');
        // return
      });
}


termscondition(){
  const dialogRef = this.dialog.open(termandcondition, {
    // width: '250px',
    // height:'500px',
    panelClass: 'footermodalcss'
  });

  dialogRef.afterClosed().subscribe(result => {
    //  // console.log('The dialog was closed');
    // return
  });
}
}
@Component({
    selector: 'privacypolicy',
    templateUrl: 'privacypolicy.html',
  
  })
  export class privacypolicy {
   
    constructor(
      public dialogRef: MatDialogRef<privacypolicy>,
  
    ) {
      // setTimeout(() => {
      //   this.dialogRef.close();
      // }, 3000);
     
    }
  
    onNoClick(): void {
      this.dialogRef.close();
    }
  
  }


  @Component({
    selector: 'termandcondition',
    templateUrl: 'termandcondition.html',
  
  })
  export class termandcondition {
   
    constructor(
      public dialogRef: MatDialogRef<termandcondition>,
  
    ) {
      // setTimeout(() => {
      //   this.dialogRef.close();
      // }, 3000);
      
    }
  
    onNoClick(): void {
      this.dialogRef.close();
    }
  
  }