import { Component, Inject, OnInit } from '@angular/core';
import {
  MatDialog,
  MatDialogRef,
  MatSnackBar,
  MAT_DIALOG_DATA,
} from '@angular/material';
import { ActivatedRoute, Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { ApiService } from 'src/app/services/api.service';
import { UserserviceService } from 'src/app/services/userservice.service';
export interface DialogData {
  data: any;
}
@Component({
  selector: 'app-view-managejobticket',
  templateUrl: './view-managejobticket.component.html',
  styleUrls: ['./view-managejobticket.component.css'],
})
export class ViewManagejobticketComponent implements OnInit {
  public ckeConfig: any = {
    toolbar: [
      { name: 'document', items: ['Source'] },
      {
        name: 'editing',
        items: ['Scayt', 'Find', 'Replace', 'SelectAll'],
      },
      {
        name: 'clipboard',
        items: [
          'Cut',
          // 'Copy',
          // 'Paste',
          // 'PasteText',
          // 'PasteFromWord',
          // '-',
          // 'Undo',
          // 'Redo'
        ],
      },
      {
        name: 'links',
        items: [
          'Link',
          //  'Unlink',
          //  'Anchor'
        ],
      },
      // {
      //     name: 'tools',
      //     items: ['Maximize', 'ShowBlocks', 'Preview', 'Print', 'Templates']
      // },
      {
        name: 'insert',
        items: [
          'Image',
          // 'Table',
          // 'HorizontalRule',
          // 'SpecialChar',
          // 'Iframe',
          // 'imageExplorer'
        ],
      },
      '/',
      {
        name: 'basicstyles',
        items: [
          'Bold',
          'Italic',
          'Underline',
          // 'Strike',
          // 'Subscript',
          // 'Superscript',
          // '-',
          // 'RemoveFormat'
        ],
      },
      // {
      //     name: 'paragraph',
      //     items: [
      //         'NumberedList',
      //         'BulletedList',
      //         '-',
      //         'Outdent',
      //         'Indent',
      //         'CreateDiv',
      //         '-',
      //         'Blockquote'
      //     ]
      // },
      // {
      //     name: 'justify',
      //     items: [
      //         'JustifyLeft',
      //         'JustifyCenter',
      //         'JustifyRight',
      //         'JustifyBlock'
      //     ]
      // },

      {
        name: 'styles',
        items: ['Styles', 'Format', 'FontSize', '-', 'TextColor', 'BGColor'],
      },
    ],
  };
  public loadmoreflg: boolean = false;
  public statusupdateflg: boolean = false;
  public replyFlag: any = false;
  public endcommFlag: any = false;
  formfieldrefresh: any;
  formfieldrefreshdata: any;
  public formdata: any;
  public viewjobticket: any = [];
  public othersJobTicketDetails: any = '';
  public rplyjobticket: any = [];
  public indexVal: any = 2;
  public jobTicketsDetails: any = [];
  public ticketId: any;
  public usertype: any;
  constructor(
    public router: Router,
    public Apiservice: ApiService,
    public cookieservice: CookieService,
    public activatedRoute: ActivatedRoute,
    public userservice: UserserviceService,
    public snakBar: MatSnackBar,
    public dialog: MatDialog
  ) {
    this.usertype = JSON.parse(this.cookieservice.get('type'));
    console.log('user type------------------------------->',this.usertype);
    
    this.ticketId = this.activatedRoute.snapshot.params._id;
    this.formdata = {
      successmessage: 'Added Successfully !!',
      redirectpath: this.router.url,
      submitactive: true, // optional, default true
      submittext: 'Submit',
      resettext: 'Reset',
      canceltext: 'Cancel',
      apiUrl: this.Apiservice.api_url,
      endpoint: 'api/updatejobticketdata',
      jwttoken: this.Apiservice.jwttoken,
      // cancelroute: '/job-tickets-list',

      fields: [
        {
          label: 'Description',
          name: 'reply_description',
          type: 'editor',
          value: '',
          ckeConfig: this.ckeConfig,
          validations: [
            { rule: 'required', message: 'Description  is required' },
          ],
        },
        {
          label: 'File Upload',
          name: 'reply_files',
          type: 'file',
          multiple: true,
          prefix: 'image-' + Date.now(),
          path: 'betoparedes/job-ticket-reply/',
          baseurl: 'betoparedes-dev-job-ticket',
          bucket: 'awsbackend-dev-patient-files-test',
          apiurl:
            'https://tge24bc2ne.execute-api.us-east-1.amazonaws.com/dev/requestUploadURL',
          apideleteurl:
            'https://tge24bc2ne.execute-api.us-east-1.amazonaws.com/dev/deletefilefromBucket',
        },
        {
          label: 'user_name',
          name: 'user_name',
          type: 'hidden',
          value:
            JSON.parse(this.cookieservice.get('firstname')) +
            ' ' +
            JSON.parse(this.cookieservice.get('lastname')),
        },
        {
          label: 'user_type',
          name: 'user_type',
          type: 'hidden',
          value: JSON.parse(this.cookieservice.get('type')),
        },
        {
          label: 'user_id',
          name: 'user_id',
          type: 'hidden',
          value: JSON.parse(this.cookieservice.get('userid')),
        },
        {
          label: 'ticket_Id',
          name: 'id',
          type: 'hidden',
          value: this.activatedRoute.snapshot.params._id,
        },
        {
          label: 'ticket_status',
          name: 'ticket_status',
          type: 'hidden',
          value: '',
        },
      ],
    };
  }

  ngOnInit() {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth',
    });
    this.activatedRoute.data.forEach((response: any) => {
      // resolve data call
      this.othersJobTicketDetails =
        response.resolvedata.results.othersJobTicketDetails;
      // console.log('response-------------',response.resolvedata.results);

      this.viewjobticket =
        response.resolvedata.results.jobTicketDetails[0];
      // console.log(this.viewjobticket, '+=+++=====viewjobtiket', response.resolvedata.results.jobTicketDetailsCommunitions);
      if (
        response.resolvedata.results.jobTicketDetailsCommunitions.length > 0
      ) {
        // console.log(response, '__________________madhu');
      }
      if (
        response.resolvedata.results.jobTicketDetailsCommunitions != null &&
        response.resolvedata.results.jobTicketDetailsCommunitions.length > 0 &&
        response.resolvedata.results.jobTicketDetailsCommunitions[0]
          .communition != null &&
        typeof response.resolvedata.results.jobTicketDetailsCommunitions[0]
          .communition != undefined
      ) {
        this.rplyjobticket =
          response.resolvedata.results.jobTicketDetailsCommunitions;
      }
      if (
        response.resolvedata.results.jobTicketDetailsCommunitions != null &&
        response.resolvedata.results.jobTicketDetailsCommunitions.length > 0 &&
        response.resolvedata.results.jobTicketDetailsCommunitions[0]
          .communition != null &&
        typeof response.resolvedata.results.jobTicketDetailsCommunitions[0]
          .communition != 'undefined' &&
        response.resolvedata.results.jobTicketDetailsCommunitions[0].communition
          .length > 2
      ) {
        // console.log('----')
        this.endcommFlag = false;
      }
      // this.rplyjobticket = response.resolvedata.results.jobTicketDetailsCommunitions;
      console.log(
        this.rplyjobticket.length,
        'madhumita crt',
        this.rplyjobticket
      );
      // this.addedby= response.resolvedata.results.user_name
    });

    if (this.usertype == 'admin') {
      this.formdata.fields[6].value = 'Awaiting-Consultant-Response';
    } else {
    this.formdata.fields[6].value = 'Awaiting-Admin-Response';
    }
    // console.log('viewjobticket**************',JSON.stringify(this.viewjobticket));
  }

  // recorddata(val:any){
  //   console.log(val)
  //   return val.reply_description
  // }
  previewimg(item: any, resourcename: any) {
    item.resourcename = resourcename;
    const dialogRef = this.dialog.open(previewimg, {
      panelClass: 'previewfile_modal',
      width: '900px',
      height: 'auto',
      data: item,
    });
    dialogRef.disableClose = true;
    dialogRef.afterClosed().subscribe((result: any) => {
      // console.log(result)
      // if (result.flag != null && result.flag == true) {
      //   this.router.navigateByUrl(this.googleScheduleRoute + result.associated_training + '/' + result.lesson_id);
      // }
    });
  }
  replybtn() {
    // this is used replybutton
    this.replyFlag = true;
    this.endcommFlag = false;
  }
  jobticket(value: any) {
    console.log(value);
    this.router.navigateByUrl('/jobticket/view-managejobticket/' + value._id);
  }
  getjobticketstatuschng(value: any) {
    // this is use for status changes
    console.log('getjobticketstatus-------------', value);

    this.statusupdateflg = true;
    const data: any = {
      data: {
        id: this.ticketId,
        ticket_status: value,
      },
    };
    this.Apiservice.getDatalistWithToken(
      data,
      'api/jobticketstatusupdate'
    ).subscribe((response: any) => {
      if (response.status === 'success') {
        const info: any = {
          condition: {
            _id: this.ticketId,
            user_id: this.activatedRoute.snapshot.params.user_id,
          },
          sort: { skip: 0, limit: 4 },
        };
        this.Apiservice.getDatalistWithToken(
          info,
          'api/getjobticketdetailsdata'
        ).subscribe((res: any) => {
          this.statusupdateflg = false;
          res = JSON.stringify(res);
          
          res = JSON.parse(res);
          console.log('soham+++++++++++++++++++++++',res);
          this.viewjobticket = res.results.jobTicketDetails[0];
          console.log('getjobticketstatuschng', this.viewjobticket);

          const message: any = 'Status Updated Successfully';
          this.snakBar.open(message, 'OK', {
            duration: 2000,
          });
        });
      } else {
        const message: any = 'Something Went Wrong';
        this.snakBar.open(message, 'OK', {
          duration: 2000,
        });
      }
    });
  }

  // reloaddata(){}

  listenFormFieldChange(value: any) {
    //  submit button click function
    console.log('listenFormFieldChange');
    if (value.field === 'fromsubmit') {
      this.replyFlag = false;
      const data: any = {
        condition: {
          _id: this.ticketId,
        },
        sort: { skip: 0, limit: 2 },
      };
      this.Apiservice.getDataWithoutToken(
        data,
        'api/getjobticketcommunicationdata'
      ).subscribe((res: any) => {
        this.rplyjobticket = [];
        this.indexVal = 2;
        this.rplyjobticket = res.results;
        console.log(this.rplyjobticket, '_+_=-=-=-======');
      });
    }
  }

  loadMoredata() {
    console.log(this.indexVal);
    this.loadmoreflg = true;
    this.indexVal = this.indexVal + 1; // this is use for loadmore
    // console.log(this.indexVal - 1, 'this.indexVal');
    console.log(this.indexVal);
    const data: any = {
      condition: {
        _id: this.ticketId,
      },
      sort: { skip: this.indexVal - 1, limit: 1 },
    };
    console.log(
      this.rplyjobticket,
      'this.rplyjobticket prev',
      this.rplyjobticket.length
    );
    this.Apiservice.getDataWithoutToken(
      data,
      'api/getjobticketcommunicationdata'
    ).subscribe((res: any) => {
      // console.log(res.results.length, '+++++___+_+_+_+');
      res = JSON.stringify(res);
      res = JSON.parse(res);
      this.loadmoreflg = false;
      if (res.results.length === 0) {
        this.endcommFlag = true;
        return;
      }
      this.rplyjobticket.push(res.results[0]);
      this.rplyjobticket = JSON.stringify(this.rplyjobticket);
      this.rplyjobticket = JSON.parse(this.rplyjobticket);
    });
    console.log(
      this.rplyjobticket.length,
      'this.rplyjobticket',
      this.rplyjobticket
    );
  }
}

@Component({
  selector: 'previewimg',
  templateUrl: 'previewimg.html',
})
export class previewimg implements OnInit {
  public dataVal: any;
  constructor(
    public dialogRef: MatDialogRef<previewimg>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData
  ) {
    this.dataVal = JSON.stringify(data);
    this.dataVal = JSON.parse(this.dataVal);
    // console.log(this.dataVal, '+++++')
  }
  ngOnInit() {}

  onNoClick() {
    // this.data.flag = false;
    this.dialogRef.close(this.data);
  }
}
