import { Component } from "@angular/core";
import { ThemePalette } from "@angular/material";
import { Event, NavigationCancel, NavigationEnd, NavigationError, NavigationStart, Router } from "@angular/router";
import { LoaderService } from "./services/loader.service";
import { HttpObservableService } from './services/http-observable.service';
import { ApiService } from './services/api.service';
import { NavAccessService } from "./services/nav-access.service";
import { CookieService } from "ngx-cookie-service";
import { DetectdomainService } from "./services/detectdomain.service";
@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.css"],
})
export class AppComponent {
  public user_id:any=''
  title = "backendbetoparedes-v2";
  loading = false;
  color: ThemePalette = 'warn';
  onActivate(event) {
    window.scroll(0, 0);
  };
  public domainName:any;
  // constructor(private router: Router,
  //   private loaderService: LoaderService) {
    
  // }
  constructor(
    public observableservice:HttpObservableService,
    public apiservice:ApiService,
    public nevaccessservice:NavAccessService,
    public router: Router,
    public loaderService: LoaderService,
    public cookie: CookieService,
    public detectdomain:DetectdomainService,

    ){
    this.router.events.subscribe((event: Event) => {
      switch (true) {
        case event instanceof NavigationStart: {
          console.log("NavigationStart ==>")
          if (this.cookie.check("type") && this.cookie.check("userid") && JSON.parse(this.cookie.get("type")) == 'technological-consultant' ) {
            this.user_id = JSON.parse(this.cookie.get("userid"));
            this.nevaccessservice.setData(this.user_id)
          }
          // this.loading = true;
          // this.loaderService.isLoading.next(true);
          break;
        }

        case event instanceof NavigationEnd:{
          console.log("NavigationEnd ==> ")
          if (this.cookie.check("type") && this.cookie.check("userid") && JSON.parse(this.cookie.get("type")) == 'technological-consultant' ) {
            this.user_id = JSON.parse(this.cookie.get("userid"));
            this.nevaccessservice.setData(this.user_id)
          }
          this.domainName = this.detectdomain.getdomain()
          console.log("domainName ==>",this.domainName)
          if (this.domainName == "medigrade") {
            const body_html =document.querySelector<HTMLElement>('body')  //.addClass("customclass");
            body_html.className = 'block_all_display_cls'
            this.loaderService.isLoading.next(true);
            let locationorigin = location.origin + '/assets/css/medigrade.css'
            let href_arr=[]
            Object.keys(document.styleSheets).map(function(key, index) {
              if(document.styleSheets[index].href != null){
                href_arr.push(document.styleSheets[index].href)
              }
            });

            console.log("href_arr",href_arr,locationorigin)

            if(!href_arr.includes(locationorigin)){
              console.log("detectdomain Inserting medigrade stylesheet ++")
              setTimeout(() => {
                let head = document.getElementsByTagName('head')[0];
                let link = document.createElement('link');
                link.rel = 'stylesheet';
                link.type = 'text/css';
                link.href = 'assets/css/medigrade.css';
                link.media = 'all';
                head.appendChild(link);
              }, 500);
  
              setTimeout(() => {
                this.loaderService.isLoading.next(false);
                body_html.classList.remove("block_all_display_cls");
              }, 1500);
            }else{
              console.log("detectdomain stylesheet exists ! ==>")
              this.loaderService.isLoading.next(false);
              body_html.classList.remove("block_all_display_cls");
            }
         
          }
          
          break;
        }
        // case event instanceof NavigationCancel:
        // case event instanceof NavigationError: {
        //   console.log("Navigation test ==> NavigationError")
        //   // this.loading = false;
        //   // this.loaderService.isLoading.next(false);
        //   break;
        // }
        // default: {
        //   break;
        // }
      }
    });
    observableservice.gethttpData().subscribe((data:any)=>{ // this is active when the data set into http-ovservable service and fetch the data here
      // console.warn(data);
      if(typeof (data) != 'undefined' && data != null){
        this.apiservice.getDataWithoutToken(data,"api10/http-logs").subscribe((data)=>{}) // this is use to store the response data which is get from http-observable
      }
    })
  }
  ngOnInit(){

  }
}
