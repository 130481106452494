import { HttpClient } from "@angular/common/http";
import { Component, OnInit, ViewChild } from "@angular/core";
import { MatAccordion } from "@angular/material";
import { DomSanitizer, SafeUrl } from "@angular/platform-browser";
import { ActivatedRoute, Router } from "@angular/router";
import { CookieService } from "ngx-cookie-service";
import { ApiService } from 'src/app/services/api.service';

import { UserserviceService } from 'src/app/services/userservice.service';


@Component({
  selector: 'app-faqview',
  templateUrl: './faqview.component.html',
  styleUrls: ['./faqview.component.css']
})
export class FaqviewComponent implements OnInit {
  @ViewChild('accordion', { static: false }) Accordion: MatAccordion
  step = 0;

  setStep(index: number) {
    console.log(index, 'index')
    this.step = index;
  }

  nextStep() {
    this.step++;
  }

  prevStep() {
    this.step--;
  }

  faqview: any = [];
  faqview_name: any = [];

  public searchData: any = '';

  public AllFaqCategory: any = [];
  public AllFaqCategoryrefresh: any = [];


  public categoryActiveClass: any = [];

  public flag: boolean = false;

  public activefaqflag: boolean = true;
  public faqloder: boolean = false;
  constructor(

    public router: Router,
    public Apiservice: ApiService,
    public http: HttpClient,
    public cookieservice: CookieService,
    public activatedRoute: ActivatedRoute,
    public userservice: UserserviceService,
    private sanitizer: DomSanitizer



  ) {

  }
  public youtubeurl: any = '';
  ngOnInit() {

    this.activatedRoute.data.forEach((res: any) => {
      console.log(res)
      // this.faqview = res.resolvedata.results.res; // data store from backend
      console.log(res.resolvedata.results.catname[0].questioncategory_s)
      for (let key in res.resolvedata.results.res) {
        console.log(res.resolvedata.results, "()()()()()()()()()");
        console.log(res.resolvedata.results.res[key].questioncategory_s, "{}{}{}{}{}{}{}{}");
        for (let key1 in res.resolvedata.results.catname) {
          if (res.resolvedata.results.catname[key1].questioncategory_s == res.resolvedata.results.res[key].questioncategory_s) {

            if (res.resolvedata.results.res[key].Youtubeid != null && res.resolvedata.results.res[key].Youtubeid != '') {
              res.resolvedata.results.res[key].safeUrl = this.sanitizer.bypassSecurityTrustUrl('https://www.youtube.com/embed/' + res.resolvedata.results.res[key].Youtubeid);
              // this.youtubeurl = this.sanitizer.bypassSecurityTrustUrl('https://www.youtube.com/watch?v=' + res.resolvedata.results.res[key].Youtubeid);
              console.log("if condition", res.resolvedata.results.res[key]);
            }
            this.faqview.push(res.resolvedata.results.res[key]);
            this.faqview_name.push(res.resolvedata.results.catname[key1]);

          }
        }
      }
      // //     this.faqview_name.map(item => item.questioncategory_s)
      // // .filter((value, index, self) => self.indexOf(value) === index)
      const unique = [...new Set(this.faqview_name.map(item => item.questioncategory_s))];
      console.log("unique", unique);
      this.faqview_name = [];
      for (let key in unique) {
        for (let key1 in res.resolvedata.results.catname) {
          if (unique[key] == res.resolvedata.results.catname[key1].questioncategory_s) {
            this.faqview_name.push(res.resolvedata.results.catname[key1]);
          }
        }
      }
      console.log(this.faqview, "fffffffffaaaaaaaaqqqqqqqqqqqqq");
      console.log(this.faqview_name, "fffffffffaaaaaaaaqqqqqqqqqqqqq+++++");

      if (res.resolvedata.results.res.length != 0) {
        this.categoryActiveClass[res.resolvedata.results.res[0].questioncategory_s] = 'active';
      }



    });


  }

  generateSafeImageUrl(val): SafeUrl {
    console.log('++++++++++')
    let url = 'https://www.youtube.com/embed/' + val
    return this.sanitizer.bypassSecurityTrustUrl(url);
  }



  activecatnamew(val) {
    this.faqloder = true;
    this.categoryActiveClass = [];

    let data = {

      secret: this.userservice.secret,
      token: this.userservice.jwttoken,
      condition: {},
      "sort": {
        "type": "desc",
        "field": "_id"
      },
      searchcondition: { questioncategory_s: val, user_role: JSON.parse(this.cookieservice.get('type')) },


    }

    this.categoryActiveClass[val] = 'active';

    this.Apiservice
      .getDataWithoutToken(data, 'api/allfaqlist')
      .subscribe((res: any) => {
        console.log(res)
        this.faqview = [];

        this.faqview = res.results.res;
        this.faqloder = false;

      })

  }

  public searchflag = false;

  search() {
    this.Accordion.openAll();

    // this.searchData='';
    console.log(this.searchData)


    let data = {

      secret: this.userservice.secret,
      token: this.userservice.jwttoken,
      condition: {},
      "sort": {
        "type": "desc",
        "field": "_id"
      },
      searchcondition: { question_s: { "$regex": this.searchData }, user_role: JSON.parse(this.cookieservice.get('type')) },


    }



    this.Apiservice
      .getDataWithoutToken(data, 'api/allfaqlist')
      .subscribe((res: any) => {
        console.log(res)
        this.faqview = [];

        this.faqview = res.results.res;


      })

    // "searchcondition":{"question":{"$regex":"hi"}


  }


  //refresh function
  refresh() {
    // this.Accordion.closeAll();

    // console.log('refresh');

    this.AllFaqCategory = [];
    this.AllFaqCategory = this.AllFaqCategoryrefresh;
    this.flag = false;
    if (this.searchData != '') {
      this.faqloder = true;
      let data = {

        secret: this.userservice.secret,
        token: this.userservice.jwttoken,
        condition: {},
        "sort": {
          "type": "desc",
          "field": "_id"
        },
        searchcondition: { user_role: JSON.parse(this.cookieservice.get('type')) },


      }

      this.Apiservice
        .getDataWithoutToken(data, 'api/allfaqlist')
        .subscribe((res: any) => {
          console.log(res)
          this.faqview = [];

          this.faqview = res.results.res;
          this.faqloder = false;

        })
    }
    this.searchData = '';
  }


  closeAllPanels() {
    this.Accordion.closeAll();
  }
  openAllPanels() {
    console.log(this.Accordion, '++++')
    this.Accordion.openAll();
  }



}
