import { Component, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiService } from 'src/app/services/api.service';
import { UserserviceService } from 'src/app/services/userservice.service';

@Component({
  selector: 'app-tmflow-contract',
  templateUrl: './tmflow-contract.component.html',
  styleUrls: ['./tmflow-contract.component.css']
})
export class TmflowContractComponent implements OnInit {
  public tmflowAlldata: any = {};
  public contractdata: any ;
  constructor(
    public apiservice: ApiService,
    public activatedRoute: ActivatedRoute,
    public userservice: UserserviceService,
    public router: Router,
    private _snackBar: MatSnackBar
  ) {
    console.log(this.userservice.type);

  }

  ngOnInit() {
    this.activatedRoute.data.subscribe((res: any) => {
      if (this.activatedRoute.snapshot.queryParams.flag == 'tmflowmedical') {
        console.log(res.resolvedata.result[0].contractdata);
        this.contractdata=res.resolvedata.result[0].contracts[0].status
        this.tmflowAlldata.billingaddress = res.resolvedata.result[0].contractdata.billingaddress;
        this.tmflowAlldata.shipingaddress = res.resolvedata.result[0].contractdata.shipingaddress;
        this.tmflowAlldata.Authsignature = res.resolvedata.result[0].contractdata.Authsignature;
        this.tmflowAlldata.unit_volume = res.resolvedata.result[0].contractdata.unit_volume;
        this.tmflowAlldata.title2 = res.resolvedata.result[0].contractdata.title2;
        this.tmflowAlldata.title = res.resolvedata.result[0].contractdata.title;
        this.tmflowAlldata.ship_via = res.resolvedata.result[0].contractdata.ship_via;
        this.tmflowAlldata.sales_agent = res.resolvedata.result[0].contractdata.sales_agent;
        this.tmflowAlldata.quotation_date = res.resolvedata.result[0].contractdata.quotation_date;
        this.tmflowAlldata.printed_name2 = res.resolvedata.result[0].contractdata.printed_name2;
        this.tmflowAlldata.printed_name = res.resolvedata.result[0].contractdata.printed_name;
        this.tmflowAlldata.medicalgroup = res.resolvedata.result[0].contractdata.medicalgroup;
        this.tmflowAlldata.medical_group_initial2 = res.resolvedata.result[0].contractdata.medical_group_initial2;
        this.tmflowAlldata.medical_group_initial1 = res.resolvedata.result[0].contractdata.medical_group_initial1;
        this.tmflowAlldata.medical_group_initial = res.resolvedata.result[0].contractdata.medical_group_initial;
        this.tmflowAlldata.hpsinitial2 = res.resolvedata.result[0].contractdata.hpsinitial2;
        this.tmflowAlldata.hpsinitial1 = res.resolvedata.result[0].contractdata.hpsinitial1;
        this.tmflowAlldata.hpsinitial = res.resolvedata.result[0].contractdata.hpsinitial;
        this.tmflowAlldata.date_singed2 = res.resolvedata.result[0].contractdata.date_singed2;
        this.tmflowAlldata.date_singed = res.resolvedata.result[0].contractdata.date_singed;
        this.tmflowAlldata.authsing = res.resolvedata.result[0].contractdata.authsing;
        this.tmflowAlldata.total = res.resolvedata.result[0].contractdata.total;
        this.tmflowAlldata.subtotal = res.resolvedata.result[0].contractdata.subtotal;
        this.tmflowAlldata.extendatewarent = res.resolvedata.result[0].contractdata.extendatewarent;
        this.tmflowAlldata.tax = res.resolvedata.result[0].contractdata.tax;
        this.tmflowAlldata.shipping = res.resolvedata.result[0].contractdata.shipping;
        this.tmflowAlldata.ammount = res.resolvedata.result[0].contractdata.ammount;
        this.tmflowAlldata.unitprice = res.resolvedata.result[0].contractdata.unitprice;
        this.tmflowAlldata.qty = res.resolvedata.result[0].contractdata.qty;
        this.tmflowAlldata.item = res.resolvedata.result[0].contractdata.item;
        this.tmflowAlldata.description = res.resolvedata.result[0].contractdata.description;
        this.tmflowAlldata.salestype = res.resolvedata.result[0].contractdata.salestype;



      } else {
        console.log(res.resolvedata.results.userView[0]);
        this.tmflowAlldata.billingaddress = res.resolvedata.results.userView[0].Billing_address;
        this.tmflowAlldata.shipingaddress = res.resolvedata.results.userView[0].shipping_address;
        this.tmflowAlldata.Authsignature = res.resolvedata.results.userView[0].singeealldata[0].First_Name + ' ' + res.resolvedata.results.userView[0].singeealldata[0].First_Name;
        this.tmflowAlldata.total = this.tmflowAlldata.shipping + this.tmflowAlldata.tax + this.tmflowAlldata.extendatewarent + this.tmflowAlldata.subtotal
      }

    })
  }

  submitbtn() {
    let data: {};
    data = {
      lead_id: this.activatedRoute.snapshot.params._id,
      tech_id: this.userservice.userid,
      product_id: this.activatedRoute.snapshot.params.p_id,

      contracts: [
        {
          status: "request",
          added_by_id: this.userservice.userid,
          addedby: this.userservice.fullname,
          addedondatetime: Math.round(new Date().getTime()),
          type: this.userservice.type,
        },
      ],
      contractflag: "tmflow_contract",
      contractdata: this.tmflowAlldata,
      contact_id: this.activatedRoute.snapshot.params.sing_id,
    }
    this.apiservice
      .getDataWithoutToken(data, "api/update-new_contract")
      .subscribe((res: any) => {
        this.openSnackBar('Contract Submitted ', "ok");
        this.activatedRoute.queryParams.subscribe((data: any) => {
          // console.warn(data);
          if (typeof (data.listing) != 'undefined' && data.listing != null && data.listing != '') {
            if (data.listing == "true") {
              if (this.userservice.type == 'technological-consultant') {
                this.router.navigateByUrl('/contract-manager/contract-request/list/equipment-purchase/tech');
              }
              if (this.userservice.type == 'admin') {
                this.router.navigateByUrl('/contract-manager/contract-request/list/equipment-purchase');
              }
            }
          } else {
            this.router.navigateByUrl(
              "/user-details/lead/" + this.activatedRoute.snapshot.params._id
            );
          }
        })
        // this.router.navigateByUrl(
        //   "/user-details/lead/" + this.activatedRoute.snapshot.params._id
        // );
        // window.close();
      });
  }
  canclebtn() {
    this.activatedRoute.queryParams.subscribe((data: any) => {
      // console.warn(data);
      if (typeof (data.listing) != 'undefined' && data.listing != null && data.listing != '') {
        if (data.listing == "true") {
          if (this.userservice.type == 'technological-consultant') {
            this.router.navigateByUrl('/contract-manager/contract-request/list/equipment-purchase/tech');
          }
          if (this.userservice.type == 'admin') {
            this.router.navigateByUrl('/contract-manager/contract-request/list/equipment-purchase');
          }
        }
      } else {
        this.router.navigateByUrl(
          "/user-details/lead/" + this.activatedRoute.snapshot.params._id
        );
      }
    })
    // this.router.navigateByUrl('/user-details/lead/' + this.activatedRoute.snapshot.params._id);
  }
  openSnackBar(message: string, action: string) {
    this._snackBar.open(message, action, {
      duration: 2000,
    });
  }
  onKeypressEvent(val: any) {
    console.log(this.tmflowAlldata.subtotal, val);
    // this.tmflowAlldata.subtotal = val;
    this.tmflowAlldata.total = this.tmflowAlldata.subtotal + this.tmflowAlldata.extendatewarent + this.tmflowAlldata.tax + this.tmflowAlldata.shipping

  }

}
