import { HttpClient } from '@angular/common/http';
import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { ActivatedRoute, Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { ApiService } from 'src/app/services/api.service';
import { UserserviceService } from 'src/app/services/userservice.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
export interface DialogData {
  data: any;
}
export interface DialogData2 {
  url: any;
  Htext: any;
  Ptext: any;
}
@Component({
  selector: 'app-distributor-dashboard',
  templateUrl: './distributor-dashboard.component.html',
  styleUrls: ['./distributor-dashboard.component.css'],
})
export class DistributorDashboardComponent implements OnInit {
  contractmanagerListdatatabledetailskip = [];
  public updatetotechcommission: any = false;
  public addcommission: any = false;
  public mostactiveblockflag: any = 0;
  public mostactiveblockoneflag: any = 0;
  public slideindex_signature: any = 0;
  public contractmanagerdata: any = [];
  public contractmanager: any = [];
  public traingdata: any = [];
  public closerdata: any = [];
  public sharelinkview: any = [];
  public userlist: any = [];
  public commissiondata: any = [];
  public contractmanagertable: any = [];
  public contractmanagertablecount: any = 0;
  public activecommissionFlag: any = 0;
  public contractmanagerFlag: boolean = false;
  public activetoggleactivecloserFlag: boolean = false;
  public closerListdatatableflag: boolean = false;
  public contractmanagerListdatatableflag: boolean = false;
  public technorecordfound: any = false;
  public contractnorecordfound: any = false;
  public closernorecordfound: any = false;
  public getallsharelinksdata: any;
  public dristibutor: any = [];
  public adminarray: any = [];
  public activetrainingFlag: boolean = false;
  public activetoggleactivesalesmanagersFlag: boolean = false;
  salesmanagerListdatatable: any = [];
  closerListdatatable: any = [];
  techtabledata: any = [];
  public salesListdatatableflag: boolean = false;
  public WebinarsFlag: boolean = false;
  public slideindex_jobtickets: any = 0;
  jobcategoryListdatatable: any[];
  public trainingdetails: any = [];
  public datesearchsourcecount: any = 0;
  public closer_datesearchsourcecount: any = 0;
  public techdatesearchsourcecount: any = 0;
  public mostactiveblocktwoflag: any = 0;
  public mostactiveblockthreeflag: any = 0;
  salesmanagerListdatatabledetailskip = [];
  public jobticket: any = [];
  public jobticketsFlag: boolean = false;
  public jobticketsListdatatableflag: boolean = false;
  public trainingListdatatableflag: boolean = false;
  public commissionprogressflag: boolean = false;
  public isrefresh_technological_consultant: boolean = false;
  public isrefresh_contract_manager: boolean = false;
  public isrefresh_closer: boolean = false;
  public isrefresh_job_ticket: boolean = false;
  public isrefresh_tech_commission: boolean = false;
  public trainingnorecordfound: boolean = false;
  public isrefresh_training_report: boolean = false;
  public techcommissionnorecordfound: boolean = false;
  public userid: any;
  public discoverycall: any;
  // public activeflag: any = 0;
  public mostactiveflag = 0;
  public userFullName: any;
  searchendpoint = '';
  deleteendpoint = '';
  updateendpoint = '';
  statusarray: any = [];
  public searching_settings_commission: any = {};
  public jobcategoryListdatatablelisting: any = [];
  public commissiondatalistview: any = [];
  editroute = '';
  public tech_dist_search: any = {};
  public searchsettingscontractmanager: any = {};
  public closer_dist_search: any = {};
  public commission_data_details: any = [];
  public limitcondsales: any = {
    limit: 10,
    skip: 0,
  };
  searchsettingssales: any = {
    textsearch: [{ label: 'Search By Email', field: 'email_s' }],
  };
  // other data
  libdatasales: any = {
    basecondition: { type: 'sales-person' },
    detailview_override: [
      { key: 'fullname', val: 'Full Name' },
      { key: 'email', val: 'Email' },
    ],
    tableheaders: ['fullname', 'email', 'logincounts', 'children'],
    hidemultipleselectbutton: true,
    hideaction: true,
    hideeditbutton: true, // all these button options are optional not mandatory
    hidedeletebutton: true,
    hideviewbutton: false,
    hidestatustogglebutton: true,
  };

  public slideindexsales_persons: any = 0;
  salesmanagerListdatatableskip = [];
  public modifyheaderarraysalesmanager: any = {
    fullname: 'Full Name',
    email: 'Email',
    logincounts: 'Login count',
    children: 'Recruit count',
  };
  // send basic sort data
  sortdatasales: any = {
    type: 'desc',
    field: '_id',
    options: [],
  };
  public slideindex: any = 0;
  slidesales_personstimeout: any;
  public datacollectionsales = 'api/distributorsdashboard';
  // jobticket table

  slide_jobticketstimeout: any;
  // jobtickets for table field skip
  jobcategoryListdatatablekip: any = [];

  // jobtickets for Table Detail Field Skip
  jobcategoryListdatatabledetailskip: any = [];

  // send basic limit data
  limitcondjobtickets: any = {
    limit: 5,
    skip: 0,
  };

  // other data
  libdatajobtickets: any = {
    basecondition: { user_id: this.userservice.userid },
    detailview_override: [
      { key: 'ticketid', val: 'Ticket Id' },
      { key: 'ticket_title', val: 'Ticket Title' },
      { key: 'user_name', val: 'Reported By' },
      { key: 'createdon_datetime', val: 'Issue date' },
      { key: 'ticket_category_name', val: 'Issue Category' },
      { key: 'lastlogin_datetime', val: 'Last Activity' },
      { key: 'ticket_status', val: 'Status' },
    ],
    tableheaders: [
      'ticketid',
      'ticket_title',
      // 'user_name',
      // 'createdon_datetime',
      'ticket_category_name',
      'lastlogin_datetime',
      'ticket_status',
    ],

    hidemultipleselectbutton: true,
    hideaction: false,
    hideeditbutton: true, // all these button options are optional not mandatory
    hidedeletebutton: true,
    hidestatustogglebutton: true,
    hideviewbutton: true,
    custombuttons: [
      {
        label: 'View Jobticket',
        type: 'listner',
        id: 'jobticketinnewpage',
        classname: 'Folder_view',
        tooltip: 'Open Jobticket View New Page',
      },
    ],
  };
  libdatatraning: any = {
    basecondition: { parentid: this.userservice.userid },
    detailview_override: [
      { key: 'ticketid', val: 'Ticket ID' },
      { key: 'user_name', val: 'Reported By' },
      { key: 'createdon_datetime', val: 'Issue date' },
      { key: 'ticket_category_name', val: 'Issue Category' },
      { key: 'reply_datetime', val: 'Last Activity' },
    ],
    tableheaders: [
      'user_fullname',
      'totaldata',
      'percent',
      'donedata',
      'current_lesson_name',
      'next_lesson_name',
      'previous_lesson_name'
    ],

    hidemultipleselectbutton: true,
    hideaction: false,
    hideeditbutton: true, // all these button options are optional not mandatory
    hidedeletebutton: true,
    hideviewbutton: true,
    hidestatustogglebutton: true,
    custombuttons: [
      {
        label: 'Open Folder View Same Page',
        type: 'listner',
        id: 'i1',
        classname: 'open_in_same_list',
        tooltip: 'Open Folder View Same Page',
      },
      {
        label: 'Open Folder View New Page',
        type: 'listner',
        id: 'i1',
        classname: 'open_in_new_list',
        tooltip: 'Open Folder View New Page  ',
      },
    ],
  };

  // modify header(key : modify header name)
  public modifyheaderarrayjob: any = {
    ticket_title: 'Ticket Title',
    createdon_datetime: 'Issue date',
    ticket_category_name: 'Issue Category',
    lastlogin_datetime: 'Last Activity',
    ticket_status: 'Status',
    ticketid: 'Ticket ID',
  };
  public modifyheaderarraytraining: any = {
    user_fullname: 'User Name',
    email: 'Email',
    traning_name: 'Associated Training',
    donedata: 'Lession Done',
    totaldata: 'No of Lesson',
    percent: 'Percent',
    'current_lesson_name': 'Current Lesson Name',
    'next_lesson_name': 'Next Lesson Name',
    'previous_lesson_name': 'Previous Lesson Name'
  };


  // send basic sort data
  sortdatajobtickets: any = {
    type: 'desc',
    field: 'lastlogin_datetime',
    options: [
      'lastlogin_datetime',
      'ticketid',
      'ticket_status',
      'reply_datetime',
      'ticket_title',
    ],
  };
  sortdatatraining: any = {
    type: 'desc',
    field: 'fullname',
    options: [
      'fullname',
      'email',
      'traning_name',
      // 'lesson_done',
      // 'totallesson',
      // 'percent',
    ],
  };

  datacollectionjobtickets: any = 'api/getjobticketlistdata';
  datacollectiontraining: any = 'api/techdetails';

  searchsettingsjobtickets: any = {
    textsearch: [{ label: 'Search By Email', field: 'email_s' }], // this is use for  Autocomplete search
  };
  // jobticket

  // techconsulat
  public techconsultantsListdatatableflag: boolean = false;
  public activetoggleactiveconsultantsFlag: boolean = false;
  techconsultantsListdatatable: any = [];
  // Sales Managers for table field skip
  techconsultantsListdatatableskip: any = [];
  contractmanagerListdatatablekip: any = [];

  // Sales Managers for Table Detail Field Skip
  techconsultantsListdatatabledetailskip: any = [];

  public modifyheaderarraytechconsultants: any = {
    fullname: 'Full Name',
    email: 'Email',
    // logincounts: 'Login count',
    children: 'Recruit count',
    lastlogin_datetime: 'Last Login',
  };
  public modifyheaderarraycontractmanager: any = {
    fullname: 'Full Name',
    email: 'Email',
    lastlogin_datetime: 'Last Login',
  };
  limitcondtechconsultants: any = {
    limit: 5,
    skip: 0,
  };
  limitcondcontractmanager: any = {
    limit: 5,
    skip: 0,
  };

  // other data
  libdatatechconsultants: any = {
    basecondition: {
      type: 'technological-consultant',
      parentid: JSON.parse(this.cookieservice.get('userid')),
    },
    detailview_override: [
      { key: 'fullname', val: 'Full Name' },
      { key: 'email', val: 'Email' },
    ],
    tableheaders: [
      'fullname',
      'email',
      // 'logincounts',
      'lastlogin_datetime',
    ],

    hidemultipleselectbutton: true,
    hideaction: false,
    hideeditbutton: true, // all these button options are optional not mandatory
    hidedeletebutton: true,
    hideviewbutton: true,
    hidestatustogglebutton: true,
    custombuttons: [
      {
        label: 'Folder View ',
        route: 'user-details/',
        type: 'internallink',
        tooltip: 'Folder View',
        classname: 'Folder_view',
        param: ['type', '_id'],
      },
      {
        label: 'Open Folder View New Page',
        type: 'listner',
        id: 'i1',
        classname: 'OpenFolderView',
        tooltip: 'Open Folder View New Page  ',
      },
    ],
  };
  libdatacontractmanager: any = {
    basecondition: { type: 'contract-manager' },
    detailview_override: [
      { key: 'fullname', val: 'Full Name' },
      { key: 'email', val: 'Email' },
    ],
    tableheaders: [
      'fullname',
      'email',
      // 'logincounts',
      'lastlogin_datetime',
    ],

    hidemultipleselectbutton: true,
    hideaction: false,
    hideeditbutton: true, // all these button options are optional not mandatory
    hidedeletebutton: true,
    hideviewbutton: true,
    hidestatustogglebutton: true,
    custombuttons: [
      {
        label: 'Folder View ',
        route: 'user-details/',
        type: 'internallink',
        tooltip: 'Folder View',
        classname: 'Folder_view',
        param: ['type', '_id'],
      },
      {
        label: 'Open Folder View New Page',
        type: 'listner',
        id: 'i1',
        classname: 'OpenFolderView',
        tooltip: 'Open Folder View New Page  ',
      },
    ],
  };
  // send basic sort data
  sortdatatechconsultants: any = {
    type: 'desc',
    field: 'lastlogin_datetime',
    options: ['fullname', 'email', 'lastlogin_datetime'],
  };
  sortdatacontractmanager: any = {
    type: 'desc',
    field: 'lastlogin_datetime',
    options: ['fullname', 'email', 'lastlogin_datetime'],
  };
  datacollectionconsultants: any = 'api/userlistview';
  datacollectioncontractmanager: any = 'api/userlistview';
  datacollectioncloser: any = 'api/userlistview';
  public slideindex_technological: any = 0;

  // techconsult

  // closer block
  public modifyheaderarray_closer: any = {
    fullname: 'Full Name',
    email: 'Email',
    // logincounts: 'Login count',
    children: 'Recruit count',
    lastlogin_datetime: 'Last Login',
  };
  public closer_Listdatatableskip: any = [];
  public closer_Listdatatabledetailskip: any = [];

  sortdatacloser: any = {
    type: 'desc',
    field: 'lastlogin_datetime',
    options: ['fullname', 'email', 'lastlogin_datetime'],
  };
  limitcondcloser: any = {
    limit: 5,
    skip: 0,
  };
  libdatacloser: any = {
    basecondition: { type: 'closer' },
    detailview_override: [
      { key: 'fullname', val: 'Full Name' },
      { key: 'email', val: 'Email' },
    ],
    tableheaders: [
      'fullname',
      'email',
      // 'logincounts',
      'lastlogin_datetime',
    ],
    hidemultipleselectbutton: true,
    hideaction: false,
    hideeditbutton: true, // all these button options are optional not mandatory
    hidedeletebutton: true,
    hideviewbutton: true,
    hidestatustogglebutton: true,
    custombuttons: [
      {
        label: 'Folder View ',
        route: 'user-details/',
        type: 'internallink',
        tooltip: 'Folder View',
        classname: 'Folder_view',
        param: ['type', '_id'],
      },
      {
        label: 'Open Folder View New Page',
        type: 'listner',
        id: 'i1',
        classname: 'OpenFolderView',
        tooltip: 'Open Folder View New Page  ',
      },
    ],
  };
  public slideindex_closer: any = 0;
  // commission block view data
  public datesearchsourcecount_commission: any = 0;
  public commissiondatatablekip: any = [];
  public modifyheaderarraycommissiondata: any = {
    distributor_fullname: 'Distributer Fullname',
    tech_fullname: 'Tech Name',
    tech_email: 'Tech Email',
    tech_event_commission: 'Event Commission',
    tech_commission: 'Sales Commission',
  };
  public commissiondatatabledetailskip: any = [];
  sortdata_commission: any = {
    type: 'desc',
    field: '_id',
    options: [
      'tech_fullname',
      'tech_email',
      'tech_event_commission',
      'tech_commission',
    ],
  };
  datacollection_commission: any =
    'api10/get-distributor-commission-management-lists';
  limitcond_commission: any = {
    limit: 5,
    skip: 0,
  };
  libdata_commission: any = {
    basecondition: { parentid: this.userservice.userid },
    detailview_override: [
      { key: 'fullname', val: 'Full Name' },
      { key: 'email', val: 'Email' },
    ],
    tableheaders: [
      'tech_fullname',
      'tech_email',
      'tech_event_commission',
      'tech_commission',
    ],
    hidemultipleselectbutton: true,
    hideaction: true,
    hideeditbutton: true, // all these button options are optional not mandatory
    hidedeletebutton: true,
    hideviewbutton: true,
    hidestatustogglebutton: true,
    custombuttons: [],
  };

  public commission: any;
  public gameplancall: any;
  public onboardingcall: boolean = false;
  public onboardingcall1: boolean = false;
  public jobticketnorecordfound: boolean = false;
  public fullname: any = '';
  public lastlogin: Date;
  public showing_commission_data: any = [];
  constructor(
    public userservice: UserserviceService,
    public Apiservice: ApiService,
    public router: Router,
    public http: HttpClient,
    public cookieservice: CookieService,
    public activatedRoute: ActivatedRoute,
    public dialog: MatDialog
  ) {
    if (this.cookieservice.check('firstname')) {
      this.fullname =
        this.fullname + JSON.parse(this.cookieservice.get('firstname'));
      console.log(this.fullname, '>>>>>>....');
    }
    if (this.cookieservice.check('lastname')) {
      this.fullname =
        this.fullname + ' ' + JSON.parse(this.cookieservice.get('lastname'));
      console.log(this.fullname, '>>>>>>....');
    }
    if (this.cookieservice.check('lastlogindate')) {
      this.lastlogin = JSON.parse(this.cookieservice.get('lastlogindate'));
      console.log(this.lastlogin, '>>>>>><<<<<<<....');
    }
    this.userid = JSON.parse(this.cookieservice.get('userid'));
    this.userFullName =
      this.userservice.firstname + ' ' + this.userservice.lastname;

    if (
      this.cookieservice.get('gameplancall') &&
      JSON.parse(this.cookieservice.get('gameplancall')) === true
    ) {
      // console.log(this.onboardingcall);
      this.gameplancall = true;
      console.log(this.onboardingcall);
    } else {
      this.gameplancall = false;
    }
    if (
      this.cookieservice.get('onbordingcall') &&
      JSON.parse(this.cookieservice.get('onbordingcall')) === true
    ) {
      // console.log(this.onboardingcall);
      this.onboardingcall = true;
      console.log(this.onboardingcall);
    } else {
      this.onboardingcall = false;
    }
    if (
      this.cookieservice.get('viewcommissionflag') &&
      JSON.parse(this.cookieservice.get('viewcommissionflag')) === true
    ) {
      this.addcommission = true;
    } else {
      this.addcommission = false;
    }
    console.log(this.addcommission);
    let data: any = {
      condition: {
        limit: 5,
        skip: 0,
      },
      sort: {
        type: 'desc',
        field: '_id',
      },

      searchcondition: { parentid: this.userservice.userid },
      secret: this.userservice.secret,
      token: this.userservice.jwttoken,
    };
    this.Apiservice.getDataWithoutToken(
      data,
      'api10/get-distributor-commission-management-lists'
    ).subscribe((data: any) => {
      console.log(data);
      this.commissiondata = data.results.res;
      if (this.commissiondata.length > 0) {
        // this.mostactiveblockthreeflag = this.mostactiveblockthreeflag + 1;
      }
    });
  }
  public forntendUrl = environment.forntendUrl;
  public forntendUrl2 = environment.forntendUrl2
  tergetblank() {
    window.open(
      environment.forntendUrl + this.userid + environment.forntendUrl2,
      'Independent Window'
    );
  }
  openDialogForSearchListViewForTech(val: any) {
    const dialogRef = this.dialog.open(Searchmodal, {
      // width: '250px',
      data: val,
      panelClass: '',
    });

    dialogRef.afterClosed().subscribe((result) => {
      console.log('result*************>', result);
      let data1 = {}
      if (result != undefined) {
        this.jobticketnorecordfound = false;
        this.technorecordfound = false;
        this.contractnorecordfound = false;
        this.closernorecordfound = false;
        this.trainingnorecordfound = false;
        this.techcommissionnorecordfound = false;
        if (val === 'technological-consultant') {
          this.techconsultantsListdatatableflag = true;
          delete result.createdon_datetime;
          if (
            typeof result.lastlogin_datetime.$gte != 'undefined' ||
            typeof result.lastlogin_datetime.$lte != 'undefined'
          ) {
            console.log(result.lastlogin_datetime);
          } else {
            delete result.lastlogin_datetime;
          }
          this.techconsultantsListdatatable = [];
        }
        if (val === 'contract-manager') {
          this.contractmanagerListdatatableflag = true;
          delete result.createdon_datetime;
          if (
            typeof result.lastlogin_datetime.$gte != 'undefined' ||
            typeof result.lastlogin_datetime.$lte != 'undefined'
          ) {
            console.log(result.lastlogin_datetime);
          } else {
            delete result.lastlogin_datetime;
          }
          this.contractmanagerdata = [];
        }
        if (val === 'closer') {
          this.closerListdatatableflag = true;
          delete result.createdon_datetime;
          if (
            typeof result.lastlogin_datetime.$gte != 'undefined' ||
            typeof result.lastlogin_datetime.$lte != 'undefined'
          ) {
            console.log(result.lastlogin_datetime);
          } else {
            delete result.lastlogin_datetime;
          }
          this.closerListdatatable = [];
        }
        if (val === 'job_ticket') {
          this.jobticketsListdatatableflag = true;
          this.jobcategoryListdatatablelisting = [];
          if (result.ticketid != '') {
            result.ticketid = { $regex: result.ticketid };
          }
          result['user_id'] = this.userservice.userid;
          delete result.type;
          delete result.createdon_datetime;
          if (
            typeof result.lastlogin_datetime.$gte != 'undefined' ||
            typeof result.lastlogin_datetime.$lte != 'undefined'
          ) {
            console.log(result.lastlogin_datetime);
          } else {
            delete result.lastlogin_datetime;
          }
        }
        if (val === 'training_report') {
          this.trainingListdatatableflag = true;
          this.trainingdetails = [];
          delete result.type;
          delete result.createdon_datetime;
          delete result.lastlogin_datetime;
        }
        if (val === 'tech_commission') {
          this.commissionprogressflag = true;
          this.commissiondatalistview = [];
          result['parentid'] = this.userservice.userid;
          delete result.type;
          delete result.createdon_datetime;
          delete result.lastlogin_datetime;
        }
        console.log('The dialog was closed', result);
        Object.keys(result).forEach((k) => result[k] === '' && delete result[k]);
        let endpoint = '';
        if (val === 'job_ticket') {
          endpoint = 'api/getjobticketlistdata';
        } else {
          if (val === 'training_report') {
            console.log(result);
            data1 = { data: { "id": this.userservice.userid, "type": this.userservice.type, searchcondition: { fullname: { $regex: result } } } }

            endpoint = 'traing_api/traninguserpercent';
          } else {
            if (val === 'tech_commission') {
              endpoint = 'api10/get-distributor-commission-management-lists';
            } else {
              endpoint = 'api/userlistview';
            }
          }
        }
        const data: any = {
          // How many data showing in first page
          condition: {
            limit: 5,
            skip: 0,
          },
          sort: {
            type: 'desc',
            field: '_id',
          },

          searchcondition: result,
          secret: this.userservice.secret,
          token: this.userservice.jwttoken,
        };
        if (val == 'training_report') {
          data1['endpoint'] = 'traing_api/traninguserpercent';
          this.Apiservice
            .getDataFortraing(data1)
            .subscribe((res: any) => {
              console.log(res);
              this.trainingdetails = res.results.res;
              if (this.trainingdetails.length === 0) {
                this.trainingnorecordfound = true;
              }


              this.trainingListdatatableflag = false;
            });
        } else {
          if (val === 'training_report') {
            data['parentid'] = this.userservice.userid;
          }
          this.Apiservice.getDataWithoutToken(data, endpoint).subscribe(
            (res: any) => {
              console.log(res);
              if (val === 'technological-consultant') {
                this.isrefresh_technological_consultant = true;
                this.techconsultantsListdatatable = res.results.res;
                if (this.techconsultantsListdatatable.length === 0) {
                  this.technorecordfound = true;
                }
                this.techconsultantsListdatatableflag = false;
              }
              if (val === 'contract-manager') {
                this.isrefresh_contract_manager = true;
                this.contractmanagerdata = res.results.res;
                if (this.contractmanagerdata.length === 0) {
                  this.contractnorecordfound = true;
                }
                this.contractmanagerListdatatableflag = false;
              }
              if (val === 'closer') {
                this.isrefresh_closer = true;
                this.closerListdatatable = res.results.res;
                if (this.closerListdatatable.length === 0) {
                  this.closernorecordfound = true;
                }
                this.closerListdatatableflag = false;
              }
              if (val === 'job_ticket') {
                this.isrefresh_job_ticket = true;
                this.jobcategoryListdatatablelisting = res.results.res;
                if (this.jobcategoryListdatatablelisting.length === 0) {
                  this.jobticketnorecordfound = true;
                }
                this.jobticketsListdatatableflag = false;
              }
              if (val === 'training_report') {
                this.isrefresh_training_report = true;
                this.trainingListdatatableflag = false;
                this.trainingdetails = res.results.res;
                if (this.trainingdetails.length === 0) {
                  this.trainingnorecordfound = true;
                }
              }
              if (val === 'tech_commission') {
                this.isrefresh_tech_commission = true;
                this.commissionprogressflag = false;
                this.commissiondatalistview = res.results.res;
                if (this.commissiondatalistview.length === 0) {
                  this.techcommissionnorecordfound = true;
                }
              }
              // this.datesearchsourcecount = res.count; // sarch count  (How many data there)
            }
          );
        }
      }
    });
  }
  toggleWebinars(e: any) {
    e.checked ? (this.WebinarsFlag = true) : (this.WebinarsFlag = false);
  }

  toggleactivesalesmanagers(e: any) {
    console.log(e.checked, this.activetoggleactivesalesmanagersFlag);
    e.checked === true
      ? this.flagfn()
      : (this.activetoggleactivesalesmanagersFlag = false);
  }

  flagfn() {
    this.activetoggleactivesalesmanagersFlag = true;
    this.salesmanagersListView();
  }

  salesmanagersListView() {
    // this.salesListdatatableflag = true;
    this.activetoggleactivesalesmanagersFlag = true;
    const endpoint = 'api/userlistview';
    const data: any = {
      // How many data showing in first page
      secret: this.userservice.secret,
      token: this.userservice.jwttoken,
      source: '',
      condition: {
        // condition for how much data show in one time
        limit: 5,
        skip: 0,
      },
      sort: {
        type: 'desc', // data sort by decreasing order
        field: 'fullname', // data sort by first name
      },
      searchcondition: {
        type: 'sales-person',
        parentid_s: this.userservice.userid,
      },
    };

    this.Apiservice.getDataWithoutToken(data, endpoint).subscribe(
      (res: any) => {
        // console.log(res, 'data')
        // this.salesmanagerListdatatable = res.results.res;
        // sarch count  (How many data there)
        this.salesListdatatableflag = false;
      }
    );
  }

  // slidesales_persons(e: string) {
  //   // console.log('slidesales_persons')
  //   let slides: any = document.getElementsByClassName('main_practice_block2');
  //   let scount: any = document.querySelectorAll('.main_practice_block2').length;
  //   if (this.slideindexsales_persons > scount) { this.slideindexsales_persons = 1; }
  //   let slideIndexsales_persons: any = this.slideindexsales_persons++;
  //   let dots: any = document.getElementsByClassName('dot');
  //   for (let i = 0; i < slides.length; i++) {
  //     slides[i].style.display = 'none';
  //   }
  //   if (slideIndexsales_persons > slides.length) { slideIndexsales_persons = 1 }
  //   for (let i = 0; i < dots.length; i++) {
  //     if (dots[i] != null) dots[i].className = dots[i].className.replace(' active', '');
  //   }
  //   if (slideIndexsales_persons > 0 && slides[slideIndexsales_persons - 1] != null) {
  //     slides[slideIndexsales_persons - 1].style.display = 'block';
  //   }
  //   if (slideIndexsales_persons > 0 && dots[slideIndexsales_persons - 1] != null) { dots[slideIndexsales_persons - 1].className += ' active'; }

  //   if (e === null || e === '') {
  //     this.slidesales_personstimeout = setTimeout(() => {
  //       this.slidesales_persons('');
  //     }, 5000);
  //   }

  // }

  slideforsalespersonsNext(idx: any) {
    this.slideindexsales_persons = 0;
    this.slideindexsales_persons = idx + 1;
    let slides: any = document.getElementsByClassName('main_practice_block2');
    let scount: any = document.querySelectorAll('.main_practice_block2').length;

    // console.log('next 111', this.slideindexsales_persons, scount)
    if (this.slideindexsales_persons >= scount) {
      this.slideindexsales_persons = 0;
    }
    let slideIndexsales_persons: any = this.slideindexsales_persons++;

    let dots: any = document.getElementsByClassName('dot');

    for (let i = 0; i < slides.length; i++) {
      slides[i].style.display = 'none';
    }

    slideIndexsales_persons++;
    if (slideIndexsales_persons > slides.length) {
      slideIndexsales_persons = 1;
    }
    for (let i in dots.length) {
      // console.log(i, '__')
      if (dots[i] != null) {
        dots[i].className = dots[i].className.replace(' active', '');
      }
    }
    if (
      slideIndexsales_persons > 0 &&
      slides[slideIndexsales_persons - 1] != null
    )
      slides[slideIndexsales_persons - 1].style.display = 'block';
    if (
      slideIndexsales_persons > 0 &&
      dots[slideIndexsales_persons - 1] != null
    )
      dots[slideIndexsales_persons - 1].className += ' active';

    // this.slidesales_persons('e');
  }

  slideforsalespersonsPrev(idx: any) {
    let slides: any = document.getElementsByClassName('main_practice_block2');
    let scount: any = document.querySelectorAll('.main_practice_block2').length;
    this.slideindex_signature = idx;
    if (this.slideindex_signature <= 0) {
      this.slideindex_signature = scount;
    }
    let slideindex_signature: any = this.slideindex_signature;
    for (var i = slides.length - 1; i >= 0; i--) {
      slides[i].style.display = 'none';
    }
    if (slideindex_signature > 0 && slides[slideindex_signature - 1] != null) {
      slides[slideindex_signature - 1].style.display = 'block';
    }
    slideindex_signature--;
  }
  slideforcommissionPrev(idx: any) {
    let slides: any = document.getElementsByClassName('main_practice_block5');
    let scount: any = document.querySelectorAll('.main_practice_block5').length;
    this.slideindex_signature = idx;
    if (this.slideindex_signature <= 0) {
      this.slideindex_signature = scount;
    }
    let slideindex_signature: any = this.slideindex_signature;
    for (var i = slides.length - 1; i >= 0; i--) {
      slides[i].style.display = 'none';
    }
    if (slideindex_signature > 0 && slides[slideindex_signature - 1] != null) {
      slides[slideindex_signature - 1].style.display = 'block';
    }
    slideindex_signature--;
  }
  // jobticket
  togglejobtickets(e: any) {
    //  console.warn('jobtickets', e.checked)

    if (e.checked === true) {
      //  console.warn('jobtickets', e.checked);
      this.jobticketsFlag = true;

      this.jobticketsListView();
    } else {
      //  console.warn('jobtickets', e.checked);
      this.jobticketsFlag = false;
      this.jobcategoryListdatatablelisting = [];
      this.jobticketnorecordfound = false;
      this.isrefresh_job_ticket = false;
    }
  }

  jobticketsListView() {
    this.jobticketsListdatatableflag = true;
    const endpoint = 'api/getjobticketlistdata';
    const data: any = {
      // How many data showing in first page
      secret: this.userservice.secret,
      token: this.userservice.jwttoken,
      source: '',
      condition: {
        // condition for how much data show in one time
        limit: 5,
        skip: 0,
      },
      sort: {
        type: 'desc', // data sort by decreasing order
        field: 'createdon_datetime', // data sort by first name
      },
      searchcondition: { user_id: this.userservice.userid },
    };

    this.Apiservice.getDataWithoutToken(data, endpoint).subscribe(
      (res: any) => {
        // console.log(res, 'jobticket');
        this.jobcategoryListdatatablelisting = res.results.res; // sarch count  (How many data there)
        if (this.jobcategoryListdatatablelisting.length === 0) {
          this.jobticketnorecordfound = true;
        }
        this.jobticketsListdatatableflag = false;
      }
    );
  }

  slide_jobtickets(e: string) {
    // console.log('slide_jobtickets')
    // let i: any;
    let slides: any = document.getElementsByClassName('main_practice_block4');
    let scount: any = document.querySelectorAll('.main_practice_block4').length;
    if (this.slideindex_jobtickets > scount) this.slideindex_jobtickets = 1;
    let slideindex_jobtickets: any = this.slideindex_jobtickets++;
    let dots: any = document.getElementsByClassName('dot');

    for (let i = 0; i < slides.length; i++) {
      slides[i].style.display = 'none';
    }

    // slideIndex++;
    if (slideindex_jobtickets > slides.length) {
      slideindex_jobtickets = 1;
    }
    for (let i = 0; i < dots.length; i++) {
      if (dots[i] != null)
        dots[i].className = dots[i].className.replace(' active', '');
    }
    if (slideindex_jobtickets > 0 && slides[slideindex_jobtickets - 1] != null)
      slides[slideindex_jobtickets - 1].style.display = 'block';
    if (slideindex_jobtickets > 0 && dots[slideindex_jobtickets - 1] != null)
      dots[slideindex_jobtickets - 1].className += ' active';
    if (e === null || e === '') {
      this.slide_jobticketstimeout = setTimeout(() => {
        this.slide_jobtickets('');
      }, 10000);
    }
  }

  slideforjobticketsNext(idx: any) {
    // console.log('next', idx, this.slideindex_jobtickets)

    this.slideindex_jobtickets = 0;
    this.slideindex_jobtickets = idx + 1;
    // let i: any;
    let slides: any = document.getElementsByClassName('main_practice_block8');
    let scount: any = document.querySelectorAll('.main_practice_block8').length;

    // console.log('next 111', this.slideindex_jobtickets, scount)
    if (this.slideindex_jobtickets >= scount) {
      this.slideindex_jobtickets = 0;
    }
    let slideindex_jobtickets: any = this.slideindex_jobtickets++;

    let dots: any = document.getElementsByClassName('dot');

    for (let i = 0; i < slides.length; i++) {
      slides[i].style.display = 'none';
    }

    slideindex_jobtickets++;
    if (slideindex_jobtickets > slides.length) {
      slideindex_jobtickets = 1;
    }
    for (let i in dots.length) {
      // console.log(i, '__')
      if (dots[i] != null) {
        dots[i].className = dots[i].className.replace(' active', '');
      }
    }
    if (slideindex_jobtickets > 0 && slides[slideindex_jobtickets - 1] != null)
      slides[slideindex_jobtickets - 1].style.display = 'block';
    if (slideindex_jobtickets > 0 && dots[slideindex_jobtickets - 1] != null)
      dots[slideindex_jobtickets - 1].className += ' active';

    this.slide_jobtickets('e');
  }
  slideforcommissionNext(idx: any) {
    // console.log('next', idx, this.slideindex_jobtickets)

    this.slideindex_jobtickets = 0;
    this.slideindex_jobtickets = idx + 1;
    // let i: any;
    let slides: any = document.getElementsByClassName('main_practice_block5');
    let scount: any = document.querySelectorAll('.main_practice_block5').length;

    // console.log('next 111', this.slideindex_jobtickets, scount)
    if (this.slideindex_jobtickets >= scount) {
      this.slideindex_jobtickets = 0;
    }
    let slideindex_jobtickets: any = this.slideindex_jobtickets++;

    let dots: any = document.getElementsByClassName('dot');

    for (let i = 0; i < slides.length; i++) {
      slides[i].style.display = 'none';
    }

    slideindex_jobtickets++;
    if (slideindex_jobtickets > slides.length) {
      slideindex_jobtickets = 1;
    }
    for (let i in dots.length) {
      // console.log(i, '__')
      if (dots[i] != null) {
        dots[i].className = dots[i].className.replace(' active', '');
      }
    }
    if (slideindex_jobtickets > 0 && slides[slideindex_jobtickets - 1] != null)
      slides[slideindex_jobtickets - 1].style.display = 'block';
    if (slideindex_jobtickets > 0 && dots[slideindex_jobtickets - 1] != null)
      dots[slideindex_jobtickets - 1].className += ' active';

    this.slide_jobtickets('e');
  }
  slidefortrainingNext(idx: any) {
    // console.log('next', idx, this.slideindex_jobtickets)

    this.slideindex_jobtickets = 0;
    this.slideindex_jobtickets = idx + 1;
    // let i: any;
    let slides: any = document.getElementsByClassName('main_practice_block9');
    let scount: any = document.querySelectorAll('.main_practice_block9').length;

    // console.log('next 111', this.slideindex_jobtickets, scount)
    if (this.slideindex_jobtickets >= scount) {
      this.slideindex_jobtickets = 0;
    }
    let slideindex_jobtickets: any = this.slideindex_jobtickets++;

    let dots: any = document.getElementsByClassName('dot');

    for (let i = 0; i < slides.length; i++) {
      slides[i].style.display = 'none';
    }

    slideindex_jobtickets++;
    if (slideindex_jobtickets > slides.length) {
      slideindex_jobtickets = 1;
    }
    for (let i in dots.length) {
      // console.log(i, '__')
      if (dots[i] != null) {
        dots[i].className = dots[i].className.replace(' active', '');
      }
    }
    if (slideindex_jobtickets > 0 && slides[slideindex_jobtickets - 1] != null)
      slides[slideindex_jobtickets - 1].style.display = 'block';
    if (slideindex_jobtickets > 0 && dots[slideindex_jobtickets - 1] != null)
      dots[slideindex_jobtickets - 1].className += ' active';

    this.slide_jobtickets('e');
  }
  slideforjobticketsPrev(idx: any) {
    let slides: any = document.getElementsByClassName('main_practice_block8');
    let scount: any = document.querySelectorAll('.main_practice_block8').length;
    this.slideindex_signature = idx;
    if (this.slideindex_signature <= 0) {
      this.slideindex_signature = scount;
    }
    let slideindex_signature: any = this.slideindex_signature;
    for (var i = slides.length - 1; i >= 0; i--) {
      slides[i].style.display = 'none';
    }
    if (slideindex_signature > 0 && slides[slideindex_signature - 1] != null) {
      slides[slideindex_signature - 1].style.display = 'block';
    }
    slideindex_signature--;
  }
  slidefortraningPrev(idx: any) {
    let slides: any = document.getElementsByClassName('main_practice_block9');
    let scount: any = document.querySelectorAll('.main_practice_block9').length;
    this.slideindex_signature = idx;
    if (this.slideindex_signature <= 0) {
      this.slideindex_signature = scount;
    }
    let slideindex_signature: any = this.slideindex_signature;
    for (var i = slides.length - 1; i >= 0; i--) {
      slides[i].style.display = 'none';
    }
    if (slideindex_signature > 0 && slides[slideindex_signature - 1] != null) {
      slides[slideindex_signature - 1].style.display = 'block';
    }
    slideindex_signature--;
  }

  toggletraining(e: any) {
    if (e.checked === true) {
      //  console.warn('consultants', e.checked);
      this.activetrainingFlag = true;
      this.trainingListdatatableflag = true;
      let requestdata = {
        data: {
          id: '',

          'type': 'distributor'
        },
        endpoint: ''
      };
      requestdata.data.id = JSON.parse(this.cookieservice.get('userid'));
      requestdata.endpoint = 'traing_api/traninguserpercent';
      this.Apiservice
        .getDataFortraing(requestdata)
        .subscribe((res: any) => {
          console.log(res);
          this.trainingdetails = res.results.res;
          if (this.trainingdetails.length === 0) {
            this.trainingnorecordfound = true;
          }


          this.trainingListdatatableflag = false;
        });

    } else {
      //  console.warn('consultants', e.checked);
      this.activetrainingFlag = false;
      this.trainingnorecordfound = false;
      this.trainingdetails = [];
      this.isrefresh_training_report = false;
    }
  }
  toggleactiveconsultants(e: any) {
    //  console.warn('consultants', e.checked);

    if (e.checked === true) {
      //  console.warn('consultants', e.checked);
      this.activetoggleactiveconsultantsFlag = true;
      this.techconsultantsListView();
    } else {
      //  console.warn('consultants', e.checked);
      this.activetoggleactiveconsultantsFlag = false;
      this.technorecordfound = false;
      this.techconsultantsListdatatable = [];
      this.isrefresh_technological_consultant = false;
    }
  }

  techconsultantsListView() {
    this.techconsultantsListdatatableflag = true;
    const endpointc = 'api/userlistview-count';
    const endpoint = 'api/userlistview';
    const data: any = {
      // How many data showing in first page
      secret: this.cookieservice.get('secret'),
      token: this.cookieservice.get('jwtToken'),
      source: '',
      condition: {
        // condition for how much data show in one time
        limit: 5,
        skip: 0,
      },
      sort: {
        type: 'desc', // data sort by decreasing order
        field: 'logincounts', // data sort by first name
      },
      searchcondition: {
        type: 'technological-consultant',
        parentid: this.userservice.userid,
      },
    };

    this.Apiservice.getDataWithoutToken(data, endpointc).subscribe(
      (res: any) => {
        this.datesearchsourcecount = res.count; // sarch count  (How many data there)
        this.techconsultantsListdatatableflag = false;
      }
    );

    this.Apiservice.getDataWithoutToken(data, endpoint).subscribe(
      (res: any) => {
        this.techconsultantsListdatatable = res.results.res; // sarch count  (How many data there)

        // this.techconsultantsListdatatable = this.adminarray.mostactive_technological_consultant; // sarch count  (How many data there)
        this.techconsultantsListdatatableflag = false;
      }
    );
  }

  slide_technological(e: string) {
    // console.log('slide_technological')
    // let i: any;
    let slides: any = document.getElementsByClassName('main_practice_block3');
    let scount: any = document.querySelectorAll('.main_practice_block3').length;
    if (this.slideindex_technological > scount)
      this.slideindex_technological = 1;
    let slideIndex_technological: any = this.slideindex_technological++;
    let dots: any = document.getElementsByClassName('dot');

    for (let i = 0; i < slides.length; i++) {
      slides[i].style.display = 'none';
    }

    // slideIndex++;
    if (slideIndex_technological > slides.length) {
      slideIndex_technological = 1;
    }
    for (let i = 0; i < dots.length; i++) {
      if (dots[i] != null)
        dots[i].className = dots[i].className.replace(' active', '');
    }
    if (
      slideIndex_technological > 0 &&
      slides[slideIndex_technological - 1] != null
    )
      slides[slideIndex_technological - 1].style.display = 'block';
    if (
      slideIndex_technological > 0 &&
      dots[slideIndex_technological - 1] != null
    )
      dots[slideIndex_technological - 1].className += ' active';
    // if (e === null || e === '') {
    //   this.slide_technologicaltimeout = setTimeout(() => {
    //     this.slide_technological('');
    //   }, 10000);
    // }
  }

  slidefortechnologicalNext(idx: any) {
    // console.log('next', idx, this.slideindex_technological)

    this.slideindex_technological = 0;
    this.slideindex_technological = idx + 1;
    // let i: any;
    let slides: any = document.getElementsByClassName('main_practice_block3');
    let scount: any = document.querySelectorAll('.main_practice_block3').length;

    // console.log('next 111', this.slideindex_technological, scount)
    if (this.slideindex_technological >= scount) {
      this.slideindex_technological = 0;
    }
    let slideIndex_technological: any = this.slideindex_technological++;

    let dots: any = document.getElementsByClassName('dot');

    for (let i = 0; i < slides.length; i++) {
      slides[i].style.display = 'none';
    }

    slideIndex_technological++;
    if (slideIndex_technological > slides.length) {
      slideIndex_technological = 1;
    }
    for (let i in dots.length) {
      // console.log(i, '__')
      if (dots[i] != null) {
        dots[i].className = dots[i].className.replace(' active', '');
      }
    }
    if (
      slideIndex_technological > 0 &&
      slides[slideIndex_technological - 1] != null
    )
      slides[slideIndex_technological - 1].style.display = 'block';
    if (
      slideIndex_technological > 0 &&
      dots[slideIndex_technological - 1] != null
    )
      dots[slideIndex_technological - 1].className += ' active';

    // this.slide_technological('e');
  }

  slidefortechnologicalPrev(idx: any) {
    //  // console.log('prev', idx)

    // let i: any;
    let slides: any = document.getElementsByClassName('main_practice_block3');
    let scount: any = document.querySelectorAll('.main_practice_block3').length;

    this.slideindex_technological = idx;
    if (this.slideindex_technological <= 0) {
      this.slideindex_technological = scount;
    }
    let slideindex_technological: any = this.slideindex_technological;
    for (var i = slides.length - 1; i >= 0; i--) {
      slides[i].style.display = 'none';
    }
    if (
      slideindex_technological > 0 &&
      slides[slideindex_technological - 1] != null
    ) {
      slides[slideindex_technological - 1].style.display = 'block';
    }
    slideindex_technological--;
  }
  slideforcloserPrev(idx: any) {
    //  // console.log('prev', idx)

    // let i: any;
    let slides: any = document.getElementsByClassName('main_practice_block6');
    let scount: any = document.querySelectorAll('.main_practice_block6').length;

    this.slideindex_closer = idx;
    if (this.slideindex_closer <= 0) {
      this.slideindex_closer = scount;
    }
    let slideindex_closer: any = this.slideindex_closer;
    for (var i = slides.length - 1; i >= 0; i--) {
      slides[i].style.display = 'none';
    }
    if (slideindex_closer > 0 && slides[slideindex_closer - 1] != null) {
      slides[slideindex_closer - 1].style.display = 'block';
    }
    slideindex_closer--;
  }

  slideforcloserNext(idx: any) {
    // console.log('next', idx, this.slideindex_technological)

    this.slideindex_closer = 0;
    this.slideindex_closer = idx + 1;
    // let i: any;
    let slides: any = document.getElementsByClassName('main_practice_block6');
    let scount: any = document.querySelectorAll('.main_practice_block6').length;

    // console.log('next 111', this.slideindex_technological, scount)
    if (this.slideindex_closer >= scount) {
      this.slideindex_closer = 0;
    }
    let slideindex_closer: any = this.slideindex_closer++;

    let dots: any = document.getElementsByClassName('dot');

    for (let i = 0; i < slides.length; i++) {
      slides[i].style.display = 'none';
    }

    slideindex_closer++;
    if (slideindex_closer > slides.length) {
      slideindex_closer = 1;
    }
    for (let i in dots.length) {
      // console.log(i, '__')
      if (dots[i] != null) {
        dots[i].className = dots[i].className.replace(' active', '');
      }
    }
    if (slideindex_closer > 0 && slides[slideindex_closer - 1] != null)
      slides[slideindex_closer - 1].style.display = 'block';
    if (slideindex_closer > 0 && dots[slideindex_closer - 1] != null)
      dots[slideindex_closer - 1].className += ' active';

    // this.slide_technological('e');
  }

  folderview(val: any, flag) {
    // contract/view/:_id
    console.log(val);
    if (flag === 'tech_same_page') {
      this.router.navigateByUrl('/user-details/' + val.type + '/' + val._id);
    } else if (flag === 'tech_new_page') {
      window.open(
        '/user-details/' + val.type + '/' + val._id,
        'Independent Window'
      );
    } else if (flag === 'closer_same_page') {
      this.router.navigateByUrl('/distributor/users/' + val.type);
    } else if (flag === 'closer_new_page') {
      window.open('/distributor/users/' + val.type, 'Independent Window');
    } else if (flag === 'contract_new_page') {
      window.open('/distributor/users/' + val.type, 'Independent Window');
    } else if (flag === 'contract_same_page') {
      this.router.navigateByUrl('/distributor/users/' + val.type);
    } else if (flag === 'jobticket_new_page') {
      window.open(
        '/jobticket/view-managejobticket/' + val._id + '/' + val.user_id,
        'Independent Window'
      );
    } else if (flag === 'jobticket_same_page') {
      this.router.navigateByUrl(
        '/jobticket/view-managejobticket/' + val._id + '/' + val.user_id
      );
    } else if (flag === 'training_new_page') {
      window.open(
        '/user-details/technological-consultant/' + val._id + '?flag=training',
        'Independent Window'
      );
    } else if (flag === 'training_same_page') {
      this.router.navigateByUrl(
        '/user-details/technological-consultant/' + val._id + '?flag=training'
      );
    } else if (flag === 'commission_new_page') {
      window.open('/managecommisson/tech/list', 'Independent Window');
    } else if (flag === 'commission_same_page') {
      this.router.navigateByUrl('/managecommisson/tech/list');
    }
  }
  onboarding(val: any) {
    console.log(val, 'valllllllllllllllllllllllllllll');
    const dialogRef = this.dialog.open(Dialogpermission);
  }

  // jobticket
  ngOnInit() {

    this.dashtraingdata();
    let slideIndex = 0;
    showSlides();
    function showSlides() {
      let i;
      let slides = document.getElementsByClassName('mySlides') as HTMLCollectionOf<HTMLElement>;
      let dots = document.getElementsByClassName('dot');
      for (i = 0; i < slides.length; i++) {
        slides[i].style.display = 'none';
      }
      slideIndex++;
      if (slideIndex > slides.length) { slideIndex = 1 }
      for (i = 0; i < dots.length; i++) {
        dots[i].className = dots[i].className.replace(' active', '');

      }
      slides[slideIndex - 1].style.display = 'block';
      dots[slideIndex - 1].className += ' active';
      setTimeout(showSlides, 4500); // Change image every 2 seconds
    }

    this.activatedRoute.data.forEach((res: any) => {
      // console.log(res, 'userdetails');
      this.dristibutor = res.resolvedata.results.users_list[0];
      // console.log(res.resolvedata.results, 'dristibutor')
      // return;
      // this.salesmanagerListdatatable = res.resolvedata.results.mostactive_sales_persons;
      this.techtabledata = res.resolvedata.results.technological_consultant;
      this.contractmanager = res.resolvedata.results.contract_manager;
      // console.log(this.salesmanagerListdatatable, 'Success')
      this.jobcategoryListdatatable = res.resolvedata.results.recentjobtickets;
      // this.traingdata = res.resolvedata.results.trainingdetails;
      this.closerdata = res.resolvedata.results.closer_data;
      this.userlist = res.resolvedata.results.users_list;
      // this.commissiondata = res.resolvedata.results.managecommission_data;
      // console.log(this.jobcategoryListdatatable, 'this.jobticket')
      if (this.traingdata.length > 0) {
        this.mostactiveblockthreeflag = this.mostactiveblockthreeflag + 1;
      }
      // if (this.commissiondata.length === 0) {
      //   this.mostactiveblockthreeflag = this.mostactiveblockthreeflag + 1;
      // }
      if (this.salesmanagerListdatatable.length === 0) {
        this.mostactiveblocktwoflag = this.mostactiveblocktwoflag + 1;
      }
      if (this.jobcategoryListdatatable.length > 0) {
        this.mostactiveblockthreeflag = this.mostactiveblockthreeflag + 1;
      }

      if (this.techtabledata.length === 0) {
        this.mostactiveblockoneflag = this.mostactiveblockoneflag + 1;
      }
      if (this.contractmanager.length === 0) {
        this.mostactiveblockoneflag = this.mostactiveblockoneflag + 1;
      }
      if (this.closerdata.length === 0) {
        this.mostactiveblockoneflag = this.mostactiveblockoneflag + 1;
      }
      console.log(this.mostactiveblocktwoflag, '------');
    });
    this.getallsharelinks();
    // this.slidesales_persons('');
    // if (this.userlist.length>0){
    //   for (const i in this.userlist) {
    //     if (this.userlist[i].viewcommissionflag != null && typeof (this.userlist[i].viewcommissionflag) != 'undefined' && this.userlist[i].viewcommissionflag === true) {
    //       this.addcommission = true;
    //       this.updatetotechcommission = true;
    //     }
    //   }

    // }

    const dataforsharelink = {
      secret: this.userservice.secret,
      token: this.userservice.jwttoken,
      userid: JSON.parse(this.cookieservice.get('userid')),
      condition: {
        limit: 10,
        skip: 0,
      },
      sort: {
        type: 'desc',
        field: '_id',
      },
    };
    this.Apiservice.getDataWithoutToken(
      dataforsharelink,
      'api/shearlinkview'
    ).subscribe((res: any) => {
      console.log(res, 'sharelinkview');
      this.sharelinkview = res.result;
      // console.log(this.getallsharelinksdata, 'getallsharelinksdata +++++')
    });
    this.commission_func();
  }
  commission_func() {
    // this.commission_data_details = [];
    let data = {
      searchcondition: {
        product_id: { $in: this.dristibutor.disableproduct },
        user_type: 'distributor'
      }
    }
    this.Apiservice.getDataWithoutToken(data, 'api10/get-commission-rule').subscribe((data: any) => {
      this.commission_data_details = data.res;
      for (let key in this.commission_data_details) {
        let check_name = this.commission_data_details[key].product_name.replace(/[-' ']/g, '_') + this.commission_data_details[key].commission_type;
        console.log(check_name);
        if (check_name === 'PECEevent_commission' && this.dristibutor.PECEevent_commission != null && this.dristibutor.PECEevent_commission != '') {
          this.commission_data_details[key].commission_value = this.dristibutor.PECEevent_commission
        }
        if (check_name === 'PECEsales_commission' && this.dristibutor.PECEsales_commission != null && this.dristibutor.PECEsales_commission != '') {
          this.commission_data_details[key].commission_value = this.dristibutor.PECEsales_commission
        }
        if (check_name === 'TM_FLOW_ANS_MEDICAL_DEVICEsales_commission' && this.dristibutor.TM_FLOW_ANS_MEDICAL_DEVICEsales_commission != null && this.dristibutor.TM_FLOW_ANS_MEDICAL_DEVICEsales_commission != '') {
          this.commission_data_details[key].commission_value = this.dristibutor.TM_FLOW_ANS_MEDICAL_DEVICEsales_commission
        }
        if (check_name === 'RST_Sanexussales_commission' && this.dristibutor.RST_Sanexussales_commission != null && this.dristibutor.RST_Sanexussales_commission != '') {
          this.commission_data_details[key].commission_value = this.dristibutor.RST_Sanexussales_commission
        }
        if (check_name === 'Bio_Energeticsevent_commission' && this.dristibutor.Bio_Energeticsevent_commission != null && this.dristibutor.Bio_Energeticsevent_commission != '') {
          this.commission_data_details[key].commission_value = this.dristibutor.Bio_Energeticsevent_commission
        }
        if (check_name === 'Bio_Energeticssales_commission' && this.dristibutor.Bio_Energeticssales_commission != null && this.dristibutor.Bio_Energeticssales_commission != '') {
          this.commission_data_details[key].commission_value = this.dristibutor.Bio_Energeticssales_commission
        }
      }
      this.showing_commission_data = [];
      let index = 0;
      this.showing_commission_data[index] = this.commission_data_details.find(e => e.product_id == '612c89242005f400082d5fb1' && e.commission_type == 'sales_commission');
      if (this.showing_commission_data[index]) {
        index++;
      }
      this.showing_commission_data[index] = this.commission_data_details.find(e => e.product_id == '612c883f118c110009f02820' && e.commission_type == 'event_commission');
      if (this.showing_commission_data[index]) {
        index++;
      }
      this.showing_commission_data[index] = this.commission_data_details.find(e => e.product_id == '604aff3691b7c80008ca11a8' && e.commission_type == 'event_commission');
      if (this.showing_commission_data[index]) {
        index++;
      }
      this.showing_commission_data[index] = this.commission_data_details.find(e => e.product_id == '604aff3691b7c80008ca11a8' && e.commission_type == 'sales_commission');
      if (this.showing_commission_data[index]) {
        index++;
      }
      this.showing_commission_data[index] = this.commission_data_details.find(e => e.product_id == '604a0b6e40962e00088f0d79' && e.commission_type == 'sales_commission');
      if (this.showing_commission_data[index]) {
        index++;
      } else {
        this.showing_commission_data.splice(index, 1);
        // index--;
      }
      // this.showing_commission_data = JSON.parse(JSON.stringify(this.showing_commission_data));
      console.log(this.showing_commission_data);
      for (const key in this.showing_commission_data) {
        if (this.showing_commission_data[key]) {
          if (this.showing_commission_data[key].product_id == '612c89242005f400082d5fb1' && this.showing_commission_data[key].commission_type == 'sales_commission') {
            this.showing_commission_data[key]['productname_details'] = this.showing_commission_data[key].product_name + ' Sales Commission';
            this.showing_commission_data[key]['product_img'] = '../../../../assets/images/traing_db_logo2_RST2.png';
            this.showing_commission_data[key].commission_value = this.showing_commission_data[key].commission_value + '%';
            this.showing_commission_data[key]['class_name'] = 'rst_text';
          }
          if (this.showing_commission_data[key].product_id == '612c883f118c110009f02820' && this.showing_commission_data[key].commission_type == 'event_commission') {
            this.showing_commission_data[key]['productname_details'] = this.showing_commission_data[key].product_name + ' Event Commission (Per treatment charge)';
            this.showing_commission_data[key]['product_img'] = '../../../../assets/images/traing_db_logo3.png';
            this.showing_commission_data[key].commission_value = '$' + this.showing_commission_data[key].commission_value;
            this.showing_commission_data[key]['class_name'] = 'bio_text';
          }
          if (this.showing_commission_data[key].product_id == '604aff3691b7c80008ca11a8' && this.showing_commission_data[key].commission_type == 'event_commission') {
            this.showing_commission_data[key]['productname_details'] = this.showing_commission_data[key].product_name + ' Standard Qualified Practice Program (Cost per test)';
            this.showing_commission_data[key]['product_img'] = '../../../../assets/images/traing_db_logo4.png';
            this.showing_commission_data[key].commission_value = '$' + this.showing_commission_data[key].commission_value;
            this.showing_commission_data[key]['class_name'] = 'PECE_text';
          }
          if (this.showing_commission_data[key].product_id == '604aff3691b7c80008ca11a8' && this.showing_commission_data[key].commission_type == 'sales_commission') {
            this.showing_commission_data[key]['productname_details'] = this.showing_commission_data[key].product_name + ' Practice Testing Center Program (15% of Gross/Net)';
            this.showing_commission_data[key]['product_img'] = '../../../../assets/images/traing_db_logo4.png';
            this.showing_commission_data[key].commission_value = this.showing_commission_data[key].commission_value + '%';
            this.showing_commission_data[key]['class_name'] = 'PECE_text';
          }
          if (this.showing_commission_data[key].product_id == '604a0b6e40962e00088f0d79' && this.showing_commission_data[key].commission_type == 'sales_commission') {
            this.showing_commission_data[key]['productname_details'] = this.showing_commission_data[key].product_name + ' Sales Commission';
            this.showing_commission_data[key]['product_img'] = '../../../../assets/images/traing_db_logo1.png';
            this.showing_commission_data[key].commission_value = this.showing_commission_data[key].commission_value + ' %';
            this.showing_commission_data[key]['class_name'] = 'tm_text';
          }
        } else {
          this.showing_commission_data.splice(key, 1);
        }
        console.log(this.showing_commission_data[key]);
      }
    })
  }
  dashtraingdata() {
    let requestdata = {
      data: {
        id: '',

        'type': 'distributor'
      },
      endpoint: ''
    };
    requestdata.data.id = JSON.parse(this.cookieservice.get('userid'));
    requestdata.endpoint = 'traing_api/traninguserpercent';
    this.Apiservice
      .getDataFortraing(requestdata)
      .subscribe((res: any) => {
        console.log(res);
        this.traingdata = res.results.res;
        if (this.traingdata.length > 0) {
          this.mostactiveblockthreeflag = this.mostactiveblockthreeflag + 1;
        }
      });
  }
  refreshlistview(val: any) {
    if (val === 'technological-consultant') {
      this.isrefresh_technological_consultant = false;
      this.techconsultantsListdatatableflag = true;
      this.techconsultantsListdatatable = [];
      this.technorecordfound = false;
      const endpointc = 'api/userlistview-count';
      const endpoint = 'api/userlistview';
      const data: any = {
        // How many data showing in first page
        secret: this.cookieservice.get('secret'),
        token: this.cookieservice.get('jwtToken'),
        source: '',
        condition: {
          // condition for how much data show in one time
          limit: 5,
          skip: 0,
        },
        sort: {
          type: 'desc', // data sort by decreasing order
          field: 'logincounts', // data sort by first name
        },
        searchcondition: {
          type: 'technological-consultant',
          parentid: this.userservice.userid,
        },
      };

      this.Apiservice.getDataWithoutToken(data, endpointc).subscribe(
        (res: any) => {
          this.datesearchsourcecount = res.count; // sarch count  (How many data there)
          this.techconsultantsListdatatableflag = false;
        }
      );

      this.Apiservice.getDataWithoutToken(data, endpoint).subscribe(
        (res: any) => {
          this.techconsultantsListdatatable = res.results.res; // sarch count  (How many data there)
          if (this.techconsultantsListdatatable.length === 0) {
            this.technorecordfound = true;
          }
          // this.techconsultantsListdatatable = this.adminarray.mostactive_technological_consultant; // sarch count  (How many data there)
          this.techconsultantsListdatatableflag = false;
        }
      );
    }
    if (val === 'contract-manager') {
      this.isrefresh_contract_manager = false;
      this.contractmanagerListdatatableflag = true;
      this.contractmanagerdata = [];
      this.contractnorecordfound = false;
      const endpointc = 'api/userlistview-count';
      const endpoint = 'api/userlistview';
      const data: any = {
        // How many data showing in first page
        secret: this.cookieservice.get('secret'),
        token: this.cookieservice.get('jwtToken'),
        source: '',
        condition: {
          // condition for how much data show in one time
          limit: 5,
          skip: 0,
        },
        sort: {
          type: 'desc', // data sort by decreasing order
          field: 'logincounts', // data sort by first name
        },
        searchcondition: {
          type: 'contract-manager',
          parentid: this.userservice.userid,
        },
      };

      this.Apiservice.getDataWithoutToken(data, endpointc).subscribe(
        (res: any) => {
          this.contractmanagertablecount = res.count; // sarch count  (How many data there)
          this.contractmanagerListdatatableflag = false;
        }
      );

      this.Apiservice.getDataWithoutToken(data, endpoint).subscribe(
        (res: any) => {
          this.contractmanagerdata = res.results.res; // sarch count  (How many data there)
          if (this.contractmanagerdata.length === 0) {
            this.contractnorecordfound = true;
          }
          // this.techconsultantsListdatatable = this.adminarray.mostactive_technological_consultant; // sarch count  (How many data there)
          this.contractmanagerListdatatableflag = false;
        }
      );
    }
    if (val === 'closer') {
      this.isrefresh_closer = false;
      this.closerListdatatableflag = true;
      this.closerListdatatable = [];
      this.closernorecordfound = false;
      const endpointc = 'api/userlistview-count';
      const endpoint = 'api/userlistview';
      const data: any = {
        // How many data showing in first page
        secret: this.cookieservice.get('secret'),
        token: this.cookieservice.get('jwtToken'),
        source: '',
        condition: {
          // condition for how much data show in one time
          limit: 5,
          skip: 0,
        },
        sort: {
          type: 'desc', // data sort by decreasing order
          field: 'logincounts', // data sort by first name
        },
        searchcondition: { type: 'closer', parentid: this.userservice.userid },
      };

      this.Apiservice.getDataWithoutToken(data, endpointc).subscribe(
        (res: any) => {
          this.closer_datesearchsourcecount = res.count; // sarch count  (How many data there)
          this.closerListdatatableflag = false;
        }
      );

      this.Apiservice.getDataWithoutToken(data, endpoint).subscribe(
        (res: any) => {
          this.closerListdatatable = res.results.res; // sarch count  (How many data there)
          if (this.closerListdatatable.length === 0) {
            this.closernorecordfound = true;
          }
          // this.techconsultantsListdatatable = this.adminarray.mostactive_technological_consultant; // sarch count  (How many data there)
          this.closerListdatatableflag = false;
        }
      );
    }
    if (val === 'job_ticket') {
      this.isrefresh_job_ticket = false;
      this.jobticketsListdatatableflag = true;
      this.jobcategoryListdatatablelisting = [];
      this.jobticketnorecordfound = false;
      const endpoint = 'api/getjobticketlistdata';
      const data: any = {
        // How many data showing in first page
        secret: this.userservice.secret,
        token: this.userservice.jwttoken,
        source: '',
        condition: {
          // condition for how much data show in one time
          limit: 5,
          skip: 0,
        },
        sort: {
          type: 'desc', // data sort by decreasing order
          field: 'createdon_datetime', // data sort by first name
        },
        searchcondition: { user_id: this.userservice.userid },
      };

      this.Apiservice.getDataWithoutToken(data, endpoint).subscribe(
        (res: any) => {
          // console.log(res, 'jobticket');
          this.jobcategoryListdatatablelisting = res.results.res; // sarch count  (How many data there)
          if (this.jobcategoryListdatatablelisting.length === 0) {
            this.jobticketnorecordfound = true;
          }
          this.jobticketsListdatatableflag = false;
        }
      );
    }
    if (val === 'training_report') {
      this.isrefresh_training_report = false;
      this.activetrainingFlag = true;
      this.trainingListdatatableflag = true;
      this.trainingdetails = [];
      const endpoint = 'api/techdetails';
      const endpointc = 'api/techdetails-count';
      const data: any = {
        condition: {
          // condition for how much data show in one time
          limit: 5,
          skip: 0,
        },
        sort: {
          type: 'desc', // data sort by decreasing order
          field: 'traning_name', // data sort by first name
        },
        // How many data showing in first page
        secret: this.cookieservice.get('secret'),
        token: this.cookieservice.get('jwtToken'),
        parentid: this.userservice.userid,
      };
      // this.Apiservice.getDataWithoutToken(data, endpointc).subscribe(
      //   (res: any) => {
      //     this.techdatesearchsourcecount = res.count; // sarch count  (How many data there)
      //   }
      // );
      this.Apiservice.getDataWithoutToken(data, endpoint).subscribe(
        (res: any) => {
          this.trainingdetails = res.results.res;
          if (this.trainingdetails.length === 0) {
            this.trainingnorecordfound = true;
          }
          console.log(
            this.trainingdetails,
            'trainingdetailstrainingdetailstrainingdetailstrainingdetails'
          );

          this.trainingListdatatableflag = false;
        }
      );
    }
    if (val === 'tech_commission') {
      this.isrefresh_tech_commission = false;
      this.commissionprogressflag = true;
      this.commissiondatalistview = [];
      this.techcommissionnorecordfound = false;
      const endpoint = 'api10/get-distributor-commission-management-lists';
      let data: any = {
        condition: {
          limit: 5,
          skip: 0,
        },
        sort: {
          type: 'desc',
          field: '_id',
        },

        searchcondition: { parentid: this.userservice.userid },
        secret: this.userservice.secret,
        token: this.userservice.jwttoken,
      };
      this.Apiservice.getDataWithoutToken(data, endpoint).subscribe(
        (data: any) => {
          console.log(data);
          this.commissiondatalistview = data.results.res;
          if (this.commissiondatalistview.length === 0) {
            this.techcommissionnorecordfound = true;
          }
          this.commissionprogressflag = false;
        }
      );
    }
  }
  togglecontractmanager(e: any) {
    console.log(e);
    if (e.checked === true) {
      //  console.warn('consultants', e.checked);
      this.contractmanagerFlag = true;
      this.contractmanagerListView();
    } else {
      //  console.warn('consultants', e.checked);
      this.contractmanagerFlag = false;
      this.contractnorecordfound = false;
      this.contractmanagerdata = [];
      this.isrefresh_contract_manager = false;
    }
  }
  toggleactivecloser(e: any) {
    console.log(e);
    if (e.checked === true) {
      //  console.warn('consultants', e.checked);
      this.activetoggleactivecloserFlag = true;
      this.closerListView();
    } else {
      //  console.warn('consultants', e.checked);
      this.activetoggleactivecloserFlag = false;
      this.closernorecordfound = false;
      this.closerListdatatable = [];
      this.isrefresh_closer = false;
    }
  }
  closerListView() {
    this.closerListdatatableflag = true;
    const endpointc = 'api/userlistview-count';
    const endpoint = 'api/userlistview';
    const data: any = {
      // How many data showing in first page
      secret: this.cookieservice.get('secret'),
      token: this.cookieservice.get('jwtToken'),
      source: '',
      condition: {
        // condition for how much data show in one time
        limit: 5,
        skip: 0,
      },
      sort: {
        type: 'desc', // data sort by decreasing order
        field: 'logincounts', // data sort by first name
      },
      searchcondition: { type: 'closer', parentid: this.userservice.userid },
    };

    this.Apiservice.getDataWithoutToken(data, endpointc).subscribe(
      (res: any) => {
        this.closer_datesearchsourcecount = res.count; // sarch count  (How many data there)
        this.closerListdatatableflag = false;
      }
    );

    this.Apiservice.getDataWithoutToken(data, endpoint).subscribe(
      (res: any) => {
        this.closerListdatatable = res.results.res; // sarch count  (How many data there)

        // this.techconsultantsListdatatable = this.adminarray.mostactive_technological_consultant; // sarch count  (How many data there)
        this.closerListdatatableflag = false;
      }
    );
  }

  folderviewlistingChange(val: any) {
    console.log(val);
    if (val.custombuttonclick.btninfo.label == "Open Folder View Same Page") {
      this.router.navigateByUrl(
        '/user-details/technological-consultant/' + val.custombuttonclick.data._id + '?flag=training'
      );
    }
    if (val.custombuttonclick.btninfo.label == "Open Folder View New Page") {
      window.open(
        '/user-details/technological-consultant/' + val.custombuttonclick.data._id + '?flag=training',
        'Independent Window'
      );
    }

  }
  contractmanagerListView() {
    this.contractmanagerListdatatableflag = true;
    const endpointc = 'api/userlistview-count';
    const endpoint = 'api/userlistview';
    const data: any = {
      // How many data showing in first page
      secret: this.cookieservice.get('secret'),
      token: this.cookieservice.get('jwtToken'),
      source: '',
      condition: {
        // condition for how much data show in one time
        limit: 5,
        skip: 0,
      },
      sort: {
        type: 'desc', // data sort by decreasing order
        field: 'logincounts', // data sort by first name
      },
      searchcondition: {
        type: 'contract-manager',
        parentid: this.userservice.userid,
      },
    };

    this.Apiservice.getDataWithoutToken(data, endpointc).subscribe(
      (res: any) => {
        this.contractmanagertablecount = res.count; // sarch count  (How many data there)
        this.contractmanagerListdatatableflag = false;
      }
    );

    this.Apiservice.getDataWithoutToken(data, endpoint).subscribe(
      (res: any) => {
        this.contractmanagerdata = res.results.res; // sarch count  (How many data there)

        // this.techconsultantsListdatatable = this.adminarray.mostactive_technological_consultant; // sarch count  (How many data there)
        this.contractmanagerListdatatableflag = false;
      }
    );
  }

  togglecommission(e: any) {
    console.log(e);
    if (e.checked === true) {
      //  console.warn('consultants', e.checked);
      this.activecommissionFlag = true;
      this.commissionprogressflag = true;
      this.commissionview();
    } else {
      //  console.warn('consultants', e.checked);
      this.activecommissionFlag = false;
      this.commissiondatalistview = [];
      this.isrefresh_tech_commission = false;
      this.techcommissionnorecordfound = false;
    }
  }
  commissionview() {
    this.commissiondatalistview = [];
    let data: any = {
      condition: {
        limit: 5,
        skip: 0,
      },
      sort: {
        type: 'desc',
        field: '_id',
      },

      searchcondition: { parentid: this.userservice.userid },
      secret: this.userservice.secret,
      token: this.userservice.jwttoken,
    };
    const endpointc = 'api10/get-distributor-commission-management-lists-count';
    const endpoint = 'api10/get-distributor-commission-management-lists';

    this.Apiservice.getDataWithoutToken(data, endpointc).subscribe(
      (res: any) => {
        this.datesearchsourcecount_commission = res.count; // sarch count  (How many data there)
        // this.contractmanagerListdatatableflag = false;
      }
    );
    this.Apiservice.getDataWithoutToken(data, endpoint).subscribe(
      (res: any) => {
        this.commissiondatalistview = res.results.res; // sarch count  (How many data there)
        this.commissionprogressflag = false;
        // this.techconsultantsListdatatable = this.adminarray.mostactive_technological_consultant; // sarch count  (How many data there)
        // this.contractmanagerListdatatableflag = false;
      }
    );
  }

  slideforcontractmanagerNext(idx: any) {
    // console.log('next', idx, this.slideindex_signature)

    this.slideindex_signature = 0;
    this.slideindex_signature = idx + 1;
    // let i: any;
    let slides: any = document.getElementsByClassName('main_practice_block4');
    let scount: any = document.querySelectorAll('.main_practice_block4').length;

    // console.log('next 111', this.slideindex_signature, scount)
    if (this.slideindex_signature >= scount) {
      this.slideindex_signature = 0;
    }
    let slideindex_signature: any = this.slideindex_signature++;

    let dots: any = document.getElementsByClassName('dot');

    for (let i = 0; i < slides.length; i++) {
      slides[i].style.display = 'none';
    }

    slideindex_signature++;
    if (slideindex_signature > slides.length) {
      slideindex_signature = 1;
    }
    for (let i in dots.length) {
      // console.log(i, '__')
      if (dots[i] != null) {
        dots[i].className = dots[i].className.replace(' active', '');
      }
    }
    if (slideindex_signature > 0 && slides[slideindex_signature - 1] != null)
      slides[slideindex_signature - 1].style.display = 'block';
    if (slideindex_signature > 0 && dots[slideindex_signature - 1] != null)
      dots[slideindex_signature - 1].className += ' active';

    // this.slide_signature('e');
  }

  slideforcontractmanagerPrev(idx: any) {
    let slides: any = document.getElementsByClassName('main_practice_block4');
    let scount: any = document.querySelectorAll('.main_practice_block4').length;
    this.slideindex_signature = idx;
    if (this.slideindex_signature <= 0) {
      this.slideindex_signature = scount;
    }
    let slideindex_signature: any = this.slideindex_signature;
    for (var i = slides.length - 1; i >= 0; i--) {
      slides[i].style.display = 'none';
    }
    if (slideindex_signature > 0 && slides[slideindex_signature - 1] != null) {
      slides[slideindex_signature - 1].style.display = 'block';
    }
    slideindex_signature--;
  }
  getallsharelinks() {
    const dataforloginmonth = {
      secret: this.userservice.secret,
      token: this.userservice.jwttoken,
    };
    this.Apiservice.getDataWithoutToken(
      dataforloginmonth,
      'api/getallsharelinks'
    ).subscribe((res: any) => {
      this.getallsharelinksdata = res.results.res;
      // console.log(this.getallsharelinksdata, 'getallsharelinksdata +++++')
    });
  }
  copyText(val: string) {
    val = val;
    const selBox = document.createElement('textarea');
    selBox.style.position = 'fixed';
    selBox.style.left = '0';
    selBox.style.top = '0';
    selBox.style.opacity = '0';
    selBox.value = val;
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand('copy');
    document.body.removeChild(selBox);
  }
  copyTextforlink(forntendUrl: string, forntendUrl2: string) {
    forntendUrl = forntendUrl + this.userid + forntendUrl2;
    const selBox = document.createElement('textarea');
    selBox.style.position = 'fixed';
    selBox.style.left = '0';
    selBox.style.top = '0';
    selBox.style.opacity = '0';
    selBox.value = forntendUrl;
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand('copy');
    document.body.removeChild(selBox);
  }
  // onClick() {

  // }
  onClickcalender(val: any, flag) {
    //

    let calenderdata: any = {};
    if (flag === 'onbordingcal') {
      calenderdata = {
        id: this.userservice.userid,
        secret: this.cookieservice.get('secret'),
        token: this.cookieservice.get('jwtToken'),
        type: 'onbordingcall',
        flag: val,
      };
      this.cookieservice.set('onbordingcall', val, undefined, '/');
    }
    if (flag === 'discoverycall') {
      calenderdata = {
        id: this.userservice.userid,
        secret: this.cookieservice.get('secret'),
        token: this.cookieservice.get('jwtToken'),
        type: 'discoverycall',
        flag: val,
      };
      this.cookieservice.set('gameplancall', val, undefined, '/');
    }

    this.Apiservice.getDataWithoutToken(
      calenderdata,
      'api/updated-status-change'
    ).subscribe((res: any) => {
      console.log(res, 'res +++++');
    });
  }

  checkbox(val, flag) {
    let data = {
      secret: this.cookieservice.get('secret'),
      token: this.cookieservice.get('jwtToken'),
      _id: this.userservice.userid,
      viewcommissionflag: val,
    };
    this.Apiservice.getDataWithoutToken(
      data,
      'api/viwetechcommission'
    ).subscribe((res: any) => {
      console.log(res, 'resssssss');

      if (res.status === 'Success') {
        this.cookieservice.set('viewcommissionflag', val, undefined, '/');
      }
    });
  }

  openDialog() {
    const dialogRef = this.dialog.open(clipboarddata, {
      // width: '250px',
      data: '',
      panelClass: 'clipboard',
    });

    dialogRef.afterClosed().subscribe((result) => {
      //  // console.log('The dialog was closed');
      // return
    });
  }
  jobticketlistenLiblistingChange(val: any) {
    // console.log(val,'KKKKKKKKKKKKK');
    if (
      val.custombuttonclick != null &&
      typeof val.custombuttonclick != 'undefined'
    ) {
      if (val.custombuttonclick.btninfo.id === 'jobticketinnewpage') {
        window.open(
          '/jobticket/view-managejobticket/' +
          val.custombuttonclick.data._id +
          '/' +
          val.custombuttonclick.data.user_id,
          'Independent Window'
        );
      }
    }
  }
  ngOnDestroy() {
    clearTimeout(this.slidesales_personstimeout);
  }
}
@Component({
  selector: 'clipboarddata',
  templateUrl: 'clipboarddata.html',
})
export class clipboarddata {
  public modalData: any;
  constructor(public dialogRef: MatDialogRef<clipboarddata>) {
    setTimeout(() => {
      this.dialogRef.close();
    }, 3000);
    // window.scroll(0,0);
  }

  onNoClick(): void {
    this.dialogRef.close();
  }
}

@Component({
  selector: 'permission',
  templateUrl: 'permission.html',
})
export class Dialogpermission {
  constructor(
    public dialogRef: MatDialogRef<Dialogpermission>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData
  ) { }
  cancelyes() { }
}

import { DomSanitizer } from '@angular/platform-browser';
import { environment } from 'src/environments/environment';
@Component({
  selector: 'videoModal',
  templateUrl: 'videoModaldist.html',
})
export class videoModaldistComponent implements OnInit {
  public resource_data: any;
  public resource_data_name: any;
  public youtubeSource: any;
  constructor(
    public dialogRef: MatDialogRef<videoModaldistComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData2,
    public router: Router,
    public sanitizer: DomSanitizer
  ) {
    console.log(data);

    this.youtubeSource = this.sanitizer.bypassSecurityTrustResourceUrl(
      'https://www.youtube.com/embed/' + data.url + '?autoplay=1'
    );
    // this.resource_data = data.data
  }
  ngOnInit() { }

  onNoClick(): void {
    this.dialogRef.close();
  }
}
@Component({
  selector: 'searchmodal',
  templateUrl: 'searchmodal.html',
})
export class Searchmodal {
  public modalData: any;
  public formdata: any = {};
  public name_array: any = [];
  public type: any = '';
  public searchForm: FormGroup;
  public options: any = [];
  public optionemail: any = [];
  public jobticketdata: any = [];
  public jobticketcatagory: any = [];
  public modalloader: boolean = false;
  public optionticketid: any = [];
  public optionreportedby: any = [];
  public trainingname: any = [];
  public optionstrainingname: any = [];
  public optiontrainingemail: any = [];
  public optionstechname: any = [];
  public optiontechemail: any = [];
  public name_s: any;
  constructor(
    public dialogRef: MatDialogRef<Searchmodal>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    public Apiservice: ApiService,
    public router: Router,
    public http: HttpClient,
    public cookieservice: CookieService,
    public activatedRoute: ActivatedRoute,
    public dialog: MatDialog,
    public userservice: UserserviceService,
    public formBuilder: FormBuilder
  ) {
    console.log('search modal fire for', data);
    this.type = data;
    console.log(this.type, 'TUYPHYRHBGHHEDNFG');

    if (this.type === 'job_ticket') {
      this.jobticketsListView();
      this.jobticketscatagory();
    }
    if (this.type === 'training_report') {
      this.trainingname_func();
    }
    this.searchForm = this.formBuilder.group({
      fullname_s: [''],
      email: [''],
      lastlogin_datetime: [{}],
      lastlogin_datetimestart: [''],
      lastlogin_datetimeend: [''],
      ticketid: [''],
      ticket_status: [''],
      createdon_datetime: [{}],
      createdon_datetimestart: [''],
      createdon_datetimeend: [''],
      ticket_category_name: [''],
      type: this.type,
      user_name: [''],
      associated_training_s: [''],
      fullname: [''],
      user_id_s: [''],
      tech_fullname: [''],
      tech_email: [''],
    });
    this.formdata = {
      successmessage: 'Search Successfully !!',
      // redirectpath: 'admin/list',
      submitactive: true, // optional, default true
      submittext: 'Submit',
      // resettext: 'Reset',
      // canceltext: 'Cancel',
      // apiUrl: this.apiservice.api_url,
      // endpoint: 'api/userupdate', //
      // jwttoken: '',
      // cancelroute: 'admin/list',

      fields: [
        {
          label: 'Name',
          name: 'fullname_s',
          type: 'text',
          val: this.name_array,
          value: '',
          validations: [{ rule: 'required' }],
        },
      ],
    };
  }
  listenFormFieldChange(val: any) {
    console.log(val);
    if (val.field.name === 'fullname_s') {
      this.fetch(val.fieldval);
    }
  }
  getTitlename(optionid: string) {
    if (optionid != '' && optionid != null) {
      return this.optionstrainingname.find((option) => option.val === optionid)
        .name;
    }
  }
  getTitleemail(optionid: string) {
    if (optionid != '' && optionid != null) {
      return this.optiontrainingemail.find((option) => option.val === optionid)
        .name;
    }
  }
  fetch(val: any) {
    let apibody = {
      search_str: val,
      type: this.type,
      _id: this.userservice.userid,
      sort: { field: 'createdon_datetime', type: 'desc' },
      secret: this.userservice.secret,
      token: this.userservice.jwttoken,
    };

    this.Apiservice.getDataWithoutToken(
      apibody,
      'api/exitsinglistname?id=' + this.userservice.userid
    ).subscribe((data: any) => {
      this.name_array = data.res;
    });
  }
  search() {
    console.log(this.searchForm, this.name_s);
    // this.data.val=this.searchForm.value;
    if (this.searchForm.value['lastlogin_datetimestart'] != '') {
      this.searchForm.value['lastlogin_datetime']['$gte'] = new Date(
        this.searchForm.value['lastlogin_datetimestart']
      ).getTime();
      this.searchForm.value['lastlogin_datetimestart'] = '';
    }
    if (this.searchForm.value['lastlogin_datetimeend'] != '') {
      this.searchForm.value['lastlogin_datetime']['$lte'] =
        new Date(this.searchForm.value['lastlogin_datetimeend']).getTime() +
        86399999;
      this.searchForm.value['lastlogin_datetimeend'] = '';
    }
    if (this.searchForm.value['createdon_datetimestart'] != '') {
      this.searchForm.value['createdon_datetime']['$gte'] = new Date(
        this.searchForm.value['createdon_datetimestart']
      ).getTime();
      this.searchForm.value['createdon_datetimestart'] = '';
    }
    if (this.searchForm.value['createdon_datetimeend'] != '') {
      this.searchForm.value['createdon_datetime']['$lte'] =
        new Date(this.searchForm.value['createdon_datetimeend']).getTime() +
        86399999;
      this.searchForm.value['createdon_datetimeend'] = '';
    }
    // if(this.searchForm.value['status'] != ''){
    //   if(this.searchForm.value['status'] === '1'){
    //     this.searchForm.value['status'] = 1;
    //   }
    //   if(this.searchForm.value['status'] === '0'){
    //     this.searchForm.value['status'] = 0;
    //   }
    // }
    if (this.type == 'training_report') {
      this.name_s = this.name_s
      this.dialogRef.close(this.name_s);

    }
    else {
      this.dialogRef.close(this.searchForm.value);

    }
  }

  filterfun(event: any) {
    console.log(event, '+++');
  }
  private _filter(value: string) {
    console.log(value);
  }
  onChangeEventName(val: any) {
    this.modalloader = true;
    console.log(val, '+++++');
    if (val) {
      let apibody = {
        search_str: val,
        type: this.type,
        id: this.userservice.userid,
        sort: { field: 'createdon_datetime', type: 'desc' },
        secret: this.userservice.secret,
        token: this.userservice.jwttoken,
      };

      this.Apiservice.getDataWithoutToken(
        apibody,
        'api/exitsinglistname?idandtype=' +
        this.userservice.userid +
        ',' +
        this.type
      ).subscribe((data: any) => {
        this.options = data.res;
        console.log(this.options);

        // this.options = [...new Set(data.res.map(x => x.name))];
        this.options = Array.from(new Set(this.options.map((x) => x.name))).map(
          (name) => {
            return {
              name: name,
              _id: this.options.find((s) => s.name === name)._id,
              val: this.options.find((s) => s.name === name).val,
              type: this.options.find((s) => s.name === name).type,
              parentid: this.options.find((s) => s.name === name).parentid,
            };
          }
        );
        console.log(this.options);
        this.modalloader = false;
      });
    } else {
      this.modalloader = false;
    }
  }
  onChangeEventEmail(val: any) {
    this.modalloader = true;
    console.log(val);
    if (val) {
      let apibody = {
        search_str: val,
        type: this.type,
        id: this.userservice.userid,
        sort: { field: 'createdon_datetime', type: 'desc' },
        secret: this.userservice.secret,
        token: this.userservice.jwttoken,
      };

      this.Apiservice.getDataWithoutToken(
        apibody,
        'api/exitsinglistemail?idandtype=' +
        this.userservice.userid +
        ',' +
        this.type
      ).subscribe((data: any) => {
        this.optionemail = data.res;
        console.log(this.optionemail);

        this.optionemail = Array.from(
          new Set(this.optionemail.map((x) => x.name))
        ).map((name) => {
          return {
            name: name,
            _id: this.optionemail.find((s) => s.name === name)._id,
            val: this.optionemail.find((s) => s.name === name).val,
            type: this.optionemail.find((s) => s.name === name).type,
            parentid: this.optionemail.find((s) => s.name === name).parentid,
          };
        });
        console.log(this.optionemail);
        this.modalloader = false;
      });
    } else {
      this.modalloader = false;
    }
  }
  reset() {
    this.searchForm.reset();
    this.searchForm = this.formBuilder.group({
      fullname_s: [''],
      email: [''],
      lastlogin_datetime: [{}],
      lastlogin_datetimestart: [''],
      lastlogin_datetimeend: [''],
      ticketid: [''],
      ticket_status: [''],
      createdon_datetime: [{}],
      createdon_datetimestart: [''],
      createdon_datetimeend: [''],
      ticket_category_name: [''],
      type: this.type,
      user_name: [''],
      associated_training_s: [''],
      fullname: [''],
      user_id_s: [''],
      tech_fullname: [''],
      tech_email: [''],
    });
  }

  onNoClick(): void {
    this.dialogRef.close();
  }
  jobticketsListView() {
    const endpoint = 'api/autocomplete_ticket_status';
    const data: any = {
      secret: this.userservice.secret,
      token: this.userservice.jwttoken,
      id: this.userservice.userid,
      // searchcondition: { user_id: this.userservice.userid },
    };

    this.Apiservice.getDataWithoutToken(data, endpoint).subscribe(
      (res: any) => {
        // console.log(res, 'jobticket');
        this.jobticketdata = res.res; // sarch count  (How many data there)
        console.log(this.jobticketdata, '::::::');
        this.jobticketdata = [
          ...new Set(this.jobticketdata.map((item) => item.name)),
        ];
        // console.log(this.jobticketdata,'::::::');
      }
    );
  }
  jobticketscatagory() {
    const endpoint = 'api/autocomplete_ticket_categories';
    const data: any = {
      secret: this.userservice.secret,
      token: this.userservice.jwttoken,
      id: this.userservice.userid,
      // searchcondition: { user_id: this.userservice.userid },
    };

    this.Apiservice.getDataWithoutToken(data, endpoint).subscribe(
      (res: any) => {
        // console.log(res, 'jobticket');
        this.jobticketcatagory = res.res; // sarch count  (How many data there)
        this.jobticketcatagory = [
          ...new Set(this.jobticketcatagory.map((item) => item.name)),
        ];
      }
    );
  }
  trainingname_func() {
    const endpoint = 'api/autocomplete_done_training_lession_training_name';
    const data: any = {
      secret: this.userservice.secret,
      token: this.userservice.jwttoken,
      parentid: this.userservice.userid,
      condition: {
        limit: 5,
        skip: 0,
      },
      sort: {
        type: 'desc',
        field: 'createdon_datetime',
      },
      // searchcondition: { user_id: this.userservice.userid },
    };

    this.Apiservice.getDataWithoutToken(data, endpoint).subscribe(
      (res: any) => {
        // console.log(res, 'jobticket');
        this.trainingname = res.res; // sarch count  (How many data there)
      }
    );
  }
  onChangeEventTicketID(val: any) {
    this.modalloader = true;
    console.log(val);
    if (val != null) {
      let apibody = {
        search_str: val,
        // type: this.type,
        id: this.userservice.userid,
        sort: { field: 'createdon_datetime', type: 'desc' },
        secret: this.userservice.secret,
        token: this.userservice.jwttoken,
      };

      this.Apiservice.getDataWithoutToken(
        apibody,
        'api/autocomplete_ticket_id'
      ).subscribe((data: any) => {
        this.modalloader = false;
        this.optionticketid = data.res;
      });
    } else {
      this.modalloader = false;
    }
  }
  onChangeEventReportedBy(val: any) {
    this.modalloader = true;
    console.log(val);
    if (val) {
      let apibody = {
        search_str: val,
        // type: this.type,
        id: this.userservice.userid,
        sort: { field: 'createdon_datetime', type: 'desc' },
        secret: this.userservice.secret,
        token: this.userservice.jwttoken,
      };

      this.Apiservice.getDataWithoutToken(
        apibody,
        'api/autocomplete_job_ticket_reported_by'
      ).subscribe((data: any) => {
        this.modalloader = false;
        this.optionreportedby = data.res;
      });
    } else {
      this.modalloader = false;
    }
  }
  onChangeEventTrainingUserName(val: any) {
    this.modalloader = true;
    console.log(val);
    if (val) {
      const endpoint = 'api/autocomplete_done_training_lession_user_name';
      const data: any = {
        secret: this.userservice.secret,
        token: this.userservice.jwttoken,
        parentid: this.userservice.userid,
        search_str: val,
        condition: {
          limit: 10,
          skip: 0,
        },
        sort: {
          type: 'desc',
          field: 'createdon_datetime',
        },
        // searchcondition: { user_id: this.userservice.userid },
      };

      this.Apiservice.getDataWithoutToken(data, endpoint).subscribe(
        (res: any) => {
          // console.log(res, 'jobticket');
          this.optionstrainingname = res.res; // sarch count  (How many data there)
          this.modalloader = false;
        }
      );
    } else {
      this.modalloader = false;
    }
  }
  onChangeEventTrainingEmail(val: any) {
    this.modalloader = true;
    console.log(val);
    if (val) {
      const endpoint = 'api/autocomplete_done_training_lession_email';
      const data: any = {
        secret: this.userservice.secret,
        token: this.userservice.jwttoken,
        parentid: this.userservice.userid,
        search_str: val,
        condition: {
          limit: 10,
          skip: 0,
        },
        sort: {
          type: 'desc',
          field: 'createdon_datetime',
        },
        // searchcondition: { user_id: this.userservice.userid },
      };

      this.Apiservice.getDataWithoutToken(data, endpoint).subscribe(
        (res: any) => {
          // console.log(res, 'jobticket');
          this.optiontrainingemail = res.res; // sarch count  (How many data there)
          this.modalloader = false;
        }
      );
    } else {
      this.modalloader = false;
    }
  }
  onChangeEventTechName(val: any) {
    this.modalloader = true;
    console.log(val);
    if (val) {
      const endpoint =
        'api/exitsinglistname?idandtype=' +
        this.userservice.userid +
        ',technological-consultant';
      const data: any = {
        secret: this.userservice.secret,
        token: this.userservice.jwttoken,
        search_str: val,
      };

      this.Apiservice.getDataWithoutToken(data, endpoint).subscribe(
        (res: any) => {
          // console.log(res, 'jobticket');
          this.optionstechname = res.res; // sarch count  (How many data there)
          this.modalloader = false;
        }
      );
    } else {
      this.modalloader = false;
    }
  }
  onChangeEventTechEmail(val: any) {
    this.modalloader = true;
    console.log(val);
    if (val) {
      const endpoint =
        'api/exitsinglistemail?idandtype=' +
        this.userservice.userid +
        ',technological-consultant';
      const data: any = {
        secret: this.userservice.secret,
        token: this.userservice.jwttoken,
        search_str: val,
      };

      this.Apiservice.getDataWithoutToken(data, endpoint).subscribe(
        (res: any) => {
          // console.log(res, 'jobticket');
          this.optiontechemail = res.res; // sarch count  (How many data there)
          this.modalloader = false;
        }
      );
    } else {
      this.modalloader = false;
    }
  }
}
