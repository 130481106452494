import { HttpClient } from "@angular/common/http";
import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { CookieService } from "ngx-cookie-service";
import { ApiService } from "src/app/services/api.service";
import { UserserviceService } from "src/app/services/userservice.service";
@Component({
  selector: 'app-commission-add',
  templateUrl: './commission-add.component.html',
  styleUrls: ['./commission-add.component.css']
})
export class CommissionAddComponent implements OnInit {
  public formdata: any;
  public formfieldrefreshdata: any = [];
  public formfieldrefresh: any;
  public product: any = [];
  public commission_rule: any = [];
  public product_details: any = [];
  public onetimeproblem: boolean = true;
  public selected_product: any = '';
  public userData: any = [];
  public parent_product_info: any = [];
  public tech_commission_value: any = '';
  public distributor_commission_value: any = '';
  public distributor_name: any = '';
  public tech_name:any = '';
  public counter:any = 0;
  constructor(
    public apiservice: ApiService,
    public http: HttpClient,
    public activatedRoute: ActivatedRoute,
    public cookies: CookieService,
    public router: Router,
    public userService: UserserviceService
  ) {
    this.activatedRoute.data.forEach((res: any) => {
      // console.log(res);
      this.commission_rule = res.resolvedata.result.commissionrule;
      for (const key in res.resolvedata.result.product) {
        this.product.push({
          val: res.resolvedata.result.product[key]._id,
          name: res.resolvedata.result.product[key].productname
        })
      }
      console.log(this.product);
      
    });
    console.log(this.product);
    this.formdata = {
      successmessage: "Added Successfully !!",
      redirectpath: "commission/list",
      submitactive: true, // optional, default true
      submittext: "Submit",
      resettext: "Reset",
      canceltext: "Cancel",
      apiUrl: this.apiservice.api_url,
      endpoint: "api10/add-commission", //
      jwttoken: this.userService.jwttoken,
      cancelroute: "commission/list",
      fields: [
        {
          label: "Select Tech",
          name: "tech_id",
          type: "autocomplete",
          // multiple: false,
          endpoint: "",
          search_field: "search_str",
          // val: [],
          // value: ' ',
          validations: [
            { rule: "required", message: "Select Tech" },
          ],
        },
      ],
    };
    if(this.router.url == '/commission/event/add'){
      this.formdata.fields[0].endpoint = "api10/autocomplete_tech_event_commission";
    }
    if(this.router.url == '/commission/sales/add'){
      this.formdata.fields[0].endpoint = "api/exitsinglistname?type=technological-consultant";
    }
  }

  ngOnInit() {
  }
  listenFormFieldChanges(val: any) {
    console.log(val);
    // if(val.field == 'fromsubmit' && val.fieldval == 'success'){

    //   let data = val.formval
    //   this.apiservice.getDataWithoutToken(data,'api10/add-commission').subscribe((res:any)=>{
    //     console.log(res);
        
    //   })
    // }
    if (val.field.label == "Select Tech" && val.autocompletedata != undefined) {
      this.counter = 0;
      this.remove_fields();
      this.distributor_name = '';
      this.tech_name = '';
      this.parent_product_info = [];
      this.userData = [];
      this.product_details = [];
      // this.formfieldrefreshdata = { field: 'tech_id', value: '' };
      // setTimeout(() => {
      //   this.formfieldrefreshdata = { field: 'tech_id', value: val.autocompletedata.val };
      // }, 2000);
      // this.onetimeproblem = false;
      console.log(val);
      this.tech_name = val.autocompletedata.name;
      let send_data = {
        secret: this.userService.secret,
        token: this.userService.jwttoken,
        id: val.autocompletedata._id,
      }
      this.apiservice.getDataWithoutToken(send_data, 'api/getoneuser').subscribe((data: any) => {
        console.log(data);
        this.userData = data.result[0];
        this.update_commission(this.counter++);
      })
      let send_data1 = {
        parent_id: val.autocompletedata.parentid,
      }
      this.apiservice.getDataWithoutToken(send_data1, 'api10/get-parent-product').subscribe((res: any) => {
        console.log(res);
        this.parent_product_info = res.res[0];
        this.distributor_name = this.parent_product_info.firstname + " " + this.parent_product_info.lastname;
        setTimeout(() => {
          this.formfieldrefreshdata = { field: 'distributor_name_s', value: this.distributor_name };
        }, 300);
        setTimeout(() => {
          this.formfieldrefreshdata = { field: 'distributor_name', value: this.distributor_name };
        }, 500);
        setTimeout(() => {
          this.formfieldrefreshdata = { field: 'distributor_id', value: this.parent_product_info._id };
        }, 1500);
        console.warn(this.distributor_name);

        this.update_commission(this.counter++);
        console.warn(this.product);
        
        for (let key of res.res[0].disableproduct) {
          for (let key1 in this.product) {
            if (this.router.url == '/commission/event/add') {
              if (key == '604aff3691b7c80008ca11a8' && this.product[key1].val == key) {
                this.product_details.push(this.product[key1]);
              }
              if (key == '612c883f118c110009f02820' && this.product[key1].val == key) {
                this.product_details.push(this.product[key1]);
              }
            }
            if (this.router.url == '/commission/sales/add') {
              if (key != '612c883f118c110009f02820' && this.product[key1].val == key) {
                this.product_details.push(this.product[key1]);
              }
            }
          }
        }
        console.log(this.product_details);
        
      })
      this.add_fields();
    }
    if(val.field.label == 'Product' && val.fieldval == '604aff3691b7c80008ca11a8' && this.router.url == '/commission/sales/add'){
      let fieldremoveforpece = [
        'createdon_datetime',
        'start_date',
        'end_date',
      ]
      setTimeout(() => {
        this.formfieldrefreshdata = {
          field: 'removefromcontrol',
          value: fieldremoveforpece
        };
      }, 100);
      setTimeout(() => {
        let fieldaddforpece = [
        {
          label: "Pay Period Start Date",
          name: "start_date",
          type: 'date',
          value: "",
          validations: [{ rule: "required", message: "Pay Period Start Date is required" }],
          after: 'commission_type'
        },
        {
          label: "Pay Period End Date",
          name: "end_date",
          type: 'date',
          value: "",
          validations: [{ rule: "required", message: "Pay Period End Date is required" }],
          after: 'start_date'
        },
      ]
      this.formfieldrefreshdata = {
        field: 'addfromcontrol',
        value: fieldaddforpece
      };
      }, 400);
    }
    if(val.field.label == 'Product' && val.fieldval != '604aff3691b7c80008ca11a8' && this.router.url == '/commission/sales/add'){
      let fieldremoveforpece = [
        'createdon_datetime',
        'start_date',
        'end_date',
      ]
      setTimeout(() => {
        this.formfieldrefreshdata = {
          field: 'removefromcontrol',
          value: fieldremoveforpece
        };
      }, 100);
      setTimeout(() => {
        let fieldaddforpece = [
          {
            label: "Sold On",
            name: "createdon_datetime",
            type: 'date',
            value: "",
            validations: [{ rule: "required", message: "Sold On is required" }],
            after: 'commission_type'
          },
      ]
      this.formfieldrefreshdata = {
        field: 'addfromcontrol',
        value: fieldaddforpece
      };
      }, 400);
    }
    if (val.field.label == "Product") {
      this.tech_commission_value = '';
      this.distributor_commission_value = '';
      console.log(val);
      this.selected_product = val.fieldval;
      for (const key in val.field.val) {
        // console.warn(val.field.val[key].val);
        // console.warn(val.fieldVal);
        if(val.field.val[key].val === val.fieldval){
          
          setTimeout(() => {
            this.formfieldrefreshdata = { field: 'product_name', value: val.field.val[key].name };
          }, 50);
        }
      }
      if (typeof (val.fromval.price) != 'undefined' && val.fromval.price != null && val.fromval.price != '') {
        for (const key in this.commission_rule) {
          if (this.commission_rule[key].product_id == this.selected_product && this.commission_rule[key].user_type == 'technological-consultant') {
            this.tech_commission_value = (Number(val.fromval.price) * (Number(this.commission_rule[key].commission_value) / 100)).toFixed(2);
            console.warn(this.tech_commission_value);
            this.formfieldrefreshdata = { field: 'tech_commission_value', value: this.tech_commission_value };
            setTimeout(() => {
              this.formfieldrefreshdata = { field: 'tech_commission', value: this.tech_commission_value };
            }, 300);
          }
          if (this.commission_rule[key].product_id == this.selected_product && this.commission_rule[key].user_type == 'distributor') {
            this.distributor_commission_value = (Number(val.fromval.price) * (Number(this.commission_rule[key].commission_value) / 100)).toFixed(2);
            console.warn(this.distributor_commission_value);
            setTimeout(() => {
              this.formfieldrefreshdata = { field: 'distributor_commission_value', value: this.distributor_commission_value };
            }, 500);
            setTimeout(() => {
              this.formfieldrefreshdata = { field: 'distributor_commission', value: this.distributor_commission_value };
            }, 1000);
          }
        }
      }
      if (typeof (val.fromval.test_count) != 'undefined' && val.fromval.test_count != null && val.fromval.test_count != '') {
        for (const key in this.commission_rule) {
          if (this.commission_rule[key].product_id == this.selected_product && this.commission_rule[key].user_type == 'technological-consultant') {
            this.tech_commission_value = (Number(val.fromval.test_count) * Number(this.commission_rule[key].commission_value)).toFixed(2);
            console.warn(this.tech_commission_value);
            this.formfieldrefreshdata = { field: 'tech_commission_value', value: this.tech_commission_value };
            setTimeout(() => {
              this.formfieldrefreshdata = { field: 'tech_commission', value: this.tech_commission_value };
            }, 300);
          }
          if (this.commission_rule[key].product_id == this.selected_product && this.commission_rule[key].user_type == 'distributor') {
            this.distributor_commission_value = (Number(val.fromval.test_count) * Number(this.commission_rule[key].commission_value)).toFixed(2);
            console.warn(this.distributor_commission_value);
            setTimeout(() => {
              this.formfieldrefreshdata = { field: 'distributor_commission_value', value: this.distributor_commission_value };
            }, 500);
            setTimeout(() => {
              this.formfieldrefreshdata = { field: 'distributor_commission', value: this.distributor_commission_value };
            }, 1000);
          }
        }
      }
    }
    if (val.field.name == "price") {
      console.log(val);
      console.log(this.commission_rule);
      for (const key in this.commission_rule) {
        if (this.commission_rule[key].product_id == this.selected_product && this.commission_rule[key].user_type == 'technological-consultant') {
          this.tech_commission_value = (Number(val.fieldval) * (Number(this.commission_rule[key].commission_value) / 100)).toFixed(2);
          console.warn(this.tech_commission_value);
          this.formfieldrefreshdata = { field: 'tech_commission_value', value: this.tech_commission_value };
          setTimeout(() => {
            this.formfieldrefreshdata = { field: 'tech_commission', value: this.tech_commission_value };
          }, 300);
        }
        if (this.commission_rule[key].product_id == this.selected_product && this.commission_rule[key].user_type == 'distributor') {
          this.distributor_commission_value = (Number(val.fieldval) * (Number(this.commission_rule[key].commission_value) / 100)).toFixed(2);
          console.warn(this.distributor_commission_value);
          setTimeout(() => {
            this.formfieldrefreshdata = { field: 'distributor_commission_value', value: this.distributor_commission_value };
          }, 500);
          setTimeout(() => {
            this.formfieldrefreshdata = { field: 'distributor_commission', value: this.distributor_commission_value };
          }, 1000);
        }
      }
    }
    if (val.field.label == "Number of Test") {
      console.log(val);
      console.log(this.commission_rule);
      for (const key in this.commission_rule) {
        if (this.commission_rule[key].product_id == this.selected_product && this.commission_rule[key].user_type == 'technological-consultant') {
          this.tech_commission_value = (Number(val.fieldval) * Number(this.commission_rule[key].commission_value)).toFixed(2);
          console.warn(this.tech_commission_value);
          this.formfieldrefreshdata = { field: 'tech_commission_value', value: this.tech_commission_value };
          setTimeout(() => {
            this.formfieldrefreshdata = { field: 'tech_commission', value: this.tech_commission_value };
          }, 300);
        }
        if (this.commission_rule[key].product_id == this.selected_product && this.commission_rule[key].user_type == 'distributor') {
          this.distributor_commission_value = (Number(val.fieldval) * Number(this.commission_rule[key].commission_value)).toFixed(2);
          console.warn(this.distributor_commission_value);
          setTimeout(() => {
            this.formfieldrefreshdata = { field: 'distributor_commission_value', value: this.distributor_commission_value };
          }, 500);
          setTimeout(() => {
            this.formfieldrefreshdata = { field: 'distributor_commission', value: this.distributor_commission_value };
          }, 1000);
        }
      }
    }
  }
  add_fields() {
    // console.log(this.distributor_name);

    let data = [];
    if (this.router.url == '/commission/sales/add') {
      data = [
        {
          label: "Product",
          name: "product_id",
          hint: "",
          type: "select",
          val: this.product_details,
          validations: [{ rule: "required", message: "Select Product" }],
          after: 'tech_id',
          // multiple: true
        },
        {
          label: "Selling Price Or Gross/Net Amount",
          name: "price",
          type: "number",
          prefix: '$',
          value: "",
          validations: [
            { rule: "required", message: "Selling Price Or Gross/Net Amount is required" },
          ],
          after: 'product_id'
        },
        {
          label: "Tech Commission",
          name: "tech_commission_value",
          hint: "",
          type: "text",
          prefix: '$',
          value: "",
          disabled: true,
          validations: [{ rule: "required", message: "Tech Commission is required" }],
          after: 'price'
        },
        {
          label: "Distributor",
          name: "distributor_name_s",
          hint: "",
          type: "text",
          value: "",
          disabled: true,
          validations: [{ rule: "required", message: "Distributor is required" }],
          after: 'tech_commission_value'
        },
        {
          label: "Distributor Commission",
          name: "distributor_commission_value",
          hint: "",
          type: "text",
          prefix: '$',
          value: "",
          disabled: true,
          validations: [{ rule: "required", message: "Distributor Commission is required" }],
          after: 'distributor_name_s'
        },
        // {
        //   label: "Sold On",
        //   name: "createdon_datetime",
        //   type: 'date',
        //   value: "",
        //   validations: [{ rule: "required", message: "Sold On is required" }],
        //   after: 'distributor_commission_value'
        // },
        {
          label: "tech name",
          name: "tech_name",
          hint: "",
          type: "hidden",
          value: "",
          after: 'distributor_commission_value'
        },
        {
          label: "distributor id",
          name: "distributor_id",
          hint: "",
          type: "hidden",
          value: "",
          after: 'tech_name'
        },
        {
          label: "Tech Commission",
          name: "tech_commission",
          hint: "",
          type: "hidden",
          value: '',
          after: 'distributor_id'
        },
        {
          label: "distributor name",
          name: "distributor_name",
          hint: "",
          type: "hidden",
          value: "",
          after: 'tech_commission'
        },
        {
          label: "Distributor Commission",
          name: "distributor_commission",
          hint: "",
          type: "hidden",
          value: "",
          after: 'distributor_name'
        },
        {
          label: "product name",
          name: "product_name",
          hint: "",
          type: "hidden",
          value: "",
          after: 'distributor_commission'
        },
        {
          label: "commission type",
          name: "commission_type",
          hint: "",
          type: "hidden",
          value: "sales_commission",
          after: 'product_name'
        },
        {
          label: "Sold On",
          name: "createdon_datetime",
          type: 'date',
          value: "",
          validations: [{ rule: "required", message: "Sold On is required" }],
          after: 'commission_type'
        },
      ]
    }
    if (this.router.url == '/commission/event/add') {
      data = [
        {
          label: "Product",
          name: "product_id",
          hint: "",
          type: "select",
          val: this.product_details,
          validations: [{ rule: "required", message: "Select Product" }],
          after: 'tech_id',
          // multiple: true
        },
        {
          label: "Number of Test",
          name: "test_count",
          type: "number",
          value: "",
          validations: [
            { rule: "required", message: "Number of Test  is required" },
          ],
          after: 'product_id'
        },
        {
          label: "Tech Commission",
          name: "tech_commission_value",
          hint: "",
          type: "text",
          prefix: '$',
          value: '',
          disabled: true,
          validations: [{ rule: "required", message: "Tech Commission is required" }],
          after: 'test_count'
        },
        {
          label: "Distributor",
          name: "distributor_name_s",
          hint: "",
          type: "text",
          value: "",
          disabled: true,
          validations: [{ rule: "required", message: "Distributor is required" }],
          after: 'tech_commission_value'
        },
        {
          label: "Distributor Commission",
          name: "distributor_commission_value",
          hint: "",
          type: "text",
          prefix: '$',
          value: "",
          disabled: true,
          validations: [{ rule: "required", message: "Distributor Commission is required" }],
          after: 'distributor_name_s'
        },
        {
          label: "Start Date",
          name: "start_date",
          type: 'date',
          value: "",
          validations: [{ rule: "required", message: "Start Date is required" }],
          after: 'distributor_commission_value'
        },
        {
          label: "End Date",
          name: "end_date",
          type: 'date',
          value: "",
          validations: [{ rule: "required", message: "End Date is required" }],
          after: 'start_date'
        },
        {
          label: "tech name",
          name: "tech_name",
          hint: "",
          type: "hidden",
          value: "",
          after: 'end_date'
        },
        {
          label: "distributor id",
          name: "distributor_id",
          hint: "",
          type: "hidden",
          value: "",
          after: 'tech_name'
        },
        {
          label: "distributor name",
          name: "distributor_name",
          hint: "",
          type: "hidden",
          value: "",
          after: 'distributor_id'
        },
        {
          label: "Tech Commission",
          name: "tech_commission",
          hint: "",
          type: "hidden",
          value: '',
          after: 'distributor_name'
        },
        {
          label: "Distributor Commission",
          name: "distributor_commission",
          hint: "",
          type: "hidden",
          value: "",
          after: 'tech_commission'
        },
        {
          label: "product name",
          name: "product_name",
          hint: "",
          type: "hidden",
          value: "",
          after: 'distributor_commission'
        },
        {
          label: "commission type",
          name: "commission_type",
          hint: "",
          type: "hidden",
          value: "event_commission",
          after: 'product_name'
        },
      ]
    }
    console.log(data);
    setTimeout(() => {
      this.formfieldrefreshdata = {
        field: 'addfromcontrol',
        value: data
      };
    }, 1000);
    setTimeout(() => {
      this.formfieldrefreshdata = { field: 'distributor_name_s', value: this.distributor_name };
    }, 1500);
    setTimeout(() => {
      this.formfieldrefreshdata = { field: 'tech_name', value: this.tech_name };
    }, 1600);
    setTimeout(() => {
      this.formfieldrefreshdata = { field: 'distributor_id', value: this.parent_product_info._id };
    }, 1700);
  }
  remove_fields() {
    let data = [];
    if (this.router.url == '/commission/sales/add') {
      data = [
        "product_id",
        "price",
        "tech_commission",
        "tech_commission_value",
        "distributor_name",
        "distributor_name_s",
        "distributor_commission_value",
        "distributor_commission",
        "createdon_datetime",
        "tech_name",
        "distributor_id",
      ]
    }
    if (this.router.url == '/commission/event/add') {
      data = [
        "product_id",
        "test_count",
        "tech_commission",
        "tech_commission_value",
        "distributor_name",
        "distributor_name_s",
        "distributor_commission_value",
        "distributor_commission",
        "start_date",
        "end_date",
        "tech_name",
        "distributor_id",
      ]
    }
    console.log(data);
    this.formfieldrefreshdata = {
      field: 'removefromcontrol',
      value: data
    };
  }
  update_commission(val:any) {
    console.warn(val);
    
    console.log(this.userData);
    console.log(this.parent_product_info);
    this.commission_rule = [];
    if(val == 1){
      let data = {};
      if(this.router.url == '/commission/event/add'){
        data = {
          searchcondition:{
            commission_type:'event_commission'
          }
        }
      }
      if(this.router.url == '/commission/sales/add'){
        data = {
          searchcondition:{
            commission_type:'sales_commission'
          }
        }
      }
      this.apiservice.getDataWithoutToken(data,'api10/get-commission-rule').subscribe((res:any)=>{
        this.commission_rule = res.res;
        for (let key in this.commission_rule) {
          if (this.commission_rule[key].user_type == 'technological-consultant') {
            let check_name = this.commission_rule[key].product_name.replace(/[-" "]/g, '_') + this.commission_rule[key].commission_type;
            console.log(check_name);
            if (check_name == "PECEevent_commission" && this.userData.PECEevent_commission != null && this.userData.PECEevent_commission != '') {
              this.commission_rule[key].commission_value = this.userData.PECEevent_commission
            }
            else {
              if (check_name == "PECEevent_commission" && this.parent_product_info.PECEevent_commission != null && this.parent_product_info.PECEevent_commission != '') {
    
                this.commission_rule[key].commission_value = this.parent_product_info.PECEevent_commission
              }
            }
            if (check_name == "PECEsales_commission" && this.userData.PECEsales_commission != null && this.userData.PECEsales_commission != '') {
              this.commission_rule[key].commission_value = this.userData.PECEsales_commission
            }
            else {
              if (check_name == "PECEsales_commission" && this.parent_product_info.PECEsales_commission != null && this.parent_product_info.PECEsales_commission != '') {
    
                this.commission_rule[key].commission_value = this.parent_product_info.PECEsales_commission
              }
            }
            if (check_name == "TM_FLOW_ANS_MEDICAL_DEVICEsales_commission" && this.userData.TM_FLOW_ANS_MEDICAL_DEVICEsales_commission != null && this.userData.TM_FLOW_ANS_MEDICAL_DEVICEsales_commission != '') {
              this.commission_rule[key].commission_value = this.userData.TM_FLOW_ANS_MEDICAL_DEVICEsales_commission
            }
            else {
              if (check_name == "TM_FLOW_ANS_MEDICAL_DEVICEsales_commission" && this.parent_product_info.TM_FLOW_ANS_MEDICAL_DEVICEsales_commission != null && this.parent_product_info.TM_FLOW_ANS_MEDICAL_DEVICEsales_commission != '') {
    
                this.commission_rule[key].commission_value = this.parent_product_info.TM_FLOW_ANS_MEDICAL_DEVICEsales_commission
              }
            }
            if (check_name == "RST_Sanexussales_commission" && this.userData.RST_Sanexussales_commission != null && this.userData.RST_Sanexussales_commission != '') {
              this.commission_rule[key].commission_value = this.userData.RST_Sanexussales_commission
            }
            else {
              if (check_name == "RST_Sanexussales_commission" && this.parent_product_info.RST_Sanexussales_commission != null && this.parent_product_info.RST_Sanexussales_commission != '') {
    
                this.commission_rule[key].commission_value = this.parent_product_info.RST_Sanexussales_commission
              }
            }
            if (check_name == "BioEnergeticsevent_commission" && this.userData.BioEnergeticsevent_commission != null && this.userData.BioEnergeticsevent_commission != '') {
              this.commission_rule[key].commission_value = this.userData.BioEnergeticsevent_commission
            }
            else {
              if (check_name == "BioEnergeticsevent_commission" && this.parent_product_info.BioEnergeticsevent_commission != null && this.parent_product_info.BioEnergeticsevent_commission != '') {
    
                this.commission_rule[key].commission_value = this.parent_product_info.BioEnergeticsevent_commission
              }
            }
            if (check_name == "BioEnergeticssales_commission" && this.userData.BioEnergeticssales_commission != null && this.userData.BioEnergeticssales_commission != '') {
              this.commission_rule[key].commission_value = this.userData.BioEnergeticssales_commission
            }
            else {
              if (check_name == "BioEnergeticssales_commission" && this.parent_product_info.BioEnergeticssales_commission != null && this.parent_product_info.BioEnergeticssales_commission != '') {
    
                this.commission_rule[key].commission_value = this.parent_product_info.BioEnergeticssales_commission
              }
            }
          }
          if (this.commission_rule[key].user_type == 'distributor') {
            let check_name = this.commission_rule[key].product_name.replace(/[-" "]/g, '_') + this.commission_rule[key].commission_type;
            console.log(check_name);
            if (check_name == "PECEevent_commission" && this.parent_product_info.PECEevent_commission != null && this.parent_product_info.PECEevent_commission != '') {
              this.commission_rule[key].commission_value = this.parent_product_info.PECEevent_commission
            }
            if (check_name == "PECEsales_commission" && this.parent_product_info.PECEsales_commission != null && this.parent_product_info.PECEsales_commission != '') {
              this.commission_rule[key].commission_value = this.parent_product_info.PECEsales_commission
            }
            if (check_name == "TM_FLOW_ANS_MEDICAL_DEVICEsales_commission" && this.parent_product_info.TM_FLOW_ANS_MEDICAL_DEVICEsales_commission != null && this.parent_product_info.TM_FLOW_ANS_MEDICAL_DEVICEsales_commission != '') {
              this.commission_rule[key].commission_value = this.parent_product_info.TM_FLOW_ANS_MEDICAL_DEVICEsales_commission
            }
            if (check_name == "RST_Sanexussales_commission" && this.parent_product_info.RST_Sanexussales_commission != null && this.parent_product_info.RST_Sanexussales_commission != '') {
              this.commission_rule[key].commission_value = this.parent_product_info.RST_Sanexussales_commission
            }
            if (check_name == "BioEnergeticsevent_commission" && this.parent_product_info.BioEnergeticsevent_commission != null && this.parent_product_info.BioEnergeticsevent_commission != '') {
              this.commission_rule[key].commission_value = this.parent_product_info.BioEnergeticsevent_commission
            }
            if (check_name == "BioEnergeticssales_commission" && this.parent_product_info.BioEnergeticssales_commission != null && this.parent_product_info.BioEnergeticssales_commission != '') {
              this.commission_rule[key].commission_value = this.parent_product_info.BioEnergeticssales_commission
            }
          }
        }
      })
      
    }

  }
}
