import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { ApiService } from 'src/app/services/api.service';
import { UserserviceService } from 'src/app/services/userservice.service';

@Component({
  selector: 'app-my-account',
  templateUrl: './my-account.component.html',
  styleUrls: ['./my-account.component.css']
})
export class MyAccountComponent implements OnInit {
  formfieldrefresh: any = true;
  formfieldrefreshdata: any = null;
  formfieldrefreshpass: any = true;
  formfieldrefreshdatapass: any = null;
  emailregex: RegExp = /^(([^<>()\[\]\\.,;:\s@']+(\.[^<>()\[\]\\.,;:\s@']+)*)|('.+'))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  passwordregex: RegExp = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})/;
  textregex: RegExp = /^[a-zA-Z]*$/;
  temtdata: any = '';
  // formdata
  updatetable: any = false;
  datasource: any;
  public formdata: any;
  public formdatachngpass: any;
  public stateVal: any = [];
  public status: any = [
    { val: 1, name: 'Active' },
    { val: 0, name: 'Inactive' },
  ];
  public onbording: any = [
    { val: 1, name: 'Yes' },
    { val: 0, name: 'No' },
  ];
  public userData: any = [];
  public userDatapass: any = [];
  public resolveval: any;
  public resolvevalpass: any;
  public flag: any;
  public url: any;
  public userid: any;

  constructor(public apiservice: ApiService, public http: HttpClient, public activatedroute: ActivatedRoute, public cookies: CookieService, public router: Router, public userService: UserserviceService) {
    if (this.cookies.check('type')) {
      this.getuserdata();
      // this.changepassword();
      this.url = JSON.parse(this.cookies.get('type')) + '/list';
    }

    this.apiservice.getState().subscribe((res: any) => {
      // console.log(res, 'state....')
      for (const i in res) {
        if (Object.prototype.hasOwnProperty.call(res, i)) {
          this.stateVal.push({ val: res[i].abbreviation, name: res[i].name })
        }
      }
    });

    if (this.cookies.check('userid')) {
      this.userid = JSON.parse(this.cookies.get('userid'));
    }
  }

  // get user details
  getuserdata() {
    if (this.activatedroute.snapshot.params._id != null) {
      this.activatedroute.data.subscribe((res: any) => {
        // console.log(res, 'ressss....');
        this.userData = res.resolvedata.result[0];
        this.resolveval = this.userData;
        var url = '/' + this.userService.type + '/dashboard';

        this.formdata = {
          
          successmessage: 'Update Successfully',
          redirectpath: url,
          submitactive: true,
          submittext: 'update',
          // resettext: 'Reset',
          canceltext: 'Cancel',
          apiUrl: this.apiservice.api_url,
          endpoint: 'api/userupdate', //
          jwttoken: this.cookies.get('jwtToken'),
          cancelroute: url,

          fields: [
            {
              label: 'First Name',
              name: 'firstname',
              value: this.resolveval.firstname,
              type: 'text',
              validations: [
                { rule: 'required', message: 'First Name is Required' },
                 // {
                //   rule: 'pattern',
                //   value: this.textregex,
                //   message: 'only use alphabet',
                // },
              ],
            },

            {
              heading: '',
              label: 'Last Name',
              name: 'lastname',
              value: this.resolveval.lastname,
              type: 'text',
              validations: [
                { rule: 'required', message: 'Last Name is Required' },
                // {
                //   rule: 'pattern',
                //   value: this.textregex,
                //   message: 'only use alphabet',
                // },
              ],
            },

            {
              label: 'Email',
              name: 'email',
              type: 'email',
              hint: '',
              disabled: true,
              value: this.resolveval.email,
              validations: [
                { rule: 'required', message: 'Email is required' },
                {
                  rule: 'pattern',
                  value: this.emailregex,
                  message: 'Must be a valid Email',
                },
              ],
            },
            {
              label: 'Address',
              name: 'address',
              value: this.resolveval.address,
              type: 'textarea',
              validations: [
                { rule: 'required', message: 'Address is Required' },
              ],
            },
            {
              label: 'type',
              name: 'type',
              type: 'hidden',
              value: 'admin',
            },
            {
              label: 'State',
              name: 'state',
              val: this.stateVal,
              value: this.resolveval.state,
              type: 'select',
              validations: [{ message: 'State is Required' }],
            },

            {
              label: 'City',
              name: 'city',
              value: this.resolveval.city,
              type: 'text',
              validations: [{ rule: 'required', message: 'City is Required' }],
            },

            {
              label: 'Zip',
              name: 'zip',
              value: this.resolveval.zip,
              type: 'number',
              validations: [
                { rule: 'required', message: 'Zip is Required' },
                {
                  rule: 'maxLength',
                  value: 5,
                  message: 'Please enter the valid Zip ID',
                },
              ],
            },
            // {
            //   label: 'Active',
            //   name: 'status',
            //   hint: '',
            //   type: 'checkbox',
            //   value: this.resolveval.status,
            // },

            {
              label: 'id',
              name: 'id',
              value: this.activatedroute.snapshot.params._id,
              type: 'hidden',
            },
          ],
        }
      })
    }
    if(this.userService.type == 'distributor'){
   this.formdata.fields.push(
  {
    label: 'On Bording Call',
    name: 'onbordingcall',
    hint: '',
    type: 'checkbox',
    val:this.onbording,
   value:this.resolveval.onbordingcall
  },
)
    }
  }

// change password
  ngOnInit() {
    const url = '/' + this.userService.type + '/dashboard';
    this.formdatachngpass = {
      successmessage: 'Updated Successfully',
      redirectpath: url,
      submitactive: true,
      submittext: 'Update',
      // resettext: 'Reset',
      canceltext: 'Cancel',
      apiUrl: this.apiservice.api_url,
      endpoint: 'api/changepassword', //
      jwttoken: this.apiservice.jwttoken,
      cancelroute: url,

      fields: [
        {
          label: 'Current Password',
          name: 'oldpassword',
          type: 'password',
          value: '',

          validations: [
            {
              rule: 'required',
              message:
                'Your provided Password must contain at-least one Uppercase letter, one Lower case letter, and one Number.',
            },
            {
              rule: 'pattern',
              value: '',
              message: 'Must contain a Capital Letter and a Number',
            },
          ],
        },
        {
          label: 'New Password',
          name: 'password',
          type: 'password',
          value: '',

          validations: [
            {
              rule: 'required',
              message:
                'Your provided Password must contain at-least one Uppercase letter, one Lower case letter, and one Number.',
            },
            {
              rule: 'pattern',
              value: this.passwordregex,
              message: 'Must contain a Capital Letter and a Number',
            },
          ],
        },

        {
          label: 'Confirm Password',
          name: 'confirmpassword',
          type: 'password',
          value: '',
          validations: [
            {
              rule: 'required',
              message:
                'Your provided Password must contain at-least one Uppercase letter, one Lower case letter, and one Number.',
            },
            {
              rule: 'match',
              message: 'Confirm Password field Needs to  match Password',
            },
            {
              rule: 'pattern',
              value: this.passwordregex,
              message: 'Must contain a Capital Letter and a Number',
            },
          ],
        },
        {
          label: 'id',
          name: 'id',
          type: 'hidden',
          value: this.userid,
        },
      ],
    }
  }

}
