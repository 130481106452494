import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { ApiService } from 'src/app/services/api.service';

@Component({
  selector: 'app-video-add-edit',
  templateUrl: './add-edit.component.html',
  styleUrls: ['./add-edit.component.css']
})
export class videoAddEditComponent implements OnInit {
  textregex: RegExp = /^[a-zA-Z]*$/;

  public ckeConfig: any = {
    toolbar: [
      {
        name: "editing",
        items: ["Scayt", "Find", "Replace", "SelectAll"],
      },
      {
        name: "clipboard",
        items: [
          "Cut",
          // "Copy",
          // "Paste",
          // "PasteText",
          // "PasteFromWord",
          // "-",
          // "Undo",
          // "Redo"
        ],
      },
      {
        name: "links",
        items: [
          "Link",
          //  "Unlink",
          //  "Anchor"
        ],
      },
      
      // {
      //     name: "tools",
      //     items: ["Maximize", "ShowBlocks", "Preview", "Print", "Templates"]
      // },
      { name: "document", items: ["Source"] },
      {
        name: "insert",
        items: [
          "Image",
          // "Table",
          // "HorizontalRule",
          // "SpecialChar",
          // "Iframe",
          // "imageExplorer"
        ],
      },
      "/",
      {
        name: "basicstyles",
        items: [
          "Bold",
          "Italic",
          "Underline",
          // "Strike",
          // "Subscript",
          // "Superscript",
          // "-",
          // "RemoveFormat"
        ],
      },
      // {
      //     name: "paragraph",
      //     items: [
      //         "NumberedList",
      //         "BulletedList",
      //         "-",
      //         "Outdent",
      //         "Indent",
      //         "CreateDiv",
      //         "-",
      //         "Blockquote"
      //     ]
      // },
      // {
      //     name: "justify",
      //     items: [
      //         "JustifyLeft",
      //         "JustifyCenter",
      //         "JustifyRight",
      //         "JustifyBlock"
      //     ]
      // },

      {
        name: "styles",
        items: ["Styles", "Format", "FontSize", "-", "TextColor", "BGColor"],
      },
    ],
  };

  public product_category: any = [];
  public flag: any;
  public formdata: any;
  public stateVal: any = [];
  public status: any = [
    { val: 1, name: 'Active' },
    { val: 0, name: 'Inactive' },
  ];
  public userData: any = [];
  public videoCategory: any = [];
  public resolveval: any;
  public endpointc: any = 'api1/getallvideodcategorydata'
  constructor(public apiservice: ApiService, public http: HttpClient, public activatedroute: ActivatedRoute, public cookies: CookieService, public router: Router) {
    let data = {

      "condition": {
        "status": 1
      },
      "sort": {
        "type": "asc",
        "limit": 50
      }

    }
    this.apiservice
      .getDataWithoutToken(data, this.endpointc)
      .subscribe((res: any) => {
        for (const key in res.results.res) {
          this.videoCategory.push({ val: res.results.res[key]._id, name: res.results.res[key].category_name })

        }

      });
      let data1: any=
        {"source":"training_category_management","condition":{"is_trash":{"$ne":1}},"token":"eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJleHAiOjE2MTMxMDk0NDUsImRhdGEiOiJXak5XVDJFd1JsZFZWMDVsIiwiaWF0IjoxNjEzMTA1ODQ1fQ.LXJcJgZsFBCDtiJ5EMPkzWAqbUoEMlLCAqELI6QD2Is"}
      
      this.apiservice
      .getDataWithoutToken(data1, 'api1/productlist')
      .subscribe((res: any) => {
        // console.log(res.res,'llllllllllllll')
        for (const key in res.res) {
          this.product_category.push({ val: res.res[key]._id, name: res.res[key].productname })

        }

      });
    this.formdata = {
      successmessage: 'Added Successfully !!',
      redirectpath: '/video-management/listing',
      submitactive: true, // optional, default true
      submittext: 'Submit',
      resettext: 'Reset',
      canceltext: 'Cancel',
      apiUrl: this.apiservice.api_url,
      endpoint: 'api1/addorupdatevideo', //
      jwttoken: '',
      cancelroute: '/video-management/listing',

      fields: [
        {
          label: 'Title',
          name: 'video_title', //
          value: '',
          type: 'text',
          validations: [
            { rule: 'required', message: 'Title is Required' },

          ],
        },

        {

          label: 'Priority',
          name: 'video_priority',
          value: '',
          type: 'number',
          validations: [
            { rule: 'required', message: 'Priority is Required' },

          ],
        },
        {
          label: 'Description',
          name: 'video_description',
          value: '',
          type: 'editor',
          validations: [{ rule: 'required', message: 'Description is Required' }],
          ckeConfig: this.ckeConfig,
        },

        {
          label: 'Category',
          name: 'category_id',
          val: this.videoCategory,
          value: '',
          type: 'select',
          validations: [{ rule: 'required', message: 'Category is Required' }],
        },
       
        {
          label: 'Product',
          name: 'product_id',
          val: this.product_category,
          value: '',
          type: 'select',
          validations: [{ rule: 'required', message: 'Product Name is Required' }],
        },
        {
          label: 'Youtube Id',
          name: 'youtube_link', //
          value: '',
          type: 'text',
          prefix: "https://www.youtube.com/watch?v=",
          validations: [
            { rule: 'required', message: 'Youtube Link is Required' },
            // {
            //   rule: 'pattern',
            //   value: this.textregex,
            //   message: 'Must be a use alphabet Youtube Id',
            // },
            {
              rule: 'minLength',
              value: 5,
              message: 'The Youtube Id must be 11 digits',
            },
  
          ],
        },
        {
          label: 'Active',
          name: 'status',
          hint: '',
          type: 'checkbox',
          val: this.status,
          value: 0,
        },
      ],
    };
    if (this.activatedroute.snapshot.params._id != null) {
      this.editresourcescategory()
      this.flag =true;
    }
  }

  ngOnInit() {
 
  }


  editresourcescategory() {
    if (this.activatedroute.snapshot.params._id != null) {
      this.activatedroute.data.subscribe((res: any) => {
        this.userData = res.resolvedata.res[0];
        // console.log(this.userData.category_name, '++++++++++++++++++++++++++++++++');
        this.formdata = {
          successmessage: 'Updated Successfully !!',
          redirectpath: '/video-management/listing',
          submitactive: true, // optional, default true
          submittext: 'Update',
          resettext: 'Reset',
          canceltext: 'Cancel',
          apiUrl: this.apiservice.api_url,
          endpoint: 'api1/addorupdatevideo', //
          jwttoken: '',
          cancelroute: '/video-management/listing',

          fields: [
            {
              label: 'Title',
              name: 'video_title', //
              value: this.userData.video_title,
              type: 'text',
              validations: [
                { rule: 'required', message: 'Title is Required' },

              ],
            },
            {

              label: 'Priority',
              name: 'video_priority',
              value: this.userData.video_priority,
              type: 'number',
              validations: [
                { rule: 'required', message: 'Priority is Required' },
              ],
            },
            {
              label: 'Description',
              name: 'video_description',
              value: this.userData.video_description,
              type: 'editor',
              validations: [{ rule: 'required', message: 'Description is Required' }],
              ckeConfig: this.ckeConfig,
            },

            {
              label: 'Category',
              name: 'category_id',
              val: this.videoCategory,
              value: this.userData.category_id,
              type: 'select',
              validations: [{ rule: 'required', message: 'Category is Required' }],
            },
            {
              label: 'Product',
              name: 'product_id',
              val: this.product_category,
              value: this.userData.product_id,
              type: 'select',
              validations: [{ rule: 'required', message: 'Product Name is Required' }],
            },
            {
              label: 'Youtube Link',
              name: 'youtube_link', //
              prefix: "https://www.youtube.com/watch?v=",
              value: this.userData.youtube_link,
              type: 'text',
              validations: [
                { rule: 'required', message: 'Youtube Link is Required' },
              ],
            },
            {
              label: 'Active',
              name: 'status',
              hint: '',
              type: 'checkbox',
              val: this.status,
              value: this.userData.status,
            },
            {
              name: 'id',
              type: 'hidden',
              value: this.activatedroute.snapshot.params._id,
            },
          ],
        };
      })
    }
  }

}
