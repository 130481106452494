import { Location } from "@angular/common";
import { stringify } from "@angular/compiler/src/util";
import { Compiler, Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { CookieService } from "ngx-cookie-service";
import { Subscription,Observable } from "rxjs";
import { ApiService } from "src/app/services/api.service";
import { NavAccessService } from "src/app/services/nav-access.service";
import { UserserviceService } from "src/app/services/userservice.service";
import { environment } from "src/environments/environment";
import { DetectdomainService } from "src/app/services/detectdomain.service";
import { ObservableService } from '../../services/observable.service';


@Component({
  selector: "app-header",
  templateUrl: "./header.component.html",
  styleUrls: ["./header.component.css"],
})
export class HeaderComponent implements OnInit {

  public hostName: any;
  public medigradeflag: any=false;
  public loginlogo: string = '';

  status: any = false;

  statusmenu: any = false;

  public hasAccessToken = false;

  public linkToAddGoogleCalendar: string =
    environment["add_google_calendar_url"];

  public associatertraing: any;
  public userid: any;
  public type: any;
  public status_for_dicoverycall: any;
  public fullname = "N/A";
  public returntoadminflag = false;
  public returntodistributoflag = false;
  public signatureflag = false;
  public accessforleadsnav:any=false;
  public is_contractmanager = "";
  public trainingcomp: any = "";
  public last_traininig_cat: any;
  public gameplancall: any = 0;
  public currentlesson: any;
  public subscription: Subscription;
  public headerLogo: string = '';



  constructor(
    public cookieservice: CookieService,
    public compiler: Compiler,
    public router: Router,
    public userservice: UserserviceService,
    public apiservice: ApiService,
    public activateRoute: ActivatedRoute,
    private _location: Location,
    public nevaccessservice:NavAccessService,
    public observableservice: ObservableService,       
    public domainService: DetectdomainService    
  ) { 

    this.hostName = this.domainService.getdomain();
    if (this.hostName == "medigrade") {
      this.medigradeflag=true  
    }



    // this.hostName = this.domainService.getdomain();
    // console.log("getdomain" ,this.hostName)
    // if (this.hostName == "betoparedes") {
    //   this.headerLogo = '../../../assets/images/logo.png';
    // }
    // if (this.hostName == "medigrade") {
    //   this.headerLogo = '../../../assets/images/medigrade_headerlogo1.png';
    // }
    
    // observableservice.getlocationsData().subscribe((res: any) => {
    //   this.trainingcomp = res;
    // })


    this.subscription = this.nevaccessservice.getData().subscribe(value => {
      if (value.data.length > 0){
        console.log('Nav subscripton from ==>', value);
        this.accessforleadsnav=true;
      }else{
        this.accessforleadsnav=false;
      }
      if (typeof(value.last_training) != undefined && value.last_training != ''){
        console.log('Nav subscripton from header==>', value);
        this.last_traininig_cat = value.last_training
      }else{
        this.last_traininig_cat = environment["last_traininig_cat"];
      }
    });
    var myDate = new Date();
    let time = parseInt(this.cookieservice.get("login_time"));
    let fuctime = time + 1800000;
    let cutTime = myDate.setHours(myDate.getHours());
    // if (fuctime <= cutTime) {
    //   console.log("+++++++++++++++++++++++++++++++++");
    //   this.apiservice
    //     .getDataWithoutToken(
    //       { secret: this.cookieservice.get("secret") },
    //       "api/gettoken"
    //     )
    //     .subscribe((res: any) => {
    //       console.log(res.jwttoken, "resresresres");
    //       this.cookieservice.set("jwtToken", res.jwttoken, undefined, "/");
    //       this.cookieservice.set(
    //         "login_time",
    //         JSON.stringify(cutTime),
    //         undefined,
    //         "/"
    //       );
    //     });
    // }

    if (this.cookieservice.check("type")) {
      this.type = JSON.parse(this.cookieservice.get("type"));
    }
    if (this.type == "technological-consultant") {
      console.log(
        this.cookieservice.check("discovery_call"),
        "****************discovery call"
      );
      if (this.cookieservice.check("discovery_call")) {
        this.status_for_dicoverycall = JSON.parse(
          this.cookieservice.get("discovery_call")
        );
      } else {
        this.status_for_dicoverycall = 0;
      }
    }
    // console.log("type of user",this.type);

    if (this.cookieservice.check("signature")) {
      this.signatureflag = true;
    }
    // console.log(this.activateRoute.snapshot.routeConfig.path, "++++");

    // if (this.activateRoute.snapshot.routeConfig.path == 'manage-appointment/on-boarding/book-appointment' && this.cookieservice.check('event_type')) {
    //   this._location.back();
    // }
    if (
      this.cookieservice.check("gameplancall") &&
      this.cookieservice.get("gameplancall") == "1"
    ) {
      this.gameplancall = this.cookieservice.get("gameplancall");
      // console.log()
    }
    if (
      this.cookieservice.check("training") &&
      this.cookieservice.get("training") == "1"
    ) {
      this.trainingcomp = this.cookieservice.get("training"); 
      // console.log()
    } else if (this.type == "technological-consultant") {
      console.log("++++++ getdashboardtrainingdata");
      // this.getdashboardtrainingdata();
    }

    if (this.cookieservice.check("is_contractmanager")) {
      this.is_contractmanager = this.cookieservice.get("is_contractmanager");
    }
    if (this.cookieservice.check("firstname")) {
      this.fullname =
        JSON.parse(this.cookieservice.get("firstname")) +
        " " +
        JSON.parse(this.cookieservice.get("lastname"));
    }
    if (this.cookieservice.check("userid")) {
      this.userid = JSON.parse(this.cookieservice.get("userid"));
    }
    if (this.cookieservice.check("main_user")) {
      this.returntoadminflag = true;
    } else {
      this.returntoadminflag = false;
    }
    if (this.cookieservice.check("distributor")) {
      this.returntoadminflag = false;
      this.returntodistributoflag = true;
    }

    if (
      !this.cookieservice.check("signature") &&
      this.cookieservice.check("randomstring") &&
      (this.type == "sales-person" ||
        this.type == "technological-consultant" ||
        this.type == "distributor")
    ) {
      // console.log('++++++++ login ====', '/contract/' + JSON.parse(this.cookieservice.get('randomstring')))
      this.router.navigateByUrl(
        "/contract/" + JSON.parse(this.cookieservice.get("randomstring"))
      );
    }

    if (
      !this.cookieservice.check("event_type") &&
      this.cookieservice.check("signature") &&
      (this.type == "sales-person" ||
        this.type == "technological-consultant" ||
        this.type == "distributor")
    ) {
      this.router.navigateByUrl(
        "/calendar-management/manage-appointment-after-sign/on-boarding/book-appointment"
      );
      return;
    }
    console.log(typeof this.cookieservice.get('associated_training'));
    setTimeout(() => {
      document.querySelector("body").style.display = 'block';

    }, 100);

    if (this.cookieservice.check("associated_training") && typeof this.cookieservice.get('associated_training') != 'string') {
      console.log("typeof associated_training ==>",typeof this.cookieservice.get('associated_training'));
      this.associatertraing = JSON.parse(this.cookieservice.get('associated_training'));
    }
    if (this.cookieservice.check("associated_training") && typeof this.cookieservice.get('associated_training') == 'string') {
      console.log("typeof associated_training ==>",typeof this.cookieservice.get('associated_training'),this.cookieservice.get('associated_training'));
      this.associatertraing = JSON.parse(this.cookieservice.get('associated_training'));
    }
    setTimeout(() => {
      if (this.cookieservice.check("associated_training") && typeof this.cookieservice.get('associated_training') == 'string') {
        console.log("typeof associated_training ==>",typeof this.cookieservice.get('associated_training'),this.cookieservice.get('associated_training'));
        this.associatertraing = JSON.parse(this.cookieservice.get('associated_training'));
      }
    }, 2000);
    console.log(this.cookieservice.get('currentlesson'));
    if (this.cookieservice.check("currentlesson") && typeof this.cookieservice.get('currentlesson') != 'string') {
      console.log(this.cookieservice.get('currentlesson'));
      this.currentlesson = JSON.parse(this.cookieservice.get('currentlesson'));
    }
    // this.currentlesson = ''
    if (this.cookieservice.check("currentlesson") && typeof this.cookieservice.get('currentlesson') == 'string') {
      console.log(this.cookieservice.get('currentlesson'));
      this.currentlesson = JSON.parse(this.cookieservice.get('currentlesson'));
    }
    
  }
  // getdashboardtrainingdata() {
  //   console.log("header");
  //   let datafortraining = {
  //     condition: {
  //       associated_training: "60922437a133f500088e46ae",
  //     },
  //     user_id: JSON.parse(this.cookieservice.get("userid")),
  //   };

  //   this.apiservice
  //     .getDataWithoutToken(datafortraining, "api1/dashboardtrainingdata")
  //     .subscribe((res: any) => {
  //       // console.log(res, 'gggggggggggggggg')

  //       if (res.status === "success" && Object.keys(res.results).length > 0) {
  //         // // console.log(res.results.alllessondata != null && res.results.done_lesson_by_cat_by_user != '' && res.results.done_lesson_by_cat_by_user[0].lessonsdone != null && res.results.alllessondata == res.results.done_lesson_by_cat_by_user[0].lessonsdone)
  //         // if (
  //         //   res.results.alllessondata != null &&
  //         //   res.results.done_lesson_by_cat_by_user != "" &&
  //         //   res.results.done_lesson_by_cat_by_user[0].lessonsdone != null &&
  //         //   res.results.done_lesson_by_cat_by_user[0].lessonsdone <=
  //         //   res.results.alllessondata
  //         // ) {
  //         //   this.cookieservice.set("training", "1", undefined, "/");
  //         //   this.trainingcomp = "1";
  //         // }
  //       }
  //     });
  // }

  downloadcontract() {
    window.open(
      "https://awsbackend-dev-patient-files-test.s3.amazonaws.com/beto-paredes-contracts-pdf/contract_" +
      this.userservice.userid +
      ".pdf",
      "_blank"
    );
  }
  returntoadmin(flag) {
    let return_url = JSON.parse(this.cookieservice.get("return_url"));
    if (flag == "admin") {
      if (this.cookieservice.check("main_user")) {
        let mainuserdata = JSON.parse(this.cookieservice.get("main_user"));
        this.cookieservice.deleteAll("/");
        this.cookieservice.delete("main_user");
        for (let [keys, values] of Object.entries(mainuserdata)) {
          this.userservice.cookieset(keys, values);
        }
        console.log(return_url, "_____");
        setTimeout(() => {
          let currentUrl = return_url;
          this.router.routeReuseStrategy.shouldReuseRoute = () => false;
          this.router.onSameUrlNavigation = "reload";
          this.router.navigate([currentUrl]);
        }, 700);
      }
    }
    if (flag == "distributor") {
      if (this.cookieservice.check("distributor")) {
        let mainuserdata = JSON.parse(this.cookieservice.get("main_user"));
        this.cookieservice.deleteAll("/");
        this.cookieservice.delete("main_user");
        for (let [keys, values] of Object.entries(mainuserdata)) {
          this.userservice.cookieset(keys, values);
        }
        console.log(return_url, "_____");
        setTimeout(() => {
          let currentUrl = return_url;
          this.router.routeReuseStrategy.shouldReuseRoute = () => false;
          this.router.onSameUrlNavigation = "reload";
          this.router.navigate([currentUrl]);
        }, 700);
      }
    }
  }

  clickMenu() {
    this.statusmenu = !this.statusmenu;
  }

  ngOnInit() {
    
    if (this.cookieservice.check("access_token")) {
      this.hasAccessToken = true;
    }
    
  }
  updateNavAccessServiseData(){
    this.nevaccessservice.setData(this.userid)
  }
  // account logout
  logOut() {
    this.cookieservice.deleteAll(); // cookie service delete
    this.cookieservice.deleteAll("/");
    this.compiler.clearCache();
    localStorage.clear(); // local storage clear
    setTimeout(() => {
      this.compiler.clearCache(); // cache clear
      this.router.navigateByUrl("/", { skipLocationChange: true }).then(() => {
        this.router.navigate(["/"]);
      }); // after loguot navigate to login part
    }, 300);
  }

  myaccount() {
    this.router.navigateByUrl("/my-account/" + this.userid); // get user account details
  }

  openlink(val) {
    // console.log(val);
    window.location.href = val;
  }

  clickEvent() { }
}
