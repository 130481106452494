import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";

import { ResolveService } from "src/app/services/resolve.service";
import { environment } from "src/environments/environment";
import { EventcommissionComponent } from "./eventcommission/eventcommission.component";
import { ManagecommissionlistComponent } from "./managecommissionlist/managecommissionlist.component";
import { SalescommissionComponent } from "./salescommission/salescommission.component";

const routes: Routes = [
    { path: 'salescommisson/add', component: SalescommissionComponent },
    {
        path: 'salescommisson/edit/:_id', component: SalescommissionComponent,
        resolve: { resolvedata: ResolveService }, // resolve servuce for load data
        data: {
            requestcondition: {
                secret: "secret",
                token: "token",
                id: "_id",
                condition: {},
            },
            endpoint: "api/getonemanagecommission", // for single user
            api_url: environment.api_url, // base url call
        },
    },
    {
        path: 'list', component: ManagecommissionlistComponent,
        resolve: { resolvedata: ResolveService },
        data: {
            requestcondition: {
                condition: {
                    "limit": 10,
                    "skip": 0
                },
                sort: {
                    "type": "desc",
                    "field": "createdon_datetime"
                },
                searchcondition: {
                    type: "type",
                    parentid:"parentid"
                }
            },
            endpoint: 'api/listmanagecommission',
            api_url: environment.api_url,
        }
    },
    {
        path: 'tech/list', component: ManagecommissionlistComponent,
        resolve: { resolvedata: ResolveService },
        data: {
            requestcondition: {
                condition: {
                    "limit": 10,
                    "skip": 0
                },
                sort: {
                    "type": "desc",
                    "field": "createdon_datetime"
                },
                searchcondition: {
                    managecommissionid:"managecommissionid"
                }
            },
            endpoint: 'api10/get-distributor-commission-management-lists',
            api_url: environment.api_url,
        }
    },

];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class ManageCommissonRoutingModule { }

