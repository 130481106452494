import { Component, OnInit } from "@angular/core";
import { MatSnackBar } from "@angular/material";
import { ActivatedRoute, Router } from "@angular/router";
import { ApiService } from "src/app/services/api.service";
import { UserserviceService } from "src/app/services/userservice.service";

@Component({
  selector: "app-tmflowfiveyear-contract",
  templateUrl: "./tmflowfiveyear-contract.component.html",
  styleUrls: ["./tmflowfiveyear-contract.component.css"],
})
export class TmflowfiveyearContractComponent implements OnInit {
  public all_tmflow_data: any = {};
  constructor(
    public apiservice: ApiService,
    public activatedRoute: ActivatedRoute,
    public userservice: UserserviceService,
    public router: Router,
    private _snackBar: MatSnackBar
  ) {}

  ngOnInit() {
    this.activatedRoute.data.subscribe((res: any) => {
      if (this.activatedRoute.snapshot.queryParams.flag == "tmflowfive") {
        // console.log(res.resolvedata.result[0].contractdata);
        this.all_tmflow_data.PracticeName =
          res.resolvedata.result[0].contractdata.PracticeName;
        this.all_tmflow_data.Street =
          res.resolvedata.result[0].contractdata.Street;
        this.all_tmflow_data.City = res.resolvedata.result[0].contractdata.City;
        this.all_tmflow_data.State =
          res.resolvedata.result[0].contractdata.State;
        this.all_tmflow_data.Zip = res.resolvedata.result[0].contractdata.Zip;
        this.all_tmflow_data.Phone =
          res.resolvedata.result[0].contractdata.Phone;
        this.all_tmflow_data.auth_signatory =
          res.resolvedata.result[0].contractdata.auth_signatory;
        this.all_tmflow_data.eqipment_warrented =
          res.resolvedata.result[0].contractdata.eqipment_warrented;
        this.all_tmflow_data.serialno =
          res.resolvedata.result[0].contractdata.serialno;
        this.all_tmflow_data.date_originaly_purchesd =
          res.resolvedata.result[0].contractdata.date_originaly_purchesd;
        this.all_tmflow_data.effectivedate =
          res.resolvedata.result[0].contractdata.effectivedate;
        this.all_tmflow_data.hpsauthsignatory =
          res.resolvedata.result[0].contractdata.hpsauthsignatory;
        this.all_tmflow_data.printed_name1 =
          res.resolvedata.result[0].contractdata.printed_name1;
        this.all_tmflow_data.singdate =
          res.resolvedata.result[0].contractdata.singdate;
        this.all_tmflow_data.printed_name =
          res.resolvedata.result[0].contractdata.printed_name;
      } else if (this.activatedRoute.snapshot.queryParams.request == "true") {
        console.log(res.resolvedata.result[0].contractdata);
        this.all_tmflow_data.PracticeName =
          res.resolvedata.result[0].contractdata.PracticeName;
        this.all_tmflow_data.Street =
          res.resolvedata.result[0].contractdata.Street;
        this.all_tmflow_data.City = res.resolvedata.result[0].contractdata.City;
        this.all_tmflow_data.State =
          res.resolvedata.result[0].contractdata.State;
        this.all_tmflow_data.Zip = res.resolvedata.result[0].contractdata.Zip;
        this.all_tmflow_data.Phone =
          res.resolvedata.result[0].contractdata.Phone;
        this.all_tmflow_data.auth_signatory =
          res.resolvedata.result[0].contractdata.auth_signatory;
        this.all_tmflow_data.eqipment_warrented =
          res.resolvedata.result[0].contractdata.eqipment_warrented;
        this.all_tmflow_data.serialno =
          res.resolvedata.result[0].contractdata.serialno;
        this.all_tmflow_data.date_originaly_purchesd =
          res.resolvedata.result[0].contractdata.date_originaly_purchesd;
        this.all_tmflow_data.effectivedate =
          res.resolvedata.result[0].contractdata.effectivedate;
        this.all_tmflow_data.hpsauthsignatory =
          res.resolvedata.result[0].contractdata.hpsauthsignatory;
        this.all_tmflow_data.printed_name1 =
          res.resolvedata.result[0].contractdata.printed_name1;
        this.all_tmflow_data.singdate =
          res.resolvedata.result[0].contractdata.singdate;
        this.all_tmflow_data.printed_name =
          res.resolvedata.result[0].contractdata.printed_name;
      } else {
        if (res.resolvedata.results.userView.length > 0) {
          this.all_tmflow_data.PracticeName =
            res.resolvedata.results.userView[0].fullname;
          this.all_tmflow_data.Street =
            res.resolvedata.results.userView[0].street;
          this.all_tmflow_data.City = res.resolvedata.results.userView[0].city;
          this.all_tmflow_data.State =
            res.resolvedata.results.userView[0].state;
          this.all_tmflow_data.Zip = res.resolvedata.results.userView[0].zip;
          this.all_tmflow_data.Phone =
            res.resolvedata.results.userView[0].phone;
          this.all_tmflow_data.auth_signatory =
            res.resolvedata.results.userView[0].singeealldata[0].First_Name +
            "" +
            res.resolvedata.results.userView[0].singeealldata[0].Last_Name;
          this.all_tmflow_data.printed_name =
            res.resolvedata.results.userView[0].singeealldata[0].First_Name +
            "" +
            res.resolvedata.results.userView[0].singeealldata[0].Last_Name;
        }
      }
    });
  }

  canclebtn() {
    this.activatedRoute.queryParams.subscribe((data: any) => {
      // console.warn(data);
      if (
        typeof data.listing != "undefined" &&
        data.listing != null &&
        data.listing != ""
      ) {
        if (data.listing == "true") {
          if (this.userservice.type == "technological-consultant") {
            this.router.navigateByUrl(
              "/contract-manager/contract-request/list/warrenty/tech"
            );
          }
          if (this.userservice.type == "admin") {
            this.router.navigateByUrl(
              "/contract-manager/contract-request/list/warrenty"
            );
          }
        }
      } else {
        this.router.navigateByUrl(
          "/user-details/lead/" + this.activatedRoute.snapshot.params._id +'/contractflag1'
        );
      }
    });
    // this.router.navigateByUrl('/user-details/lead/' + this.activatedRoute.snapshot.params._id);
  }
  openSnackBar(message: string, action: string) {
    this._snackBar.open(message, action, {
      duration: 2000,
    });
  }
  submitbtn() {
    let data = {
      lead_id: this.activatedRoute.snapshot.params._id,
      tech_id: this.userservice.userid,
      product_id: "",
      contractdata: this.all_tmflow_data,
      contractflag: "warrenty",
      contracts: [
        {
          status: "request",
          added_by_id: this.userservice.userid,
          addedby: this.userservice.fullname,
          addedondatetime: Math.round(new Date().getTime()),
          type: this.userservice.type,
        },
      ],
      contact_id: this.activatedRoute.snapshot.params.sing_id,
    };
    console.log(data);
    this.apiservice
      .getDataWithoutToken(data, "api/update-new_contract")
      .subscribe((res: any) => {
        this.openSnackBar(res.successmsg, "ok");
        this.activatedRoute.queryParams.subscribe((data: any) => {
          // console.warn(data);
          if (
            typeof data.listing != "undefined" &&
            data.listing != null &&
            data.listing != ""
          ) {
            if (data.listing == "true") {
              if (this.userservice.type == "technological-consultant") {
                this.router.navigateByUrl(
                  "/contract-manager/contract-request/list/warrenty/tech"
                );
              }
              if (this.userservice.type == "admin") {
                this.router.navigateByUrl(
                  "/contract-manager/contract-request/list/warrenty"
                );
              }
            }
          } else {
            this.router.navigateByUrl(
              "/user-details/lead/" + this.activatedRoute.snapshot.params._id +'/contractflag1'
            );
          }
        });
        // this.router.navigateByUrl('/user-details/lead/' + this.activatedRoute.snapshot.params._id);
      });
  }
}
