import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { ApiService } from 'src/app/services/api.service';

@Component({
  selector: 'app-temporary-tech-signup-stop',
  templateUrl: './temporary-tech-signup-stop.component.html',
  styleUrls: ['./temporary-tech-signup-stop.component.css']
})
export class TemporaryTechSignupStopComponent implements OnInit {
  public name:any = '';
  public fromlogin:boolean = false;
  constructor(public cookie: CookieService, public route: ActivatedRoute, public router: Router, public apiservice: ApiService) { 
    
  }
  
  ngOnInit() {
    this.name = JSON.parse(this.cookie.get('firstname')) + ' ' + JSON.parse(this.cookie.get('lastname'));
    this.fromlogin = this.cookie.get('from_login') == 'true'?true:false;
    console.log(this.fromlogin);
  }

}
