import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'replace'
})
export class ReplacePipe implements PipeTransform {

  transform(value: any): any {

    let record = value.split('-').join(' '); // this is for '-' checking in sentence
    record = value.split('_').join(' '); // this is for '_' checking in sentence
    const sentence = record.toLowerCase().split(' '); // convert sentence into lower case and split with space
    let x = '';
    for (const key in sentence) {
      if (Object.prototype.hasOwnProperty.call(sentence, key)) {
        x += sentence[key][0].toUpperCase() + sentence[key].slice(1) + ' '; // convert first letter into upper case and concat the remaining letter ex. sentence = ['pece'], x = P + ece, x = Pece

      }
    }
    return x; // return the result
    // for (let i = 0; i < sentence.length; i++) {
    //   x += sentence[i][0].toUpperCase() + sentence[i].slice(1) + ' ';
    // }
  }

}
