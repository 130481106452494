import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { HttpClient } from "@angular/common/http";
import { ActivatedRoute, Router } from "@angular/router";
import { CookieService } from "ngx-cookie-service";
import { ApiService } from "src/app/services/api.service";
import { UserserviceService } from "src/app/services/userservice.service";
export interface DialogData {
  data: any
}
@Component({
  selector: 'app-commission-listing',
  templateUrl: './commission-listing.component.html',
  styleUrls: ['./commission-listing.component.css']
})
export class CommissionListingComponent implements OnInit {
  public commissiondata: any = [];
  public commissiondataskip: any = [
    '_id',
    'tech_id',
    'product_id',
    'distributor_id',
    'commission_type_s',
    'tech_name_s',
    'distributor_name_s',
    'product_name_s',
    'commission_type',
    'createdon_datetime',
    '__v',
    'is_payable',
    'payment_record',
    'is_payable_view'
  ];
  public modifyheaderarray: any = {
    product_name: "Product Name",
    price: "Selling Price",
    tech_name: "Tech Name",
    tech_commission_dollar: "Tech Commission",
    distributor_name: "Distributor Name",
    distributor_commission_dollar: 'Distributor Commission',
    commission_type_s: 'Commission Type',
    test_count: 'Number of Test',
    createdon_date: 'Sold On',
    start_date: 'Start Date',
    end_date: 'End Date',
    tech_payment_percentage: "Tech Payment %",
    distributor_payment_percentage: "Distributor Payment %",
  };
  public deleteendpoint: any = '';
  public updateendpoint: any = '';
  public tablename: any = '';
  public statusarray: any = [];
  public editroute: any = '';
  public commissiondatadetailsskiparray: any = [
    '_id',
    'tech_id',
    'product_id',
    'distributor_id',
    'tech_name_s',
    'distributor_name_s',
    'product_name_s',
    'commission_type',
    'createdon_datetime',
    'is_payable',
    'payment_record',
    'is_payable_view',
    'tech_commission_dollar',
    'distributor_commission_dollar'
  ];
  public searchsettings: any = {
    datesearch: [
      {
        startdatelabel: " Start Date",
        submit: "Search",
        field: "start_date",
      },
      {
        startdatelabel: " End Date",
        submit: "Search",
        field: "end_date",
      },
      {
        startdatelabel: "Sold On",
        submit: "Search",
        field: "createdon_date",
      },
    ], // this is use for  Autocomplete search
    // textsearch: [
    //   { label: "Search By Tech Name", field: "tech_name_s" },
    //   { label: "Search By Distributor Name", field: "distributor_name_s" },

    // ],
    search: [
      {
        label: "Search By Tech Name",
        field: "tech_name_s",
        values: "",
        serversearchdata: { endpoint: "api10/autocomplete_commissionadd_tech_name" },
      },
      {
        label: "Search By Distributor Name",
        field: "distributor_name_s",
        values: "",
        serversearchdata: { endpoint: "api10/autocomplete_commissionadd_distributor_name" },
      },
    ],
    selectsearch: [
      {
        label: "Search By Product",
        field: "product_name",
        values: [],
      },
      {
        label: "Search By Commission Type",
        field: "commission_type",
        values: [
          { val: "sales_commission", name: "Sales And Gross/Net" },
          { val: "event_commission", name: "Event" },
        ],
      },
    ],
  };
  public searchendpoint: any = '';
  public sortdata: any = {
    type: "desc",
    field: "_id",
    options: [
      'product_name',
      // 'price',
      // 'test_count',
      'commission_type_s',
      'tech_name',
      'tech_commission_dollar',
      'distributor_name',
      'distributor_commission_dollar',
      'createdon_date',
      'start_date',
      'end_date',
    ],
  };
  public datacollection: any = 'api10/get-commission-list';
  public datesearchsourcecount: any = 0;
  public total_tech_commission: any = 0;
  public total_distributor_commission: any = 0;
  public subtotal_tech_commission: any = 0;
  public subtotal_distributor_commission: any = 0;
  public total_sales_commission: any = 0;
  public total_event_commission: any = 0;
  public subtotal_sales_commission: any = 0;
  public subtotal_event_commission: any = 0;
  public libdata: any = {
    preview_header: {
      header: "Preview Data for Details",
      class: 'preheadercls'
  },
    footersettings: [
      { key: 'f0', data: `<span><strong>SubTotal Tech Commission:</strong> $${this.subtotal_tech_commission}</span> <span><strong>Total Tech Commission:</strong> $${this.total_tech_commission}</span>`, colspan: 3 },
      { key: 'f4', data: `<span><strong>SubTotal Distributor Commission:</strong> $${this.subtotal_distributor_commission}</span><span><strong>Total Distributor Commission:</strong> $${this.total_distributor_commission}</span>`, colspan: 3 },
      { key: 'f6', data: `<div><span><strong>SubTotal Number of Sales And Gross/Net Commission:</strong> ${this.subtotal_sales_commission}</span><span><strong>Total Number of Sales And Gross/Net Commission:</strong> ${this.total_sales_commission}</span></div>`, colspan: 2 },
      { key: 'f8', data: `<div><span><strong>SubTotal Number of Event Commission:</strong> ${this.subtotal_sales_commission}</span><span><strong>Total Number of Event Commission:</strong> ${this.total_event_commission}</span></div>`, colspan: 2 },
    ],
    basecondition: {
    },
    detailview_override: [
      { key: "product_name", val: "Product Name" },
      { key: "price", val: "Selling Price" },
      { key: "test_count", val: "Number of Test" },
      { key: "commission_type_s", val: "Commission Type" },
      { key: "tech_name", val: "Tech Name" },
      { key: "tech_commission", val: "Tech Commission" },
      { key: "createdon_date", val: "Sold On" },
      { key: "start_date", val: "Start Date" },
      { key: "end_date", val: "End Date" },
      { key: "tech_paid", val: "Commission Paid to Tech" },
      { key: "remaining_tech_paid", val: "Remaining Commission for Tech" },
      { key: "distributor_name", val: "Distributor Name" },
      { key: "distributor_commission", val: "Distributor Commission" },
      { key: "distributor_paid", val: "Commission Paid to Distributor" },
      { key: "remaining_distributor_paid", val: "Remaining Commission for Distributor" },
      { key: "tech_payment_percentage", val: "Tech Payment %" },
      { key: "distributor_payment_percentage", val: "Distributor Payment %" },
    ],
    tableheaders: [
      'product_name',
      // 'price',
      // 'test_count',
      'commission_type_s',
      'tech_name',
      'tech_commission_dollar',
      'distributor_name',
      'distributor_commission_dollar',
      // 'createdon_date',
      // 'start_date',
      // 'end_date',
      'tech_payment_percentage',
      'distributor_payment_percentage'
    ],
    custombuttons: [
      {
        label: "Payment History",
        type: "listner",
        id: "view_pay_commission",
        classname: "Payment_History",
        tooltip: "Payment History",
        cond: "is_payable_view",
        condval: 1,
      },
    ],
    // hidestatustogglebutton: true,
    hidedeletebutton: true,
    updateendpointmany: "", // update multiple user
    updateendpoint: "", // update status
    deleteendpointmany: "", // delete multiple user
    hidemultipleselectbutton: true, // hide multiple selection button (false)
    hidecounter: null, // hide counter (false)
    hideaction: false, // hide action (false)
    hideviewbutton: false, // (hide view button)
    hideeditbutton: true, // all these button options are optional not mandatory
    hidestatustogglebutton: true,
  }
  public limitcond: any = {
    limit: 10,
    skip: 0,
    pagecount: 1,
  };
  public user_type: any = '';
  public loading: boolean = false;
  constructor(
    public dialog: MatDialog,
    public apiservice: ApiService,
    public http: HttpClient,
    public activatedRoute: ActivatedRoute,
    public cookies: CookieService,
    public router: Router,
    public userService: UserserviceService
  ) {
    this.user_type = JSON.parse(this.cookies.get('type'));
    if (this.user_type == 'admin') { //this is to add custombutton in listing for admin
      this.libdata.custombuttons.push(
        {
          label: "Pay Commission",
          type: "listner",
          id: "pay_commission",
          classname: "Pay_Commission",
          tooltip: "Pay Commission",
          cond: "is_payable",
          condval: 1,
        },
      )
    }
    if (JSON.parse(this.cookies.get('type')) == 'technological-consultant') { //this is for tech commission list modification
      this.libdata.basecondition['tech_id'] = JSON.parse(this.cookies.get('userid'));
      this.libdata.basecondition['type'] = JSON.parse(this.cookies.get('type'));
      this.libdata.tableheaders = [
        'product_name',
        // 'price',
        // 'test_count',
        'commission_type_s',
        'tech_commission_dollar',
        'tech_payment_percentage',
        'createdon_date',
        'start_date',
        'end_date',
      ];
      this.commissiondatadetailsskiparray.push('tech_name');
      this.commissiondatadetailsskiparray.push('distributor_name');
      this.commissiondatadetailsskiparray.push('distributor_commission');
      this.searchsettings.search = [];
    }
    if (JSON.parse(this.cookies.get('type')) == 'distributor') { //this is for distributor commission list modification
      this.libdata.basecondition['distributor_id'] = JSON.parse(this.cookies.get('userid'));
      this.libdata.basecondition['type'] = JSON.parse(this.cookies.get('type'));
      this.libdata.tableheaders = [
        'product_name',
        // 'price',
        // 'test_count',
        'commission_type_s',
        'tech_name',
        'tech_commission_dollar',
        'distributor_commission_dollar',
        // 'createdon_date',
        // 'start_date',
        // 'end_date',
        'tech_payment_percentage',
        'distributor_payment_percentage'
      ];
      this.commissiondatadetailsskiparray.push('distributor_name');
      this.searchsettings.search = [
        {
          label: "Search By Tech Name",
          field: "tech_name_s",
          values: "",
          serversearchdata: { endpoint: "api10/autocomplete_commissionadd_tech_name" },
        },
      ];
    }
    this.activatedRoute.data.forEach((response: any) => {
      // this.loading = true;
      console.log("response of commission list==>",response);
      this.libdata.footersettings = [
        { key: 'f0', data: `<span><strong>SubTotal Tech Commission:</strong> $${(response.resolvedata.total_details.subtotal_tech_commission).toFixed(2)}</span> <span><strong>Total Tech Commission:</strong> ${(response.resolvedata.total_details.total_tech_commission).toFixed(2)}</span>`, colspan: 3 },
        { key: 'f4', data: `<span><strong>SubTotal Distributor Commission:</strong> $${(response.resolvedata.total_details.subtotal_distributor_commission).toFixed(2)}</span><span><strong>Total Distributor Commission:</strong> $${(response.resolvedata.total_details.total_distributor_commission).toFixed(2)}</span>`, colspan: 3 },
        { key: 'f6', data: `<div><span><strong>SubTotal Number of Sales And Gross/Net Commission:</strong> ${response.resolvedata.total_details.subtotal_sales_commission}</span><span><strong>Total Number of Sales And Gross/Net Commission:</strong> ${response.resolvedata.total_details.total_sales_commission}</span></div>`, colspan: 2 },
        { key: 'f8', data: `<div><span><strong>SubTotal Number of Event Commission:</strong> ${response.resolvedata.total_details.subtotal_event_commission}</span><span><strong>Total Number of Event Commission:</strong> ${response.resolvedata.total_details.total_event_commission}</span></div>`, colspan: 2 },
      ]
      if (JSON.parse(cookies.get('type')) != 'admin') { //this block id for tech and distributor for the footer colspan settings
        this.libdata.footersettings[0].colspan = 3;
        this.libdata.footersettings[1].colspan = 3;
        this.libdata.footersettings[2].colspan = 2;
        this.libdata.footersettings[3].colspan = 1;
      }
      console.log(this.libdata);
      this.commissiondata = response.resolvedata.results.res;
      // this.loading = false;
    });
    let data = {
      searchcondition: {},
      secret: this.userService.secret,
      token: this.userService.jwttoken
    }
    if (JSON.parse(this.cookies.get('type')) == 'technological-consultant') { //this is for tech commission list count endpoint modification
      data.searchcondition = { 'tech_id': JSON.parse(this.cookies.get('userid')) };
    }
    if (JSON.parse(this.cookies.get('type')) == 'distributor') { //this is for distributor commission list modification
      data.searchcondition = { 'distributor_id': JSON.parse(this.cookies.get('userid')) };
    }
    this.apiservice.getDataWithoutToken(data, 'api10/get-commission-list-count').subscribe((data: any) => {
      this.datesearchsourcecount = data.count;
    })
    if (JSON.parse(cookies.get('type')) != 'admin') {
      let send = {
        searchcondition: {
        }
      }
      if (JSON.parse(this.cookies.get('type')) == 'technological-consultant') { //this is for tech commission list count endpoint modification
        send.searchcondition = { 'parentid': JSON.parse(this.cookies.get('parentid')) };
      }
      if (JSON.parse(this.cookies.get('type')) == 'distributor') { //this is for distributor commission list modification
        send.searchcondition = { '_id': JSON.parse(this.cookies.get('userid')) };
      }
      this.apiservice.getDataWithoutToken(send, "api10/commission-product-search").subscribe((data: any) => {
        for (const key in data.res) {
          this.searchsettings.selectsearch[0].values.push({ val: data.res[key].productname, name: data.res[key].productname })
        }
      })
    }
    else {
      this.searchsettings.selectsearch[0].values = [
        { val: "TM-FLOW ANS MEDICAL DEVICE", name: "TM-FLOW ANS MEDICAL DEVICE" },
        { val: "PECE ", name: "PECE " },
        { val: "BioEnergetics", name: "BioEnergetics" },
        { val: "RST Sanexas", name: "RST Sanexas" },
      ]
    }
  }

  ngOnInit() {
  }
  listenLiblistingChange(val: any) {
    console.log(val);
    if (typeof (val.action) != 'undefined' && val.action != null && val.action == "paging") { //dynamically update the total details when the pagination is performed
      console.log(val.totalresults.total_details);
      this.libdata.footersettings[0].data = `<span><strong>SubTotal Tech Commission:</strong> $${(val.totalresults.total_details.subtotal_tech_commission).toFixed(2)}</span> <span><strong>Total Tech Commission:</strong> $${(val.totalresults.total_details.total_tech_commission).toFixed(2)}</span>`
      this.libdata.footersettings[1].data = `<span><strong>SubTotal Distributor Commission:</strong> $${(val.totalresults.total_details.subtotal_distributor_commission).toFixed(2)}</span><span><strong>Total Distributor Commission:</strong> $${(val.totalresults.total_details.total_distributor_commission).toFixed(2)}</span>`
      this.libdata.footersettings[2].data = `<div><span><strong>SubTotal Number of Sales And Gross/Net Commission:</strong> ${val.totalresults.total_details.subtotal_sales_commission}</span><span><strong>Total Number of Sales And Gross/Net Commission:</strong> ${val.totalresults.total_details.total_sales_commission}</span></div>`
      this.libdata.footersettings[3].data = `<div><span><strong>SubTotal Number of Event Commission:</strong> ${val.totalresults.total_details.subtotal_event_commission}</span><span><strong>Total Number of Event Commission:</strong> ${val.totalresults.total_details.total_event_commission}</span></div>`
    }
    if (typeof (val.action) != 'undefined' && val.action != null && val.action == "search") { //dynamically update the total details when the pagination is performed
      console.log(val.totalresults.total_details);
      this.libdata.footersettings[0].data = `<span><strong>SubTotal Tech Commission:</strong> $${(val.totalresults.total_details.subtotal_tech_commission).toFixed(2)}</span> <span><strong>Total Tech Commission:</strong> $${(val.totalresults.total_details.total_tech_commission).toFixed(2)}</span>`
      this.libdata.footersettings[1].data = `<span><strong>SubTotal Distributor Commission:</strong> $${(val.totalresults.total_details.subtotal_distributor_commission).toFixed(2)}</span><span><strong>Total Distributor Commission:</strong> $${(val.totalresults.total_details.total_distributor_commission).toFixed(2)}</span>`
      this.libdata.footersettings[2].data = `<div><span><strong>SubTotal Number of Sales And Gross/Net Commission:</strong> ${val.totalresults.total_details.subtotal_sales_commission}</span><span><strong>Total Number of Sales And Gross/Net Commission:</strong> ${val.totalresults.total_details.total_sales_commission}</span></div>`
      this.libdata.footersettings[3].data = `<div><span><strong>SubTotal Number of Event Commission:</strong> ${val.totalresults.total_details.subtotal_event_commission}</span><span><strong>Total Number of Event Commission:</strong> ${val.totalresults.total_details.total_event_commission}</span></div>`
    }
    if (typeof (val.custombuttonclick) != 'undefined' && val.custombuttonclick != null && val.custombuttonclick != '') {
      if (val.custombuttonclick.btninfo.id == "pay_commission") {
        const dialogRef = this.dialog.open(PayCommissionTypeModal, {
          panelClass: "AddUserComponent",
          data: { id: val.custombuttonclick.data._id }
        });

        dialogRef.afterClosed().subscribe((payment_type) => {

          if (payment_type == 'partial-payment') {
            this.router.navigateByUrl('commission/payment/partial/' + val.custombuttonclick.data._id);
          }
          if (payment_type == 'full-payment') {
            this.router.navigateByUrl('commission/payment/full/' + val.custombuttonclick.data._id);
          }
        });
      }
      if (val.custombuttonclick.btninfo.id == "view_pay_commission") {
        const dialogRef = this.dialog.open(ViewPaymentRecord, {
          panelClass: "AddUserComponent",
          data: { data: val.custombuttonclick.data }
        });

        dialogRef.afterClosed().subscribe(() => {
        });
      }
    }
  }
  openCommissionType(): void {
    const dialogRef = this.dialog.open(CommissionTypeModal, {
      panelClass: "AddUserComponent",
      data: ""
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed');
      if (result == 'sales-commission') {
        console.log(result, "1111");
        this.router.navigateByUrl('commission/sales/add');
      }
      if (result == 'event-commission') {
        console.log(result, "2222");
        this.router.navigateByUrl('commission/event/add');
      }
    });
  }

}
@Component({
  selector: 'commission-type-modal',
  templateUrl: 'commission-type-modal.html',
})
export class CommissionTypeModal {

  constructor(
    public dialogRef: MatDialogRef<CommissionTypeModal>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData) { }
  commission_func(val: any) {
    this.dialogRef.close(val);
  }
  onNoClick(): void {
    this.dialogRef.close();
  }

}

@Component({
  selector: 'pay-commission-type-modal',
  templateUrl: 'pay-commission-type-modal.html',
})
export class PayCommissionTypeModal {

  constructor(
    public dialogRef: MatDialogRef<PayCommissionTypeModal>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData) { }
  pay_commission_func(val: any) {
    this.dialogRef.close(val);
  }
  onNoClick(): void {
    this.dialogRef.close();
  }

}

@Component({
  selector: 'view-payment-record',
  templateUrl: 'view-payment-record.html',
})
export class ViewPaymentRecord {
  public view: any = {};
  public user_type: any = '';
  constructor(
    public dialogRef: MatDialogRef<ViewPaymentRecord>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData, public cookies: CookieService,) {
    this.view = data.data;
    console.log(this.view);
    this.user_type = JSON.parse(this.cookies.get('type'));
  }
  onNoClick(): void {
    this.dialogRef.close();
  }

}