import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filterArray'
})
export class FilterArrayPipe implements PipeTransform {

  transform(value: any, args: any): any {
    let data = value.filter((itemInArray) => itemInArray.contractflag == args);
    return data[0].contracts[0];
  }
}
