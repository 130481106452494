import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { ApiService } from 'src/app/services/api.service';
import { UserserviceService } from 'src/app/services/userservice.service';

@Component({
  selector: 'app-eventcommission',
  templateUrl: './eventcommission.component.html',
  styleUrls: ['./eventcommission.component.css']
})
export class EventcommissionComponent implements OnInit {
  public formdata: any = {};
  public status: any = [
    { val: 1, name: "Active" },
    { val: 0, name: "Inactive" },
  ];
  public count: number = 0;
  // public addrolefiels: any = [
  //   { val: 'distributor', name: "Distributor" },
  //   { val: 'admin', name: "Admin" },
  //   { val: 'technological-consultant', name: "Associate Director" },
  //   { val: 'closer', name: "Closer" },
  // ];
  public choiceconditionfields: any = [
    { val: 'greaterthaneq', name: ">=" },
    { val: 'lessthan', name: "<" },
  ];
  public formfieldrefresh: any = true;
  public editdata: any;
  public product: any = [];

  constructor(
    public apiservice: ApiService,
    public http: HttpClient,
    public activatedRoute: ActivatedRoute,
    public cookies: CookieService,
    public router: Router,
    public userservice: UserserviceService
  ) {

    this.getproductlist();
    this.formdata = {
      successmessage: "Added Successfully !!",
      redirectpath: 'managecommisson/list',
      submitactive: true, // optional, default true
      submittext: "Submit",
      resettext: "Reset",
      canceltext: "Cancel",
      apiUrl: this.apiservice.api_url,
      endpoint: "api/addmanagecommission",
      jwttoken: this.cookies.get("jwtToken"),
      cancelroute: 'managecommisson/list',

      fields: [
        {
          label: "Add Roll",
          name: "addrole", //
          value: "distributor",
          // val: this.addrolefiels,
          type: "text",
          validations: [
            { rule: "required", message: "Add Roll is Required" },
          ],
        },
        {
          heading: "",
          label: "Choice Condition",
          name: "choicecondition",
          value: "",
          val: this.choiceconditionfields,
          type: "select",
          validations: [{ rule: "required", message: "Choice Condition is Required" }],
        },
        {
          label: "Product",
          name: "product",
          value: "",
          val: this.product,
          type: "select",
          validations: [{ rule: "required", message: "Product is Required" }],
        },
        {
          label: "Range form",
          name: "rangefrm",
          value: "",
          type: "number",
          validations: [
            { rule: "required", message: "Value is Required" },

          ],
        },
        {
          label: "Range to",
          name: "rangeto",
          value: "",
          type: "number",
          validations: [
            { rule: "required", message: "Value is Required" },

          ],
        },
        {
          label: "Ammount",
          name: "ammount",
          value: "",
          type: "number",
          validations: [
            { rule: "required", message: "Ammount is Required" },

          ],
        },
        {
          label: "Commission calcullate",
          name: "commissioncalcullate",
          value: '',
          type: "hidden",
        },

        {
          label: "Type",
          name: "type",
          value: 'event commission',
          type: "hidden",
        },
      ],
    };

  }


  getproductlist() {
    this.apiservice
      .getDataWithoutToken(
        {
          token: this.userservice.jwttoken,
          secret: this.userservice.secret,
        },
        "api/productsearch"
      )
      .subscribe((response: any) => {
        // this.productVal=response.res
        // // console.log(this.productVal,'madhumitaaaaa')
        for (const i in response.res) {
          if (Object.prototype.hasOwnProperty.call(response.res, i)) {
            this.product.push({
              val: response.res[i].val,
              name: response.res[i].name,
            });
          }
        }
      });
  }
  public formfieldrefreshdata: any;

  listenFormFieldChange(event) {

    console.log(event, '++++--------++');
    this.count = event.fromval.value - event.fromval.payableprice;
    if (this.count != null && typeof (this.count) != "undefined" && this.count != 0 && event.field.label == 'Payable Price') {
      // console.log(this.formdata.fields[4].value, '+_______________________')
      // console.log(this.count, '++++++', typeof (this.count.toString()));
      this.formdata.fields[4].type = "number";
      this.formdata.fields[4].value = this.count;
      this.formdata.fields[4].validations = [{ rule: "required", message: "Commission calcullate is Required" }];
      console.log(this.formdata.fields[4].value, '+___________+++____________')
      this.formfieldrefreshdata = { field: 'commissioncalcullate', value: this.count, type: "number" };

    }

    // console.log(event);

  }
  ngOnInit() {
  }

}
