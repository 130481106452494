import { HttpClient } from '@angular/common/http';
import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { ActivatedRoute, Router } from '@angular/router';
// import { DialogData } from 'login-lib-influxiq/lib/sign-up/sign-up.component';
import { CookieService } from 'ngx-cookie-service';
import { ApiService } from 'src/app/services/api.service';
import { UserserviceService } from 'src/app/services/userservice.service';

export interface DialogData {
  val: any;

}
@Component({
  selector: 'app-resource-list',
  templateUrl: './resource-list.component.html',
  styleUrls: ['./resource-list.component.css']
})
export class ResourceListComponent implements OnInit {
  productlisttable: any = [];

  public status: any = [
    { val: 1, name: 'Active' },
    { val: 0, name: 'Inactive' },
  ];

  // use for status search
  statusarray: any = [
    { val: 1, name: 'Active' },
    { val: 0, name: 'Inactive' },
  ];

  // editrouting
  editroute: any = 'resources/edit';

  // modify header(key : modify header name)
  modifyheaderarray: any = {
   
    resourcename: 'Resources Name',
    productname:'Product Name',
    resourcecategorsname:'Resource Category Name',
    description: 'Description',
    priority: 'Priority',
    // launched: 'Launched',
    // verification: 'Verification Needed',
    status: 'Status',
  };

  // use for table field skip
  productlisttableskip: any = [];

  // use for Table Detail Field Skip
  productlisttabledetailskip: any = [];

  // updateendpoint is use for data update endpoint
  updateendpoint = 'api/resourceupdate';

  // deleteendpoint is use for data delete endpoint
  deleteendpoint = 'api/deleteresource';

  // this is a database collection name
  tablename = 'users';

  // searchendpoint is use for data search endpoint
  searchendpoint = '';

  // use for click to another page routing path
  clicktoaddananotherpage = '';

  // date_search_endpoint is use for date search endpoint
  datesearchendpoint: any = '';

  // send basic limit data
  limitcond: any = {
    limit: 10,
    skip: 0,
  };

  // other data
  libdata: any = {
    // basecondition: { type: '' },
    detailview_override: [
      { key: 'resourcename', val: 'Resource Name' },
      { key: 'description', val: 'Description' },
      { key: 'priority', val: 'Priority' },
      { key: 'status', val: 'Status' },
    ],
    tableheaders: [
      
      'resourcename',
      'productname',
      'resourcecategorsname',
      'description',
      'priority',
      'status',
    ],
    // searchBarFlagVal:true,
    // tableheaders name [key]
    // optional
    updateendpointmany: 'api/resourceupdate',
    updateendpoint: 'api/resourcestatusupdate',
    deleteendpointmany: 'api/deleteresourcey',
    hidemultipleselectbutton: null,
    hidecounter: null,
    hideaction: null,
    hidestatustogglebutton: false,                  // (hide status toggle button)
    hideviewbutton: true,    
                          // (hide view button)
    // actioncolname: 'Actn',
    custombuttons: [
      {
        label: 'Resource Preview',
        type: 'listner',
        id: 'li',
        name: 'resourcepreview',
        tooltip: 'Resource Preview',
        classname:'ResourcePreview'
      },
    ]
  };

  // send basic sort data
  sortdata: any = {
    type: 'desc',
    field: 'priority',
    options: [
      'status',
      'resourcename',
      'resourcecategorsname',
      'priority',
      'productname'
    ],
  };

  // this is a database collection or view name
  // datesearchsource: any = 'admin_blog_list';
  // datacollection
  datacollection: any = 'api/resourcelistview';
  // source count
  datesearchsourcecount: any = 0;

  searchsettings: any = {
    search: [
      {
        label: 'Search By Resources Name', field: 'resources_s',
        values: '',
        serversearchdata: { endpoint: 'api/resourcesautocomplete' }
      },
      {
        label: 'Search By Products', field: 'products_s',
        values: '',
        serversearchdata: { endpoint: 'api/productautocomplete' }
      },
      {
        label: 'Search By Resource Category Name', field: 'resourcescat_s',
        values: '',
        serversearchdata: { endpoint: 'api/resourcescatautocomplete' }
      },
    ],
    selectsearch: [
      { label: 'Search By Status', field: 'status', values: this.status },
    ],
    textsearch: [
      
    ],
  };

  public userid: any;
  public userFullName: any;
  constructor(
    public router: Router,
    public Apiservice: ApiService,
    public http: HttpClient,
    public cookieservice: CookieService,
    public activatedRoute: ActivatedRoute,
    public userservice: UserserviceService, public dialog: MatDialog
  ) {
    this.userid = this.userservice.userid;
    this.userFullName =
      this.userservice.firstname +
      ' ' +
      this.userservice.lastname; // user dtls ( cookieservice)
    const endpointc = 'api/resourcelistview-count';
    const endpoint = 'api/resourcelistview';
    const data: any = { // How many data showing in first page
      secret: this.userservice.secret,
      token: this.userservice.jwttoken,
      source: '',
      condition: {  // condition for how much data show in one time
        limit: 10,
        skip: 0,
      },
      sort: {
        type: 'desc',  // data sort by decreasing order
        field: 'priority',  // data sort by first name
      },
    };

    this.Apiservice
      .getDataWithoutToken(data, endpointc)
      .subscribe((res: any) => {
        this.datesearchsourcecount = res.count; // sarch count  (How many data there)
      });
  }
  ngOnInit() {
    this.activatedRoute.data.forEach((res: any) => {
      this.productlisttable = res.resolvedata.results.res; // data store from backend
      // console.log(this.productlisttable, 'madhumita khutia');
    });
  }
  listenLiblistingChange(val: any) {
    // console.log('rrr', val);
    if (val.action === 'custombuttonclick' && val.custombuttonclick.btninfo.name === 'resourcepreview') {

      const dialogRef = this.dialog.open(ImageModalComponent, {
        width: '700px',
        height: '500px',       
        data: { val: val.custombuttonclick.data.image_array },
      });
      dialogRef.afterClosed().subscribe(result => {

      });

    }
  }
}

@Component({
  selector: 'image',
  templateUrl: './image.html'
})

export class ImageModalComponent {

  constructor(public dialogRef: MatDialogRef<ImageModalComponent>, @Inject(MAT_DIALOG_DATA) public data: DialogData, public apiservice: ApiService, public activatedroute: ActivatedRoute) {
    // console.log(data.val.converted_array, 'gfdfgdfgdghfghf');
  }

  ngOnInit() {

  }
  onNoClick(): void {
    this.dialogRef.close();
  }

}
