import { HttpClient } from "@angular/common/http";
import { Component, OnInit, Inject } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { CookieService } from "ngx-cookie-service";
import { ApiService } from 'src/app/services/api.service';

import { UserserviceService } from 'src/app/services/userservice.service';

import { MatBottomSheet, MatDialog, MatDialogRef, MatSnackBar, MAT_DIALOG_DATA } from '@angular/material';


export interface DialogData {
  data: any;
  val: any;
  flag: any
}
@Component({ 
  selector: 'app-faqlisting',
  templateUrl: './faqlisting.component.html',
  styleUrls: ['./faqlisting.component.css']
})
 
export class FaqlistingComponent implements OnInit {
  faqlisttable: any = [];

  public status: any = [
    { val: 1, name: 'Active' },
    { val: 0, name: 'Inactive' },
  ];
  public launched: any = [
    { val: true, name: 'Yes' },
    { val: null, name: 'No' },
  ];

  // use for status search
  statusarray: any = [
    { val: 1, name: 'Active' },
    { val: 0, name: 'Inactive' },
  ];

  // editrouting
  editroute: any = '/admin/faq/edit/';

  // modify header(key : modify header name)
  modifyheaderarray: any = {
 
    question: 'Question',
    answer: 'Answer',
    categoryname: 'Category',
  
    Youtubeid:'Youtube ID',
    status: 'Status',
    createdon_datetime: 'Added on',
    priority: 'Priority',

    user_role_s: 'User Role',

   
  };

  // use for table field skip
  faqlisttableskip: any = [];

  // use for Table Detail Field Skip
  faqlisttabledetailskip: any = ['_id','faqname_s', 'status', 'question_s', 'categoryname_s', 'priority', 'createdon_datetime', 'answer', 'questioncategory_s','questioncategory','user_role'];

  // updateendpoint is use for data update endpoint
  updateendpoint = 'api/addfaq';

  // deleteendpoint is use for data delete endpoint
  deleteendpoint = 'api/deletefaq';

  // this is a database collection name
  tablename = '';

  // searchendpoint is use for data search endpoint
  searchendpoint = '';

  // use for click to another page routing path
  clicktoaddananotherpage = '';

  // date_search_endpoint is use for date search endpoint
  datesearchendpoint: any = '';

  // send basic limit data
  limitcond: any = {
    limit: 10,
    skip: 0,
  };

  // other data
  libdata: any = {
    preview_header: {
      header: "Preview Data for Details",
      class: 'preheadercls'
  },
    // basecondition: { type: '' },
    detailview_override: [
      { key: 'question', val: 'Question' },
      { key: 'answer', val: 'Answer' },
      { key: 'categoryname', val: 'Category' },

      { key: 'user_role_s', val: 'User Role' },

      { key: 'Youtubeid', val: 'Youtube ID' },
      { key: 'status', val: 'Status' },
      { key: 'createdon_datetime', val: 'Added on' },
      { key: 'priority', val: 'Priority' },
    ],
    tableheaders: [
      'question',
      // 'answer',
      'categoryname',
      'Youtubeid',
      'status',
      'createdon_datetime',
      'priority',
      'user_role_s'
     
    ],
    // searchBarFlagVal:true,
    // tableheaders name [key]
    // optional
    updateendpointmany: 'api/faqstatusupdate',
    updateendpoint: 'api/faqstatusupdate',
    deleteendpointmany: 'api/deletefaq',
    hidemultipleselectbutton: null,
    hidecounter: null,
    hideaction: null,
    hidestatustogglebutton: false,                  // (hide status toggle button)
    hideviewbutton: false,                          // (hide view button)
    // actioncolname: 'Actn',

    custombuttons: [

      {
        label: "answer",
        type: "listner",
        tooltip: "Answer ",
        classname: "answer ",
        id: "i1", 
      },

  
    ],

  };

  // send basic sort data
  sortdata: any = {
    type: 'desc',
    field: 'createdon_datetime',
    options: [ 
      'status',
      'createdon_datetime',
      'priority',
      'user_role',
      'Youtubeid',
      
    ],
  };
  
  // this is a database collection or view name
  // datesearchsource: any = 'admin_blog_list';
  // datacollection
  datacollection: any = 'api/faqlistview';
  // source count
  datesearchsourcecount: any = 0;

  searchsettings: any = {


    datesearch: [
      {
        startdatelabel: 'Added on start Date',
        enddatelabel: 'Added On End Date',
        submit: 'Search',
        field: 'createdon_datetime',
      },
    ],
    selectsearch: [
      { label: 'Search By Status', field: 'status', values: this.status },
    ],
    textsearch: [
      { label: 'Search By question', field: 'question_s' },
      // { label:'Search By Email', field:'verifiedemail'}
    ], 
  };

  public userid: any;
  public userFullName: any;
  constructor(
    public router: Router,
    public Apiservice: ApiService,
    public http: HttpClient,
    public cookieservice: CookieService,
    public activatedRoute: ActivatedRoute,
    public userservice: UserserviceService,
    public dialog: MatDialog,
    public bottomSheet: MatBottomSheet,
    private _snackBar: MatSnackBar,

  ) { 
    this.userid = this.userservice.userid;
    this.userFullName =
      this.userservice.firstname +
      ' ' +
      this.userservice.lastname; // user dtls ( cookieservice)
    const endpointc = 'api/faqlistview-count';
    const endpoint = 'api/faqlistview';
    const data: any = { // How many data showing in first page
      secret: this.userservice.secret,
      token: this.userservice.jwttoken,
      source: '',
      condition: {  // condition for how much data show in one time
        limit: 10,
        skip: 0,
      },
      sort: {
        type: 'desc',  // data sort by decreasing order
        field: '_id',  // data sort by first name
      },
    };

    this.Apiservice
      .getDataWithoutToken(data, endpointc)
      .subscribe((res: any) => {
        this.datesearchsourcecount = res.count; // sarch count  (How many data there)
      });
  }

  ngOnInit() {
    this.activatedRoute.data.forEach((res: any) => {
      this.faqlisttable = res.resolvedata.results.res; // data store from backend
       
    });
  }

  listenLiblistingChange(e: any) {
    console.log("e==>",e);
    if (e.custombuttonclick.btninfo.label == 'answer') {
      const dialogRef = this.dialog.open(Answer, {
        panelClass: "answerBoxComponent",

        data: { val: e.custombuttonclick.data, flag: 'sales' },
      });
      dialogRef.afterClosed().subscribe((result: any) => {

      })
    }
    
  }



}


@Component({
  selector: "answer",
  templateUrl: "answer.html",
})
export class Answer { 
  public answer: any;
  constructor(
    public dialogRef: MatDialogRef<Answer>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    
    public apiservice: ApiService,
    public activatedroute: ActivatedRoute,
    public userservice: UserserviceService
  ) {
    // console.log(data, 'aaaaaaaaaaaaaaaa'); 

    this.answer = data.val.answer;
  }

  onNoClick(val): void {
    if (val == 'No') {
      this.dialogRef.close();
    }
  }
}
