import { Component, Inject, OnInit } from "@angular/core";
import { MatSnackBar } from "@angular/material";
import { ActivatedRoute, Router } from "@angular/router";
import { ApiService } from "src/app/services/api.service";
import { UserserviceService } from "src/app/services/userservice.service";
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
export interface DialogData {
  data: any
}
@Component({
  selector: "app-credit",
  templateUrl: "./credit.component.html",
  styleUrls: ["./credit.component.css"],
})
export class CreditComponent implements OnInit {
  public creditdata: any = {
    federeltax: "",
  };
  public singeedata: any;
  public status_flag: any;
  public submitflag: any;
  public submitbankflag: any;
  public errdatat: any;
  public submitinfoflags: any;
  constructor(
    public apiservice: ApiService,
    public activatedRoute: ActivatedRoute,
    public userservice: UserserviceService,
    public router: Router,
    private _snackBar: MatSnackBar,
    public dialog: MatDialog
  ) {
    this.creditdata = {};
    this.errdatat = {
      compane_name: "A",
      license: "B",
      address: "K",
      city: "C",
      state: "S",
      zip: "Z",
      signer: "s",
      title: "t",
      phone: "p",
      yearof_busniss: "y",
      phonetwo: "j",
      website: "w",
      email: "ew",
      preson_name: "q",
      Security: "ss",
      Ownership: "ss",
      Ownrent: "ll",
      homeaddress: "ff",
      city_two: "ee",
      state_two: "fss",
      home_phone: "hp",
      zip_two: "hp",
      home_phone1: "hp1",
      zip_two1: "zp1",
      state_two1: "st1",
      city_two1: "ct1",
      homeaddress1: "ha1",
      Ownrent1: "or1",
      Ownership1: "os1",
      Security2: "s2",
      preson_name1: "pn1",
      business: "bs",
      bank_name: "bn",
      bank_phone: "bp",
      check_account: "ca",
      contract_name: "cn",
      phone3: "p3",
    };
  }

  ngOnInit() {
    if (this.activatedRoute.snapshot.params._id != null) {
      this.activatedRoute.data.subscribe((res: any) => {
        if (
          this.activatedRoute.snapshot.queryParams.request == "true" ||
          this.activatedRoute.snapshot.queryParams.flag == "view"
        ) {
          this.status_flag = res.resolvedata.result[0].contracts[0].status;
          // let dataVal = res.resolvedata.result[0].contractdata;
          this.creditdata.compane_name =
            res.resolvedata.result[0].contractdata.compane_name;
          this.creditdata.city = res.resolvedata.result[0].contractdata.city;
          this.creditdata.state = res.resolvedata.result[0].contractdata.state;
          this.creditdata.zip = res.resolvedata.result[0].contractdata.zip;
          this.creditdata.website =
            res.resolvedata.result[0].contractdata.Website;
          this.creditdata.email = res.resolvedata.result[0].contractdata.email;
          this.creditdata.phonetwo =
            res.resolvedata.result[0].contractdata.phone;

          // this.singeedata = res.resolvedata.result[0].contractdata.singeealldata;
          this.creditdata.license =
            res.resolvedata.result[0].contractdata.license;
          this.creditdata.homeaddress =
            res.resolvedata.result[0].contractdata.homeaddress;
          this.creditdata.city_two =
            res.resolvedata.result[0].contractdata.city_two;
          this.creditdata.state_two =
            res.resolvedata.result[0].contractdata.state_two;
          this.creditdata.zip_two =
            res.resolvedata.result[0].contractdata.zip_two;
          this.creditdata.address =
            res.resolvedata.result[0].contractdata.address;
          this.creditdata.city = res.resolvedata.result[0].contractdata.city;
          this.creditdata.state = res.resolvedata.result[0].contractdata.state;
          this.creditdata.zip = res.resolvedata.result[0].contractdata.zip;
          this.creditdata.signer =
            res.resolvedata.result[0].contractdata.signer;
          this.creditdata.title = res.resolvedata.result[0].contractdata.title;
          this.creditdata.phone = res.resolvedata.result[0].contractdata.phone;
          this.creditdata.business =
            res.resolvedata.result[0].contractdata.business;
          this.creditdata.yearof_busniss =
            res.resolvedata.result[0].contractdata.yearof_busniss;
          this.creditdata.phonetwo =
            res.resolvedata.result[0].contractdata.phonetwo;
          this.creditdata.website =
            res.resolvedata.result[0].contractdata.website;

          this.creditdata.federeltax =
            res.resolvedata.result[0].contractdata.federeltax;
          this.creditdata.preson_name =
            res.resolvedata.result[0].contractdata.preson_name;
          this.creditdata.Security =
            res.resolvedata.result[0].contractdata.Security;
          this.creditdata.Ownership =
            res.resolvedata.result[0].contractdata.Ownership;
          this.creditdata.Ownrent =
            res.resolvedata.result[0].contractdata.Ownrent;
          this.creditdata.homeaddress =
            res.resolvedata.result[0].contractdata.homeaddress;
          this.creditdata.city_two =
            res.resolvedata.result[0].contractdata.city_two;
          this.creditdata.state_two =
            res.resolvedata.result[0].contractdata.state_two;
          this.creditdata.home_phone =
            res.resolvedata.result[0].contractdata.home_phone;
          this.creditdata.preson_name1 =
            res.resolvedata.result[0].contractdata.preson_name1;
          this.creditdata.Security2 =
            res.resolvedata.result[0].contractdata.Security2;
          this.creditdata.Ownership1 =
            res.resolvedata.result[0].contractdata.Ownership1;
          this.creditdata.Ownrent1 =
            res.resolvedata.result[0].contractdata.Ownrent1;
          this.creditdata.homeaddress1 =
            res.resolvedata.result[0].contractdata.homeaddress1;
          this.creditdata.city_two1 =
            res.resolvedata.result[0].contractdata.city_two1;
          this.creditdata.state_two1 =
            res.resolvedata.result[0].contractdata.state_two1;
          this.creditdata.zip_two1 =
            res.resolvedata.result[0].contractdata.zip_two1;
          this.creditdata.home_phone1 =
            res.resolvedata.result[0].contractdata.home_phone1;
          this.creditdata.bank_name =
            res.resolvedata.result[0].contractdata.bank_name;
          this.creditdata.bank_phone =
            res.resolvedata.result[0].contractdata.bank_phone;
          this.creditdata.check_account =
            res.resolvedata.result[0].contractdata.check_account;
          this.creditdata.contract_name =
            res.resolvedata.result[0].contractdata.contract_name;
          this.creditdata.phone3 =
            res.resolvedata.result[0].contractdata.phone3;
          this.creditdata.opainingtime =
            res.resolvedata.result[0].contractdata.opainingtime;
          this.creditdata.equipmentcost =
            res.resolvedata.result[0].contractdata.equipmentcost;
          this.creditdata.vendor =
            res.resolvedata.result[0].contractdata.vendor;
          this.creditdata.eqippent_contract_name =
            res.resolvedata.result[0].contractdata.eqippent_contract_name;
          this.creditdata.phonenothree =
            res.resolvedata.result[0].contractdata.phonenothree;
          this.creditdata.equip_type =
            res.resolvedata.result[0].contractdata.equip_type;
          this.creditdata.teamrequesed =
            res.resolvedata.result[0].contractdata.teamrequesed;
          this.creditdata.startdate =
            res.resolvedata.result[0].contractdata.startdate;
          this.creditdata.enddate =
            res.resolvedata.result[0].contractdata.enddate;
          this.creditdata.Experian =
            res.resolvedata.result[0].contractdata.Experian;
          this.creditdata.Equifax =
            res.resolvedata.result[0].contractdata.Equifax;
          this.creditdata.TransUnion =
            res.resolvedata.result[0].contractdata.TransUnion;
          this.creditdata.credit =
            res.resolvedata.result[0].contractdata.credit;
          this.creditdata.Applicant =
            res.resolvedata.result[0].contractdata.Applicant;
          this.creditdata.Signature =
            res.resolvedata.result[0].contractdata.Signature;
          this.creditdata.Title = res.resolvedata.result[0].contractdata.Title;
          this.creditdata.Date = res.resolvedata.result[0].contractdata.Date;

          //
        } else {
          console.log(res.resolvedata.results);

          this.creditdata.address = res.resolvedata.results.userView[0].address;
          this.creditdata.city = res.resolvedata.results.userView[0].city;
          this.creditdata.state = res.resolvedata.results.userView[0].state;
          this.creditdata.zip = res.resolvedata.results.userView[0].zip;
          this.creditdata.website = res.resolvedata.results.userView[0].Website;
          this.creditdata.email = res.resolvedata.results.userView[0].email;
          this.creditdata.phonetwo = res.resolvedata.results.userView[0].phone;
          this.creditdata.compane_name =
            res.resolvedata.results.userView[0].company;
          this.singeedata = res.resolvedata.results.userView[0].singeealldata;
          this.creditdata.preson_name =
            res.resolvedata.results.userView[0].singeealldata[0].First_Name +
            " " +
            res.resolvedata.results.userView[0].singeealldata[0].Last_Name;
          this.creditdata.homeaddress =
            res.resolvedata.results.userView[0].singeealldata[0]
              .Mailing_Street +
            "," +
            res.resolvedata.results.userView[0].singeealldata[0].Mailing_City +
            "," +
            res.resolvedata.results.userView[0].singeealldata[0].Mailing_State +
            "," +
            res.resolvedata.results.userView[0].singeealldata[0]
              .Mailing_Country;
          this.creditdata.city_two =
            res.resolvedata.results.userView[0].singeealldata[0].Mailing_City;
          this.creditdata.state_two =
            res.resolvedata.results.userView[0].singeealldata[0].Mailing_State;
          this.creditdata.zip_two =
            res.resolvedata.results.userView[0].singeealldata[0].Mailing_Zip;
          this.creditdata.home_phone =
            res.resolvedata.results.userView[0].singeealldata[0].Phone;

          this.creditdata.website = res.resolvedata.results.userView[0].Website;
          this.creditdata.website = res.resolvedata.results.userView[0].Website;

          this.creditdata.signer =
            res.resolvedata.results.userView[0].singeealldata[0].First_Name +
            " " +
            res.resolvedata.results.userView[0].singeealldata[0].Last_Name;
          this.creditdata.compane_name =
            res.resolvedata.results.userView[0].firstname;
        }
      });
    }
  }
  submitbtn(flag) {
    this.errdatat = this.creditdata;
    this.submitflag = true;
    this.submitinfoflags = true;
    this.submitbankflag = true;
    console.log(this.activatedRoute.snapshot.queryParams);
    

    if (this.submitflag && this.submitinfoflags && this.submitbankflag) {
      let data: any = {};
      if (this.activatedRoute.snapshot.queryParams.addedby!=null&&this.activatedRoute.snapshot.queryParams.addedby!=''&&typeof(this.activatedRoute.snapshot.queryParams.addedby)!='undefined') {
        if (flag == "submit") {
          data = {
            lead_id: this.activatedRoute.snapshot.params._id,
            addedby: this.activatedRoute.snapshot.queryParams.addedby,
            product_id: this.activatedRoute.snapshot.params.p_id,
  
            contracts: [
              {
                status: "request",
                added_by_id: this.activatedRoute.snapshot.queryParams.addedbyname,
                addedby: this.activatedRoute.snapshot.queryParams.addedby,
                addedondatetime: Math.round(new Date().getTime()),
                type: this.activatedRoute.snapshot.queryParams.type,
              },
            ],
            contractflag: "credit",
            contractdata: this.creditdata,
            contact_id: this.activatedRoute.snapshot.params.sing_id,
          };
          console.log(
            data,
            "lllllllllllllllllllllllllllllllllllllllllllllllllll"
          );
          this.action_func(data, flag);
        }
        if (flag == "approved") {
          data = {
            lead_id: this.activatedRoute.snapshot.params._id,
            addedby: this.activatedRoute.snapshot.queryParams.addedby,
            product_id: this.activatedRoute.snapshot.params.p_id,
  
            contracts: [
              {
                status: "approved",
                added_by_id: this.activatedRoute.snapshot.queryParams.addedbyname,
                addedby: this.activatedRoute.snapshot.queryParams.addedby,
                addedondatetime: Math.round(new Date().getTime()),
                type: this.activatedRoute.snapshot.queryParams.type,
              },
            ],
            contractflag: "credit",
            contractdata: this.creditdata,
            contact_id: this.activatedRoute.snapshot.params.sing_id,
          };
          this.action_func(data, flag);
        }
        if (flag == "decline") {
          const dialogRef = this.dialog.open(DialogOverviewExampleDialogForCancel, {
            width: '400px',
            data: ""
          });
  
          dialogRef.afterClosed().subscribe(result => {
            if (result == 'confirm') {
              data = {
                lead_id: this.activatedRoute.snapshot.params._id,
                addedby: this.activatedRoute.snapshot.queryParams.addedby,
                product_id: this.activatedRoute.snapshot.params.p_id,
  
                contracts: [
                  {
                    status: "decline",
                    added_by_id: this.activatedRoute.snapshot.queryParams.addedbyname,
                    addedby: this.activatedRoute.snapshot.queryParams.addedby,
                    addedondatetime: Math.round(new Date().getTime()),
                    type: this.activatedRoute.snapshot.queryParams.type,
                  },
                ],
                contractflag: "credit",
                contractdata: this.creditdata,
                contact_id: this.activatedRoute.snapshot.params.sing_id,
              };
              this.action_func(data, flag);
            }
          });
        }
      }else{
        if (flag == "submit") {
          data = {
            lead_id: this.activatedRoute.snapshot.params._id,
            addedby: this.userservice.userid,
            product_id: this.activatedRoute.snapshot.params.p_id,
  
            contracts: [
              {
                status: "request",
                added_by_id: this.userservice.userid,
                addedby: this.userservice.fullname,
                addedondatetime: Math.round(new Date().getTime()),
                type: this.userservice.type,
              },
            ],
            contractflag: "credit",
            contractdata: this.creditdata,
            contact_id: this.activatedRoute.snapshot.params.sing_id,
          };
          console.log(
            data,
            "lllllllllllllllllllllllllllllllllllllllllllllllllll"
          );
          this.action_func(data, flag);
        }
        if (flag == "approved") {
          data = {
            lead_id: this.activatedRoute.snapshot.params._id,
            addedby: this.userservice.userid,
            product_id: this.activatedRoute.snapshot.params.p_id,
  
            contracts: [
              {
                status: "approved",
                added_by_id: this.userservice.userid,
                addedby: this.userservice.fullname,
                addedondatetime: Math.round(new Date().getTime()),
                type: this.userservice.type,
              },
            ],
            contractflag: "credit",
            contractdata: this.creditdata,
            contact_id: this.activatedRoute.snapshot.params.sing_id,
          };
          this.action_func(data, flag);
        }
        if (flag == "decline") {
          const dialogRef = this.dialog.open(DialogOverviewExampleDialogForCancel, {
            width: '400px',
            data: ""
          });
  
          dialogRef.afterClosed().subscribe(result => {
            if (result == 'confirm') {
              data = {
                lead_id: this.activatedRoute.snapshot.params._id,
                tech_id: this.userservice.userid,
                product_id: this.activatedRoute.snapshot.params.p_id,
  
                contracts: [
                  {
                    status: "decline",
                    added_by_id: this.userservice.userid,
                    addedby: this.userservice.fullname,
                    addedondatetime: Math.round(new Date().getTime()),
                    type: this.userservice.type,
                  },
                ],
                contractflag: "credit",
                contractdata: this.creditdata,
                contact_id: this.activatedRoute.snapshot.params.sing_id,
              };
              this.action_func(data, flag);
            }
          });
        }
      }
     

    }
  }
  action_func(data: any, flag: any) {
    this.apiservice
      .getDataWithoutToken(data, "api/request-contracts")
      .subscribe((res: any) => {
        if (flag == "approved") {
          this.openSnackBar("Contract Approved ", "ok");
        }
        if (flag == "decline") {
          this.openSnackBar("Contract Decline ", "ok");
        }
        if (flag == "submit") {
          this.openSnackBar("Contract Submitted ", "ok");
        }
        this.activatedRoute.queryParams.subscribe((data: any) => {
          // console.warn(data);
          if (typeof (data.listing) != 'undefined' && data.listing != null && data.listing != '') {
            if (data.listing == "true") {
              if (this.userservice.type == 'technological-consultant') {
                this.router.navigateByUrl('/contract-manager/contract-request/list/credit/tech');
              }
              if (this.userservice.type == 'admin') {
                this.router.navigateByUrl('/contract-manager/contract-request/list/credit');
              }
            }
          } else {
            this.router.navigateByUrl(
              "/user-details/lead/" + this.activatedRoute.snapshot.params._id +'/contractflag1'
            );
          }
        })
        // window.close();
      });
  }
  canclebtn() {
    // const dialogRef = this.dialog.open(DialogOverviewExampleDialogForCancel, {
    //   width: '400px',
    //   data: ""
    // });

    // dialogRef.afterClosed().subscribe(result => {
    //   if(result == 'confirm'){
    this.activatedRoute.queryParams.subscribe((data: any) => {
      // console.warn(data);
      if (typeof (data.listing) != 'undefined' && data.listing != null && data.listing != '') {
        if (data.listing == "true") {
          if (this.userservice.type == 'technological-consultant') {
            this.router.navigateByUrl('/contract-manager/contract-request/list/credit/tech');
          }
          if (this.userservice.type == 'admin') {
            this.router.navigateByUrl('/contract-manager/contract-request/list/credit');
          }
        }
      } else {
        this.router.navigateByUrl(
          "/user-details/lead/" + this.activatedRoute.snapshot.params._id +'/contractflag1'
        );
      }
    })
    //   }
    // });
    // window.close();
  }
  openSnackBar(message: string, action: string) {
    this._snackBar.open(message, action, {
      duration: 2000,
    });
  }
  checkProperties(obj) {
    for (var key in obj) {
      if (obj[key] != null && obj[key] != "") return false;
    }
    return true;
  }
}
@Component({
  selector: 'dialog-overview-example-dialog-for-cancel',
  templateUrl: 'dialog-overview-example-dialog-for-cancel.html',
})
export class DialogOverviewExampleDialogForCancel {

  constructor(
    public dialogRef: MatDialogRef<DialogOverviewExampleDialogForCancel>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData) { }

  onNoClick(val: any): void {
    this.dialogRef.close(val);
  }

}