import { ApiService } from 'src/app/services/api.service';
import { ActivatedRoute } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';

export interface DialogData {
  emailbody_data: any;
  emailsubject_data:any;
}

@Component({
  selector: 'app-allemailsqued-reports',
  templateUrl: './allemailsqued-reports.component.html',
  styleUrls: ['./allemailsqued-reports.component.css']
})
export class AllemailsquedReportsComponent implements OnInit {

  public particular_index = {
    value: 0
  }
  public progressSpinner: any = {
    mode: 'indeterminate',
    loading: false,
    // bookingStatus: 'Sending request',
  };

  emails_datatableskip: any = [
    '_id',
    'email_sent',
    'actual_sender',
    '__v',
    // 'subject',
    'body',
    'sender',
    'send_attempts',
    'error'
  ];

  emails_modifyheaderarray: any = {

    // cc:'CC',
    // bcc:'BCC',
    subject:'Subject',
    recipients:'Recipients',
    createdon_datetime:'Added On',
    senton_datetime:'Sent On'
    
  };
  // this is use delete  data
  deleteendpoint = 'api/deleteallemailsqued';
  emails_datatabledetailskip: any = [
    '_id',
    'cc',
    'bcc',
    'email_sent',
    'actual_sender',
    '__v',
    'body',
    // 'subject',
    'sender',
    'recipients',
    // 'createdon_datetime',
    // 'senton_datetime',
    'send_attempts'
   
  ];

  emails_searchsettings: any = {
    datesearch: [
      {
        startdatelabel: ' Start Date',
        enddatelabel: 'End Date',
        submit: 'Search',
        field: 'createdon_datetime',
      },
    ],

    search: [
      {
          label: "Search By Recipient", field: 'recipients',
          values: '',
          serversearchdata: { endpoint: 'api/autocompleterecipientsemail' }
      },
  ]// this is use for  Autocomplete search
  };

  emails_sortdata: any = {
    type: 'desc',
    field: '_id',
    options: [
      'createdon_datetime'
    ],
  };

  public emails_datacollection: any = 'api/allemailsqued-queued-emails';


  // for SUCCESS email part
  public success_allemails: any = null;
  successemail_datesearchsourcecount: any = 0;
  public successemail_datacollection: any = 'api/allemailsqued-success-emails';

  successemail_libdata: any = {
    preview_header: {
      header: "Preview Details",
      class: 'preheadercls'
  },
    basecondition: {
      actual_sender:'betoparedes' || 'betoparedestech',
      email_sent:1
    },
    detailview_override: [
      { key: 'cc_vd', val: 'CC' },
      { key: 'bcc_vd', val: 'BCC' },
      // { key: 'sender', val: 'Sender' },
      { key: 'recipients_vd', val: 'Recipients' },
      { key: 'email_sent', val: 'Email Sent' },
      { key: 'createdon_datetime', val: 'Added On' },
      { key: 'senton_datetime', val: 'Sent On' },
    ],
    // table headline
    
    tableheaders: [
      // 'cc',
      // 'bcc',
      'recipients',
      'subject',
      'createdon_datetime',
      'senton_datetime'
    ],
    hideviewbutton: false,// (hide view button)
    hidedeletebutton: true,
    hidemultipleselectbutton: true, // hide multiple selection button (false)
    hideeditbutton: true, // all these button options are optional not mandatory
    hidestatustogglebutton: true,

    custombuttons: [
      {
        label: 'Email',
        type: 'listner',
        id: 'i1',
        classname: 'body_preview',
        tooltip: 'Email',
      }]
  }

  // for QUEUED email part
  queuedemail_datesearchsourcecount: any = 0;

  public queued_allemails: any = null;

  queued_libdata: any = {
    basecondition: {
      actual_sender:'betoparedes' || 'betoparedestech',
      email_sent:false
    },
    detailview_override: [
      { key: 'cc_vd', val: 'CC' },
      { key: 'bcc_vd', val: 'BCC' },
      // { key: 'sender', val: 'Sender' },
      { key: 'recipients_vd', val: 'Recipients' },
      { key: 'createdon_datetime', val: 'Added On' },
      { key: 'senton_datetime', val: 'Sent On' },
      { key: 'priority', val: 'Priority' },
    ],
    // table headline
    tableheaders: [
      // 'cc',
      // 'bcc',
      'recipients',
      'subject',
      'createdon_datetime',
      'senton_datetime'
    ],
    hidedeletebutton: true,
    hidemultipleselectbutton: true, // hide multiple selection button (false)
    hideviewbutton: false, // (hide view button)
    hideeditbutton: true, // all these button options are optional not mandatory
    hidestatustogglebutton: true,

    custombuttons: [
      {
        label: 'Email',
        type: 'listner',
        id: 'i1',
        classname: 'body_preview',
        tooltip: 'Email',
      }]
  };

  emails_limitcond: any = {
    limit: 10,
    skip: 0,
    pagecount: 1,
  };

  
  //for FAILED email part
  public failed_datatable:any=null;
  public failedemail_datesearchsourcecount : any = 0;
  public failedemail_datacollection: any = 'api/allemailsqued-failed-emails';

  failedemail_libdata:any={
    basecondition: {
      actual_sender:'betoparedes' || 'betoparedestech',
      email_sent:0
    },
    // for preview button 
    detailview_override: [
      { key: 'cc_vd', val: 'CC' },
      { key: 'bcc_vd', val: 'BCC' },
      // { key: 'sender', val: 'Sender' },
      { key: 'recipients_vd', val: 'Recipients' },
      { key: 'createdon_datetime', val: 'Added On' },
      { key: 'senton_datetime', val: 'Sent On' },
    ],
    // for main table headline
    tableheaders: [
      // 'cc',
      // 'bcc',
      'recipients',
      'subject',
      'createdon_datetime',
      'senton_datetime'
    ],
    hidedeletebutton: true,
    hidemultipleselectbutton: true, // hide multiple selection button (false)
    hideviewbutton: false, // (hide view button)
    hideeditbutton: true, // all these button options are optional not mandatory
    hidestatustogglebutton: true,

    custombuttons: [
      {
        label: 'Email',
        type: 'listner',
        id: 'i1',
        classname: 'body_preview',
        tooltip: 'Email',
      }]
  }


  constructor(
    public cookieservice: CookieService,
    public activatedRoute: ActivatedRoute,
    public Apiservice: ApiService,
    public dialog: MatDialog,

    ) { 
    // const endpointc = 'api/queued-emails-count';
    // const endpoint = 'api/allemailsqued-queued-emails';
    const endpointc = 'api/allemailsqued-success-emails-count';
    const endpoint = 'api/allemailsqued-success-emails';
    const data: any = {
      // How many data showing in first page
      secret: this.cookieservice.get('secret'),
      token: this.cookieservice.get('jwtToken'),
      source: '',
      condition: {
        // condition for how much data show in one time
        limit: 10,
        skip: 0,
      },
      sort: {
        type: 'desc', // data sort by decreasing order
        field: 'createdon_datetime', // data sort by first name
      },
      searchcondition: {
        // insert_google_event: true
      },
    };

      this.Apiservice.getDataWithoutToken(data, endpointc).subscribe(
        (success_count_res: any) => {
          console.log("success_count_res==>",success_count_res);
          this.successemail_datesearchsourcecount = success_count_res.count; // sarch count  (How many data there)
        }
      );
    }

  ngOnInit() {
    this.activatedRoute.data.forEach((res: any) => {
      this.success_allemails = res.resolvedata.results.res; // data store from backend
      console.log("success_allemails data==>",this.success_allemails);

    });
  }


  onTabClick(onTabClickval) {
    console.log("onTabClickval==>",onTabClickval);
    console.log("onTabClickval.index==>",onTabClickval.index);
    this.particular_index.value = onTabClickval.index;
    if (onTabClickval.index == 0) {
      const endpoint = 'api/allemailsqued-success-emails';
      const data: any = {
        // How many data showing in first page
        secret: this.cookieservice.get('secret'),
        token: this.cookieservice.get('jwtToken'),
        source: '',
        condition: {
          // condition for how much data show in one time
          limit: 10,
          skip: 0,
        },
        sort: {
          type: 'desc', // data sort by decreasing order
          field: 'createdon_datetime', // data sort by first name
        },
        searchcondition: {
          actual_sender:'betoparedes' || 'betoparedestech',
          email_sent:1
        },
      };
    }
    if (onTabClickval.index == 1) {
      this.progressSpinner.loading = true;
      let data = {
        secret: this.cookieservice.get('secret'),
        token: this.cookieservice.get('jwtToken'),
        source: '',
        condition: {
          limit: 10,
          skip: 0,
        },
        sort: {
          type: 'desc',
          field: 'createdon_datetime',
        },
        searchcondition: {
          actual_sender:'betoparedes' || 'betoparedestech',
          email_sent:false
        },
      };
      console.log("Queued data==>",data);
      this.Apiservice.getDataWithoutToken(
        data,
        'api/allemailsqued-queued-emails'
      ).subscribe((res: any) => {
        console.log("Queued res==>",res);    

        // this.cc.push(res.cc)
        // this.bcc.push(res.bcc)

        if (res.status == 'success') {
          this.queued_allemails = res.results.res
          this.progressSpinner.loading = false;
        }
      });
      this.Apiservice.getDataWithoutToken(
        data,
        'api/allemailsqued-queued-emails-count'
      ).subscribe((res: any) => {
        this.queuedemail_datesearchsourcecount = res.count;
      });
    }
    if (onTabClickval.index == 2) {
    this.progressSpinner.loading = true;
      let data = {
        secret: this.cookieservice.get('secret'),
        token: this.cookieservice.get('jwtToken'),
        source: '',
        condition: {
          limit: 10,
          skip: 0,
        },
        sort: {
          type: 'desc',
          field: 'createdon_datetime',
        },
        searchcondition: {
          actual_sender:'betoparedes' || 'betoparedestech',
          email_sent:0
        },
      };
      console.log("Failed data==>",data);
      this.Apiservice.getDataWithoutToken(
        data,
        'api/allemailsqued-failed-emails'
      ).subscribe((res: any) => {
        console.log("Failed res==>",res);
        // console.log("cc res==>",res.cc);        
        // console.log("bcc res==>",res.bcc);        

        // if(res.cc == undefined || res.bcc == undefined){
        //   res.cc = 'N/A';
        //   res.bcc = 'N/A';
        //   console.log("res.cc for N/A==>",res.cc);
        //   console.log("resb.cc for N/A==>",res.bcc);
        // }
        // if(res.cc != undefined || res.bcc != undefined){
          // this.cc.push(res.cc)
          // this.bcc.push(res.bcc)
        // }

        if (res.status == 'success') {
          this.failed_datatable = res.results.res
          this.progressSpinner.loading = false;
        }
      });
      this.Apiservice.getDataWithoutToken(
        data,
        'api/allemailsqued-failed-emails-count'
      ).subscribe((res: any) => {
        this.failedemail_datesearchsourcecount = res.count;
      });
    }
  }

  listenLiblistingChange(val: any) {
    console.log("val of listenLiblistingChange==>", val);
    // for extra email body show custom modal
    if (val.custombuttonclick.btninfo.classname == 'body_preview') {
      const dialogRef = this.dialog.open(EmailbodCustommodalDialog, {
        data: {
          emailbody_data: val.custombuttonclick.data.body,
          emailsubject_data: val.custombuttonclick.data.subject,
        },
        panelClass: 'custom-modalbox'
      })
      dialogRef.afterClosed().subscribe(result => {
        console.log('The dialog was closed');
      });
    }
  
  }

}



// for extra email body show custom modal
@Component({
  selector:'emailbody-modal',
  templateUrl:'emailbody_modal.html'
})
export class EmailbodCustommodalDialog{
  public email_body: any = [];
  public email_sub: any = [];

  constructor(public dialogRef: MatDialogRef<EmailbodCustommodalDialog>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,){

      this.email_body = data.emailbody_data;
      this.email_sub = data.emailsubject_data;
      console.log("email_body==>", this.email_body);
      console.log("email_sub==>", this.email_sub);
    }
    onNoClick(): void {
      this.dialogRef.close();
    }

}
