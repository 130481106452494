import { HttpClient } from '@angular/common/http';
import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MatSnackBar, MAT_DIALOG_DATA } from '@angular/material';
import { ActivatedRoute, Router } from '@angular/router';
import { DialogData } from 'login-lib-influxiq/lib/sign-up/sign-up.component';
import { CookieService } from 'ngx-cookie-service';
import { ApiService } from 'src/app/services/api.service';
import { UserserviceService } from 'src/app/services/userservice.service';
import { environment } from 'src/environments/environment';
import { DialogCancelAlert } from '../calendar-management/appointment-listing/upcoming-appointment/upcoming-appointment.component';

@Component({
  selector: 'app-upcoming-past-appointment',
  templateUrl: './upcoming-past-appointment.component.html',
  styleUrls: ['./upcoming-past-appointment.component.css']
})
export class UpcomingPastAppointmentComponent implements OnInit {
  public schedulepastdatatableflag: boolean = false;
  public scheduledatatableflag: boolean = false;
  public progressSpinner: any = {
    mode: 'indeterminate',
    loading: false,
    bookingStatus: 'Sending request',
  };
  public statusarray_schedule: any = [
    { val: 0, name: 'Pending' },
    { val: 1, name: 'Done' },
    { val: 2, name: 'Canceled' }
  ];
  public status: any = [
    { val: 0, name: 'Pending' },
    { val: 1, name: 'Done' },
    { val: 2, name: 'Canceled' }
  ];
  schedulepast_datatable: any = [];
  schedule_datatable: any = [];
  schedulepast_datesearchsourcecount: any = 0;
  // public schedulepast_datatable: any = [];
  public modifyheaderarray_schedule: any = {
    event_title: 'Appointment Title',
    start_datetime_unix: 'Appointment Date',
    timespan: 'Appointment Duration',
    product_name: 'Concerning Product',
    username: 'Organizer Name',
    useremail: 'Organizer email',
    attende_name: 'Attendee Name',
    attende_email: 'Attendee Email',
    attende_type: 'Attendee Type',
    status: 'Status',
  };
  public schedule_datatabledetailskip: any = [];
  public sortdata_schedule: any = {
    type: 'desc',
    field: 'event_title',
    options: ['event_title', ,],
  };
  // send basic limit data
  public limitcond_schedule: any = {
    limit: 10,
    skip: 0,
    pagecount: 1,
  };
  public schedulepast_datatableskip: any = [
    '_id',
    'slotTimeActual',
    'notes',
    'eid',
    'start_datetime_unix',
    'event_type_val',
    'googleevent',
    'event_title_s',
    'eventid',
    'is_google_event',
    'lesson_id',
    'id',
    'attende_email_s',
    'attende_name_s',
    'user_id',
    'username_s',
    'product_name',
    'product_name_s',
    'product_id',
    'slot_end_time',
    'training_id',
    'parent_id',
  ];
  public libdata_schedulepast: any = {
    basecondition: {
      parentid: this.userservice.userid
    },
    detailview_override: [
      { key: 'event_title', val: 'Event Title' },
      { key: 'event_type', val: 'Event Type' },
      { key: 'description', val: 'Description' },
      { key: 'username', val: 'Organiser Name' },
      { key: 'useremail', val: 'Organiser Email' },
      { key: 'notes', val: 'Notes' },
      { key: 'booking_date', val: 'Booked On' },
      { key: 'startdate', val: 'Appointment Date' },
      { key: 'slot', val: 'Slot' },
      { key: 'timespan', val: 'Time span' },
      { key: 'timezone', val: 'Time zone' },
      { key: 'product_name', val: 'Product Name' },
      { key: 'attende_name', val: 'Attende Name' },
      { key: 'attende_email', val: 'Attende Email' },
      { key: 'attende_type', val: 'Attende Type' },
    ],
    // table headline
    tableheaders: [
      'event_title',
      'attende_name',
      'attende_email',
      'attende_type',
      'start_datetime_unix',
      'status',
    ],
    custombuttons: [
      // {
      //   label: 'View Appointment',
      //   route: '/contract-manager/contract/view/',
      //   type: 'internallink',
      //   tooltip: ''View Job Ticket',
      //   classname: 'view_jobticketinBTN',
      //   param: ['_id'],
      // },
      // {
      //   label: 'Cancel',
      //   type: 'listner',
      //   id: 'btn_cancel',
      //   cond: 'status', condval: 0,
      //   // refreshdata: true,
      //   classname: 'cancelBTN',
      //   tooltip: 'Cancel',
      // },
      // {
      //   label: 'Reschedule',
      //   route:
      //     '/calendar-management/manage-appointment/reschedule-appointments/',
      //   type: 'internallink',
      //   cond: 'status',
      //   condval: 0,
      //   param: ['_id', 'event_type'],
      //   classname: 'rescheduleBTN',
      //   tooltip: 'Reschedule',
      // },
    ],
    notes: {
      label: 'Add Notes',
      addendpoint: 'api/addnoteforappoinment',
      deleteendpoint: 'api/deletenotedataforlead',
      listendpoint: 'api/list-noteforappoinment',
      user: this.userservice.userid,
      currentuserfullname:
        this.userservice.firstname + ' ' + this.userservice.lastname,
      header: 'Add Notes',
    },
    // hidestatustogglebutton: true,
    hidedeletebutton: true,
    updateendpointmany: '', // update multiple user
    updateendpoint: '', // update status
    deleteendpointmany: '', // delete multiple user
    hidemultipleselectbutton: true, // hide multiple selection button (false)
    hidecounter: null, // hide counter (false)
    hideaction: false, // hide action (false)
    hideviewbutton: false, // (hide view button)
    hideeditbutton: true, // all these button options are optional not mandatory
    hidestatustogglebutton: true,
  };
  public libdata_schedule: any = {
    basecondition: {
      id: this.userservice.userid,
      event_type_val: 4,
      status: 0,
    },
    detailview_override: [
      { key: 'event_title', val: 'Event Title' },
      { key: 'event_type', val: 'Event Type' },
      { key: 'description', val: 'Description' },
      { key: 'username', val: 'Organiser Name' },
      { key: 'useremail', val: 'Organiser Email' },
      { key: 'notes', val: 'Notes' },
      { key: 'booking_date', val: 'Booked On' },
      { key: 'startdate', val: 'Appointment Date' },
      { key: 'slot', val: 'Slot' },
      { key: 'timespan', val: 'Time span' },
      { key: 'timezone', val: 'Time zone' },
      { key: 'product_name', val: 'Product Name' },
      { key: 'attende_name', val: 'Attende Name' },
      { key: 'attende_email', val: 'Attende Email' },
      { key: 'attende_type', val: 'Attende Type' },
    ],
    // table headline
    tableheaders: [
      'event_title',
      'attende_name',
      'attende_email',
      'attende_type',
      'start_datetime_unix',
      'status',
    ],
    custombuttons: [
      // {
      //   label: 'View Appointment',
      //   route: '/contract-manager/contract/view/',
      //   type: 'internallink',
      //   tooltip: ''View Job Ticket',
      //   classname: 'view_jobticketinBTN',
      //   param: ['_id'],
      // },

      {
        label: 'Cancel',
        type: 'listner',
        id: 'btn_cancel',
        cond: 'status',
        condval: 0,
        // refreshdata: true,
        classname: 'cancelBTN',
        tooltip: 'Cancel',
      },
      // {
      //   label: 'Reschedule',
      //   route:
      //     '/calendar-management/manage-appointment/reschedule-appointments/',
      //   type: 'internallink',
      //   cond: 'status',
      //   condval: 0,
      //   param: ['_id', 'event_type_val'],
      //   classname: 'rescheduleBTN',
      //   tooltip: 'Reschedule',
      // },
      {
        label: 'Reschedule',
        type: 'listner',
        cond: 'status',
        condval: 0,
        classname: 'rescheduleBTN',
        tooltip: 'Reschedule',
      },
      {
        label: 'Mark as Done',
        type: 'listner',
        id: 'btn_mark_as_done',
        cond: 'status',
        condval: 0,
        classname: 'btn_mark_as_done',
        tooltip: 'Mark as Done',
        refreshdata: true,
      },
    ],
    notes: {
      label: 'Add Notes',
      addendpoint: 'api/addnoteforappoinment',
      deleteendpoint: 'api/deletenotedataforlead',
      listendpoint: 'api/list-noteforappoinment',
      user: this.userservice.userid,
      currentuserfullname:
        this.userservice.firstname + ' ' + this.userservice.lastname,
      header: 'Add Notes',
    },
    // hidestatustogglebutton: true,
    hidedeletebutton: true,
    updateendpointmany: '', // update multiple user
    updateendpoint: '', // update status
    deleteendpointmany: '', // delete multiple user
    hidemultipleselectbutton: true, // hide multiple selection button (false)
    hidecounter: null, // hide counter (false)
    hideaction: false, // hide action (false)
    hideviewbutton: false, // (hide view button)
    hideeditbutton: true, // all these button options are optional not mandatory
    hidestatustogglebutton: true,
  };
  datacollection_schedule = 'api/appointment-parentid-view';
  datacollection_schedulepast = 'api/appointment-parentid-past-view';
  schedulepast_searchsettings: any = {
    datesearch: [
      {
        startdatelabel: 'Appointments After',
        enddatelabel: 'Appointments Before',
        submit: 'Search',
        field: 'start_datetime_unix',
      },
      // {
      //   startdatelabel: 'Booking After', enddatelabel: 'Booking Before',
      //   submit: 'Search', field: 'booking_date'
      // }
    ], // this is use for  Autocomplete search
    textsearch: [
      { label: 'Search By  Attendees Name', field: 'attende_name_s' },
      { label: 'Search By  Attendees Email', field: 'attende_email_s' },
    ],
    selectsearch: [
      {
        label: 'Search By Status',
        field: 'status',
        values: this.status
      },
    ],
  };
  schedule_datesearchsourcecount: any = 0;
  public updatetable: any = false;
  constructor(
    public router: Router,
    public Apiservice: ApiService,
    public http: HttpClient,
    public cookieservice: CookieService,
    public activatedRoute: ActivatedRoute,
    public userservice: UserserviceService,
    public snackBar: MatSnackBar,
    public dialog: MatDialog

  ) {
    // this.userid = this.userservice.userid;
    // this.userFullName =
    //   this.userservice.firstname +
    //   ' ' +
    //   this.userservice.lastname; // user dtls ( cookieservice)
    const endpointc = 'api/appointment-parentid-view-count';
    // const endpoint = 'api/userlistview';
    const data: any = { // How many data showing in first page
      secret: this.userservice.secret,
      token: this.userservice.jwttoken,
      source: '',
      condition: {  // condition for how much data show in one time
        limit: 10,
        skip: 0,
        parentid: this.userservice.userid,
        event_type_val: 2
      },
      sort: {
        type: 'desc',  // data sort by decreasing order
        field: 'booking_date',  // data sort by first name
      },
      sarchconditions: {},
    };

    this.Apiservice
      .getDataWithoutToken(data, endpointc)
      .subscribe((res: any) => {
        this.schedule_datesearchsourcecount = res.count; // sarch count  (How many data there)
      });
  }
  listenLiblistingChange_sechedule(event: any) {
    console.log('eventtt', event);
    if (event.custombuttonclick != undefined) {
      if (event.custombuttonclick.btninfo.id === 'btn_cancel') {
        const dialogRef = this.dialog.open(DialogCancelAlert);

        dialogRef.afterClosed().subscribe((result) => {
          // console.log(`Dialog result: ${result}`);
          console.log(result, 'testingggggggggggggggggggggggggggggggggggg');
          if (result.flag == true) {
            // google_events
            let note = result.data;
            let google_events_data: any = {
              userid: event.custombuttonclick.data.user_id,
              source: 'google_events',
              token: this.userservice.jwttoken,
              note: note,
            };
            this.Apiservice.customRequest(
              google_events_data,
              'get-refresh-token',
              environment['api_calender_url']
            ).subscribe((response: any) => {
              console.log('responseresponseresponse', response);
              let r_token = response.refresh_token;
              // console.log(r_token, 'r_token')
              const data: any = {
                token: this.userservice.jwttoken,
                _id: event.custombuttonclick.data._id,
                attende_email: event.custombuttonclick.data.attende_email,
                attende_name: event.custombuttonclick.data.attende_name,
                attende_type: event.custombuttonclick.data.attende_type,
                useremail: event.custombuttonclick.data.useremail,
                appointment_date: event.custombuttonclick.data.startdate,
                username: event.custombuttonclick.data.username,
                slot: event.custombuttonclick.data.slot,
                event_type: event.custombuttonclick.data.event_type,
                event_title: event.custombuttonclick.data.event_title,
                // 'appointment_date': '01/27/2021',
                refresh_token: r_token,
                eid: event.custombuttonclick.data.eid,
                slotTimeActual: event.custombuttonclick.data.slotTimeActual,
                googleevent: event.custombuttonclick.data.googleevent,
                note: note,
              };
              // console.log(data, 'data')
              this.Apiservice.customRequest(
                data,
                'google-calendar-event/delete-booked-event',
                environment['google_calendar_api']
              ).subscribe((response: any) => {
                // console.log('response', response);
                setTimeout(() => {
                  this.updatetable = true;
                }, 10);
              });

              this.Apiservice.customRequest(
                data,
                'delete-booked-event',
                environment['api_calender_url']
              ).subscribe((response: any) => {
                console.log(response, 'responsedelete');
              });
            });
          }
        });
      }
      if (event.custombuttonclick != undefined) {
        if (event.custombuttonclick.btninfo.id === 'btn_mark_as_done') {
          const dialogRef = this.dialog.open(markasdoneappointmentAlert);
          dialogRef.afterClosed().subscribe((result) => {
            const mark_data: any = {
              _id: event.custombuttonclick.data._id,
            };
            if (result === 'true') {
              this.Apiservice.customRequest(
                mark_data,
                'update-status-done',
                environment['api_calender_url']
              ).subscribe((response: any) => {
                console.log('response', response);
                // this.configData.updatetable = !this.configData.updatetable;
                setTimeout(() => {
                  this.updatetable = true;
                }, 10);
              });
            }
          });
        }
      }

      // if (event.custombuttonclick.btninfo.id === 'btn_mark_as_done') {
      //   console.log(event.custombuttonclick.btninfo.id, 'mark')

      //   const mark_data: any = {
      //     _id: event.custombuttonclick.data._id
      //   }

      //   this.apiService.customRequest(mark_data, 'update-status-done', environment['api_calender_url']).subscribe((response: any) => {
      //     console.log(response, 'response')
      //     setTimeout(() => {
      //       this.updatetable =true;
      //       }, 10);
      //   })
      // }

      if (event.custombuttonclick.btninfo.classname === 'rescheduleBTN') {
        console.log(event.custombuttonclick.btninfo.id, 'mark');
        this.router.navigateByUrl(
          '/calendar-management/manage-appointment/reschedule-appointments/' +
          event.custombuttonclick.data._id +
          '/' +
          event.custombuttonclick.data.event_type_val
        );
      }
    }
  }
  ngOnInit() {
    this.activatedRoute.data.forEach((res: any) => {
      this.schedule_datatable = res.resolvedata.results.res;
      if (this.schedule_datatable.length == 0) {
        this.scheduledatatableflag = true;
      } else {
        this.scheduledatatableflag = false;
      }
      // data store from backend
      console.log(res, 'madhumita khutia closer appointment');
    });
  }

  onTabClickappoinment(val: any) {
    console.log(val, 'valllllllll')
    this.progressSpinner.loading = false;
    let data: any = {};
    if (val.index == 1) {
      console.log('yyyyyyyyyyyyyyyyyyyyyy');
      // this.router.navigateByUrl('/calendar-management/manage-appointment/genarelcall-appointments')
      // this.schedulepast_datatable = [];
      data = {
        secret: this.userservice.secret,
        token: this.userservice.jwttoken,
        condition: {
          limit: 10,
          skip: 0,
          parentid: this.userservice.userid,
          event_type_val: 2
        },
        sort: {
          type: 'desc',
          field: 'booking_date',
        },
        sarchconditions: {
          // id: this.userservice.userid,
          // event_type_val: 4,
        },
      };

      console.log(data);
      this.Apiservice.getDataWithoutToken(
        data,
        'api/appointment-parentid-past-view'
      ).subscribe((res: any) => {
        console.log(res, 'appointment-parentid-past-view');
        if (res.status == 'success') {
          this.progressSpinner.loading = false;
          this.schedulepast_datatable = res.results.res;
          if (this.schedulepast_datatable.length == 0) {
            this.schedulepastdatatableflag = true;
          } else {
            this.schedulepastdatatableflag = false;
          }
          console.log(this.schedulepast_datatable, 'this.schedulepast_datatable');
          // sarch count  (How many data there)
        }
      });
      this.Apiservice.getDataWithoutToken(
        data,
        'api/appointment-parentid-past-view-count'
      ).subscribe((res: any) => {
        console.log(res, 'appointment-parentid-past-view-count');
        if (res.status == 'success') {
          this.progressSpinner.loading = false;
          this.schedulepast_datesearchsourcecount = res.count;
          // sarch count  (How many data there)
        }
      });
    }
  }
}



@Component({
  selector: 'markasdoneappointment',
  templateUrl: 'markasdoneappointment.html',
})
export class markasdoneappointmentAlert { }