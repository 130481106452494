import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { ApiService } from 'src/app/services/api.service';
import { UserserviceService } from 'src/app/services/userservice.service';

@Component({
  selector: 'app-full-details',
  templateUrl: './full-details.component.html',
  styleUrls: ['./full-details.component.css']
})
export class FullDetailsComponent implements OnInit {
  public userdetails: any = [];
  public childdata: any =[];
  salesmanagerListdatatable: any = [];
  public updatetable: any = false;

  public status: any = [
    { val: 1, name: 'Active' },
    { val: 0, name: 'Inactive' },
  ];

  // use for status search
  statusarray: any = [
    { val: 1, name: 'Active' },
    { val: 0, name: 'Inactive' },
  ];
  searchsettings: any = {
   
  };
  // editrouting
  editroute: any = 'sales-person/edit/';

  // modify header(key : modify header name)
  modifyheaderarray: any = {
    fullname: 'Full Name',
    email: 'Email',
    phone: 'Contact Number',
    status: 'Status',
    lastlogin_datetime: 'Last Login',
    createdon_datetime: 'Joind On',
  };

  // use for table field skip
  salesmanagerListdatatableskip: any = [
    '_id',
    'type',
    'password',
    'created_at',
    'type',
    'city',
    'gallery_image',
    'accesscode',
    '__v',
    'notes',
  ];

  // use for Table Detail Field Skip
  salesmanagerListdatatabledetailskip: any = [
    '_id',
    'email_s',
    'name',
    'blogtitle',
    'type',
    'city',
    '__v',
    'fullname',
    'notes',
    'accesscode',
    'location_s',
    'address_search',
    'fullname_s',
    'zip_s',
    'createdon_datetime',
    'fulladdress',
    'notescount',
    'login_data',
    'lastlogin_datetime',
    'contactInFo',
    'related_info',
    'firstname_s',
    'status',
  ];

  // updateendpoint is use for data update endpoint
  updateendpoint = 'api/userstatusupdate';

  // deleteendpoint is use for data delete endpoint
  deleteendpoint = 'api/deleteuser';

  // this is a database collection name
  tablename = 'users';

  // searchendpoint is use for data search endpoint


  // use for click to another page routing path
  clicktoaddananotherpage = '';

  // date_search_endpoint is use for date search endpoint
  datesearchendpoint: any = '';

  // send basic limit data
  limitcond: any = {
    limit: 10,
    skip: 0,
    pagecount:1
  };

  // other data
  libdata: any = {
    basecondition: { type: 'sales-person' },
    detailview_override: [
      { key: 'fullname', val: 'Full Name' },
      { key: 'location', val: 'Location' },
      { key: 'contactInfo', val: 'Contact Info' },
      { key: 'status', val: 'Status' },
      // { key: 'login_details', val: 'Login Details' },
    ],
    tableheaders: [
      'fullname',
      'email',
      'phone',
      'createdon_datetime',
      'lastlogin_datetime',
      'status',
    ],

    hidedeletebutton: true,
    // notes: {
    //   label: 'Add Notes',
    //   addendpoint: 'api/addnotedata',
    //   deleteendpoint: 'api/deletenotedata',
    //   listendpoint: 'api/listnotedata',
    //   user: this.userservice.userid,
    //   currentuserfullname:
    //     this.userservice.firstname +
    //     ' ' +
    //     this.userservice.lastname,
    //   header: 'Add Notes',
    // },
    updateendpointmany: 'api/userupdate',
    updateendpoint: 'api/userstatusupdate',
    deleteendpointmany: 'api/deleteuser',
    hidemultipleselectbutton: null,
    hidecounter: null,
    hideaction: null,
    hideviewbutton: false, // (hide view button)

    // actioncolname: 'Actn',
    // not required,
    custombuttons: [

    ],
  };

  // send basic sort data
  sortdata: any = {
    type: 'desc',
    field: 'createdon_datetime',
    options: [
      'fullname',
      'email',
      'phone',
      'lastlogin_datetime',
      'status',
      'createdon_datetime'
    ],
  };

  // this is a database collection or view name
  // datesearchsource: any = 'admin_blog_list';
  // datacollection
  datacollection: any = 'api/usersdetails';
  // source count
  datesearchsourcecount: any = 0;



  public userid: any;
  public userFullName: any;
  constructor( public router: Router,
    public Apiservice: ApiService,
    public http: HttpClient,
    public cookieservice: CookieService,
    public activatedRoute: ActivatedRoute,
    public userservice: UserserviceService,
    ) {
      this.userid = this.userservice.userid;
      this.userFullName =
        this.userservice.firstname +
        ' ' +
        this.userservice.lastname; // user dtls ( cookieservice)
      // const endpointc = 'api/usersdetails-count';
      // const endpoint = 'api/usersdetails';
      const data: any = { // How many data showing in first page
        secret: this.userservice.secret,
        token: this.userservice.jwttoken,
        source: '',
        condition: {  // condition for how much data show in one time
          limit: 10,
          skip: 0,
        },
        sort: {
          type: 'desc',  // data sort by decreasing order
          field: 'createdon_datetime',  // data sort by first name
        },
        searchcondition: { type: 'sales-person' },
      };
  
      // this.Apiservice
      //   .getDataWithoutToken(data, endpointc)
      //   .subscribe((res: any) => {
      //     this.datesearchsourcecount = res.count; // sarch count  (How many data there)
      //   });
    


     }

  ngOnInit() {
    this.activatedRoute.data.forEach((res: any) => {
      // console.log(res,'userdetails');
      this.userdetails = res.resolvedata.result[0];
      // console.log(this.userdetails,'**********');
      this.salesmanagerListdatatable =[]
      // console.log(this.salesmanagerListdatatable,'childdata ')
    })

  }
  addnotes(){
        
  }
}
