import { HttpClient } from '@angular/common/http';
import { Component, Inject, OnInit } from '@angular/core';
import { MatBottomSheet, MatBottomSheetRef, MatDialog, MatDialogRef, MatSnackBar, MAT_BOTTOM_SHEET_DATA, MAT_DIALOG_DATA } from '@angular/material';
import { ActivatedRoute, Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { ApiService } from 'src/app/services/api.service';
import { UserserviceService } from 'src/app/services/userservice.service';


export interface DialogData {
  data: any;
}

@Component({
  selector: 'app-closer-list',
  templateUrl: './closer-list.component.html',
  styleUrls: ['./closer-list.component.css']
})
export class CloserListComponent implements OnInit {

  distributorListdatatable: any = [];
  public updatetable: any = false;

  public status: any = [
    { val: 1, name: 'Active' },
    { val: 0, name: 'Inactive' },
  ];

  // use for status search
  statusarray: any = [
    { val: 1, name: 'Active' },
    { val: 0, name: 'Inactive' },
  ];

  // editrouting
  editroute: any = 'closer/edit/';

  // modify header(key : modify header name)
  modifyheaderarray: any = {
    fullname: 'Full Name',
    
    email: 'Email',
    phone: 'Contact Number',
    status: 'Status',
    lastlogin_datetime: 'Last Login',
    createdon_datetime: 'Joind On',
  };

  // use for table field skip
  distributorListdatatableskip: any = [
    '_id',
    'type',
    'password',
    'created_at',
    'type',
    'city',
    'gallery_image',
    'accesscode',
    '__v',
    'notes',
  ];

  // use for Table Detail Field Skip
  distributorListdatatabledetailskip: any = [
    '_id',
    'email_s',
    'name',
    'blogtitle',
    'type',
    'city',
    '__v',
    'fullname',
    'notes',
    'accesscode',
    'location_s',
    'address_search',
    'fullname_s',
    'zip_s',
    'createdon_datetime',
    'fulladdress',
    'notescount',
    'login_data',
    'lastlogin_datetime',
    'contactInFo',
    'related_info',
    'firstname_s',
    'status',
  ];

  // updateendpoint is use for data update endpoint
  updateendpoint = 'api/userstatusupdate';

  // deleteendpoint is use for data delete endpoint
  deleteendpoint = 'api/deleteuser';

  // this is a database collection name
  tablename = 'users';

  // searchendpoint is use for data search endpoint
  searchendpoint = '';

  // use for click to another page routing path
  clicktoaddananotherpage = '';

  // date_search_endpoint is use for date search endpoint
  datesearchendpoint: any = '';

  // send basic limit data
  limitcond: any = {
    limit: 10,
    skip: 0,
  };

  // other data
  libdata: any = {
    basecondition: { type: 'closer' },
    detailview_override: [
      { key: 'fullname', val: 'Full Name' },
      { key: 'location', val: 'Location' },
      { key: 'contactInfo', val: 'Contact Info' },
      { key: 'status', val: 'Status' },
      // { key: 'login_details', val: 'Login Details' },
    ],
    tableheaders: [
      'fullname',
      'email',
      'phone',
      'createdon_datetime',
      'lastlogin_datetime',
      'status',
    ],
    // searchBarFlagVal:true,
    // tableheaders name [key]
    // optional
    hidedeletebutton: true,
    notes: {
      label: 'Add Notes',
      addendpoint: 'api/addnotedata',
      deleteendpoint: 'api/deletenotedata',
      listendpoint: 'api/listnotedata',
      user: this.userservice.userid,
      currentuserfullname:
        this.userservice.firstname +
        ' ' +
        this.userservice.lastname,
      header: 'Add Notes',
    },
    updateendpointmany: 'api/userupdate',
    updateendpoint: 'api/userstatusupdate',
    deleteendpointmany: 'api/deleteuser',
    hidemultipleselectbutton: null,
    hidecounter: null,
    hideaction: null,
    hideviewbutton: true, // (hide view button)

    // actioncolname: 'Actn',
    // not required,
    custombuttons: [
      // {
      //   label: 'Resend Welcome Mail',
      //   type: 'action',
      //   datatype: 'api',
      //   endpoint: 'api/resendemail',
      //   otherparam: ['type', '_id'],
      //   headermessage: 'Api Info',
      // },
      // {
      //   label: ' Send Reset Password Mail',
      //   type: 'action',
      //   datatype: 'api',
      //   endpoint: 'api/forgetpassword',
      //   otherparam: ['email', '_id'],
      // },
      {
        label: 'Others Action',
        type: 'listner',
        id: 'a2',
        tooltip: 'Other Actions',
        classname:'OtherActions'
      }
    ],
  };

  // send basic sort data
  sortdata: any = {
    type: 'desc',
    field: 'createdon_datetime',
    options: [
      'fullname',
      'email',
      'phone',
      'lastlogin_datetime',
      'status',
      'createdon_datetime'
    ],
  };

  // this is a database collection or view name
  // datesearchsource: any = 'admin_blog_list';
  // datacollection
  datacollection: any = 'api/userlistview';
  // source count
  datesearchsourcecount: any = 0;

  searchsettings: any = {
    datesearch: [
      {
        startdatelabel: 'Joined On Start Date',
        enddatelabel: 'Joined On End Date',
        submit: 'Search',
        field: 'createdon_datetime',
      },
    ],
    selectsearch: [
      { label: 'Search By Status', field: 'status', values: this.status },
    ],
    textsearch: [
      { label: 'Search By Name', field: 'fullname_s' },
      { label: 'Search By Email', field: 'email_s' },
      { label: 'Search By Location', field: 'location_s' },
    ], // this is use for  Autocomplete search
  };

  public userid: any;
  public userFullName: any;

  constructor(
    public router: Router,
    public Apiservice: ApiService,
    public http: HttpClient,
    public cookieservice: CookieService,
    public activatedRoute: ActivatedRoute,
    public userservice: UserserviceService,
    public bottomSheet: MatBottomSheet
  ) {

    this.userid = this.userservice.userid;
    this.userFullName =
      this.userservice.firstname +
      ' ' +
      this.userservice.lastname; // user dtls ( cookieservice)
    const endpointc = 'api/userlistview-count';
    const endpoint = 'api/userlistview';
    const data: any = { // How many data showing in first page
      secret: this.userservice.secret,
      token: this.userservice.jwttoken,
      source: '',
      condition: {  // condition for how much data show in one time
        limit: 10,
        skip: 0,
      },
      sort: {
        type: 'desc',  // data sort by decreasing order
        field: 'createdon_datetime',  // data sort by first name
      },
      searchcondition: { type: 'closer' },
    };

    this.Apiservice
      .getDataWithoutToken(data, endpointc)
      .subscribe((res: any) => {
        this.datesearchsourcecount = res.count; // sarch count  (How many data there)
      });
  }

  ngOnInit() {
    this.activatedRoute.data.forEach((res: any) => {
      this.distributorListdatatable = res.resolvedata.results.res; // data store from backend
      // console.log(this.distributorListdatatable, 'madhumita khutia');
    });
  }

  listenLiblistingChange(val: any) {
    // console.log(val, '------------');
    if (val.custombuttonclick != null && val.custombuttonclick.btninfo != null && val.custombuttonclick.btninfo.label === 'Others Action') {
      const btnSheet = this.bottomSheet.open(BottonSheetCloser, {
        data: val
      });
      btnSheet.afterDismissed().subscribe(res => {
        if (res === 'yes') {
          // console.log('++++', res);
          this.updatetable = true;
        }
      });
    }

  }

}

@Component({
  selector: 'bottonsheet',
  templateUrl: './bottomsheet.html'
})

export class BottonSheetCloser {
  public deleteflag: any = false;
  constructor(public bottomSheetRef: MatBottomSheetRef<BottonSheetCloser>, public apiservice: ApiService, public userservice: UserserviceService, @Inject(MAT_BOTTOM_SHEET_DATA) public data, private snackbar: MatSnackBar, public dialog: MatDialog) {
    // // console.log(data.custombuttonclick.data, '777777777777777')
  }

  resendMail() {
    const data = {
      token: this.userservice.jwttoken,
      secret: this.userservice.secret,
      type: this.data.custombuttonclick.data.type,
      _id: this.data.custombuttonclick.data._id
    };
    // console.log('resendMail');
    this.apiservice.getDataWithoutToken(data, 'api/resendemail').subscribe((res: any) => {
      // console.log(res, 'resendMail');
      if (res.status === 'success') {
        this.snackbar.open('Resend Mail Successfull.', 'Ok', {
          duration: 2000
        });
      }
      this.bottomSheetRef.dismiss();
    });
  }

  sendResetPassword() {
    const data = {
      token: this.userservice.jwttoken,
      secret: this.userservice.secret,
      _id: this.data.custombuttonclick.data._id,
      email: this.data.custombuttonclick.data.email
    };
    this.apiservice.getDataWithoutToken(data, 'api/forgetpassword').subscribe((res: any) => {
      if (res.status === 'success') {
        this.snackbar.open('Request for Password Reset is successful, Please check your registered mail.', 'Ok', {
          duration: 2000
        });
      }
      this.bottomSheetRef.dismiss();
    });
  }

  dltBt() {
    const data = {
      token: this.userservice.jwttoken,
      secret: this.userservice.secret,
      id: this.data.custombuttonclick.data._id,
    };
    let flag = '';
    this.apiservice.getDataWithoutToken(data, 'api/deleteuser').subscribe((res: any) => {
      if (res.status === 'success') {
        this.snackbar.open('Record Delete Successfully', 'Ok', {
          duration: 2000
        });
        flag = 'yes';
        this.bottomSheetRef.dismiss(flag);
      } else {
        flag = 'no';
        this.bottomSheetRef.dismiss(flag);
      }
    });
  }

  dltBtn() {
    this.deleteflag = false;
  }

  deleteBtn() {
    this.deleteflag = true;
    // console.log('=====', this.deleteflag);
  }

  ViewDetails(val: any) {
    // console.log(val, 'divicesInfo');
    const dialogRef = this.dialog.open(ViewDetailsCloser, {
      height: '500px',
      width: '500px',
      data: { val: this.data }
    })
  }
}



@Component({
  selector: 'viewdetails',
  templateUrl: 'viewdetails.html'
})

export class ViewDetailsCloser {
  public valdata: any;
  constructor(public dialogRef: MatDialogRef<ViewDetailsCloser>, @Inject(MAT_DIALOG_DATA) public data: DialogData, public apiservice: ApiService, public activatedroute: ActivatedRoute, public userservice: UserserviceService) {
    this.valdata = JSON.stringify(data);
    this.valdata = JSON.parse(this.valdata)
    // console.log(data, '+++++');
    // console.log(this.valdata, '+++data++');
  }

  onNoClick(): void {
    this.dialogRef.close();
  }
}