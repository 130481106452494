import { filter } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import {
  Component,
  ElementRef,
  Inject,
  OnInit,
  ViewChild,
} from '@angular/core';
import {
  MatBottomSheet,
  MatBottomSheetRef,
  MatDialog,
  MatDialogRef,
  MatSnackBar,
  MAT_BOTTOM_SHEET_DATA,
  MAT_DIALOG_DATA,
} from '@angular/material';
import { ActivatedRoute, Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { ApiService } from 'src/app/services/api.service';
import { UserserviceService } from 'src/app/services/userservice.service';
import { environment } from 'src/environments/environment';
import { FormControl, FormBuilder, FormGroup } from '@angular/forms';
import { DOCUMENT } from '@angular/common';
import { LoaderService } from 'src/app/services/loader.service';
import { DialogFollowUpComponent } from '../../folder-view-management/folder-view/folder-view.component';
import { ObservableService } from 'src/app/services/observable.service';
export interface DialogData {
  data: any;
  datetimeslot: any;
  _id: any;
  disposition: any;
  Dispositionflag: any;
  res: any;
  dispo: any;
}
@Component({
  selector: 'app-lead-list',
  templateUrl: './lead-list.component.html',
  styleUrls: ['./lead-list.component.css'],
})
export class LeadListComponent implements OnInit {
  @ViewChild('myDiv', { static: false }) myDiv: ElementRef<HTMLElement>;

  public searchForm: FormGroup;
  public basecondition_object: any = {};
  public progressSpinner: any = {
    mode: 'indeterminate',
    loading: false,
    bookingStatus: 'Sending request',
  };
  public leadListdatatableflag: any = false;
  leadListdatatable: any = [];
  schedule_datatable: any = [];
  schedulepast_datatable: any = [];
  public scheduledatatableflag: any = false;
  updatetable: any = false;
  public refreshbtnflag: any = false;
  public notes_data_count: any;
  public count: number = 6;
  schedule_datesearchsourcecount: any = 0;
  schedulepast_datesearchsourcecount: any = 0;
  public status: any = [
    { val: 1, name: 'Active' },
    { val: 0, name: 'Inactive' },
  ];

  public typecheck: any;

  // use for status search
  statusarray: any = [
    { val: 1, name: 'Active' },
    { val: 0, name: 'Inactive' },
  ];

  // editrouting
  editroute: any = 'admin/lead/edit/';

  // modify header(key : modify header name)
  modifyheaderarray: any = {
    final_fullname: 'Practice Name / Company',
    email: 'Main Contact Email',
    phone: 'Main Contact Number',
    // salesrepfullname: 'Parent Name',
    status: 'Status',
    // lastlogin_datetime: 'Last Login',
    createdon_datetime: 'Joind On',
    tags: 'Tags',
  };
  modifyheaderarray_leads: any = {
    final_fullname: 'Practice Name / Company',
    email: 'Main Contact Email',
    phone: 'Main Contact Number',
    salesrepfullname: 'Parent Name',
    status: 'Status',
    lastactivity_datetime: 'Last Activity',
    // tags: 'Tags',
    dispositionview: 'Disposition',
    final_main_contact_name: 'Main Contact Name'
  };
  // use for table field skip
  leadListdatatableskip: any = [
    '_id',
    'type',
    'password',
    'created_at',
    'type',
    'city',
    'gallery_image',
    'accesscode',
    '__v',
    'notes',
    'salesrepfullname_s',
  ];

  // use for Table Detail Field Skip
  leadListdatatabledetailskip: any = [
    '_id',
    'name',
    'email_s',
    'tags_s',
    'blogtitle',
    'type',
    'city',
    '__v',
    'productsarray',
    'notes',
    'accesscode',
    'location_s',
    'address_search',
    'fullname_s',
    'zip_s',
    'createdon_datetime',
    'fulladdress',
    'notescount',
    'login_data',
    'lastlogin_datetime',
    'contactInFo',
    'related_info',
    'firstname_s',
    'firstname',
    'lastname',
    'status',
    'parentid',
    'products',
    'contactInfo',
    'salesrep_f_name',
    'salesrep_l_name',
    'Salesrep_phone',
    'lastlogin_datetime',
    'parentid_s',
    'login_details',
    'tags',
    'tagsearch',
    'location',
    'salesrepfullname_s',
    'city',
    'state',
    'source',
    'zip',
    'lastactivity',
    'lastactivity_datetimeshort',
    'vd_array',
    'HPS_Representative',
    'prod',
  ];

  // updateendpoint is use for data update endpoint
  updateendpoint = 'api/leadstatusupdate';

  // deleteendpoint is use for data delete endpoint
  deleteendpoint = 'api/deletelead';

  // this is a database collection name
  tablename = 'users';

  // searchendpoint is use for data search endpoint
  searchendpoint = '';

  // use for click to another page routing path
  clicktoaddananotherpage = '';

  // date_search_endpoint is use for date search endpoint
  datesearchendpoint: any = '';

  // send basic limit data
  limitcond: any = {
    limit: 10,
    skip: 0,
    pagecount: 1,
  };

  // other data
  libdata_leads: any = {

    preview_header: {

      header: "Preview Details",
      class: 'preheadercls'
  },
    basecondition: this.basecondition_object,
    detailview_override: [
      { key: 'fullname', val: 'Practice Name / Company' },
      { key: 'main_contact_name', val: 'Main Contact Name' },
      { key: 'email', val: 'Main Contact  Email' },
      { key: 'phone', val: 'Main Contact Phone' },
      { key: 'salesrepfullname', val: 'Parent Name' },
      { key: 'salesrep_email', val: 'Parent Email' },
      { key: 'salesrep_phone', val: 'Parent  Phone Number' },
      { key: 'company', val: 'Company Name' },
      { key: 'lastactivity_datetime', val: 'Last Activity' },
      { key: 'status', val: 'Status' },
      { key: 'prod', val: 'Product' },
      // { key: 'HPS_Representative', val: 'HPS Representative' },
    ],
    tableheaders: [
      'final_fullname',
      // 'fullname',
      'final_main_contact_name',
      // 'main_contact_name',
      'email',
      'phone',
      'salesrepfullname',
      'lastactivity_datetime',
      'status',
      // 'tags',
      'dispositionview'
    ],
    // searchBarFlagVal:true,
    // tableheaders name [key]
    // optional
    hidedeletebutton: false,
    notes: {
      label: 'Add Notes',
      addendpoint: 'api/addnotedataforlead',
      deleteendpoint: 'api/deletenotedataforlead',
      listendpoint: 'api/listnotedataforlead',
      user: this.userservice.userid,
      currentuserfullname:
        this.userservice.firstname + ' ' + this.userservice.lastname,
      header: 'fullname',
      tooltip: 'View notes',
    },
    updateendpointmany: 'api/leadupdate',
    updateendpoint: 'api/leadstatusupdate',
    deleteendpointmany: 'api/deletelead',
    hidemultipleselectbutton: null,
    hidecounter: null,
    hideaction: null,
    hideviewbutton: false, // (hide view button)

    // actioncolname: 'Actn',
    // not required,
    custombuttons: [
      // {
      //   label: 'Resend Welcome Mail',
      //   type: 'action',
      //   datatype: 'api',
      //   endpoint: 'api/resendemail',
      //   otherparam: ['type', '_id'],
      //   headermessage: 'Api Info',
      // },
      // {
      //   label: ' Send Reset Password Mail',
      //   type: 'action',
      //   datatype: 'api',
      //   endpoint: 'api/forgetpassword',
      //   otherparam: ['email', '_id'],
      // },
      // {
      //   label: 'Others Action',
      //   type: 'listner',
      //   id: 'a2',
      //   tooltip: 'Other Actions',
      //   classname:'OtherActions'
      // },
      // {
      //   label: 'View notes',
      //   route: '/user-details/',
      //   type: 'internallink',
      //   param: ['type', '_id'],
      //   tooltip: 'View notes',
      //   classname: 'viewnotes',
      // },
      {
        label: 'Folder View',
        route: '/user-details/',
        type: 'internallink',
        param: ['type', '_id'],
        tooltip: 'Folder View',
        classname: 'FolderView',
      },
      {
        label: 'Presentation Links',
        type: 'listner',
        id: 'plbtn',
        tooltip: 'Send Presentation Links',
        classname: 'PresentationLinks',
      },
    ],
  };

  libdata: any = {
    basecondition: this.basecondition_object,
    detailview_override: [
      { key: 'fullname', val: 'Name' },
      { key: 'email', val: 'Email' },
      // { key: 'salesrepfullname', val: 'Parent Name' },
      { key: 'salesrep_email', val: 'Parent Email' },
      { key: 'salesrep_phone', val: 'Parent  Phone Number' },
      { key: 'company', val: 'Company Name' },
      { key: 'status', val: 'Status' },
      { key: 'productsdata', val: 'Product' },
    ],
    tableheaders: [
      'final_fullname',
      // 'fullname',
      'email',
      'phone',
      // 'salesrepfullname',
      'createdon_datetime',
      'status',
      // 'tags',
    ],
    // searchBarFlagVal:true,
    // tableheaders name [key]
    // optional
    hidedeletebutton: false,
    notes: {
      label: 'Add Notes',
      addendpoint: 'api/addnotedataforlead',
      deleteendpoint: 'api/deletenotedataforlead',
      listendpoint: 'api/listnotedataforlead',
      user: this.userservice.userid,
      currentuserfullname:
        this.userservice.firstname + ' ' + this.userservice.lastname,
      header: 'fullname',
      tooltip: 'View notes',

    },
    updateendpointmany: 'api/leadupdate',
    updateendpoint: 'api/leadstatusupdate',
    deleteendpointmany: 'api/deletelead',
    hidemultipleselectbutton: null,
    hidecounter: null,
    hideaction: null,
    hideviewbutton: false, // (hide view button)

    // actioncolname: 'Actn',
    // not required,
    custombuttons: [
      // {
      //   label: 'Resend Welcome Mail',
      //   type: 'action',
      //   datatype: 'api',
      //   endpoint: 'api/resendemail',
      //   otherparam: ['type', '_id'],
      //   headermessage: 'Api Info',
      // },
      // {
      //   label: ' Send Reset Password Mail',
      //   type: 'action',
      //   datatype: 'api',
      //   endpoint: 'api/forgetpassword',
      //   otherparam: ['email', '_id'],
      // },
      // {
      //   label: 'Others Action',
      //   type: 'listner',
      //   id: 'a2',
      //   tooltip: 'Other Actions',
      //   classname:'OtherActions'
      // },
      {
        label: 'Folder View',
        route: '/user-details/',
        type: 'internallink',
        param: ['type', '_id'],
        tooltip: 'Folder View',
        classname: 'FolderView',
      },
      {
        label: 'Presentation Links',
        type: 'listner',
        id: 'plbtn',
        tooltip: 'Send Presentation Links',
        classname: 'PresentationLinks',
      },
    ],
  };
  searchsettings_schedule: any = {
    datesearch: [
      {
        startdatelabel: 'Appointments Before',
        enddatelabel: 'Appointments After',
        submit: 'Search',
        field: 'start_datetime_unix',
      },
      // {
      //   startdatelabel: 'Booking After', enddatelabel: 'Booking Before',
      //   submit: 'Search', field: 'booking_date'
      // }
    ],
    textsearch: [
      // { label: 'Search By  Attendees Name', field: 'attende_name_s' },
      { label: 'Search By  Attendees Email', field: 'attende_email_s' },
    ],
    search: [
      {
        label: 'Search By Attende Name',
        field: 'attende_name_s',
        values: '',
        serversearchdata: { endpoint: 'api/autocomplete_attendee_name' },
      },
    ],
    selectsearch: [
      {
        label: 'Search By Status',
        field: 'status',
        values: [
          { val: 0, name: 'Pending' },
          { val: 1, name: 'Done' },
          { val: 2, name: 'Canceled' },
        ],
      },
    ],
  };
  // send basic sort data
  sortdata: any = {
    type: 'desc',
    field: 'createdon_datetime',
    options: [
      'fullname',
      'email',
      'phone',
      'lastlogin_datetime',
      'status',
      'createdon_datetime',
    ],
  };
  sortdata_lead: any = {
    type: 'desc',
    field: 'lastactivity',
    options: [
      'fullname',
      'email',
      'phone',
      'lastlogin_datetime',
      'status',
      'lastactivity',
    ],
  };

  // this is a database collection or view name
  // datesearchsource: any = 'admin_blog_list';
  // datacollection
  datacollection: any = 'api/leadlistview';
  // source count
  datesearchsourcecount: any = 0;

  searchsettings: any = {
    datesearch: [
      {
        startdatelabel: 'Joined On Start Date',
        enddatelabel: 'Joined On End Date',
        submit: 'Search',
        field: 'createdon_datetime',

      },
    ],
    selectsearch: [
      { label: 'Search By Status', field: 'status', values: this.status },
    ],
    textsearch: [
      // { label: 'Search By Tags', field: 'tagsearch' },
      // { label: 'Search By Email', field: 'email_s' },
      // { label: 'Search By Parent Name', field: 'salesrepfullname_s' },
    ], 
    // this is use for  Autocomplete search
    search: [
      {
        label: 'Search By Parent Name',
        field: 'salesrepfullname_s',
        values: '',
        serversearchdata: { endpoint: 'api/exitsinglistname' },
      },
      {
        label: 'Search By Practice Name / Company',
        field: 'fullname_s',
        values: '',
        serversearchdata: {
          endpoint:
            'api/exitsinglistnameforlead?id=' +
            JSON.parse(this.cookieservice.get('userid')),
        },
      },
      {
        label: 'Search By Main Contact Name',
        field: 'main_contact_name_s',
        values: '',
        serversearchdata: { endpoint: 'api11/autocomplete-maincontactname' },
      },
      {
        label: 'Search By Main Contact Email',
        field: 'email_s',
        values: '',
        serversearchdata: {
          endpoint:
            'api/exitsinglistemailforlead?id=' +
            JSON.parse(this.cookieservice.get('userid')),
          // + '&type=' +
          // this.userservice.type,
        },
      },
      {
        label: 'Search By Billing Location',
        field: 'billing_location_s',
        values: '',
        serversearchdata: { endpoint: 'api/exitsingbillinglocationforlead' },
      },
      {
        label: 'Search By Shipping Location',
        field: 'shipping_location_s',
        values: '',
        serversearchdata: { endpoint: 'api/shippinglocationforlead' },
      },
      
      // {
      //   label: 'Search By Tags',
      //   field: 'tags_s',
      //   values: '',
      //   serversearchdata: { endpoint: 'api/exitsinglisttags' },
      // },
    ],
  };
  public notes_data: any = [];
  public userid: any;
  public userFullName: any;
  public showmoreflag: boolean = false;
  public token: any = '';
  public flagnote: boolean = false;
  public schedulepastdatatableflag: boolean = false;
  public statusarray_schedule: [
    { val: 0; name: 'Pending' },
    { val: 1; name: 'Done' },
    { val: 2; name: 'Canceled' }
  ];
  public statusarray_pastschedule: [
    { val: 0; name: 'Pending' },
    { val: 1; name: 'Done' },
    { val: 2; name: 'Canceled' }
  ];
  // public schedule_datatable: any = [];
  public modifyheaderarray_schedule: any = {
    event_title: 'Appointment Title',
    start_datetime_unix: 'Appointment Date',
    timespan: 'Appointment Duration',
    product_name: 'Concerning Product',
    username: 'Organizer Name',
    useremail: 'Organizer email',
    attende_name: 'Attendee Name',
    attende_email: 'Attendee Email',
    attende_type: 'Attendee Type',
    appoinmentStatus: 'Status',
  };
  public schedule_datatableskip: any = [];
  public sortdata_schedule: any = {
    type: 'asc',
    field: 'event_title',
    options: ['event_title', 'start_datetime_unix', 'appoinmentStatus'],
  };
  // send basic limit data
  public limitcond_schedule: any = {
    limit: 10,
    skip: 0,
    pagecount: 1,
  };
  public schedule_datatabledetailskip: any = [
    '_id',
    'slotTimeActual',
    'notes',
    'eid',
    'start_datetime_unix',
    'event_type_val',
    'googleevent',
    'event_title_s',
    'eventid',
    'is_google_event',
    'lesson_id',
    'id',
    'attende_email_s',
    'attende_name_s',
    'status',
    'user_id',
    'username_s',
    'product_name',
    'product_name_s',
    'product_id',
    'slot_end_time',
    'training_id',
    'parent_id',
  ];
  public libdata_schedule: any = {
    basecondition: {
      id: this.userservice.userid,
      event_type_val: 4,
      status: 0,
    },
    detailview_override: [
      { key: 'event_title', val: 'Event Title' },
      { key: 'event_type', val: 'Event Type' },
      { key: 'description', val: 'Description' },
      { key: 'username', val: 'Organiser Name' },
      { key: 'useremail', val: 'Organiser Email' },
      { key: 'notes', val: 'Notes' },
      { key: 'booking_date', val: 'Booked On' },
      { key: 'startdate', val: 'Appointment Date' },
      { key: 'slot', val: 'Slot' },
      { key: 'timespan', val: 'Time span' },
      { key: 'timezone', val: 'Time zone' },
      { key: 'product_name', val: 'Product Name' },
      { key: 'attende_name', val: 'Attende Name' },
      { key: 'attende_email', val: 'Attende Email' },
      { key: 'attende_type', val: 'Attende Type' },
    ],
    // table headline
    tableheaders: [
      'event_title',
      'attende_name',
      'attende_email',
      'attende_type',
      'start_datetime_unix',
      'appoinmentStatus',
    ],
    custombuttons: [
      // {
      //   label: 'View Appointment',
      //   route: '/contract-manager/contract/view/',
      //   type: 'internallink',
      //   tooltip: ''View Job Ticket',
      //   classname: 'view_jobticketinBTN',
      //   param: ['_id'],
      // },

      {
        label: 'Cancel',
        type: 'listner',
        id: 'btn_cancel',
        cond: 'status',
        condval: 0,
        // refreshdata: true,
        classname: 'cancelBTN',
        tooltip: 'Cancel',
      },
      // {
      //   label: 'Reschedule',
      //   route:
      //     '/calendar-management/manage-appointment/reschedule-appointments/',
      //   type: 'internallink',
      //   cond: 'status',
      //   condval: 0,
      //   param: ['_id', 'event_type_val'],
      //   classname: 'rescheduleBTN',
      //   tooltip: 'Reschedule',
      // },
      {
        label: 'Reschedule',
        type: 'listner',
        cond: 'status',
        condval: 0,
        classname: 'rescheduleBTN',
        tooltip: 'Reschedule',
      },
      {
        label: 'Mark as Done',
        type: 'listner',
        id: 'btn_mark_as_done',
        cond: 'status',
        condval: 0,
        classname: 'btn_mark_as_done',
        tooltip: 'Mark as Done',
        refreshdata: true,
      },
    ],
    notes: {
      label: 'Add Notes',
      addendpoint: 'api/addnoteforappoinment',
      deleteendpoint: 'api/deletenotedataforlead',
      listendpoint: 'api/list-noteforappoinment',
      user: this.userservice.userid,
      currentuserfullname:
        this.userservice.firstname + ' ' + this.userservice.lastname,
      header: 'Add Notes',
    },
    // hidestatustogglebutton: true,
    hidedeletebutton: true,
    updateendpointmany: '', // update multiple user
    updateendpoint: '', // update status
    deleteendpointmany: '', // delete multiple user
    hidemultipleselectbutton: true, // hide multiple selection button (false)
    hidecounter: null, // hide counter (false)
    hideaction: false, // hide action (false)
    hideviewbutton: false, // (hide view button)
    hideeditbutton: true, // all these button options are optional not mandatory
    hidestatustogglebutton: true,
  };
  datacollection_schedule = 'api/genarelcall-data';
  schedule_searchsettings: any = {
    datesearch: [
      {
        startdatelabel: 'Appointments After',
        enddatelabel: 'Appointments Before',
        submit: 'Search',
        field: 'start_datetime_unix',
      },
      // {
      //   startdatelabel: 'Booking After', enddatelabel: 'Booking Before',
      //   submit: 'Search', field: 'booking_date'
      // }
    ], // this is use for  Autocomplete search
  };
  // schedule_datesearchsourcecount: any = 0;
  // past genarelcall start

  // public schedulepast_datatable: any = [];
  public modifyheaderarray_schedulepast: any = {
    event_title: 'Appointment Title',
    start_datetime_unix: 'Appointment Date',
    timespan: 'Appointment Duration',
    product_name: 'Concerning Product',
    username: 'Organizer Name',
    useremail: 'Organizer email',
    attende_name: 'Attendee Name',
    attende_email: 'Attendee Email',
    attende_type: 'Attendee Type',
    appoinmentStatus: 'Status',
  };
  public schedulepast_datatableskip: any = [];
  public sortdata_schedulepast: any = {
    type: 'desc',
    field: 'event_title',
    options: ['event_title', 'appoinmentStatus'],
  };
  // send basic limit data
  public limitcond_schedulepast: any = {
    limit: 10,
    skip: 0,
    pagecount: 1,
  };
  public schedulepast_datatabledetailskip: any = [
    '_id',
    'slotTimeActual',
    'notes',
    'eid',
    'start_datetime_unix',
    'event_type_val',
    'googleevent',
    'event_title_s',
    'eventid',
    'is_google_event',
    'lesson_id',
    'id',
    'attende_email_s',
    'attende_name_s',
    'status',
    'user_id',
    'username_s',
    'product_name',
    'product_name_s',
    'product_id',
    'slot_end_time',
    'training_id',
    'parent_id',
  ];
  public libdata_schedulepast: any = {
    basecondition: {
      id: this.userservice.userid,
      event_type_val: 4,
    },
    detailview_override: [
      { key: 'event_title', val: 'Event Title' },
      { key: 'event_type', val: 'Event Type' },
      { key: 'description', val: 'Description' },
      { key: 'username', val: 'Organiser Name' },
      { key: 'useremail', val: 'Organiser Email' },
      { key: 'notes', val: 'Notes' },
      { key: 'booking_date', val: 'Booked On' },
      { key: 'startdate', val: 'Appointment Date' },
      { key: 'slot', val: 'Slot' },
      { key: 'timespan', val: 'Time span' },
      { key: 'timezone', val: 'Time zone' },
      { key: 'product_name', val: 'Product Name' },
      { key: 'attende_name', val: 'Attende Name' },
      { key: 'attende_email', val: 'Attende Email' },
      { key: 'attende_type', val: 'Attende Type' },
    ],
    // table headline
    tableheaders: [
      'event_title',
      'attende_name',
      'attende_email',
      'attende_type',
      'start_datetime_unix',
      'appoinmentStatus',
    ],
    custombuttons: [
      // {
      //   label: 'View Appointment',
      //   route: '/contract-manager/contract/view/',
      //   type: 'internallink',
      //   tooltip: ''View Job Ticket',
      //   classname: 'view_jobticketinBTN',
      //   param: ['_id'],
      // },
      // {
      //   label: 'Cancel',
      //   type: 'listner',
      //   id: 'btn_cancel',
      //   cond: 'status', condval: 0,
      //   // refreshdata: true,
      //   classname: 'cancelBTN',
      //   tooltip: 'Cancel',
      // },
      // {
      //   label: 'Reschedule',
      //   route:
      //     '/calendar-management/manage-appointment/reschedule-appointments/',
      //   type: 'internallink',
      //   cond: 'status',
      //   condval: 0,
      //   param: ['_id', 'event_type'],
      //   classname: 'rescheduleBTN',
      //   tooltip: 'Reschedule',
      // },
    ],
    notes: {
      label: 'Add Notes',
      addendpoint: 'api/addnoteforappoinment',
      deleteendpoint: 'api/deletenotedataforlead',
      listendpoint: 'api/list-noteforappoinment',
      user: this.userservice.userid,
      currentuserfullname:
        this.userservice.firstname + ' ' + this.userservice.lastname,
      header: 'Add Notes',
    },
    // hidestatustogglebutton: true,
    hidedeletebutton: true,
    updateendpointmany: '', // update multiple user
    updateendpoint: '', // update status
    deleteendpointmany: '', // delete multiple user
    hidemultipleselectbutton: true, // hide multiple selection button (false)
    hidecounter: null, // hide counter (false)
    hideaction: false, // hide action (false)
    hideviewbutton: false, // (hide view button)
    hideeditbutton: true, // all these button options are optional not mandatory
    hidestatustogglebutton: true,
  };
  datacollection_schedulepast = 'api/genarelcall-past-data';
  schedulepast_searchsettings: any = {
    datesearch: [
      {
        startdatelabel: 'Appointments After',
        enddatelabel: 'Appointments Before',
        submit: 'Search',
        field: 'start_datetime_unix',
      },
      // {
      //   startdatelabel: 'Booking After', enddatelabel: 'Booking Before',
      //   submit: 'Search', field: 'booking_date'
      // }
    ], // this is use for  Autocomplete search
    textsearch: [
      // { label: 'Search By  Attendees Name', field: 'attende_name_s' },

      { label: 'Search By  Attendees Email', field: 'attende_email_s' },
    ],
    search: [
      {
        label: 'Search By Attende Name',
        field: 'attende_name_s',
        values: '',
        serversearchdata: { endpoint: 'api/autocomplete_attendee_name' },
      },
    ],
    selectsearch: [
      {
        label: 'Search By Status',
        field: 'status',
        values: [
          { val: 0, name: 'Pending' },
          { val: 1, name: 'Done' },
          { val: 2, name: 'Canceled' },
        ],
      },
    ],
  };

  // past genarelcall end
  public type_check: any = '';
  // lib details for followup and discovery call 
  public followupdatatable: any = [];
  // tabel data skip 
  public followup_datatableskip: any = [
    '_id',
    'slotTimeActual',
    'notes',
    'eid',
    'start_datetime_unix',
    'event_type_val',
    'googleevent',
    'event_title_s',
    'eventid',
    'is_google_event',
    'lesson_id',
    'id',
    'attende_email_s',
    'attende_name_s',
    'status',
    'user_id',
    'username_s',
    'product_name',
    'product_name_s',
    'product_id',
    'slot_end_time',
    'training_id',
    'parent_id',
  ];
  // tabel headerarr data modify
  public modifyheaderarray_followup: any = {
    event_title: 'Appointment Title',
    // event_type: 'Event Type',
    datetimeslot: 'follow up time',
    timespan: 'Appointment Duration',
    username: 'Organizer Name',
    useremail: 'Organizer email',
    attende_name: 'Lead Name',
    attende_email: 'Lead Email',
    attende_type: 'Phone Number',
    followup: 'No of calls',
    attende_phone: 'Phone',
    contact_person: 'Contact Person',
    dispositionfollow: 'Disposition'
  }
  //
  followup_deleteendpoint: any = ''
  public followup_statusarray: [
    { val: 0; name: 'Pending' },
    { val: 1; name: 'Done' },
    { val: 2; name: 'Canceled' }
  ]
  followup_editroute = ''
  public followup_datatabledetailskip: any = [
    '_id',
    'slotTimeActual',
    'notes',
    'eid',
    'start_datetime_unix',
    'event_type_val',
    'googleevent',
    'event_title_s',
    'eventid',
    'is_google_event',
    'lesson_id',
    'id',
    'attende_email_s',
    'attende_name_s',
    'status',
    'user_id',
    'username_s',
    'product_name',
    'product_name_s',
    'product_id',
    'slot_end_time',
    'training_id',
    'parent_id',
    'markasdoneflag',
    'flag_for_followup',
    'flag_for_show',
    'pendingflag',
    'event_type_val_s',
    'appoinmentStatus',
    'event_type_s',
    'start_time',
    'bookinguser',
    'organizer_email',
    'organizer_name',
    'attende_phone',
    'attende_name',
    'event_type',
    'createdon_datetime',
    'datetimeslot',
    'lid',
    'products',
    'ss',
    'product',
    'timeflag',
    'timenow',
    'unow',
    'datefld',
    'date2',
    'unixtoday',
    'qa',
    'dateDifference',
    'difftype',
    'followups',
    'followup',
    'disposition',
    'dispositionfollow_s',
    'offsetvalue',
    'callcounts',
    'dispo',
    'dispositionlead',
    'contact_person_s'

  ];
  public sortdata_followup: any = {
    type: 'asc',
    field: 'datetimeslot',
    options: ['attende_name', 'attende_phone', 'ss', 'event_type'],
  };
  followup_datacollection: any = 'api8/list-followup-data';
  public followup_limitcond: any = {
    limit: 10,
    skip: 0,
    pagecount: 1,
  };
  public libdata_followup: any = {
    basecondition: {
      // bookinguser: this.cookieservice.get('userid'),
      // bookinguser: JSON.parse(this.cookieservice.get('userid'))
    },

    detailview_override: [
      { key: 'event_title', val: 'Event Title' },
      { key: 'contact_person', val: 'Contact Person' },
      { key: 'description', val: 'Description' },
      { key: 'username', val: 'Organiser Name' },
      { key: 'useremail', val: 'Organiser Email' },
      { key: 'dispositionfollow', val: 'Disposition' },
      { key: 'booking_date', val: 'Booked On' },
      { key: 'startdate', val: 'Appointment Date' },
      { key: 'slot', val: 'Slot' },
      { key: 'timespan', val: 'Time span' },
      { key: 'timezoneName', val: 'Time zone' },
      { key: 'product_name', val: 'Product Name' },
      { key: 'attende_name', val: 'Attende Name' },
      { key: 'attende_email', val: 'Lead Email' },
      { key: 'attende_type', val: 'Attende Type' },
    ],
    // table headline
    tableheaders: [
      'attende_name',
      'attende_phone',
      'contact_person',
      'datetimeslot',
      'followup',
      'dispositionfollow'
    ],
    customselectbuttons: [
      {
        label: "Update Disposition",
        type: 'listner',
        // cond: 'status',
        // condval: 0,
        classname: 'disposition_warranty',
        tooltip: 'Disposition for Follow up call '
      }],
    custombuttons: [
      // {
      //   label: 'Edit',
      //   type: 'listner',
      //   cond: 'status',
      //   condval: 0,
      //   id: 'i1',
      //   classname: 'userEdit',
      //   tooltip: 'Reschedule',
      // },
      {
        label: 'Folder View',
        // route: '/user-details/',
        type: 'listner',
        // param: ['type', '_id'],
        tooltip: 'Folder View',
        classname: 'FolderView',
      },
      {
        label: 'Follow Up Call',
        type: 'listner',
        id: 'follow_up_call',
        classname: 'follow_up_call',
        tooltip: 'Follow Up Calls',
        refreshdata: true,
      },
      {
        label: 'Disposition',
        type: 'listner',
        // cond: 'status',
        // condval: 0,
        classname: 'disposition_warranty',
        tooltip: 'Disposition for Follow up call ',
        // refreshdata: true,
      },
      {
        label: 'View All Disposition',
        type: 'listner',
        // cond: 'status',
        // condval: 0,
        classname: 'disposition',
        tooltip: 'View All Disposition',
        // refreshdata: true,
      },
    ],
    notes: {
      label: 'Add Notes',
      addendpoint: 'api8/addnoteforfollowup',
      deleteendpoint: 'api8/deletenotedataforfollowup',
      listendpoint: 'api8/listnotesfor-followupcalls',
      user: this.userservice.userid,
      currentuserfullname:
        this.userservice.firstname + ' ' + this.userservice.lastname,
      header: 'attende_name_s',
      classname: 'followupcallnotes',
      tooltip: 'Add Notes',
    },
    // hidestatustogglebutton: true,
    hidedeletebutton: true,
    updateendpointmany: '', // update multiple user
    updateendpoint: '', // update status
    deleteendpointmany: '', // delete multiple user
    hidemultipleselectbutton: null, // hide multiple selection button (false)
    hidecounter: null, // hide counter (false)
    hideaction: false, // hide action (false)
    hideviewbutton: false, // (hide view button)
    hideeditbutton: true, // all these button options are optional not mandatory
    hidestatustogglebutton: true,
    hideupdatemany: true,
    hidedeletemany: true,
  }
  // public followup_data: [

  // ]

  followup_datesearchsourcecount: any = 0;
  public followup_datatableflag: boolean = false;
  public searchsettings_followup: any = {
    selectsearch: [
      {
        label: 'Upcoming follow up call', field: 'timeflag',
        values: [
          { val: [1], name: 'All follow up call' },
          { val: [1, 2], name: 'Past follow up call' },
          { val: [1, 3], name: 'Upcoming follow up call' }
        ],
        value: [1, 3]
      },
    ],
    textsearch: [
      { label: 'Search By  Lead Phone', field: 'attende_phone' },
      { label: 'Search By  Contact Phone', field: 'contact_person_s' },
    ],
    search: [
      {
        label: 'Search By Product',
        field: 'products',
        values: '',
        serversearchdata: {
          endpoint:
            'api8/autocomplete-followup-product'
        },
      },
      {
        label: 'Search By Disposition',
        field: 'dispositionfollow_s',
        values: '',
        serversearchdata: {
          endpoint:
            'api8/autocompletedispositon'
        },
      },
      {
        label: 'Search By Lead Name',
        field: 'attende_name_s',
        values: '',
        serversearchdata: {
          endpoint:
            'api8/autocomplete-followup-attendee'
        },
      },
    ],
    datesearch: [
      {
        startdatelabel: ' Start Date',
        enddatelabel: 'End Date',
        submit: 'Search',
        field: 'datetimeslot',
      },

    ],
    // this is use for  select search
    // this is use for  Autocomplete search
  }
  public selected = {
    value: 0
  }
  constructor(
    public router: Router,
    public Apiservice: ApiService,
    public http: HttpClient,
    public cookieservice: CookieService,
    public activatedRoute: ActivatedRoute,
    public userservice: UserserviceService,
    public bottomSheet: MatBottomSheet,
    public snackBar: MatSnackBar,
    public formBuilder: FormBuilder,
    public dialog: MatDialog,
    @Inject(DOCUMENT) private document: Document,
    public elementRef: ElementRef,
    private loaderService: LoaderService,
    public ObservableService: ObservableService

  ) {




    console.log(this.activatedRoute.snapshot.queryParams.id);
    if (typeof (this.activatedRoute.snapshot.queryParams.id) != 'undefined' && this.activatedRoute.snapshot.queryParams.id != null && this.activatedRoute.snapshot.queryParams.id != '') {
      this.onTabClick({ index: this.activatedRoute.snapshot.queryParams.id });
    }
    this.type_check = '';
    this.type_check = JSON.parse(this.cookieservice.get('type'));
    if (this.type_check == 'admin') {
      this.libdata_leads.hideeditbutton = true;
    }
    this.searchForm = this.formBuilder.group({
      created_datetime_start: [''],
      created_datetime_end: [''],
      user: [''],
      parentdata: [''],
    });
    if (this.userservice.type == 'admin') {
      this.basecondition_object['type'] = 'lead';
    } else {
      this.basecondition_object['type'] = 'lead';
      this.basecondition_object['parentid'] = this.userservice.userid;
    }
    this.token = this.cookieservice.get('jwtToken');
    console.log(
      this.libdata.basecondition,
      'this.libdata.basecondition',
      this.userservice.type
    );

    this.userid = this.userservice.userid;
    this.userFullName =
      this.userservice.firstname + ' ' + this.userservice.lastname; // user dtls ( cookieservice)
    const endpointc = 'api/leadlistview-count';
    const endpoint = 'api/leadlistview';
    const data: any = {
      // How many data showing in first page
      secret: this.userservice.secret,
      token: this.userservice.jwttoken,
      source: '',
      condition: {
        // condition for how much data show in one time
        limit: 10,
        skip: 0,
      },
      sort: {
        type: 'desc', // data sort by decreasing order
        field: 'createdon_datetime', // data sort by first name
      },
      searchcondition: this.basecondition_object,
    };

    if (JSON.parse(this.cookieservice.get('type')) != 'admin') {
      // console.log('*(*(*(*(**')
      data.searchcondition.parentid = this.userservice.userid;
      this.editroute =
        JSON.parse(this.cookieservice.get('type')) + '/lead/edit';
    }

    this.Apiservice.getDataWithoutToken(data, endpointc).subscribe(
      (res: any) => {
        this.datesearchsourcecount = res.count; // sarch count  (How many data there)
      }
    );
  }

  bindrelatedinfoevent() {
    console.log(
      'bindrelatedinfoevent called  ',
      this.elementRef.nativeElement.getElementsByClassName('related_info_icon')
        .length
    );
    const reatedclassarr =
      this.elementRef.nativeElement.querySelectorAll('.related_info_icon');
    console.log(reatedclassarr);

    let counter: number = 0;
    for (let i1: number = 0; i1 < reatedclassarr.length; i1++) {
      document
        .querySelectorAll('.related_info_icon')
      [i1].addEventListener('click', this.showrelatedinfo.bind(this));
      document
        .querySelectorAll('.related_info_icon')
      [i1].setAttribute('title', 'More Information');

      if (
        document.querySelectorAll('.cuploaded.cuploadedtwelvehr')[i1] !=
        undefined
      ) {
        document
          .querySelectorAll('.cuploaded.cuploadedtwelvehr')
        [i1].closest('tr')
          .classList.add('cuploadedtwelvehr');
      }
      if (
        document.querySelectorAll('.cuploaded.cuploadedsixhr')[i1] != undefined
      ) {
        document
          .querySelectorAll('.cuploaded.cuploadedsixhr')
        [i1].closest('tr')
          .classList.add('cuploadedsixhr');
      }
      if (
        document.querySelectorAll('.cuploaded.cuploadedtwohr')[i1] != undefined
      ) {
        document
          .querySelectorAll('.cuploaded.cuploadedtwohr')
        [i1].closest('tr')
          .classList.add('cuploadedtwohr');
      }
      if (document.querySelectorAll('.creview.twodays')[i1] != undefined) {
        document
          .querySelectorAll('.creview.twodays')
        [i1].closest('tr')
          .classList.add('twodays');
      }
      counter++;
    }
  }

  //related info modal fire here and next span class which is report id basically
  showrelatedinfo(el: any) {
    console.log('related info event click f showrelatedinfo', el);
    let rid: any = el.target.nextSibling.getAttribute('class').trim();
    // console.log('related info event click ', el.target.getAttribute('class'), el.target.nextSibling.getAttribute('class'),rid);
    console.log(rid);
    let data: any = {
      secret: this.cookieservice.get('secret'),
      token: this.cookieservice.get('jwtToken'),
      condition: { _id: rid },
    };
    this.loaderService.isLoading.next(true);
    this.Apiservice.getDataWithoutToken(data, 'api/getonelead').subscribe(
      (res: any) => {
        this.loaderService.isLoading.next(false);
        let dialogRef = this.dialog.open(Dialogmoremodal, {
          data: {
            data: res.result[0],
          },
          panelClass: 'ContractLife_modal',
        });
      }
    );
  }

  moremodal() {
    console.log('lllllllllll');
  }
  search_data_for_note() {
    console.log('ppppppppppppppp');

    let data = {
      secret: this.userservice.secret,
      token: this.token,
      type: this.userservice.type,
      source: '',
      condition: {
        // condition for how much data show in one time
        limit: this.count,
        skip: 0,
      },
      sort: {
        type: 'desc', // data sort by decreasing order
        field: 'created_datetime', // data sort by first name
      },
    };
    if (this.userservice.type == 'technological-consultant') {
      data['id'] = this.userservice.userid;
    }
    if (this.searchForm.value['created_datetime_start'] != '') {
      this.searchForm.value['created_datetime_start'] = new Date(
        this.searchForm.value['created_datetime_start']
      ).getTime();
    }
    if (this.searchForm.value['created_datetime_end'] != '') {
      this.searchForm.value['created_datetime_end'] = new Date(
        this.searchForm.value['created_datetime_end']
      ).getTime();
    }
    console.log(this.searchForm.value);

    if (
      this.searchForm.value != '' &&
      this.searchForm.value != undefined &&
      this.searchForm.value != null
    ) {
      Object.keys(this.searchForm.value).forEach(
        (k) => this.searchForm.value[k] == '' && delete this.searchForm.value[k]
      );
      data['searchcondition'] = this.searchForm.value;
    }
    this.Apiservice.getDataWithoutToken(data, 'api/leadesnotes').subscribe(
      (res: any) => {
        if (res.status == 'success') {
          this.showmoreflag = true;
          console.log(res);

          this.progressSpinner.loading = false;

          this.notes_data = res.count;
          console.log(this.notes_data, ' this.notes_dataaaaaaaaa');
          // sarch count  (How many data there)
          if (this.notes_data.length == 0) {
            this.flagnote = true;
            console.log("flagnote true==>",this.flagnote);
          } else {
            this.flagnote = false;
            console.log("flagnote false==>",this.flagnote);

          }
        }
      }
    );
    console.log(this.searchForm.value, '+++++++++search note data+++++++++');
  }
  refresh_data_for_note() {
    this.flagnote = false;
    this.searchForm.reset();
    let data = {
      secret: this.userservice.secret,
      token: this.token,
      type: this.userservice.type,
      source: '',
      condition: {
        // condition for how much data show in one time
        limit: this.count,
        skip: 0,
        // skip: 0,
      },
      sort: {
        type: 'desc', // data sort by decreasing order
        field: 'created_datetime', // data sort by first name
      },
    };
    if (this.userservice.type == 'technological-consultant') {
      data['id'] = this.userservice.userid;
    }
    this.Apiservice.getDataWithoutToken(data, 'api/leadesnotes').subscribe(
      (res: any) => {
        if (res.status == 'success') {
          this.showmoreflag = true;
          console.log(res, 'responce notes data');

          this.progressSpinner.loading = false;

          this.notes_data = res.results.count; // sarch count  (How many data there)
          if (this.notes_data.length == 0) {
            this.flagnote = true;
          } else {
            this.flagnote = false;
          }
        }
      }
    );
    console.log(this.searchForm.value, '+++++++++search note data+++++++++');
  }
  addlead() {
    this.router.navigateByUrl('/' + JSON.parse(this.cookieservice.get('type')) + '/lead/add');
  }
  ngOnInit() {

    console.log(this.activatedRoute.snapshot, 'lead components ', this.activatedRoute.snapshot.params);

    const body = document.getElementsByTagName('body')[0];
    body.classList.add('leadlistpage');
    setTimeout(() => {
      this.bindrelatedinfoevent();
    }, 3000);
    this.activatedRoute.data.forEach((res: any) => {
      this.leadListdatatable = res.resolvedata.results.res; // data store from backend
      console.log(this.leadListdatatable, 'AFFFF');
      if (this.leadListdatatable.length == 0) {
        this.leadListdatatableflag = true;
      } else {
        this.leadListdatatableflag = false;
      }
    });

    console.log(
      document.getElementsByClassName('more'),
      'njkghhhhhhhhhhjhgkjhgkjhgkjhgkkjhy',
      document.querySelectorAll('.more')
    );
    var photo = document.getElementsByClassName('more');

    let elements = document.getElementsByClassName('more');

    var myFunction = function () {
      console.log(myFunction, 'myFunction');

      var attribute = this.getAttribute('data-myattribute');
      alert(attribute);
    };

    for (var i = 0; i < elements.length; i++) {
      elements[i].addEventListener('click', myFunction, false);
    }
    // if (this.cookieservice.get('type') == 'technological-consultant') {
    //   this.libdata_followup.basecondition = {
    //     bookinguser: this.cookieservice.get('userid')
    //   }
    // }

    if (JSON.parse(this.cookieservice.get('type')) == 'technological-consultant') {
      this.libdata_followup.basecondition = {
        bookinguser: JSON.parse(this.cookieservice.get('userid'))
      }
    }

    if (JSON.parse(this.cookieservice.get('type')) == 'admin') {
      this.libdata_followup = {
        basecondition: {
        },
        detailview_override: [
          { key: 'event_title', val: 'Event Title' },
          { key: 'contact_person', val: 'Contact Person' },
          { key: 'description', val: 'Description' },
          { key: 'username', val: 'Organiser Name' },
          { key: 'useremail', val: 'Organiser Email' },
          { key: 'dispositionfollow', val: 'Disposition' },
          { key: 'booking_date', val: 'Booked On' },
          { key: 'startdate', val: 'Appointment Date' },
          { key: 'slot', val: 'Slot' },
          { key: 'timespan', val: 'Time span' },
          { key: 'timezoneName', val: 'Time zone' },
          { key: 'product_name', val: 'Product Name' },
          { key: 'attende_name', val: 'Attende Name' },
          { key: 'attende_email', val: 'Lead Email' },
          { key: 'attende_type', val: 'Attende Type' },
        ],
        // table headline
        tableheaders: [
          'event_title',
          'attende_name',
          'attende_phone',
          'contact_person',
          'datetimeslot',
          'followup',
          'dispositionfollow'
        ],
        custombuttons: [
          {
            label: 'View All Disposition',
            type: 'listner',
            // cond: 'status',
            // condval: 0,
            classname: 'disposition',
            tooltip: 'View All Disposition',
            // refreshdata: true,
          },
        ],
        // hidestatustogglebutton: true,
        hidedeletebutton: true,
        updateendpointmany: '', // update multiple user
        updateendpoint: '', // update status
        deleteendpointmany: '', // delete multiple user
        hidemultipleselectbutton: true, // hide multiple selection button (false)
        hidecounter: null, // hide counter (false)
        hideaction: false, // hide action (false)
        hideviewbutton: false, // (hide view button)
        hideeditbutton: true, // all these button options are optional not mandatory
        hidestatustogglebutton: true,
        hideupdatemany: true,
        hidedeletemany: true,
      }
    }
    console.log("this.libdata_leads.tableheaders==>", this.libdata_leads.tableheaders);
    if (JSON.parse(this.cookieservice.get('type')) != 'admin') {
      for (const i in this.libdata_leads.tableheaders) {
        console.log("this.libdata_leads.tableheaders in i==>", i);
        if (this.libdata_leads.tableheaders[i] === "salesrepfullname") {
          this.libdata_leads.tableheaders.splice(i, 1);
        }
      }
      console.log("this.libdata_leads.tableheaders after==>", this.libdata_leads.tableheaders);
    }
    console.log("this.searchsettings.search==>", this.searchsettings.search);

    if (JSON.parse(this.cookieservice.get('type')) != 'admin') {
      
      this.searchsettings.search=this.searchsettings.search.filter(i => i.label != 'Search By Parent Name')
    console.log("this.searchsettings.search after==>", this.searchsettings.search);
    }
  }
  listenLiblistingChange(val: any) {
    console.log(val, '------------');
    if (
      val.custombuttonclick != null &&
      val.custombuttonclick.btninfo != null &&
      val.custombuttonclick.btninfo.label === 'Others Action'
    ) {
      const btnSheet = this.bottomSheet.open(BottonSheetLead, {
        data: val,
      });
      btnSheet.afterDismissed().subscribe((res) => {
        if (res === 'yes') {
          // console.log('++++', res);
          this.updatetable = true;
        }
      });
    }
    if (
      val.custombuttonclick != null &&
      val.custombuttonclick.btninfo != null &&
      val.custombuttonclick.btninfo.label === 'Presentation Links'
    ) {
      console.log(val.custombuttonclick);
      let id = val.custombuttonclick.data._id;
      let product = val.custombuttonclick.data.products;
      // console.log('sendmail ++++++++++++++',id);
      // console.log(this.userservice.userid);

      // console.log(this.activatedRoute.snapshot.params._id);
      // console.log(product+'/'+this.userservice.userid+'/'+id);

      let form_data = {
        email_data: {
          subject: 'Presentation Links',
          template: '',
          productsarray: [product],
          presentationLink:
            'https://marketing.betoparedes.com/marketing-review-new/' +
            product +
            '/' +
            this.userservice.userid +
            '/' +
            id,
          priority: 1,
          sent_to: val.custombuttonclick.data.email,
          user_name: this.userservice.fullname,
        },
        user_id: this.userservice.userid,
        leadid: id,
        token: this.userservice.jwttoken,
        secret: this.userservice.secret,
      };

      this.Apiservice.customRequest(
        form_data,
        'api/presentationlinkemail',
        environment['api_url']
      ).subscribe((response: any) => {
        console.log('email*************response*****>', response);

        if (response == 'success') {
          this.progressSpinner.loading = false;
          this.openSnackBar('Email Send Successfully.', null);

          // this.dialogRef.close(this.data);
        } else {
          this.openSnackBar('Something went wrong. Please try again.', null);
        }
      });
    }
  }

  listenLiblistingChange_sechedule(event: any) {
    console.log('eventtt', event);
    if (event.custombuttonclick != undefined) {
      if (event.custombuttonclick.btninfo.id === 'btn_cancel') {
        const dialogRef = this.dialog.open(cancelappoinmentAlert);

        dialogRef.afterClosed().subscribe((result) => {
          // console.log(`Dialog result: ${result}`);
          console.log(result, 'testingggggggggggggggggggggggggggggggggggg');
          if (result.flag == true) {
            // google_events
            let note = result.data;
            let google_events_data: any = {
              userid: event.custombuttonclick.data.user_id,
              source: 'google_events',
              token: this.userservice.jwttoken,
              note: note,
            };
            this.Apiservice.customRequest(
              google_events_data,
              'get-refresh-token',
              environment['api_calender_url']
            ).subscribe((response: any) => {
              console.log('responseresponseresponse', response);
              let r_token = response.refresh_token;
              // console.log(r_token, 'r_token')
              const data: any = {
                token: this.userservice.jwttoken,
                _id: event.custombuttonclick.data._id,
                attende_email: event.custombuttonclick.data.attende_email,
                attende_name: event.custombuttonclick.data.attende_name,
                attende_type: event.custombuttonclick.data.attende_type,
                useremail: event.custombuttonclick.data.useremail,
                appointment_date: event.custombuttonclick.data.startdate,
                username: event.custombuttonclick.data.username,
                slot: event.custombuttonclick.data.slot,
                event_type: event.custombuttonclick.data.event_type,
                event_title: event.custombuttonclick.data.event_title,
                // 'appointment_date': '01/27/2021',
                refresh_token: r_token,
                eid: event.custombuttonclick.data.eid,
                slotTimeActual: event.custombuttonclick.data.slotTimeActual,
                googleevent: event.custombuttonclick.data.googleevent,
                note: note,
              };
              // console.log(data, 'data')
              this.Apiservice.customRequest(
                data,
                'google-calendar-event/delete-booked-event',
                environment['google_calendar_api']
              ).subscribe((response: any) => {
                // console.log('response', response);
                setTimeout(() => {
                  this.updatetable = true;
                }, 10);
              });

              this.Apiservice.customRequest(
                data,
                'delete-booked-event',
                environment['api_calender_url']
              ).subscribe((response: any) => {
                console.log(response, 'responsedelete');
              });
            });
          }
        });
      }
      if (event.custombuttonclick != undefined) {
        if (event.custombuttonclick.btninfo.id === 'btn_mark_as_done') {
          const dialogRef = this.dialog.open(DialogmarkasdoneAlert);
          dialogRef.afterClosed().subscribe((result) => {
            const mark_data: any = {
              _id: event.custombuttonclick.data._id,
            };
            if (result === 'true') {
              this.Apiservice.customRequest(
                mark_data,
                'update-status-done',
                environment['api_calender_url']
              ).subscribe((response: any) => {
                console.log('response', response);
                // this.configData.updatetable = !this.configData.updatetable;
                setTimeout(() => {
                  this.updatetable = true;
                }, 10);
              });
            }
          });
        }
      }

      // if (event.custombuttonclick.btninfo.id === 'btn_mark_as_done') {
      //   console.log(event.custombuttonclick.btninfo.id, 'mark')

      //   const mark_data: any = {
      //     _id: event.custombuttonclick.data._id
      //   }

      //   this.apiService.customRequest(mark_data, 'update-status-done', environment['api_calender_url']).subscribe((response: any) => {
      //     console.log(response, 'response')
      //     setTimeout(() => {
      //       this.updatetable =true;
      //       }, 10);
      //   })
      // }

      if (event.custombuttonclick.btninfo.classname === 'rescheduleBTN') {
        console.log(event.custombuttonclick.btninfo.id, 'mark');
        this.router.navigateByUrl(
          '/calendar-management/manage-appointment/reschedule-appointments/' +
          event.custombuttonclick.data._id +
          '/' +
          event.custombuttonclick.data.event_type_val +
          '?genarelcallappoinment=true'
        );
      }
    }
  }


  listenLiblistingChangefollowup(event: any) {
    console.log(event, 'eventtt',);
    if (event.res != null && event.res != undefined) {
      this.followup_datesearchsourcecount = event.res

    }
    if (event.custombuttonclick != undefined) {
      if (event.custombuttonclick.btninfo.id === 'btn_cancel') {
        const dialogRef = this.dialog.open(cancelappoinmentAlert);

        dialogRef.afterClosed().subscribe((result) => {
          // console.log(`Dialog result: ${result}`);
          console.log(result, 'testingggggggggggggggggggggggggggggggggggg');
          if (result.flag == true) {
            // google_events
            let note = result.data;
            let google_events_data: any = {
              userid: event.custombuttonclick.data.user_id,
              source: 'google_events',
              token: this.userservice.jwttoken,
              note: note,
            };
            this.Apiservice.customRequest(
              google_events_data,
              'get-refresh-token',
              environment['api_calender_url']
            ).subscribe((response: any) => {
              console.log('responseresponseresponse', response);
              let r_token = response.refresh_token;
              // console.log(r_token, 'r_token')
              const data: any = {
                token: this.userservice.jwttoken,
                _id: event.custombuttonclick.data._id,
                attende_email: event.custombuttonclick.data.attende_email,
                attende_name: event.custombuttonclick.data.attende_name,
                attende_type: event.custombuttonclick.data.attende_type,
                useremail: event.custombuttonclick.data.useremail,
                appointment_date: event.custombuttonclick.data.startdate,
                username: event.custombuttonclick.data.username,
                slot: event.custombuttonclick.data.slot,
                event_type: event.custombuttonclick.data.event_type,
                event_title: event.custombuttonclick.data.event_title,
                // 'appointment_date': '01/27/2021',
                refresh_token: r_token,
                eid: event.custombuttonclick.data.eid,
                slotTimeActual: event.custombuttonclick.data.slotTimeActual,
                googleevent: event.custombuttonclick.data.googleevent,
                note: note,
              };
              // console.log(data, 'data')
              this.Apiservice.customRequest(
                data,
                'google-calendar-event/delete-booked-event',
                environment['google_calendar_api']
              ).subscribe((response: any) => {
                // console.log('response', response);
                setTimeout(() => {
                  this.updatetable = true;
                }, 10);
              });

              this.Apiservice.customRequest(
                data,
                'delete-booked-event',
                environment['api_calender_url']
              ).subscribe((response: any) => {
                console.log(response, 'responsedelete');
              });
            });
          }
        });
      }
      if (event.custombuttonclick != undefined) {
        if (event.custombuttonclick.btninfo.id === 'btn_mark_as_done') {
          const dialogRef = this.dialog.open(DialogmarkasdoneAlert);
          dialogRef.afterClosed().subscribe((result) => {
            const mark_data: any = {
              _id: event.custombuttonclick.data._id,
            };
            if (result === 'true') {
              this.Apiservice.customRequest(
                mark_data,
                'update-status-done',
                environment['api_calender_url']
              ).subscribe((response: any) => {
                console.log('response', response);
                // this.configData.updatetable = !this.configData.updatetable;
                setTimeout(() => {
                  this.updatetable = true;
                }, 10);
              });
            }
          });
        }
      }
      //  show disposition view 
      if (event.custombuttonclick.btninfo.classname === 'disposition') {
        console.log(event.custombuttonclick.data, 'disposition')
        const dialogRef = this.dialog.open(Dispositionview, {
          data: event.custombuttonclick.data,
          panelClass: 'dispositionview_modal',

        });


        // bottomSheetRef.afterDismissed().subscribe(() => {
        //   // console.log('Bottom sheet has been dismissed.');
        //   this.progressSpinner.loading = true;
        //   setTimeout(() => {
        //     this.updatetable = true;
        //     this.progressSpinner.loading = false;
        //   }, 2000);

        // });
      }
      // add new follow up 
      if (event.custombuttonclick.btninfo.classname === 'follow_up_call') {
        console.log(event.custombuttonclick.btninfo.classname, 'follow_up_call')
        const bottomSheetRef = this.bottomSheet.open(bottomsheetfollowup, {
          data: event.custombuttonclick.data, disableClose: true
        });
        this.updatetable = false;
        bottomSheetRef.afterDismissed().subscribe(() => {
          this.progressSpinner.loading = true;
          setTimeout(() => {
            this.updatetable = true;
            this.progressSpinner.loading = false;
          }, 2000);
        });
        this.ObservableService.getlocationsData().subscribe(res => {
          console.log("getmultilingualData*************", res);
          this.updatetable = false;
          if (res.status == 'success') {
            console.log('succccccccccccc');
            setTimeout(() => {
              this.updatetable = true;
              // this.progressSpinner.loading = false;
            }, 2000)
          }
        });
      }
      // updated dispositon
      if (event.custombuttonclick.btninfo.classname == 'disposition_warranty') {
        // console.log(event.custombuttonclick.btninfo.classname, 'disposition_warranty')
        const bottomSheetRef = this.bottomSheet.open(bottomsheetstatus, {
          data: event.custombuttonclick.data, disableClose: true
        });

        bottomSheetRef.afterDismissed().subscribe(() => {
          this.updatetable = false;
          console.log('Bottom sheet has been dismissed on follow up.');
          // this.progressSpinner.loading = true;
          setTimeout(() => {
            this.updatetable = true;
            // console.log();

            // this.progressSpinner.loading = false;
          }, 2000);

        });
      }
      // FolderView
      if (event.custombuttonclick.btninfo.classname === 'FolderView') {
        console.log(event.custombuttonclick.data, 'disposition_warranty')
        window.open(
          "/user-details/lead" + "/" + event.custombuttonclick.data.id,
          "Independent Window"
        )
      }
      // followup call delete
      if (
        event.action == 'custombuttonclick' &&
        event.custombuttonclick.btninfo.classname == 'deletebut'
      ) {
        //  console.log('');
        let followup_delete_data = {
          secret: this.userservice.secret,
          token: this.userservice.jwttoken,
          condition: {
            id: event.custombuttonclick.data._id,
          }
        }
        this.Apiservice.getDataWithoutToken(followup_delete_data, 'api8/delete-followupdata').subscribe(
          (res: any) => {
            console.log(res, 'res res delete follow up');
            // const dialogRef = this.dialog.open(cancelappoinmentAlert);
            if (res.message == 'updated successfull') {
              this.snackBar.open('Cancel Appointment  Successfull.', 'Ok', {
                duration: 2000,
              });
            }

          })


      }

    };
    // updated multiple disposition
    if (event.action == 'multipleselectoptionclick') {
      console.log(event.action, 'disposition_warranty', event.selecteddata)
      let res = event.selecteddata
      const bottomSheetRef = this.bottomSheet.open(bottomsheetstatus, {
        data: { res, Dispositionflag: true }, disableClose: true
      });
      this.updatetable = false;
      bottomSheetRef.afterDismissed().subscribe(() => {
        // console.log('Bottom sheet has been dismissed.');
        // this.progressSpinner.loading = true;
        setTimeout(() => {
          this.updatetable = true;
          this.progressSpinner.loading = false;
        }, 2000);

      });
    }


  }
  openSnackBar(message: string, action: string = null) {
    this.snackBar.open(message, action, {
      duration: 3000,
    });
  }
  refreshbtn() {
    this.searchsettings.selectsearch[0].value = '';
    this.searchsettings.selectsearch[1].value = '';
    this.searchsettings.textsearch[0].value = '';
    this.searchsettings.textsearch[1].value = '';
    this.searchsettings.textsearch[2].value = '';
    // // return;
    // this.userlistdata();
    // setTimeout(() => {
    // this.updatetable = true;
    // this.clearsearchflag = true;
    // }, 500);
  }
  clickEvent(val: any) {
    console.log(val, 'kkkkkkkkkkkkkkkkkkkkkkkiuhgoiuh');
  }

  myFunction() {
    // document.getElementById('demo').innerHTML = 'YOU CLICKED ME!';
    console.log('hiiiiiiiiiiiiiiiiiiiiiiiiii');
  }
  onTabClick(val) {
    this.progressSpinner.loading = true;
    console.log(val);
    console.log(val.index);
    this.selected.value = val.index;
    // return
    let data: any = {};
    if (val.index == 2) {
      let body = document.getElementsByTagName('body')[0];
      body.classList.remove('followuplist');
      data = {
        secret: this.userservice.secret,
        token: this.cookieservice.get('jwtToken'),
        type: this.userservice.type,
        source: '',
        condition: {
          // condition for how much data show in one time
          limit: this.count,
          skip: 0,
        },
        sort: {
          type: 'desc', // data sort by decreasing order
          field: 'created_datetime', // data sort by first name
        },
      };
      if (this.userservice.type == 'technological-consultant') {
        data.id = this.userservice.userid;
      }
      let noteproducts = []
      this.Apiservice.getDataWithoutToken(data, 'api/leadesnotes').subscribe(
        (res: any) => {

          console.log(res, 'notes data');

          if (res.status == 'success') {
            this.progressSpinner.loading = false;
            this.notes_data = res.results.count; // sarch count  (How many data there)
            // noteproducts = res.results.count
            console.log(this.notes_data, 'noteproducts');
            this.notes_data_count = res.results.counts; // sarch count  (How many data there)
            if (this.notes_data.length == 0) {
              this.flagnote = true;
            } else {
              this.flagnote = false;
            }
          }
        }
      );
    }
    if (val.index == 0) {
      let body = document.getElementsByTagName('body')[0];
      body.classList.remove('followuplist');
      this.leadListdatatable = [];
      data = {
        secret: this.userservice.secret,
        token: this.cookieservice.get('jwtToken'),
        source: '',
        condition: {
          limit: 10,
          skip: 0,
        },
        sort: {
          type: 'desc',
          field: 'lastactivity',
        },
        searchcondition: {
          type: 'lead',
        },
      };
      if (this.userservice.type == 'technological-consultant') {
        data.searchcondition.parentid = this.userservice.userid;
      }
      console.log(data);
      this.Apiservice.getDataWithoutToken(data, 'api/leadlistview').subscribe(
        (res: any) => {
          if (res.status == 'success') {
            this.progressSpinner.loading = false;
            this.leadListdatatable = res.results.res;
            if (this.leadListdatatable.length == 0) {
              this.leadListdatatableflag = true;
            } else {
              this.leadListdatatableflag = false;
            }
            // sarch count  (How many data there)
          }
        }
      );
    }
    if (val.index == 1) {
      let body = document.getElementsByTagName('body')[0];
      body.classList.remove('followuplist');
      this.leadListdatatable = [];
      data = {
        secret: this.userservice.secret,
        token: this.cookieservice.get('jwtToken'),
        source: '',
        condition: {
          limit: 10,
          skip: 0,
        },
        sort: {
          type: 'desc',
          field: 'createdon_datetime',
        },
        searchcondition: {
          type: 'lead',
        },
      };
      if (this.userservice.type == 'technological-consultant') {
        data.searchcondition.parentid = this.userservice.userid;
      }
      console.log(data);
      this.Apiservice.getDataWithoutToken(data, 'api/leadlistview').subscribe(
        (res: any) => {
          if (res.status == 'success') {
            this.progressSpinner.loading = false;
            this.leadListdatatable = res.results.res;
            // sarch count  (How many data there)
          }
        }
      );
    }
    if (val.index == 3) {
      let body = document.getElementsByTagName('body')[0];
      body.classList.add('followuplist');
      this.progressSpinner.loading = true;
      // this.onTabClickappoinment(val);
      console.log(
        'uuuuuuuuuuuuuuuuuuuuuuuuuuuuuuuuuuuuuuuuuuuuuuuuuuuuuuuuuuuuuuuu'
      );
      this.followup_datesearchsourcecount = 0;
      this.followupdatatable = [];
      data = {
        secret: this.cookieservice.get('secret'),
        token: this.cookieservice.get('jwtToken'),
        source: '',
        condition: {
          limit: 10,
          skip: 0,
        },
        sort: {
          type: 'asc',
          field: 'datetimeslot',
        },
        searchcondition: {
        },
      };
      console.log('madhumita k');
      if (JSON.parse(this.cookieservice.get('type')) == 'technological-consultant') {
        data.searchcondition['bookinguser'] = JSON.parse(this.cookieservice.get('userid'));
      }
      console.log(data);
      this.Apiservice.getDataWithoutToken(
        data,
        'api8/list-followup-data'
      ).subscribe((res: any) => {
        if (res.status == 'success') {
          this.progressSpinner.loading = false;
          this.followupdatatable = res.results.res;
          if (this.followupdatatable.length == 0) {
            this.followup_datatableflag = true;
          } else {
            this.followup_datatableflag = false;
          }
          // sarch count  (How many data there)
        }
      });
      this.Apiservice.getDataWithoutToken(
        data,
        'api8/list-followup-data-count'
      ).subscribe((res: any) => {
        if (res.status == 'success') {
          this.progressSpinner.loading = false;
          setTimeout(() => {
            console.log(res.count, 'res.count+++++');
            this.followup_datesearchsourcecount = res.count;
          }, 1000);

          // sarch count  (How many data there)
        }
      });
      // if (this.searchsettings_followup.selectsearch.value) {

      // }
    }

  }
  onTabClickappoinment(val) {
    this.progressSpinner.loading = true;
    if (val.index == 0) {
      this.progressSpinner.loading = false;
    }
    let data: any = {};
    if (val.index == 1) {
      console.log('yyyyyyyyyyyyyyyyyyyyyy');
      console.log(
        'uuuuuuuuuuuuuuuuuuuuuuuuuuuuuuuuuuuuuuuuuuuuuuuuuuuuuuuuuuuuuuuu'
      );
      // this.router.navigateByUrl('/calendar-management/manage-appointment/genarelcall-appointments')
      this.schedulepast_datatable = [];
      data = {
        secret: this.userservice.secret,
        token: this.userservice.jwttoken,
        source: '',
        condition: {
          limit: 10,
          skip: 0,
        },
        sort: {
          type: 'desc',
          field: 'createdon_datetime',
        },
        searchcondition: {
          id: this.userservice.userid,
          event_type_val: 4,
        },
      };

      console.log(data);
      this.Apiservice.getDataWithoutToken(
        data,
        'api/genarelcall-past-data'
      ).subscribe((res: any) => {
        if (res.status == 'success') {
          this.progressSpinner.loading = false;
          this.schedulepast_datatable = res.results.res;
          if (this.schedulepast_datatable.length == 0) {
            this.schedulepastdatatableflag = true;
          } else {
            this.schedulepastdatatableflag = false;
          }
          // sarch count  (How many data there)
        }
      });
      this.Apiservice.getDataWithoutToken(
        data,
        'api/genarelcall-past-data-count'
      ).subscribe((res: any) => {
        if (res.status == 'success') {
          this.progressSpinner.loading = false;
          this.schedulepast_datesearchsourcecount = res.count;
          // sarch count  (How many data there)
        }
      });
    }
  }
  leadsdelete(val: any) {
    console.log(val, '+++++++++++++');

    let leaddltdata = {
      id: val,
      secret: this.userservice.secret,
      token: this.userservice.jwttoken,
    };
    this.Apiservice.customRequest(
      leaddltdata,
      'api/deletenotedataforlead',
      environment['api_url']
    ).subscribe((response: any) => {
      console.log('PPPPPPPPPPPPP', response);
    });
  }
  showMore(flag) {
    this.progressSpinner.loading = true;
    let data: any = {};
    // this.notes_data = [];
    console.log(this.notes_data);

    if (flag == 'showmore') {
      data = {
        secret: this.userservice.secret,
        token: this.token,
        type: this.userservice.type,
        source: '',
        condition: {
          limit: (this.count += this.count),

          skip: 0, // condition for how much data show in one time
        },
        sort: {
          type: 'desc', // data sort by decreasing order
          field: 'created_datetime', // data sort by first name
        },
      };
      if (this.userservice.type == 'technological-consultant') {
        data.id = this.userservice.userid;
      }
      this.Apiservice.getDataWithoutToken(data, 'api/leadesnotes').subscribe(
        (res: any) => {
          console.log('notes data', res);

          if (res.status == 'success') {
            //   let dialogRef = this.dialog.open(Dialogmoremodal, {
            //   height: '400px',
            //   width: '600px',
            // });
            this.showmoreflag = true;
            console.log(res);

            this.progressSpinner.loading = false;

            this.notes_data = res.results.count; // sarch count  (How many data there)
          }
        }
      );
    }
    if (flag == 'showless') {
      let datacount = 6;
      // console.log(this.count-=  datacount);
      // return
      this.count -= datacount;
      data = {
        secret: this.userservice.secret,
        token: this.token,
        type: this.userservice.type,
        source: '',
        condition: {
          // condition for how much data show in one time
          limit: (datacount = this.notes_data_count - datacount),

          skip: 0,
        },
        sort: {
          type: 'desc', // data sort by decreasing order
          field: 'createdon_datetime', // data sort by first name
        },
      };
      if (this.userservice.type == 'technological-consultant') {
        data.id = this.userservice.userid;
      }
      this.Apiservice.getDataWithoutToken(data, 'api/leadesnotes').subscribe(
        (res: any) => {
          if (res.status == 'success') {
            this.showmoreflag = false;
            console.log(res, 'notes data');

            this.progressSpinner.loading = false;

            this.notes_data = res.results.count; // sarch count  (How many data there)
          }
        }
      );
    }

    console.log(data);
  }
}

@Component({
  selector: 'bottonsheet',
  templateUrl: './bottomsheet.html',
})
export class BottonSheetLead {
  public deleteflag: any = false;
  constructor(
    public bottomSheetRef: MatBottomSheetRef<BottonSheetLead>,
    public apiservice: ApiService,
    public userservice: UserserviceService,
    @Inject(MAT_BOTTOM_SHEET_DATA) public data,
    private snackbar: MatSnackBar,
    public dialog: MatDialog,
    public router: Router
  ) { }

  resendMail() {
    const data = {
      token: this.userservice.jwttoken,
      secret: this.userservice.secret,
      type: this.data.custombuttonclick.data.type,
      _id: this.data.custombuttonclick.data._id,
    };
    // console.log('resendMail');
    this.apiservice
      .getDataWithoutToken(data, 'api/resendemail')
      .subscribe((res: any) => {
        // console.log(res, 'resendMail');
        if (res.status === 'success') {
          this.snackbar.open('Resend Mail Successfull.', 'Ok', {
            duration: 2000,
          });
        }
        this.bottomSheetRef.dismiss();
      });
  }

  sendResetPassword() {
    const data = {
      token: this.userservice.jwttoken,
      secret: this.userservice.secret,
      _id: this.data.custombuttonclick.data._id,
      email: this.data.custombuttonclick.data.email,
    };
    this.apiservice
      .getDataWithoutToken(data, 'api/forgetpassword')
      .subscribe((res: any) => {
        if (res.status === 'success') {
          this.snackbar.open(
            'Request for Password Reset is successful, Please check your registered mail.',
            'Ok',
            {
              duration: 2000,
            }
          );
        }
        this.bottomSheetRef.dismiss();
      });
  }

  dltBt() {
    const data = {
      token: this.userservice.jwttoken,
      secret: this.userservice.secret,
      id: this.data.custombuttonclick.data._id,
    };
    let flag = '';
    this.apiservice
      .getDataWithoutToken(data, 'api/deletelead')
      .subscribe((res: any) => {
        if (res.status === 'success') {
          this.snackbar.open('Record Delete Successfully', 'Ok', {
            duration: 2000,
          });
          flag = 'yes';
          this.bottomSheetRef.dismiss(flag);
        } else {
          flag = 'no';
          this.bottomSheetRef.dismiss(flag);
        }
      });
  }
  folderview() {
    // console.log(this.data.custombuttonclick.data,'folderview')
    // this.folderview = this.data.custombuttonclick.data
    // // console.log(this.folderview,'folderview')
    this.bottomSheetRef.dismiss();
    this.router.navigateByUrl(
      'user-details/' +
      this.data.custombuttonclick.data.type +
      '/' +
      this.data.custombuttonclick.data._id
    );
    // if()
  }
  dltBtn() {
    this.deleteflag = false;
  }

  deleteBtn() {
    this.deleteflag = true;
    // console.log('=====', this.deleteflag);
  }
  ViewDetails(val: any) {
    // console.log(val, 'divicesInfo');
    const dialogRef = this.dialog.open(ViewDetailsLead, {
      height: '500px',
      width: '500px',
      data: { val: this.data },
    });
  }
}

@Component({
  selector: 'viewdetails',
  templateUrl: 'viewdetails.html',
})
export class ViewDetailsLead {
  public valdata: any;
  constructor(
    public dialogRef: MatDialogRef<ViewDetailsLead>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    public apiservice: ApiService,
    public activatedroute: ActivatedRoute,
    public userservice: UserserviceService
  ) {
    this.valdata = JSON.stringify(data);
    this.valdata = JSON.parse(this.valdata);
    // console.log(data, '+++++');
    // console.log(this.valdata, '+++data++');
  }

  onNoClick(): void {
    this.dialogRef.close();
  }
}

@Component({
  selector: 'cancelappoinment',
  templateUrl: 'cancelappoinment.html',
})
export class cancelappoinmentAlert {
  public addreason: any;
  public addreasoncancel = {};
  public cancelflag: boolean = false;
  public seubmitreasonsflag: boolean = false;
  constructor(
    public dialogRef: MatDialogRef<cancelappoinmentAlert>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData
  ) { }
  cancelyes() {
    this.addreasoncancel = {
      flag: (this.cancelflag = true),
      data: this.addreason,
    };
    console.log(this.addreasoncancel, 'addreasoncancel', this.addreason);
    if (
      this.addreason != null &&
      typeof this.addreason != 'undefined' &&
      this.addreason != ''
    ) {
      this.dialogRef.close(this.addreasoncancel);
    } else {
      this.seubmitreasonsflag = true;
    }
  }
}

@Component({
  selector: 'markasdone',
  templateUrl: 'markasdone.html',
})
export class DialogmarkasdoneAlert { }

@Component({
  selector: 'moremodal',
  templateUrl: 'moremodal.html',
})
export class Dialogmoremodal {
  public cstatusdata: any;
  constructor(
    public dialogRef: MatDialogRef<Dialogmoremodal>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    public apiservice: ApiService,
    public activatedroute: ActivatedRoute,
    public userservice: UserserviceService
  ) {
    console.log(data.data, '+++++');

    this.cstatusdata = data.data.tags;

    console.log(this.cstatusdata);
  }

  onNoClick(): void {
    this.dialogRef.close();
  }
}

// this component add disposition status change 
@Component({
  selector: 'dispositionbottomsheet',
  templateUrl: './bottomsheetstatus.html',
})
export class bottomsheetstatus {
  // public Dispositionflag:boolean=false;
  public result: any
  constructor(
    public cookieservice: CookieService,
    public bottomSheetRef: MatBottomSheetRef<bottomsheetstatus>,
    public apiservice: ApiService,
    public userservice: UserserviceService,
    public router: Router,
    @Inject(MAT_BOTTOM_SHEET_DATA) public data: DialogData,
    private _snackBar: MatSnackBar,
    public dialog: MatDialog
  ) {
    // console.log(data.res._id, 'bottom sheet status id');
    // let result = data
  }
  // this func for change disposition
  statuschng(val: any) {
    // console.log(this.data.res._id, 'val status change');
    // Dispositionflag this flag use for multiple disposition
    if (this.data.Dispositionflag == true) {
      let ids = [] //
      for (let i of this.data.res) {
        ids.push(i._id);
        console.log(ids, 'ids')
      }
      let dispo_data = {
        token: this.userservice.jwttoken,
        secret: this.userservice.secret,
        ids: ids,
        disposition: {
          status: val,
          user: this.userservice.userid,
          created_date: new Date().getTime()
        },
      }
      this.apiservice
        .getDataWithoutToken(dispo_data, 'api8/disposition-updatedmany')
        .subscribe((res: any) => {
          console.log(res, 'ress status chnge');
          this._snackBar.open('Set Disposition', 'Ok', {
            duration: 2000,
          });
          this.bottomSheetRef.dismiss(res);
        });
      console.log(dispo_data, 'dispo_data');
      // disposition-updatedmany
    } else {         // else part for single disposition updated
      let status_data = {
        token: this.userservice.jwttoken,
        secret: this.userservice.secret,
        id: this.data._id,
        followupstatus: val,
        user: this.userservice.userid
      }
      this.apiservice
        .getDataWithoutToken(status_data, 'api8/updated-disposition')
        .subscribe((res: any) => {
          console.log(res, 'ress status chnge');
          this._snackBar.open('Set Disposition', 'Ok', {
            duration: 2000,
          });
          this.bottomSheetRef.dismiss(res);
        });
    }

  }

  onNoClick(): void {
    this.bottomSheetRef.dismiss();
  }
}
// this component followup call status change
@Component({
  selector: 'bottomsheetfollowup',
  templateUrl: './bottomsheetfollowup.html',
})
export class bottomsheetfollowup {
  // public deleteflag: boolean = false;
  constructor(
    public cookieservice: CookieService,
    public bottomSheetRef: MatBottomSheetRef<bottomsheetfollowup>,
    public apiservice: ApiService,
    public userservice: UserserviceService,
    public router: Router,
    @Inject(MAT_BOTTOM_SHEET_DATA) public data: DialogData,
    private _snackBar: MatSnackBar,
    public dialog: MatDialog,
    public ObservableService: ObservableService

  ) {
    console.log(data, '777777777777777');
    // var day = data.datetimeslot +days * 24 * 60 * 60 * 1000,
    // var newDate = Math.round(new Date(Date.now() + days * 24 * 60 * 60 * 1000));
  }
  // this function push to tomorrow (follow up call)
  pushtotomorrow() {
    let days = 0;
    let newDate = 0;
    let d = (new Date(this.data.datetimeslot)).getDay();
    if (d == 5) {
      days = 3;
      newDate = this.data.datetimeslot + days * 24 * 60 * 60 * 1000;
    } else {
      days = 1;
      newDate = this.data.datetimeslot + days * 24 * 60 * 60 * 1000;
    }
    console.log('+++++++++++++++++', d);
    console.log(newDate, 'newDate');
    this.data.datetimeslot = newDate
    console.log(this.data.datetimeslot, 'this.data.datetimeslot');
    let data = {
      id: this.data._id,
      newDate: newDate
    }
    this.apiservice
      .getDataWithoutToken(data, 'api8/update_datetime')
      .subscribe((res: any) => {
        console.log(res, 'ress');
        this._snackBar.open('Follow Up Call Push To Tomorrow', 'Ok', {
          duration: 2000,
        });
        this.bottomSheetRef.dismiss(res);
      });

  };
  // this function push to 2 days (recored will be changed before 2 days) (follow up call)
  pushtotwodays() {
    let days = 0;
    let newDate = 0;
    // this d variable calculete day from unixtime 
    let d = (new Date(this.data.datetimeslot)).getDay();
    // number (result) [0-6] [sun-sat]
    if (d == 5) {
      days = 5;
      newDate = this.data.datetimeslot + days * 24 * 60 * 60 * 1000;  // this is calculate add day value
    } else {
      days = 2;
      newDate = this.data.datetimeslot + days * 24 * 60 * 60 * 1000;
    }
    if (d == 4) {
      days = 4;
      newDate = this.data.datetimeslot + days * 24 * 60 * 60 * 1000;
    }
    console.log('+++++++++++++++++', d);
    let data = {
      id: this.data._id,
      newDate: newDate
    }
    this.apiservice
      .getDataWithoutToken(data, 'api8/update_datetime')
      .subscribe((res: any) => {
        console.log(res, 'ress');
        this._snackBar.open('Follow Up Call Push to 2 days', 'Ok', {
          duration: 2000,
        });
        // this is close bottom sheet
        this.bottomSheetRef.dismiss();
      });
  }
  // add new follow up func
  setanewfollowup() {
    console.log(this.data, 'data set');
    let result = this.data
    let dialogRef = this.dialog.open(DialogFollowUpComponent, {
      panelClass: 'followupmodal',
      data: { result, flag_followup: true },
    });
    this.bottomSheetRef.dismiss();

  }

  onNoClick(): void {
    this.bottomSheetRef.dismiss();
  }

}


// view disposition
@Component({
  selector: 'dispositionview',
  templateUrl: 'dispositionview.html',
})
export class Dispositionview {
  public cstatusdata: any;
  public fullname: any;
  constructor(
    public dialogRef: MatDialogRef<Dispositionview>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    public apiservice: ApiService,
    public activatedroute: ActivatedRoute,
    public userservice: UserserviceService
  ) {
    console.log(data.disposition, '+++++');
    let fullname = this.userservice.firstname + ' ' + this.userservice.lastname
  }

  onNoClick(): void {
    this.dialogRef.close();
  }
}
