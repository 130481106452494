import { Component, OnInit } from '@angular/core';
import { environment } from '../../../../../environments/environment';
import { CookieService } from 'ngx-cookie-service';
import { ApiService } from '../../../../services/api.service';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-past-appointment',
  templateUrl: './past-appointment.component.html',
  styleUrls: ['./past-appointment.component.css'],
})
export class PastAppointmentComponent implements OnInit {
  private tableHeaderFields: any = [];

  public progressSpinner: any = {
    mode: 'indeterminate',
    loading: false,
    bookingStatus: 'Sending request',
  };
  public configData: any = {
    jwtToken: this.cookieService.get('jwt_for_calendar'),
    baseUrl: environment['api_calender_url'],
    endPoint: {
      listBookedEvents: 'list-booked-events',
      listBookedEventsCount: 'list-booked-events-count',
      // listUpcomingBookedEvents: 'list-upcoming-booked-events',
      // listUpcomingBookedEventsCount: 'list-upcoming-booked-events-count',
      deleteBookedEvent: 'delete-booked-event',
      rescheduleBookedEvent: 'reschedule',
    },
    responseData: [],
    primaryCondition: {
      userid: { $in: JSON.parse(this.cookieService.get('userid')) },
    },

    // lib-listing inputs
    skipFields: [],
    modify_header_array: {
      event_title: 'Event Title',
      event_type: 'Event Type',
      product_name: 'Product Name',
      username: ' Organiser Name',
      attende_name: 'Attendees Name',
      attende_email: 'Attendees Email',
      attende_type: 'Attendees Type',
      training_category_name: 'Training Category Name',
      mentee_name: 'Mentee Name',
      mentee_email: 'Mentee Email',
      lesson_title: 'Lesson Title',
      startdate: 'Appointment Date',
      appointment_timing: 'Appointment Timing',
      timezoneName: 'Timezone',
      // booking_date: 'Booked On',
      status: 'Status',
      is_google_event: 'Calendar Event',
      // createdon_datetime: 'Booked On',
      appointment_date:'Booked On'
    },
    source: 'google_events',
    date_search_source_count: 0,
    libdata: {
      preview_header: {

        header: "Details",
        class: 'preheadercls'
    },
      basecondition: {},
      detailview_override: [
        { key: 'event_title', val: 'Event Title' },
        { key: 'event_type', val: 'Event Type' },
        { key: 'description', val: 'Description' },
        { key: 'username', val: 'Organiser Name' },
        { key: 'useremail', val: 'Organiser Email' },
        { key: 'notes', val: 'Notes' },
        { key: 'booking_date', val: 'Booked On' },
        { key: 'startdate', val: 'Appointment Date' },
        { key: 'slot', val: 'Slot' },
        { key: 'timespanmins', val: 'Time span' },
        { key: 'timezone', val: 'Time zone' },
        { key: 'attende_name', val: 'Attende Name' },
        { key: 'attende_email', val: 'Attende Email' },
        { key: 'attende_type', val: 'Attende Type' },
        { key: 'product_name', val: 'Product Name' },
        // { key: 'appoinment_date_timezone', val: 'appoiment Date/Timezone' },
      ],
      updateendpoint: 'statusupdate',
      hidestatustogglebutton: true,
      hidedeletebutton: true,
      hideeditbutton: true, // all these button options are optional not mandatory
      tableheaders: this.tableHeaderFields,
      hidedeletemany: true,
      hideupdatemany: true,
      hidemultipleselectbutton: true,
      custombuttons: [],
    },
    updatetable: false,
    limitcond: {
      limit: 10,
      skip: 0,
      pagecount: 1,
    },
    sortdata: {
      type: 'desc',
      field: 'booking_date',
      options: [
        'startdate',
        'event_title',
        'event_type',
        'timezone',
        'attende_name',
        'createdon_datetime',
      ],
    },
    custom_link: [],
    search_settings: {
      // this is use for  date search
      datesearch: [
        {
          startdatelabel: 'Appointments After',
          enddatelabel: 'Appointments Before',
          submit: 'Search',
          field: 'start_datetime_unix',
        },
        // {
        //   startdatelabel: 'Booking After', enddatelabel: 'Booking Before',
        //   submit: 'Search', field: 'booking_date'
        // }
      ],

      // this is use for  select search
      selectsearch: [
        {
          label: 'Search By Status',
          field: 'status',
          values: [
            { val: 0, name: 'Pending' },
            { val: 1, name: 'Done' },
            { val: 2, name: 'Canceled' },
          ],
        },
      ],

      textsearch: [
        { label: 'Search By  Attendees Name', field: 'attende_name_s' },

        { label: 'Search By  Organiser Name', field: 'username' },
      ],

      // this is use for Autocomplete search
      search: [],
    },
    statusarray: [
      { val: 0, name: 'Pending' },
      { val: 1, name: 'Done' },
      { val: 2, name: 'Canceled' },
    ],
    // detail_skip_array: ['_id', 'username', 'useremail', 'startdate_unix', 'can_reschedule', 'is_google_event', 'userid', 'username', 'start_datetime_unix']
    detail_skip_array: [
      '_id',
      'slotTimeActual',
      'notes',
      'eid',
      'start_datetime_unix',
      'event_type_val',
      'googleevent',
      'event_title_s',
      'eventid',
      'is_google_event',
      'lesson_id',
      'id',
      'attende_email_s',
      'attende_name_s',
      // 'status',
      'user_id',
      'username_s',
      'product_name',
      'product_name_s',
      'product_id',
      'slot_end_time',
      'training_id',
      'parent_id',
      'event_type_val_s',
      'flag_for_show',
      'appoinment_date_timezone',
      'timespan',
    ],
  };

  constructor(
    public cookieService: CookieService,
    private apiService: ApiService,
    public dialog: MatDialog,
    public activatedRoute: ActivatedRoute
  ) {}

  ngOnInit() {
    if (
      JSON.parse(this.cookieService.get('type')) ==
        'technological-consultant' &&
      this.activatedRoute.snapshot.routeConfig.path ==
        'manage-appointment/lead-appointments'
    ) {
      console.log(this.activatedRoute.snapshot.routeConfig.path, 'past path');
      this.configData.libdata.tableheaders = [
        'event_title',
        'event_type',
        'product_name',
        'username',
        'attende_name',
        'attende_email',
        'attende_type',
        'product_name',
        'appointment_date',
        'status',
      ];
      // this.configData.libdata.tableheaders = this.tableHeaderFields;
      this.configData.libdata.basecondition = {
        parent_id: JSON.parse(this.cookieService.get('userid')),
      };
    }

    // set jwtToken

    if (
      JSON.parse(this.cookieService.get('type')) ==
        'technological-consultant' &&
      this.activatedRoute.snapshot.routeConfig.path ==
        'manage-appointment/warranty-appointments'
    ) {
      this.configData.libdata.basecondition = {
        bookinguser: JSON.parse(this.cookieService.get('userid')),
        event_type_val: 5,
      };
      this.tableHeaderFields = [
        'event_title',
        'event_type',
        'username',
        'attende_name',
        'attende_email',
        'attende_type',
        'appointment_date',
        'timezoneName',
        'status',
      ];
      this.configData.libdata.tableheaders = this.tableHeaderFields;
    }

    // if (
    //   JSON.parse(this.cookieService.get('type')) ==
    //     'technological-consultant' &&
    //   this.activatedRoute.snapshot.routeConfig.path ==
    //     'manage-appointment/genarelcall-appointments'
    // ) {
    //   console.log(
    //     this.activatedRoute.snapshot.routeConfig.path,
    //     'this.activatedRoute.snapshot.routeConfig.path'
    //   );
    //   this.configData.libdata.basecondition = {
    //     user_id: JSON.parse(this.cookieService.get('userid')),
    //   };
    //   this.tableHeaderFields = [
    //     'event_title',
    //     'event_type',
    //     'username',
    //     'attende_name',
    //     'attende_email',
    //     'attende_type',
    //     'product_name',
    //     'startdate',
    //     'timezoneName',
    //     'status',
    //   ];
    //   this.configData.libdata.tableheaders = this.tableHeaderFields;
    // }
    // this.configData.jwtToken = this.cookieService.get('jwt_for_calendar');

    // set table headers fields for different users
    if (JSON.parse(this.cookieService.get('type')) === 'sales-person') {
      this.tableHeaderFields = [
        'event_title',
        'event_type',
        'username',
        'attende_name',
        'attende_email',
        'attende_type',
        'timezoneName',
        'appointment_date',
        'status',
      ];
      this.configData.libdata.tableheaders = this.tableHeaderFields;
      this.configData.libdata.basecondition = {
        id: JSON.parse(this.cookieService.get('userid')),
      };
    }

    if (
      JSON.parse(this.cookieService.get('type')) === 'admin' ||
      JSON.parse(this.cookieService.get('type')) === 'closer' ||
      JSON.parse(this.cookieService.get('type')) === 'technological-consultant'
    ) {
      this.tableHeaderFields = [
        'event_title',
        'event_type',
        'username',
        'attende_name',
        'attende_email',
        'attende_type',
        'timezoneName',
        'appointment_date',
        'status',
      ];
      this.configData.libdata.tableheaders = this.tableHeaderFields;

      // this.configData.libdata.basecondition = { id: JSON.parse(this.cookieService.get('userid')) };

      this.configData.libdata.custombuttons.push({
        label: 'Mark as Done',
        type: 'listner',
        id: 'btn_mark_as_done',
        cond: 'status',
        condval: 0,
        classname: 'btn_mark_as_done',
        tooltip: 'Mark as Done',
        refreshdata: true,
      });
    }

    this.loadPastAppointments();
  }

  loadPastAppointments(): void {
    this.configData.responseData = null;
    this.progressSpinner.loading = true;
    const requestData: any = {
      token: this.configData.jwtToken,
      condition: {
        limit: 10,
        skip: 0,
      },
      sort: {
        type: 'desc',
        field: 'booking_date',
      },
    };

    if (
      JSON.parse(this.cookieService.get('type')) ==
        'technological-consultant' &&
      this.activatedRoute.snapshot.routeConfig.path ==
        'manage-appointment/lead-appointments'
    ) {
      requestData.condition.parent_id = JSON.parse(
        this.cookieService.get('userid')
      );
      // this.configData.libdata.custombuttons.push({
      //   label: 'Mark as Done',
      //   type: 'listner',
      //   id: 'btn_mark_as_done',
      //   cond: 'status',
      //   condval: 0,
      //   classname: 'Post-Discovery',
      //   tooltip: 'Mark as Done',
      //   refreshdata: true,
      // });
    }
    // if (
    //   JSON.parse(this.cookieService.get('type')) ==
    //   'technological-consultant' &&
    //   this.activatedRoute.snapshot.routeConfig.path ==
    //   'manage-appointment/warranty-appointments' && this.cookieService.check('userid')
    // ) {
    //   requestData.sarchconditions.bookinguser = JSON.parse(this.cookieService.get('userid'))

    // }
    if (
      JSON.parse(this.cookieService.get('type')) ==
        'technological-consultant' &&
      this.activatedRoute.snapshot.routeConfig.path ==
        'manage-appointment/warranty-appointments' &&
      this.cookieService.check('userid')
    ) {
      requestData.condition.bookinguser = JSON.parse(
        this.cookieService.get('userid')
      );
      requestData.condition.event_type_val = 5;
    }

    if (
      JSON.parse(this.cookieService.get('type')) ===
        'technological-consultant' &&
      this.activatedRoute.snapshot.routeConfig.path ==
        'manage-appointment/genarelcall-appointments'
    ) {
      requestData.condition.user_id = JSON.parse(
        this.cookieService.get('userid')
      );
    }
    if (JSON.parse(this.cookieService.get('type')) === 'closer') {
      requestData.condition.user_id = JSON.parse(
        this.cookieService.get('userid')
      );
    }
    if (JSON.parse(this.cookieService.get('type')) == 'distributor') {
      this.activatedRoute.snapshot.routeConfig.path ==
        'manage-appointment/closer-appointments';
      requestData.condition.parent_id = JSON.parse(
        this.cookieService.get('userid')
      );
    }
    if (
      JSON.parse(this.cookieService.get('type')) === 'sales-person' &&
      this.activatedRoute.snapshot.routeConfig.path !=
        'calendar-management/manage-appointment/my-appointments'
    ) {
      requestData.condition.id = JSON.parse(this.cookieService.get('userid'));
      this.configData.search_settings.textsearch.push(
        { label: 'Search By  Event Type', field: 'event_type' },
        // { label: 'Search By  Product  Name', field: 'product_name_s' },
        { label: 'Search By  Attendees Email', field: 'attende_email_s' }
      );
    }
    if (
      JSON.parse(this.cookieService.get('type')) ==
      'technological-consultant' &&
      this.activatedRoute.snapshot.routeConfig.path ==
      'manage-appointment/my-appointments' && this.cookieService.check('userid')
    ) {
      requestData.condition.bookinguser = JSON.parse(this.cookieService.get('userid'));
      this.configData.libdata.basecondition.bookinguser = JSON.parse(this.cookieService.get('userid'));
    }
    setTimeout(() => {
      this.apiService
        .customRequest(
          requestData,
          this.configData.endPoint.listBookedEvents,
          this.configData.baseUrl
        )
        .subscribe((response: any) => {
          // Set dataset in responseData
          this.progressSpinner.loading = false;

          if (response.status === 'success') {
            this.configData.responseData = response.results.res;
            console.log(
              this.configData.responseData,
              ' this.configData.responseData'
            );
            // Create skipFields array(first save all the keys from the dataset)
            if (response.results.res > 0) {
              this.configData.skipFields = Object.keys(response.results.res[0]);
            }
          }
          const requiredFields = this.tableHeaderFields;

          // Modify the skipFields array(splicing the keys which is in the requiredFields)
          for (let i = 0; i < requiredFields.length; i++) {
            this.configData.skipFields.splice(
              this.configData.skipFields.indexOf(requiredFields[i]),
              1
            );
          }
        });
    }, 1000);

    setTimeout(() => {
      this.apiService
        .customRequest(
          requestData,
          this.configData.endPoint.listBookedEventsCount,
          this.configData.baseUrl
        )
        .subscribe((response: any) => {
          this.configData.date_search_source_count = response.count;
        });
    }, 1500);
  }

  listenLiblistingChange(event: any) {
    console.log('event', event);
    // endpoint: 'delete-booked-event'
    if (event.custombuttonclick != undefined) {
      if (event.custombuttonclick.btninfo.id === 'btn_mark_as_done') {
        const dialogRef = this.dialog.open(DialogMarkAsDoneAlert);

        dialogRef.afterClosed().subscribe((result) => {
          // console.log(`Dialog result: ${result}`);
          // 'mentee_id', 'mentor_id', 'lesson_id', 'training_id', 'lesson_title', 'training_category_name', 'mentor_name', 'mentee_name', 'mentee_email'
          const data: any = {
            token: this.configData.jwtToken,
            _id: event.custombuttonclick.data._id,
          };
          if (result === 'true') {
            this.apiService
              .customRequest(
                data,
                'update-status-done',
                environment['api_calender_url']
              )
              .subscribe((response: any) => {
                // console.log('response', response);
                this.configData.updatetable = !this.configData.updatetable;
              });
          }
        });
      }
    }
  }
}
@Component({
  selector: 'mark-as-done-alert',
  templateUrl: 'mark-as-done-alert.html',
})
export class DialogMarkAsDoneAlert {}
