import { CUSTOM_ELEMENTS_SCHEMA, NgModule, NO_ERRORS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ShareModule } from '../../modules/share/share.module';
import { ManageCommissonRoutingModule } from './managecommisson-routing.module';
import { SalescommissionComponent } from './salescommission/salescommission.component';
import { ManagecommissionlistComponent } from './managecommissionlist/managecommissionlist.component';
import { EventcommissionComponent } from './eventcommission/eventcommission.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CKEditorModule } from 'ngx-ckeditor';


@NgModule({
    declarations: [SalescommissionComponent, ManagecommissionlistComponent, EventcommissionComponent],
    imports: [
        CommonModule, ManageCommissonRoutingModule, ShareModule,    CommonModule,
        ShareModule,
        FormsModule, ReactiveFormsModule,
        CKEditorModule
    ],
    schemas: [CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA],
})
export class ManageCommissonModule { }