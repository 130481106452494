import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { ApiService } from 'src/app/services/api.service';
import { LoaderService } from 'src/app/services/loader.service';
import { UserserviceService } from 'src/app/services/userservice.service';

@Component({
  selector: 'app-product-video',
  templateUrl: './product-video.component.html',
  styleUrls: ['./product-video.component.css']
})
export class ProductVideoComponent implements OnInit {
  public currentThing: any;
  public video_data: any = [];
  public category_data: any = [];
  public active_cls_id: any = 'active';
  public activeclsflag: any = [];
  public productId: any = '';
  // public loaderService:LoaderService;
  public allactive: any = false;
  public loderflag = false;
  constructor(public router: Router,
    public Apiservice: ApiService,
    public http: HttpClient,
    public cookieservice: CookieService,
    public activatedRoute: ActivatedRoute,
    public userservice: UserserviceService,

    public dialog: MatDialog,
    public sanitizer: DomSanitizer,
    public loaderService: LoaderService) {
    this.activatedRoute.data.forEach((res: any) => {
      if (this.activatedRoute.snapshot.params._prodid != null) {
        console.log('wara=====>', res.resolvedata.results)

        this.video_data = res.resolvedata.results.res;
        this.category_data = null
        this.allactive = true;
      }
      else {
        this.video_data = res.resolvedata.results.getallvideodata;
        let catdata = res.resolvedata.results.getallvideocategorydata;
        let category_id_array_unique: any = [];
        let category_id_array: any = this.video_data && this.video_data.length > 0 && this.video_data.map((val: any) =>
          val.category_id
        )
        category_id_array_unique = category_id_array.filter((item: any, pos: any, self: any) => self.indexOf(item) == pos)

        let catDat: any = []
        for (let i = 0; i < catdata.length; i++) {
          if (category_id_array_unique.includes(catdata[i]['_id'])) {
            catDat.push(catdata[i])
          }
        }

        this.category_data = catDat
        this.allactive = true;
      }

      // this.loaderService = loaderService

      // this.loaderService.isLoading.next(false);
    });

  }
  playvideo(val: any) {
    console.log(val, 'val video play');
    window.open(
      'video-gallery/' + val.product_id
    )
  }
  ngOnInit() {
  }

}
