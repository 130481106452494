import { Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { Observable, Subject, Observer } from 'rxjs';
import { ApiService } from './api.service';
@Injectable({
  providedIn: 'root'
})
export class ObservableService {
  private subject = new Subject<any>();
  constructor(
    public apiservice: ApiService,
    public cookieService: CookieService,
    public activatedRoute: ActivatedRoute,
    public router: Router,
    // public sanitizer: DomSanitizer,
  ) { }
  setlocationsData(data) {
    this.subject.next(data);
  }
  getlocationsData(): Observable<any> {
    return this.subject.asObservable();
  }


}
