import { HttpClient } from "@angular/common/http";
import {
  Component,
  Input,
  OnInit,
  Output,
  EventEmitter,
  Inject,
} from "@angular/core";
import { FormControl, Validators } from "@angular/forms";
import {
  MatBottomSheet,
  MatBottomSheetRef,
  MatDialog,
  MatDialogRef,
  MatSelect,
  MatSelectChange,
  MatSnackBar,
  MatTableDataSource,
  MAT_BOTTOM_SHEET_DATA,
  MAT_DIALOG_DATA,
} from "@angular/material";
import { ActivatedRoute, Router } from "@angular/router";
// import { EventEmitter } from "events";
import { CookieService } from "ngx-cookie-service";
import { Subscription } from "rxjs";
import { ApiService } from "src/app/services/api.service";
import { LoaderService } from "src/app/services/loader.service";
import { NavAccessService } from "src/app/services/nav-access.service";
import { UserserviceService } from "src/app/services/userservice.service";
import { environment } from "src/environments/environment";
export interface DialogData {
  data: any;
  flag: any;
}
@Component({
  selector: "app-lead-add-edit",
  templateUrl: "./lead-add-edit.component.html",
  styleUrls: ["./lead-add-edit.component.css"],
})
export class LeadAddEditComponent implements OnInit {
  emailregex: RegExp =
    /^(([^<>()\[\]\\.,;:\s@']+(\.[^<>()\[\]\\.,;:\s@']+)*)|('.+'))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  passwordregex: RegExp = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})/;
  textregex: RegExp = /^[a-zA-Z]*$/;
  webregex: RegExp =
    /^((ftp|http|https):\/\/)?(www.)?(?!.*(ftp|http|https|www.))[a-zA-Z0-9_-]+(\.[a-zA-Z]+)+((\/)[\w#]+)*(\/\w+\?[a-zA-Z0-9_]+=\w+(&[a-zA-Z0-9_]+=\w+)*)?$/gm;
  public productVal = [];
  public parentVal = [];
  temtdata: any = "";
  @Output() myOutput: EventEmitter<string> = new EventEmitter();
  outputMessage: string = "I am child component.";
  @Input() myinputMsg: string;
  @Input() myRoute: string;
  // formdata
  // public flagproduct: any;
  formfieldrefresh: any = false;
  updatetable: any = false;
  public submitflag: boolean = false;
  public batchdata: any;
  public leads_list: any;
  public fromvalue: any;
  public filename: any;
  formfieldrefreshdata: any = null;
  datasource: any;
  public formdata: any;
  public formdata1: any;
  public stateVal: any = [];
  public nextbtnflg: boolean = false;
  public productsList: any = [];
  public productsname: any;
  public editorconfig: any = {};
  public previewflag: any = false;
  public total_no_data: any = 0;
  public duplicate_data: any = 0;
  public duplicate_data_details: any = [];
  public unique_data: any = 0;
  public tab_header: any;
  public status: any = [
    { val: 1, name: "Active" },
    { val: 0, name: "Inactive" },
  ];
  public lNmae: any;
  public cName: any;
  public address: any;
  public city: any;
  public country: any;
  public state: any;
  public zip: any;
  public Phone: any;
  public email: any;
  public webdata: any;
  public baseurl: any;
  public bucket: any;
  public fileservername: any;
  public name: any;
  public path: any;
  public notes: any;
  public source: any;
  public hospital_name: any;
  public Account_Name: any;
  public Currency: any;
  public Fax: any;
  public EIN_Number: any;
  public Practice_NPI_Number: any;
  public Account_Type: any;
  public Practice_Status: any;
  public Exchange_Rate: any;
  public Practice_EMR: any;
  public Practice_Email: any;
  public Practice_Notes: any;
  public Location_Name: any;
  public Billing_Street: any;
  public Billing_City: any;
  public Billing_State: any;
  public Billing_Zip_Code: any;
  public Billing_Country: any;
  public Shipping_Location_Name: any;
  public Shipping_Street: any;
  public Shipping_City: any;
  public Shipping_State: any;
  public Shipping_Zip_Code: any;
  public Shipping_Country: any;
  public Demo_Completed: any;
  public Amount_Paid_for_Equipment_Warranty: any;
  public Warranty_Contract_Paid: any;
  public Contract_Signed: any;
  public Payment_Method_Document: any;
  public Practice_Questionnaire_Completed: any;
  public PECE_Software_Agreement: any;
  public Number_of_Cuffs: any;
  public OnBoarding_Notes: any;
  public Everyone_Trained: any;
  public Status: any;
  public Shipped: any;
  public Serial_Number: any;
  public Equipment_Notes: any;
  public Pick_List_2: any;
  displayedColumns: string[] = [
    "FirstName",
    "LastName",
    "CompanyName",
    "Address",
  ];
  public dataSource: any;
  public isLoading: boolean = false;
  public flag: boolean = false;
  // public 823: any = [];
  public precticeowner: any = [];
  public productdata: any = [];
  public formdata3: any;
  public currency: any = [{ val: "USD", name: "USD" }];
  public selectflag: boolean = false;
  public practicetype: any = [];
  public equpmenntstatusval: any = [
    { val: "Existing", name: "Existing" },
    { val: "Loaned", name: "Loaned" },
    { val: "Purchased", name: "Purchased" },
  ];
  public pecepracticestatus: any = [
    { val: "Live", name: "Live" },
    { val: "Pending", name: "Pending" },
    { val: "Sold", name: "Sold" },
    { val: "WarrantyOnly", name: "Warranty Only" },
  ];
  public equpmenntversionval: any = [];
  public cmatcontract: any = [];
  public hpsrepval: any = [];
  public hps_details: any = {
    name: "Michael White",
    id: "4754570000000341056",
  };
  public distributorval: any = [
    // {
    //   "name": "Health Profit Solutions",
    //   "val": "4754570000000797009"
    // }
  ];
  public soldbyval: any = [];

  public addtags: any = [];

  public leadeditdata: any = [];
  public resolveval: any;
  // public flag: any;
  public nextflagtrue: boolean = false;
  public csvleaddata: any = [];
  public productdataVal: any = [];
  public productValdata: any = [];
  custombuttons: [
    {
      label: "fb search with blog title";
      link: "https://www.facebook.com/search/top/";
      type: "externallink";
      param: [{ key: "blogtitle"; q: "q" }];
      tooltip: "listner 1234";
      classname: "fbcls";
    }
  ];
  public distributor_details: any = {
    name: "",
    id: "",
  };
  public wrong_data: any = [];
  public subscription: Subscription;
  public productdorselection:any = []
  constructor(
    public nevaccessservice:NavAccessService,
    public apiservice: ApiService,
    public http: HttpClient,
    public activatedRoute: ActivatedRoute,
    public cookies: CookieService,
    public router: Router,
    public userservice: UserserviceService,
    private snackBar: MatSnackBar,
    private _bottomSheet: MatBottomSheet,
    private loaderService: LoaderService,
    public dialog: MatDialog
  ) {
    let dist_send_data = {};
    if (this.cookies.check("parentid")) {
      dist_send_data = {
        parent_id: JSON.parse(this.cookies.get("parentid")),
      };
      this.apiservice
        .getDataWithoutToken(dist_send_data, "api10/get_parent_zoho_id")
        .subscribe((data: any) => {
          this.distributor_details.id = data.res[0].zoho_id;
          this.distributor_details.name =
            data.res[0].firstname + " " + data.res[0].lastname;
        });
    } else {
      this.distributor_details.id = "4754570000004755022";
      this.distributor_details.name = "Beto Paredes";
    }
    console.log(this.myinputMsg, "+*-+*-+*-+");

    console.log(Math.round(new Date().getTime()), "+++++++++++++++++");

    this.getproductlist();
    this.getparentlist();
    // this.getproductselectlist();

    this.addtags.push({
      satatus: "Manual",
      time: Math.round(new Date().getTime()),
    });
    console.log(this.addtags);
    this.apiservice.getState().subscribe((res: any) => {
      for (const i in res) {
        if (Object.prototype.hasOwnProperty.call(res, i)) {
          this.stateVal.push({ val: res[i].abbreviation, name: res[i].name });
        }
      }
    });

    this.formdata = {
      successmessage: "",
      redirectpath: this.userservice.type + "/" + "lead/list",
      submitactive: true, // optional, default true
      submittext: "Submit",
      resettext: "Reset",
      canceltext: "Cancel",
      apiUrl: this.apiservice.api_url,
      endpoint: "",
      jwttoken: this.cookies.get("jwtToken"),
      cancelroute: this.userservice.type + "/" + "lead/list",

      fields: [
        {
          label: "First Name",
          name: "firstname", //
          value: "",
          type: "text",
          validations: [
            { rule: "required", message: "First Name is Required" },
          ],
        },
        {
          heading: "",
          label: "Last Name",
          name: "lastname",
          value: "",
          type: "text",
          validations: [{ rule: "required", message: "Last Name is Required" }],
        },
        {
          label: "Telephone",
          name: "phone",
          value: "",
          type: "number",
          validations: [
            { rule: "required", message: "Telephone is Required" },
            {
              rule: "minLength",
              value: 10,
              message: "The Phone Number must be 10 digits",
            },
            {
              rule: "maxLength",
              value: 15,
              message: "Please enter the valid number",
            },
          ],
        },
        {
          label: "Email",
          name: "email",
          type: "email",
          hint: "",
          validations: [
            { rule: "required", message: "Email is required" },
            {
              rule: "pattern",
              value: this.emailregex,
              message: "Must be a valid Email",
            },
          ],
        },
        {
          label: "Company Name",
          name: "company",
          value: "",
          type: "text",
          validations: [
            { rule: "required", message: "Company Name is Required" },
          ],
        },
        {
          label: "Address",
          name: "address",
          value: "",
          type: "textarea",
          validations: [{ rule: "required", message: "Address is Required" }],
        },
        {
          label: "type",
          name: "type",
          type: "hidden",
          value: "lead",
        },
        {
          label: "Tech Name",
          name: "parentid",
          val: this.parentVal,
          type: "select",
          validations: [
            { rule: "required", message: "Tech Name  is Required" },
          ],
        },
        {
          label: "tags",
          name: "tags",
          type: "hidden",
          value: [
            {
              satatus: "Manual",
              updated_datetime: Math.round(new Date().getTime()),
            },
            {
              satatus: "New Lead",
              updated_datetime: Math.round(new Date().getTime()),
            },
          ],
        },
        {
          label: "State",
          name: "state",
          val: this.stateVal,
          value: "",
          type: "select",
          validations: [{ rule: "required", message: "State is Required" }],
        },
        {
          label: "City",
          name: "city",
          value: "",
          type: "text",
          validations: [{ rule: "required", message: "City is Required" }],
        },
        {
          label: "Zip",
          name: "zip",
          value: "",
          type: "number",
          validations: [
            { rule: "required", message: "Zip is Required" },
            {
              rule: "maxLength",
              value: 5,
              message: "Please enter the valid Zip ID",
            },
          ],
        },
        {
          label: "Products",
          name: "products",
          val: this.productVal,
          type: "select",
          multiple: true,
          validations: [{ rule: "required", message: "Products  is Required" }],
        },
        {
          label: "Active",
          name: "status",
          hint: "",
          type: "checkbox",
          val: this.status,
          value: 0,
        },
      ],
    };
    if (this.userservice.type == "technological-consultant") {
      this.formdata.fields[7].type = "hidden";
      delete this.formdata.fields[7].val;
      this.formdata.fields[7].value = JSON.parse(this.cookies.get("userid"));
      console.log(this.formdata.fields);
      this.formdata.fields[12].val = this.productdataVal;
    }
    console.log(
      this.activatedRoute.snapshot.params._id,
      "sssssssssssssssssssssss"
    );

    if (this.activatedRoute.snapshot.params._id != null) {
      this.flag = true;
      this.editlead();
    } else {
      this.lead_data();
    }

    // const data: any = {
    //   "secret": this.userservice.secret, "token": this.userservice.jwttoken
    //   , "source": "", "condition": { "limit": 10, "skip": 0 }, "sort": { "type": "desc", "field": "_id" }
    // };
    // this.apiservice.getDataWithoutToken(data, 'api/productlistview').subscribe((res: any) => {

    //   console.log(res);
    //   for (const key in res.results.res) {
    //     this.productsList.push({ val: res.results.res[key]._id, name: res.results.res[key].productname })
    //   }
    // })
  }

  lead_data() {
    this.getdata();
    this.getproductlist1();
    // console.log(this.hpsrepval, "this.distributorval");
    this.formdata3 = {
      successmessage: "",
      redirectpath: "",
      submitactive: true, // optional, default true
      submittext: "Submit",
      resettext: "Reset",
      canceltext: "Cancel",
      apiUrl: this.apiservice.api_url,
      // endpoint: "practice",
      jwttoken: this.cookies.get("jwtToken"),
      // cancelroute: "",

      fields: [
        {
          label: "HPS Representative",
          name: "HPS_Representative",
          value: null,
          // multiple:true,
          val: this.hps_details,
          type: "hidden",
          // validations: [
          //   { rule: "required", message: "HPS Representative is Required" },
          // ],
        },
        {
          label: "Distributor",
          name: "Distributor",
          value: null,
          val: this.distributor_details,
          type: "hidden",
          // validations: [
          //   { rule: "required", message: "Distributor is Required" },
          // ],
        },
        // {
        //   label: "Sales Person",
        //   name: "Sold_by",
        //   value: this.soldbyval[0].val,
        //   type: "hidden",
        //   // validations: [
        //   //   { rule: "required", message: "Sales Person is Required" },
        //   // ],
        // },
        {
          label: "Practice Name / Company",
          name: "Account_Name",
          // hint:"Practice Name is Required",
          value: "",
          type: "text",
          validations: [
            {
              rule: "required",
              message: "Practice Name / Company is Required",
            },
          ],
          prefix: "*",
        },
        {
          label: "Main Contact Name",
          name: "main_contact_name",
          // hint:"Main Contact Name is Required",
          value: "",
          type: "text",
          validations: [
            { rule: "required", message: "Main Contact Name is Required" },
          ],
          prefix: "*",
        },
        {
          label: "Main Contact Phone",
          name: "Phone",
          value: "",
          type: "number",
          validations: [
            { rule: "required", message: "Main Contact Phone is Required" },
          ],
          prefix: "*",
        },
        {
          label: "Main Contact Email",
          name: "Practice_Email",
          // hint:"Practice Email is Required",
          value: "",
          type: "text",
          validations: [
            { rule: "required", message: "Main Contact Email is Required" },
            {
              rule: "pattern",
              value: this.emailregex,
              message: "Must be a valid Email",
            },
          ],
          prefix: "*",
        },

        {
          label: "Currency",
          name: "Currency",
          value: "USD",
          type: "hidden",
          // validations: [
          //   { rule: "required", message: "Currency is Required" },
          // ],
        },
        {
          label: "Fax",
          name: "Fax",
          value: "",
          type: "text",
          // validations: [
          //   { rule: "required", message: "Fax is Required" },
          // ],
        },
        {
          label: " EIN Number",
          name: "EIN_Number",
          value: "",
          type: "number",
          validations: [
            {
              rule: "maxLength",
              value: 9,
              message: "Please enter the valid 9 digit EIN Number of ",
            },
          ],
        },
        {
          label: "Practice NPI Number",
          name: "Practice_NPI_Number",
          value: "",
          type: "text",
          // validations: [
          //   { rule: "required", message: "Practice NPI Number is Required" },
          // ],
        },
        // {
        //   label: "Practice Type",
        //   name: "Account_Type",
        //   value: "",
        //   val: this.practicetype,
        //   type: "select",
        //   // validations: [
        //   //   { rule: "required", message: "Practice Type is Required" },
        //   // ],
        // },
        {
          label: "Practice PECE Status",
          name: "Practice_Status",
          value: "Pending",
          // hint:'None',
          // val: this.pecepracticestatus,
          type: "hidden",
        },
        {
          label: "Exchange Rate",
          name: "Exchange_Rate",
          value: 1,

          type: "hidden",
          // validations: [
          //   { rule: "required", message: "Exchange Rate is Required" },
          // ],
        },
        {
          label: "Practice EMR",
          name: "Practice_EMR",
          value: "",
          type: "text",
          // validations: [
          //   { rule: "required", message: "Practice EMR is Required" },
          // ],
        },
        {
          label: "Website",
          name: "Website",
          parttern:
            /^((ftp|http|https):\/\/)?(www.)?(?!.*(ftp|http|https|www.))[a-zA-Z0-9_-]+(\.[a-zA-Z]+)+((\/)[\w#]+)*(\/\w+\?[a-zA-Z0-9_]+=\w+(&[a-zA-Z0-9_]+=\w+)*)?$/gm,
          value: "",
          type: "text",
          validations: [
            // { rule: "required", message: "Website is Required" },
            {
              rule: "pattern",
              value: this.webregex,
              message: " Use Proper Web Site Format ",
            },
          ],
        },
        {
          label: "Practice Notes",
          name: "Practice_Notes",
          value: "",
          type: "textarea",
          labelPosition: "after",
          // validations: [
          //   { rule: "required", message: "Practice Notes is Required" },
          // ],
        },

        {
          heading: "Address Information",
          name: "statusgroup",
          hint: ",0000",
          type: "group",
          fields: [
            // {
            //   label: "Age1",
            //   name: "age1",
            //   type: 'number',
            //   hint: 'Age ??',
            //   validations: [
            //       // { rule: 'required' },
            //       // { rule: 'min', value: 5 },
            //       // { rule: 'max', value: 50, message: "Age can't be more than 50 " }
            //   ]
            // },
          ],
        },
        {
          label: "Billing Location Name",
          name: "Location_Name",
          value: "",
          type: "text",

          // validations: [
          //   { rule: "required", message: "Billing Street is Required" },
          // ],
        },
        {
          label: "Billing Street",
          name: "Billing_Street",
          value: "",
          type: "text",

          // validations: [
          //   { rule: "required", message: "Billing Street is Required" },
          // ],
        },
        {
          label: "Billing State",
          name: "Billing_State",
          val: this.stateVal,
          value: "",
          type: "select",

          // validations: [
          //   { rule: "required", message: "Billing Street is Required" },
          // ],
        },
        {
          label: " Billing City",
          name: "Billing_City",
          value: "",
          type: "text",

          // validations: [
          //   { rule: "required", message: "Billing Street is Required" },
          // ],
        },
        {
          label: " Billing Zip Code",
          name: "Billing_Zip_Code",
          value: "",
          type: "number",

          // validations: [
          //   { rule: "required", message: "Billing Street is Required" },
          // ],
        },
        {
          label: "Billing Country",
          name: "Billing_Country",
          value: "",
          type: "text",

          // validations: [
          //   { rule: "required", message: "Billing Street is Required" },
          // ],
        },

        {
          label: "Set billing address as shipping address",
          name: "set_billing_address_as_shipping_address",
          type: "hidden",
          value: "",
        },
        {
          label: "Shipping Location Name",
          name: "Shipping_Location_Name",
          value: "",
          type: "text",

          // validations: [
          //   { rule: "required", message: "Billing Street is Required" },
          // ],
        },
        {
          label: "Shipping Street",
          name: "Shipping_Street",
          value: "",
          type: "text",

          // validations: [
          //   { rule: "required", message: "Billing Street is Required" },
          // ],
        },
        {
          label: "Shipping State",
          name: "Shipping_State",
          val: this.stateVal,
          value: "",
          type: "select",

          // validations: [
          //   { rule: "required", message: "Billing Street is Required" },
          // ],
        },
        {
          label: "Shipping City",
          name: "Shipping_City",
          value: "",
          type: "text",

          // validations: [
          //   { rule: "required", message: "Billing Street is Required" },
          // ],
        },

        {
          label: "Shipping Zip Code",
          name: "Shipping_Zip_Code",
          value: "",
          type: "number",

          // validations: [
          //   { rule: "required", message: "Billing Street is Required" },
          // ],
        },
        {
          label: "Shipping Country",
          name: "Shipping_Country",
          value: "",
          type: "text",

          // validations: [
          //   { rule: "required", message: "Billing Street is Required" },
          // ],
        },
        // {
        //   heading: "OnBoarding Checklist (DO NOT UPLOAD-Use ZOHO SIGN)",
        //   name: "statusgroup",
        //   hint: ',0000',
        //   type: 'group',
        //   fields: [
        //     // {
        //     //   label: "Age1",
        //     //   name: "age1",
        //     //   type: 'number',
        //     //   hint: 'Age ??',
        //     //   validations: [
        //     //       // { rule: 'required' },
        //     //       // { rule: 'min', value: 5 },
        //     //       // { rule: 'max', value: 50, message: "Age can't be more than 50 " }
        //     //   ]
        //     // },

        //   ]
        // },

        // {
        //   label: "Demo Completed",
        //   name: "Demo_Completed",
        //   value: "",
        //   type: "text",

        //   // validations: [
        //   //   { rule: "required", message: "Billing Street is Required" },
        //   // ],
        // },
        // {
        //   label: "CMAT Contract",
        //   name: "cmat_Country",
        //   value: "",
        //   val: this.cmatcontract,
        //   type: "select",

        //   // validations: [
        //   //   { rule: "required", message: "Billing Street is Required" },
        //   // ],
        // },
        // {
        //   label: "Amount Paid for Equipment Warranty",
        //   name: "Amount_Paid_for_Equipment_Warranty",
        //   value: "",
        //   type: "text",

        //   // validations: [
        //   //   { rule: "required", message: "Billing Street is Required" },
        //   // ],
        // },
        // {
        //   label: "Warranty Contract Paid",
        //   name: "Warranty_Contract_Paid",
        //   value: "",
        //   type: "text",

        //   // validations: [
        //   //   { rule: "required", message: "Billing Street is Required" },
        //   // ],
        // },
        // {
        //   label: "All Contracts Signed",
        //   name: "Contract_Signed",
        //   value: "",
        //   type: "text",

        //   // validations: [
        //   //   { rule: "required", message: "Billing Street is Required" },
        //   // ],
        // },
        // {
        //   label: "Payment Authorization Form",
        //   name: "Payment_Method_Document",
        //   value: "",
        //   type: "text",

        //   // validations: [
        //   //   { rule: "required", message: "Billing Street is Required" },
        //   // ],
        // },
        // {
        //   label: "Practice Information Worksheet",
        //   name: "Practice_Questionnaire_Completed",
        //   value: "",
        //   type: "text",

        //   // validations: [
        //   //   { rule: "required", message: "Billing Street is Required" },
        //   // ],
        // }, {
        //   label: "PECE Software Agreement",
        //   name: "PECE_Software_Agreement",
        //   value: "",
        //   type: "text",

        //   // validations: [
        //   //   { rule: "required", message: "Billing Street is Required" },
        //   // ],
        // }, {
        //   label: "Number of Cuffs",
        //   name: "Number_of_Cuffs",
        //   value: "",
        //   type: "text",

        //   // validations: [
        //   //   { rule: "required", message: "Billing Street is Required" },
        //   // ],
        // }, {
        //   label: "OnBoarding Notes",
        //   name: "OnBoarding_Notes",
        //   value: "",
        //   type: "textarea",

        //   // validations: [
        //   //   { rule: "required", message: "Billing Street is Required" },
        //   // ],
        // },
        // {
        //   label: "Everyone Trained",
        //   name: "Everyone_Trained",
        //   value: "",
        //   type: "text",

        //   // validations: [
        //   //   { rule: "required", message: "Billing Street is Required" },
        //   // ],
        // },

        // {
        //   heading: "Equipment",
        //   name: "statusgroup",
        //   hint: ",0000",
        //   type: "group",
        //   fields: [
        //     // {
        //     //   label: "Age1",
        //     //   name: "age1",
        //     //   type: 'number',
        //     //   hint: 'Age ??',
        //     //   validations: [
        //     //       // { rule: 'required' },
        //     //       // { rule: 'min', value: 5 },
        //     //       // { rule: 'max', value: 50, message: "Age can't be more than 50 " }
        //     //   ]
        //     // },
        //   ],
        // },
        // {
        //   label: "Equipment Status",
        //   name: "Status",
        //   value: "",
        //   val: this.equpmenntstatusval,
        //   type: "select",

        //   // validations: [
        //   //   { rule: "required", message: "Billing Street is Required" },
        //   // ],
        // },
        // {
        //   label: "Equipment Version",
        //   name: "Equipment_Version",
        //   value: "",
        //   val: this.equpmenntversionval,
        //   type: "select",

        //   // validations: [
        //   //   { rule: "required", message: "Billing Street is Required" },
        //   // ],
        // },
        // {
        //   label: "Equipment Shipped",
        //   name: "Shipped",
        //   value: "",
        //   type: "date",

        //   // validations: [
        //   //   { rule: "required", message: "Billing Street is Required" },
        //   // ],
        // },
        // {
        //   label: "Equipment Payment Method",
        //   name: "Equipment_Serial_Number",
        //   value: "",
        //   type: "text",

        //   // validations: [
        //   //   { rule: "required", message: "Billing Street is Required" },
        //   // ],
        // },
        // {
        //   label: "Equipment Status Date",
        //   name: "Status_Date",
        //   type: "date",
        //   value: "",
        //   // hint: "05/05/2020",

        //   // validations: [
        //   //   { rule: "required", message: "Billing Street is Required" },
        //   // ],
        // },
        // {
        //   label: "Equipment Serial Number",
        //   name: "Serial_Number",
        //   value: "",
        //   type: "text",

        //   // validations: [
        //   //   { rule: "required", message: "Billing Street is Required" },
        //   // ],
        // },

        // {
        //   label: "Equipment Notes",
        //   name: "Equipment_Notes",
        //   value: "",
        //   type: "textarea",

        //   // validations: [
        //   //   { rule: "required", message: "Billing Street is Required" },
        //   // ],
        // },
        {
          label: "tags",
          name: "tags",
          type: "hidden",
          value: [
            {
              satatus: "Manual",
              updated_datetime: Math.round(new Date().getTime()),
            },
            {
              satatus: "New Lead",
              updated_datetime: Math.round(new Date().getTime()),
            },
          ],
        },
        // {
        //   label: "Dispositionlead",
        //   name: "dispositionlead",
        //   type: "hidden",
        //   value: [
        //     {
        //       status: "No Discovery",
        //       created_date: Math.round(new Date().getTime()),
        //     },
        //   ],
        // },
        {
          label: "Active",
          name: "status",
          hint: "",
          type: "checkbox",
          // val: this.status,
          value: 0,
        },
      ],
    };
    console.log(this.formdata3);
  }
  onChangeTimezone(val: any) {
    console.log(val, "productdata", { source: MatSelect });
    if (val != null && val != "") {
      setTimeout(() => {
        this.selectflag = true;
      }, 500);
    }
    // if (this.activatedRoute.snapshot.params._id != null) {
    //   this.flagproduct == false;
    // }
  }

  getdata() {
    this.apiservice
      .getDataWithoutToken(
        {
          token: this.userservice.jwttoken,
          secret: this.userservice.secret,
        },
        "api/exitsing_auto_complete_hps"
      )
      .subscribe((response: any) => {
        // this.productVal=response.res
        console.log(response, "madhumitaaaaa11111111111111111111");

        for (const i in response.res) {
          if (Object.prototype.hasOwnProperty.call(response.res, i)) {
            this.hpsrepval.push({
              key: response.res[i].id,
              val: response.res[i].name,
            });
          }
        }
        console.log(this.hpsrepval, "kkkkkkkkkkkkkkkkkkkk");
      });
    this.apiservice
      .getDataWithoutToken(
        {
          token: this.userservice.jwttoken,
          secret: this.userservice.secret,
        },
        "api/exitsing_auto_complete_distributor"
      )
      .subscribe((response1: any) => {
        // this.productVal=response.res
        console.log(response1, "madhumitaaaaa");

        for (const i in response1.res) {
          if (Object.prototype.hasOwnProperty.call(response1.res, i)) {
            this.distributorval.push({
              key: response1.res[i].id,
              val: response1.res[i].name,
            });
          }
        }
      });
    this.apiservice
      .getDataWithoutToken(
        {
          token: this.userservice.jwttoken,
          secret: this.userservice.secret,
        },
        "api/exitsing_equipment_version_searchs"
      )
      .subscribe((response1: any) => {
        // this.productVal=response.res
        console.log(response1, "madhumitaaaaa");

        for (const i in response1.res) {
          if (Object.prototype.hasOwnProperty.call(response1.res, i)) {
            this.equpmenntversionval.push({
              val: response1.res[i].val,
              name: response1.res[i].name,
            });
          }
        }
      });

    this.apiservice
      .getDataWithoutToken(
        {
          token: this.userservice.jwttoken,
          secret: this.userservice.secret,
        },
        "api/exitsing_practice_type_searchs"
      )
      .subscribe((response1: any) => {
        // this.productVal=response.res
        console.log(response1, "madhumitaaaaa");

        for (const i in response1.res) {
          if (Object.prototype.hasOwnProperty.call(response1.res, i)) {
            this.practicetype.push({
              val: response1.res[i].val,
              name: response1.res[i].name,
            });
          }
        }
      });

    this.apiservice
      .getDataWithoutToken(
        {
          token: this.userservice.jwttoken,
          secret: this.userservice.secret,
          user_id: JSON.parse(this.cookies.get("userid")),
        },
        "api/exitsing_technical_consultant_searchs"
      )
      .subscribe((response: any) => {
        // this.productVal=response.res
        console.log(response, "madhumitaaaaa11111111111111111111");

        for (const i in response.res) {
          if (Object.prototype.hasOwnProperty.call(response.res, i)) {
            this.soldbyval.push({
              val: response.res[i].id,
              name: response.res[i].name,
            });
          }
        }
        console.log(this.hpsrepval, "kkkkkkkkkkkkkkkkkkkk");
      });
  }

  getproductlist1() {
    this.apiservice
      .getDataWithoutToken(
        {
          token: this.userservice.jwttoken,
          secret: this.userservice.secret,
        },
        "api/productsearch"
      )
      .subscribe((response: any) => {
        // this.productVal=response.res
        // // console.log(this.productVal,'madhumitaaaaa')
        for (const i in response.res) {
          if (Object.prototype.hasOwnProperty.call(response.res, i)) {
            this.productValdata.push({
              val: response.res[i].val,
              name: response.res[i].name,
            });
          }
        }
      });
  }
  public errormessage = "";
  listenFormFieldChanges2(val: any) {
    console.log(val);
    if (
      val.field.name == "Location_Name" ||
      val.field.name == "Billing_Street" ||
      val.field.name == "Billing_State" ||
      val.field.name == "Billing_State" ||
      val.field.name == "Billing_City" ||
      val.field.name == "Billing_Zip_Code" ||
      val.field.name == "Billing_Country"
    ) {
      this.formdata3.fields[22].type = "checkbox";
    }
    if (val.field.name == "set_billing_address_as_shipping_address") {
      if (val.fieldval) {
        let formdata: any = {
          Shipping_Location_Name: val.fromval.Location_Name,
          Shipping_Street: val.fromval.Billing_Street,
          Shipping_State: val.fromval.Billing_State,
          Shipping_City: val.fromval.Billing_City,
          Shipping_Zip_Code: val.fromval.Billing_Zip_Code,
          Shipping_Country: val.fromval.Billing_Country,
        };
        // console.warn('formdata',formdata)
        this.formfieldrefreshdata = { formvaldata: formdata };
        // this.formdata3.fields[22].value = val.fromval.Location_Name;
        // this.formdata3.fields[23].value = val.fromval.Billing_Street;
        // this.formdata3.fields[24].value = val.fromval.Billing_State;
        // this.formdata3.fields[25].value = val.fromval.Billing_City;
        // this.formdata3.fields[26].value = val.fromval.Billing_Zip_Code;
        // this.formdata3.fields[27].value = val.fromval.Billing_Country;
        console.log(this.formdata3.fields);
      } else {
        let formdata: any = {
          Shipping_Location_Name: "",
          Shipping_Street: "",
          Shipping_State: "",
          Shipping_City: "",
          Shipping_Zip_Code: "",
          Shipping_Country: "",
        };
        // console.warn('formdata',formdata)
        this.formfieldrefreshdata = { formvaldata: formdata };
        // this.formdata3.fields[22].value = "";
        // this.formdata3.fields[23].value = "";
        // this.formdata3.fields[24].value = "";
        // this.formdata3.fields[25].value = "";
        // this.formdata3.fields[26].value = "";
        // this.formdata3.fields[27].value = "";
        console.log(this.formdata3.fields);
      }
    }
    if (val.field == "formcancel") {
      if (this.myRoute != undefined && this.myRoute != "") {
        console.log("+++++");

        this.myOutput.emit("formcancel");
        // this.router.navigateByUrl(this.myRoute);
      } else {
        this.router.navigateByUrl(
          "/" + this.userservice.type + "/" + "lead/list"
        );
        console.log("/" + this.userservice.type + "/" + "lead/list");
        console.log("-----");
      }
    }
    if (val.action == "externaldata") {
      this.formdata3.fields[23].value = val;
    }

    var names_hps1: any = {
      name: "",
      id: "",
    };
    var distributorvaldata: any = {
      name: "",
      id: "",
    };
    var soldbyvaldata: any = {
      name: "",
      id: "",
    };
    var x: any = 1;

    if (val.field == "fromsubmit") {
      // if (
      //   val.fromval &&
      //   val.fromval.HPS_Representative != null &&
      //   typeof val.fromval.HPS_Representative != "undefined" &&
      //   val.fromval.HPS_Representative != ""
      // ) {
      //   for (const key in this.hpsrepval) {
      //     // console.log(this.hpsrepval[key], "this.hpsrepval");

      //     if (this.hpsrepval[key].key == val.fromval.HPS_Representative) {
      //       // console.log(this.hpsrepval[key].name);
      //       names_hps1.name = this.hpsrepval[key].val;
      //       names_hps1.id = val.fromval.HPS_Representative;
      //     }
      //   }
      // }
      // if (
      //   val.fromval &&
      //   val.fromval.Distributor != null &&
      //   val.fromval.Distributor != ""
      // ) {
      //   for (const key in this.distributorval) {
      //     // console.log(
      //     //   this.distributorval[key].key,
      //     //   "this.distributorval[key].key",
      //     //   val.fromval.Distributor
      //     // );

      //     if (this.distributorval[key].key == val.fromval.Distributor) {
      //       // console.log(this.distributorval[key].name);
      //       distributorvaldata.name = this.distributorval[key].val;
      //       distributorvaldata.id = val.fromval.Distributor;
      //     }
      //   }
      // }
      this.loaderService.isLoading.next(true);
      // console.log(this.productdata, "this.productdata");

      let data: any = {
        data: {
          Owner: {
            name: "Larry Fresinski",
            id: "4754570000000298001",
            email: "larry@hpspros.com",
          },
          HPS_Representative: this.hps_details,
          Distributor: this.distributor_details,
          // Sold_by: {
          //   name: this.soldbyval[0].name,
          //   id: this.soldbyval[0].val,
          // },
          Account_Name: val.fromval.Account_Name,
          main_contact_name: val.fromval.main_contact_name,
          Account_Type: val.fromval.Account_Type,
          // Amount_Paid_for_Equipment_Warranty: val.fromval.Amount_Paid_for_Equipment_Warranty,
          Billing_City: val.fromval.Billing_City,
          Billing_Country: val.fromval.Billing_Country,
          Billing_Street: val.fromval.Billing_Street,
          Billing_Zip_Code: val.fromval.Billing_Zip_Code,
          Billing_State: val.fromval.Billing_State,
          Currency: val.fromval.Currency,
          EIN_Number: val.fromval.EIN_Number,
          Equipment_Notes: val.fromval.Equipment_Notes,
          Equipment_Serial_Number: val.fromval.Equipment_Serial_Number,
          Pick_List_2: val.fromval.Equipment_Version,
          Exchange_Rate: val.fromval.Exchange_Rate,
          Fax: val.fromval.Fax,
          Location_Name: val.fromval.Location_Name,
          Phone: val.fromval.Phone,
          Practice_EMR: val.fromval.Practice_EMR,
          Practice_Email: val.fromval.Practice_Email,
          Practice_NPI_Number: val.fromval.Practice_NPI_Number,
          Practice_Notes: val.fromval.Practice_Notes,
          Practice_Status: val.fromval.Practice_Status,
          Serial_Number: val.fromval.Serial_Number,
          Shipped: this.dateconverter(val.fromval.Shipped),
          Shipping_City: val.fromval.Shipping_City,
          Shipping_Country: val.fromval.Shipping_Country,
          Shipping_Location_Name: val.fromval.Shipping_Location_Name,
          Shipping_State: val.fromval.Shipping_State,
          Shipping_Street: val.fromval.Shipping_Street,
          Shipping_Zip_Code: val.fromval.Shipping_Zip_Code,
          Status: val.fromval.Status,
          Status_Date: this.dateconverter(val.fromval.Status_Date),
          Website: val.fromval.Website,
          billinglocationname: val.fromval.billinglocationname,
          // statusgroup: val.fromval.statusgroup,
          type: "lead",
          products: this.productdata,
          parentid: this.userservice.userid,
          status: val.fromval.status,
          tags: val.fromval.tags,
          // dispositionlead: val.fromval.dispositionlead,
        },
      };
      if (
        typeof val.fromval != "undefined" &&
        typeof val.fromval.id != "undefined" &&
        val.fromval.id != null
      ) {
        data.data.id = val.fromval.id;
      }
      console.log(data, "llllllllllllllllll");

      this.apiservice
        .getDataWithoutToken(data, "api3/usersinguptwo")
        .subscribe((response: any) => {
          this.loaderService.isLoading.next(false);
          if (response.status == "success") {
            // this._bottomSheet.open(BottomSheetforaddlead);
            // this.router.navigateByUrl("user-details/lead/" + response.result._id);
            // const bottomSheetRef = this._bottomSheet.open(BottomSheetforaddlead, {
            //   data: response.result
            // });
            response["flag"] = this.myRoute;
            let bottomSheetRef = this._bottomSheet.open(BottomSheetforaddlead, {
              data: response,
            });
            bottomSheetRef.afterDismissed().subscribe(() => {
              this.myOutput.emit("editsuccessfully");
            });
          } else {
            let bottomSheetRef = this._bottomSheet.open(BottomSheetforaddlead, {
              data: response,
            });
            bottomSheetRef.afterDismissed().subscribe(() => {
              this.myOutput.emit("editsuccessfully");
            });
          }
        });
    }
  }
  cancel() {
    console.log("ll");
    this.nextflagtrue = false;

    this.router.navigateByUrl(this.userservice.type + "/" + "lead/list");
  }

  dateconverter(val) {
    console.log(val);

    let statusdate = "";
    if (val != null && val != "") {
      var date = new Date(val),
        mnth = ("0" + (date.getMonth() + 1)).slice(-2),
        day = ("0" + date.getDate()).slice(-2);
      return [date.getFullYear(), mnth, day].join("-");
    } else {
      return null;
    }
  }

  submit(val) {
    console.log(val, "val");
    this.submitflag = false;
    if (val == "Next") {
      this.loaderService.isLoading.next(true);
      let data: any = {
        data: [
          {
            Account_Name: this.Account_Name,
            Phone: this.Phone,
            Fax: this.Fax,
            EIN_Number: this.EIN_Number,
            Practice_NPI_Number: this.Practice_NPI_Number,
            Practice_EMR: this.Practice_EMR,
            Practice_Email: this.Practice_Email,
            Website: this.webdata,
            Practice_Notes: this.Practice_Notes,
            Location_Name: this.Location_Name,
            Billing_Street: this.Billing_Street,
            Billing_State: this.Billing_State,
            Billing_City: this.Billing_City,
            Billing_Zip_Code: this.Billing_Zip_Code,
            Billing_Country: this.Billing_Country,
            Shipping_Location_Name: this.Shipping_Location_Name,
            Shipping_Street: this.Shipping_Street,
            Shipping_State: this.Shipping_State,
            Shipping_City: this.Shipping_City,
            Shipping_Zip_Code: this.Shipping_Zip_Code,
            Shipping_Country: this.Shipping_Country,
            status: this.Status,
            Currency: "USD",
          },
        ],
        screenshots: [
          {
            baseurl: this.baseurl,
            bucket: this.bucket,
            fileservername: this.fileservername,
            name: this.name,
            path: this.path,
          },
        ],
        filename: this.filename,
        product: this.fromvalue.products,
        // sold_by_id: this.soldbyval[0].val,
        secret: this.userservice.secret,
        token: this.userservice.jwttoken,
      };
      this.apiservice
        .customRequest(data, "api/writecsv", environment["api_url"])
        .subscribe((response: any) => {
          window.scrollTo({
            top: 0,
            left: 0,
            behavior: "smooth",
          });
          this.previewflag = true;
          this.submitflag = true;
          this.dataSource = JSON.parse(JSON.stringify(response.results));
          this.leads_list = JSON.parse(JSON.stringify(response.results));
          this.duplicate_data_details = JSON.parse(
            JSON.stringify(response.duplicatedatalist)
          );
          for (const key in this.duplicate_data_details) {
            this.duplicate_data_details[key].error_type = "Already Registered";
          }
          let array = this.checkForDuplicates(
            JSON.parse(JSON.stringify(response.results))
          );
          console.warn(array);
          this.dataSource = array;
          this.leads_list = array;
          this.duplicate_data = this.duplicate_data_details.length;
          this.unique_data = this.unique_data + this.leads_list.length;
          this.total_no_data =
            this.duplicate_data + this.unique_data + this.wrong_data.length;
          if (this.leads_list.length > 0) {
            this.nextbtnflg = true;
            this.tab_header = Object.keys(this.leads_list[0]);
          }
          this.loaderService.isLoading.next(false);
        });
    }
    if (val == "Submit") {
      this.loaderService.isLoading.next(true);
      for (const key in this.dataSource) {
        this.dataSource[key].products = this.fromvalue.products;
        this.dataSource[key].type = "lead";
        this.dataSource[key].status = 1;
        this.dataSource[key].tags = [
          {
            satatus: "New Lead",
            updated_datetime: Math.round(new Date().getTime()),
          },
          {
            satatus: "Bulk",
            updated_datetime: Math.round(new Date().getTime()),
          },
          {
            satatus: this.fromvalue.batchname,
            updated_datetime: Math.round(new Date().getTime()),
          },
        ];
        if (this.userservice.type == "admin") {
          this.dataSource[key].parentid = this.fromvalue.parentid;
        } else {
          this.dataSource[key].parentid = this.userservice.userid;
        }
        this.dataSource[key].batchname = this.fromvalue.batchname;
        this.dataSource[key]["Owner"] = {
          name: "Larry Fresinski",
          id: "4754570000000298001",
          email: "larry@hpspros.com",
        };
        this.dataSource[key]['HPS_Representative'] = this.hps_details;
        this.dataSource[key]['Distributor'] = this.distributor_details;
        // this.dataSource[key]['Sold_by'] = {
        //   name: this.soldbyval[0].name,
        //   id: this.soldbyval[0].val,
        // };
        // this.dataSource[key]['dispositionlead'] = {
        //   status: "No Discovery",
        //   updated_datetime: Math.round(new Date().getTime()),
        // }
      }
      // console.log(this.dataSource)
      // return;
      let data = {
        data: this.dataSource,

        secret: this.userservice.secret,
        token: this.userservice.jwttoken,
      };
      console.log(data, "+++++++++++");
      this.apiservice
        .customRequest(data, "api3/multipleusersignup", environment["api_url"])
        .subscribe((response: any) => {
          if (response.status == "success") {
            this.loaderService.isLoading.next(false);
            const dialogRef = this.dialog.open(ViewSuccessDialogBulkUpload, {
              //this is use for show how many new leads are insert and success message
              // width: '250px',
              panelClass: "addedleadmodal",
              data: { data: this.unique_data },
              disableClose: true, //Disable click outside of angular material dialog area to close the dialog
            });

            dialogRef.afterClosed().subscribe((result) => {
              // console.log('The dialog was closed');
            });
          }
        });
    }
    console.log(this.batchdata);
  }
  multipleleadlistcancle() {
    this.previewflag = false;
    this.submitflag = false;
  }

  //   malformedJSON2Array (tar) {
  //     var arr = [];
  //     tar = tar.replace(/^\{|\}$/g,'').split(',');
  //     for(var i=0,cur,pair;cur=tar[i];i++){
  //         arr[i] = {};
  //         pair = cur.split(':');
  //         arr[i][pair[0]] = /^\d*$/.test(pair[1]) ? +pair[1] : pair[1];
  //     }
  //     return arr;
  // }

  // malformedJSON2Array("{a:12, b:c, foo:bar}");
  checkForDuplicates(array: any) {
    // this function is use for check duplicate and worn value(email) in lead list
    const regex = /\S+@\S+\.\S+/; // this is use to check email is valid or not
    console.log(array, "array");
    for (let key = 0; key < array.length; key++) {
      console.log(key, "key");

      let data = array[key];
      console.log(data, "data");
      let split_phone = data.Phone.split("-").join(""); // this is use to store phone number without '-'
      // console.warn(Number(split_phone),'00000000');
      let flag = true;
      let flag_phone = false;
      for (let index = key + 1; index < array.length; index++) {
        console.log(array[index]);
        if (
          data.Practice_Email == array[index].Practice_Email &&
          data.Phone == array[index].Phone
        ) {
          flag = false;
        }
      }
      if (
        regex.test(data.Practice_Email) == true &&
        Number(split_phone) != NaN &&
        flag == false
      ) {
        flag = false;
      } else {
        if (regex.test(data.Practice_Email) == false && flag == false) {
          flag = true;
        } else {
          if (regex.test(data.Practice_Email) == false) {
            flag = true;
          } else {
            flag = null;
          }
        }
      }
      console.log(flag, "flag");
      if (flag == false) {
        data.error_type = "Duplicate Data";
        this.duplicate_data_details.push(data);
        console.log(this.duplicate_data_details);
        array.splice(key, 1);
        key = key - 1;
      }
      if (flag) {
        data.error_type = "Wrong Email";
        this.wrong_data.push(data);
        console.log(this.wrong_data);
        array.splice(key, 1);
        key = key - 1;
      }
      if (Number(split_phone)) {
        flag_phone = false;
        // console.warn(Number(split_phone),'00000000');
      } else {
        flag_phone = true;
        // console.warn(Number(split_phone),'00000000');
      }
      if (flag_phone) {
        let len = 0;
        if (this.wrong_data.length > 0) {
          len = this.wrong_data.length - 1;
        }
        if (flag) {
          if (
            typeof this.wrong_data[len].error_type != "undefined" &&
            this.wrong_data[len].error_type != null &&
            this.wrong_data[len].error_type != ""
          ) {
            this.wrong_data[len].error_type =
              this.wrong_data[len].error_type + " and Wrong Phone Number";
          }
        } else {
          data.error_type = "Wrong Phone Number";
          this.wrong_data.push(data);
          console.log(this.wrong_data);
          array.splice(key, 1);
          key = key - 1;
        }
      }
    }
    return array;
  }
  preview() { }
  public isproductloaded: boolean = true;
  ngOnInit() {
    if(this.myinputMsg == 'manuallyEditFromFolderView'){
      console.log("manuallyEditFromFolderView flag ts logic ++")
      this.apiservice
      .customRequest({'user_id':JSON.parse(this.cookies.get("userid"))},'getaccesableproductstech', environment['api_url_py'])
          .subscribe((response: any) => {
            if (response.data.length > 0){
              this.productdorselection = response.data;
            }else{
              this.productdorselection = [];
            }
            
          })
  
    }
    this.subscription = this.nevaccessservice.getData().subscribe(value => {
      if (value.data.length > 0){
        console.log('Product list for seletion from observable ==>', value);
        this.productdorselection = value.data;
      }else{
        this.productdorselection = [];
      }
    });
    console.log(this.myinputMsg, "*******");
    console.log(this.myRoute, "routeeeeeeeeeeeeeeeeeeeeeeeee");

    if (this.userservice.type == "admin") {
      this.formdata.cancelroute = "admin/lead/list";
      this.formdata.redirectpath = "admin/lead/list";
    }
    // if (this.userservice.type == "technological-consultant") {
    //   setTimeout(() => {
    //     this.apiservice
    //       .getDataWithoutToken(
    //         {
    //           token: this.cookies.get("jwtToken"),
    //           secret: this.cookies.get("secret"),
    //           id: JSON.parse(this.cookies.get("userid")),
    //         },
    //         "api1/selectproduct"
    //       )
    //       .subscribe((response: any) => {
    //         console.log(response.result);
    //         if (response.result.length > 0) {
    //           for (const key in response.result) {
    //             for (const i in this.productVal) {
    //               if (
    //                 response.result[key].product_id == this.productVal[i].val
    //               ) {
    //                 // this.productVal[i].val=response.result[key].product_id;
    //                 this.productdataVal.push({
    //                   val: response.result[key].product_id,
    //                   name: this.productVal[i].name,
    //                 });
    //                 this.isproductloaded = false;
    //                 console.log(this.productdataVal);
    //               }
    //             }
    //           }
    //         }
    //       });
    //   }, 1000);
    // }

    this.formdata1 = {
      successmessage: "Added Successfully !!",
      redirectpath: this.userservice.type + "/" + "lead/list",
      submitactive: true, // optional, default true
      submittext: "Submit",
      resettext: "Reset",
      canceltext: "Cancel",
      apiUrl: this.apiservice.api_url,
      // endpoint: "api3/usersignup", //
      jwttoken: this.apiservice.jwttoken,
      cancelroute: this.userservice.type + "/" + "lead/list",
      fields: [
        {
          label: "Batch Name",
          name: "batchname",
          value: "",
          type: "text",
          validations: [
            { rule: "required", message: "Category Name is required" },
          ],
        },
        {
          label: "Products",
          name: "products",
          hint: "",
          type: "select",
          value: "",
          multiple: true,
          val: this.productdataVal,
          validations: [{ rule: "required", message: "Select Product" }],
        },
        {
          label: "Upload CSV file: ",
          name: "screenshots",
          type: "file",
          multiple: true,
          prefix: "image-" + Date.now(),
          path: "betoparedes/bulk-upload/",
          baseurl: "betoparedes-dev-screenshots",
          bucket: "awsbackend-dev-patient-files-test",
          apiurl:
            "https://tge24bc2ne.execute-api.us-east-1.amazonaws.com/dev/requestUploadURL",
          apideleteurl:
            "https://tge24bc2ne.execute-api.us-east-1.amazonaws.com/dev/deletefilefromBucket",
        },
      ],
    };
    setTimeout(() => {
      if (this.userservice.type == "technological-consultant") {
        console.log("hiiiiiiiiiiiiiiiiiiiiiiiiiiiiii", this.productdataVal);

        this.formdata1.fields[1].val = this.productdataVal;
      }
    }, 500);

    // console.log(this.productsList)
  }
  ngAfterViewInit() {
    if(typeof(this.flag) != undefined && this.flag == true){
      console.log("ngAfterViewInit reached ++")

      let singletabshow = document.querySelector<HTMLElement>('.mat-tab-labels');
      singletabshow.style.display = 'none';

    }
  }
  // public isOpen = false;
  // public isLoading = false;
  // public savedValue: number | null = null;
  // selected = new FormControl(null, [Validators.required]);
  // public options:any = [];
  // openChanged(isOpen: boolean) {
  //   this.isOpen = !isOpen;
  //   this.isLoading = !isOpen;
  //   if (isOpen) {
  //     // save the current value because it is not guarenteed it will stil be an option after we get the new list of options
  //     this.savedValue = this.selected.value;
  //     this.options = [];
  //     this.selected.reset();

  //     // call the service to get the new list of options
  //     const simulatedLoadingTime = 3000; // milliseconds
  //     const simulatedFailureRate = 0.1; // between 0 and 1
  //     this.options = this.productdataVal;
  //     console.log(this.options,"oooooooooooooooooooooooo");
  //     console.log(this.productdataVal,"oooooooooooooooooooooooo");

  //   }
  // }
  listenFormFieldChanges(val) {
    // console.log(this.activatedRoute.snapshot.queryParams, 'this.activatedRoute.snapshot.queryParams');
    console.log(val);
    if (val.field == "fromsubmit") {
      let data_leads = {
        data: val.fromval,
        secret: this.userservice.secret,
        token: this.userservice.jwttoken,
      };
      this.apiservice
        .customRequest(data_leads, "api3/usersinguptwo", environment["api_url"])
        .subscribe((response: any) => {
          console.log(response, "response lead");
          // if (response.status = 'success') {
 
          // }
          if (
            this.myinputMsg == "manuallyadd" &&
            response.status == "success"
          ) {
            console.log("madhumita", response.result._id);
            this.router.navigateByUrl(
              "calendar-management/manage-appointment/warranty/book-appointment/" +
              response.result._id
            );
          } else {
            this.router.navigateByUrl(
              this.userservice.type + "/" + "lead/list"
            );
          }
        });

      this.formdata.successmessage = val.fromval.message;
    }

    if (this.myinputMsg == "manuallyadd" || this.myinputMsg == "manuallyEdit") {
      if (val.field != null && val.field === "formcancel") {
        this.myOutput.emit(val);
      }
      if (val.field != null && val.field === "fromsubmit") {
        this.myOutput.emit(val);
      }
    }
  }
  listenFormFieldChange(val) {
    console.log(val, "lkj,ivgfcytgv kli");
    if (val.field == "fromsubmit" && val.fromval.screenshots[0].baseurl != "") {
      this.loaderService.isLoading.next(true);
      this.fromvalue = val.fromval;
      this.baseurl = val.fromval.screenshots[0].baseurl;
      this.bucket = val.fromval.screenshots[0].bucket;
      this.fileservername = val.fromval.screenshots[0].fileservername;
      this.name = val.fromval.screenshots[0].name;
      this.path = val.fromval.screenshots[0].path;
      let data: any = {
        screenshots: [
          {
            baseurl: this.baseurl,
            bucket: this.bucket,
            fileservername: this.fileservername,
            name: this.name,
            path: this.path,
          },
        ],

        secret: this.userservice.secret,
        token: this.userservice.jwttoken,
      };
      this.apiservice
        .customRequest(data, "api/csvuploadforlead", environment["api_url"])
        .subscribe((response: any) => {
          console.log(response, "df");
          this.nextflagtrue = true;
          this.csvleaddata = response.results;
          this.filename = response.filename;
          for (const key in this.csvleaddata) {
            if (this.csvleaddata[key] == "Account_Name") {
              this.Account_Name = this.csvleaddata[key];
            }
            if (this.csvleaddata[key] == "Phone") {
              this.Phone = this.csvleaddata[key];
            }
            if (this.csvleaddata[key] == "Fax") {
              this.Fax = this.csvleaddata[key];
            }
            if (this.csvleaddata[key] == "EIN_Number") {
              this.EIN_Number = this.csvleaddata[key];
            }
            if (this.csvleaddata[key] == "Practice_NPI_Number") {
              this.Practice_NPI_Number = this.csvleaddata[key];
            }
            if (this.csvleaddata[key] == "Practice_EMR") {
              this.Practice_EMR = this.csvleaddata[key];
            }
            if (this.csvleaddata[key] == "Practice_Email") {
              this.Practice_Email = this.csvleaddata[key];
            }
            if (this.csvleaddata[key] == "Website") {
              this.webdata = this.csvleaddata[key];
            }
            if (this.csvleaddata[key] == "Practice_Notes") {
              this.Practice_Notes = this.csvleaddata[key];
            }
            if (this.csvleaddata[key] == "Billing_Location_Name") {
              this.Location_Name = this.csvleaddata[key];
            }
            if (this.csvleaddata[key] == "Billing_Street") {
              this.Billing_Street = this.csvleaddata[key];
            }
            if (this.csvleaddata[key] == "Billing_State") {
              this.Billing_State = this.csvleaddata[key];
            }
            if (this.csvleaddata[key] == "Billing_City") {
              this.Billing_City = this.csvleaddata[key];
            }
            if (this.csvleaddata[key] == "Billing_Zip_Code") {
              this.Billing_Zip_Code = this.csvleaddata[key];
            }
            if (this.csvleaddata[key] == "Billing_Country") {
              this.Billing_Country = this.csvleaddata[key];
            }
            if (this.csvleaddata[key] == "Shipping_Location_Name") {
              this.Shipping_Location_Name = this.csvleaddata[key];
            }
            if (this.csvleaddata[key] == "Shipping_Street") {
              this.Shipping_Street = this.csvleaddata[key];
            }
            if (this.csvleaddata[key] == "Shipping_State") {
              this.Shipping_State = this.csvleaddata[key];
            }
            if (this.csvleaddata[key] == "Shipping_City") {
              this.Shipping_City = this.csvleaddata[key];
            }
            if (this.csvleaddata[key] == "Shipping_Zip_Code") {
              this.Shipping_Zip_Code = this.csvleaddata[key];
            }
            if (this.csvleaddata[key] == "Shipping_Country") {
              this.Shipping_Country = this.csvleaddata[key];
            }
            if (this.csvleaddata[key] == "status") {
              this.Status = this.csvleaddata[key];
            }
          }
          this.loaderService.isLoading.next(false);
          window.scrollTo({
            top: 0,
            left: 0,
            behavior: "smooth",
          });
        });
    }
  }
  product(val) { }

  downloadsamplefile() {
    window.open(
      "https://awsbackend-dev-patient-files-test.s3.amazonaws.com/betoparedes/bulk-upload/image-1631793660058SampleLeads.csv",
      "_blank"
    );
  }

  editlead() {
    this.getdata();
    this.getproductlist1();
    if (this.activatedRoute.snapshot.params._id != null) {
      this.leadeditdata = {};
      this.activatedRoute.data.subscribe((res: any) => {
        if (res.resolvedata.result == undefined) {
          let requestcondition = {
            secret: this.userservice.secret,
            token: this.userservice.jwttoken,
            id: this.activatedRoute.snapshot.params._id,
            condition: { _id: this.activatedRoute.snapshot.params._id },
          };
          this.apiservice
            .getDataWithoutToken(requestcondition, "api/getonelead")
            .subscribe((data: any) => {
              console.log(data, "datatatatatat");

              this.leadeditdata = data.result[0];
              console.log(this.leadeditdata, " this.leadeditdata");
              console.log(res, "++++++++++++++++++++++++++++++++");
              console.log(this.leadeditdata, " this.leadeditdata");
              // let val={value:'hhh'}
              // this.onChangeTimezone(this.productdata)
              // if (this.activatedRoute.snapshot.params._id != null) {
              console.log(this.productdata, "this.leadeditdata.products");
              this.productdata = this.leadeditdata.products;
              this.selectflag = true;

              console.log(this.productdata, "this.productdataVal");
              // console.log(this.productdata,'productdata');
              // }
              this.edit_form();
            });
        } else {
          this.leadeditdata = res.resolvedata.result[0];
          console.log(res, "++++++++++++++++++++++++++++++++");
          console.log(this.leadeditdata, " this.leadeditdata");
          // let val={value:'hhh'}
          // this.onChangeTimezone(this.productdata)
          // if (this.activatedRoute.snapshot.params._id != null) {
          console.log(this.productdata, "this.leadeditdata.products");
          this.productdata = this.leadeditdata.products;
          this.selectflag = true;

          console.log(this.productdata, "this.productdataVal");
          // console.log(this.productdata,'productdata');
          // }
          this.edit_form();
        }
      });
    }
  }
  edit_form() {
    this.formdata3 = {
      successmessage: "",
      redirectpath: "",
      submitactive: true, // optional, default true
      submittext: "Submit",
      resettext: "Reset",
      canceltext: "Cancel",
      apiUrl: this.apiservice.api_url,
      // endpoint: "practice",
      jwttoken: this.cookies.get("jwtToken"),
      // cancelroute:"",
      // cancelroute: this.userservice.type + "/" + "lead/list",
      fields: [
        // {
        //   label: "Product",
        //   name: "productdata",
        //   value: this.leadeditdata.products,
        //   type: "text",
        //   validations: [
        //     { rule: "required", message: "products is Required" },
        //   ],
        // },
        {
          label: "HPS Representative",
          name: "HPS_Representative",
          value:
            this.leadeditdata.HPS_Representative != null
              ? this.leadeditdata.HPS_Representative.id
              : this.leadeditdata.HPS_Representative,
          // multiple:true,
          val: this.hpsrepval,
          type: "hidden",
          // validations: [
          //   { rule: "required", message: "HPS Representative is Required" },
          // ],
        },
        {
          label: "Distributor",
          name: "Distributor",
          value:
            this.leadeditdata.Distributor != null
              ? this.leadeditdata.Distributor.id
              : this.leadeditdata.Distributors,
          val: this.distributorval,
          type: "hidden",
          // validations: [
          //   { rule: "required", message: "Distributor is Required" },
          // ],
        },
        // {
        //   label: "Sales Person",
        //   name: "Sold_by",
        //   value: this.soldbyval[0].val,
        //   type: "hidden",
        //   // validations: [
        //   //   { rule: "required", message: "Sales Person is Required" },
        //   // ],
        // },
        {
          label: "Practice Name / Company",
          name: "Account_Name",
          value: this.leadeditdata.Account_Name,
          type: "text",
          validations: [
            {
              rule: "required",
              message: "Practice Name / Company is Required",
            },
          ],
          prefix: "*",
        },
        {
          label: "Main Contact Name",
          name: "main_contact_name",
          // hint:"Main Contact Name is Required",
          value: this.leadeditdata.main_contact_name,
          type: "text",
          validations: [
            { rule: "required", message: "Main Contact Name is Required" },
          ],
          prefix: "*",
        },
        {
          label: "Main Contact Phone",
          name: "Phone",
          value: this.leadeditdata.Phone,
          type: "number",
          validations: [
            { rule: "required", message: "Main Contact Phone is Required" },
          ],
          prefix: "*",
        },
        {
          label: "Main Contact Email",
          name: "Practice_Email",
          value: this.leadeditdata.Practice_Email,
          type: "text",
          validations: [
            { rule: "required", message: "Main Contact Email is Required" },
            {
              rule: "pattern",
              value: this.emailregex,
              message: "Must be a valid Email",
            },
          ],
          prefix: "*",
        },
        {
          label: "Currency",
          name: "Currency",
          value: this.leadeditdata.Currency,
          val: this.currency,
          type: "hidden",
          // validations: [
          //   { rule: "required", message: "Currency is Required" },
          // ],
        },
        {
          label: "Fax",
          name: "Fax",
          value: this.leadeditdata.Fax,
          type: "text",
          // validations: [
          //   { rule: "required", message: "Fax is Required" },
          // ],
        },
        {
          label: " EIN Number",
          name: "EIN_Number",
          value: this.leadeditdata.EIN_Number,
          type: "number",
          validations: [
            {
              rule: "maxLength",
              value: 9,
              message: "Please enter the valid 9 digit EIN Number of ",
            },
          ],
        },
        {
          label: "Practice NPI Number",
          name: "Practice_NPI_Number",
          value: this.leadeditdata.Practice_NPI_Number,
          type: "text",
          // validations: [
          //   { rule: "required", message: "Practice NPI Number is Required" },
          // ],
        },
        // {
        //   label: "Practice Type",
        //   name: "Account_Type",
        //   value: this.leadeditdata.Account_Type,
        //   val: this.practicetype,
        //   type: "select",
        //   // validations: [
        //   //   { rule: "required", message: "Practice Type is Required" },
        //   // ],
        // },
        {
          label: "Practice PECE Status",
          name: "Practice_Status",
          value: "Pending",
          // hint:'None',
          // val: this.pecepracticestatus,
          type: "hidden",
        },
        {
          label: "Exchange Rate",
          name: "Exchange_Rate",
          value: 1,
          type: "hidden",
          // validations: [
          //   { rule: "required", message: "Exchange Rate is Required" },
          // ],
        },
        {
          label: "Practice EMR",
          name: "Practice_EMR",
          value: this.leadeditdata.Practice_EMR,
          type: "text",
          // validations: [
          //   { rule: "required", message: "Practice EMR is Required" },
          // ],
        },
        {
          label: "Website",
          name: "Website",
          parttern:
            /^((ftp|http|https):\/\/)?(www.)?(?!.*(ftp|http|https|www.))[a-zA-Z0-9_-]+(\.[a-zA-Z]+)+((\/)[\w#]+)*(\/\w+\?[a-zA-Z0-9_]+=\w+(&[a-zA-Z0-9_]+=\w+)*)?$/gm,
          value: this.leadeditdata.Website,
          type: "text",
          validations: [
            // { rule: "required", message: "Website is Required" },
            {
              rule: "pattern",
              value: this.webregex,
              message: " Use Proper Web Site Format ",
            },
          ],
        },
        {
          label: "Practice Notes",
          name: "Practice_Notes",
          value: this.leadeditdata.Practice_Notes,
          type: "textarea",
          labelPosition: "after",
          // validations: [
          //   { rule: "required", message: "Practice Notes is Required" },
          // ],
        },

        {
          heading: "Address Information",
          name: "statusgroup",
          // value:this.leadeditdata.,
          hint: ",0000",
          type: "group",
          fields: [
            // {
            //   label: "Age1",
            //   name: "age1",
            //   type: 'number',
            //   hint: 'Age ??',
            //   validations: [
            //       // { rule: 'required' },
            //       // { rule: 'min', value: 5 },
            //       // { rule: 'max', value: 50, message: "Age can't be more than 50 " }
            //   ]
            // },
          ],
        },
        {
          label: "Billing Location Name",
          name: "Location_Name",
          value: this.leadeditdata.Location_Name,
          type: "text",

          // validations: [
          //   { rule: "required", message: "Billing Street is Required" },
          // ],
        },
        {
          label: "Billing Street",
          name: "Billing_Street",
          value: this.leadeditdata.Billing_Street,
          type: "text",

          // validations: [
          //   { rule: "required", message: "Billing Street is Required" },
          // ],
        },
        {
          label: "Billing State",
          name: "Billing_State",
          val: this.stateVal,
          value: this.leadeditdata.Billing_State,
          type: "select",

          // validations: [
          //   { rule: "required", message: "Billing Street is Required" },
          // ],
        },
        {
          label: " Billing City",
          name: "Billing_City",
          value: this.leadeditdata.Billing_City,
          type: "text",

          // validations: [
          //   { rule: "required", message: "Billing Street is Required" },
          // ],
        },

        {
          label: " Billing Zip Code",
          name: "Billing_Zip_Code",
          value: this.leadeditdata.Billing_Zip_Code,
          type: "number",

          // validations: [
          //   { rule: "required", message: "Billing Street is Required" },
          // ],
        },
        {
          label: "Billing Country",
          name: "Billing_Country",
          value: this.leadeditdata.Billing_Country,
          type: "text",

          // validations: [
          //   { rule: "required", message: "Billing Street is Required" },
          // ],
        },
        {
          label: "Set billing address as shipping address",
          name: "set_billing_address_as_shipping_address",
          type: "hidden",
          value: "",
        },
        // {
        //   label: "New External Button",
        //   name: "externalmodaldataimg",
        //   type: 'externaldata',
        //   value: ''
        // },
        {
          label: "Shipping Location Name",
          name: "Shipping_Location_Name",
          value: this.leadeditdata.Shipping_Location_Name,
          type: "text",

          // validations: [
          //   { rule: "required", message: "Billing Street is Required" },
          // ],
        },
        {
          label: "Shipping Street",
          name: "Shipping_Street",
          value: this.leadeditdata.Shipping_Street,
          type: "text",

          // validations: [
          //   { rule: "required", message: "Billing Street is Required" },
          // ],
        },
        {
          label: "Shipping State",
          name: "Shipping_State",
          val: this.stateVal,
          value: this.leadeditdata.Shipping_State,
          type: "select",

          // validations: [
          //   { rule: "required", message: "Billing Street is Required" },
          // ],
        },
        {
          label: "Shipping City",
          name: "Shipping_City",
          value: this.leadeditdata.Shipping_City,
          type: "text",

          // validations: [
          //   { rule: "required", message: "Billing Street is Required" },
          // ],
        },

        {
          label: "Shipping Zip Code",
          name: "Shipping_Zip_Code",
          value: this.leadeditdata.Shipping_Zip_Code,
          type: "number",

          // validations: [
          //   { rule: "required", message: "Billing Street is Required" },
          // ],
        },
        {
          label: "Shipping Country",
          name: "Shipping_Country",
          value: this.leadeditdata.Shipping_Country,
          type: "text",

          // validations: [
          //   { rule: "required", message: "Billing Street is Required" },
          // ],
        },
        // {
        //   heading: "OnBoarding Checklist (DO NOT UPLOAD-Use ZOHO SIGN)",
        //   name: "statusgroup",
        //   hint: ',0000',
        //   type: 'group',
        //   fields: [
        //     // {
        //     //   label: "Age1",
        //     //   name: "age1",
        //     //   type: 'number',
        //     //   hint: 'Age ??',
        //     //   validations: [
        //     //       // { rule: 'required' },
        //     //       // { rule: 'min', value: 5 },
        //     //       // { rule: 'max', value: 50, message: "Age can't be more than 50 " }
        //     //   ]
        //     // },

        //   ]
        // },

        // {
        //   label: "Demo Completed",
        //   name: "Demo_Completed",
        //   value: "",
        //   type: "text",

        //   // validations: [
        //   //   { rule: "required", message: "Billing Street is Required" },
        //   // ],
        // },
        // {
        //   label: "CMAT Contract",
        //   name: "cmat_Country",
        //   value: "",
        //   val: this.cmatcontract,
        //   type: "select",

        //   // validations: [
        //   //   { rule: "required", message: "Billing Street is Required" },
        //   // ],
        // },
        // {
        //   label: "Amount Paid for Equipment Warranty",
        //   name: "Amount_Paid_for_Equipment_Warranty",
        //   value: "",
        //   type: "text",

        //   // validations: [
        //   //   { rule: "required", message: "Billing Street is Required" },
        //   // ],
        // },
        // {
        //   label: "Warranty Contract Paid",
        //   name: "Warranty_Contract_Paid",
        //   value: "",
        //   type: "text",

        //   // validations: [
        //   //   { rule: "required", message: "Billing Street is Required" },
        //   // ],
        // },
        // {
        //   label: "All Contracts Signed",
        //   name: "Contract_Signed",
        //   value: "",
        //   type: "text",

        //   // validations: [
        //   //   { rule: "required", message: "Billing Street is Required" },
        //   // ],
        // },
        // {
        //   label: "Payment Authorization Form",
        //   name: "Payment_Method_Document",
        //   value: "",
        //   type: "text",

        //   // validations: [
        //   //   { rule: "required", message: "Billing Street is Required" },
        //   // ],
        // },
        // {
        //   label: "Practice Information Worksheet",
        //   name: "Practice_Questionnaire_Completed",
        //   value: "",
        //   type: "text",

        //   // validations: [
        //   //   { rule: "required", message: "Billing Street is Required" },
        //   // ],
        // }, {
        //   label: "PECE Software Agreement",
        //   name: "PECE_Software_Agreement",
        //   value: "",
        //   type: "text",

        //   // validations: [
        //   //   { rule: "required", message: "Billing Street is Required" },
        //   // ],
        // }, {
        //   label: "Number of Cuffs",
        //   name: "Number_of_Cuffs",
        //   value: "",
        //   type: "text",

        //   // validations: [
        //   //   { rule: "required", message: "Billing Street is Required" },
        //   // ],
        // }, {
        //   label: "OnBoarding Notes",
        //   name: "OnBoarding_Notes",
        //   value: "",
        //   type: "textarea",

        //   // validations: [
        //   //   { rule: "required", message: "Billing Street is Required" },
        //   // ],
        // },
        // {
        //   label: "Everyone Trained",
        //   name: "Everyone_Trained",
        //   value: "",
        //   type: "text",

        //   // validations: [
        //   //   { rule: "required", message: "Billing Street is Required" },
        //   // ],
        // },

        // {
        //   heading: "Equipment",
        //   name: "statusgroup",
        //   value: this.leadeditdata.statusgroup,
        //   hint: ",0000",
        //   type: "group",
        //   fields: [
        //     // {
        //     //   label: "Age1",
        //     //   name: "age1",
        //     //   type: 'number',
        //     //   hint: 'Age ??',
        //     //   validations: [
        //     //       // { rule: 'required' },
        //     //       // { rule: 'min', value: 5 },
        //     //       // { rule: 'max', value: 50, message: "Age can't be more than 50 " }
        //     //   ]
        //     // },
        //   ],
        // },
        // {
        //   label: "Equipment Status",
        //   name: "Status",
        //   value: this.leadeditdata.Status,
        //   val: this.equpmenntstatusval,
        //   type: "select",

        //   // validations: [
        //   //   { rule: "required", message: "Billing Street is Required" },
        //   // ],
        // },
        // {
        //   label: "Equipment Version",
        //   name: "Equipment_Version",
        //   value: this.leadeditdata.Equipment_Version,
        //   val: this.equpmenntversionval,
        //   type: "select",

        //   // validations: [
        //   //   { rule: "required", message: "Billing Street is Required" },
        //   // ],
        // },
        // {
        //   label: "Equipment Shipped",
        //   name: "Shipped",
        //   value: this.leadeditdata.Shipped,
        //   type: "date",

        //   // validations: [
        //   //   { rule: "required", message: "Billing Street is Required" },
        //   // ],
        // },
        // {
        //   label: "Equipment Payment Method",
        //   name: "Equipment_Serial_Number",
        //   value: this.leadeditdata.Equipment_Serial_Number,
        //   type: "text",

        //   // validations: [
        //   //   { rule: "required", message: "Billing Street is Required" },
        //   // ],
        // },
        // {
        //   label: "Equipment Status Date",
        //   name: "Status_Date",
        //   type: "date",
        //   value: this.leadeditdata.Status_Date,
        //   // hint: "05/05/2020",

        //   // validations: [
        //   //   { rule: "required", message: "Billing Street is Required" },
        //   // ],
        // },
        // {
        //   label: "Equipment Serial Number",
        //   name: "Serial_Number",
        //   value: this.leadeditdata.Serial_Number,
        //   type: "text",

        //   // validations: [
        //   //   { rule: "required", message: "Billing Street is Required" },
        //   // ],
        // },

        // {
        //   label: "Equipment Notes",
        //   name: "Equipment_Notes",
        //   value: this.leadeditdata.Equipment_Notes,
        //   type: "textarea",

        //   // validations: [
        //   //   { rule: "required", message: "Billing Street is Required" },
        //   // ],
        // },
        {
          label: "tags",
          name: "tags",
          type: "hidden",
          value: [
            {
              satatus: "Manual",
              updated_datetime: Math.round(new Date().getTime()),
            },
            {
              satatus: "New Lead",
              updated_datetime: Math.round(new Date().getTime()),
            },
          ],
        },
        {
          label: "Active",
          name: "status",
          hint: "",
          type: "checkbox",
          // val: this.status,
          value: this.leadeditdata.status,
        },
        {
          label: "id",
          name: "id",
          value: this.activatedRoute.snapshot.params._id,
          type: "hidden",
        },
      ],
    };
    // if(this.myRoute != undefined){
    //   this.formdata3.cancelroute = this.myRoute;
    // }
  }
  getproductlist() {
    this.apiservice
      .getDataWithoutToken(
        {
          token: this.userservice.jwttoken,
          secret: this.userservice.secret,
        },
        "api/productsearch"
      )
      .subscribe((response: any) => {
        console.log("++++")
        // this.productVal=response.res
        // // console.log(this.productVal,'madhumitaaaaa')
        for (const i in response.res) {
          if (Object.prototype.hasOwnProperty.call(response.res, i)) {
            this.productVal.push({
              val: response.res[i].val,
              name: response.res[i].name,
            });
          }
        }
        this.getproductselectlist();
      });
  }

  getparentlist() {
    if (this.userservice.type != "admin") {
      this.parentVal.push({
        val: this.userservice.userid,
        name: this.userservice.fullname,
      });
    } else {
      this.apiservice
        .getDataWithoutToken(
          {
            token: this.userservice.jwttoken,
            secret: this.userservice.secret,
            type: "technological-consultant",
          },
          "api/exitsinglistname"
        )
        .subscribe((response: any) => {
          for (const i in response.res) {
            if (Object.prototype.hasOwnProperty.call(response.res, i)) {
              this.parentVal.push({
                val: response.res[i]._id,
                name: response.res[i].name,
              });
            }
          }
        });
    }
  }

  getproductselectlist() {
    this.isproductloaded = true;
    this.apiservice
      .getDataWithoutToken(
        {
          token: this.cookies.get("jwtToken"),
          secret: this.cookies.get("secret"),
          id: JSON.parse(this.cookies.get("userid")),
        },
        "api1/selectproduct"
      )
      .subscribe((response: any) => {
        console.log(response.result);
        var unique;
        if (response.result.length > 0) {
          for (const key in response.result) {
            for (const i in this.productVal) {
              if (response.result[key].product_id == this.productVal[i].val) {
                // this.productVal[i].val=response.result[key].product_id;
                let pval: any = '';
                // if (response.result[key].product_id[0] instanceof Array) {
                if (Array.isArray(response.result[key].product_id)) {
                  pval = response.result[key].product_id[0];
                }
                else pval = response.result[key].product_id;
                console.log(pval, 'pval');
                this.productdataVal.push({
                  val: pval,
                  name: this.productVal[i].name,
                });
                // unique = [...new Set(this.productdataVal)];
                unique = [...new Map(this.productdataVal.map(item =>
                  [item['val'], item])).values()];
                console.log(unique, 'unique');
                console.log(this.productdataVal);
              }
            }
          }
          this.productdataVal = unique;
          this.isproductloaded = false;
        }
      });
  }
  duplicate_data_view() {
    //this funtion is use for open the duplicate lead list in modal
    const dialogRef = this.dialog.open(DuplicateWrongDataViewDialog, {
      // width: '250px',
      data: {
        data: this.duplicate_data_details,
        flag: "Duplicate Lead Data List",
      },

      panelClass: "DuplicateLeadBoxComponent",
    });

    dialogRef.afterClosed().subscribe((result) => {
      // console.log('The dialog was closed');
    });
  }
  wrong_data_view() {
    //this funtion is use for open the wrong lead list in modal
    const dialogRef = this.dialog.open(DuplicateWrongDataViewDialog, {
      // width: '250px',
      data: { data: this.wrong_data, flag: "Inaccurate Lead Data List" },
      panelClass: "DuplicateLeadBoxComponent",
    });

    dialogRef.afterClosed().subscribe((result) => {
      // console.log('The dialog was closed');
    });
  }
}

@Component({
  selector: "bottom-sheet-overview-example-sheet",
  templateUrl: "bottomforaddleadsheet.html",
})
export class BottomSheetforaddlead {
  public msg: any;
  constructor(
    public coock: CookieService,
    private _bottomSheetRef: MatBottomSheetRef<BottomSheetforaddlead>,
    public router: Router,
    @Inject(MAT_BOTTOM_SHEET_DATA) public data: any
  ) {
    console.log(data);
    if (data.status == "success") {
      if (
        typeof data.update_message != "undefined" &&
        data.update_message != null
      ) {
        this.msg = data.update_message;
        if (data.flag != undefined && data.flag != null && data.flag != "") {
        } else {
          this.router.navigateByUrl(
            JSON.parse(this.coock.get("type")) + "/" + "lead/list"
          );
        }
        setTimeout(() => {
          this._bottomSheetRef.dismiss();
        }, 10000);
      } else {
        this.msg = data.message;
      }
    } else {
      this.msg = data.errormessage;
      setTimeout(() => {
        this._bottomSheetRef.dismiss();
      }, 10000);
    }
  }

  openLink(event: MouseEvent, flag): void {
    if (flag == "contract") {
      this.router.navigateByUrl(
        "user-details/lead/" + this.data.result._id + "/contactflag"
      );
    }

    if (flag == "questionnaire") {
      this.router.navigateByUrl(
        "user-details/lead/" + this.data.result._id + "/questionnaireflag"
      );
    }
    if (flag == "cancel") {
      this.router.navigateByUrl("user-details/lead/" + this.data.result._id);
    }

    this._bottomSheetRef.dismiss();
    event.preventDefault();
  }
}

@Component({
  selector: "duplicate-wrong-data-view-dialog",
  templateUrl: "duplicate-wrong-data-view-dialog.html",
})
export class DuplicateWrongDataViewDialog {
  public header: any = [];
  public value: any = [];
  public flag: any = "";
  constructor(
    public dialogRef: MatDialogRef<DuplicateWrongDataViewDialog>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData
  ) {
    console.log(data.data);
    if (data.data.length > 0) {
      this.header = Object.keys(data.data[0]); // this is for store table header in header variable from 0 index of duplicate data array
    }
    this.value = data.data;
    this.flag = data.flag;
  }

  onNoClick(): void {
    this.dialogRef.close();
  }
}
@Component({
  selector: "view-success-dialog-bulk-upload",
  templateUrl: "view-success-dialog-bulk-upload.html",
})
export class ViewSuccessDialogBulkUpload {
  // this modal is use to show the success message of bulk upload
  public newleadinserted: any = 0;
  constructor(
    public dialogRef: MatDialogRef<ViewSuccessDialogBulkUpload>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    public router: Router
  ) {
    dialogRef.disableClose = true;
    console.log(data.data);
    this.newleadinserted = data.data;
  }
  goto(val: any) {
    if (val == "new_lead") {
      this.dialogRef.close();
      this.router.navigateByUrl("/technological-consultant/lead/list?id=1");
    }
    if (val == "lead") {
      this.dialogRef.close();
      this.router.navigateByUrl("/technological-consultant/lead/list?id=0");
    }
  }
  onNoClick(): void {
    this.dialogRef.close();
  }
}
