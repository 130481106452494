import { HttpClient } from '@angular/common/http';
import { Component, Inject, OnInit } from '@angular/core';
import { MatBottomSheet, MatDialog, MatDialogRef, MatSnackBar, MAT_DIALOG_DATA } from '@angular/material';
import { ActivatedRoute, Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { ApiService } from 'src/app/services/api.service';
import { UserserviceService } from 'src/app/services/userservice.service';

export interface DialogData {
  data: any;
  val: any;
  flag: any
}
@Component({
  selector: 'app-managecommissionlist',
  templateUrl: './managecommissionlist.component.html',
  styleUrls: ['./managecommissionlist.component.css']
})


export class ManagecommissionlistComponent implements OnInit {
  public managecommisson: any = [];
  public product_array: any = [];
  public token: any = this.cookieservice.get('jwtToken');
  limitcond: any = {
    limit: 10,
    skip: 0,
  };
  editroute: any = 'managecommisson/salescommisson/edit/';
  // table header
  modifyheaderarray: any = {
    // userfullname: 'Tech Name',
    // // Addedbyfullname: "Addedby ",
    // productsname: "Products",
    // eventcommissiondata: "Event Commission ",
    // salescommissiondata: "Sales Commission",
    // productprice: "Productprice",
    // createdon_datetime: "Created On",
    // salescommissionprice: "Sales Commission Price",
    // eventcommissionrole: "Event Commission Price",
    // eventcount: "Event Count",
    // commissionpercount: "Commison Count",
  };
  deleteendpoint = 'api/deletecommissions';

  libdata: any = {
    // basecondition: { type:this.userservice.type, parentid: this.userservice.userid },
    // detailview_override: [
    //   { key: 'eventcommissiondata', val: 'Event Commission Data' },
    //   { key: 'productsname', val: 'Product' },
    //   { key: 'Addedbyfullname', val: 'Addedby Name' },
    //   { key: 'salesrep_email', val: 'Parent Email' },
    //   { key: 'salesrep_phone', val: 'Parent  Phone Number' },
    //   { key: 'salescommissiondata', val: 'Sales Commission Data' },
    //   { key: 'status', val: 'Status' },
    // ],
    // tableheaders: [
    //   'userfullname',
    //   // 'Addedbyfullname',
    //   'productsname',
    //   'eventcommissiondata',
    //   'salescommissiondata',
    //   'createdon_datetime'
    // ],
    // // searchBarFlagVal:true,
    // // tableheaders name [key]
    // // optional
    // hidedeletebutton: false,
    // // notes: {
    // //   label: 'Add Notes',
    // //   addendpoint: 'api/addnotedataforlead',
    // //   deleteendpoint: 'api/deletenotedataforlead',
    // //   listendpoint: 'api/listnotedataforlead',
    // //   user: this.userservice.userid,
    // //   currentuserfullname:
    // //     this.userservice.firstname +
    // //     ' ' +
    // //     this.userservice.lastname,
    // //   header: 'Add Notes',
    // // },
    // // updateendpointmany: 'api/leadupdate',
    // // updateendpoint: 'api/leadstatusupdate',
    // // deleteendpointmany: 'api/deletelead',
    // hidemultipleselectbutton: true,
    // hidecounter: null,
    // hideaction: null,
    // hideviewbutton: false, // (hide view button)
    // hidestatustogglebutton: true,
    // custombuttons: [
    //   {
    //     label: "Sales Commission",
    //     type: "listner",
    //     id: "i1",
    //     classname: "SalesCommission",
    //     tooltip: "Sales Commission",
    //     cond: "salescommissiondata",
    //     condval: 'Yes',
    //   },

    //   {
    //     label: "Event Commission",
    //     type: "listner",
    //     id: "i1",
    //     classname: "EventCommission",
    //     tooltip: "Event Commission",
    //     cond: "eventcommissiondata",
    //     condval: 'Yes',
    //   },

    // ],

  };
  datacollection: any = 'api/listmanagecommission';

  managecommissonskip: any = [
    '_id',
    'parentid',

  ];

  // use for Table Detail Field Skip
  managecommissondetailskip: any = [
    '_id', 'userdata', 'addedby', 'userfullname', 'addedfortype', 'addedbytype', 'createdon_datetime', 'userfullname_s', 'eventcommission', 'salescommission', 'product_s'
  ];
  count;
  // source count
  datesearchsourcecount: any = 0;
  searchsettings: any = {
    datesearch: [
      {
        startdatelabel: 'Joined On Start Date',
        enddatelabel: 'Joined On End Date',
        submit: 'Search',
        field: 'createdon_datetime',
      },
    ],
    selectsearch: [
      {
        label: "Search By Product",
        field: "product_s",
        values: this.product_array,

      },
    ],
    // textsearch: [{ label: "Search By Added For", field: "userfullname_s" }],
    search: [
      {
        label: 'Search By Tech Name', field: 'userfullname_s',
        values: '',
        serversearchdata: { endpoint: 'api/exitsinglistname' }
      },
    ],
  };
  sortdata: any = {
    // type: 'desc',
    // field: 'tech_fullname',
    // options: [
    //   'tech_fullname',
    //   'tech_email',
    //   'tech_commission',
    //   'tech_event_commission',
    // ],
  };
  public url_string: any = '';
  public type:any = '';
  constructor(public activatedRoute: ActivatedRoute,
    public userservice: UserserviceService,
    public router: Router,
    public apiservice: ApiService,
    public http: HttpClient,
    public cookieservice: CookieService,
    public bottomSheet: MatBottomSheet,
    private _snackBar: MatSnackBar,
    public dialog: MatDialog
  ) {
    this.type = JSON.parse(this.cookieservice.get('type'))
    this.url_string = this.router.url;
    if (this.url_string == '/managecommisson/tech/list') {
      this.datacollection = "api10/get-distributor-commission-management-lists";
      if(this.type == 'distributor'){
        this.searchsettings = {
          search: [
            {
              label: 'Search By Tech Name', field: 'tech_fullname_s',
              values: '',
              serversearchdata: { endpoint: 'api/exitsinglistname?idandtype=' + this.userservice.userid + ",technological-consultant" }
            },
            {
              label: 'Search By Tech Email', field: 'tech_email_s',
              values: '',
              serversearchdata: { endpoint: 'api/exitsinglistemail?idandtype=' + this.userservice.userid + ",technological-consultant" }
            },
          ],
        };
      }
      if(this.type == 'technological-consultant'){
        this.searchsettings = {
          search: [
            {
              label: 'Search By Distributor Name', field: 'distributor_fullname_s',
              values: '',
              serversearchdata: { endpoint: 'api10/get_autocomplete_commission_distributor_name?id=' + this.userservice.userid }
            },
            {
              label: 'Search By Distributor Email', field: 'dirtibutor_email_s',
              values: '',
              serversearchdata: { endpoint: 'api10/get_autocomplete_commission_distributor_email?id=' + this.userservice.userid }
            },
          ],
        };
      }
      if(this.type == 'distributor'){
        this.sortdata = {
          type: 'desc',
          field: 'tech_fullname',
          options: [
            'tech_fullname',
            'tech_email',
            'tech_commission',
            'tech_event_commission',
          ],
        }
      }
      if(this.type == 'technological-consultant'){
        this.sortdata = {
          type: 'desc',
          field: 'distributor_fullname',
          options: [
            'distributor_fullname',
            'dirtibutor_email',
            'tech_commission',
            'tech_event_commission',
          ],
        }
      }
      this.libdata = {
        basecondition: {},
        detailview_override: [
          { key: 'tech_fullname', val: 'Tech Name' },
          { key: 'distributor_fullname', val: 'Distributor Name' },
          { key: 'tech_email', val: 'Tech Email' },
          { key: 'dirtibutor_email', val: 'Distributor Email' },
          { key: 'tech_commission', val: 'Sales Commission' },
          { key: 'tech_event_commission', val: 'Event Commission' }
        ],
        tableheaders: [],
        hidedeletebutton: true,
        hidemultipleselectbutton: true,
        hidecounter: null,
        hideaction: true,
        hideviewbutton: true, // (hide view button)
        hidestatustogglebutton: true,
        custombuttons: [],

      };
      if(JSON.parse(this.cookieservice.get('type')) == 'technological-consultant'){
        this.libdata.basecondition['techid'] = JSON.parse(
          this.cookieservice.get("userid")
        );
        this.libdata.tableheaders = [
          'distributor_fullname',
          'dirtibutor_email',
          'tech_commission',
          'tech_event_commission',
        ];
      }
      if(JSON.parse(this.cookieservice.get('type')) == 'distributor'){
        this.libdata.basecondition['parentid'] = JSON.parse(
          this.cookieservice.get("userid")
        );
        this.libdata.tableheaders = [
          'tech_fullname',
          'tech_email',
          'tech_commission',
          'tech_event_commission',
        ];
      }
      this.modifyheaderarray = {
        tech_fullname: 'Tech Name',
        distributor_fullname: 'Distributor Name',
        tech_email: "Tech Email",
        dirtibutor_email: 'Distributor Email',
        tech_commission: "Sales Commission",
        tech_event_commission: "Sales Commission"
      };
    }
    // if (this.userservice.type == 'distributor') {
    //   this.libdata.hidedeletebutton = false;
    //   this.libdata.hideviewbutton = false;
    //   this.libdata.hideeditbutton= false;
    // }
  }

  ngOnInit() {

    this.activatedRoute.data.forEach((res: any) => {
      console.log(res);
      this.managecommisson = res.resolvedata.results.res;
    })
    if (this.url_string == '/managecommisson/tech/list') {
      const endpointc = 'api10/get-distributor-commission-management-lists-count';
      let data = {
        "searchcondition": {},
        "secret": this.userservice.secret,
        "token": this.userservice.jwttoken
      }
      if(JSON.parse(this.cookieservice.get('type')) == 'technological-consultant'){
        data.searchcondition['techid'] = JSON.parse(
          this.cookieservice.get("userid")
        );
      }
      if(JSON.parse(this.cookieservice.get('type')) == 'distributor'){
        
        data.searchcondition['parentid'] = JSON.parse(
          this.cookieservice.get("userid")
        );
      }
      this.apiservice
        .getDataWithoutToken(data, endpointc)
        .subscribe((res: any) => {
          this.datesearchsourcecount = res.count;
        });
    }
    // const endpointc = 'api/listmanagecommission-count';
    // const data: any = {

    //   "condition": {
    //     "limit": 10,
    //     "skip": 0
    //   },
    //   "sort": {
    //     "type": "desc",
    //     "field": "createdon_datetime"
    //   },
    //   "searchcondition": {
    //     "type": this.userservice.type,
    //     "parentid": this.userservice.userid
    //   },
    //   "secret": this.userservice.secret,
    //   "token": this.userservice.jwttoken

    // };
    // this.apiservice
    //   .getDataWithoutToken(data, endpointc)
    //   .subscribe((res: any) => {
    //     this.datesearchsourcecount = res.count; // sarch count  (How many data there)
    //   });
    //   let productendpointdata = {
    //     token:this.userservice.jwttoken,
    //     secret:this.userservice.secret
    //   }
    //   this.apiservice.getDataWithoutToken(productendpointdata,"api/productsearch").subscribe((data:any)=>{
    //     // console.log(data,"+++++++++++++++++");
    //     for(let i=0;i< data.res.length;i++){
    //       let array = {
    //         val:data.res[i].val,
    //         name:data.res[i].name
    //       }
    //       this.product_array.push(array)
    //     }
    //     // this.product_array = data.res;
    //     console.log(this.product_array,"+++++++++++++++++++++++++++");

    //   })
  }

  listenLiblistingChange(e: any) {
    console.log(e);
    if (e.custombuttonclick) {
      if (e.custombuttonclick.btninfo.label == 'Sales Commission') {
        const dialogRef = this.dialog.open(Viewcommission, {
          panelClass: "DialogBoxComponent",

          data: { val: e.custombuttonclick.data, flag: 'sales' },
        });
        dialogRef.afterClosed().subscribe((result: any) => {

        })
      }
      if (e.custombuttonclick.btninfo.label == 'Event Commission') {
        const dialogRef = this.dialog.open(Viewcommission, {
          panelClass: "DialogBoxComponent",

          data: { val: e.custombuttonclick.data, flag: 'event' },
        });
        dialogRef.afterClosed().subscribe((result: any) => {

        })
      }
    }
  }
}

@Component({
  selector: "viewcommission",
  templateUrl: "viewcommission.html",
})
export class Viewcommission {
  public salescommission: any;
  public eventcommission: any;
  constructor(
    public dialogRef: MatDialogRef<Viewcommission>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    public apiservice: ApiService,
    public activatedroute: ActivatedRoute,
    public userservice: UserserviceService
  ) {
    console.log(data, '+++++');
    this.salescommission = data.val.salescommission;
    this.eventcommission = data.val.eventcommission
  }

  onNoClick(val): void {
    if (val == 'No') {
      this.dialogRef.close();
    }
  }
}

