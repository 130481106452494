import { BrowserModule } from '@angular/platform-browser';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule, NO_ERRORS_SCHEMA } from '@angular/core';
import { ResolveService } from './services/resolve.service';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormControl, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { LoginModule } from 'login-lib-influxiq';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { ApiService } from './services/api.service';
import { CookieService } from 'ngx-cookie-service';
import { ShareModule } from './modules/share/share.module';
import { CommonModule } from '@angular/common';
import { ResetPasswordComponent } from './common/reset-password/reset-password.component';
import { ForgetPasswordComponent } from './common/forget-password/forget-password.component';
import { LoginComponent } from './common/login/login.component';
import { MyAccountComponent } from './common/my-account/my-account.component';
import { AppRoutingModule } from './app-routing.module';
import { LoaderComponent } from './loader/loader.component';
import { LoaderService } from './services/loader.service';
import { LoaderInterceptor } from './services/loader.interceptor';
import { ImageModalComponent, ResourceListComponent } from './components/resources/resource-list/resource-list.component';
import { AddUserComponent, BottonSheetAdmin, DialogBoxComponent, iscontractmanagerDialog, ViewDetailsAdmin } from './components/admin/admin-list/admin-list.component';

import { MatCarouselModule } from '@ngmodule/material-carousel';
// import { CKEditorModule } from 'ngx-ckeditor';

import { ChartsModule } from 'ng2-charts';
import { BottonSheetCloser, ViewDetailsCloser } from './components/closer/closer-list/closer-list.component';
import { BottonSheetContact, ViewDetailsManager } from './components/contact-manager/contract-anager-list/contract-anager-list.component';
import { BottonSheetDistributor, ViewDetailsDistributor } from './components/distributor/distributor-list/distributor-list.component';
import { bottomsheetfollowup, bottomsheetstatus, BottonSheetLead, Dispositionview, ViewDetailsLead } from './components/lead/lead-list/lead-list.component';
import { BottonSheetSales, ViewDetailsSales } from './components/sales-manager/sales-manager-list/sales-manager-list.component';
import { BottonSheetTech, ViewDetailsTech } from './components/technological-consultant/technological-consultant-list/technological-consultant-list.component';
import { ContractComponent, Signmodal } from './components/contract/contract.component';
// import { CalendarManagementModule } from 'calendar-management-lib-influxiq';

import { CalendarManagementModule } from 'calendar-management-lib-influxiq';
import { CalendarService } from './services/calendar.service';
import { FullDetailsComponent } from './components/full-details/full-details.component';
import { AuthGuard } from './services/auth.guard';
import { VideoManagementModule } from './components/video-management/video-management.module';
import { VideoGalleryComponent } from './components/video-gallery/video-gallery/video-gallery.component';
// import { ContractViewComponent } from './components/contract-view/contract-view.component';
// import { YoutubePlayerModule } from 'ngx-youtube-player';
// import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
// import { TraningAddEditComponent } from './components/manage-traning/traning-add-edit/traning-add-edit.component';
// import { TraningListComponent } from './components/manage-traning/traning-list/traning-list.component';
// import { ContractManagerListClosedComponent } from './components/contact-manager/contract-manager-list-closed/contract-manager-list-closed.component';
// import { ContractManagerListPendingComponent } from './components/contact-manager/contract-manager-list-pending/contract-manager-list-pending.component';
// import { ContactAddEditComponent } from './components/contact-manager/contact-add-edit/contact-add-edit.component';
// import { ContactListComponent } from './components/contact-manager/contact-list/contact-list.component';
import { VideoModalComponent } from './components/video-gallery/video-gallery/video-gallery.component';
import { BlockUIModule } from 'ng-block-ui';
import { existinglead, newleadmodal, previewfile, Searchmodaltech, warrantybottomsheet } from './components/user-dashboard/technological-consultant-dashboard/technological-consultant-dashboard.component';
import { AddTagsDialog, CommunicationComponent, FolderViewComponent, ViewContentComponent, WorkSheetComponent, clipboardFolderview, viewcontractstatusview, viewcontactssing, Warrentycallfromcontract, DialogOverviewWarrentyCallBlockDialog, DialogFollowUpComponent, viewfollowup, snackbarmodalFolderview, notedeletemodal } from './components/folder-view-management/folder-view/folder-view.component';
import { LeadFolderViewComponent } from './components/lead-folder-view/lead-folder-view.component';
import { addnewcontract, ContractManagerViewComponent } from './components/contact-manager/contract-manager-view/contract-manager-view.component';
import { SalesOpportunityOverviewComponent } from './components/sales-opportunity-overview/sales-opportunity-overview.component';
import { privacypolicy, termandcondition } from './components/footer/footer.component';
import { previewimg } from './components/jobticket/view-managejobticket/view-managejobticket.component';
import { ErrorComponent } from './common/error/error.component';
import { CommunicationWithEmailComponent } from './common/communication-with-email/communication-with-email.component';
import { LeadcontractComponent, LeadsignmodalComponent, MessagemodalComponent } from './leadcontract/leadcontract.component';
import { DialogCancelAlert } from './components/calendar-management/appointment-listing/upcoming-appointment/upcoming-appointment.component';
import { FaqaddeditComponent } from './components/faq_managment/faqaddedit/faqaddedit.component';
import { FaqcategoryaddeditComponent } from './components/faq_managment/faqcategoryaddedit/faqcategoryaddedit.component';
import { FaqcategorylistingComponent } from './components/faq_managment/faqcategorylisting/faqcategorylisting.component';
import { Answer, FaqlistingComponent } from './components/faq_managment/faqlisting/faqlisting.component';
import { FaqviewComponent } from './components/faq_managment/faqview/faqview.component';
import { Addusermodal, LogmeComponent, BottonSheetuser, ChangeStatusModal } from './components/distributor/users/users.component';

import { Addcommison, CommissionViewDialogOverviewExampleDialog } from './components/resources/product-list/product-list.component';
import { ManageCommissonModule } from './components/managecommisson/managecommison.module';
import { Viewcommission } from './components/managecommisson/managecommissionlist/managecommissionlist.component';
import { paymentsuggetions } from './components/managecommisson/salescommission/salescommission.component';
import { clipboarddata } from './components/user-dashboard/distributor-dashboard/distributor-dashboard.component';
import { contractstatus } from './components/contact-manager/contract-request-list/contract-request-list.component';

import { environment } from 'src/environments/environment';
import { LeadpracticeComponent } from './components/lead/leadpractice/leadpractice.component';
import { BottomSheetforaddlead } from './components/lead/lead-add-edit/lead-add-edit.component';
import { AddleadcontractComponent } from './components/lead/addleadcontract/addleadcontract.component';
// import { cancelupcomingAlert, markasdoneappointmentAlert, UpcomingPastAppointmentComponent } from './components/upcoming-past-appointment/upcoming-past-appointment.component';
import { PrcticeQuestionnaireComponent } from './components/prctice-questionnaire/prctice-questionnaire.component';
import { markasdoneappointmentAlert } from './components/upcoming-past-appointment/upcoming-past-appointment.component';
import { CreditComponent } from './components/credit/credit.component';
import { DialogMarkAsDoneAlert } from './components/calendar-management/appointment-listing/past-appointment/past-appointment.component';
import { PeceContractComponent } from './components/pece-contract/pece-contract.component';
import { TmflowContractComponent } from './components/tmflow-contract/tmflow-contract.component';
import { TmflowfiveyearContractComponent } from './components/tmflowfiveyear-contract/tmflowfiveyear-contract.component';
import { CommissionListingComponent } from './components/commission/commission-listing/commission-listing.component';
import { CommissionAddComponent } from './components/commission/commission-add/commission-add.component';
import { FollowupListingComponent } from './components/followup-listing/followup-listing.component';
import { CommissionPaymentComponent } from './components/commission/commission-payment/commission-payment.component';
import { ObservableService } from './services/observable.service';
import { NavAccessService } from './services/nav-access.service';
import { CornGoogleEventsComponent } from './components/corn-google-events/corn-google-events.component';
import { TemporaryTechSignupStopComponent } from './common/temporary-tech-signup-stop/temporary-tech-signup-stop.component';
import { DialogPendingContractDialog, PendingContractListComponent } from './components/pending-contract-list/pending-contract-list.component';
import { TrainingproxymanagementComponent } from './components/trainingproxymanagement/trainingproxymanagement/trainingproxymanagement.component';
import { AllemailsquedReportsComponent } from './components/allemailsqued-reports/allemailsqued-reports.component';
import { Addemailmodal, AdminEmailSettingsComponent } from './components/admin-email-settings/admin-email-settings.component';

// import { EarningSalesComponent } from './components/resources/earning-sales/earning-sales.component';
// import { ProductRelatedResourcesComponent } from './components/resources/product-related-resources/product-related-resources.component';
// import { TestComponent } from './test/test.component';
// import { ProductVideoComponent } from './components/video-managent/product-video/product-video.component';
// import { DetailspipePipe } from './pipes/detailspipe.pipe';
// import { SafePipe } from './pipes/safe.pipe';
// import { markasdoneappointmentAlert } from './components/upcoming-past-appointment/upcoming-past-appointment.component';
// import { cancelupcomingAlert, markasdoneappointmentAlert, UpcomingPastAppointmentComponent } from './components/upcoming-past-appointment/upcoming-past-appointment.component';

// import { ReplacePipe } from './pipes/replace.pipe';
@NgModule({
  declarations: [
    viewcontractstatusview,
    AppComponent,
    LoginComponent,
    ForgetPasswordComponent,
    ResetPasswordComponent,
    MyAccountComponent,
    LoaderComponent,
    ContractComponent,
    ImageModalComponent,
    BottonSheetAdmin,
    BottonSheetCloser,
    BottonSheetContact,
    BottonSheetDistributor,
    BottonSheetLead,
    BottonSheetSales,
    BottonSheetTech,
    ViewDetailsAdmin,
    ViewDetailsCloser,
    ViewDetailsManager,
    ViewDetailsDistributor,
    ViewDetailsLead,
    ViewDetailsSales,
    ViewDetailsTech,
    Signmodal,
    DialogBoxComponent,
    FullDetailsComponent,
    AddUserComponent,
    VideoGalleryComponent,
    iscontractmanagerDialog,
    VideoModalComponent,
    Searchmodaltech,
    previewfile,
    previewimg,
    FolderViewComponent,
    // ContractViewComponent
    // AddEditLessionComponent,
    // LessionListComponent,
    WorkSheetComponent,
    CommunicationComponent,
    LeadFolderViewComponent,
    SalesOpportunityOverviewComponent,
    ViewContentComponent,
    // StatePipe,
    // ContractManagerViewComponent,
    // ContractViewComponent,
    privacypolicy,
    termandcondition,
    AddTagsDialog,
    ErrorComponent,
    CommunicationWithEmailComponent,
    DialogCancelAlert,
    DialogMarkAsDoneAlert,
    LeadcontractComponent,
    LeadsignmodalComponent,
    clipboardFolderview,
    snackbarmodalFolderview,
    FaqaddeditComponent,
    FaqcategoryaddeditComponent,
    FaqcategorylistingComponent,
    FaqlistingComponent,
    Addcommison,
    CommissionViewDialogOverviewExampleDialog,
    FaqviewComponent,
    MessagemodalComponent,
    Addusermodal,
    Addemailmodal,
    MessagemodalComponent,
    LogmeComponent,
    BottonSheetuser,
    ChangeStatusModal,
    Viewcommission,
    paymentsuggetions,
    Answer,
    clipboarddata,
    contractstatus,
    addnewcontract,
    warrantybottomsheet,
    existinglead,
    newleadmodal,
    LeadpracticeComponent,
    BottomSheetforaddlead,
    AddleadcontractComponent,
    // cancelupcomingAlert,
    markasdoneappointmentAlert,
    PrcticeQuestionnaireComponent,
    viewcontactssing,
    Warrentycallfromcontract,
    DialogOverviewWarrentyCallBlockDialog,
    CreditComponent,
    PeceContractComponent,
    TmflowContractComponent,
    TmflowfiveyearContractComponent,
    DialogFollowUpComponent,
    // CommissionListingComponent,
    // CommissionAddComponent,
    bottomsheetstatus,
    bottomsheetfollowup,
    Dispositionview,
    CommissionPaymentComponent,
    viewfollowup,
    CornGoogleEventsComponent,
    TemporaryTechSignupStopComponent,
    PendingContractListComponent,
    DialogPendingContractDialog,
    TrainingproxymanagementComponent,
    AllemailsquedReportsComponent,
    AdminEmailSettingsComponent,
    notedeletemodal
    
    // EarningSalesComponent,
    // ProductRelatedResourcesComponent
    // TestComponent,
    // ProductVideoComponent,
    // DetailspipePipe
    // SafePipe
    // cancelupcomingAlert,

    // ReplacePipe
  ],
  imports: [
    BlockUIModule.forRoot(),
    BrowserModule,
    // AngularFireModule.initializeApp(environment),
    AppRoutingModule,
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    LoginModule,
    HttpClientModule,
    ShareModule,
    CommonModule,
    ChartsModule,
    CalendarManagementModule,
    // TraningModule,
    MatCarouselModule,
    VideoManagementModule,
    ManageCommissonModule

  ],
  entryComponents: [
    viewcontractstatusview,
    ImageModalComponent,
    contractstatus,
    BottonSheetAdmin,
    BottonSheetCloser,
    BottonSheetContact,
    BottonSheetDistributor,
    BottonSheetLead,
    BottonSheetSales,
    BottonSheetTech,
    ViewDetailsAdmin,
    ViewDetailsCloser,
    ViewDetailsManager,
    ViewDetailsDistributor,
    ViewDetailsLead,
    ViewDetailsSales,
    ViewDetailsTech,
    Signmodal,
    DialogBoxComponent,
    AddUserComponent,
    iscontractmanagerDialog,
    VideoModalComponent,
    Searchmodaltech,
    previewfile,
    previewimg,
    WorkSheetComponent,
    CommunicationComponent,
    ViewContentComponent,
    privacypolicy,
    termandcondition,
    AddTagsDialog,
    DialogCancelAlert,
    DialogMarkAsDoneAlert,
    LeadsignmodalComponent,
    clipboardFolderview,
    snackbarmodalFolderview,
    MessagemodalComponent,
    Addusermodal,
    Addemailmodal,
    Addcommison,
    CommissionViewDialogOverviewExampleDialog,
    LogmeComponent,
    BottonSheetuser,
    ChangeStatusModal,
    Viewcommission,
    paymentsuggetions,
    Answer,
    clipboarddata,
    addnewcontract,
    warrantybottomsheet,
    existinglead,
    newleadmodal,
    BottomSheetforaddlead,
    // cancelupcomingAlert,
    markasdoneappointmentAlert,
    viewcontactssing,
    Warrentycallfromcontract,
    DialogOverviewWarrentyCallBlockDialog,
    DialogFollowUpComponent,
    bottomsheetstatus,
    bottomsheetfollowup,
    Dispositionview,
    viewfollowup,
    DialogPendingContractDialog,
    notedeletemodal
  ],
  providers: [ApiService, CalendarService, AuthGuard, CookieService, ResolveService, LoaderService,ObservableService,NavAccessService

    // { provide: HTTP_INTERCEPTORS, useClass: LoaderInterceptor, multi: true }
  ],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA],
})
export class AppModule { }
