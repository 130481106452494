import { DatePipe } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { ActivatedRoute, Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { ApiService } from 'src/app/services/api.service';
import { UserserviceService } from 'src/app/services/userservice.service';

export interface DialogData {
  data: any;
  val: any;
  leadid: any;
}
@Component({
  selector: 'app-contract-manager-view',
  templateUrl: './contract-manager-view.component.html',
  styleUrls: ['./contract-manager-view.component.css']
})
export class ContractManagerViewComponent implements OnInit {
  public editorconfig: any = {}
  public ckeConfig: any = {
    toolbar: [
      {
        name: "editing",
        items: ["Scayt", "Find", "Replace", "SelectAll"]
      },
      {
        name: "clipboard",
        items: [
          "Cut",
        ]
      },
      {
        name: "links",
        items: [
          "Link",
        ]
      },
      { name: "document", items: ["Source"] },
      {
        name: "insert",
        items: [
          "Image",
        ]
      },
      "/",
      {
        name: "basicstyles",
        items: [
          "Bold",
          "Italic",
          "Underline",
        ]
      },

      {
        name: "styles",
        items: ["Styles", "Format", "FontSize", "-", "TextColor", "BGColor"]
      }
    ]
  }
  public htmType: any = '';
  public formdata: any;
  public alldata: any;
  public notesdata: any;
  public allnotesdata: any = {};
  public current_date: any = new Date();
  public nowDate: any = new Date();
  public types: any;
  public topcontract: any;
  public tarmsspecification: any;
  public buttomcontract: any;
  public disabledcheck: boolean = false;
  public status2: any;
  constructor(public Apiservice: ApiService, public userservice: UserserviceService, public activatedRoute: ActivatedRoute, public router: Router, public cookies: CookieService, public dialog: MatDialog) {
    // console.log(activatedRoute.snapshot.params.userid)
    this.types = JSON.parse(this.cookies.get('type'));
    this.nowDate = new Date()
    this.activatedRoute.data.subscribe((res: any) => {

      if (res.resolvedata.result != null && res.resolvedata.result != '') {
        this.alldata = res.resolvedata.result[0];
        this.buttomcontract = this.alldata.buttomcontract;
        this.topcontract = this.alldata.topcontract;
        this.tarmsspecification = this.alldata.tarmsspecification;
        if (this.alldata.status && this.alldata.status != null && typeof (this.alldata.status) != 'undefined' && Array.isArray(this.alldata.status) == true &&
          this.alldata.status[this.alldata.status.length - 1].satatus != '') {
          this.status2 = this.alldata.status[this.alldata.status.length - 1].satatus;
        }
        console.log(this.alldata, 'llllllllllllll', this.status2);

      }

    })

    this.editorconfig.extraAllowedContent = '*[class](*),span;ul;li;table;td;style;*[id];*(*);*{*}';
    setInterval(() => {
      this.current_date = Math.round(new Date().getTime())
    }, 1);
    console.log(this.nowDate)

  }

  ngOnInit() {
    console.log(this.activatedRoute.snapshot.url[1].path);


  }

  complete(status, flag) {
    console.log(flag, 'ppppppoiuytrfghnbfdx', this.activatedRoute.snapshot)
    let data: any = {};
    // console.log(this.notesdata)
    this.allnotesdata = {
      updatedatetime: Math.round(new Date().getTime()),
      notes: this.notesdata,
      username: this.userservice.fullname,
      userId: this.userservice.userid
    };

    if (flag == 'add') {
      data = {
        data: {
          leadid: this.activatedRoute.snapshot.params.leadid,
          userid: this.alldata.userid,
          notes: this.allnotesdata,
          products: this.alldata.products,
          status: {
            "satatus": status,
            "updated_datetime": Math.round(new Date().getTime())
          },
          id: this.alldata._id
        }
      }
    }

    if (flag == 'view') {
      if (this.userservice.type == 'technological-consultant') {
        console.log(this.activatedRoute.snapshot.params);
        data = {
          date: this.nowDate,
          data: {
            leadid: this.activatedRoute.snapshot.params.leadid,
            userid: this.userservice.userid,
            notes: this.allnotesdata,
            products: this.alldata.products,
            status: {
              "addedby": this.userservice.fullname,
              "satatus": status,
              "updated_datetime": Math.round(new Date().getTime())
            },
            topcontract: this.topcontract,
            tarmsspecification: this.tarmsspecification,
            buttomcontract: this.buttomcontract,
            termsandspecification: this.disabledcheck,
            id: this.alldata._id,
            contract_name:this.alldata.contractname,
            
          },
          secret: this.userservice.secret,
          token: this.userservice.jwttoken
        }
        if (this.activatedRoute.snapshot.url[1].path=='create') {
          data.data.c_id=  this.alldata._id;
        }
      } else if (this.userservice.type == 'contract-manager') {
        console.log(this.activatedRoute.snapshot.params);
        data = {
          date: this.nowDate,
          data: {
            leadid: this.activatedRoute.snapshot.params.leadid,
            contract_id: this.userservice.userid,
            notes: this.allnotesdata,
            products: this.alldata.products,
            status: {
              "addedby": this.userservice.fullname,
              "satatus": status,
              "updated_datetime": Math.round(new Date().getTime())
            },
            topcontract: this.topcontract,
            tarmsspecification: this.tarmsspecification,
            buttomcontract: this.buttomcontract,
            termsandspecification: this.disabledcheck,
            id: this.alldata._id,
            contract_name:this.alldata.contractname
          },
          secret: this.userservice.secret,
          token: this.userservice.jwttoken
        }
      }

    }

    this.Apiservice
      .getDataWithoutToken(data, 'api/addcontractrequests')
      .subscribe((res: any) => {
        // console.log(res, 'asdfghjklzxcvbn')
        if (this.types == 'technological-consultant') {
          if (flag == 'view') {
            this.router.navigateByUrl('/contract-manager/contract-request/list')

          } else {
            this.router.navigateByUrl('/user-details/lead/' + this.activatedRoute.snapshot.params.leadid,)


          }
        }
        if (this.types == 'contract-manager') {
          if (flag == 'view') {
            this.router.navigateByUrl('/contract-manager/contract-request/list')

          } else {
            this.router.navigateByUrl('/contract-manager/contract-request/list')

          }
        }

      })
  }
  checking() {
    // console.log(this.disabledcheck)
  }
  addcontentmodal() {
    const dialogRef = this.dialog.open(addnewcontract, {
      panelClass: "addcontract_modal",

      data: { val: this.alldata, leadid: this.activatedRoute.snapshot.params.leadid },
    });
    dialogRef.afterClosed().subscribe((result: any) => {
      console.log(result, "tttttttttttttttt");
    });
  }
}
@Component({
  selector: "adduser",
  templateUrl: "addcontent.html",
})
export class addnewcontract {
  public formdata: any;
  public nowDate: any = new Date();
  public allnotesdata: any = {};
  constructor(
    public dialogRef: MatDialogRef<addnewcontract>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    public apiservice: ApiService,
    public activatedroute: ActivatedRoute,
    public userservice: UserserviceService,
    public cookies: CookieService,
    public activatedRoute: ActivatedRoute,
    public router: Router
  ) {
    console.log(data, "+++++");
    this.formdata = {
      successmessage: "Added Successfully !!",

      submitactive: true, // optional, default true
      submittext: "Submit",

      canceltext: "Cancel",
      apiUrl: this.apiservice.api_url,
      //endpoint: ,
      jwttoken: this.apiservice.jwttoken,

      fields: [
        {
          label: "Contract Title",
          name: "contract_title",
          value: "",
          type: "text",
          validations: [
            { rule: "required", message: "Contract Title is required" },
          ],
        },


        {
          label: "Upload Pdf file: ",
          name: "uploadcontract",
          type: "file",
          multiple: true,
          prefix: "image-" + Date.now(),
          path: "betoparedes/bulk-upload/",
          baseurl: "betoparedes-dev-screenshots",
          bucket: "awsbackend-dev-patient-files-test",
          apiurl:
            "https://tge24bc2ne.execute-api.us-east-1.amazonaws.com/dev/requestUploadURL",
          apideleteurl:
            "https://tge24bc2ne.execute-api.us-east-1.amazonaws.com/dev/deletefilefromBucket",
          validations: [
            { rule: "required", message: "Please Upload  file " },
          ],
        },
        {
          label: "Notes",
          name: "notes",
          value: "",
          type: "textarea",
        
        },

        {
          label: "User Id",
          name: "userid",
          type: "hidden",
          value: this.userservice.userid,
        },
        {
          label: "User Name",
          name: "user_name",
          type: "hidden",
          value: this.userservice.fullname,
        },
      ],
    };
  }

  listenFormFieldChange(val) {
    console.log(val);
    if (val.field == 'formcancel') {
      this.dialogRef.close();
    }

    if (val.field == 'fromsubmit' && val.fieldval == 'success') {
      this.allnotesdata = {
        updatedatetime: Math.round(new Date().getTime()),
        notes: val.fromval.notes,
        username: this.userservice.fullname,
        userId: this.userservice.userid
      };
      let data1;
      data1 = {
        date: this.nowDate,
        data: {
          leadid: this.data.leadid,
          userid: this.userservice.userid,
          notes: this.allnotesdata,
          products: this.data.val.products,
          status: {
            "addedby": this.userservice.fullname,
            "satatus": 'Awating response from system',
            "updated_datetime": Math.round(new Date().getTime())
          },
          uploadcontract: val.fromval.uploadcontract,
          contracttitle: val.fromval.contract_title
        },
        secret: this.userservice.secret,
        token: this.userservice.jwttoken
      }
      this.apiservice
        .getDataWithoutToken(data1, 'api/updatecontracttemplate')
        .subscribe((res: any) => {
          console.log(res);
          if (res.status == 'success') {
            this.dialogRef.close();
            this.router.navigateByUrl('/contract-manager/contract-request/list');          }
        })
    }
  }

}
