import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class DetectdomainService {
  public domain: any;

  constructor() { }
  getdomain() {
    // console.log("window.location from service ==>",window.location.hostname);
    console.log("window.location from service ==>", window.location.port);
    if (window.location.port == "4215" || window.location.hostname == 'backoffice.medigradetechnologies.com' || window.location.hostname == 'www.backoffice.medigradetechnologies.com' || window.location.hostname == 'dev.backoffice.medigradetechnologies.com' || window.location.hostname == 'www.dev.backoffice.medigradetechnologies.com') {
      console.log("window.location from service ==>", window.location.port);
      console.log("window.location hostname from service ==>", window.location.hostname);
      this.domain = "medigrade";
    }
    if (window.location.port == "4210" || window.location.port == "4200" || window.location.hostname == 'backoffice.betoparedes.com' || window.location.hostname == 'www.backoffice.betoparedes.com' || window.location.hostname == 'dev.backoffice.betoparedes.com' || window.location.hostname == 'www.dev.backoffice.betoparedes.com') {
      console.log("window.location from service ==>", window.location.port);
      console.log("window.location hostname from service ==>", window.location.hostname);
      this.domain = "betoparedes";
    }
    return this.domain
  }
}

