export const environment = {
  production: false,
  api_url: 'https://z2oo2a8oq9.execute-api.us-east-1.amazonaws.com/dev/',
  // api_url: 'https://vg3kx9c825.execute-api.us-east-2.amazonaws.com/production/',
  api_url2: 'https://8p924jiila.execute-api.us-east-2.amazonaws.com/dev/',
  // api_url2: 'https://x2qsds0gi7.execute-api.us-east-2.amazonaws.com/production/',
  // api_url:'http://localhost:3000/dev/',
  // api_calender_url: 'http://localhost:3000/beto-paredes-api/',
  api_calender_url: 'https://sakkrtutk3.execute-api.us-east-2.amazonaws.com/dev/beto-paredes-api/',
  add_google_calendar_url: 'https://px5mpxecj1.execute-api.us-east-1.amazonaws.com/dev/beto-google-calendar',
  api_url_py:'https://8p924jiila.execute-api.us-east-2.amazonaws.com/dev/',
  google_calendar_api: 'https://px5mpxecj1.execute-api.us-east-1.amazonaws.com/dev/',
  domain_url: 'http://localhost:4200/',
  pdfcreated: 'https://bp-html-pdf.influxiq.com/',
  forntendUrl:'https://betoparedes.com/sales-associate.php?affid=',
  forntendUrl2:'&flag=dev',
  product_tmflow: '604a0b6e40962e00088f0d79',
  product_pece: '604aff3691b7c80008ca11a8',
  product_bioEnergetics: '612c883f118c110009f02820',
  product_RSTSanexas: '612c89242005f400082d5fb1',
  

  // domain_url: 'https://backoffice.betoparedes.com/',
  // api_url2: 'https://x2qsds0gi7.execute-api.us-east-2.amazonaws.com/production/',
  // api_url: 'https://vg3kx9c825.execute-api.us-east-2.amazonaws.com/production/',
  // api_calender_url: 'https://ejau3m66a3.execute-api.us-east-2.amazonaws.com/production/beto-paredes-api/',
  // add_google_calendar_url: 'https://d0fw1rgejc.execute-api.us-east-2.amazonaws.com/production/beto-google-calendar',
  // google_calendar_api: 'https://d0fw1rgejc.execute-api.us-east-2.amazonaws.com/production/',
  // pdfcreated: 'https://bp-html-pdf.influxiq.com/',
  // forntendUrl:'https://betoparedes.com/sales-associate.php?affid=',
  // forntendUrl2:''
};
