import { HttpClient } from "@angular/common/http";
import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { MatSnackBar } from '@angular/material';
import { ActivatedRoute, Router } from '@angular/router';
// import {  } from 'events';
import { CookieService } from 'ngx-cookie-service';
import { ApiService } from 'src/app/services/api.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-prctice-questionnaire',
  templateUrl: './prctice-questionnaire.component.html',
  styleUrls: ['./prctice-questionnaire.component.css'],
})
export class PrcticeQuestionnaireComponent implements OnInit {
  public formdata: any;
  public worksheetdata: any;
  public position: any = [];
  public medtechinhouse: any = [];
  public flag: boolean = false;
  public progressSpinner: any = {
    mode: 'indeterminate',
    loading: false,
    loading1: false,
    loading4: false,
    bookingStatus: 'Sending request',
  };
  public nuropathy_patients_in_house: any = [];
  @Output() myOutput: EventEmitter<any> = new EventEmitter();
  @Input() set myinput(val: any) {
    console.log(val);
    this.worksheetdata = val;
  }
  public editdata: any;
  public Doc_pracIs: any = [];
  public lastvisit: any = [];
  public prcticeHolistic: any = [];
  public formfieldrefreshdata: any = null;
  constructor(
    public apiservice: ApiService,
    public http: HttpClient,
    public activatedroute: ActivatedRoute,
    public cookies: CookieService,
    public router: Router,
    private _snackBar: MatSnackBar
  ) { }

  ngOnInit() {
    this.progressSpinner.loading4 = true;
    console.log(this.activatedroute.snapshot.params._id, this.worksheetdata);
    if (this.worksheetdata.formtype == 'edit') {
      let form_data = {
        user_id: this.worksheetdata.user_details._id,
        product_id: this.worksheetdata._id,

        token: this.cookies.get('jwtToken'),
        secret: this.cookies.get('secret'),
      };
      this.apiservice
        .customRequest(form_data, 'api/work-sheet-data', environment['api_url'])
        .subscribe((response: any) => {
          // console.log(response.result[0].worksheet_data.nms_representative,);
          this.progressSpinner.loading4 = false;
          this.editdata = response.result.worksheet_data;

          if (response.status == 'success') {
            for (const key in this.editdata.Doc_pracIs) {
              this.editdata.Doc_pracIs[key] = Number(this.editdata.Doc_pracIs[key])
            }
            for (const key in this.editdata.pain_and_inflammation) {
              this.editdata.pain_and_inflammation[key] = Number(this.editdata.pain_and_inflammation[key])
            }
            for(const key in this.editdata.tmflow_condition){
              this.editdata.tmflow_condition[key] = Number(this.editdata.tmflow_condition[key])
            }
            console.log(response.result.worksheet_data.Doc_pracIs__0);
            // if (response.result.worksheet_data.Doc_pracIs__0 == true) {
            //   this.Doc_pracIs.push(0);
            //   console.log('ppppppppppppppppppppp');
            // }
            // if (response.result.worksheet_data.Doc_pracIs__1 == true) {
            //   this.Doc_pracIs.push(1);
            // }

            // if (response.result.worksheet_data.Doc_pracIs__2 == true) {
            //   this.Doc_pracIs.push(2);
            // }
            // if (response.result.worksheet_data.Doc_pracIs__3 == true) {
            //   this.Doc_pracIs.push(3);
            // }
            // if (response.result.worksheet_data.Doc_pracIs__4 == true) {
            //   this.Doc_pracIs.push(4);
            // }
            // if (response.result.worksheet_data.Doc_pracIs__5 == true) {
            //   this.Doc_pracIs.push(5);
            // }
            // if (response.result.worksheet_data.Doc_pracIs__6 == true) {
            //   this.Doc_pracIs.push(6);
            // }
            // if (response.result.worksheet_data.Doc_pracIs__7 == true) {
            //   this.Doc_pracIs.push(7);
            // }
            // if (response.result.worksheet_data.Doc_pracIs__8 == true) {
            //   this.Doc_pracIs.push(8);
            // }
            // if (response.result.worksheet_data.Doc_pracIs__9 == true) {
            //   this.Doc_pracIs.push(9);
            // }

            // if (response.result.worksheet_data.lastvisit__0 == true) {
            //   this.lastvisit.push(0);
            // }
            // if (response.result.worksheet_data.lastvisit__1 == true) {
            //   this.lastvisit.push(1);
            // }
            // if (response.result.worksheet_data.lastvisit__2 == true) {
            //   this.lastvisit.push(2);
            // }
            // if (response.result.worksheet_data.lastvisit__3 == true) {
            //   this.lastvisit.push(3);
            // }
            // if (response.result.worksheet_data.lastvisit__4 == true) {
            //   this.lastvisit.push(4);
            // }
            // if (
            //   response.result.worksheet_data.nuropathy_patients_in_house__0 ==
            //   true
            // ) {
            //   this.nuropathy_patients_in_house.push(0);
            // }
            // if (
            //   response.result.worksheet_data.nuropathy_patients_in_house__1 ==
            //   true
            // ) {
            //   this.nuropathy_patients_in_house.push(1);
            // }
            // if (response.result.worksheet_data.prcticeHolistic__0 == true) {
            //   this.prcticeHolistic.push(0);
            // }
            // if (response.result.worksheet_data.prcticeHolistic__1 == true) {
            //   this.prcticeHolistic.push(1);
            // }
            // if (response.result.worksheet_data.medtechinhouse__0 == true) {
            //   this.medtechinhouse.push(0);
            // }
            // if (response.result.worksheet_data.medtechinhouse__1 == true) {
            //   this.medtechinhouse.push(1);
            // }
            // if (response.result.worksheet_data.prcticeHolistic__0== true) {
            //   this.prcticeHolistic.push(0);
            // }
            console.log(this.Doc_pracIs);
            this.editdata_info(response.result.worksheet_data);
            let position_val = '';
            // for (let i = 0; i <= 4; i++) {
            //   position_val = 'lastvisit__' + [i].toString();
            //   position_val = position_val.toString();
            //   console.log(position_val, 'response.result.worksheet_data.lastvisit__[i]', response.result.worksheet_data.position_val);

            //   // if (response.result.worksheet_data.lastvisit__[i] == true) {
            //   //   this.position.push(i)
            //   // }
            // }
            console.log(this.position);
          } else {
            //   // this.openSnackBar('Something went wrong. Please try again.', null);
          }
        });
    }
    if (this.worksheetdata.formtype == 'add') {
      this.progressSpinner.loading4 = false;
      console.log(this.worksheetdata.user_details);

      this.formdata = {
        // successmessage: 'Added Successfully !!',
        // redirectpath: 'admin/list',
        submitactive: true, // optional, default true
        submittext: 'Submit',
        resettext: 'Reset',
        canceltext: 'Print',
        apiUrl: this.apiservice.api_url,
        // endpoint: 'api/addquestionnaire', //
        jwttoken: '',
        // cancelroute: 'admin/list',

        fields: [
          {
            heading: 'GENERAL INFORMATION',
            label: 'Name of Practice',
            name: 'name_of_practice',
            type: 'text',
            value: this.worksheetdata.user_details.firstname,
            validations: [],
          },
          {
            label: 'Address',
            name: 'address',
            value: this.worksheetdata.user_details.address,
            type: 'textarea',
            validations: [
              // { rule: 'required', message: 'Zip is Required' },
              // {
              //   rule: 'maxLength',
              //   value: 5,
              //   message: 'Please enter the valid Zip ID',
              // },
            ],
          },

          {
            label: 'City',
            name: 'city',
            type: 'text',

            value: this.worksheetdata.user_details.city,

            validations: [],
          },
          {
            label: 'State',
            name: 'state',
            type: 'text',

            value: this.worksheetdata.user_details.state,

            validations: [],
          },
          {
            label: 'Zip',
            name: 'zip',
            type: 'number',

            value: this.worksheetdata.user_details.zip,

            validations: [],
          },
          {
            label: 'Office Phone',
            name: 'officephone',
            type: 'number',
            value: '',
            validations: [],
          },

          {
            label: 'Office Fax',
            name: 'officefax',
            type: 'number',
            value: '',
            validations: [],
          },
          {
            label: 'Contact Person',
            name: 'title',
            type: 'text',
            value: '',
            validations: [],
          },
          {
            label: 'Direct Line',
            name: 'extension_one',
            type: 'text',
            value: '',
            validations: [],
          },
          {
            label: 'Email',
            name: 'email_one',
            type: 'email',
            hint: '',
            value: this.worksheetdata.user_details.email,

            validations: [],
          },
          {
            heading: "PRACTITIONER'S INFORMATION",
            label: 'Doctor/Practice is : ',
            name: 'Doc_pracIs',
            // hint: 'In months',
            // prefix:"*",
            type: 'checkbox',
            multiple: true,
            val: [
              { key: 0, val: 'Family Medicine' },
              { key: 1, val: 'Neurology' },
              { key: 2, val: 'D.O Doctor of Osteopathy' },
              { key: 3, val: 'General Practice' },
              { key: 4, val: 'Internal Medicine' },
              { key: 5, val: 'Pain Mgnt (Integrated Practice)' },
              { key: 6, val: 'Primary Care' },
              { key: 7, val: 'Endocrinology' },
              { key: 8, val: 'Integrated Specialty' },
              { key: 9, val: 'Cardiology' },
              { key: 10, val: 'Physical Therapy' },
              { key: 11, val: 'Chiropractic' },
              { key: 12, val: 'Nurse Practitioner' },
            ],
            value: null,
            validations: [{ rule: 'required', message: "Must select any of them." }],
          },
          {
            heading: "Pain Management",
            label: 'Please check all disease states currently treated in the practice for pain and inflammation.',
            name: 'pain_and_inflammation',
            // hint: 'In months',
            // prefix:"*",
            type: 'checkbox',
            multiple: true,
            val: [
              { key: 0, val: 'AIDS Syndrome' },
              { key: 1, val: 'Anterior Tibial Compartment' },
              { key: 2, val: 'Arthritis - All Body Parts' },
              { key: 3, val: 'Asthma' },
              { key: 4, val: 'Atrophy' },
              { key: 5, val: 'Bechterew’s Disease' },
              { key: 6, val: 'Bells Palsy (in total denervation)' },
              { key: 7, val: 'Bell’s Palsy (Motor nerve lesions' },
              { key: 8, val: 'Bell’s Palsy (partial denervation, neuropraxia, or after onset of reinnervation)' },
              { key: 9, val: 'Bursitis - All Body Parts' },
              { key: 10, val: 'Carpal Tunnel Syndrome' },
              { key: 11, val: 'Cervical Compression Syndrome' },
              { key: 12, val: 'Constipation' },
              { key: 13, val: 'Costoclavicular Syndrome' },
              { key: 14, val: 'Chrohn’s Disease' },
              { key: 15, val: 'Crush Syndrome' },
              { key: 16, val: 'Cubital Tunnel Syndrome' },
              { key: 17, val: 'Cystitis' },
              { key: 18, val: 'Decubitis & Pressure Ulceration’s/Wounds' },
              { key: 19, val: 'Degenerative Joint Disease' },
              { key: 20, val: 'DeQuervain’s Tenosynovitis' },
              { key: 21, val: 'Disorders of the Metacarpus' },
              { key: 22, val: 'Duchenne’s Syndrome' },
              { key: 23, val: 'Endometriosis' },
              { key: 24, val: 'Epicondylitis (Lateral & Media)' },
              { key: 25, val: 'Facial Neuralgia' },
              { key: 26, val: 'Fibromyalgia' },
              { key: 27, val: 'Gout' },
              { key: 28, val: 'Headache' },
              { key: 29, val: 'Herpes Zoster' },
              { key: 30, val: 'Hyperabduction Syndrome' },
              { key: 31, val: 'Ideopathic Polyneuritis (acute)' },
              { key: 32, val: 'Inflammatory Bowel Diseasee' },
              { key: 33, val: 'Interdigital Neuralgia of the Foot' },
              { key: 34, val: 'Intermittent Claudication' },
              { key: 35, val: 'Knee Surgery' },
              { key: 36, val: 'Lateral Femoral Cutaneous Nerve Syndrome' },
              { key: 37, val: 'Lumbago' },
              { key: 38, val: 'Lyme disease' },
              { key: 39, val: 'Median Nerve Injury or Palsy' },
              { key: 40, val: 'Muscle Strengthening' },
              { key: 41, val: 'Myofascitis' },
              { key: 42, val: 'Neuropraxia' },
              { key: 43, val: 'Osgood Schlatter Disease' },
              { key: 44, val: 'Osteoporosis' },
              { key: 45, val: 'Painful Arc Syndrome' },
              { key: 46, val: 'Parkinson’s Disease' },
              { key: 47, val: 'Piriformis Syndrome' },
              { key: 48, val: 'Polyneuropathies' },
              { key: 49, val: 'Post burn Pain' },
              { key: 50, val: 'Postphiebitic Syndrome' },
              { key: 51, val: 'Premenstrual Syndrome' },
              { key: 52, val: 'Prolapse or Herniated Disc' },
              { key: 53, val: 'Radial Nerve Injury or Palsy' },
              { key: 54, val: 'Radiation Syndrome (Acute)' },
              { key: 55, val: 'Raynaud’s Disease' },
              { key: 56, val: 'Reflex Sympathic Dystrophy' },
              { key: 57, val: 'Rheumatoid Disease' },
              { key: 58, val: 'Scalenus Syndrome' },
              { key: 59, val: 'Sciatica' },
              { key: 60, val: 'Scapulocostal Syndrome' },
              { key: 61, val: 'Sinusitis' },
              { key: 62, val: 'Stroke Syndrome' },
              { key: 63, val: 'Tarsal Tunnel Syndrome' },
              { key: 64, val: 'Temporal Mandibular Joint Disorder' },
              { key: 65, val: 'Tendinitis-Tenosynovitus' },
              { key: 66, val: 'Thoracic Outlet Syndrome' },
              { key: 67, val: 'Toothache' },
              { key: 68, val: 'Trigeminal Neuralgia' },
              { key: 69, val: 'UlnarNerve Injury or Palsy' },
              { key: 70, val: 'Pain - All Body Parts' }
            ],
            value: null,
            validations: [{ rule: 'required', message: "Must select any of them." }],
          },
          {
            heading: "Conditions treated by TM-Flow device:",
            label: '',
            name: 'tmflow_condition',
            // hint: 'In months',
            // prefix:"*",
            type: 'checkbox',
            multiple: true,
            val: [
              { key: 0, val: 'Heart Attack' },
              { key: 1, val: 'Heart Rate Variability Issues' },
              { key: 2, val: 'Cardiac Output to Body Surface Area' },
              { key: 3, val: 'Abnormal LDL Cholesterol levels' },
              { key: 4, val: 'Insulin Resistance' },
              { key: 5, val: 'Diabetes with Complications' },
              { key: 6, val: 'Physical and Mental Stress, Anxiety and Fatigue' },
              { key: 7, val: 'Stroke' },
              { key: 8, val: 'Angiotensin Activity' },
              { key: 9, val: 'Atherosclerosis' },
              { key: 10, val: 'Peripheral Vascular Disease' },
              { key: 11, val: 'Hyperhidrosis and Hypohidrosis' },
              { key: 12, val: 'Impaired Glucose Intolerance' },
              { key: 13, val: 'Nerve Damage' },
              { key: 14, val: 'Obesity' },
              { key: 15, val: 'Amyloid Neuropathy' },
              { key: 16, val: 'Asthma' },
              { key: 17, val: 'Cardiovascular Associated Diseases' },
              { key: 18, val: 'Idiopathic Neuropathy' },
              { key: 19, val: 'Multiple System Dystrophy' },
              { key: 20, val: 'Post- Partum Dysfunctions' },
              { key: 21, val: 'Psychological Conditions' },
              { key: 22, val: 'Pure Autonomic Failure' },
              { key: 23, val: 'Sjogren’s Syndrome' },
            ],
            value: null,
            validations: [{ rule: 'required', message: "Must select any of them." }],
          },
          {
            heading: 'Total Practitioner(s) In Your Office:'
          },
          {
            // heading: "Total Practitioner(s) In Your Office:",
            label: "MD's/DO's",
            name: "no_of_md_or_do",
            type: "number",
            value: "",
            validations: [],
          },
          {
            label: "NP's",
            name: 'no_of_np',
            type: 'number',
            value: '',
            validations: [],
          },
          {
            label: "PA's",
            name: 'no_of_pa',
            type: 'number',
            value: '',
            validations: [],
          },
          {
            label: "Other(s)",
            name: 'no_of_others',
            type: 'number',
            value: '',
            validations: [],
          },
          {
            label: "Organizational Name(if any):",
            name: 'organizational_name',
            type: 'text',
            value: '',
            validations: [],
          },
          {
            label: "NPI#:",
            name: 'npi_one',
            type: 'text',
            value: '',
            validations: [],
          },
          {
            label: "Specialty/Taxonomy:",
            name: 'specialty_taxonomy_one',
            type: 'text',
            value: '',
            validations: [],
          },
          {
            label: "Total Patients Seen Per Day:",
            name: 'seen_per_day_one',
            type: 'text',
            value: '',
            validations: [],
          },
          {
            label: "Primary Practitioner:",
            name: 'primary_practitioner',
            type: 'text',
            value: '',
            validations: [],
          },
          {
            label: "NPI#:",
            name: 'npi_two',
            type: 'text',
            value: '',
            validations: [],
          },
          {
            label: "Specialty/Taxonomy:",
            name: 'specialty_taxonomy_two',
            type: 'text',
            value: '',
            validations: [],
          },
          {
            label: "Total Patients Seen Per Day:",
            name: 'seen_per_day_two',
            type: 'text',
            value: '',
            validations: [],
          },
          {
            label: "Practitioner",
            name: 'practitioner_one',
            type: 'text',
            value: '',
            validations: [],
          },
          {
            label: "NPI#:",
            name: 'npi_three',
            type: 'text',
            value: '',
            validations: [],
          },
          {
            label: "Specialty/Taxonomy:",
            name: 'specialty_taxonomy_three',
            type: 'text',
            value: '',
            validations: [],
          },
          {
            label: "Total Patients Seen Per Day:",
            name: 'seen_per_day_three',
            type: 'text',
            value: '',
            validations: [],
          },
          {
            label: "Practitioner",
            name: 'practitioner_two',
            type: 'text',
            value: '',
            validations: [],
          },
          {
            label: "NPI#:",
            name: 'npi_four',
            type: 'text',
            value: '',
            validations: [],
          },
          {
            label: "Specialty/Taxonomy:",
            name: 'specialty_taxonomy_four',
            type: 'text',
            value: '',
            validations: [],
          },
          {
            label: "Total Patients Seen Per Day:",
            name: 'seen_per_day_four',
            type: 'text',
            value: '',
            validations: [],
          },
          {
            label: "NP or PA (circle one):",
            name: 'np_or_pa',
            type: 'text',
            value: '',
            validations: [],
          },
          {
            label: "NPI#:",
            name: 'npi_five',
            type: 'text',
            value: '',
            validations: [],
          },
          {
            label: "Specialty/Taxonomy:",
            name: 'specialty_taxonomy_five',
            type: 'text',
            value: '',
            validations: [],
          },
          {
            label: "Total Patients Seen Per Day:",
            name: 'seen_per_day_five',
            type: 'text',
            value: '',
            validations: [],
          },
          {
            heading: 'PATIENT POPULATION',
            label:
              'What is your total patient count?',
            name: 'total_patient_count',
            // hint: 'In months',
            type: 'number',
            value: '',
            validations: []
          },
          {
            label:
              'Estimate what percentage are in the following category',
            name: 'estimate_percentage_category',
            // hint: 'In months',
            type: 'radio',
            labelPosition: 'before',
            val: [
              { key: 0, val: 'Under 16' },
              { key: 1, val: 'Between 17 to 70' },
              { key: 2, val: 'Over 70' },
            ],
            value: '',
            validations: []
          },
          {
            heading: 'BILLING AND INSURANCE',
            label:
              'How is your billing set up',
            name: 'billing_setup',
            // hint: 'In months',
            type: 'radio',
            labelPosition: 'before',
            val: [
              { key: 0, val: 'Inhouse billing' },
              { key: 1, val: 'Third party' },
            ],
            value: '',
            validations: []
          },
          {
            label: '% private insurance Patients',
            name: 'percent_of_private_insurance_patients',
            type: 'number',
            value: '',
            validations: [],
          },
          {
            label: '% of Madicare Patients',
            name: 'percent_of_madicare_patients',
            type: 'number',
            value: '',
            validations: [],
          },
          {
            label: '% of Madicaid Patients',
            name: 'percent_of_madicaid_patients',
            type: 'number',
            value: '',
            validations: [],
          },
          {
            label: '% of Cash Patients',
            name: 'percent_of_cash_patients',
            type: 'number',
            value: '',
            validations: [],
          },
          {
            label: 'LIST OF INSURANCE YOU PRATICIPATE WITH:',
            name: 'insurance',
            type: 'text',
            value: '',
            validations: [],
          },
          {
            label: 'REP NAME',
            name: 'rep_name',
            type: 'text',
            value: '',
            validations: [],
          },
          {
            label: 'PHONE NUMBER',
            name: 'phone_number',
            type: 'text',
            value: '',
            validations: [],
          },
          {
            label: ' ',
            name: 'leads',
            type: 'hidden',
            value: this.activatedroute.snapshot.params._id,
            validations: [],
          },
        ],
      };
      let isbioenergatics = '';
      let tmflow = '';
      for (const key in this.worksheetdata.user_details.products) {
        if (this.worksheetdata.user_details.products[key] == '612c883f118c110009f02820') {
          isbioenergatics = this.worksheetdata.user_details.products[key];
        }
        if (this.worksheetdata.user_details.products[key] == '604a0b6e40962e00088f0d79') {
          tmflow = this.worksheetdata.user_details.products[key];
        }
      }
      console.log(isbioenergatics);

      if (isbioenergatics == '') {
        setTimeout(() => {
          this.formfieldrefreshdata = { field: 'removefromcontrol', value: { name: 'pain_and_inflammation' } };
        }, 50);
        this.formdata.fields[10].val = [
          { key: 0, val: 'Family Medicine' },
          { key: 1, val: 'Neurology' },
          { key: 2, val: 'D.O Doctor of Osteopathy' },
          { key: 3, val: 'General Practice' },
          { key: 4, val: 'Internal Medicine' },
          { key: 5, val: 'Pain Mgnt (Integrated Practice)' },
          { key: 6, val: 'Primary Care' },
          { key: 7, val: 'Endocrinology' },
          { key: 8, val: 'Integrated Specialty' },
          { key: 9, val: 'Cardiology' },
        ];
      }
      if (tmflow == '') {
        setTimeout(() => {
          this.formfieldrefreshdata = { field: 'removefromcontrol', value: { name: 'tmflow_condition' } };
        }, 200);
      }
      if (
        this.worksheetdata.user_details.HPS_Representative != null &&
        typeof this.worksheetdata.user_details.HPS_Representative !=
        'undefined' &&
        this.worksheetdata.user_details.HPS_Representative != ''
      ) {
        this.formdata.fields[0].value =
          this.worksheetdata.user_details.HPS_Representative.name;
      }
    }
  }
  listenFormFieldChanges(val: any) {
    // console.log(val);
    // console.log(
    //   this.worksheetdata,
    //   '  console.log(         this.myOutput.emit(val) );'
    // );
    let product_id_details = [];
    console.log(this.worksheetdata, 'this.worksheetdata.product_id');
    if (this.worksheetdata.product_id.length > 0) {
      for (
        let index = 0;
        index < this.worksheetdata.product_id.length;
        index++
      ) {
        product_id_details.push(this.worksheetdata.product_id[index].val);
      }
    } else {
      product_id_details = [
        '604aff3691b7c80008ca11a8',
        '604a0b6e40962e00088f0d79',
      ];
    }

    if (
      val.field &&
      val.field == 'fromsubmit' &&
      this.worksheetdata &&
      this.worksheetdata != null &&
      typeof this.worksheetdata != 'undefined' &&
      this.worksheetdata != ''
    ) {
      let form_data = {
        worksheet_data: val.fromval,
        worksheet_val: 1,
        user_id: this.worksheetdata.user_details._id,
        product_id: product_id_details,
        tags: [
          {
            satatus: 'work sheet',
            updated_datetime: Math.round(new Date().getTime()),
          },
          {
            satatus: 'New tag',
            updated_datetime: Math.round(new Date().getTime()),
          },
        ],
        token: this.cookies.get('jwtToken'),
        secret: this.cookies.get('secret'),
      };
      // this.progressSpinner.loading = true;
      console.log(val.fromval);

      // return;
      this.apiservice
        .customRequest(form_data, 'api/work-sheet', environment['api_url'])
        .subscribe((response: any) => {
          if (response.status == 'success') {
            console.log(response.message);

            // this.formdata.successmessage = response.mmessage;
            // this._snackBar.open(response.mmessage: String, 'ok');
            this.openSnackBar(response.message, 'ok');
            this.myOutput.emit({ action: response.message });
            if (response.status1 == 'add') {
              // this.router.navigateByUrl(
              //   '/calendar-management/manage-appointment/discovery/book-appointment/' +
              //     this.worksheetdata._id +
              //     '/' +
              //     response.res._id +
              //     '/' +
              //     this.worksheetdata.user_details._id +
              //     '/' +
              //     this.worksheetdata.user_details_email +
              //     '/' +
              //     this.worksheetdata.user_details_type
              // );
            }
          } else {
            // this.openSnackBar('Something went wrong. Please try again.', null);
          }
        });
    }
  }
  editdata_info(val) {
    console.log(val);
    this.flag = true;
    this.formdata = {
      successmessage: 'Added Successfully !!',
      // redirectpath: 'admin/list',
      submitactive: true, // optional, default true
      submittext: 'Update',
      resettext: 'Reset',
      canceltext: 'Print',
      apiUrl: this.apiservice.api_url,
      // endpoint: 'api/addquestionnaire', //
      jwttoken: '',
      // cancelroute: 'admin/list',
      fields: [
        {
          heading: 'GENERAL INFORMATION',
          label: 'Name of Practice',
          name: 'name_of_practice',
          type: 'text',
          value: val.name_of_practice,
          validations: [],
        },
        {
          label: 'Address',
          name: 'address',
          value: val.address,
          type: 'textarea',
          validations: [
            // { rule: 'required', message: 'Zip is Required' },
            // {
            //   rule: 'maxLength',
            //   value: 5,
            //   message: 'Please enter the valid Zip ID',
            // },
          ],
        },

        {
          label: 'City',
          name: 'city',
          type: 'text',

          value: val.city,

          validations: [],
        },
        {
          label: 'State',
          name: 'state',
          type: 'text',

          value: val.state,

          validations: [],
        },
        {
          label: 'Zip',
          name: 'zip',
          type: 'number',

          value: val.zip,

          validations: [],
        },
        {
          label: 'Office Phone',
          name: 'officephone',
          type: 'number',
          value: val.officephone,
          validations: [],
        },

        {
          label: 'Office Fax',
          name: 'officefax',
          type: 'number',
          value: val.officefax,
          validations: [],
        },
        {
          label: 'Contact Person',
          name: 'title',
          type: 'text',
          value: val.title,
          validations: [],
        },
        {
          label: 'Direct Line',
          name: 'extension_one',
          type: 'text',
          value: val.extension_one,
          validations: [],
        },
        {
          label: 'Email',
          name: 'email_one',
          type: 'email',
          hint: '',
          value: val.email_one,

          validations: [],
        },
        {
          heading: "PRACTITIONER'S INFORMATION",
          label: 'Doctor/Practice is : ',
          name: 'Doc_pracIs',
          // hint: 'In months',
          // prefix:"*",
          type: 'checkbox',
          multiple: true,
          val: [
            { key: 0, val: 'Family Medicine' },
            { key: 1, val: 'Neurology' },
            { key: 2, val: 'D.O Doctor of Osteopathy' },
            { key: 3, val: 'General Practice' },
            { key: 4, val: 'Internal Medicine' },
            { key: 5, val: 'Pain Mgnt (Integrated Practice)' },
            { key: 6, val: 'Primary Care' },
            { key: 7, val: 'Endocrinology' },
            { key: 8, val: 'Integrated Specialty' },
            { key: 9, val: 'Cardiology' },
            { key: 10, val: 'Physical Therapy' },
            { key: 11, val: 'Chiropractic' },
            { key: 12, val: 'Nurse Practitioner' },
          ],
          value: this.editdata.Doc_pracIs,
          validations: [{ rule: 'required', message: "Must select any of them." }],
        },
        {
          heading: "Pain Management",
          label: 'Please check all disease states currently treated in the practice for pain and inflammation.',
          name: 'pain_and_inflammation',
          // hint: 'In months',
          // prefix:"*",
          type: 'checkbox',
          multiple: true,
          val: [
            { key: 0, val: 'AIDS Syndrome' },
            { key: 1, val: 'Anterior Tibial Compartment' },
            { key: 2, val: 'Arthritis - All Body Parts' },
            { key: 3, val: 'Asthma' },
            { key: 4, val: 'Atrophy' },
            { key: 5, val: 'Bechterew’s Disease' },
            { key: 6, val: 'Bells Palsy (in total denervation)' },
            { key: 7, val: 'Bell’s Palsy (Motor nerve lesions' },
            { key: 8, val: 'Bell’s Palsy (partial denervation, neuropraxia, or after onset of reinnervation)' },
            { key: 9, val: 'Bursitis - All Body Parts' },
            { key: 10, val: 'Carpal Tunnel Syndrome' },
            { key: 11, val: 'Cervical Compression Syndrome' },
            { key: 12, val: 'Constipation' },
            { key: 13, val: 'Costoclavicular Syndrome' },
            { key: 14, val: 'Chrohn’s Disease' },
            { key: 15, val: 'Crush Syndrome' },
            { key: 16, val: 'Cubital Tunnel Syndrome' },
            { key: 17, val: 'Cystitis' },
            { key: 18, val: 'Decubitis & Pressure Ulceration’s/Wounds' },
            { key: 19, val: 'Degenerative Joint Disease' },
            { key: 20, val: 'DeQuervain’s Tenosynovitis' },
            { key: 21, val: 'Disorders of the Metacarpus' },
            { key: 22, val: 'Duchenne’s Syndrome' },
            { key: 23, val: 'Endometriosis' },
            { key: 24, val: 'Epicondylitis (Lateral & Media)' },
            { key: 25, val: 'Facial Neuralgia' },
            { key: 26, val: 'Fibromyalgia' },
            { key: 27, val: 'Gout' },
            { key: 28, val: 'Headache' },
            { key: 29, val: 'Herpes Zoster' },
            { key: 30, val: 'Hyperabduction Syndrome' },
            { key: 31, val: 'Ideopathic Polyneuritis (acute)' },
            { key: 32, val: 'Inflammatory Bowel Diseasee' },
            { key: 33, val: 'Interdigital Neuralgia of the Foot' },
            { key: 34, val: 'Intermittent Claudication' },
            { key: 35, val: 'Knee Surgery' },
            { key: 36, val: 'Lateral Femoral Cutaneous Nerve Syndrome' },
            { key: 37, val: 'Lumbago' },
            { key: 38, val: 'Lyme disease' },
            { key: 39, val: 'Median Nerve Injury or Palsy' },
            { key: 40, val: 'Muscle Strengthening' },
            { key: 41, val: 'Myofascitis' },
            { key: 42, val: 'Neuropraxia' },
            { key: 43, val: 'Osgood Schlatter Disease' },
            { key: 44, val: 'Osteoporosis' },
            { key: 45, val: 'Painful Arc Syndrome' },
            { key: 46, val: 'Parkinson’s Disease' },
            { key: 47, val: 'Piriformis Syndrome' },
            { key: 48, val: 'Polyneuropathies' },
            { key: 49, val: 'Post burn Pain' },
            { key: 50, val: 'Postphiebitic Syndrome' },
            { key: 51, val: 'Premenstrual Syndrome' },
            { key: 52, val: 'Prolapse or Herniated Disc' },
            { key: 53, val: 'Radial Nerve Injury or Palsy' },
            { key: 54, val: 'Radiation Syndrome (Acute)' },
            { key: 55, val: 'Raynaud’s Disease' },
            { key: 56, val: 'Reflex Sympathic Dystrophy' },
            { key: 57, val: 'Rheumatoid Disease' },
            { key: 58, val: 'Scalenus Syndrome' },
            { key: 59, val: 'Sciatica' },
            { key: 60, val: 'Scapulocostal Syndrome' },
            { key: 61, val: 'Sinusitis' },
            { key: 62, val: 'Stroke Syndrome' },
            { key: 63, val: 'Tarsal Tunnel Syndrome' },
            { key: 64, val: 'Temporal Mandibular Joint Disorder' },
            { key: 65, val: 'Tendinitis-Tenosynovitus' },
            { key: 66, val: 'Thoracic Outlet Syndrome' },
            { key: 67, val: 'Toothache' },
            { key: 68, val: 'Trigeminal Neuralgia' },
            { key: 69, val: 'UlnarNerve Injury or Palsy' },
            { key: 70, val: 'Pain - All Body Parts' }
          ],
          value: this.editdata.pain_and_inflammation,
          validations: [{ rule: 'required', message: "Must select any of them." }],
        },
        {
          heading: "Conditions treated by TM-Flow device:",
          label: '',
          name: 'tmflow_condition',
          // hint: 'In months',
          // prefix:"*",
          type: 'checkbox',
          multiple: true,
          val: [
            { key: 0, val: 'Heart Attack' },
            { key: 1, val: 'Heart Rate Variability Issues' },
            { key: 2, val: 'Cardiac Output to Body Surface Area' },
            { key: 3, val: 'Abnormal LDL Cholesterol levels' },
            { key: 4, val: 'Insulin Resistance' },
            { key: 5, val: 'Diabetes with Complications' },
            { key: 6, val: 'Physical and Mental Stress, Anxiety and Fatigue' },
            { key: 7, val: 'Stroke' },
            { key: 8, val: 'Angiotensin Activity' },
            { key: 9, val: 'Atherosclerosis' },
            { key: 10, val: 'Peripheral Vascular Disease' },
            { key: 11, val: 'Hyperhidrosis and Hypohidrosis' },
            { key: 12, val: 'Impaired Glucose Intolerance' },
            { key: 13, val: 'Nerve Damage' },
            { key: 14, val: 'Obesity' },
            { key: 15, val: 'Amyloid Neuropathy' },
            { key: 16, val: 'Asthma' },
            { key: 17, val: 'Cardiovascular Associated Diseases' },
            { key: 18, val: 'Idiopathic Neuropathy' },
            { key: 19, val: 'Multiple System Dystrophy' },
            { key: 20, val: 'Post- Partum Dysfunctions' },
            { key: 21, val: 'Psychological Conditions' },
            { key: 22, val: 'Pure Autonomic Failure' },
            { key: 23, val: 'Sjogren’s Syndrome' },
          ],
          value: this.editdata.tmflow_condition,
          validations: [{ rule: 'required', message: "Must select any of them." }],
        },
        {
          heading: 'Total Practitioner(s) In Your Office:'
        },
        {
          // heading: "Total Practitioner(s) In Your Office:",
          label: "MD's/DO's",
          name: "no_of_md_or_do",
          type: "number",
          value: val.no_of_md_or_do,
          validations: [],
        },
        {
          label: "NP's",
          name: 'no_of_np',
          type: 'number',
          value: val.no_of_np,
          validations: [],
        },
        {
          label: "PA's",
          name: 'no_of_pa',
          type: 'number',
          value: val.no_of_pa,
          validations: [],
        },
        {
          label: "Other(s)",
          name: 'no_of_others',
          type: 'number',
          value: val.no_of_others,
          validations: [],
        },
        {
          label: "Organizational Name(if any):",
          name: 'organizational_name',
          type: 'text',
          value: val.organizational_name,
          validations: [],
        },
        {
          label: "NPI#:",
          name: 'npi_one',
          type: 'text',
          value: val.npi_one,
          validations: [],
        },
        {
          label: "Specialty/Taxonomy:",
          name: 'specialty_taxonomy_one',
          type: 'text',
          value: val.specialty_taxonomy_one,
          validations: [],
        },
        {
          label: "Total Patients Seen Per Day:",
          name: 'seen_per_day_one',
          type: 'text',
          value: val.seen_per_day_one,
          validations: [],
        },
        {
          label: "Primary Practitioner:",
          name: 'primary_practitioner',
          type: 'text',
          value: val.primary_practitioner,
          validations: [],
        },
        {
          label: "NPI#:",
          name: 'npi_two',
          type: 'text',
          value: val.npi_two,
          validations: [],
        },
        {
          label: "Specialty/Taxonomy:",
          name: 'specialty_taxonomy_two',
          type: 'text',
          value: val.specialty_taxonomy_two,
          validations: [],
        },
        {
          label: "Total Patients Seen Per Day:",
          name: 'seen_per_day_two',
          type: 'text',
          value: val.seen_per_day_two,
          validations: [],
        },
        {
          label: "Practitioner",
          name: 'practitioner_one',
          type: 'text',
          value: val.practitioner_one,
          validations: [],
        },
        {
          label: "NPI#:",
          name: 'npi_three',
          type: 'text',
          value: val.npi_three,
          validations: [],
        },
        {
          label: "Specialty/Taxonomy:",
          name: 'specialty_taxonomy_three',
          type: 'text',
          value: val.specialty_taxonomy_three,
          validations: [],
        },
        {
          label: "Total Patients Seen Per Day:",
          name: 'seen_per_day_three',
          type: 'text',
          value: val.seen_per_day_three,
          validations: [],
        },
        {
          label: "Practitioner",
          name: 'practitioner_two',
          type: 'text',
          value: val.practitioner_two,
          validations: [],
        },
        {
          label: "NPI#:",
          name: 'npi_four',
          type: 'text',
          value: val.npi_four,
          validations: [],
        },
        {
          label: "Specialty/Taxonomy:",
          name: 'specialty_taxonomy_four',
          type: 'text',
          value: val.specialty_taxonomy_four,
          validations: [],
        },
        {
          label: "Total Patients Seen Per Day:",
          name: 'seen_per_day_four',
          type: 'text',
          value: val.seen_per_day_four,
          validations: [],
        },
        {
          label: "NP or PA (circle one):",
          name: 'np_or_pa',
          type: 'text',
          value: val.np_or_pa,
          validations: [],
        },
        {
          label: "NPI#:",
          name: 'npi_five',
          type: 'text',
          value: val.npi_five,
          validations: [],
        },
        {
          label: "Specialty/Taxonomy:",
          name: 'specialty_taxonomy_five',
          type: 'text',
          value: val.specialty_taxonomy_five,
          validations: [],
        },
        {
          label: "Total Patients Seen Per Day:",
          name: 'seen_per_day_five',
          type: 'text',
          value: val.seen_per_day_five,
          validations: [],
        },
        {
          heading: 'PATIENT POPULATION',
          label:
            'What is your total patient count?',
          name: 'total_patient_count',
          // hint: 'In months',
          type: 'number',
          value: val.total_patient_count,
          validations: []
        },
        {
          label:
            'Estimate what percentage are in the following category',
          name: 'estimate_percentage_category',
          // hint: 'In months',
          type: 'radio',
          labelPosition: 'before',
          val: [
            { key: 0, val: 'Under 16' },
            { key: 1, val: 'Between 17 to 70' },
            { key: 2, val: 'Over 70' },
          ],
          value: val.estimate_percentage_category,
          validations: []
        },
        {
          heading: 'BILLING AND INSURANCE',
          label:
            'How is your billing set up',
          name: 'billing_setup',
          // hint: 'In months',
          type: 'radio',
          labelPosition: 'before',
          val: [
            { key: 0, val: 'Inhouse billing' },
            { key: 1, val: 'Third party' },
          ],
          value: val.billing_setup,
          validations: []
        },
        {
          label: '% private insurance Patients',
          name: 'percent_of_private_insurance_patients',
          type: 'number',
          value: val.percent_of_private_insurance_patients,
          validations: [],
        },
        {
          label: '% of Madicare Patients',
          name: 'percent_of_madicare_patients',
          type: 'number',
          value: val.percent_of_madicare_patients,
          validations: [],
        },
        {
          label: '% of Madicaid Patients',
          name: 'percent_of_madicaid_patients',
          type: 'number',
          value: val.percent_of_madicaid_patients,
          validations: [],
        },
        {
          label: '% of Cash Patients',
          name: 'percent_of_cash_patients',
          type: 'number',
          value: val.percent_of_cash_patients,
          validations: [],
        },
        {
          label: 'LIST OF INSURANCE YOU PRATICIPATE WITH:',
          name: 'insurance',
          type: 'text',
          value: val.insurance,
          validations: [],
        },
        {
          label: 'REP NAME',
          name: 'rep_name',
          type: 'text',
          value: val.rep_name,
          validations: [],
        },
        {
          label: 'PHONE NUMBER',
          name: 'phone_number',
          type: 'text',
          value: val.phone_number,
          validations: [],
        },
        {
          label: ' ',
          name: 'leads',
          type: 'hidden',
          value: this.activatedroute.snapshot.params._id,
          validations: [],
        },
      ],
    };
    let isbioenergatics = '';
    let tmflow = '';
    for (const key in this.worksheetdata.user_details.products) {
      if (this.worksheetdata.user_details.products[key] == '612c883f118c110009f02820') {
        isbioenergatics = this.worksheetdata.user_details.products[key];
      }
      if (this.worksheetdata.user_details.products[key] == '604a0b6e40962e00088f0d79') {
        tmflow = this.worksheetdata.user_details.products[key];
      }
    }
    console.log(isbioenergatics);

    if (isbioenergatics == '') {
      setTimeout(() => {
        this.formfieldrefreshdata = { field: 'removefromcontrol', value: { name: 'pain_and_inflammation' } };
      }, 50);
      this.formdata.fields[10].val = [
        { key: 0, val: 'Family Medicine' },
        { key: 1, val: 'Neurology' },
        { key: 2, val: 'D.O Doctor of Osteopathy' },
        { key: 3, val: 'General Practice' },
        { key: 4, val: 'Internal Medicine' },
        { key: 5, val: 'Pain Mgnt (Integrated Practice)' },
        { key: 6, val: 'Primary Care' },
        { key: 7, val: 'Endocrinology' },
        { key: 8, val: 'Integrated Specialty' },
        { key: 9, val: 'Cardiology' },
      ];
    }
    if (tmflow == '') {
      setTimeout(() => {
        this.formfieldrefreshdata = { field: 'removefromcontrol', value: { name: 'tmflow_condition' } };
      }, 200);
    }
  }

  openSnackBar(message: string, action: string) {
    this._snackBar.open(message, action, {
      duration: 2000,
    });
  }
}
