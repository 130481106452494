import { Component, OnInit } from '@angular/core';
import { ApiService } from '../../services/api.service';
import { DetectdomainService } from "src/app/services/detectdomain.service";
import { ObservableService } from '../../services/observable.service';


@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.css']
})
export class ResetPasswordComponent implements OnInit {
  public hostName: any;
  public medigradeflag: any=false;
  public loginlogo: string = '';

  public fromTitleName: any = 'Reset From';

  public serverUrl: any = this.apiservice.api_url;
  public addEndpoint: any = {
    endpoint: 'api/resetpassword',
    source: 'users',
    redirect_url : '/login'
  };
  constructor(public apiservice: ApiService, public observableservice: ObservableService,       
    public domainService: DetectdomainService) {

      this.hostName = this.domainService.getdomain();
      if (this.hostName == "medigrade") {
        this.medigradeflag=true  
      }

     }

  ngOnInit() {
  }

}
