import { Injectable } from '@angular/core';
import {
  ActivatedRoute,
  ActivatedRouteSnapshot,
  NavigationEnd,
  Resolve,
  Router,
  RouterEvent,
  RouterStateSnapshot,
} from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { Observable } from 'rxjs';
import { ApiService } from './api.service';
import { UserserviceService } from './userservice.service';
import { LoaderService } from './loader.service';
import { filter } from 'rxjs/operators';
import { HttpObservableService } from './http-observable.service';
export interface EndpointComponent {
  endpoint: string;
}
@Injectable({
  providedIn: 'root',
})
export class ResolveService implements Resolve<any> {
  public userid: any;
  public secret: any;
  public token: any;
  public userType: any;
  public userId: any;

  private previousUrl: string;
  private currentUrl: string;
  constructor(
    public apiservice: ApiService,
    public cookie: CookieService,
    public activatedroute: ActivatedRoute,
    public router: Router,
    public userService: UserserviceService,
    private loaderService: LoaderService,
    public observable: HttpObservableService
  ) {

    this.currentUrl = this.router.url;
    this.previousUrl = null;

    this.router.events
      .pipe(filter((event: RouterEvent) => event instanceof NavigationEnd))
      .subscribe((event: NavigationEnd) => {
        this.previousUrl = this.currentUrl;
        this.currentUrl = event.urlAfterRedirects;
        console.log('prev: ', this.previousUrl);
        console.log('curr: ', this.currentUrl);

        this.cookie.set('currentUrl', this.currentUrl, undefined, '/');
        console.log(
          this.activatedroute.children[0].snapshot.routeConfig.path,
          'this.activatedroute.snapshot.routeConfig.path'
        );
      });

    if (
      !this.cookie.check('secret') ||
      !this.cookie.check('jwtToken') ||
      (!this.cookie.check('userid') &&
        this.activatedroute.children[0].snapshot.routeConfig.path ==
        'contract/:randomstring')
    ) {
      // return true;
    }

    if (
      this.cookie.check('secret') &&
      this.cookie.check('jwtToken') &&
      this.cookie.check('userid')
    ) {
      this.cookie.get('login_time');
      console.log(this.cookie.get('login_time'), '+++++++++++++++');
      this.userid = JSON.parse(this.cookie.get('userid'));
      this.secret = this.cookie.get('secret');
      this.token = this.cookie.get('jwtToken');
      console.log('token-------------------', this.secret);
    }
    if (this.cookie.get('type') != null && this.cookie.get('type') != '') {
      this.userType = JSON.parse(this.cookie.get('type'));
    }

    if (this.cookie.get('userid') != null && this.cookie.get('userid') != '') {
      this.userId = JSON.parse(this.cookie.get('userid'));
    }

    if (!this.cookie.check('signature') && this.cookie.check('randomstring')) {
      // console.log('++++++++ login ====', '/contract/' + JSON.parse(this.cookie.get('randomstring')))
      this.router.navigateByUrl(
        '/contract/' + JSON.parse(this.cookie.get('randomstring'))
      );
    }
    if(this.currentUrl === '/technological-consultant/dashboard' && JSON.parse(this.cookie.get('type')) === 'technological-consultant'){
      let send_data = {
            data:{
              signup_process: 'find',
            }
          }
          this.apiservice.getDataWithoutToken(send_data,'api10/change-signup-process').subscribe((res:any)=>{
              if(res.result[0].signup_process == 'Off'){   
                // console.log(this.cookie.check('recent_tech'));
                if(this.cookie.check('recent_tech')){
                console.log('++++++');
                  this.router.navigateByUrl('/block_onbordingcall');
                }
              }
          })
    }
    if (
      !this.cookie.check('event_type') &&
      this.cookie.check('signature') &&
      (this.userType === 'sales-person' ||
        this.userType === 'technological-consultant' ||
        this.userType === 'distributor')
    ) {
      // console.error('resolve*****************')
      this.router.navigateByUrl(
        '/calendar-management/manage-appointment-after-sign/on-boarding/book-appointment'
      );
    }

    if (
      this.cookie.check('signature') &&
      this.cookie.check('event_type') &&
      this.activatedroute.snapshot.routeConfig != null &&
      this.activatedroute.snapshot.routeConfig.path ==
      '/calendar-management/manage-appointment-after-sign/on-boarding/book-appointment'
    ) {
      this.router.navigateByUrl(this.currentUrl);
    }
  }

  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<any> | Promise<any> | any {
    const _id = route.params._id;
    const endpoint = route.data.link;
    const source = route.data.source;
    const condition = route.data.condition;
    let requestData: any = route.data.requestcondition;
    requestData = JSON.stringify(requestData);
    requestData = JSON.parse(requestData);

    let alldata = JSON.stringify(route.data);
    route.data = JSON.parse(alldata);

    console.log(requestData, 'routerservice');

    if (requestData.id != null && requestData.id != '') {
      // console.log(route.params._id)
      route.data.requestcondition.id = route.params._id;
      requestData.id = route.params._id;
    }
    // if (requestData.data._id != null && requestData.data._id != '') {
    //   // console.log(route.params._id)
    //   route.data.requestcondition.data._id = route.params.id;
    //   requestData.data._id = route.params.id;
    // }
    if (requestData._id != null && requestData._id != '') {
      // console.log(route.params._id)
      route.data.requestcondition._id = route.params._id;
      requestData._id = route.params._id;
    }
    if (requestData.template_id != null && requestData.template_id != '' && requestData.template_id == 'template_id') {
      // console.log(route.params._id)
      route.data.requestcondition.template_id = route.params.template_id;
      requestData.template_id = route.params.template_id;
    }
    if (
      (route.routeConfig.path === 'user-details/:type/:_id' ||
        route.routeConfig.path === 'user-details/:type/:_id/:flag') &&
      route.params.type === 'lead'
    ) {
      route.data.endpoint = 'api/getoneleadfolderview';
    }

    if (
      requestData.uid != null &&
      requestData.uid != '' &&
      this.cookie.check('userid')
    ) {
      route.data.requestcondition.id = JSON.parse(this.cookie.get('userid'));
      requestData.id = JSON.parse(this.cookie.get('userid'));
      delete route.data.requestcondition.uid;
      delete requestData.uid;
      // console.log('===')
    }
    if (
      requestData.pid != null &&
      requestData.pid != '' &&
      this.cookie.check('parentid') &&
      this.cookie.get('parentid') != 'undefined'
    ) {
      route.data.requestcondition.parentid = JSON.parse(
        this.cookie.get('parentid')
      );
      requestData.parentid = JSON.parse(this.cookie.get('parentid'));
      delete requestData.pid;
      delete route.data.requestcondition.pid;
      // console.log('===+++++',route.data.requestcondition)
    }
    if (
      requestData.condition != null &&
      requestData.condition != '' &&
      requestData.condition.user_id != null &&
      typeof requestData.condition.user_id != 'undefined'
    ) {
      requestData.condition.user_id = JSON.parse(this.cookie.get('userid'));
    }

    if (
      requestData.condition != null &&
      requestData.condition != '' &&
      requestData.condition.id != null &&
      typeof requestData.condition.id != 'undefined'
    ) {
      route.data.requestcondition.condition._id = _id;
      delete route.data.requestcondition.condition.id;
    }
    if (
      requestData.condition != null &&
      requestData.condition != '' &&
      requestData.condition.leadid != null &&
      typeof requestData.condition.leadid != 'undefined'
    ) {
      requestData.condition.leadid = _id;
      delete requestData.condition._id;
    }
    if (
      this.cookie.check('type') &&
      JSON.parse(this.cookie.get('type')) === 'contract-manager' &&
      route.routeConfig.path === 'contract-request/list'
    ) {
      requestData.searchcondition.products = JSON.parse(
        this.cookie.get('products')
      );
      delete requestData.searchcondition.user_id;
      console.log(
        'fromservice-------------------___________________________-',
        requestData.searchcondition
      );
    }

    if (
      requestData.condition != null &&
      requestData.condition != '' &&
      requestData.condition.products != null &&
      typeof requestData.condition.products != 'undefined'
    ) {
      requestData.condition.products = _id;
      delete requestData.condition._id;
    }
    if (requestData.randomstring != null && requestData.randomstring != '') {
      requestData.randomstring = route.params.randomstring;
    }

    if (
      requestData.userid != null &&
      requestData.userid != '' &&
      requestData.userid === 'userid' &&
      this.cookie.check('userid')
    ) {
      route.data.requestcondition.userid = JSON.parse(
        this.cookie.get('userid')
      );
    }

    if (
      requestData.searchcondition != null &&
      typeof requestData.searchcondition != undefined &&
      (requestData.searchcondition._id === 'id' ||
        requestData.searchcondition._id != route.params._id)
    ) {
      route.data.requestcondition.searchcondition._id = route.params._id;
    }
    if (
      requestData.condition != null &&
      typeof requestData.condition != undefined &&
      requestData.condition.parentid === 'parentid'
    ) {
      console.log('my name is Himadri');

      requestData.condition.parentid = JSON.parse(this.cookie.get('userid'));
    }
    if (
      requestData.searchcondition != null &&
      typeof requestData.searchcondition != undefined &&
      (requestData.searchcondition.product_id === '_prodid' ||
        requestData.searchcondition.product_id != route.params._prodid)
    ) {
      requestData.searchcondition.product_id = route.params._prodid;
      delete requestData.condition._prodid;
    }

    if (
      requestData.searchcondition != null &&
      typeof requestData.searchcondition != undefined &&
      requestData.searchcondition.user_id === 'user_id'
    ) {
      requestData.searchcondition.user_id = JSON.parse(
        this.cookie.get('userid')
      );
    }
    if (
      requestData.searchcondition != null &&
      typeof requestData.searchcondition != undefined &&
      requestData.searchcondition.tech_id_s === 'tech_id'
    ) {
      requestData.searchcondition.tech_id_s = JSON.parse(
        this.cookie.get('userid')
      );
    }
    if (
      requestData.searchcondition != null &&
      typeof requestData.searchcondition != undefined &&
      requestData.searchcondition.parentid === 'parentid'
    ) {
      requestData.searchcondition.parentid = JSON.parse(
        this.cookie.get('userid')
      );
    }
    if (
      requestData.searchcondition != null &&
      typeof requestData.searchcondition != undefined &&
      requestData.searchcondition.managecommissionid === 'managecommissionid'
    ) {
      if (JSON.parse(this.cookie.get('type')) === 'technological-consultant') {
        requestData.searchcondition['techid'] = JSON.parse(
          this.cookie.get('userid')
        );
      }
      if (JSON.parse(this.cookie.get('type')) === 'distributor') {
        requestData.searchcondition['parentid'] = JSON.parse(
          this.cookie.get('userid')
        );
      }
      delete requestData.searchcondition.managecommissionid;
    }
    // if (
    //   requestData.searchcondition != null &&
    //   typeof requestData.searchcondition != undefined &&
    //   requestData.searchcondition.user_id === 'pruserid'
    // ) {
    //   requestData.searchcondition.user_id = route.params.user_id;
    //   console.log('XXXXXXXXXXXXXXXXXXXXXXXXXXXXXXxx')
    // }

    if (
      requestData.searchcondition != null &&
      typeof requestData.searchcondition != undefined &&
      requestData.searchcondition.type === 'type'
    ) {
      requestData.searchcondition.type = JSON.parse(this.cookie.get('type'));
    }
    if (
      requestData.searchcondition != null &&
      typeof requestData.searchcondition !== undefined &&
      requestData.searchcondition.techordistributor == 'techordistributor'
    ) {
      if (JSON.parse(this.cookie.get('type')) == 'technological-consultant') {
        requestData.searchcondition.tech_id = JSON.parse(
          this.cookie.get('userid')
        );
      }
      if (JSON.parse(this.cookie.get('type')) == 'distributor') {
        requestData.searchcondition.distributor_id = JSON.parse(
          this.cookie.get('userid')
        );
      }
      delete requestData.searchcondition.techordistributor;
      // requestData.searchcondition.type = JSON.parse(this.cookie.get('type'));
    }
    if (
      requestData.searchcondition != null &&
      typeof requestData.searchcondition != undefined &&
      requestData.searchcondition.products === 'products' &&
      this.cookie.check('products')
    ) {
      console.log(requestData);

      requestData.searchcondition.products = JSON.parse(
        this.cookie.get('products')
      );
    }

    /******added by soham *********/

    if (
      requestData.searchcondition != null &&
      typeof requestData.searchcondition != undefined &&
      requestData.searchcondition.user_role === 'user_role'
    ) {
      requestData.searchcondition.user_role = JSON.parse(
        this.cookie.get('type')
      );
    }
    if (
      requestData.searchcondition != null &&
      typeof requestData.searchcondition != undefined &&
      requestData.searchcondition._id === 'commission_id'
    ) {
      requestData.searchcondition._id = route.params._id;
    }
    if (route.routeConfig.path === 'list/:type') {
      requestData.searchcondition = { type: { $eq: route.params.type } };
    }

    // if (
    //   requestData.secret != null &&
    //   requestData.secret != '' &&
    //   typeof requestData.secret != 'undefined' &&
    //   requestData.token != 'undefined' &&
    //   requestData.token != null &&
    //   requestData.token != '' &&
    //   this.token != ''
    // ) {
    if (this.cookie.check('userid') && requestData && requestData.id === '') {
      requestData.condition._id = JSON.parse(
        this.cookie.get('userid')
      ); /* recent */
      requestData.id = JSON.parse(this.cookie.get('userid')); /* recent */
    }
    requestData.secret = this.cookie.get('secret');
    requestData.token = this.cookie.get('jwtToken');
    if (
      route.routeConfig.path === 'contract-request/list' &&
      this.cookie.get('type') &&
      JSON.parse(this.cookie.get('type')) === 'admin'
    ) {
      // requestData.searchcondition = {};
      console.log(
        route.routeConfig.path,
        // 'lllllllllllllllllllllllllllllllllllllllll',
        requestData.searchcondition
      );
    }
    // }
    if (
      this.cookie.get('type') &&
      JSON.parse(this.cookie.get('type')) === 'contract-manager' &&
      route.url[0].path === 'dashboard'
    ) {
      console.log(route.url[0].path);
      requestData.secret = this.cookie.get('secret');
      console.log(requestData.secret, this.cookie.get('secret'), requestData);
    }
    // for associated_training and lession id
    if (route.url[0].path === 'training-center-beto-paredes') {
      //// console.log(requestData , 'prev')
      requestData.condition['associated_training'] =
        route.params.associated_training;
      requestData['user_id'] = JSON.parse(this.cookie.get('userid'));
      requestData['type'] = this.userType;
      //// console.log(route.params.associated_training,'ramu',this.userid)
      requestData['associated_training'] = route.params.associated_training;
      // // console.log(requestData , 'next')
    }
    // console.log(route);
    if (
      route.queryParams.request === 'true' ||
      route.queryParams.flag === 'view'
    ) {
      route.data.endpoint = 'api/getonenewcontract';
      requestData.lead_id = route.params._id;
      requestData.product_id = route.params.p_id;
      requestData.flag = 'credit';
      console.log(requestData);

      // route.data.requestcondition.lead_id = route.params._id;
    }
    // console.log(route);
    if (route.url[0].path == 'tmflowfive'&&route.queryParams.request === 'true' ) {
      route.data.endpoint = 'api/getonenewcontract';
      requestData.lead_id = route.params._id;
      requestData.product_id = route.params.p_id;
      requestData.flag = 'warrenty';
    }

    if (route.queryParams.flag === 'tmflowfive') {
      route.data.endpoint = 'api/getonenewcontract';
      requestData.lead_id = route.params._id;
      // requestData.product_id = route.params.p_id;
      requestData.flag = 'warrenty';

      console.log(requestData);

      // route.data.requestcondition.lead_id = route.params._id;
    }
    if (route.queryParams.flag === 'tmflowmedical') {
      route.data.endpoint = 'api/getonenewcontract';
      requestData.lead_id = route.params._id;
      // requestData.product_id = route.params.p_id;
      requestData.flag = 'tmflow_contract';

      console.log(requestData);

      // route.data.requestcondition.lead_id = route.params._id;
    }
    if (route.queryParams.flag === 'peceviewcontract') {
      route.data.endpoint = 'api/getonenewcontract';
      requestData.lead_id = route.params._id;
      // requestData.product_id = route.params.p_id;
      requestData.flag = 'Pece Contract';

      console.log(requestData);

      // route.data.requestcondition.lead_id = route.params._id;
    }
    if (route.queryParams.flag === 'peceresendcontract') {
      route.data.endpoint = 'api/getonenewcontract';
      requestData.lead_id = route.params._id;
      // requestData.product_id = route.params.p_id;
      requestData.flag = 'Pece Contract';

      console.log(requestData);

      // route.data.requestcondition.lead_id = route.params._id;
    }
    let product: any;
    if (
      route.params.associated_training != null &&
      route.params.associated_training != ''
    ) {
      // console.log(localStorage.getItem('productarray'));

      // console.log(JSON.parse(localStorage.getItem('productarray'))[0]);
      if (
        this.cookie.get('productarray') &&
        this.cookie.get('productarray') != null &&
        this.cookie.get('productarray') != ''
      ) {
        product = this.cookie.get('productarray');
        // console.log(product,'First');
        const products = product.split(',');
        console.log(products, 'second');
        requestData.condition.products = products;
      }

      console.log(route);
    }
    console.log(route.data.endpoint);

    if (route.data.endpoint === 'api/techconsultantdashboard') {
      if (
        this.cookie.get('productarray') &&
        this.cookie.get('productarray') != null &&
        this.cookie.get('productarray') != ''
      ) {
        product = this.cookie.get('productarray');
        // console.log(product,'First');
        let products = product.split(',');
        console.log(products, 'second');
        requestData.condition.products = products;
      }
    }

    // if (condition) {

    // }
    if (
      requestData.searchcondition != null &&
      requestData.searchcondition.parentid != undefined &&
      requestData.searchcondition.parentid != null &&
      requestData.searchcondition.parentid != '' &&
      requestData.searchcondition.parentid === 'userid' &&
      this.cookie.check('userid')
    ) {
      requestData.searchcondition.parentid = JSON.parse(
        this.cookie.get('userid')
      );
    }
    if (
      requestData.searchcondition != null &&
      requestData.searchcondition.bookinguser != undefined &&
      requestData.searchcondition.bookinguser != null &&
      requestData.searchcondition.bookinguser != '' &&
      requestData.searchcondition.bookinguser === 'userid' &&
      this.cookie.check('userid')
    ) {
      console.log(
        requestData.searchcondition.bookinguser,
        ' requestData.searchcondition.bookinguser'
      );

      requestData.searchcondition.bookinguser = JSON.parse(
        this.cookie.get('userid')
      );
    }

    if (
      requestData.condition != null &&
      requestData.condition != '' &&
      typeof requestData.condition != 'undefined'
    ) {
      requestData.condition = Object.assign(
        requestData.condition,
        route.params
      );
    }
    
    if (requestData && requestData.searchcondition && requestData.searchcondition.id) {
      requestData.searchcondition.id = JSON.parse(this.cookie.get('userid'));
    }
    if (requestData && requestData.searchcondition && requestData.searchcondition.user_id) {
      console.log("user id in resolve hitt!!")
      requestData.searchcondition.user_id = JSON.parse(this.cookie.get('userid'));
    }

    // console.log(route.routeConfig.path, '++++++__________+++++++++', requestData.searchcondition.distributor)
    if (
      this.cookie.get('type') &&
      JSON.parse(this.cookie.get('type')) === 'distributor' &&
      requestData.searchcondition != null &&
      typeof requestData.searchcondition != 'undefined' &&
      typeof requestData.searchcondition.distributor != 'undefined' &&
      requestData.searchcondition.distributor != null &&
      requestData.searchcondition.distributor != ''
    ) {
      requestData.searchcondition.distributor = JSON.parse(
        this.cookie.get('userid')
      );
    }
    if (
      route.routeConfig.path ==
      'training-center-beto-paredes/:associated_training'
    ) {
      delete requestData.condition._id;
      console.log(
        route.routeConfig.path,
        '++++++__________+++++++++',
        requestData.condition
      );
    }
    console.log("resolve endpoint",route.data.endpoint);
    console.log('resolve requestData==>',requestData);
    let start_time = new Date().getTime(); // this is use to store the time when endpoint hit
    let end_time; // this is use to store the time when endpoint hit completly
    let time_taken; // this is use to get the difference time for end point reponse
    // console.warn(start_time,'start');
    this.loaderService.isLoading.next(true);
    return new Promise((resolve) => {
      this.apiservice
        .customRequest(requestData, route.data.endpoint, route.data.api_url)
        .subscribe((apiobject) => {
          if (apiobject) {
            this.loaderService.isLoading.next(false);
            end_time = new Date().getTime(); // this is use to store the time when endpoint hit completly
            time_taken = end_time - start_time; // this is use to get the difference time for end point reponse
            // console.warn(end_time,'end');
            // console.warn(time_taken,'time taken');
            let logval: any = { route: route.data.endpoint, timetaken: time_taken, logtime: start_time, param: JSON.stringify(route.data.requestcondition) }; // in this object we set the endpoint name, the time taken by the end point to get the data,when the endpoint hit,the body of the endpoint
            // console.warn(logval,'logval');
            this.observable.sethttpData(logval); // this is use to set the data in observable
            return resolve(apiobject);
          } else {
            this.loaderService.isLoading.next(false);
            return true;
          }
        });
    });
  }
}
