import { HttpClient } from "@angular/common/http";
import { Component, Inject, OnInit } from "@angular/core";
import {
  MatBottomSheet,
  MatBottomSheetRef,
  MatDialog,
  MatDialogRef,
  MatSnackBar,
  MAT_BOTTOM_SHEET_DATA,
  MAT_DIALOG_DATA,
} from "@angular/material";
import { ActivatedRoute, Router } from "@angular/router";
// import { DialogData } from 'login-lib-influxiq/lib/sign-up/sign-up.component';
import { CookieService } from "ngx-cookie-service";
import { ApiService } from "src/app/services/api.service";
import { UserserviceService } from "src/app/services/userservice.service";

export interface DialogData {
  data: any;
  message: any;
  secret: any;
  token: any;
}

@Component({
  selector: "app-users",
  templateUrl: "./users.component.html",
  styleUrls: ["./users.component.css"],
})
export class UsersComponent implements OnInit {
  public type_check:any = '';
  distributorListdatatable: any = [];
  public updatetable: any = false;

  public status: any = [
    { val: 1, name: "Active" },
    { val: 0, name: "Inactive" },
  ];
  public refreshbtnflag: boolean;
  // use for status search
  statusarray: any = [
    { val: 1, name: "Active" },
    { val: 0, name: "Inactive" },
  ];

  public customlistenbutton: any = {
    flag: true,
    tooltipflag: true,
    buttons: [
      // {
      //   label: "ADD USER",
      //   type: "button",
      //   name: "add_user",
      //   tooltip: "Add User",
      //   classname: "add_adminBTN",
      // },
      // {
      //   label: "Refresh",
      //   type: 'button',
      //   name: 'refreshbtn',
      //   tooltip: "Refresh",
      //   classname: 'refreshbtn'
      // }
    ],
  };

  // for type search
  public typeval: any = [
    { val: "contract-manager", name: "Contract Manager" },
    { val: "closer", name: "Closer" },
    { val: "technological-consultant", name: "Associate Director" },
  ];

  // editrouting
  editroute: any = "";

  // modify header(key : modify header name)
  modifyheaderarray: any = {
    fullname: "Full Name",
    email: "Email",
    phone: "Contact Number",
    createdon_datetime: "Joined On",
    status: "Status",
    type_show: "User Type",
    lastlogin_datetime: "Last Login"
  };

  // use for table field skip
  distributorListdatatableskip: any = [
    "_id",
    "type",
    "password",
    "created_at",
    "type",
    "city",
    "gallery_image",
    "accesscode",
    "__v",
    "notes",
  ];

  // use for Table Detail Field Skip
  distributorListdatatabledetailskip: any = [
    "_id",
    "email_s",
    "name",
    "blogtitle",
    "type",
    "city",
    "__v",
    "fullname",
    "notes",
    "accesscode",
    "location_s",
    "address_search",
    "fullname_s",
    "zip_s",
    "createdon_datetime",
    "fulladdress",
    "notescount",
    "login_data",
    // 'lastlogin_datetime',
    "contactInFo",
    "related_info",
    "firstname_s",
    "status",
  ];

  // updateendpoint is use for data update endpoint
  updateendpoint = "api/userstatusupdate";

  // deleteendpoint is use for data delete endpoint
  deleteendpoint = "api/deleteuser";

  // this is a database collection name
  tablename = "users";

  // searchendpoint is use for data search endpoint
  searchendpoint = "";

  // use for click to another page routing path
  clicktoaddananotherpage = "";

  // date_search_endpoint is use for date search endpoint
  datesearchendpoint: any = "";

  // send basic limit data
  limitcond: any = {
    limit: 10,
    skip: 0,
  };

  // other data
  libdata: any = {
    basecondition: {
      type: { $in: ["contract-manager", "closer", "technological-consultant"] },
      parentid: this.userservice.userid,
    },
    detailview_override: [
      { key: "fullname", val: "Full Name" },
      { key: "location", val: "Location" },
      { key: "contactInfo", val: "Contact Info" },
      { key: "status", val: "Status" },
      // { key: 'login_details', val: 'Login Details' },
    ],
    tableheaders: [
      "fullname",
      "email",
      "phone",
      "createdon_datetime",
      "type_show",
      "status",
      "lastlogin_datetime",
    ],
    // searchBarFlagVal:true,
    // tableheaders name [key]
    // optional
    hidedeletebutton: true,
    notes: {
      label: "Add Notes",
      addendpoint: "api/addnotedata",
      deleteendpoint: "api/deletenotedata",
      listendpoint: "api/listnotedata",
      user: this.userservice.userid,
      currentuserfullname:
        this.userservice.firstname + " " + this.userservice.lastname,
      header: "fullname",
    },
    updateendpointmany: "api/userupdate",
    updateendpoint: "api/userstatusupdate",
    deleteendpointmany: "api/deleteuser",
    hidemultipleselectbutton: null,
    hidecounter: null,
    hideaction: null,
    hideviewbutton: true, // (hide view button)
    hidestatustogglebutton: true,
    hideeditbutton: true,
    // actioncolname: 'Actn',
    // not required,
    custombuttons: [
      {
        label: "Resend Welcome Mail",
        type: "action",
        datatype: "api",
        endpoint: "api/resendemail",
        otherparam: ["type", "_id"],
        headermessage: "Api Info",
        classname: "Resendmail",
        tooltip: "Resend Welcome Mail",
      },
      {
        label: " Send Reset Password Mail",
        type: "action",
        datatype: "api",
        endpoint: "api/forgetpassword",
        otherparam: ["email", "_id"],
        classname: "ResetPasswordMail",
        tooltip: "Send Reset Password Mail",
      },

      {
        label: "Login As User",
        type: "listner",
        id: "i1",
        classname: "LogMe",
        tooltip: "Login As User",
      },

      // {
      //   label: "View User Details",
      //   type: 'listner',
      //   id: 'i1',
      //   classname: 'viewuserdetails',
      //   tooltip: 'View User Details',
      // },
      // {
      //   label: "Edit User",
      //   type: "listner",
      //   id: "i1",
      //   classname: "userEdit",
      //   tooltip: "Edit User",
      // },

      {
        label: "View Contract",
        type: "listner",
        id: "i1",
        classname: "viewContract",
        tooltip: "View Contract",
        cond: "signaturestatus",
        condval: 1,
      },

      {
        label: "Folder View",
        route: "user-details/",
        type: "internallink",
        param: ["type", "_id"],
        tooltip: "Folder View",
        classname: "FolderView",
        cond: "type",
        condval: "technological-consultant",
      },
      {
        label: "Discovery Status",
        type: "listner",
        id: "updatestatus",
        tooltip: "Discovery Call Enable",
        classname: "UpdateStatus",
        cond: "type",
        condval: "technological-consultant",
      },
      {
        label: "Status Change",
        type: "listner",
        id: "statuschange",
        tooltip: "Status Change",
        classname: "StatusChange",
      },
      {
        label: "Edit User",
        type: "listner",
        id: "i1",
        classname: "userEdit",
        tooltip: "Edit User",
      },
      // {
      //   label: "Contract Manager",
      //   type: 'listner',
      //   id: 'a2',
      //   tooltip: 'Contract Manager',
      //   refreshdata: true,
      //   classname: 'isContractmanager'
      // }
      // {
      //   label: "Update Status",
      //   type: "listner",
      //   id: "updatestatus",
      //   tooltip: "Update Status",
      //   classname: "UpdateStatus",
      //   cond: 'type',
      //   condval: 'technological-consultant'
      // },
    ],
  };

  // send basic sort data
  sortdata: any = {
    type: "desc",
    field: "createdon_datetime",
    options: [
      "fullname",
      "email",
      "lastlogin_datetime",
      "status",
      "createdon_datetime",
    ],
  };

  // this is a database collection or view name
  // datesearchsource: any = 'admin_blog_list';
  // datacollection
  datacollection: any = "api/userlistview";
  // source count
  datesearchsourcecount: any = 0;

  searchsettings: any = {
    datesearch: [
      {
        startdatelabel: "Joined On Start Date",
        enddatelabel: "Joined On End Date",
        submit: "Search",
        field: "createdon_datetime",
      },
    ],
    selectsearch: [
      { label: "Search By Status", field: "status", values: this.status },
      {
        label: "Search By Type",
        field: "type_s",
        values: this.typeval,
        value: "",
      },
    ],
    textsearch: [
      // { label: 'Search By Name', field: 'fullname_s' },
      // { label: 'Search By Email', field: 'email_s' },
      // { label: 'Search By Location', field: 'location_s' },
    ], // this is use for  Autocomplete search
    search: [
      {
        label: "Search By Name",
        field: "fullname_s",
        values: "",
        serversearchdata: {
          endpoint:
            "api/exitsinglistname?id=" +
            JSON.parse(this.cookieservice.get("userid")),
        },
      },
      {
        label: "Search By Email",
        field: "email_s",
        values: "",
        serversearchdata: {
          endpoint: "api/exitsinglistemail?id=" +
            JSON.parse(this.cookieservice.get("userid"))
        },
      },
      {
        label: "Search By Location",
        field: "location_s",
        values: "",
        serversearchdata: {
          endpoint: "api/exitsinglistlocation?id=" +
            JSON.parse(this.cookieservice.get("userid"))
        },
      },
    ],
  };

  public userid: any;
  public userFullName: any;

  constructor(
    public router: Router,
    public Apiservice: ApiService,
    public http: HttpClient,
    public cookieservice: CookieService,
    public activatedRoute: ActivatedRoute,
    public userservice: UserserviceService,
    public bottomSheet: MatBottomSheet,
    public dialog: MatDialog
  ) {
    this.type_check = JSON.parse(this.cookieservice.get('type'));
    if(JSON.parse(this.cookieservice.get('type')) == 'distributor'){  //this is use for remove specific action button from distributor user list
      this.libdata.custombuttons = [
        {
          label: "View Contract",
          type: "listner",
          id: "i1",
          classname: "viewContract",
          tooltip: "View Contract",
          cond: "signaturestatus",
          condval: 1,
        },
  
        {
          label: "Folder View",
          route: "user-details/",
          type: "internallink",
          param: ["type", "_id"],
          tooltip: "Folder View",
          classname: "FolderView",
          cond: "type",
          condval: "technological-consultant",
        },
      ]
    }
    this.userid = this.userservice.userid;
    this.userFullName =
      this.userservice.firstname + " " + this.userservice.lastname; // user dtls ( cookieservice)
    const endpointc = "api/userlistview-count";
    const endpoint = "api/userlistview";
    const data: any = {
      // How many data showing in first page
      secret: this.userservice.secret,
      token: this.userservice.jwttoken,
      source: "",
      condition: {
        // condition for how much data show in one time
        limit: 10,
        skip: 0,
      },
      sort: {
        type: "desc", // data sort by decreasing order
        field: "createdon_datetime", // data sort by first name
      },
      searchcondition: {
        type: {
          $in: ["contract-manager", "closer", "technological-consultant"],
        },
        parentid: this.userservice.userid,
      },
    };

    this.Apiservice.getDataWithoutToken(data, endpointc).subscribe(
      (res: any) => {
        this.datesearchsourcecount = res.count; // sarch count  (How many data there)
      }
    );
  }

  ngOnInit() {
    this.activatedRoute.data.forEach((res: any) => {
      this.distributorListdatatable = res.resolvedata.results.res; // data store from backend
      // console.log(this.distributorListdatatable, 'madhumita khutia');

      const body = document.getElementsByTagName("body")[0];
      body.classList.add("distributorlistpage");
    });
  }

  onLiblistingButtonChange(val: any) {
    console.log(val, "+++++-----");

    if (
      val.action == "customlistenbutton" &&
      val.buttondata.name == "add_user"
    ) {
      // this.router.navigateByUrl('/admin/add');
      // console.log(val, 'valllll')
      const dialogRef = this.dialog.open(Addusermodal, {
        panelClass: "AddUserComponent",
        // height: '500px',
        // width: '500px',
        data: { val: val.data },
      });
      //       dialogRef.afterClosed().subscribe((result:any) => {
      // // console.log(result,'tttttttttttttttt')

      //       })
    }
  }

  // addusers() {
  //   const dialogRef = this.dialog.open(Addusermodal, {
  //     height: '500px',
  //     width: '500px',

  //   })
  // }

  listenLiblistingChange(val: any) {
    console.log("val of cookie check==>",val);
    // this.type_check = JSON.parse(this.cookieservice.get('type'));
    // if(JSON.parse(this.cookieservice.get('type')) != 'admin'){
      // console.log("admin type");
      if (
        val.custombuttonclick != null &&
        val.custombuttonclick.btninfo != null &&
        val.custombuttonclick.btninfo.label === "Login As User"
      ) {
        const dialogRef = this.dialog.open(LogmeComponent, {
          panelClass: "LogmeComponent",
  
          data: { val: val.custombuttonclick.data },
        });
        dialogRef.afterClosed().subscribe((result: any) => {
          // // console.log('The dialog was closed', result);
          if (result == "Yes") {
            let main_user = this.cookieservice.getAll();
            let secretdata = this.cookieservice.get("secret");
            sessionStorage.clear();
            localStorage.clear();
            this.cookieservice.deleteAll();
            this.cookieservice.deleteAll("/");
            setTimeout(() => {
              this.userservice.cookieset("main_user", JSON.stringify(main_user));
              this.userservice.cookieset(
                "type",
                JSON.stringify(val.custombuttonclick.data.type)
              );
              this.userservice.cookieset(
                "firstname",
                JSON.stringify(val.custombuttonclick.data.firstname)
              );
              this.userservice.cookieset(
                "lastname",
                JSON.stringify(val.custombuttonclick.data.lastname)
              );
              this.userservice.cookieset(
                "userid",
                JSON.stringify(val.custombuttonclick.data._id)
              );
              this.userservice.cookieset(
                "lastlogindate",
                JSON.stringify(val.custombuttonclick.data.lastlogin_datetime)
              );
              this.userservice.cookieset(
                "useremail",
                JSON.stringify(val.custombuttonclick.data.email)
              );
              this.userservice.cookieset(
                "status",
                JSON.stringify(val.custombuttonclick.data.status)
              );
              this.userservice.cookieset(
                "lastlogincity",
                JSON.stringify(val.custombuttonclick.data.lastlogincity)
              );
              this.userservice.cookieset(
                "lastloginregion",
                JSON.stringify(val.custombuttonclick.data.lastloginregion)
              );
              this.userservice.cookieset("jwtToken", main_user.jwtToken);
              this.userservice.cookieset("secret", secretdata);
              this.userservice.cookieset(
                "parentid",
                JSON.stringify(val.custombuttonclick.data.parentid)
              );
            }, 100);
            setTimeout(() => {
              this.router.navigateByUrl(
                "/" + val.custombuttonclick.data.type + "/dashboard"
              );
            }, 700);
          }
        });
        if (
          val.action == "customlistenbutton" &&
          val.buttondata.name == "add_user"
        ) {
          // this.router.navigateByUrl('/admin/add');
          // console.log(val, 'valllll')
          const dialogRef = this.dialog.open(Addusermodal, {
            panelClass: "AddUserComponent",
            // height: '500px',
            // width: '500px',
            data: { val: val.data },
          });
          //       dialogRef.afterClosed().subscribe((result:any) => {
          // // console.log(result,'tttttttttttttttt')
  
          //       })
        }
      }
      // console.log(val, '------------');
      if (
        val.custombuttonclick != null &&
        val.custombuttonclick.btninfo != null &&
        val.custombuttonclick.btninfo.label === "Discovery Status"
      ) {
        let update_status = {
          source: this.tablename,
          data: val.custombuttonclick.data,
          secret: this.cookieservice.get("secret"),
          token: this.cookieservice.get("jwttoken"),
        };
        const bottomSheetRef = this.bottomSheet.open(BottonSheetuser, {
          data: update_status,
        });
        bottomSheetRef.afterDismissed().subscribe(() => {
  
          console.log('Bottom sheet has been dismissed.');
          let data1 = {
            "secret": this.userservice.secret,
            "token": this.userservice.jwttoken,
            "source": "",
            "condition": {
              "limit": 10,
              "skip": 0
            },
            "sort": {
              "type": "desc",
              "field": "createdon_datetime"
            },
            "searchcondition": {
              "type": {
                "$in": [
                  "contract-manager",
                  "closer",
                  "technological-consultant"
                ]
              },
              "parentid": this.userservice.userid
            }
          }
          this.Apiservice
            .getDataWithoutToken(data1, "api/userlistview")
            .subscribe((res: any) => {
              this.distributorListdatatable = null;
              setTimeout(() => {
                this.distributorListdatatable = res.results.res;
  
              }, 50);
              // let currentUrl = this.router.url;
              // this.router.routeReuseStrategy.shouldReuseRoute = () => false;
              // this.router.onSameUrlNavigation = 'reload';
              // this.router.navigate([currentUrl]);
            })
        });
      }
      if (
        val.custombuttonclick != null &&
        val.custombuttonclick.btninfo != null &&
        val.custombuttonclick.btninfo.label === "Status Change"
      ) {
        let update_status = {
          source: this.tablename,
          data: val.custombuttonclick.data,
          secret: this.cookieservice.get("secret"),
          token: this.cookieservice.get("jwttoken"),
        };
        // this.bottomSheet.open(ChangeStatusModal, {
        //   data: update_status,
        // });
        const dialogRef = this.dialog.open(ChangeStatusModal, {
          panelClass: "statuschangemodal",
          data: update_status,
        });
  
        dialogRef.afterClosed().subscribe((result) => {
          console.log("The dialog was closed");
          console.log(result);
        });
      }
      if (
        val.action == "custombuttonclick" &&
        val.custombuttonclick.btninfo.label == "Edit User"
      ) {
        // console.log("Edit User");
        // return;
        if (val.custombuttonclick.data.type == "contract-manager") {
          this.router.navigateByUrl(
            "/contract-manager/edit/" + val.custombuttonclick.data._id
          );
        }
        if (val.custombuttonclick.data.type == "technological-consultant") {
          this.router.navigateByUrl(
            "/technological-consultant/edit/" + val.custombuttonclick.data._id
          );
        }
        if (val.custombuttonclick.data.type == "closer") {
          this.router.navigateByUrl(
            "/closer/edit/" + val.custombuttonclick.data._id
          );
        }
      }
  
      if (
        val.custombuttonclick != null &&
        val.custombuttonclick.btninfo != null &&
        val.custombuttonclick.btninfo.label === "View Contract"
      ) {
        window.open("/contract-view/" + val.custombuttonclick.data._id, "_blank");
  
      }
  
      // if (val.custombuttonclick != null && val.custombuttonclick.btninfo != null && val.custombuttonclick.btninfo.label === 'Others Action') {
      //   const btnSheet = this.bottomSheet.open(BottonSheetDistributor, {
      //     data: val
      //   });
      //   btnSheet.afterDismissed().subscribe(res => {
      //     if (res === 'yes') {
      //       // console.log('++++', res);
      //       this.updatetable = true;
      //     }
      //   });
      // }
    // }
   
  }
}
@Component({
  selector: "changestatusmodal",
  templateUrl: "changestatusmodal.html",
})
export class ChangeStatusModal {
  public editorconfig: any = {};
  public topcontract: any;
  public formdata: any;
  public ckeConfig: any = {
    toolbar: [
      {
        name: "editing",
        items: ["Scayt", "Find", "Replace", "SelectAll"],
      },
      {
        name: "clipboard",
        items: ["Cut"],
      },
      {
        name: "links",
        items: ["Link"],
      },
      { name: "document", items: ["Source"] },
      {
        name: "insert",
        items: ["Image"],
      },
      "/",
      {
        name: "basicstyles",
        items: ["Bold", "Italic", "Underline"],
      },

      {
        name: "styles",
        items: ["Styles", "Format", "FontSize", "-", "TextColor", "BGColor"],
      },
    ],
  };
  constructor(
    public dialogRef: MatDialogRef<ChangeStatusModal>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    public apiservice: ApiService,
    public activatedroute: ActivatedRoute,
    public userservice: UserserviceService,
    public snackBar: MatSnackBar
  ) {
    console.log(data);

    this.formdata = {
      successmessage: "Added Successfully !!",
      submittext: "Submit",
      canceltext: "Cancel",
      fields: [
        {
          label: "Enter Your Message",
          name: "message",
          value: "",
          type: "editor",
          // validations: [
          //   { rule: 'required', message: ' Commison Suggestions is required' },
          // ],
          ckeConfig: this.ckeConfig,
        },
      ],
    };
  }
  listenFormFieldChange(val) {
    console.log(val.fromval.message);
    if (val.field == "fromsubmit") {
      this.data.secret = this.userservice.secret;
      this.data.token = this.userservice.jwttoken;

      if (
        val.fromval.message &&
        val.fromval.message != null &&
        typeof val.fromval.message != "undefined" &&
        val.fromval.message != ""
      ) {
        this.data.message = [
          {
            value: val.fromval.message,
            addedby: this.userservice.userid,
            date: Math.round(new Date().getTime()),
          },
        ];
      }
      if (this.data.data.status == 1) {
        this.data.data.status = 0;
      } else {
        this.data.data.status = 1;
      }
      // let data = {
      //   secret: this.userservice.secret,
      //   token: this.userservice.jwttoken,

      // }
      console.log(this.data);

      this.apiservice
        .getDataWithoutToken(this.data, "api/userstatusupdate")
        .subscribe((res: any) => {
          if (res.status == "success") {
            this.snackBar.open("Commition suggetion added Succesfully", "ok", {
              duration: 3000,
            });
            this.dialogRef.close();
          }
        });
    }
    if (val.field == "formcancel") {
      this.dialogRef.close();
    }
  }
  onNoClick() { }
}

@Component({
  selector: "bottonsheetuser",
  templateUrl: "./bottonsheetuser.html",
})
export class BottonSheetuser {
  public deleteflag: boolean = false;
  constructor(
    public cookieservice: CookieService,
    public bottomSheetRef: MatBottomSheetRef<BottonSheetuser>,
    public apiservice: ApiService,
    public userservice: UserserviceService,
    @Inject(MAT_BOTTOM_SHEET_DATA) public data,
    private _snackBar: MatSnackBar,
    public dialog: MatDialog
  ) {
    console.log(data, "777777777777777");
  }
  isContractmanagerfun(val: any) {
    // console.log(val, '+_+');

    this.data.data["discoverystatus"] = val;
    this.data.token = this.cookieservice.get("jwtToken");
    console.log(this.data);

    this.apiservice
      .getDataWithoutToken(this.data, "api/userdiscoverystatusupdate")
      .subscribe((res: any) => {
        if (res.status == "success") {
          this._snackBar.open("Updated successfully!!", "Ok", {
            duration: 2000,
          });
        }
        this.bottomSheetRef.dismiss();
      });
  }
}

@Component({
  selector: "adduser",
  templateUrl: "adduser.html",
})
export class Addusermodal {
  public valdata: any;
  constructor(
    public dialogRef: MatDialogRef<Addusermodal>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    public apiservice: ApiService,
    public activatedroute: ActivatedRoute,
    public userservice: UserserviceService
  ) {
    this.valdata = JSON.stringify(data);
    this.valdata = JSON.parse(this.valdata);
    // console.log(data, '+++++');
    // console.log(this.valdata, '+++data++');
  }

  onNoClick(): void {
    this.dialogRef.close();
  }
}

@Component({
  selector: "dialogbox",
  templateUrl: "logme.html",
})
export class LogmeComponent {
  public valdata: any;
  constructor(
    public dialogRef: MatDialogRef<LogmeComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    public apiservice: ApiService,
    public activatedroute: ActivatedRoute,
    public userservice: UserserviceService
  ) {
    console.log(data);

    this.valdata = JSON.stringify(data);
    this.valdata = JSON.parse(this.valdata);
    // console.log(data, '+++++');
    // console.log(this.valdata, '+++data++');
  }

  onNoClick(val: any): void {
    this.dialogRef.close(val);
  }
}
