import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material';
import { ActivatedRoute, Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { ApiService } from 'src/app/services/api.service';
import { UserserviceService } from 'src/app/services/userservice.service';

@Component({
  selector: 'app-leadpractice',
  templateUrl: './leadpractice.component.html',
  styleUrls: ['./leadpractice.component.css']
})
export class LeadpracticeComponent implements OnInit {
  public flag: boolean = false;
  public productVal: any = [];
  public precticeowner: any = [];
  public productdata: any;
  public formdata: any;
  public currency: any = [{ val: 'USD', name: 'USD' }];
  public selectflag: boolean = false;
  public practicetype: any = [];
  public equpmenntstatusval: any = [{ val: 'Existing', name: 'Existing' }, { val: 'Loaned', name: 'Loaned' }, { val: 'Purchased', name: 'Purchased' }];
  public pecepracticestatus: any = [
    { val: 'Live', name: 'Live' },
    { val: 'Pending', name: 'Pending' },
    { val: 'Sold', name: 'Sold' },
    { val: 'WarrantyOnly', name: 'Warranty Only' },

  ];
  public equpmenntversionval: any = [];
  public cmatcontract: any = [];
  public hpsrepval: any = [];
  public distributorval: any = [
    // {
    //   "name": "Health Profit Solutions",
    //   "val": "4754570000000797009"
    // }
  ];
  public soldbyval: any = [];
  constructor(
    public apiservice: ApiService,
    public http: HttpClient,
    public activatedRoute: ActivatedRoute,
    public cookies: CookieService,
    public router: Router,
    public userservice: UserserviceService,
    private snackBar: MatSnackBar,
  ) {
    this.getdata();
    this.getproductlist();

    this.formdata = {
      successmessage: '',
      redirectpath: '',
      submitactive: true, // optional, default true
      submittext: "Submit",
      resettext: "Reset",
      canceltext: "Cancel",
      apiUrl: this.apiservice.api_url,
      // endpoint: "practice",
      jwttoken: this.cookies.get("jwtToken"),
      cancelroute: this.userservice.type + "/" + "lead/list",

      fields: [

        {
          label: "HPS Representative",
          name: "HPS_Representative",
          value: "",
          val: this.hpsrepval,
          type: "select",
          // validations: [
          //   { rule: "required", message: "HPS Representative is Required" },
          // ],
        },
        {
          label: "Distributor",
          name: "Distributor",
          value: "",
          val: this.distributorval,
          type: "select",
          // validations: [
          //   { rule: "required", message: "Distributor is Required" },
          // ],
        },
        // {
        //   label: "Sales Person",
        //   name: "Sold_by",
        //   value: "",
        //   val: this.soldbyval,
        //   type: "select",
        //   // validations: [
        //   //   { rule: "required", message: "Sales Person is Required" },
        //   // ],
        // },
        {
          label: "Practice Name",
          name: "Account_Name",
          value: "",
          type: "text",
          validations: [
            { rule: "required", message: "Practice Name is Required" },
          ],
        },
        {
          label: " Phone",
          name: "Phone",
          value: "",
          type: "text",
          // validations: [
          //   { rule: "required", message: "Practice Phone is Required" },
          // ],
        },

        {
          label: "Currency",
          name: "Currency",
          value: "",
          val: this.currency,
          type: "select",
          // validations: [
          //   { rule: "required", message: "Currency is Required" },
          // ],
        },
        {
          label: "Fax",
          name: "Fax",
          value: "",
          type: "text",
          // validations: [
          //   { rule: "required", message: "Fax is Required" },
          // ],
        },
        {
          label: " EIN Number",
          name: "EIN_Number",
          value: "",
          type: "number",
          validations: [
            {
              rule: 'maxLength',
              value: 9,
              message: 'Please enter the valid 9 digit EIN Number of ',
            },
          ],
        },
        {
          label: "Practice NPI Number",
          name: "Practice_NPI_Number",
          value: "",
          type: "text",
          // validations: [
          //   { rule: "required", message: "Practice NPI Number is Required" },
          // ],
        },
        {
          label: "Practice Type",
          name: "Account_Type",
          value: "",
          val: this.practicetype,
          type: "select",
          // validations: [
          //   { rule: "required", message: "Practice Type is Required" },
          // ],
        },
        {
          label: "Practice PECE Status",
          name: "Practice_Status",
          value: 'Pending',
          // hint:'None',
          // val: this.pecepracticestatus,
          type: "hidden",
       
        },
        {
          label: "Exchange Rate",
          name: "Exchange_Rate",
          value: 1,

          type: "hidden",
          // validations: [
          //   { rule: "required", message: "Exchange Rate is Required" },
          // ],
        },
        {
          label: "Practice EMR",
          name: "Practice_EMR",
          value: "",
          type: "text",
          // validations: [
          //   { rule: "required", message: "Practice EMR is Required" },
          // ],
        },
        {
          label: "Practice Email",
          name: "Practice_Email",
          value: "",
          type: "text",
          // validations: [
          //   { rule: "required", message: "Practice Email is Required" },
          // ],
        },
        {
          label: "Website",
          name: "Website",
          parttern:/^((ftp|http|https):\/\/)?(www.)?(?!.*(ftp|http|https|www.))[a-zA-Z0-9_-]+(\.[a-zA-Z]+)+((\/)[\w#]+)*(\/\w+\?[a-zA-Z0-9_]+=\w+(&[a-zA-Z0-9_]+=\w+)*)?$/gm,
          value: "",
          type: "text",
          // validations: [
          //   { rule: "required", message: "Website is Required" },
          // ],
        },
        {
          label: "Practice Notes",
          name: "Practice_Notes",
          value: "",
          type: "textarea",
          labelPosition: 'after',
          // validations: [
          //   { rule: "required", message: "Practice Notes is Required" },
          // ],
        },

        {
          heading: "Address Information",
          // name: "statusgroup",
          hint: ',0000',
          type: 'group',
          fields: [
            // {
            //   label: "Age1",
            //   name: "age1",
            //   type: 'number',
            //   hint: 'Age ??',
            //   validations: [
            //       // { rule: 'required' },
            //       // { rule: 'min', value: 5 },
            //       // { rule: 'max', value: 50, message: "Age can't be more than 50 " }
            //   ]
            // },

          ]
        },
        {
          label: "Billing Location Name",
          name: "billinglocationname",
          value: "",
          type: "text",

          // validations: [
          //   { rule: "required", message: "Practice Notes is Required" },
          // ],
        },
        {
          label: "Billing Location Name",
          name: "Location_Name",
          value: "",
          type: "text",

          // validations: [
          //   { rule: "required", message: "Billing Street is Required" },
          // ],
        },
        {
          label: "Billing Street",
          name: "Billing_Street",
          value: "",
          type: "text",

          // validations: [
          //   { rule: "required", message: "Billing Street is Required" },
          // ],
        },
        {
          label: " Billing City",
          name: "Billing_City",
          value: "",
          type: "text",

          // validations: [
          //   { rule: "required", message: "Billing Street is Required" },
          // ],
        },
        {
          label: "Billing State",
          name: "Billing_City",
          value: "",
          type: "text",

          // validations: [
          //   { rule: "required", message: "Billing Street is Required" },
          // ],
        },
        {
          label: " Billing Zip Code",
          name: "Billing_Zip_Code",
          value: "",
          type: "text",

          // validations: [
          //   { rule: "required", message: "Billing Street is Required" },
          // ],
        },
        {
          label: "Billing Country",
          name: "Billing_Country",
          value: "",
          type: "text",

          // validations: [
          //   { rule: "required", message: "Billing Street is Required" },
          // ],
        },
        {
          label: "Shipping Location Name",
          name: "Shipping_Location_Name",
          value: "",
          type: "text",

          // validations: [
          //   { rule: "required", message: "Billing Street is Required" },
          // ],
        },
        {
          label: "Shipping Street",
          name: "Shipping_Street",
          value: "",
          type: "text",

          // validations: [
          //   { rule: "required", message: "Billing Street is Required" },
          // ],
        },
        {
          label: "Shipping City",
          name: "Shipping_City",
          value: "",
          type: "text",

          // validations: [
          //   { rule: "required", message: "Billing Street is Required" },
          // ],
        },
        {
          label: "Shipping State",
          name: "Shipping_State",
          value: "",
          type: "text",

          // validations: [
          //   { rule: "required", message: "Billing Street is Required" },
          // ],
        },
        {
          label: "Shipping Zip Code",
          name: "Shipping_Zip_Code",
          value: "",
          type: "text",

          // validations: [
          //   { rule: "required", message: "Billing Street is Required" },
          // ],
        },
        {
          label: "Shipping Country",
          name: "Shipping_Country",
          value: "",
          type: "text",

          // validations: [
          //   { rule: "required", message: "Billing Street is Required" },
          // ],
        },
        // {
        //   heading: "OnBoarding Checklist (DO NOT UPLOAD-Use ZOHO SIGN)",
        //   name: "statusgroup",
        //   hint: ',0000',
        //   type: 'group',
        //   fields: [
        //     // {
        //     //   label: "Age1",
        //     //   name: "age1",
        //     //   type: 'number',
        //     //   hint: 'Age ??',
        //     //   validations: [
        //     //       // { rule: 'required' },
        //     //       // { rule: 'min', value: 5 },
        //     //       // { rule: 'max', value: 50, message: "Age can't be more than 50 " }
        //     //   ]
        //     // },

        //   ]
        // },

        // {
        //   label: "Demo Completed",
        //   name: "Demo_Completed",
        //   value: "",
        //   type: "text",

        //   // validations: [
        //   //   { rule: "required", message: "Billing Street is Required" },
        //   // ],
        // },
        // {
        //   label: "CMAT Contract",
        //   name: "cmat_Country",
        //   value: "",
        //   val: this.cmatcontract,
        //   type: "select",

        //   // validations: [
        //   //   { rule: "required", message: "Billing Street is Required" },
        //   // ],
        // },
        // {
        //   label: "Amount Paid for Equipment Warranty",
        //   name: "Amount_Paid_for_Equipment_Warranty",
        //   value: "",
        //   type: "text",

        //   // validations: [
        //   //   { rule: "required", message: "Billing Street is Required" },
        //   // ],
        // },
        // {
        //   label: "Warranty Contract Paid",
        //   name: "Warranty_Contract_Paid",
        //   value: "",
        //   type: "text",

        //   // validations: [
        //   //   { rule: "required", message: "Billing Street is Required" },
        //   // ],
        // },
        // {
        //   label: "All Contracts Signed",
        //   name: "Contract_Signed",
        //   value: "",
        //   type: "text",

        //   // validations: [
        //   //   { rule: "required", message: "Billing Street is Required" },
        //   // ],
        // },
        // {
        //   label: "Payment Authorization Form",
        //   name: "Payment_Method_Document",
        //   value: "",
        //   type: "text",

        //   // validations: [
        //   //   { rule: "required", message: "Billing Street is Required" },
        //   // ],
        // },
        // {
        //   label: "Practice Information Worksheet",
        //   name: "Practice_Questionnaire_Completed",
        //   value: "",
        //   type: "text",

        //   // validations: [
        //   //   { rule: "required", message: "Billing Street is Required" },
        //   // ],
        // }, {
        //   label: "PECE Software Agreement",
        //   name: "PECE_Software_Agreement",
        //   value: "",
        //   type: "text",

        //   // validations: [
        //   //   { rule: "required", message: "Billing Street is Required" },
        //   // ],
        // }, {
        //   label: "Number of Cuffs",
        //   name: "Number_of_Cuffs",
        //   value: "",
        //   type: "text",

        //   // validations: [
        //   //   { rule: "required", message: "Billing Street is Required" },
        //   // ],
        // }, {
        //   label: "OnBoarding Notes",
        //   name: "OnBoarding_Notes",
        //   value: "",
        //   type: "textarea",

        //   // validations: [
        //   //   { rule: "required", message: "Billing Street is Required" },
        //   // ],
        // },
        // {
        //   label: "Everyone Trained",
        //   name: "Everyone_Trained",
        //   value: "",
        //   type: "text",

        //   // validations: [
        //   //   { rule: "required", message: "Billing Street is Required" },
        //   // ],
        // },

        {
          heading: "Equipment",
          name: "statusgroup",
          hint: ',0000',
          type: 'group',
          fields: [
            // {
            //   label: "Age1",
            //   name: "age1",
            //   type: 'number',
            //   hint: 'Age ??',
            //   validations: [
            //       // { rule: 'required' },
            //       // { rule: 'min', value: 5 },
            //       // { rule: 'max', value: 50, message: "Age can't be more than 50 " }
            //   ]
            // },

          ]
        },
        {
          label: "Equipment Status",
          name: "Status",
          value: "",
          val: this.equpmenntstatusval,
          type: "select",

          // validations: [
          //   { rule: "required", message: "Billing Street is Required" },
          // ],
        },
        {
          label: "Equipment Version",
          name: "Equipment_Version",
          value: "",
          val: this.equpmenntversionval,
          type: "select",

          // validations: [
          //   { rule: "required", message: "Billing Street is Required" },
          // ],
        },
        {
          label: "Equipment Shipped",
          name: "Shipped",
          value: "",
          type: "date",

          // validations: [
          //   { rule: "required", message: "Billing Street is Required" },
          // ],
        },
        {
          label: "Equipment Payment Method",
          name: "Equipment_Serial_Number",
          value: "",
          type: "text",

          // validations: [
          //   { rule: "required", message: "Billing Street is Required" },
          // ],
        },
        {
          label: "Equipment Status Date",
          name: "Status_Date",
          type: 'date',
          value: '',
          hint: "05/05/2020",
         
          // validations: [
          //   { rule: "required", message: "Billing Street is Required" },
          // ],
        },
        {
          label: "Equipment Serial Number",
          name: "Serial_Number",
          value: "",
          type: "text",

          // validations: [
          //   { rule: "required", message: "Billing Street is Required" },
          // ],
        },

        {
          label: "Equipment Notes",
          name: "Equipment_Notes",
          value: "",
          type: "textarea",

          // validations: [
          //   { rule: "required", message: "Billing Street is Required" },
          // ],
        },

        // {
        //   label: "",
        //   name: "type",
        //   value: 'lead',
        //   type: "hidden",

        //   // validations: [
        //   //   { rule: "required", message: "Billing Street is Required" },
        //   // ],
        // },

      ],
    };
  }

  ngOnInit() {
  }

  onChangeTimezone(val: any) {
    console.log(val.value, 'productdata', this.productdata);
    if (val != null && val != '') {
      this.selectflag = true;
    }
  }

  getdata() {
    this.apiservice
      .getDataWithoutToken(
        {
          token: this.userservice.jwttoken,
          secret: this.userservice.secret,
        },
        "api/exitsing_auto_complete_hps"
      )
      .subscribe((response: any) => {
        // this.productVal=response.res
        console.log(response, 'madhumitaaaaa')

        for (const i in response.res) {
          if (Object.prototype.hasOwnProperty.call(response.res, i)) {
            this.hpsrepval.push({
              val: response.res[i].id,
              name: response.res[i].name,
            });
          }
        }
      });
    this.apiservice
      .getDataWithoutToken(
        {
          token: this.userservice.jwttoken,
          secret: this.userservice.secret,
        },
        "api/exitsing_auto_complete_distributor"
      )
      .subscribe((response1: any) => {
        // this.productVal=response.res
        console.log(response1, 'madhumitaaaaa')

        for (const i in response1.res) {
          if (Object.prototype.hasOwnProperty.call(response1.res, i)) {
            this.distributorval.push({
              val: response1.res[i].id,
              name: response1.res[i].name,
            });
          }
        }
      });
    this.apiservice
      .getDataWithoutToken(
        {
          token: this.userservice.jwttoken,
          secret: this.userservice.secret,
        },
        "api/exitsing_equipment_version_searchs"
      )
      .subscribe((response1: any) => {
        // this.productVal=response.res
        console.log(response1, 'madhumitaaaaa')

        for (const i in response1.res) {
          if (Object.prototype.hasOwnProperty.call(response1.res, i)) {
            this.equpmenntversionval.push({
              val: response1.res[i].val,
              name: response1.res[i].name,
            });
          }
        }
      });

    this.apiservice
      .getDataWithoutToken(
        {
          token: this.userservice.jwttoken,
          secret: this.userservice.secret,
        },
        "api/exitsing_practice_type_searchs"
      )
      .subscribe((response1: any) => {
        // this.productVal=response.res
        console.log(response1, 'madhumitaaaaa')

        for (const i in response1.res) {
          if (Object.prototype.hasOwnProperty.call(response1.res, i)) {
            this.practicetype.push({
              val: response1.res[i].val,
              name: response1.res[i].name,
            });
          }
        }
      });
  }

  getproductlist() {
    this.apiservice
      .getDataWithoutToken(
        {
          token: this.userservice.jwttoken,
          secret: this.userservice.secret,
        },
        "api/productsearch"
      )
      .subscribe((response: any) => {
        // this.productVal=response.res
        // // console.log(this.productVal,'madhumitaaaaa')
        for (const i in response.res) {
          if (Object.prototype.hasOwnProperty.call(response.res, i)) {
            this.productVal.push({
              val: response.res[i].val,
              name: response.res[i].name,
            });
          }
        }
      });
  }

  listenFormFieldChanges(val: any) {
    console.log(val.fromval);

    var names_hps1: any = {
      name: '',
      id: '',
    };
    var distributorvaldata: any = {
      name: '',
      id: '',
    }
    var soldbyvaldata: any = {
      name: '',
      id: '',
    }
    var x: any = 1;
    if (val.fromval.HPS_Representative != null && val.fromval.HPS_Representative != '') {
      for (const key in this.hpsrepval) {
        if (this.hpsrepval[key].val == val.fromval.HPS_Representative) {
          console.log(this.hpsrepval[key].name);
          names_hps1.name = this.hpsrepval[key].name;
          names_hps1.id = val.fromval.HPS_Representative;
        }
      }
    }
    if (val.fromval.Distributor != null && val.fromval.Distributor != '') {
      for (const key in this.distributorval) {
        if (this.distributorval[key].val == val.fromval.Distributor) {
          console.log(this.distributorval[key].name);
          distributorvaldata.name = this.distributorval[key].name;
          distributorvaldata.id = val.fromval.Distributor;
        }
      }
    }
    if (val.fromval.Sold_by != null && typeof (val.fromval.Sold_by) != 'undefined' && val.fromval.Sold_by != '') {
      for (const key in this.soldbyval) {
        if (this.soldbyval[key].val == val.fromval.Sold_by) {
          console.log(this.soldbyval[key].name);
          soldbyvaldata.name = this.soldbyval[key].name;
          soldbyvaldata.id = val.fromval.Sold_by;
        }
      }
    }


    let data: any = {
      data: {
        "Owner": {
          "name": "Larry Fresinski",
          "id": "4754570000000298001",
          "email": "larry@hpspros.com"
        },
        'HPS_Representative': names_hps1,
        Distributor: distributorvaldata,
        Sold_by: {
          name: this.userservice.fullname,
          id: this.userservice.userid
        },
        Account_Name: val.fromval.Account_Name,
        Account_Type: val.fromval.Account_Type,
        // Amount_Paid_for_Equipment_Warranty: val.fromval.Amount_Paid_for_Equipment_Warranty,
        Billing_City: val.fromval.Billing_City,
        Billing_Country: val.fromval.Billing_Country,
        Billing_Street: val.fromval.Billing_Street,
        Billing_Zip_Code: val.fromval.Billing_Zip_Code,
        Currency: val.fromval.Currency,
        EIN_Number: val.fromval.EIN_Number,
        Equipment_Notes: val.fromval.Equipment_Notes,
        Equipment_Serial_Number: val.fromval.Equipment_Serial_Number,
        Pick_List_2: val.fromval.Equipment_Version,
        Exchange_Rate: val.fromval.Exchange_Rate,
        Fax: val.fromval.Fax,
        Location_Name: val.fromval.Location_Name,
        Phone: val.fromval.Phone,
        Practice_EMR: val.fromval.Practice_EMR,
        Practice_Email: val.fromval.Practice_Email,
        Practice_NPI_Number: val.fromval.Practice_NPI_Number,
        Practice_Notes: val.fromval.Practice_Notes,
        Practice_Status: val.fromval.Practice_Status,
        Serial_Number: val.fromval.Serial_Number,
        Shipped: val.fromval.Shipped,
        Shipping_City: val.fromval.Shipping_City,
        Shipping_Country: val.fromval.Shipping_Country,
        Shipping_Location_Name: val.fromval.Shipping_Location_Name,
        Shipping_State: val.fromval.Shipping_State,
        Shipping_Street: val.fromval.Shipping_Street,
        Shipping_Zip_Code: val.fromval.Shipping_Zip_Code,
        Status: val.fromval.Status,
        Status_Date: val.fromval.Status_Date,
        Website: val.fromval.Website,
        billinglocationname: val.fromval.billinglocationname,
        // statusgroup: val.fromval.statusgroup,
        type:'lead',
        products:this.productdata
      }


    }


    console.log(data, 'llllllllllllllllll');
    if (val.field == "fromsubmit") {
      this.apiservice
        .getDataWithoutToken(
          data,
          "api3/usersinguptwo"
        )
        .subscribe((response: any) => {
          if (response.status=='success') {
            this.router.navigateByUrl("user-details/lead/" + response.result._id)

          }

        });
    }
  }

}
