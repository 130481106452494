import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material';
import { ActivatedRoute, Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { ApiService } from 'src/app/services/api.service';
import { UserserviceService } from 'src/app/services/userservice.service';

@Component({
  selector: 'app-technological-consultant-add-edit',
  templateUrl: './technological-consultant-add-edit.component.html',
  styleUrls: ['./technological-consultant-add-edit.component.css'],
})
export class TechnologicalConsultantAddEditComponent implements OnInit {
  emailregex: RegExp =
    /^(([^<>()\[\]\\.,;:\s@']+(\.[^<>()\[\]\\.,;:\s@']+)*)|('.+'))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  passwordregex: RegExp = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})/;
  textregex: RegExp = /^[a-zA-Z]*$/;

  temtdata: any = '';
  // formdata
  formfieldrefresh: any = false;
  updatetable: any = false;
  formfieldrefreshdata: any = null;
  datasource: any;
  public flag: any;
  public typeVal = [];
  public formdata: any = {
    cancelroute: '',
    redirectpath: '',
  };
  public stateVal: any = [];
  public status: any = [
    { val: 1, name: 'Active' },
    { val: 0, name: 'Inactive' },
  ];
  public onbording: any = [
    { val: 1, name: 'Yes' },
    { val: 0, name: 'No' },
  ];
  public gameplancall: any = [
    { val: 1, name: 'Yes' },
    { val: 0, name: 'No' },
  ];
  public userData: any = [];
  public resolveval: any;
  public parentidVal: any = [];
  public product_details: any = [];
  public selected_product: any = [];
  public edit_selected_product: any = [];
  public parent_product_info: any = [];
  public add_data: any = [];
  constructor(
    public apiservice: ApiService,
    public http: HttpClient,
    public activatedRoute: ActivatedRoute,
    public cookies: CookieService,
    public router: Router,
    public userservice: UserserviceService,
    public _snackBar: MatSnackBar
  ) {
    // console.log(this.cookies.get('type'), '++++')
    this.userselection();
    if (this.router.url == '/admin/technological-consultant/add') {
      this.getproductdetails(false);
    } else {
      this.getproductdetails(true);
    }
    this.apiservice.getState().subscribe((res: any) => {
      for (const i in res) {
        if (Object.prototype.hasOwnProperty.call(res, i)) {
          this.stateVal.push({ val: res[i].abbreviation, name: res[i].name });
        }
      }
    });

    this.formdata = {
      successmessage: 'Added Successfully !!',
      redirectpath: 'list',
      submitactive: true, // optional, default true
      submittext: 'Submit',
      resettext: 'Reset',
      canceltext: 'Cancel',
      apiUrl: this.apiservice.api_url,
      // endpoint: 'api/addusers', //
      jwttoken: this.cookies.get('jwtToken'),
      cancelroute: 'list',

      fields: [
        {
          label: 'First Name',
          name: 'firstname', //
          value: '',
          type: 'text',
          validations: [
            { rule: 'required', message: 'First Name is Required' },
            // {
            //   rule: 'pattern',
            //   value: this.textregex,
            //   message: 'only use alphabet',
            // },
          ],
        },

        {
          heading: '',
          label: 'Last Name',
          name: 'lastname',
          value: '',
          type: 'text',
          validations: [
            { rule: 'required', message: 'Last Name is Required' },
            // {
            //   rule: 'pattern',
            //   value: this.textregex,
            //   message: 'only use alphabet',
            // },
          ],
        },

        {
          label: 'Telephone',
          name: 'phone',
          value: '',
          type: 'number',
          validations: [
            { rule: 'required', message: 'Telephone is Required' },
            {
              rule: 'minLength',
              value: 10,
              message: 'The Phone Number must be 10 digits',
            },
            {
              rule: 'maxLength',
              value: 15,
              message: 'Please enter the valid number',
            },
          ],
        },

        {
          label: 'Email',
          name: 'email',
          type: 'email',
          hint: '',

          validations: [
            { rule: 'required', message: 'Email is required' },
            {
              rule: 'pattern',
              value: this.emailregex,
              message: 'Must be a valid Email',
            },
          ],
        },
        {
          label: 'Company Name',
          name: 'company',
          type: 'text',
          validations: [
            { rule: 'required', message: 'Company Name is Required' },
          ],
        },
        {
          label: 'Address',
          name: 'address',
          value: '',
          type: 'textarea',
          validations: [{ rule: 'required', message: 'Address is Required' }],
        },
        {
          label: 'type',
          name: 'type',
          type: 'hidden',
          value: 'technological-consultant',
        },
        {
          label: 'State',
          name: 'state',
          val: this.stateVal,
          value: '',
          type: 'select',
          validations: [{ rule: 'required', message: 'State is Required' }],
        },

        {
          label: 'City',
          name: 'city',
          value: '',
          type: 'text',
          validations: [{ rule: 'required', message: 'City is Required' }],
        },

        {
          label: 'Zip',
          name: 'zip',
          value: '',
          type: 'number',
          validations: [
            { rule: 'required', message: 'Zip is Required' },
            {
              rule: 'maxLength',
              value: 5,
              message: 'Please enter the valid Zip ID',
            },
          ],
        },
        {
          label: 'Password',
          name: 'password',
          type: 'password',
          passwordflag: true,
          value: '',

          validations: [
            {
              rule: 'required',
              message:
                'Your provided Password must contain at-least one Uppercase letter, one Lower case letter, and one Number.',
            },
            {
              rule: 'pattern',
              value: this.passwordregex,
              message: 'Must contain a Capital Letter and a Number',
            },
          ],
        },

        { 
          label: 'Confirm Password',
          name: 'confirmpassword',
          type: 'password',
          passwordflag: false,
          value: '',
          validations: [
            {
              rule: 'required',
              message:
                'Your provided Password must contain at-least one Uppercase letter, one Lower case letter, and one Number.',
            },
            {
              rule: 'match',
              message: 'Confirm Password field Needs to  match Password',
            },
            {
              rule: 'pattern',
              value: this.passwordregex,
              message: 'Must contain a Capital Letter and a Number',
            },
          ],
        },
        {
          label: 'Distributor',
          name: 'parentid',
          val: this.typeVal,
          type: 'select',
          validations: [
            { rule: 'required', message: 'Distributor is Required' },
          ],
        },
        // {
        //   label: 'On Bording Call',
        //   name: 'onbordingcall',
        //   hint: '',
        //   type: 'checkbox',
        //   val:this.onbording,

        // },
        // {
        //   label: 'Game Plan Call',
        //   name: 'gameplancall',
        //   hint: '',
        //   type: 'checkbox',
        //   val:this.gameplancall,

        // },
        {
          label: 'Active',
          name: 'status',
          hint: '',
          type: 'checkbox',
          val: this.status,
          value: 0,
        },
      ],
    };
    // console.log(this.formdata, '+++')
    if (
      this.activatedRoute.snapshot.routeConfig.path ==
      'technological-consultant/add'
    ) {
      this.formdata.cancelroute = 'distributor/users';
      this.formdata.redirectpath = 'distributor/users';
    }
    if (JSON.parse(this.cookies.get('type')) == 'distributor') {
      this.formdata.fields[12].type = 'hidden';
      this.formdata.fields[12].value = JSON.parse(this.cookies.get('userid'));
      this.formdata.fields[12].validations = [];
    }
    if (this.activatedRoute.snapshot.params._id != null) {
      this.flag = true;
      this.edittechnology();
    }
    // this.salespersondata();
  }
  getproductdetails(val: any) {
    this.apiservice
      .getDataWithoutToken(
        {
          token: this.userservice.jwttoken,
          secret: this.userservice.secret,
          searchcondition: {
            user_type: 'technological-consultant'
          }
        },
        'api10/get-commission-rule'
      )
      .subscribe((response: any) => {
        // this.productVal=response.res
        // // console.log(this.productVal,'madhumitaaaaa')
        this.product_details = response.res;
        console.log(this.product_details);
        if (val) {
          let listner_val = {
            field: { name: 'parentid' },
            fieldval: this.resolveval.parentid
          }
          this.listenFormFieldChange(listner_val);
        }
        // if (val) {
        //   let data = [];
        //   let selected_product_current = [];
        //   if (this.router.url == '/admin/technological-consultant/add') {
        //     selected_product_current = this.selected_product;
        //   } else {
        //     selected_product_current = this.edit_selected_product;
        //     for (let key in this.product_details) {
        //       let check_name = this.product_details[key].product_name.replace(/[-' ']/g, '_') + this.product_details[key].commission_type;
        //       console.log(check_name);
        //       if (check_name == 'PECEevent_commission' && this.userData.PECEevent_commission != null && this.userData.PECEevent_commission != '') {
        //         this.product_details[key].commission_value = this.userData.PECEevent_commission
        //       }
        //       if (check_name == 'PECEsales_commission' && this.userData.PECEsales_commission != null && this.userData.PECEsales_commission != '') {
        //         this.product_details[key].commission_value = this.userData.PECEsales_commission
        //       }
        //       if (check_name == 'TM_FLOW_ANS_MEDICAL_DEVICEsales_commission' && this.userData.TM_FLOW_ANS_MEDICAL_DEVICEsales_commission != null && this.userData.TM_FLOW_ANS_MEDICAL_DEVICEsales_commission != '') {
        //         this.product_details[key].commission_value = this.userData.TM_FLOW_ANS_MEDICAL_DEVICEsales_commission
        //       }
        //       if (check_name == 'neoGENsales_commission' && this.userData.neoGENsales_commission != null && this.userData.neoGENsales_commission != '') {
        //         this.product_details[key].commission_value = this.userData.neoGENsales_commission
        //       }
        //       if (check_name == 'BioEnergeticsevent_commission' && this.userData.BioEnergeticsevent_commission != null && this.userData.BioEnergeticsevent_commission != '') {
        //         this.product_details[key].commission_value = this.userData.BioEnergeticsevent_commission
        //       }
        //       if (check_name == 'BioEnergeticssales_commission' && this.userData.BioEnergeticssales_commission != null && this.userData.BioEnergeticssales_commission != '') {
        //         this.product_details[key].commission_value = this.userData.BioEnergeticssales_commission
        //       }
        //     }
        //   }
        //   for (let iterator of selected_product_current) {
        //     for (let key in this.product_details) {
        //       if (this.product_details[key].product_id == iterator) {
        //         if (data.length == 0) {
        //           if (this.product_details[key].commission_type == 'sales_commission') {
        //             data.push({
        //               label: this.product_details[key].product_name + ' Sales Commission(%)',
        //               name: this.product_details[key].product_name.replace(/[-' ']/g, '_') + this.product_details[key].commission_type,
        //               value: this.product_details[key].commission_value,
        //               type: 'number',
        //               id: this.product_details[key].product_id,
        //               validations: [{ rule: 'required', message: 'Commission is Required' }, { rule: 'min', value: 0 },],
        //               after: 'disableproduct'
        //             })
        //           }
        //           if (this.product_details[key].commission_type == 'event_commission') {
        //             data.push({
        //               label: this.product_details[key].product_name + ' Event Commission(per event)',
        //               name: this.product_details[key].product_name.replace(/[-' ']/g, '_') + this.product_details[key].commission_type,
        //               value: this.product_details[key].commission_value,
        //               type: 'number',
        //               id: this.product_details[key].product_id,
        //               validations: [{ rule: 'required', message: 'Commission is Required' }, { rule: 'min', value: 0 },],
        //               after: 'disableproduct'
        //             })
        //           }
        //         } else {
        //           if (this.product_details[key].product_name.replace(/[-' ']/g, '_') + this.product_details[key].commission_type != data[data.length - 1].name) {
        //             if (this.product_details[key].commission_type == 'sales_commission') {
        //               data.push({
        //                 label: this.product_details[key].product_name + ' Sales Commission(%)',
        //                 name: this.product_details[key].product_name.replace(/[-' ']/g, '_') + this.product_details[key].commission_type,
        //                 value: this.product_details[key].commission_value,
        //                 type: 'number',
        //                 id: this.product_details[key].product_id,
        //                 validations: [{ rule: 'required', message: 'Commission is Required' }, { rule: 'min', value: 0 },],
        //                 after: data[data.length - 1].name
        //               })
        //             }
        //             if (this.product_details[key].commission_type == 'event_commission') {
        //               data.push({
        //                 label: this.product_details[key].product_name + ' Event Commission(per event)',
        //                 name: this.product_details[key].product_name.replace(/[-' ']/g, '_') + this.product_details[key].commission_type,
        //                 value: this.product_details[key].commission_value,
        //                 type: 'number',
        //                 id: this.product_details[key].product_id,
        //                 validations: [{ rule: 'required', message: 'Commission is Required' }, { rule: 'min', value: 0 },],
        //                 after: data[data.length - 1].name
        //               })
        //             }
        //           }
        //         }
        //       }
        //     }
        //   }
        //   console.log(data);
        //   this.formfieldrefreshdata = {
        //     field: 'addfromcontrol',
        //     value: data
        //   };
        //   // setTimeout(() => {
        //   //   console.log(this.formdata.fields);
        //   // }, 100);
        // }
      });
  }
  listenFormFieldChange(val) {
    console.log(val);
    this.selected_product = [];
    this.parent_product_info = [];
    if (val.field.name == 'parentid') {
      let send_data = {
        parent_id: val.fieldval
      }
      this.apiservice.getDataWithoutToken(send_data, 'api10/get-parent-product').subscribe((res: any) => {
        console.log(res);
        this.selected_product = res.res[0].disableproduct;
        this.parent_product_info = res.res[0];
        for (let key in this.product_details) {

          let check_name = this.product_details[key].product_name.replace(/[-' ']/g, '_') + this.product_details[key].commission_type;
          console.log(check_name);
          if (check_name == 'PECEevent_commission' && this.userData.PECEevent_commission != null && this.userData.PECEevent_commission != '') {
            this.product_details[key].commission_value = this.userData.PECEevent_commission
          }
          else {
            if (check_name == 'PECEevent_commission' && this.parent_product_info.PECEevent_commission != null && this.parent_product_info.PECEevent_commission != '') {
              this.product_details[key].commission_value = this.parent_product_info.PECEevent_commission
            }
          }
          if (check_name == 'PECEsales_commission' && this.userData.PECEsales_commission != null && this.userData.PECEsales_commission != '') {
            this.product_details[key].commission_value = this.userData.PECEsales_commission
          }
          else {
            if (check_name == 'PECEsales_commission' && this.parent_product_info.PECEsales_commission != null && this.parent_product_info.PECEsales_commission != '') {
              this.product_details[key].commission_value = this.parent_product_info.PECEsales_commission
            }
          }
          if (check_name == 'TM_FLOW_ANS_MEDICAL_DEVICEsales_commission' && this.userData.TM_FLOW_ANS_MEDICAL_DEVICEsales_commission != null && this.userData.TM_FLOW_ANS_MEDICAL_DEVICEsales_commission != '') {
            this.product_details[key].commission_value = this.userData.TM_FLOW_ANS_MEDICAL_DEVICEsales_commission
          }
          else {
            if (check_name == 'TM_FLOW_ANS_MEDICAL_DEVICEsales_commission' && this.parent_product_info.TM_FLOW_ANS_MEDICAL_DEVICEsales_commission != null && this.parent_product_info.TM_FLOW_ANS_MEDICAL_DEVICEsales_commission != '') {
              this.product_details[key].commission_value = this.parent_product_info.TM_FLOW_ANS_MEDICAL_DEVICEsales_commission
            }
          }
          if (check_name == 'neoGENsales_commission' && this.userData.neoGENsales_commission != null && this.userData.neoGENsales_commission != '') {
            this.product_details[key].commission_value = this.userData.neoGENsales_commission
          }
          else {
            if (check_name == 'neoGENsales_commission' && this.parent_product_info.neoGENsales_commission != null && this.parent_product_info.neoGENsales_commission != '') {
              this.product_details[key].commission_value = this.parent_product_info.neoGENsales_commission
            }
          }
          if (check_name == 'BioEnergeticsevent_commission' && this.userData.BioEnergeticsevent_commission != null && this.userData.BioEnergeticsevent_commission != '') {
            this.product_details[key].commission_value = this.userData.BioEnergeticsevent_commission
          }
          else {
            if (check_name == 'BioEnergeticsevent_commission' && this.parent_product_info.BioEnergeticsevent_commission != null && this.parent_product_info.BioEnergeticsevent_commission != '') {
              this.product_details[key].commission_value = this.parent_product_info.BioEnergeticsevent_commission
            }
          }
          if (check_name == 'BioEnergeticssales_commission' && this.userData.BioEnergeticssales_commission != null && this.userData.BioEnergeticssales_commission != '') {
            this.product_details[key].commission_value = this.userData.BioEnergeticssales_commission
          }
          else {
            if (check_name == 'BioEnergeticssales_commission' && this.parent_product_info.BioEnergeticssales_commission != null && this.parent_product_info.BioEnergeticssales_commission != '') {
              this.product_details[key].commission_value = this.parent_product_info.BioEnergeticssales_commission
            }
          }
        }
        let data1 = [];
        for (let iterator1 of this.selected_product) {
          // let flag = false;

          for (let key in this.product_details) {
            if (this.product_details[key].product_id == iterator1) {
              data1.push(this.product_details[key])
            }
          }
        }
        console.log(data1, 'data111111111111111111');
        if (this.add_data.length > 0) {
          console.log(this.add_data, ':::::::::::::::');
          let remove_field_name = [];
          for (let key in this.add_data) {
            remove_field_name.push(this.add_data[key].name);
          }
          console.log(remove_field_name);

          this.formfieldrefreshdata = { field: 'removefromcontrol', value: remove_field_name };
        }
        // if (flag){
        // let data = this.formdata.fields.filter(product => product.id == iterator1);
        // if (data.length == 0) {
        this.add_data = [];
        // let data1 = this.product_details.filter(product => product.product_id == iterator1);;
        // console.log(data1, 'data1');
        // let added_place = '';
        // if (this.router.url == '/admin/distributor/add') {
        //   added_place = this.formdata.fields[this.formdata.fields.length - 2].name
        // } else {
        //   added_place = this.formdata.fields[this.formdata.fields.length - 3].name
        // }
        for (let key in data1) {

          if (key == '0') {

            if (data1[key].commission_type == 'sales_commission') {
              if (data1[key].product_id == '604aff3691b7c80008ca11a8') {
                this.add_data.push({
                  label: data1[key].product_name + ' Practice Testing Center Program(%)',
                  name: data1[key].product_name.replace(/[-' ']/g, '_') + data1[key].commission_type,
                  value: data1[key].commission_value,
                  type: 'number',
                  id: data1[key].product_id,
                  suffix: '%',
                  validations: [{ rule: 'required', message: 'Commission is Required' }, { rule: 'min', value: 0 },],
                  after: 'parentid'
                })
              }
              if (data1[key].product_id != '612c883f118c110009f02820' && data1[key].product_id != '604aff3691b7c80008ca11a8') {
                this.add_data.push({
                  label: data1[key].product_name + ' Sales Commission(%)',
                  name: data1[key].product_name.replace(/[-' ']/g, '_') + data1[key].commission_type,
                  value: data1[key].commission_value,
                  type: 'number',
                  id: data1[key].product_id,
                  suffix: '%',
                  validations: [{ rule: 'required', message: 'Commission is Required' }, { rule: 'min', value: 0 },],
                  after: 'parentid'
                })
              }
            }
            if (data1[key].commission_type == 'event_commission') {
              if (data1[key].product_id == '604aff3691b7c80008ca11a8') {
                this.add_data.push({
                  label: data1[key].product_name + ' Standard Qualified Practice Program(per event)',
                  name: data1[key].product_name.replace(/[-' ']/g, '_') + data1[key].commission_type,
                  value: data1[key].commission_value,
                  type: 'number',
                  id: data1[key].product_id,
                  prefix: '$',
                  validations: [{ rule: 'required', message: 'Commission is Required' }, { rule: 'min', value: 0 },],
                  after: 'parentid'
                })
              }
              if (data1[key].product_id != '604aff3691b7c80008ca11a8') {
                this.add_data.push({
                  label: data1[key].product_name + ' Event Commission(per event)',
                  name: data1[key].product_name.replace(/[-' ']/g, '_') + data1[key].commission_type,
                  value: data1[key].commission_value,
                  type: 'number',
                  id: data1[key].product_id,
                  prefix: '$',
                  validations: [{ rule: 'required', message: 'Commission is Required' }, { rule: 'min', value: 0 },],
                  after: 'parentid'
                })
              }
            }

          } else {

            if (data1[key].commission_type == 'sales_commission') {
              if (data1[key].product_id == '604aff3691b7c80008ca11a8') {
                this.add_data.push({
                  label: data1[key].product_name + ' Practice Testing Center Program(%)',
                  name: data1[key].product_name.replace(/[-' ']/g, '_') + data1[key].commission_type,
                  value: data1[key].commission_value,
                  type: 'number',
                  id: data1[key].product_id,
                  suffix: '%',
                  validations: [{ rule: 'required', message: 'Commission is Required' }, { rule: 'min', value: 0 },],
                  after: this.add_data[this.add_data.length - 1].name
                })
              }
              if (data1[key].product_id != '612c883f118c110009f02820' && data1[key].product_id != '604aff3691b7c80008ca11a8') {
                this.add_data.push({
                  label: data1[key].product_name + ' Sales Commission(%)',
                  name: data1[key].product_name.replace(/[-' ']/g, '_') + data1[key].commission_type,
                  value: data1[key].commission_value,
                  type: 'number',
                  id: data1[key].product_id,
                  suffix: '%',
                  validations: [{ rule: 'required', message: 'Commission is Required' }, { rule: 'min', value: 0 },],
                  after: this.add_data[this.add_data.length - 1].name
                })
              }
            }
            if (data1[key].commission_type == 'event_commission') {
              if (data1[key].product_id == '604aff3691b7c80008ca11a8') {
                this.add_data.push({
                  label: data1[key].product_name + ' Standard Qualified Practice Program(per event)',
                  name: data1[key].product_name.replace(/[-' ']/g, '_') + data1[key].commission_type,
                  value: data1[key].commission_value,
                  type: 'number',
                  id: data1[key].product_id,
                  prefix: '$',
                  validations: [{ rule: 'required', message: 'Commission is Required' }, { rule: 'min', value: 0 },],
                  after: this.add_data[this.add_data.length - 1].name
                })
              }
              if (data1[key].product_id != '604aff3691b7c80008ca11a8') {
                this.add_data.push({
                  label: data1[key].product_name + ' Event Commission(per event)',
                  name: data1[key].product_name.replace(/[-' ']/g, '_') + data1[key].commission_type,
                  value: data1[key].commission_value,
                  type: 'number',
                  id: data1[key].product_id,
                  prefix: '$',
                  validations: [{ rule: 'required', message: 'Commission is Required' }, { rule: 'min', value: 0 },],
                  after: this.add_data[this.add_data.length - 1].name
                })
              }
            }
          }
        }
        console.log(this.add_data, 'this.add_data');

        setTimeout(() => {

          console.log(this.formdata.fields);
          this.formfieldrefreshdata = {
            field: 'addfromcontrol',
            value: this.add_data
          };
        }, 50);

        // }
        // }
        // }
      })
    }
    if (val.field == 'fromsubmit' && val.fieldval == 'success') {
      // console.log(this.formdata);

      let send_data = val.fromval;
      // let remove_same_commission_data = [];
      // console.log(send_data);
      for (const key1 in this.product_details) {
        let check_name1 = this.product_details[key1].product_name.replace(/[-' ']/g, '_') + this.product_details[key1].commission_type;
        // console.log(send_data[check_name1]);

        if (typeof (send_data[check_name1]) != 'undefined' && send_data[check_name1] != null && send_data[check_name1] != '') {
          if (send_data[check_name1] == this.product_details[key1].commission_value) {
            delete send_data[check_name1];
          }
        }
      }
      console.log(send_data);
      let add_user_data = {
        data: send_data,
        token: this.userservice.jwttoken,
        secret: this.userservice.secret
      }
      this.apiservice.getDataWithoutToken(add_user_data, 'api/addusers').subscribe((data: any) => {
        // console.log(data);
        if (data.status == 'success') {
          if (this.router.url == '/admin/technological-consultant/add') {
            this._snackBar.open('User added successfully', 'Dismiss', {
              duration: 3000
            });
          } else {
            this._snackBar.open('User edited successfully', 'Dismiss', {
              duration: 3000
            });
          }
          this.router.navigateByUrl('/admin/list');
        }
      })
      // console.log(remove_same_commission_data);
      // if(remove_same_commission_data.length > 0 && remove_same_commission_data.length == 1){
      //   this.formfieldrefreshdata = { field: 'removefromcontrol', value: {name:remove_same_commission_data[0]}};
      // }else{
      //   this.formfieldrefreshdata = { field: 'removefromcontrol', value:remove_same_commission_data};
      // }
      // console.log(delete_position);
      // for (const iterator of delete_position) {
      //   send_data.splice(iterator,1);
      // }
      // console.log(send_data);

    }
    //   if (val.field.name == 'disableproduct') {
    //     
    // }
  }
  salespersondata() {
    const data = {
      secret: this.userservice.secret,
      token: this.cookies.get('jwtToken'),
      type: 'sales-person',
    };
    this.apiservice
      .getDataWithoutToken(data, 'api/getalluser')
      .subscribe((res: any) => {
        for (let y in res.result) {
          if (Object.prototype.hasOwnProperty.call(res.result, y)) {
            this.parentidVal.push({
              val: res.result[y]._id,
              name: res.result[y].firstname + ' ' + res.result[y].lastname,
            });
          }
        }
      });
  }
  userselection() {
    this.apiservice
      .getDataWithoutToken(
        {
          type: 'distributor',
        },
        'api/alltypeusers'
      )
      .subscribe((response: any) => {
        console.log(response);

        // this.productVal=response.res
        // // console.log(this.productVal,'madhumitaaaaa')
        for (const i in response.results.res) {
          if (Object.prototype.hasOwnProperty.call(response.results.res, i)) {
            this.typeVal.push({
              val: response.results.res[i]._id,
              name: response.results.res[i].fullname,
            });
          }
        }
      });
  }

  ngOnInit() {
    if (this.userservice.type == 'admin') {
      this.formdata.cancelroute = 'admin/list';
      this.formdata.redirectpath = 'admin/list';
    }
  }
  edittechnology() {
    if (this.activatedRoute.snapshot.params._id != null) {
      this.activatedRoute.data.subscribe((res: any) => {
        // console.log(res.resolvedata.result, '++++++++++++++++++++++++++++++++');
        this.userData = res.resolvedata.result[0];
        this.resolveval = this.userData;
        // let listner_val = {
        //   field:{name : 'parentid'},
        //   fieldval:this.resolveval.parentid
        // }
        // this.listenFormFieldChange(listner_val);
        this.formdata = {
          successmessage: 'Edit Successfully !!',
          redirectpath: 'list',
          submitactive: true, // optional, default true
          submittext: 'Update',
          resettext: 'Reset',
          canceltext: 'Cancel',
          apiUrl: this.apiservice.api_url,
          // endpoint: 'api/addusers', //
          jwttoken: this.cookies.get('jwtToken'),
          cancelroute: 'list',

          fields: [
            {
              label: 'First Name',
              name: 'firstname',
              value: this.resolveval.firstname,
              type: 'text',
              // parttern:/^[a-zA-Z\s]*$/,
              validations: [
                { rule: 'required', message: 'First Name is Required' },
                // {
                //   rule: 'pattern',
                //   value: this.textregex,
                //   message: 'only use alphabet',
                // },
              ],
            },

            {
              heading: '',
              label: 'Last Name',
              name: 'lastname',
              value: this.resolveval.lastname,
              type: 'text',
              validations: [
                { rule: 'required', message: 'Last Name is Required' },
                // {
                //   rule: 'pattern',
                //   value: this.textregex,
                //   message: 'only use alphabet',
                // },
              ],
            },

            {
              label: 'Telephone',
              name: 'phone',
              value: this.resolveval.phone,
              type: 'number',
              validations: [
                { rule: 'required', message: 'Telephone is Required' },
                {
                  rule: 'minLength',
                  value: 10,
                  message: 'The Phone Number must be 10 digits',
                },
                {
                  rule: 'maxLength',
                  value: 15,
                  message: 'Please enter the valid number',
                },
              ],
            },


            {
              label: 'Email',
              name: 'email',
              type: 'email',
              disabled: true,
              hint: '',
              value: this.resolveval.email,
              //  disabled: true,
              validations: [
                { rule: 'required', message: 'Email is required' },
                {
                  rule: 'pattern',
                  value: this.emailregex,
                  message: 'Must be a valid Email',
                },
              ],
            },
            {
              label: 'Company Name',
              name: 'company',
              value: this.resolveval.company,
              type: 'text',
              validations: [
                { rule: 'required', message: 'Company Name is Required' },
              ],
            },
            {
              label: 'Address',
              name: 'address',
              value: this.resolveval.address,
              type: 'textarea',
              validations: [
                { rule: 'required', message: 'Address is Required' },
              ],
            },
            {
              label: 'type',
              name: 'type',
              type: 'hidden',
              value: 'technological-consultant',
            },
            {
              label: 'State',
              name: 'state',
              val: this.stateVal,
              value: this.resolveval.state,
              type: 'select',
              validations: [{ message: 'State is Required' }],
            },

            {
              label: 'City',
              name: 'city',
              value: this.resolveval.city,
              type: 'text',
              validations: [{ rule: 'required', message: 'City is Required' }],
            },

            {
              label: 'Zip',
              name: 'zip',
              value: this.resolveval.zip,
              type: 'number',
              validations: [
                { rule: 'required', message: 'Zip is Required' },
                {
                  rule: 'maxLength',
                  value: 5,
                  message: 'Please enter the valid Zip ID',
                },
              ],
            },

            {
              label: 'Distributor',
              name: 'parentid',
              value: this.resolveval.parentid,
              val: this.typeVal,
              type: 'select',
              validations: [
                { rule: 'required', message: 'Distributor is Required' },
              ],
            },
            // {
            //   label: 'On Bording Call',
            //   name: 'onbordingcall',
            //   hint: '',
            //   type: 'checkbox',
            //   val: this.onbording,
            //   value: this.resolveval.onbordingcall,
            // },
            {
              label: 'Active',
              name: 'status',
              hint: '',
              type: 'checkbox',
              value: this.resolveval.status,
            },
            // {
            //   label: 'On Bording Call',
            //   name: 'onbordingcall',
            //   hint: '',
            //   type: 'checkbox',
            //   value: this.resolveval.status,
            // },
            {
              label: 'id',
              name: 'id',
              value: this.activatedRoute.snapshot.params._id,
              type: 'hidden',
            },
          ],
        };
        if (JSON.parse(this.cookies.get('type')) == 'distributor') {
          this.formdata.cancelroute = 'distributor/users';
          this.formdata.redirectpath = 'distributor/users';
        }
        if (JSON.parse(this.cookies.get('type')) == 'distributor') {
          this.formdata.fields[9].type = 'hidden';
          this.formdata.fields[9].value = JSON.parse(
            this.cookies.get('userid')
          );
          this.formdata.fields[9].validations = [];
        }
      });
    }
  }
}
