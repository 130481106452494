import { Compiler, Component, Inject, OnInit, TemplateRef } from "@angular/core";
import {
  MatDialog,
  MatDialogRef,
  MatSnackBar,
  MAT_DIALOG_DATA,
} from "@angular/material";
import { Router, ActivatedRoute, Params } from "@angular/router";
import { CookieService } from "ngx-cookie-service";
import { ApiService } from "src/app/services/api.service";
import { DatePipe } from "@angular/common";
import { LoaderService } from "src/app/services/loader.service";
import { UserserviceService } from "src/app/services/userservice.service";
import { environment } from "src/environments/environment";
import { takeUntil } from "rxjs/operators";
import { ReplaySubject } from "rxjs";
import { DetectdomainService } from "src/app/services/detectdomain.service";


export interface DialogData {
  data: any;
  sign: any;
}

@Component({
  selector: "app-contract",
  templateUrl: "./contract.component.html",
  styleUrls: ["./contract.component.css"],
})
export class ContractComponent implements OnInit {
  private unsubscribe: ReplaySubject<boolean> = new ReplaySubject(1);
  public datalist: any;
  public sourceconditionval: any;
  public sourceval = "users";
  public errorflag: any = false;
  public signval;
  public val;
  public userData: any = {
    todateDate: Date.now(),
    fullname: "",
    fulladdress: "",
    sign: "",
  };
  public alldata: any;
  public submitbtnflag = false;
  public product_details: any = [];
  public parent_product_info: any = [];
  public commission_data: any = [];
  public commission_data_flag = false;
  // public signup_process:boolean;
  public medigradeflag: boolean= false;
  public betoparedesflag: boolean= false;
  public domainName:any;

  constructor(
    private router: Router,
    private cookeiservice: CookieService,
    public activatedroute: ActivatedRoute,
    public dialog: MatDialog,
    public apiservice: ApiService,
    public snakBar: MatSnackBar,
    public datepipe: DatePipe,
    private loaderService: LoaderService,
    public userService: UserserviceService,
    public compiler: Compiler,
    public detectdomain:DetectdomainService,

  ) {
    this.getproductdetails();
    console.log('randomstringrandomstringrandomstringrandomstringrandomstring');
    
    this.cookeiservice.set(
      "randomstring",
      JSON.stringify(this.activatedroute.snapshot.params.randomstring),
      undefined,
      "/"
    );
    this.getsignupdetails();
    let send_data = {
      data:{
        signup_process: 'find',
      }
    }
    // this.apiservice.getDataWithoutToken(send_data,'api10/change-signup-process').subscribe((res:any)=>{
    //     if(res.result[0].signup_process == 'On'){
    //       this.signup_process = true;
    //     }else{
    //       this.signup_process = false;
    //     }
    // })

    this.domainName = this.detectdomain.getdomain()
      console.log("domainName betoparedesflag==>",this.domainName);
      if (this.domainName == "betoparedes") {
        this.betoparedesflag = true;
        this.medigradeflag = false;
      }
  }

  ngOnInit() {
      // this.openSnackBar('Token not found', null);
      this.apiservice
        .customRequest({}, "temptoken", environment["api_calender_url"])
        .pipe(takeUntil(this.unsubscribe))
        .subscribe((response: any) => {
          this.cookeiservice.set(
            "jwt_for_calendar",
            response.token,
            undefined,
            "/"
          );
          // console.log(response.token, 'hhjhgh======', response);
        });
    this.activatedroute.data.forEach((res: any) => {
      if (res.resolvedata.item[0].length > 0) {
        // console.log(JSON.stringify(this.cookeiservice.getAll()));
        this.cookeiservice.deleteAll(); // cookie service delete
        this.cookeiservice.deleteAll("/");
        this.compiler.clearCache();
        localStorage.clear();
        // console.log(JSON.stringify(this.cookeiservice.getAll()));
        this.alldata = res.resolvedata;
        this.userData = res.resolvedata.item[0][0];
        this.userData.todateDate = Date.now();
        this.userData.fullname =
          this.userData.firstname + " " + this.userData.lastname;
        this.userData.sign = "";

        // console.log(this.userData, 'admin userlist');

        this.cookeiservice.set(
          "type",
          JSON.stringify(this.userData.type),
          undefined,
          "/"
        );
        this.cookeiservice.set(
          "status",
          JSON.stringify(this.userData.status),
          undefined,
          "/"
        );
        this.cookeiservice.set(
          "useremail",
          JSON.stringify(this.userData.email),
          undefined,
          "/"
        );
        this.cookeiservice.set(
          "firstname",
          JSON.stringify(this.userData.firstname),
          undefined,
          "/"
        );
        this.cookeiservice.set(
          "lastname",
          JSON.stringify(this.userData.lastname),
          undefined,
          "/"
        );
        this.cookeiservice.set(
          "userid",
          JSON.stringify(this.userData._id),
          undefined,
          "/"
        );
        this.cookeiservice.set(
          "parentid",
          JSON.stringify(this.userData.parentid),
          undefined,
          "/"
        );
        this.cookeiservice.set(
          "recent_tech",
          JSON.stringify(this.userData.recent_tech),
          undefined,
          "/"
        );
        // this.cookeiservice.set('from_login','true');
        this.cookeiservice.set("jwtToken", this.alldata.token, undefined, "/");
        this.cookeiservice.set("secret", this.alldata.secret, undefined, "/");
      } else {
        this.router.navigateByUrl("/login");
      }
    });
    // console.log(this.userData, 'admin userlist');
  }
  getproductdetails() {
    this.apiservice
      .getDataWithoutToken(
        {
          token: this.userService.jwttoken,
          secret: this.userService.secret,
          searchcondition: {
            user_type: "distributor",
          },
        },
        "api10/get-commission-rule"
      )
      .subscribe((response: any) => {
        this.product_details = response.res;
        this.parent_data_details();
      });
  }
  parent_data_details() {
    this.commission_data_flag = true;
    if (this.userData.parentid != null && this.userData.parentid != "") {
      let send_data = {
        parent_id: this.userData.parentid,
      };
      this.apiservice
        .getDataWithoutToken(send_data, "api10/get-parent-product")
        .subscribe((res: any) => {
          console.log(res);
          this.parent_product_info = res.res[0];
          for (const iterator of res.res[0].disableproduct) {
            for (const key in this.product_details) {
              if (this.product_details[key].product_id == iterator) {
                this.commission_data.push(this.product_details[key]);
              }
            }
          }
          for (let key in this.commission_data) {
            let check_name =
              this.commission_data[key].product_name.replace(/[-" "]/g, "_") +
              this.commission_data[key].commission_type;
            console.log(check_name);
            if (
              check_name == "PECEevent_commission" &&
              this.parent_product_info.PECEevent_commission != null &&
              this.parent_product_info.PECEevent_commission != ""
            ) {
              this.commission_data[key].commission_value =
                this.parent_product_info.PECEevent_commission;
            }
            if (
              check_name == "PECEsales_commission" &&
              this.parent_product_info.PECEsales_commission != null &&
              this.parent_product_info.PECEsales_commission != ""
            ) {
              this.commission_data[key].commission_value =
                this.parent_product_info.PECEsales_commission;
            }
            if (
              check_name == "TM_FLOW_ANS_MEDICAL_DEVICEsales_commission" &&
              this.parent_product_info
                .TM_FLOW_ANS_MEDICAL_DEVICEsales_commission != null &&
              this.parent_product_info
                .TM_FLOW_ANS_MEDICAL_DEVICEsales_commission != ""
            ) {
              this.commission_data[key].commission_value =
                this.parent_product_info.TM_FLOW_ANS_MEDICAL_DEVICEsales_commission;
            }
            if (
              check_name == "RST_Sanexussales_commission" &&
              this.parent_product_info.RST_Sanexussales_commission != null &&
              this.parent_product_info.RST_Sanexussales_commission != ""
            ) {
              this.commission_data[key].commission_value =
                this.parent_product_info.RST_Sanexussales_commission;
            }
            if (
              check_name == "Bio_Energeticsevent_commission" &&
              this.parent_product_info.Bio_Energeticsevent_commission != null &&
              this.parent_product_info.Bio_Energeticsevent_commission != ""
            ) {
              this.commission_data[key].commission_value =
                this.parent_product_info.Bio_Energeticsevent_commission;
            }
            if (
              check_name == "Bio_Energeticssales_commission" &&
              this.parent_product_info.Bio_Energeticssales_commission != null &&
              this.parent_product_info.Bio_Energeticssales_commission != ""
            ) {
              this.commission_data[key].commission_value =
                this.parent_product_info.Bio_Energeticssales_commission;
            }
          }
          this.commission_data_flag = false;
        });
    } else {
      this.commission_data_flag = false;
    }
  }
  getsignupdetails() {}
  sign1(val) {
    // console.log('test', val);
    const dialogRef = this.dialog.open(Signmodal, {
      // width: '250px',
      data: { sign: "" },
      panelClass: "signmodal",
    });
    dialogRef.disableClose = true;
    dialogRef.afterClosed().subscribe((result) => {
      // console.log('The dialog was closed', result);

      this.userData.sign = result.sign;
      this.errorflag = this.userData.sign == "" ? true : false;
    });
  }
  savesignval() {}
  logOut() {
    this.cookeiservice.deleteAll(); // cookie service delete
    this.cookeiservice.deleteAll("/");
    // this.compiler.clearCache();
    localStorage.clear(); // local storage clear
    setTimeout(() => {
      // this.compiler.clearCache(); // cache clear
      this.router.navigateByUrl("/", { skipLocationChange: true }).then(() => {
        this.router.navigate(["/"]);
      }); // after loguot navigate to login part
    }, 300);
  }

  dosubmit() {
    let x: any;
    // console.log('test', this.alldata);
    // console.log('test', this.userData);

    // return
    this.errorflag = this.userData.sign == "" ? true : false;
    if (this.userData.sign != "") {
      // console.log('+++++', this.submitbtnflag)
      this.submitbtnflag = true;

      this.loaderService.isLoading.next(true);
      let data = {
        data: {
          user_id: this.userData._id,
          user_signature: this.userData.sign,
          location: this.userData.fulladdress,
          username: this.userData.fullname,
          firstName: this.userData.firstname,
          lastName: this.userData.lastname,
          email: this.userData.email,
          phone: this.userData.phone,
          date: this.datepipe.transform(this.userData.todateDate, "MM-dd-yyyy"),
          signature_date: this.datepipe.transform(
            this.userData.todateDate,
            "MM-dd-yyyy"
          ),
          signature_date_unix: this.userData.todateDate,
          commission_data: this.commission_data
        },
      };

      if (
        typeof this.userData.salesrep_f_name != "undefined" &&
        this.userData.salesrep_f_name != null &&
        this.userData.salesrep_f_name != ""
      ) {
        data.data["salesrep_f_name"] = this.userData.salesrep_f_name;
        data.data["salesrep_l_name"] = this.userData.salesrep_l_name;
        data.data["salesrep_email"] = this.userData.salesrep_email;
      }
      // for (const key in this.commission_data) {
      //   if(this.commission_data[key].commission_type == 'sales_commission'){
      //     data.data['commission_details'].push(`<h5 style="display: flex; align-items: baseline">${this.commission_data[key].product_name} Sales Commission: <p style="margin-left: 12px">${this.commission_data[key].commission_value}%</p></h5>`)
      //   }
      //   if(this.commission_data[key].commission_type == 'event_commission'){
      //     data.data['commission_details'].push(`<h5 style="display: flex; align-items: baseline">${this.commission_data[key].product_name} Event Commission: <p style="margin-left: 12px">$${this.commission_data[key].commission_value}</p></h5>`)
      //   }
      // }
      // console.error(data);
      
      this.apiservice
        .getDataWithoutToken(data, "api/contractupdate")
        .subscribe((response: any) => {
          if (response.status == "success") {
            this.snakBar.open("Signature added successfully", "OK", {
              duration: 3000,
            });
            this.cookeiservice.set(
              "signature",
              JSON.stringify(this.userData.sign),
              undefined,
              "/"
            );
            let send_data = {
              data:{
                id:this.userData._id,
                recent_tech:true
              },
              token:this.cookeiservice.get('jwtToken'),
              secret:this.cookeiservice.get('secret')
            }
            this.apiservice.getDataWithoutToken(send_data,'api/userupdate').subscribe((res:any)=>{
              setTimeout(() => {
                this.submitbtnflag = false;
                this.loaderService.isLoading.next(false);
                this.router.navigateByUrl(
                  "/calendar-management/manage-appointment-after-sign/on-boarding/book-appointment"
                );
              }, 500);
            })
            // setTimeout(() => {
            //   window.open("https://awsbackend-dev-patient-files-test.s3.amazonaws.com/beto-paredes-contracts-pdf/contract_" + this.userData._id + ".pdf", "_blank");
            // }, 990);
            // if(this.signup_process){
            // }else{
            //   this.loaderService.isLoading.next(false);
            //   this.router.navigateByUrl('/block_onbordingcall');
            // }
          } else {
            this.submitbtnflag = false;
            this.loaderService.isLoading.next(false);
          }
        });
    }
  }
  printcontract() {
    window.print();
  }
}

@Component({
  selector: "signmodal",
  templateUrl: "signmodal.html",
})
export class Signmodal {
  public date = Date.now();
  public errorflag = false;
  public medigradeflag:boolean=false;
  public betoparedesflag:boolean=false;
  public domainName:any;

  constructor(
    public detectdomain:DetectdomainService,
    public dialogRef: MatDialogRef<Signmodal>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData
  ) {
    this.domainName = this.detectdomain.getdomain()
      console.log("domainName for medigrade==>",this.domainName);
      if (this.domainName == "medigrade") {
        this.medigradeflag = true;
        this.betoparedesflag = false;
      }
  }

  onNoClick(val: any): void {
    // console.log(this.data, '+++++', val)
    if (val == "") {
      this.errorflag = true;
    } else {
      this.errorflag = false;
      this.dialogRef.close(this.data);
    }
  }
}
