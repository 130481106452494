import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material';
import { ActivatedRoute, Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { ApiService } from 'src/app/services/api.service';
import { UserserviceService } from 'src/app/services/userservice.service';

@Component({
  selector: 'app-distributor-add-edit',
  templateUrl: './distributor-add-edit.component.html',
  styleUrls: ['./distributor-add-edit.component.css']
})
export class DistributorAddEditComponent implements OnInit {
  emailregex: RegExp = /^(([^<>()\[\]\\.,;:\s@']+(\.[^<>()\[\]\\.,;:\s@']+)*)|('.+'))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  passwordregex: RegExp = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})/;
  textregex: RegExp = /^[a-zA-Z]*$/;
  temtdata: any = '';
  // formdata
  public flag: any;
  formfieldrefresh: any = false;
  updatetable: any = false;
  formfieldrefreshdata: any = null;
  datasource: any;
  public formdata: any;
  public stateVal: any = [];
  public status: any = [
    { val: 1, name: 'Active' },
    { val: 0, name: 'Inactive' },
  ];

  public userData: any = [];
  public resolveval: any;
  public onbording: any = [
    { val: 1, name: 'Yes' },
    { val: 0, name: 'No' },
  ];
  public product_details: any = [];
  public productValdata: any = [];
  public selected_product: any = ["604aff3691b7c80008ca11a8", "604a0b6e40962e00088f0d79", "612c883f118c110009f02820", "612c89242005f400082d5fb1"];
  public landingPage: any = [
    { val: 'pece', name: 'PECE' },
    { val: 'peceandtmflow', name: 'PECE AND TM-Flow' },
    { val: 'bioenergetics', name: 'BioEnergetics' },
    { val: 'quadrant', name: 'Quadrant' },
  ]
  public edit_selected_product: any = [];
  constructor(
    public apiservice: ApiService,
    public http: HttpClient,
    public activatedRoute: ActivatedRoute,
    public cookies: CookieService,
    public router: Router,
    public userService: UserserviceService,
    public _snackBar: MatSnackBar

  ) {
    this.getproductlist();
    this.getproductdetails();
    this.apiservice.getState().subscribe((res: any) => {
      for (const i in res) {
        if (Object.prototype.hasOwnProperty.call(res, i)) {
          this.stateVal.push({ val: res[i].abbreviation, name: res[i].name });
        }
      }
    });
    this.formdata = {
      successmessage: 'Added Successfully !!',
      redirectpath: 'distributor/list',
      submitactive: true, // optional, default true
      submittext: 'Submit',
      resettext: 'Reset',
      canceltext: 'Cancel',
      apiUrl: this.apiservice.api_url,
      // endpoint: 'api/addusers', //
      jwttoken: '',
      cancelroute: 'distributor/list',

      fields: [
        {
          label: 'First Name',
          name: 'firstname', //
          value: '',
          type: 'text',
          validations: [
            { rule: 'required', message: 'First Name is Required' },
            {
              rule: 'pattern',
              value: this.textregex,
              message: 'only use alphabet',
            },
          ],
        },

        {
          heading: '',
          label: 'Last Name',
          name: 'lastname',
          value: '',
          type: 'text',
          validations: [
            { rule: 'required', message: 'Last Name is Required' },
            {
              rule: 'pattern',
              value: this.textregex,
              message: 'only use alphabet',
            },
          ],
        },

        {
          label: 'Telephone',
          name: 'phone',
          value: '',
          type: 'number',
          validations: [
            { rule: 'required', message: 'Telephone is Required' },
            {
              rule: 'minLength',
              value: 10,
              message: 'The Phone Number must be 10 digits',
            },
            {
              rule: 'maxLength',
              value: 15,
              message: 'Please enter the valid number',
            },
          ],
        },

        {
          label: 'Email',
          name: 'email',
          type: 'email',
          hint: '',

          validations: [
            { rule: 'required', message: 'Email is required' },
            {
              rule: 'pattern',
              value: this.emailregex,
              message: 'Must be a valid Email',
            },
          ],
        },

        {
          label: 'Company Name',
          name: 'company',
          value: '',
          type: 'text',
          validations: [{ rule: 'required', message: 'Company Name is Required' }],
        },

        {
          label: 'Address',
          name: 'address',
          value: '',
          type: 'textarea',
          validations: [{ rule: 'required', message: 'Address is Required' }],
        },



        {
          label: 'type',
          name: 'type',
          type: 'hidden',
          value: 'distributor',
        },
        {
          label: 'State',
          name: 'state',
          val: this.stateVal,
          value: '',
          type: 'select',
          validations: [{ rule: 'required', message: 'State is Required' }],
        },

        {
          label: 'City',
          name: 'city',
          value: '',
          type: 'text',
          validations: [{ rule: 'required', message: 'City is Required' }],
        },

        {
          label: 'Zip',
          name: 'zip',
          value: '',
          type: 'number',
          validations: [
            { rule: 'required', message: 'Zip is Required' },
            {
              rule: 'maxLength',
              value: 5,
              message: 'Please enter the valid Zip ID',
            },
          ],
        },
        {
          label: 'Password',
          name: 'password',
          type: 'password',
          passwordflag: true,
          value: '',

          validations: [
            {
              rule: 'required',
              message:
                'Your provided Password must contain at-least one Uppercase letter, one Lower case letter, and one Number.',
            },
            {
              rule: 'pattern',
              value: this.passwordregex,
              message: 'Must contain a Capital Letter and a Number',
            },
          ],
        },
        {
          label: 'Confirm Password',
          name: 'confirmpassword',
          type: 'password',
          passwordflag: false,
          value: '',
          validations: [
            {
              rule: 'required',
              message:
                'Your provided Password must contain at-least one Uppercase letter, one Lower case letter, and one Number.',
            },
            {
              rule: 'match',
              message: 'Confirm Password field Needs to  match Password',
            },
            {
              rule: 'pattern',
              value: this.passwordregex,
              message: 'Must contain a Capital Letter and a Number',
            },
          ],
        },
        {
          label: "Products",
          name: "disableproduct",
          val: this.productValdata,
          value: this.selected_product,
          type: "select",
          multiple: true,
          validations: [{ rule: "required", message: "Products  is Required" }],
        },
        {
          label: "Landing Page",
          name: "landing_page_name",
          val: this.landingPage,
          value: '',
          type: "select",
          multiple: true,
          validations: [{ rule: "required", message: "Landing Page is Required" }],
        },
        {
          label: 'On Bording Call',
          name: 'onbordingcall',
          hint: '',
          type: 'hidden',
          val: this.onbording,

        },
        {
          label: 'Active',
          name: 'status',
          hint: '',
          type: 'checkbox',
          val: this.status,
          value: 0,
        },
      ],
    };

    if (this.activatedRoute.snapshot.routeConfig.path == 'distributor/add') {

      this.formdata.cancelroute = 'admin/list';
      this.formdata.redirectpath = 'admin/list';
    }

    if (this.activatedRoute.snapshot.params._id != null) {
      this.flag = true;
      this.landingPage = [];
      this.editdistributor();
    }
    if (this.userService.type == "distributor") {
      this.formdata.cancelroute = 'distributor/users';
      this.formdata.redirectpath = 'distributor/users';
    }
  }

  ngOnInit() {
    if (this.userService.type == 'admin') {
      this.formdata.cancelroute = 'admin/list';
      this.formdata.redirectpath = 'admin/list';
    }
    console.log(this.formdata.fields);

  }
  listenFormFieldChange(val) {
    console.log(val);
    if (val.field.name == "disableproduct") {
      for (const iterator1 of this.selected_product) {
        let flag = true;
        for (let iterator of val.fieldval) {
          if (iterator1 == iterator) {
            flag = false;
          }
        }
        if (flag) {
          // console.log(iterator1);
          let data = this.formdata.fields.filter(product => product.id == iterator1);
          console.log(data, "adatatatatat");

          let namefield = [];
          let namefield1 = '';
          if (data.length != 0) {
            if (data.length > 1) {
              for (const key in data) {
                namefield.push(data[key].name);
                console.log(namefield);
              }
              this.formfieldrefreshdata = { field: 'removefromcontrol', value: namefield };
            } else {
              for (const key in data) {
                namefield1 = data[key].name;
                console.log(namefield1);
              }
              this.formfieldrefreshdata = { field: 'removefromcontrol', value: { name: namefield1 } };
            }
          }
        } else {
          let data = this.formdata.fields.filter(product => product.id == iterator1);
          if (data.length == 0) {
            let add_data = [];
            let data1 = this.product_details.filter(product => product.product_id == iterator1);
            console.log(data1, "data1");
            let added_place = "";
            if (this.router.url == '/admin/distributor/add') {
              added_place = this.formdata.fields[this.formdata.fields.length - 3].name
            } else {
              added_place = this.formdata.fields[this.formdata.fields.length - 4].name
            }
            for (let key in data1) {
              if (data1[key].commission_type == 'sales_commission') {
                if (data1[key].product_id == '604aff3691b7c80008ca11a8') {
                  add_data.push({
                    label: data1[key].product_name + " Practice Testing Center Program(%)",
                    name: data1[key].product_name.replace(/[-" "]/g, '_') + data1[key].commission_type,
                    value: data1[key].commission_value,
                    type: "number",
                    id: data1[key].product_id,
                    suffix: '%',
                    validations: [{ rule: "required", message: "Commission is Required" }, { rule: 'min', value: 0 },],
                    after: added_place
                  })
                }
                if (data1[key].product_id != '612c883f118c110009f02820' && data1[key].product_id != '604aff3691b7c80008ca11a8') {
                  add_data.push({
                    label: data1[key].product_name + " Sales Commission(%)",
                    name: data1[key].product_name.replace(/[-" "]/g, '_') + data1[key].commission_type,
                    value: data1[key].commission_value,
                    type: "number",
                    id: data1[key].product_id,
                    suffix: '%',
                    validations: [{ rule: "required", message: "Commission is Required" }, { rule: 'min', value: 0 },],
                    after: added_place
                  })
                }
              }
              if (data1[key].commission_type == 'event_commission') {
                if (data1[key].product_id == '604aff3691b7c80008ca11a8') {
                  add_data.push({
                    label: data1[key].product_name + " Standard Qualified Practice Program(per event)",
                    name: data1[key].product_name.replace(/[-" "]/g, '_') + data1[key].commission_type,
                    value: data1[key].commission_value,
                    type: "number",
                    id: data1[key].product_id,
                    prefix: '$',
                    validations: [{ rule: "required", message: "Commission is Required" }, { rule: 'min', value: 0 },],
                    after: added_place
                  })
                }
                if (data1[key].product_id != '604aff3691b7c80008ca11a8') {
                  add_data.push({
                    label: data1[key].product_name + " Event Commission(per event)",
                    name: data1[key].product_name.replace(/[-" "]/g, '_') + data1[key].commission_type,
                    value: data1[key].commission_value,
                    type: "number",
                    id: data1[key].product_id,
                    prefix: '$',
                    validations: [{ rule: "required", message: "Commission is Required" }, { rule: 'min', value: 0 },],
                    after: added_place
                  })
                }
              }
            }
            console.log(add_data, "add_data");
            this.formfieldrefreshdata = {
              field: 'addfromcontrol',
              value: add_data
            };
            // setTimeout(() => {
            //   console.log(this.formdata.fields);
            // }, 100);
          }
        }
      }
      setTimeout(() => {
        this.formfieldrefreshdata = { field: 'removefromcontrol', value: { name: 'landing_page_name' } };
      }, 100);
      this.landingPage = [];
      console.log(this.landingPage);
      for (const id of val.fieldval) {
        if (id == '604aff3691b7c80008ca11a8') {
          this.landingPage.push({ val: 'pece', name: 'PECE' })
        }
        if (id == '604a0b6e40962e00088f0d79') {
          this.landingPage.push({ val: 'peceandtmflow', name: 'PECE AND TM-Flow' })
        }
        if (id == '612c883f118c110009f02820') {
          this.landingPage.push({ val: 'bioenergetics', name: 'BioEnergetics' })
        }
        if (id == '612c89242005f400082d5fb1') {
          this.landingPage.push({ val: 'quadrant', name: 'Quadrant' })
        }
      }
      console.log(this.landingPage);
      setTimeout(() => {
        this.formfieldrefreshdata = {
          field: 'addfromcontrol', value: {
            label: "Landing Page",
            name: "landing_page_name",
            val: this.landingPage,
            value: '',
            type: "select",
            multiple: true,
            validations: [{ rule: "required", message: "Landing Page is Required" }],
            after: 'onbordingcall',
          }
        };
      }, 200);
    }
    if (val.field == 'fromsubmit' && val.fieldval == "success") {
      // console.log(this.formdata);

      let send_data = val.fromval;
      send_data['landing_page'] = [];
      // let remove_same_commission_data = [];
      console.log(send_data);
      for (const key1 in this.product_details) {
        let check_name1 = this.product_details[key1].product_name.replace(/[-" "]/g, '_') + this.product_details[key1].commission_type;
        // console.log(send_data[check_name1]);

        if (typeof (send_data[check_name1]) != 'undefined' && send_data[check_name1] != null && send_data[check_name1] != '') {
          if (send_data[check_name1] == this.product_details[key1].commission_value) {
            delete send_data[check_name1];
          }
        }
      }
      for (const key in send_data.landing_page_name) {
        if (send_data.landing_page_name[key] == 'pece') {
          send_data.landing_page.push('604aff3691b7c80008ca11a8');
        }
        if (send_data.landing_page_name[key] == 'peceandtmflow') {
          if (send_data.landing_page_name.find(e => e == 'pece')) {
            send_data.landing_page.splice(key, 1);
            send_data.landing_page.push('604a0b6e40962e00088f0d79');
          } else {
            send_data.landing_page.push('604aff3691b7c80008ca11a8');
            send_data.landing_page.push('604a0b6e40962e00088f0d79');
          }
        }
        if (send_data.landing_page_name[key] == 'bioenergetics') {
          send_data.landing_page.push('612c883f118c110009f02820');
        }
        if (send_data.landing_page_name[key] == 'quadrant') {
          send_data.landing_page.splice(0, send_data.landing_page_name.length);
          send_data.landing_page.push('604aff3691b7c80008ca11a8');
          send_data.landing_page.push('604a0b6e40962e00088f0d79');
          send_data.landing_page.push('612c883f118c110009f02820');
          send_data.landing_page.push('612c89242005f400082d5fb1');

        }
      }
      let add_user_data = {
        data: send_data,
        token: this.userService.jwttoken,
        secret: this.userService.secret
      }
      console.log(add_user_data);

      this.apiservice.getDataWithoutToken(add_user_data, 'api/addusers').subscribe((data: any) => {
        // console.log(data);
        if (data.status == "success") {
          if (this.router.url == '/admin/distributor/add') {
            this._snackBar.open('User added successfully', 'Dismiss', {
              duration: 3000
            });
          } else {
            this._snackBar.open('User edited successfully', 'Dismiss', {
              duration: 3000
            });
          }
          this.router.navigateByUrl('/admin/list');
        }
      })
      // console.log(remove_same_commission_data);
      // if(remove_same_commission_data.length > 0 && remove_same_commission_data.length == 1){
      //   this.formfieldrefreshdata = { field: 'removefromcontrol', value: {name:remove_same_commission_data[0]}};
      // }else{
      //   this.formfieldrefreshdata = { field: 'removefromcontrol', value:remove_same_commission_data};
      // }
      // console.log(delete_position);
      // for (const iterator of delete_position) {
      //   send_data.splice(iterator,1);
      // }
      console.log(send_data);

    }
  }
  getproductlist() {
    this.apiservice
      .getDataWithoutToken(
        {
          token: this.userService.jwttoken,
          secret: this.userService.secret,
        },
        "api/productsearch"
      )
      .subscribe((response: any) => {
        // this.productVal=response.res
        // // console.log(this.productVal,'madhumitaaaaa')
        for (const i in response.res) {
          if (Object.prototype.hasOwnProperty.call(response.res, i)) {
            this.productValdata.push({
              val: response.res[i].val,
              name: response.res[i].name,
            });
          }
        }
      });
  }
  getproductdetails() {
    this.apiservice
      .getDataWithoutToken(
        {
          token: this.userService.jwttoken,
          secret: this.userService.secret,
          searchcondition: {
            user_type: 'distributor'
          }
        },
        "api10/get-commission-rule"
      )
      .subscribe((response: any) => {
        // this.productVal=response.res
        // // console.log(this.productVal,'madhumitaaaaa')
        this.product_details = response.res;
        console.log(this.product_details);
        let data = [];
        let selected_product_current = [];
        if (this.router.url == '/admin/distributor/add') {
          selected_product_current = this.selected_product;
        } else {
          selected_product_current = this.edit_selected_product;
          for (let key in this.product_details) {
            let check_name = this.product_details[key].product_name.replace(/[-" "]/g, '_') + this.product_details[key].commission_type;
            console.log(check_name);
            if (check_name == "PECEevent_commission" && this.userData.PECEevent_commission != null && this.userData.PECEevent_commission != '') {
              this.product_details[key].commission_value = this.userData.PECEevent_commission
            }
            if (check_name == "PECEsales_commission" && this.userData.PECEsales_commission != null && this.userData.PECEsales_commission != '') {
              this.product_details[key].commission_value = this.userData.PECEsales_commission
            }
            if (check_name == "TM_FLOW_ANS_MEDICAL_DEVICEsales_commission" && this.userData.TM_FLOW_ANS_MEDICAL_DEVICEsales_commission != null && this.userData.TM_FLOW_ANS_MEDICAL_DEVICEsales_commission != '') {
              this.product_details[key].commission_value = this.userData.TM_FLOW_ANS_MEDICAL_DEVICEsales_commission
            }
            if (check_name == "neoGENsales_commission" && this.userData.neoGENsales_commission != null && this.userData.neoGENsales_commission != '') {
              this.product_details[key].commission_value = this.userData.neoGENsales_commission
            }
            if (check_name == "BioEnergeticsevent_commission" && this.userData.BioEnergeticsevent_commission != null && this.userData.BioEnergeticsevent_commission != '') {
              this.product_details[key].commission_value = this.userData.BioEnergeticsevent_commission
            }
            if (check_name == "BioEnergeticssales_commission" && this.userData.BioEnergeticssales_commission != null && this.userData.BioEnergeticssales_commission != '') {
              this.product_details[key].commission_value = this.userData.BioEnergeticssales_commission
            }
          }
        }
        for (let iterator of selected_product_current) {
          for (let key in this.product_details) {
            if (this.product_details[key].product_id == iterator) {
              if (data.length == 0) {
                if (this.product_details[key].commission_type == 'sales_commission') {
                  if (iterator == '604aff3691b7c80008ca11a8') {
                    data.push({
                      label: this.product_details[key].product_name + " Practice Testing Center Program(%)",
                      name: this.product_details[key].product_name.replace(/[-" "]/g, '_') + this.product_details[key].commission_type,
                      value: this.product_details[key].commission_value,
                      type: "number",
                      id: this.product_details[key].product_id,
                      suffix: '%',
                      validations: [{ rule: "required", message: "Commission is Required" }, { rule: 'min', value: 0 },],
                      after: 'disableproduct'
                    })
                  }
                  if (iterator != '612c883f118c110009f02820' && iterator != '604aff3691b7c80008ca11a8') {
                    data.push({
                      label: this.product_details[key].product_name + " Sales Commission(%)",
                      name: this.product_details[key].product_name.replace(/[-" "]/g, '_') + this.product_details[key].commission_type,
                      value: this.product_details[key].commission_value,
                      type: "number",
                      id: this.product_details[key].product_id,
                      suffix: '%',
                      validations: [{ rule: "required", message: "Commission is Required" }, { rule: 'min', value: 0 },],
                      after: 'disableproduct'
                    })
                  }
                }
                if (this.product_details[key].commission_type == 'event_commission') {
                  if (iterator == '604aff3691b7c80008ca11a8') {
                    data.push({
                      label: this.product_details[key].product_name + " Standard Qualified Practice Program(per event)",
                      name: this.product_details[key].product_name.replace(/[-" "]/g, '_') + this.product_details[key].commission_type,
                      value: this.product_details[key].commission_value,
                      type: "number",
                      id: this.product_details[key].product_id,
                      prefix: '$',
                      validations: [{ rule: "required", message: "Commission is Required" }, { rule: 'min', value: 0 },],
                      after: 'disableproduct'
                    })
                  }
                  if (iterator != '604aff3691b7c80008ca11a8') {
                    data.push({
                      label: this.product_details[key].product_name + " Event Commission(per event)",
                      name: this.product_details[key].product_name.replace(/[-" "]/g, '_') + this.product_details[key].commission_type,
                      value: this.product_details[key].commission_value,
                      type: "number",
                      id: this.product_details[key].product_id,
                      prefix: '$',
                      validations: [{ rule: "required", message: "Commission is Required" }, { rule: 'min', value: 0 },],
                      after: 'disableproduct'
                    })
                  }
                }
              } else {
                if (this.product_details[key].product_name.replace(/[-" "]/g, '_') + this.product_details[key].commission_type != data[data.length - 1].name) {
                  if (this.product_details[key].commission_type == 'sales_commission') {
                    if (iterator == '604aff3691b7c80008ca11a8') {
                      data.push({
                        label: this.product_details[key].product_name + " Practice Testing Center Program(%)",
                        name: this.product_details[key].product_name.replace(/[-" "]/g, '_') + this.product_details[key].commission_type,
                        value: this.product_details[key].commission_value,
                        type: "number",
                        id: this.product_details[key].product_id,
                        suffix: '%',
                        validations: [{ rule: "required", message: "Commission is Required" }, { rule: 'min', value: 0 },],
                        after: data[data.length - 1].name
                      })
                    }
                    if (iterator != '612c883f118c110009f02820' && iterator != '604aff3691b7c80008ca11a8') {
                      data.push({
                        label: this.product_details[key].product_name + " Sales Commission(%)",
                        name: this.product_details[key].product_name.replace(/[-" "]/g, '_') + this.product_details[key].commission_type,
                        value: this.product_details[key].commission_value,
                        type: "number",
                        id: this.product_details[key].product_id,
                        suffix: '%',
                        validations: [{ rule: "required", message: "Commission is Required" }, { rule: 'min', value: 0 },],
                        after: data[data.length - 1].name
                      })
                    }
                  }
                  if (this.product_details[key].commission_type == 'event_commission') {
                    if (iterator == '604aff3691b7c80008ca11a8') {
                      data.push({
                        label: this.product_details[key].product_name + " Standard Qualified Practice Program(per event)",
                        name: this.product_details[key].product_name.replace(/[-" "]/g, '_') + this.product_details[key].commission_type,
                        value: this.product_details[key].commission_value,
                        type: "number",
                        id: this.product_details[key].product_id,
                        prefix: '$',
                        validations: [{ rule: "required", message: "Commission is Required" }, { rule: 'min', value: 0 },],
                        after: data[data.length - 1].name
                      })
                    }
                    if (iterator != '604aff3691b7c80008ca11a8') {
                      data.push({
                        label: this.product_details[key].product_name + " Event Commission(per event)",
                        name: this.product_details[key].product_name.replace(/[-" "]/g, '_') + this.product_details[key].commission_type,
                        value: this.product_details[key].commission_value,
                        type: "number",
                        id: this.product_details[key].product_id,
                        prefix: '$',
                        validations: [{ rule: "required", message: "Commission is Required" }, { rule: 'min', value: 0 },],
                        after: data[data.length - 1].name
                      })
                    }
                  }
                }
              }
            }
          }
        }
        console.log(data);
        this.formfieldrefreshdata = {
          field: 'addfromcontrol',
          value: data
        };
        // setTimeout(() => {
        //   console.log(this.formdata.fields);
        // }, 100);

      });
  }
  editdistributor() {
    if (this.activatedRoute.snapshot.params._id != null) {
      this.activatedRoute.data.subscribe((res: any) => {
        // console.log(res.resolvedata.result, '++++++++++++++++++++++++++++++++');
        this.userData = res.resolvedata.result[0];
        this.resolveval = this.userData;
        this.edit_selected_product = this.resolveval.disableproduct;
        this.formdata = {
          successmessage: 'Edit Successfully !!',
          redirectpath: 'distributor/list',
          submitactive: true, // optional, default true
          submittext: 'Update',
          resettext: 'Reset',
          canceltext: 'Cancel',
          apiUrl: this.apiservice.api_url,
          // endpoint: 'api/addusers', //
          jwttoken: this.apiservice.jwttoken,
          cancelroute: 'distributor/list',

          fields: [
            {
              label: 'First Name',
              name: 'firstname',
              value: this.resolveval.firstname,
              type: 'text',
              // parttern:/^[a-zA-Z\s]*$/,
              validations: [
                { rule: 'required', message: 'First Name is Required' },
                // {
                //   rule: 'pattern',
                //   value: this.textregex,
                //   message: 'only use alphabet',
                // },
              ],
            },

            {
              heading: '',
              label: 'Last Name',
              name: 'lastname',
              value: this.resolveval.lastname,
              type: 'text',
              validations: [
                { rule: 'required', message: 'Last Name is Required' },
                // {
                //   rule: 'pattern',
                //   value: this.textregex,
                //   message: 'only use alphabet',
                // },
              ],
            },


            {
              label: 'Telephone',
              name: 'phone',
              value: this.resolveval.phone,
              type: 'number',
              validations: [
                { rule: 'required', message: 'Telephone is Required' },
                {
                  rule: 'minLength',
                  value: 10,
                  message: 'The Phone Number must be 10 digits',
                },
                {
                  rule: 'maxLength',
                  value: 15,
                  message: 'Please enter the valid number',
                },
              ],
            },

            {
              label: 'Email',
              name: 'email',
              type: 'email',
              disabled: true,
              hint: '',
              value: this.resolveval.email,
              //  disabled: true,
              validations: [
                { rule: 'required', message: 'Email is required' },
                {
                  rule: 'pattern',
                  value: this.emailregex,
                  message: 'Must be a valid Email',
                },
              ],
            },

            {
              label: 'Company Name',
              name: 'company',
              value: this.resolveval.company,
              type: 'text',
            },

            {
              label: 'Address',
              name: 'address',
              value: this.resolveval.address,
              type: 'textarea',
              validations: [
                { rule: 'required', message: 'Address is Required' },
              ],
            },



            {
              label: 'type',
              name: 'type',
              type: 'hidden',
              value: 'distributor',
            },
            {
              label: 'State',
              name: 'state',
              val: this.stateVal,
              value: this.resolveval.state,
              type: 'select',
              validations: [{ message: 'State is Required' }],
            },

            {
              label: 'City',
              name: 'city',
              value: this.resolveval.city,
              type: 'text',
              validations: [{ rule: 'required', message: 'City is Required' }],
            },

            {
              label: 'Zip',
              name: 'zip',
              value: this.resolveval.zip,
              type: 'number',
              validations: [
                { rule: 'required', message: 'Zip is Required' },
                {
                  rule: 'maxLength',
                  value: 5,
                  message: 'Please enter the valid Zip ID',
                },
              ],
            },
            {
              label: "Products",
              name: "disableproduct",
              val: this.productValdata,
              value: this.resolveval.disableproduct,
              type: "select",
              multiple: true,
              validations: [{ rule: "required", message: "Products  is Required" }],
            },
            // {
            //   label: "Landing Page",
            //   name: "landing_page_name",
            //   val: this.landingPage,
            //   value: this.resolveval.landing_page_name,
            //   type: "select",
            //   multiple: true,
            //   validations: [{ rule: "required", message: "Landing Page  is Required" }],
            // },
            {
              label: 'On Bording Call',
              name: 'onbordingcall',
              hint: '',
              type: 'hidden',
              val: this.onbording,
              value: this.resolveval.onbordingcall
            },
            {
              label: 'Active',
              name: 'status',
              hint: '',
              type: 'checkbox',
              value: this.resolveval.status,
            },

            {
              label: 'id',
              name: 'id',
              value: this.activatedRoute.snapshot.params._id,
              type: 'hidden',
            },
          ],
        };
      this.landingPage = [];
      console.log(this.landingPage);
      for (const id of this.resolveval.disableproduct) {
        if (id == '604aff3691b7c80008ca11a8') {
          this.landingPage.push({ val: 'pece', name: 'PECE' })
        }
        if (id == '604a0b6e40962e00088f0d79') {
          this.landingPage.push({ val: 'peceandtmflow', name: 'PECE AND TM-Flow' })
        }
        if (id == '612c883f118c110009f02820') {
          this.landingPage.push({ val: 'bioenergetics', name: 'BioEnergetics' })
        }
        if (id == '612c89242005f400082d5fb1') {
          this.landingPage.push({ val: 'quadrant', name: 'Quadrant' })
        }
      }
      console.log(this.landingPage);
      setTimeout(() => {
        this.formfieldrefreshdata = {
          field: 'addfromcontrol', value: {
            label: "Landing Page",
            name: "landing_page_name",
            val: this.landingPage,
            value: this.resolveval.landing_page_name,
            type: "select",
            multiple: true,
            validations: [{ rule: "required", message: "Landing Page is Required" }],
            after: 'onbordingcall',
          }
        };
      }, 150);
      });
    }
  }
}
