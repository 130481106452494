import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { videoAddEditComponent } from './video/add-edit/add-edit.component';
import { VideoManagementRoutingModule } from './video-management-routing.module';
import { CategoryAddEditComponent } from './category/add-edit/add-edit.component';
import { VideoCategoryListingComponent } from './listing/listing.component';
import { ShareModule } from '../../modules/share/share.module';
import { ProductVideoComponent } from './product-video/product-video.component';


@NgModule({
  declarations: [videoAddEditComponent, CategoryAddEditComponent, VideoCategoryListingComponent, ProductVideoComponent],
  imports: [
    CommonModule,
    VideoManagementRoutingModule, ShareModule
  ]
})
export class VideoManagementModule { }
