import { Component, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material';
import { CookieService } from 'ngx-cookie-service';
import { ApiService } from 'src/app/services/api.service';
import { LoaderService } from 'src/app/services/loader.service';
import { environment } from "src/environments/environment";

@Component({
  selector: 'app-trainingproxymanagement',
  templateUrl: './trainingproxymanagement.component.html',
  styleUrls: ['./trainingproxymanagement.component.css']
})
export class TrainingproxymanagementComponent implements OnInit {
  public formdata:any={}
  public formfieldrefreshdata: any = null;
  public status:any=true
  public trainingCatArr:any=[]
  constructor(
    public cookies: CookieService,
    public apiservice: ApiService,
    public _snackBar: MatSnackBar,
    private loaderService: LoaderService
  ) {
    
    this.formdata = {
      successmessage: 'Requested Successfully !!',
      redirectpath: 'admin/dashboard',
      submitactive: true, // optional, default true
      submittext: 'Request',
      resettext: 'Reset',
      canceltext: 'Cancel',
      // apiUrl: 'https://vg3kx9c825.execute-api.us-east-2.amazonaws.com/production/',
      apiUrl: environment['api_url'],
      // endpoint: 'trainigproxyreq', //
      jwttoken: this.cookies.get('jwtToken'),
      cancelroute: 'list',

      fields: [
        {
          label: "User Email",
          name: "user_id",
          hint: 'User Email',
          type: 'autocomplete',
          // multiple: false,
          // endpoint: "api/exitsinglistemail?type=technological-consultant",
          endpoint: "api/exitsinglistemail?type=technological-consultant&source=trainingproxy",
          search_field: "search_str",
          val: [],
          value: '',
          validations: [
              { rule: 'required' }
          ]
        },
        {
          label: "a Training category",
          name: "training_cat",
          hint: '',
          type: 'select',
          val: [],
          // multiple: true,
          value: [],
          //value: '',
          validations: [
            { rule: 'required' }
          ]
        },
      ],
    };
   }

  ngOnInit() {
    this.apiservice
      .customRequest({"searchcondition": {"is_trash": {"$ne": 1 },"status": 1 }},'api1/getalltrainingdata', environment['api_url'])
      .subscribe((res: any) => {
        console.log("getalltrainingdata  ==>",res)
        for (let cat_data of res.res){
          console.log(cat_data.catagory_name)
          this.trainingCatArr.push({ val: cat_data, name: cat_data.catagory_name })
        }
        this.formdata.fields[1].val = this.trainingCatArr
        this.formdata.fields[1].value = this.trainingCatArr
      })

  }
  listenFormFieldChange(value){
    console.log("listenFormFieldChange==>",value)
    if (value.field.name == "user_email") {
      console.log("value.autocompletedata.name==>",value.autocompletedata.name)
      this.formfieldrefreshdata = { field: 'user_id', value: value.autocompletedata.val };
      this.formdata.fields[0].val = value.autocompletedata.val
    }
    if (value.fieldval == "success" && value.field == "fromsubmit") {
      this.loaderService.isLoading.next(true)
      this.apiservice
      .customRequest(value.fromval,'trainigproxyreq', environment['api_url_py'])
      .subscribe((res: any) => {
        console.log("trainigproxyreq resp==>",res)
        if (res.status=="success"){
          this.loaderService.isLoading.next(false)
          this._snackBar.open('Requested successfully', 'Dismiss', {
            duration: 3000
          });
        }else{
          this.loaderService.isLoading.next(false)
          this._snackBar.open('Requested Failed', 'Dismiss', {
            duration: 3000
          });
        }
        
      })
    }
  }
}

