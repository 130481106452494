import { HttpClient } from "@angular/common/http";
import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { CookieService } from "ngx-cookie-service";
import { ApiService } from 'src/app/services/api.service';

import { UserserviceService } from 'src/app/services/userservice.service';


@Component({
  selector: 'app-faqcategorylisting',
  templateUrl: './faqcategorylisting.component.html',
  styleUrls: ['./faqcategorylisting.component.css']
})
 

export class FaqcategorylistingComponent implements OnInit {
  faqcategorylisttable: any = [];

  public status: any = [ 
    { val: 1, name: 'Active' },
    { val: 0, name: 'Inactive' },
  ];
  public launched: any = [
    { val: true, name: 'Yes' },
    { val: null, name: 'No' },
  ];

  // use for status search
  statusarray: any = [
    { val: 1, name: 'Active' },
    { val: 0, name: 'Inactive' },
  ];

  // editrouting
  editroute: any = 'admin/faqcategory/edit/';

  // modify header(key : modify header name)
  modifyheaderarray: any = {
    categoryname: 'Category Name',
    description: 'Description', 
    createdon_datetime:'Added on',
    status: 'Status',
    priority: 'Priority',
  };

  // use for table field skip
  faqcategorylisttableskip: any = [];

  // use for Table Detail Field Skip
  faqcategorylisttabledetailskip: any = ['_id','categoryname_s', 'status',  'description_s', 'priority', 'createdon_datetime'];

  // updateendpoint is use for data update endpoint
  updateendpoint = "api/addfaqcategory"; 

  // deleteendpoint is use for data delete endpoint
  deleteendpoint = 'api/deletefaqcategory';

  // this is a database collection name
  tablename = 'users';

  // searchendpoint is use for data search endpoint
  searchendpoint = 'api/faqcategorssearch';

  // use for click to another page routing path
  clicktoaddananotherpage = '';

  // date_search_endpoint is use for date search endpoint
  datesearchendpoint: any = '';

  // send basic limit data
  limitcond: any = {
    limit: 10,
    skip: 0,
  };

  // other data
  libdata: any = {

    preview_header: {
      header: "Preview Details",
      class: 'preheadercls'
  },
    
    // basecondition: { type: '' },
    detailview_override: [
      { key: 'categoryname', val: ' Category Name' },
      { key: 'description', val: 'Description' },
     
      { key: 'status', val: 'Status' },
      { key: 'createdon_datetime', val: 'Added on' },

      { key: 'priority', val: 'Priority' },
    ],
    tableheaders: [
       
      'categoryname',
      'description',
      'createdon_datetime',
      'status',
      'priority'
      
     
    ],
    // searchBarFlagVal:true,
    // tableheaders name [key]
    // optional
    updateendpointmany: 'api/faqcategoryupdate',
    updateendpoint: 'api/faqcategorystatusupdate',
    deleteendpointmany: 'api/deletefaqcategory',
    hidemultipleselectbutton: null,
    hidecounter: null,
    hideaction: null,
    hidestatustogglebutton: false,                  // (hide status toggle button)
    hideviewbutton: false,                          // (hide view button)
    // actioncolname: 'Actn',
  };

  // send basic sort data
  sortdata: any = {
    type: 'desc',
    field: 'categoryname',
    options: [
     
      'status',
      'categoryname'
    ],
  };
  
  // this is a database collection or view name
  // datesearchsource: any = 'admin_blog_list';
  // datacollection
  datacollection: any = 'api/faqcategorylistview';
  // source count
  datesearchsourcecount: any = 0;

  searchsettings: any = {


    datesearch: [
      {
        startdatelabel: 'Added on start Date',
        enddatelabel: 'Added On End Date',
        submit: 'Search',
        field: 'createdon_datetime',
      },
    ],
    selectsearch: [
      { label: 'Search By Status', field: 'status', values: this.status },
    ],
    textsearch: [
      // { label: 'Search By Category', field: 'categoryname_s' },
      // { label:'Search By Email', field:'verifiedemail'}
    ], 
    search: [
      {
        label: 'Search By Category',
        field: 'categoryname_l',
        values: '',
        serversearchdata: { endpoint: 'api11/autocomplete-faqcatsearch' },
      }
    ]
  };

  public userid: any;
  public userFullName: any;
  constructor(
    public router: Router,
    public Apiservice: ApiService,
    public http: HttpClient,
    public cookieservice: CookieService,
    public activatedRoute: ActivatedRoute,
    public userservice: UserserviceService

  ) { 
    this.userid = this.userservice.userid;
    this.userFullName =
      this.userservice.firstname +
      ' ' +
      this.userservice.lastname; // user dtls ( cookieservice)
    const endpointc = 'api/faqcategorylistview-count';
    const endpoint = 'api/faqcategorylistview';
    const data: any = { // How many data showing in first page
      secret: this.userservice.secret,
      token: this.userservice.jwttoken,
      source: '',
      condition: {  // condition for how much data show in one time
        limit: 10,
        skip: 0,
      },
      sort: {
        type: 'desc',  // data sort by decreasing order
        field: '_id',  // data sort by first name
      },
    };

    this.Apiservice
      .getDataWithoutToken(data, endpointc)
      .subscribe((res: any) => {
        this.datesearchsourcecount = res.count; // sarch count  (How many data there)
      });
  }

  ngOnInit() {
    this.activatedRoute.data.forEach((res: any) => {
      this.faqcategorylisttable = res.resolvedata.results.res; // data store from backend
       
    });
  }



}
