import { HttpClient } from "@angular/common/http";
import { Component, Inject, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { CookieService } from "ngx-cookie-service";
import { ApiService } from "src/app/services/api.service";
import { UserserviceService } from "src/app/services/userservice.service";
import { MatDialog, MatDialogRef, MatSnackBar, MAT_DIALOG_DATA } from "@angular/material";

export interface DialogData {
  data: any;
  val: any;
}
@Component({
  selector: "app-salescommission",
  templateUrl: "./salescommission.component.html",
  styleUrls: ["./salescommission.component.css"],
})
export class SalescommissionComponent implements OnInit {
  public formdata: any = {};
  public status: any = [
    { val: 1, name: "Active" },
    { val: 0, name: "Inactive" },
  ];
  public count: number = 1;
  public choiceconditionfields: any = [
    { val: "greaterthaneq", name: ">=" },
    { val: "lessthan", name: "<" },
  ];
  public editflag: boolean = false;
  public formfieldrefresh: any = true;
  public editdata: any;
  public data1: any = {};
  public productid: any;
  public incompleteflag: any = {};
  public submitted: any = {};
  public viewcommissionflag: boolean = false;
  public distributorlist: any = [];
  public techlist: any = [];
  public product: any = [];
  public distdata: any = { name: "", val: "" };
  public techdata: any = { name: "", val: "" };
  public eventcommission = false;
  public salescommission: boolean = false;
  public productdata: any;
  public hintflag: any = {};
  public hintflag2: any = {};
  public hintsalescommissiondata: any;
  public greaterthansalescommission: number = 0;
  public hinteventcommissiondata: any;
  public addcommissionarray: any = [
    { rule: "", salesprice: "", productprice: "", salescommissionprice: " " },
  ];
  public addsalescommissionarray: any = [
    { rule: "", eventcount: "", commissionpercount: "" },
  ];
  public productprice: any;
  public salescommissionprice: any;
  public eventcommisiionflag: boolean = false;
  public commissionpercount: any;
  public userdata: any;
  public selectedValue1: any;
  public selectedValue2: any;
  public notes: any;
  public commissionstatus: any;
  public ruleflag: boolean = false;
  public lessthansalescommission: number = 0;
  public lessthaneventscommission: number = 0;
  public greaterthaneventscommission: number = 0;
  public calculatedata: any;
  public calculatedatagreater: any;
  public paymentsuggetionflag: any = false;
  constructor(
    public apiservice: ApiService,
    public http: HttpClient,
    public activatedRoute: ActivatedRoute,
    public cookies: CookieService,
    public router: Router,
    public userservice: UserserviceService,
    public dialog: MatDialog,
    public _snackBar: MatSnackBar

  ) {
    this.getproductlist();

  }
  public formfieldrefreshdata: any;
  ngOnInit() {
    this.editcommission();

  }
  addcommission(flag, i) {
    if (flag == "ec") {
      this.addcommissionarray.push({
        rule: "",
        salesprice: "",
        productprice: "",
        salescommissionprice: " ",
      });
    }
    if (flag == "sc") {
      this.addsalescommissionarray.push({
        rule: "",
        eventcount: "",
        commissionpercount: "",
      });
    }
    if (flag == "dsc") {
      console.log(this.addsalescommissionarray, "l ghjulkhg v", i);
      this.addsalescommissionarray.splice(i, 1);
    }
    if (flag == "dec") {
      console.log(this.addsalescommissionarray, "l ghjulkhg v", i);
      this.addcommissionarray.splice(i, 1);
    }
  }
  onKeypressEvent(value: any, flag) {
    this.distributorlist = [];
    // console.log(this.userdata,'value',value);

    if (flag == "ecp") {
      for (const key in this.addcommissionarray) {
        this.addcommissionarray[key].salescommissionprice =
          this.addcommissionarray[key].salesprice -
          this.addcommissionarray[key].productprice;
      }
      // console.log(value, this.productprice, this.salesprice);
    }
    if (flag == "product") {
      console.log(flag);
      let data: {};

      data = {
        type: "distributor",
        fullname_s: { $regex: this.distdata.name },
      };
      console.log(data);

      this.apiservice
        .getDataWithoutToken(data, "api/alltypeusers")
        .subscribe((res: any) => {
          setTimeout(() => {
            this.distributorlist = [];

            if (
              res.results.res != null &&
              typeof res.results.res != "undefined" &&
              res.results.res != ""
            ) {
              this.distributorlist = [];

              for (const i in res.results.res) {
                this.distributorlist.push({
                  val: res.results.res[i]._id,
                  name: res.results.res[i].fullname,
                });
              }
            }
          }, 500);
          // console.log(res.res, '///////////')

          // sarch count  (How many data there)
          // console.log(this.productName, 'lllllllllllllllll', this.productVal)
        });
    }
    if (flag == 'tech') {
      let data: any = {
        type: "technological-consultant",
        parentid: this.userservice.userid,
        fullname_s: { $regex: this.techdata.name.toLowerCase() },

      }
      console.log(data);

      this.apiservice
        .getDataWithoutToken(data, "api/alltypeusers")
        .subscribe((res: any) => {
          setTimeout(() => {
            this.techlist = [];

            if (
              res.results.res != null &&
              typeof res.results.res != "undefined" &&
              res.results.res != ""
            ) {


              for (const i in res.results.res) {
                this.techlist.push({
                  val: res.results.res[i]._id,
                  name: res.results.res[i].fullname,
                });
              }
            }
          }, 500);

        })


    }
  }
  onChange(val: any) {
    if (val == true) {
      this.salescommission = val;
    } else {
      this.salescommission = false;
    }
  }
  checkbox(val) {
    console.log(val);

    if (val == true) {
      this.eventcommission = val;
    } else {
      this.eventcommission = false;
    }
  }
  getproductlist() {
    this.apiservice
      .getDataWithoutToken(
        {
          token: this.userservice.jwttoken,
          secret: this.userservice.secret,
        },
        "api/productsearch"
      )
      .subscribe((response: any) => {
        // this.productVal=response.res
        for (const i in response.res) {
          if (Object.prototype.hasOwnProperty.call(response.res, i)) {

            this.product.push({
              val: response.res[i].val,
              name: response.res[i].name,
              commisonsugggetions: response.res[i].commisonsugggetions
            });
          }
        }
      });
  }

  submit() {

    if (this.userservice.type == 'admin') {
      let eventcommissioncopyarr: any[] = this.addsalescommissionarray;
      let salescommissioncopyarr: any[] = this.addcommissionarray;

      if (this.eventcommission == true && eventcommissioncopyarr.length > 0) {
        while (eventcommissioncopyarr.findIndex((e) => e.rule === "") >= 0)
          eventcommissioncopyarr.splice(
            eventcommissioncopyarr.findIndex((f) => f.rule === ""),
            1
          );
      }

      if (this.salescommission == true && salescommissioncopyarr.length > 0) {
        while (salescommissioncopyarr.findIndex((e) => e.rule === "") >= 0)
          salescommissioncopyarr.splice(
            salescommissioncopyarr.findIndex((f) => f.rule === ""),
            1
          );
      }
      if (this.distdata.name == null || typeof (this.distdata.name) == 'undefined' || this.distdata.name == '') {
        this.incompleteflag.distributoname = true;
      }
      else {
        this.incompleteflag.distributoname = false;
      }
      if (this.productdata == null || typeof (this.productdata) == 'undefined' || this.productdata == '') {
        this.incompleteflag.productdata = true;
      } else {
        this.incompleteflag.productdata = false;
      }
      let dataval;
      if (this.incompleteflag.distributoname != true && this.incompleteflag.productdata != true) {
        if (this.activatedRoute.snapshot.params._id != null) {
          dataval = {
            token: this.userservice.jwttoken,
            secret: this.userservice.secret,
            data: {
              addedby: this.userservice.userid,
              addedbytype: this.userservice.type,
              userdata: this.distdata.val,
              productdata: this.productdata,
              salescommission: salescommissioncopyarr,
              eventcommission: eventcommissioncopyarr,
              notes: this.notes,
              commissionstatus: this.commissionstatus,
              _id: this.activatedRoute.snapshot.params._id,
            },
          };
        } else {
          dataval = {
            token: this.userservice.jwttoken,
            secret: this.userservice.secret,
            data: {
              addedby: this.userservice.userid,
              addedbytype: this.userservice.type,
              userdata: this.distdata.val,
              productdata: this.productdata,
              salescommission: salescommissioncopyarr,
              eventcommission: eventcommissioncopyarr,
              notes: this.notes,
              commissionstatus: this.commissionstatus,
            },
          };
        }
        this.apiservice
          .getDataWithoutToken(dataval, "api/addmanagecommission")
          .subscribe((res: any) => {
            if (res.status) {
              this.router.navigateByUrl("/managecommisson/list");
            }
          });
      }
    }
    if (this.userservice.type == 'distributor') {

      this.distributorsubmit();
    }



  }
  getValue(val: any, flag) {
    console.log(val);

    if (this.userservice.type != 'distributor' && flag == 'distname') {
      console.log(val);
      this.distdata.val = val.val;
      this.distdata.name = val.name;
    }
    if (this.userservice.type == 'distributor' && flag == 'techname') {
      console.log(val);
      this.techdata.val = val.val;
      this.techdata.name = val.name;
    }
  }
  onChangeTimezone(val: any, flag) {
    if (flag == "sp") {
      console.log(val.value, "++++");

      this.selectedValue1 = val.value;
      if (this.userservice.type == 'distributor') {
        this.productid = val.value;
        this.paymentsuggetionflag = true;
        let data = { "condition": { "limit": 10, "skip": 0 }, "sort": { "field": "createdon_datetime", "type": "asc" }, "searchcondition": { "product_s": val.value, "type": this.userservice.type, "userid": this.userservice.userid }, "secret": this.userservice.secret, "token": this.userservice.jwttoken }
        this.apiservice
          .getDataWithoutToken(data, "api/listmanagecommission")
          .subscribe((res: any) => {
            console.log(res);

            if (res.results.res.length > 0) {
              // this.hintflag = true;
              this.hintsalescommissiondata = res.results.res[0].salescommission;
              for (const key in this.hintsalescommissiondata) {
                if (this.hintsalescommissiondata[key].rule == 'greaterthaneq') {
                  console.log('hiiiiiiiiiiiiii');

                  this.greaterthansalescommission = this.hintsalescommissiondata[key].salescommissionprice;
                  for (const key in this.addcommissionarray) {
                    this.calculatedatagreater = this.greaterthansalescommission - this.addcommissionarray[key].salesprice
                  }
                }
                if (this.hintsalescommissiondata[key].rule == 'lessthan') {
                  this.lessthansalescommission = this.hintsalescommissiondata[key].salescommissionprice;

                  for (const key in this.addcommissionarray) {
                    this.calculatedata = this.lessthansalescommission - this.addcommissionarray[key].salesprice
                  }


                }
              }

              this.hinteventcommissiondata = res.results.res[0].eventcommission;
              for (const key in this.hinteventcommissiondata) {
                if (this.hinteventcommissiondata[key].rule == 'greaterthaneq') {
                  this.greaterthaneventscommission = this.hinteventcommissiondata[key].commissionpercount;

                }
                if (this.hinteventcommissiondata[key].rule == 'lessthan') {
                  this.lessthaneventscommission = this.hinteventcommissiondata[key].commissionpercount;

                }
              }
            }

          });
      }
    }
    if (flag == "sr") {
      if (this.hintsalescommissiondata.length > 0) {
        for (const key in this.hintsalescommissiondata) {
          if (this.hintsalescommissiondata[key].rule == 'greaterthaneq' && val.value == 'greaterthaneq') {

            this.hintflag.greaterthaneq = true;
            this.hintflag.lessthan = false;

          }
          if (this.hintsalescommissiondata[key].rule == 'lessthan' && val.value == 'lessthan') {
            console.log('ppppppppppppppppppppppp');
            this.hintflag.greaterthaneq = false;
            this.hintflag.lessthan = true;
          }

        }

      }
      console.log(this.hintflag);
      this.selectedValue2 = val.value;
    }
    if (flag == 'sre') {
      if (this.hinteventcommissiondata.length > 0) {
        for (const key in this.hinteventcommissiondata) {
          if (this.hinteventcommissiondata[key].rule == 'greaterthaneq' && val.value == 'greaterthaneq') {
            this.hintflag2.greaterthaneq = true;
            this.hintflag2.lessthan = false;
          }

          if (this.hinteventcommissiondata[key].rule == 'lessthan' && val.value == 'lessthan') {
            this.hintflag2.lessthan = true;
            this.hintflag2.greaterthaneq = false;
          }
        }
      }
    }
  }

  cancel() {
    this.router.navigateByUrl("/managecommisson/list");
  }
  editcommission() {
    if (this.activatedRoute.snapshot.params._id != null) {
      this.activatedRoute.data.subscribe((res: any) => {
        this.editflag = true;
        console.log(res.resolvedata.result);
        let data: {};
        if (this.userservice.type == 'admin') {
          data = {
            type: "distributor",
            _id: res.resolvedata.result.userdata,
            fullname_s: { $regex: this.distdata.name },
          };
        }
        if (this.userservice.type == 'distributor') {
          data = {
            id: res.resolvedata.result.userdata

          };
        }
        this.apiservice
          .getDataWithoutToken(data, "api/alltypeusers")
          .subscribe((res: any) => {
            console.log();

            // this.distdata.name = res.results.res[0].fullname;
            // this.distdata.val = res.results.res[0]._id;
          });



        this.productdata = res.resolvedata.result.productdata;

        if (res.resolvedata.result.eventcommission.length > 0) {
          this.eventcommission = true;
          this.addsalescommissionarray = res.resolvedata.result.eventcommission;
        }
        if (res.resolvedata.result.salescommission.length > 0) {
          this.salescommission = true;
          this.addcommissionarray = res.resolvedata.result.salescommission;
        }
        console.log(res, "llllllllllllllllll");
        this.notes = res.resolvedata.result.notes;
        this.commissionstatus = res.resolvedata.result.commissionstatus;
      });
    }
  }

  distributorsubmit() {
    let dataval: any = {};
    this.submitted = {};
    console.log(this.techdata.val, 'kkkkkkkkkkkkkkkkkkkkkkkkkkkk', this.lessthansalescommission);

    let eventcommissioncopyarr: any[] = this.addsalescommissionarray;
    let salescommissioncopyarr: any[] = this.addcommissionarray;
    if (this.techdata.name == null || typeof (this.techdata.name) == 'undefined' || this.techdata.name == '') {
      this.incompleteflag.techname = true;
    }
    else {
      this.incompleteflag.techname = false;
    }
    if (this.productdata == null || typeof (this.productdata) == 'undefined' || this.productdata == '') {
      this.incompleteflag.productdata = true;
    } else {
      this.incompleteflag.productdata = false;
    }

    if (this.incompleteflag.techname != true && this.incompleteflag.productdata != true) {

      if (salescommissioncopyarr.length > 0) {
        for (const key in salescommissioncopyarr) {
          console.log(salescommissioncopyarr[key].salesprice + salescommissioncopyarr[key].productprice, this.calculatedata);
          if (this.hintflag.lessthan == true && (salescommissioncopyarr[key].salesprice + salescommissioncopyarr[key].productprice) < this.calculatedata) {
            console.log('right');

            this.submitted.salespricelessthan = true;

          }

          if (this.hintflag.lessthan == true && (salescommissioncopyarr[key].salesprice + salescommissioncopyarr[key].productprice) > this.calculatedata) {

            this.submitted.salespricelessthan = false;

          }

          if (this.hintflag.greaterthaneq == true && (salescommissioncopyarr[key].salesprice + salescommissioncopyarr[key].productprice) >= this.calculatedatagreater) {
            this.submitted.salespricegreaterthan = true;
          }

          if (this.hintflag.greaterthaneq == true && (salescommissioncopyarr[key].salesprice + salescommissioncopyarr[key].productprice) < this.calculatedatagreater) {
            this.submitted.salespricegreaterthan = false;
          }

        }
      }

      if (eventcommissioncopyarr.length > 0) {
        for (const key in eventcommissioncopyarr) {
          console.log(eventcommissioncopyarr);
          if (this.hintflag2.lessthan == true && (eventcommissioncopyarr[key].commissionpercount) < this.lessthaneventscommission) {
            console.log('right');

            this.submitted.eventcommissionlessthan = true;

          }

          if (this.hintflag2.lessthan == true && (eventcommissioncopyarr[key].commissionpercount) >= this.lessthaneventscommission) {

            this.submitted.eventcommissionlessthan = false;

          }

          if (this.hintflag2.greaterthaneq == true && (eventcommissioncopyarr[key].commissionpercount) >= this.greaterthaneventscommission) {
            this.submitted.eventcommissiongreaterthan = true;
          }

          if (this.hintflag2.greaterthaneq == true && (eventcommissioncopyarr[key].commissionpercount) < this.greaterthaneventscommission) {
            this.submitted.eventcommissiongreaterthan = false;
          }

        }

      }
      console.log(this.submitted);

      if (this.salescommission == true && this.eventcommission == false) {
        dataval = {
          token: this.userservice.jwttoken,
          secret: this.userservice.secret,
          data: {
            addedby: this.userservice.userid,
            addedbytype: this.userservice.type,
            userdata: this.techdata.val,
            productdata: this.productdata,
            salescommission: salescommissioncopyarr,
            eventcommission: [],
            notes: this.notes,
            commissionstatus: this.commissionstatus,
          },
        };
      }
      if (this.eventcommission == true && this.salescommission == false) {
        dataval = {
          token: this.userservice.jwttoken,
          secret: this.userservice.secret,
          data: {
            addedby: this.userservice.userid,
            addedbytype: this.userservice.type,
            userdata: this.techdata.val,
            productdata: this.productdata,
            salescommission: [],
            eventcommission: eventcommissioncopyarr,
            notes: this.notes,
            commissionstatus: this.commissionstatus,

          },
        };
      }
      if (this.eventcommission == true && this.salescommission == true) {
        dataval = {
          token: this.userservice.jwttoken,
          secret: this.userservice.secret,
          data: {
            addedby: this.userservice.userid,
            addedbytype: this.userservice.type,
            userdata: this.techdata.val,
            productdata: this.productdata,
            salescommission: salescommissioncopyarr,
            eventcommission: eventcommissioncopyarr,
            notes: this.notes,
            commissionstatus: this.commissionstatus,

          },
        };
      }
      // Object.values(this.submitted).every((item) => {
      console.log(this.allTrue(this.submitted));

      if (this.allTrue(this.submitted) == true && dataval != '') {
        this.apiservice
          .getDataWithoutToken(dataval, "api/addmanagecommission")
          .subscribe((res: any) => {
            if (res.status) {
              this.router.navigateByUrl("/managecommisson/list");
            }
          });
      } if (this.allTrue(this.submitted) == false && dataval == '') {
        this._snackBar.open("Plzz Submit Ass per rule", "Ok", {
          duration: 2000,
        });

      }
      // })
    }


  }

  allTrue(obj) {
    for (var o in obj)
      if (!obj[o]) return false;

    return true;
  }
  paymentsuggest() {
    console.log(this.product, 'lllllllllllllproductid', this.productid);
    if (this.product.length > 0) {
     
        if (this.viewcommissionflag == false) {
          const dialogRef1 = this.dialog.open(paymentsuggetions, {
            panelClass: 'paymentsuggetions',
            data: { val: this.product, flag: 'alldata' },
          });
          dialogRef1.afterClosed().subscribe(result => {

          });
        }
        // if (this.viewcommissionflag == true) {
        //   if (this.product[key].commisonsugggetions != null && typeof (this.product[key].commisonsugggetions) != 'undefined' && this.productid == this.product[key].val) {
        //     // console.log(this.product[key]);
        //     const dialogRef2 = this.dialog.open(paymentsuggetions, {
        //       panelClass: 'paymentsuggetions',
        //       data: { val: this.product[key].commisonsugggetions, flag: 'singledata' },
        //     });
        //     dialogRef2.afterClosed().subscribe(result => {

        //     });
        //   }
        // }

      
    }

  }

}



@Component({
  selector: 'paymentsuggetion',
  templateUrl: 'paymentsuggetion.html',

})
export class paymentsuggetions {
  public commission: any;
  constructor(
    public dialogRef: MatDialogRef<paymentsuggetions>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData
  ) {
    console.log(data);

    this.commission = data.val;

  }

}
