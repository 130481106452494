import { Component, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiService } from 'src/app/services/api.service';
import { UserserviceService } from 'src/app/services/userservice.service';

@Component({
  selector: 'app-pece-contract',
  templateUrl: './pece-contract.component.html',
  styleUrls: ['./pece-contract.component.css']
})
export class PeceContractComponent implements OnInit {
  public pececontractdata: any = {};
  public errdatat: any = {};
  public submitflag: any;
  constructor(
    public apiservice: ApiService,
    public activatedRoute: ActivatedRoute,
    public userservice: UserserviceService,
    public router: Router,
    private _snackBar: MatSnackBar
  ) {
    this.errdatat = {
      Agreement: 'A',
      dayof: 'b',
      year: 'b',
      leadname: 'c',
      address: 'v',
      euipmentadd: 'c',
      Serial: 's'
    }
  }

  ngOnInit() {
    
      this.activatedRoute.data.subscribe((res: any) => {
        if (this.activatedRoute.snapshot.queryParams.flag == 'peceviewcontract' || this.activatedRoute.snapshot.queryParams.flag == 'peceresendcontract') {
          console.log(res.resolvedata.result[0].contractdata);
          this.pececontractdata.leadname = res.resolvedata.result[0].contractdata.leadname;
            this.pececontractdata.address = res.resolvedata.result[0].contractdata.address;
            this.pececontractdata.Serial = res.resolvedata.result[0].contractdata.Serial;
            this.pececontractdata.Agreement = res.resolvedata.result[0].contractdata.Agreement;
            this.pececontractdata.authsing = res.resolvedata.result[0].contractdata.authsing;
            this.pececontractdata.authsing1 = res.resolvedata.result[0].contractdata.authsing1;
            this.pececontractdata.authsing2 = res.resolvedata.result[0].contractdata.authsing2;
            this.pececontractdata.authsing3 = res.resolvedata.result[0].contractdata.authsing3;
            this.pececontractdata.dateofsing = res.resolvedata.result[0].contractdata.dateofsing;
            this.pececontractdata.dateofsing1 = res.resolvedata.result[0].contractdata.dateofsing1;
            this.pececontractdata.dayof = res.resolvedata.result[0].contractdata.dayof;
            this.pececontractdata.euipmentadd = res.resolvedata.result[0].contractdata.euipmentadd;
            this.pececontractdata.printedname = res.resolvedata.result[0].contractdata.printedname;
            this.pececontractdata.printedname1 = res.resolvedata.result[0].contractdata.printedname1;
            this.pececontractdata.title = res.resolvedata.result[0].contractdata.title;
            this.pececontractdata.title1 = res.resolvedata.result[0].contractdata.title1;
            this.pececontractdata.title2 = res.resolvedata.result[0].contractdata.title2;
            this.pececontractdata.title3 = res.resolvedata.result[0].contractdata.title3;
            this.pececontractdata.year = res.resolvedata.result[0].contractdata.year;
            
        }else{
          if (this.activatedRoute.snapshot.params._id != null) {
            console.log(res.resolvedata.results.userView[0]);
            this.pececontractdata.leadname = res.resolvedata.results.userView[0].fullname;
            this.pececontractdata.address = res.resolvedata.results.userView[0].address;
            this.pececontractdata.Serial = res.resolvedata.results.userView[0].Serial_Number;
        }
        }
     

      })
    this.errdatat = {
      Agreement: 'A',
      dayof: 'b',
      year: 'b',
      leadname: 'c',
      address: 'v',
      euipmentadd: 'c',
      Serial: 's'
    }
  }
  openSnackBar(message: string, action: string) {
    this._snackBar.open(message, action, {
      duration: 2000,
    });
  }
  submitbtn() {
    this.errdatat = this.pececontractdata;
    console.log(this.errdatat);
    console.log(this.pececontractdata);
    this.submitflag = true;
    // if (typeof (this.pececontractdata.Agreement) != 'undefined' && this.pececontractdata.Agreement != null && typeof (this.pececontractdata.dayof) != 'undefined' && this.pececontractdata.dayof != null && typeof (this.pececontractdata.year) != 'undefined' && this.pececontractdata.year != null && typeof (this.pececontractdata.leadname) != 'undefined' && this.pececontractdata.leadname != null && typeof (this.pececontractdata.address) != 'undefined' && this.pececontractdata.address != null && typeof (this.pececontractdata.euipmentadd) != 'undefined' && this.pececontractdata.euipmentadd != null && typeof (this.pececontractdata.Serial) != 'undefined' && this.pececontractdata.Serial != null ) {
    //   this.submitflag = true;

    // } else {
    //   // console.log(this.creditdata);

    //   this.openSnackBar('Complete the  INFORMATION properly', 'ok');
    //   this.submitflag = false;

    // }
    if (this.submitflag) {
      let data = {
        lead_id: this.activatedRoute.snapshot.params._id,
        tech_id: this.userservice.userid,
        product_id: this.activatedRoute.snapshot.params.p_id,
        contractdata: this.pececontractdata,
        "contractflag": "Pece Contract",
        contracts: [
          {
            status: "request",
            added_by_id: this.userservice.userid,
            addedby: this.userservice.fullname,
            addedondatetime: Math.round(new Date().getTime()),
            type: this.userservice.type,
          },
        ],
        contact_id: this.activatedRoute.snapshot.params.sing_id,
      };
      console.log(data);
      this.apiservice
        .getDataWithoutToken(data, "api/request-contracts")
        .subscribe((res: any) => {
          this.openSnackBar(res.successmsg, 'ok');
          this.activatedRoute.queryParams.subscribe((data:any)=>{
            // console.warn(data);
            if(typeof (data.listing) != 'undefined' && data.listing != null && data.listing != ''){
              if(data.listing == "true"){
                if(this.userservice.type == 'technological-consultant'){
                  this.router.navigateByUrl('/contract-manager/contract-request/list/software-purchase/tech');
                }
                if(this.userservice.type == 'admin'){
                  this.router.navigateByUrl('/contract-manager/contract-request/list/software-purchase');
                }
              }
            }else{
              this.router.navigateByUrl(
                "/user-details/lead/" + this.activatedRoute.snapshot.params._id +'/contractflag1'
              );
            }
          })
          // this.router.navigateByUrl('/user-details/lead/' + this.activatedRoute.snapshot.params._id);
        });
    }

  }
  canclebtn() {
    this.activatedRoute.queryParams.subscribe((data:any)=>{
      // console.warn(data);
      if(typeof (data.listing) != 'undefined' && data.listing != null && data.listing != ''){
        if(data.listing == "true"){
          if(this.userservice.type == 'technological-consultant'){
            this.router.navigateByUrl('/contract-manager/contract-request/list/software-purchase/tech');
          }
          if(this.userservice.type == 'admin'){
            this.router.navigateByUrl('/contract-manager/contract-request/list/software-purchase');
          }
        }
      }else{
        this.router.navigateByUrl(
          "/user-details/lead/" + this.activatedRoute.snapshot.params._id +'/contractflag1'
        );
      }
    })
    // this.router.navigateByUrl('/user-details/lead/' + this.activatedRoute.snapshot.params._id);
  }
}
