import { Component, Inject, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { ChartDataSets } from "chart.js";
import { CookieService } from "ngx-cookie-service";
import { ApiService } from "src/app/services/api.service";
import { UserserviceService } from "src/app/services/userservice.service";
import { BlockUI, NgBlockUI } from "ng-block-ui";
import { ObservableService } from "../../../services/observable.service";

import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from "@angular/material/dialog";
import { environment } from "src/environments/environment";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { HttpClient } from "@angular/common/http";
import { DetectdomainService } from 'src/app/services/detectdomain.service';

export interface DialogData {
  data: any;
}
export interface DialogData2 {
  url: any;
  Htext: any;
  Ptext: any;
}
export interface DialogData5 {
  data: any;
  flag: any;
}
export interface DialogData4 {
  data: any;
  flag: any;
}

@Component({
  selector: "app-technological-consultant-dashboard",
  templateUrl: "./technological-consultant-dashboard.component.html",
  styleUrls: ["./technological-consultant-dashboard.component.css"],
})
export class TechnologicalConsultantDashboardComponent implements OnInit {
  @BlockUI("hero-section") blockUI: NgBlockUI;
  public singletrainingdata: any = [];
  public today = new Date();
  public hasAccessToken = false;
  public trainingblocktoggle: boolean = true;
  public notificationdata: any;
  public singletrainingdata_count: any = [];
  public linkToAddGoogleCalendar: string =
    environment["add_google_calendar_url"];
  public userFullName = "";
  public trainingcomp: any = "";
  public jobDataList: any = [];
  public user_data: any = [];
  public training_Data_block: any;
  public leadListdatatablegrid: any = [];
  public activeflagone = 0;
  public lastlogindate = "";
  public ScheduledFlagScheduledFlag = false;
  public ScheduledFlag: boolean = false;
  public warrantyFlag: boolean = false;
  public getallsharelinksdata: any;
  public jobticketsFlag: boolean = false;
  public jobticketsListdatatableflag: boolean = false;
  public leadsFlag: boolean = false;
  public leadsListdatatableflag: boolean = false;
  public Scheduleddatatableflag: boolean = false;
  public warrantyappointmentsddatatableflag: boolean = false;
  public WebinarsFlag: boolean = false;
  public NewlyAddedFlag: boolean = false;
  public jobcategoryListdatatable: any = [];
  public warranty_appointments: any = [];
  public webinarsListdatatable: any = [];
  public leadListdatatable: any = [];
  slide_jobticketstimeout: any;
  slideindex_leadtimeout: any;
  commissiondata: any;
  public commissiondatatableflag: any;
  public commissionflag: boolean = false;
  public commissionviewflag: boolean = false;
  public slideindex_jobtickets: any = 0;
  public slideindex_leads: any = 0;
  public slideindex_commission: any = 0;
  public traininggraph: any;
  public AppointmentgridFlag: boolean = false;
  public trainingFlag: boolean = false;
  public TraininggridFlag: boolean = false;
  public LeadsgridFlag: boolean = false;
  public jobticketnorecordfound: boolean = false;
  public NewlyAddedtableflag: boolean = false;
  public styleObject = {};
  public googleScheduleRoute: any =
    "/calendar-management/manage-appointment/game-plan/book-appointment/";
  public activeflag: any = 0;
  public progessflag: Number = 0;
  public activeflags: any = 0;
  public datesearchsourcecount: any = 0;
  public datesearchsourcecountnewlyaddedlead: any = 0;
  public datesearchsourcecount_commission = 0;
  public slideindex_lead: any = 0;
  public updatetable: boolean = false;
  public commissionlistdata: any = [];
  public datesearchsourcecount_jobticket: any = 0;
  public isrefresh_job_ticket: boolean = false;
  public searchsettings: any = {};
  public searchsettingsleadDataList: any = {};
  public searchsettingswarrantyDataList: any = {};
  public leadnorecordfound: boolean = false;
  public isrefresh_lead: boolean = false;
  public isrefresh_warranty_appointments: boolean = false;
  public warranty_appointmentsnorecordfound: boolean = false;
  public techcommissionnorecordfound: boolean = false;
  public isrefresh_tech_commission: boolean = false;
  public slideindex_signature: any = 0;
  public commission_data_details: any = [];
  public leftcompletetraing: boolean = false;
  public trainingtoggle: any = false;
  public hostName: any;
  public medigradeflag: any=false;
  // events
  public chartClicked(e: any): void {
    //  // console.log(e);
  }

  public chartHovered(e: any): void {
    // // console.log(e);
  }
  // public leadListdatatable: any = [];
  // use for table field skip
  leadListdatatableskip: any = [
    "_id",
    "type",
    "password",
    "created_at",
    "type",
    "city",
    "gallery_image",
    "accesscode",
    "__v",
    "notes",
  ];

  // use for Table Detail Field Skip
  leadListdatatabledetailskip: any = [
    "_id",
    "email_s",
    "name",
    "blogtitle",
    "type",
    "city",
    "__v",
    "fullname",
    "notes",
    "accesscode",
    "location_s",
    "address_search",
    "fullname_s",
    "zip_s",
    "createdon_datetime",
    "fulladdress",
    "notescount",
    "login_data",
    "lastlogin_datetime",
    "contactInFo",
    "related_info",
    "firstname_s",
    "status",
    "parentid",
    "products",
    "contactInfo",
    "salesrep_f_name",
    "salesrep_l_name",
    "Salesrep_phone",
    "lastlogin_datetime",
    "parentid_s",
    "login_details",
  ];
  public commissionlistdataskip: any = [
    "addedby",
    "addedbytype",
    "addedfortype",
    "eventcommission",
    "product_s",
    "salescommission",
    "userdata",
    "userfullname",
    "userfullname_s",
    "_id",
  ];
  public commissionlistdatadetailskip: any = [];
  public searchsettingscommissionlistdata: any = {};
  public sortdatacommissionlistdata: any = {
    type: "desc",
    field: "createdon_datetime",
    options: [
      "Addedbyfullname",
      "productsname",
      "eventcommissiondata",
      "salescommissiondata",
      "createdon_datetime",
    ],
  };
  public datacollectioncommissionlistdata: any = "api/listmanagecommission";
  public newlyaddedleadsdatacollection: any = "api/leadlistview";
  libdatacommissionlistdata: any = {
    basecondition: { userid: this.userservice.userid },
    detailview_override: [],
    tableheaders: [
      "Addedbyfullname",
      "productsname",
      "eventcommissiondata",
      "salescommissiondata",
      "createdon_datetime",
    ],
    // searchBarFlagVal:true,
    // tableheaders name [key]
    // optional
    hidedeletebutton: false,
    updateendpointmany: "",
    updateendpoint: "",
    deleteendpointmany: "",
    hidemultipleselectbutton: true,
    hidecounter: null,
    hideaction: true,
    hideviewbutton: false, // (hide view button)
    // actioncolname: 'Actn',
    // not required,
    custombuttons: [],
  };
  libdatalead: any = {
    basecondition: { type: "lead", parentid: this.userservice.userid },
    detailview_override: [],
    tableheaders: [
      "fullname",
      "email",
      // 'phone',
      "createdon_datetime",
      "status",
    ],
    // searchBarFlagVal:true,
    // tableheaders name [key]
    // optional
    updateendpointmany: "",
    updateendpoint: "",
    deleteendpointmany: "",
    hidemultipleselectbutton: true,
    hidecounter: null,
    hideaction: false,
    hideviewbutton: true, // (hide view button)
    hideeditbutton: true,
    hidedeletebutton: true,
    hidestatustogglebutton: true,
    // actioncolname: 'Actn',
    // not required,
    custombuttons: [
      {
        label: "View Leads",
        type: "listner",
        id: "leadsfolderview",
        classname: "OpenFolderView",
        tooltip: "Open Leads Folder View",
      },
    ],
  };
  sortdatalead: any = {
    type: "desc",
    field: "createdon_datetime",
    options: ["fullname", "email", "phone", "status", "createdon_datetime"],
  };
  limitcondcommissionlistdata: any = {
    limit: 5,
    skip: 0,
    pagecount: 1,
  };
  limitcondlead: any = {
    limit: 5,
    skip: 0,
  };
  public slideindex_schedule: any = 0;
  slideindex_scheduletimeout: any;
  // public ScheduledFlag: boolean = false;
  public modifyheaderarray_schedule: any = {
    event_title: "Appointment Title",
    start_datetime_unix: "Appointment Date",
    timespan: "Appointment Duration",
    product_name: "Concerning Product",
    username: "Organizer Name",
    useremail: "Organizer email",
  };
  public modifyheaderarraycommissionlistdata: any = {
    Addedbyfullname: "Commission Added By",
    productsname: "Product Name",
    eventcommissiondata: "Event Commission",
    salescommissiondata: "Sales Commission",
    createdon_datetime: "Commission Created on",
  };
  public modifyheaderarrayjobDataList: any = {
    ticketid: "Ticket Id ",
    ticket_title: "Ticket Title",
    createdon_datetime: "Issue date",
    ticket_category_name: "Issue Category",
    details_html: "Issue description",
    reply_datetime: "Last Activity",
    ticket_status: "Status",
  };
  public leadmodify_header_array: any = {
    fullname: "Name ",
    email: "Email",
    createdon_datetime: "Join date",
    phone: "Phone",
    status: "Status",
  };
  public schedule_datatableskip: any = [];
  public schedule_datatabledetailskip: any = [];
  public sortdata_schedule: any = {
    type: "desc",
    field: "event_title",
    options: ["event_title"],
  };
  // send basic limit data
  public limitcond_schedule: any = {
    limit: 5,
    skip: 0,
    pagecount: 1,
  };
  public limitcondjobDataList: any = {
    limit: 10,
    skip: 0,
    pagecount: 1,
  };
  public libdata_schedule: any = {
    basecondition: { type: "" },
    detailview_override: [
      // { key: 'event_title', val: 'Appointment Title' },
      { key: "start_datetime_unix", val: "Appointment Date" },
      { key: "timespan", val: "Appointment Duration" },
      { key: "product_name", val: "Concerning Product" },
      { key: "username", val: "Organizer Name" },
      { key: "useremail", val: "Organizer email" },
    ],
    // table headline
    tableheaders: [
      // 'event_title',
      "start_datetime_unix",
      "timespan",
      "product_name",
      "username",
      "useremail",
    ],
    // hidestatustogglebutton: true,
    hidemultipleselectbutton: true,
    hideaction: false,
    hideeditbutton: true, // all these button options are optional not mandatory
    hidedeletebutton: true,
    hideviewbutton: true,
    hidestatustogglebutton: true,
    custombuttons: [
      {
        label: "Cancel",
        type: "listner",
        id: "btn_cancel",
        cond: "cancel_cond",
        condval: 0,
        // refreshdata: true,
        classname: "cancelBTN",
        tooltip: "Cancel",
      },
      {
        label: "Reschedule",
        route:
          "/calendar-management/manage-appointment/reschedule-appointments",
        type: "internallink",
        cond: "status",
        condval: 0,
        param: ["_id"],
        classname: "rescheduleBTN",
        tooltip: "Reschedule",
      },
    ],
  };
  value = 50;
  public dashboardData;
  public scheduled_appointments: any;
  public resourcedatalist: any = "";
  tablename = "";
  schedule_datesearchsourcecount: any = 0;
  datacollection_schedule = "api/techconsultantdashboard";
  datacollectionjobDataList = "api/getjobticketlistdata";
  schedule_datatable: any = [];
  public jobDataListskip: any = [];
  public jobDataListdetailskip: any = [];
  public libdatajobDataList: any = {
    basecondition: { user_id: this.userservice.userid },
    detailview_override: [
      { key: "ticketid", val: "Appointment Title" },
      { key: "user_name", val: "Appointment Date" },
      { key: "createdon_datetime", val: "Appointment Duration" },
      { key: "ticket_category_name", val: "Concerning Product" },
      { key: "details_html", val: "Organizer Name" },
      { key: "reply_datetime", val: "Organizer email" },
      { key: "ticket_status", val: "Organizer email" },
    ],
    // table headline
    tableheaders: [
      "ticketid",
      "ticket_title",
      "createdon_datetime",
      "ticket_category_name",
      "ticket_status",
    ],
    // hidestatustogglebutton: true,
    hidedeletebutton: true,
    updateendpointmany: "", // update multiple user
    updateendpoint: "", // update status
    deleteendpointmany: "", // delete multiple user
    hidemultipleselectbutton: true, // hide multiple selection button (false)
    hidecounter: null, // hide counter (false)
    hideaction: false, // hide action (false)
    hideviewbutton: true, // (hide view button)
    hideeditbutton: true,
    hidestatustogglebutton: true,
    custombuttons: [
      {
        label: "View Jobticket",
        type: "listner",
        id: "jobticketinnewpage",
        classname: "Folder_view",
        tooltip: "Open Jobticket View New Page",
      },
    ],
  };
  public searchsettingsjobDataList = [];
  public searchendpoint = "";
  public sortdatajobDataList: any = {
    type: "desc",
    field: "ticketid",
    options: [
      "event_title",
      "ticketid",
      "createdon_datetime",
      "ticket_category_name",
      "ticket_status",
      "ticket_title",
    ],
  };

  google_calendar_connect_time: any = "";
  public connected_gmail: any = "";
  public leadstatusarray: any = [
    { val: 1, name: "Active" },
    { val: 0, name: "Inactive" },
  ];

  public warranty_statusarr: any = [
    { val: 0, name: "Pending" },
    { val: 1, name: "Done" },
    { val: 2, name: "Canceled" },
  ];
  // warranty appoinment
  warranty_datesearchsourcecount: any = 0;
  public warranty_datatable: any = [];
  datacollection_warranty = "api8/list-followup-data";
  public warranty_datatableskip: any = [];
  public modifyheaderarray_warranty: any = {
    datetimeslot: "follow up time",
    timespan: "Appointment Duration",
    username: "Organizer Name",
    useremail: "Organizer email",
    attende_name: "Lead Name",
    attende_email: "Lead Email",
    attende_type: "Phone Number",
    followup: "No of calls",
    attende_phone: "Phone",
    contact_person: "Contact Person",
    dispositionfollow: "Disposition",
  };
  public warranty_datatabledetailskip: any = [
    "_id",
    "slotTimeActual",
    "notes",
    "eid",
    "start_datetime_unix",
    "event_type_val",
    "googleevent",
    "event_title_s",
    "eventid",
    "is_google_event",
    "lesson_id",
    "id",
    "attende_email_s",
    "attende_name_s",
    "status",
    "user_id",
    "username_s",
    "product_name",
    "product_name_s",
    "product_id",
    "slot_end_time",
    "training_id",
    "parent_id",
    "flag_for_show",
    "bookinguser",
    "event_type_val_s",
    "appoinment_date_timezone",
    "timespan",
    "endDateTime",
  ];
  public sortdata_warranty: any = {
    type: "desc",
    field: "event_title",
    options: [
      "event_title",
      "start_datetime_unix",
      "product_name",
      "attende_name",
      "attende_email",
    ],
  };
  public libdata_warranty: any = {
    basecondition: {
      // bookinguser: this.cookieservice.get('userid'),
      bookinguser: JSON.parse(this.cookie.get("userid")),
      timeflag: [1, 3],
    },

    detailview_override: [
      { key: "event_title", val: "Event Title" },
      { key: "contact_person", val: "Contact Person" },
      { key: "description", val: "Description" },
      { key: "username", val: "Organiser Name" },
      { key: "useremail", val: "Organiser Email" },
      { key: "dispositionfollow", val: "Disposition" },
      { key: "booking_date", val: "Booked On" },
      { key: "startdate", val: "Appointment Date" },
      { key: "slot", val: "Slot" },
      { key: "timespan", val: "Time span" },
      { key: "timezoneName", val: "Time zone" },
      { key: "product_name", val: "Product Name" },
      { key: "attende_name", val: "Attende Name" },
      { key: "attende_email", val: "Lead Email" },
      { key: "attende_type", val: "Attende Type" },
    ],
    // table headline
    tableheaders: ["attende_name", "datetimeslot", "dispositionfollow"],
    customselectbuttons: [
      {
        label: "Update Disposition",
        type: "listner",
        // cond: 'status',
        // condval: 0,
        classname: "disposition_warranty",
        tooltip: "Disposition for Follow up call ",
      },
    ],
    custombuttons: [
      {
        label: "Folder View",
        // route: '/user-details/',
        type: "listner",
        // param: ['type', '_id'],
        tooltip: "Folder View",
        classname: "FolderView",
      },
      {
        label: "Follow Up Call",
        type: "listner",
        id: "follow_up_call",
        classname: "follow_up_call",
        tooltip: "Follow Up Calls",
        refreshdata: true,
      },
      {
        label: "Disposition",
        type: "listner",
        // cond: 'status',
        // condval: 0,
        classname: "disposition_warranty",
        tooltip: "Disposition for Follow up call ",
        // refreshdata: true,
      },
      {
        label: "View All Disposition",
        type: "listner",
        // cond: 'status',
        // condval: 0,
        classname: "disposition",
        tooltip: "View All Disposition",
        // refreshdata: true,
      },
    ],
    // hidestatustogglebutton: true,
    hidedeletebutton: true,
    updateendpointmany: "", // update multiple user
    updateendpoint: "", // update status
    deleteendpointmany: "", // delete multiple user
    hidemultipleselectbutton: true, // hide multiple selection button (false)
    hidecounter: null, // hide counter (false)
    hideaction: false, // hide action (false)
    hideviewbutton: true, // (hide view button)
    hideeditbutton: true, // all these button options are optional not mandatory
    hidestatustogglebutton: true,
    hideupdatemany: true,
    hidedeletemany: true,
  };
  public limitcond_warranty: any = {
    limit: 5,
    skip: 0,
    pagecount: 1,
  };
  public select_product: any = [];
  public contractrequestlist: any = [];
  public fullname: any = "";
  public lastlogin: "";
  public refreshtokenfailed: any;
  public techcommissiondata: any = [];
  public activecommissionFlag: boolean = false;
  public commissionprogressflag: boolean = false;
  public commissiondatalistview: any = [];
  public commissiondatatablekip: any = [];
  public modifyheaderarraycommissiondata: any = {
    distributor_fullname: "Distributor Fullname",
    dirtibutor_email: "Distributor Email",
    tech_event_commission: "Event Commission",
    tech_commission: "Sales Commission",
  };
  public statusarray: any = [];
  public commissiondatatabledetailskip: any = [];
  public searching_settings_commission: any = {};
  public sortdata_commission: any = {
    type: "desc",
    field: "_id",
    options: [
      "distributor_fullname",
      "dirtibutor_email",
      "tech_event_commission",
      "tech_commission",
    ],
  };
  public datacollection_commission: any =
    "api10/get-distributor-commission-management-lists";
  libdata_commission: any = {
    basecondition: { techid: this.userservice.userid },
    detailview_override: [
      { key: "fullname", val: "Full Name" },
      { key: "email", val: "Email" },
    ],
    tableheaders: [
      "distributor_fullname",
      "dirtibutor_email",
      "tech_event_commission",
      "tech_commission",
    ],
    hidemultipleselectbutton: true,
    hideaction: true,
    hideeditbutton: true, // all these button options are optional not mandatory
    hidedeletebutton: true,
    hideviewbutton: true,
    hidestatustogglebutton: true,
    custombuttons: [],
  };
  limitcond_commission: any = {
    limit: 5,
    skip: 0,
  };
  color: ThemePalette = "warn";
  public randomstringFlag = false;
  public showing_commission_data: any = [];
  public landingpage_access_pece: boolean = false;
  public landingpage_access_pece_tmflow: boolean = false;
  public landingpage_access_bio: boolean = false;
  public landingpage_access_quadrant: boolean = false;
  public resource_access_pece: any = {
    flag: false,
    product_id: '',
  };
  public resource_access_pece_tmflow: any = {
    flag: false,
    product_id: '',
  };
  public resource_access_bio: any = {
    flag: false,
    product_id: '',
  };
  public resource_access_quadrant: any = {
    flag: false,
    product_id: '',
  };
  public tech_id: any = "";
  constructor(
    public userservice: UserserviceService,
    public cookie: CookieService,
    public router: Router,
    public apiservice: ApiService,
    public activateRoute: ActivatedRoute,
    public dialog: MatDialog,
    public bottomSheet: MatBottomSheet,
    public _snackBar: MatSnackBar,
    public observableservice: ObservableService,
    public domainService: DetectdomainService,
    

  ) {
    this.hostName = this.domainService.getdomain();
    if (this.hostName == "medigrade") {
      // console.log("medigrade ==> flag true")
      this.medigradeflag=true
    }




    this.tech_id = JSON.parse(this.cookie.get("userid"));
    // if (this.cookie.check('randomstring')) {
    //   console.log('+++++++++++++++');
    //   this.randomstringFlag = true;
    //   this.router.navigateByUrl(
    //     '/calendar-management/manage-appointment/reschedule-appointments/' +
    //     this.cookie.get('randomstring')
    //   );
    // } else {
    //   this.randomstringFlag = false;
    // }

    document.querySelector("body").style.display = 'none';

    if (this.cookie.check("firstname")) {
      this.fullname = this.fullname + JSON.parse(this.cookie.get("firstname"));
      console.log(this.fullname, ">>>>>>....");
    }
    if (this.cookie.check("lastname")) {
      this.fullname =
        this.fullname + " " + JSON.parse(this.cookie.get("lastname"));
      console.log(this.fullname, ">>>>>>....");
    }
    if (this.cookie.check("lastlogindate")) {
      this.lastlogin = JSON.parse(this.cookie.get("lastlogindate"));
      console.log(this.lastlogin, ">>>>>><<<<<<<....");
    }
    console.log("success cal add ========================>>>");

    if (this.cookie.check("connected_gmail")) {
      this.connected_gmail = JSON.parse(this.cookie.get("connected_gmail"));
    }
    if (this.cookie.check("google_calendar_connect_time")) {
      this.google_calendar_connect_time = JSON.parse(
        this.cookie.get("google_calendar_connect_time")
      );
    }
    if (this.cookie.check("refreshtokenfailed")) {
      this.refreshtokenfailed = JSON.parse(
        this.cookie.get("refreshtokenfailed")
      );
      console.log(this.refreshtokenfailed, ">>>>>><<<<<<<....+++++++++++++++");
    }
    // setTimeout(() => { }, 1000);

    // if (this.cookie.check('training') && this.cookie.get('training') == '1') {
    //   this.trainingcomp = this.cookie.get('training');
    //   // console.log()
    // } else {
    //   console.log('this.getdashboardtrainingdata(); +++++')
    //   this.getdashboardtrainingdata();
    // }
    if (
      this.cookie.check("secret") &&
      this.cookie.check("jwtToken") &&
      this.cookie.check("userid")
    ) {
      this.getproductselectlist();
      setTimeout(() => {
        this.gettrainglist();
      }, 500);
    }
    // this.commissionviewflag = JSON.parse(this.cookie.get('viewcommissionfortech'))
    let data: any = {
      condition: {
        limit: 5,
        skip: 0,
      },
      sort: {
        type: "desc",
        field: "_id",
      },

      searchcondition: { techid: JSON.parse(this.cookie.get("userid")) },
      secret: this.userservice.secret,
      token: this.userservice.jwttoken,
    };
    const endpointc = "api10/get-distributor-commission-management-lists-count";
    const endpoint = "api10/get-distributor-commission-management-lists";
    this.apiservice
      .getDataWithoutToken(data, endpoint)
      .subscribe((res: any) => {
        console.log(res);
        this.techcommissiondata = res.results.res;
        if (this.techcommissiondata.length > 0) {
          // this.activeflagone = this.activeflagone + 1;
        }
      });
    this.apiservice
      .getDataWithoutToken(
        { parentid: JSON.parse(this.cookie.get("parentid")) },
        "api10/get-landing-page"
      )
      .subscribe((res: any) => {
        for (const key in res.res[0].landing_page) {
          if (res.res[0].landing_page[key] == this.apiservice.product_pece) {
            this.landingpage_access_pece = true;
          }
          if (res.res[0].landing_page[key] == this.apiservice.product_tmflow) {
            this.landingpage_access_pece_tmflow = true;
          }
          if (res.res[0].landing_page[key] == this.apiservice.product_bioEnergetics) {
            this.landingpage_access_bio = true;
          }
          if (res.res[0].landing_page[key] == this.apiservice.product_RSTSanexas) {
            this.landingpage_access_quadrant = true;
          }
        }
      });
  }
  bookcalendar() {
    window.open(
      "calendar-management/manage-appointment/discovery/book-appointment/61adb3204f4bdb00081b2872/" +
      JSON.parse(this.cookie.get("userid"))
    );
  }
  ngAfterViewChecked() {
    setTimeout(() => {
      document.querySelector("body").style.display = 'block';

    }, 4000);


  }
  toggletechcommission(e: any) {
    console.log(e);
    if (e.checked === true) {
      //  console.warn('consultants', e.checked);
      this.activecommissionFlag = true;
      this.commissionprogressflag = true;
      this.techcommissionview();
    } else {
      //  console.warn('consultants', e.checked);
      this.activecommissionFlag = false;
      this.commissiondatalistview = [];
      this.techcommissionnorecordfound = false;
      this.isrefresh_tech_commission = false;
    }
  }
  techcommissionview() {
    this.commissiondatalistview = [];
    let data: any = {
      condition: {
        limit: 5,
        skip: 0,
      },
      sort: {
        type: "desc",
        field: "_id",
      },
      searchcondition: { techid: this.userservice.userid },
      secret: this.userservice.secret,
      token: this.userservice.jwttoken,
    };
    const endpointc = "api10/get-distributor-commission-management-lists-count";
    const endpoint = "api10/get-distributor-commission-management-lists";
    this.apiservice
      .getDataWithoutToken(data, endpointc)
      .subscribe((res: any) => {
        this.datesearchsourcecount_commission = res.count; // sarch count  (How many data there)
        // this.contractmanagerListdatatableflag = false;
      });
    this.apiservice
      .getDataWithoutToken(data, endpoint)
      .subscribe((res: any) => {
        this.commissiondatalistview = res.results.res; // sarch count  (How many data there)
        this.commissionprogressflag = false;
        // this.techconsultantsListdatatable = this.adminarray.mostactive_technological_consultant; // sarch count  (How many data there)
        // this.contractmanagerListdatatableflag = false;
      });
  }
  public dashbordtraiingdata: any = [];
  public dashbordlefttraiingdata: any = [];
  public dashbordrighttraiingdata: any = [];
  public dashbordlefttraiingdata1: any = [];
  getproductselectlist() {
    this.apiservice
      .getDataWithoutToken(
        {
          token: this.cookie.get("jwtToken"),
          secret: this.cookie.get("secret"),
          id: JSON.parse(this.cookie.get("userid")),
        },
        "api1/selectproduct"
      )
      .subscribe((response: any) => {
        console.log(response.result, "select products");
        this.select_product = response.result;
        console.log(this.select_product, "this.select_product");
      });
  }
  gettrainglist() {
    this.blockUI.start("Loading.......");
    let product;
    let products;
    // if (route.data.endpoint == 'api/techconsultantdashboard') {
    if (
      this.cookie.get("productarray") &&
      this.cookie.get("productarray") != null &&
      this.cookie.get("productarray") != ""
    ) {
      product = this.cookie.get("productarray");
      // console.log(product,'First');
      products = product.split(",");
      // console.log(products, 'second');
      // requestData.condition.products = products
    }
    // }
    setTimeout(() => {
      this.apiservice
        .getDataWithoutToken(
          {
            token: this.cookie.get("jwtToken"),
            secret: this.cookie.get("secret"),
            user_id: JSON.parse(this.cookie.get("userid")),
            products: products,
            type: this.userservice.type,
          },
          "api1/dashboardtraingpercent"
        )
        .subscribe((response: any) => {
          console.log("dashboardtraingpercent ==>",response);
          this.cookie.set("singletraining", response.results.singletraining); // this cookie is set for checking the universal tech product traing is complete or not
          this.training_Data_block = true;
          console.log(response.results.associatedtraining.associated_training);
          // if(response.results.rigthtrainingdata.length > 0){
          //   let product_training_complete_flag =0;
          //   for (var i in response.results.rigthtrainingdata){
          //     if(response.results.rigthtrainingdata[i].percent == 100){
          //       console.log("Atleast one product training complete")
          //       product_training_complete_flag = 1
          //     }
          //   }
          //   if(product_training_complete_flag ==1){
          //     this.cookie.set("product_training_complete_flag",
          //     "1",
          //     undefined,
          //     "/");
          //   }
            
          // }
          if (
            response.results.rigthtrainingdata.length > 0 
          ) {
            for (let data of response.results.rigthtrainingdata){
              if (data.percent == 100){
                this.training_Data_block = false;
              }
            } 
            
          }
          this.dashbordtraiingdata = response.results;
          response.results.associatedtraining.associated_training =
            JSON.stringify(
              response.results.associatedtraining.associated_training
            );
          response.results.associatedtraining.current_lesson_id =
            JSON.stringify(
              response.results.associatedtraining.current_lesson_id
            );
          this.cookie.set(
            "associated_training",
            response.results.associatedtraining.associated_training,
            undefined,
            "/"
          );
          this.cookie.set(
            "currentlesson",
            response.results.associatedtraining.current_lesson_id,
            undefined,
            "/"
          );

          // console.log(this.dashbordtraiingdata.alltrainglist);
          // console.log(this.dashbordtraiingdata.lefttrainingdata);
          let alltrainglist = this.dashbordtraiingdata.alltrainglist;
          let lefttrainingdata = this.dashbordtraiingdata.lefttrainingdata;
          let rigthtrainingdata = this.dashbordtraiingdata.rigthtrainingdata;

          for (const key in this.dashbordtraiingdata.alltrainglist) {
            if (this.dashbordtraiingdata.lefttrainingdata.length > 0) {
              for (const keys in this.dashbordtraiingdata.lefttrainingdata) {
                if (
                  this.dashbordtraiingdata.lefttrainingdata[keys]
                    .associated_training ==
                  this.dashbordtraiingdata.alltrainglist[key]._id &&
                  this.dashbordtraiingdata.alltrainglist[key]
                    .traingcompleteflag == "true" &&
                  this.dashbordtraiingdata.lefttrainingdata[keys].percent !=
                  null &&
                  typeof this.dashbordtraiingdata.lefttrainingdata[keys]
                    .percent != undefined
                ) {
                  this.dashbordlefttraiingdata.push({
                    percent:
                      this.dashbordtraiingdata.lefttrainingdata[keys].percent,
                    categoryname:
                      this.dashbordtraiingdata.lefttrainingdata[keys]
                        .categoryname,
                    _id: this.dashbordtraiingdata.lefttrainingdata[keys]
                      .associated_training,
                  });
                  // console.log(this.dashbordtraiingdata.lefttrainingdata);
                }

                // if (this.dashbordtraiingdata.alltrainglist[key]._id !=this.dashbordtraiingdata.lefttrainingdata[keys].associated_training   && this.dashbordtraiingdata.alltrainglist[key].traingcompleteflag == 'true' ) {
                //   console.log(this.dashbordtraiingdata.alltrainglist[key]);
                //   // this.dashbordlefttraiingdata.push({ percent: this.dashbordtraiingdata.lefttrainingdata[keys].percent, categoryname: this.dashbordtraiingdata.lefttrainingdata[keys].categoryname });
                //   // console.log(this.dashbordlefttraiingdata);

                // }
              }
            }
          }
          const uniqueResultOne = alltrainglist.filter(function (obj) {
            return !lefttrainingdata.some(function (obj2) {
              return (
                obj._id == obj2.associated_training &&
                obj.traingcompleteflag == obj2.categorydataflag
              );
            });
          });
          console.log(uniqueResultOne, this.dashbordlefttraiingdata);
          if (
            uniqueResultOne != null &&
            uniqueResultOne != "" &&
            typeof uniqueResultOne != undefined
          ) {
            for (const key in uniqueResultOne) {
              if (uniqueResultOne[key].traingcompleteflag == "true") {
                this.dashbordlefttraiingdata.push({
                  percent: 0,
                  categoryname: uniqueResultOne[key].catagory_name,
                  _id: uniqueResultOne[key]._id,
                });
              }
            }
          }

          for (const key in this.dashbordtraiingdata.alltrainglist) {
            if (this.dashbordtraiingdata.rigthtrainingdata.length > 0) {
              for (const keys in this.dashbordtraiingdata.rigthtrainingdata) {
                if (
                  this.dashbordtraiingdata.rigthtrainingdata[keys]
                    .associated_training ==
                  this.dashbordtraiingdata.alltrainglist[key]._id &&
                  this.dashbordtraiingdata.alltrainglist[key]
                    .traingcompleteflag == "false" &&
                  this.dashbordtraiingdata.rigthtrainingdata[keys].percent !=
                  null &&
                  typeof this.dashbordtraiingdata.rigthtrainingdata[keys]
                    .percent != undefined
                ) {
                  console.log(this.dashbordtraiingdata.rigthtrainingdata[keys]);
                  this.dashbordrighttraiingdata.push({
                    percent:
                      this.dashbordtraiingdata.rigthtrainingdata[keys].percent,
                    categoryname:
                      this.dashbordtraiingdata.rigthtrainingdata[keys]
                        .categoryname,
                    _id: this.dashbordtraiingdata.rigthtrainingdata[keys]
                      .associated_training,
                  });
                }
                // if (this.dashbordtraiingdata.alltrainglist[key]._id !=this.dashbordtraiingdata.lefttrainingdata[keys].associated_training   && this.dashbordtraiingdata.alltrainglist[key].traingcompleteflag == 'true' ) {
                //   console.log(this.dashbordtraiingdata.alltrainglist[key]);
                //   // this.dashbordlefttraiingdata.push({ percent: this.dashbordtraiingdata.lefttrainingdata[keys].percent, categoryname: this.dashbordtraiingdata.lefttrainingdata[keys].categoryname });
                //   // console.log(this.dashbordlefttraiingdata);

                // }
              }
            }
          }

          const uniqueResulttwo = alltrainglist.filter(function (obj) {
            return !rigthtrainingdata.some(function (obj2) {
              return obj._id == obj2.associated_training;
            });
          });
          if (
            uniqueResulttwo != null &&
            uniqueResulttwo != "" &&
            typeof uniqueResulttwo != undefined
          ) {
            // console.warn(uniqueResulttwo);

            for (const key in uniqueResulttwo) {
              if (uniqueResulttwo[key].traingcompleteflag == "false") {
                this.dashbordrighttraiingdata.push({
                  percent: 0,
                  categoryname: uniqueResulttwo[key].catagory_name,
                  _id: uniqueResulttwo[key]._id,
                });
              }
            }
          }
          setTimeout(() => {
            this.blockUI.stop();
          }, 100);
          // for (const key in this.dashbordtraiingdata.alltrainglist) {

          //   if (this.dashbordtraiingdata.lefttrainingdata.length > 0) {
          //     for (const keys in this.dashbordtraiingdata.lefttrainingdata) {

          //     }
          //   }
          // }
          console.log(this.dashbordlefttraiingdata, "++++++++++++++++++++");
          if (this.dashbordlefttraiingdata.length > 0) {
            for (const key in this.dashbordlefttraiingdata) {
              console.log(this.dashbordlefttraiingdata[key]);
            }
          }
          if (response.results.rigthtrainingdata.length > 0) {
            let flag = false;
            for (const key in response.results.rigthtrainingdata) {
              if (response.results.rigthtrainingdata[key].percent >= 100) {
                flag = true;
              }
            }
            console.log(flag);
            if (flag) {
              this.cookie.set("training", "1", undefined, "/");
              this.observableservice.setlocationsData("1");
              this.trainingcomp = "1";
            }
            // else {
            //   this.cookieservice.set('training', '0', undefined, '/');
            //   this.trainingcomp = '0';
            // }
          }
          console.log(this.dashbordlefttraiingdata);
          if (this.dashbordlefttraiingdata.length > 0) {
            const result = this.dashbordlefttraiingdata.filter((s) => {
              if (s.percent == 100) {
                return s;
              }
            });
            const result1 = this.dashbordtraiingdata.alltrainglist.filter(
              (s) => {
                if (s.traingcompleteflag == "true") {
                  // console.log(s);
                  return s;
                }
              }
            );

            // console.log(result.length, result1);

            if (result.length == result1.length) {
              this.leftcompletetraing = true;
            }
          }
          if (this.dashbordtraiingdata.singletrainglessoncount.length > 0) {
            for (const key in this.dashbordtraiingdata
              .singletrainglessoncount) {
              this.singletrainingdata[
                this.dashbordtraiingdata.singletrainglessoncount[key]._id
              ] =
                this.dashbordtraiingdata.singletrainglessoncount[
                  key
                ].lessoncount;
            }
          }
          if (this.dashbordtraiingdata.singletraining.length > 0) {
            for (const key in this.dashbordtraiingdata.singletraining) {
              if (this.dashbordtraiingdata.singletrainglessoncount.length > 0) {
                for (const keys in this.dashbordtraiingdata
                  .singletrainglessoncount) {
                  if (
                    this.dashbordtraiingdata.singletrainglessoncount[keys]
                      ._id ==
                    this.dashbordtraiingdata.singletraining[key]
                      .associated_training
                  ) {
                    this.singletrainingdata_count[
                      this.dashbordtraiingdata.singletraining[
                        key
                      ].associated_training
                    ] = this.dashbordtraiingdata.singletraining.length;
                    console.log(
                      "hhhhhhhhhhhhhhh",
                      this.singletrainingdata_count
                    );
                  }
                }
              }
            }
          }
        });
    }, 500);
  }
  goToResorce(val) {
    console.log(this.apiservice.domain_url, '+++');
    // return
    window.open(this.apiservice.domain_url + "resources/product/" + val, "_blank");
  }
  gototaining(val) {
    console.log(val, this.dashbordtraiingdata.alltrainglist);
    let ind = 0;
    let associated_training = "";
    for (const i in val) {
      for (const j in this.dashbordtraiingdata.alltrainglist) {
        if (
          val[i].associated_training ==
          this.dashbordtraiingdata.alltrainglist[j]._id
        ) {
          ind = parseInt(j) + 1;
          console.log(typeof ind, ind);
          if (
            ind &&
            ind != null &&
            this.dashbordtraiingdata.alltrainglist[ind]
          ) {
            associated_training =
              this.dashbordtraiingdata.alltrainglist[ind]._id;
          }
        }
      }
    }
    this.router.navigateByUrl(
      "/traning/training-center-beto-paredes/" + associated_training
    );
  }
  reschedule(val: any) {
    console.log(val, "hjjjjjjjjjjjjjjjjjjjjjjjj");
    this.router.navigateByUrl(
      "/calendar-management/manage-appointment/reschedule-appointments/" +
      val._id +
      "/" +
      val.event_type
    );
  }
  // getdashboardtrainingdata() {

  //   let datafortraining = {
  //     condition: {

  //       associated_training: '6007d728411dbf000889b1ef'
  //     },
  //     user_id: this.userservice.userid
  //   }

  //   this.apiservice.getDataWithoutToken(datafortraining, 'api1/dashboardtrainingdata').subscribe((res: any) => {
  //     // console.log(res, 'gggggggggggggggg')

  //     if (res.status === 'success' && Object.keys(res.results).length > 0) {
  //       // // console.log(res.results.alllessondata != null && res.results.done_lesson_by_cat_by_user != '' && res.results.done_lesson_by_cat_by_user[0].lessonsdone != null && res.results.alllessondata == res.results.done_lesson_by_cat_by_user[0].lessonsdone)
  //       if (res.results.alllessondata != null && res.results.done_lesson_by_cat_by_user != '' && res.results.done_lesson_by_cat_by_user[0].lessonsdone != null && res.results.done_lesson_by_cat_by_user[0].lessonsdone <= res.results.alllessondata) {
  //         this.cookie.set('training', '1', undefined, '/');
  //         this.trainingcomp = '1';
  //       }
  //     }
  //   })
  // }
  resourcedatafun() {
    this.apiservice
      .getData("api/resourcelist", { id: this.userservice.userid })
      .subscribe((res: any) => {
        for (const i in res.results.ResourceView) {
          for (const j in res.results.trainingcompletedwithproducts) {
            if (
              res.results.trainingcompletedwithproducts[j].productid != null &&
              res.results.ResourceView[i].products ==
              res.results.trainingcompletedwithproducts[j].productid
            ) {
              res.results.ResourceView[i]["complete"] = true;
            }
          }
        }
        this.resourcedatalist = res.results;
        // console.log('+_+_+_+_', this.resourcedatalist);
      });
  }

  toggleaAppointmentgrid(e: any) {
    //  console.warn('Appointment', e.checked);

    if (e.checked === true) {
      //  console.warn('Appointment', e.checked);
      this.AppointmentgridFlag = true;
    } else {
      //  console.warn('Appointment', e.checked);
      this.AppointmentgridFlag = false;
    }
  }
  toggleaLeadsgrid(e: any) {
    //  console.warn('Leads', e.checked);

    if (e.checked === true) {
      //  console.warn('Leads', e.checked);
      this.LeadsgridFlag = true;
    } else {
      //  console.warn('Leads', e.checked);
      this.LeadsgridFlag = false;
    }
  }
  schedule_appointments() {
    // this.ScheduledFlagScheduledFlag = true;
    // this.Scheduleddatatableflag = true;
    // const endpoint = 'api/techconsultantdashboard';
    // const data: any = { // How many data showing in first page
    //   secret: this.userservice.secret,
    //   token: this.userservice.jwttoken,
    //   source: '',
    //   id:this.userservice.userid,
    //   condition: {  // condition for how much data show in one time
    //     limit: 10,
    //     skip: 0,
    //   },
    //   sort: {
    //     type: 'desc',  // data sort by decreasing order
    //     field: 'event_title',  // data sort by first name
    //   },
    // };

    // this.apiservice
    //   .getDataWithoutToken(data, endpoint)
    //   .subscribe((res: any) => {
    //     // console.log(res, 'schedule_appointments')
    //     this.schedule_datatable = res.results.scheduled_appointments; // sarch count  (How many data there)
    //     // console.log(this.schedule_datatable,'schedule_datatableeeeeeee')
    this.schedule_datatable = this.scheduled_appointments;
    // this.Scheduleddatatableflag = false;

    //   });
  }
  slideforcommissionPrev(idx: any) {
    let slides: any = document.getElementsByClassName("main_practice_block5");
    let scount: any = document.querySelectorAll(".main_practice_block5").length;
    this.slideindex_signature = idx;
    if (this.slideindex_signature <= 0) {
      this.slideindex_signature = scount;
    }
    let slideindex_signature: any = this.slideindex_signature;
    for (var i = slides.length - 1; i >= 0; i--) {
      slides[i].style.display = "none";
    }
    if (slideindex_signature > 0 && slides[slideindex_signature - 1] != null) {
      slides[slideindex_signature - 1].style.display = "block";
    }
    slideindex_signature--;
  }

  slideforcommissionNext(idx: any) {
    // console.log('next', idx, this.slideindex_jobtickets)

    this.slideindex_jobtickets = 0;
    this.slideindex_jobtickets = idx + 1;
    // let i: any;
    let slides: any = document.getElementsByClassName("main_practice_block5");
    let scount: any = document.querySelectorAll(".main_practice_block5").length;

    // console.log('next 111', this.slideindex_jobtickets, scount)
    if (this.slideindex_jobtickets >= scount) {
      this.slideindex_jobtickets = 0;
    }
    let slideindex_jobtickets: any = this.slideindex_jobtickets++;

    let dots: any = document.getElementsByClassName("dot");

    for (let i = 0; i < slides.length; i++) {
      slides[i].style.display = "none";
    }

    slideindex_jobtickets++;
    if (slideindex_jobtickets > slides.length) {
      slideindex_jobtickets = 1;
    }
    for (let i in dots.length) {
      // console.log(i, '__')
      if (dots[i] != null) {
        dots[i].className = dots[i].className.replace(" active", "");
      }
    }
    if (slideindex_jobtickets > 0 && slides[slideindex_jobtickets - 1] != null)
      slides[slideindex_jobtickets - 1].style.display = "block";
    if (slideindex_jobtickets > 0 && dots[slideindex_jobtickets - 1] != null)
      dots[slideindex_jobtickets - 1].className += " active";

    this.slide_jobtickets("e");
  }
  leadlist() {
    this.NewlyAddedtableflag = true;
    const endpointc = "api/leadlistview-count";
    const endpoint = "api/leadlistview";
    const data: any = {
      // How many data showing in first page
      secret: this.cookie.get("secret"),
      token: this.cookie.get("jwtToken"),
      source: "",
      condition: {
        // condition for how much data show in one time
        limit: 5,
        skip: 0,
      },
      sort: {
        type: "desc", // data sort by decreasing order
        field: "createdon_datetime", // data sort by first name
      },
      searchcondition: { type: "lead", parentid: this.userservice.userid },
    };

    this.apiservice
      .getDataWithoutToken(data, endpoint)
      .subscribe((res: any) => {
        this.leadListdatatable = res.results.res; // sarch count  (How many data there)
        this.NewlyAddedtableflag = false;
      });

    this.apiservice
      .getDataWithoutToken(data, endpointc)
      .subscribe((res: any) => {
        this.datesearchsourcecountnewlyaddedlead = res.count; // sarch count  (How many data there)
      });
  }
  public slideIndex = 0;
  public _timer: any;
  showSlides() {
    let i;
    let slides = document.getElementsByClassName(
      "mySlides"
    ) as HTMLCollectionOf<HTMLElement>;
    let dots = document.getElementsByClassName("dot");
    for (i = 0; i < slides.length; i++) {
      slides[i].style.display = "none";
    }
    this.slideIndex++;
    if (this.slideIndex > slides.length) {
      this.slideIndex = 1;
    }
    for (i = 0; i < dots.length; i++) {
      dots[i].className = dots[i].className.replace(" active", "");
    }
    slides[this.slideIndex - 1].style.display = "block";
    dots[this.slideIndex - 1].className += " active";
    // setTimeout(this.showSlides(), 4500); // Change image every 2 seconds

    this._timer = setTimeout(() => {
      this.showSlides();
    }, 4500);
  }

  scroll1() {
    document
      .querySelector(".landingpage_access_pece")
      .scrollIntoView({ behavior: "smooth" });
  }

  scroll2() {
    document
      .querySelector(".landingpage_access_pece_tmflow")
      .scrollIntoView({ behavior: "smooth" });
  }

  scroll3() {
    document
      .querySelector(".landingpage_access_bio")
      .scrollIntoView({ behavior: "smooth" });
  }

  scroll4() {
    document
      .querySelector(".landingpage_access_bio ")
      .scrollIntoView({ behavior: "smooth" });
  }

  ngOnInit() {
    this.hostName = this.domainService.getdomain();
    if (this.hostName == "medigrade") {
      this.medigradeflag=true  
    }
    this.showSlides();
    if (this.cookie.check("access_token")) {
      this.hasAccessToken = true;
    }

    const body = document.getElementsByTagName("body")[0];
    body.classList.add("technologicalconsultant");

    if (this.cookie.check("firstname")) {
      this.userFullName = JSON.parse(this.cookie.get("firstname"));
    }
    if (this.cookie.check("lastname")) {
      this.userFullName =
        this.userFullName + " " + JSON.parse(this.cookie.get("lastname"));
    }
    if (this.cookie.check("lastlogindate")) {
      this.lastlogindate = JSON.parse(this.cookie.get("lastlogindate"));
    }

    this.activateRoute.data.forEach((res: any) => {
      console.log(
        res.resolvedata.results.userdata[0].productsarray,
        "technological..............."
      );
      // this.adminlistarray = res.resolvedata.results.res;
      this.user_data = res.resolvedata.results.userdata[0];
      this.jobcategoryListdatatable = res.resolvedata.results.recentjobtickets;
      this.dashboardData = res.resolvedata.results;
      this.leadListdatatablegrid = res.resolvedata.results.leaddata;
      console.log(this.dashboardData, "ttttttttttttttttttttttttttttttttttt");
      this.scheduled_appointments =
        res.resolvedata.results.scheduled_appointments;
      // this.commissiondata = res.resolvedata.results.commissiondata;
      this.warranty_appointments =
        res.resolvedata.results.warranty_appointments;
      console.log(this.warranty_appointments, "this.warranty_appointments");

      this.notificationdata =
        res.resolvedata.results.contactmodificationforsystem;
      if (
        this.dashboardData.parentdata &&
        this.dashboardData.parentdata.length > 0 &&
        typeof this.dashboardData.parentdata[0].viewcommissionflag !=
        "undefined" &&
        this.dashboardData.parentdata[0].viewcommissionflag == true
      ) {
        this.commissionviewflag = true;
      } 
      // if (this.dashboardData.parentdata[0].viewcommissionflag != true) {
      //   this.commissionviewflag = false;
      // }
      // // console.log(this.scheduled_appointments, 'yyyyyyyyyyyyyy');booking_user_id

      //  this.schedule_datatable = res.resolvedata.results.scheduled_appointments
      // if (this.warranty_appointments.length == 0) {
      //   this.activeflag = this.activeflag + 1;
      // }
      if (this.dashboardData.trainingresult.length >= 0) {
        this.activeflag = 1;
      }
      if (this.scheduled_appointments.length == 0) {
        this.activeflag = this.activeflag + 1;
      }
      console.log(
        this.dashboardData.trainingresult.length,
        "this.dashboardData.trainingresult[0].length"
      );

      // if (this.warranty_appointments.length == 0) {
      //   this.activeflag = this.activeflag + 1;
      // }

      // if (this.webinarsListdatatable.length == 0) {
      //   this.activeflag = this.activeflag + 1;
      // }
      // if (this.commissiondata.length == 0) {
      //   this.activeflagone = this.activeflagone + 1;
      // }
      if (this.scheduled_appointments.length > 0) {
        this.activeflag = this.activeflag + 1;
      }
      if (this.warranty_appointments.length == 0) {
        this.activeflag = this.activeflag + 1;
      }
      if (this.jobcategoryListdatatable.length > 0) {
        this.activeflagone = this.activeflagone + 1;
      }
      if (
        this.dashboardData.userdata.length > 0 &&
        this.dashboardData.userdata[0].productsarray != null &&
        this.dashboardData.userdata[0].productsarray.length > 0
      ) {
        console.log(this.dashboardData.userdata[0].productsarray);
        this.cookie.set(
          "productarray",
          this.dashboardData.userdata[0].productsarray,
          undefined,
          "/"
        );
        // localStorage.setItem('productarray', this.dashboardData.userdata[0].productsarray);
      }
      if (
        typeof res.resolvedata.results.parentdata[0].disableproduct !=
        "undefined" &&
        res.resolvedata.results.parentdata[0].disableproduct != null
      ) {
        for (const key in res.resolvedata.results.parentdata[0]
          .disableproduct) {
          if (
            res.resolvedata.results.parentdata[0].disableproduct[key] ==
            this.apiservice.product_pece
          ) {
            this.resource_access_pece.flag = true;
            this.resource_access_pece.product_id = this.apiservice.product_pece;
          }
          if (
            res.resolvedata.results.parentdata[0].disableproduct[key] ==
            this.apiservice.product_tmflow
          ) {
            this.resource_access_pece_tmflow.flag = true;
            this.resource_access_pece_tmflow.product_id = this.apiservice.product_tmflow;
          }
          if (
            res.resolvedata.results.parentdata[0].disableproduct[key] ==
            this.apiservice.product_bioEnergetics
          ) {
            this.resource_access_bio.flag = true;
            this.resource_access_bio.product_id = this.apiservice.product_bioEnergetics;
          }
          if (
            res.resolvedata.results.parentdata[0].disableproduct[key] ==
            this.apiservice.product_RSTSanexas
          ) {
            this.resource_access_quadrant.flag = true;
            this.resource_access_quadrant.product_id = this.apiservice.product_RSTSanexas;
          }
        }
      }
    });

    // setTimeout(() => {
    //   this.getallsharelinks();
    // }, 1000);

    // setTimeout(() => {
    //   // console.log('cjuhiugboiuguygviuuhyvuhv', this.userservice.userid)
    //   this.resourcedatafun();
    // }, 3400);
    setTimeout(() => {
      this.gameplanmodal();
    }, 50);
    this.commission_func();
    this.newtraingpercent();
  }
  dashbordtraingdata: any = [];
  newtraingpercent() {
    let requestdata = {
      data: {
        id: "",
        products: "",
      },
      endpoint: "",
    };
    this.blockUI.start("Loading.......");
    if (this.cookie.check("userid")) {
      console.log("llllllllllllllllll+++++++++===lll");
      requestdata.data.id = JSON.parse(this.cookie.get("userid"));
      requestdata.data.products = this.dashboardData.userdata[0].productsarray;
      requestdata.endpoint = "trainingpercentfortech";
    }
    console.log(requestdata);

    // data: {
    //   id:
    // },
    // endpoint: 'trainingpercentfortech'

    this.apiservice.getDataFortraing(requestdata).subscribe((res: any) => {
      console.log(res);
      if (res.Status == "success") {
        this.dashbordtraingdata = res.data;
        console.log(this.dashbordtraingdata.percent);
        // this.training_Data_block = false;
        let styleObject_wi = { width: this.dashbordtraingdata.percent };
        this.styleObject = styleObject_wi;
        setTimeout(() => {
          this.blockUI.stop();
        }, 50);
      }
    });
  }

  jobticketlistenLiblistingChange(val: any) {
    // console.log(val,'KKKKKKKKKKKKK');
    if (
      val.custombuttonclick != null &&
      typeof val.custombuttonclick != "undefined"
    ) {
      if (val.custombuttonclick.btninfo.id == "jobticketinnewpage") {
        window.open(
          "/jobticket/view-managejobticket/" +
          val.custombuttonclick.data._id +
          "/" +
          val.custombuttonclick.data.user_id,
          "Independent Window"
        );
      }
    }
  }
  traingdata(alldata, i, flag) {
    console.log(alldata[i], i, flag);
    console.log(localStorage.getItem("productarray"));
    if (flag == "left") {
      if (i == 0) {
        this.router.navigateByUrl(
          "/traning/training-center-beto-paredes/" + alldata[i]._id
        );
      } else if (i > 0 && alldata[i - 1].percent == 100) {
        this.router.navigateByUrl(
          "/traning/training-center-beto-paredes/" + alldata[i]._id
        );
      } else {
        let msg = "Sorry you have to Complete " + alldata[i - 1].categoryname;
        this.openSnackBar(msg, "ok");
      }
    }
    if (flag == "right") {
      if (this.dashbordlefttraiingdata.length > 0) {
        const uniqueResultOne = this.dashbordlefttraiingdata.filter(function (
          obj
        ) {
          return obj.percent != 100;
        });

        // for (const key in this.dashbordlefttraiingdata) {
        //   if (this.dashbordlefttraiingdata[key].percent==100) {

        //   }
        // }
        console.log(uniqueResultOne, this.dashbordlefttraiingdata);

        if (uniqueResultOne.length == 0) {
          this.router.navigateByUrl(
            "/traning/training-center-beto-paredes/" + alldata[i]._id
          );
          // console.warn(alldata);
        } else {
          let msg = "Sorry you have to Complete ";
          let msg2: any = " ";
          let msgarray: any = [];
          console.log(this.dashbordlefttraiingdata);

          for (const key in this.dashbordlefttraiingdata) {
            msgarray.push(
              this.dashbordlefttraiingdata[key].categoryname + ",  "
            );
            msg2 += msgarray[key];
            console.warn(msg2);
          }
          console.log(msgarray);

          msg2 = msg + msg2;
          this.openSnackBar(msg2, "ok");
        }
        console.log(uniqueResultOne, "llllllllllllllll");
      }
    }
  }
  openSnackBar(message: string, action: string) {
    this._snackBar.open(message, action);
  }
  commission_func() {
    // this.commission_data_details = [];
    console.log("====================================");
    console.log(this.dashboardData, "++++++++");
    console.log("====================================");
    let data = {
      searchcondition: {
        user_type: "technological-consultant",
      },
    };

    if (
      this.dashboardData &&
      this.dashboardData.parentdata &&
      this.dashboardData.parentdata.length > 0
    ) {
      data.searchcondition["product_id"] = {
        $in: this.dashboardData.parentdata[0].disableproduct,
      };
    }
    this.apiservice
      .getDataWithoutToken(data, "api10/get-commission-rule")
      .subscribe((data: any) => {
        this.commission_data_details = data.res;
        for (let key in this.commission_data_details) {
          let check_name =
            this.commission_data_details[key].product_name.replace(
              /[-' ']/g,
              "_"
            ) + this.commission_data_details[key].commission_type;
          // console.log('====================================');
          // console.log(check_name, '+++++++');
          // console.log('====================================');
          if (
            check_name == "PECEevent_commission" &&
            this.user_data.PECEevent_commission != null &&
            this.user_data.PECEevent_commission != ""
          ) {
            this.commission_data_details[key].commission_value =
              this.user_data.PECEevent_commission;
          } else {
            if (
              check_name == "PECEevent_commission" &&
              this.dashboardData.parentdata != null &&
              this.dashboardData.parentdata.length > 0 &&
              this.dashboardData.parentdata[0].PECEevent_commission != null &&
              this.dashboardData.parentdata[0].PECEevent_commission != ""
            ) {
              this.commission_data_details[key].commission_value =
                this.dashboardData.parentdata[0].PECEevent_commission;
            }
          }
          if (
            check_name == "PECEsales_commission" &&
            this.user_data.PECEsales_commission != null &&
            this.user_data.PECEsales_commission != ""
          ) {
            this.commission_data_details[key].commission_value =
              this.user_data.PECEsales_commission;
          } else {
            if (
              check_name == "PECEsales_commission" &&
              this.dashboardData.parentdata != null &&
              this.dashboardData.parentdata.length > 0 &&
              this.dashboardData.parentdata[0].PECEsales_commission != null &&
              this.dashboardData.parentdata != null &&
              this.dashboardData.parentdata[0].PECEsales_commission != ""
            ) {
              this.commission_data_details[key].commission_value =
                this.dashboardData.parentdata[0].PECEsales_commission;
            }
          }
          if (
            check_name == "TM_FLOW_ANS_MEDICAL_DEVICEsales_commission" &&
            this.user_data.TM_FLOW_ANS_MEDICAL_DEVICEsales_commission != null &&
            this.user_data.TM_FLOW_ANS_MEDICAL_DEVICEsales_commission != ""
          ) {
            this.commission_data_details[key].commission_value =
              this.user_data.TM_FLOW_ANS_MEDICAL_DEVICEsales_commission;
          } else {
            if (
              check_name == "TM_FLOW_ANS_MEDICAL_DEVICEsales_commission" &&
              this.dashboardData.parentdata != null &&
              this.dashboardData.parentdata.length > 0 &&
              this.dashboardData.parentdata[0]
                .TM_FLOW_ANS_MEDICAL_DEVICEsales_commission != null &&
              this.dashboardData.parentdata[0]
                .TM_FLOW_ANS_MEDICAL_DEVICEsales_commission != ""
            ) {
              this.commission_data_details[key].commission_value =
                this.dashboardData.parentdata[0].TM_FLOW_ANS_MEDICAL_DEVICEsales_commission;
            }
          }
          if (
            check_name == "RST_Sanexussales_commission" &&
            this.user_data.RST_Sanexussales_commission != null &&
            this.user_data.RST_Sanexussales_commission != ""
          ) {
            this.commission_data_details[key].commission_value =
              this.user_data.RST_Sanexussales_commission;
          } else {
            if (
              check_name == "RST_Sanexussales_commission" &&
              this.dashboardData.parentdata != null &&
              this.dashboardData.parentdata.length > 0 &&
              this.dashboardData.parentdata[0].RST_Sanexussales_commission !=
              null &&
              this.dashboardData.parentdata[0].RST_Sanexussales_commission != ""
            ) {
              this.commission_data_details[key].commission_value =
                this.dashboardData.parentdata[0].RST_Sanexussales_commission;
            }
          }
          if (
            check_name == "Bio_Energeticsevent_commission" &&
            this.user_data.Bio_Energeticsevent_commission != null &&
            this.user_data.Bio_Energeticsevent_commission != ""
          ) {
            this.commission_data_details[key].commission_value =
              this.user_data.Bio_Energeticsevent_commission;
          } else {
            if (
              check_name == "Bio_Energeticsevent_commission" &&
              this.dashboardData.parentdata != null &&
              this.dashboardData.parentdata.length > 0 &&
              this.dashboardData.parentdata[0].Bio_Energeticsevent_commission !=
              null &&
              this.dashboardData.parentdata[0].Bio_Energeticsevent_commission !=
              ""
            ) {
              this.commission_data_details[key].commission_value =
                this.dashboardData.parentdata[0].Bio_Energeticsevent_commission;
            }
          }
          if (
            check_name == "Bio_Energeticssales_commission" &&
            this.user_data.Bio_Energeticssales_commission != null &&
            this.user_data.Bio_Energeticssales_commission != ""
          ) {
            this.commission_data_details[key].commission_value =
              this.user_data.Bio_Energeticssales_commission;
          } else {
            if (
              check_name == "Bio_Energeticssales_commission" &&
              this.dashboardData.parentdata != null &&
              this.dashboardData.parentdata.length > 0 &&
              typeof this.dashboardData.parentdata[0]
                .Bio_Energeticssales_commission != "undefined" &&
              this.dashboardData.parentdata[0].Bio_Energeticssales_commission !=
              null &&
              this.dashboardData.parentdata[0].Bio_Energeticssales_commission !=
              ""
            ) {
              this.commission_data_details[key].commission_value =
                this.dashboardData.parentdata[0].Bio_Energeticssales_commission;
            }
          }
        }
        console.log(this.commission_data_details);
        this.showing_commission_data = [];
        let index = 0;
        this.showing_commission_data[index] = this.commission_data_details.find(
          (e) =>
            e.product_id == this.apiservice.product_RSTSanexas &&
            e.commission_type == "sales_commission"
        );
        if (this.showing_commission_data[index]) {
          index++;
        }
        this.showing_commission_data[index] = this.commission_data_details.find(
          (e) =>
            e.product_id == this.apiservice.product_bioEnergetics &&
            e.commission_type == "event_commission"
        );
        if (this.showing_commission_data[index]) {
          index++;
        }
        this.showing_commission_data[index] = this.commission_data_details.find(
          (e) =>
            e.product_id == this.apiservice.product_pece &&
            e.commission_type == "event_commission"
        );
        if (this.showing_commission_data[index]) {
          index++;
        }
        this.showing_commission_data[index] = this.commission_data_details.find(
          (e) =>
            e.product_id == this.apiservice.product_pece &&
            e.commission_type == "sales_commission"
        );
        if (this.showing_commission_data[index]) {
          index++;
        }
        this.showing_commission_data[index] = this.commission_data_details.find(
          (e) =>
            e.product_id == this.apiservice.product_tmflow &&
            e.commission_type == "sales_commission"
        );
        if (this.showing_commission_data[index]) {
          index++;
        } else {
          this.showing_commission_data.splice(index, 1);
          // index--;
        }
        // this.showing_commission_data = JSON.parse(JSON.stringify(this.showing_commission_data));
        console.log(this.showing_commission_data);
        for (const key in this.showing_commission_data) {
          if (this.showing_commission_data[key]) {
            if (
              this.showing_commission_data[key].product_id ==
              this.apiservice.product_RSTSanexas &&
              this.showing_commission_data[key].commission_type ==
              "sales_commission"
            ) {
              this.showing_commission_data[key]["productname_details"] =
                this.showing_commission_data[key].product_name +
                " Sales Commission";
              this.showing_commission_data[key]["product_img"] =
                "../../../../assets/images/traing_db_logo2_RST2.png";
              this.showing_commission_data[key].commission_value =
                this.showing_commission_data[key].commission_value + "%";
              this.showing_commission_data[key]["class_name"] = "rst_text";
            }
            if (
              this.showing_commission_data[key].product_id ==
              this.apiservice.product_bioEnergetics &&
              this.showing_commission_data[key].commission_type ==
              "event_commission"
            ) {
              this.showing_commission_data[key]["productname_details"] =
                this.showing_commission_data[key].product_name +
                " Event Commission (Per treatment charge)";
              this.showing_commission_data[key]["product_img"] =
                "../../../../assets/images/traing_db_logo3.png";
              this.showing_commission_data[key].commission_value =
                "$" + this.showing_commission_data[key].commission_value;
              this.showing_commission_data[key]["class_name"] = "bio_text";
            }
            if (
              this.showing_commission_data[key].product_id ==
              this.apiservice.product_pece &&
              this.showing_commission_data[key].commission_type ==
              "event_commission"
            ) {
              this.showing_commission_data[key]["productname_details"] =
                this.showing_commission_data[key].product_name +
                " Standard Qualified Practice Program (Cost per test)";
              this.showing_commission_data[key]["product_img"] =
                "../../../../assets/images/traing_db_logo4.png";
              this.showing_commission_data[key].commission_value =
                "$" + this.showing_commission_data[key].commission_value;
              this.showing_commission_data[key]["class_name"] = "PECE_text";
            }
            if (
              this.showing_commission_data[key].product_id ==
              this.apiservice.product_pece &&
              this.showing_commission_data[key].commission_type ==
              "sales_commission"
            ) {
              this.showing_commission_data[key]["productname_details"] =
                this.showing_commission_data[key].product_name +
                " Practice Testing Center Program (15% of Gross/Net)";
              this.showing_commission_data[key]["product_img"] =
                "../../../../assets/images/traing_db_logo4.png";
              this.showing_commission_data[key].commission_value =
                this.showing_commission_data[key].commission_value + "%";
              this.showing_commission_data[key]["class_name"] = "PECE_text";
            }
            if (
              this.showing_commission_data[key].product_id ==
              this.apiservice.product_tmflow &&
              this.showing_commission_data[key].commission_type ==
              "sales_commission"
            ) {
              this.showing_commission_data[key]["productname_details"] =
                this.showing_commission_data[key].product_name +
                " Sales Commission";
              this.showing_commission_data[key]["product_img"] =
                "../../../../assets/images/traing_db_logo1.png";
              this.showing_commission_data[key].commission_value =
                this.showing_commission_data[key].commission_value + "%";
              this.showing_commission_data[key]["class_name"] = "tm_text";
            }
          } else {
            this.showing_commission_data.splice(key, 1);
          }
          console.log(this.showing_commission_data[key]);
        }
      });
  }
  refreshlistview(val: any) {
    if (val == "job_ticket") {
      this.isrefresh_job_ticket = false;
      this.jobticketsListdatatableflag = true;
      this.jobDataList = [];
      this.jobticketnorecordfound = false;
      const endpoint = "api/getjobticketlistdata";
      const data: any = {
        // How many data showing in first page
        secret: this.userservice.secret,
        token: this.userservice.jwttoken,
        source: "",
        condition: {
          // condition for how much data show in one time
          limit: 10,
          skip: 0,
        },
        sort: {
          type: "desc", // data sort by decreasing order
          field: "createdon_datetime", // data sort by first name
        },
        searchcondition: { user_id: this.userservice.userid },
      };

      this.apiservice
        .getDataWithoutToken(data, endpoint)
        .subscribe((res: any) => {
          // console.log(res, 'jobticket');
          this.jobDataList = res.results.res; // sarch count  (How many data there)
          if (this.jobDataList.length == 0) {
            this.jobticketnorecordfound = true;
          }
          this.jobticketsListdatatableflag = false;
        });
    }
    if (val == "lead") {
      this.isrefresh_lead = false;
      this.NewlyAddedtableflag = true;
      this.leadnorecordfound = false;
      this.leadListdatatable = [];
      const endpointc = "api/leadlistview-count";
      const endpoint = "api/leadlistview";
      const data: any = {
        // How many data showing in first page
        secret: this.cookie.get("secret"),
        token: this.cookie.get("jwtToken"),
        source: "",
        condition: {
          // condition for how much data show in one time
          limit: 5,
          skip: 0,
        },
        sort: {
          type: "desc", // data sort by decreasing order
          field: "createdon_datetime", // data sort by first name
        },
        searchcondition: { type: "lead", parentid: this.userservice.userid },
      };

      this.apiservice
        .getDataWithoutToken(data, endpoint)
        .subscribe((res: any) => {
          this.leadListdatatable = res.results.res; // sarch count  (How many data there)
          if (this.leadListdatatable.length == 0) {
            this.leadnorecordfound = true;
          }
          this.NewlyAddedtableflag = false;
        });

      // this.apiservice
      //   .getDataWithoutToken(data, endpointc)
      //   .subscribe((res: any) => {
      //     this.datesearchsourcecountnewlyaddedlead = res.count; // sarch count  (How many data there)
      //   });
    }
    if (val == "warranty_appointments") {
      this.isrefresh_warranty_appointments = false;
      this.warranty_appointmentsnorecordfound = false;
      this.warrantyappointmentsddatatableflag = true;
      this.warranty_datatable = [];
      let data = {
        token: this.userservice.jwttoken,
        condition: {
          limit: 5,
          skip: 0,
          event_type_val: 5,
          bookinguser: JSON.parse(this.cookie.get("userid")),
        },
        sarchconditions: {},
        sort: {
          type: "desc",
          field: "booking_date",
        },
      };
      this.apiservice
        .customRequest(
          data,
          "list-upcoming-booked-events",
          environment["api_calender_url"]
        )
        .subscribe((res: any) => {
          console.log(res, " warranty ressssssssss");
          this.warranty_datatable = res.results.res;
          console.log(this.warranty_datatable, "this.warranty_datatable");
          this.warrantyappointmentsddatatableflag = false;
          // this.scheduleappointment = res.results.res;
          // this.schedule_datatable = this.scheduleappointment;
        });
      this.apiservice
        .customRequest(
          data,
          "list-upcoming-booked-events-count",
          environment["api_calender_url"]
        )
        .subscribe((res: any) => {
          console.log(res, " warranty ressssssssss count ");
          this.warranty_datesearchsourcecount = res.count;
          // console.log(this.warranty_datatable, 'this.warranty_datatable');

          // this.scheduleappointment = res.results.res;
          // this.schedule_datatable = this.scheduleappointment;
        });
      // setTimeout(() => {
      //   // this.Scheduleddatatableflag = false;
      // }, 500);
    }
    if (val == "tech_commission") {
      this.isrefresh_tech_commission = false;
      this.commissionprogressflag = true;
      this.commissiondatalistview = [];
      this.techcommissionnorecordfound = false;
      const endpoint = "api10/get-distributor-commission-management-lists";
      let data: any = {
        condition: {
          limit: 5,
          skip: 0,
        },
        sort: {
          type: "desc",
          field: "_id",
        },

        searchcondition: { techid: this.userservice.userid },
        secret: this.userservice.secret,
        token: this.userservice.jwttoken,
      };
      this.apiservice
        .getDataWithoutToken(data, endpoint)
        .subscribe((data: any) => {
          console.log(data);
          this.commissiondatalistview = data.results.res;
          if (this.commissiondatalistview.length == 0) {
            this.techcommissionnorecordfound = true;
          }
          this.commissionprogressflag = false;
        });
    }
  }
  jobticketsListView() {
    let jobdata: any = {
      secret: "secret",
      token: "token",
      condition: {
        limit: 5,
        skip: 0,
      },
      sort: {
        type: "desc",
        field: "_id",
      },
      searchcondition: {
        user_id: this.userservice.userid,
      },
    };

    this.apiservice
      .getDataWithoutToken(jobdata, "api/getjobticketlistdata")
      .subscribe((res: any) => {
        console.log(res);
        this.jobDataList = res.results.res;
        this.jobticketsListdatatableflag = false;
      });
    this.apiservice
      .getDataWithoutToken(jobdata, "api/getjobticketlistdata-count")
      .subscribe((res: any) => {
        // console.log(res, 'jobticket');
        this.datesearchsourcecount_jobticket = res.count; // sarch count  (How many data there)
      });
  }
  openDialogForSearchListViewForTech(val: any) {
    const dialogRef = this.dialog.open(Searchmodaltech, {
      // width: '250px',
      data: val,
      panelClass: "",
    });

    dialogRef.afterClosed().subscribe((result) => {
      console.log("result*************>", result);
      if (result != undefined) {
        if (val == "job_ticket") {
          this.jobticketnorecordfound = false;
          this.jobticketsListdatatableflag = true;
          this.jobDataList = [];
          if (result.ticketid != "") {
            result.ticketid = { $regex: result.ticketid };
          }
          result["user_id"] = this.userservice.userid;
          delete result.type;
          if (
            typeof result.lastlogin_datetime.$gte != "undefined" ||
            typeof result.lastlogin_datetime.$lte != "undefined"
          ) {
            console.log(result.lastlogin_datetime);
          } else {
            delete result.lastlogin_datetime;
          }
          delete result.createdon_datetime;
          delete result.start_datetime_unix;
        }
        if (val == "lead") {
          this.NewlyAddedtableflag = true;
          this.leadListdatatable = [];
          this.leadnorecordfound = false;
          result["parentid"] = this.userservice.userid;
          // delete result.type;
          if (
            typeof result.createdon_datetime.$gte != "undefined" ||
            typeof result.createdon_datetime.$lte != "undefined"
          ) {
            console.log(result.createdon_datetime);
          } else {
            delete result.createdon_datetime;
          }
          delete result.lastlogin_datetime;
          delete result.start_datetime_unix;
        }
        if (val == "warranty_appointments") {
          this.warrantyappointmentsddatatableflag = true;
          this.warranty_datatable = [];
          this.warranty_appointmentsnorecordfound = false;
          result["bookinguser"] = this.userservice.userid;
          result["event_type_val"] = 5;
          delete result.type;
          if (
            typeof result.start_datetime_unix.$gte != "undefined" ||
            typeof result.start_datetime_unix.$lte != "undefined"
          ) {
            console.log(result.start_datetime_unix);
          } else {
            delete result.start_datetime_unix;
          }
          delete result.lastlogin_datetime;
          delete result.createdon_datetime;
        }
        if (val == "tech_commission") {
          this.commissionprogressflag = true;
          this.techcommissionnorecordfound = false;
          this.commissiondatalistview = [];
          result["techid"] = this.userservice.userid;
          delete result.type;
          delete result.createdon_datetime;
          delete result.lastlogin_datetime;
          delete result.start_datetime_unix;
        }
        console.log("The dialog was closed", result);
        Object.keys(result).forEach((k) => result[k] == "" && delete result[k]);
        let endpoint = "";
        if (val == "job_ticket") {
          endpoint = "api/getjobticketlistdata";
        } else {
          if (val == "lead") {
            endpoint = "api/leadlistview";
          } else {
            if (val == "tech_commission") {
              endpoint = "api10/get-distributor-commission-management-lists";
            } else {
              endpoint = "api/userlistview";
            }
          }
        }
        const data: any = {
          // How many data showing in first page
          condition: {
            limit: 5,
            skip: 0,
          },
          sort: {
            type: "desc",
            field: "_id",
          },

          searchcondition: result,
          secret: this.userservice.secret,
          token: this.userservice.jwttoken,
        };
        if (val == "warranty_appointments") {
          this.apiservice
            .customRequest(
              data,
              "list-upcoming-booked-events",
              environment["api_calender_url"]
            )
            .subscribe((res: any) => {
              this.isrefresh_warranty_appointments = true;
              console.log(res, " warranty ressssssssss");
              this.warranty_datatable = res.results.res;
              if (this.warranty_datatable.length == 0) {
                this.warranty_appointmentsnorecordfound = true;
              }
              console.log(this.warranty_datatable, "this.warranty_datatable");
              this.warrantyappointmentsddatatableflag = false;
              // this.scheduleappointment = res.results.res;
              // this.schedule_datatable = this.scheduleappointment;
            });
        } else {
          this.apiservice
            .getDataWithoutToken(data, endpoint)
            .subscribe((res: any) => {
              console.log(res);
              if (val == "job_ticket") {
                this.isrefresh_job_ticket = true;
                this.jobDataList = res.results.res;
                if (this.jobDataList.length == 0) {
                  this.jobticketnorecordfound = true;
                }
                this.jobticketsListdatatableflag = false;
              }
              if (val == "lead") {
                this.isrefresh_lead = true;
                this.leadListdatatable = res.results.res;
                if (this.leadListdatatable.length == 0) {
                  this.leadnorecordfound = true;
                }
                this.NewlyAddedtableflag = false;
              }
              if (val == "tech_commission") {
                this.isrefresh_tech_commission = true;
                this.commissiondatalistview = res.results.res;
                if (this.commissiondatalistview.length == 0) {
                  this.techcommissionnorecordfound = true;
                }
                this.commissionprogressflag = false;
              }
              // this.datesearchsourcecount = res.count; // sarch count  (How many data there)
            });
        }
      }
    });
  }
  openDialog() {
    const dialogRef = this.dialog.open(clipboard, {
      // width: '250px',
      data: "",
      panelClass: "clipboard",
    });

    dialogRef.afterClosed().subscribe((result) => {
      //  // console.log('The dialog was closed');
      // return
    });
  }
  gameplanmodal() {
    console.log("this.getdashboardtrainingdata(); +++++ 2222222");

    let datafortraining = {
      condition: {
        associated_training: "60922437a133f500088e46ae",
      },
      user_id: JSON.parse(this.cookie.get("userid")),
    };

    this.apiservice
      .getDataWithoutToken(datafortraining, "api1/dashboardtrainingdata")
      .subscribe((res: any) => {
        console.log(res, "dashboardtrainingdata");

        if (res.status === "success" && Object.keys(res.results).length > 0) {
          // console.log(res.results.alllessondata != null && res.results.done_lesson_by_cat_by_user != '' && res.results.done_lesson_by_cat_by_user[0].lessonsdone != null && res.results.alllessondata == res.results.done_lesson_by_cat_by_user[0].lessonsdone)
          if (
            res.results.done_lesson_by_cat_by_user != null &&
            typeof res.results.done_lesson_by_cat_by_user != "undefined" &&
            res.results.done_lesson_by_cat_by_user != "" &&
            this.cookie.check("gameplancall") &&
            JSON.parse(this.cookie.get("gameplancall")) == 1
          ) {
            console.log(
              res.results.done_lesson_by_cat_by_user,
              "soooooooooooooooooooooooooooooooo"
            );

            for (const key in res.results.done_lesson_by_cat_by_user) {
              if (
                res.results.alllessondata != null &&
                res.results.done_lesson_by_cat_by_user != null &&
                res.results.done_lesson_by_cat_by_user.length > 0 &&
                res.results.done_lesson_by_cat_by_user[key].lessonsdone !=
                null &&
                res.results.done_lesson_by_cat_by_user[key]
                  .associated_training == "60922437a133f500088e46ae" &&
                res.results.calendar_booking_data == 0 &&
                res.results.alllessondata ==
                res.results.done_lesson_by_cat_by_user[key].lessonsdone
              ) {
                res.results.done_lesson_by_cat_by_user[0].lesson_id =
                  "60922942c24f250009cc8434";
                // console.log('modal open')
                const dialogRef = this.dialog.open(TechGameplanModalComponent, {
                  panelClass: "schedule_modal",
                  // width: '900px',
                  height: "auto",
                  data: res.results.done_lesson_by_cat_by_user[0],
                });
                // dialogRef.afterOpened().subscribe((_) => {
                //   setTimeout(() => {
                //     dialogRef.close();
                //   });
                // });
                dialogRef.disableClose = true;
                dialogRef.afterClosed().subscribe((result: any) => {
                  if (
                    result != null &&
                    typeof result != "undefined" &&
                    result.flag != null &&
                    result.flag == true
                  ) {
                    this.router.navigateByUrl(
                      this.googleScheduleRoute +
                      result.associated_training +
                      "/" +
                      result.lesson_id
                    );
                  }
                });
              }
            }
          }
        }
      });
  }

  resorceModal(val, value) {
    console.log(val, "[[[[][][][][][][]");
    // return;
    if (
      val.complete == null ||
      typeof val.complete == "undefined" ||
      val.complete == false
    ) {
      const dialogRef = this.dialog.open(ResourseModalComponent, {
        panelClass: "resource_modal",

        height: "auto",
        data: { data: val, trainingVal: value },
      });
      dialogRef.disableClose = true;
      dialogRef.afterClosed().subscribe((result: any) => {
        // console.log(result);
        if (
          result.flag != null &&
          typeof result.flag != undefined &&
          result.flag == true
        ) {
          this.router.navigateByUrl(
            "traning/training-center-beto-paredes/" + result.data.trainingid
          );
        }
      });
    }
  }

  videoModalDialog(url: any, Htext: any) {
    const dialogRef = this.dialog.open(videoModalComponent, {
      // width: '250px',
      data: { url: url, Htext: Htext },

      panelClass: "techvideoModal",
    });

    dialogRef.afterClosed().subscribe((result) => {
      console.log("The dialog was closed");
      // this.animal = result;
    });
  }
  folderview(val: any, flag) {
    // contract/view/:_id

    console.log(val);
    if (flag == "leads_same_page") {
      this.router.navigateByUrl("user-details/lead/" + val._id);
    } else if (flag == "leads_new_page") {
      window.open("user-details/lead/" + val._id, "Independent Window");
    } else if (flag == "job_ticket_same_page") {
      this.router.navigateByUrl(
        "/jobticket/view-managejobticket/" + val._id + "/" + val.user_id
      );
    } else if (flag == "job_ticket_new_page") {
      window.open(
        "/jobticket/view-managejobticket/" + val._id + "/" + val.user_id,
        "Independent Window"
      );
    } else if (flag == "warranty_new_page") {
      window.open("/user-details/lead/" + val.id, "Independent Window");
    } else if (flag == "warranty_same_page") {
      this.router.navigateByUrl("/user-details/lead/" + val.id);
    } else if (flag == "commission_new_page") {
      window.open("/managecommisson/tech/list", "Independent Window");
    } else if (flag == "commission_same_page") {
      this.router.navigateByUrl("/managecommisson/tech/list");
    } else if (flag == "schedule_appointment") {
      window.open(
        "/calendar-management/manage-appointment/my-appointments",
        "Independent Window"
      );
    } else if (flag == "schedule_appointment_same") {
      this.router.navigateByUrl(
        "/calendar-management/manage-appointment/my-appointments"
      );
    }
  }

  slide_jobtickets(e: string) {
    // console.log('slide_jobtickets')
    // let i: any;
    let slides: any = document.getElementsByClassName("main_practice_block4");
    let scount: any = document.querySelectorAll(".main_practice_block4").length;
    if (this.slideindex_jobtickets > scount) this.slideindex_jobtickets = 1;
    let slideindex_jobtickets: any = this.slideindex_jobtickets++;
    let dots: any = document.getElementsByClassName("dot");

    for (let i = 0; i < slides.length; i++) {
      slides[i].style.display = "none";
    }

    // slideIndex++;
    if (slideindex_jobtickets > slides.length) {
      slideindex_jobtickets = 1;
    }
    for (let i = 0; i < dots.length; i++) {
      if (dots[i] != null)
        dots[i].className = dots[i].className.replace(" active", "");
    }
    if (slideindex_jobtickets > 0 && slides[slideindex_jobtickets - 1] != null)
      slides[slideindex_jobtickets - 1].style.display = "block";
    if (slideindex_jobtickets > 0 && dots[slideindex_jobtickets - 1] != null)
      dots[slideindex_jobtickets - 1].className += " active";
    if (e == null || e == "") {
      this.slide_jobticketstimeout = setTimeout(() => {
        this.slide_jobtickets("");
      }, 10000);
    }
  }

  slideforjobticketsPrev(idx: any) {
    // console.log('prev', idx)
    // let i: any;
    let slides: any = document.getElementsByClassName("main_practice_block4");
    let scount: any = document.querySelectorAll(".main_practice_block4").length;
    this.slideindex_jobtickets = scount;
    this.slideindex_jobtickets = idx;
    if (this.slideindex_jobtickets <= 0) {
      this.slideindex_jobtickets = scount;
    }
    let slideindex_jobtickets: any = this.slideindex_jobtickets;

    let dots: any = document.getElementsByClassName("dot");

    for (let i = slides.length - 1; i < 0; i--) {
      slides[i].style.display = "none";
    }

    slideindex_jobtickets--;
    if (slideindex_jobtickets < slides.length) {
      slideindex_jobtickets = slides.length;
    }

    for (let i = dots.length; i < 0; i--) {
      if (dots[i] != null) {
        dots[i].className = dots[i].className.replace(" active", "");
      }
    }
    if (slideindex_jobtickets > 0 && slides[slideindex_jobtickets - 1] != null)
      slides[slideindex_jobtickets - 1].style.display = "block";
    if (slideindex_jobtickets > 0 && dots[slideindex_jobtickets - 1] != null)
      dots[slideindex_jobtickets - 1].className = -" active";
    this.slide_jobtickets("e");
  }
  commissionPrev(idx: any) {
    //  // console.log('prev', idx)

    // let i: any;
    let slides: any = document.getElementsByClassName("main_practice_block8");
    let scount: any = document.querySelectorAll(".main_practice_block8").length;

    this.slideindex_commission = idx;
    if (this.slideindex_commission <= 0) {
      this.slideindex_commission = scount;
    }
    let slideindex_technological: any = this.slideindex_commission;
    for (var i = slides.length - 1; i >= 0; i--) {
      slides[i].style.display = "none";
    }
    if (
      slideindex_technological > 0 &&
      slides[slideindex_technological - 1] != null
    ) {
      slides[slideindex_technological - 1].style.display = "block";
    }
    slideindex_technological--;
  }
  slideforjobticketsNext(idx: any) {
    // console.log('next', idx, this.slideindex_jobtickets)

    this.slideindex_jobtickets = 0;
    this.slideindex_jobtickets = idx + 1;
    // let i: any;
    let slides: any = document.getElementsByClassName("main_practice_block4");
    let scount: any = document.querySelectorAll(".main_practice_block4").length;

    // console.log('next 111', this.slideindex_jobtickets, scount)
    if (this.slideindex_jobtickets >= scount) {
      this.slideindex_jobtickets = 0;
    }
    let slideindex_jobtickets: any = this.slideindex_jobtickets++;

    let dots: any = document.getElementsByClassName("dot");

    for (let i = 0; i < slides.length; i++) {
      slides[i].style.display = "none";
    }

    slideindex_jobtickets++;
    if (slideindex_jobtickets > slides.length) {
      slideindex_jobtickets = 1;
    }
    for (let i in dots.length) {
      // console.log(i, '__')
      if (dots[i] != null) {
        dots[i].className = dots[i].className.replace(" active", "");
      }
    }
    if (slideindex_jobtickets > 0 && slides[slideindex_jobtickets - 1] != null)
      slides[slideindex_jobtickets - 1].style.display = "block";
    if (slideindex_jobtickets > 0 && dots[slideindex_jobtickets - 1] != null)
      dots[slideindex_jobtickets - 1].className += " active";

    this.slide_jobtickets("e");
  }
  commissionNext(idx: any) {
    // console.log('next', idx, this.slideindex_jobtickets)

    this.slideindex_commission = 0;
    this.slideindex_commission = idx + 1;
    // let i: any;
    let slides: any = document.getElementsByClassName("main_practice_block8");
    let scount: any = document.querySelectorAll(".main_practice_block8").length;

    // console.log('next 111', this.slideindex_commission, scount)
    if (this.slideindex_commission >= scount) {
      this.slideindex_commission = 0;
    }
    let slideindex_commission: any = this.slideindex_commission++;

    let dots: any = document.getElementsByClassName("dot");

    for (let i = 0; i < slides.length; i++) {
      slides[i].style.display = "none";
    }

    slideindex_commission++;
    if (slideindex_commission > slides.length) {
      slideindex_commission = 1;
    }
    for (let i in dots.length) {
      // console.log(i, '__')
      if (dots[i] != null) {
        dots[i].className = dots[i].className.replace(" active", "");
      }
    }
    if (slideindex_commission > 0 && slides[slideindex_commission - 1] != null)
      slides[slideindex_commission - 1].style.display = "block";
    if (slideindex_commission > 0 && dots[slideindex_commission - 1] != null)
      dots[slideindex_commission - 1].className += " active";

    // this.slide_jobtickets('e');
  }
  lead_list_slide(e: string) {
    // console.log('slide')
    // let i: any;
    let slides: any = document.getElementsByClassName("main_practice_block11");
    let scount: any = document.querySelectorAll(
      ".main_practice_block11"
    ).length;
    if (this.slideindex_lead > scount) this.slideindex_lead = 1;
    let slideindex_lead: any = this.slideindex_lead++;
    let dots: any = document.getElementsByClassName("dot");

    for (let i = 0; i < slides.length; i++) {
      slides[i].style.display = "none";
    }
    // slideIndex++;
    if (slideindex_lead > slides.length) {
      slideindex_lead = 1;
    }
    for (let i = 0; i < dots.length; i++) {
      if (dots[i] != null)
        dots[i].className = dots[i].className.replace(" active", "");
    }
    if (slideindex_lead > 0 && slides[slideindex_lead - 1] != null)
      slides[slideindex_lead - 1].style.display = "block";
    if (slideindex_lead > 0 && dots[slideindex_lead - 1] != null)
      dots[slideindex_lead - 1].className += " active";
    if (e == null || e == "") {
      this.slideindex_leadtimeout = setTimeout(() => {
        this.slideindex_lead("");
      }, 10000);
    }
  }
  slideforleadNext(idx: any) {
    // console.log('next slideforschdeuleNext', idx, this.slideindex_schedule)

    this.slideindex_lead = 0;
    this.slideindex_lead = idx + 1;
    // let i: any;
    let slides: any = document.getElementsByClassName("main_practice_block11");
    let scount: any = document.querySelectorAll(
      ".main_practice_block11"
    ).length;

    // console.log('next 111 slideforschdeuleNext', this.slideindex_schedule, scount)
    if (this.slideindex_lead >= scount) {
      this.slideindex_lead = 0;
    }
    let slideindex_lead: any = this.slideindex_lead++;

    let dots: any = document.getElementsByClassName("dot");

    for (let i = 0; i < slides.length; i++) {
      slides[i].style.display = "none";
    }

    slideindex_lead++;
    if (slideindex_lead > slides.length) {
      slideindex_lead = 1;
    }
    for (let i in dots.length) {
      // console.log(i, '__')
      if (dots[i] != null) {
        dots[i].className = dots[i].className.replace(" active", "");
      }
    }
    if (slideindex_lead > 0 && slides[slideindex_lead - 1] != null)
      slides[slideindex_lead - 1].style.display = "block";
    if (slideindex_lead > 0 && dots[slideindex_lead - 1] != null)
      dots[slideindex_lead - 1].className += " active";

    this.lead_list_slide("e");
  }
  slideforleadPrev(idx: any) {
    // console.log('++__+_+-slideforschedulePrev', idx)
    let slides: any = document.getElementsByClassName("main_practice_block11");
    let scount: any = document.querySelectorAll(
      ".main_practice_block11"
    ).length;

    this.slideindex_lead = scount;
    this.slideindex_lead = idx;
    if (this.slideindex_lead <= 0) {
      this.slideindex_lead = scount;
    }
    let slideindex_lead: any = this.slideindex_lead;

    let dots: any = document.getElementsByClassName("dot");

    for (let i = slides.length - 1; i < 0; i--) {
      slides[i].style.display = "none";
    }

    slideindex_lead--;
    if (slideindex_lead < slides.length) {
      slideindex_lead = slides.length;
    }

    for (let i = dots.length; i < 0; i--) {
      if (dots[i] != null) {
        dots[i].className = dots[i].className.replace(" active", "");
      }
    }
    if (slideindex_lead > 0 && slides[slideindex_lead - 1] != null)
      slides[slideindex_lead - 1].style.display = "block";
    if (slideindex_lead > 0 && dots[slideindex_lead - 1] != null)
      dots[slideindex_lead - 1].className = -" active";

    this.lead_list_slide("e");
  }

  slideindex_schedule_slide(e: string) {
    // console.log('slideindex_schedule')
    // let i: any;
    let slides: any = document.getElementsByClassName("main_practice_block8");
    let scount: any = document.querySelectorAll(".main_practice_block8").length;
    if (this.slideindex_schedule > scount) this.slideindex_schedule = 1;
    let slideindex_schedule: any = this.slideindex_schedule++;
    let dots: any = document.getElementsByClassName("dot");

    for (let i = 0; i < slides.length; i++) {
      slides[i].style.display = "none";
    }
    // slideindex_schedule++;
    if (slideindex_schedule > slides.length) {
      slideindex_schedule = 1;
    }
    for (let i = 0; i < dots.length; i++) {
      if (dots[i] != null)
        dots[i].className = dots[i].className.replace(" active", "");
    }
    if (slideindex_schedule > 0 && slides[slideindex_schedule - 1] != null)
      slides[slideindex_schedule - 1].style.display = "block";
    if (slideindex_schedule > 0 && dots[slideindex_schedule - 1] != null)
      dots[slideindex_schedule - 1].className += " active";
    if (e == null || e == "") {
      this.slideindex_scheduletimeout = setTimeout(() => {
        this.slideindex_schedule_slide("");
      }, 10000);
    }
  }
  togglecommissions(e) {
    if (e.checked === true) {
      this.commissiondatatableflag = true;
      this.commissionflag = true;
      const endpoint = "api/listmanagecommission";
      const endpointc = "api/listmanagecommission-count";
      const data: any = {
        // How many data showing in first page
        secret: this.cookie.get("secret"),
        token: this.cookie.get("jwtToken"),
        source: "",
        condition: {
          // condition for how much data show in one time
          limit: 5,
          skip: 0,
        },
        sort: {
          type: "desc", // data sort by decreasing order
          field: "createdon_datetime", // data sort by first name
        },
        searchcondition: { userid: this.userservice.userid },
      };

      this.apiservice
        .getDataWithoutToken(data, endpoint)
        .subscribe((res: any) => {
          this.commissiondatatableflag = false;
          this.commissionlistdata = res.results.res; // sarch count  (How many data there)
        });
      this.apiservice
        .getDataWithoutToken(data, endpointc)
        .subscribe((res: any) => {
          this.datesearchsourcecount_commission = res.count; // sarch count  (How many data there)
        });
    } else {
      //  console.warn('jobtickets', e.checked);
      this.commissiondatatableflag = false;
      this.commissionflag = false;
    }
  }
  slideforschdeuleNext(idx: any) {
    // console.log('next slideforschdeuleNext', idx, this.slideindex_schedule)

    this.slideindex_schedule = 0;
    this.slideindex_schedule = idx + 1;
    // let i: any;
    let slides: any = document.getElementsByClassName("main_practice_block4");
    let scount: any = document.querySelectorAll(".main_practice_block4").length;

    // console.log('next 111 slideforschdeuleNext', this.slideindex_schedule, scount)
    if (this.slideindex_schedule >= scount) {
      this.slideindex_schedule = 0;
    }
    let slideindex_schedule: any = this.slideindex_schedule++;

    let dots: any = document.getElementsByClassName("dot");

    for (let i = 0; i < slides.length; i++) {
      slides[i].style.display = "none";
    }

    slideindex_schedule++;
    if (slideindex_schedule > slides.length) {
      slideindex_schedule = 1;
    }
    for (let i in dots.length) {
      // console.log(i, '__')
      if (dots[i] != null) {
        dots[i].className = dots[i].className.replace(" active", "");
      }
    }
    if (slideindex_schedule > 0 && slides[slideindex_schedule - 1] != null)
      slides[slideindex_schedule - 1].style.display = "block";
    if (slideindex_schedule > 0 && dots[slideindex_schedule - 1] != null)
      dots[slideindex_schedule - 1].className += " active";

    this.slideindex_schedule_slide("e");
  }

  slideforfollowupNext(idx: any) {
    // console.log('next slideforschdeuleNext', idx, this.slideindex_schedule)

    this.slideindex_schedule = 0;
    this.slideindex_schedule = idx + 1;
    // let i: any;
    let slides: any = document.getElementsByClassName("main_practice_block7");
    let scount: any = document.querySelectorAll(".main_practice_block7").length;

    // console.log('next 111 slideforschdeuleNext', this.slideindex_schedule, scount)
    if (this.slideindex_schedule >= scount) {
      this.slideindex_schedule = 0;
    }
    let slideindex_schedule: any = this.slideindex_schedule++;

    let dots: any = document.getElementsByClassName("dot");

    for (let i = 0; i < slides.length; i++) {
      slides[i].style.display = "none";
    }

    slideindex_schedule++;
    if (slideindex_schedule > slides.length) {
      slideindex_schedule = 1;
    }
    for (let i in dots.length) {
      // console.log(i, '__')
      if (dots[i] != null) {
        dots[i].className = dots[i].className.replace(" active", "");
      }
    }
    if (slideindex_schedule > 0 && slides[slideindex_schedule - 1] != null)
      slides[slideindex_schedule - 1].style.display = "block";
    if (slideindex_schedule > 0 && dots[slideindex_schedule - 1] != null)
      dots[slideindex_schedule - 1].className += " active";

    this.slideindex_schedule_slide("e");
  }

  slideforschedulePrev(idx: any) {
    //  // console.log('prev', idx)

    // let i: any;
    let slides: any = document.getElementsByClassName("main_practice_block4");
    let scount: any = document.querySelectorAll(".main_practice_block4").length;

    this.slideindex_jobtickets = idx;
    if (this.slideindex_jobtickets <= 0) {
      this.slideindex_jobtickets = scount;
    }
    let slideindex_technological: any = this.slideindex_jobtickets;
    for (var i = slides.length - 1; i >= 0; i--) {
      slides[i].style.display = "none";
    }
    if (
      slideindex_technological > 0 &&
      slides[slideindex_technological - 1] != null
    ) {
      slides[slideindex_technological - 1].style.display = "block";
    }
    slideindex_technological--;
  }

  slideforfollowupPrev(idx: any) {
    //  // console.log('prev', idx)

    // let i: any;
    let slides: any = document.getElementsByClassName("main_practice_block7");
    let scount: any = document.querySelectorAll(".main_practice_block7").length;

    this.slideindex_jobtickets = idx;
    if (this.slideindex_jobtickets <= 0) {
      this.slideindex_jobtickets = scount;
    }
    let slideindex_technological: any = this.slideindex_jobtickets;
    for (var i = slides.length - 1; i >= 0; i--) {
      slides[i].style.display = "none";
    }
    if (
      slideindex_technological > 0 &&
      slides[slideindex_technological - 1] != null
    ) {
      slides[slideindex_technological - 1].style.display = "block";
    }
    slideindex_technological--;
  }

  downloadcontract() {
    window.open(
      "https://awsbackend-dev-patient-files-test.s3.amazonaws.com/beto-paredes-contracts-pdf/contract_" +
      JSON.parse(this.cookie.get("userid")) +
      ".pdf",
      "_blank"
    );
  }
  downloadfile(path: any, name) {
    window.open(
      "https://awsbackend-dev-patient-files-test.s3.amazonaws.com/" +
      path +
      name,
      "_blank"
    );
  }
  openlink(val) {
    // console.log(val);
    window.location.href = val;
  }
  previewfile(item: any, resourcename: any) {
    item.resourcename = resourcename;
    const dialogRef = this.dialog.open(previewfile, {
      panelClass: "previewfile_modal",
      width: "900px",
      height: "auto",
      data: item,
    });
    dialogRef.disableClose = true;
    dialogRef.afterClosed().subscribe((result: any) => {
      // console.log(result)
      // if (result.flag != null && result.flag == true) {
      //   this.router.navigateByUrl(this.googleScheduleRoute + result.associated_training + '/' + result.lesson_id);
      // }
    });
  }

  viewcontract() {
    window.open(
      "/contract-view/" + JSON.parse(this.cookie.get("userid")),
      "_blank"
    );

    // this.router.navigateByUrl('/contract-view/' + this.userservice.userid)
  }
  toggleScheduled(e: any) {
    //  console.warn('Scheduled', e.checked);

    if (e.checked === true) {
      this.ScheduledFlagScheduledFlag = false;
      //  console.warn('Scheduled', e.checked);
      this.ScheduledFlag = true;
      this.schedule_appointments();
    } else {
      this.ScheduledFlagScheduledFlag = true;

      //  console.warn('Scheduled', e.checked);
      this.ScheduledFlag = false;
    }
  }
  togglewarranty(e: any) {
    //  console.warn('Scheduled', e.checked);

    if (e.checked === true) {
      // this.warrantyappointmentsddatatableflag = false;
      //  console.warn('Scheduled', e.checked);
      this.warrantyFlag = true;
      this.warranty_appointments_data();
    } else {
      // this.warrantyappointmentsddatatableflag = true;

      //  console.warn('Scheduled', e.checked);
      this.warrantyFlag = false;
      this.warranty_appointmentsnorecordfound = false;
      this.warranty_datatable = [];
      this.isrefresh_warranty_appointments = false;
    }
  }
  toggleNewlyAddedFlag(e: any) {
    //  console.warn('NewlyAdded', e.checked);
    if (e.checked === true) {
      //  console.warn('NewlyAdded', e.checked);
      this.NewlyAddedFlag = true;
      this.leadlist();
    } else {
      //  console.warn('NewlyAdded', e.checked);
      this.NewlyAddedFlag = false;
      this.leadnorecordfound = false;
      this.leadListdatatable = [];
      this.isrefresh_lead = false;
    }
  }

  // jobticket
  togglejobtickets(e: any) {
    //  console.warn('jobtickets', e.checked)

    if (e.checked === true) {
      console.warn("jobtickets", e.checked);
      this.jobticketsListdatatableflag = true;
      this.jobticketsFlag = true;

      this.jobticketsListView();
    } else {
      //  console.warn('jobtickets', e.checked);
      this.jobticketsListdatatableflag = false;
      this.jobticketsFlag = false;
      this.jobticketnorecordfound = false;
      this.jobDataList = [];
      this.isrefresh_job_ticket = false;
    }
  }

  toggleWebinars(e: any) {
    e.checked ? (this.WebinarsFlag = true) : (this.WebinarsFlag = false);
  }
  warranty_appointments_data() {
    // this.warrantyappointmentsddatatableflag = true;
    let data = {
      secret: this.cookie.get("secret"),
      token: this.cookie.get("jwtToken"),
      source: "",
      condition: {
        limit: 3,
        skip: 0,
      },
      sort: {
        type: "asc",
        field: "datetimeslot",
      },
      searchcondition: {
        bookinguser: JSON.parse(this.cookie.get("userid")),
        timeflag: [1, 3],
      },
    };
    this.apiservice
      .getDataWithoutToken(data, "api8/list-followup-data")
      .subscribe((res: any) => {
        console.log(res, " warranty ressssssssss");
        this.warranty_datatable = res.results.res;
        console.log(this.warranty_datatable, "this.warranty_datatable");
        this.warrantyappointmentsddatatableflag = false;
        // this.scheduleappointment = res.results.res;
        // this.schedule_datatable = this.scheduleappointment;
      });
    this.apiservice
      .getDataWithoutToken(data, "api8/list-followup-data-count")
      .subscribe((res: any) => {
        console.log(res, " warranty ressssssssss count ");
        this.warranty_datesearchsourcecount = res.count;
        // console.log(this.warranty_datatable, 'this.warranty_datatable');

        // this.scheduleappointment = res.results.res;
        // this.schedule_datatable = this.scheduleappointment;
      });
    // setTimeout(() => {
    //   // this.Scheduleddatatableflag = false;
    // }, 500);
  }
  getallsharelinks() {
    const dataforloginmonth = {
      secret: this.cookie.get("secret"),
      token: this.cookie.get("jwtToken"),
      id: JSON.parse(this.cookie.get("userid")),
    };
    this.apiservice
      .getDataWithoutToken(dataforloginmonth, "api/getallsharelinkslist")
      .subscribe((res: any) => {
        if (res.results != undefined) {
          this.getallsharelinksdata = res.results.res;
        }
        // console.log(this.getallsharelinksdata, 'getallsharelinksdata +++++')
      });
  }
  copyText(val) {
    val = val + this.tech_id;
    const selBox = document.createElement("textarea");
    selBox.style.position = "fixed";
    selBox.style.left = "0";
    selBox.style.top = "0";
    selBox.style.opacity = "0";
    selBox.value = val;
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand("copy");
    document.body.removeChild(selBox);
  }

  gototraining(trainingid: any, lessonid: any) {
    console.log(trainingid, "++++", lessonid);
    if (lessonid != null && lessonid != "") {
      this.router.navigateByUrl(
        "/traning/training-center-beto-paredes/" + trainingid + "/" + lessonid
      );
    } else {
      this.router.navigateByUrl(
        "/traning/training-center-beto-paredes/" + trainingid
      );
    }
  }

  ngOnDestroy() {
    clearTimeout(this.slide_jobticketstimeout);
    clearTimeout(this.slideindex_scheduletimeout);
    clearTimeout(this._timer);
  }
  listenLiblistingChange(event: any) {
    console.log("event", event);
    if (event.custombuttonclick != undefined) {
      if (event.custombuttonclick.btninfo.id === "btn_cancel") {
        const dialogRef = this.dialog.open(CancelAlert);

        dialogRef.afterClosed().subscribe((result) => {
          // console.log(`Dialog result: ${result}`);
          if (result === "true") {
            // google_events
            let google_events_data: any = {
              userid: event.custombuttonclick.data.user_id,
              source: "google_events",
            };
            this.apiservice
              .customRequest(
                google_events_data,
                "get-refresh-token",
                environment["api_calender_url"]
              )
              .subscribe((response: any) => {
                console.log("response", response);
                let r_token = response.refresh_token;
                // console.log(r_token, 'r_token')
                const data: any = {
                  _id: event.custombuttonclick.data._id,
                  appointment_date: event.custombuttonclick.data.startdate,
                  refresh_token: r_token,
                  eid: event.custombuttonclick.data.eid,
                  slotTimeActual: event.custombuttonclick.data.slotTimeActual,
                  googleevent: event.custombuttonclick.data.googleevent,
                };
                // console.log(data, 'data')
                this.apiservice
                  .customRequest(
                    data,
                    "google-calendar-event/delete-booked-event",
                    environment["google_calendar_api"]
                  )
                  .subscribe((response: any) => {
                    // console.log('response', response);
                    setTimeout(() => {
                      this.updatetable = true;
                    }, 10);
                  });
              });
          }
        });
      }
    }
  }
  listenLiblistingChangeLead(val: any) {
    if (val.custombuttonclick != undefined) {
      if (val.custombuttonclick.btninfo.id == "leadsfolderview") {
        window.open(
          "/user-details/lead/" + val.custombuttonclick.data._id,
          "_blank"
        );
      }
    }
  }
  listenLiblistingChangewarranty(event: any) {
    console.log("eventtt disposition", event);
    if (event.custombuttonclick != undefined) {
      if (event.custombuttonclick.btninfo.label == "Disposition") {
        const bottomSheetRef = this.bottomSheet.open(bottomsheetstatus, {
          data: event.custombuttonclick.data,
          disableClose: true,
        });
        bottomSheetRef.afterDismissed().subscribe(() => {
          this.updatetable = false;
          console.log("Bottom sheet has been dismissed on follow up.");
          // this.progressSpinner.loading = true;
          setTimeout(() => {
            this.updatetable = true;
            // console.log();

            // this.progressSpinner.loading = false;
          }, 2000);
        });
      }

      if (event.custombuttonclick.btninfo.classname === "disposition") {
        console.log(event.custombuttonclick.data, "disposition");
        const dialogRef = this.dialog.open(Dispositionview, {
          data: event.custombuttonclick.data,
          panelClass: "dispositionview_modal",
        });

        // bottomSheetRef.afterDismissed().subscribe(() => {
        //   // console.log('Bottom sheet has been dismissed.');
        //   this.progressSpinner.loading = true;
        //   setTimeout(() => {
        //     this.updatetable = true;
        //     this.progressSpinner.loading = false;
        //   }, 2000);

        // });
      }

      if (event.custombuttonclick.btninfo.classname === "follow_up_call") {
        console.log(
          event.custombuttonclick.btninfo.classname,
          "follow_up_call"
        );
        const bottomSheetRef = this.bottomSheet.open(bottomsheetfollowup, {
          data: event.custombuttonclick.data,
          disableClose: true,
        });
        this.updatetable = false;
        bottomSheetRef.afterDismissed().subscribe(() => {
          // this.progressSpinner.loading = true;
          setTimeout(() => {
            this.updatetable = true;
            // this.progressSpinner.loading = false;
          }, 2000);
        });
        this.observableservice.getlocationsData().subscribe((res) => {
          console.log("getmultilingualData*************", res);
          this.updatetable = false;
          if (res.status == "success") {
            console.log("succccccccccccc");
            setTimeout(() => {
              this.updatetable = true;
              // this.progressSpinner.loading = false;
            }, 2000);
          }
        });
      }
      // FolderView
      if (event.custombuttonclick.btninfo.classname === "FolderView") {
        console.log(event.custombuttonclick.data, "disposition_warranty");
        window.open(
          "/user-details/lead" + "/" + event.custombuttonclick.data.id,
          "Independent Window"
        );
      }
    }
  }
  warranty() {
    const bottomSheetRef = this.bottomSheet.open(warrantybottomsheet, {
      // ariaLabel: 'Share on social media'
    });
    bottomSheetRef.afterDismissed().subscribe((openfor: any) => {
      if (openfor == "NewLead") {
        const dialogRef = this.dialog.open(newleadmodal, {
          panelClass: "inventorylistingquote_modal",
          data: {},
        });
        dialogRef.afterClosed().subscribe((result) => {
          console.log("The dialog was closed");
          console.log("The dialog was closed", result);
          if (result) {
            if (result.field == "formcancel") {
              this.router.navigateByUrl("/technological-consultant/dashboard");
            }
          }
        });

        // dialogRef.close();
      }
      if (openfor == "existinglead") {
        const dialogRef = this.dialog.open(existinglead, {
          panelClass: "inventorylistingquote_modal",

          data: {},
        });
      }
    });
  }
  goto_producttraining(val: any) {
    let basictraing: any = [];
    this.dashbordlefttraiingdata.forEach((value, index, array) => {
      if (value.percent == 100) {
        basictraing[index] = true;
      }
    });
    const uniqueResultOne = this.dashbordlefttraiingdata.filter(function (obj) {
      return obj.percent == 100;
    });
    console.log(val, this.dashbordlefttraiingdata, uniqueResultOne);
    if (uniqueResultOne.length == this.dashbordlefttraiingdata.length) {
      this.router.navigateByUrl(val);
    } else {
      let msg = "Sorry you have to Complete ";

      let msg2 = "";
      let msgarray: any = [];
      for (const key in this.dashbordlefttraiingdata) {
        msgarray.push(this.dashbordlefttraiingdata[key].categoryname);
        // msg2 += msgarray[key];

        // msg2 += this.dashbordlefttraiingdata[key].categoryname;
        console.warn(msg2);

        setTimeout(() => {
          document
            .getElementById("Training_Data_block")
            .scrollIntoView({ behavior: "smooth" });
        }, 500);
      }
      msg2 = msg + msgarray;
      console.log(msgarray);

      this.openSnackBar(msg2, "ok");
    }
  }
  traingroute() {
    this.router.navigateByUrl(
      "/traning/training-center-beto-paredes/61c317b79defb40009ca27d1/61c5618871ff49000894361a?singletraining=true&catname=Universal Tech Associate Intro "
    );
  }
}

@Component({
  selector: "cancel-alert",
  templateUrl: "cancel.html",
})
export class CancelAlert { }

@Component({
  selector: "previewfile",
  templateUrl: "previewfile.html",
})
export class previewfile implements OnInit {
  public dataVal: any;
  constructor(
    public dialogRef: MatDialogRef<previewfile>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData
  ) {
    this.dataVal = JSON.stringify(data);
    this.dataVal = JSON.parse(this.dataVal);
    // console.log(this.dataVal, '+++++')
  }
  ngOnInit() { }

  onNoClick() {
    // this.data.flag = false;
    this.dialogRef.close(this.data);
  }
}

@Component({
  selector: "clipboard",
  templateUrl: "clipboard.html",
})
export class clipboard {
  public modalData: any;
  constructor(public dialogRef: MatDialogRef<clipboard>) {
    setTimeout(() => {
      this.dialogRef.close();
    }, 3000);
    // window.scroll(0,0);
  }

  onNoClick(): void {
    this.dialogRef.close();
  }
}
@Component({
  selector: "game-tech-plan-modal",
  templateUrl: "game-paln-modal.html",
})
export class TechGameplanModalComponent implements OnInit {
  constructor(
    public dialogRef: MatDialogRef<TechGameplanModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData5
  ) { }
  ngOnInit() {
    this.data.flag = true;
    // this.dialogRef.close(this.data);
    // setTimeout(() => {
    //   this.dialogRef.close();
    // }, 6000);
  }

  gameplay() {
    this.data.flag = true;
    this.dialogRef.close(this.data);
  }
  onNoClick() {
    this.data.flag = false;
    this.dialogRef.close(this.data);
  }
}

@Component({
  selector: "resource-modal",
  templateUrl: "resource-modal.html",
})
export class ResourseModalComponent implements OnInit {
  public resource_data: any;
  public resource_data_name: any;
  constructor(
    public dialogRef: MatDialogRef<ResourseModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData4,
    public router: Router
  ) {
    // console.log(data);
    this.resource_data = data.data;
  }
  ngOnInit() { }
  gototraining() {
    this.data.flag = true;
    this.dialogRef.close(this.data);
  }
  cancelbtn() {
    this.data.flag = false;
    this.dialogRef.close(this.data);
  }
}

import { DomSanitizer } from "@angular/platform-browser";
import {
  MatBottomSheet,
  MatBottomSheetRef,
  MatSnackBar,
  MAT_BOTTOM_SHEET_DATA,
  ThemePalette,
} from "@angular/material";
import { DialogCancelAlert } from "../../calendar-management/appointment-listing/upcoming-appointment/upcoming-appointment.component";
import {
  bottomsheetfollowup,
  bottomsheetstatus,
  Dispositionview,
} from "../../lead/lead-list/lead-list.component";
// import { DetectdomainService } from "src/app/services/detectdomain.service";
@Component({
  selector: "videoModal",
  templateUrl: "videoModal.html",
})
export class videoModalComponent implements OnInit {
  public resource_data: any;
  public resource_data_name: any;
  public youtubeSource: any;
  constructor(
    public dialogRef: MatDialogRef<videoModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData2,
    public router: Router,
    public sanitizer: DomSanitizer
  ) {
    console.log(data);

    this.youtubeSource = this.sanitizer.bypassSecurityTrustResourceUrl(
      "https://www.youtube.com/embed/" + data.url + "?autoplay=1"
    );
    // this.resource_data = data.data
  }

  ngOnInit() { }

  onNoClick(): void {
    this.dialogRef.close();
  }
}
@Component({
  selector: "searchmodaltech",
  templateUrl: "searchmodaltech.html",
})
export class Searchmodaltech {
  public modalData: any;
  public formdata: any = {};
  public name_array: any = [];
  public type: any = "";
  public searchForm: FormGroup;
  public options: any = [];
  public optionemail: any = [];
  public jobticketdata: any = [];
  public jobticketcatagory: any = [];
  public modalloader: boolean = false;
  public optionticketid: any = [];
  public optionreportedby: any = [];
  public warranty_appointments_status: any = [];
  public options_attende_name: any = [];
  public option_attende_email: any = [];
  public event_type: any;
  public optionstechname: any = [];
  public optiontechemail: any = [];
  constructor(
    public dialogRef: MatDialogRef<Searchmodaltech>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    public Apiservice: ApiService,
    public router: Router,
    public http: HttpClient,
    public cookieservice: CookieService,
    public activatedRoute: ActivatedRoute,
    public dialog: MatDialog,
    public userservice: UserserviceService,
    public formBuilder: FormBuilder
  ) {
    console.log("search modal fire for", data);
    this.type = data;
    if (this.type == "job_ticket") {
      this.jobticketsListView();
      this.jobticketscatagory();
    }
    if (this.type == "warranty_appointments") {
      this.event_type = 5;
      this.google_product_name();
    }
    this.searchForm = this.formBuilder.group({
      fullname_s: [""],
      email: [""],
      lastlogin_datetime: [{}],
      lastlogin_datetimestart: [""],
      lastlogin_datetimeend: [""],
      ticketid: [""],
      ticket_status: [""],
      createdon_datetime: [{}],
      createdon_datetimestart: [""],
      createdon_datetimeend: [""],
      ticket_category_name: [""],
      type: this.type,
      fullname: [""],
      status: [""],
      attende_name: [""],
      attende_email: [""],
      product_id: [""],
      start_datetime_unix: [{}],
      start_datetime_unixstart: [""],
      start_datetime_unixend: [""],
      distributor_fullname: [""],
      dirtibutor_email: [""],
    });
    this.formdata = {
      successmessage: "Search Successfully !!",
      // redirectpath: 'admin/list',
      submitactive: true, // optional, default true
      submittext: "Submit",
      // resettext: 'Reset',
      // canceltext: 'Cancel',
      // apiUrl: this.apiservice.api_url,
      // endpoint: 'api/userupdate', //
      // jwttoken: '',
      // cancelroute: 'admin/list',

      fields: [
        {
          label: "Name",
          name: "fullname_s",
          type: "text",
          val: this.name_array,
          value: "",
          validations: [{ rule: "required" }],
        },
      ],
    };
  }
  listenFormFieldChange(val: any) {
    console.log(val);
    if (val.field.name == "fullname_s") {
      this.fetch(val.fieldval);
    }
  }
  fetch(val: any) {
    let apibody = {
      search_str: val,
      type: this.type,
      _id: this.userservice.userid,
      sort: { field: "createdon_datetime", type: "desc" },
      secret: this.userservice.secret,
      token: this.userservice.jwttoken,
    };

    this.Apiservice.getDataWithoutToken(
      apibody,
      "api/exitsinglistname"
    ).subscribe((data: any) => {
      this.name_array = data.res;
    });
  }
  search() {
    console.log(this.searchForm.value);
    // this.data.val=this.searchForm.value;
    if (this.searchForm.value["lastlogin_datetimestart"] != "") {
      this.searchForm.value["lastlogin_datetime"]["$gte"] = new Date(
        this.searchForm.value["lastlogin_datetimestart"]
      ).getTime();
      this.searchForm.value["lastlogin_datetimestart"] = "";
    }
    if (this.searchForm.value["lastlogin_datetimeend"] != "") {
      this.searchForm.value["lastlogin_datetime"]["$lte"] =
        new Date(this.searchForm.value["lastlogin_datetimeend"]).getTime() +
        86399999;
      this.searchForm.value["lastlogin_datetimeend"] = "";
    }
    if (this.searchForm.value["createdon_datetimestart"] != "") {
      this.searchForm.value["createdon_datetime"]["$gte"] = new Date(
        this.searchForm.value["createdon_datetimestart"]
      ).getTime();
      this.searchForm.value["createdon_datetimestart"] = "";
    }
    if (this.searchForm.value["createdon_datetimeend"] != "") {
      this.searchForm.value["createdon_datetime"]["$lte"] =
        new Date(this.searchForm.value["createdon_datetimeend"]).getTime() +
        86399999;
      this.searchForm.value["createdon_datetimeend"] = "";
    }
    if (this.searchForm.value["start_datetime_unixstart"] != "") {
      this.searchForm.value["start_datetime_unix"]["$gte"] = new Date(
        this.searchForm.value["start_datetime_unixstart"]
      ).getTime();
      this.searchForm.value["start_datetime_unixstart"] = "";
    }
    if (this.searchForm.value["start_datetime_unixend"] != "") {
      this.searchForm.value["start_datetime_unix"]["$lte"] =
        new Date(this.searchForm.value["start_datetime_unixend"]).getTime() +
        86399999;
      this.searchForm.value["start_datetime_unixend"] = "";
    }
    // if(this.searchForm.value['status'] != ''){
    //   if(this.searchForm.value['status'] == '1'){
    //     this.searchForm.value['status'] = 1;
    //   }
    //   if(this.searchForm.value['status'] == '0'){
    //     this.searchForm.value['status'] = 0;
    //   }
    // }
    this.dialogRef.close(this.searchForm.value);
  }

  filterfun(event: any) {
    console.log(event, "+++");
  }
  private _filter(value: string) {
    console.log(value);
  }
  onChangeEventName(val: any) {
    console.log(val, "+++++");
    if (val) {
      let apibody = {
        search_str: val,
        type: this.type,
        id: this.userservice.userid,
        sort: { field: "createdon_datetime", type: "desc" },
        secret: this.userservice.secret,
        token: this.userservice.jwttoken,
      };

      this.Apiservice.getDataWithoutToken(
        apibody,
        "api/exitsinglistname"
      ).subscribe((data: any) => {
        this.options = data.res;
      });
    }
  }
  onChangeEventLeadName(val: any) {
    this.modalloader = true;
    console.log(val, "+++++");
    if (val) {
      let apibody = {
        search_str: val,
        sort: { field: "createdon_datetime", type: "desc" },
        secret: this.userservice.secret,
        token: this.userservice.jwttoken,
      };

      this.Apiservice.getDataWithoutToken(
        apibody,
        "api/exitsinglistnameforlead?id=" + this.userservice.userid
      ).subscribe((data: any) => {
        this.options = data.res;
        this.options = [...new Set(this.options.map((item) => item.name))];
        this.modalloader = false;
      });
    } else {
      this.modalloader = false;
    }
  }
  onChangeEventEmail(val: any) {
    console.log(val);
    if (val) {
      let apibody = {
        search_str: val,
        type: this.type,
        id: this.userservice.userid,
        sort: { field: "createdon_datetime", type: "desc" },
        secret: this.userservice.secret,
        token: this.userservice.jwttoken,
      };

      this.Apiservice.getDataWithoutToken(
        apibody,
        "api/exitsinglistemail"
      ).subscribe((data: any) => {
        this.optionemail = data.res;
      });
    }
  }
  onChangeEventLeadEmail(val: any) {
    this.modalloader = true;
    console.log(val);
    if (val) {
      let apibody = {
        search_str: val,
        sort: { field: "createdon_datetime", type: "desc" },
        secret: this.userservice.secret,
        token: this.userservice.jwttoken,
      };

      this.Apiservice.getDataWithoutToken(
        apibody,
        "api/exitsinglistemailforlead?id=" + this.userservice.userid
      ).subscribe((data: any) => {
        this.optionemail = data.res;
        this.optionemail = [
          ...new Set(this.optionemail.map((item) => item.name)),
        ];
        this.modalloader = false;
      });
    } else {
      this.modalloader = false;
    }
  }
  onChangeEventAttendeeEmail(val: any) {
    this.modalloader = true;
    console.log(val);
    if (val) {
      let apibody = {
        attende_email: val,
        bookinguser: this.userservice.userid,
        event_type: this.event_type,
        sort: { field: "createdon_datetime", type: "desc" },
        secret: this.userservice.secret,
        token: this.userservice.jwttoken,
      };

      this.Apiservice.getDataWithoutToken(
        apibody,
        "api10/autocomplete_google_event_atendee_name_email"
      ).subscribe((data: any) => {
        this.option_attende_email = data.res;
        this.option_attende_email = [
          ...new Set(
            this.option_attende_email.map((item) => item.attende_email)
          ),
        ];
        this.modalloader = false;
      });
    } else {
      this.modalloader = false;
    }
  }
  onChangeEventAttendeName(val: any) {
    this.modalloader = true;
    console.log(val);
    if (val) {
      let apibody = {
        attende_name: val,
        bookinguser: this.userservice.userid,
        sort: { field: "datetimeslot", type: "desc" },
        secret: this.userservice.secret,
        token: this.userservice.jwttoken,
      };

      this.Apiservice.getDataWithoutToken(
        apibody,
        "api10/autocomplete_google_event_atendee_name_email"
      ).subscribe((data: any) => {
        this.options_attende_name = data.res;
        this.options_attende_name = [
          ...new Set(
            this.options_attende_name.map((item) => item.attende_name)
          ),
        ];
        this.modalloader = false;
      });
    } else {
      this.modalloader = false;
    }
  }
  reset() {
    this.searchForm.reset();
    this.searchForm = this.formBuilder.group({
      fullname_s: [""],
      email: [""],
      lastlogin_datetime: [{}],
      lastlogin_datetimestart: [""],
      lastlogin_datetimeend: [""],
      ticketid: [""],
      ticket_status: [""],
      createdon_datetime: [{}],
      createdon_datetimestart: [""],
      createdon_datetimeend: [""],
      ticket_category_name: [""],
      type: this.type,
      fullname: [""],
      status: [""],
      attende_name: [""],
      attende_email: [""],
      product_id: [""],
      start_datetime_unix: [{}],
      start_datetime_unixstart: [""],
      start_datetime_unixend: [""],
      distributor_fullname: [""],
      dirtibutor_email: [""],
    });
  }
  onNoClick(): void {
    this.dialogRef.close();
  }
  jobticketsListView() {
    const endpoint = "api/autocomplete_ticket_status";
    const data: any = {
      secret: this.userservice.secret,
      token: this.userservice.jwttoken,
      id: this.userservice.userid,
      // searchcondition: { user_id: this.userservice.userid },
    };

    this.Apiservice.getDataWithoutToken(data, endpoint).subscribe(
      (res: any) => {
        // console.log(res, 'jobticket');
        this.jobticketdata = res.res; // sarch count  (How many data there)
        console.log(this.jobticketdata, "::::::");
        this.jobticketdata = [
          ...new Set(this.jobticketdata.map((item) => item.name)),
        ];
        // console.log(this.jobticketdata,'::::::');
      }
    );
  }
  jobticketscatagory() {
    const endpoint = "api/autocomplete_ticket_categories";
    const data: any = {
      secret: this.userservice.secret,
      token: this.userservice.jwttoken,
      id: this.userservice.userid,
      // searchcondition: { user_id: this.userservice.userid },
    };

    this.Apiservice.getDataWithoutToken(data, endpoint).subscribe(
      (res: any) => {
        // console.log(res, 'jobticket');
        this.jobticketcatagory = res.res; // sarch count  (How many data there)
        this.jobticketcatagory = [
          ...new Set(this.jobticketcatagory.map((item) => item.name)),
        ];
      }
    );
  }
  google_product_name() {
    const endpoint = "api10/autocomplete_google_event_product_name";
    const data: any = {
      secret: this.userservice.secret,
      token: this.userservice.jwttoken,
      bookinguser: this.userservice.userid,
      // searchcondition: { user_id: this.userservice.userid },
    };

    this.Apiservice.getDataWithoutToken(data, endpoint).subscribe(
      (res: any) => {
        // console.log(res, 'jobticket');
        this.warranty_appointments_status = res.res; // sarch count  (How many data there)
        // this.warranty_appointments_status = [...new Set(this.warranty_appointments_status.map(item => item.name))];
      }
    );
  }
  onChangeEventTicketID(val: any) {
    this.modalloader = true;
    console.log(val);
    if (val != null) {
      let apibody = {
        search_str: val,
        // type: this.type,
        id: this.userservice.userid,
        sort: { field: "createdon_datetime", type: "desc" },
        secret: this.userservice.secret,
        token: this.userservice.jwttoken,
      };

      this.Apiservice.getDataWithoutToken(
        apibody,
        "api/autocomplete_ticket_id"
      ).subscribe((data: any) => {
        this.modalloader = false;
        this.optionticketid = data.res;
      });
    } else {
      this.modalloader = false;
    }
  }
  onChangeEventReportedBy(val: any) {
    this.modalloader = true;
    console.log(val);
    if (val) {
      let apibody = {
        search_str: val,
        // type: this.type,
        id: this.userservice.userid,
        sort: { field: "createdon_datetime", type: "desc" },
        secret: this.userservice.secret,
        token: this.userservice.jwttoken,
      };

      this.Apiservice.getDataWithoutToken(
        apibody,
        "api/autocomplete_job_ticket_reported_by"
      ).subscribe((data: any) => {
        this.modalloader = false;
        this.optionreportedby = data.res;
      });
    } else {
      this.modalloader = false;
    }
  }
  onChangeEventDistributorName(val: any) {
    this.modalloader = true;
    console.log(val);
    if (val) {
      const endpoint =
        "api10/get_autocomplete_commission_distributor_name?id=" +
        this.userservice.userid;
      const data: any = {
        secret: this.userservice.secret,
        token: this.userservice.jwttoken,
        search_str: val,
      };

      this.Apiservice.getDataWithoutToken(data, endpoint).subscribe(
        (res: any) => {
          // console.log(res, 'jobticket');
          this.optionstechname = res.res; // sarch count  (How many data there)
          this.modalloader = false;
        }
      );
    } else {
      this.modalloader = false;
    }
  }
  onChangeEventDistributorEmail(val: any) {
    this.modalloader = true;
    console.log(val);
    if (val) {
      const endpoint =
        "api10/get_autocomplete_commission_distributor_email?id=" +
        this.userservice.userid;
      const data: any = {
        secret: this.userservice.secret,
        token: this.userservice.jwttoken,
        search_str: val,
      };

      this.Apiservice.getDataWithoutToken(data, endpoint).subscribe(
        (res: any) => {
          // console.log(res, 'jobticket');
          this.optiontechemail = res.res; // sarch count  (How many data there)
          this.modalloader = false;
        }
      );
    } else {
      this.modalloader = false;
    }
  }
}
@Component({
  selector: "warrantybottomsheet",
  templateUrl: "warrantybottomsheet.html",
})
export class warrantybottomsheet {
  constructor(
    private _bottomSheetRef: MatBottomSheetRef<warrantybottomsheet>,
    public router: Router,
    public dialog: MatDialog
  ) { }
  openLink(event: MouseEvent, val: any): void {
    this._bottomSheetRef.dismiss(val);
  }
  // newlead() {
  //   // this.router.navigateByUrl('technological-consultant/lead/add' + '?warrantyappoinment=true')
  //   // this._bottomSheetRef.dismiss();
  //   const dialogRef = this.dialog.open(newleadmodal, {
  //     height: '400px',
  //     width: '600px',
  //   });
  // }
  // existinglead() {
  //   this._bottomSheetRef.dismiss();
  //   const dialogRef = this.dialog.open(existinglead, {
  //     height: '400px',
  //     width: '600px',
  //   });
  //   // this._bottomSheetRef.dismiss();

  // }
  onNoClick(): void {
    // this.dialogRef.close();
  }
}

@Component({
  selector: "existinglead",
  templateUrl: "existinglead.html",
})
export class existinglead {
  public leaddata: any;
  public leadval: any = [];
  public searchForm: FormGroup;
  public options: any = [];
  public details: any = [];
  public is_add: boolean = false;
  constructor(
    public dialogRef: MatDialogRef<existinglead>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    public userservice: UserserviceService,
    public apiservice: ApiService,
    public router: Router,
    public formBuilder: FormBuilder
  ) {
    this.searchForm = this.formBuilder.group({
      search_str: [""],
    });
  }
  onChangeEvent(val: any) {
    console.log(val, "val lead");
    if (val) {
      const data_lead = {
        // secret: this.userservice.secret,
        // token: this.userservice.jwttoken,
        parentid: this.userservice.userid,
        fullname_s: { $regex: val.toLowerCase() },
        type: "lead",
      };
      this.apiservice
        .getDataWithoutToken(data_lead, "api/all_typelead")
        .subscribe((res: any) => {
          this.leadval = [];
          console.log(res, "res lead");
          // this.options = res.results.res
          for (let y in res.results.res) {
            if (Object.prototype.hasOwnProperty.call(res.results.res, y)) {
              this.leadval.push({
                value: res.results.res[y]._id,
                text: res.results.res[y].fullname,
              });
              console.log(this.leadval, " this.parentidVal");
            }
          }
        });
    }
    console.log("selected value", val);
  }
  selectOption(val: any) {
    console.log(val, "val lead submit");
  }
  getValue(val: any) {
    console.log(val, "value");
    this.leaddata = val.value;
  }
  submitlead() {
    // console.log(val,'val submit');

    console.log("leaddata", this.leaddata);
    if (
      this.leaddata != null &&
      typeof this.leaddata != "undefined" &&
      this.leaddata != ""
    ) {
      this.router.navigateByUrl(
        "calendar-management/manage-appointment/warranty/book-appointment/" +
        this.leaddata
      );
      this.dialogRef.close();
      // this._bottomSheetRef.dismiss();
    }
  }

  onChangeTimezone(event: any) {
    console.log(event, "eventeventevent");
  }
  onNoClick(): void {
    this.dialogRef.close();
  }
}

@Component({
  selector: "newleadmodal",
  templateUrl: "newleadmodal.html",
})
export class newleadmodal {
  myInputMessage: string = "";
  myroute: string = "";
  constructor(
    public dialogRef: MatDialogRef<newleadmodal>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    public userservice: UserserviceService,
    public apiservice: ApiService,
    public router: Router,
    public cookieservice: CookieService
  ) {
    console.log(this.router.url, "this.router.urlthis.router.url");
    if (this.router.url == "/technological-consultant/dashboard") {
      this.myInputMessage = "manuallyadd";
    } else {
      this.myroute = this.router.url;
      this.myInputMessage = "manuallyEdit";
    }
  }
  onNoClick(): void {
    this.dialogRef.close();
  }
  GetChildData(val: any) {
    console.log(val, "child data************");
    this.dialogRef.close(val);
  }
  // ngOnDestroy() {
  //   console.log('ttttttttttttttttttttttttttttttttttttt')
  //   this.dialogRef.close();
  // }
}
