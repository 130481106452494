import { Pipe, PipeTransform } from '@angular/core';
import memo from 'memo-decorator';

@Pipe({
  name: 'timezone',
})
export class TimezonePipe implements PipeTransform {
  @memo()
  transform(time: any): any {
    const zone = [
      {
        value: 'Dateline Standard Time',
        abbr: 'DST',
      },
      {
        value: 'UTC-11',
        abbr: 'U',
      },
      {
        value: 'Hawaiian Standard Time',
        abbr: 'HST',
      },
      {
        value: 'Alaskan Standard Time',
        abbr: 'AKDT',
      },
      {
        value: 'Pacific Standard Time (Mexico)',
        abbr: 'PDT',
      },
      {
        value: 'Pacific Daylight Time',
        abbr: 'PDT',
      },
      {
        value: 'Pacific Standard Time',
        abbr: 'PST',
      },
      {
        value: 'US Mountain Standard Time',
        abbr: 'UMST',
      },
      {
        value: 'Mountain Standard Time (Mexico)',
        abbr: 'MDT',
      },
      {
        value: 'Mountain Standard Time',
        abbr: 'MDT',
      },
      {
        value: 'Central America Standard Time',
        abbr: 'CAST',
      },
      {
        value: 'Central Standard Time',
        abbr: 'CDT',
      },
      {
        value: 'Central Standard Time (Mexico)',
        abbr: 'CDT',
      },
      {
        value: 'Canada Central Standard Time',
        abbr: 'CCST',
      },
      {
        value: 'SA Pacific Standard Time',
        abbr: 'SPST',
      },
      {
        value: 'Eastern Standard Time',
        abbr: 'EDT',
      },
      {
        value: 'US Eastern Standard Time',
        abbr: 'UEDT',
      },
      {
        value: 'Venezuela Standard Time',
        abbr: 'VST',
      },
      {
        value: 'Paraguay Standard Time',
        abbr: 'PYT',
      },
      {
        value: 'Atlantic Standard Time',
        abbr: 'ADT',
      },
      {
        value: 'Central Brazilian Standard Time',
        abbr: 'CBST',
      },
      {
        value: 'SA Western Standard Time',
        abbr: 'SWST',
      },
      {
        value: 'Pacific SA Standard Time',
        abbr: 'PSST',
      },
      {
        value: 'Newfoundland Standard Time',
        abbr: 'NDT',
      },
      {
        value: 'E. South America Standard Time',
        abbr: 'ESAST',
      },
      {
        value: 'Argentina Standard Time',
        abbr: 'AST',
      },
      {
        value: 'SA Eastern Standard Time',
        abbr: 'SEST',
      },
      {
        value: 'Greenland Standard Time',
        abbr: 'GDT',
      },
      {
        value: 'Montevideo Standard Time',
        abbr: 'MST',
      },
      {
        value: 'Bahia Standard Time',
        abbr: 'BST',
      },
      {
        value: 'UTC-02',
        abbr: 'U',
      },
      {
        value: 'Mid-Atlantic Standard Time',
        abbr: 'MDT',
      },
      {
        value: 'Azores Standard Time',
        abbr: 'ADT',
      },
      {
        value: 'Cape Verde Standard Time',
        abbr: 'CVST',
      },
      {
        value: 'Morocco Standard Time',
        abbr: 'MDT',
      },
      {
        value: 'UTC',
        abbr: 'UTC',
      },
      {
        value: 'GMT Standard Time',
        abbr: 'GMT',
      },
      {
        value: 'British Summer Time',
        abbr: 'BST',
      },
      {
        value: 'GMT Standard Time',
        abbr: 'GDT',
      },
      {
        value: 'Greenwich Standard Time',
        abbr: 'GST',
      },
      {
        value: 'W. Europe Standard Time',
        abbr: 'WEDT',
      },
      {
        value: 'Central Europe Standard Time',
        abbr: 'CEDT',
      },
      {
        value: 'Romance Standard Time',
        abbr: 'RDT',
      },
      {
        value: 'Central European Standard Time',
        abbr: 'CEDT',
      },
      {
        value: 'W. Central Africa Standard Time',
        abbr: 'WCAST',
      },
      {
        value: 'Namibia Standard Time',
        abbr: 'NST',
      },
      {
        value: 'GTB Standard Time',
        abbr: 'GDT',
      },
      {
        value: 'Middle East Standard Time',
        abbr: 'MEDT',
      },
      {
        value: 'Egypt Standard Time',
        abbr: 'EST',
      },
      {
        value: 'Syria Standard Time',
        abbr: 'SDT',
      },
      {
        value: 'E. Europe Standard Time',
        abbr: 'EEDT',
      },
      {
        value: 'South Africa Standard Time',
        abbr: 'SAST',
      },
      {
        value: 'FLE Standard Time',
        abbr: 'FDT',
      },
      {
        value: 'Turkey Standard Time',
        abbr: 'TDT',
      },
      {
        value: 'Israel Standard Time',
        abbr: 'JDT',
      },
      {
        value: 'Libya Standard Time',
        abbr: 'LST',
      },
      {
        value: 'Jordan Standard Time',
        abbr: 'JST',
      },
      {
        value: 'Arabic Standard Time',
        abbr: 'AST',
      },
      {
        value: 'Kaliningrad Standard Time',
        abbr: 'KST',
      },
      {
        value: 'Arab Standard Time',
        abbr: 'AST',
      },
      {
        value: 'E. Africa Standard Time',
        abbr: 'EAST',
      },
      {
        value: 'Moscow Standard Time',
        abbr: 'MSK',
      },
      {
        value: 'Samara Time',
        abbr: 'SAMT',
      },
      {
        value: 'Iran Standard Time',
        abbr: 'IDT',
      },
      {
        value: 'Arabian Standard Time',
        abbr: 'AST',
      },
      {
        value: 'Azerbaijan Standard Time',
        abbr: 'ADT',
      },
      {
        value: 'Mauritius Standard Time',
        abbr: 'MST',
      },
      {
        value: 'Georgian Standard Time',
        abbr: 'GET',
      },
      {
        value: 'Caucasus Standard Time',
        abbr: 'CST',
      },
      {
        value: 'Afghanistan Standard Time',
        abbr: 'AST',
      },
      {
        value: 'West Asia Standard Time',
        abbr: 'WAST',
      },
      {
        value: 'Yekaterinburg Time',
        abbr: 'YEKT',
      },
      {
        value: 'Pakistan Standard Time',
        abbr: 'PKT',
      },
      {
        value: 'India Standard Time',
        abbr: 'IST',
      },
      {
        value: 'Sri Lanka Standard Time',
        abbr: 'SLST',
      },
      {
        value: 'Nepal Standard Time',
        abbr: 'NST',
      },
      {
        value: 'Central Asia Standard Time',
        abbr: 'CAST',
      },
      {
        value: 'Bangladesh Standard Time',
        abbr: 'BST',
      },
      {
        value: 'Myanmar Standard Time',
        abbr: 'MST',
      },
      {
        value: 'SE Asia Standard Time',
        abbr: 'SAST',
      },
      {
        value: 'N. Central Asia Standard Time',
        abbr: 'NCAST',
      },
      {
        value: 'China Standard Time',
        abbr: 'CST',
      },
      {
        value: 'North Asia Standard Time',
        abbr: 'NAST',
      },
      {
        value: 'Singapore Standard Time',
        abbr: 'MPST',
      },
      {
        value: 'W. Australia Standard Time',
        abbr: 'WAST',
      },
      {
        value: 'Taipei Standard Time',
        abbr: 'TST',
      },
      {
        value: 'Ulaanbaatar Standard Time',
        abbr: 'UST',
      },
      {
        value: 'North Asia East Standard Time',
        abbr: 'NAEST',
      },
      {
        value: 'Japan Standard Time',
        abbr: 'JST',
      },
      {
        value: 'Korea Standard Time',
        abbr: 'KST',
      },
      {
        value: 'Cen. Australia Standard Time',
        abbr: 'CAST',
      },
      {
        value: 'AUS Central Standard Time',
        abbr: 'ACST',
      },

      {
        value: 'Australian Central Standard Time',
        abbr: 'ACST',
      },
      {
        value: 'E. Australia Standard Time',
        abbr: 'EAST',
      },
      {
        value: 'AUS Eastern Standard Time',
        abbr: 'AEST',
      },
      {
        value: 'West Pacific Standard Time',
        abbr: 'WPST',
      },
      {
        value: 'Tasmania Standard Time',
        abbr: 'TST',
      },
      {
        value: 'Yakutsk Standard Time',
        abbr: 'YST',
      },
      {
        value: 'Central Pacific Standard Time',
        abbr: 'CPST',
      },
      {
        value: 'Vladivostok Standard Time',
        abbr: 'VST',
      },
      {
        value: 'New Zealand Standard Time',
        abbr: 'NZST',
      },
      {
        value: 'UTC+12',
        abbr: 'U',
      },
      {
        value: 'Fiji Standard Time',
        abbr: 'FST',
      },
      {
        value: 'Magadan Standard Time',
        abbr: 'MST',
      },
      {
        value: 'Kamchatka Standard Time',
        abbr: 'KDT',
      },
      {
        value: 'Tonga Standard Time',
        abbr: 'TST',
      },
      {
        value: 'Samoa Standard Time',
        abbr: 'SST',
      },
    ];

    if (time == null || time == '' || typeof time == undefined) {
      return 'N/A';
    }

    const today = new Date(time);
    const hour12 = today.toLocaleString('en-US', {
      hour: '2-digit',
      minute: '2-digit',
      hour12: true,
    });
    let fullDate: any = today.toString();
    const xy = today.toString().split(' ');
    fullDate = fullDate.split(xy[0]).join(xy[0] + ',');
    fullDate = fullDate.split(xy[3]).join(xy[3] + ',');
    fullDate = fullDate.split(xy[4]).join(hour12);
    fullDate = fullDate.split(xy[5]);
    fullDate[1] = fullDate[1].split('(').join('');
    fullDate[1] = fullDate[1].split(')').join('');
    for (const key in zone) {
      if (
        zone[key].value.toLocaleLowerCase().replace(/^\s+|\s+$/gm, '') ===
        fullDate[1].toLocaleLowerCase().replace(/^\s+|\s+$/gm, '')
      ) {
        return fullDate[0] + ' (' + zone[key].abbr + ')';
      }
    }
    const txshort = fullDate[1]
      .replace(/^\s+|\s+$/gm, '')
      .toString()
      .split(' ');
    let str1 = '';
    for (const i in txshort) {
      if (Object.prototype.hasOwnProperty.call(txshort, i)) {
        str1 += txshort[i].charAt(0);
      }
    }
    return fullDate[0] + ' (' + str1 + ')';
  }
}
