import { Component, OnInit } from '@angular/core';
import { ApiService } from '../../services/api.service';
import { DetectdomainService } from "src/app/services/detectdomain.service";
import { ObservableService } from '../../services/observable.service';

@Component({
  selector: 'app-forget-password',
  templateUrl: './forget-password.component.html',
  styleUrls: ['./forget-password.component.css']
})
export class ForgetPasswordComponent implements OnInit {

  public hostName: any;
  public medigradeflag: any=false;
  public loginlogo: string = '';

  public loginRouteingUrl: any = {
    path: '',
    buttonName: 'Login',
    customLink: '/login',
    customURl: '/'
  };
  public signUpRouteingUrl: any = {
    path: '',
    // 'buttonName':'Sign-Up',
    customLink: '/sign-up',
    customURl: ''
  };
  public buttonName: any = 'Forgot Password';
  // public signUpRouteingUrl: any = 'sign-up';
  public formTitle: any = 'Forgot Password';
  public serverUrl: any = this.apiservice.api_url;
  public addEndpoint: any = {
    endpoint: 'api/forgetpassword'
  };
  public domainUrl: any = this.apiservice.domain_url + 'reset-password';

  constructor(public apiservice: ApiService,  public observableservice: ObservableService,       
    public domainService: DetectdomainService ) {
      this.hostName = this.domainService.getdomain();
      if (this.hostName == "medigrade") {
        this.medigradeflag=true  
      }

   }

  ngOnInit() {
  }

}
