import {
  CUSTOM_ELEMENTS_SCHEMA,
  NgModule,
  NO_ERRORS_SCHEMA,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { ListingModule } from 'listing-angular7';
import { DemoMaterialModule } from '../material.module';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HeaderComponent } from 'src/app/components/header/header.component';
import { FooterComponent } from 'src/app/components/footer/footer.component';
import { ChartsModule, ThemeService } from 'ng2-charts';
import { CalendarManagementModule } from 'calendar-management-lib-influxiq';
import { TimezonePipe } from 'src/app/timezone.pipe';
import { TitlecasePipe } from 'src/app/titlecase.pipe';
import { DatePipe } from '@angular/common';
import { TechnologicalConsultantAddEditComponent } from 'src/app/components/technological-consultant/technological-consultant-add-edit/technological-consultant-add-edit.component';
import { CloserAddEditComponent } from 'src/app/components/closer/closer-add-edit/closer-add-edit.component';
import { SalesManagerAddEditComponent } from '../../components/sales-manager/sales-manager-add-edit/sales-manager-add-edit.component';
import { DistributorAddEditComponent } from '../../components/distributor/distributor-add-edit/distributor-add-edit.component';
import { SalesManagerListComponent } from 'src/app/components/sales-manager/sales-manager-list/sales-manager-list.component';
import { TechnologicalConsultantListComponent } from 'src/app/components/technological-consultant/technological-consultant-list/technological-consultant-list.component';
import {
  Dialogmoremodal,
  LeadListComponent,
} from 'src/app/components/lead/lead-list/lead-list.component';
import { DuplicateWrongDataViewDialog, LeadAddEditComponent, ViewSuccessDialogBulkUpload } from 'src/app/components/lead/lead-add-edit/lead-add-edit.component';
import { ContractViewComponent } from 'src/app/components/contract-view/contract-view.component';
import { BlockUIModule } from 'ng-block-ui';
import { CKEditorModule } from 'ngx-ckeditor';
import { StatePipe } from 'src/app/state.pipe';
import { CheckedflagPipe } from 'src/app/checkedflag.pipe';
import { ReplacePipe } from 'src/app/pipes/replace.pipe';
import {
  DateAdapter,
  MAT_DATE_FORMATS,
  MAT_DATE_LOCALE,
  SatDatepickerModule,
} from 'saturn-datepicker';
import { ContractAnagerAddEditComponent } from 'src/app/components/contact-manager/contract-anager-add-edit/contract-anager-add-edit.component';
import { SafePipe } from 'src/app/pipes/safe.pipe';
import { DialogOverviewExampleDialogForCancel } from 'src/app/components/credit/credit.component';
import { DetailspipePipe } from 'src/app/pipes/detailspipe.pipe';
import { CommissionAddComponent } from 'src/app/components/commission/commission-add/commission-add.component';
import { CommissionListingComponent, CommissionTypeModal, PayCommissionTypeModal, ViewPaymentRecord } from 'src/app/components/commission/commission-listing/commission-listing.component';
import { UpcomingAppointmentComponent } from 'src/app/components/calendar-management/appointment-listing/upcoming-appointment/upcoming-appointment.component';
import { DialogOverviewSigneeDialog } from 'src/app/components/folder-view-management/folder-view/folder-view.component';
import { DialogNewContractDialog } from 'src/app/components/admin/admindashboard/admindashboard.component';
import { FilterArrayPipe } from 'src/app/pipes/filter-array.pipe';
import{EmailbodCustommodalDialog} from 'src/app/components/allemailsqued-reports/allemailsqued-reports.component'
@NgModule({
  declarations: [
    HeaderComponent,
    FooterComponent,
    TimezonePipe,
    TitlecasePipe,
    StatePipe,
    CheckedflagPipe,
    ReplacePipe,
    SafePipe,
    FilterArrayPipe,
    TechnologicalConsultantAddEditComponent,
    CloserAddEditComponent,
    SalesManagerAddEditComponent,
    DistributorAddEditComponent,
    SalesManagerListComponent,
    TechnologicalConsultantListComponent,
    LeadListComponent,
    LeadAddEditComponent,
    ContractViewComponent,
    ContractAnagerAddEditComponent,
    Dialogmoremodal,
    DialogOverviewExampleDialogForCancel,
    DetailspipePipe,
    CommissionAddComponent,
    CommissionListingComponent,
    CommissionTypeModal,
    PayCommissionTypeModal,
    ViewPaymentRecord,
    UpcomingAppointmentComponent,
    DuplicateWrongDataViewDialog,
    ViewSuccessDialogBulkUpload,
    DialogOverviewSigneeDialog,
    DialogNewContractDialog,
    EmailbodCustommodalDialog
  ],
  imports: [
    BlockUIModule.forRoot(),
    CommonModule,
    DemoMaterialModule,
    RouterModule,
    ListingModule,
    FormsModule,
    ReactiveFormsModule,
    ChartsModule,
    CalendarManagementModule,
    CKEditorModule,
    SatDatepickerModule
  ],
  exports: [
    ListingModule,
    DemoMaterialModule,
    HeaderComponent,
    FooterComponent,
    TechnologicalConsultantAddEditComponent,
    CloserAddEditComponent,
    SalesManagerAddEditComponent,
    DistributorAddEditComponent,
    CalendarManagementModule,
    TimezonePipe,
    TitlecasePipe,
    StatePipe,
    CheckedflagPipe,
    ReplacePipe,
    SafePipe,
    FilterArrayPipe,
    SalesManagerListComponent,
    TechnologicalConsultantListComponent,
    LeadListComponent,
    LeadAddEditComponent,
    ContractViewComponent,
    BlockUIModule,
    SatDatepickerModule,
    ContractAnagerAddEditComponent,
    Dialogmoremodal,
    DialogOverviewExampleDialogForCancel,
    DetailspipePipe,
    CommissionAddComponent,
    CommissionListingComponent,
    CommissionTypeModal,
    PayCommissionTypeModal,
    ViewPaymentRecord,
    UpcomingAppointmentComponent,
    DuplicateWrongDataViewDialog,
    ViewSuccessDialogBulkUpload,
    DialogOverviewSigneeDialog,
    DialogNewContractDialog,
    EmailbodCustommodalDialog
  ],
  providers: [ThemeService, DatePipe],
  entryComponents: [Dialogmoremodal, DialogOverviewExampleDialogForCancel, CommissionTypeModal, PayCommissionTypeModal, ViewPaymentRecord, DuplicateWrongDataViewDialog, ViewSuccessDialogBulkUpload,DialogOverviewSigneeDialog,DialogNewContractDialog,EmailbodCustommodalDialog],
  schemas: [CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA],
})
export class ShareModule { }
