import 'hammerjs';
import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { isDevMode } from '@angular/core';
import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
// isDevMode() && console.log('bla bla bla bla');
if (environment.production) {
  enableProdMode();
  if (window) {
    // window['log'] = console.log;
    // window['error'] = console.error;
    // window['debug'] = console.debug;
  }

  // console.log = console.error = () => {};
}

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .catch((err) => console.log(err));
