import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { ApiService } from 'src/app/services/api.service';
import { UserserviceService } from 'src/app/services/userservice.service';

@Component({
  selector: 'app-listing',
  templateUrl: './listing.component.html',
  styleUrls: ['./listing.component.css'],
})
export class VideoCategoryListingComponent implements OnInit {
  categorylisttable: any = [];

  public status: any = [
    { val: 1, name: 'Active' },
    { val: 0, name: 'Inactive' },
  ];
  public launched: any = [
    { val: true, name: 'Yes' },
    { val: null, name: 'No' },
  ];

  // use for status search
  statusarray: any = [
    { val: 1, name: 'Active' },
    { val: 0, name: 'Inactive' },
  ];

  // editrouting
  editroute: any = '/video-management/category/edit/';

  // modify header(key : modify header name)
  modifyheaderarray: any = {
    category_name: 'Name',

    description: 'Description',
    category_priority: 'Priority',
    createdatetime: 'Added Date',
    parentvideocategory: ' Category',
    status: 'Status',
  };

  // use for table field skip
  categorylisttableskip: any = [
    '_id',
    'parentvideocategory_search',
    'category_name_s',
    'category_description',
  ];

  // use for Table Detail Field Skip
  categorylisttabledetailskip: any = [
    '_id',
    'parentvideocategory_search',
    'category_name_s',
    'description',
    'status',
  ];

  // updateendpoint is use for data update endpoint
  updateendpoint = 'api1/addorupdatevideocategory';

  // deleteendpoint is use for data delete endpoint
  deleteendpoint = 'api1/deletevideocategorydata';

  // this is a database collection name
  tablename = 'users';

  // searchendpoint is use for data search endpoint
  searchendpoint = 'api1/getallvideodcategorydata';

  // use for click to another page routing path
  clicktoaddananotherpage = '';

  // date_search_endpoint is use for date search endpoint
  datesearchendpoint: any = '';

  // send basic limit data
  limitcond: any = {
    limit: 10,
    skip: 0,
    pagecount: 1,
  };

  // other data
  libdata: any = {
    // basecondition: { type: '' },



    preview_header: {

      header: "Preview Data for Details",
      class: 'preheadercls'
  },
    detailview_override: [
      { key: 'category_name', val: ' Name' },
      { key: 'category_description', val: 'Description' },
      { key: 'category_priority', val: 'Priority' },
      { key: 'createdatetime', val: 'Added Date' },
      { key: 'parentvideocategory', val: 'Parent Category' }
    ],
    tableheaders: [
      'category_name',
      'description',
      'parentvideocategory',
      'category_priority',
      'status',
      'createdatetime',
    ],
    // searchBarFlagVal:true,
    // tableheaders name [key]
    // optional
    updateendpointmany: 'api1/videocategorystatusupdate',
    updateendpoint: 'api1/videocategorystatusupdate',
    deleteendpointmany: 'api1/deletevideocategory',
    hidemultipleselectbutton: null,
    hidecounter: null,
    hideaction: null,
    hidestatustogglebutton: false, // (hide status toggle button)
    hideviewbutton: false, // (hide view button)
    // actioncolname: 'Actn',
  };

  // send basic sort data
  sortdata: any = {
    type: 'asc',
    field: 'category_name',
    options: ['category_name', 'status', 'category_priority'],
  };

  // this is a database collection or view name
  // datesearchsource: any = 'admin_blog_list';
  // datacollection
  datacollection: any = 'api1/getallvideodcategorydata';
  // source count
  datesearchsourcecount: any = 0;

  searchsettings: any = {
    datesearch: [
      {
        startdatelabel: 'Start Date',
        enddatelabel: ' End Date',
        submit: 'Search',
        field: 'createdatetime',
      },
    ],
    selectsearch: [
      { label: 'Search By Status', field: 'status', values: this.status },
    ],
    textsearch: [
      { label: 'Search By Name', field: 'category_name_s' },
      // { label:'Search By Email', field:'verifiedemail'}
    ],
  };

  public userid: any;
  public userFullName: any;
  // for first component end

  // for Second component Start
  public product_nameSearch: any = [];
  public videolisttable: any = [];
  videolisttableskip: any = [
    '_id',
    'video_title_s',
    'videocategory_s',
    'category_description',
    'product_name_s',
    'Video_title_s',
  ];

  // use for Table Detail Field Skip
  videolisttabledetailskip: any = [
    '_id',
    'parentvideocategory_search',
    'description',
    'product_name_s',
    'category_id',
    'video_title_s',
    'videocategory_s',
    'product_id',
    'status'
  ];
  videomodifyheaderarray: any = {
    video_title: 'Name',

    // video_description: 'Description',
    video_priority: 'Priority',
    createdatetime: 'Added Date',
    videocategory: ' Category',
    status: 'Status',
    video: 'Video',
    product_name: 'Product Name',
  };
  // deleteendpoint is use for data delete endpoint
  videodeleteendpoint = 'api1/deletevideodata';
  // other data
  videolibdata: any = {
    basecondition: { status: 1 },
    detailview_override: [
      { key: 'video_title', val: 'Video Name' },
      { key: 'video_description', val: 'Video Description' },
      { key: 'video_priority', val: 'Video Priority' },
      { key: 'createdatetime', val: 'Added Date' },
      { key: 'parentvideocategory', val: 'Parent Category' },
      { key: 'product_name', val: 'Product Name' },
      { key: 'videocategory', val: 'Video Category'},
      { key: 'sataus', val: 'Status'}
    ],
    tableheaders: [
      'video_title',
      'video',
      // 'video_description',
      'videocategory',
      'product_name',
      'video_priority',
      'status',
      'createdatetime',
    ],
    // searchBarFlagVal:true,
    // tableheaders name [key]
    // optional
    updateendpointmany: 'api1/videostatusupdate',
    updateendpoint: 'api1/videostatusupdate',
    deleteendpointmany: 'api1/deletevideodata',
    hidemultipleselectbutton: null,
    hidecounter: null,
    hideaction: null,
    hidestatustogglebutton: false, // (hide status toggle button)
    hideviewbutton: false, // (hide view button)
    // actioncolname: 'Actn',
  };

  videosortdata: any = {
    type: 'asc',
    field: 'video_title',
    options: ['video_title', 'status', 'video_priority'],
  };
  videotablename = 'users';
  videostatusarray: any = [
    { val: 1, name: 'Active' },
    { val: 0, name: 'Inactive' },
  ];

  // updateendpoint is use for data update endpoint
  videoupdateendpoint = 'api1/addorupdatevideo';
  videodatesearchsourcecount: any = 0;
  videosearchendpoint = 'api1/getallvideoddata';

  videosearchsettings: any = {
    datesearch: [
      {
        startdatelabel: 'Start Date',
        enddatelabel: ' End Date',
        submit: 'Search',
        field: 'createdatetime',
      },
    ],
    selectsearch: [
      { label: 'Search By category', field: 'videocategory', values: [] },
      { label: 'Search By Status', field: 'status', values: this.status },

      // { label: 'Search By Category', field: 'videocategory_s', values: this.status }
      // { label: 'Search By Product Name', field: 'product_name', values: this.product_name_s },
    ],
    textsearch: [
      { label: 'Search By Name', field: 'video_title_s' },
      // { label:'Search By Email', field:'verifiedemail'}
    ],
    search: [
      {
        label: 'Search By Product Name ',
        field: 'product_name_s',
        values: this.product_nameSearch,
        serversearchdata: { endpoint: 'api1/productautocompletesearch' },
      },
    ],
  };
  videodatacollection: any = 'api1/getallvideodata';

  videolimitcond: any = {
    limit: 10,
    skip: 0,
    pagecount: 1,
  };

  videoeditroute: any = '/video-management/video/edit';

  // for second component end
  public categorylist = [];
  constructor(
    public router: Router,
    public Apiservice: ApiService,
    public http: HttpClient,
    public cookieservice: CookieService,
    public activatedRoute: ActivatedRoute,
    public userservice: UserserviceService
  ) {
    this.activatedRoute.data.forEach((res: any) => {
      this.categorylisttable = res.resolvedata.results.res; // data store from backend
    });

    const endpointc = 'api1/getallvideodcategorydata-count';
    const endpoint = 'api1/getallvideodcategorydata';
    const data: any = {
      // How many data showing in first page
      condition: {
        // condition for how much data show in one time
        limit: 10,
        skip: 0,
      },
      sort: {
        type: 'asc', // data sort by decreasing order
        field: 'category_name', // data sort by first name
      },
    };

    this.Apiservice.getDataWithoutToken(data, endpointc).subscribe(
      (res: any) => {
        this.datesearchsourcecount = res.count; // sarch count  (How many data there)
      }
    );
  }

  ngOnInit() {
    const endpointc = 'api1/getallvideodata-count';
    const endpoint = 'api1/getallvideodata';
    const data: any = {
      // How many data showing in first page
      condition: {
        // condition for how much data show in one time
        limit: 10,
        skip: 0,
      },
      sort: {
        type: 'asc', // data sort by decreasing order
        field: 'video_title', // data sort by first name
      },
    };
    this.Apiservice.getDataWithoutToken(data, endpoint).subscribe(
      (res: any) => {
        // console.log(res.results.res[1].product_name_s, 'jjjjjjjjjjjj')
        this.videolisttable = res.results.res; // sarch count  (How many data there)
        for (const key in res.results.res) {
          this.product_nameSearch.push({
            val: res.results.res[key]._id,
            name: res.results.res[key].product_name_s,
          });
        }
      }
    );
    this.Apiservice.getDataWithoutToken(data, endpointc).subscribe(
      (res: any) => {
        this.videodatesearchsourcecount = res.count; // sarch count  (How many data there)
      }
    );

    this.Apiservice.getDataWithoutToken(
      {},
      'api1/getallvideodcategoryselectsearchdata'
    ).subscribe((res: any) => {
      // console.log(res.results.res[1].product_name_s, 'jjjjjjjjjjjj')
      for (const key in res.results.res) {
        this.categorylist.push({
          val: res.results.res[key].category_name,
          name: res.results.res[key].category_name,
        });
      }

      this.videosearchsettings.selectsearch[0].values = this.categorylist;
    });
  }

  addVideoButton() {
    this.router.navigateByUrl('/video-management/video/add');
  }
  addCategoryButton() {
    this.router.navigateByUrl('/video-management/category/add');
  }
}
