import { Pipe, PipeTransform } from '@angular/core';
import memo from 'memo-decorator';

@Pipe({
  name: 'titlecase',
})
export class TitlecasePipe implements PipeTransform {
  @memo()
  transform(value: any): any {
    if (typeof value !== 'undefined' && value !== null) {
      return value.replace(/\w\S*/g, (txt: any) => {
        return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
      });
    } else {
      return 'N/A';
    }
  }
}
