import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { MatBottomSheet, MatSnackBar } from '@angular/material';
import { ActivatedRoute, Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { ApiService } from 'src/app/services/api.service';
import { UserserviceService } from 'src/app/services/userservice.service';

@Component({
  selector: 'app-corn-google-events',
  templateUrl: './corn-google-events.component.html',
  styleUrls: ['./corn-google-events.component.css']
})
export class CornGoogleEventsComponent implements OnInit {
  public progressSpinner: any = {
    mode: 'indeterminate',
    loading: false,
    bookingStatus: 'Sending request',
  };
  public insertgoogleevent_datatable: any = [];
  public deletebpslots_datatable: any = [];
  public userstatus_datatable: any = [];
  public deleteeventslots_datatable: any = [];
  public selected = {
    value: 0
  }
  insertgoogleevent_datatableskip: any = [
    'executed_ids',
    'description',
    'eid',
    'end_date',
    'end_time',
    'event_type',
    'product_id',
    'slot',
    'slot_index',
    'st',
    'start_date',
    'start_time',
    'startdate',
    'timezone',
    'userid',
    '_id',
    'executed_on'
  ];
  insertgoogleevent_modifyheaderarray: any = {
    execution_time_seconds: 'Execution Time Seconds',
    insert_google_event: 'Insert Google  Event',
    delete_bp_slots: 'Delete Bp Slots',
    createdon_datetime: 'Executed On',
    event_title: 'Event Title',
    event_type_val: 'Event Type',
    product_name: 'Product Name',
    timespan: 'Time Span',
    username: 'User Name',
    useremail: 'User Email',
    start_datetime_unix: 'Start Date Time'
  };

  

  insertgoogleevent_datatabledetailskip: any = [
    'executed_ids',
    'createdon_datetime',
    'description',
    'eid',
    'end_date',
    'end_time',
    'event_type',
    'product_id',
    'slot',
    'slot_index',
    'st',
    'start_date',
    'start_time',
    'startdate',
    'timezone',
    'userid',
    '_id',
    'start_datetime_unix',
    'executed_on'
  ];
  insertgoogleevent_searchsettings: any = {
    datesearch: [
      {
        startdatelabel: ' Start Date',
        enddatelabel: 'End Date',
        submit: 'Search',
        field: 'createdon_datetime',
      },

    ],
  };
  insertgoogleevent_sortdata: any = {
    type: 'desc',
    field: '_id',
    options: [
      'createdon_datetime'
    ],
  };
  insertgoogleevent_libdata: any = {
    basecondition: {
      insert_google_event: true
    },
    detailview_override: [
      { key: 'insert_google_event', val: 'Insert Google Event' },
      { key: 'execution_time_seconds', val: 'Execution Time Seconds' },
      { key: 'date', val: 'Executed On' },
    ],
    // table headline
    tableheaders: [
      'execution_time_seconds',
      'insert_google_event',
      'createdon_datetime',
    ],
    custombuttons: [
      {
        label: "View Google Events",
        type: 'action',
        datatype: 'api',
        endpoint: 'api11/fetchgoogleeventreport',
        datafields: ['Executed On', 'Insert Google Event', 'Execution Time Seconds'],
        param: 'id',
        headermessage: 'View Google Events',
      },
    ],
    // hidestatustogglebutton: true,
    hidedeletebutton: true,
    updateendpointmany: '', // update multiple user
    updateendpoint: '', // update status
    deleteendpointmany: '', // delete multiple user
    hidemultipleselectbutton: true, // hide multiple selection button (false)
    hidecounter: null, // hide counter (false)
    hideaction: false, // hide action (false)
    hideviewbutton: true, // (hide view button)
    hideeditbutton: true, // all these button options are optional not mandatory
    hidestatustogglebutton: true,
  };
  insertgoogleevent_limitcond: any = {
    limit: 10,
    skip: 0,
    pagecount: 1,
  };
  insertgoogleevent_datesearchsourcecount: any = 0;
  deletebpslots_datesearchsourcecount: any = 0;
  public insertgoogleevent_datacollection: any = 'api8/google_event_cron_report';
  public deletebpslots_datacollection: any = 'api8/google_event_cron_report';

  deletebpslots_libdata: any = {
    basecondition: {
      delete_bp_slots: true
    },
    detailview_override: [
      { key: 'delete_bp_slots', val: 'Delete Bp Slots' },
      { key: 'execution_time_seconds', val: 'Execution Time Seconds' },
    ],
    // table headline
    tableheaders: [
      'execution_time_seconds',
      'delete_bp_slots',
      'createdon_datetime',
    ],
    custombuttons: [],
    // hidestatustogglebutton: true,
    hidedeletebutton: true,
    updateendpointmany: '', // update multiple user
    updateendpoint: '', // update status
    deleteendpointmany: '', // delete multiple user
    hidemultipleselectbutton: true, // hide multiple selection button (false)
    hidecounter: null, // hide counter (false)
    hideaction: false, // hide action (false)
    hideviewbutton: false, // (hide view button)
    hideeditbutton: true, // all these button options are optional not mandatory
    hidestatustogglebutton: true,
  }
  // for delete event slot block 
  deleteeventslots_datesearchsourcecount: any = 0;

 
  // for delete event slot block 

  // for user status tab //
  userstatus_libdata: any = {
    basecondition: {
      delete_bp_slots: true
    },
    detailview_override: [
      { key: 'email', val: 'User Email' },
      { key: 'full_name', val: 'User Email' },
      { key: 'connected_gmail', val: 'Google Email' },
      { key: 'calendar_event_updated', val: 'Updated On' },
    ],
    // table headline
    tableheaders: [
      'email',
      'full_name',
      'connected_gmail',
      'calendar_event_updated'
    ],
    custombuttons: [],
    // hidestatustogglebutton: true,
    hidedeletebutton: true,
    updateendpointmany: '', // update multiple user
    updateendpoint: '', // update status
    deleteendpointmany: '', // delete multiple user
    hidemultipleselectbutton: true, // hide multiple selection button (false)
    hidecounter: null, // hide counter (false)
    hideaction: false, // hide action (false)
    hideviewbutton: false, // (hide view button)
    hideeditbutton: true, // all these button options are optional not mandatory
    hidestatustogglebutton: true,
  }
  user_status_modifyheaderarray:any={
    email : 'User Email',
    full_name:'User Email',
    connected_gmail:'Google Email',
    calendar_event_updated :'Updated On'
  }
  user_status_datatableskip: any = [
    '_id',
  ];

  // for user status tab // 


  deleteeventslots_libdata: any = {
    basecondition: {
      delete_bp_slots: true
    },
    detailview_override: [
      { key: 'event_title', val: 'Event Title' },
      { key: 'event_type_val', val: 'Event Type' },
      { key: 'product_name', val: 'Product Name' },
      { key: 'timespan', val: 'Time Span' },
      { key: 'username', val: 'User Name' },
      { key: 'useremail', val: 'User Email' },
    ],
    // table headline
    tableheaders: [
      'event_title',
      'event_type_val',
      'product_name',
      'timespan',
      'username',
      'useremail',
      'start_datetime_unix'
    ],
    custombuttons: [],
    // hidestatustogglebutton: true,
    hidedeletebutton: true,
    updateendpointmany: '', // update multiple user
    updateendpoint: '', // update status
    deleteendpointmany: '', // delete multiple user
    hidemultipleselectbutton: true, // hide multiple selection button (false)
    hidecounter: null, // hide counter (false)
    hideaction: false, // hide action (false)
    hideviewbutton: false, // (hide view button)
    hideeditbutton: true, // all these button options are optional not mandatory
    hidestatustogglebutton: true,
  }
  public deleteeventslots_datacollection: any = 'api8/delete-google-event-report';
  deleteeventslots_sortdata: any = {
    type: 'desc',
    field: '_id',
    options: [
      'createdon_datetime'
    ],
  };
  constructor(
    public router: Router,
    public Apiservice: ApiService,
    public http: HttpClient,
    public cookieservice: CookieService,
    public activatedRoute: ActivatedRoute,
    public userservice: UserserviceService,
    public bottomSheet: MatBottomSheet,
    public snackBar: MatSnackBar,
  ) {
    const endpointc = 'api8/google_event_cron_report-count';
    const endpoint = 'api8/google_event_cron_report';
    const data: any = {
      // How many data showing in first page
      secret: this.cookieservice.get('secret'),
      token: this.cookieservice.get('jwtToken'),
      source: '',
      condition: {
        // condition for how much data show in one time
        limit: 10,
        skip: 0,
      },
      sort: {
        type: 'desc', // data sort by decreasing order
        field: 'createdon_datetime', // data sort by first name
      },
      searchcondition: {
        insert_google_event: true
      },
    };
    this.Apiservice.getDataWithoutToken(data, endpointc).subscribe(
      (res: any) => {
        this.insertgoogleevent_datesearchsourcecount = res.count; // sarch count  (How many data there)
      }
    );
  }

  ngOnInit() {
    this.activatedRoute.data.forEach((res: any) => {
      this.insertgoogleevent_datatable = res.resolvedata.results.res; // data store from backend
      console.log(this.insertgoogleevent_datatable, 'AFFFF');

    });
  }
  listenLiblistingChange(){

  }
  onTabClick(val) {
    // this.progressSpinner.loading = true;
    console.log(val, 'index val');
    console.log(val.index);
    this.selected.value = val.index;
    if (val.index == 0) {
      const endpoint = 'api8/google_event_cron_report';
      const data: any = {
        // How many data showing in first page
        secret: this.cookieservice.get('secret'),
        token: this.cookieservice.get('jwtToken'),
        source: '',
        condition: {
          // condition for how much data show in one time
          limit: 10,
          skip: 0,
        },
        sort: {
          type: 'desc', // data sort by decreasing order
          field: 'createdon_datetime', // data sort by first name
        },
        searchcondition: {
          insert_google_event: true
        },
      };

    }
    if (val.index == 1) {
      this.progressSpinner.loading = true;
      let data = {
        secret: this.cookieservice.get('secret'),
        token: this.cookieservice.get('jwtToken'),
        source: '',
        condition: {
          limit: 10,
          skip: 0,
        },
        sort: {
          type: 'desc',
          field: 'createdon_datetime',
        },
        searchcondition: {
          delete_bp_slots: true
        },
      };
      console.log(data);
      this.Apiservice.getDataWithoutToken(
        data,
        'api8/google_event_cron_report'
      ).subscribe((res: any) => {
        console.log(res, 'results');
        if (res.status == 'success') {
          this.deletebpslots_datatable = res.results.res
          this.progressSpinner.loading = false;

        }
      });
      this.Apiservice.getDataWithoutToken(
        data,
        'api8/google_event_cron_report-count'
      ).subscribe((res: any) => {
        this.deletebpslots_datesearchsourcecount = res.count;
      });
    }
    if (val.index == 2) {
    this.progressSpinner.loading = true;
      let data = {
        secret: this.cookieservice.get('secret'),
        token: this.cookieservice.get('jwtToken'),
        source: '',
        condition: {
          limit: 10,
          skip: 0,
        },
        sort: {
          type: 'desc',
          field: 'createdon_datetime',
        },
        searchcondition: {

        },
      };
      console.log(data);
      this.Apiservice.getDataWithoutToken(
        data,
        'api8/googleuserreportadmin'
      ).subscribe((res: any) => {
        console.log(res, 'results');
        if (res.status == 'success') {
          this.userstatus_datatable = res.results.res
          this.progressSpinner.loading = false;
        }
      });
      // this.Apiservice.getDataWithoutToken(
      //   data,
      //   'api8/delete-google-event-report-count'
      // ).subscribe((res: any) => {
      //   this.deleteeventslots_datesearchsourcecount = res.count;
      // });
    }
  }
}

