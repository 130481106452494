import { Component, Inject, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiService } from 'src/app/services/api.service';
import { UserserviceService } from 'src/app/services/userservice.service';
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from '@angular/material/dialog';
import { ChartDataSets } from 'chart.js';
import { environment } from 'src/environments/environment';
import { LoaderService } from 'src/app/services/loader.service';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { CookieService } from 'ngx-cookie-service';
import { error } from '@angular/compiler/src/util';
import { element } from 'protractor';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import { DialogCancelAlert } from '../../calendar-management/appointment-listing/upcoming-appointment/upcoming-appointment.component';
import { DOCUMENT } from '@angular/common';
import { MatSnackBar } from '@angular/material';
import { DetectdomainService } from 'src/app/services/detectdomain.service';
export interface DialogData {
  data: any;
  val: any;
}
export interface NotesValue {
  data: any;
}
export interface DialogData5 {
  data: any;
  flag: any;
  time: any;
  training_flag: any;
}

@Component({
  selector: 'app-admindashboard',
  templateUrl: './admindashboard.component.html',
  styleUrls: ['./admindashboard.component.css'],
})
export class AdmindashboardComponent implements OnInit {
  @BlockUI('hero-section') blockUI: NgBlockUI;
  public opennotes: boolean = false;
  public notesbtnval: string = 'Open Notes';
  public allflags: any = {};
  public allflags1: any = {};
  public allflags2: any = {};
  public allflags3: any = {};
  public activetrainingFlag: boolean = false;
  public trainingListdatatableflag: boolean = false;
  public trainingdetails: any = [];
  public isrefresh_training_report: boolean = false;
  public trainingnorecordfound: boolean = false;
  public showing_commission_data_distributor: any = [];
  public showing_commission_data_tech: any = [];
  public allflags4: any = {};
  public traingdata: any = [];
  public mostactiveblockthreeflag: any;
  public notificationdata: any = '';
  public addcommission: any = false;
  public updatetotechcommission: any = false;
  public getalltrainingdatanodatafound: any = false;
  public getlessonlistdatanodatafound: boolean = false;
  public getalltrainingdata: any = [];
  public getlessondatalist: any = [];
  public lessonlistflg: boolean = false;
  public Traininglistflg: boolean = false;
  public usersflg: any = {};
  public Trainingflg: any = {};
  public Leadsflg: any = {};
  public calenderflg: any = {};
  public contractsflg: any = {};
  public activedistributorsearchsettings: any = {};
  public allleadsflg: any = false;
  public allcalenderflg: any = false;
  public allcontractflg: any = false;
  public usertype = ['admin'];
  public Trainingdata = [];
  public leads = [];
  public calender = [];
  public contracts = [];
  public contruct_status:any=[];
  public contruct_status_string: any = [];  
  public leads_status: any = [];
  public calender_status: any = [];
  public allcontractdata: any = [];
  public allcalenderdatanodatafound: any = [];
  public allcalenderdata: any = [];
  public allleadsdata: any = [];
  public allleadsdatasearchlistcount: any = 0;
  public resourceActiveflg: boolean = false;
  public productActiveflg: boolean = false;
  public gameplan_alert: boolean = false;
  public success_booking: boolean = false;
  public jobticketnorecordfound: boolean = false;
  public search_by: boolean = false;
  public search_by_text: any = [];
  public header_text: any = 'User Summary';
  public header_text_sub: any = [' Admin'];
  public individual_usersummary_text: any = [' Admin'];
  public individual_training_list_text: any = [];
  public individual_calendar_text: any = [];
  public individual_contracts_text: any = [];
  public headerCat = '';
  slidetimeout;
  slide_technologicaltimeout: any;
  slide_jobticketstimeout: any;
  public status_s: Number;
  public isCheckedName = 'admin';
  public calenderRoute: any;
  public mostactiveflag = 0;
  public mostactiveflagfornextblock = 0;
  public secendactiveflag = 0;
  public isChecked: boolean;
  slide_signaturetimeout: any;
  public gameplancall: any;
  public onboardingcall: any;
  slide_productstimeout: any;

  slide_resourcestimeout: any;
  slideindex_scheduletimeout: any;
  slideindex_leadtimeout: any;

  slidesales_personstimeout: any;
  signupdatadayarray: any = [];
  signupdatamontharray: any = [];

  logindatadayarray: any = [];
  logindatamontharray: any = [];

  deleteendpoint = '';
  updateendpoint = '';
  tablename = '';
  searchendpoint = '';
  searchendpointresourcelist = '';
  public Scheduleddatatableflag: any = false;
  public Commmissiondatatableflag: any = false;
  public NewlyAddedtableflag: any = false;
  public signupFlag: boolean = false;
  public loginFlag: boolean = false;
  public trainingFlag: boolean = false;
  public distributerListdatatableflag: boolean = false;
  public activetoggleactivedisributerFlag: boolean = false;
  public distributor_datesearchsourcecount: any = 0;
  public scheduleappointmentnorecordfound: boolean = false;
  public isrefresh_schedule_appointment: boolean = false;
  public refresh_user_management: boolean = false;
  public mostactiveflagforfirstblock: any = 0;
  // lineChart

  sortdatatraining: any = {
    type: 'desc',
    field: 'percent',
    options: [
      'percent',
      'donedata',
      'totaldata',
      'current_lesson_name',
      'current_lesson_name',
      'user_fullname'
    ],
  };

  public libdatatraning: any = {
    // basecondition: { parentid: this.userservice.userid },
    detailview_override: [
      { key: 'user_fullname', val: 'Tech Name ' },
      { key: 'percent', val: 'Training Percentage' },
      { key: 'current_lesson_name', val: 'Current Lesson Name ' },
      { key: 'next_lesson_name', val: 'Next Lesson Name ' },
      { key: 'previous_lesson_name', val: 'Previous Lesson Name' },
      { key: 'donedata', val: 'No. Of lessons done' },
      { key: 'totaldata', val: 'Total no. of lessons' },
    ],
    tableheaders: [
      'user_fullname',
      'percent',
      'current_lesson_name',
      'next_lesson_name',
      'previous_lesson_name',
      'donedata',
      'totaldata'
    ],

    hidemultipleselectbutton: true,
    hideaction: false,
    hideeditbutton: true, // all these button options are optional not mandatory
    hidedeletebutton: true,
    hideviewbutton: true,
    hidestatustogglebutton: true,
    custombuttons: [
      {
        label: 'Open Folder View Same Page',
        type: 'listner',
        id: 'i1',
        classname: 'open_in_same_list',
        tooltip: 'Open Folder View Same Page',
      },
      {
        label: 'Open Folder View New Page',
        type: 'listner',
        id: 'i1',
        classname: 'open_in_new_list',
        tooltip: 'Open Folder View New Page  ',
      },
    ],
  };
  public modifyheaderarraytraining: any = {
    user_fullname: 'User Name',
    traning_name: 'Associated Training',
    donedata: 'Lession Done',
    totaldata: 'No of Lesson',
    percent: 'Percent',
    'current_lesson_name': 'Current Lesson Name',
    'next_lesson_name': 'Next Lesson Name',
    'previous_lesson_name': 'Previous Lesson Name'
  };
  datacollectiontrainings: any = 'traing_api/traninguserpercent';
  public techdatesearchsourcecount: any = 0;

  public lineChartData: ChartDataSets[] = [
    { data: [0, 0, 0, 0, 0, 0, 0], label: 'Admin' },
    { data: [0, 0, 0, 0, 0, 0, 0], label: 'Distributer' },
    { data: [0, 0, 0, 0, 0, 0, 0], label: 'Sales Manager' },
    { data: [0, 0, 0, 0, 0, 0, 0], label: 'Tech Consultant' },
    { data: [0, 0, 0, 0, 0, 0, 0], label: 'Closer' },
    { data: [0, 0, 0, 0, 0, 0, 0], label: 'Contract Manager' },
  ];

  // lineChart month
  public lineChartDatamonth: ChartDataSets[] = [
    { data: [0, 0, 0, 0, 0, 0, 0], label: 'Admin' },
    { data: [0, 0, 0, 0, 0, 0, 0], label: 'Distributer' },
    { data: [0, 0, 0, 0, 0, 0, 0], label: 'Sales Manager' },
    { data: [0, 0, 0, 0, 0, 0, 0], label: 'Tech Consultant' },
    { data: [0, 0, 0, 0, 0, 0, 0], label: 'Closer' },
    { data: [0, 0, 0, 0, 0, 0, 0], label: 'Contract Manager' },
  ];

  // Training lineChartData
  public TraininglineChartData: ChartDataSets[] = [
    { data: [0, 0, 0, 0, 0, 0, 0], label: 'Advanced wellness solutions' },
    { data: [0, 0, 0, 0, 0, 0, 0], label: 'ApogeeINVENT' },
    { data: [0, 0, 0, 0, 0, 0, 0], label: 'Beto Paredes Training' },
    { data: [0, 0, 0, 0, 0, 0, 0], label: 'Med World One' },
    { data: [0, 0, 0, 0, 0, 0, 0], label: 'Universal Tech Associates' },
  ];

  public TraininglineChartDatamonth: ChartDataSets[] = [
    { data: [0, 0, 0, 0, 0, 0, 0], label: 'Advanced wellness solutions' },
    { data: [0, 0, 0, 0, 0, 0, 0], label: 'ApogeeINVENT' },
    { data: [0, 0, 0, 0, 0, 0, 0], label: 'Beto Paredes Training' },
    { data: [0, 0, 0, 0, 0, 0, 0], label: 'Med World One' },
    { data: [0, 0, 0, 0, 0, 0, 0], label: 'Universal Tech Associates' },
  ];
  public ContractlineChartData: Array<any> = [
    { data: [50, 59, 80, 81, 56, 55, 40], label: 'Contracts added' },
    { data: [28, 48, 40, 19, 86, 27, 60], label: 'Contracts Closed' },
    {
      data: [1, 18, 20, 25, 50, 28, 80],
      label: 'Time Taken to Complete Contract',
    },
  ];

  // Contract lineChartData month
  public ContractlineChartDatamonth: Array<any> = [
    { data: [50, 59, 80, 81, 56, 55, 40], label: 'Contracts added' },
    { data: [28, 48, 40, 19, 86, 27, 60], label: 'Contracts Closed' },
    {
      data: [1, 18, 20, 25, 50, 28, 80],
      label: 'Time Taken to Complete Contract',
    },
  ];

  // Appointment lineChartData
  public AppointmentlineChartData: Array<any> = [
    { data: [50, 59, 80, 81, 56, 55, 40], label: 'Appointments Booked' },
    { data: [28, 48, 40, 19, 86, 27, 60], label: 'Appointments Completed' },
  ];

  // Appointment lineChartData month
  public AppointmentChartDatamonth: Array<any> = [
    { data: [50, 59, 80, 81, 56, 55, 40], label: 'Appointments Booked' },
    { data: [28, 48, 40, 19, 86, 27, 60], label: 'Appointments Completed' },
  ];

  // User lineChart
  public UserlineChartData: ChartDataSets[] = [
    { data: [0, 0, 0, 0, 0, 0, 0], label: 'Admin' },
    { data: [0, 0, 0, 0, 0, 0, 0], label: 'Distributer' },
    { data: [0, 0, 0, 0, 0, 0, 0], label: 'Sales Manager' },
    { data: [0, 0, 0, 0, 0, 0, 0], label: 'Tech Consultant' },
    { data: [0, 0, 0, 0, 0, 0, 0], label: 'Closer' },
    { data: [0, 0, 0, 0, 0, 0, 0], label: 'Contract Manager' },
  ];

  // User lineChart month
  public UserChartDatamonth: ChartDataSets[] = [
    { data: [0, 0, 0, 0, 0, 0, 0], label: 'Admin' },
    { data: [0, 0, 0, 0, 0, 0, 0], label: 'Distributer' },
    { data: [0, 0, 0, 0, 0, 0, 0], label: 'Sales Manager' },
    { data: [0, 0, 0, 0, 0, 0, 0], label: 'Tech Consultant' },
    { data: [0, 0, 0, 0, 0, 0, 0], label: 'Closer' },
    { data: [0, 0, 0, 0, 0, 0, 0], label: 'Contract Manager' },
  ];

  // Leads lineChart
  public LeadslineChartData: Array<any> = [
    { data: [65, 59, 80, 81, 56, 55, 40], label: 'Leads added' },
    { data: [28, 48, 40, 19, 86, 27, 60], label: 'Leads Closed' },
    { data: [18, 48, 77, 9, 30, 27, 20], label: 'Leads Lost' },
  ];

  // Leads lineChart month
  public LeadsChartDatamonth: Array<any> = [
    { data: [65, 59, 80, 81, 56, 55, 40], label: 'Leads added' },
    { data: [28, 48, 40, 19, 86, 27, 60], label: 'Leads Closed' },
    { data: [18, 48, 77, 9, 30, 27, 20], label: 'Leads Lost' },
  ];

  public lineChartLabels: Array<any> = [
    'Sunday',
    'Monday',
    'Tuesday',
    'Wednesday',
    'Thursday',
    'Friday',
    'Saturday',
  ];

  public lineChartLabelsmonth: Array<any> = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ];

  public lineChartOptions: any = {
    responsive: true,
  };
  public lineChartColors: Array<any> = [
    {
      // grey
      backgroundColor: 'rgb(0, 255, 255,0.0)',
      borderColor: 'rgba(99,99,67,5)',
      pointBackgroundColor: 'rgba(99,99,67,5)',
      pointBorderColor: '#fff',
      pointHoverBackgroundColor: '#fff',
      pointHoverBorderColor: 'rgba(99,99,67,5)',
    },
    {
      // dark grey
      backgroundColor: 'rgb(0, 255, 255,0.0)',
      borderColor: 'rgba(55,49,74,31)',
      pointBackgroundColor: 'rgba(55,49,74,31)',
      pointBorderColor: '#fff',
      pointHoverBackgroundColor: '#fff',
      pointHoverBorderColor: 'rgba(55,49,74,31)',
    },
    {
      // grey
      backgroundColor: 'rgb(0, 255, 255,0.0)',
      borderColor: 'rgba(245,74,245,1)',
      pointBackgroundColor: 'rgba(245,74,245,1)',
      pointBorderColor: '#fff',
      pointHoverBackgroundColor: '#fff',
      pointHoverBorderColor: 'rgba(245,74,245,1)',
    },
    {
      // blue
      backgroundColor: 'rgb(0, 255, 255,0.0)',
      borderColor: 'rgb(148,159,177,1)',
      pointBackgroundColor: 'rgba(148,159,177,1)',
      pointBorderColor: '#fff',
      pointHoverBackgroundColor: '#fff',
      pointHoverBorderColor: 'rgb(148,159,177,1)',
    },
    {
      // grey
      backgroundColor: 'rgb(0, 255, 255,0.0)',
      borderColor: 'rgba(67,87,99,1)',
      pointBackgroundColor: 'rgba(67,87,99,1)',
      pointBorderColor: '#fff',
      pointHoverBackgroundColor: '#fff',
      pointHoverBorderColor: 'rgba(67,87,99,1)',
    },
    {
      // grey
      backgroundColor: 'rgb(0, 255, 255,0.0)',
      borderColor: 'rgba(34,133,215,1)',
      pointBackgroundColor: 'rgba(34,133,215,1)',
      pointBorderColor: '#fff',
      pointHoverBackgroundColor: '#fff',
      pointHoverBorderColor: 'rgba(34,133,215,1)',
    },
    {
      // blue
      backgroundColor: 'rgb(0, 255, 255,0.0)',
      borderColor: 'rgb(74,99,67,1)',
      pointBackgroundColor: 'rgba(74,99,67,1)',
      pointBorderColor: '#fff',
      pointHoverBackgroundColor: '#fff',
      pointHoverBorderColor: 'rgb(74,99,67,1)',
    },
  ];
  public lineChartLegend: boolean = true;
  public lineChartType: string = 'line';

  // events
  public chartClicked(e: any): void {
    //  // console.log(e);
  }

  public chartHovered(e: any): void {
    // // console.log(e);
  }

  public adminarray: any = [];

  public userlogingridFlag: boolean = false;

  public TraininggridFlag: boolean = false;

  public ContractgridFlag: boolean = false;

  public AppointmentgridFlag: boolean = false;

  public UsergridFlag: boolean = false;

  public LeadsgridFlag: boolean = false;

  public jobticketsFlag: boolean = false;
  public allTypeOfListnodatafound: boolean = false;
  public SignatureFlag: boolean = false;
  public technorecordfound: boolean = false;
  public WebinarsFlag: boolean = false;
  public distributornorecordfound: boolean = false;
  public ScheduledFlagScheduledFlag = false;
  public ScheduledFlagCommissionFlag = false;
  // public Scheduleddatatableflag = false;
  public activedistributorsFlag: boolean = false;
  public activetoggleactivesalesmanagersFlag: boolean = false;
  public activetoggleactiveconsultantsFlag: boolean = false;
  public allcontractnodatafound: boolean = false;
  public userid: any;
  public userFullName: any;
  public datesearchsourcecount: any = 0;
  public status: any = [];
  public isrefresh_distributor: boolean = false;
  public isrefresh_tech: boolean = false;
  public isrefresh_job_ticket: boolean = false;
  public distributorListdatatableflag: boolean = false;
  public isrefresh_commission: boolean = false;
  distributorListdatatable: any = [];
  public datacollectiontraining: any = 'api10/getalltrainingdata';
  public datacollectionlession: any = 'api10/getlessondata';
  public googleScheduleRoute: any =
    '/calendar-management/manage-appointment/game-plan/book-appointment/';
  public commission_data_details: any = [];
  public allflagblock: any = 'alllist';
  searchsettingsallTypeOfList: any = {
    // datesearch: [
    //   {
    //     startdatelabel: 'Joined On Start Date',
    //     enddatelabel: 'Joined On End Date',
    //     submit: 'Search',
    //     field: 'createdon_datetime',
    //     // value: {}
    //   },
    // ],
    // selectsearch: [
    //   { label: 'Search By Status', field: 'status', values: this.status, value: '' },
    //   { label: 'Search By Type', field: 'type', values: this.typeval, value: '' },
    // ],
    textsearch: [
      { label: 'Search By Name', field: 'fullname_s', value: '' },
      // { label: 'Search By Email', field: 'email_s', value: '' },
      // { label: 'Search By Location', field: 'location_s', value: '' },
    ],
    // search: [
    //   {
    //     label: 'Search By Name', field: 'fullname_s',
    //     values: '',
    //     serversearchdata: { endpoint: 'api/exitsinglistname' }
    //   },
    //   {
    //     label: 'Search By Email', field: 'email_s',
    //     values: '',
    //     serversearchdata: { endpoint: 'api/exitsinglistemail' }
    //   },
    // {
    //   label: 'Search By Location', field: 'location_s',
    //   values: '',
    //   serversearchdata: { endpoint: 'api/exitsinglistlocation' }
    // },
    // ],
  };

  public allTypeOfList: any = [];

  // Resources for table field skip
  public allTypeOfListSkip: any = [];

  public modifyheaderarrayallTypeOfList: any = {
    fullname: 'Full Name',
    email: 'Email',
    phone: 'Contact Number',
    status: 'Status',
    type_show: 'Type',
    lastlogin_datetime: 'Last Login',
    createdon_datetime: 'Joind On',
  };
  public allTypeOfListdetailskip: any = [
    '_id',
    'firstname',
    'lastname',
    'accesscode',
    'firstname_s',
    'fullname_s',
    'address',
    'state',
    'city',
    'zip',
    'location_s',
  ];

  // public searchsettingsallTypeOfList: any = {
  //   textsearch: [
  //     { label: 'Search By Email', field: 'email_s' }
  //   ], // this is use for  Autocomplete search
  // };
  public sortDataAllTypeOfList: any = {
    type: 'desc',
    field: 'fullname',
    options: ['fullname', 'email', 'type_show', 'createdon_datetime', 'lastlogin_datetime', 'status'],
  };
  public sortDataTrainingList: any = {
    type: 'desc',
    field: 'status',
    options: ['parent_catagory', 'catagory_name', 'product_name', 'priority', 'type', 'status'],
  };
  public sortDataLessionList: any = {
    type: 'desc',
    field: 'status',
    options: ['associated_training', 'lession_title', 'prerequisite_lession', 'test_percentage', 'priority', 'status'],
  };
  public allcontractlist: any = {
    type: 'desc',
    field: 'createdon_datetime',
    options: [
      'leadfullname',
      'userfullname',
      'createdon_datetime',
      'currentstatus'
    ],
  };
  public allcalenderlist: any = {
    type: 'desc',
    field: 'start_datetime_unix',
    options: ['attende_name', 'event_title', 'event_type', 'start_datetime_unix', 'slot', 'timespanmins', 'product_name'],
  };

  public datacollectionallTypeOfList: any = 'api/resourcelistview';
  public allcontractdatacollectionlist: any = 'api/contractrequestslistviews';
  public allcalenderdatacollectionlist: any = '';
  public allTypeOfListLibData: any = {
    basecondition: { type: { $in: this.usertype } },
    detailview_override: [
      { key: 'createdon_datetime', val: 'Joined On' },
      { key: 'type', val: 'Type' },
      { key: 'fullname', val: 'Full Name' },
      // { key: 'phone', val: 'Contact Number' },
      { key: 'fulladdress', val: 'Full Address' },
      { key: 'first_login', val: 'First Login' },
      { key: 'lastlogincity', val: 'Last Login City' },
      { key: 'lastloginregion', val: 'Last Login Region' },
      { key: 'zip_s', val: 'Zip Code' },
      // { key: 'logincounts', val: 'Login Counts' },
      { key: 'zip_s', val: 'Zip Code' },
    ],
    // table headline
    tableheaders: [
      'fullname',
      'email',
      // 'phone',
      'type_show',
      'createdon_datetime',
      'lastlogin_datetime',
      'status',
    ],
    // hidestatustogglebutton: true,
    hidedeletebutton: true,
    updateendpointmany: 'api/userupdate', // update multiple user
    updateendpoint: 'api/userstatusupdate', // update status
    deleteendpointmany: 'api/deleteuser', // delete multiple user
    hidemultipleselectbutton: true, // hide multiple selection button (false)
    hidecounter: null, // hide counter (false)
    hideaction: false, // hide action (false)
    searchBarFlagVal: true,
    hideviewbutton: true, // (hide view button)
    hideeditbutton: true,
    custombuttons: [
      {
        label: 'Log Me',
        type: 'listner',
        id: 'a2',
        tooltip: 'Log Me ',
        refreshdata: true,
        classname: 'LogmeBTN',
        cond: 'custombuttonflag',
        condval: 0
      },
      {
        label: 'Edit',
        type: 'listner',
        id: 'edituser',
        tooltip: 'Edit ',
        refreshdata: true,
        classname: 'editlistencls',
      },
      {
        label: 'Folder View',
        route: 'user-details/',
        type: 'internallink',
        param: ['type', '_id'],
        tooltip: 'Folder View',
        classname: 'FolderView',
        cond: 'type',
        condval: 'technological-consultant',
      },
    ],
  };
  public productLibData: any = {
    basecondition: { type: 'admin' },
    detailview_override: [
      { key: 'createdon_datetime', val: 'Joined On' },
      { key: 'type', val: 'Type' },
      { key: 'fullname', val: 'Full Name' },
      { key: 'phone', val: 'Contact Number' },
      { key: 'fulladdress', val: 'Full Address' },
      { key: 'first_login', val: 'First Login' },
      { key: 'lastlogincity', val: 'Last Login City' },
      { key: 'lastloginregion', val: 'Last Login Region' },
      { key: 'zip_s', val: 'Zip Code' },
      // { key: 'logincounts', val: 'Login Counts' },
      { key: 'zip_s', val: 'Zip Code' },
    ],
    // table headline
    tableheaders: ['productname', 'launched', 'launcheddate', 'status'],
    // hidestatustogglebutton: true,
    hidedeletebutton: true,
    updateendpointmany: 'api/userupdate', // update multiple user
    updateendpoint: 'api/userstatusupdate', // update status
    deleteendpointmany: 'api/deleteuser', // delete multiple user
    hidemultipleselectbutton: true, // hide multiple selection button (false)
    hidecounter: null, // hide counter (false)
    hideaction: true, // hide action (false)
    hideviewbutton: true, // (hide view button)
  };

  // send basic limit data
  public allTypeOfListLimitCond: any = {
    limit: 10,
    skip: 0,
    pagecount: 1,
  };
  public productListLimitCond: any = {
    limit: 10,
    skip: 0,
    pagecount: 1,
  };
  public resourceListLimitCond: any = {
    limit: 10,
    skip: 0,
    pagecount: 1,
  };
  public lessonListLimitCond: any = {
    limit: 10,
    skip: 0,
    pagecount: 1,
  };
  public allcontractlistlimit: any = {
    limit: 10,
    skip: 0,
    pagecount: 1,
  };
  public categoryListLimitCond: any = {
    limit: 10,
    skip: 0,
    pagecount: 1,
  };
  datesearchAllTypeOfListCount: any = 0;
  public allcontractsearchalllistcount: any = 0;
  public allcalendersearchalllistcount: any = 0;
  // send basic sort data

  // use for status search
  AllTypeOfListStatusArray: any = [
    { val: 1, name: 'Active' },
    { val: 0, name: 'Inactive' },
  ];

  // editrouting
  AllTypeOfListEditRoute: any = 'admin/edit/';

  salesmanagerListdatatable: any = [];
  public salesListdatatableflag: boolean = false;

  techconsultantsListdatatable: any = [];
  public techconsultantsListdatatableflag: boolean = false;

  jobcategoryListdatatable: any = [];

  signatureListdatatable: any = [];

  public jobticketsListdatatableflag: boolean = false;

  public signatureListdatatableflag: boolean = false;

  public ScheduledFlag: boolean = false;
  public CommissionFlag: boolean = false;

  public NewlyAddedFlag: boolean = false;

  public ProductsFlag: boolean = false;
  public isrefresh_contract_pending_signature: boolean = false;
  public ResourcesFlag: boolean = false;
  public commissionnorecordfound: boolean = false;
  public contract_pending_signaturenorecordfound: boolean = false;
  productlisttable: any = [];
  public productStatus: any = [
    { val: 1, name: 'Active' },
    { val: 0, name: 'Inactive' },
  ];
  public resourceStatus: any = [
    { val: 1, name: 'Active' },
    { val: 0, name: 'Inactive' },
  ];
  public TraningStatus: any = [
    { val: 1, name: 'Active' },
    { val: 0, name: 'Inactive' },
  ];
  public allcontractstatus: any = [
    { val: 1, name: 'Active' },
    { val: 0, name: 'Inactive' },
  ];
  public allcalenderstatus: any = [
    { val: 0, name: 'Pending' },
    { val: 1, name: 'Done' },
    { val: 2, name: 'Canceled' }
  ];
  public lessonStatus: any = [
    { val: 1, name: 'Active' },
    { val: 0, name: 'Inactive' },
  ];
  public Productsdetatableflag: boolean = false;

  resourcelisttable: any = [];
  resourcelisttablelibdata: any = {
    // basecondition: { type: 'distributor' },
    // detailview_override: [
    //   { key: 'fullname', val: 'Full Name' },
    //   { key: 'email', val: 'Email' }
    // ],
    tableheaders: [
      'resourcecategorsname',
      'productname',
      'resourcecategorsname',
      'description',
      'priority',
      'status',
    ],
    hidemultipleselectbutton: true,
    hideaction: true,
    hideeditbutton: true, // all these button options are optional not mandatory
    hidedeletebutton: true,
    hideviewbutton: false,
    hidestatustogglebutton: true,
    searchBarFlagVal: false,
  };
  getalltrainingdatalibdata: any = {
    basecondition: { 'is_trash': { $ne: 1 } },
    // detailview_override: [
    //   { key: 'fullname', val: 'Full Name' },
    //   { key: 'email', val: 'Email' }
    // ],
    tableheaders: [
      'parent_catagory',
      'catagory_name',
      'priority',
      'product_name',
      'type',
      'status',
    ],
    hidemultipleselectbutton: true,
    hideaction: true,
    hideeditbutton: true, // all these button options are optional not mandatory
    hidedeletebutton: true,
    hideviewbutton: false,
    hidestatustogglebutton: true,
    searchBarFlagVal: false,
  };
  getalllessondatalibdata: any = {
    basecondition: { 'is_trash': { $ne: 1 } },
    // detailview_override: [
    //   { key: 'fullname', val: 'Full Name' },
    //   { key: 'email', val: 'Email' }
    // ],
    tableheaders: [
      'associated_training',
      'lession_title',
      // 'description',
      'prerequisite_lession',
      // 'test_percentage',
      'priority',
      'status',
    ],
    hidemultipleselectbutton: true,
    hideaction: true,
    hideeditbutton: true, // all these button options are optional not mandatory
    hidedeletebutton: true,
    hideviewbutton: false,
    hidestatustogglebutton: true,
    searchBarFlagVal: false,
  };
  allcontractlibdata: any = {
    basecondition: { type: 'admin', currentstatus: { $in: this.contracts } },
    // detailview_override: [
    //   { key: 'fullname', val: 'Full Name' },
    //   { key: 'email', val: 'Email' }
    // ],
    tableheaders: [
      'leadfullname',
      'productname',
      'userfullname',
      'createdon_datetime',
      'currentstatus'
    ],

    hidemultipleselectbutton: true,
    hideaction: true,
    hideeditbutton: true, // all these button options are optional not mandatory
    hidedeletebutton: true,
    hideviewbutton: true,
    hidestatustogglebutton: true,
  };
  allcalenderdatadetailsskip: any = [
    '_id',
    'slotTimeActual',
    'notes',
    'eid',
    'start_datetime_unix',
    'event_type_val',
    'googleevent',
    'event_title_s',
    'eventid',
    'is_google_event',
    'lesson_id',
    'id',
    'attende_email_s',
    'attende_name_s',
    'status',
    'user_id',
    'username_s',
    'product_name',
    'product_name_s',
    'product_id',
    'slot_end_time',
    'training_id',
    'parent_id',
    '_id',
    'slotTimeActual',
    'notes',
    'eid',
    'start_datetime_unix',
    'event_type_val',
    'googleevent',
    'event_title_s',
    'eventid',
    'is_google_event',
    'lesson_id',
    'id',
    'attende_email_s',
    'attende_name_s',
    'status',
    'user_id',
    'username_s',
    'product_name',
    'product_name_s',
    'product_id',
    'slot_end_time',
    'training_id',
    'parent_id',
    'flag_for_show',
    'bookinguser',
    'event_type_val_s',
    'appoinment_date_timezone',
    'timespan',
    'endDateTime'
  ];
  allcalenderlibdata: any = {
    basecondition: {},
    detailview_override: [
      { key: 'event_title', val: 'Event Title' },
      { key: 'event_type', val: 'Event Type' },
      { key: 'description', val: 'Description' },
      { key: 'username', val: 'Organiser Name' },
      { key: 'useremail', val: 'Organiser Email' },
      { key: 'notes', val: 'Notes' },
      { key: 'booking_date', val: 'Booked On' },
      { key: 'startdate', val: 'Appointment Date' },
      { key: 'slot', val: 'Slot' },
      { key: 'timespanmins', val: 'Time span' },
      { key: 'timezone', val: 'Time zone' },
      { key: 'product_name', val: 'Product Name' },
      { key: 'attende_name', val: 'Attendee Name' },
      { key: 'attende_email', val: 'Attendee Email' },
      { key: 'attende_type', val: 'Attendee Type' },
      { key: 'appoinment_date_timezone', val: 'Appoinment Date Timezone' },
      { key: 'Appointmentedstatus', val: 'Appointment Status' },
    ],
    tableheaders: ['attende_name', 'event_title', 'event_type', 'product_name', 'start_datetime_unix', 'slot', 'timespanmins', 'status'],
    updateendpoint: 'statusupdate',
    hidestatustogglebutton: true,
    hidedeletebutton: true,
    hideeditbutton: true,
    hidedeletemany: true,
    hideupdatemany: true,
    hidemultipleselectbutton: true,
  };
  public Resourcesdetatableflag: boolean = false;

  // use for status search

  // editrouting
  editroute: any = 'admin/distributor/edit/';

  // use for table field skip
  distributorListdatatableskip: any = [];

  // distributorListdatatableskipalllib1: any = [];

  // use for Table Detail Field Skip
  distributorListdatatabledetailskip: any = [];

  // Sales Managers for table field skip
  salesmanagerListdatatableskip: any = [];

  // Sales Managers for Table Detail Field Skip
  salesmanagerListdatatabledetailskip: any = [];

  // Sales Managers for table field skip
  techconsultantsListdatatableskip: any = [];

  // Sales Managers for Table Detail Field Skip
  techconsultantsListdatatabledetailskip: any = [];

  // jobtickets for table field skip
  jobcategoryListdatatablekip: any = [];

  // jobtickets for Table Detail Field Skip
  jobcategoryListdatatabledetailskip: any = [];

  // Contracts Pending Signature for table field skip
  signatureListdatatableskip: any = [];

  // Contracts Pending Signature for Table Detail Field Skip
  signatureListdatatabledetailskip: any = [];

  // productlist for table field skip
  productlisttableskip: any = [];

  // productlist for Table Detail Field Skip
  productlisttabledetailskip: any = [];

  // Resources for table field skip
  resourcelisttableskip: any = [];
  getalltrainingdataskip: any = [];
  getlessondatalistskip: any = [];
  allcontractdataskip: any = [];
  allcalenderdataskip: any = [];
  // Resources for Table Detail Field Skip
  resourcelisttabledetailskip: any = [];
  getalltrainingdatadetailskip: any = [];

  getalllessondatadetailskip: any = [];
  allcontractdatadetailsskip: any = [];
  // allcalenderdatadetailsskip:any = [];
  // send basic limit data
  limitcond: any = {
    limit: 7,
    skip: 0,
  };

  // modify header(key : modify header name)
  modifyheaderarray: any = {
    fullname: 'Name',
    email: 'Email',
    createdon_datetime: 'Joind On',
    phone: 'Phone',
    lastlogin_datetime: 'Last Login'
  };

  // other data
  libdata: any = {
    basecondition: { type: 'distributor' },
    detailview_override: [
      { key: 'fullname', val: 'Full Name' },
      { key: 'email', val: 'Email' },
      { key: 'lastlogin_datetime', val: 'Last Login' },
    ],
    tableheaders: [
      'fullname',
      'email',
      // 'logincounts',
      'lastlogin_datetime',
    ],

    hidemultipleselectbutton: true,
    hideaction: false,
    hideeditbutton: true, // all these button options are optional not mandatory
    hidedeletebutton: true,
    hideviewbutton: true,
    hidestatustogglebutton: true,
    custombuttons: [
      {
        label: 'Folder View ',
        route: 'user-details/',
        type: 'internallink',
        tooltip: 'Folder View',
        classname: 'Folder_view',
        param: ['type', '_id'],
      },
      {
        label: 'Open Folder View New Page',
        type: 'listner',
        id: 'i1',
        classname: 'OpenFolderView',
        tooltip: 'Open Folder View New Page  ',
      },
    ],
  };
  datesearchsourcecountresourcelist: any = 0;
  // send basic sort data
  sortdata: any = {
    type: 'desc',
    field: 'lastlogin_datetime',
    options: [
      'fullname',
      'email',
      'lastlogin_datetime',
    ],
  };

  datacollection: any = 'api/userlistview';

  searchsettings: any = {
    textsearch: [{ label: 'Search By Email', field: 'email_s' }], // this is use for  Autocomplete search
  };

  schedule_searchsettings: any = {
    textsearch: [
      // { label: 'Search By Email', field: 'email_s' }
    ], // this is use for  Autocomplete search
  };

  commission_searchsettings: any = {
    textsearch: [
      // { label: 'Search By Email', field: 'email_s' }
    ], // this is use for  Autocomplete search
  };

  leadList_searchsettings: any = {
    textsearch: [
      // { label: 'Search By Email', field: 'email_s' }
    ], // this is use for  Autocomplete search
  };

  limitcondsales: any = {
    limit: 7,
    skip: 0,
  };

  // other data
  libdatasales: any = {
    basecondition: { type: 'distributor' },
    detailview_override: [
      { key: 'fullname', val: 'Full Name' },
      { key: 'email', val: 'Email' },
    ],
    tableheaders: ['fullname', 'email', 'logincounts', 'children'],
    hidemultipleselectbutton: true,
    hideaction: true,
    hideeditbutton: true, // all these button options are optional not mandatory
    hidedeletebutton: true,
    hideviewbutton: false,
    hidestatustogglebutton: true,
  };

  public modifyheaderarraysalesmanager: any = {
    fullname: 'Full Name',
    email: 'Email',
    // logincounts: 'Login count',
    children: 'Recruit count',
  };
  // send basic sort data
  sortdatasales: any = {
    type: 'desc',
    field: '_id',
    options: [],
  };

  datacollectionsales: any = 'api/userlistview';

  limitcondtechconsultants: any = {
    limit: 7,
    skip: 0,
  };

  public modifyheaderarraytechconsultants: any = {
    fullname: 'Name',
    email: 'Email',
    // logincounts: 'Login count',
    children: 'Recruit count',
    lastlogin_datetime: 'Last Login',
  };

  // other data
  libdatatechconsultants: any = {
    basecondition: { type: 'technological-consultant' },
    detailview_override: [
      { key: 'fullname', val: 'Full Name' },
      { key: 'email', val: 'Email' },
    ],
    tableheaders: [
      'fullname',
      'email',
      // 'logincounts',
      'lastlogin_datetime',
    ],

    hidemultipleselectbutton: true,
    hideaction: false,
    hideeditbutton: true, // all these button options are optional not mandatory
    hidedeletebutton: true,
    hideviewbutton: true,
    hidestatustogglebutton: true,
    custombuttons: [
      {
        label: 'Folder View ',
        route: 'user-details/',
        type: 'internallink',
        tooltip: 'Folder View',
        classname: 'Folder_view',
        param: ['type', '_id'],
      },
      {
        label: 'Open Folder View New Page',
        type: 'listner',
        id: 'i1',
        classname: 'OpenFolderView',
        tooltip: 'Open Folder View New Page  ',
      },
    ],
  };

  // send basic sort data
  sortdatatechconsultants: any = {
    type: 'desc',
    field: 'lastlogin_datetime',
    options: [
      'fullname',
      'email',
      'lastlogin_datetime',
    ],
  };

  datacollectionconsultants: any = 'api/userlistview';

  searchsettingssales: any = {
    textsearch: [{ label: 'Search By Email', field: 'email_s' }], // this is use for  Autocomplete search
  };

  // send basic limit data
  limitcondjobtickets: any = {
    limit: 7,
    skip: 0,
  };

  // other data
  libdatajobtickets: any = {
    // basecondition: { type: 'distributor' },
    detailview_override: [
      { key: 'ticketid', val: 'Ticket Id' },
      { key: 'user_name', val: 'Reported By' },
      { key: 'ticket_status', val: 'Ticket Status' },
      { key: 'createdon_datetime', val: 'Issue date' },
      { key: 'ticket_category_name', val: 'Issue Category' },
      { key: 'reply_datetime', val: 'Last Activity' },
    ],
    tableheaders: [
      'ticketid',
      'user_name',
      'ticket_status',
      // 'createdon_datetime',
      'ticket_category_name',
      // 'reply_datetime',
    ],

    hidemultipleselectbutton: true,
    hideaction: false,
    hideeditbutton: true, // all these button options are optional not mandatory
    hidedeletebutton: true,
    hideviewbutton: true,
    hidestatustogglebutton: true,
    custombuttons: [
      {
        label: 'View Job Ticket',
        route: 'jobticket/view-managejobticket/',
        type: 'internallink',
        tooltip: 'View Job Ticket',
        classname: 'view_jobticketinBTN',
        param: ['_id', 'user_id'],
      },
      {
        label: 'Add Job Ticket',
        route: 'jobticket/add',
        type: 'internallink',
        tooltip: 'Add Job Ticket',
        classname: 'add_jobticketinBTN',
      },
    ],
  };

  // modify header(key : modify header name)
  public modifyheaderarrayjob: any = {
    user_name: 'Reported By',
    createdon_datetime: 'Issue Date',
    ticket_category_name: 'Issue Category',
    reply_datetime: 'Last Activity',
    ticket_status: 'Ticket Status',
    ticketid: 'Ticket ID',
  };

  // send basic sort data
  sortdatajobtickets: any = {
    type: 'desc',
    field: 'user_name',
    options: [
      'ticketid',
      'ticket_status',
      'user_name',
      'ticket_category_name'
    ],
  };

  datacollectionjobtickets: any = 'api/getjobticketlistdata';

  searchsettingsjobtickets: any = {
    textsearch: [{ label: 'Search By Email', field: 'email_s' }], // this is use for  Autocomplete search
  };

  //------*//

  // send basic limit data
  signaturelimitcond: any = {
    limit: 5,
    skip: 0,
  };

  // other data
  signaturelibdata: any = {
    basecondition: { 'currentstatus': 'Send To Lead', leadfullname: { $ne: null } },
    detailview_override: [
      { key: 'leadfullname', val: 'Full Name' },
      { key: 'email', val: 'Email' },
      { key: 'phone', val: 'Contact Number' },
      { key: 'productname', val: 'Product Name' },
      { key: 'status', val: 'Contract Status' },
      { key: 'createdon_datetime', val: 'Joind On' },
    ],
    tableheaders: [
      'leadfullname',
      'leademail',
      'userfullname',
      'contractfullname',
      'productname',
    ],
    // custombuttons: [
    //   {
    //     label: 'View Contract',
    //     route: '/contract-view/',
    //     type: 'internallink',
    //     tooltip: ''View Job Ticket',
    //     classname: 'view_jobticketinBTN',
    //     param: ['_id'],
    //   },
    // ],
    hidemultipleselectbutton: true,
    hideaction: true,
    hideeditbutton: true, // all these button options are optional not mandatory
    hidedeletebutton: true,
    hideviewbutton: true,
    hidestatustogglebutton: true,
  };

  // modify header(key : modify header name)
  public modifyheaderarraysignature: any = {
    leadfullname: 'Lead Name',
    leademail: 'Lead Email',
    userfullname: 'Tech Name',
    contractfullname: 'Contract Manager Name',
    productname: 'Product Name',
    createdon_datetime: 'Joind On',
  };

  // send basic sort data
  signaturesortdata: any = {
    type: 'desc',
    field: 'productname',
    options: [
      'leadfullname',
      'leademail',
      'userfullname',
      'contractfullname',
      'productname',
    ],
  };

  signaturedatacollection: any = 'api/contractrequestslistviews';

  signaturesearchsettings: any = {
    textsearch: [{ label: 'Search By Email', field: 'email_s' }], // this is use for  Autocomplete search
  };
  //----*//

  // send basic limit data
  limitcondproductlis: any = {
    limit: 10,
    skip: 0,
  };

  // other data
  libdataproductlis: any = {
    basecondition: { type: 'distributor' },
    detailview_override: [
      { key: 'productname', val: 'Product Name' },
      { key: 'launched', val: 'Launched' },
      { key: 'launcheddate', val: 'Launched Date' },
    ],
    tableheaders: ['productname', 'launched', 'launcheddate'],
    hidemultipleselectbutton: true,
    hideaction: true,
    hideeditbutton: true, // all these button options are optional not mandatory
    hidedeletebutton: true,
    hideviewbutton: false,
    hidestatustogglebutton: true,
  };

  // modify header(key : modify header name)
  public modifyheaderarrayproduct: any = {
    productname: 'Product Name',
    launched: 'Launched',
    launcheddate: 'Launched Date',
    status: 'Status',
  };
  public modifyheaderarrayresource: any = {};

  // send basic sort data
  sortdataproductlist: any = {
    type: 'desc',
    field: 'fullname',
    options: ['fullname', 'email', 'createdon_datetime'],
  };

  datacollectionproductlist: any = 'api/productlistview';

  searchsettingsproductlist: any = {
    textsearch: [{ label: 'Search By Email', field: 'email_s' }], // this is use for  Autocomplete search
  };

  // send basic limit data
  resourcelistlimitcond: any = {
    limit: 10,
    skip: 0,
  };

  // other data
  resourcelistlibdata: any = {
    basecondition: { type: 'distributor' },
    detailview_override: [
      { key: 'productname', val: 'Product Name' },
      { key: 'categoryname', val: 'Resource Category Name' },
      { key: 'resourcename', val: 'Resource Name' },
    ],
    tableheaders: ['resourcename', 'productname', 'resourcecategorsname'],
    hidemultipleselectbutton: true,
    hideaction: true,
    hideeditbutton: true, // all these button options are optional not mandatory
    hidedeletebutton: true,
    hideviewbutton: true,
    hidestatustogglebutton: true,
  };

  // modify header(key : modify header name)
  public modifyheaderarrayresorce: any = {
    resourcename: 'Resource Name',
    productname: 'Product Name',
    resourcecategorsname: 'Resource Name',
    description: 'Description',
    priority: 'Priority',
    status: 'Status',
  };
  // send basic sort data
  sortdataresourcelist: any = {
    type: 'desc',
    field: 'createdon_datetime',
    options: ['resourcename', 'productname', 'resourcecategorsname'],
  };
  public getallmodifyheader: any = {
    parent_catagory: 'Parent Category',
    product_name: 'Product Name',
    catagory_name: 'Catagory Name',
    description: 'Description',
    type: 'Type',
    priority: 'Priority',
    status: 'Status',
  };

  public getlessonmodifyheader: any = {
    associated_training: ' Associated Training ',
    lession_title: 'Lesson Name',
    resourcecategorsname: 'Resource Name',
    description: 'Description',
    prerequisite_lession: 'Prerequisite Lesson',
    test_percentage: 'Parcentage',
    priority: 'Priority',
    status: 'Status',
  };
  public allcontractmodifyheaderr: any = {
    leadfullname: 'Lead Name',
    productname: 'Product Name',
    userfullname: 'Tech Name',
    status: 'Status',
    lastlogin_datetime: 'Last Login',
    createdon_datetime: 'Joind On',
    currentstatus: 'Status'
  };
  public allcalendermodifyheader: any = {
    event_title: 'Event Title',
    event_type: 'Event Type',
    product_name: 'Product Name',
    username: ' Organiser Name',
    attende_name: 'Attendees Name',
    attende_email: 'Attendees Email',
    attende_type: 'Attendees Type',
    training_category_name: 'Training Category Name',
    mentee_name: 'Mentee Name',
    mentee_email: 'Mentee Email',
    lesson_title: 'Lesson Title',
    startdate: 'Appointment Date',
    appointment_timing: 'Appointment Timing',
    timezone: 'Timezone',
    booking_date: 'Booked On',
    status: 'Status',
    is_google_event: 'Calendar Event',
    start_datetime_unix: 'Appoinment Date',
    slot: 'Slot',
    timespanmins: 'Time Span',
  };
  datacollectionresourcelist: any = 'api/resourcelistview';

  searchsettingsresourcelist: any = {
    textsearch: [{ label: 'Search By Email', field: 'email_s' }], // this is use for  Autocomplete search
  };

  public getallsharelinksdata: any;
  public dashboardlistarray: any;
  public alertmsg: any = 0;
  public scheduleappointment: any;
  public commissionManagement: any = [];
  public traininggraph: any;
  // scheduleappointment
  public modifyheaderarray_schedule: any = {
    event_title: 'Appointment Title',
    start_datetime_unix: 'Appointment Date',
    timespan: 'Appointment Duration',
    product_name: 'Concerning Product',
    username: 'Organizer Name',
    useremail: 'Organizer email',
    status: 'Status'
  };
  public modifyheaderarray_commission: any = {
    'userfullname': 'Distributor Name',
    'productsname': 'Products',
    'eventcommissiondata': 'Event Commission',
    'salescommissiondata': 'Sales Commission',
    'createdon_datetime': 'Added Time and Date',
  };
  public schedule_datatableskip: any = [

  ];
  public commission_datatableskip: any = ['_id', 'tech_id', 'tech_id_s'];
  public schedule_datatabledetailskip: any = [
    '_id',
    'slotTimeActual',
    'notes',
    'eid',
    'start_datetime_unix',
    'event_type_val',
    'googleevent',
    'event_title_s',
    'eventid',
    'is_google_event',
    'lesson_id',
    'id',
    'attende_email_s',
    'attende_name_s',
    'status',
    'user_id',
    'username_s',
    'product_name',
    'product_name_s',
    'product_id',
    'slot_end_time',
    'training_id',
    'parent_id',
    'flag_for_show',
    'bookinguser',
    'event_type_val_s',
    'appoinment_date_timezone',
    'timespan',
    'endDateTime'
  ];
  public commission_datatabledetailskip: any = [];
  public sortdata_schedule: any = {
    type: 'desc',
    field: 'booking_date',
    options: ['booking_date', 'event_title', 'start_datetime_unix', 'username'],
  };

  public sortdata_commission: any = {
    type: 'desc',
    field: 'createdon_datetime',
    options: ['createdon_datetime', 'productsname', 'userfullname'],
  };
  // send basic limit data
  public limitcond_schedule: any = {
    limit: 5,
    skip: 0,
  };
  public limitcond_commission: any = {
    limit: 5,
    skip: 0,
  };
  statusarrayschedule: any = [
    { val: 0, name: 'Pending' },
    { val: 1, name: 'Done' },
    { val: 2, name: 'Canceled' },
  ];
  public libdata_schedule: any = {
    basecondition: {},
    detailview_override: [
      { key: 'event_title', val: 'Event Title' },
      { key: 'event_type', val: 'Event Type' },
      { key: 'description', val: 'Description' },
      { key: 'username', val: 'Organiser Name' },
      { key: 'useremail', val: 'Organiser Email' },
      { key: 'notes', val: 'Notes' },
      { key: 'booking_date', val: 'Booked On' },
      { key: 'startdate', val: 'Appointment Date' },
      { key: 'slot', val: 'Slot' },
      { key: 'timespanmins', val: 'Time span' },
      { key: 'timezone', val: 'Time zone' },
      { key: 'product_name', val: 'Product Name' },
      { key: 'attende_name', val: 'Attendee Name' },
      { key: 'attende_email', val: 'Attendee Email' },
      { key: 'attende_type', val: 'Attendee Type' },
      { key: 'appoinment_date_timezone', val: 'Appoinment Date Timezone' },
      { key: 'Appointmentedstatus', val: 'Appointment Status' },
    ],
    // table headline
    tableheaders: ['event_title', 'start_datetime_unix', 'username', 'status'],
    custombuttons: [
      // {
      //   label: 'View Appointment',
      //   route: '/contract-manager/contract/view/',
      //   type: 'internallink',
      //   tooltip: ''View Schedule Appointment',
      //   classname: 'view_jobticketinBTN',
      //   param: ['_id'],
      // },

      {
        label: 'Cancel',
        type: 'listner',
        id: 'btn_cancel',
        cond: 'flag_for_show',
        condval: this.userservice.userid + 'false',
        // refreshdata: true,
        classname: 'cancelBTN',
        tooltip: 'Cancel',
      },
      {
        label: 'Reschedule',
        route:
          '/calendar-management/manage-appointment/reschedule-appointments/',
        type: 'listner',
        cond: 'flag_for_show',
        condval: this.userservice.userid + 'false',
        param: ['_id', 'event_type_val'],
        classname: 'rescheduleBTN',
        tooltip: 'Reschedule',
      },
    ],
    // hidestatustogglebutton: true,
    hidedeletebutton: true,
    updateendpointmany: '', // update multiple user
    updateendpoint: '', // update status
    deleteendpointmany: '', // delete multiple user
    hidemultipleselectbutton: true, // hide multiple selection button (false)
    hidecounter: null, // hide counter (false)
    hideaction: false, // hide action (false)
    hideviewbutton: false, // (hide view button)
    hideeditbutton: true, // all these button options are optional not mandatory
    hidestatustogglebutton: true,
  };

  public libdata_commission: any = {
    basecondition: { addedfortype: 'distributor' },
    detailview_override: [
      { key: 'userfullname', val: 'User Name' },
      { key: 'productsname', val: 'Products' },
      { key: 'eventcommissiondata', val: 'Event Commission' },
      { key: 'salescommissiondata', val: 'Sales Commission' },
      { key: 'createdon_datetime', val: 'Added Time and Date' },
    ],
    // table headline
    tableheaders: [
      'userfullname',
      'productsname',
      'eventcommissiondata',
      'salescommissiondata',
      'createdon_datetime',
      //      'username',
    ],
    custombuttons: [
      // {
      //   label: 'View Appointment',
      //   route: '/contract-manager/contract/view/',
      //   type: 'internallink',
      //   tooltip: ''View Job Ticket',
      //   classname: 'view_jobticketinBTN',
      //   param: ['_id'],
      // },

      // {
      //   label: 'Cancel',
      //   type: 'listner',
      //   id: 'btn_cancel',
      //   cond: 'status', condval: 0,
      //   // refreshdata: true,
      //   classname: 'cancelBTN',
      //   tooltip: 'Cancel',
      // },
      // {
      //   label: 'Reschedule',
      //   route:
      //     '/calendar-management/manage-appointment/reschedule-appointments/',
      //   type: 'internallink',
      //   cond: 'status',
      //   condval: 0,
      //   param: ['_id', 'event_type_val'],
      //   classname: 'rescheduleBTN',
      //   tooltip: 'Reschedule',
      // },
    ],
    // hidestatustogglebutton: true,
    hidedeletebutton: true,
    updateendpointmany: '', // update multiple user
    updateendpoint: '', // update status
    deleteendpointmany: '', // delete multiple user
    hidemultipleselectbutton: true, // hide multiple selection button (false)
    hidecounter: null, // hide counter (false)
    hideaction: true, // hide action (false)
    hideviewbutton: true, // (hide view button)
    hideeditbutton: true, // all these button options are optional not mandatory
    hidestatustogglebutton: true,
  };
  value = 50;
  public dashboardData: any;
  public scheduled_appointments: any;
  schedule_datesearchsourcecount: any = 0;
  commission_datesearchsourcecount: any = 0;
  datacollection_schedule = 'api/listmanagecommission';
  scheduledatacollection = 'list-upcoming-booked-events';
  schedule_datatable: any = [];
  commission_datatable: any = [];
  public leadListdatatable: any = [];
  // use for table field skip
  leadListdatatableskip: any = [
    '_id',
    'type',
    'password',
    'created_at',
    'type',
    'city',
    'gallery_image',
    'accesscode',
    '__v',
    'notes',
  ];
  statusarray: any = [
    { val: 1, name: 'Active' },
    { val: 0, name: 'Inactive' },
  ];
  // use for Table Detail Field Skip
  leadListdatatabledetailskip: any = [
    '_id',
    'email_s',
    'name',
    'blogtitle',
    'type',
    'city',
    '__v',
    'fullname',
    'notes',
    'accesscode',
    'location_s',
    'address_search',
    'fullname_s',
    'zip_s',
    'createdon_datetime',
    'fulladdress',
    'notescount',
    'login_data',
    'lastlogin_datetime',
    'contactInFo',
    'related_info',
    'firstname_s',
    'status',
    'parentid',
    'products',
    'contactInfo',
    'salesrep_f_name',
    'salesrep_l_name',
    'Salesrep_phone',
    'lastlogin_datetime',
    'parentid_s',
    'login_details',
  ];
  libdatalead: any = {
    basecondition: { type: 'lead' },
    detailview_override: [],
    tableheaders: [
      'fullname',
      'email',
      'phone',
      'createdon_datetime',
      'status',
    ],
    // searchBarFlagVal:true,
    // tableheaders name [key]
    // optional
    hidedeletebutton: true,
    updateendpointmany: '',
    updateendpoint: '',
    deleteendpointmany: '',
    hidemultipleselectbutton: true,
    hidecounter: null,
    hideaction: false,
    hideviewbutton: true, // (hide view button)
    hideeditbutton: true, // all these button options are optional not mandatory
    hidestatustogglebutton: true,
    // actioncolname: 'Actn',
    // not required,
    custombuttons: [
      {
        label: 'Folder View',
        route: 'user-details/lead/',
        type: 'internallink',
        tooltip: 'Folder View ',
        classname: 'folder_view',
        param: ['_id'],
      },

      {
        label: 'Open Folder View New Page',
        type: 'listner',
        id: 'i1',
        classname: 'OpenFolderView',
        tooltip: 'Open Folder View New Page  ',
      },
    ],
  };
  sortdatalead: any = {
    type: 'desc',
    field: 'createdon_datetime',
    options: [
      'fullname',
      'email',
      'phone',
      'lastlogin_datetime',
      'status',
      'createdon_datetime',
    ],
  };

  limitcondlead: any = {
    limit: 10,
    skip: 0,
  };


  searchModal() {

    if (this.usertype.length == 0) {
      this.usertype = ['admin'];
      console.log('searchModal', this.usertype);
    }
    let val = this.allflagblock;
    console.log(val, 'vallavvallav');

    let time = '';
    console.log(this.header_text_sub, '++++++++++++++++');

    if (this.header_text_sub[0] == ' Upcoming Appointments') {
      time = 'upcoming'
    }
    if (this.header_text_sub[0] == ' Past Appointments') {
      time = 'past'
    }
    switch (val) {
      case 'alllist':
        const dialogRef = this.dialog.open(SearchComponent, {
          panelClass: 'SearchComponent',

          data: { data: this.usertype, flag: this.allflagblock, training_flag: this.header_text_sub[0] },
        });
        dialogRef.afterClosed().subscribe((result: any) => {
          if (result != undefined) {
            this.blockUI.start('Loading.......');
            this.search_by_text = [];
            this.search_by = true;
            this.allTypeOfListnodatafound = false;
            this.refresh_user_management = false;
            this.allTypeOfList = [];
            if (typeof (result.lastlogin_datetime.$gte) != 'undefined' || typeof (result.lastlogin_datetime.$lte) != 'undefined') {
              console.log(result.lastlogin_datetime);
            } else {
              delete result.lastlogin_datetime;
            }
            if (typeof (result.createdon_datetime.$gte) != 'undefined' || typeof (result.createdon_datetime.$lte) != 'undefined') {
              console.log(result.createdon_datetime);
            } else {
              delete result.createdon_datetime;
            }
            delete result.start_datetime_unix;
            console.log('The dialog was closed', result);
            Object.keys(result).forEach((k) => result[k] == '' && delete result[k]);
            this.search_by_text.push(result);
            const data: any = {
              // How many data showing in first page
              condition: {
                limit: 10,
                skip: 0,
              },
              sort: {
                type: 'desc',
                field: '_id',
              },

              searchcondition: result,
              secret: this.userservice.secret,
              token: this.userservice.jwttoken,
            };
            let endpoint = 'api/userlistview';
            console.log(result, 'result');
            this.Apiservice.getDataWithoutToken(data, endpoint).subscribe(
              (res: any) => {
                this.refresh_user_management = true;
                console.log(res);
                this.allTypeOfList = res.results.res;
                if (this.allTypeOfList.length == 0) {
                  this.allTypeOfListnodatafound = true;
                }

                this.blockUI.stop();
              }
            );
          }
        });

        break;
      case 'productlist':
        const dialogRef1 = this.dialog.open(SearchComponent, {
          panelClass: 'SearchComponent',

          data: { val },
        });
        dialogRef1.afterClosed().subscribe((result: any) => {
          console.log(result);
          const endpointc = 'api/productlistview-count';
          const endpoint = 'api/productlistview';
          const data: any = {
            // How many data showing in first page
            secret: this.coockie.get('secret'),
            token: this.coockie.get('jwtToken'),
            source: '',
            condition: {
              // condition for how much data show in one time
              limit: 5,
              skip: 0,
            },
            sort: {
              type: 'desc', // data sort by decreasing order
              field: 'fullname', // data sort by first name
            },
            searchcondition: {
              productname_s: {
                $regex: result,
              },
            },
          };
          this.blockUI.start('Loading.......');

          this.Apiservice.getDataWithoutToken(data, endpointc).subscribe(
            (res: any) => {
              this.datesearchsourcecount = 0;
              setTimeout(() => {
                this.datesearchsourcecount = res.count; // sarch count  (How many data there)
              }, 500);
              this.Productsdetatableflag = false;
            }
          );

          this.Apiservice.getDataWithoutToken(data, endpoint).subscribe(
            (res: any) => {
              this.productlisttable = [];
              // sarch count  (How many data there)
              setTimeout(() => {
                this.productlisttable = res.results.res;
                this.blockUI.stop();
              }, 700);
              console.log(this.productlisttable, 'vbnmm,mj,j,kj,k');
              this.Productsdetatableflag = false;
            }
          );
        });
        break;
      case 'resourcelist':
        const dialogRef2 = this.dialog.open(SearchComponent, {
          panelClass: 'SearchComponent',

          data: { val },
        });
        dialogRef2.afterClosed().subscribe((result: any) => {
          console.log(result);
          const endpointc = 'api/resourcelistview-count';
          const endpoint = 'api/resourcelistview';
          const data: any = {
            // How many data showing in first page
            secret: this.coockie.get('secret'),
            token: this.coockie.get('secret'),
            source: '',
            condition: {
              // condition for how much data show in one time
              limit: 5,
              skip: 0,
            },
            sort: {
              type: 'desc', // data sort by decreasing order
              field: 'fullname', // data sort by first name
            },
            searchcondition: {
              resourcecategorsname_s: {
                $regex: result,
              },
            },
            // searchcondition: { type: 'getjobticketcategorylistdata' },
          };
          this.blockUI.start('Loading.......');
          this.Apiservice.getDataWithoutToken(data, endpointc).subscribe(
            (res: any) => {
              this.datesearchsourcecount = res.count; // sarch count  (How many data there)
              this.Resourcesdetatableflag = false;
            }
          );

          this.Apiservice.getDataWithoutToken(data, endpoint).subscribe(
            (res: any) => {
              this.resourcelisttable = [];
              setTimeout(() => {
                this.resourcelisttable = res.results.res; // sarch count  (How many data there)
                this.blockUI.stop();
              }, 700);

              this.Resourcesdetatableflag = false;
            }
          );
        });
        break;
      case 'traininglist':
        const dialogRef4 = this.dialog.open(SearchComponent, {
          panelClass: 'SearchComponent',

          data: { data: this.usertype, flag: this.allflagblock, training_flag: this.header_text_sub[0] },
        });
        dialogRef4.afterClosed().subscribe((result: any) => {
          if (result != undefined) {
            this.blockUI.start('Loading.......');
            this.search_by_text = [];
            this.search_by = true;
            this.getalltrainingdatanodatafound = false;
            this.getlessonlistdatanodatafound = false;
            this.refresh_user_management = false;
            this.getalltrainingdata = [];
            this.getlessondatalist = [];
            delete result.lastlogin_datetime;
            delete result.createdon_datetime;
            delete result.type_array;
            delete result.start_datetime_unix;
            console.log('The dialog was closed', result);
            Object.keys(result).forEach((k) => result[k] == '' && delete result[k]);
            if (typeof (result.status_s) != 'undefined' && result.status_s != null && result.status_s != '') {
              this.search_by_text[0] = { status_s: result.status_s };
              delete result.status_s;
            }
            this.search_by_text.push(result);
            const data: any = {
              // How many data showing in first page
              condition: {
                limit: 10,
                skip: 0,
              },
              sort: {
                type: 'desc',
                field: '_id',
              },

              searchcondition: result,
              secret: this.userservice.secret,
              token: this.userservice.jwttoken,
            };
            data.searchcondition['is_trash'] = { $ne: 1 };
            let endpoint = '';
            if (this.header_text_sub[0] == ' Categories') {
              endpoint = 'api10/getalltrainingdata';
            }
            if (this.header_text_sub[0] == ' lessons') {
              endpoint = 'api10/getlessondata';
            }
            console.log(result, 'result');
            this.Apiservice.getDataWithoutToken(data, endpoint).subscribe(
              (res: any) => {
                this.refresh_user_management = true;
                console.log(res);
                if (this.header_text_sub[0] == ' Categories') {
                  this.getalltrainingdata = res.results.res;
                  if (this.getalltrainingdata.length == 0) {
                    this.getalltrainingdatanodatafound = true;
                  }
                }
                if (this.header_text_sub[0] == ' lessons') {
                  this.getlessondatalist = res.results.res;
                  if (this.getlessondatalist.length == 0) {
                    this.getlessonlistdatanodatafound = true;
                  }

                }
                this.blockUI.stop();
              }
            );
          }
        });
        break;
      case 'calender':
        const dialogRef3 = this.dialog.open(SearchComponent, {
          panelClass: 'SearchComponent',

          data: { data: this.usertype, flag: this.allflagblock, time: time },
        });
        dialogRef3.afterClosed().subscribe((result: any) => {
          if (result != undefined) {
            this.blockUI.start('Loading.......');
            this.search_by = true;
            this.allcalenderdatanodatafound = false;
            this.refresh_user_management = false;
            this.allcalenderdata = [];
            this.search_by_text = [];
            if (typeof (result.lastlogin_datetime.$gte) != 'undefined' || typeof (result.lastlogin_datetime.$lte) != 'undefined') {
              console.log(result.lastlogin_datetime);
            } else {
              delete result.lastlogin_datetime;
            }
            if (typeof (result.createdon_datetime.$gte) != 'undefined' || typeof (result.createdon_datetime.$lte) != 'undefined') {
              console.log(result.createdon_datetime);
            } else {
              delete result.createdon_datetime;
            }
            if (typeof (result.start_datetime_unix.$gte) != 'undefined' || typeof (result.start_datetime_unix.$lte) != 'undefined') {
              console.log(result.start_datetime_unix);
            } else {
              delete result.start_datetime_unix;
            }
            delete result.type_array;
            Object.keys(result).forEach((k) => result[k] == '' && delete result[k]);
            console.log('The dialog was closed', result);
            if (typeof (result.status) != 'undefined' && result.status != null && result.status != '') {
              result['status'] = Number(result.status);
            }
            let text = result;
            this.search_by_text.push(text);
            if (typeof (text.product_name) != 'undefined' && text.product_name != null && text.product_name != '') {
              this.search_by_text[0] = { product_name: text.product_name };
              delete result.product_name;
            }
            if (typeof (text.status_s) != 'undefined' && text.status_s != null && text.status_s != '') {
              this.search_by_text[0] = { status_s: text.status_s };
              delete result.status_s;
            }
            console.log(this.search_by_text, '>>>>>>>>>>>>>>>');
            let endpoint = '';
            if (this.header_text_sub[0] == ' Upcoming Appointments') {
              endpoint = 'list-upcoming-booked-events';
            }
            if (this.header_text_sub[0] == ' Past Appointments') {
              endpoint = 'list-booked-events';
            }
            const data: any = {
              // How many data showing in first page
              condition: {
                limit: 10,
                skip: 0,
              },
              sort: {
                type: 'desc',
                field: '_id',
              },

              searchcondition: result,
              secret: this.userservice.secret,
              token: this.userservice.jwttoken,
            };
            this.Apiservice.customRequest(
              data,
              endpoint,
              environment['api_calender_url']
            ).subscribe((res: any) => {
              this.refresh_user_management = true;
              console.log(res);
              this.allcalenderdata = res.results.res;
              if (this.allcalenderdata.length == 0) {
                this.allcalenderdatanodatafound = true;
              }

              this.blockUI.stop();
            }
            );
          }
        });
        break;
      default:
        break;
    }
  }
  refresh_user_management_data() {
    let val = this.allflagblock;
    console.log(this.allflagblock, '______________');

    switch (val) {
      case 'alllist':
        this.blockUI.start('Loading.......');
        this.search_by = false;
        this.allTypeOfListnodatafound = false;
        this.refresh_user_management = false;
        this.allTypeOfList = [];
        var dataalllist: any = {
          // How many data showing in first page
          condition: {
            limit: 10,
            skip: 0,
          },
          sort: {
            type: 'desc',
            field: '_id',
          },

          searchcondition: { type: { $in: this.usertype } },
          secret: this.userservice.secret,
          token: this.userservice.jwttoken,
        };
        let endpoint = 'api/userlistview';
        this.Apiservice.getDataWithoutToken(dataalllist, endpoint).subscribe(
          (res: any) => {
            console.log(res);
            this.allTypeOfList = res.results.res;
            if (this.allTypeOfList.length == 0) {
              this.allTypeOfListnodatafound = true;
            }
            this.blockUI.stop();
          }
        );
        break;
      case 'calender':
        this.blockUI.start('Loading.......');
        this.search_by = false;
        this.allcalenderdatanodatafound = false;
        this.refresh_user_management = false;
        this.allcalenderdata = [];
        var datacalender: any = {
          // How many data showing in first page
          condition: {
            limit: 10,
            skip: 0,
          },
          sort: {
            type: 'desc',
            field: '_id',
          },
          secret: this.userservice.secret,
          token: this.userservice.jwttoken,
        };
        this.Apiservice.customRequest(
          datacalender,
          'list-upcoming-booked-events',
          environment['api_calender_url']
        ).subscribe((res: any) => {
          console.log(res);
          this.allcalenderdata = res.results.res;
          if (this.allcalenderdata.length == 0) {
            this.allcalenderdatanodatafound = true;
          }

          this.blockUI.stop();
        }
        );
        break;
      case 'traininglist':
        this.blockUI.start('Loading.......');
        this.search_by = false;
        this.getalltrainingdata = [];
        this.getlessondatalist = [];
        this.getalltrainingdatanodatafound = false;
        this.getlessonlistdatanodatafound = false;
        this.refresh_user_management = false;
        const data: any = {
          // How many data showing in first page
          condition: {
            limit: 10,
            skip: 0,
          },
          sort: {
            type: 'desc',
            field: '_id',
          },

          searchcondition: { is_trash: { $ne: 1 } },
          secret: this.userservice.secret,
          token: this.userservice.jwttoken,
        };
        let endpoint_training = '';
        if (this.header_text_sub[0] == ' Categories') {
          endpoint_training = 'api10/getalltrainingdata';
        }
        if (this.header_text_sub[0] == ' lessons') {
          endpoint_training = 'api10/getlessondata';
        }
        this.Apiservice.getDataWithoutToken(data, endpoint_training).subscribe(
          (res: any) => {
            console.log(res);
            if (this.header_text_sub[0] == ' Categories') {
              this.getalltrainingdata = res.results.res;
              if (this.getalltrainingdata.length == 0) {
                this.getalltrainingdatanodatafound = true;
              }
            }
            if (this.header_text_sub[0] == ' lessons') {
              this.getlessondatalist = res.results.res;
              if (this.getlessondatalist.length == 0) {
                this.getlessonlistdatanodatafound = true;
              }

            }

            this.blockUI.stop();
          }
        );
        break;
      default:
        break;
    }
  }
  public updatetable: any = [];
  public fullname: any = '';
  public lastlogin: Date;
  public refreshtokenfailed: any;
  public pending_contract_count: any = 0;
  public hostName: any;
  public medigradeflag: any = false;
  constructor(
    public activatedroute: ActivatedRoute,
    public userservice: UserserviceService,
    public Apiservice: ApiService,
    public dialog: MatDialog,
    public router: Router,
    public loaderService: LoaderService,
    public coockie: CookieService,
    private _snackBar: MatSnackBar,
    public domainService: DetectdomainService
  ) {
    this.hostName = this.domainService.getdomain();
    if (this.hostName == "medigrade") {
      // console.log("medigrade ==> flag true")
      this.medigradeflag = true
    }
    if (this.coockie.check('firstname')) {
      this.fullname = this.fullname + JSON.parse(this.coockie.get('firstname'));
      console.log(this.fullname, '>>>>>>....');

    }
    if (this.coockie.check('lastname')) {
      this.fullname = this.fullname + ' ' + JSON.parse(this.coockie.get('lastname'));
      console.log(this.fullname, '>>>>>>....');
    }
    if (this.coockie.check('lastlogindate')) {
      this.lastlogin = JSON.parse(this.coockie.get('lastlogindate'));
      console.log(this.lastlogin, '>>>>>><<<<<<<....');

    }
    if (this.coockie.check('refreshtokenfailed')) {
      this.refreshtokenfailed = JSON.parse(this.coockie.get('refreshtokenfailed'));
      console.log(this.refreshtokenfailed, '>>>>>><<<<<<<....+++++++++++++++');

    }
    this.allflags['admin'] = true;
    this.usersflg.admin = true;
    this.userid = this.userservice.userid;
    this.userFullName =
      this.userservice.firstname + ' ' + this.userservice.lastname;
    this.dashboardlist();
    this.schedule_appointments("constructor");
    this.commission_func('from_constructor');
    // this.training();
    this.togglebutton('constructor', '')
    const endpointc = 'api10/pending-contract-list-count';
    const data: any = { // How many data showing in first page
      secret: this.userservice.secret,
      token: this.coockie.get('jwtToken'),
      source: '',
      condition: {  // condition for how much data show in one time
        limit: 10,
        skip: 0,
      },
      searchcondition: {
        contract_status: {
          $in: [
            'requested by technological-consultant',
            'requested by lead'
          ]
        }
      },
      sort: {
        type: 'desc',  // data sort by decreasing order
        field: '_id',  // data sort by first name
      }
    };
    this.Apiservice
      .getDataWithoutToken(data, 'api10/pending_contract_list_count_for_admin')
      .subscribe((res: any) => {
        this.pending_contract_count = res.result; // sarch count  (How many data there)
      });
  }
  togglebutton(val: any, event: any) {
    console.log(this.isChecked);
    console.log(event);

    console.log(val);
    let send_data = {};
    if (val == 'constructor') {
      send_data = {
        data: {
          signup_process: 'find',
        }
      }
    } else {
      send_data = {
        data: {
          signup_process: val == true ? 'Off' : 'On',
        }
      }
    }
    this.Apiservice.getDataWithoutToken(send_data, 'api10/change-signup-process').subscribe((res: any) => {
      if (val == 'constructor') {
        if (res.result[0].signup_process == 'On') {
          this.isChecked = true;
        } else {
          this.isChecked = false;
        }
      } else {
        this._snackBar.open('Signup process update successfully', 'Dismiss', {
          duration: 4000
        });
      }
    })
  }
  routerlinkfun(val: any) {
    console.log(val, '++++++++++++++++++++++');

    this.router.navigate(['pending/contract/list'], { queryParams: { flag: val } });
  }
  folderviewlistingChange(val: any) {
    console.log(val);
    if (val.custombuttonclick.btninfo.label == 'Open Folder View Same Page') {
      this.router.navigateByUrl(
        '/user-details/technological-consultant/' + val.custombuttonclick.data._id + '?flag=training'
      );
    }
    if (val.custombuttonclick.btninfo.label == 'Open Folder View New Page') {
      window.open(
        '/user-details/technological-consultant/' + val.custombuttonclick.data._id + '?flag=training',
        'Independent Window'
      );
    }

  }
  schedule_appointments(val: any) {
    let data = {
      token: this.userservice.jwttoken,
      condition: {
        limit: 5,
        skip: 0,
      },
      sarchconditions: {},
      sort: {
        type: 'desc',
        field: 'booking_date',
      },
    };
    this.Apiservice.customRequest(
      data,
      'list-upcoming-booked-events',
      environment['api_calender_url']
    ).subscribe((res: any) => {
      this.scheduleappointment = res.results.res;
      if (val == 'constructor') {
        if (this.scheduleappointment.length > 0) {
          this.mostactiveflagfornextblock = this.mostactiveflagfornextblock + 1
        }
      }
      this.schedule_datatable = this.scheduleappointment;
    });
    setTimeout(() => {
      this.Scheduleddatatableflag = false;
    }, 500);

    //   });
  }
  listenLiblistingChangeSchedule(event: any) {
    console.log('eventtt', event);
    if (event.custombuttonclick != undefined) {
      if (event.custombuttonclick.btninfo.id === 'btn_cancel') {
        const dialogRef = this.dialog.open(DialogCancelAlert);

        dialogRef.afterClosed().subscribe((result) => {
          // console.log(`Dialog result: ${result}`);
          console.log(result, 'testingggggggggggggggggggggggggggggggggggg');
          if (result.flag == true) {
            // google_events
            let note = result.data
            let google_events_data: any = {
              userid: event.custombuttonclick.data.user_id,
              source: 'google_events',
              token: this.userservice.jwttoken,
              note: note
            };
            this.Apiservice
              .customRequest(
                google_events_data,
                'get-refresh-token',
                environment['api_calender_url']
              )
              .subscribe((response: any) => {
                console.log('responseresponseresponse', response);
                let r_token = response.refresh_token;
                // console.log(r_token, 'r_token')
                const data: any = {
                  token: this.userservice.jwttoken,
                  _id: event.custombuttonclick.data._id,
                  attende_email: event.custombuttonclick.data.attende_email,
                  attende_name: event.custombuttonclick.data.attende_name,
                  attende_type: event.custombuttonclick.data.attende_type,
                  useremail: event.custombuttonclick.data.useremail,
                  appointment_date: event.custombuttonclick.data.startdate,
                  username: event.custombuttonclick.data.username,
                  slot: event.custombuttonclick.data.slot,
                  event_type: event.custombuttonclick.data.event_type,
                  event_title: event.custombuttonclick.data.event_title,
                  // 'appointment_date': '01/27/2021',
                  refresh_token: r_token,
                  eid: event.custombuttonclick.data.eid,
                  slotTimeActual: event.custombuttonclick.data.slotTimeActual,
                  googleevent: event.custombuttonclick.data.googleevent,
                  note: note
                };
                // console.log(data, 'data')
                this.Apiservice
                  .customRequest(
                    data,
                    'google-calendar-event/delete-booked-event',
                    environment['google_calendar_api']
                  )
                  .subscribe((response: any) => {
                    // console.log('response', response);
                    setTimeout(() => {
                      this.updatetable = true;
                    }, 10);
                  });

                this.Apiservice.customRequest(data, 'delete-booked-event', environment['api_calender_url']).subscribe((response: any) => {
                  console.log(response, 'responsedelete')

                })


              });
          }
        });
      }
      if (event.custombuttonclick.btninfo.label == 'Reschedule') {
        console.log(event.custombuttonclick.data, '{}{}{}');
        window.open('/calendar-management/manage-appointment/reschedule-appointments/' + event.custombuttonclick.data._id + '/' + event.custombuttonclick.data.event_type_val, '_blank');
      }

      // if (event.custombuttonclick.btninfo.id === 'btn_mark_as_done') {
      //   console.log(event.custombuttonclick.btninfo.id, 'mark')

      //   const mark_data: any = {
      //     _id: event.custombuttonclick.data._id
      //   }

      //   this.apiService.customRequest(mark_data, 'update-status-done', environment['api_calender_url']).subscribe((response: any) => {
      //     console.log(response, 'response')
      //     setTimeout(() => {
      //       this.updatetable =true;
      //       }, 10);
      //   })
      // }
    }
  }
  commission_appointments() {
    this.commission_datatable = this.commissionManagement;
    setTimeout(() => {
      this.Commmissiondatatableflag = false;
    }, 500);

    //   });
  }

  dashboardlist() {
    this.Apiservice.getDataWithoutToken(
      {
        secret: this.coockie.get('secret'),
        token: this.coockie.get('jwtToken'),
      },
      'api/dashboardlist'
    ).subscribe((res: any) => {
      this.dashboardlistarray = res.results;
      console.log(
        this.dashboardlistarray,
        '++++++++++++++++++++++++++++++++++++++++++++++++++'
      );
      this.alertmsg = this.dashboardlistarray.contactmodificationforsystem;
      // console.log(res,'++++++++++++++++');

      // this.scheduleappointment = res.results.scheduled_appointments;
      // if (this.scheduleappointment.length == 0) {
      //   this.mostactiveflagfornextblock = this.mostactiveflagfornextblock + 1

      // }
      this.commissionManagement = res.results.managecommission;
      if (this.commissionManagement.length > 0) {
        // this.mostactiveflagfornextblock = this.mostactiveflagfornextblock + 1
      }
      // console.log(this.scheduleappointment, 'scheduleappointment')
    });
  }
  // training() {
  //   this.Apiservice
  //     .getDataWithoutToken('', 'api/traininggraph')
  //     .subscribe((res: any) => {
  //       this.traininggraph = res.results;
  //     });
  // }
  //toggle fuction for block User Login

  toggleauserlogingrid(e: any) {
    //  console.warn('userlogin', e.checked)

    if (e.checked == true) {
      //  console.warn('userlogin', e.checked)
      this.userlogingridFlag = true;
      this.graphloginmonth();
    } else {
      //  console.warn('userlogin', e.checked)
      this.userlogingridFlag = false;
    }
  }

  getallsharelinks() {
    const dataforloginmonth = {
      secret: this.coockie.get('secret'),
      token: this.coockie.get('jwtToken'),
      id: JSON.parse(this.coockie.get('userid')),
    };

    // this.loginFlag = true;

    this.Apiservice.getDataWithoutToken(
      dataforloginmonth,
      'api/getallsharelinkslist'
    ).subscribe((res: any) => {
      this.getallsharelinksdata = res.results.res;
      // console.log(this.getallsharelinksdata, 'getallsharelinksdata +++++')
    });
  }

  graphloginmonth() {
    const dataforloginmonth = {
      secret: this.coockie.get('secret'),
      token: this.coockie.get('jwtToken'),
    };

    this.loginFlag = true;

    this.Apiservice.getDataWithoutToken(
      dataforloginmonth,
      'api/logingrpmonth'
    ).subscribe((res: any) => {
      //  // console.log(res, 'logingrpmonth');
      this.logindatamontharray = res.results;

      // admin graph
      this.lineChartDatamonth[0].data[0] = this.logindatamontharray.admin_Jan;
      this.lineChartDatamonth[0].data[1] = this.logindatamontharray.admin_Feb;
      this.lineChartDatamonth[0].data[2] = this.logindatamontharray.admin_Mar;
      this.lineChartDatamonth[0].data[3] = this.logindatamontharray.admin_Apr;
      this.lineChartDatamonth[0].data[4] = this.logindatamontharray.admin_May;
      this.lineChartDatamonth[0].data[5] = this.logindatamontharray.admin_Jun;
      this.lineChartDatamonth[0].data[6] = this.logindatamontharray.admin_Jul;
      this.lineChartDatamonth[0].data[7] = this.logindatamontharray.admin_Aug;
      this.lineChartDatamonth[0].data[8] = this.logindatamontharray.admin_Sep;
      this.lineChartDatamonth[0].data[9] = this.logindatamontharray.admin_Oct;
      this.lineChartDatamonth[0].data[10] = this.logindatamontharray.admin_Nov;
      this.lineChartDatamonth[0].data[11] = this.logindatamontharray.closer_Dec;

      // distributor graph
      this.lineChartDatamonth[1].data[0] =
        this.logindatamontharray.distributor_Jan;
      this.lineChartDatamonth[1].data[1] =
        this.logindatamontharray.distributor_Feb;
      this.lineChartDatamonth[1].data[2] =
        this.logindatamontharray.distributor_Mar;
      this.lineChartDatamonth[1].data[3] =
        this.logindatamontharray.distributor_Apr;
      this.lineChartDatamonth[1].data[4] =
        this.logindatamontharray.distributor_May;
      this.lineChartDatamonth[1].data[5] =
        this.logindatamontharray.distributor_Jun;
      this.lineChartDatamonth[1].data[6] =
        this.logindatamontharray.distributor_Jul;
      this.lineChartDatamonth[1].data[7] =
        this.logindatamontharray.distributor_Aug;
      this.lineChartDatamonth[1].data[8] =
        this.logindatamontharray.distributor_Sep;
      this.lineChartDatamonth[1].data[9] =
        this.logindatamontharray.distributor_Oct;
      this.lineChartDatamonth[1].data[10] =
        this.logindatamontharray.distributor_Nov;
      this.lineChartDatamonth[1].data[11] =
        this.logindatamontharray.distributor_Dec;

      // sales manager graph
      this.lineChartDatamonth[2].data[0] =
        this.logindatamontharray.salesperson_Jan;
      this.lineChartDatamonth[2].data[1] =
        this.logindatamontharray.distributor_Feb;
      this.lineChartDatamonth[2].data[2] =
        this.logindatamontharray.sales_person_Feb;
      this.lineChartDatamonth[2].data[3] =
        this.logindatamontharray.sales_person_Apr;
      this.lineChartDatamonth[2].data[4] =
        this.logindatamontharray.sales_person_May;
      this.lineChartDatamonth[2].data[5] =
        this.logindatamontharray.sales_person_Jun;
      this.lineChartDatamonth[2].data[6] =
        this.logindatamontharray.sales_person_Jul;
      this.lineChartDatamonth[2].data[7] =
        this.logindatamontharray.sales_person_Aug;
      this.lineChartDatamonth[2].data[8] =
        this.logindatamontharray.sales_person_Sep;
      this.lineChartDatamonth[2].data[9] =
        this.logindatamontharray.sales_person_Oct;
      this.lineChartDatamonth[2].data[10] =
        this.logindatamontharray.sales_person_Nov;
      this.lineChartDatamonth[2].data[11] =
        this.logindatamontharray.sales_person_Dec;

      // tech consult graph
      this.lineChartDatamonth[3].data[0] =
        this.logindatamontharray.technological_consultant_Jan;
      this.lineChartDatamonth[3].data[1] =
        this.logindatamontharray.technological_consultant_Feb;
      this.lineChartDatamonth[3].data[2] =
        this.logindatamontharray.technological_consultant_Feb;
      this.lineChartDatamonth[3].data[3] =
        this.logindatamontharray.technological_consultant_Apr;
      this.lineChartDatamonth[3].data[4] =
        this.logindatamontharray.technological_consultant_May;
      this.lineChartDatamonth[3].data[5] =
        this.logindatamontharray.technological_consultant_Jun;
      this.lineChartDatamonth[3].data[6] =
        this.logindatamontharray.technological_consultant_Jul;
      this.lineChartDatamonth[3].data[7] =
        this.logindatamontharray.technological_consultant_Aug;
      this.lineChartDatamonth[3].data[8] =
        this.logindatamontharray.technological_consultant_Sep;
      this.lineChartDatamonth[3].data[9] =
        this.logindatamontharray.technological_consultant_Oct;
      this.lineChartDatamonth[3].data[10] =
        this.logindatamontharray.technological_consultant_Nov;
      this.lineChartDatamonth[3].data[11] =
        this.logindatamontharray.technological_consultant_Dec;

      // closer graph
      this.lineChartDatamonth[3].data[0] = this.logindatamontharray.closer_Jan;
      this.lineChartDatamonth[3].data[1] = this.logindatamontharray.closer_Feb;
      this.lineChartDatamonth[3].data[2] = this.logindatamontharray.closer_Feb;
      this.lineChartDatamonth[3].data[3] = this.logindatamontharray.closer_Apr;
      this.lineChartDatamonth[3].data[4] = this.logindatamontharray.closer_May;
      this.lineChartDatamonth[3].data[5] = this.logindatamontharray.closer_Jun;
      this.lineChartDatamonth[3].data[6] = this.logindatamontharray.closer_Jul;
      this.lineChartDatamonth[3].data[7] = this.logindatamontharray.closer_Aug;
      this.lineChartDatamonth[3].data[8] = this.logindatamontharray.closer_Sep;
      this.lineChartDatamonth[3].data[9] = this.logindatamontharray.closer_Oct;
      this.lineChartDatamonth[3].data[10] = this.logindatamontharray.closer_Nov;
      this.lineChartDatamonth[3].data[11] = this.logindatamontharray.closer_Dec;

      // contract manager graph
      this.lineChartDatamonth[3].data[0] =
        this.logindatamontharray.contract_manager_Jan;
      this.lineChartDatamonth[3].data[1] =
        this.logindatamontharray.contract_manager_Feb;
      this.lineChartDatamonth[3].data[2] =
        this.logindatamontharray.contract_manager_Feb;
      this.lineChartDatamonth[3].data[3] =
        this.logindatamontharray.contract_manager_Apr;
      this.lineChartDatamonth[3].data[4] =
        this.logindatamontharray.contract_manager_May;
      this.lineChartDatamonth[3].data[5] =
        this.logindatamontharray.contract_manager_Jun;
      this.lineChartDatamonth[3].data[6] =
        this.logindatamontharray.contract_manager_Jul;
      this.lineChartDatamonth[3].data[7] =
        this.logindatamontharray.contract_manager_Aug;
      this.lineChartDatamonth[3].data[8] =
        this.logindatamontharray.contract_manager_Sep;
      this.lineChartDatamonth[3].data[9] =
        this.logindatamontharray.contract_manager_Oct;
      this.lineChartDatamonth[3].data[10] =
        this.logindatamontharray.contract_manager_Nov;
      this.lineChartDatamonth[3].data[11] =
        this.logindatamontharray.contract_manager_Dec;

      this.loginFlag = false;
    });
  }

  graphtraining() {
    this.trainingFlag = true;
    const datafortraingmonth = {
      secret: this.coockie.get('secret'),
      token: this.coockie.get('jwtToken'),
    };
    // this.TraininggridFlag = true
    this.Apiservice.getDataWithoutToken(
      datafortraingmonth,
      'api/traininggraph'
    ).subscribe((res: any) => {
      // console.log(res, 'trainggraphhhhhhhhhhhhhhhhhhhhhhhhhhhhhhh');
      this.traininggraph = res.results;

      // advanced_wellness_solutions_friday
      this.TraininglineChartData[0].data[0] =
        this.traininggraph.advanced_wellness_solutions_monday;
      this.TraininglineChartData[0].data[1] =
        this.traininggraph.advanced_wellness_solutions_tuesday;
      this.TraininglineChartData[0].data[2] =
        this.traininggraph.advanced_wellness_solutions_wednesday;
      this.TraininglineChartData[0].data[3] =
        this.traininggraph.advanced_wellness_solutions_thrusday;
      this.TraininglineChartData[0].data[4] =
        this.traininggraph.advanced_wellness_solutions_friday;
      this.TraininglineChartData[0].data[5] =
        this.traininggraph.advanced_wellness_solutions_saturday;
      this.TraininglineChartData[0].data[6] =
        this.traininggraph.advanced_wellness_solutions_sunday;

      // apogeeINVENT
      this.TraininglineChartData[1].data[0] =
        this.traininggraph.apogeeINVENT_monday;
      this.TraininglineChartData[1].data[1] =
        this.traininggraph.apogeeINVENT_tuesday;
      this.TraininglineChartData[1].data[2] =
        this.traininggraph.apogeeINVENT_wednesday;
      this.TraininglineChartData[1].data[3] =
        this.traininggraph.apogeeINVENT_thrusday;
      this.TraininglineChartData[1].data[4] =
        this.traininggraph.apogeeINVENT_friday;
      this.TraininglineChartData[1].data[5] =
        this.traininggraph.apogeeINVENT_saturday;
      this.TraininglineChartData[1].data[6] =
        this.traininggraph.apogeeINVENT_sunday;

      //beto_paredes_training
      this.TraininglineChartData[2].data[0] =
        this.traininggraph.beto_paredes_training_monday;
      this.TraininglineChartData[2].data[1] =
        this.traininggraph.beto_paredes_training_tuesday;
      this.TraininglineChartData[2].data[2] =
        this.traininggraph.beto_paredes_training_wednesday;
      this.TraininglineChartData[2].data[3] =
        this.traininggraph.beto_paredes_training_thrusday;
      this.TraininglineChartData[2].data[4] =
        this.traininggraph.beto_paredes_training_friday;
      this.TraininglineChartData[2].data[5] =
        this.traininggraph.beto_paredes_training_saturday;
      this.TraininglineChartData[2].data[6] =
        this.traininggraph.beto_paredes_training_sunday;

      //med_world_one
      this.TraininglineChartData[3].data[0] =
        this.traininggraph.med_world_one_monday;
      this.TraininglineChartData[3].data[1] =
        this.traininggraph.med_world_one_tuesday;
      this.TraininglineChartData[3].data[2] =
        this.traininggraph.med_world_one_wednesday;
      this.TraininglineChartData[3].data[3] =
        this.traininggraph.med_world_one_thrusday;
      this.TraininglineChartData[3].data[4] =
        this.traininggraph.med_world_one_friday;
      this.TraininglineChartData[3].data[5] =
        this.traininggraph.med_world_one_saturday;
      this.TraininglineChartData[3].data[6] =
        this.traininggraph.med_world_one_sunday;

      //universal_tech_associates
      this.TraininglineChartData[4].data[0] =
        this.traininggraph.universal_tech_associates_monday;
      this.TraininglineChartData[4].data[1] =
        this.traininggraph.universal_tech_associates_tuesday;
      this.TraininglineChartData[4].data[2] =
        this.traininggraph.universal_tech_associates_wednesday;
      this.TraininglineChartData[4].data[3] =
        this.traininggraph.universal_tech_associates_thrusday;
      this.TraininglineChartData[4].data[4] =
        this.traininggraph.universal_tech_associates_friday;
      this.TraininglineChartData[4].data[5] =
        this.traininggraph.universal_tech_associates_saturday;
      this.TraininglineChartData[4].data[6] =
        this.traininggraph.universal_tech_associates_sunday;
      this.trainingFlag = false;
    });
  }

  toggleaTraininggrid(e: any) {
    //  console.warn('Training', e.checked);

    if (e.checked === true) {
      console.warn('Training', e.checked);
      this.TraininggridFlag = true;
      // this.graphtraining();
    } else {
      this.graphtraining();

      console.warn('Training', e.checked);
      this.TraininggridFlag = false;
    }
  }

  toggleaContractgrid(e: any) {
    //  console.warn('Contract', e.checked);

    if (e.checked === true) {
      //  console.warn('Contract', e.checked);
      this.ContractgridFlag = true;
    } else {
      //  console.warn('Contract', e.checked);
      this.ContractgridFlag = false;
    }
  }

  toggleaAppointmentgrid(e: any) {
    //  console.warn('Appointment', e.checked);

    if (e.checked === true) {
      //  console.warn('Appointment', e.checked);
      this.AppointmentgridFlag = true;
    } else {
      //  console.warn('Appointment', e.checked);
      this.AppointmentgridFlag = false;
    }
  }

  toggleaUsergrid(e: any) {
    //  console.warn('User', e.checked);

    if (e.checked === true) {
      //  console.warn('User', e.checked);

      this.UsergridFlag = true;
      this.graphsignupmonth();
    } else {
      //  console.warn('User', e.checked);
      this.UsergridFlag = false;
    }
  }
  graphsignupmonth() {
    const dataforsignupmonth: any = {
      secret: this.coockie.get('secret'),
      token: this.coockie.get('jwtToken'),
    };

    this.signupFlag = true;

    this.Apiservice.getDataWithoutToken(
      dataforsignupmonth,
      'api/singupgrpmonth'
    ).subscribe((res: any) => {
      //  // console.log(res, 'signupmonth');
      this.signupdatamontharray = res.results;
      //  // console.log(this.signupdatamontharray, 'signup month data..');

      // admin graph
      this.UserChartDatamonth[0].data[0] = this.signupdatamontharray.admin_Jan;
      this.UserChartDatamonth[0].data[1] = this.signupdatamontharray.admin_Feb;
      this.UserChartDatamonth[0].data[2] = this.signupdatamontharray.admin_Mar;
      this.UserChartDatamonth[0].data[3] = this.signupdatamontharray.admin_Apr;
      this.UserChartDatamonth[0].data[4] = this.signupdatamontharray.admin_May;
      this.UserChartDatamonth[0].data[5] = this.signupdatamontharray.admin_Jun;
      this.UserChartDatamonth[0].data[6] = this.signupdatamontharray.admin_Jul;
      this.UserChartDatamonth[0].data[7] = this.signupdatamontharray.admin_Aug;
      this.UserChartDatamonth[0].data[8] = this.signupdatamontharray.admin_Sep;
      this.UserChartDatamonth[0].data[9] = this.signupdatamontharray.admin_Oct;
      this.UserChartDatamonth[0].data[10] = this.signupdatamontharray.admin_Nov;
      this.UserChartDatamonth[0].data[11] = this.signupdatamontharray.admin_Dec;

      // distributor graph
      this.UserChartDatamonth[1].data[0] =
        this.signupdatamontharray.distributor_Jan;
      this.UserChartDatamonth[1].data[1] =
        this.signupdatamontharray.distributor_Feb;
      this.UserChartDatamonth[1].data[2] =
        this.signupdatamontharray.distributor_Mar;
      this.UserChartDatamonth[1].data[3] =
        this.signupdatamontharray.distributor_Apr;
      this.UserChartDatamonth[1].data[4] =
        this.signupdatamontharray.distributor_May;
      this.UserChartDatamonth[1].data[5] =
        this.signupdatamontharray.distributor_Jun;
      this.UserChartDatamonth[1].data[6] =
        this.signupdatamontharray.distributor_Jul;
      this.UserChartDatamonth[1].data[7] =
        this.signupdatamontharray.distributor_Aug;
      this.UserChartDatamonth[1].data[8] =
        this.signupdatamontharray.distributor_Sep;
      this.UserChartDatamonth[1].data[9] =
        this.signupdatamontharray.distributor_Oct;
      this.UserChartDatamonth[1].data[10] =
        this.signupdatamontharray.distributor_Nov;
      this.UserChartDatamonth[1].data[11] =
        this.signupdatamontharray.distributor_Dec;

      // sales manager graph
      this.UserChartDatamonth[2].data[0] =
        this.signupdatamontharray.salesperson_Jan;
      this.UserChartDatamonth[2].data[1] =
        this.signupdatamontharray.sales_person_Feb;
      this.UserChartDatamonth[2].data[2] =
        this.signupdatamontharray.sales_person_Mar;
      this.UserChartDatamonth[2].data[3] =
        this.signupdatamontharray.sales_person_Apr;
      this.UserChartDatamonth[2].data[4] =
        this.signupdatamontharray.sales_person_May;
      this.UserChartDatamonth[2].data[5] =
        this.signupdatamontharray.sales_person_Jun;
      this.UserChartDatamonth[2].data[6] =
        this.signupdatamontharray.sales_person_Jul;
      this.UserChartDatamonth[2].data[7] =
        this.signupdatamontharray.sales_person_Aug;
      this.UserChartDatamonth[2].data[8] =
        this.signupdatamontharray.sales_person_Sep;
      this.UserChartDatamonth[2].data[9] =
        this.signupdatamontharray.sales_person_Oct;
      this.UserChartDatamonth[2].data[10] =
        this.signupdatamontharray.sales_person_Nov;
      this.UserChartDatamonth[2].data[11] =
        this.signupdatamontharray.sales_person_Dec;

      // tech consult graph
      this.UserChartDatamonth[3].data[0] =
        this.signupdatamontharray.technological_consultant_Jan;
      this.UserChartDatamonth[3].data[1] =
        this.signupdatamontharray.technological_consultant_Feb;
      this.UserChartDatamonth[3].data[2] =
        this.signupdatamontharray.technological_consultant_Mar;
      this.UserChartDatamonth[3].data[3] =
        this.signupdatamontharray.technological_consultant_Apr;
      this.UserChartDatamonth[3].data[4] =
        this.signupdatamontharray.technological_consultant_May;
      this.UserChartDatamonth[3].data[5] =
        this.signupdatamontharray.technological_consultant_Jun;
      this.UserChartDatamonth[3].data[6] =
        this.signupdatamontharray.technological_consultant_Jul;
      this.UserChartDatamonth[3].data[7] =
        this.signupdatamontharray.technological_consultant_Aug;
      this.UserChartDatamonth[3].data[8] =
        this.signupdatamontharray.technological_consultant_Sep;
      this.UserChartDatamonth[3].data[9] =
        this.signupdatamontharray.technological_consultant_Oct;
      this.UserChartDatamonth[3].data[10] =
        this.signupdatamontharray.technological_consultant_Nov;
      this.UserChartDatamonth[3].data[11] =
        this.signupdatamontharray.technological_consultant_Dec;

      // closer graph
      this.UserChartDatamonth[4].data[0] = this.signupdatamontharray.closer_Jan;
      this.UserChartDatamonth[4].data[1] = this.signupdatamontharray.closer_Feb;
      this.UserChartDatamonth[4].data[2] = this.signupdatamontharray.closer_Mar;
      this.UserChartDatamonth[4].data[3] = this.signupdatamontharray.closer_Apr;
      this.UserChartDatamonth[4].data[4] = this.signupdatamontharray.closer_May;
      this.UserChartDatamonth[4].data[5] = this.signupdatamontharray.closer_Jun;
      this.UserChartDatamonth[4].data[6] = this.signupdatamontharray.closer_Jul;
      this.UserChartDatamonth[4].data[7] = this.signupdatamontharray.closer_Aug;
      this.UserChartDatamonth[4].data[8] = this.signupdatamontharray.closer_Sep;
      this.UserChartDatamonth[4].data[9] = this.signupdatamontharray.closer_Oct;
      this.UserChartDatamonth[4].data[10] =
        this.signupdatamontharray.closer_Nov;
      this.UserChartDatamonth[4].data[11] =
        this.signupdatamontharray.closer_Dec;

      // contract manager graph
      this.UserChartDatamonth[5].data[0] =
        this.signupdatamontharray.contract_manager_Jan;
      this.UserChartDatamonth[5].data[1] =
        this.signupdatamontharray.contract_manager_Feb;
      this.UserChartDatamonth[5].data[2] =
        this.signupdatamontharray.contract_manager_Mar;
      this.UserChartDatamonth[5].data[3] =
        this.signupdatamontharray.contract_manager_Apr;
      this.UserChartDatamonth[5].data[4] =
        this.signupdatamontharray.contract_manager_May;
      this.UserChartDatamonth[5].data[5] =
        this.signupdatamontharray.contract_manager_Jun;
      this.UserChartDatamonth[5].data[6] =
        this.signupdatamontharray.contract_manager_Jul;
      this.UserChartDatamonth[5].data[7] =
        this.signupdatamontharray.contract_manager_Aug;
      this.UserChartDatamonth[5].data[8] =
        this.signupdatamontharray.contract_manager_Sep;
      this.UserChartDatamonth[5].data[9] =
        this.signupdatamontharray.contract_manager_Oct;
      this.UserChartDatamonth[5].data[10] =
        this.signupdatamontharray.contract_manager_Nov;
      this.UserChartDatamonth[5].data[11] =
        this.signupdatamontharray.contract_manager_Dec;

      this.signupFlag = false;
    });
  }
  toggleaLeadsgrid(e: any) {
    //  console.warn('Leads', e.checked);

    if (e.checked === true) {
      //  console.warn('Leads', e.checked);
      this.LeadsgridFlag = true;
    } else {
      //  console.warn('Leads', e.checked);
      this.LeadsgridFlag = false;
    }
  }

  // toggle fuction for block active distributors
  toggleactivedistributors(e: any) {
    //  console.warn('distributors', e.checked);

    if (e.checked === true) {
      //  console.warn('distributors', e.checked);
      this.activedistributorsFlag = true;
      this.distributorsListView();
    } else {
      //  console.warn('distributors', e.checked);
      this.activedistributorsFlag = false;
    }
  }

  distributorsListView() {
    this.distributorListdatatableflag = true;

    const endpointc = 'api/userlistview-count';
    const endpoint = 'api/userlistview';
    const data: any = {
      // How many data showing in first page
      secret: this.coockie.get('secret'),
      token: this.coockie.get('jwtToken'),
      source: '',
      condition: {
        // condition for how much data show in one time
        limit: 10,
        skip: 0,
      },
      sort: {
        type: 'desc', // data sort by decreasing order
        field: 'children', // data sort by first name
      },
      searchcondition: { type: 'distributor' },
    };

    this.Apiservice.getDataWithoutToken(data, endpoint).subscribe(
      (res: any) => {
        this.distributorListdatatable = res.results.res; // sarch count  (How many data there)
        // setTimeout(() => {
        // this.distributorListdatatable = this.adminarray.mostactive_distributors
        // this.distributorListdatatableflag = false;
        // }, 100);

        this.Apiservice.getDataWithoutToken(data, endpointc).subscribe(
          (res: any) => {
            this.datesearchsourcecount = res.count; // sarch count  (How many data there)
            this.distributorListdatatableflag = false;
          }
        );
      }
    );
  }

  // toggle fuction for block active salesmanagers
  toggleactivesalesmanagers(e: any) {
    //  console.warn('salesmanagers', e.checked);

    if (e.checked === true) {
      //  console.warn('salesmanagers', e.checked);
      this.activetoggleactivesalesmanagersFlag = true;
      this.salesmanagersListView();
    } else {
      //  console.warn('salesmanagers', e.checked);
      this.activetoggleactivesalesmanagersFlag = false;
    }
  }

  salesmanagersListView() {
    this.salesListdatatableflag = true;
    const endpointc = 'api/userlistview-count';
    const endpoint = 'api/userlistview';
    const data: any = {
      // How many data showing in first page
      secret: this.coockie.get('secret'),
      token: this.coockie.get('jwtToken'),
      source: '',
      condition: {
        // condition for how much data show in one time
        limit: 10,
        skip: 0,
      },
      sort: {
        type: 'desc', // data sort by decreasing order
        field: 'logincounts', // data sort by first name
      },
      searchcondition: { type: 'sales-person' },
    };

    this.Apiservice.getDataWithoutToken(data, endpointc).subscribe(
      (res: any) => {
        this.datesearchsourcecount = res.count; // sarch count  (How many data there)
        this.salesListdatatableflag = false;
      }
    );

    this.Apiservice.getDataWithoutToken(data, endpoint).subscribe(
      (res: any) => {
        this.salesmanagerListdatatable = res.results.res; // sarch count  (How many data there)
        // this.salesmanagerListdatatable = this.adminarray.mostactive_sales_persons; // sarch count  (How many data there)
        this.salesListdatatableflag = false;
      }
    );
  }
  // toggle fuction for block active Consultants
  toggleactiveconsultants(e: any) {
    //  console.warn('consultants', e.checked);

    if (e.checked === true) {
      //  console.warn('consultants', e.checked);
      this.activetoggleactiveconsultantsFlag = true;
      this.techconsultantsListView();
    } else {
      //  console.warn('consultants', e.checked);
      this.activetoggleactiveconsultantsFlag = false;
      this.technorecordfound = false;
      this.techconsultantsListdatatable = [];
      this.isrefresh_tech = false;
    }
  }
  toggleactivedistributer(e: any) {
    //  console.warn('consultants', e.checked);

    if (e.checked === true) {
      //  console.warn('consultants', e.checked);
      this.activetoggleactivedisributerFlag = true;
      this.distributorListView();
    } else {
      //  console.warn('consultants', e.checked);
      this.activetoggleactivedisributerFlag = false;
      this.distributorListdatatable = [];
      this.distributornorecordfound = false;
      this.isrefresh_distributor = false;
    }
  }
  distributorListView() {
    this.distributerListdatatableflag = true;
    const endpointc = 'api/userlistview-count';
    const endpoint = 'api/userlistview';
    const data: any = {
      // How many data showing in first page
      secret: this.coockie.get('secret'),
      token: this.coockie.get('jwtToken'),
      source: '',
      condition: {
        // condition for how much data show in one time
        limit: 7,
        skip: 0,
      },
      sort: {
        type: 'desc', // data sort by decreasing order
        field: 'logincounts', // data sort by first name
      },
      searchcondition: { type: 'distributor' },
    };

    this.Apiservice.getDataWithoutToken(data, endpointc).subscribe(
      (res: any) => {
        this.distributor_datesearchsourcecount = res.count; // sarch count  (How many data there)
        this.distributerListdatatableflag = false;
      }
    );

    this.Apiservice.getDataWithoutToken(data, endpoint).subscribe(
      (res: any) => {
        this.distributorListdatatable = res.results.res; // sarch count  (How many data there)

        // this.techconsultantsListdatatable = this.adminarray.mostactive_technological_consultant; // sarch count  (How many data there)
        this.distributerListdatatableflag = false;
      }
    );
  }
  techconsultantsListView() {
    this.techconsultantsListdatatableflag = true;
    const endpointc = 'api/userlistview-count';
    const endpoint = 'api/userlistview';
    const data: any = {
      // How many data showing in first page
      secret: this.coockie.get('secret'),
      token: this.coockie.get('jwtToken'),
      source: '',
      condition: {
        // condition for how much data show in one time
        limit: 7,
        skip: 0,
      },
      sort: {
        type: 'desc', // data sort by decreasing order
        field: 'logincounts', // data sort by first name
      },
      searchcondition: { type: 'technological-consultant' },
    };

    this.Apiservice.getDataWithoutToken(data, endpointc).subscribe(
      (res: any) => {
        this.datesearchsourcecount = res.count; // sarch count  (How many data there)
        this.techconsultantsListdatatableflag = false;
      }
    );

    this.Apiservice.getDataWithoutToken(data, endpoint).subscribe(
      (res: any) => {
        this.techconsultantsListdatatable = res.results.res; // sarch count  (How many data there)

        // this.techconsultantsListdatatable = this.adminarray.mostactive_technological_consultant; // sarch count  (How many data there)
        this.techconsultantsListdatatableflag = false;
      }
    );
  }
  leadlist() {
    const endpointc = 'api/leadlistview-count';
    const endpoint = 'api/leadlistview';
    const data: any = {
      // How many data showing in first page
      secret: this.coockie.get('secret'),
      token: this.coockie.get('jwtToken'),
      source: '',
      condition: {
        // condition for how much data show in one time
        limit: 5,
        skip: 0,
      },
      sort: {
        type: 'desc', // data sort by decreasing order
        field: 'createdon_datetime', // data sort by first name
      },
      searchcondition: { type: 'lead' },
    };

    this.Apiservice.getDataWithoutToken(data, endpoint).subscribe(
      (res: any) => {
        console.log(res);
        this.leadListdatatable = res.results.res; // sarch count  (How many data there)
        this.NewlyAddedtableflag = false;
      }
    );

    this.Apiservice.getDataWithoutToken(data, endpointc).subscribe(
      (res: any) => {
        this.datesearchsourcecount = res.count; // sarch count  (How many data there)
      }
    );
  }

  togglejobtickets(e: any) {
    //  console.warn('jobtickets', e.checked)

    if (e.checked === true) {
      console.warn('jobtickets', e.checked);
      this.jobticketsFlag = true;
      this.jobticketsListdatatableflag = true;

      this.jobticketsListView();
    } else {
      //  console.warn('jobtickets', e.checked);
      this.jobticketsFlag = false;
      this.jobticketnorecordfound = false;
      this.jobcategoryListdatatable = [];
      this.isrefresh_job_ticket = false;
    }
  }

  jobticketsListView() {
    // console.log(this.adminarray.recentjobtickets,'kkkkkkkkkkkkkkk')

    // this.jobcategoryListdatatable = this.adminarray.recentjobtickets; // sarch count  (How many data there)
    // setTimeout(() => {
    //   this.jobticketsListdatatableflag = false;
    // }, 500);
    // return;
    this.jobticketsListdatatableflag = true;
    const endpointc = 'api/getjobticketlistdata-count';
    const endpoint = 'api/getjobticketlistdata';
    const data: any = {
      // How many data showing in first page
      secret: this.userservice.secret,
      token: this.userservice.jwttoken,
      source: '',
      condition: {
        // condition for how much data show in one time
        limit: 7,
        skip: 0,
      },
      sort: {
        type: 'desc', // data sort by decreasing order
        field: 'fullname', // data sort by first name
      },
      // searchcondition: { type: 'getjobticketcategorylistdata' },
    };

    this.Apiservice.getDataWithoutToken(data, endpointc).subscribe(
      (res: any) => {
        this.datesearchsourcecount = res.count; // sarch count  (How many data there)
        this.jobticketsListdatatableflag = false;
      }
    );

    this.Apiservice.getDataWithoutToken(data, endpoint).subscribe(
      (res: any) => {
        this.jobcategoryListdatatable = res.results.res; // sarch count  (How many data there)
        // this.jobcategoryListdatatable = this.adminarray.recentjobtickets; // sarch count  (How many data there)
        this.jobticketsListdatatableflag = false;
      }
    );
  }

  toggleSignature(e: any) {
    //  console.warn('Signature', e.checked);

    if (e.checked === true) {
      //  console.warn('Signature', e.checked);
      this.SignatureFlag = true;

      this.signatureListView();
    } else {
      //  console.warn('Signature', e.checked);
      this.SignatureFlag = false;
      this.contract_pending_signaturenorecordfound = false;
      this.signatureListdatatable = [];
      this.isrefresh_contract_pending_signature = false;
    }
  }

  signatureListView() {
    this.signatureListdatatable = [];
    this.signatureListdatatable = this.adminarray.contract_pending_signature;
  }

  toggleWebinars(e: any) {
    //  console.warn('Webinars', e.checked);

    if (e.checked === true) {
      //  console.warn('Webinars', e.checked);
      this.WebinarsFlag = true;
    } else {
      //  console.warn('Webinars', e.checked);
      this.WebinarsFlag = false;
    }
  }

  toggleScheduled(e: any) {
    //  console.warn('Scheduled', e.checked);
    this.ScheduledFlagScheduledFlag = false;

    if (e.checked === true) {
      //  console.warn('Scheduled', e.checked);
      this.ScheduledFlag = true;
      this.Scheduleddatatableflag = true;

      this.schedule_appointments('toggle');
    } else {
      //  console.warn('Scheduled', e.checked);
      this.schedule_datatable = [];
      this.ScheduledFlagScheduledFlag = true;
      this.scheduleappointmentnorecordfound = false;
      this.ScheduledFlag = false;
      this.isrefresh_schedule_appointment = false;
      // this.dashboardlist();
    }
  }

  toggleCommission(e: any) {
    //  console.warn('Scheduled', e.checked);
    this.ScheduledFlagCommissionFlag = false;

    if (e.checked === true) {
      //  console.warn('Scheduled', e.checked);
      this.CommissionFlag = true;
      this.Commmissiondatatableflag = true;

      this.commission_appointments();
    } else {
      //  console.warn('Scheduled', e.checked);
      this.ScheduledFlagCommissionFlag = true;
      this.commissionnorecordfound = false;
      this.CommissionFlag = false;
      this.commission_datatable = [];
      this.isrefresh_commission = false;
      // this.dashboardlist();
    }
  }

  toggleNewlyAddedFlag(e: any) {
    //  console.warn('NewlyAdded', e.checked);
    this.NewlyAddedtableflag = false;
    if (e.checked === true) {
      //  console.warn('NewlyAdded', e.checked);
      this.NewlyAddedtableflag = true;
      this.NewlyAddedFlag = true;
      this.leadlist();
    } else {
      //  console.warn('NewlyAdded', e.checked);
      this.NewlyAddedFlag = false;
    }
  }

  toggleProducts(e: any) {
    //  console.warn('Products', e.checked);

    if (e.checked === true) {
      //  console.warn('Products', e.checked);
      this.ProductsFlag = true;

      this.productListView(1);
    } else {
      //  console.warn('Products', e.checked);
      this.ProductsFlag = false;
    }
  }
  checkbox1(val, flag) {

  }
  onClickcalender(val, flag) {
    console.log(val, 'flag', flag);
    let data = {

      secret: this.coockie.get('secret'),
      token: this.coockie.get('jwtToken'),
      type: flag,
      flag: val
    };
    this.Apiservice.getDataWithoutToken(data, 'api/updated-status-change-for-admin').subscribe(
      (res: any) => {
        if (res.status == 'success') {
          if (flag == 'discoverycall') {
            console.log('ppppppppppppppppppppp');

            this.coockie.set('gameplancall', val, undefined, '/');
          }
          if (flag == 'onbordingcall') {
            this.coockie.set('onbordingcall', val, undefined, '/');
          }
          if (flag == 'commission') {
            this.coockie.set('viewcommissionflag', val, undefined, '/');
          }
        }
      })
  }
  checkbox(val, id, flag) {
    // console.log(val);
    let data: any = {}
    if (val == true) {
      if (flag == 'view') {
        data = {

          secret: this.coockie.get('secret'),
          token: this.coockie.get('jwtToken'),
          viewcommissionflag: true,
          _id: id
        };
        this.addcommission = true;
        this.updatetotechcommission = false;
      }
      // console.log(val);
      if (flag == 'update') {
        data = {

          secret: this.coockie.get('secret'),
          token: this.coockie.get('jwtToken'),
          viewcommissionflag: false,
          _id: id
        };
        this.addcommission = false;
        this.updatetotechcommission = true;
      }
      this.Apiservice.getDataWithoutToken(data, 'api/viwetechcommission').subscribe(
        (res: any) => {
          if (res.status == 'Success') {

          }
        })
    }


  }
  productListView(val) {
    this.Productsdetatableflag = true;
    const endpointc = 'api/productlistview-count';
    const endpoint = 'api/productlistview';
    const data: any = {
      // How many data showing in first page
      secret: this.coockie.get('secret'),
      token: this.coockie.get('jwtToken'),
      source: '',
      condition: {
        // condition for how much data show in one time
        limit: 5,
        skip: 0,
      },
      sort: {
        type: 'desc', // data sort by decreasing order
        field: 'fullname', // data sort by first name
      },
      searchcondition: {
        status: val,
      },
    };

    this.Apiservice.getDataWithoutToken(data, endpointc).subscribe(
      (res: any) => {
        this.datesearchsourcecount = res.count; // sarch count  (How many data there)
        this.Productsdetatableflag = false;
      }
    );

    this.Apiservice.getDataWithoutToken(data, endpoint).subscribe(
      (res: any) => {
        this.productlisttable = res.results.res; // sarch count  (How many data there)
        console.log(this.productlisttable, 'vbnmm,mj,j,kj,k');
        this.Productsdetatableflag = false;
      }
    );

    // this.productlisttable = [];
    // this.productlisttable = this.dashboardlistarray.Products_list;
  }

  toggleResources(e: any) {
    //  console.warn('Resources', e.checked);

    if (e.checked === true) {
      //  console.warn('Resources', e.checked)
      this.ResourcesFlag = true;

      this.resourceListView(1);
    } else {
      //  console.warn('Resources', e.checked);
      this.ResourcesFlag = false;
    }
  }

  resourceListView(val) {
    this.Resourcesdetatableflag = true;
    const endpointc = 'api/resourcelistview-count';
    const endpoint = 'api/resourcelistview';
    const data: any = {
      // How many data showing in first page
      secret: this.coockie.get('secret'),
      token: this.coockie.get('jwtToken'),
      source: '',
      condition: {
        // condition for how much data show in one time
        limit: 5,
        skip: 0,
      },
      sort: {
        type: 'desc', // data sort by decreasing order
        field: 'fullname', // data sort by first name
      },
      searchcondition: {
        status: val,
      },
      // searchcondition: { type: 'getjobticketcategorylistdata' },
    };

    this.Apiservice.getDataWithoutToken(data, endpointc).subscribe(
      (res: any) => {
        this.datesearchsourcecount = res.count; // sarch count  (How many data there)
        this.Resourcesdetatableflag = false;
      }
    );

    this.Apiservice.getDataWithoutToken(data, endpoint).subscribe(
      (res: any) => {
        this.resourcelisttable = res.results.res; // sarch count  (How many data there)

        this.Resourcesdetatableflag = false;
      }
    );

    // this.resourcelisttable = [];
    // this.resourcelisttable = this.dashboardlistarray.resources_list;
  }

  copyText(val: string) {
    const selBox = document.createElement('textarea');
    selBox.style.position = 'fixed';
    selBox.style.left = '0';
    selBox.style.top = '0';
    selBox.style.opacity = '0';
    selBox.value = val;
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand('copy');
    document.body.removeChild(selBox);
  }

  //this slide function for most active practice

  public slideindex: any = 0;

  public slideindexsales_persons: any = 0;

  public slideindex_technological: any = 0;

  public slideindex_jobtickets: any = 0;

  public slideindex_signature: any = 0;

  public slideindex_products: any = 0;

  public slideindex_resources: any = 0;
  public slideindex_schedule: any = 0;
  public slideindex_commission: any = 0;
  public slideindex_lead: any = 0;

  slide(e: string) {
    // console.log('slide')
    // let i: any;
    let slides: any = document.getElementsByClassName('main_practice_block');
    let scount: any = document.querySelectorAll('.main_practice_block').length;
    if (this.slideindex > scount) this.slideindex = 1;
    let slideIndex: any = this.slideindex++;
    let dots: any = document.getElementsByClassName('dot');

    for (let i = 0; i < slides.length; i++) {
      slides[i].style.display = 'none';
    }
    // slideIndex++;
    if (slideIndex > slides.length) {
      slideIndex = 1;
    }
    for (let i = 0; i < dots.length; i++) {
      if (dots[i] != null)
        dots[i].className = dots[i].className.replace(' active', '');
    }
    if (slideIndex > 0 && slides[slideIndex - 1] != null)
      slides[slideIndex - 1].style.display = 'block';
    if (slideIndex > 0 && dots[slideIndex - 1] != null)
      dots[slideIndex - 1].className += ' active';
    // if (e == null || e == '') {
    //   this.slidetimeout = setTimeout(() => {
    //     this.slide('');
    //   }, 10000);
    // }
  }

  lead_list_slide(e: string) {
    // console.log('slideindex_lead +++++++++')
    // let i: any;
    // console.log('next slideforleadNext',  this.slideindex_lead)
    let slides: any = document.getElementsByClassName('main_practice_block11');
    let scount: any = document.querySelectorAll(
      '.main_practice_block11'
    ).length;
    if (this.slideindex_lead > scount) this.slideindex_lead = 1;
    let slideindex_lead: any = this.slideindex_lead++;
    let dots: any = document.getElementsByClassName('dot');

    for (let i = 0; i < slides.length; i++) {
      slides[i].style.display = 'none';
    }
    // slideIndex++;
    if (slideindex_lead > slides.length) {
      slideindex_lead = 1;
    }
    for (let i = 0; i < dots.length; i++) {
      if (dots[i] != null)
        dots[i].className = dots[i].className.replace(' active', '');
    }
    if (slideindex_lead > 0 && slides[slideindex_lead - 1] != null)
      slides[slideindex_lead - 1].style.display = 'block';
    if (slideindex_lead > 0 && dots[slideindex_lead - 1] != null)
      dots[slideindex_lead - 1].className += ' active';
    // if (e == null || e == '') {
    //   this.slideindex_leadtimeout = setTimeout(() => {
    //     this.lead_list_slide('');
    //   }, 10000);
    // }
  }
  slideforleadNext(idx: any) {
    // console.log('next slideforleadNext', idx, this.slideindex_schedule)

    this.slideindex_lead = 0;
    this.slideindex_lead = idx + 1;
    // let i: any;
    let slides: any = document.getElementsByClassName('main_practice_block11');
    let scount: any = document.querySelectorAll(
      '.main_practice_block11'
    ).length;

    // console.log('next 111 slideforschdeuleNext', this.slideindex_schedule, scount)
    if (this.slideindex_lead >= scount) {
      this.slideindex_lead = 0;
    }
    let slideindex_lead: any = this.slideindex_lead++;

    let dots: any = document.getElementsByClassName('dot');

    for (let i = 0; i < slides.length; i++) {
      slides[i].style.display = 'none';
    }

    slideindex_lead++;
    if (slideindex_lead > slides.length) {
      slideindex_lead = 1;
    }
    for (let i in dots.length) {
      // console.log(i, '__')
      if (dots[i] != null) {
        dots[i].className = dots[i].className.replace(' active', '');
      }
    }
    if (slideindex_lead > 0 && slides[slideindex_lead - 1] != null)
      slides[slideindex_lead - 1].style.display = 'block';
    if (slideindex_lead > 0 && dots[slideindex_lead - 1] != null)
      dots[slideindex_lead - 1].className += ' active';

    // this.lead_list_slide('e');
  }
  slideforleadPrev(idx: any) {
    let slides: any = document.getElementsByClassName('main_practice_block11');
    let scount: any = document.querySelectorAll(
      '.main_practice_block11'
    ).length;
    this.slideindex_lead = idx;
    if (this.slideindex_lead <= 0) {
      this.slideindex_lead = scount;
    }
    let slideindex_lead: any = this.slideindex_lead;
    for (var i = slides.length - 1; i >= 0; i--) {
      slides[i].style.display = 'none';
    }
    if (slideindex_lead > 0 && slides[slideindex_lead - 1] != null) {
      slides[slideindex_lead - 1].style.display = 'block';
    }
    slideindex_lead--;
  }

  slideindex_schedule_slide(e: string) {
    // console.log('slideindex_schedule')
    // let i: any;
    let slides: any = document.getElementsByClassName('main_practice_block8');
    let scount: any = document.querySelectorAll('.main_practice_block8').length;
    if (this.slideindex_schedule > scount) this.slideindex_schedule = 1;
    let slideindex_schedule: any = this.slideindex_schedule++;
    let dots: any = document.getElementsByClassName('dot');

    for (let i = 0; i < slides.length; i++) {
      slides[i].style.display = 'none';
    }

    // slideindex_schedule++;
    if (slideindex_schedule > slides.length) {
      slideindex_schedule = 1;
    }
    for (let i = 0; i < dots.length; i++) {
      if (dots[i] != null)
        dots[i].className = dots[i].className.replace(' active', '');
    }
    if (slideindex_schedule > 0 && slides[slideindex_schedule - 1] != null)
      slides[slideindex_schedule - 1].style.display = 'block';
    if (slideindex_schedule > 0 && dots[slideindex_schedule - 1] != null)
      dots[slideindex_schedule - 1].className += ' active';
    // if (e == null || e == '') {
    //   this.slideindex_scheduletimeout = setTimeout(() => {
    //     this.slideindex_schedule_slide('');
    //   }, 10000);
    // }
  }
  slideforschdeuleNext(idx: any) {
    // console.log('next slideforschdeuleNext', idx, this.slideindex_schedule)

    this.slideindex_schedule = 0;
    this.slideindex_schedule = idx + 1;
    // let i: any;
    let slides: any = document.getElementsByClassName('main_practice_block8');
    let scount: any = document.querySelectorAll('.main_practice_block8').length;

    // console.log('next 111 slideforschdeuleNext', this.slideindex_schedule, scount)
    if (this.slideindex_schedule >= scount) {
      this.slideindex_schedule = 0;
    }
    let slideindex_schedule: any = this.slideindex_schedule++;

    let dots: any = document.getElementsByClassName('dot');

    for (let i = 0; i < slides.length; i++) {
      slides[i].style.display = 'none';
    }

    slideindex_schedule++;
    if (slideindex_schedule > slides.length) {
      slideindex_schedule = 1;
    }
    for (let i in dots.length) {
      // console.log(i, '__')
      if (dots[i] != null) {
        dots[i].className = dots[i].className.replace(' active', '');
      }
    }
    if (slideindex_schedule > 0 && slides[slideindex_schedule - 1] != null)
      slides[slideindex_schedule - 1].style.display = 'block';
    if (slideindex_schedule > 0 && dots[slideindex_schedule - 1] != null)
      dots[slideindex_schedule - 1].className += ' active';

    // this.slideindex_schedule_slide('e');
  }

  slideforCommissionNext(idx: any) {
    // console.log('next slideforschdeuleNext', idx, this.slideindex_schedule)

    this.slideindex_commission = 0;
    this.slideindex_commission = idx + 1;
    // let i: any;
    let slides: any = document.getElementsByClassName('main_practice_block9');
    let scount: any = document.querySelectorAll('.main_practice_block9').length;

    console.log(
      'next 111 slideforschdeuleNext',
      this.slideindex_schedule,
      scount
    );
    if (this.slideindex_commission >= scount) {
      this.slideindex_commission = 0;
    }
    let slideindex_commission: any = this.slideindex_commission++;

    let dots: any = document.getElementsByClassName('dot');

    for (let i = 0; i < slides.length; i++) {
      slides[i].style.display = 'none';
    }

    slideindex_commission++;
    if (slideindex_commission > slides.length) {
      slideindex_commission = 1;
    }
    for (let i in dots.length) {
      // console.log(i, '__')
      if (dots[i] != null) {
        dots[i].className = dots[i].className.replace(' active', '');
      }
    }
    if (slideindex_commission > 0 && slides[slideindex_commission - 1] != null)
      slides[slideindex_commission - 1].style.display = 'block';
    if (slideindex_commission > 0 && dots[slideindex_commission - 1] != null)
      dots[slideindex_commission - 1].className += ' active';

    // this.slideindex_schedule_slide('e');
  }

  slideforDistributorsNext(idx: any) {
    // console.log('next slideforDistributorsNext', idx, this.slideindex)

    this.slideindex = 0;
    this.slideindex = idx + 1;
    // let i: any;
    let slides: any = document.getElementsByClassName('main_practice_block');
    let scount: any = document.querySelectorAll('.main_practice_block').length;

    // console.log('next 111 slideforDistributorsNext', this.slideindex, scount)
    if (this.slideindex >= scount) {
      this.slideindex = 0;
    }
    let slideIndex: any = this.slideindex++;

    let dots: any = document.getElementsByClassName('dot');

    for (let i = 0; i < slides.length; i++) {
      slides[i].style.display = 'none';
    }

    slideIndex++;
    if (slideIndex > slides.length) {
      slideIndex = 1;
    }
    for (let i in dots.length) {
      // console.log(i, '__')
      if (dots[i] != null) {
        dots[i].className = dots[i].className.replace(' active', '');
      }
    }
    if (slideIndex > 0 && slides[slideIndex - 1] != null)
      slides[slideIndex - 1].style.display = 'block';
    if (slideIndex > 0 && dots[slideIndex - 1] != null)
      dots[slideIndex - 1].className += ' active';

    // this.slide('e');
  }

  listenLiblistingChangeMostActivePractices(val: any) {
    // console.log('listenLiblistingChangeMostActivePractices +++++', val);
    if (val.action == 'search') {
      document
        .querySelector('.admindashboard_practiceblocklist')
        .classList.remove('hide_practicelist');
      // this.practiceblockProgressBar = false;
    }
  }

  slideforDistributorsPrev(idx: any) {
    let slides: any = document.getElementsByClassName('main_practice_block');
    let scount: any = document.querySelectorAll('.main_practice_block').length;
    this.slideindex = idx;
    if (this.slideindex <= 0) {
      this.slideindex = scount;
    }
    let slideindex: any = this.slideindex;
    for (var i = slides.length - 1; i >= 0; i--) {
      slides[i].style.display = 'none';
    }
    if (slideindex > 0 && slides[slideindex - 1] != null) {
      slides[slideindex - 1].style.display = 'block';
    }
    slideindex--;
  }

  // slidesales_persons() {

  slideforschedulePrev(idx: any) {
    // console.log('++__+_+-slideforschedulePrev', idx)
    let slides: any = document.getElementsByClassName('main_practice_block8');
    let scount: any = document.querySelectorAll('.main_practice_block8').length;

    this.slideindex_schedule = idx;
    if (this.slideindex_schedule <= 0) {
      this.slideindex_schedule = scount;
    }
    let slideindex_schedule: any = this.slideindex_schedule;
    for (var i = slides.length - 1; i >= 0; i--) {
      slides[i].style.display = 'none';
    }
    if (slideindex_schedule > 0 && slides[slideindex_schedule - 1] != null) {
      slides[slideindex_schedule - 1].style.display = 'block';
    }
    slideindex_schedule--;
  }

  slideforCommissionPrev(idx: any) {
    // console.log('++__+_+-slideforschedulePrev', idx)
    let slides: any = document.getElementsByClassName('main_practice_block9');
    let scount: any = document.querySelectorAll('.main_practice_block9').length;

    this.slideindex_commission = idx;
    if (this.slideindex_commission <= 0) {
      this.slideindex_commission = scount;
    }
    let slideindex_commission: any = this.slideindex_commission;
    for (var i = slides.length - 1; i >= 0; i--) {
      slides[i].style.display = 'none';
    }
    if (
      slideindex_commission > 0 &&
      slides[slideindex_commission - 1] != null
    ) {
      slides[slideindex_commission - 1].style.display = 'block';
    }
    slideindex_commission--;
  }

  slidesales_persons(e: string) {
    // console.log('slidesales_persons')
    // let i: any;
    let slides: any = document.getElementsByClassName('main_practice_block2');
    let scount: any = document.querySelectorAll('.main_practice_block2').length;
    if (this.slideindexsales_persons > scount) this.slideindexsales_persons = 1;
    let slideIndexsales_persons: any = this.slideindexsales_persons++;
    let dots: any = document.getElementsByClassName('dot');

    for (let i = 0; i < slides.length; i++) {
      slides[i].style.display = 'none';
    }

    // slideIndex++;
    if (slideIndexsales_persons > slides.length) {
      slideIndexsales_persons = 1;
    }
    for (let i = 0; i < dots.length; i++) {
      if (dots[i] != null)
        dots[i].className = dots[i].className.replace(' active', '');
    }
    if (
      slideIndexsales_persons > 0 &&
      slides[slideIndexsales_persons - 1] != null
    )
      slides[slideIndexsales_persons - 1].style.display = 'block';
    if (
      slideIndexsales_persons > 0 &&
      dots[slideIndexsales_persons - 1] != null
    )
      dots[slideIndexsales_persons - 1].className += ' active';
    // if (e == null || e == '') {
    //   this.slidesales_personstimeout = setTimeout(() => {
    //     this.slidesales_persons('');
    //   }, 10000);
    // }
  }

  slideforsalespersonsNext(idx: any) {
    // console.log('next', idx, this.slideindexsales_persons)

    this.slideindexsales_persons = 0;
    this.slideindexsales_persons = idx + 1;
    // let i: any;
    let slides: any = document.getElementsByClassName('main_practice_block2');
    let scount: any = document.querySelectorAll('.main_practice_block2').length;

    // console.log('next 111', this.slideindexsales_persons, scount)
    if (this.slideindexsales_persons >= scount) {
      this.slideindexsales_persons = 0;
    }
    let slideIndexsales_persons: any = this.slideindexsales_persons++;

    let dots: any = document.getElementsByClassName('dot');

    for (let i = 0; i < slides.length; i++) {
      slides[i].style.display = 'none';
    }

    slideIndexsales_persons++;
    if (slideIndexsales_persons > slides.length) {
      slideIndexsales_persons = 1;
    }
    for (let i in dots.length) {
      // console.log(i, '__')
      if (dots[i] != null) {
        dots[i].className = dots[i].className.replace(' active', '');
      }
    }
    if (
      slideIndexsales_persons > 0 &&
      slides[slideIndexsales_persons - 1] != null
    )
      slides[slideIndexsales_persons - 1].style.display = 'block';
    if (
      slideIndexsales_persons > 0 &&
      dots[slideIndexsales_persons - 1] != null
    )
      dots[slideIndexsales_persons - 1].className += ' active';

    // this.slidesales_persons('e');
  }

  slideforsalespersonsPrev(idx: any) {
    //  // console.log('prev', idx)

    // let i: any;
    let slides: any = document.getElementsByClassName('main_practice_block2');
    let scount: any = document.querySelectorAll('.main_practice_block2').length;

    this.slideindexsales_persons = idx;
    if (this.slideindexsales_persons <= 0) {
      this.slideindexsales_persons = scount;
    }
    let slideindexsales_persons: any = this.slideindexsales_persons;
    for (var i = slides.length - 1; i >= 0; i--) {
      slides[i].style.display = 'none';
    }
    if (
      slideindexsales_persons > 0 &&
      slides[slideindexsales_persons - 1] != null
    ) {
      slides[slideindexsales_persons - 1].style.display = 'block';
    }
    slideindexsales_persons--;
  }

  // slide_technological() {

  slide_technological(e: string) {
    // console.log('slide_technological')
    // let i: any;
    let slides: any = document.getElementsByClassName('main_practice_block3');
    let scount: any = document.querySelectorAll('.main_practice_block3').length;
    if (this.slideindex_technological > scount)
      this.slideindex_technological = 1;
    let slideIndex_technological: any = this.slideindex_technological++;
    let dots: any = document.getElementsByClassName('dot');

    for (let i = 0; i < slides.length; i++) {
      slides[i].style.display = 'none';
    }

    // slideIndex++;
    if (slideIndex_technological > slides.length) {
      slideIndex_technological = 1;
    }
    for (let i = 0; i < dots.length; i++) {
      if (dots[i] != null)
        dots[i].className = dots[i].className.replace(' active', '');
    }
    if (
      slideIndex_technological > 0 &&
      slides[slideIndex_technological - 1] != null
    )
      slides[slideIndex_technological - 1].style.display = 'block';
    if (
      slideIndex_technological > 0 &&
      dots[slideIndex_technological - 1] != null
    )
      dots[slideIndex_technological - 1].className += ' active';
    // if (e == null || e == '') {
    //   this.slide_technologicaltimeout = setTimeout(() => {
    //     this.slide_technological('');
    //   }, 10000);
    // }
  }



  slidefortechnologicalNext(idx: any) {
    // console.log('next', idx, this.slideindex_technological)

    this.slideindex_technological = 0;
    this.slideindex_technological = idx + 1;
    // let i: any;
    let slides: any = document.getElementsByClassName('main_practice_block2');
    let scount: any = document.querySelectorAll('.main_practice_block2').length;

    // console.log('next 111', this.slideindex_technological, scount)
    if (this.slideindex_technological >= scount) {
      this.slideindex_technological = 0;
    }
    let slideIndex_technological: any = this.slideindex_technological++;

    let dots: any = document.getElementsByClassName('dot');

    for (let i = 0; i < slides.length; i++) {
      slides[i].style.display = 'none';
    }

    slideIndex_technological++;
    if (slideIndex_technological > slides.length) {
      slideIndex_technological = 1;
    }
    for (let i in dots.length) {
      // console.log(i, '__')
      if (dots[i] != null) {
        dots[i].className = dots[i].className.replace(' active', '');
      }
    }
    if (
      slideIndex_technological > 0 &&
      slides[slideIndex_technological - 1] != null
    )
      slides[slideIndex_technological - 1].style.display = 'block';
    if (
      slideIndex_technological > 0 &&
      dots[slideIndex_technological - 1] != null
    )
      dots[slideIndex_technological - 1].className += ' active';

    // this.slide_technological('e');
  }
  slidefordistributerPrev(idx: any) {
    //  // console.log('prev', idx)

    // let i: any;
    let slides: any = document.getElementsByClassName('main_practice_block3');
    let scount: any = document.querySelectorAll('.main_practice_block3').length;

    this.slideindex_technological = idx;
    if (this.slideindex_technological <= 0) {
      this.slideindex_technological = scount;
    }
    let slideindex_technological: any = this.slideindex_technological;
    for (var i = slides.length - 1; i >= 0; i--) {
      slides[i].style.display = 'none';
    }
    if (
      slideindex_technological > 0 &&
      slides[slideindex_technological - 1] != null
    ) {
      slides[slideindex_technological - 1].style.display = 'block';
    }
    slideindex_technological--;
  }
  slidefortechnologicalPrev(idx: any) {
    //  // console.log('prev', idx)

    // let i: any;
    let slides: any = document.getElementsByClassName('main_practice_block2');
    let scount: any = document.querySelectorAll('.main_practice_block2').length;

    this.slideindex_technological = idx;
    if (this.slideindex_technological <= 0) {
      this.slideindex_technological = scount;
    }
    let slideindex_technological: any = this.slideindex_technological;
    for (var i = slides.length - 1; i >= 0; i--) {
      slides[i].style.display = 'none';
    }
    if (
      slideindex_technological > 0 &&
      slides[slideindex_technological - 1] != null
    ) {
      slides[slideindex_technological - 1].style.display = 'block';
    }
    slideindex_technological--;
  }

  // slide_jobtickets() {

  slide_jobtickets(e: string) {
    // console.log('slide_jobtickets')
    // let i: any;
    let slides: any = document.getElementsByClassName('main_practice_block4');
    let scount: any = document.querySelectorAll('.main_practice_block4').length;
    if (this.slideindex_jobtickets > scount) this.slideindex_jobtickets = 1;
    let slideindex_jobtickets: any = this.slideindex_jobtickets++;
    let dots: any = document.getElementsByClassName('dot');

    for (let i = 0; i < slides.length; i++) {
      slides[i].style.display = 'none';
    }

    // slideIndex++;
    if (slideindex_jobtickets > slides.length) {
      slideindex_jobtickets = 1;
    }
    for (let i = 0; i < dots.length; i++) {
      if (dots[i] != null)
        dots[i].className = dots[i].className.replace(' active', '');
    }
    if (slideindex_jobtickets > 0 && slides[slideindex_jobtickets - 1] != null)
      slides[slideindex_jobtickets - 1].style.display = 'block';
    if (slideindex_jobtickets > 0 && dots[slideindex_jobtickets - 1] != null)
      dots[slideindex_jobtickets - 1].className += ' active';
    // if (e == null || e == '') {
    //   this.slide_jobticketstimeout = setTimeout(() => {
    //     this.slide_jobtickets('');
    //   }, 10000);
    // }
  }

  slideforjobticketsNext(idx: any) {
    // console.log('next', idx, this.slideindex_jobtickets)

    this.slideindex_jobtickets = 0;
    this.slideindex_jobtickets = idx + 1;
    // let i: any;
    let slides: any = document.getElementsByClassName('main_practice_block4');
    let scount: any = document.querySelectorAll('.main_practice_block4').length;

    // console.log('next 111', this.slideindex_jobtickets, scount)
    if (this.slideindex_jobtickets >= scount) {
      this.slideindex_jobtickets = 0;
    }
    let slideindex_jobtickets: any = this.slideindex_jobtickets++;

    let dots: any = document.getElementsByClassName('dot');

    for (let i = 0; i < slides.length; i++) {
      slides[i].style.display = 'none';
    }

    slideindex_jobtickets++;
    if (slideindex_jobtickets > slides.length) {
      slideindex_jobtickets = 1;
    }
    for (let i in dots.length) {
      // console.log(i, '__')
      if (dots[i] != null) {
        dots[i].className = dots[i].className.replace(' active', '');
      }
    }
    if (slideindex_jobtickets > 0 && slides[slideindex_jobtickets - 1] != null)
      slides[slideindex_jobtickets - 1].style.display = 'block';
    if (slideindex_jobtickets > 0 && dots[slideindex_jobtickets - 1] != null)
      dots[slideindex_jobtickets - 1].className += ' active';

    // this.slide_jobtickets('e');
  }
  slidefordistributerNext(idx: any) {
    // console.log('next', idx, this.slideindex_jobtickets)

    this.slideindex_jobtickets = 0;
    this.slideindex_jobtickets = idx + 1;
    // let i: any;
    let slides: any = document.getElementsByClassName('main_practice_block3');
    let scount: any = document.querySelectorAll('.main_practice_block3').length;

    // console.log('next 111', this.slideindex_jobtickets, scount)
    if (this.slideindex_jobtickets >= scount) {
      this.slideindex_jobtickets = 0;
    }
    let slideindex_jobtickets: any = this.slideindex_jobtickets++;

    let dots: any = document.getElementsByClassName('dot');

    for (let i = 0; i < slides.length; i++) {
      slides[i].style.display = 'none';
    }

    slideindex_jobtickets++;
    if (slideindex_jobtickets > slides.length) {
      slideindex_jobtickets = 1;
    }
    for (let i in dots.length) {
      // console.log(i, '__')
      if (dots[i] != null) {
        dots[i].className = dots[i].className.replace(' active', '');
      }
    }
    if (slideindex_jobtickets > 0 && slides[slideindex_jobtickets - 1] != null)
      slides[slideindex_jobtickets - 1].style.display = 'block';
    if (slideindex_jobtickets > 0 && dots[slideindex_jobtickets - 1] != null)
      dots[slideindex_jobtickets - 1].className += ' active';

    // this.slide_jobtickets('e');
  }
  slideforjobticketsPrev(idx: any) {
    // console.log('prev', idx)

    // let i: any;
    let slides: any = document.getElementsByClassName('main_practice_block4');
    let scount: any = document.querySelectorAll('.main_practice_block4').length;

    this.slideindex_jobtickets = idx;
    if (this.slideindex_jobtickets <= 0) {
      this.slideindex_jobtickets = scount;
    }
    let slideindex_jobtickets: any = this.slideindex_jobtickets;
    for (var i = slides.length - 1; i >= 0; i--) {
      slides[i].style.display = 'none';
    }
    if (
      slideindex_jobtickets > 0 &&
      slides[slideindex_jobtickets - 1] != null
    ) {
      slides[slideindex_jobtickets - 1].style.display = 'block';
    }
    slideindex_jobtickets--;
  }

  // slide_Contracts Pending Signature() {

  slide_signature(e: string) {
    // console.log('slide_signature')
    // let i: any;
    let slides: any = document.getElementsByClassName('main_practice_block5');
    let scount: any = document.querySelectorAll('.main_practice_block5').length;
    if (this.slideindex_signature > scount) this.slideindex_signature = 1;
    let slideindex_signature: any = this.slideindex_signature++;
    let dots: any = document.getElementsByClassName('dot');

    for (let i = 0; i < slides.length; i++) {
      slides[i].style.display = 'none';
    }

    // slideIndex++;
    if (slideindex_signature > slides.length) {
      slideindex_signature = 1;
    }
    for (let i = 0; i < dots.length; i++) {
      if (dots[i] != null)
        dots[i].className = dots[i].className.replace(' active', '');
    }
    if (slideindex_signature > 0 && slides[slideindex_signature - 1] != null)
      slides[slideindex_signature - 1].style.display = 'block';
    if (slideindex_signature > 0 && dots[slideindex_signature - 1] != null)
      dots[slideindex_signature - 1].className += ' active';
    // if (e == null || e == '') {
    //   this.slide_signaturetimeout = setTimeout(() => {
    //     this.slide_signature('');
    //   }, 10000);
    // }
  }
  slide_products(e: string) {
    // console.log('slide_products');
    let slides: any = document.getElementsByClassName('main_practice_block6');
    let scount: any = document.querySelectorAll('.main_practice_block6').length;
    if (this.slideindex_products > scount) this.slideindex_products = 1;
    let slideindex_products: any = this.slideindex_products++;
    let dots: any = document.getElementsByClassName('dot');

    for (let i = 0; i < slides.length; i++) {
      slides[i].style.display = 'none';
    }
    // console.log('slide_products+++', this.slideindex_products);

    // slideIndex++;
    if (slideindex_products > slides.length) {
      slideindex_products = 1;
    }
    for (let i = 0; i < dots.length; i++) {
      if (dots[i] != null)
        dots[i].className = dots[i].className.replace(' active', '');
    }
    if (slideindex_products > 0 && slides[slideindex_products - 1] != null)
      slides[slideindex_products - 1].style.display = 'block';
    if (slideindex_products > 0 && dots[slideindex_products - 1] != null)
      dots[slideindex_products - 1].className += ' active';
    // if (e == null || e == '') {
    //   this.slide_productstimeout = setTimeout(() => {
    //     this.slide_products('');
    //   }, 10000);
    // }
  }

  slideforsignatureNext(idx: any) {
    // console.log('next', idx, this.slideindex_signature)

    this.slideindex_signature = 0;
    this.slideindex_signature = idx + 1;
    // let i: any;
    let slides: any = document.getElementsByClassName('main_practice_block5');
    let scount: any = document.querySelectorAll('.main_practice_block5').length;

    // console.log('next 111', this.slideindex_signature, scount)
    if (this.slideindex_signature >= scount) {
      this.slideindex_signature = 0;
    }
    let slideindex_signature: any = this.slideindex_signature++;

    let dots: any = document.getElementsByClassName('dot');

    for (let i = 0; i < slides.length; i++) {
      slides[i].style.display = 'none';
    }

    slideindex_signature++;
    if (slideindex_signature > slides.length) {
      slideindex_signature = 1;
    }
    for (let i in dots.length) {
      // console.log(i, '__')
      if (dots[i] != null) {
        dots[i].className = dots[i].className.replace(' active', '');
      }
    }
    if (slideindex_signature > 0 && slides[slideindex_signature - 1] != null)
      slides[slideindex_signature - 1].style.display = 'block';
    if (slideindex_signature > 0 && dots[slideindex_signature - 1] != null)
      dots[slideindex_signature - 1].className += ' active';

    // this.slide_signature('e');
  }

  slideforsignaturePrev(idx: any) {
    let slides: any = document.getElementsByClassName('main_practice_block5');
    let scount: any = document.querySelectorAll('.main_practice_block5').length;
    this.slideindex_signature = idx;
    if (this.slideindex_signature <= 0) {
      this.slideindex_signature = scount;
    }
    let slideindex_signature: any = this.slideindex_signature;
    for (var i = slides.length - 1; i >= 0; i--) {
      slides[i].style.display = 'none';
    }
    if (slideindex_signature > 0 && slides[slideindex_signature - 1] != null) {
      slides[slideindex_signature - 1].style.display = 'block';
    }
    slideindex_signature--;
  }

  // slide_Contracts Products() {

  slideforproductNext(idx: any) {
    // console.log('next slideforproductNext', idx)

    this.slideindex_products = 0;
    this.slideindex_products = idx + 1;
    // let i: any;
    let slides: any = document.getElementsByClassName('main_practice_block6');
    let scount: any = document.querySelectorAll('.main_practice_block6').length;

    // console.log('next 111 slideforproductNext +++++', this.slideindex_products, scount)
    if (this.slideindex_products >= scount) {
      this.slideindex_products = 0;
    }
    let slideindex_products: any = this.slideindex_products++;

    let dots: any = document.getElementsByClassName('dot');

    for (let i = 0; i < slides.length; i++) {
      slides[i].style.display = 'none';
    }

    slideindex_products++;
    if (slideindex_products > slides.length) {
      slideindex_products = 1;
    }
    for (let i in dots.length) {
      // console.log(i, '__')
      if (dots[i] != null) {
        dots[i].className = dots[i].className.replace(' active', '');
      }
    }
    if (slideindex_products > 0 && slides[slideindex_products - 1] != null)
      slides[slideindex_products - 1].style.display = 'block';
    if (slideindex_products > 0 && dots[slideindex_products - 1] != null)
      dots[slideindex_products - 1].className += ' active';

    // this.slide_products('e');
  }

  slideforproductPrev(idx: any) {
    // console.log('prev', idx)

    // let i: any;
    let slides: any = document.getElementsByClassName('main_practice_block6');
    let scount: any = document.querySelectorAll('.main_practice_block6').length;

    this.slideindex_products = idx;
    if (this.slideindex_products <= 0) {
      this.slideindex_products = scount;
    }
    let slideindex_products: any = this.slideindex_products;
    for (var i = slides.length - 1; i >= 0; i--) {
      slides[i].style.display = 'none';
    }
    if (slideindex_products > 0 && slides[slideindex_products - 1] != null) {
      slides[slideindex_products - 1].style.display = 'block';
    }
    slideindex_products--;
  }

  // slide_Resources() {

  slide_resources(e: string) {
    // console.log('slide_resources')
    // let i: any;
    let slides: any = document.getElementsByClassName('main_practice_block7');
    let scount: any = document.querySelectorAll('.main_practice_block7').length;
    if (this.slideindex_resources > scount) this.slideindex_resources = 1;
    let slideindex_resources: any = this.slideindex_resources++;
    let dots: any = document.getElementsByClassName('dot');

    for (let i = 0; i < slides.length; i++) {
      slides[i].style.display = 'none';
    }

    // slideIndex++;
    if (slideindex_resources > slides.length) {
      slideindex_resources = 1;
    }
    for (let i = 0; i < dots.length; i++) {
      if (dots[i] != null)
        dots[i].className = dots[i].className.replace(' active', '');
    }
    if (slideindex_resources > 0 && slides[slideindex_resources - 1] != null)
      slides[slideindex_resources - 1].style.display = 'block';
    if (slideindex_resources > 0 && dots[slideindex_resources - 1] != null)
      dots[slideindex_resources - 1].className += ' active';
    // if (e == null || e == '') {
    //   this.slide_resourcestimeout = setTimeout(() => {
    //     this.slide_resources('');
    //   }, 10000);
    // }
  }

  slideforesourceNext(idx: any) {
    // console.log('next', idx, this.slideindex_resources)

    this.slideindex_resources = 0;
    this.slideindex_resources = idx + 1;
    // let i: any;
    let slides: any = document.getElementsByClassName('main_practice_block7');
    let scount: any = document.querySelectorAll('.main_practice_block7').length;

    // console.log('next 111', this.slideindex_resources, scount)
    if (this.slideindex_resources >= scount) {
      this.slideindex_resources = 0;
    }
    let slideindex_resources: any = this.slideindex_resources++;

    let dots: any = document.getElementsByClassName('dot');

    for (let i = 0; i < slides.length; i++) {
      slides[i].style.display = 'none';
    }

    slideindex_resources++;
    if (slideindex_resources > slides.length) {
      slideindex_resources = 1;
    }
    for (let i in dots.length) {
      // console.log(i, '__')
      if (dots[i] != null) {
        dots[i].className = dots[i].className.replace(' active', '');
      }
    }
    if (slideindex_resources > 0 && slides[slideindex_resources - 1] != null)
      slides[slideindex_resources - 1].style.display = 'block';
    if (slideindex_resources > 0 && dots[slideindex_resources - 1] != null)
      dots[slideindex_resources - 1].className += ' active';

    // this.slide_resources('e');
  }

  slideforesourcePrev(idx: any) {
    // console.log('prev', idx)

    // let i: any;
    let slides: any = document.getElementsByClassName('main_practice_block7');
    let scount: any = document.querySelectorAll('.main_practice_block7').length;

    this.slideindex_resources = idx;
    if (this.slideindex_resources <= 0) {
      this.slideindex_resources = scount;
    }
    let slideindex_resources: any = this.slideindex_resources;
    for (var i = slides.length - 1; i >= 0; i--) {
      slides[i].style.display = 'none';
    }
    if (slideindex_resources > 0 && slides[slideindex_resources - 1] != null) {
      slides[slideindex_resources - 1].style.display = 'block';
    }
    slideindex_resources--;
  }

  public selectedIds: any = {};

  onLiblistingButtonChange(val: any) {
    // console.log('onLiblistingButtonChange++++', val)
    if (val.action == 'searchbar') {
      this.openDialogSearch();
    }
  }
  //Search Modal fire here for
  openDialogSearch() {
    this.selectedIds = {
      locationVal: '',
      name: '',
      email: '',
      practicename: [],
    };

    const dialogRef = this.dialog.open(newAdminSearchmodal, {
      data: this.selectedIds,
      panelClass: 'searchmodalnewdashboard',
    });

    dialogRef.afterClosed().subscribe((result: any) => {
      // console.log('The dialog was closed', result);
      // console.warn(this.mostActivemodal_search_settings)

      let count: number = 0;
      if (result.email != '') {
        this.searchsettings.textsearch[0].value = result.email;
        count++;
      }
      if (count == 0) {
        return;
      }
      this.distributorListdatatableflag = true;
      setTimeout(() => {
        this.distributorListdatatableflag = false;
      }, 800);
    });
  }

  categoryListView(flag) {
    console.log(this.Trainingdata.length, ':::::');

    //for category list in dashboard
    this.blockUI.start('Loading.......');
    if (this.Trainingdata.length != 0) {
      this.getalltrainingdatanodatafound = false;
      this.allTypeOfList = [];
      if (flag == 'training') {
        const data = {
          source: 'training_category_management',
          condition: { is_trash: { $ne: 1 } },
          sort: {
            type: 'desc',
            field: 'createdon_datetime',
          },
          limit: 10,
          skip: 0,
        };
        this.Apiservice.getDataWithoutToken(
          data,
          'api10/getalltrainingdata'
        ).subscribe((res: any) => {
          // console.log(res.results.res);
          this.getalltrainingdata = res.results.res;
          if (this.getalltrainingdata.length == 0) {
            this.getalltrainingdatanodatafound = true;
          }
          this.blockUI.stop();
        });
      }
      if (flag == 'lesson') {
        const data = {
          source: 'manage_lession_view',
          condition: { is_trash: { $ne: 1 } },
          sort: {
            type: 'desc',
            field: 'createdon_datetime',
          },
          limit: 10,
          skip: 0,
        };
        this.Apiservice.getDataWithoutToken(data, 'api10/getlessondata').subscribe(
          (res: any) => {
            // console.log(res.results.res);
            this.getlessondatalist = res.results.res;
            this.blockUI.stop();
          }
        );
      }
    } else {
      this.adminlist(['admin']);
      this.blockUI.stop();
    }
  }

  FieldsChange(event: any, text_val: string) {
    console.log(text_val, 'jukjhfkhj', event);
    let va = event.source.name;
    va = va.split('-').join('');
    console.log(va);
    // if (event.checked) {
    //   this.allflags[va] = true
    // } else {
    //   this.allflags[va] = false
    // }
    this.allflagblock = '';
    this.getalltrainingdata = [];
    this.getlessondatalist = [];
    this.productlisttable = [];
    // this.usertype = [];
    this.allTypeOfList = [];
    this.resourcelisttable = [];
    this.allcalenderdata = [];
    this.search_by_text = [];
    this.isCheckedName = event.source.name;
    let text_val_arr = text_val.split(':')[0];
    text_val_arr = text_val_arr.replace(/\s/g, '');
    console.log(text_val_arr);
    this.search_by = false;
    this.refresh_user_management = false;
    if (text_val_arr == 'UserSummary') {
      this.allflagblock = 'alllist';
      this.header_text = 'User Summary';
      this.header_text_sub = [];
      this.individual_calendar_text = [];
      this.individual_training_list_text = [];
      console.log('------0-0-', text_val_arr);
      this.Trainingdata = [];
      this.leads = [];
      this.calender = [];
      this.contracts = [];
      this.calender_status = [];
      // this.usersflg = true;

      // this.Trainingflg = false;
      if (!event.checked) {
        this.individual_usersummary_text.forEach((element, index) => {
          if (element == text_val.split(':')[1]) this.individual_usersummary_text.splice(index, 1);
        });
        console.log(text_val.split(':')[1], '<<<<<<<');
        console.log(this.individual_usersummary_text, '<<<<<<<');

        console.log(this.isCheckedName, '>>>>>>>>');
        let index = this.usertype.indexOf(this.isCheckedName);
        if (index > -1) {
          this.usertype.splice(index, 1);
        }
        this.allflags[va] = false;
        this.usersflg[va] = false;
      } else {
        this.individual_usersummary_text.push(text_val.split(':')[1]);
        Object.keys(this.Trainingflg).forEach(
          (key) => (this.Trainingflg[key] = false)
        );
        Object.keys(this.allflags1).forEach(
          (key) => (this.allflags1[key] = false)
        );
        Object.keys(this.allflags2).forEach(
          (key) => (this.allflags2[key] = false)
        );
        Object.keys(this.Leadsflg).forEach(
          (key) => (this.Leadsflg[key] = false)
        );
        Object.keys(this.allflags3).forEach(
          (key) => (this.allflags3[key] = false)
        );
        Object.keys(this.calenderflg).forEach(
          (key) => (this.calenderflg[key] = false)
        );
        Object.keys(this.allflags4).forEach(
          (key) => (this.allflags4[key] = false)
        );
        Object.keys(this.contractsflg).forEach(
          (key) => (this.contractsflg[key] = false)
        );
        this.allflags[va] = true;
        this.usersflg[va] = true;
        this.usertype.push(this.isCheckedName);
      }
      this.header_text_sub = this.individual_usersummary_text;
      this.adminlist(this.usertype);
    }
    if (text_val_arr == 'Traininglist') {
      this.allflagblock = 'traininglist';
      console.log(this.header_text_sub, '<<<<<<<<<<<<<<');

      this.header_text = 'Training List';
      this.header_text_sub = [];
      this.individual_calendar_text = [];
      this.individual_usersummary_text = [];
      this.allflags1 = [];
      this.Trainingflg = [];
      console.log('------0-0-', text_val_arr);
      // this.usersflg = false;
      // this.Trainingflg = true;
      console.log(this.header_text_sub, '<<<<<<<<<<<<<<');

      this.usertype = [];
      this.leads = [];
      this.calender = [];
      this.contracts = [];
      this.allTypeOfList = [];
      this.calender_status = [];
      if (!event.checked) {
        console.log(this.isCheckedName, '>>>>>>>>');
        this.individual_training_list_text.forEach((element, index) => {
          if (element == text_val.split(':')[1]) this.individual_training_list_text.splice(index, 1);
        });
        this.allflags1[va] = false;
        let index = this.Trainingdata.indexOf(this.isCheckedName);
        if (index > -1) {
          this.Trainingdata.splice(index, 1);
        }
      } else {
        this.individual_training_list_text = [text_val.split(':')[1]];
        Object.keys(this.usersflg).forEach(
          (key) => (this.usersflg[key] = false)
        );
        Object.keys(this.allflags).forEach(
          (key) => (this.allflags[key] = false)
        );
        Object.keys(this.allflags2).forEach(
          (key) => (this.allflags2[key] = false)
        );
        Object.keys(this.Leadsflg).forEach(
          (key) => (this.Leadsflg[key] = false)
        );
        Object.keys(this.allflags3).forEach(
          (key) => (this.allflags3[key] = false)
        );
        Object.keys(this.calenderflg).forEach(
          (key) => (this.calenderflg[key] = false)
        );
        Object.keys(this.allflags4).forEach(
          (key) => (this.allflags4[key] = false)
        );
        Object.keys(this.contractsflg).forEach(
          (key) => (this.contractsflg[key] = false)
        );
        let index = this.Trainingdata.indexOf(this.isCheckedName);
        if (index > -1) {
          this.Trainingdata.splice(index, 1);
        }
        this.allflags1[va] = true;
        this.Trainingflg[va] = true;
        this.Trainingdata = [this.isCheckedName];
      }
      this.header_text_sub = this.individual_training_list_text;
      console.log(this.individual_training_list_text.length, ';;;;;;;;;;;;;;;');
      console.log(this.header_text_sub.length, ';;;;;;;;;;;;;;;');

    }
    if (text_val_arr == 'Leads') {
      this.header_text = 'Leads';
      console.log('------0-0-', text_val_arr);
      // this.usersflg = false;
      // this.Trainingflg = true;

      this.usertype = [];
      this.Trainingdata = [];
      this.calender = [];
      this.contracts = [];
      this.allTypeOfList = [];
      this.calender_status = [];
      if (!event.checked) {
        if (event.source.name == 'LeadsAttemptingContact') {
          this.leads_status.forEach((element, index) => {
            // console.log('index*********',index);

            if (element.status == 'Pending Approval') {
              this.leads_status.splice(index, 1);
            }
          });
        }
        if (event.source.name == 'LeadsContractSent') {
          this.leads_status.forEach((element, index) => {
            // console.log('index*********',index);

            if (element.status == 'Contract Signed') {
              this.leads_status.splice(index, 1);
            }
          });
        }
        if (event.source.name == 'LeadsDisqualifiedLost') {
          this.leads_status.forEach((element, index) => {
            // console.log('index*********',index);

            if (element.status == 'Contract Declined') {
              this.leads_status.splice(index, 1);
            }
          });
        }
        this.allflags2[va] = false;
        let index = this.leads.indexOf(this.isCheckedName);
        if (index > -1) {
          this.leads.splice(index, 1);
        }
        this.Leadsflg[va] = false;
      } else {
        Object.keys(this.Trainingflg).forEach(
          (key) => (this.Trainingflg[key] = false)
        );
        Object.keys(this.usersflg).forEach(
          (key) => (this.usersflg[key] = false)
        );
        Object.keys(this.allflags).forEach(
          (key) => (this.allflags[key] = false)
        );
        Object.keys(this.allflags1).forEach(
          (key) => (this.allflags1[key] = false)
        );
        Object.keys(this.allflags3).forEach(
          (key) => (this.allflags3[key] = false)
        );
        Object.keys(this.calenderflg).forEach(
          (key) => (this.calenderflg[key] = false)
        );
        Object.keys(this.allflags4).forEach(
          (key) => (this.allflags4[key] = false)
        );
        Object.keys(this.contractsflg).forEach(
          (key) => (this.contractsflg[key] = false)
        );
        this.allflags2[va] = true;
        console.log('leads*******>', va);

        this.Leadsflg[va] = true;
        this.leads.push(this.isCheckedName);
      }
    }
    if (text_val_arr == 'Calender') {
      this.allflagblock = 'calender'
      this.header_text = 'Calender';
      this.header_text_sub = [];
      this.individual_training_list_text = [];
      this.individual_usersummary_text = [];
      console.log('------0-0-', text_val_arr);
      // this.usersflg = false;
      // this.Trainingflg = true;
      this.allflags3 = [];
      this.calenderflg = [];
      this.calender_status = [];
      this.usertype = [];
      this.Trainingdata = [];
      this.leads = [];
      this.contracts = [];
      this.allTypeOfList = [];
      if (!event.checked) {
        this.individual_calendar_text.forEach((element, index) => {
          if (element == text_val.split(':')[1]) this.individual_calendar_text.splice(index, 1);
        });
        if (event.source.name == 'UpcomingAppointments') {
          this.calender_status.forEach((element, index) => {
            if (element.status == 'list-upcoming-booked-events') {
              this.calender_status.splice(index, 1);
            }
          });
        }
        if (event.source.name == 'PastAppointments') {
          this.calender_status.forEach((element, index) => {
            if (element.status == 'list-booked-events') {
              this.calender_status.splice(index, 1);
            }
          });
        }
        this.calenderflg[va] = false;
        this.allflags3[va] = false;
        let index = this.calender.indexOf(this.isCheckedName);
        if (index > -1) {
          this.calender.splice(index, 1);
        }
      } else {
        this.individual_calendar_text = [text_val.split(':')[1]];
        Object.keys(this.Trainingflg).forEach(
          (key) => (this.Trainingflg[key] = false)
        );
        Object.keys(this.usersflg).forEach(
          (key) => (this.usersflg[key] = false)
        );
        Object.keys(this.allflags).forEach(
          (key) => (this.allflags[key] = false)
        );
        Object.keys(this.allflags1).forEach(
          (key) => (this.allflags1[key] = false)
        );
        Object.keys(this.allflags2).forEach(
          (key) => (this.allflags2[key] = false)
        );
        Object.keys(this.Leadsflg).forEach(
          (key) => (this.Leadsflg[key] = false)
        );
        Object.keys(this.allflags4).forEach(
          (key) => (this.allflags4[key] = false)
        );
        Object.keys(this.contractsflg).forEach(
          (key) => (this.contractsflg[key] = false)
        );
        this.allflags3[va] = true;
        console.log('leads*******>', va);

        this.calenderflg[va] = true;
        this.calender = [this.isCheckedName];
      }
      this.header_text_sub = this.individual_calendar_text;
    }
    if (text_val_arr == 'Contracts') {
 
      

      this.header_text_sub = [];
      this.individual_calendar_text = [];
      this.individual_training_list_text = [];
      this.individual_usersummary_text = [];
      console.log('------0-0-', text_val_arr);
      console.log('------0-1-', event.source.name);
      // this.usersflg = false;
      // this.Trainingflg = true;
      console.log("event.source==>", event.source);
      console.log("contruct_status==>", this.contruct_status);
      let contruct_status_arr = this.contruct_status;
      console.log("contruct_status_arr==>", contruct_status_arr);

      // console.log("contruct_status_string==>", this.contruct_status_string);

      // this.header_text = 'Contracts :' + this.contracts;

      this.usertype = [];
      this.Trainingdata = [];
      this.leads = [];
      this.calender = [];
      this.allTypeOfList = [];
      this.calender_status = [];
      console.log("event.checked==", event.checked);

      if (!event.checked) {
        console.log("event.checked within !!==", event.checked);

        // this.individual_contracts_text.forEach((element, index) => {
        //   if (element == text_val.split(':')[1]) this.individual_contracts_text.splice(index, 1);
        // });

        if (event.source.name == 'PendingApproval') {
          this.contruct_status.forEach((element, index) => {
            console.log('event.source.name==>', event.source.name);

            if (element.currentstatus == 'Pending Approval') {
              this.contruct_status.splice(index, 1);
              console.log("this.contruct_status==>", this.contruct_status);
              console.log("element.currentstatus==>", element.currentstatus);

            }
          });
        }
        if (event.source.name == 'ContractSigned') {
          this.contruct_status.forEach((element, index) => {
            // console.log('index*********',index);

            if (element.currentstatus == 'Contract Signed') {
              this.contruct_status.splice(index, 1);
            }
          });
        }
        if (event.source.name == 'ContractApproved') {
          this.contruct_status.forEach((element, index) => {
            // console.log('index*********',index);

            if (element.currentstatus == 'Contract Approved') {
              this.contruct_status.splice(index, 1);
            }
          });
        }
        if (event.source.name == 'ContractPendingSignature') {
          this.contruct_status.forEach((element, index) => {
            // console.log('index*********',index);

            if (element.currentstatus == 'Contract Pending Signature') {
              this.contruct_status.splice(index, 1);
            }
          });
        }
        if (event.source.name == 'ContractDeclined') {
          this.contruct_status.forEach((element, index) => {
            // console.log('index*********',index);

            if (element.currentstatus == 'Contract Declined') {
              this.contruct_status.splice(index, 1);
            }
          });
        }
        if (event.source.name == 'ContractModification') {
          this.contruct_status.forEach((element, index) => {
            // console.log('index*********',index);

            if (element.currentstatus == 'Contract Modification') {
              this.contruct_status.splice(index, 1);
            }
          });
        }
        if (event.source.name == 'DraftingasTechconsultant') {
          this.contruct_status.forEach((element, index) => {
            // console.log('index*********',index);

            if (element.currentstatus == 'Drafting as Tech consultant') {
              this.contruct_status.splice(index, 1);
            }
          });
        }
        if (event.source.name == 'DraftingasContractManager') {
          this.contruct_status.forEach((element, index) => {
            console.log('element.status*********', element.currentstatus);

            if (element.currentstatus == 'Drafting as Contract Manager') {
              this.contruct_status.splice(index, 1);
            }
          });
        }
        this.allflags4[va] = false;
        let index = this.contracts.indexOf(this.isCheckedName);
        if (index > -1) {
          this.contracts.splice(index, 1);
        }
        this.contractsflg[va] = false;
        console.log(
          'contruct status--<><uncheck><>-----',
          this.contruct_status
        );
      } else {
        Object.keys(this.Trainingflg).forEach(
          (key) => (this.Trainingflg[key] = false)
        );
        Object.keys(this.usersflg).forEach(
          (key) => (this.usersflg[key] = false)
        );
        Object.keys(this.allflags).forEach(
          (key) => (this.allflags[key] = false)
        );
        Object.keys(this.allflags1).forEach(
          (key) => (this.allflags1[key] = false)
        );
        Object.keys(this.allflags2).forEach(
          (key) => (this.allflags2[key] = false)
        );
        Object.keys(this.Leadsflg).forEach(
          (key) => (this.Leadsflg[key] = false)
        );
        Object.keys(this.allflags3).forEach(
          (key) => (this.allflags3[key] = false)
        );
        Object.keys(this.calenderflg).forEach(
          (key) => (this.calenderflg[key] = false)
        );
        this.allflags4[va] = true;
        console.log('leads*******>', va);

        this.contractsflg[va] = true;
        this.contracts.push(this.isCheckedName);
        console.log('contruct status--<><check><>-----', this.contruct_status);
        console.log("this.contracts==>",this.contracts);
      }
    }
    // console.log(this.usertype, '+++++', this.Trainingdata);

    console.log('event name*************>', event.source.name);

    switch (event.source.name) {
      // case 'ProductActive':
      //   this.productActiveflg = true;
      //   this.productListView(1);
      //  console.log(event.checked,'.D.GBFD.GB.')
      //   break;
      // case 'ProductInactive':
      //   this.productActiveflg = true;
      //   this.productListView(0);
      //   break;
      // case 'ResourcesActive':
      //   this.resourceActiveflg = true;
      //   this.resourceListView(1);
      //   break;
      // case 'ResourcesInactive':
      //   this.resourceActiveflg = true;
      //   this.resourceListView(0);
      //   break;
      // Traininglistlessons
      case 'UpcomingAppointments':
        this.allcalenderdatacollectionlist = '';
        this.allcalenderflg = true;
        this.allcontractflg = false;
        this.allleadsflg = false;
        this.leads_status = [];
        this.contruct_status = [];
        if (this.calenderflg['UpcomingAppointments']) {
          this.calender_status.push({ status: 'list-upcoming-booked-events' });
          this.allcalenderdatacollectionlist = 'list-upcoming-booked-events';
        }
        this.calender_func();
        break;
      case 'PastAppointments':
        this.allcalenderdatacollectionlist = '';
        this.allcalenderflg = true;
        this.allcontractflg = false;
        this.allleadsflg = false;
        this.leads_status = [];
        this.contruct_status = [];
        if (this.calenderflg['PastAppointments']) {
          this.calender_status.push({ status: 'list-booked-events' });
          this.allcalenderdatacollectionlist = 'list-booked-events';
        }
        this.calender_func();
        break;
      case 'LeadsAttemptingContact':
        // this.allleadsflg = true;
        this.allleadsflg = true;
        this.allcontractflg = false;
        this.allcalenderflg = false;
        this.calender_status = [];
        this.contruct_status = [];
        if (this.Leadsflg['LeadsAttemptingContact']) {
          this.leads_status.push({ currentstatus: 'Pending Approval' });
        }
        this.leads_func();
        break;
      case 'LeadsDisqualifiedLost':
        this.allleadsflg = true;
        this.allcontractflg = false;
        this.allcalenderflg = false;
        this.calender_status = [];
        this.contruct_status = [];
        if (this.Leadsflg['LeadsDisqualifiedLost']) {
          this.leads_status.push({ currentstatus: 'Contract Declined' });
        }
        this.leads_func();
        break;
      case 'LeadsContractSent':
        this.allleadsflg = true;
        this.allcontractflg = false;
        this.allcalenderflg = false;
        this.calender_status = [];
        this.contruct_status = [];
        if (this.Leadsflg['LeadsContractSent']) {
          this.leads_status.push({ currentstatus: 'Contract Signed' });
        }
        this.leads_func();
        break;
      case 'PendingApproval':
        this.allcontractflg = true;
        this.allleadsflg = false;
        this.allcalenderflg = false;
        this.calender_status = [];
        this.leads_status = [];
        if (this.contractsflg['PendingApproval']) {
          this.contruct_status.push({ currentstatus: 'Pending Approval' });
        }
        this.contracts_func();
        break;
      case 'ContractSigned':
        this.allcontractflg = true;
        this.allleadsflg = false;
        this.allcalenderflg = false;
        this.calender_status = [];
        this.leads_status = [];
        if (this.contractsflg['ContractSigned']) {
          this.contruct_status.push({ currentstatus: 'Contract Signed' });
        }
        this.contracts_func();
        break;
      case 'ContractApproved':
        this.allcontractflg = true;
        this.allleadsflg = false;
        this.allcalenderflg = false;
        this.calender_status = [];
        this.leads_status = [];
        if (this.contractsflg['ContractApproved']) {
          this.contruct_status.push({ currentstatus: 'Contract Approved' });
        }
        this.contracts_func();
        break;
      case 'ContractPendingSignature':
        this.allcontractflg = true;
        this.allleadsflg = false;
        this.allcalenderflg = false;
        this.calender_status = [];
        this.leads_status = [];
        if (this.contractsflg['ContractPendingSignature']) {
          this.contruct_status.push({ currentstatus: 'Contract Pending Signature' });
        }
        this.contracts_func();
        break;
      case 'ContractDeclined':
        this.allcontractflg = true;
        this.allleadsflg = false;
        this.allcalenderflg = false;
        this.calender_status = [];
        this.leads_status = [];
        if (this.contractsflg['ContractDeclined']) {
          this.contruct_status.push({ currentstatus: 'Contract Declined' });
        }
        this.contracts_func();
        break;
      case 'ContractModification':
        this.allcontractflg = true;
        this.allleadsflg = false;
        this.allcalenderflg = false;
        this.calender_status = [];
        this.leads_status = [];
        if (this.contractsflg['ContractModification']) {
          this.contruct_status.push({ currentstatus: 'Contract Modification' });
        }
        this.contracts_func();
        break;
      case 'DraftingasTechconsultant':
        this.allcontractflg = true;
        this.allleadsflg = false;
        this.allcalenderflg = false;
        this.calender_status = [];
        this.leads_status = [];
        if (this.contractsflg['DraftingasTechconsultant']) {
          this.contruct_status.push({ currentstatus: 'Drafting as Tech consultant' });
        }
        this.contracts_func();
        break;
      case 'DraftingasContractManager':
        this.allcontractflg = true;
        this.allleadsflg = false;
        this.allcalenderflg = false;
        this.calender_status = [];
        this.leads_status = [];
        if (this.contractsflg['DraftingasContractManager']) {
          this.contruct_status.push({ currentstatus: 'Drafting as Contract Manager' });
        }
        this.contracts_func();
        break;
      case 'TraininglistCategories':
        this.Traininglistflg = true;
        this.allcontractflg = false;
        this.allleadsflg = false;
        this.allcalenderflg = false;
        this.calender_status = [];
        this.leads_status = [];
        this.contruct_status = [];
        this.categoryListView('training');
        break;
      case 'Traininglistlessons':
        this.lessonlistflg = true;
        this.allcontractflg = false;
        this.allleadsflg = false;
        this.allcalenderflg = false;
        this.calender_status = [];
        this.leads_status = [];
        this.contruct_status = [];
        this.categoryListView('lesson');
        break;
      default:
        break;
    }

    if (text_val_arr == 'Contracts') {
    console.log("this.contruct_status after switch case==>",this.contruct_status);
    this.contruct_status_string = this.contruct_status.map(function (x) {
        console.log("x==>",x);
        console.log("x.currentstatus==>",x.currentstatus);
        return x.currentstatus;
      });

      console.log("contruct_status_string==>", this.contruct_status_string);
      this.header_text = 'Contracts : ' + this.contruct_status_string
      console.log("this.header_text==>",this.header_text);
    }
  }
  //  checkedflag(val:string, arr:any){
  //    console.log('checkedflag')
  //   return arr.find(x=>x == val) == val;
  //  }
  leads_func() {
    this.blockUI.start('Loading.......');

    let data = {
      secret: this.coockie.get('secret'),
      token: this.coockie.get('jwtToken'),
      source: '',
      condition: {
        limit: 10,
        skip: 0,
      },
      sort: {
        type: 'desc',
        field: 'createdon_datetime',
      },
      searchcondition: {
        type: this.userservice.type,
        $or: this.leads_status,
      },
    };
    this.allleadsdata = [];
    this.allleadsdatasearchlistcount = 0;
    if (this.leads_status.length != 0) {
      this.Apiservice.getDataWithoutToken(
        data,
        'api/contractrequestslistviews'
      ).subscribe(
        (res: any) => {
          this.allleadsdata = res.results.res;
          console.log('++++++-----------**********>', res.results.res);
          this.blockUI.stop();
        },
        (error) => {
          this.allleadsdata = [];
          this.blockUI.stop();
        }
      );
      this.Apiservice.getDataWithoutToken(
        data,
        'api/contractrequestslistviews-count'
      ).subscribe(
        (res: any) => {
          console.log(
            'allcontractsearchalllistcount-----------------------<><><>',
            res
          );
          this.allleadsdatasearchlistcount = res.count;
        },
        (error) => {
          this.allleadsdatasearchlistcount = null;
        }
      );
    } else {
      this.adminlist(['admin']);
      this.blockUI.stop();
    }

    console.log('pending********************************>++++++', data);
  }
  calender_func() {
    this.allcalenderdatanodatafound = false;
    this.blockUI.start('Loading.......');
    let data = {
      token: this.userservice.jwttoken,
      condition: {
        limit: 10,
        skip: 0,
      },
      sarchconditions: {},
      sort: {
        type: 'desc',
        field: 'booking_date',
      },
    };
    this.allcalenderdata = [];
    this.allcalendersearchalllistcount = 0;
    let alldata = [];
    if (this.calender_status.length != 0) {
      this.calender_status.forEach((element, index) => {
        console.log('calender<><><><********><><><>', element);
        this.Apiservice.customRequest(
          data,
          element.status,
          environment['api_calender_url']
        ).subscribe(
          (res: any) => {
            // console.log('calender data**************before+++++++++++++++++>',res.results.res);
            // this.allcalenderdata=res.results.res
            // Array.prototype.push.apply(this.allcalenderdata,res.results.res);
            alldata = [...alldata, ...res.results.res];
            this.allcalendersearchalllistcount =
              this.allcalendersearchalllistcount + res.count;
            if (this.calender_status.length == index + 1) {
              this.allcalenderdata = alldata;
              if (this.allcalenderdata.length == 0) {
                this.allcalenderdatanodatafound = true;
              }
              this.blockUI.stop();
            }
            console.log(
              'calender data**************after+++++++++++++++++>',
              alldata
            );
            // this.allcontractdata = res.results.res;
            // console.log('++++++-----------**********>',res.results.res);
            // this.blockUI.stop();
          },
          (error) => {
            this.allcalenderdata = [];
            this.blockUI.stop();
          }
        );
        // this.Apiservice.customRequest(data, element.status+'-count',environment['api_calender_url']).subscribe((res: any) => {
        //   this.allcalendersearchalllistcount=this.allcalendersearchalllistcount + res.count
        //   console.log('calender data**************count+++++++++++++++++>',this.allcalendersearchalllistcount);
        // },error =>{
        //   this.allcalendersearchalllistcount = null;
        // })
      });
    } else {
      this.adminlist(['admin']);
      this.blockUI.stop();
    }
  }
  contracts_func() {
    this.allcontractnodatafound = false;
    this.blockUI.start('Loading.......');

    let data = {
      secret: this.coockie.get('secret'),
      token: this.coockie.get('jwtToken'),
      source: '',
      condition: {
        limit: 10,
        skip: 0,
      },
      sort: {
        type: 'desc',
        field: 'createdon_datetime',
      },
      searchcondition: {
        type: this.userservice.type,
        $or: this.contruct_status,
      },
    };
    this.allcontractdata = [];
    this.allcontractsearchalllistcount = 0;
    if (this.contruct_status.length != 0) {
      this.Apiservice.getDataWithoutToken(
        data,
        'api/contractrequestslistviews'
      ).subscribe(
        (res: any) => {
          this.allcontractdata = res.results.res;
          if (this.allcontractdata.length == 0) {
            this.allcontractnodatafound = true;
          }
          console.log('++++++-----------**********>', res.results.res);
          this.blockUI.stop();
        },
        (error) => {
          this.allcontractdata = [];
          this.blockUI.stop();
        }
      );
      this.Apiservice.getDataWithoutToken(
        data,
        'api/contractrequestslistviews-count'
      ).subscribe(
        (res: any) => {
          console.log(
            'allcontractsearchalllistcount-----------------------<><><>',
            res
          );
          this.allcontractsearchalllistcount = res.count;
        },
        (error) => {
          this.allcontractsearchalllistcount = null;
        }
      );
    } else {
      this.adminlist(['admin']);
      this.blockUI.stop();
    }

    console.log('pending********************************>++++++', data);
  }

  adminlist(val: any) {
    console.log(val);
    if (val == 'admin') {
      console.log(val);
      this.allflags['admin'] = true;
      this.usersflg['admin'] = true;
      this.header_text = 'User Summary';
      this.header_text_sub = [' Admin'];
      this.individual_usersummary_text = [' Admin'];
    }
    if (val.length == 0) {
      console.log('__________________________');

      val = ['admin'];
      setTimeout(() => {
        this.allflags.admin = true;
        this.usersflg.admin = true;
        console.log(this.usersflg);
      }, 1000);
      this.header_text = 'User Summary';
      this.header_text_sub = [' Admin'];
      this.individual_usersummary_text = [' Admin'];
    }
    console.log(this.allflags);
    console.log(this.usersflg);

    this.productActiveflg = false;
    this.Traininglistflg = false;
    this.lessonlistflg = false;
    this.resourceActiveflg = false;
    this.allTypeOfListnodatafound = false;
    // this.loaderService.isLoading.next(true);
    this.blockUI.start('Loading.......');
    // this.allTypeOfListLibData.basecondition.type = val;
    this.allTypeOfList = [];
    const data = {
      secret: this.coockie.get('secret'),
      token: this.coockie.get('jwtToken'),
      source: '',
      condition: { limit: 10, skip: 0 },
      sort: { type: 'desc', field: 'logincounts' },
      searchcondition: { type: { $in: val } },
    };
    this.Apiservice.getDataWithoutToken(data, 'api/userlistview').subscribe(
      (res: any) => {
        this.allTypeOfList = res.results.res;
        if (this.allTypeOfList.length == 0) {
          this.allTypeOfListnodatafound = true;
        }
        console.log(this.allTypeOfList, 'allTypeOfList');
        this.adminlistcount(val);
      }
    );
  }

  adminlistcount(val: any) {
    this.datesearchAllTypeOfListCount = 0;
    const data = {
      secret: this.coockie.get('secret'),
      token: this.coockie.get('jwtToken'),
      source: '',
      condition: { limit: 10, skip: 0 },
      sort: { type: 'desc', field: 'logincounts' },
      searchcondition: { type: { $in: val } },
    };
    this.Apiservice.getDataWithoutToken(
      data,
      'api/userlistview-count'
    ).subscribe((res: any) => {
      this.datesearchAllTypeOfListCount = res.count;
      // this.loaderService.isLoading.next(false);
      this.blockUI.stop();
    });
  }

  ngOnInit() {

    this.dashtraingdata();
    let slideIndex = 0;
    showSlides();
    function showSlides() {
      let i;
      let slides = document.getElementsByClassName('mySlides') as HTMLCollectionOf<HTMLElement>;
      let dots = document.getElementsByClassName('dot');
      for (i = 0; i < slides.length; i++) {
        slides[i].style.display = 'none';
      }
      slideIndex++;
      if (slideIndex > slides.length) { slideIndex = 1 }
      for (i = 0; i < dots.length; i++) {
        dots[i].className = dots[i].className.replace(' active', '');

      }
      slides[slideIndex - 1].style.display = 'block';
      dots[slideIndex - 1].className += ' active';
      setTimeout(showSlides, 4500); // Change image every 2 seconds
      // clearTimeout(setTimeout(showSlides, 4500));
    }



    // this.Apiservice.getDataWithoutToken({}, 'api/getcoock').subscribe((res: any) => {
    //  console.log('+++++++>>>>>>>>>>>.',res)
    // })
    // this.usersflg.admin=true;

    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth',
    });

    setTimeout(() => {
      this.adminlist([this.isCheckedName]);
    }, 1000);
    // var slideIndex = 1;
    // this.slide('');

    // this.slidesales_persons('');

    // this.slide_technological('');

    // this.slide_jobtickets('');

    // this.slide_signature('');

    // this.slide_products('');

    // this.slide_resources('');
    // this.lead_list_slide('');
    // this.slideindex_schedule_slide('');

    // fetch data from database using resolve
    this.activatedroute.data.forEach((res: any) => {
      console.log(res);
      this.adminarray = res.resolvedata.results;
      // // console.log(this.adminarray.lastlogin[0].last_login_data)
      // console.log(this.adminarray, 'adminarray+++++++++++++++++++++++++');
      if (this.adminarray.mostactive_technological_consultant.length > 0) {
        for (const key in this.adminarray.mostactive_technological_consultant) {
          if (this.adminarray.mostactive_technological_consultant[key].viewcommissionflag != null && typeof (this.adminarray.mostactive_technological_consultant[key].viewcommissionflag) != 'undefined' && this.adminarray.mostactive_technological_consultant[key].viewcommissionflag == true) {
            this.addcommission = true;
            this.updatetotechcommission = false;
          }
          if (this.adminarray.mostactive_technological_consultant[key].viewcommissionflag != null && typeof (this.adminarray.mostactive_technological_consultant[key].viewcommissionflag) != 'undefined' && this.adminarray.mostactive_technological_consultant[key].viewcommissionflag == false) {
            this.addcommission = false;
            this.updatetotechcommission = true;
          }
        }
      }
      if (this.adminarray.mostactive_technological_consultant.length == 0) {
        this.mostactiveflag = this.mostactiveflag + 1;
      }
      if (this.adminarray.contract_pending_signature.length > 0) {
        this.mostactiveflagfornextblock = this.mostactiveflagfornextblock + 1;
      }
      // if (this.adminarray.mostactive_sales_persons.length == 0) {
      //   this.mostactiveflag = this.mostactiveflag + 1;
      // }
      if (this.adminarray.mostactive_distributors.length == 0) {
        this.mostactiveflag = this.mostactiveflag + 1;
      }

      if (this.adminarray.recentjobtickets.length == 0) {
        this.mostactiveflag = this.mostactiveflag + 1;
      }
      // if (this.adminarray.recentjobtickets.length == 0) {
      //   this.secendactiveflag = this.secendactiveflag + 1;
      // }
      // if (this.dashboardlistarray != null && typeof (this.dashboardlistarray.contract_pending_signature) != 'undefined' && this.dashboardlistarray.contract_pending_signature.length == 0) {
      //   this.secendactiveflag = this.secendactiveflag + 1;
      // }
      // if (this.adminarray.mostactive_distributors.length == 0) {
      //   this.secendactiveflag = this.secendactiveflag + 1;
      // }


    });

    const userdata: any = {
      id: JSON.parse(this.coockie.get('userid')),
    }
    this.Apiservice.getDataWithoutToken(userdata, 'api1/admintraingdata').subscribe(
      (res: any) => {
        console.log(res);
        if (res.status == 'success') {
          this.userservice.cookieset('associated_training', JSON.stringify(res.data.associated_training));
          this.userservice.cookieset('currentlesson', JSON.stringify(res.data.current_lesson_id));
        }

      });


    // signup data for day
    const data: any = {
      secret: this.coockie.get('secret'),
      token: this.coockie.get('jwtToken'),
    };
    this.signupFlag = true;
    setTimeout(() => {
      this.Apiservice.getDataWithoutToken(data, 'api/singupgrp').subscribe(
        (res: any) => {
          //  // console.log(res, 'signup');
          this.signupdatadayarray = res.results;

          //  // console.log(this.signupdatadayarray, 'signupdata..');

          // admin graph
          this.UserlineChartData[0].data[0] =
            this.signupdatadayarray.admin_sunday;
          this.UserlineChartData[0].data[1] =
            this.signupdatadayarray.admin_monday;
          this.UserlineChartData[0].data[2] =
            this.signupdatadayarray.admin_tuesday;
          this.UserlineChartData[0].data[3] =
            this.signupdatadayarray.admin_wednesday;
          this.UserlineChartData[0].data[4] =
            this.signupdatadayarray.admin_thrusday;
          this.UserlineChartData[0].data[5] =
            this.signupdatadayarray.admin_friday;
          this.UserlineChartData[0].data[6] =
            this.signupdatadayarray.admin_saturday;

          // distributor graph
          this.UserlineChartData[1].data[0] =
            this.signupdatadayarray.distributor_sunday;
          this.UserlineChartData[1].data[1] =
            this.signupdatadayarray.distributor_monday;
          this.UserlineChartData[1].data[2] =
            this.signupdatadayarray.distributor_tuesday;
          this.UserlineChartData[1].data[3] =
            this.signupdatadayarray.distributor_wednesday;
          this.UserlineChartData[1].data[4] =
            this.signupdatadayarray.distributor_thrusday;
          this.UserlineChartData[1].data[5] =
            this.signupdatadayarray.distributor_friday;
          this.UserlineChartData[1].data[6] =
            this.signupdatadayarray.distributor_saturday;

          // sales person graph
          this.UserlineChartData[2].data[0] =
            this.signupdatadayarray.salesperson_sunday;
          this.UserlineChartData[2].data[1] =
            this.signupdatadayarray.sales_person_monday;
          this.UserlineChartData[2].data[2] =
            this.signupdatadayarray.sales_person_tuesday;
          this.UserlineChartData[2].data[3] =
            this.signupdatadayarray.sales_person_wednesday;
          this.UserlineChartData[2].data[4] =
            this.signupdatadayarray.sales_person_thrusday;
          this.UserlineChartData[2].data[5] =
            this.signupdatadayarray.sales_person_friday;
          this.UserlineChartData[2].data[6] =
            this.signupdatadayarray.sales_person_saturday;

          // tech consultant graph
          this.UserlineChartData[3].data[0] =
            this.signupdatadayarray.technological_consultant_sunday;
          this.UserlineChartData[3].data[1] =
            this.signupdatadayarray.technological_consultant_monday;
          this.UserlineChartData[3].data[2] =
            this.signupdatadayarray.technological_consultant_tuesday;
          this.UserlineChartData[3].data[3] =
            this.signupdatadayarray.technological_consultant_wednesday;
          this.UserlineChartData[3].data[4] =
            this.signupdatadayarray.technological_consultant_thrusday;
          this.UserlineChartData[3].data[5] =
            this.signupdatadayarray.technological_consultant_friday;
          this.UserlineChartData[3].data[6] =
            this.signupdatadayarray.technological_consultant_saturday;

          // closer graph
          this.UserlineChartData[4].data[0] =
            this.signupdatadayarray.closer_sunday;
          this.UserlineChartData[4].data[1] =
            this.signupdatadayarray.closer_monday;
          this.UserlineChartData[4].data[2] =
            this.signupdatadayarray.closer_tuesday;
          this.UserlineChartData[4].data[3] =
            this.signupdatadayarray.closer_wednesday;
          this.UserlineChartData[4].data[4] =
            this.signupdatadayarray.closer_thrusday;
          this.UserlineChartData[4].data[5] =
            this.signupdatadayarray.closer_friday;
          this.UserlineChartData[4].data[6] =
            this.signupdatadayarray.closer_saturday;

          // contact manager graph
          this.UserlineChartData[5].data[0] =
            this.signupdatadayarray.contract_manager_sunday;
          this.UserlineChartData[5].data[1] =
            this.signupdatadayarray.contract_manager_monday;
          this.UserlineChartData[5].data[2] =
            this.signupdatadayarray.contract_manager_tuesday;
          this.UserlineChartData[5].data[3] =
            this.signupdatadayarray.contract_manager_wednesday;
          this.UserlineChartData[5].data[4] =
            this.signupdatadayarray.contract_manager_thrusday;
          this.UserlineChartData[5].data[5] =
            this.signupdatadayarray.contract_manager_friday;
          this.UserlineChartData[5].data[6] =
            this.signupdatadayarray.contract_manager_saturday;

          this.signupFlag = false;
        }
      );
    }, 3000);

    setTimeout(() => {
      this.graphtraining();
    }, 3600);

    //  // console.log(this.UserlineChartData, '+++++++++++');

    // signup data for month

    // login data for day
    const dataforlogin = {
      secret: this.coockie.get('secret'),
      token: this.coockie.get('jwtToken'),
    };
    this.loginFlag = true;
    setTimeout(() => {
      this.Apiservice.getDataWithoutToken(
        dataforlogin,
        'api/logingrp'
      ).subscribe((res: any) => {
        //  // console.log(res, 'login data ...');
        this.logindatadayarray = res.results;
        //  // console.log(this.logindatadayarray.admin_monday, '.....');

        //  // console.log(this.lineChartData, '=======');
        // admin graph
        this.lineChartData[0].data[0] = this.logindatadayarray.admin_sunday;
        this.lineChartData[0].data[1] = this.logindatadayarray.admin_monday;
        this.lineChartData[0].data[2] = this.logindatadayarray.admin_tuesday;
        this.lineChartData[0].data[3] = this.logindatadayarray.admin_wednesday;
        this.lineChartData[0].data[4] = this.logindatadayarray.admin_thrusday;
        this.lineChartData[0].data[5] = this.logindatadayarray.admin_friday;
        this.lineChartData[0].data[6] = this.logindatadayarray.admin_saturday;

        // distributor graph
        this.lineChartData[1].data[0] =
          this.logindatadayarray.distributor_sunday;
        this.lineChartData[1].data[1] =
          this.logindatadayarray.distributor_monday;
        this.lineChartData[1].data[2] =
          this.logindatadayarray.distributor_tuesday;
        this.lineChartData[1].data[3] =
          this.logindatadayarray.distributor_wednesday;
        this.lineChartData[1].data[4] =
          this.logindatadayarray.distributor_thrusday;
        this.lineChartData[1].data[5] =
          this.logindatadayarray.distributor_friday;
        this.lineChartData[1].data[6] =
          this.logindatadayarray.distributor_saturday;

        // sales manager graph
        this.lineChartData[2].data[0] =
          this.logindatadayarray.sales_person_sunday;
        this.lineChartData[2].data[1] =
          this.logindatadayarray.sales_person_monday;
        this.lineChartData[2].data[2] =
          this.logindatadayarray.sales_person_tuesday;
        this.lineChartData[2].data[3] =
          this.logindatadayarray.sales_person_wednesday;
        this.lineChartData[2].data[4] =
          this.logindatadayarray.sales_person_thrusday;
        this.lineChartData[2].data[5] =
          this.logindatadayarray.sales_person_friday;
        this.lineChartData[2].data[6] =
          this.logindatadayarray.sales_person_saturday;

        // tech consult graph
        this.lineChartData[3].data[0] =
          this.logindatadayarray.technological_consultant_sunday;
        this.lineChartData[3].data[1] =
          this.logindatadayarray.technological_consultant_monday;
        this.lineChartData[3].data[2] =
          this.logindatadayarray.technological_consultant_tuesday;
        this.lineChartData[3].data[3] =
          this.logindatadayarray.technological_consultant_wednesday;
        this.lineChartData[3].data[4] =
          this.logindatadayarray.technological_consultant_thrusday;
        this.lineChartData[3].data[5] =
          this.logindatadayarray.technological_consultant_friday;
        this.lineChartData[3].data[6] =
          this.logindatadayarray.technological_consultant_saturday;

        // closer graph
        this.lineChartData[4].data[0] = this.logindatadayarray.closer_sunday;
        this.lineChartData[4].data[1] = this.logindatadayarray.closer_monday;
        this.lineChartData[4].data[2] = this.logindatadayarray.closer_tuesday;
        this.lineChartData[4].data[3] = this.logindatadayarray.closer_wednesday;
        this.lineChartData[4].data[4] = this.logindatadayarray.closer_thrusday;
        this.lineChartData[4].data[5] = this.logindatadayarray.closer_friday;
        this.lineChartData[4].data[6] = this.logindatadayarray.closer_saturday;

        // contact manager graph
        this.lineChartData[5].data[0] =
          this.logindatadayarray.contract_manager_sunday;
        this.lineChartData[5].data[1] =
          this.logindatadayarray.contract_manager_monday;
        this.lineChartData[5].data[2] =
          this.logindatadayarray.contract_manager_tuesday;
        this.lineChartData[5].data[3] =
          this.logindatadayarray.contract_manager_wednesday;
        this.lineChartData[5].data[4] =
          this.logindatadayarray.contract_manager_thrusday;
        this.lineChartData[5].data[5] =
          this.logindatadayarray.contract_manager_friday;
        this.lineChartData[5].data[6] =
          this.logindatadayarray.contract_manager_saturday;

        this.loginFlag = false;
      });
    }, 2000);

    // data for login by month

    setTimeout(() => {
      this.getallsharelinks();
    }, 600);

    // setTimeout(() => {
    //   this.gameplanmodal()
    // }, 700)

    if (
      this.coockie.get('onbordingcall') &&
      JSON.parse(this.coockie.get('onbordingcall')) == true
    ) {
      // console.log(this.onboardingcall);
      this.onboardingcall = true;
      console.log(this.onboardingcall);
    } else {
      this.onboardingcall = false;
    }

    if (
      this.coockie.get('gameplancall') &&
      JSON.parse(this.coockie.get('gameplancall')) == true
    ) {
      // console.log(this.onboardingcall);
      this.gameplancall = true;
      console.log(this.gameplancall);
    } else {
      this.gameplancall = false;
    }

    if (
      this.coockie.get('viewcommissionflag') &&
      JSON.parse(this.coockie.get('viewcommissionflag')) == true
    ) {
      // console.log(this.onboardingcall);
      this.addcommission = true;
      console.log(this.addcommission);
    } else {
      this.addcommission = false;
    }






  }
  commission_func(val: any) {
    // this.commission_data_details = [];
    let data = {}
    this.Apiservice.getDataWithoutToken(data, 'api10/get-commission-rule').subscribe((data: any) => {
      this.commission_data_details = data.res;
      console.log(this.commission_data_details);

      this.showing_commission_data_distributor = [];
      this.showing_commission_data_tech = [];
      this.showing_commission_data_distributor[0] = this.commission_data_details.find(e => e.product_id == '612c89242005f400082d5fb1' && e.commission_type == 'sales_commission' && e.user_type == 'distributor');
      this.showing_commission_data_tech[0] = this.commission_data_details.find(e => e.product_id == '612c89242005f400082d5fb1' && e.commission_type == 'sales_commission' && e.user_type == 'technological-consultant');
      this.showing_commission_data_distributor[1] = this.commission_data_details.find(e => e.product_id == '612c883f118c110009f02820' && e.commission_type == 'event_commission' && e.user_type == 'distributor');
      this.showing_commission_data_tech[1] = this.commission_data_details.find(e => e.product_id == '612c883f118c110009f02820' && e.commission_type == 'event_commission' && e.user_type == 'technological-consultant');
      this.showing_commission_data_distributor[2] = this.commission_data_details.find(e => e.product_id == '604aff3691b7c80008ca11a8' && e.commission_type == 'event_commission' && e.user_type == 'distributor');
      this.showing_commission_data_tech[2] = this.commission_data_details.find(e => e.product_id == '604aff3691b7c80008ca11a8' && e.commission_type == 'event_commission' && e.user_type == 'technological-consultant');
      this.showing_commission_data_distributor[3] = this.commission_data_details.find(e => e.product_id == '604aff3691b7c80008ca11a8' && e.commission_type == 'sales_commission' && e.user_type == 'distributor');
      this.showing_commission_data_tech[3] = this.commission_data_details.find(e => e.product_id == '604aff3691b7c80008ca11a8' && e.commission_type == 'sales_commission' && e.user_type == 'technological-consultant');
      this.showing_commission_data_distributor[4] = this.commission_data_details.find(e => e.product_id == '604a0b6e40962e00088f0d79' && e.commission_type == 'sales_commission' && e.user_type == 'distributor');
      this.showing_commission_data_tech[4] = this.commission_data_details.find(e => e.product_id == '604a0b6e40962e00088f0d79' && e.commission_type == 'sales_commission' && e.user_type == 'technological-consultant');
      if (this.showing_commission_data_distributor[0].product_id == '612c89242005f400082d5fb1' && this.showing_commission_data_distributor[0].commission_type == 'sales_commission') {
        this.showing_commission_data_distributor[0]['productname_details'] = this.showing_commission_data_distributor[0].product_name + ' Sales Commission';
        this.showing_commission_data_distributor[0]['product_img'] = '../../../../assets/images/traing_db_logo2_RST2.png';
        this.showing_commission_data_distributor[0].commission_value = this.showing_commission_data_distributor[0].commission_value + '%';
        this.showing_commission_data_distributor[0]['class_name'] = 'rst_text';
      }
      if (this.showing_commission_data_distributor[1].product_id == '612c883f118c110009f02820' && this.showing_commission_data_distributor[1].commission_type == 'event_commission') {
        this.showing_commission_data_distributor[1]['productname_details'] = this.showing_commission_data_distributor[1].product_name + ' Event Commission (Per treatment charge)';
        this.showing_commission_data_distributor[1]['product_img'] = '../../../../assets/images/traing_db_logo3.png';
        this.showing_commission_data_distributor[1].commission_value = '$' + this.showing_commission_data_distributor[1].commission_value;
        this.showing_commission_data_distributor[1]['class_name'] = 'bio_text';
      }
      if (this.showing_commission_data_distributor[2].product_id == '604aff3691b7c80008ca11a8' && this.showing_commission_data_distributor[2].commission_type == 'event_commission') {
        this.showing_commission_data_distributor[2]['productname_details'] = this.showing_commission_data_distributor[2].product_name + ' Standard Qualified Practice Program (Cost per test)';
        this.showing_commission_data_distributor[2]['product_img'] = '../../../../assets/images/traing_db_logo4.png';
        this.showing_commission_data_distributor[2].commission_value = '$' + this.showing_commission_data_distributor[2].commission_value;
        this.showing_commission_data_distributor[2]['class_name'] = 'PECE_text';
      }
      if (this.showing_commission_data_distributor[3].product_id == '604aff3691b7c80008ca11a8' && this.showing_commission_data_distributor[3].commission_type == 'sales_commission') {
        this.showing_commission_data_distributor[3]['productname_details'] = this.showing_commission_data_distributor[3].product_name + ' Practice Testing Center Program (15% of Gross/Net)';
        this.showing_commission_data_distributor[3]['product_img'] = '../../../../assets/images/traing_db_logo4.png';
        this.showing_commission_data_distributor[3].commission_value = this.showing_commission_data_distributor[3].commission_value + '%';
        this.showing_commission_data_distributor[3]['class_name'] = 'PECE_text';
      }
      if (this.showing_commission_data_distributor[4].product_id == '604a0b6e40962e00088f0d79' && this.showing_commission_data_distributor[4].commission_type == 'sales_commission') {
        this.showing_commission_data_distributor[4]['productname_details'] = this.showing_commission_data_distributor[4].product_name + ' Sales Commission';
        this.showing_commission_data_distributor[4]['product_img'] = '../../../../assets/images/traing_db_logo1.png';
        this.showing_commission_data_distributor[4].commission_value = this.showing_commission_data_distributor[4].commission_value + ' %';
        this.showing_commission_data_distributor[4]['class_name'] = 'tm_text';
      }
      if (this.showing_commission_data_tech[0].product_id == '612c89242005f400082d5fb1' && this.showing_commission_data_tech[0].commission_type == 'sales_commission') {
        this.showing_commission_data_tech[0]['productname_details'] = this.showing_commission_data_tech[0].product_name + ' Sales Commission';
        this.showing_commission_data_tech[0]['product_img'] = '../../../../assets/images/traing_db_logo2_RST2.png';
        this.showing_commission_data_tech[0].commission_value = this.showing_commission_data_tech[0].commission_value + '%';
        this.showing_commission_data_tech[0]['class_name'] = 'rst_text';
      }
      if (this.showing_commission_data_tech[1].product_id == '612c883f118c110009f02820' && this.showing_commission_data_tech[1].commission_type == 'event_commission') {
        this.showing_commission_data_tech[1]['productname_details'] = this.showing_commission_data_tech[1].product_name + ' Event Commission (Per treatment charge)';
        this.showing_commission_data_tech[1]['product_img'] = '../../../../assets/images/traing_db_logo3.png';
        this.showing_commission_data_tech[1].commission_value = '$' + this.showing_commission_data_tech[1].commission_value;
        this.showing_commission_data_tech[1]['class_name'] = 'bio_text';
      }
      if (this.showing_commission_data_tech[2].product_id == '604aff3691b7c80008ca11a8' && this.showing_commission_data_tech[2].commission_type == 'event_commission') {
        this.showing_commission_data_tech[2]['productname_details'] = this.showing_commission_data_tech[2].product_name + ' Standard Qualified Practice Program (Cost per test)';
        this.showing_commission_data_tech[2]['product_img'] = '../../../../assets/images/traing_db_logo4.png';
        this.showing_commission_data_tech[2].commission_value = '$' + this.showing_commission_data_tech[2].commission_value;
        this.showing_commission_data_tech[2]['class_name'] = 'PECE_text';
      }
      if (this.showing_commission_data_tech[3].product_id == '604aff3691b7c80008ca11a8' && this.showing_commission_data_tech[3].commission_type == 'sales_commission') {
        this.showing_commission_data_tech[3]['productname_details'] = this.showing_commission_data_tech[3].product_name + ' Practice Testing Center Program (15% of Gross/Net)';
        this.showing_commission_data_tech[3]['product_img'] = '../../../../assets/images/traing_db_logo4.png';
        this.showing_commission_data_tech[3].commission_value = this.showing_commission_data_tech[3].commission_value + '%';
        this.showing_commission_data_tech[3]['class_name'] = 'PECE_text';
      }
      if (this.showing_commission_data_tech[4].product_id == '604a0b6e40962e00088f0d79' && this.showing_commission_data_tech[4].commission_type == 'sales_commission') {
        this.showing_commission_data_tech[4]['productname_details'] = this.showing_commission_data_tech[4].product_name + ' Sales Commission';
        this.showing_commission_data_tech[4]['product_img'] = '../../../../assets/images/traing_db_logo1.png';
        this.showing_commission_data_tech[4].commission_value = this.showing_commission_data_tech[4].commission_value + ' %';
        this.showing_commission_data_tech[4]['class_name'] = 'tm_text';
      }
      if (val == 'from_edit') {
        this._snackBar.open('Commission Update Successfully', 'Dismiss', {
          duration: 3000
        });
      }
    })
  }
  commission_edit_func(val: any) {
    const dialogRef = this.dialog.open(CommissionEditDialog, {
      data: { data: val }
    });

    dialogRef.afterClosed().subscribe(result => {
      // console.log('The dialog was closed');
      if (result) {
        this.commission_func('from_edit');
      }
    });
  }
  folderview(val: any, flag) {
    // contract/view/:_id
    console.log(val);
    if (flag == 'view') {
      this.router.navigateByUrl('/user-details/' + val.type + '/' + val._id);
    } else if (flag == 'new') {
      window.open(
        '/user-details/' + val.type + '/' + val._id,
        'Independent Window'
      );
    } else if (flag == 'reply') {
      this.router.navigateByUrl('/jobticket/view-managejobticket/' + val._id);
    } else if (flag == 'reply1') {
      window.open(
        '/jobticket/view-managejobticket/' + val._id,
        'Independent Window'
      );
    } else if (flag == 'contract') {
      this.router.navigateByUrl('/contract-manager/contract/view/' + val._id);
    }
    //  else if(flag=='apponitment'){
    //   this.router.navigateByUrl('/contract-manager/contract/view/' + val._id);
    // }
    else if (flag == 'leads') {
      this.router.navigateByUrl('/user-details/' + val.type + '/' + val._id);
    } else if (flag == 'leads1') {
      window.open(
        '/user-details/' + val.type + '/' + val._id,
        'Independent Window'
      );
    }
    else if (flag == 'commission') {
      this.router.navigateByUrl('/managecommisson/list');
    } else if (flag == 'commission1') {
      window.open(
        '/managecommisson/list',
        'Independent Window'
      );
    }
    else if (flag == 'contracts') {
      this.router.navigateByUrl('/managecommisson/list');
    } else if (flag == 'contracts1') {
      window.open(
        '/managecommisson/list',
        'Independent Window'
      );
    }
    else if (flag == 'apponitment') {
      this.router.navigateByUrl('/calendar-management/manage-appointment/my-appointments');
    } else if (flag == 'apponitment1') {
      window.open(
        '/calendar-management/manage-appointment/my-appointments',
        'Independent Window'
      );
    }
    else if (flag === 'training_new_page') {
      window.open(
        '/user-details/technological-consultant/' + val._id + '?flag=training',
        'Independent Window'
      );
    } else if (flag === 'training_same_page') {
      this.router.navigateByUrl(
        '/user-details/technological-consultant/' + val._id + '?flag=training'
      );
    }

  }
  navigateTo(val: any) {
    console.log(val, 'case');
    // return
    switch (val) {
      case 'Admin':
        window.open('admin/list/admin', '_blank');
        break;
      case 'Admin1':
        this.router.navigateByUrl('/admin/list/admin');
        break;
      case 'distributor':
        window.open('admin/list/distributor', '_blank');
        break;
      case 'distributor1':
        this.router.navigateByUrl('/admin/list/distributor');
        break;
      case 'sales-person':
        window.open('admin/list/sales-person', '_blank');
        break;
      case 'sales-person1':
        this.router.navigateByUrl('/admin/list/sales-person');

        break;
      case 'contract-manager':
        window.open('admin/list/contract-manager', '_blank');
        break;
      case 'contract-manager1':
        this.router.navigateByUrl('/admin/list/contract-manager');

        break;
      case 'closer':
        window.open('admin/list/closer', '_blank');
        break;
      case 'closer1':
        this.router.navigateByUrl('/admin/list/closer');

        break;
      case 'technological-consultant':
        window.open('admin/list/technological-consultant', '_blank');
        break;
      case 'technological-consultant1':
        this.router.navigateByUrl('/admin/list/technological-consultant');

        break;
      case 'lessons':
        window.open('/traning/lesson/list', '_blank');
        break;
      case 'lessons1':
        this.router.navigateByUrl('/traning/lesson/list');

        break;
      case 'Categories':
        window.open('/traning/category/list', '_blank');
        break;
      case 'Categories1':
        this.router.navigateByUrl('/traning/category/list');

        break;

      case 'Resources':
        window.open('/resources/list/active', '_blank');
        break;
      case 'Resources1':
        this.router.navigateByUrl('/resources/list/active');

        break;
      case 'Resources2':
        window.open('/resources/list/inactive', '_blank');
        break;
      case 'Resources3':
        this.router.navigateByUrl('/resources/list/inactive');

        break;
      case 'Product':
        window.open('/resources/product/list/active', '_blank');
        break;
      case 'Product1':
        this.router.navigateByUrl('/resources/product/list/active');

        break;
      case 'Product2':
        window.open('/resources/product/list/inactive', '_blank');
        break;
      case 'Product3':
        this.router.navigateByUrl('/resources/product/list/inactive');

        break;
      case 'calendar':
        window.open('/calendar-management/manage-appointment/my-appointments', '_blank');
        break;
      case 'calendar1':
        this.router.navigateByUrl('/calendar-management/manage-appointment/my-appointments');
        break;
      default:
        break;
    }
  }
  openDialog() {
    const dialogRef = this.dialog.open(clipboard, {
      // width: '250px',
      data: '',
      panelClass: 'clipboard',
    });

    dialogRef.afterClosed().subscribe((result) => {
      //  // console.log('The dialog was closed');
      // return
    });
  }
  NotesModalFunc(val) {
    // this.opennotes = !this.opennotes;
    // if (this.opennotes) {
    //   this.notesbtnval = 'Close Notes';
    // } else {
    //   this.notesbtnval = 'Open Notes';
    // }
    console.log(val);

    const dialogRef = this.dialog.open(NotesModal, {
      width: '400px',
      // height:'400px',
      data: val,
      panelClass: 'notes-modal_view'
    });

    dialogRef.afterClosed().subscribe(result => {
      //  // console.log('The dialog was closed');
      // return
    });
  }

  dashtraingdata() {
    let requestdata = {
      data: {
        id: '',

        'type': 'admin'
      },
      endpoint: ''
    };
    requestdata.data.id = JSON.parse(this.coockie.get('userid'));
    requestdata.endpoint = 'traing_api/traninguserpercent';
    this.Apiservice
      .getDataFortraing(requestdata)
      .subscribe((res: any) => {
        console.log(res, 'traingdata');
        this.traingdata = res.results.res;
        if (this.traingdata.length > 0) {
          this.mostactiveflagfornextblock = this.mostactiveflagfornextblock + 1;
        }
      });
  }
  // fb(e, url: any) {
  //   // console.log(e, url)
  //   // userservice?.userid
  //   // let url = 'www.google.com';
  //   e.preventDefault();
  //   var facebookWindow = window.open(
  //     'https://www.facebook.com/sharer/sharer.php?u=' + url + this.userid.replace(/^\s+|\s+$/gm, ''),
  //     'facebook-popup',
  //     'width=800,height=500,left=200,top=5,scrollbars,toolbar=0,resizable'
  //   );
  //   if (facebookWindow.focus) {
  //     facebookWindow.focus();
  //   }
  //   return false;

  // }
  // tw(url: any, name: any) {
  //   var twitterWindow =   window.open('https://twitter.com/intent/tweet?source=tweetbutton&text=' + name + '&url=' + url + this.userid.replace(/^\s+|\s+$/gm, ''),
  //   'popupwindow',
  //   'width=800,height=500,left=200,top=5,scrollbars,toolbar=0,resizable');
  //   if (twitterWindow.focus) {
  //     twitterWindow.focus();
  //  }
  //  return false;
  // }
  // linkedin(url: any, name: any) {
  //   var linkedinWindow = window.open('https://www.linkedin.com/shareArticle?mini=true&url=' + url + this.userid.replace(/^\s+|\s+$/gm, '') + '&title=' + name + '&summary=' + name + '&source=' + name,
  //    'popupwindow',
  //    'width=800,height=500,left=200,top=5,scrollbars,toolbar=0,resizable'); if (linkedinWindow.focus) {
  //     linkedinWindow.focus();
  //   }
  //   return false;
  // }
  listenLiblistingChange(val: any) {
    console.log(val, 'kkk');

    if (
      val.custombuttonclick != null &&
      val.custombuttonclick != '' &&
      typeof val.custombuttonclick != undefined &&
      val.custombuttonclick.btninfo.label == 'Log Me'
    ) {
      const dialogRef = this.dialog.open(LogMeComponent, {
        panelClass: 'LogMeComponent',

        data: { val: val.custombuttonclick.data },
      });
      dialogRef.afterClosed().subscribe((result: any) => {
        if (result == 'Yes') {
          let main_user = this.coockie.getAll();
          console.log(main_user, '>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>');

          sessionStorage.clear();
          localStorage.clear();
          this.coockie.deleteAll();
          this.coockie.deleteAll('/');

          setTimeout(() => {
            this.userservice.cookieset('main_user', JSON.stringify(main_user));
            this.userservice.cookieset(
              'type',
              JSON.stringify(val.custombuttonclick.data.type)
            );
            this.userservice.cookieset(
              'firstname',
              JSON.stringify(val.custombuttonclick.data.firstname)
            );
            this.userservice.cookieset(
              'lastname',
              JSON.stringify(val.custombuttonclick.data.lastname)
            );
            this.userservice.cookieset(
              'userid',
              JSON.stringify(val.custombuttonclick.data._id)
            );
            this.userservice.cookieset(
              'lastlogindate',
              JSON.stringify(val.custombuttonclick.data.lastlogin_datetime)
            );
            this.userservice.cookieset(
              'useremail',
              JSON.stringify(val.custombuttonclick.data.email)
            );
            this.userservice.cookieset(
              'status',
              JSON.stringify(val.custombuttonclick.data.status)
            );
            this.userservice.cookieset(
              'lastlogincity',
              JSON.stringify(val.custombuttonclick.data.lastlogincity)
            );
            this.userservice.cookieset(
              'lastloginregion',
              JSON.stringify(val.custombuttonclick.data.lastloginregion)
            );
            this.userservice.cookieset(
              'jwtToken',
              main_user.jwtToken
            );
            this.userservice.cookieset(
              'secret',
              main_user.secret
            );
            this.userservice.cookieset(
              'parentid',
              JSON.stringify(val.custombuttonclick.data.parentid)
            );
            this.userservice.cookieset(
              'return_url',
              JSON.stringify(this.router.url)
            );
            console.log('The dialog was closed', result);
          }, 100);
          setTimeout(() => {
            this.router.navigateByUrl(
              '/' + val.custombuttonclick.data.type + '/dashboard'
            );
            //       let currentUrl = this.router.url;
            // this.router.routeReuseStrategy.shouldReuseRoute = () => false;
            // this.router.onSameUrlNavigation = 'reload';
            // this.router.navigate([currentUrl]);
          }, 700);
        }
      });
    }
    if (
      val.custombuttonclick != null &&
      val.custombuttonclick != '' &&
      typeof val.custombuttonclick != undefined &&
      val.custombuttonclick.btninfo.label == 'Search'
    ) {
      // const dialogRef = this.dialog.open(SearchComponent, {
      //   panelClass: 'SearchComponent',
      //   data: { val: val.custombuttonclick.data }
      // })
      // dialogRef.afterClosed().subscribe((result: any) => {
      // })
    }

    if (
      val.custombuttonclick != null &&
      val.custombuttonclick != '' &&
      typeof val.custombuttonclick != undefined &&
      val.custombuttonclick.btninfo.label == 'Open Folder View New Page'
    ) {
      window.open(
        '/user-details/' +
        val.custombuttonclick.data.type +
        '/' +
        val.custombuttonclick.data._id,
        '_blank'
      );
    }
    if (
      val.custombuttonclick != null &&
      val.custombuttonclick != '' &&
      typeof val.custombuttonclick != undefined &&
      val.custombuttonclick.btninfo.id == 'edituser') {
      console.log(val.custombuttonclick.data.type + '/edit/' + val.custombuttonclick.data._id);

      this.router.navigateByUrl('/admin/' + val.custombuttonclick.data.type + '/edit/' + val.custombuttonclick.data._id);
    }
  }
  openDialogForSearchListViewForTech(val: any) {
    const dialogRef = this.dialog.open(Searchmodaladmin, {
      // width: '250px',
      data: val,
      panelClass: '',
    });

    dialogRef.afterClosed().subscribe((result) => {
      console.log('result*************>', result);
      if (result != undefined) {
        this.jobticketnorecordfound = false;
        this.distributornorecordfound = false;
        this.technorecordfound = false;
        this.commissionnorecordfound = false;
        this.contract_pending_signaturenorecordfound = false;
        this.scheduleappointmentnorecordfound = false;
        if (val == 'distributor') {
          delete result.start_datetime_unix;
          this.distributerListdatatableflag = true;
          if (typeof (result.lastlogin_datetime.$gte) != 'undefined' || typeof (result.lastlogin_datetime.$lte) != 'undefined') {
            console.log(result.lastlogin_datetime);
          } else {
            delete result.lastlogin_datetime;
          }
          delete result.createdon_datetime;
          this.distributorListdatatable = [];
        }
        if (val == 'technological-consultant') {
          delete result.start_datetime_unix;
          this.techconsultantsListdatatableflag = true;
          delete result.createdon_datetime;
          if (typeof (result.lastlogin_datetime.$gte) != 'undefined' || typeof (result.lastlogin_datetime.$lte) != 'undefined') {
            console.log(result.lastlogin_datetime);
          } else {
            delete result.lastlogin_datetime;
          }
          this.techconsultantsListdatatable = [];
        }
        if (val == 'commission') {
          delete result.start_datetime_unix;
          this.Commmissiondatatableflag = true;
          if (typeof (result.createdon_datetime.$gte) != 'undefined' || typeof (result.createdon_datetime.$lte) != 'undefined') {
            console.log(result.createdon_datetime);
          } else {
            delete result.createdon_datetime;
          }
          delete result.lastlogin_datetime;
          delete result.type;
          this.commission_datatable = [];
          result['addedfortype'] = 'distributor';
        }
        if (val == 'contract_pending_signature') {
          delete result.start_datetime_unix;
          if (typeof (result.createdon_datetime.$gte) != 'undefined' || typeof (result.createdon_datetime.$lte) != 'undefined') {
            console.log(result.createdon_datetime);
          } else {
            delete result.createdon_datetime;
          }
          delete result.lastlogin_datetime;
          delete result.type;
          this.signatureListdatatableflag = true;
          this.signatureListdatatable = [];
          result['currentstatus'] = 'Send To Lead';
          result['leadfullname'] = { $ne: null };
        }
        // if (val == 'contract-manager') {
        //   this.contractmanagerListdatatableflag = true;
        //   delete result.createdon_datetime;
        //   delete result.lastlogin_datetime;
        //   this.contractmanagerdata = [];
        // }
        // if (val == 'closer') {
        //   this.closerListdatatableflag = true;
        //   delete result.createdon_datetime;
        //   delete result.lastlogin_datetime;
        //   this.closerListdatatable = [];
        // }
        if (val == 'job_ticket') {
          this.jobticketsListdatatableflag = true;
          this.jobcategoryListdatatable = [];
          if (result.ticketid != '') {
            result.ticketid = { $regex: result.ticketid };
          }
          // result['user_id'] = this.userservice.userid;
          delete result.type;
          delete result.start_datetime_unix;
          if (typeof (result.createdon_datetime.$gte) != 'undefined' || typeof (result.createdon_datetime.$lte) != 'undefined') {
            console.log(result.createdon_datetime);
          } else {
            delete result.createdon_datetime;
          }
          delete result.lastlogin_datetime;
        }
        if (val == 'schedule_appointment') {
          this.Scheduleddatatableflag = true;
          this.schedule_datatable = [];
          delete result.type;
          if (typeof (result.start_datetime_unix.$gte) != 'undefined' || typeof (result.start_datetime_unix.$lte) != 'undefined') {
            console.log(result.start_datetime_unix);
          } else {
            delete result.start_datetime_unix;
          }
          delete result.createdon_datetime;
          delete result.lastlogin_datetime;
        }
        console.log('The dialog was closed', result);
        Object.keys(result).forEach((k) => result[k] == '' && delete result[k]);
        const data: any = {
          // How many data showing in first page
          condition: {
            limit: 3,
            skip: 0,
          },
          sort: {
            type: 'desc',
            field: '_id',
          },

          searchcondition: result,
          secret: this.userservice.secret,
          token: this.userservice.jwttoken,
        };
        let endpoint = '';
        if (val == 'job_ticket') {
          endpoint = 'api/getjobticketlistdata';
          data.condition.limit = 7;
        }
        if (val == 'distributor' || val == 'technological-consultant') {
          endpoint = 'api/userlistview';
          data.condition.limit = 7;
        }
        if (val == 'commission') {
          endpoint = 'api/listmanagecommission';
          data.condition.limit = 5;
        }
        if (val == 'contract_pending_signature') {
          endpoint = 'api/contractrequestslistviews';
          data.condition.limit = 5;
        }
        if (val == 'schedule_appointment') {
          endpoint = 'api/schedule-appoinment-details';
          data.condition.limit = 5;
        }
        console.log(result, 'result');



        this.Apiservice.getDataWithoutToken(data, endpoint).subscribe(
          (res: any) => {
            console.log(res);
            if (val == 'distributor') {
              this.isrefresh_distributor = true;
              this.distributorListdatatable = res.results.res;
              if (this.distributorListdatatable.length == 0) {
                this.distributornorecordfound = true;
              }
              this.distributerListdatatableflag = false;
            }
            if (val == 'technological-consultant') {
              this.isrefresh_tech = true;
              this.techconsultantsListdatatable = res.results.res;
              if (this.techconsultantsListdatatable.length == 0) {
                this.technorecordfound = true;
              }
              this.techconsultantsListdatatableflag = false;
            }
            if (val == 'contract_pending_signature') {
              this.isrefresh_contract_pending_signature = true;
              this.signatureListdatatable = res.results.res;
              if (this.signatureListdatatable.length == 0) {
                this.contract_pending_signaturenorecordfound = true;
              }
              this.signatureListdatatableflag = false;
            }
            // if (val == 'contract-manager') {
            //   this.contractmanagerdata = res.results.res;
            //   this.contractmanagerListdatatableflag = false;
            // }
            // if (val == 'closer') {
            //   this.closerListdatatable = res.results.res;
            //   this.closerListdatatableflag = false;
            // }
            if (val == 'job_ticket') {
              this.isrefresh_job_ticket = true;
              this.jobcategoryListdatatable = res.results.res;
              if (this.jobcategoryListdatatable.length == 0) {
                this.jobticketnorecordfound = true;
              }
              this.jobticketsListdatatableflag = false;
            }
            if (val == 'commission') {
              this.isrefresh_commission = true;
              this.commission_datatable = res.results.res;
              if (this.commission_datatable.length == 0) {
                this.commissionnorecordfound = true;
              }
              this.Commmissiondatatableflag = false;
            }
            if (val == 'schedule_appointment') {
              this.isrefresh_schedule_appointment = true;
              this.schedule_datatable = res.results.res;
              if (this.schedule_datatable.length == 0) {
                this.scheduleappointmentnorecordfound = true;
              }
              this.Scheduleddatatableflag = false;
            }
            // this.datesearchsourcecount = res.count; // sarch count  (How many data there)
          }
        );
      }
    });
  }
  toggletraining(e: any) {
    if (e.checked == true) {
      //  console.warn('consultants', e.checked);
      this.activetrainingFlag = true;
      this.trainingListdatatableflag = true;
      let requestdata = {
        data: {
          id: '',

          'type': 'admin'
        },
        endpoint: ''
      };
      requestdata.data.id = JSON.parse(this.coockie.get('userid'));
      requestdata.endpoint = 'traing_api/traninguserpercent';
      this.Apiservice
        .getDataFortraing(requestdata)
        .subscribe((res: any) => {
          console.log(res);
          this.trainingdetails = res.results.res;
          if (this.trainingdetails.length == 0) {
            this.trainingnorecordfound = true;
          }
          this.trainingListdatatableflag = false;
        });

    } else {
      //  console.warn('consultants', e.checked);
      this.activetrainingFlag = false;
      this.trainingnorecordfound = false;
      this.trainingdetails = [];
      this.isrefresh_training_report = false;
    }
  }
  slidefortraningPrev(idx: any) {
    let slides: any = document.getElementsByClassName('main_practice_block9');
    let scount: any = document.querySelectorAll('.main_practice_block9').length;
    this.slideindex_signature = idx;
    if (this.slideindex_signature <= 0) {
      this.slideindex_signature = scount;
    }
    let slideindex_signature: any = this.slideindex_signature;
    for (var i = slides.length - 1; i >= 0; i--) {
      slides[i].style.display = 'none';
    }
    if (slideindex_signature > 0 && slides[slideindex_signature - 1] != null) {
      slides[slideindex_signature - 1].style.display = 'block';
    }
    slideindex_signature--;
  }
  slidefortrainingNext(idx: any) {
    // console.log('next', idx, this.slideindex_jobtickets)

    this.slideindex_jobtickets = 0;
    this.slideindex_jobtickets = idx + 1;
    // let i: any;
    let slides: any = document.getElementsByClassName('main_practice_block9');
    let scount: any = document.querySelectorAll('.main_practice_block9').length;

    // console.log('next 111', this.slideindex_jobtickets, scount)
    if (this.slideindex_jobtickets >= scount) {
      this.slideindex_jobtickets = 0;
    }
    let slideindex_jobtickets: any = this.slideindex_jobtickets++;

    let dots: any = document.getElementsByClassName('dot');

    for (let i = 0; i < slides.length; i++) {
      slides[i].style.display = 'none';
    }

    slideindex_jobtickets++;
    if (slideindex_jobtickets > slides.length) {
      slideindex_jobtickets = 1;
    }
    for (let i in dots.length) {
      // console.log(i, '__')
      if (dots[i] != null) {
        dots[i].className = dots[i].className.replace(' active', '');
      }
    }
    if (slideindex_jobtickets > 0 && slides[slideindex_jobtickets - 1] != null)
      slides[slideindex_jobtickets - 1].style.display = 'block';
    if (slideindex_jobtickets > 0 && dots[slideindex_jobtickets - 1] != null)
      dots[slideindex_jobtickets - 1].className += ' active';

    this.slide_jobtickets('e');
  }
  refreshlistview(val: any) {
    if (val == 'distributor') {
      this.isrefresh_distributor = false;
      this.distributornorecordfound = false;
      this.distributerListdatatableflag = true;
      this.distributorListdatatable = [];
      const endpointc = 'api/userlistview-count';
      const endpoint = 'api/userlistview';
      const data: any = {
        // How many data showing in first page
        secret: this.coockie.get('secret'),
        token: this.coockie.get('jwtToken'),
        source: '',
        condition: {
          // condition for how much data show in one time
          limit: 7,
          skip: 0,
        },
        sort: {
          type: 'desc', // data sort by decreasing order
          field: 'logincounts', // data sort by first name
        },
        searchcondition: { type: 'distributor' },
      };

      this.Apiservice.getDataWithoutToken(data, endpointc).subscribe(
        (res: any) => {
          this.distributor_datesearchsourcecount = res.count; // sarch count  (How many data there)
          this.distributerListdatatableflag = false;
        }
      );

      this.Apiservice.getDataWithoutToken(data, endpoint).subscribe(
        (res: any) => {
          this.distributorListdatatable = res.results.res; // sarch count  (How many data there)

          // this.techconsultantsListdatatable = this.adminarray.mostactive_technological_consultant; // sarch count  (How many data there)
          this.distributerListdatatableflag = false;
        }
      );
    }
    if (val == 'technological-consultant') {
      this.isrefresh_tech = false;
      this.techconsultantsListdatatableflag = true;
      this.techconsultantsListdatatable = [];
      this.technorecordfound = false;
      const endpointc = 'api/userlistview-count';
      const endpoint = 'api/userlistview';
      const data: any = {
        // How many data showing in first page
        secret: this.coockie.get('secret'),
        token: this.coockie.get('jwtToken'),
        source: '',
        condition: {
          // condition for how much data show in one time
          limit: 7,
          skip: 0,
        },
        sort: {
          type: 'desc', // data sort by decreasing order
          field: 'logincounts', // data sort by first name
        },
        searchcondition: { type: 'technological-consultant' },
      };

      this.Apiservice.getDataWithoutToken(data, endpointc).subscribe(
        (res: any) => {
          this.datesearchsourcecount = res.count; // sarch count  (How many data there)
          this.techconsultantsListdatatableflag = false;
        }
      );

      this.Apiservice.getDataWithoutToken(data, endpoint).subscribe(
        (res: any) => {
          this.techconsultantsListdatatable = res.results.res; // sarch count  (How many data there)

          // this.techconsultantsListdatatable = this.adminarray.mostactive_technological_consultant; // sarch count  (How many data there)
          this.techconsultantsListdatatableflag = false;
        }
      );
    }
    if (val == 'job_ticket') {
      this.jobticketsListdatatableflag = true;
      this.jobcategoryListdatatable = [];
      this.isrefresh_job_ticket = false;
      this.jobticketnorecordfound = false;
      const endpointc = 'api/getjobticketlistdata-count';
      const endpoint = 'api/getjobticketlistdata';
      const data: any = {
        // How many data showing in first page
        secret: this.userservice.secret,
        token: this.userservice.jwttoken,
        source: '',
        condition: {
          // condition for how much data show in one time
          limit: 7,
          skip: 0,
        },
        sort: {
          type: 'desc', // data sort by decreasing order
          field: 'fullname', // data sort by first name
        },
        // searchcondition: { type: 'getjobticketcategorylistdata' },
      };

      this.Apiservice.getDataWithoutToken(data, endpointc).subscribe(
        (res: any) => {
          this.datesearchsourcecount = res.count; // sarch count  (How many data there)
          this.jobticketsListdatatableflag = false;
        }
      );

      this.Apiservice.getDataWithoutToken(data, endpoint).subscribe(
        (res: any) => {
          this.jobcategoryListdatatable = res.results.res; // sarch count  (How many data there)
          // this.jobcategoryListdatatable = this.adminarray.recentjobtickets; // sarch count  (How many data there)
          this.jobticketsListdatatableflag = false;
        }
      );
    }
    if (val == 'contract_pending_signature') {
      this.isrefresh_contract_pending_signature = true;
      this.contract_pending_signaturenorecordfound = false;
      this.signatureListdatatable = [];
      this.signatureListdatatable = this.adminarray.contract_pending_signature;
    }
    if (val == 'commission') {
      this.Commmissiondatatableflag = true;
      this.isrefresh_commission = false;
      this.commissionnorecordfound = false;
      this.commission_datatable = [];
      console.log(this.commission_datatable, ':::::::::::::::::::::::::');
      setTimeout(() => {
        this.Commmissiondatatableflag = false;
        this.commission_datatable = this.commissionManagement;
      }, 500);
      console.log(this.commission_datatable, ':::::::::::::::::::::::::');

    }
    if (val == 'schedule_appointment') {
      this.isrefresh_schedule_appointment = false;
      this.Scheduleddatatableflag = true;
      this.scheduleappointmentnorecordfound = false;
      this.schedule_datatable = [];
      let data = {
        token: this.userservice.jwttoken,
        condition: {
          limit: 5,
          skip: 0,
        },
        sarchconditions: {},
        sort: {
          type: 'desc',
          field: 'booking_date',
        },
      };
      this.Apiservice.customRequest(
        data,
        'list-upcoming-booked-events',
        environment['api_calender_url']
      ).subscribe((res: any) => {
        this.scheduleappointment = res.results.res;
        // if(this.scheduleappointment.length > 0){
        //   this.mostactiveflagfornextblock = this.mostactiveflagfornextblock + 1
        // }
        this.schedule_datatable = this.scheduleappointment;
        this.Scheduleddatatableflag = false;
      });
    }
  }
  onLiblistingButtonadminChange(val: any) {
    // console.log(val, 'lllllll')
    // if (val.action == 'searchbar') {
    //   const dialogRef = this.dialog.open(SearchComponent, {
    //     panelClass: 'SearchComponent',
    //     data: { val }
    //   })
    //   dialogRef.afterClosed().subscribe((result: any) => {
    //     this.searchsettingsallTypeOfList.textsearch[0].value = result
    //   })
    // }
  }

  ngOnDestroy() {
    clearTimeout(this.slide_technologicaltimeout);
    clearTimeout(this.slidesales_personstimeout);
    clearTimeout(this.slide_jobticketstimeout);
    clearTimeout(this.slidetimeout);

    clearTimeout(this.slide_signaturetimeout);

    clearTimeout(this.slide_productstimeout);

    clearTimeout(this.slide_resourcestimeout);
    clearTimeout(this.slideindex_scheduletimeout);
    clearTimeout(this.slideindex_leadtimeout);
  }

  userdetails(val: any) {
    // console.log(val, 'value.....');

    this.router.navigate(['/user-details/' + val.type + '/' + val._id]);
  }
  // gameplanmodal() {

  //   let datafortraining = {
  //     condition: {

  //       associated_training: '6007d728411dbf000889b1ef'
  //     },
  //     user_id: this.userservice.userid
  //   }

  //   this.Apiservice.getDataWithoutToken(datafortraining, 'api1/dashboardtrainingdata').subscribe((res: any) => {
  //     // console.log(res, 'gggggggggggggggg')

  //     if (res.status === 'success' && Object.keys(res.results).length > 0) {
  //       if (res.results.alllessondata != null && res.results.done_lesson_by_cat_by_user != '' && res.results.done_lesson_by_cat_by_user[0].lessonsdone != null && res.results.alllessondata == res.results.done_lesson_by_cat_by_user[0].lessonsdone) {
  //         this.coockie.set('training', '1', undefined, '/')
  //       }
  //       if (res.results.alllessondata != null && res.results.done_lesson_by_cat_by_user != '' && res.results.done_lesson_by_cat_by_user[0].lessonsdone != null && res.results.alllessondata == res.results.done_lesson_by_cat_by_user[0].lessonsdone && res.results.calendar_booking_data == 0) {
  //         res.results.done_lesson_by_cat_by_user[0].lesson_id = '600e67a4ca205a0008b00ef4';
  //         this.gameplan_alert = true;
  //         const dialogRef = this.dialog.open(GameplanModalComponent, {
  //           panelClass: 'schedule_modal',
  //           // width: '900px',
  //           height: 'auto',
  //           data: res.results.done_lesson_by_cat_by_user[0]
  //         });
  //         // dialogRef.afterOpened().subscribe(_ => {
  //         //   setTimeout(() => {
  //         //     dialogRef.close();
  //         //   }, 6000)
  //         // })
  //         dialogRef.disableClose = true;
  //         dialogRef.afterClosed().subscribe((result: any) => {
  //           if (result != null && typeof (result) != 'undefined' && result.flag != null && result.flag == true) {
  //             this.router.navigateByUrl(this.googleScheduleRoute + result.associated_training + '/' + result.lesson_id);
  //           }
  //           else if (result == null && typeof (result) == 'undefined' || result.flag == null || result.flag == false) {
  //             this.calenderRoute = this.googleScheduleRoute + result.associated_training + '/' + result.lesson_id
  //           }
  //         })
  //       } else {
  //         this.success_booking = true
  //       }
  //     }
  //   })
  // }
  close() {
    this.gameplan_alert = false;
    this.success_booking = false;
  }
  createcontract() {
    const dialogRef = this.dialog.open(DialogNewContractDialog, {
      // width: '250px',
      data: {},
      panelClass: ['contractAadd', 'newcontractmodal']
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed');
    });
  }
  viewcontract() { }
}
@Component({
  selector: 'dialog-new-contract-dialog',
  templateUrl: 'dialog-new-contract-dialog.html',
  styleUrls: ['./admindashboard.component.css']
})
export class DialogNewContractDialog {
  public formfieldrefresh: boolean = true;
  public formfieldrefreshdata: any = null;
  public formdata: any;
  public product_details: any = [
    { val: '604aff3691b7c80008ca11a8', name: 'PECE' },
    { val: '604a0b6e40962e00088f0d79', name: 'TM-FLOW ANS MEDICAL DEVICE' },
    { val: '612c883f118c110009f02820', name: 'BioEnergetics' },
    { val: '612c89242005f400082d5fb1', name: 'RST Sanexas' },
  ];
  public product_depeneds_on_tech: any = [];
  public template_details_with_product: any = [
    { val: '618b7fc373e50000097b6324', name: 'PECE Contract', product_id: '604aff3691b7c80008ca11a8' },
    { val: '618cf5bf43dbf00009e6c6c2', name: 'TM-Flow Contract', product_id: '604a0b6e40962e00088f0d79' },
    { val: '618d0c934206a40008e814d7', name: 'Credit Contract', product_id: '604a0b6e40962e00088f0d79' },
    { val: '618d0cc24206a40008e814dc', name: 'Warranty Contract', product_id: '604a0b6e40962e00088f0d79' },
    { val: '618e1468bcba120009fdac1c', name: 'BioEntergetics Contract', product_id: '612c883f118c110009f02820' },
  ];
  public template_details: any = [];
  public lead_id: any = '';
  public condition: any = 0;
  public loader: boolean = false;
  public signee_id: any = '';
  public lead_details: any = {};
  public showcontractui: boolean = false;
  public template_id: any = '';
  constructor(
    public dialogRef: MatDialogRef<DialogNewContractDialog>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    public apiService: ApiService,
    private snackBar: MatSnackBar,
    public userservice: UserserviceService,
    public router: Router,
    public cookies: CookieService
  ) {

    this.formdata = {
      successmessage: 'Added Successfully !!',
      redirectpath: '/admin/dashboard',
      submitactive: true,
      submittext: 'Preview And Submit',
      resettext: 'Reset',
      canceltext: 'Cancel',
      apiUrl: this.apiService.api_url,
      // endpoint: 'api/',
      jwttoken: this.apiService.jwttoken,
      cancelroute: '/admin/dashboard',

      fields: [
        {
          label: 'Tech Name',
          name: 'tech_id',
          type: 'autocomplete',
          endpoint: "api10/autocomplete-tech-search-contracts",
          search_field: "search_str",
          // val: [],
          // value: '',
          validations: [{ rule: 'required', message: 'Tech Name is Required' }],
        },
        {
          label: 'Lead/Practice Name',
          name: 'lead_id',
          type: 'hidden',
          endpoint: "/",
          search_field: "search_str",
          // val: [],
          // value: '',
          validations: [{ rule: 'required', message: 'Lead Name is Required' }],
        },
        {
          label: 'Product Name',
          name: 'product_id',
          type: 'hidden',
          value: '',
          val: [],
          validations: [{ rule: 'required', message: 'Product Name is Required' }]
        },
        {
          label: 'Template Name',
          name: 'template_id',
          type: 'hidden',
          value: '',
          val: [],
          validations: [{ rule: 'required', message: 'Template Name is Required' }],
        },
      ]

    }
  }
  listenFormFieldChange(val: any) {
    val = JSON.parse(JSON.stringify(val));
    let key = '';
    console.log(val);
    if (typeof (val.field) != 'undefined' && val.field != null) {
      if (typeof (val.field.name) != 'undefined' && val.field.name != null && val.field.name == 'lead_id') {
        if (typeof (val.autocompletedata) != 'undefined' && val.autocompletedata != null) {
          this.lead_details = [];
          let send_data = {
            "secret": this.cookies.get('secret'),
            "token": this.cookies.get('jwtToken'),
            "condition": {
              "id": "_id",
              "limit": 1,
              "skip": 0,
              "type": "lead",
              "_id": val.autocompletedata.key
            }
          }
          this.apiService.getDataWithoutToken(send_data, 'api/getoneleadfolderview').subscribe((res: any) => {
            this.lead_details = res.results.userView[0];
            this.signee_id = res.results.userView[0].singeealldata.length > 0 ? res.results.userView[0].singeealldata[0]._id : '';
          })
        }
      }
      if (val.field == 'formcancel') {
        this.onNoClick();
      }
      if (val.field.name == 'product_id') {
        this.template_details = [];
        for (const key in this.template_details_with_product) {
          if (this.template_details_with_product[key].product_id == val.fieldval) {
            this.template_details.push(this.template_details_with_product[key]);
          }
        }
        console.log(this.template_details);
        this.formdata.fields[3].val = this.template_details;
        // this.formfieldrefreshdata = { field: 'removefromcontrol', value: { name: 'template_id' } };
        // setTimeout(() => {
        //   this.formfieldrefreshdata = {
        //     field: 'addfromcontrol', value: {
        //       label: "Template Name",
        //       name: "template_id",
        //       type: 'select',
        //       value: '',
        //       val: this.template_details,
        //       validations: [{ rule: 'required', message: 'Template Name is Required' }],
        //       after: 'product_id',
        //     }
        //   };
        // }, 100);
        this.remove_contract_field();
        setTimeout(() => {
          this.formfieldrefreshdata = { field: 'template_id', value: '' };
          this.showcontractui = false;
          var form: any = document.getElementsByTagName("input");
          // console.log(form);
          var elements = form;
          // console.log(elements);
          for (var i = 0, len = elements.length; i < len; ++i) {
            elements[i].disabled = false;
          }
        }, 100);
        // this.add_contract_field('', this.lead_details);
      }
      if (val.field.name == 'template_id') {
        this.loader = true;
        key = val.fieldval;
        this.remove_contract_field();
        this.showcontractui = false;
        this.template_id = val.fieldval;
        console.warn(val, this.lead_id);
        let event_type;
        let msg: any = '';
        if (key == '618d0c934206a40008e814d7') {
          // this.loader = false;
          // this.add_contract_field(key,this.lead_details);
          let send_data = {
            "secret": this.cookies.get('secret'),
            "token": this.cookies.get('jwtToken'),
            "contract_id": key,
          };
          this.apiService.getDataWithoutToken(send_data, 'api10/get-contract-details').subscribe((res: any) => {
            let alldata = res.result.contract_template[0];
            document.querySelector(".topcontractcls").innerHTML = alldata.topcontract;
            document.querySelector(".tarmsspecificationcls").innerHTML = alldata.tarmsspecification;
            document.querySelector(".buttomcontractcls").innerHTML = alldata.buttomcontract;
            var form: any = document.getElementsByTagName("input");
            // console.log(form);
            var elements = form;
            // console.log(elements);
            for (var i = 0, len = elements.length; i < len; ++i) {
              elements[i].disabled = true;
            }
            document.querySelector("#company_address").setAttribute('value', this.lead_details.address);
            document.querySelector("#company_city").setAttribute('value', this.lead_details.city);
            document.querySelector("#company_state").setAttribute('value', this.lead_details.state);
            document.querySelector("#company_zip").setAttribute('value', this.lead_details.zip);
            document.querySelector("#authorized_signer").setAttribute('value', this.lead_details.singeealldata.length > 0 ? this.lead_details.singeealldata[0].Full_Name : '');
            document.querySelector("#Cell_Phone").setAttribute('value', this.lead_details.phone);
            document.querySelector("#Website").setAttribute('value', this.lead_details.Website);
            document.querySelector("#Email").setAttribute('value', this.lead_details.email);
            document.querySelector("#company_address").setAttribute('readonly', 'true');
            document.querySelector("#company_city").setAttribute('readonly', 'true');
            document.querySelector("#company_state").setAttribute('readonly', 'true');
            document.querySelector("#company_zip").setAttribute('readonly', 'true');
            document.querySelector("#authorized_signer").setAttribute('readonly', 'true');
            document.querySelector("#Cell_Phone").setAttribute('readonly', 'true');
            document.querySelector("#Website").setAttribute('readonly', 'true');
            document.querySelector("#Email").setAttribute('readonly', 'true');
            Object.entries(this.lead_details).forEach(
              ([key, value]: any) => {
                console.log(key, '*----------*', value);
                if (key == 'fullname') {
                  document.querySelector(`#Legal_Company_Name`).setAttribute('value', value);
                  document.querySelector(`#Legal_Company_Name`).setAttribute('readonly', 'true');
                }
                if (key == 'singeealldata') {
                  document.querySelector(`#Name1`).setAttribute('value', value.length > 0 ? value[0].First_Name + " " + value[0].Last_Name : '');
                  document.querySelector(`#Name1`).setAttribute('readonly', 'true');

                  document.querySelector(`#Home_Address`).setAttribute('value', value.length > 0 ? value[0].Mailing_Street + "," + value[0].Mailing_City + "," + value[0].Mailing_State + "," + value[0].Mailing_Country : '');
                  document.querySelector(`#Home_Address`).setAttribute('readonly', 'true');

                  document.querySelector(`#Home_Phone`).setAttribute('value', value.length > 0 ? value[0].Phone : '');
                  document.querySelector(`#Home_Phone`).setAttribute('readonly', 'true');

                  document.querySelector(`#City_2`).setAttribute('value', value.length > 0 ? value[0].Mailing_City : '');
                  document.querySelector(`#City_2`).setAttribute('readonly', 'true');

                  document.querySelector(`#State_2`).setAttribute('value', value.length > 0 ? value[0].Mailing_State : '');
                  document.querySelector(`#State_2`).setAttribute('readonly', 'true');

                  document.querySelector(`#Zip2`).setAttribute('value', value.length > 0 ? value[0].Mailing_Zip : '');
                  document.querySelector(`#Zip2`).setAttribute('readonly', 'true');
                }
                if (key == 'city') {
                  document.querySelector(`#City_personal_address`).setAttribute('value', value);
                  document.querySelector(`#City_personal_address`).setAttribute('readonly', 'true');
                }
                if (key == 'address') {
                  document.querySelector(`#State_personal_address`).setAttribute('value', value);
                  document.querySelector(`#State_personal_address`).setAttribute('readonly', 'true');
                }
                if (key == 'zip') {
                  document.querySelector(`#Zip_personal_address`).setAttribute('value', value);
                  document.querySelector(`#Zip_personal_address`).setAttribute('readonly', 'true');
                }
                if (key == 'phone') {
                  document.querySelector(`#Phone2`).setAttribute('value', value);
                  document.querySelector(`#Phone2`).setAttribute('readonly', 'true');
                }
              }
            )
            const submit = document.getElementById('submit') as HTMLButtonElement;
            submit.style.display = 'none';
            const cancel = document.getElementById('cancel') as HTMLButtonElement;
            cancel.style.display = 'none';
            this.add_contract_field(key, this.lead_details);
            this.showcontractui = true;
            this.loader = false;
          })
        } else {
          if (key == '618d0cc24206a40008e814dc') {
            event_type = 5;
            msg = 'You must book a Warranty Call to continue.';
          } else {
            let product_name: any = this.product_details.find(e => e.val == val.fromval.product_id);
            event_type = 2;
            msg = `You must book a ${product_name.name} Discovery Call to continue.`;
          }
          let send_data = {
            "id": this.lead_id,
            "event_type": event_type,
            "product_id": val.fromval.product_id
          }
          this.apiService.getDataWithoutToken(send_data, 'api10/send-contract-condition').subscribe((res: any) => {
            console.log(res);
            if (res.result.discoveryorwarrantycall == 1) {
              msg = '';
            }
            if (res.result.contact.length == 0) {
              if (msg != '') {
                msg = msg + '\n You must add a dicision maker for this lead.';
              } else {
                msg = msg + 'You must add a dicision maker for this lead.';
              }
            } else {
              this.signee_id = res.result.contact[0]._id;

            }
            console.log(msg);

            if (res.result.discoveryorwarrantycall == 0 || res.result.contact.length == 0) {
              this.remove_contract_field();
              this.snackBar.open(msg, 'Ok', {
                duration: 10000
              });
              this.loader = false;
            } else {
              let send_data = {
                "secret": this.cookies.get('secret'),
                "token": this.cookies.get('jwtToken'),
                "contract_id": key,
              };
              this.apiService.getDataWithoutToken(send_data, 'api10/get-contract-details').subscribe((res: any) => {
                let alldata = res.result.contract_template[0];
                document.querySelector(".topcontractcls").innerHTML = alldata.topcontract;
                document.querySelector(".tarmsspecificationcls").innerHTML = alldata.tarmsspecification;
                document.querySelector(".buttomcontractcls").innerHTML = alldata.buttomcontract;
                var form: any = document.getElementsByTagName("input");
                // console.log(form);
                var elements = form;
                // console.log(elements);
                for (var i = 0, len = elements.length; i < len; ++i) {
                  elements[i].disabled = true;
                }
                if (key == '618b7fc373e50000097b6324') {
                  Object.entries(this.lead_details).forEach(
                    ([key, value]: any) => {
                      console.log(key, '*----------*', value);
                      if (key == 'fullname') {
                        document.querySelector(`#leadname`).setAttribute('value', value);
                        document.querySelector(`#leadname`).setAttribute('readonly', 'true');
                      }
                      if (key == 'address') {
                        document.querySelector(`#address`).setAttribute('value', value);
                        document.querySelector(`#address`).setAttribute('readonly', 'true');
                      }
                    }
                  )
                }
                if (key == '618d0cc24206a40008e814dc') {
                  document.querySelector("#equipment").setAttribute('value', 'TM-FLOW ANS MEDICAL DEVICE');
                  document.querySelector("#equipment").setAttribute('readonly', 'true');
                  Object.entries(this.lead_details).forEach(
                    ([key, value]: any) => {
                      console.log(key, '*----------*', value);
                      if (key == 'fullname') {
                        document.querySelector(`#PracticeName`).setAttribute('value', value);
                        document.querySelector(`#PracticeName`).setAttribute('readonly', 'true');
                      }
                      if (key == 'street') {
                        document.querySelector(`#Street`).setAttribute('value', value);
                        document.querySelector(`#Street`).setAttribute('readonly', 'true');
                      }
                      if (key == 'city') {
                        document.querySelector(`#City`).setAttribute('value', value);
                        document.querySelector(`#City`).setAttribute('readonly', 'true');
                      }
                      if (key == 'state') {
                        document.querySelector(`#State`).setAttribute('value', value);
                        document.querySelector(`#State`).setAttribute('readonly', 'true');
                      }
                      if (key == 'zip') {
                        document.querySelector(`#Zip`).setAttribute('value', value);
                        document.querySelector(`#Zip`).setAttribute('readonly', 'true');
                      }
                      if (key == 'phone') {
                        document.querySelector(`#Phone`).setAttribute('value', value);
                        document.querySelector(`#Phone`).setAttribute('readonly', 'true');
                      }
                      // if (key == 'singeealldata') {
                      //   document.querySelector(`#auth_signatory`).setAttribute('value', value.length > 0 ? value[0].First_Name + " " + value[0].Last_Name : '');
                      //   document.querySelector(`#auth_signatory`).setAttribute('readonly', 'true');

                      //   document.querySelector(`#printed_name`).setAttribute('value', value.length > 0 ? value[0].First_Name + " " + value[0].Last_Name : '');
                      //   document.querySelector(`#printed_name`).setAttribute('readonly', 'true');

                      // }
                    }
                  )
                }
                const submit = document.getElementById('submit') as HTMLButtonElement;
                submit.style.display = 'none';
                const cancel = document.getElementById('cancel') as HTMLButtonElement;
                cancel.style.display = 'none';
                this.add_contract_field(key, this.lead_details);
                this.showcontractui = true;
                this.loader = false;
              })
            }
          })
        }
      }
      if (val.field == 'fromsubmit') {
        this.loader = true;
        let contractflag: any = '';
        let product_name: any = this.template_details_with_product.find(e => e.val == val.fromval.template_id);
        let contract = val.fromval;
        let lead_id = val.fromval.lead_id
        let tech_id = val.fromval.tech_id
        let product_id = val.fromval.product_id
        let template_id = val.fromval.template_id
        delete contract.product_id;
        delete contract.lead_id;
        delete contract.tech_id;
        delete contract.template_id;
        if (product_name.name == 'PECE Contract') {
          contractflag = 'Pece Contract'
        }
        if (product_name.name == 'TM-Flow Contract') {
          contractflag = 'tmflow_contract'
        }
        if (product_name.name == 'Credit Contract') {
          contractflag = 'credit'
        }
        if (product_name.name == 'Warranty Contract') {
          contractflag = 'warrenty'
        }
        let data = {
          lead_id: lead_id,
          tech_id: tech_id,
          product_id: product_id,
          template_id: template_id,
          contractdata: contract,
          "contractflag": contractflag,
          // contracts: [
          //   {
          //     status: "sent for signature",
          //     added_by_id: this.userservice.userid,
          //     addedby: this.userservice.fullname,
          //     addedondatetime: Math.round(new Date().getTime()),
          //     type: this.userservice.type,
          //   },
          // ],
          contact_id: this.signee_id,
        };
        this.apiService.getDataWithoutToken(data, 'api/update-new_contract').subscribe((res: any) => {
          console.log(res);
          this.loader = false;
          // this.snackBar.open('Send Contract Successfully', 'Dismiss', {
          //   duration: 5000
          // });
          if (template_id == "618b7fc373e50000097b6324") {
            // this.router.navigateByUrl('/new-contract/pece',{state:  val.custombuttonclick.data })
            window.open('/new-contract/pece/' + res.result._id, '_blank');
            // this.router.navigateByUrl('/new-contract/pece/'+res.result._id);
          }
          if (template_id == "618cf5bf43dbf00009e6c6c2") {
            // this.router.navigateByUrl('/new-contract/tm-flow',{state:  val.custombuttonclick.data })
            window.open('/new-contract/tm-flow/' + res.result._id, '_blank');
            // this.router.navigateByUrl('/new-contract/tm-flow/'+res.result._id)
          }
          if (template_id == "618d0cc24206a40008e814dc") {
            // this.router.navigateByUrl('/new-contract/warranty',{state:  val.custombuttonclick.data })
            window.open('/new-contract/warranty/' + res.result._id, '_blank');

            // this.router.navigateByUrl('/new-contract/warranty/'+res.result._id)
          }
          if (template_id == "618d0c934206a40008e814d7") {
            // this.router.navigateByUrl('/new-contract/credit',{state:  val.custombuttonclick.data })
            window.open('/new-contract/credit/' + res.result._id, '_blank');
            // this.router.navigateByUrl('/new-contract/credit/'+res.result._id)
          }
          if (template_id == "618e1468bcba120009fdac1c") {
            // this.router.navigateByUrl('/new-contract/bio-energetics',{state:  val.custombuttonclick.data })
            window.open('/new-contract/bio-energetics/' + res.result._id, '_blank');
            // this.router.navigateByUrl('/new-contract/bio-energetics/'+res.result._id)
          }
          this.onNoClick();
        })
      }
    }
    if (typeof (val.autocompletedata) != 'undefined' && val.autocompletedata != null) {
      if (typeof (val.autocompletefiledvalue) != 'undefined' && val.autocompletefiledvalue != null && val.field && val.field.name == 'tech_id') {
        this.formfieldrefreshdata = { field: 'removefromcontrol', value: { name: 'lead_id' } };
        setTimeout(() => {
          this.formfieldrefreshdata = { field: 'product_id', value: '' };
        }, 50);
        setTimeout(() => {
          this.formfieldrefreshdata = { field: 'template_id', value: '' };
        }, 100);
        setTimeout(() => {
          this.formfieldrefreshdata = {
            field: 'addfromcontrol', value: {
              label: 'Lead/Practice Name',
              name: 'lead_id',
              type: 'autocomplete',
              endpoint: "api10/autocomplete-lead-search-contracts?id=" + val.autocompletedata.key,
              search_field: "search_str",
              validations: [{ rule: 'required', message: 'Lead Name is Required' }],
              after: 'tech_id'
            },
          };
        }, 200);
        setTimeout(() => {
          this.remove_contract_field();
        }, 300);
        this.formdata.fields[2].type = 'hidden';
        this.formdata.fields[3].type = 'hidden';
        // this.formdata.fields[1].endpoint = "api10/autocomplete-lead-search-contracts?id=" + val.autocompletedata.key;
        this.product_depeneds_on_tech = [];
        for (const key1 in this.product_details) {
          for (const key in val.autocompletedata.products) {
            if (this.product_details[key1].val == val.autocompletedata.products[key]) {
              this.product_depeneds_on_tech.push(this.product_details[key1]);
            }
          }
        }
        console.log(this.product_depeneds_on_tech);
        // this.formdata.fields[1].type = 'autocomplete';
        this.formdata.fields[2].val = this.product_depeneds_on_tech;
        this.formdata.fields[3].val = this.template_details;
      }
    }
    if (typeof (val.autocompletedata) != 'undefined' && val.autocompletedata != null) {
      if (typeof (val.autocompletefiledvalue) != 'undefined' && val.autocompletefiledvalue != null && val.field && val.field.name == 'lead_id') {
        this.formfieldrefreshdata = { field: 'product_id', value: '' };
        setTimeout(() => {
          this.formfieldrefreshdata = { field: 'template_id', value: '' };
        }, 50);
        setTimeout(() => {
          this.remove_contract_field();
        }, 100);
        this.lead_id = val.autocompletedata.key;
        this.formdata.fields[2].type = 'select';
        this.formdata.fields[3].type = 'select';
      }
    }
    if (typeof (val.removedDataSet) != 'undefined' && val.removedDataSet != null && typeof (val.val) != 'undefined' && val.val != null) {
      if (val.val.name == 'tech_id') {
        this.formfieldrefreshdata = {
          field: 'resetform'
        };
        this.formdata.fields[1].type = 'hidden';
        this.formdata.fields[2].type = 'hidden';
        this.formdata.fields[3].type = 'hidden';
        this.showcontractui = false;
        var form: any = document.getElementsByTagName("input");
        // console.log(form);
        var elements = form;
        // console.log(elements);
        for (var i = 0, len = elements.length; i < len; ++i) {
          elements[i].disabled = false;
        }
      }
      if (val.val.name == 'lead_id') {
        this.formfieldrefreshdata = { field: 'product_id', value: '' };
        setTimeout(() => {
          this.formfieldrefreshdata = { field: 'template_id', value: '' };
        }, 50);
        setTimeout(() => {
          this.remove_contract_field();
        }, 100);
        this.formdata.fields[2].type = 'hidden';
        this.formdata.fields[3].type = 'hidden';
        this.showcontractui = false;
        var form: any = document.getElementsByTagName("input");
        // console.log(form);
        var elements = form;
        // console.log(elements);
        for (var i = 0, len = elements.length; i < len; ++i) {
          elements[i].disabled = false;
        }
      }
    }
    if (val.field.name == 'Federal_Tax_ID') {
      document.querySelector("#Federal_Tax_ID").setAttribute('value', val.fieldval);
    }
    if (val.field.name == 'Medical_Business_License') {
      document.querySelector("#Medical_Business_License").setAttribute('value', val.fieldval);
    }
    if (val.field.name == 'Title') {
      document.querySelector("#Title").setAttribute('value', val.fieldval);
    }
    if (val.field.name == 'Bus_Phone') {
      document.querySelector("#Bus_Phone").setAttribute('value', val.fieldval);
    }
    if (val.field.name == 'Business_Structure') {
      let s_corp = document.getElementById(`${val.field.name}_S_Corp`) as HTMLInputElement;
      s_corp.disabled = true;
      s_corp.checked = false;
      let corp = document.getElementById(`${val.field.name}_Corp`) as HTMLInputElement;
      corp.disabled = true;
      corp.checked = false;
      let lls = document.getElementById(`${val.field.name}_LLs`) as HTMLInputElement;
      lls.disabled = true;
      lls.checked = false;
      let sol_prop = document.getElementById(`${val.field.name}_Sol_Prop`) as HTMLInputElement;
      sol_prop.disabled = true;
      sol_prop.checked = false;
      const ele = document.getElementById(`${val.field.name}_${val.fieldval}`) as HTMLInputElement;
      ele.checked = true;
      ele.disabled = false;
    }
    if (val.field.name == 'Term_Requested') {
      let t24 = document.getElementById(`${val.field.name}_24`) as HTMLInputElement;
      t24.disabled = true;
      t24.checked = false;
      let t26 = document.getElementById(`${val.field.name}_26`) as HTMLInputElement;
      t26.disabled = true;
      t26.checked = false;
      let t38 = document.getElementById(`${val.field.name}_38`) as HTMLInputElement;
      t38.disabled = true;
      t38.checked = false;
      let t60 = document.getElementById(`${val.field.name}_60`) as HTMLInputElement;
      t60.disabled = true;
      t60.checked = false;
      const ele = document.getElementById(`${val.field.name}_${val.fieldval}`) as HTMLInputElement;
      ele.checked = true;
      ele.disabled = false;
    }
    if (val.field.name == 'Social_Security') {
      document.querySelector("#Social_Security").setAttribute('value', val.fieldval);
    }
    if (val.field.name == 'ownership_percentage') {
      document.querySelector("#ownership_percentage").setAttribute('value', val.fieldval);
    }
    if (val.field.name == 'Own_Rent_Home') {
      document.querySelector("#Own_Rent_Home").setAttribute('value', val.fieldval);
    }
    if (val.field.name == 'Name_2') {
      document.querySelector("#Name_2").setAttribute('value', val.fieldval);
    }
    if (val.field.name == 'Social_Security_2') {
      document.querySelector("#Social_Security_2").setAttribute('value', val.fieldval);
    }
    if (val.field.name == 'Ownership1') {
      document.querySelector("#Ownership1").setAttribute('value', val.fieldval);
    }
    if (val.field.name == 'Own_Rent_Home_2') {
      document.querySelector("#Own_Rent_Home_2").setAttribute('value', val.fieldval);
    }
    if (val.field.name == 'Home_Address_2') {
      document.querySelector("#Home_Address_2").setAttribute('value', val.fieldval);
    }
    if (val.field.name == 'Home_Phone_2') {
      document.querySelector("#Home_Phone_2").setAttribute('value', val.fieldval);
    }
    if (val.field.name == 'Name_of_Bank_Branch') {
      document.querySelector("#Name_of_Bank_Branch").setAttribute('value', val.fieldval);
    }
    if (val.field.name == 'Bank_Branch_Phone') {
      document.querySelector("#Bank_Branch_Phone").setAttribute('value', val.fieldval);
    }
    if (val.field.name == 'Checking_Acct') {
      document.querySelector("#Checking_Acct").setAttribute('value', val.fieldval);
    }
    if (val.field.name == 'Contact_Name') {
      document.querySelector("#Contact_Name").setAttribute('value', val.fieldval);
    }
    if (val.field.name == 'Phone1') {
      document.querySelector("#Phone1").setAttribute('value', val.fieldval);
    }
    if (val.field.name == 'How_Long_Has_This_Account_Been_Open') {
      document.querySelector("#How_Long_Has_This_Account_Been_Open").setAttribute('value', val.fieldval);
    }
    if (val.field.name == 'Equipment_Cost') {
      document.querySelector("#Equipment_Cost").setAttribute('value', `$${val.fieldval}`);
    }
    if (val.field.name == 'Vendor_Supplier') {
      document.querySelector("#Vendor_Supplier").setAttribute('value', val.fieldval);
    }
    if (val.field.name == 'Contact_Name1') {
      document.querySelector("#Contact_Name1").setAttribute('value', val.fieldval);
    }
    if (val.field.name == 'Equipment_Type') {
      document.querySelector("#Equipment_Type").setAttribute('value', val.fieldval);
    }
    if (val.field.name == 'Date_Closed') {
      document.querySelector("#Date_Closed").setAttribute('value', val.fieldval);
    }
    if (val.field.name == 'Experian') {
      document.querySelector("#Experian").setAttribute('value', val.fieldval);
    }
    if (val.field.name == 'Equifax') {
      document.querySelector("#Equifax").setAttribute('value', val.fieldval);
    }
    if (val.field.name == 'TransUnion') {
      document.querySelector("#TransUnion").setAttribute('value', val.fieldval);
    }
    if (val.field.name == 'Credit') {
      document.querySelector("#Credit").setAttribute('value', val.fieldval);
    }
    if (val.field.name == 'Serial_Number') {
      document.querySelector("#Serial_Number").setAttribute('value', val.fieldval);
    }
    if (val.field.name == 'Pick_List_2') {
      document.querySelector("#Pick_List_2").setAttribute('value', val.fieldval);
    }
  }
  // add_contract_field(key) {
  //   this.remove_contract_field();
  //   switch (key) {
  //     case '618d0c934206a40008e814d7': // Credit application
  //       setTimeout(() => {
  //         this.formfieldrefreshdata = {
  //           field: 'addfromcontrol',
  //           value: [
  //             {
  //               label: "Federal Tax ID",
  //               name: "Federal_Tax_ID",
  //               type: 'text',
  //               after: 'template_id'
  //             },
  //             {
  //               label: "Legal Company Name:",
  //               name: "Legal_Company_Name",
  //               type: 'text',
  //               after: 'Federal_Tax_ID'
  //             },
  //             {
  //               label: "Medical / Business License #:",
  //               name: "Medical_Business_License",
  //               type: 'text',
  //               after: 'Legal_Company_Name'
  //             },
  //             {
  //               label: "Title",
  //               name: "Title",
  //               type: 'text',
  //               after: 'Medical_Business_License'
  //             },
  //             {
  //               label: "Bus Phone",
  //               name: "Bus_Phone",
  //               type: 'number',
  //               after: 'Title'
  //             },
  //             {
  //               label: "Business Structure",
  //               name: "Business_Structure",
  //               type: 'select',
  //               val: [{ name: 'S Corp', val: 'S_Corp' }, { name: 'Corp', val: 'Corp' }, { name: 'LLs', val: 'LLs' }, { name: 'Sol Prop', val: 'Sol_Prop' }],
  //               after: 'Bus_Phone'
  //             },
  //             {
  //               label: "Term Requested",
  //               name: "Term_Requested",
  //               type: 'select',
  //               val: [{ name: '24', val: '24' }, { name: '26', val: '26' }, { name: '38', val: '38' }, { name: '60', val: '60' }],
  //               after: 'Business_Structure'
  //             },
  //             {
  //               label: "Years in Business",
  //               name: "Years_in_Business",
  //               type: 'date',
  //               after: 'Term_Requested'
  //             },
  //             {
  //               heading: "Personal Information",
  //               label: "Name1",
  //               name: "Name1",
  //               type: 'text',
  //               after: 'Years_in_Business'
  //             },

  //             {
  //               label: "Social Security",
  //               name: "Social_Security",
  //               type: 'text',
  //               after: 'Name1'
  //             },
  //             {
  //               label: "Ownership Percentage",
  //               name: "ownership_percentage",
  //               type: 'number',
  //               after: 'Social_Security'
  //             },
  //             {
  //               label: "Own Rent Home",
  //               name: "Own_Rent_Home",
  //               type: 'text',
  //               after: 'ownership_percentage'
  //             },
  //             {
  //               label: "Home Address",
  //               name: "Home_Address",
  //               type: 'text',
  //               after: 'Own_Rent_Home'
  //             },

  //             {
  //               label: "City personal address",
  //               name: "City_personal_address",
  //               type: 'text',
  //               after: 'Home_Address'
  //             },
  //             {
  //               label: "State personal address",
  //               name: "State_personal_address",
  //               type: 'text',
  //               after: 'City_personal_address'
  //             },
  //             {
  //               label: "Zip personal address",
  //               name: "Zip_personal_address",
  //               type: 'number',
  //               after: 'State_personal_address'
  //             },
  //             {
  //               label: "Home Phone",
  //               name: "Home_Phone",
  //               type: 'number',
  //               after: 'Zip_personal_address'
  //             },
  //             {
  //               label: "Name_2",
  //               name: "Name_2",
  //               type: 'text',
  //               after: 'Home_Phone'
  //             },
  //             {
  //               label: "Social Security 2",
  //               name: "Social_Security_2",
  //               type: 'text',
  //               after: 'Name_2'
  //             },

  //             {
  //               label: "Ownership1",
  //               name: "Ownership1",
  //               type: 'text',
  //               after: 'Social_Security_2'
  //             },
  //             {
  //               label: "Own Rent Home 2",
  //               name: "Own_Rent_Home_2",
  //               type: 'text',
  //               after: 'Ownership1'
  //             },
  //             {
  //               label: "Home Address 2",
  //               name: "Home_Address_2",
  //               type: 'text',
  //               after: 'Own_Rent_Home_2'
  //             },
  //             {
  //               label: "City 2",
  //               name: "City_2",
  //               type: 'text',
  //               after: 'Home_Address_2'
  //             },
  //             {
  //               label: "State 2",
  //               name: "State_2",
  //               type: 'text',
  //               after: 'City_2'
  //             },
  //             {
  //               label: "Zip2",
  //               name: "Zip2",
  //               type: 'text',
  //               after: 'State_2'
  //             },
  //             {
  //               label: "Home Phone 2",
  //               name: "Home_Phone_2",
  //               type: 'number',
  //               after: 'Zip2'
  //             },
  //             {
  //               label: "Name of Bank Branch",
  //               name: "Name_of_Bank_Branch",
  //               type: 'text',
  //               after: 'Home_Phone_2'
  //             },
  //             {
  //               label: "Bank Branch Phone",
  //               name: "Bank_Branch_Phone",
  //               type: 'number',
  //               after: 'Name_of_Bank_Branch'
  //             },
  //             {
  //               label: "Checking Acct",
  //               name: "Checking_Acct",
  //               type: 'text',
  //               after: 'Bank_Branch_Phone'
  //             },
  //             {
  //               label: "Contact Name",
  //               name: "Contact_Name",
  //               type: 'text',
  //               after: 'Checking_Acct'
  //             },
  //             {
  //               label: "Phone1",
  //               name: "Phone1",
  //               type: 'number',
  //               after: 'Contact_Name'
  //             },
  //             {
  //               label: "Opening Time",
  //               name: "How_Long_Has_This_Account_Been_Open",
  //               type: 'text',
  //               after: 'Phone1'
  //             },
  //             {
  //               label: "Equipment Cost",
  //               name: "Equipment_Cost",
  //               type: 'text',
  //               after: 'How_Long_Has_This_Account_Been_Open'
  //             },
  //             {
  //               label: "Vendor Supplier",
  //               name: "Vendor_Supplier",
  //               type: 'text',
  //               after: 'Equipment_Cost'
  //             },

  //             {
  //               label: "Contact Name1",
  //               name: "Contact_Name1",
  //               type: 'text',
  //               after: 'Vendor_Supplier'
  //             },
  //             {
  //               label: "Phone2",
  //               name: "Phone2",
  //               type: 'number',
  //               after: 'Contact_Name1'
  //             },
  //             {
  //               label: "Equipment Type",
  //               name: "Equipment_Type",
  //               type: 'text',
  //               after: 'Phone2'
  //             },
  //             {
  //               label: "Bankruptcies month year",
  //               name: "bankruptcies_month_year",
  //               type: 'date',
  //               after: 'Equipment_Type'
  //             },
  //             {
  //               label: "Date Closed",
  //               name: "Date_Closed",
  //               type: 'text',
  //               after: 'bankruptcies_month_year'
  //             },
  //             {
  //               label: "Experian",
  //               name: "Experian",
  //               type: 'text',
  //               after: 'Date_Closed'
  //             },
  //             {
  //               label: "Equifax",
  //               name: "Equifax",
  //               type: 'text',
  //               after: 'Experian'
  //             },
  //             {
  //               label: "TransUnion",
  //               name: "TransUnion",
  //               type: 'text',
  //               after: 'Equifax'
  //             },
  //             {
  //               label: "Credit",
  //               name: "Credit",
  //               type: 'text',
  //               after: 'TransUnion'
  //             },

  //           ]
  //         };
  //       }, 200);
  //       break;
  //     case '618b7fc373e50000097b6324': // Pece contract
  //       setTimeout(() => {
  //         this.formfieldrefreshdata = {
  //           field: 'addfromcontrol',
  //           value: [
  //             {
  //               label: "Shipped",
  //               name: "Shipped",
  //               type: 'date',
  //               after: 'template_id'
  //             },
  //             {
  //               label: "Equipment Model",
  //               name: "Pick_List_2",
  //               type: 'text',
  //               after: 'Shipped'
  //             },
  //             {
  //               label: "Serial Number",
  //               name: "Serial_Number",
  //               type: 'text',
  //               after: 'Pick_List_2'
  //             },
  //           ]
  //         };
  //       }, 200);
  //       break;
  //     case '618cf5bf43dbf00009e6c6c2': // Tm-Flow contract
  //       setTimeout(() => {
  //         this.formfieldrefreshdata = {
  //           field: 'addfromcontrol',
  //           value: [
  //             {
  //               label: "Quotation Date",
  //               name: "Quotation_Date",
  //               type: 'date',
  //               after: 'template_id'
  //             },
  //             {
  //               label: "Ship Via",
  //               name: "Ship_Via",
  //               type: 'text',
  //               after: 'Quotation_Date'
  //             },
  //             {
  //               label: "Sales Type",
  //               name: "Sales_Type",
  //               type: 'select',
  //               val: [
  //                 { val: 'Wholesale', name: 'Wholesale' },
  //                 { val: 'Retail', name: 'Retail' },
  //               ],
  //               value: '',
  //               after: 'Ship_Via'
  //             },
  //             {
  //               label: "TM-Flow QTY",
  //               name: "TM_Flow_QTY",
  //               type: 'number',
  //               after: 'Sales_Type'
  //             },
  //             {
  //               label: "TM-Flow Item",
  //               name: "TM_Flow_Item",
  //               type: 'text',
  //               after: 'TM_Flow_QTY'
  //             },
  //             {
  //               label: "TM-Flow Description",
  //               name: "TM_Flow_Description",
  //               type: 'text',
  //               after: 'TM_Flow_Item'
  //             },
  //             {
  //               label: "Unit Volume",
  //               name: "Unit_Volume",
  //               type: 'text',
  //               after: 'TM_Flow_Description'
  //             },
  //             {
  //               label: "TM-Flow Unit Price",
  //               name: "TM_Flow_Unit_Price",
  //               type: 'number',
  //               after: 'Unit_Volume'
  //             },
  //             {
  //               label: "TM-Flow Amount",
  //               name: "TM_Flow_Amount",
  //               type: 'number',
  //               after: 'TM_Flow_Unit_Price'
  //             },
  //             {
  //               label: "TM-Flow Subtotal",
  //               name: "TM_Flow_Subtotal",
  //               type: 'number',
  //               after: 'TM_Flow_Amount'
  //             },
  //             {
  //               label: "YEAR EXTENDED WARRANTY WITH 30 DAY TRIAL OF PECE1",
  //               name: "YEAR_EXTENDED_WARRANTY_WITH_30_DAY_TRIAL_OF_PECE1",
  //               type: 'number',
  //               after: 'TM_Flow_Subtotal'
  //             },
  //             {
  //               label: "TM-Flow Tax",
  //               name: "TM_FLow_Tax",
  //               type: 'number',
  //               after: 'YEAR_EXTENDED_WARRANTY_WITH_30_DAY_TRIAL_OF_PECE1'
  //             },
  //             {
  //               label: "TM-Flow Shipping",
  //               name: "TM_Flow_Shipping",
  //               type: 'number',
  //               after: 'TM_FLow_Tax'
  //             },
  //             {
  //               label: "TM-Flow Total",
  //               name: "TM_Flow_Total",
  //               type: 'number',
  //               after: 'TM_Flow_Shipping'
  //             },
  //           ]
  //         };
  //       }, 200);
  //       break;
  //     case '618d0cc24206a40008e814dc': // Warranty contract
  //       setTimeout(() => {
  //         this.formfieldrefreshdata = {
  //           field: 'addfromcontrol',
  //           value: [
  //             {
  //               label: "Serial Number",
  //               name: "Serial_Number",
  //               type: 'text',
  //               after: 'template_id'
  //             },
  //             {
  //               label: "Shipped",
  //               name: "Shipped",
  //               type: 'date',
  //               after: 'Serial_Number'
  //             },
  //           ]
  //         };
  //       }, 200);
  //       break;
  //     case '618e1468bcba120009fdac1c': // BioEnergetics contract
  //       setTimeout(() => {
  //         this.formfieldrefreshdata = {
  //           field: 'addfromcontrol',
  //           value: [
  //             {
  //               label: "Agreement On",
  //               name: "agreement_on",
  //               type: 'date',
  //               after: 'template_id'
  //             },
  //           ]
  //         };
  //       }, 200);
  //       break;
  //     default:
  //       this.remove_contract_field();
  //       break;
  //   }
  //   this.formfieldrefresh = true;
  // }
  add_contract_field(key, val) {
    this.remove_contract_field();
    switch (key) {
      case '618d0c934206a40008e814d7': // Credit application
        setTimeout(() => {
          this.formfieldrefreshdata = {
            field: 'addfromcontrol',
            value: [
              {
                label: "Federal Tax ID",
                name: "Federal_Tax_ID",
                type: 'text',
                // value: val.Federal_Tax_ID,
                validations: [{ rule: 'required', message: 'Federal Tax ID is Required' }],
                after: 'template_id'
              },
              {
                label: "Legal Company Name:",
                name: "Legal_Company_Name",
                type: 'hidden',
                // validations: [{ rule: 'required', message: 'Legal Company Name is Required' }],
                value: val.fullname,
                after: 'Federal_Tax_ID'
              },
              {
                label: "Medical / Business License #:",
                name: "Medical_Business_License",
                type: 'text',
                // value: val.Medical_Business_License,
                validations: [{ rule: 'required', message: 'Medical / Business License is Required' }],
                after: 'Legal_Company_Name'
              },
              {
                label: "Title",
                name: "Title",
                type: 'text',
                // value: val.Title,
                validations: [{ rule: 'required', message: 'Title is Required' }],
                after: 'Medical_Business_License'
              },
              {
                label: "Bus Phone",
                name: "Bus_Phone",
                type: 'number',
                // value: val.Bus_Phone,
                validations: [{ rule: 'required', message: 'Bus Phone is Required' }],
                after: 'Title'
              },
              {
                label: "Business Structure",
                name: "Business_Structure",
                type: 'select',
                // value: val.Business_Structure,
                val: [{ name: 'S Corp', val: 'S_Corp' }, { name: 'Corp', val: 'Corp' }, { name: 'LLs', val: 'LLs' }, { name: 'Sol Prop', val: 'Sol_Prop' }],
                validations: [{ rule: 'required', message: 'Business Structure is Required' }],
                after: 'Bus_Phone'
              },
              {
                label: "Term Requested",
                name: "Term_Requested",
                type: 'select',
                // value: val.Term_Requested,
                val: [{ name: '24', val: '24' }, { name: '26', val: '26' }, { name: '38', val: '38' }, { name: '60', val: '60' }],
                validations: [{ rule: 'required', message: 'Term Requested is Required' }],
                after: 'Business_Structure'
              },
              {
                label: "Years in Business",
                name: "Years_in_Business",
                type: 'date',
                // value: val.Years_in_Business,
                validations: [{ rule: 'required', message: 'Years in Business is Required' }],
                after: 'Term_Requested'
              },
              {
                heading: "Personal Information",
                label: "Name1",
                name: "Name1",
                type: 'hidden',
                // validations: [{ rule: 'required', message: 'Personal Information is Required' }],
                value: val.singeealldata.length > 0 ? val.singeealldata[0].First_Name + " " + val.singeealldata[0].Last_Name : '',
                after: 'Years_in_Business'
              },

              {
                label: "Social Security",
                name: "Social_Security",
                type: 'text',
                // value: val.Social_Security,
                validations: [{ rule: 'required', message: 'Social Security is Required' }],
                after: 'Name1'
              },
              {
                label: "Ownership Percentage",
                name: "ownership_percentage",
                type: 'number',
                // value: val.ownership_percentage,
                suffix: '%',
                validations: [{ rule: 'required', message: 'Ownership Percentage is Required' }],
                after: 'Social_Security'
              },
              {
                label: "Own Rent Home",
                name: "Own_Rent_Home",
                type: 'select',
                val: [{ val: 'Own', name: 'Own' }, { val: 'Rent', name: 'Rent' }],
                // value: val.Own_Rent_Home,
                validations: [{ rule: 'required', message: 'Own Rent Home is Required' }],
                after: 'ownership_percentage'
              },
              {
                label: "Home Address",
                name: "Home_Address",
                type: 'hidden',
                // validations: [{ rule: 'required', message: 'Home Address is Required' }],
                value: val.singeealldata.length > 0 ? val.singeealldata[0].Mailing_Street + "," + val.singeealldata[0].Mailing_City + "," + val.singeealldata[0].Mailing_State + "," + val.singeealldata[0].Mailing_Country : '',
                after: 'Own_Rent_Home'
              },

              {
                label: "City personal address",
                name: "City_personal_address",
                type: 'hidden',
                // validations: [{ rule: 'required', message: 'City personal address is Required' }],
                value: val.city,
                after: 'Home_Address'
              },
              {
                label: "State personal address",
                name: "State_personal_address",
                type: 'hidden',
                // validations: [{ rule: 'required', message: 'State personal address is Required' }],
                value: val.address,
                after: 'City_personal_address'
              },
              {
                label: "Zip personal address",
                name: "Zip_personal_address",
                type: 'hidden',
                // validations: [{ rule: 'required', message: 'Zip personal address is Required' }],
                value: val.zip,
                after: 'State_personal_address'
              },
              {
                label: "Home Phone",
                name: "Home_Phone",
                type: 'hidden',
                // validations: [{ rule: 'required', message: 'Home Phone is Required' }],
                value: val.singeealldata.length > 0 ? val.singeealldata[0].Phone : '',
                after: 'Zip_personal_address'
              },
              {
                label: "Name 2",
                name: "Name_2",
                type: 'text',
                // value: val.Name_2,
                validations: [{ rule: 'required', message: 'Name 2 is Required' }],
                after: 'Home_Phone'
              },
              {
                label: "Social Security 2",
                name: "Social_Security_2",
                type: 'text',
                // value: val.Social_Security_2,
                validations: [{ rule: 'required', message: 'Social Security 2 is Required' }],
                after: 'Name_2'
              },

              {
                label: "Ownership 1",
                name: "Ownership1",
                type: 'number',
                // value: val.Ownership1,
                suffix: '%',
                validations: [{ rule: 'required', message: 'Ownership 1 is Required' }],
                after: 'Social_Security_2'
              },
              {
                label: "Own Rent Home 2",
                name: "Own_Rent_Home_2",
                type: 'select',
                val: [{ val: 'Own', name: 'Own' }, { val: 'Rent', name: 'Rent' }],
                // value: val.Own_Rent_Home_2,
                validations: [{ rule: 'required', message: 'Own Rent Home 2 is Required' }],
                after: 'Ownership1'
              },
              {
                label: "Home Address 2",
                name: "Home_Address_2",
                type: 'text',
                // value: val.Home_Address_2,
                validations: [{ rule: 'required', message: 'Home Address 2 is Required' }],
                after: 'Own_Rent_Home_2'
              },
              {
                label: "City 2",
                name: "City_2",
                type: 'hidden',
                // validations: [{ rule: 'required', message: 'City 2 is Required' }],
                value: val.singeealldata.length > 0 ? val.singeealldata[0].Mailing_City : '',
                after: 'Home_Address_2'
              },
              {
                label: "State 2",
                name: "State_2",
                type: 'hidden',
                // validations: [{ rule: 'required', message: 'State 2 is Required' }],
                value: val.singeealldata.length > 0 ? val.singeealldata[0].Mailing_State : '',
                after: 'City_2'
              },
              {
                label: "Zip 2",
                name: "Zip2",
                type: 'hidden',
                // validations: [{ rule: 'required', message: 'Zip 2 is Required' }],
                value: val.singeealldata.length > 0 ? val.singeealldata[0].Mailing_Zip : '',
                after: 'State_2'
              },
              {
                label: "Home Phone 2",
                name: "Home_Phone_2",
                type: 'number',
                // value: val.Home_Phone_2,
                validations: [{ rule: 'required', message: 'Home Phone 2 is Required' }],
                after: 'Zip2'
              },
              {
                label: "Name of Bank Branch",
                name: "Name_of_Bank_Branch",
                type: 'text',
                // value: val.Name_of_Bank_Branch,
                validations: [{ rule: 'required', message: 'Name of Bank Branch is Required' }],
                after: 'Home_Phone_2'
              },
              {
                label: "Bank Branch Phone",
                name: "Bank_Branch_Phone",
                type: 'number',
                // value: val.Bank_Branch_Phone,
                validations: [{ rule: 'required', message: 'Bank Branch Phone is Required' }],
                after: 'Name_of_Bank_Branch'
              },
              {
                label: "Checking Acct",
                name: "Checking_Acct",
                type: 'number',
                // value: val.Checking_Acct,
                validations: [{ rule: 'required', message: 'Checking Acct is Required' }],
                after: 'Bank_Branch_Phone'
              },
              {
                label: "Contact Name",
                name: "Contact_Name",
                type: 'text',
                // value: val.Contact_Name,
                validations: [{ rule: 'required', message: 'Contact Name is Required' }],
                after: 'Checking_Acct'
              },
              {
                label: "Phone 1",
                name: "Phone1",
                type: 'number',
                // value: val.Phone1,
                validations: [{ rule: 'required', message: 'Phone 1 is Required' }],
                after: 'Contact_Name'
              },
              {
                label: "How Long Has This Account Been Open?",
                name: "How_Long_Has_This_Account_Been_Open",
                type: 'text',
                // value: val.How_Long_Has_This_Account_Been_Open,
                validations: [{ rule: 'required', message: 'How Long Has This Account Been Open is Required' }],
                after: 'Phone1'
              },
              {
                label: "Equipment Cost",
                name: "Equipment_Cost",
                type: 'number',
                // value: val.Equipment_Cost,
                prefix: '$',
                validations: [{ rule: 'required', message: 'Equipment Cost is Required' }],
                after: 'How_Long_Has_This_Account_Been_Open'
              },
              {
                label: "Vendor Supplier",
                name: "Vendor_Supplier",
                type: 'text',
                // value: val.Vendor_Supplier,
                validations: [{ rule: 'required', message: 'Vendor Supplier is Required' }],
                after: 'Equipment_Cost'
              },

              {
                label: "Contact Name 1",
                name: "Contact_Name1",
                type: 'text',
                // value: val.Contact_Name1,
                validations: [{ rule: 'required', message: 'Contact Name 1 is Required' }],
                after: 'Vendor_Supplier'
              },
              {
                label: "Phone 2",
                name: "Phone2",
                type: 'hidden',
                // validations: [{ rule: 'required', message: 'Phone 2 is Required' }],
                value: val.phone,
                after: 'Contact_Name1'
              },
              {
                label: "Equipment Type",
                name: "Equipment_Type",
                type: 'text',
                // value: val.Equipment_Type,
                validations: [{ rule: 'required', message: 'Equipment Type is Required' }],
                after: 'Phone2'
              },
              {
                label: "Bankruptcies Month Year",
                name: "bankruptcies_month_year",
                type: 'date',
                // value: val.bankruptcies_month_year,
                validations: [{ rule: 'required', message: 'Bankruptcies Month Year is Required' }],
                after: 'Equipment_Type'
              },
              {
                label: "Date Closed",
                name: "Date_Closed",
                type: 'date',
                // value: val.Date_Closed,
                validations: [{ rule: 'required', message: 'Date Closed is Required' }],
                after: 'bankruptcies_month_year'
              },
              {
                label: "Experian",
                name: "Experian",
                type: 'text',
                // value: val.Experian,
                validations: [{ rule: 'required', message: 'Experian is Required' }],
                after: 'Date_Closed'
              },
              {
                label: "Equifax",
                name: "Equifax",
                type: 'text',
                // value: val.Equifax,
                validations: [{ rule: 'required', message: 'Equifax is Required' }],
                after: 'Experian'
              },
              {
                label: "Trans Union",
                name: "TransUnion",
                type: 'text',
                // value: val.TransUnion,
                validations: [{ rule: 'required', message: 'Trans Union is Required' }],
                after: 'Equifax'
              },
              {
                label: "Credit",
                name: "Credit",
                type: 'text',
                // value: val.Credit,
                validations: [{ rule: 'required', message: 'Credit is Required' }],
                after: 'TransUnion'
              },

            ]
          };
        }, 200);
        break;
      case '618b7fc373e50000097b6324': // Pece contract
        setTimeout(() => {
          this.formfieldrefreshdata = {
            field: 'addfromcontrol',
            value: [
              {
                label: "Shipped",
                name: "Shipped",
                type: 'date',
                // value: val.Shipped,
                validations: [{ rule: 'required', message: 'Shipped is Required' }],
                after: 'template_id'
              },
              {
                label: "Equipment Model",
                name: "Pick_List_2",
                type: 'text',
                // value: val.Pick_List_2,
                validations: [{ rule: 'required', message: 'Pick List 2 is Required' }],
                after: 'Shipped'
              },
              {
                label: "Serial Number",
                name: "Serial_Number",
                type: 'number',
                value: val.Serial_Number,
                validations: [{ rule: 'required', message: 'Serial Number is Required' }],
                after: 'Pick_List_2'
              },
              {
                label: "",
                name: "leadname",
                type: 'hidden',
                value: val.fullname,
                after: 'Serial_Number'
              },
              {
                label: "",
                name: "address",
                type: 'hidden',
                value: val.address,
                after: 'leadname'
              },
            ]
          };
        }, 200);
        break;
      case '618cf5bf43dbf00009e6c6c2': // Tm-Flow contract
        setTimeout(() => {
          this.formfieldrefreshdata = {
            field: 'addfromcontrol',
            value: [
              {
                label: "Quotation Date",
                name: "Quotation_Date",
                type: 'date',
                after: 'template_id'
              },
              {
                label: "Ship Via",
                name: "Ship_Via",
                type: 'text',
                after: 'Quotation_Date'
              },
              {
                label: "Sales Type",
                name: "Sales_Type",
                type: 'select',
                val: [
                  { val: 'Wholesale', name: 'Wholesale' },
                  { val: 'Retail', name: 'Retail' },
                ],
                value: '',
                after: 'Ship_Via'
              },
              {
                label: "TM-Flow QTY",
                name: "TM_Flow_QTY",
                type: 'number',
                after: 'Sales_Type'
              },
              {
                label: "TM-Flow Item",
                name: "TM_Flow_Item",
                type: 'text',
                after: 'TM_Flow_QTY'
              },
              {
                label: "TM-Flow Description",
                name: "TM_Flow_Description",
                type: 'text',
                after: 'TM_Flow_Item'
              },
              {
                label: "Unit Volume",
                name: "Unit_Volume",
                type: 'text',
                after: 'TM_Flow_Description'
              },
              {
                label: "TM-Flow Unit Price",
                name: "TM_Flow_Unit_Price",
                type: 'number',
                after: 'Unit_Volume'
              },
              {
                label: "TM-Flow Amount",
                name: "TM_Flow_Amount",
                type: 'number',
                after: 'TM_Flow_Unit_Price'
              },
              {
                label: "TM-Flow Subtotal",
                name: "TM_Flow_Subtotal",
                type: 'number',
                after: 'TM_Flow_Amount'
              },
              {
                label: "YEAR EXTENDED WARRANTY WITH 30 DAY TRIAL OF PECE1",
                name: "YEAR_EXTENDED_WARRANTY_WITH_30_DAY_TRIAL_OF_PECE1",
                type: 'number',
                after: 'TM_Flow_Subtotal'
              },
              {
                label: "TM-Flow Tax",
                name: "TM_FLow_Tax",
                type: 'number',
                after: 'YEAR_EXTENDED_WARRANTY_WITH_30_DAY_TRIAL_OF_PECE1'
              },
              {
                label: "TM-Flow Shipping",
                name: "TM_Flow_Shipping",
                type: 'number',
                after: 'TM_FLow_Tax'
              },
              {
                label: "TM-Flow Total",
                name: "TM_Flow_Total",
                type: 'number',
                after: 'TM_Flow_Shipping'
              },
            ]
          };
        }, 200);
        break;
      case '618d0cc24206a40008e814dc': // Warranty contract
        setTimeout(() => {
          this.formfieldrefreshdata = {
            field: 'addfromcontrol',
            value: [
              {
                label: "Serial Number",
                name: "Serial_Number",
                type: 'number',
                // value: val.Serial_Number,
                validations: [{ rule: 'required', message: 'Serial Number is Required' }],
                after: 'template_id'
              },
              {
                label: "Shipped",
                name: "Shipped",
                type: 'date',
                // value: val.Shipped,
                validations: [{ rule: 'required', message: 'Shipped is Required' }],
                after: 'Serial_Number'
              },
              {
                label: "",
                name: "City",
                type: 'hidden',
                value: val.city,
                after: 'Shipped'
              },
              {
                label: "",
                name: "Phone",
                type: 'hidden',
                value: val.phone,
                after: 'City'
              },
              {
                label: "",
                name: "PracticeName",
                type: 'hidden',
                value: val.fullname,
                after: 'Phone'
              },
              {
                label: "",
                name: "State",
                type: 'hidden',
                value: val.state,
                after: 'PracticeName'
              },
              {
                label: "",
                name: "Street",
                type: 'hidden',
                value: val.street,
                after: 'State'
              },
              {
                label: "",
                name: "Zip",
                type: 'hidden',
                value: val.zip,
                after: 'Street'
              },
              {
                label: "",
                name: "auth_signatory",
                type: 'hidden',
                // value: val.singeealldata[0].First_Name +
                //   " " +
                //   val.singeealldata[0].Last_Name,
                after: 'Zip'
              },
              {
                label: "",
                name: "printed_name",
                type: 'hidden',
                // value: val.singeealldata[0].First_Name +
                //   " " +
                //   val.singeealldata[0].Last_Name,
                after: 'auth_signatory'
              },
              {
                label: "",
                name: "equipment",
                type: 'hidden',
                value: 'TM-FLOW ANS MEDICAL DEVICE',
                after: 'printed_name'
              },
            ]
          };
        }, 200);
        break;
      case '618e1468bcba120009fdac1c': // BioEnergetics contract
        setTimeout(() => {
          this.formfieldrefreshdata = {
            field: 'addfromcontrol',
            value: [
              {
                label: "Agreement On",
                name: "agreement_on",
                type: 'date',
                after: 'template_id'
              },
            ]
          };
        }, 200);
        break;
      default:
        // this.remove_contract_field();
        break;
    }
    setTimeout(() => {
      // this.loader = false;
      // this.onload = true;
    }, 1000);
    this.formfieldrefresh = true;
  }
  remove_contract_field() {
    console.warn('.............')
    switch (this.template_id) {
      case '618d0c934206a40008e814d7': // Credit application
        this.formfieldrefreshdata = {
          field: 'removefromcontrol',
          value: [
            "Federal_Tax_ID",
            "Legal_Company_Name",
            "Medical_Business_License",
            "Title",
            "Bus_Phone",
            "Business_Structure",
            "Term_Requested",
            "Years_in_Business",
            "Name1",
            "Social_Security",
            "ownership_percentage",
            "Own_Rent_Home",
            "Home_Address",
            "City_personal_address",
            "State_personal_address",
            "Zip_personal_address",
            "Home_Phone",
            "Name_2",
            "Social_Security_2",
            "Ownership1",
            "Own_Rent_Home_2",
            "Home_Address_2",
            "City_2",
            "State_2",
            "Zip2",
            "Home_Phone_2",
            "Name_of_Bank_Branch",
            "Bank_Branch_Phone",
            "Checking_Acct",
            "Contact_Name",
            "Phone1",
            "How_Long_Has_This_Account_Been_Open",
            "Equipment_Cost",
            "Vendor_Supplier",
            "Contact_Name1",
            "Phone2",
            "Equipment_Type",
            "bankruptcies_month_year",
            "Date_Closed",
            "Experian",
            "Equifax",
            "TransUnion",
            "Credit",
          ]
        };
        break;
      case '618b7fc373e50000097b6324': // Pece contract
        this.formfieldrefreshdata = {
          field: 'removefromcontrol',
          value: [
            "Shipped",
            "Pick_List_2",
            "Serial_Number",
            "leadname",
            "address",
          ]
        };
        break;
      case '618cf5bf43dbf00009e6c6c2': // Tm-Flow contract
        this.formfieldrefreshdata = {
          field: 'removefromcontrol',
          value: [
            "Quotation_Date",
            "Ship_Via",
            "Sales_Type",
            "TM_Flow_QTY",
            "TM_Flow_Item",
            "TM_Flow_Description",
            "Unit_Volume",
            "TM_Flow_Unit_Price",
            "TM_Flow_Amount",
            "TM_Flow_Subtotal",
            "YEAR_EXTENDED_WARRANTY_WITH_30_DAY_TRIAL_OF_PECE1",
            "TM_FLow_Tax",
            "TM_Flow_Shipping",
            "TM_Flow_Total",
          ]
        };
        break;
      case '618d0cc24206a40008e814dc': // Warranty contract
        this.formfieldrefreshdata = {
          field: 'removefromcontrol',
          value: [
            "Serial_Number",
            "Shipped",
            "City",
            "Phone",
            "PracticeName",
            "State",
            "Street",
            "Zip",
            "auth_signatory",
            "printed_name",
            "equipment",
          ]
        };
        break;
      case '618e1468bcba120009fdac1c': // BioEnergetics contract
        this.formfieldrefreshdata = {
          field: 'removefromcontrol',
          value: [
            "agreement_on",
          ]
        };
        break;
      default:
        break;
    }
    // this.formfieldrefreshdata = {
    //   field: 'removefromcontrol', value: [
    //       'Serial_Number',
    //       'Shipped',
    //       'Federal_Tax_ID',
    //       'Legal_Company_Name',
    //       'Medical_Business_License',
    //       'Title',
    //       'Bus_Phone',
    //       'Business_Structure',
    //       'Term_Requested',
    //       'Years_in_Business',
    //       'Name1',
    //       'Social_Security',
    //       'ownership_percentage',
    //       'Own_Rent_Home',
    //       'Home_Address',
    //       'City_personal_address',
    //       'State_personal_address',
    //       'Zip_personal_address',
    //       'Home_Phone',
    //       'Name_2',
    //       'Social_Security_2',
    //       'Ownership1',
    //       'Own_Rent_Home_2',
    //       'Home_Address_2',
    //       'City_2',
    //       'State_2',
    //       'Zip2',
    //       'Home_Phone_2',
    //       'Name_of_Bank_Branch',
    //       'Bank_Branch_Phone',
    //       'Checking_Acct',
    //       'Contact_Name',
    //       'Phone1',
    //       'How_Long_Has_This_Account_Been_Open',
    //       'Equipment_Cost',
    //       'Vendor_Supplier',
    //       'Contact_Name1',
    //       'Phone2',
    //       'Equipment_Type',
    //       'bankruptcies_month_year',
    //       'Date_Closed',
    //       'Experian',
    //       'Equifax',
    //       'TransUnion',
    //       'Credit',
    //       'Pick_List_2',
    //       'Quotation_Date',
    //       'Ship_Via',
    //       'Sales_Type',
    //       'TM_Flow_QTY',
    //       'TM_Flow_Item',
    //       'TM_Flow_Description',
    //       'Unit_Volume',
    //       'TM_Flow_Unit_Price',
    //       'TM_Flow_Amount',
    //       'TM_Flow_Subtotal',
    //       'YEAR_EXTENDED_WARRANTY_WITH_30_DAY_TRIAL_OF_PECE1',
    //       'TM_FLow_Tax',
    //       'TM_Flow_Shipping',
    //       'TM_Flow_Total',
    //       'City',
    //       'Phone',
    //       'PracticeName',
    //       'State',
    //       'Zip',
    //       'auth_signatory',
    //       'printed_name',
    //       'equipment',
    //       'leadname',
    //       'address',
    //     ]
    //   };
  }
  onNoClick(): void {
    this.dialogRef.close();
  }
}
@Component({
  selector: 'game-plan-modal',
  templateUrl: 'game-plan-modal.html',
})
export class GameplanModalComponent implements OnInit {
  constructor(
    public dialogRef: MatDialogRef<GameplanModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData5
  ) { }
  ngOnInit() {
    setTimeout(() => {
      this.data.flag = false;
      this.dialogRef.close(this.data);
    }, 6000);
  }

  gameplay() {
    this.data.flag = true;
    this.dialogRef.close(this.data);
  }
  onNoClick() {
    this.data.flag = false;
    this.dialogRef.close(this.data);
  }
}

//new-admin-search-modal
@Component({
  selector: 'new-admin-search',
  templateUrl: 'new-admin-search.html',
})
export class newAdminSearchmodal implements OnInit {
  public modalDataSearch: any;
  public headerMsg: string = 'Search';
  public matAutosearchData: any = [];
  public buttonSearchData: any = [];
  public modalname: any;

  constructor(
    public dialogRefe: MatDialogRef<newAdminSearchmodal>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData
  ) {
    this.modalDataSearch = data;
  }
  ngOnInit() { }

  onNoClick() {
    this.dialogRefe.close(this.modalDataSearch);
  }

  chooseChip(data: any, i: any) {
    // // console.log(data, '??data chooseChip')
    this.modalDataSearch.modalname.push(data);
    // this.selectedData.push(data);
    this.buttonSearchData.push(data);
    // console.warn(this.buttonSearchData)
  }
  removePatientNameChip(i: any) {
    if (this.buttonSearchData != null) {
      this.buttonSearchData.splice(i, 1);
    }
  }
}

@Component({
  selector: 'clipboard',
  templateUrl: 'clipboard.html',
})
export class clipboard {
  public modalData: any;
  constructor(public dialogRef: MatDialogRef<clipboard>) {
    setTimeout(() => {
      this.dialogRef.close();
    }, 3000);
    // window.scroll(0,0);
  }

  onNoClick(): void {
    this.dialogRef.close();
  }
}
@Component({
  selector: 'notesmodal',
  templateUrl: 'notesmodal.html',
  styleUrls: ['./admindashboard.component.css'],
})
export class NotesModal {
  public modalData: any;
  constructor(
    public dialogRef: MatDialogRef<NotesModal>,
    @Inject(MAT_DIALOG_DATA) public data: NotesValue
  ) {
    console.log('notes data', data);
  }
  onNoClick(val: any): void {
    console.log(val);
    this.dialogRef.close(val);
  }
}

@Component({
  selector: 'dialogbox',
  templateUrl: './logme.html',
})
export class LogMeComponent {
  public valdata: any;
  constructor(
    public dialogRef: MatDialogRef<LogMeComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    public apiservice: ApiService,
    public activatedroute: ActivatedRoute,
    public userservice: UserserviceService
  ) {
    this.valdata = JSON.stringify(data);
    this.valdata = JSON.parse(this.valdata);
    // console.log(data, '+++++');
    // console.log(this.valdata, '+++data++');
  }

  onNoClick(val: any): void {
    console.log(val);
    this.dialogRef.close(val);
  }
}
@Component({
  selector: 'dialogbox',
  templateUrl: './search.html',
})
export class SearchComponent {
  public namesearch: any = '';
  public selectedType: any = '';
  public emailsearch: any = '';
  public pnamesearch: any = '';
  public resourcesearch: any = '';
  public flag: any = '';
  public loader: boolean = false;
  types = [
    { value: '0', viewValue: 'InActive' },
    { value: '1', viewValue: 'Active' },
  ];
  public options: any = [];
  public optionemail: any = [];
  public searchFormone: FormGroup;
  public options_attende_name: any = [];
  public option_attende_email: any = [];
  public warranty_appointments_status: any = [];
  public training_product_name: any = [];
  public time: any = '';
  public trainingflag: any = '';
  public optionsparentcatagory: any = [];
  public optionscatagoryname: any = [];
  public optionsassociatedtraining: any = [];
  public optionslessionname: any = [];
  constructor(
    public dialogRef: MatDialogRef<SearchComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData5,
    public apiservice: ApiService,
    public activatedroute: ActivatedRoute,
    public userservice: UserserviceService,
    public formBuilder: FormBuilder
  ) {
    this.time = data.time;
    this.flag = data.flag;
    this.trainingflag = data.training_flag;
    if (this.flag == 'calender') {
      this.google_product_name();
    }
    if (this.flag == 'traininglist' && this.trainingflag == ' Categories') {
      this.training_product_name_func();
    }
    console.log(data, '+++++');
    // console.log(this.valdata, '+++data++');
    this.searchFormone = this.formBuilder.group({
      fullname_s: [''],
      email_s: [''],
      lastlogin_datetime: [{}],
      lastlogin_datetimestart: [''],
      lastlogin_datetimeend: [''],
      createdon_datetime: [{}],
      createdon_datetimestart: [''],
      createdon_datetimeend: [''],
      type_array: [data.data],
      status: [''],
      start_datetime_unix: [{}],
      start_datetime_unixstart: [''],
      start_datetime_unixend: [''],
      attende_name_s: [''],
      attende_email_s: [''],
      product_id: [''],
      product_name: [''],
      training_product_name: [''],
      status_s: [''],
      parent_catagory_search: [''],
      catagory_name_search: [''],
      status_search: [''],
      type_search: [''],
      associated_training: [''],
      lession_title: ['']
    });
  }

  onNoClick() {
    this.dialogRef.close();
  }
  productsearch(val: any) {
    this.dialogRef.close(val);
  }
  search() {
    console.log(this.searchFormone.value);
    // this.data.val=this.searchFormone.value;
    if (this.searchFormone.value['lastlogin_datetimestart'] != '') {
      this.searchFormone.value['lastlogin_datetime']['$gte'] = new Date(this.searchFormone.value['lastlogin_datetimestart']).getTime();
      this.searchFormone.value['lastlogin_datetimestart'] = '';
    }
    if (this.searchFormone.value['lastlogin_datetimeend'] != '') {
      this.searchFormone.value['lastlogin_datetime']['$lte'] = new Date(this.searchFormone.value['lastlogin_datetimeend']).getTime() +
        86399999;
      this.searchFormone.value['lastlogin_datetimeend'] = '';
    }
    if (this.searchFormone.value['createdon_datetimestart'] != '') {
      this.searchFormone.value['createdon_datetime']['$gte'] = new Date(this.searchFormone.value['createdon_datetimestart']).getTime();
      this.searchFormone.value['createdon_datetimestart'] = '';
    }
    if (this.searchFormone.value['createdon_datetimeend'] != '') {
      this.searchFormone.value['createdon_datetime']['$lte'] = new Date(this.searchFormone.value['createdon_datetimeend']).getTime() +
        86399999;
      this.searchFormone.value['createdon_datetimeend'] = '';
    }
    if (this.searchFormone.value['start_datetime_unixstart'] != '') {
      this.searchFormone.value['start_datetime_unix']['$gte'] = new Date(
        this.searchFormone.value['start_datetime_unixstart']
      ).getTime();
      this.searchFormone.value['start_datetime_unixstart'] = '';
    }
    if (this.searchFormone.value['start_datetime_unixend'] != '') {
      this.searchFormone.value['start_datetime_unix']['$lte'] =
        new Date(this.searchFormone.value['start_datetime_unixend']).getTime() +
        86399999;
      this.searchFormone.value['start_datetime_unixend'] = '';
    }
    // if(this.searchFormone.value['status'] != ''){
    //   if(this.searchFormone.value['status'] == '1'){
    //     this.searchFormone.value['status'] = 1;
    //   }
    //   if(this.searchFormone.value['status'] == '0'){
    //     this.searchFormone.value['status'] = 0;
    //   }
    // }
    if (this.searchFormone.value['type_search'] != '') {
      this.searchFormone.value['type_array'] = [];
      this.searchFormone.value['type_array'] = [this.searchFormone.value['type_search']];
      this.searchFormone.value['type_search'] = '';
    }
    if (this.searchFormone.value['product_id'] != '') {
      this.searchFormone.value['product_name'] = this.warranty_appointments_status.find(i => i.product_id === this.searchFormone.value['product_id']).name;
    }
    if (this.searchFormone.value['status'] != '') {
      if (this.searchFormone.value['status'] == '0') {

        this.searchFormone.value['status_s'] = 'Pending'
      }
      if (this.searchFormone.value['status'] == '1') {

        this.searchFormone.value['status_s'] = 'Done'
      }
      if (this.searchFormone.value['status'] == '2') {

        this.searchFormone.value['status_s'] = 'Canceled'
      }
    }
    if (this.searchFormone.value['status_search'] != '') {
      if (this.searchFormone.value['status_search'] == '0') {

        this.searchFormone.value['status_s'] = 'Inactive'
      }
      if (this.searchFormone.value['status_search'] == '1') {

        this.searchFormone.value['status_s'] = 'Active'
      }
    }
    this.dialogRef.close(this.searchFormone.value);
  }
  onChangeEventName(val: any) {
    this.loader = true;
    console.log(val, '+++++');
    if (val) {
      let apibody = {
        search_str: val,
        searchcondition: {
          type_array: this.data.data,
        },
        // id: this.userservice.userid,
        sort: { field: 'createdon_datetime', type: 'desc' },
        secret: this.userservice.secret,
        token: this.userservice.jwttoken,
      };
      let endpoint = 'api/exitsinglistname';
      this.apiservice.getDataWithoutToken(
        apibody,
        endpoint
      ).subscribe((data: any) => {
        this.loader = false;
        this.options = data.res;
      });
    }
    else {
      this.loader = false;
    }
  }
  onChangeEventEmail(val: any) {
    this.loader = true;
    console.log(val);
    if (val) {
      let apibody = {
        search_str: val,
        searchcondition: {
          type_array: this.data.data,
        },
        // id: this.userservice.userid,
        sort: { field: 'createdon_datetime', type: 'desc' },
        secret: this.userservice.secret,
        token: this.userservice.jwttoken,
      };

      this.apiservice.getDataWithoutToken(
        apibody,
        'api/exitsinglistemail'
      ).subscribe((data: any) => {
        this.loader = false;
        this.optionemail = data.res;
      });
    }
    else {
      this.loader = false;
    }
  }
  getTitlename(optionval: string) {
    if (optionval != '' && optionval != null) {
      return this.options.find(option => option.val === optionval).name;
    }
  }
  getTitleemail(optionval: string) {
    if (optionval != '' && optionval != null) {
      return this.optionemail.find(option => option.val === optionval).name;
    }
  }
  reset() {
    this.searchFormone.reset();
    this.searchFormone = this.formBuilder.group({
      fullname_s: [''],
      email_s: [''],
      lastlogin_datetime: [{}],
      lastlogin_datetimestart: [''],
      lastlogin_datetimeend: [''],
      createdon_datetime: [{}],
      createdon_datetimestart: [''],
      createdon_datetimeend: [''],
      type_array: [this.data.data],
      status: [''],
      start_datetime_unix: [{}],
      start_datetime_unixstart: [''],
      start_datetime_unixend: [''],
      attende_name_s: [''],
      attende_email_s: [''],
      product_id: [''],
      product_name: [''],
      training_product_name: [''],
      status_s: [''],
      parent_catagory_search: [''],
      catagory_name_search: [''],
      status_search: [''],
      type_search: [''],
      associated_training: [''],
      lession_title: ['']
    });
  }
  onChangeEventAttendeeEmail(val: any) {
    this.loader = true;
    console.log(val);
    if (val) {
      let apibody = {
        attende_email: val,
        time: this.time,
        sort: { field: 'createdon_datetime', type: 'desc' },
        secret: this.userservice.secret,
        token: this.userservice.jwttoken,
      };

      this.apiservice.getDataWithoutToken(
        apibody,
        'api10/autocomplete_google_event_atendee_name_email'
      ).subscribe((data: any) => {
        this.option_attende_email = data.res;
        this.option_attende_email = [
          ...new Set(
            this.option_attende_email.map((item) => item.attende_email)
          ),
        ];
        this.loader = false;
      });
    } else {
      this.loader = false;
    }
  }
  onChangeEventAttendeName(val: any) {
    this.loader = true;
    console.log(val);
    if (val) {
      let apibody = {
        attende_name: val,
        time: this.time,
        sort: { field: 'createdon_datetime', type: 'desc' },
        secret: this.userservice.secret,
        token: this.userservice.jwttoken,
      };

      this.apiservice.getDataWithoutToken(
        apibody,
        'api10/autocomplete_google_event_atendee_name_email'
      ).subscribe((data: any) => {
        this.options_attende_name = data.res;
        this.options_attende_name = [
          ...new Set(
            this.options_attende_name.map((item) => item.attende_name)
          ),
        ];
        this.loader = false;
      });
    } else {
      this.loader = false;
    }
  }
  google_product_name() {
    const endpoint = 'api10/autocomplete_google_event_product_name';
    const data: any = {
      secret: this.userservice.secret,
      token: this.userservice.jwttoken,
      // searchcondition: { user_id: this.userservice.userid },
    };

    this.apiservice.getDataWithoutToken(data, endpoint).subscribe(
      (res: any) => {
        // console.log(res, 'jobticket');
        this.warranty_appointments_status = res.res; // sarch count  (How many data there)
        this.warranty_appointments_status =
          Array.from(new Set(this.warranty_appointments_status.map(item => item.product_id))).map(id => {
            return {
              product_id: id,
              name: this.warranty_appointments_status.find(i => i.product_id === id).name
            }
          })
      }
    );
  }
  training_product_name_func() {
    const endpoint = 'api10/getcategorytrainingmanagementsearch';
    const data: any = {
      'searchcondition': {
        'is_trash': {
          '$ne': 1
        },
        'product_id': {
          '$ne': ''
        },
        'product_name_serach': {
          '$ne': ''
        }
      },
      'sort': {
        'type': 'desc',
        'field': 'createdon_datetime'
      }
    };

    this.apiservice.getDataWithoutToken(data, endpoint).subscribe(
      (res: any) => {
        // console.log(res, 'jobticket');
        this.training_product_name = res.results.res;
        this.training_product_name =
          Array.from(new Set(this.training_product_name.map(item => item.product_id))).map(id => {
            return {
              product_id: id,
              product_name: this.training_product_name.find(i => i.product_id === id).product_name
            }
          })
      }
    );
  }
  onChangeEventParentCatagory(val: any) {
    if (val) {
      this.loader = true;
      const endpoint = 'api10/getcategorytrainingmanagementsearch';
      const data: any = {
        'searchcondition': {
          'parent_catagory_name_search': {
            '$regex': val.toLowerCase()
          }
        },
        'sort': {
          'type': 'desc',
          'field': 'createdon_datetime'
        }
      };

      this.apiservice.getDataWithoutToken(data, endpoint).subscribe(
        (res: any) => {
          // console.log(res, 'jobticket');
          this.optionsparentcatagory = res.results.res;
          console.log(this.optionsparentcatagory);

          this.optionsparentcatagory = Array.from(new Set(this.optionsparentcatagory.map(item => item.parent_catagory))).map(id => {
            return {
              parent_catagory_name: this.optionsparentcatagory.find(i => i.parent_catagory === id).parent_catagory_name,
              parent_catagory_name_search: this.optionsparentcatagory.find(i => i.parent_catagory === id).parent_catagory_name_search
            }
          })
          this.loader = false;
          console.log(this.optionsparentcatagory);

        }
      );
    }
  }
  onChangeEventCatagoryName(val: any) {
    if (val) {
      this.loader = true;
      const endpoint = 'api10/getcategorytrainingmanagementsearch';
      const data: any = {
        'searchcondition': {
          'catagory_name_search': {
            '$regex': val.toLowerCase()
          }
        },
        'sort': {
          'type': 'desc',
          'field': 'createdon_datetime'
        }
      };

      this.apiservice.getDataWithoutToken(data, endpoint).subscribe(
        (res: any) => {
          // console.log(res, 'jobticket');
          this.optionscatagoryname = res.results.res;
          console.log(this.optionscatagoryname);

          this.optionscatagoryname = Array.from(new Set(this.optionscatagoryname.map(item => item.catagory_name_search))).map(id => {
            return {
              catagory_name: this.optionscatagoryname.find(i => i.catagory_name_search === id).catagory_name,
              catagory_name_search: this.optionscatagoryname.find(i => i.catagory_name_search === id).catagory_name_search
            }
          })
          this.loader = false;
          console.log(this.optionscatagoryname);
        }
      );
    }
  }
  onChangeEventAssociatedTraining(val: any) {
    if (val) {
      this.loader = true;
      const endpoint = 'api10/get_autocomplete_lession_associated_training';
      const data: any = {
        'search_str': val,
        secret: this.userservice.secret,
        token: this.userservice.jwttoken,
      };

      this.apiservice.getDataWithoutToken(data, endpoint).subscribe(
        (res: any) => {
          // console.log(res, 'jobticket');
          this.optionsassociatedtraining = res.res;
          this.loader = false;
        }
      );
    }
  }
  onChangeEventLessonName(val: any) {
    if (val) {
      this.loader = true;
      const endpoint = 'api10/get_autocomplete_lession_lession_title';
      const data: any = {
        'search_str': val,
        secret: this.userservice.secret,
        token: this.userservice.jwttoken,
      };

      this.apiservice.getDataWithoutToken(data, endpoint).subscribe(
        (res: any) => {
          // console.log(res, 'jobticket');
          this.optionslessionname = res.res;
          this.loader = false;
        }
      );
    }
  }
}
@Component({
  selector: 'searchmodaladmin',
  templateUrl: 'searchmodaladmin.html',
})
export class Searchmodaladmin {
  public modalData: any;
  public formdata: any = {};
  public name_array: any = [];
  public type: any = '';
  public searchForm: FormGroup;
  public options: any = [];
  public optionemail: any = [];
  public jobticketdata: any = [];
  public jobticketcatagory: any = [];
  public product_array: any = [];
  public optionticketid: any = [];
  public optionreportedby: any = [];
  public loader: boolean = false;
  public optionsorganizername: any = [];
  public optionseventtitle: any = [];
  public optionscommissionname: any = [];
  constructor(
    public dialogRef: MatDialogRef<Searchmodaladmin>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    public Apiservice: ApiService,
    public router: Router,
    public cookieservice: CookieService,
    public activatedRoute: ActivatedRoute,
    public http: HttpClient,
    public dialog: MatDialog,
    public userservice: UserserviceService,
    public formBuilder: FormBuilder
  ) {
    console.log('search modal fire for', data);
    this.type = data;
    if (this.type == 'job_ticket') {
      this.jobticketsListView();
      this.jobticketscatagory();
    }
    if (this.type == 'commission' || this.type == 'contract_pending_signature') {
      this.commission_product();
    }
    this.searchForm = this.formBuilder.group({
      fullname_s: [''],
      userfullname_s: [''],
      leadfullname_s: [''],
      email_s: [''],
      leademail: [''],
      product_s: [''],
      products_s: [''],
      lastlogin_datetime: [{}],
      lastlogin_datetimestart: [''],
      lastlogin_datetimeend: [''],
      ticketid: [''],
      ticket_status: [''],
      createdon_datetime: [{}],
      createdon_datetimestart: [''],
      createdon_datetimeend: [''],
      ticket_category_name: [''],
      start_datetime_unix: [{}],
      start_datetime_unixstart: [''],
      start_datetime_unixend: [''],
      type: this.type,
      _id_s: [''],
      userdata_s: [''],
      user_name: [''],
      username: [''],
      event_title: ['']
    });
    this.formdata = {
      successmessage: 'Search Successfully !!',
      // redirectpath: 'admin/list',
      submitactive: true, // optional, default true
      submittext: 'Submit',
      // resettext: 'Reset',
      // canceltext: 'Cancel',
      // apiUrl: this.apiservice.api_url,
      // endpoint: 'api/userupdate', //
      // jwttoken: '',
      // cancelroute: 'admin/list',

      fields: [
        {
          label: 'Name',
          name: 'fullname_s',
          type: 'text',
          val: this.name_array,
          value: '',
          validations: [{ rule: 'required' }],
        },
      ],
    };
  }
  listenFormFieldChange(val: any) {
    console.log(val);
    if (val.field.name == 'fullname_s') {
      this.fetch(val.fieldval);
    }
  }
  fetch(val: any) {
    let apibody = {
      search_str: val,
      type: this.type,
      _id: this.userservice.userid,
      sort: { field: 'createdon_datetime', type: 'desc' },
      secret: this.userservice.secret,
      token: this.userservice.jwttoken,
    };

    this.Apiservice.getDataWithoutToken(
      apibody,
      'api/exitsinglistname'
    ).subscribe((data: any) => {
      this.name_array = data.res;
    });
  }
  search() {
    console.log(this.searchForm.value);
    // this.data.val=this.searchForm.value;
    if (this.searchForm.value['lastlogin_datetimestart'] != '') {
      this.searchForm.value['lastlogin_datetime']['$gte'] = new Date(this.searchForm.value['lastlogin_datetimestart']).getTime();
      this.searchForm.value['lastlogin_datetimestart'] = '';
    }
    if (this.searchForm.value['lastlogin_datetimeend'] != '') {
      this.searchForm.value['lastlogin_datetime']['$lte'] = new Date(this.searchForm.value['lastlogin_datetimeend']).getTime() +
        86399999;
      this.searchForm.value['lastlogin_datetimeend'] = '';
    }
    if (this.searchForm.value['createdon_datetimestart'] != '') {
      this.searchForm.value['createdon_datetime']['$gte'] = new Date(this.searchForm.value['createdon_datetimestart']).getTime();
      this.searchForm.value['createdon_datetimestart'] = '';
    }
    if (this.searchForm.value['createdon_datetimeend'] != '') {
      this.searchForm.value['createdon_datetime']['$lte'] = new Date(this.searchForm.value['createdon_datetimeend']).getTime() +
        86399999;
      this.searchForm.value['createdon_datetimeend'] = '';
    }
    if (this.searchForm.value['start_datetime_unixstart'] != '') {
      this.searchForm.value['start_datetime_unix']['$gte'] = new Date(this.searchForm.value['start_datetime_unixstart']).getTime();
      this.searchForm.value['start_datetime_unixstart'] = '';
    }
    if (this.searchForm.value['start_datetime_unixend'] != '') {
      this.searchForm.value['start_datetime_unix']['$lte'] = new Date(this.searchForm.value['start_datetime_unixend']).getTime() +
        86399999;
      this.searchForm.value['start_datetime_unixend'] = '';
    }
    // if(this.searchForm.value['status'] != ''){
    //   if(this.searchForm.value['status'] == '1'){
    //     this.searchForm.value['status'] = 1;
    //   }
    //   if(this.searchForm.value['status'] == '0'){
    //     this.searchForm.value['status'] = 0;
    //   }
    // }
    this.dialogRef.close(this.searchForm.value);
  }

  filterfun(event: any) {
    console.log(event, '+++');
  }
  private _filter(value: string) {
    console.log(value);
  }
  getTitle(optionid: string) {
    if (optionid != '' && optionid != null) {
      return this.options.find(option => option._id === optionid).name;
    }
  }
  getTitleforcommission(optionname: string) {
    if (optionname != '' && optionname != null) {
      console.log(this.options);
      return this.optionscommissionname.find(name => name.val === optionname).name;
    }
  }
  // getReportedby(name: string) {
  //   return this.optionreportedby.find(option => option.val === name).name;
  // }
  onChangeEventName(val: any) {
    this.loader = true;
    console.log(val, '+++++');
    if (val) {
      let apibody = {
        search_str: val,
        // type: this.type,
        // id: this.userservice.userid,
        sort: { field: 'createdon_datetime', type: 'desc' },
        secret: this.userservice.secret,
        token: this.userservice.jwttoken,
      };
      let endpoint = '';
      if (this.type == 'distributor' || this.type == 'commission') {
        endpoint = 'api/autocomplete_distributor_name';
      } else {
        endpoint = 'api/exitsinglistname?type=' + this.type;
      }
      this.Apiservice.getDataWithoutToken(
        apibody,
        endpoint
      ).subscribe((data: any) => {
        this.loader = false;
        this.options = data.res;
      });
    }
    else {
      this.loader = false;
    }
  }
  onChangeEventCommissionName(val: any) {
    this.loader = true;
    console.log(val, '+++++');
    if (val) {
      let apibody = {
        search_str: val,
        // type: this.type,
        // id: this.userservice.userid,
        sort: { field: 'createdon_datetime', type: 'desc' },
        secret: this.userservice.secret,
        token: this.userservice.jwttoken,
      };
      let endpoint = 'api/autocomplete_distributor_commission_name?type=distributor';
      this.Apiservice.getDataWithoutToken(
        apibody,
        endpoint
      ).subscribe((data: any) => {
        this.loader = false;
        this.optionscommissionname = data.res;
      });
    }
    else {
      this.loader = false;
    }
  }
  onChangeEventOrganizerName(val: any) {
    this.loader = true;
    console.log(val, '+++++');
    if (val) {
      let apibody = {
        search_str: val,
        // type: this.type,
        // id: this.userservice.userid,
        sort: { field: 'createdon_datetime', type: 'desc' },
        secret: this.userservice.secret,
        token: this.userservice.jwttoken,
      };
      let endpoint = 'api/autocomplete-organizername';
      this.Apiservice.getDataWithoutToken(
        apibody,
        endpoint
      ).subscribe((data: any) => {
        this.loader = false;
        this.optionsorganizername = data.res;
      });
    }
    else {
      this.loader = false;
    }
  }
  onChangeEventEventTitle(val: any) {
    this.loader = true;
    console.log(val, '+++++');
    if (val) {
      let apibody = {
        search_str: val,
        // type: this.type,
        // id: this.userservice.userid,
        sort: { field: 'createdon_datetime', type: 'desc' },
        secret: this.userservice.secret,
        token: this.userservice.jwttoken,
      };
      let endpoint = 'api/autocomplete-eventtitle';

      this.Apiservice.getDataWithoutToken(
        apibody,
        endpoint
      ).subscribe((data: any) => {
        this.loader = false;
        this.optionseventtitle = data.res;
      });
    }
    else {
      this.loader = false;
    }
  }
  onChangeEventLeadName(val: any) {
    this.loader = true;
    console.log(val, '+++++');
    if (val) {
      let apibody = {
        search_str: val,
        // type: this.type,
        // id: this.userservice.userid,
        sort: { field: 'createdon_datetime', type: 'desc' },
        secret: this.userservice.secret,
        token: this.userservice.jwttoken,
      };

      this.Apiservice.getDataWithoutToken(
        apibody,
        'api/exitsinglistnameforlead'
      ).subscribe((data: any) => {
        this.loader = false;
        this.options = data.res;
      });
    }
    else {
      this.loader = false;
    }
  }
  onChangeEventEmail(val: any) {
    this.loader = true;
    console.log(val);
    if (val) {
      let apibody = {
        search_str: val,
        // type: this.type,
        // id: this.userservice.userid,
        sort: { field: 'createdon_datetime', type: 'desc' },
        secret: this.userservice.secret,
        token: this.userservice.jwttoken,
      };

      this.Apiservice.getDataWithoutToken(
        apibody,
        'api/exitsinglistemail?type=' + this.type
      ).subscribe((data: any) => {
        this.loader = false;
        this.optionemail = data.res;
      });
    }
    else {
      this.loader = false;
    }
  }
  onChangeEventLeadEmail(val: any) {
    this.loader = true;
    console.log(val);
    if (val) {
      let apibody = {
        search_str: val,
        // type: this.type,
        // id: this.userservice.userid,
        sort: { field: 'createdon_datetime', type: 'desc' },
        secret: this.userservice.secret,
        token: this.userservice.jwttoken,
      };

      this.Apiservice.getDataWithoutToken(
        apibody,
        'api/exitsinglistemailforlead'
      ).subscribe((data: any) => {
        this.loader = false;
        this.optionemail = data.res;
      });
    }
    else {
      this.loader = false;
    }
  }
  onChangeEventTicketID(val: any) {
    this.loader = true;
    console.log(val);
    if (val != null) {
      let apibody = {
        search_str: val,
        // type: this.type,
        // id: this.userservice.userid,
        sort: { field: 'createdon_datetime', type: 'desc' },
        secret: this.userservice.secret,
        token: this.userservice.jwttoken,
      };

      this.Apiservice.getDataWithoutToken(
        apibody,
        'api/autocomplete_ticket_id'
      ).subscribe((data: any) => {
        this.loader = false;
        this.optionticketid = data.res;
      });
    }
    else {
      this.loader = false;
    }
  }
  onChangeEventReportedBy(val: any) {
    this.loader = true;
    console.log(val);
    if (val) {
      let apibody = {
        search_str: val,
        // type: this.type,
        // id: this.userservice.userid,
        sort: { field: 'createdon_datetime', type: 'desc' },
        secret: this.userservice.secret,
        token: this.userservice.jwttoken,
      };

      this.Apiservice.getDataWithoutToken(
        apibody,
        'api/autocomplete_job_ticket_reported_by'
      ).subscribe((data: any) => {
        this.loader = false;
        this.optionreportedby = data.res;
      });
    }
    else {
      this.loader = false;
    }
  }
  reset() {
    this.searchForm.reset();
    this.searchForm = this.formBuilder.group({
      fullname_s: [''],
      userfullname_s: [''],
      leadfullname_s: [''],
      email_s: [''],
      leademail: [''],
      product_s: [''],
      products_s: [''],
      lastlogin_datetime: [{}],
      lastlogin_datetimestart: [''],
      lastlogin_datetimeend: [''],
      ticketid: [''],
      ticket_status: [''],
      createdon_datetime: [{}],
      createdon_datetimestart: [''],
      createdon_datetimeend: [''],
      ticket_category_name: [''],
      start_datetime_unix: [{}],
      start_datetime_unixstart: [''],
      start_datetime_unixend: [''],
      type: this.type,
      _id_s: [''],
      userdata_s: [''],
      user_name: [''],
      username: [''],
      event_title: ['']
    });
  }
  onNoClick(): void {
    this.dialogRef.close();
  }
  jobticketsListView() {
    const endpoint = 'api/autocomplete_ticket_status';
    const data: any = {
      secret: this.userservice.secret,
      token: this.userservice.jwttoken,
      // searchcondition: { user_id: this.userservice.userid },
    };

    this.Apiservice.getDataWithoutToken(data, endpoint).subscribe(
      (res: any) => {
        // console.log(res, 'jobticket');
        this.jobticketdata = res.res; // sarch count  (How many data there)
        console.log(this.jobticketdata, '::::::');
        this.jobticketdata = [...new Set(this.jobticketdata.map(item => item.name))];
        // console.log(this.jobticketdata,'::::::');

      }
    );
  }
  jobticketscatagory() {
    const endpoint = 'api/autocomplete_ticket_categories';
    const data: any = {
      secret: this.userservice.secret,
      token: this.userservice.jwttoken,
      // searchcondition: { user_id: this.userservice.userid },
    };

    this.Apiservice.getDataWithoutToken(data, endpoint).subscribe(
      (res: any) => {
        // console.log(res, 'jobticket');
        this.jobticketcatagory = res.res; // sarch count  (How many data there)
        this.jobticketcatagory = [...new Set(this.jobticketcatagory.map(item => item.name))];

      }
    );
  }
  commission_product() {
    let productendpointdata = {
      token: this.userservice.jwttoken,
      secret: this.userservice.secret
    }
    this.Apiservice.getDataWithoutToken(productendpointdata, 'api/productsearch').subscribe((data: any) => {
      // console.log(data,'+++++++++++++++++');
      for (let i = 0; i < data.res.length; i++) {
        let array = {
          val: data.res[i].val,
          name: data.res[i].name
        }
        this.product_array.push(array)
      }
      // this.product_array = data.res;
      console.log(this.product_array, '+++++++++++++++++++++++++++');

    })
  }
}
@Component({
  selector: 'commission-edit-dialog',
  templateUrl: 'commission-edit-dialog.html',
})
export class CommissionEditDialog {
  public details_data: any = {};
  public submitForm: FormGroup;
  public loader: boolean = false;
  public commission_validation_error: boolean = false;
  public submit_var: boolean = false;
  public commission_validation_error_zero: boolean = false;
  public commission_validation_error_hundred: boolean = false;
  constructor(
    public dialogRef: MatDialogRef<CommissionEditDialog>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    public Apiservice: ApiService,
    public router: Router,
    public cookieservice: CookieService,
    public activatedRoute: ActivatedRoute,
    public http: HttpClient,
    public dialog: MatDialog,
    public userservice: UserserviceService,
    public formBuilder: FormBuilder) {
    console.log(data);
    this.details_data = data;
    this.submitForm = this.formBuilder.group({
      commission_value: [this.details_data.data.commission_value],
      _id: [this.details_data.data._id]
    });
  }
  setCapacityType(e) {
    console.log(e);

    let keyCode = (e.keyCode ? e.keyCode : e.which);

    if (keyCode == 69 || keyCode == 107 || keyCode == 109) {
      e.preventDefault();
    }
  }
  onChangeEventName(val) {
    // console.log(val);
    this.commission_validation_error_zero = false;
    this.commission_validation_error_hundred = false;
    if (this.submitForm.value['commission_value'] == null) {
      this.commission_validation_error = true;
    } else {
      this.commission_validation_error = false;
    }
  }
  submit() {
    this.submit_var = true;
    console.log(typeof (this.submitForm.value['commission_value']));
    console.log(this.submitForm.value['commission_value']);
    if (this.submitForm.value['commission_value'] != null) {
      if (this.submitForm.value['commission_value'] == 0) {
        this.commission_validation_error_zero = true;
        console.warn('1');
      } else {
        this.commission_validation_error_zero = false;
        console.log((this.submitForm.value['commission_value']).toFixed(2));

        if ((this.submitForm.value['commission_value']).toFixed(2) >= 100.01 && this.details_data.data.commission_type != 'event_commission') {
          this.commission_validation_error_hundred = true;
          console.warn('2');
        } else {
          console.warn('3');
          this.submitForm.value['commission_value'] = (this.submitForm.value['commission_value']).toFixed(2);
          this.commission_validation_error_zero = false;
          this.commission_validation_error_hundred = false;
          this.loader = true;
          this.commission_validation_error = false;
          let send_data = {
            searchcondition: this.submitForm.value
          }
          this.Apiservice.getDataWithoutToken(send_data, 'api10/update-commission-rule').subscribe(() => {
            this.loader = false;
            this.dialogRef.close(true);
          })
        }
      }
    } else {
      this.commission_validation_error = true;
    }
  }
  onNoClick(): void {
    this.dialogRef.close();
  }

}