import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { VideoCategoryListingComponent } from './listing/listing.component';
import { CategoryAddEditComponent } from './category/add-edit/add-edit.component';

import { videoAddEditComponent } from './video/add-edit/add-edit.component';
import { ResolveService } from 'src/app/services/resolve.service';
import { environment } from 'src/environments/environment';
import { ProductVideoComponent } from './product-video/product-video.component';

const routes: Routes = [
  { path: 'video/add', component: videoAddEditComponent },
  { path: 'video/edit/:_id', component: videoAddEditComponent,
  resolve: { resolvedata: ResolveService },
  data: {
    requestcondition: {
      condition: {},
    },
    endpoint: 'api1/getvideobyid',
    api_url: environment.api_url,
  } },

  { path: 'category/add', component: CategoryAddEditComponent },
  {
    path: 'category/edit/:_id', component: CategoryAddEditComponent,
    resolve: { resolvedata: ResolveService },
    data: {
      requestcondition: {
        condition: {},
      },
      endpoint: 'api1/getvideocategorybyid',
      api_url: environment.api_url,
    }

  },

  {
    path: 'listing', component: VideoCategoryListingComponent,
    resolve: { resolvedata: ResolveService }, // resolve service for load data
    data: {
      requestcondition: {
        condition: {
          limit: 10,
          skip: 0
        },
        sort: {
          type: 'asc',
          field: 'category_name'
        }
      },
      endpoint: 'api1/getallvideodcategorydata',  // endpoint of userlist
      api_url: environment.api_url, // base url of database
    },
  },
  {
    path: 'video-gallery-management',
    component: ProductVideoComponent,
    resolve: { resolvedata: ResolveService },
    data: {
      requestcondition: {
        condition: {
          limit: 10,
          skip: 0,
        },
        sort: {
          type: 'asc',
          field: 'video_title',
        },
      },
      endpoint: 'api1/allcategoryvideosasyncdata',
      api_url: environment.api_url,
    },
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class VideoManagementRoutingModule { }


