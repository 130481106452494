import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { takeUntil, map } from 'rxjs/operators';
import { Subscription, ReplaySubject } from 'rxjs';

import { ApiService } from '../../services/api.service';
// import { MetaService } from '@ngx-meta/core';
import { environment } from 'src/environments/environment';

import { DetectdomainService } from "src/app/services/detectdomain.service";
import { ObservableService } from '../../services/observable.service';


@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})

export class LoginComponent implements OnInit {
  public fromTitle: any = 'Welcome to Beto Paredes';
  public fullUrl: any = environment.api_url;
  public signUpRouteingUrl: any = {
    path: '',
    // 'buttonName':'sign-up',
    customLink: '',
  };

  public forgetRouteingUrl: any = {
    path: '',
    buttonName: 'Forgot Password',
    customLink: '/forgot-password',
    customURl: '',
  };
  public loginflag: boolean = false;
  public routerStatus: any;
  public endpoint: any = 'api/login';
  public buttonName: any = 'Login';
  public defaultLoginUrl = '/login';
  public ipinfoid = '9797c42b93078a'; // this is info api key
  public logo: any = '';
  
  public hostName: any;
  public medigradeflag: any=false;
  public loginlogo: string = '';

  private unsubscribe: ReplaySubject<boolean> = new ReplaySubject(1);


  constructor(
  public cookie: CookieService,
  public route: ActivatedRoute,
  public router: Router,
  public apiservice: ApiService ,
  public observableservice: ObservableService,       
  public domainService: DetectdomainService 
  ) {
        
    this.hostName = this.domainService.getdomain();
    if (this.hostName == "medigrade") {
      this.medigradeflag=true
      this.fromTitle = 'Welcome to MediGrade Technologies';

    }

    this.cookie.set('from_login','true');
    this.apiservice.customRequest({}, 'temptoken', environment['api_calender_url']).pipe(takeUntil(this.unsubscribe)).subscribe((response: any) => {
      // console.log(response);
      this.cookie.set('jwt_for_calendar', response.token, undefined, '/');
    });
    // console.warn('calendar');
    if (this.cookie.check('type') && this.cookie.check('jwtToken')) {
      const type = JSON.parse(this.cookie.get('type'));
      if (!this.cookie.check('signature') && this.cookie.check('randomstring') && (type == 'sales-person' || type == 'technological-consultant' || type == 'distributor')) {
         console.log('++++++++ login ====', '/contract/' + JSON.parse(this.cookie.get('randomstring')))
        this.router.navigateByUrl('/contract/' + JSON.parse(this.cookie.get('randomstring')));
        return;
      }
      if (!this.cookie.check('event_type') && this.cookie.check('signature') && (type == 'sales-person' || type == 'technological-consultant' || type == 'distributor')) {
        // console.warn('calendar');
        this.router.navigateByUrl('/calendar-management/manage-appointment-after-sign/on-boarding/book-appointment');
        return;
      }
      // if (!this.cookie.check('event_type') && this.cookie.check('signature') &&  type == 'technological-consultant') {

      // //  console.log('ppppppppppppppppppppppppp');

      //   return;
      // }
      this.router.navigate([type + '/dashboard']);

      return;
    } else {
      // if (!this.cookie.check('type') &&JSON.parse(this.cookie.get('type')) == 'technological-consultant') {
      // console.log('ppppppppppppppppppppppppp');
      // // localStorage.setItem('dataSource', this.dataSource.length);

      // }

      this.loginflag = true;
      this.routerStatus = {
        data: [
          {
            type: 'admin',
            routerNav: 'admin/dashboard',
            cookies: {
              userid: '_id',
              useremail: 'email',
              firstname: 'firstname',
              lastname: 'lastname',
              jwttoken: 'jwtToken',
              status: 'status',
              type: 'type',
              lastlogincity: 'lastlogincity',
              lastloginregion: 'lastloginregion',
              lastlogindate: 'lastlogin_datetime',
              access_token: 'access_token',
              refresh_token: 'refresh_token',
              connected_gmail: 'connected_gmail',
              google_calendar_connect_time: 'google_calendar_connect_time',
              event_type: 'event_type',
              products: 'products',
              login_time: 'login_time',
              timeZone: "timeZone",
              refreshtokenfailed: "refreshtokenfailed",
              currentlesson:'currentlesson',
              associated_training:'associated_training'

            }
          },
          {
            type: 'distributor',
            routerNav: 'distributor/dashboard',
            cookies: {
              userid: '_id',
              useremail: 'email',
              firstname: 'firstname',
              lastname: 'lastname',
              jwttoken: 'jwtToken',
              status: 'status',
              type: 'type',
              lastlogincity: 'lastlogincity',
              lastloginregion: 'lastloginregion',
              lastlogindate: 'lastlogin_datetime',
              signature: 'signature',
              randomstring: 'randomstring',
              parentid: 'parentid',
              event_type: 'event_type',
              is_contractmanager: 'is_contractmanager',
              products: 'products',
              login_time: 'login_time',
              timeZone: "timeZone",
              onbordingcall: 'onbordingcall',
              gameplancall: 'gameplancall',
              viewcommissionflag: 'viewcommissionflag',
              currentlesson:'currentlesson',
              associated_training:'associated_training'
            }
          },
          {
            type: 'contract-manager',
            routerNav: 'contract-manager/dashboard',
            cookies: {
              userid: '_id',
              useremail: 'email',
              firstname: 'firstname',
              lastname: 'lastname',
              jwttoken: 'jwtToken',
              status: 'status',
              type: 'type',
              lastlogincity: 'lastlogincity',
              lastloginregion: 'lastloginregion',
              lastlogindate: 'lastlogin_datetime',
              parentid: 'parentid',
              signature: 'signature',
              randomstring: 'randomstring',
              event_type: 'event_type',
              is_contractmanager: 'is_contractmanager',
              products: 'products',
              login_time: 'login_time',
              timeZone: "timeZone"
            }
          },
          {
            type: 'closer',
            routerNav: 'closer/dashboard',
            cookies: {
              userid: '_id',
              useremail: 'email',
              firstname: 'firstname',
              lastname: 'lastname',
              jwttoken: 'jwtToken',
              status: 'status',
              type: 'type',
              lastlogincity: 'lastlogincity',
              lastloginregion: 'lastloginregion',
              lastlogindate: 'lastlogin_datetime',
              access_token: 'access_token',
              refresh_token: 'refresh_token',
              connected_gmail: 'connected_gmail',
              google_calendar_connect_time: 'google_calendar_connect_time',
              parentid: 'parentid',
              event_type: 'event_type',
              is_contractmanager: 'is_contractmanager',
              products: 'products',
              login_time: 'login_time',
              timeZone: "timeZone",
              refreshtokenfailed: "refreshtokenfailed"
            }
          },
          {
            type: 'technological-consultant',
            routerNav: 'technological-consultant/dashboard',
            cookies: {
              userid: '_id',
              useremail: 'email',
              firstname: 'firstname',
              lastname: 'lastname',
              jwttoken: 'jwtToken',
              status: 'status',
              type: 'type',
              lastlogincity: 'lastlogincity',
              lastloginregion: 'lastloginregion',
              lastlogindate: 'lastlogin_datetime',
              signature: 'signature',
              randomstring: 'randomstring',
              parentid: 'parentid',
              event_type: 'event_type',
              is_contractmanager: 'is_contractmanager',
              products: 'products',
              login_time: 'login_time',
              access_token: 'access_token',
              refresh_token: 'refresh_token',
              timeZone: "timeZone",
              discovery_call: 'discovery_call',
              viewcommissionflag: 'viewcommissionflag',
              onbordingcall: 'onbordingcall',
              gameplancall: 'gameplancall',
              viewcommissionfortech: 'viewcommissionfortech',
              connected_gmail: 'connected_gmail',
              google_calendar_connect_time: 'google_calendar_connect_time',
              refreshtokenfailed: "refreshtokenfailed",
              currentlesson:'currentlesson',
              associated_training:'associated_training',
              recent_tech:'recent_tech',
              Jeffrey:'Jeffrey',
            }
          },
          {
            type: 'lead',
            routerNav: 'lead/dashboard',
            cookies: {
              userid: '_id',
              useremail: 'email',
              firstname: 'firstname',
              lastname: 'lastname',
              jwttoken: 'jwtToken',
              status: 'status',
              type: 'type',
              lastlogincity: 'lastlogincity',
              lastloginregion: 'lastloginregion',
              lastlogindate: 'lastlogin_datetime',
              // parentid: 'parentid',
              event_type: 'event_type',
              login_time: 'login_time'
            }
          },
          {
            type: 'sales-person',
            routerNav: 'sales-person/dashboard',
            cookies: {
              userid: '_id',
              useremail: 'email',
              firstname: 'firstname',
              lastname: 'lastname',
              jwttoken: 'jwtToken',
              status: 'status',
              type: 'type',
              lastlogincity: 'lastlogincity',
              lastloginregion: 'lastloginregion',
              lastlogindate: 'lastlogin_datetime',
              parentid: 'parentid',
              randomstring: 'randomstring',
              signature: 'signature',
              event_type: 'event_type',
              is_contractmanager: 'is_contractmanager',
              products: 'products',
              login_time: 'login_time',
              timeZone: "timeZone"
            }
          }
        ]
      };
    }
  }
  ngOnInit() {

  }
}
