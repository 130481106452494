import {
  ElementRef,
  EventEmitter,
  Injectable,
  Input,
  ViewChild,
} from '@angular/core';
import { switchMap, map, takeWhile, catchError } from 'rxjs/operators';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, Subject, Subscription, throwError } from 'rxjs';
import { CookieService } from 'ngx-cookie-service';
import { Router, ActivatedRoute } from '@angular/router';
import { MatSnackBar } from '@angular/material';
import { environment } from '../../environments/environment';
// import { error } from 'console';

@Injectable({
  providedIn: 'root',
})
export class ApiService {

  constructor(
    private http: HttpClient,
    private cookieservice: CookieService,
    public router: Router,
    public activatedroute: ActivatedRoute,
    private snackbar: MatSnackBar
  ) {
    if (this.cookieservice.check('jwtToken')) {
      this.jwttoken = this.cookieservice.get('jwtToken');
    }

    if (this.cookieservice.check('secret')) {
      this.secret = this.cookieservice.get('secret');
    }
  }
  // public localhost_url = environment.localhost_url;
  public api_url = environment.api_url;
  public api_url2 = environment.api_url2;
  public domain_url = environment.domain_url;
  public pdfcreated = environment.pdfcreated;
  public calender_url = environment.api_calender_url;
  public forntendUrl1 = environment.forntendUrl;

  public product_tmflow = environment.product_tmflow;
  public product_pece = environment.product_pece;
  public product_bioEnergetics = environment.product_bioEnergetics;
  public product_RSTSanexas = environment.product_RSTSanexas;
  public fileimgsslurl: any;
  fileservername: any = [];
  serverUrl: any;
  addendpointUrl: any;
  uploadEndpointUrl: any;
  updateendpointUrl: any;
  updatestatussingleendpointUrl: any;
  deletemultipleendpointUrl: any;
  updatestatusmultipleendpointUrl: any;
  getdataendpointUrl: any;
  public invalidApi: any;
  public jwttoken = '';
  public tokenVal: any;
  public updatestatussingleendpointurl: any;
  public deletesingle_endpointUrl: any;
  public deletesingleendpointUrl: any;

  public secret: string;
  public count = 3;

  isTokenExpired() { }

  /* read site setting data */
  public getSiteSettingData(url): Observable<any> {
    return this.http.get(url);
  }
  /* read Json data */
  public getJsonData(url): Observable<any> {
    return this.http.get(url);
  }

  // http by data and endpoint
  postDatawithoutToken(endpoint: any, data: any) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };
    const result = this.http
      .post(this.api_url + endpoint, JSON.stringify(data), httpOptions)
      .pipe(
        catchError((error) => {
          this.openSnackBar();
          return throwError(error);
        }),
        map((response) => response)
      );
    return result;
  }

  addDataApi1(requestdata: any) {
    // // console.log('in adddata apiservice');
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        //  'Authorization': this.cookieService.get('jwtToken')          //hard code written access-token(temp)
      }),
    };
    const result = this.http
      .post(
        this.api_url + this.addendpointUrl,
        JSON.stringify(requestdata),
        httpOptions
      )
      .pipe(
        catchError((error) => {
          this.openSnackBar();
          return throwError(error);
        }),
        map((response) => response)
      );
    return result;
  }

  /*************** Added by himadri start here ***************/

  getDataForDatalist(endpoint: any) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: this.cookieservice.get('jwtToken'),
      }),
    };

    // this.isTokenExpired()
    const result = this.http
      .post(this.api_url + 'datalist', endpoint, httpOptions)
      .pipe(
        catchError((error) => {
          this.openSnackBar();
          return throwError(error);
        }),
        map((response) => response)
      );
    return result;
  }

  // getData end

  getDatalist(requestdata: any) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: this.cookieservice.get('jwtToken'),
      }),
    };
    const result = this.http
      .post(
        this.api_url + requestdata.endpoint,
        JSON.stringify(requestdata),
        httpOptions
      )
      .pipe(
        catchError((error) => {
          this.openSnackBar();
          return throwError(error);
        }),
        map((response) => response)
      );
    return result;
  }

  getDatalistforapi1(requestdata: any) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        // 'Authorization': this.cookieService.get('jwtToken')
      }),
    };
    const result = this.http
      .post(
        this.api_url + requestdata.endpoint,
        JSON.stringify(requestdata),
        httpOptions
      )
      .pipe(
        catchError((error) => {
          this.openSnackBar();
          return throwError(error);
        }),
        map((response) => response)
      );
    return result;
  }

  getDatalistWithToken(requestdata: any, endpoint: any) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };
    const result = this.http
      .post(this.api_url + endpoint, JSON.stringify(requestdata), httpOptions)
      .pipe(map((res) => res));
    return result;
  }

  getTempToken() {
    const result = this.http.get(this.api_url + 'gettemptoken').pipe(
      catchError((error) => {
        this.openSnackBar();
        return throwError(error);
      }),
      map((response) => response)
    );
    return result;
  }

  /*************** Added by himadri end here ***************/

  getDatalistForResolve(requestdata: any) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: this.cookieservice.get('jwtToken'),
      }),
    };
    const result = this.http
      .post(
        this.api_url + requestdata.endpoint,
        JSON.stringify(requestdata.requestcondition),
        httpOptions
      )
      .pipe(
        catchError((error) => {
          this.openSnackBar();
          return throwError(error);
        }),
        map((response) => response)
      );
    return result;
  }

  getDataForResolve(requestdata: any) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };
    // // console.log(requestdata)

    const result = this.http
      .post(
        this.api_url + requestdata.endpoint,
        JSON.stringify(requestdata.requestcondition),
        httpOptions
      )
      .pipe(
        catchError((error) => {
          this.openSnackBar();
          return throwError(error);
        }),
        map((response) => response)
      );
    return result;
  }

  getDataFortraing(requestdata: any) {
    console.log(requestdata, 'requestdata_-apiservice');
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };
    const result = this.http
      .post(this.api_url2 + requestdata.endpoint, JSON.stringify(requestdata.data), httpOptions)
      .pipe(map((res) => res));
    return result;
  }

  getDataForResolveAp1(requestdata: any) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };
    // // console.log(requestdata)
    const result = this.http
      .post(
        this.api_url + requestdata.endpoint,
        JSON.stringify(requestdata.requestcondition),
        httpOptions
      )
      .pipe(
        catchError((error) => {
          this.openSnackBar();
          return throwError(error);
        }),
        map((response) => response)
      );
    return result;
  }

  addLogin(requestdata: any) {
    // // console.log('in addLogin apiservice');
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        // 'Authorization': this.cookieService.get('jwtToken')          //hard code written access-token(temp)
      }),
    };

    // // console.log(this.serverUrl,requestdata);
    const result = this.http
      .post(
        this.api_url + this.addendpointUrl,
        JSON.stringify(requestdata),
        httpOptions
      )
      .pipe(
        catchError((error) => {
          this.openSnackBar();
          return throwError(error);
        }),
        map((response) => response)
      );
    return result;
  }

  /*************** Added by himadri end here ***************/

  /*************** Added by himadri start here ***************/
  forgetPassword(requestdata: any) {
    // // console.log('in forgetPassword apiservice');
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        // 'Authorization': this.cookieService.get('jwtToken')          //hard code written access-token(temp)
      }),
    };

    // // console.log(this.serverUrl,requestdata);
    const result = this.http
      .post(
        this.serverUrl + this.addendpointUrl,
        JSON.stringify(requestdata),
        httpOptions
      )
      .pipe(
        catchError((error) => {
          this.openSnackBar();
          return throwError(error);
        }),
        map((response) => response)
      );
    return result;
  }

  /*************** Added by himadri end here ***************/

  deleteSingleData(requestdata: any) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: this.cookieservice.get('jwtToken'),
      }),
    };
    const result = this.http
      .post(
        this.api_url + this.deletesingleendpointUrl,
        JSON.stringify(requestdata),
        httpOptions
      )
      .pipe(
        catchError((error) => {
          this.openSnackBar();
          return throwError(error);
        }),
        map((response) => response)
      );
    return result;
  }

  deleteMultipleData(requestdata: any) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: this.cookieservice.get('jwtToken'),
      }),
    };
    const result = this.http
      .post(
        this.serverUrl + this.deletesingleendpointUrl + 'many',
        JSON.stringify(requestdata),
        httpOptions
      )
      .pipe(
        catchError((error) => {
          this.openSnackBar();
          return throwError(error);
        }),
        map((response) => response)
      );
    return result;
  }

  UpdateStatusForSingleData(requestdata: any) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: this.cookieservice.get('jwtToken'),
      }),
    };
    const result = this.http
      .post(
        this.serverUrl + this.updatestatussingleendpointUrl,
        JSON.stringify(requestdata),
        httpOptions
      )
      .pipe(
        catchError((error) => {
          this.openSnackBar();
          return throwError(error);
        }),
        map((response) => response)
      );
    return result;
  }

  UpdateStatusForMultipleData(requestdata: any) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: this.cookieservice.get('jwtToken'),
      }),
    };
    const result = this.http
      .post(
        this.serverUrl + this.updatestatussingleendpointUrl + 'many',
        JSON.stringify(requestdata),
        httpOptions
      )
      .pipe(
        catchError((error) => {
          this.openSnackBar();
          return throwError(error);
        }),
        map((response) => response)
      );
    return result;
  }

  customRequest(requestdata: any, endpoint: any, url: any) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        // Authorization: this.cookieService.get('jwtToken')
      }),
    };
    const result = this.http
      .post(url + endpoint, JSON.stringify(requestdata), httpOptions)
      .pipe(
        catchError((error) => {
          this.openSnackBar();
          return throwError(error);
        }),
        map((response) => response)
      );
    return result;
  }

  customRequest_temporary(requestdata: any, endpoint: any, url: any) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        // Authorization: this.cookieService.get('jwtToken')
      }),
    };
    const result = this.http
      .post(url + endpoint, JSON.stringify(requestdata), httpOptions)
      .pipe(
        catchError((error) => {
          // this.openSnackBar();
          return throwError(error);
        }),
        map((response) => response)
      );
    return result;
  }

  gettemptoken() {
    const result = this.http
      .get(this.api_url + 'gettemptoken')
      .pipe(map((res) => res));
    return result;
  }

  getJsonObject(path: any) {
    const result = this.http.get(path).pipe(
      catchError((error) => {
        this.openSnackBar();
        return throwError(error);
      }),
      map((response) => response)
    );
    return result;
  }




  getDataWithoutToken(requestdata: any, endpoint: any) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };
    const result = this.http
      .post(this.api_url + endpoint, JSON.stringify(requestdata), httpOptions)
      .pipe(
        catchError((error) => {
          this.openSnackBar();
          return throwError(error);
        }),
        map((response) => response)
      );
    return result;
  }


  contractdownload(requestdata: any, endpoint: any) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Accept: '*/*',
        Referer: 'bp-html-pdf.influxiq.com',
        'Accept-Encoding': 'gzip, deflate, br',
        'Access-Control-Allow-Origin': this.pdfcreated,
        'Access-Control-Allow-Methods': 'POST',
        'Access-Control-Allow-Headers': 'Content-Type, Authorization',
        'Access-Control-Allow-Credentials': 'true'
      }),
    };
    const result = this.http
      .post(this.pdfcreated + endpoint, JSON.stringify(requestdata), httpOptions)
      .pipe(
        catchError((error) => {
          this.openSnackBar();
          return throwError(error);
        }),
        map((response) => response)
      );
    return result;
  }

  getDataforAdminList(endpoint: any, requestdata: any) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: this.cookieservice.get('jwtToken'),
      }),
    };
    const result = this.http
      .post(this.api_url + endpoint, JSON.stringify(requestdata), httpOptions)
      .pipe(
        catchError((error) => {
          this.openSnackBar();
          return throwError(error);
        }),
        map((response) => response)
      );
    return result;
  }

  getData(endpoint: any, requestdata: any) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: this.cookieservice.get('jwtToken'),
      }),
    };
    const result = this.http
      .post(this.api_url + endpoint, JSON.stringify(requestdata), httpOptions)
      .pipe(
        catchError((error) => {
          this.openSnackBar();
          return throwError(error);
        }),
        map((response) => response)
      );
    return result;
  }


  updatedata(requestdata: any, endpoint: any) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };
    const result = this.http
      .post(this.api_url + endpoint, JSON.stringify(requestdata), httpOptions)
      .pipe(
        catchError((error) => {
          this.openSnackBar();
          return throwError(error);
        }),
        map((response) => response)
      );
    return result;
  }

  getState() {
    const result = this.http.get('assets/data/states.json').pipe(
      catchError((error) => {
        this.openSnackBar();
        return throwError(error);
      }),
      map((response) => response)
    );
    return result;
  }

  getCountry() {
    const result = this.http.get('assets/data/countries.json').pipe(
      catchError((error) => {
        this.openSnackBar();
        return throwError(error);
      }),
      map((response) => response)
    );
    return result;
  }




  openSnackBar() {
    this.snackbar.open('Something Went Wrong ,Try Again!!', 'ok', {
      duration: 6000,
    });
  }
}
