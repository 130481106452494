import { HttpClient } from "@angular/common/http";
import { Component, Inject, OnInit } from "@angular/core";
import { FormControl, FormBuilder, FormGroup } from "@angular/forms";
import {
  MatBottomSheet,
  MatDialog,
  MatDialogRef,
  MatSnackBar,
  MAT_DIALOG_DATA,
} from "@angular/material";
import { DomSanitizer } from "@angular/platform-browser";
import { ActivatedRoute, Router } from "@angular/router";
import { warn } from "console";
// import { ObservableserviceService } from 'listing-angular7/lib/service/observableservice.service';
//import { timingSafeEqual } from 'crypto';
// import * as console from 'console';
import { CookieService } from "ngx-cookie-service";
import { Observable, Subject } from "rxjs";
import { debounceTime, map, startWith } from "rxjs/operators";
import { ApiService } from "src/app/services/api.service";
import { NavAccessService } from "src/app/services/nav-access.service";
import { ObservableService } from "src/app/services/observable.service";
import { UserserviceService } from "src/app/services/userservice.service";
import { environment } from "src/environments/environment";
import { DialogMarkAsDoneAlert } from "../../calendar-management/appointment-listing/past-appointment/past-appointment.component";
import { DialogCancelAlert } from "../../calendar-management/appointment-listing/upcoming-appointment/upcoming-appointment.component";
import {
  bottomsheetfollowup,
  bottomsheetstatus,
  cancelappoinmentAlert,
  DialogmarkasdoneAlert,
  Dispositionview,
} from "../../lead/lead-list/lead-list.component";

export interface DialogData {
  data: any;
  flag: any;
  flag_followup: any;
  result: any;
  res: any;
  msg: any;
  note_del_modal: any;
}
export interface DialogData2 {
  data: any;
  flag: any;
  view: any;
  flag_check: any;
}
export interface DialogData3 {
  data: any;
}

@Component({
  selector: "app-folder-view",
  templateUrl: "./folder-view.component.html",
  styleUrls: ["./folder-view.component.css"],
})
export class FolderViewComponent implements OnInit {
  public searchsettings_followup: any;
  public tablename: any;
  // public listenLiblistingChange:any;
  public loaderFlag = "";
  public google_events_data: any = [];
  public nonotesfound: boolean = false;
  public contract_approved_credit: boolean = false;
  public user_contract: any = [];
  public flag: any;
  public initial: boolean;
  public download_pdf_pece: boolean = false;
  public download_pdf_warranty: boolean = false;
  public download_pdf_credit: boolean = false;
  public download_pdf_tmflow: boolean = false;
  public awaiting_for_hps_signature: boolean = false;
  public download_pdf_link_pece: any = "";
  public download_pdf_link_warranty: any = "";
  public download_pdf_link_tmflow: any = "";
  public download_pdf_link_credit: any = "";
  public sent_for_signature: any = [];
  public contactnorecordfound: boolean;
  public cntractflags_approved: any = [];
  public addflag: boolean = false;
  public updateflag: boolean = false;
  public openfrom: boolean = false;
  public flag1: boolean = true;
  public viewcontractflag: any = [];
  public resendproduct: any = [];
  public attachmentflag: any;
  public searchForm: FormGroup;
  public alldata: any;
  public resendcount_pece: any = "";
  public tags_data: any;
  public disposition_data: any = [];
  public dispositionfollowup: any = [];
  public trainingCategoryName: any = [];
  public donetime: any = [];
  public reportPercentage: any;
  public divisor: number = 0;
  public dividend: any = 0;
  public lessonData: any = [];
  public forwarrentyonly: boolean = false;
  public doneTraininglesson: any = [];
  panelOpenState = false;
  public tmflowflag: boolean = true;
  public questionnaireflag: boolean = false;
  public productContractlist: any;
  public sendMailStatus: any = false;
  public createdApprove: any = false;
  public isresendcredit: boolean = false;
  public sendMailButtonText: any = "Show Send Mail Section";
  public maillist: any = [];
  public maillist_count: any = 0;
  public count = 1;
  public count1 = 1;
  public contractproducts: any = [];
  public book_appointment_data: any = [];
  public is_presentation_link_flg: boolean = false;
  public no_record_found_book_appointment_data: boolean = false;
  public myinput: any;
  public indexVal: any = 5;
  public warranty_btn_show: boolean = false;
  public norecordfoundfor_discovery_appointments: boolean = false;
  public appointmentdiscoverydata: boolean = false;
  public norecordfoundfor_ganeral_past_appointments: boolean = false;
  public norecordfoundfor_ganeral_upcoming_appointments: boolean = false;
  public discovery_worksheet_flag: boolean = false;
  public myInputMessage: string = "";
  // public schedule_datatablecount: any;
  // public schedulepast_datesearchsourcecount:any;
  // upcomening genarel call start
  public schedule_datatable: any = [];
  public google_event_warranty_call: boolean = true;
  public worksheet_warrant_call: boolean = false;
  public editflaglead: boolean = false;
  public genarelcall_statusarr: any = [
    { val: 0, name: "Pending" },
    { val: 1, name: "Done" },
    { val: 2, name: "Canceled" },
  ];
  public selected = {
    value: 0,
  };
  public modifyheaderarray_schedule: any = {
    event_title: "Appointment Title",
    start_datetime_unix: "Appointment Date",
    timespan: "Appointment Duration",
    product_name: "Concerning Product",
    username: "Organizer Name",
    useremail: "Organizer email",
    status: "Status",
  };
  public schedule_datatableskip: any = [];
  public sortdata_schedule: any = {
    type: "desc",
    field: "event_title",
    options: ["event_title", "start_datetime_unix", "status"],
  };
  // send basic limit data
  public limitcond_schedule: any = {
    limit: 10,
    skip: 0,
    pagecount: 1,
  };
  public schedule_datatabledetailskip: any = [];
  public libdata_scheduleupcoming: any = {
    basecondition: {
      id: this.activatedRoute.snapshot.params._id,
      status: 0,
    },
    detailview_override: [
      { key: "event_title", val: "Appointment Title" },
      { key: "start_datetime_unix", val: "Appointment Date" },
      { key: "timespan", val: "Appointment Duration" },
      { key: "product_name", val: "Concerning Product" },
      { key: "username", val: "Organizer Name" },
      { key: "useremail", val: "Organizer email" },
    ],
    // table headline
    tableheaders: ["event_title", "start_datetime_unix", "username", "status"],
    custombuttons: [
      {
        label: "Cancel",
        type: "listner",
        id: "btn_cancel",
        cond: "status",
        condval: 0,
        // refreshdata: true,
        classname: "cancelBTN",
        tooltip: "Cancel",
      },
      {
        label: "Reschedule",
        route:
          "/calendar-management/manage-appointment/reschedule-appointments/",
        type: "internallink",
        cond: "status",
        condval: 0,
        param: ["_id", "event_type_val"],
        classname: "rescheduleBTN",
        tooltip: "Reschedule",
      },
    ],
    // hidestatustogglebutton: true,
    hidedeletebutton: true,
    updateendpointmany: "", // update multiple user
    updateendpoint: "", // update status
    deleteendpointmany: "", // delete multiple user
    hidemultipleselectbutton: true, // hide multiple selection button (false)
    hidecounter: null, // hide counter (false)
    hideaction: true, // hide action (false)
    hideviewbutton: false, // (hide view button)
    hideeditbutton: true, // all these button options are optional not mandatory
    hidestatustogglebutton: true,
  };
  public libdata_schedule: any = {
    basecondition: {
      id: this.activatedRoute.snapshot.params._id,
    },
    detailview_override: [
      { key: "event_title", val: "Appointment Title" },
      { key: "start_datetime_unix", val: "Appointment Date" },
      { key: "timespan", val: "Appointment Duration" },
      { key: "product_name", val: "Concerning Product" },
      { key: "username", val: "Organizer Name" },
      { key: "useremail", val: "Organizer email" },
    ],
    // table headline
    tableheaders: ["event_title", "start_datetime_unix", "username", "status"],
    custombuttons: [
      {
        label: "Cancel",
        type: "listner",
        id: "btn_cancel",
        cond: "status",
        condval: 0,
        // refreshdata: true,
        classname: "cancelBTN",
        tooltip: "Cancel",
      },
      {
        label: "Reschedule",
        route:
          "/calendar-management/manage-appointment/reschedule-appointments/",
        type: "internallink",
        cond: "status",
        condval: 0,
        param: ["_id", "event_type_val"],
        classname: "rescheduleBTN",
        tooltip: "Reschedule",
      },
    ],
    // hidestatustogglebutton: true,
    hidedeletebutton: true,
    updateendpointmany: "", // update multiple user
    updateendpoint: "", // update status
    deleteendpointmany: "", // delete multiple user
    hidemultipleselectbutton: true, // hide multiple selection button (false)
    hidecounter: null, // hide counter (false)
    hideaction: false, // hide action (false)
    hideviewbutton: false, // (hide view button)
    hideeditbutton: true, // all these button options are optional not mandatory
    hidestatustogglebutton: true,
  };
  datacollection_schedule = "list-upcoming-booked-events";
  schedule_searchsettings: any = {
    datesearch: [
      {
        startdatelabel: "Appointments After",
        enddatelabel: "Appointments Before",
        submit: "Search",
        field: "start_datetime_unix",
      },
      // {
      //   startdatelabel: 'Booking After', enddatelabel: 'Booking Before',
      //   submit: 'Search', field: 'booking_date'
      // }
    ], // this is use for  Autocomplete search
  };
  public schedule_datesearchsourcecount: any = 0;
  public schedule_datatablecount: any = 0;
  // upcomening genarel call End

  // past genarel call start
  public schedulepast_datatable: any = [];
  public modifyheaderarray_schedulepast: any = {
    event_title: "Appointment Title",
    start_datetime_unix: "Appointment Date",
    timespan: "Appointment Duration",
    product_name: "Concerning Product",
    username: "Organizer Name",
    useremail: "Organizer email",
  };
  public schedulepast_datatableskip: any = [];
  public sortdata_schedulepast: any = {
    type: "desc",
    field: "event_title",
    options: ["event_title", "start_datetime_unix", "username"],
  };
  // send basic limit data
  public limitcond_schedulepast: any = {
    limit: 10,
    skip: 0,
    pagecount: 1,
  };
  public schedulepast_datatabledetailskip: any = [];
  public libdata_schedulepast: any = {
    basecondition: {
      id: this.activatedRoute.snapshot.params._id,
      event_type_val: 4,
    },
    detailview_override: [
      { key: "event_title", val: "Appointment Title" },
      { key: "start_datetime_unix", val: "Appointment Date" },
      { key: "timespan", val: "Appointment Duration" },
      { key: "product_name", val: "Concerning Product" },
      { key: "username", val: "Organizer Name" },
      { key: "useremail", val: "Organizer email" },
    ],
    // table headline
    tableheaders: ["event_title", "start_datetime_unix", "username"],
    custombuttons: [
      // {
      //   label: 'View Appointment',
      //   route: '/contract-manager/contract/view/',
      //   type: 'internallink',
      //   tooltip: ''View Job Ticket',
      //   classname: 'view_jobticketinBTN',
      //   param: ['_id'],
      // },

      {
        label: "Cancel",
        type: "listner",
        id: "btn_cancel",
        cond: "status",
        condval: 0,
        // refreshdata: true,
        classname: "cancelBTN",
        tooltip: "Cancel",
      },
      {
        label: "Reschedule",
        route:
          "/calendar-management/manage-appointment/reschedule-appointments/",
        type: "internallink",
        cond: "status",
        condval: 0,
        param: ["_id", "event_type"],
        classname: "rescheduleBTN",
        tooltip: "Reschedule",
      },
    ],
    // hidestatustogglebutton: true,
    hidedeletebutton: true,
    updateendpointmany: "", // update multiple user
    updateendpoint: "", // update status
    deleteendpointmany: "", // delete multiple user
    hidemultipleselectbutton: true, // hide multiple selection button (false)
    hidecounter: null, // hide counter (false)
    hideaction: false, // hide action (false)
    hideviewbutton: false, // (hide view button)
    hideeditbutton: true, // all these button options are optional not mandatory
    hidestatustogglebutton: true,
  };
  datacollection_schedulepast = "list-booked-events";
  schedulepast_searchsettings: any = {
    datesearch: [
      {
        startdatelabel: "Appointments After",
        enddatelabel: "Appointments Before",
        submit: "Search",
        field: "start_datetime_unix",
      },
      // {
      //   startdatelabel: 'Booking After', enddatelabel: 'Booking Before',
      //   submit: 'Search', field: 'booking_date'
      // }
    ], // this is use for  Autocomplete search
  };
  public schedulepast_datesearchsourcecount: any = 0;
  // past genarel call end
  emailregex: RegExp =
    /^(([^<>()\[\]\\.,;:\s@']+(\.[^<>()\[\]\\.,;:\s@']+)*)|('.+'))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  passwordregex: RegExp = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})/;
  textregex: RegExp = /^[a-zA-Z]*$/;
  public formfieldrefresh: boolean = false;
  public formfieldrefreshdata: any = null;
  temtdata: any = "";
  public fullname: any = "";
  public flAds = "";
  public emaildata: any;
  public totalComplete: any = [];
  // formdata
  public sharelinkclickcounts: any = 0;
  public sharelinkclickcountsavg: any = 0;
  public sharelinkreport: any = "";
  // formfieldrefresh: any = false;
  updatetable: any = false;
  public errormsgflg: boolean = false;
  // formfieldrefreshdata: any = null;
  datasource: any;
  public formdata: any;
  public attachmentFormdata: any;

  public contractDetails: any;
  // public stateVal: any = [];
  public status: any = [
    { val: 1, name: "Active" },
    { val: 0, name: "Inactive" },
  ];
  public discovery_call: any = [
    { val: 1, name: "Active" },
    { val: 0, name: "Inactive" },
  ];
  public distributercommissiondata: any = [];
  public techconsultantdata: any = [];
  public techconsultantdatacount: any = 0;
  public leadListdatatable: any = [];
  public product_list: any = [];
  public user_details: any = {};
  public jobticketdata: any = [];
  public appointmentdata: any = [];
  public productVal: any = [];
  public contractproduct: any = [];
  public product_array: any = [];
  public trainingreportsdata: any = [];
  public trainingreportsdatacount: any = 0;

  public progressSpinner: any = {
    mode: "indeterminate",
    loading: false,
    loading1: false,
    loading4: false,
    bookingStatus: "Sending request",
  };
  public contractreportsdataarray: any = [
    { val: "Drafting", name: "Drafting" },
    { val: "Requested contract Approval", name: "Requested contract Approval" },
    { val: "Approved", name: "Approved" },
    { val: "Contract Pending Signature", name: "Contract Pending Signature" },
    { val: "Pending Approval", name: "Pending Approval" },
    { val: "Send To Lead", name: "Send To Lead" },
    { val: "Contract Approved ", name: "Contract Approved" },
    {
      val: "Lead Request For Contract Modify",
      name: "Lead Request For Contract Modify",
    },
    {
      val: "Contract modification approval asked by Rep",
      name: " Contract modification approval asked by Rep",
    },
  ];
  public searchsettingsfortech: any = {
    datesearch: [
      {
        startdatelabel: "Joined On Start Date",
        enddatelabel: "Joined On End Date",
        submit: "Search",
        field: "createdon_datetime",
        // value: {}
      },
    ],
    selectsearch: [
      {
        label: "Search By Status",
        field: "status",
        values: this.status,
        value: "",
      },
    ],
    textsearch: [
      // { label: 'Search By Name', field: 'fullname_s', value: '' },
      // { label: 'Search By Email', field: 'email_s', value: '' },
      // { label: 'Search By Location', field: 'location_s', value: '' },
    ],
    search: [
      {
        label: "Search By Name",
        field: "fullname_s",
        values: "",
        serversearchdata: { endpoint: "api/exitsinglistname" },
      },
      {
        label: "Search By Email",
        field: "email_s",
        values: "",
        serversearchdata: { endpoint: "api/exitsinglistemail" },
      },
      {
        label: "Search By Location",
        field: "location_ar",
        values: "",
        serversearchdata: { endpoint: "api/exitsinglistlocation" },
      },
    ],
  };
  public searchsettingsforcommission: any = {
    datesearch: [
      {
        startdatelabel: "Joined On Start Date",
        enddatelabel: "Joined On End Date",
        submit: "Search",
        field: "createdon_datetime",
        // value: {}
      },
    ],
    selectsearch: [
      {
        label: "Search By Product",
        field: "product_s",
        values: this.product_array,
        // value: '',
      },
    ],
    textsearch: [
      // { label: 'Search By Name', field: 'fullname_s', value: '' },
      // { label: 'Search By Email', field: 'email_s', value: '' },
      // { label: 'Search By Location', field: 'location_s', value: '' },
    ],
    search: [
      {
        label: "Added By",
        field: "Addedbyfullname",
        values: "",
        serversearchdata: { endpoint: "api/exitsinglistemail" },
      },
    ],
  };
  public contractreportsdatasearchsettings: any = {
    datesearch: [
      {
        startdatelabel: "Joined On Start Date",
        enddatelabel: "Joined On End Date",
        submit: "Search",
        field: "createdon_datetime",
        // value: {}
      },
    ],
    selectsearch: [
      {
        label: "Search By Status",
        field: "status",
        values: this.contractreportsdataarray,
        // value: '',
      },
      {
        label: "Search By Product",
        field: "products_s",
        values: this.product_array,
        // value: '',
      },
    ],
    textsearch: [
      { label: "Search By Lead Name", field: "leadfullname_s", value: "" },
      // { label: 'Search By Email', field: 'email_s', value: '' },
      // { label: 'Search By Location', field: 'location_s', value: '' },
    ],
    search: [
      // {
      //   label: 'Added By',
      //   field: 'Addedbyfullname',
      //   values: '',
      //   serversearchdata: { endpoint: 'api/exitsinglistemail' },
      // },
    ],
  };
  public statusarraytrainingreports: any = [];
  public searchsettingsfortrainingreports: any = {
    datesearch: [
      // {
      //   startdatelabel: 'Joined On Start Date',
      //   enddatelabel: 'Joined On End Date',
      //   submit: 'Search',
      //   field: 'createdon_datetime',
      //   // value: {}
      // },
    ],
    selectsearch: [
      // {
      //   label: 'Search By Status',
      //   field: 'status',
      //   values: this.contractreportsdataarray,
      //   // value: '',
      // },
      // {
      //   label: 'Search By Product',
      //   field: 'products_s',
      //   values: this.product_array,
      //   // value: '',
      // },
    ],
    textsearch: [
      { label: "Search By Techconsultant Name", field: "fullname", value: "" },
      // { label: 'Search By Email', field: 'email_s', value: '' },
      // { label: 'Search By Location', field: 'location_s', value: '' },
    ],
    search: [
      // {
      //   label: 'Added By',
      //   field: 'Addedbyfullname',
      //   values: '',
      //   serversearchdata: { endpoint: 'api/exitsinglistemail' },
      // },
    ],
  };
  public notes: any = {};
  public userdetails: any = [];
  public opennotes1: any = [];
  public userid: any;
  public userFullName: any;
  public personalinfoflag: boolean = false;
  public managenoteflag: boolean = false;
  public worksheetflag: boolean = false;
  public warrenty_call_block_flag: boolean = false;
  public book_appointmentwarrenty_flag: boolean = false;
  public jobticketflag: boolean = false;
  public editflag: boolean = false;
  public notesflag: any = false;
  public appointmentflag: any = false;
  public communitionflag: any = false;
  public presentationflag: any = false;
  public logindetailsflg: boolean = true;
  public changepasswordflag: boolean = false;
  public contractflag: boolean = false;
  public contactflag: boolean = false;
  public attachmentsflag: boolean = false;
  public owecontractflag: boolean = false;
  public activeflag = false;
  public leadlistflag: boolean = false;
  public sharelinkflag: boolean = false;
  public opnenotesflag: boolean = false;
  public trainingactivityflag: boolean = false;
  public paymentflag: boolean = false;
  public contract_reportsflg: boolean = false;
  public training_reportsflg: boolean = false;
  public book_appoinmentflag: boolean = false;
  public book_appoinmentgenarelcallflag: boolean = false;
  public addcontact: boolean = false;
  public addattachmentflag: boolean = false;
  public listattachmentview: boolean = false;
  public dataArray: any = [];
  public condflag: any = {
    managenoteflag: false,
  };
  public shwmrflg: any = "";
  public types: any;
  public stepval: number = 0;
  public step: number = 0;
  public commissionflag: boolean = false;
  public datesearchsourcecount: any = 0;
  public distibutorsearchcount: any = 0;
  public techview: boolean = false;
  public contractreportsdata: any = [];
  public contractreportsdatacount: any = 0;
  public listcontractedit: boolean = false;
  public follow_up_flag: boolean = false;
  public upcoming_events_flag: boolean = false;
  public upcoming_events_appoinments_flag: boolean = false;
  public myroute: any = "";
  public ckeConfig: any = {
    toolbar: [
      {
        name: "editing",
        items: ["Scayt", "Find", "Replace", "SelectAll"],
      },
      {
        name: "clipboard",
        items: [
          "Cut",
          // 'Copy',
          // 'Paste',
          // 'PasteText',
          // 'PasteFromWord',
          // '-',
          // 'Undo',
          // 'Redo'
        ],
      },
      {
        name: "links",
        items: [
          "Link",
          //  'Unlink',
          //  'Anchor'
        ],
      },
      // {
      //     name: 'tools',
      //     items: ['Maximize', 'ShowBlocks', 'Preview', 'Print', 'Templates']
      // },
      { name: "document", items: ["Source"] },
      {
        name: "insert",
        items: [
          "Image",
          // 'Table',
          // 'HorizontalRule',
          // 'SpecialChar',
          // 'Iframe',
          // 'imageExplorer'
        ],
      },
      "/",
      {
        name: "basicstyles",
        items: [
          "Bold",
          "Italic",
          "Underline",
          // 'Strike',
          // 'Subscript',
          // 'Superscript',
          // '-',
          // 'RemoveFormat'
        ],
      },
      // {
      //     name: 'paragraph',
      //     items: [
      //         'NumberedList',
      //         'BulletedList',
      //         '-',
      //         'Outdent',
      //         'Indent',
      //         'CreateDiv',
      //         '-',
      //         'Blockquote'
      //     ]
      // },
      // {
      //     name: 'justify',
      //     items: [
      //         'JustifyLeft',
      //         'JustifyCenter',
      //         'JustifyRight',
      //         'JustifyBlock'
      //     ]
      // },

      {
        name: "styles",
        items: ["Styles", "Format", "FontSize", "-", "TextColor", "BGColor"],
      },
    ],
  };
  public resolvedata: any = [];
  skipMails: any = 0;
  limitMails: any = 5;
  public onbording_dta: any;
  public contract_data: any;
  public onbording_dta1: any;
  public showMails() {
    // console.log('userlistcount')
    const endpointc = "api/getcommunicationemail";
    const data: any = {
      // How many data showing in first page
      id: this.userid,
      skip: this.skipMails,
      limit: this.limitMails,
    };

    this.apiservice
      .getDataWithoutToken(data, endpointc)
      .subscribe((res: any) => {
        console.log("RESSSSS=====>", res);
        this.maillist = res.res; // search count  (How many data there)
        this.maillist_count = res.count; // search count  (How many data there)
      });
  }
  loadmoreflg: any = false;
  loadMoredata() {
    this.skipMails = this.skipMails + 5;
    this.limitMails = this.limitMails + 5;
    const endpointc = "api/getcommunicationemail";
    const data: any = {
      // How many data showing in first page
      id: this.userid,
      skip: this.skipMails,
      limit: this.limitMails,
    };

    this.apiservice
      .getDataWithoutToken(data, endpointc)
      .subscribe((res: any) => {
        if (res.count > 0) {
          for (let val of res.res) {
            this.maillist.push(val);
          }
          this.maillist_count = this.maillist_count + res.count;
        }
      });
    console.log("John hopkins 1=======>", this.maillist);
    console.log("John hopkins 2=======>", this.maillist_count);
  }

  public sendMail() {
    this.sendMailStatus = this.sendMailStatus == false ? true : false;
    this.sendMailButtonText =
      this.sendMailStatus == false
        ? "Show Send Mail Section"
        : "Hide Send Mail Section";
  }

  public statusarray: any = [
    { val: 1, name: "Active" },
    { val: 0, name: "Inactive" },
  ];
  public modifyheaderarray: any = {
    fullname: "Name",
    email: "Email",
    phone: "Contact Number",
    status: "Status",
    createdon_datetime: "Joind On",
  };
  public distributercommissiondatamodifyheaderarray: any = {
    createdon_datetime: "Create Date",
    salesprice: "Sales Price",
    salescommissiondata: "Sales Commission",
    productsname: "Products Name",
    productprice: "Products Price",
    eventcommissiondata: "Event Commission",
    Addedbyfullname: "Added By",
    commissionpercount: "Commission Count",
  };
  public techconsultantdatamodifyheaderarray: any = {
    fullname: "Name",
    email: "Email",
    phone: "Contact Number",
    createdon_datetime: "Joined On",
    status: "Status",
    type: "User Type",
    lastlogin_datetime: "Last Login",
    discovery_call_status: "Discovery Call",
  };
  public trainingreportsdatamodifyheaderarray: any = {
    fullname: "Techconsultant Name",
    traning_name: "Training Name",
    totallesson: "Total Lesson",
    lesson_done: "Lession Done",
    percent: "Percentage",
  };
  public contractreportsdatamodifyheaderarray: any = {
    userfullname: "Tech Name",
    productname: "Product Name",
    leadfullname: "Lead Name",
    currentstatus: "Status",
    createdon_datetime: "Added On",
  };
  // use for table field skip
  public leadListdatatableskip: any = [
    "_id",
    "type",
    "password",
    "created_at",
    "type",
    "city",
    "gallery_image",
    "accesscode",
    "__v",
    "notes",
  ];
  public distributercommissiondataskip: any = [
    "_id",
    "userdata",
    "eventcommissionrole",
    "salescommissionrole",
  ];
  public distributorListdatatabledetailskip: any = [];
  public techListdatatabledetailskip: any = [
    "_id",
    "email_s",
    "name",
    "blogtitle",
    "type",
    "city",
    "__v",
    "fullname",
    "notes",
    "accesscode",
    "location_s",
    "address_search",
    "fullname_s",
    "zip_s",
    "createdon_datetime",
    "fulladdress",
    "notescount",
    "login_data",
    // 'lastlogin_datetime',
    "contactInFo",
    "related_info",
    "firstname_s",
    "status",
  ];
  public leadListdatatabledetailskip: any = [
    "_id",
    "email_s",
    "name",
    "blogtitle",
    "type",
    "city",
    "__v",
    "fullname",
    "notes",
    "accesscode",
    "location_s",
    "address_search",
    "fullname_s",
    "zip_s",
    "createdon_datetime",
    "fulladdress",
    "notescount",
    "login_data",
    "lastlogin_datetime",
    "contactInFo",
    "related_info",
    "firstname_s",
    "status",
  ];
  public techconsultantdataskip: any = [];
  public trainingreportsdataskip: any = [
    "firstname",
    "lastname",
    "user_id",
    "associated_training",
    "parentid",
    "email",
  ];
  public trainingreportsListdatatabledetailskip: any = [];
  public contractreportsdataskip: any = [
    "_id",
    "notes",
    "leadid",
    "userid",
    "tarmsspecification",
    "contract_id",
    "contractemail",
    "useremail",
    "leademail",
    "leadphone",
    "statusarr",
    "userfullname_s",
    "leadfullname_s",
    "products_s",
    "distributor",
    "products",
    "topcontract",
    "buttomcontract",
    "currentstatus",
    "parentid",
    "contractfirstname",
    "contractlastname",
    "contractfullname",
  ];
  public contractreportsdatadetailskip: any = [];
  public limitcondforlead: any = {
    limit: 10,
    skip: 0,
    pagecount: 1,
  };
  public limitcondfortech: any = {
    limit: 10,
    skip: 0,
    pagecount: 1,
  };
  public limitcondfortrainingreports: any = {
    limit: 10,
    skip: 0,
    pagecount: 1,
  };
  public limitcondforcontractreportsdata: any = {
    limit: 10,
    skip: 0,
    pagecount: 1,
  };
  public contractreportsdatasortdata: any = {
    type: "desc",
    field: "createdon_datetime",
    options: [],
  };
  public sortdata: any = {
    type: "desc",
    field: "createdon_datetime",
    options: [
      "fullname",
      "email",
      "phone",
      "lastlogin_datetime",
      "status",
      "createdon_datetime",
    ],
  };
  public distributosortdata: any = {
    type: "asc",
    field: "createdon_datetime",
    options: [],
  };
  public techsortdata: any = {
    type: "asc",
    field: "createdon_datetime",
    options: [
      "fullname",
      "email",
      "createdon_datetime",
      "status",
      "lastlogin_datetime",
      "discovery_call_status",
    ],
  };
  public traningreportssortdata: any = {
    type: "asc",
    field: "createdon_datetime",
    options: [],
  };
  // send basic sort data\
  public distibutorlibdata: any = {
    basecondition: {
      type: "distributor",
      user_id: this.activatedRoute.snapshot.params._id,
    },
    detailview_override: [
      { key: "createdon_datetime", val: "Create date" },
      { key: "salesprice", val: "Sales Price" },
      { key: "salescommissiondata", val: "Sales Commission" },
      { key: "productsname", val: "Products Name" },
      { key: "productprice", val: "Products Price" },
      { key: "eventcommissiondata", val: "Event Commission" },
      { key: "Addedbyfullname", val: "Added By" },
      { key: "commissionpercount", val: "Commission Count" },
    ],
    tableheaders: [
      "Addedbyfullname",
      "productsname",
      "salescommissiondata",
      "eventcommissiondata",
      "createdon_datetime",
    ],
    hidedeletebutton: true,
    updateendpointmany: "",
    updateendpoint: "",
    deleteendpointmany: "",
    hidemultipleselectbutton: null,
    hidecounter: null,
    hideaction: true,
    hideviewbutton: true,
    custombuttons: [],
  };
  public techlibdata: any = {
    basecondition: {
      type: { $in: ["technological-consultant"] },
      distributor: this.activatedRoute.snapshot.params._id,
    },
    detailview_override: [
      { key: "fullname", val: "Full Name" },
      { key: "location", val: "Location" },
      { key: "contactInfo", val: "Contact Info" },
      { key: "status", val: "Status" },
      { key: "lastlogin_datetime", val: "Last Login" },
      { key: "discovery_call_status", val: "Discovery Call" },
    ],
    tableheaders: [
      "fullname",
      "email",
      "phone",
      "createdon_datetime",
      "status",
      "lastlogin_datetime",
      "discovery_call_status",
    ],
    hidedeletebutton: true,
    updateendpointmany: "",
    updateendpoint: "",
    deleteendpointmany: "",
    hidemultipleselectbutton: null,
    hidecounter: null,
    hideaction: false,
    hideviewbutton: true,
    hideeditbutton: true,
    hidestatustogglebutton: true,
    custombuttons: [
      {
        label: "Folder View",
        route: "user-details/",
        type: "listner",
        param: ["type", "_id"],
        tooltip: "Folder View",
        classname: "FolderViewcls",
      },
    ],
  };
  public traininglibdata: any = {
    basecondition: { parentid: this.activatedRoute.snapshot.params._id },
    detailview_override: [],
    tableheaders: [
      "fullname",
      "traning_name",
      "totallesson",
      "lesson_done",
      "percent",
    ],
    hidedeletebutton: true,
    updateendpointmany: "",
    updateendpoint: "",
    deleteendpointmany: "",
    hidemultipleselectbutton: null,
    hidecounter: null,
    hideaction: true,
    hideviewbutton: true,
    hideeditbutton: true,
    hidestatustogglebutton: true,
    custombuttons: [
      // {
      //   label: 'Folder View',
      //   route: 'user-details/',
      //   type: 'listner',
      //   param: ['type', '_id'],
      //   tooltip: 'Folder View',
      //   classname: 'FolderViewcls',
      // },
    ],
  };
  public contractreportsdatalibdata: any = {
    basecondition: { id: this.activatedRoute.snapshot.params._id },
    detailview_override: [
      { key: "contact_name", val: "Contact Name" },
      { key: "createdon_datetime", val: "Create date" },
      { key: "productname", val: "Product Name" },
      { key: "userfullname", val: "Tech Name" },
      { key: "leadfullname", val: "Lead Name" },
      { key: "currentstatus", val: "Status" },
    ],
    tableheaders: [
      "productname",
      "userfullname",
      "leadfullname",
      "currentstatus",
      "createdon_datetime",
    ],
    hidedeletebutton: true,
    updateendpointmany: "",
    updateendpoint: "",
    deleteendpointmany: "",
    hidemultipleselectbutton: null,
    hidecounter: null,
    hideaction: true,
    hideviewbutton: true,
    hideeditbutton: true,
    hidestatustogglebutton: true,
    custombuttons: [
      // {
      //   label: 'Folder View',
      //   route: 'user-details/',
      //   type: 'listner',
      //   param: ['type', '_id'],
      //   tooltip: 'Folder View',
      //   classname: 'FolderViewcls',
      // },
    ],
  };
  public libdata: any = {
    basecondition: { type: "lead", parentid: this.userdetails._id },
    detailview_override: [
      { key: "fullname", val: "Full Name" },
      { key: "location", val: "Location" },
      { key: "contactInfo", val: "Contact Info" },
      { key: "status", val: "Status" },
      // { key: 'login_details', val: 'Login Details' },
    ],
    tableheaders: [
      "fullname",
      "email",
      "phone",
      "createdon_datetime",
      "status",
    ],
    // searchBarFlagVal:true,
    // tableheaders name [key]
    // optional
    hidedeletebutton: true,
    updateendpointmany: "",
    updateendpoint: "",
    deleteendpointmany: "",
    hidemultipleselectbutton: null,
    hidecounter: null,
    hideaction: true,
    hideviewbutton: true,
    // (hide view button)
    // actioncolname: 'Actn',
    // not required,
    custombuttons: [],
  };
  public searchsettings: any = {
    datesearch: [
      {
        startdatelabel: "Appointments After",
        enddatelabel: "Appointments Before",
        submit: "Search",
        field: "start_datetime_unix",
      },
      // {
      //   startdatelabel: 'Booking After', enddatelabel: 'Booking Before',
      //   submit: 'Search', field: 'booking_date'
      // }
    ],
    selectsearch: [
      {
        label: "Search By Event Type",
        field: "event_type_val",
        values: [
          { val: 2, name: "Discovery Call" },
          { val: 5, name: "Warranty Call" },
        ],
      },
    ],
    textsearch: [],
    search: [],
  };
  datacollection: any = "api/userlistview";
  public distributorcollection: any = "api/listmanagecommission";
  public techcollection: any = "api/userlistview";
  public trainingreportscollection: any = "api/lessiondata";
  public contractreportsdatadatacollection: any = "api/distributorcontractdata";
  public stateVal: any = [];
  public techNo: any = 0;
  // contract listing added by madhumita khutia
  public listcontractview: boolean = false;
  public contractListdatatable: any = [];
  public contract_Listdatatableskip: any = [
    "Contact_Notes",
    "Email",
    "First_Name",
    "Last_Name",
    "Mailing_City",
    "Mailing_Country",
    "Mailing_State",
    "Mailing_Street",
    "Mailing_Zip",
    "Other_Country",
    "Other_Phone",
    "Other_State",
    "Other_Zip",
    "Phone",
    "Secondary_Email",
    "isSignee",
    'contact_name_s'
  ];
  public contract_modifyheaderarray: any = {
    contactname: "Name",
    Email: "Email",
    createdon_datetime: "Added On",
    Salutation: "Salutation",
  };
  public contract_searchsettings: any = {
    datesearch: [
      {
        startdatelabel: "Added On Start Date",
        enddatelabel: "Added On End Date",
        submit: "Search",
        field: "createdon_datetime",
        // value: {}
      },
    ],
    selectsearch: [
      // {
      //   label: 'Search By Status',
      //   field: 'status',
      //   values: this.contractreportsdataarray,
      //   // value: '',
      // },
    ],
    textsearch: [
      // { label: "Search By Lead Name", field: "leadname_s", value: "" },
      // { label: "Search By Lead Email", field: "leademail_s", value: "" },
    ],
    search: [
      {
        label: "Search By Contact Name",
        field: 'contact_name_s',
        value: '',
        serversearchdata: { endpoint: 'api11/autocomplete-contactnamefv' },
      },
      {
        label: "Search By Contact Email",
        field: "contact_email_s",
        value: "",
        serversearchdata: { endpoint: 'api11/autocomplete-contactmailfv' }
      },
    ],
  };
  contract_datacollection: any = "api/practiceContracts-list";
  public contract_datesearchsourcecount: any = 0;
  public contract_leadListdatatabledetailskip: any = [
    "_id",
    "leadID",
    "createdon_datetime",
    "leadName",
    "leademail_s",
    "contactname",
    "leadname_s",
    "First_Name",
    "Last_Name",
    "Salutation",
    "contact_name_s",
    "contact_email_s",
    "contactperson"
  ];
  public contract_sortdata: any = {
    type: "desc",
    field: "createdon_datetime",
    options: ["contactname", "Email"],
  };
  public contract_libdata: any = {
    preview_header: {

      header: "Preview Data for Details",
      class: 'preheadercls'
    },
    basecondition: { leadID: this.activatedRoute.snapshot.params._id },
    detailview_override: [
      { key: "contact_name", val: "Contact Name" },
      { key: "isSignee", val: "Decision Maker" },
      { key: "leadEmail", val: "Lead Email" },
      { key: "leadname", val: "Lead Name" },
      { key: "Contact_Notes", val: "Contact Notes" },
      { key: "Mailing_City", val: "Mailing City" },
      { key: "Mailing_Country", val: "Mailing Country" },
      { key: "Mailing_State", val: "Mailing State" },
      { key: "Mailing_Street", val: "Mailing Street" },
      { key: "Mailing_Zip", val: "Mailing Zip" },
      { key: "Other_Country", val: "Other Country" },
      { key: "Other_Phone", val: "Other Phone" },
      { key: "Other_State", val: "Other State" },
      { key: "Other_Zip", val: "Other Zip" },
      { key: "Secondary_Email", val: "Secondary Email" },
    ],
    tableheaders: ["contactname", "Email", "createdon_datetime"],
    // searchBarFlagVal:true,
    // tableheaders name [key]
    // optional
    // deleteendpoint :'api/deletecontactpractice',
    updateendpointmany: "",
    updateendpoint: "",
    deleteendpointmany: "",
    hidemultipleselectbutton: true,
    hidecounter: null,
    hideaction: false,
    hideviewbutton: false,
    hideeditbutton: true,
    hidestatustogglebutton: true,
    hidedeletebutton: false,
    // (hide view button)
    // actioncolname: 'Actn',
    // not required,
    custombuttons: [
      {
        label: "edit",
        type: "listner",
        tooltip: "Edit",
        classname: "editcontract",
      },
    ],
  };
  public contract_limitcond: any = {
    limit: 10,
    skip: 0,
    pagecount: 1,
  };
  deleteendpoint = "api/deletecontactpractice"; // delete single

  // attachment listing added by Madhumita Khutia
  public attachmentListdatatable: any = [];
  public attachment_Listdatatableskip: any = [
    "zoho_updated_on",
    "_id",
    "leadID",
    "parentID",
    "zoho_id",
  ];
  public attachment_modifyheaderarray: any = {
    description: "Description",
    createdon_datetime: "Added On",
  };
  public attachment_searchsettings: any = {
    datesearch: [
      {
        startdatelabel: "Added On Start Date",
        enddatelabel: "Added On End Date",
        submit: "Search",
        field: "createdon_datetime",
        // value: {}
      },
    ],
  };
  attachment_datacollection: any = "api8/practice-attachment-list";
  public attachment_datesearchsourcecount: any = 0;
  public attachment_leadListdatatabledetailskip: any = [
    "createdon_datetime",
    "zoho_updated_on",
    "_id",
    "leadID",
    "parentID",
    "zoho_id",
    "attachment_file",
    "zoho_updated",
  ];
  public attachment_sortdata: any = {
    type: "desc",
    field: "createdon_datetime",
    options: [],
  };
  public attachment_libdata: any = {
    basecondition: { leadID: this.activatedRoute.snapshot.params._id },
    detailview_override: [{ key: "description", val: "Description" }],
    tableheaders: ["description", "createdon_datetime"],
    // searchBarFlagVal:true,
    // tableheaders name [key]
    // optional
    // deleteendpoint :'api/deletecontactpractice',
    updateendpointmany: "",
    updateendpoint: "",
    deleteendpointmany: "",
    hidemultipleselectbutton: true,
    hidecounter: null,
    hideaction: false,
    hideviewbutton: false,
    hideeditbutton: true,
    hidestatustogglebutton: true,
    hidedeletebutton: false,
    // (hide view button)
    // actioncolname: 'Actn',
    // not required,
    custombuttons: [
      {
        label: "edit",
        type: "listner",
        tooltip: "Edit",
        classname: "editcontract",
      },
      {
        label: "Download",
        type: "listner",
        tooltip: "Download",
        classname: "downloadattagement",
      },
    ],
  };
  public attachment_limitcond: any = {
    limit: 10,
    skip: 0,
    pagecount: 1,
  };
  attachment_deleteendpoint = "api8/delete-prachticeattachment";
  // follow up upcoming events start
  public followupdatatable: any = [];
  public followup_datatableflag: boolean = false;
  followup_datesearchsourcecount: any = 0;
  public followup_datatableskip: any = [
    "_id",
    "slotTimeActual",
    "notes",
    "eid",
    "start_datetime_unix",
    "event_type_val",
    "googleevent",
    "event_title_s",
    "eventid",
    "is_google_event",
    "lesson_id",
    "id",
    "attende_email_s",
    "attende_name_s",
    "status",
    "user_id",
    "username_s",
    "product_name",
    "product_name_s",
    "product_id",
    "slot_end_time",
    "training_id",
    "parent_id",
  ];
  public modifyheaderarray_followup: any = {
    event_title: "Appointment Title",
    // event_type: 'Event Type',
    datetimeslot: "follow up time",
    timespan: "Appointment Duration",
    username: "Organizer Name",
    useremail: "Organizer email",
    attende_name: "Lead Name",
    attende_email: "Lead Email",
    attende_type: "Phone Number",
    followup: "No of calls",
    attende_phone: "Phone",
    contact_person: "Contact Person",
    dispositionfollow: "Disposition",
  };
  //
  followup_deleteendpoint: any = "";
  public followup_statusarray: [
    { val: 0; name: "Pending" },
    { val: 1; name: "Done" },
    { val: 2; name: "Canceled" }
  ];
  followup_editroute = "";
  public followup_datatabledetailskip: any = [
    "_id",
    "slotTimeActual",
    "notes",
    "eid",
    "start_datetime_unix",
    "event_type_val",
    "googleevent",
    "event_title_s",
    "eventid",
    "is_google_event",
    "lesson_id",
    "id",
    "attende_email_s",
    "attende_name_s",
    "status",
    "user_id",
    "username_s",
    "product_name",
    "product_name_s",
    "product_id",
    "slot_end_time",
    "training_id",
    "parent_id",
    "markasdoneflag",
    "flag_for_followup",
    "flag_for_show",
    "pendingflag",
    "event_type_val_s",
    "appoinmentStatus",
    "event_type_s",
    "start_time",
    "bookinguser",
    "organizer_email",
    "organizer_name",
    "attende_phone",
    "attende_name",
    "event_type",
    "createdon_datetime",
    "datetimeslot",
    "lid",
    "products",
    "ss",
    "product",
    "timeflag",
    "timenow",
    "unow",
    "datefld",
    "date2",
    "unixtoday",
    "qa",
    "dateDifference",
    "difftype",
    "followups",
    "followup",
    "disposition",
    "dispositionfollow_s",
    "offsetvalue",
    "callcounts",
    "dispo",
    "dispositionlead",
    "contact_person_s",
  ];
  public sortdata_followup: any = {
    type: "asc",
    field: "datetimeslot",
    options: ["attende_name", "attende_phone", "ss", "event_type"],
  };
  followup_datacollection: any = "api8/list-followup-data";
  public followup_limitcond: any = {
    limit: 10,
    skip: 0,
    pagecount: 1,
  };
  public libdata_followup: any = {
    basecondition: {
      // bookinguser: this.cookieservice.get('userid'),
      bookinguser: JSON.parse(this.cookieService.get("userid")),
    },

    detailview_override: [
      { key: "event_title", val: "Event Title" },
      { key: "contact_person", val: "Contact Person" },
      { key: "description", val: "Description" },
      { key: "username", val: "Organiser Name" },
      { key: "useremail", val: "Organiser Email" },
      { key: "dispositionfollow", val: "Disposition" },
      { key: "booking_date", val: "Booked On" },
      { key: "startdate", val: "Appointment Date" },
      { key: "slot", val: "Slot" },
      { key: "timespan", val: "Time span" },
      { key: "timezoneName", val: "Time zone" },
      { key: "product_name", val: "Product Name" },
      { key: "attende_name", val: "Attende Name" },
      { key: "attende_email", val: "Lead Email" },
      { key: "attende_type", val: "Attende Type" },
    ],
    // table headline
    tableheaders: [
      "attende_name",
      "attende_phone",
      "contact_person",
      "datetimeslot",
      "followup",
      "dispositionfollow",
    ],
    customselectbuttons: [
      {
        label: "Update Disposition",
        type: "listner",
        // cond: 'status',
        // condval: 0,
        classname: "disposition_warranty",
        tooltip: "Disposition for Follow up call ",
      },
    ],
    custombuttons: [
      // {
      //   label: 'Edit',
      //   type: 'listner',
      //   cond: 'status',
      //   condval: 0,
      //   id: 'i1',
      //   classname: 'userEdit',
      //   tooltip: 'Reschedule',
      // },
      {
        label: "Folder View",
        // route: '/user-details/',
        type: "listner",
        // param: ['type', '_id'],
        tooltip: "Folder View",
        classname: "FolderView",
      },
      {
        label: "Follow Up Call",
        type: "listner",
        id: "follow_up_call",
        classname: "follow_up_call",
        tooltip: "Follow Up Calls",
        refreshdata: true,
      },
      {
        label: "Disposition",
        type: "listner",
        // cond: 'status',
        // condval: 0,
        classname: "disposition_warranty",
        tooltip: "Disposition for Follow up call ",
        // refreshdata: true,
      },
      {
        label: "View All Disposition",
        type: "listner",
        // cond: 'status',
        // condval: 0,
        classname: "disposition",
        tooltip: "View All Disposition",
        // refreshdata: true,
      },
    ],
    notes: {
      label: "Add Notes",
      addendpoint: "api8/addnoteforfollowup",
      deleteendpoint: "api8/deletenotedataforfollowup",
      listendpoint: "api8/listnotesfor-followupcalls",
      user: this.userservice.userid,
      currentuserfullname:
        this.userservice.firstname + " " + this.userservice.lastname,
      header: "attende_name_s",
      classname: "followupcallnotes",
      tooltip: "Add Notes",
    },
    // hidestatustogglebutton: true,
    hidedeletebutton: true,
    updateendpointmany: "", // update multiple user
    updateendpoint: "", // update status
    deleteendpointmany: "", // delete multiple user
    hidemultipleselectbutton: true, // hide multiple selection button (false)
    hidecounter: null, // hide counter (false)
    hideaction: false, // hide action (false)
    hideviewbutton: false, // (hide view button)
    hideeditbutton: true, // all these button options are optional not mandatory
    hidestatustogglebutton: true,
    hideupdatemany: true,
    hidedeletemany: true,
  };
  // p
  searchsettings_followups: any = {
    textsearch: [
      { label: "Search By  Lead Phone", field: "attende_phone" },
      { label: "Search By  Contact Phone", field: "contact_person_s" },
    ],
    search: [
      {
        label: "Search By Product",
        field: "products",
        values: "",
        serversearchdata: {
          endpoint: "api8/autocomplete-followup-product",
        },
      },
      {
        label: "Search By Disposition",
        field: "dispositionfollow_s",
        values: "",
        serversearchdata: {
          endpoint: "api8/autocompletedispositon",
        },
      },
      {
        label: "Search By Lead Name",
        field: "attende_name_s",
        values: "",
        serversearchdata: {
          endpoint: "api8/autocomplete-followup-attendee",
        },
      },
    ],
    datesearch: [
      {
        startdatelabel: " Start Date",
        enddatelabel: "End Date",
        submit: "Search",
        field: "datetimeslot",
      },
    ],
    // this is use for  select search
    // this is use for  Autocomplete search
  };

  // follow up upcoming events end
  // upcoming appoinments events
  public scheduleupcoming_datatable: any = [];
  scheduleupcoming_datesearchsourcecount: any = 0;
  public scheduleupcoming_statusarr = [
    { val: 0, name: "Pending" },
    { val: 1, name: "Done" },
    { val: 2, name: "Canceled" },
  ];
  constructor(
    public apiservice: ApiService,
    public cookieService: CookieService,
    public activatedRoute: ActivatedRoute,
    public snackBar: MatSnackBar,
    public matDialog: MatDialog,
    public router: Router,
    public userservice: UserserviceService,
    public sanitizer: DomSanitizer,
    public formBuilder: FormBuilder,
    public ObservableService: ObservableService,
    public bottomSheet: MatBottomSheet,
    public nevaccessservice: NavAccessService,
    
  ) {
    this.types = JSON.parse(this.cookieService.get("type"));
    this.userid = JSON.parse(this.cookieService.get("userid"));
    console.log(this.activatedRoute.snapshot.queryParams.flag);

    if (
      typeof this.activatedRoute.snapshot.params.flag == "undefined" ||
      this.activatedRoute.snapshot.params.flag == null ||
      this.activatedRoute.snapshot.params.flag == ""
    ) {
      this.personalinfoflag = true;
      console.log("personalinfoflagpersonalinfoflagpersonalinfoflag");
    } else {
      if (this.activatedRoute.snapshot.params.flag == "contactflag") {
        this.showData("contact");
        // this.contactflag = true;
        console.log("contactflagcontactflagcontactflag");
      }
      if (this.activatedRoute.snapshot.params.flag == "questionnaireflag") {
        this.showData("questionnaire");
      }
      if (this.activatedRoute.snapshot.params.flag == "contractflag") {
        this.showData("contract");
        this.snackBar.open("Now you can continue to send contract", "Dismiss", {
          duration: 6000,
        });

        // this.contactflag = true;
        console.log("contactflagcontactflagcontactflag");
      }
      if (this.activatedRoute.snapshot.params.flag == "contractflag1") {
        this.showData("contract");
        // this.contactflag = true;
        console.log("contactflagcontactflagcontactflag");
      }
      if (this.activatedRoute.snapshot.params.flag == "discoveryblock") {
        this.showData("worksheet");
      }
      if (this.activatedRoute.snapshot.params.flag == "warrantyblock") {
        this.showData("warrenty_call_block");
        // this.contactflag = true;
        console.log("contactflagcontactflagcontactflag");
      }
    }

    this.apiservice.getState().subscribe((res: any) => {
      for (const i in res) {
        if (Object.prototype.hasOwnProperty.call(res, i)) {
          this.stateVal.push({ val: res[i].abbreviation, name: res[i].name });
        }
      }
    });
    let data = {
      secret: this.userservice.secret,
      token: this.userservice.jwttoken,
      source: "",
      condition: {
        // condition for how much data show in one time
        limit: 10,
        skip: 0,
      },
      sort: {
        type: "desc", // data sort by decreasing order
        field: "createdon_datetime", // data sort by first name
      },
      searchcondition: {
        type: { $in: ["technological-consultant"] },
        distributor: this.activatedRoute.snapshot.params._id,
      },
    };
    let endpointc = "api/userlistview-count";
    this.apiservice
      .getDataWithoutToken(data, endpointc)
      .subscribe((res: any) => {
        console.log(res, "technological_consultantfunc**********");
        this.techNo = res.count;
      });
    this.showMails();
  }


  addcontract() {
    // console.warn('addcontract');

    this.addcontact = true;
    this.listcontractview = false;
    this.flag = true;
    this.flag1 = true;
  }
  addattachment() {
    this.addattachmentflag = true;
    this.attachmentflag = false;
    this.listattachmentview = false;
  }
  downloadcontract() {
    if (this.userservice.type == "admin") {
      window.open(
        "https://awsbackend-dev-patient-files-test.s3.amazonaws.com/beto-paredes-contracts-pdf/contract_" +
        this.activatedRoute.snapshot.params._id +
        ".pdf",
        "_blank"
      );
    } else {
      window.open(
        "https://awsbackend-dev-patient-files-test.s3.amazonaws.com/beto-paredes-contracts-pdf/contract_" +
        this.userservice.userid +
        ".pdf",
        "_blank"
      );
    }
  }

  showData(flag) {
    console.log(flag);
    if (flag == "personalinfo" || flag == "follow_up") {
      this.activeflag = false;
    } else {
      this.activeflag = true;
    }
    this.openfrom = false;
    this.commissionflag = false;
    this.managenoteflag = false;
    this.jobticketflag = false;
    this.personalinfoflag = false;
    this.logindetailsflg = false;
    this.editflag = false;
    this.editflaglead = false;
    this.techview = false;
    this.paymentflag = false;
    this.contract_reportsflg = false;
    this.training_reportsflg = false;
    this.book_appoinmentflag = false;
    this.book_appoinmentgenarelcallflag = false;
    this.questionnaireflag = false;
    this.addcontact = false;
    this.follow_up_flag = false;
    this.upcoming_events_flag = false;
    this.upcoming_events_appoinments_flag = false;
    // (this.follow_up_flag = false)
    (this.warrenty_call_block_flag = false),
      (this.book_appointmentwarrenty_flag = false),
      (this.worksheetflag = false),
      (this.appointmentflag = false),
      (this.changepasswordflag = false),
      (this.contractflag = false),
      (this.contactflag = false),
      (this.attachmentsflag = false),
      (this.owecontractflag = false),
      (this.leadlistflag = false),
      (this.sharelinkflag = false),
      (this.communitionflag = false),
      (this.presentationflag = false),
      (this.trainingactivityflag = false),
      (this.book_appoinmentflag = false),
      (this.book_appoinmentgenarelcallflag = false),
      // this.opnenotesflag = false;

      (this.condflag = {
        managenoteflag: false,
      });
    switch (flag) {
      case "worksheet":
        this.worksheetflag = true;
        this.appointmentdiscoverydata = false;
        this.getProductData();
        this.appointmentlist("upcoming");
        this.contractchecking();
        break;
      case "appointments":
        this.appointmentflag = true;
        this.appointmentlist("upcoming");
        break;
      case "notes":
        this.managenoteflag = true;
        // this.opnenotesflag = true;
        this.opennotes();
        break;
      case "jobticket":
        this.jobticketflag = true;
        this.jobticketlist();
        break;
      case "editinfo":
        this.editflag = true;
        this.editlead();
        break;
      case "editinfolead":
        this.editflaglead = true;
        this.progressSpinner.loading = true;
        this.edit_lead_func();
        break;
      case "communition":
        this.communitionflag = true;
        console.log("communition");
        this.Communications();
        break;
      case "presentation":
        this.presentationflag = true;
        this.presentation();
        this.googleevent();
        break;
      case "personalinfo":
        this.personalinfoflag = true;
        this.logindetailsflg = true;
        this.personalinfo();
        break;
      case "changepsw":
        this.changepasswordflag = true;
        this.changepassword();
        break;
      case "leads":
        this.leadlistflag = true;
        this.leadlist();
        break;
      case "contract":
        this.contractflag = true;
        // this.getProductData();
        this.contractProductdata();
        break;
      case "contact":
        this.contactflag = true;
        this.addcontact = false;
        this.listcontractview = false;
        this.flag = true;
        this.initial = false;
        this.contactnorecordfound = false;
        this.getcontractlist();
        // this.listcontractview = true;
        // this.contactdata();
        // this.getProductData();
        this.contactdata();
        break;
      case "attachments":
        this.attachmentsflag = true;
        // this.getProductData();
        this.addAttachment();
        this.attachmentlist();
        break;

      case "contractdownload":
        this.owecontractflag = true;
        this.woncontract();
        break;
      case "sharelink":
        this.sharelinkflag = true;
        this.sharelinklist();
        break;
      case "training":
        // training block 
        this.trainingactivityflag = true;
        this.trainingdata();
        break;
      case "commission":
        this.commissionflag = true;
        this.progressSpinner.loading = true;
        this.commissionfunc();
        break;
      case "payment_settings":
        this.paymentflag = true;
        this.progressSpinner.loading = false;
        break;
      case "technological_consultant":
        this.techview = true;
        this.progressSpinner.loading = true;
        this.technological_consultantfunc();
        break;
      case "contract_reports":
        this.contract_reportsflg = true;
        this.progressSpinner.loading = true;
        this.contractreportsfunc();
        break;
      case "training_reports":
        this.training_reportsflg = true;
        this.progressSpinner.loading = true;
        this.training_reportsfunc();
        break;
      case "bookappoinmentlead":
        // this.book_appoinmentflag = true;
        this.progressSpinner.loading = true;
        this.bookappoinmentlead();
        break;
      case "appoinmentgenarelcall":
        this.book_appoinmentgenarelcallflag = true;
        this.progressSpinner.loading = true;
        this.bookappoinmentgenarelcall("upcoming");
        break;
      case "questionnaire":
        this.progressSpinner.loading4 = true;
        this.openfrom = true;
        this.questioner_func();
        break;
      case "warrenty_call_block":
        this.warrenty_call_block_flag = true;
        this.book_appointmentwarrenty_flag = true;
        this.warrenty_call_block();
        this.book_appointmentwarrenty();
        break;
      case "follow_up":
        this.personalinfoflag = true;
        this.logindetailsflg = true;
        this.followup_func();
        break;
      case "upcoming_events":
        this.upcoming_events_flag = true;
        this.onTabClick("");
        break;
      case "upcoming_events_appoinments":
        this.upcoming_events_appoinments_flag = true;
        this.onTabClickappointments("");
        break;
    }
  }
  // upcoming_events(){

  // }
  public contractcheckingpece: boolean = false;
  public contractcheckingtmflow: boolean = false;
  public contractcheckingbio: boolean = false;
  public contractcheckingrst: boolean = false;

  contractchecking() {
    const data: any = {
      condition: {
        id: this.activatedRoute.snapshot.params._id,
        status: 1,
      },
      secret: this.userservice.secret,
      token: this.userservice.jwttoken,
      limit: 0,
      skip: 0,
    };
    this.apiservice
      .getDataWithoutToken(data, "api/firstcontractrequest")
      .subscribe((res: any) => {
        console.log(res, "google");
        // this.viewcontractflag = false;
        if (res.status == "success") {
          if (res.res[0].contracts.length > 0) {
            for (const key in res.res[0].contracts) {
              if (res.res[0].contracts[key].contractflag == "Pece Contract") {
                this.contractcheckingpece = true;
              }
              if (res.res[0].contracts[key].contractflag == "tmflow_contract") {
                this.contractcheckingtmflow = true;
              }
            }
          }
        }
      });
  }
  public discoverypece: boolean = false;
  public discoverytmflow: boolean = false;
  public discoverybio: boolean = false;
  public discoveryrst: boolean = false;
  googleevent() {
    let endpoint = "api10/check-dicovery-call";
    this.apiservice
      .getDataWithoutToken(
        {
          id: this.activatedRoute.snapshot.params._id,
        },
        endpoint
      )
      .subscribe((resp: any) => {
        console.log(resp);
        if (resp.res.length > 0) {
          for (const key in resp.res) {
            if (
              resp.res[key].product_id == "604aff3691b7c80008ca11a8" &&
              resp.res[key].status != 2
            ) {
              this.discoverypece = true;
            }
            if (
              resp.res[key].product_id == "604a0b6e40962e00088f0d79" &&
              resp.res[key].status != 2
            ) {
              this.discoverytmflow = true;
            }
            if (
              resp.res[key].product_id == "612c883f118c110009f02820" &&
              resp.res[key].status != 2
            ) {
              this.discoverybio = true;
            }
            if (
              resp.res[key].product_id == "612c89242005f400082d5fb1" &&
              resp.res[key].status != 2
            ) {
              this.discoveryrst = true;
            }
          }
        }
      });
  }
  onTabClickappointments(val) {
    console.log(val, "on tab change for upcoming appoinments", val.index);
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
    this.scheduleupcoming_datatable = [];
    if (val.index == 0 || typeof val.index == "undefined") {
      this.progressSpinner.loading = true;
      // this.onTabClickappoinment(val);
      console.log(
        "uuuuuuuuuuuuuuuuuuuuuuuuuuuuuuuuuuuuuuuuuuuuuuuuuuuuuuuuuuuuuuuu"
      );
      // this.followup_datesearchsourcecount = 0;
      // this.followupdatatable = [];
      let data = {
        secret: this.cookieService.get("secret"),
        token: this.cookieService.get("jwtToken"),
        source: "",
        condition: {
          limit: 10,
          skip: 0,
        },
        sort: {
          type: "asc",
          field: "datetimeslot",
        },
        searchcondition: {
          id: this.userdetails._id,
          timeflag: [1, 3],
        },
      };
      console.log("madhumita ");
      console.log(data);
      this.apiservice
        .getDataWithoutToken(data, "api8/list-followup-data")
        .subscribe((res: any) => {
          if (res.status == "success") {
            this.progressSpinner.loading = false;
            this.followupdatatable = res.results.res;
            if (this.followupdatatable.length == 0) {
              this.followup_datatableflag = true;
            } else {
              this.followup_datatableflag = false;
            }
            // sarch count  (How many data there)
          }
        });
      this.apiservice
        .getDataWithoutToken(data, "api8/list-followup-data-count")
        .subscribe((res: any) => {
          if (res.status == "success") {
            this.progressSpinner.loading = false;
            setTimeout(() => {
              console.log(res, "res.count+++++ for past follow up calls");
              this.followup_datesearchsourcecount = res.count;
            }, 1000);

            // sarch count  (How many data there)
          }
        });
      // if (this.searchsettings_followup.selectsearch.value) {

      // }
    }
    if (val.index == 1) {
      this.progressSpinner.loading = true;
      const data: any = {
        condition: {
          skip: 0,
          limit: 5,
        },
        searchcondition: {
          status: 0,
          id: this.activatedRoute.snapshot.params._id,
        },
        sort: {
          type: "desc",
          field: "booking_date",
        },
      };
      this.apiservice
        .customRequest(
          data,
          "list-booked-events",
          environment["api_calender_url"]
        )
        .subscribe((response: any) => {
          if (response.status == "success") {
            this.progressSpinner.loading = false;
            console.log(response, "list-upcoming-booked-events-count");
            this.scheduleupcoming_datatable = response.results.res;
            console.log(
              this.schedule_datatablecount,
              "this.schedule_datatable"
            );
          }
        });
      this.apiservice
        .customRequest(
          data,
          "list-booked-events-count",
          environment["api_calender_url"]
        )
        .subscribe((response: any) => {
          console.log(response, "list-upcoming-booked-events-count");
          this.scheduleupcoming_datesearchsourcecount = response.count;
          console.log(
            this.scheduleupcoming_datesearchsourcecount,
            "this.schedule_datatable"
          );
        });
    }
  }
  onTabClick(val) {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
    console.log(val, "1234");
    console.log(val.index, "123456");
    this.selected.value = val.index;
    let data = {};
    this.followupdatatable = [];
    this.followup_datesearchsourcecount = 0;
    if (val.index == 0 || typeof val.index == "undefined") {
      this.progressSpinner.loading = true;
      // this.onTabClickappoinment(val);
      console.log(
        "uuuuuuuuuuuuuuuuuuuuuuuuuuuuuuuuuuuuuuuuuuuuuuuuuuuuuuuuuuuuuuuu"
      );
      data = {
        secret: this.cookieService.get("secret"),
        token: this.cookieService.get("jwtToken"),
        source: "",
        condition: {
          limit: 10,
          skip: 0,
        },
        sort: {
          type: "asc",
          field: "datetimeslot",
        },
        searchcondition: {
          id: this.userdetails._id,
          timeflag: [1, 3],
        },
      };
      console.log("madhumita ");
      console.log(data);
      this.apiservice
        .getDataWithoutToken(data, "api8/list-followup-data")
        .subscribe((res: any) => {
          if (res.status == "success") {
            this.progressSpinner.loading = false;
            this.followupdatatable = res.results.res;
            if (this.followupdatatable.length == 0) {
              this.followup_datatableflag = true;
            } else {
              this.followup_datatableflag = false;
            }
            // sarch count  (How many data there)
          }
        });
      this.apiservice
        .getDataWithoutToken(data, "api8/list-followup-data-count")
        .subscribe((res: any) => {
          if (res.status == "success") {
            this.progressSpinner.loading = false;
            setTimeout(() => {
              console.log(res.count, "res.count+++++");

              this.followup_datesearchsourcecount = res.count;
            }, 1000);

            // sarch count  (How many data there)
          }
        });
      // if (this.searchsettings_followup.selectsearch.value) {

      // }
    }
    if (val.index == 1) {
      this.progressSpinner.loading = true;
      const data: any = {
        condition: {
          skip: 0,
          limit: 5,
        },
        searchcondition: {
          status: 0,
          id: this.activatedRoute.snapshot.params._id,
        },
        sort: {
          type: "desc",
          field: "booking_date",
        },
      };
      this.apiservice
        .customRequest(
          data,
          "list-upcoming-booked-events",
          environment["api_calender_url"]
        )
        .subscribe((response: any) => {
          console.log(response, "list-upcoming-booked-events-count");
          if (response.status == "success") {
            this.progressSpinner.loading = false;
            this.scheduleupcoming_datatable = response.results.res;
            console.log(
              this.schedule_datatablecount,
              "this.schedule_datatable"
            );
          }
        });
      this.apiservice
        .customRequest(
          data,
          "list-upcoming-booked-events-count",
          environment["api_calender_url"]
        )
        .subscribe((response: any) => {
          console.log(response, "list-upcoming-booked-events-count");
          this.scheduleupcoming_datesearchsourcecount = response.count;
          console.log(
            this.scheduleupcoming_datesearchsourcecount,
            "this.schedule_datatable"
          );
        });
    }
  }
  questioner_func() {
    this.addflag = false;
    this.updateflag = false;
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
    this.progressSpinner.loading = true;
    let data = {
      user_id: this.activatedRoute.snapshot.params._id,
      searchcondition: {
        leadID: this.activatedRoute.snapshot.params._id,
        isSignee: true,
      },
    };
    this.apiservice
      .getDataWithoutToken(data, "api10/contact_questioner_list")
      .subscribe((res: any) => {
        console.log(
          res.result.questioner,
          "5//////////55555555555////////////"
        );

        if (res.result.questioner == 0) {
          this.questionnaireflag = true;
          this.addflag = true;
          this.myinput.product_id = this.product_array;
          // this.myinput.user_details =
          this.myinput.user_details_email = this.userdetails.email;
          this.myinput.user_details_type = this.userdetails.type;
          this.myinput.flag = 1;
          this.myinput.formtype = "add";
          this.progressSpinner.loading4 = false;
        }
        if (res.result.questioner != 0) {
          this.questionnaireflag = true;
          this.updateflag = true;
          this.worksheetflag = false;
          this.myinput.product_id = this.product_array;
          // this.myinput.user_details =
          this.myinput.user_details_email = this.userdetails.email;
          this.myinput.user_details_type = this.userdetails.type;
          this.myinput.flag = 1;
          this.myinput.formtype = "edit";
          this.progressSpinner.loading4 = false;
        }
      });
    // this.questionnaireflag = true;
    // this.progressSpinner.loading = true;
    // console.log(this.userdetails, 'this.userdetails');
    this.myinput = {
      user_details: this.userdetails,
    };
    this.myinput.product_id = this.product_array;
    // this.myinput.user_details =
    this.myinput.user_details_email = this.userdetails.email;
    this.myinput.user_details_type = this.userdetails.type;
    this.myinput.flag = 1;
    this.myinput.formtype = "add";
    this.progressSpinner.loading = false;
  }

  openfroms() {
    this.progressSpinner.loading4 = true;

    this.openfrom = true;
    this.progressSpinner.loading4 = false;
  }
  edit_lead_func() {
    this.myroute = this.router.url;

    this.myInputMessage = "manuallyEditFromFolderView";
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
    setTimeout(() => {
      this.progressSpinner.loading = false;
    }, 2500);
  }
  GetChildDataEdit(val: any) {
    console.log(val, "child data************");
    this.showData("personalinfo");
  }
  gotopresentationlink(val: any) {
    window.open(
      "https://marketing.betoparedes.com/marketing-review/" + val._id,
      "_blank"
    );
  }

  followup_func() {
    // console.log(val, 'val follow up');
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
    let dialogRef = this.matDialog.open(DialogFollowUpComponent, {
      // height: '600px',
      // width: '600px',
      panelClass: "followupmodal",
      data: this.activatedRoute.snapshot.params._id,
    });
    dialogRef.afterClosed().subscribe((result) => {
      // this.cookieService.set("discovery_call", 'false', undefined, "/");
      console.log(result, "follow up result");
      let currentUrl = this.router.url;
      this.router.routeReuseStrategy.shouldReuseRoute = () => false;
      this.router.onSameUrlNavigation = "reload";
      this.router.navigate([currentUrl]);
    });
  }
  training_reportsfunc() {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
    this.trainingreportsdata = null;
    let data = {
      secret: this.userservice.secret,
      token: this.userservice.jwttoken,
      searchcondition: {
        parentid: this.activatedRoute.snapshot.params._id,
      },
      condition: {
        limit: 10,
        skip: 0,
      },
      sort: {
        type: "desc",
        field: "_id",
      },
    };
    let endpoint = "api/lessiondata";
    this.apiservice
      .getDataWithoutToken(data, endpoint)
      .subscribe((res: any) => {
        console.log(res, "training_reportsfunc**********");
        this.trainingreportsdata = res.results.res;
        this.trainingreportsdatacount = this.trainingreportsdata.length;
        this.progressSpinner.loading = false;
      });
  }
  contractreportsfunc() {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
    this.contractreportsdata = null;
    let data = {
      secret: this.userservice.secret,
      token: this.userservice.jwttoken,
      searchcondition: {
        id: this.userdetails._id,
      },
      condition: {
        limit: 10,
        skip: 0,
      },
      sort: {
        type: "desc",
        field: "_id",
      },
    };
    let endpoint = "api/distributorcontractdata";
    let endpointc = "api/distributorcontractdata-count";
    this.apiservice
      .getDataWithoutToken(data, endpoint)
      .subscribe((res: any) => {
        console.log(res, "contractreportsdata**********");
        this.contractreportsdata = res.results.res;
        this.progressSpinner.loading = false;
      });
    this.apiservice
      .getDataWithoutToken(data, endpointc)
      .subscribe((res: any) => {
        console.log(res, "contractreportsdata**********");
        this.contractreportsdatacount = res.count;
      });
  }
  technological_consultantfunc() {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
    this.techconsultantdata = null;
    let data = {
      secret: this.userservice.secret,
      token: this.userservice.jwttoken,
      source: "",
      condition: {
        // condition for how much data show in one time
        limit: 10,
        skip: 0,
      },
      sort: {
        type: "desc", // data sort by decreasing order
        field: "createdon_datetime", // data sort by first name
      },
      searchcondition: {
        type: { $in: ["technological-consultant"] },
        distributor: this.userdetails._id,
      },
    };
    let endpoint = "api/userlistview";
    let endpointc = "api/userlistview-count";
    this.apiservice
      .getDataWithoutToken(data, endpoint)
      .subscribe((res: any) => {
        console.log(res, "technological_consultantfunc**********");
        this.techconsultantdata = res.results.res;
        this.progressSpinner.loading = false;
      });
    this.apiservice
      .getDataWithoutToken(data, endpointc)
      .subscribe((res: any) => {
        console.log(res, "technological_consultantfunc**********");
        this.techconsultantdatacount = res.count;
      });
  }
  commissionLiblistingChange(val: any) {
    console.log(val, "*-+*-+*-+*-+*-+");
  }
  commissionfunc() {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
    this.distributercommissiondata = null;
    let data = {
      condition: {
        limit: 10,
        skip: 0,
      },
      sort: {
        type: "asc",
        field: "createdon_datetime",
      },
      searchcondition: {
        type: this.userdetails.type,
        user_id: this.userdetails._id,
      },
      token: this.cookieService.get("jwtToken"),
      secret: this.cookieService.get("secret"),
    };
    let endpoint = "api/listmanagecommission";
    let endpointc = "api/listmanagecommission-count";
    this.apiservice
      .getDataWithoutToken(data, endpoint)
      .subscribe((res: any) => {
        console.log("commission data**********", res);
        this.distributercommissiondata = res.results.res;
        this.progressSpinner.loading = false;
      });
    this.apiservice
      .getDataWithoutToken(data, endpointc)
      .subscribe((res: any) => {
        console.log("commission data**********", res);
        this.distibutorsearchcount = res.count;
      });
  }
  contract_reportsflgs() {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
    // this.distributercommissiondata = null;
    let data = {
      condition: {
        limit: 10,
        skip: 0,
      },
      sort: {
        type: "asc",
        field: "createdon_datetime",
      },
      searchcondition: {
        id: this.userdetails._id,
      },
      token: this.cookieService.get("jwtToken"),
      secret: this.cookieService.get("secret"),
    };
    let endpoint = "api/distributorcontractdata";
    let endpointc = "api/distributorcontractdata-count";
    this.apiservice
      .getDataWithoutToken(data, endpoint)
      .subscribe((res: any) => {
        console.log("commission data**********", res);
        this.distributercommissiondata = res.results.res;
        this.progressSpinner.loading = false;
      });
  }
  public allcontractdata: any;
  public tmflowcredit: any;
  public tmfloworcredit: boolean = false;
  public iscredit: boolean = false;
  public medigrade: any = 0;
  public biorst: any = 0;
  presentation() {
    this.is_presentation_link_flg = false;
    console.log("+");
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
    this.progressSpinner.loading = true;
    let endpoint = "api/firstcontractrequest";
    let data: any = {
      // condition: {
      // _id: { $in: this.userdetails.productsarray },
      // },
      condition: {
        id: this.activatedRoute.snapshot.params._id,
      },
      token: this.cookieService.get("jwtToken"),
      secret: this.cookieService.get("secret"),
    };
    // if (
    //   this.userdetails.productsarray != undefined &&
    //   this.userdetails.productsarray != null &&
    //   this.userdetails.productsarray != ''
    // ) {
    //   data = {
    //     condition: {
    //       _id: { $in: this.userdetails.productsarray },
    //     },
    //   };
    // } else {
    //   data = {
    //     condition: {
    //       _id: { $in: this.userdetails.products },
    //     },
    //   };
    // }
    this.product_list = [];
    this.apiservice
      .customRequest(data, endpoint, environment["api_url"])
      .subscribe((resp: any) => {
        console.log(resp, "++_+_+_+_+_+");
        this.progressSpinner.loading = false;
        if (resp.status == "success") {
          if (resp.res[0].contracts.length > 0) {
            for (const key in resp.res[0].contracts) {
              if (resp.res[0].contracts[key].contractflag == "credit") {
                this.tmfloworcredit = true;
                this.iscredit = true;
              }
            }
          }
          // let products = resp.results.res;
          // let google_events = [];
          // google_events = resp.result.google_events;
          // let worksheets = [];
          // worksheets = resp.result.worksheets;
          // this.product_list = resp.result.products;

          // if (google_events.length > 0 || worksheets.length > 0) {

          //   if (google_events.length > 0) {
          //     for (let i in this.product_list) {
          //       for (let j in google_events) {
          //         if (this.product_list[i]._id == google_events[j].product_id) {
          //           this.product_list[i].booking_done = true;
          //         }
          //       }
          //     }
          //   }
          //   //console.log(this.product_list, '++++++++')
          // } else {
          // this.product_list = resp.result.products;
          this.product_list = resp.res[0].product_data;
          if (this.product_list.length == 0) {
            this.is_presentation_link_flg = true;
          }
          console.log(this.is_presentation_link_flg, "__________________");
          this.allcontractdata = resp.res[0];
          // }
          for (const key in this.allcontractdata.product_data) {
            if (
              this.allcontractdata.product_data[key]._id ==
              "604aff3691b7c80008ca11a8"
            ) {
              this.medigrade++;
            }
            if (
              this.allcontractdata.product_data[key]._id ==
              "604a0b6e40962e00088f0d79"
            ) {
              this.medigrade++;
            }
            if (
              this.allcontractdata.product_data[key]._id ==
              "612c883f118c110009f02820"
            ) {
              this.biorst++;
            }
            if (
              this.allcontractdata.product_data[key]._id ==
              "612c89242005f400082d5fb1"
            ) {
              this.biorst++;
            }
          }
        } else {
          this.openSnackBar("Something went wrong. Please try again.", null);
        }
      });
  }

  woncontract() {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }
  replyjobticket(val: any) {
    console.log(val, "valllll");
    // this.progressSpinner.loading = true;
    // this.router.navigateByUrl('/jobticket/view-managejobticket' + '/' + val);
    window.open(
      "/jobticket/view-managejobticket" + "/" + val._id + "/" + val.user_id,
      "_blank"
    );
  }

  listenLiblistingChangegenarelcall(event: any) {
    console.log("eventtt", event);
    if (event.custombuttonclick != undefined) {
      if (event.custombuttonclick.btninfo.id === "btn_cancel") {
        const dialogRef = this.matDialog.open(DialogCancelAlert);
        dialogRef.afterClosed().subscribe((result) => {
          // console.log(`Dialog result: ${result}`);
          console.log(result, "testingggggggggggggggggggggggggggggggggggg");
          if (result.flag == true) {
            // google_events
            let note = result.data;
            let google_events_data: any = {
              userid: event.custombuttonclick.data.user_id,
              source: "google_events",
              token: this.cookieService.get("jwt_for_calendar"),
              note: note,
            };
            this.apiservice
              .customRequest(
                google_events_data,
                "get-refresh-token",
                environment["api_calender_url"]
              )
              .subscribe((response: any) => {
                console.log("responseresponseresponse", response);
                let r_token = response.refresh_token;
                // console.log(r_token, 'r_token')
                const data: any = {
                  token: this.cookieService.get("jwt_for_calendar"),
                  _id: event.custombuttonclick.data._id,
                  attende_email: event.custombuttonclick.data.attende_email,
                  attende_name: event.custombuttonclick.data.attende_name,
                  attende_type: event.custombuttonclick.data.attende_type,
                  useremail: event.custombuttonclick.data.useremail,
                  appointment_date: event.custombuttonclick.data.startdate,
                  username: event.custombuttonclick.data.username,
                  slot: event.custombuttonclick.data.slot,
                  event_type: event.custombuttonclick.data.event_type,
                  event_title: event.custombuttonclick.data.event_title,
                  // 'appointment_date': '01/27/2021',
                  refresh_token: r_token,
                  eid: event.custombuttonclick.data.eid,
                  slotTimeActual: event.custombuttonclick.data.slotTimeActual,
                  googleevent: event.custombuttonclick.data.googleevent,
                  note: note,
                };
                // console.log(data, 'data')
                this.apiservice
                  .customRequest(
                    data,
                    "google-calendar-event/delete-booked-event",
                    environment["google_calendar_api"]
                  )
                  .subscribe((response: any) => {
                    // console.log('response', response);
                    setTimeout(() => {
                      this.updatetable = true;
                    }, 10);
                  });

                this.apiservice
                  .customRequest(
                    data,
                    "delete-booked-event",
                    environment["api_calender_url"]
                  )
                  .subscribe((response: any) => {
                    console.log(response, "responsedelete");
                  });
              });
          }
        });
      }
    }
  }

  listenFormFieldChangescontactlist(event: any) {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
    this.progressSpinner.loading = true;
    console.log(event, "eeeeeeeeeeeeeeeeeeee");
    if (
      event.action == "custombuttonclick" &&
      event.custombuttonclick.btninfo.classname == "editcontract"
    ) {
      // this.listcontractedit = true;
      let data = {
        secret: this.userservice.secret,
        token: this.userservice.jwttoken,
        id: event.custombuttonclick.data._id,
        condition: {},
      };
      this.apiservice
        .getDataWithoutToken(data, "api/getonepracticecontact")
        .subscribe((res: any) => {
          console.log(res, "res contact");
          this.flag = true;
          this.flag1 = false;
          this.resolvedata = res.result[0];
          this.addcontact = true;
          this.listcontractview = false;
          this.progressSpinner.loading = false;
          // this.resolvedata = res.resolvedata.result[0]
          this.formdata3 = {
            successmessage: "Contact Edit Successfully Submitted",
            redirectpath:
              "user-details/lead/" + this.activatedRoute.snapshot.params._id,
            submitactive: true, // optional, default true
            submittext: "Save",
            // resettext: 'Reset',
            canceltext: "Cancel",
            apiUrl: this.apiservice.api_url,
            // endpoint: 'api/add-practice-contracts',
            jwttoken: this.cookieService.get("jwtToken"),
            cancelroute:
              "user-details/lead/" + this.activatedRoute.snapshot.params._id,

            fields: [
              {
                label: "Salutation",
                name: "Salutation",
                value: this.resolvedata.Salutation,
                val: this.surname_status,
                type: "select",
                validations: [
                  { rule: "required", message: "Salutation is Required" },
                ],
                prefix: "*",
              },

              {
                label: "First Name",
                name: "First_Name",
                value: this.resolvedata.First_Name,
                // multiple:true,

                type: "text",
                validations: [
                  { rule: "required", message: "First Name is Required" },
                ],
                prefix: "*",
              },
              {
                label: "Last Name",
                name: "Last_Name",
                value: this.resolvedata.Last_Name,
                type: "text",
                validations: [
                  { rule: "required", message: "Last Name is Required" },
                ],
                prefix: "*",
              },
              {
                label: "Phone",
                name: "Phone",
                value: this.resolvedata.Phone,
                type: "number",
                validations: [
                  { rule: "required", message: "Phone is Required" },
                  {
                    rule: "minLength",
                    value: 10,
                    message: "The Phone Number must be 10 digits",
                  },
                ],
                prefix: "*",
              },
              {
                label: "Other Phone",
                name: "Other_Phone",
                value: this.resolvedata.Other_Phone,
                type: "number",
                validations: [
                  // { rule: 'required', message: 'Practice Name is Required' },
                ],
              },

              {
                label: "Email",
                name: "Email",
                value: this.resolvedata.Email,
                val: "",
                type: "text",
                validations: [
                  { rule: "required", message: "Email is Required" },
                ],
                prefix: "*",
              },
              {
                label: "Secondary Email",
                name: "Secondary_Email",
                value: this.resolvedata.Secondary_Email,
                type: "text",
                // validations: [
                //   { rule: 'required', message: 'Fax is Required' },
                // ],
              },
              {
                label: "Fax",
                name: "fax",
                value: this.resolvedata.fax,
                type: "text",
                validations: [
                  // {
                  //   rule: 'maxLength',
                  //   value: 9,
                  //   message: 'Please enter the valid 9 digit EIN Number of ',
                  // },
                ],
              },
              {
                label: "Contact Notes",
                name: "Contact_Notes",
                value: this.resolvedata.Contact_Notes,
                type: "text",
                validations: [
                  // {
                  //   rule: 'maxLength',
                  //   value: 9,
                  //   message: 'Please enter the valid 9 digit EIN Number of ',
                  // },
                ],
              },
              {
                heading: "Address Information",
                name: "statusgroup",
                hint: ",0000",
                type: "group",
                fields: [
                  // {
                  //   label: 'Age1',
                  //   name: 'age1',
                  //   type: 'number',
                  //   hint: 'Age ??',
                  //   validations: [
                  //       // { rule: 'required' },
                  //       // { rule: 'min', value: 5 },
                  //       // { rule: 'max', value: 50, message: 'Age can't be more than 50 ' }
                  //   ]
                  // },
                ],
              },
              {
                label: "Mailing Street",
                name: "Mailing_Street",
                value: this.resolvedata.Mailing_Street,

                type: "text",
                // validations: [
                //   { rule: 'required', message: 'Practice Type is Required' },
                // ],
              },
              {
                label: "Mailing City",
                name: "Mailing_City",
                value: this.resolvedata.Mailing_City,
                // hint:'None',
                // val: this.pecepracticestatus,
                type: "text",
              },
              {
                label: "Mailing State",
                name: "Mailing_State",
                value: this.resolvedata.Mailing_State,

                type: "text",
                // validations: [
                //  g { rule: 'required', message: 'Exchange Rate is Required' },
                // ],
              },
              {
                label: "Mailing Zip",
                name: "Mailing_Zip",
                value: this.resolvedata.Mailing_Zip,
                type: "number",
                // validations: [
                //   { rule: 'required', message: 'Practice EMR is Required' },
                // ],
              },
              {
                label: "Mailing Country",
                name: "Mailing_Country",
                value: this.resolvedata.Mailing_Country,
                type: "text",
                // validations: [
                //   { rule: 'required', message: 'Practice Email is Required' },
                // ],
              },
              {
                label: "Other Street",
                name: "Other_Street",
                value: this.resolvedata.Other_Street,
                type: "text",
                validations: [
                  // { rule: 'required', message: 'Website is Required' },
                ],
              },
              {
                label: "Other City",
                name: "Other_City",
                value: this.resolvedata.Other_City,
                type: "textarea",
                labelPosition: "after",
                // validations: [
                //   { rule: 'required', message: 'Practice Notes is Required' },
                // ],
              },

              {
                label: "Other State",
                name: "Other_State",
                value: this.resolvedata.Other_State,
                type: "text",

                // validations: [
                //   { rule: 'required', message: 'Practice Notes is Required' },
                // ],
              },
              {
                label: "Other Zip",
                name: "Other_Zip",
                value: this.resolvedata.Other_Zip,
                type: "text",
              },
              {
                label: "Other Country",
                name: "Other_Country",
                value: this.resolvedata.Other_Country,
                type: "text",
              },
              {
                label: "Decision Maker",
                name: "isSignee",
                value: this.resolvedata.isSignee,
                type: "checkbox",
              },
              {
                label: "",
                name: "leadID",
                value: this.activatedRoute.snapshot.params._id,
                type: "hidden",
              },
              {
                label: "id",
                name: "id",
                value: event.custombuttonclick.data._id,
                type: "hidden",
              },
            ],
          };
        });
    }
    if (event.action == "search") {
      this.progressSpinner.loading = false;
    }
  }
  listenFormFieldChangesattachmentlist(event: any) {
    console.log(event, "attachment");
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
    // console.log(event, 'eeeeeeeeeeeeeeeeeeee');
    if (
      event.action == "custombuttonclick" &&
      event.custombuttonclick.btninfo.classname == "editcontract"
    ) {
      // this.listcontractedit = true;
      this.progressSpinner.loading = true;

      let data = {
        secret: this.userservice.secret,
        token: this.userservice.jwttoken,
        id: event.custombuttonclick.data._id,
        condition: {},
      };
      this.apiservice
        .getDataWithoutToken(data, "api8/getoneattachment")
        .subscribe((res: any) => {
          // console.log(res, 'res contact');
          this.progressSpinner.loading = false;

          // this.flag = true;
          this.attachmentflag = true;
          this.resolvedata = res.result[0];
          this.addattachmentflag = true;
          this.listattachmentview = false;
          // this.resolvedata = res.resolvedata.result[0]
          this.attachmentFormdata = {
            successmessage: "Attachment Edit Successfully",
            // redirectpath: '',
            submitactive: true,
            submittext: "Save",
            // resettext: null,
            canceltext: "Cancel",
            apiUrl: this.apiservice.api_url,
            endpoint: "api/add-practice-attachment", //
            jwttoken: this.cookieService.get("jwtToken"),
            cancelroute:
              "user-details/lead/" + this.activatedRoute.snapshot.params._id,
            fields: [
              {
                label: "Attachment:",
                name: "attachment_file",
                type: "file",
                prefix: "image-" + Date.now(),
                path: "betoparedes/attachment/",
                baseurl: "betoparedes-dev-attachment",
                bucket: "awsbackend-dev-patient-files-test",
                apiurl:
                  "https://tge24bc2ne.execute-api.us-east-1.amazonaws.com/dev/requestUploadURL",
                apideleteurl:
                  "https://tge24bc2ne.execute-api.us-east-1.amazonaws.com/dev/deletefilefromBucket",
                value: this.resolvedata.attachment_file,
              },
              {
                label: "Description",
                name: "description",
                value: this.resolvedata.description,
                type: "editor",
                validations: [
                  { rule: "required", message: " Template is required" },
                ],
                ckeConfig: this.ckeConfig,
              },
              {
                label: "id",
                name: "leadID",
                type: "hidden",
                value: this.activatedRoute.snapshot.params._id,
              },
              {
                label: "",
                name: "parentID",
                type: "hidden",
                value: this.userservice.userid,
              },
              {
                label: "id",
                name: "id",
                value: event.custombuttonclick.data._id,
                type: "hidden",
              },
            ],
          };
        });
    }
    if (
      event.action == "custombuttonclick" &&
      event.custombuttonclick.btninfo.classname == "downloadattagement"
    ) {
      // console.log('dffffffffffffffffffffffffffffffffffff');
      window.open(event.custombuttonclick.data.image, "_blank");
    }
  }
  listenFormChangePassword(val: any) {
    console.log(val, "val+++++++");
    // return;
    if (val.fieldval == "success") {
      this.showData("personalinfo");
    }
    if (val.field == "formcancel") {
      this.showData("personalinfo");
    }
  }

  listenFormEditFieldChange(val: any) {
    console.log(val, "val+++++++");
    if (val.fieldval == "success") {
      this.showData("personalinfo");
    }
    if (val.field == "formcancel") {
      this.showData("personalinfo");
    }
  }
  addnotes(val: any) {
    console.log(val, "xcdvfgbnhmk,");
    // this.addnotesflag = val._id;
    this.notesflag = true;
    this.nonotesfound = false;
  }
  someMethod(val: any) {
    console.log(val, "++++++");
    this.notes.product = val;
  }
  // product flage

  public producterrormsg = false;

  notesubmit() {
    console.log(this.notes, "hgjhg");
    // return;
    if (
      this.notes == null ||
      this.notes.product == null ||
      this.notes.product == "" ||
      this.notes.product.length == 0
    ) {
      this.producterrormsg = true;
      // return true;
    } else {
      this.producterrormsg = false;
    }

    if (
      this.notes == null ||
      this.notes.note == null ||
      this.notes.note == ""
    ) {
      this.errormsgflg = true;
      // return true;
    } else {
      this.errormsgflg = false;
      if (this.errormsgflg || this.producterrormsg) {
        return true;
      }
      const data: any = {
        id: this.userdetails._id,
        note: this.notes.note,
        product: this.notes.product,
        user: this.userservice.userid,
        token: this.cookieService.get("jwtToken"),
        secret: this.cookieService.get("secret"),
      };
      let endpoint = "api/addnotedata";
      if (this.activatedRoute.snapshot.params.type == "lead") {
        this.progressSpinner.loading = true;
        endpoint = "api/addnotedataforlead";
      }
      this.apiservice
        .getDataWithoutToken(data, endpoint)
        .subscribe((res: any) => {
          console.log(res, "madhumita khutiaaaa");
          if (res.status === "success") {
            const message: any = "Successfully Added";
            this.notesflag = false;
            this.notes.note = "";
            this.progressSpinner.loading = false;
            this.notes = {};
            this.snackBar.open(message, "OK", {
              duration: 2000,
            });
            this.opennotes();
          }
        });
    }
  }
  notescancel() {
    this.notesflag = false;
    this.notes = {};
    this.producterrormsg = false;
    this.errormsgflg = false;
    if (this.opennotes1.length == 0) {
      this.nonotesfound = true;
    }
  }
  bookappoinmentgenarelcall(val: any) {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
    console.log(this.userservice.userid, "bookappoinmentgenarelcall");
    this.norecordfoundfor_ganeral_past_appointments = false;
    this.norecordfoundfor_ganeral_upcoming_appointments = false;
    this.progressSpinner.loading = true;
    const data: any = {
      condition: {
        skip: 0,
        limit: 3,
        id: this.activatedRoute.snapshot.params._id,
        event_type_val: 4,
      },
      sarchconditions: {},
      sort: {
        type: "desc",
        field: "booking_date",
      },
    };
    if (val == "upcoming") {
      this.apiservice
        .customRequest(
          data,
          "list-upcoming-booked-events",
          environment["api_calender_url"]
        )
        .subscribe((response: any) => {
          this.progressSpinner.loading = false;

          // console.log(response, 'list-upcoming-booked-events')
          this.schedule_datatable = response.results.res;
          if (this.schedule_datatable.length == 0) {
            this.norecordfoundfor_ganeral_upcoming_appointments = true;
          }
          this.progressSpinner.loading = false;

          console.log(this.schedule_datatable, "this.schedule_datatable");
        });
      this.schedule_datatablecount = 0;
      this.apiservice
        .customRequest(
          data,
          "list-upcoming-booked-events-count",
          environment["api_calender_url"]
        )
        .subscribe((response: any) => {
          console.log(response, "list-upcoming-booked-events-count");
          this.schedule_datatablecount = response.count;
          console.log(this.schedule_datatablecount, "this.schedule_datatable");
        });
    }

    if (val == "past") {
      this.progressSpinner.loading = true;
      this.apiservice
        .customRequest(
          data,
          "list-booked-events",
          environment["api_calender_url"]
        )
        .subscribe((response: any) => {
          // console.log(response, 'list-booked-events')
          this.progressSpinner.loading = false;
          this.schedulepast_datatable = response.results.res;
          if (this.schedulepast_datatable.length == 0) {
            this.norecordfoundfor_ganeral_past_appointments = true;
          }
          console.log(this.schedule_datatable, "this.schedule_datatable");
        });
      this.apiservice
        .customRequest(
          data,
          "list-booked-events-count",
          environment["api_calender_url"]
        )
        .subscribe((response: any) => {
          console.log(response, "list-booked-events");
          this.schedulepast_datesearchsourcecount = response.count;
          console.log(this.schedule_datatable, "this.schedule_datatable");
        });
      this.progressSpinner.loading = false;
    }
  }
  opennotes() {
    this.nonotesfound = false;
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
    this.progressSpinner.loading = true;

    const data: any = {
      id: this.userdetails._id,
      token: this.cookieService.get("jwtToken"),
      secret: this.cookieService.get("secret"),
    };
    let endpoint = "api/listnotedata";
    if (this.activatedRoute.snapshot.params.type == "lead") {
      endpoint = "api/listnotedataforlead";
    }
    this.apiservice
      .getDataWithoutToken(data, endpoint)
      .subscribe((res: any) => {
        console.log(res.res, "madhumita khutiaaaa");
        this.progressSpinner.loading = false;
        // this.opennotes1.push(res.res)
        this.opennotes1 = res.res;
        if (this.opennotes1.length == 0) {
          this.nonotesfound = true;
        }
        console.log(this.opennotes1, "madhumita khutiaaaa");
      });
  }

  bookappoinmentlead() {
    window.scrollTo({
      top: 0,
      left: 0,
      // behavior: 'smooth',
    });
    this.progressSpinner.loading = false;
    window.open(
      "calendar-management/manage-appointment/genarel_call/book-appointment/" +
      this.activatedRoute.snapshot.params._id +
      "/" +
      this.userdetails.email +
      "/" +
      this.userdetails.type
    );
    // this.router.navigateByUrl(
    //   'calendar-management/manage-appointment/genarel_call/book-appointment?id=' +this.activatedRoute.snapshot.params._id
    // );
  }
  listenLiblistingChangefollowup(event: any) {
    console.log(event, "eventtt");
    // this.followup_datesearchsourcecount = event.res
    if (event.custombuttonclick != undefined) {
      if (event.custombuttonclick.btninfo.id === "btn_cancel") {
        const dialogRef = this.matDialog.open(cancelappoinmentAlert);

        dialogRef.afterClosed().subscribe((result) => {
          // console.log(`Dialog result: ${result}`);
          console.log(result, "testingggggggggggggggggggggggggggggggggggg");
          if (result.flag == true) {
            // google_events
            let note = result.data;
            let google_events_data: any = {
              userid: event.custombuttonclick.data.user_id,
              source: "google_events",
              token: this.userservice.jwttoken,
              note: note,
            };
            this.apiservice
              .customRequest(
                google_events_data,
                "get-refresh-token",
                environment["api_calender_url"]
              )
              .subscribe((response: any) => {
                console.log("responseresponseresponse", response);
                let r_token = response.refresh_token;
                // console.log(r_token, 'r_token')
                const data: any = {
                  token: this.userservice.jwttoken,
                  _id: event.custombuttonclick.data._id,
                  attende_email: event.custombuttonclick.data.attende_email,
                  attende_name: event.custombuttonclick.data.attende_name,
                  attende_type: event.custombuttonclick.data.attende_type,
                  useremail: event.custombuttonclick.data.useremail,
                  appointment_date: event.custombuttonclick.data.startdate,
                  username: event.custombuttonclick.data.username,
                  slot: event.custombuttonclick.data.slot,
                  event_type: event.custombuttonclick.data.event_type,
                  event_title: event.custombuttonclick.data.event_title,
                  // 'appointment_date': '01/27/2021',
                  refresh_token: r_token,
                  eid: event.custombuttonclick.data.eid,
                  slotTimeActual: event.custombuttonclick.data.slotTimeActual,
                  googleevent: event.custombuttonclick.data.googleevent,
                  note: note,
                };
                // console.log(data, 'data')
                this.apiservice
                  .customRequest(
                    data,
                    "google-calendar-event/delete-booked-event",
                    environment["google_calendar_api"]
                  )
                  .subscribe((response: any) => {
                    // console.log('response', response);
                    setTimeout(() => {
                      this.updatetable = true;
                    }, 10);
                  });
                this.apiservice
                  .customRequest(
                    data,
                    "delete-booked-event",
                    environment["api_calender_url"]
                  )
                  .subscribe((response: any) => {
                    console.log(response, "responsedelete");
                  });
              });
          }
        });
      }
      if (event.custombuttonclick != undefined) {
        if (event.custombuttonclick.btninfo.id === "btn_mark_as_done") {
          const dialogRef = this.matDialog.open(DialogmarkasdoneAlert);
          dialogRef.afterClosed().subscribe((result) => {
            const mark_data: any = {
              _id: event.custombuttonclick.data._id,
            };
            if (result === "true") {
              this.apiservice
                .customRequest(
                  mark_data,
                  "update-status-done",
                  environment["api_calender_url"]
                )
                .subscribe((response: any) => {
                  console.log("response", response);
                  // this.configData.updatetable = !this.configData.updatetable;
                  setTimeout(() => {
                    this.updatetable = true;
                  }, 10);
                });
            }
          });
        }
      }

      if (event.custombuttonclick.btninfo.classname === "disposition") {
        console.log(event.custombuttonclick.data, "disposition");
        const dialogRef = this.matDialog.open(Dispositionview, {
          data: event.custombuttonclick.data,
          panelClass: "dispositionview_modal",
        });

        // bottomSheetRef.afterDismissed().subscribe(() => {
        //   // console.log('Bottom sheet has been dismissed.');
        //   this.progressSpinner.loading = true;
        //   setTimeout(() => {
        //     this.updatetable = true;
        //     this.progressSpinner.loading = false;
        //   }, 2000);

        // });
      }

      if (event.custombuttonclick.btninfo.classname === "follow_up_call") {
        console.log(
          event.custombuttonclick.btninfo.classname,
          "follow_up_call"
        );
        const bottomSheetRef = this.bottomSheet.open(bottomsheetfollowup, {
          data: event.custombuttonclick.data,
          disableClose: true,
        });
        this.updatetable = false;
        bottomSheetRef.afterDismissed().subscribe(() => {
          this.progressSpinner.loading = true;
          setTimeout(() => {
            this.updatetable = true;
            this.progressSpinner.loading = false;
          }, 2000);
        });
        this.ObservableService.getlocationsData().subscribe((res) => {
          console.log("getmultilingualData*************", res);
          this.updatetable = false;
          if (res.status == "success") {
            console.log("succccccccccccc");
            setTimeout(() => {
              this.updatetable = true;
              // this.progressSpinner.loading = false;
            }, 2000);
          }
        });
      }

      if (event.custombuttonclick.btninfo.classname == "disposition_warranty") {
        // console.log(event.custombuttonclick.btninfo.classname, 'disposition_warranty')
        const bottomSheetRef = this.bottomSheet.open(bottomsheetstatus, {
          data: event.custombuttonclick.data,
          disableClose: true,
        });

        bottomSheetRef.afterDismissed().subscribe(() => {
          this.updatetable = false;
          console.log("Bottom sheet has been dismissed on follow up.");
          // this.progressSpinner.loading = true;
          setTimeout(() => {
            this.updatetable = true;
            // console.log();

            // this.progressSpinner.loading = false;
          }, 2000);
        });
      }
      // FolderView
      if (event.custombuttonclick.btninfo.classname === "FolderView") {
        console.log(event.custombuttonclick.data, "disposition_warranty");
        window.open(
          "/user-details/lead" + "/" + event.custombuttonclick.data.id,
          "Independent Window"
        );
      }
      // followup call delete
      if (
        event.action == "custombuttonclick" &&
        event.custombuttonclick.btninfo.classname == "deletebut"
      ) {
        //  console.log('');
        let followup_delete_data = {
          secret: this.userservice.secret,
          token: this.userservice.jwttoken,
          condition: {
            id: event.custombuttonclick.data._id,
          },
        };
        this.apiservice
          .getDataWithoutToken(followup_delete_data, "api8/delete-followupdata")
          .subscribe((res: any) => {
            console.log(res, "res res delete follow up");
            // const dialogRef = this.dialog.open(cancelappoinmentAlert);
            if (res.message == "updated successfull") {
              this.snackBar.open("Cancel Appointment  Successfull.", "Ok", {
                duration: 2000,
              });
            }
          });
      }
    }
    if (event.action == "multipleselectoptionclick") {
      console.log(event.action, "disposition_warranty", event.selecteddata);
      let res = event.selecteddata;
      const bottomSheetRef = this.bottomSheet.open(bottomsheetstatus, {
        data: { res, Dispositionflag: true },
        disableClose: true,
      });
      this.updatetable = false;
      bottomSheetRef.afterDismissed().subscribe(() => {
        // console.log('Bottom sheet has been dismissed.');
        // this.progressSpinner.loading = true;
        setTimeout(() => {
          this.updatetable = true;
          this.progressSpinner.loading = false;
        }, 2000);
      });
    }
    // if (event.searchdata.selectsearch[0].value == 1) {
    //   console.log('search@@@@@@@@@@@@@@@@@@@');
    //   // let currenttime = Date.now();
    //   // event.searchcondition = {
    //   //   // datetimeslot
    //   // }
    //   // this.followupdatatable = [];
    //   let data = {
    //     secret: this.userservice.secret,
    //     token: this.userservice.jwttoken,
    //     source: '',
    //     condition: {
    //       limit: 10,
    //       skip: 0,
    //     },
    //     sort: {
    //       type: 'asc',
    //       field: 'datetimeslot',
    //     },
    //     searchcondition: {
    //       bookinguser: this.userservice.userid,
    //     },
    //   };

    //   console.log(data);
    //   this.Apiservice.getDataWithoutToken(
    //     data,
    //     'api8/past-followupcall'
    //   ).subscribe((res: any) => {
    //     console.log(res, 'past');

    //     if (res.status == 'success') {
    //       this.progressSpinner.loading = false;

    //       // this.followupdatatable = null;
    //       setTimeout(() => {
    //         this.followupdatatable = res.results.res;

    //       }, 1000);
    //       // if (this.followupdatatable.length == 0) {
    //       //   this.followup_datatableflag = true;
    //       // } else {
    //       //   this.followup_datatableflag = false;
    //       // }
    //       // // sarch count  (How many data there)
    //     }
    //   });
    //   this.Apiservice.getDataWithoutToken(
    //     data,
    //     'api8/past-followup-count'
    //   ).subscribe((res: any) => {
    //     if (res.status == 'success') {
    //       this.progressSpinner.loading = false;
    //       this.followup_datesearchsourcecount = res.count;
    //       // sarch count  (How many data there)
    //     }
    //   });

    // }
  }
  ngOnInit() {
    this.activatedRoute.data.forEach((res) => {
      console.log(res.resolvedata.results, "++++++++++");
      if (
        res.resolvedata.results.tags != null &&
        typeof res.resolvedata.results.tags != undefined
      ) {
        this.tags_data = res.resolvedata.results.tags[0].tags;
      }
      this.disposition_data =
        res.resolvedata.results.userView[0].dispositionlead;
      this.dispositionfollowup =
        res.resolvedata.results.userView[0].disposition;

      this.user_contract = res.resolvedata.results.contract_data;

      this.google_events_data = res.resolvedata.results.google_events;
      console.log(
        res.resolvedata.results.contract_data,
        "disposition",
        this.google_events_data
      );

      this.user_details = res.resolvedata.results.userView[0];
      this.userdetails = res.resolvedata.results.userView[0];
      if (
        res.resolvedata.results.google &&
        res.resolvedata.results.google != null &&
        typeof res.resolvedata.results.google != "undefined" &&
        res.resolvedata.results.google.length > 0
      ) {
        this.onbording_dta = res.resolvedata.results.google;
        // for (const key of res.resolvedata.results.google) {
        //   if (key.event_type == 'Onboarding Call') {
        //     this.onbording_dta = key.endDateTime;
        //   }
        // }
      }
      if (
        res.resolvedata.results.contractrequests &&
        res.resolvedata.results.contractrequests != null &&
        typeof res.resolvedata.results.contractrequests != "undefined" &&
        res.resolvedata.results.contractrequests.length > 0
      ) {
        this.contract_data = res.resolvedata.results.contractrequests;
      }
      // if (res.resolvedata.results.google.length > 0) {
      //   for (const key of res.resolvedata.results.google) {
      //     if (key.event_type == 'Discovery Call') {
      //       this.onbording_dta1 = key.endDateTime;
      //     }
      //   }
      // }
      if (
        this.activatedRoute.snapshot.queryParams.flag &&
        this.activatedRoute.snapshot.queryParams.flag != null &&
        this.activatedRoute.snapshot.queryParams.flag != "" &&
        this.activatedRoute.snapshot.queryParams.flag == "training"
      ) {
        console.log(
          "lllllllllllllllLLLLLLLLLLLLLL@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@ppppppppppppppppppppp"
        );

        this.showData("training");
      }
      console.log(this.userdetails, "qwertyuioppppppppppppppppppppp");
      // this.progressSpinner.loading = false;
      this.emaildata = this.userdetails.email;
      this.contractDetails = res.resolvedata.results.contract_logs;
      console.log(this.contractDetails, "++++++++++++++++");
      // this.salesmanagerListdatatable = []
      this.alldata = res.resolvedata.results.userView[0];
      this.fullname = this.alldata.firstname + " " + this.alldata.lastname;
      this.flAds =
        this.alldata.address +
        ", " +
        this.alldata.state +
        ", " +
        this.alldata.city +
        ", " +
        this.alldata.zip;
    });
    let productendpointdata = {
      token: this.userservice.jwttoken,
      secret: this.userservice.secret,
    };
    this.apiservice
      .getDataWithoutToken(productendpointdata, "api/productsearch")
      .subscribe((data: any) => {
        // console.log(data,'+++++++++++++++++');
        for (let i = 0; i < data.res.length; i++) {
          let array = {
            val: data.res[i].val,
            name: data.res[i].name,
          };
          this.product_array.push(array);
        }
        // this.product_array = data.res;
        console.log(this.product_array, "+++++++++++++++++++++++++++");
      });
    // this.editlead();
    this.getProductData();
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });

    this.searchForm = this.formBuilder.group({
      start_date: [""],
      end_date: [""],
    });
  }
  public isShowContractLog: any = false;
  public showContractbuttonText = "Show";
  showHideContractDetails() {
    if (this.showContractbuttonText == "Show") {
      this.isShowContractLog = true;
      this.showContractbuttonText = "Hide";
    } else {
      this.isShowContractLog = false;
      this.showContractbuttonText = "Show";
    }
  }
  techLiblistingChange(val: any) {
    console.log(val);
    if (
      val.custombuttonclick != null &&
      val.custombuttonclick.btninfo.label == "Folder View"
    ) {
      window.open(
        `user-details/${val.custombuttonclick.data.type}/${val.custombuttonclick.data._id}`,
        "_blank"
      );
    }
    // if (
    //   val.action == 'customlistenbutton' &&
    //   val.buttondata.name == 'Folder View'
    // ) {

    // }
  }
  trainingreportsLiblistingChange(val: any) { }
  search_by_date_in_sharelink() {
    this.progressSpinner.loading = true;
    console.log(
      "search_by_date_in_sharelink------------>",
      new Date(this.searchForm.value["start_date"]).getTime()
    );
    console.log(
      "search_by_date_in_sharelink------------>",
      new Date(this.searchForm.value["end_date"]).getTime()
    );
    this.apiservice
      .getDataWithoutToken(
        {
          condition: {
            id: this.userdetails._id,
          },
          searchcondition: {
            createdon_datetime: {
              $gte: new Date(this.searchForm.value["start_date"]).getTime(),
              $lte: new Date(this.searchForm.value["end_date"]).getTime(),
            },
          },
          secret: this.userservice.secret,
          token: this.userservice.jwttoken,
        },
        "api/sharelinkreport"
      )
      .subscribe((res: any) => {
        console.log("search data***********>", res);
        this.sharelinkreport = res.results;
        this.progressSpinner.loading = false;
        let apogee = 0,
          medWorldOne = 0,
          pece = 0,
          beto = 0,
          influx = 0,
          uta = 0,
          hps = 0;
        for (let i in res.results.sharelinkclickcounts) {
          this.sharelinkclickcounts +=
            res.results.sharelinkclickcounts[i].sharelinkclickcount;
          if (
            res.results.sharelinkclickcounts[i].products ==
            "602e3966022d8d000841ca46"
          ) {
            apogee += res.results.sharelinkclickcounts[i].sharelinkclickcount;
          }
          if (
            res.results.sharelinkclickcounts[i].products ==
            "5ff567e8bad2240008b33c9f"
          ) {
            medWorldOne +=
              res.results.sharelinkclickcounts[i].sharelinkclickcount;
          }

          if (
            res.results.sharelinkclickcounts[i].products ==
            "604aff3691b7c80008ca11a8"
          ) {
            pece += res.results.sharelinkclickcounts[i].sharelinkclickcount;
          }
          if (
            res.results.sharelinkclickcounts[i].products ==
            "602e39bd15c7ce0008f2456f"
          ) {
            beto += res.results.sharelinkclickcounts[i].sharelinkclickcount;
          }
          if (
            res.results.sharelinkclickcounts[i].products ==
            "604a0b6e40962e00088f0d79"
          ) {
            influx += res.results.sharelinkclickcounts[i].sharelinkclickcount;
          }
          if (
            res.results.sharelinkclickcounts[i].products ==
            "604afd0f4bb55500082eb18d"
          ) {
            uta += res.results.sharelinkclickcounts[i].sharelinkclickcount;
          }
          if (
            res.results.sharelinkclickcounts[i].products ==
            "604afd6a4bb55500082eb18e"
          ) {
            hps += res.results.sharelinkclickcounts[i].sharelinkclickcount;
          }
        }
        this.alldatacount.apogee = apogee;
        this.alldatacount.medWorldOne = medWorldOne;
        this.alldatacount.pece = pece;
        this.alldatacount.beto = beto;
        this.alldatacount.influx = influx;
        this.alldatacount.uta = uta;
        this.alldatacount.hps = hps;

        console.log(
          "++++++++ this.alldatacount.Apogee",
          apogee,
          medWorldOne,
          pece,
          beto,
          influx,
          uta,
          hps
        );
        console.log("++++++++ this.alldatacount.Apogee", this.alldatacount);

        if (
          (this.alldatacount.apogee / this.sharelinkreport.leadcount) * 100 ==
          Infinity
        ) {
          console.log(
            (this.alldatacount.apogee / this.sharelinkreport.leadcount) * 100,
            "++++++"
          );
          this.alldatacountlead.apogee = 0;
        } else {
          if (!(this.alldatacount.apogee / this.sharelinkreport.leadcount)) {
            this.alldatacountlead.apogee = 0;
          } else {
            this.alldatacountlead.apogee =
              (this.alldatacount.apogee / this.sharelinkreport.leadcount) * 100;
          }
        }
        if (
          (this.alldatacount.medWorldOne / this.sharelinkreport.leadcount) *
          100 ==
          Infinity
        ) {
          console.log(
            (this.alldatacount.medWorldOne / this.sharelinkreport.leadcount) *
            100,
            "++++++"
          );
          this.alldatacountlead.medWorldOne = 0;
        } else {
          if (
            !(this.alldatacount.medWorldOne / this.sharelinkreport.leadcount)
          ) {
            this.alldatacountlead.medWorldOne = 0;
          } else {
            this.alldatacountlead.medWorldOne =
              (this.alldatacount.medWorldOne / this.sharelinkreport.leadcount) *
              100;
          }
        }
        if (
          (this.alldatacount.pece / this.sharelinkreport.leadcount) * 100 ==
          Infinity
        ) {
          console.log(
            (this.alldatacount.pece / this.sharelinkreport.leadcount) * 100,
            "++++++"
          );
          this.alldatacountlead.pece = 0;
        } else {
          if (!(this.alldatacount.pece / this.sharelinkreport.leadcount)) {
            this.alldatacountlead.pece = 0;
          } else {
            this.alldatacountlead.pece =
              (this.alldatacount.pece / this.sharelinkreport.leadcount) * 100;
          }
        }
        if (
          (this.alldatacount.beto / this.sharelinkreport.leadcount) * 100 ==
          Infinity
        ) {
          console.log(
            (this.alldatacount.beto / this.sharelinkreport.leadcount) * 100,
            "++++++"
          );
          this.alldatacountlead.beto = 0;
        } else {
          if (!(this.alldatacount.beto / this.sharelinkreport.leadcount)) {
            this.alldatacountlead.beto = 0;
            console.log("beto++++++++++++++++");
          } else {
            this.alldatacountlead.beto =
              (this.alldatacount.beto / this.sharelinkreport.leadcount) * 100;
          }
        }
        if (
          (this.alldatacount.influx / this.sharelinkreport.leadcount) * 100 ==
          Infinity
        ) {
          console.log(
            (this.alldatacount.influx / this.sharelinkreport.leadcount) * 100,
            "++++++"
          );
          this.alldatacountlead.influx = 0;
        } else {
          if (!(this.alldatacount.influx / this.sharelinkreport.leadcount)) {
            this.alldatacountlead.influx = 0;
          } else {
            this.alldatacountlead.influx =
              (this.alldatacount.influx / this.sharelinkreport.leadcount) * 100;
          }
        }
        if (
          (this.alldatacount.uta / this.sharelinkreport.leadcount) * 100 ==
          Infinity
        ) {
          console.log(
            (this.alldatacount.uta / this.sharelinkreport.leadcount) * 100,
            "++++++"
          );
          this.alldatacountlead.uta = 0;
        } else {
          if (!(this.alldatacount.uta / this.sharelinkreport.leadcount)) {
            this.alldatacountlead.uta = 0;
          } else {
            this.alldatacountlead.uta =
              (this.alldatacount.uta / this.sharelinkreport.leadcount) * 100;
          }
        }
        if (
          (this.alldatacount.hps / this.sharelinkreport.leadcount) * 100 ==
          Infinity
        ) {
          console.log(
            (this.alldatacount.hps / this.sharelinkreport.leadcount) * 100,
            "++++++"
          );
          this.alldatacountlead.hps = 0;
        } else {
          if (!(this.alldatacount.hps / this.sharelinkreport.leadcount)) {
            this.alldatacountlead.hps = 0;
            console.log("hps***************");
          } else {
            this.alldatacountlead.hps =
              (this.alldatacount.hps / this.sharelinkreport.leadcount) * 100;
          }
        }
      });
  }
  delete(user, i) {
    console.log("i, user==>",i, user);
    const dialogReference = this.matDialog.open(notedeletemodal, {
      // data: listnervalue.custombuttonclick.data,
      panelClass: "custom-modalbox",
    });

    dialogReference.afterClosed().subscribe((afterCloseclick: any) => {
      console.log("afterCloseclick==>", afterCloseclick);
      if (afterCloseclick == "Yes") {
        console.log("going to delete");
        this.progressSpinner.loading = true;
        let endpoint = "api/deletenotedata";
        if (this.activatedRoute.snapshot.params.type == "lead") {
          endpoint = "api/deletenotedataforlead";
        }
        this.apiservice.getDataWithoutToken(
          {
          id: user._id,
          index: i,
          token: this.cookieService.get("jwtToken"),
          secret: this.cookieService.get("secret"),
          },
          endpoint
        ).subscribe((res: any) => {
          this.opennotes1.splice(i, 1);
          if (this.opennotes1.length == 0) {
            this.nonotesfound = true;
          }
          let message: any = res.message;
          this.progressSpinner.loading = false;
          this.snackBar.open(message, "OK", {
            duration: 2000,
          });
        })
      }
    });

    // this.progressSpinner.loading = true;
    // let endpoint = "api/deletenotedata";
    // if (this.activatedRoute.snapshot.params.type == "lead") {
    //   endpoint = "api/deletenotedataforlead";
    // }
    // this.apiservice
    //   .getDataWithoutToken(
    //     {
    //       id: user._id,
    //       index: i,
    //       token: this.cookieService.get("jwtToken"),
    //       secret: this.cookieService.get("secret"),
    //     },
    //     endpoint
    //   )
    //   .subscribe((res: any) => {
    //     this.opennotes1.splice(i, 1);
    //     if (this.opennotes1.length == 0) {
    //       this.nonotesfound = true;
    //     }
    //     let message: any = res.message;
    //     this.progressSpinner.loading = false;
    //     this.snackBar.open(message, "OK", {
    //       duration: 2000,
    //     });
    //   });
  }
  showmore(flag) {
    if (flag == "call") {
      this.progressSpinner.loading = true;
      console.log(flag);
      let data = {
        secret: this.userservice.secret,
        token: this.userservice.jwttoken,
        condition: {
          limit: ++this.count,
          skip: 0,
          type: "technological-consultant",
          _id: "60082631b219ff0008f5a76a",
        },
      };
      this.apiservice
        .getDataWithoutToken(data, "api/allevents")
        .subscribe((res: any) => {
          this.progressSpinner.loading = false;
          this.onbording_dta = res.count;
        });
    }
    if (flag == "contract") {
      this.progressSpinner.loading1 = true;
      console.log(flag);
      let data = {
        secret: this.userservice.secret,
        token: this.userservice.jwttoken,
        condition: {
          limit: ++this.count1,
          skip: 0,
          type: "technological-consultant",
          _id: "60082631b219ff0008f5a76a",
        },
      };
      this.apiservice
        .getDataWithoutToken(data, "api/allcontracts")
        .subscribe((res: any) => {
          this.progressSpinner.loading1 = false;
          this.contract_data = res.count;
        });
    }
  }

  addtags() {
    const dialogRef = this.matDialog.open(AddTagsDialog);

    dialogRef.afterClosed().subscribe((result) => {
      let tagsdata = [];
      if (typeof result != "undefined" && result != "") {
        console.log(result);
        tagsdata.push({
          satatus: result,
          updated_datetime: Math.round(new Date().getTime()),
        });
        const endpoint = "api/addtagsforlead";
        const data: any = {
          data: {
            id: this.userdetails._id,
            tags: tagsdata,
            email: this.userdetails.email,
          },
          secret: this.userservice.secret,
          token: this.userservice.jwttoken,
        };
        this.apiservice
          .getDataWithoutToken(data, endpoint)
          .subscribe((res: any) => {
            console.log(res);
            const endpoint = "api/getoneleadfolderview";
            const data: any = {
              secret: this.userservice.secret,
              token: this.userservice.jwttoken,
              condition: {
                type: "lead",
                _id: this.userdetails._id,
              },
            };
            this.apiservice
              .getDataWithoutToken(data, endpoint)
              .subscribe((res: any) => {
                this.tags_data = res.results.tags[0].tags;
              });
          });
      }
    });
  }

  Communications() {
    // console.log(product_data, '++++++');
    console.log(this.userdetails.email, "+++++++++++++++");
    // console.log(this.product_list,'jbhhhjhjhjhjjjjjjjjjjj')
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });

    console.log(this.product_list, "communication");
    this.productVal = [];
    for (let key in this.product_list) {
      this.productVal.push({
        val: this.product_list[key]._id,
        name: this.product_list[key].productname,
      });
      // console.log(this.productVal, 'communication_productVal');
    }

    // this.progressSpinner.loading = true;
    // const endpoint = 'api/userlistview';
    // const data: any = {
    //   secret: this.userservice.secret,
    //   token: this.userservice.jwttoken,
    //   source: '',
    //   condition: {  // condition for how much data show in one time
    //     limit: 10,
    //     skip: 0,
    //   },
    //   sort: {
    //     type: 'desc',  // data sort by decreasing order
    //     field: 'createdon_datetime',  // data sort by createdon_datetime
    //   },
    //   searchcondition: { _id: this.userdetails._id },
    // };
    // this.apiservice.getDataWithoutToken(data, endpoint).subscribe((res: any) => {
    //   if (res.status === 'success') {
    //     // let data_list = res.results.res;
    //     this.progressSpinner.loading = false;
    //     // for (let i in data_list) {
    //     //   this.dataArray.push({ val: data_list[i].products, name: data_list[i].productname })
    //     //   console.log(this.dataArray, 'dataarray')
    //     // }
    //   }
    // })
    this.formdata = {
      // from start
      successmessage: "Added Successfully !!", // success message
      submittext: "Send",
      apiUrl: this.apiservice.api_url,
      // endpoint: '',
      resettext: "Reset",
      canceltext: "Cancel",
      jwttoken: this.apiservice.jwttoken,
      // cancelroute: '', // use for redirect after cancel the form
      fields: [
        {
          label: "Send To",
          name: "sent_to",
          value: this.emaildata,
          type: "text",
          disabled: true,
          // multiple: true,
          validations: [{ rule: "required", message: " Send to is required" }],
        },
        {
          label: "Subject",
          name: "subject",
          value: "",
          type: "text",
          validations: [{ rule: "required", message: " Subject is required" }],
        },
        {
          label: "Description",
          name: "template",
          value: "",
          type: "editor",
          validations: [{ rule: "required", message: " Template is required" }],
          ckeConfig: this.ckeConfig,
        },
        {
          label: "Products",
          name: "productsarray",
          val: this.productVal,
          type: "select",
          multiple: true,
          // validations: [
          //   { rule: 'required', message: 'Products  is Required' },
          // ],
        },
        {
          label: "Priority",
          name: "priority",
          value: 1,
          type: "hidden",
          validations: [],
        },
      ],
    };
    // this.listenFormFieldChange(this.dataArray)
  }

  listenFormFieldChange(val: any) {
    console.log(val, "++=++");
    // return;
    if (val.field == "fromsubmit" && val.fieldval == "success") {
      this.progressSpinner.loading = true;

      //   this.data.flag = 'submit';
      //   this.data.val = val;
      val.fromval.sent_to = this.emaildata;
      val.fromval.user_name =
        this.userservice.firstname + " " + this.userservice.lastname;

      let form_data = {
        email_data: val.fromval,
        user_id: this.userdetails._id,
        added_by: this.userservice.userid,
        added_by_email: this.userservice.useremail,
      };
      // console.log(this.formdata.fields[0].value = '', '+++++');
      // this.formfieldrefresh = true;
      // return;
      this.apiservice
        .customRequest(
          form_data,
          "api/send-communication-email",
          environment["api_url"]
        )
        .subscribe((response: any) => {
          console.log(response, "success++++");
          if (response == "success") {
            this.progressSpinner.loading = false;
            this.openSnackBar("Email Send Successfully.", null);
            console.log(this.formdata.fields, "+++++");
            // this.formdata.reset();
          } else {
            this.openSnackBar("Something went wrong. Please try again.", null);
          }
        });
    }
  }
  sendmail(val: any) {
    console.log(
      "sendmail ++++++++++++++",
      val,
      this.userservice.userid,
      this.activatedRoute.snapshot.params._id
    );
    console.log("sendmail ++++++email++++++++", this.emaildata);
    console.log(
      "sendmail ++++++activateroute++++++++",
      this.activatedRoute.snapshot.params._id
    );
    console.log(this.userservice.userid);

    let form_data = {
      email_data: {
        subject: "Presentation Links",
        template: "",
        productsarray: [val],
        presentationLink:
          "https://marketing.betoparedes.com/marketing-review-new/" +
          val +
          "/" +
          this.userservice.userid +
          "/" +
          this.activatedRoute.snapshot.params._id,
        priority: 1,
        sent_to: this.emaildata,
        user_name: this.userservice.fullname,
      },
      user_id: this.userservice.userid,
      leadid: this.activatedRoute.snapshot.params._id,
      token: this.userservice.jwttoken,
      secret: this.userservice.secret,
    };

    this.apiservice
      .customRequest(
        form_data,
        "api/presentationlinkemail",
        environment["api_url"]
      )
      .subscribe((response: any) => {
        if (response == "success") {
          this.progressSpinner.loading = false;
          this.openSnackBar("Email Send Successfully.", null);

          // this.dialogRef.close(this.data);
        } else {
          this.openSnackBar("Something went wrong. Please try again.", null);
        }
      });
  }
  sendmail1(val: any) {
    const data: any = {
      condition: {
        id: this.activatedRoute.snapshot.params._id,
        status: 1,
      },
      secret: this.userservice.secret,
      token: this.userservice.jwttoken,
      limit: 0,
      skip: 0,
    };
    this.apiservice
      .getDataWithoutToken(data, "api/firstcontractrequest")
      .subscribe((res: any) => {
        // console.warn(res);

        let right_event = false;
        for (const key in res.res[0].googledata) {
          this.userdetails.products.forEach((element) => {
            if (
              res.res[0].googledata[key].product_id == element &&
              res.res[0].googledata[key].event_type == 2
            ) {
              right_event = true;
            }
          });
        }
        console.warn(right_event);

        if (
          res.res[0].googledata.length == 0 ||
          res.res[0].singnee.length == 0 ||
          !right_event
        ) {
          const dialogRef = this.matDialog.open(viewcontactssing, {
            data: {
              data_event: res.res[0].googledata,
              data_sing: res.res[0].singnee,
              flag: right_event,
              view: "presentation_links",
            },
            panelClass: "worksheet-modal",
          });

          dialogRef.afterClosed().subscribe((result) => {
            console.log(result);

            if (result && result.flag != null && result.flag == "signee") {
              this.showData("contact");

              this.addcontact = true;
              console.log(result, "addcontact", this.addcontact);

              this.listcontractview = false;
              this.flag = false;
            }
            if (result && result.flag != null && result.flag == "Discovery") {
              this.showData("worksheet");
            }
          });
        } else {
          let form_data = {
            email_data: {
              subject: "Presentation Links",
              template: "",
              productsarray: [val],
              presentationLink:
                this.apiservice.domain_url +
                "lead/credit/" +
                this.activatedRoute.snapshot.params._id +
                "/" +
                val +
                "/" +
                this.userdetails.singeedata +
                "?request=true&addedby=" +
                this.userservice.userid +
                "&addedbyname=" +
                this.userservice.fullname +
                "&type=" +
                this.userservice.type,
              priority: 1,
              sent_to: this.emaildata,
              user_name: this.userservice.fullname,
            },
            user_id: this.userservice.userid,
            leadid: this.activatedRoute.snapshot.params._id,
            token: this.userservice.jwttoken,
            secret: this.userservice.secret,
          };

          this.apiservice
            .customRequest(
              form_data,
              "api/presentationlinkemail",
              environment["api_url"]
            )
            .subscribe((response: any) => {
              if (response == "success") {
                this.progressSpinner.loading = false;
                this.openSnackBar("Email Send Successfully.", null);

                // this.dialogRef.close(this.data);
              } else {
                this.openSnackBar(
                  "Something went wrong. Please try again.",
                  null
                );
              }
            });
        }
      });
  }
  copyText(val: string) {
    let data =
      "https://marketing.betoparedes.com/marketing-review-new/" +
      this.userservice.userid +
      "/" +
      this.activatedRoute.snapshot.params._id;
    if (val == "peceonly") {
      data =
        "https://marketing.betoparedes.com/marketing-review-new/" +
        this.userservice.userid +
        "/" +
        this.activatedRoute.snapshot.params._id +
        "?pid1=604aff3691b7c80008ca11a8";
    }
    if (val == "medigrade") {
      data =
        "https://marketing.betoparedes.com/marketing-review-new/" +
        this.userservice.userid +
        "/" +
        this.activatedRoute.snapshot.params._id +
        "?pid1=604aff3691b7c80008ca11a8&pid2=604a0b6e40962e00088f0d79";
    }
    if (val == "biorst") {
      data =
        "https://marketing.betoparedes.com/marketing-review-new/" +
        this.userservice.userid +
        "/" +
        this.activatedRoute.snapshot.params._id +
        "?pid1=612c883f118c110009f02820&pid2=612c89242005f400082d5fb1";
    }
    const selBox = document.createElement("textarea");
    selBox.style.position = "fixed";
    selBox.style.left = "0";
    selBox.style.top = "0";
    selBox.style.opacity = "0";
    selBox.value = data;
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand("copy");
    document.body.removeChild(selBox);
  }

  copyTextRedirect(val: string) {
    let data =
      "https://marketing.betoparedes.com/marketing-review-new/" +
      this.userservice.userid +
      "/" +
      this.activatedRoute.snapshot.params._id;
    if (val == "peceonly") {
      data =
        "https://marketing.betoparedes.com/marketing-review-new/" +
        this.userservice.userid +
        "/" +
        this.activatedRoute.snapshot.params._id +
        "?pid1=604aff3691b7c80008ca11a8";
    }
    if (val == "medigrade") {
      data =
        "https://marketing.betoparedes.com/marketing-review-new/" +
        this.userservice.userid +
        "/" +
        this.activatedRoute.snapshot.params._id +
        "?pid1=604aff3691b7c80008ca11a8&pid2=604a0b6e40962e00088f0d79";
    }
    if (val == "biorst") {
      data =
        "https://marketing.betoparedes.com/marketing-review-new/" +
        this.userservice.userid +
        "/" +
        this.activatedRoute.snapshot.params._id +
        "?pid1=612c883f118c110009f02820&pid2=612c89242005f400082d5fb1";
    }
    window.open(data, "_blank");
  }

  copyText1(val: string, event, sing, p_id) {
    console.log(this.allcontractdata);
    let discoveryflag = false;
    let singeeflag = false;
    let right_event = false;
    if (this.allcontractdata.googledata.length > 0) {
      for (const key in this.allcontractdata.googledata) {
        console.log(this.allcontractdata.googledata[key].event_type);

        if (this.allcontractdata.googledata[key].event_type == 2) {
          console.log("kkkkkkkkkkkkkkkkkkk");
          console.log(this.allcontractdata.googledata[key].event_type);
          discoveryflag = true;
        }
        if (this.allcontractdata.googledata[key].product_id == p_id) {
          right_event = true;
        }
      }
    }
    if (
      this.allcontractdata.singnee.length > 0 &&
      this.allcontractdata.singnee[0].isSignee == true
    ) {
      singeeflag = true;
    }
    if (discoveryflag && singeeflag) {
      let data =
        this.apiservice.domain_url +
        "lead/credit/" +
        this.activatedRoute.snapshot.params._id +
        "/" +
        val +
        "/" +
        this.userdetails.singeedata +
        "?request=true&addedby=" +
        this.userservice.userid +
        "&addedbyname=" +
        this.userservice.fullname +
        "&type=" +
        this.userservice.type;
      const selBox = document.createElement("textarea");
      selBox.style.position = "fixed";
      selBox.style.left = "0";
      selBox.style.top = "0";
      selBox.style.opacity = "0";
      selBox.value = data;
      document.body.appendChild(selBox);
      selBox.focus();
      selBox.select();
      document.execCommand("copy");
      document.body.removeChild(selBox);
      this.openDialog();
    } else {
      const dialogRef = this.matDialog.open(viewcontactssing, {
        data: {
          data_event: event,
          data_sing: sing,
          flag: right_event,
        },
        panelClass: "worksheet-modal",
      });

      dialogRef.afterClosed().subscribe((result) => {
        console.log(result);

        if (result && result.flag != null && result.flag == "signee") {
          this.showData("contact");

          this.addcontact = true;
          console.log(result, "addcontact", this.addcontact);

          this.listcontractview = false;
          this.flag = false;
        }
        if (result && result.flag != null && result.flag == "Discovery") {
          this.showData("worksheet");
        }
      });
    }
  }
  gotopresentationlink1(val: any) {
    window.open(
      this.apiservice.domain_url +
      "lead/credit/" +
      this.activatedRoute.snapshot.params._id +
      "/" +
      val +
      "/" +
      this.userdetails.singeedata +
      "?flag=request",
      "_blank"
    );
  }
  openDialog() {
    const dialogRef = this.matDialog.open(clipboardFolderview, {
      // width: '250px',
      data: "",
      panelClass: "clipboard",
    });

    dialogRef.afterClosed().subscribe((result) => {
      //  // console.log('The dialog was closed');
      // return
    });
  }



  openContract() {
    const dialogRef = this.matDialog.open(snackbarmodalFolderview, {
      // width: '250px',
      data: "",
      panelClass: "clipboard",
    });

    dialogRef.afterClosed().subscribe((result) => {
      //  // console.log('The dialog was closed');
      // return
    });
  }




  personalinfo() {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
    this.progressSpinner.loading = true;
    const data: any = {
      // How many data showing in first page
      secret: this.userservice.secret,
      token: this.userservice.jwttoken,
      condition: {
        id: "_id",
        type: this.activatedRoute.snapshot.params.type,
        _id: this.activatedRoute.snapshot.params._id,
      },
    };

    let endpoint = "api/getoneuserfolderview";
    if (this.activatedRoute.snapshot.params.type == "lead") {
      endpoint = "api/getoneleadfolderview";
    }
    this.apiservice
      .getDataWithoutToken(data, endpoint)
      .subscribe((res: any) => {
        console.log(res, "getoneleadfolderview");
        this.userdetails = res.results.userView[0];
        this.progressSpinner.loading = false;
      });
  }

  leadlist() {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
    this.progressSpinner.loading = true;
    const endpointc = "api/leadlistview-count";
    const endpoint = "api/leadlistview";
    const data: any = {
      // How many data showing in first page
      secret: this.userservice.secret,
      token: this.userservice.jwttoken,
      source: "",
      condition: {
        // condition for how much data show in one time
        limit: 10,
        skip: 0,
      },
      sort: {
        type: "desc", // data sort by decreasing order
        field: "createdon_datetime", // data sort by first name
      },
      searchcondition: { type: "lead", parentid: this.userdetails._id },
    };
    this.apiservice
      .getDataWithoutToken(data, endpoint)
      .subscribe((res: any) => {
        console.log(res, "leadlist");
        this.leadListdatatable = res.results.res;
        console.log(this.leadListdatatable, "this.leadListdatatable");
        this.progressSpinner.loading = false;
      });
    this.datesearchsourcecount = 0;
    this.apiservice
      .getDataWithoutToken(data, endpointc)
      .subscribe((res: any) => {
        // console.log('+++++++++++__+_+_+_+', res);
        this.datesearchsourcecount = res.count; // sarch count  (How many data there)
        console.log(res.count, "qqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqq");
      });
  }
  public alldatacount: any = {};
  public alldatacountlead: any = {};
  sharelinklist() {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
    this.progressSpinner.loading = true;
    const data: any = {
      secret: this.userservice.secret,
      token: this.userservice.jwttoken,
      condition: {
        // condition for how much data show in one time
        id: this.userdetails._id,
      },
    };
    this.apiservice
      .getDataWithoutToken(data, "api/sharelinkreport")
      .subscribe((res: any) => {
        console.log(res, "sharelinkreport");
        this.sharelinkreport = res.results;
        this.progressSpinner.loading = false;
        let apogee = 0,
          medWorldOne = 0,
          pece = 0,
          beto = 0,
          influx = 0,
          uta = 0,
          hps = 0;
        for (let i in res.results.sharelinkclickcounts) {
          this.sharelinkclickcounts +=
            res.results.sharelinkclickcounts[i].sharelinkclickcount;
          if (
            res.results.sharelinkclickcounts[i].products ==
            "602e3966022d8d000841ca46"
          ) {
            apogee += res.results.sharelinkclickcounts[i].sharelinkclickcount;
          }
          if (
            res.results.sharelinkclickcounts[i].products ==
            "5ff567e8bad2240008b33c9f"
          ) {
            medWorldOne +=
              res.results.sharelinkclickcounts[i].sharelinkclickcount;
          }

          if (
            res.results.sharelinkclickcounts[i].products ==
            "604aff3691b7c80008ca11a8"
          ) {
            pece += res.results.sharelinkclickcounts[i].sharelinkclickcount;
          }
          if (
            res.results.sharelinkclickcounts[i].products ==
            "602e39bd15c7ce0008f2456f"
          ) {
            beto += res.results.sharelinkclickcounts[i].sharelinkclickcount;
          }
          if (
            res.results.sharelinkclickcounts[i].products ==
            "604a0b6e40962e00088f0d79"
          ) {
            influx += res.results.sharelinkclickcounts[i].sharelinkclickcount;
          }
          if (
            res.results.sharelinkclickcounts[i].products ==
            "604afd0f4bb55500082eb18d"
          ) {
            uta += res.results.sharelinkclickcounts[i].sharelinkclickcount;
          }
          if (
            res.results.sharelinkclickcounts[i].products ==
            "604afd6a4bb55500082eb18e"
          ) {
            hps += res.results.sharelinkclickcounts[i].sharelinkclickcount;
          }
        }
        this.alldatacount.apogee = apogee;
        this.alldatacount.medWorldOne = medWorldOne;
        this.alldatacount.pece = pece;
        this.alldatacount.beto = beto;
        this.alldatacount.influx = influx;
        this.alldatacount.uta = uta;
        this.alldatacount.hps = hps;

        console.log(
          "++++++++ this.alldatacount.Apogee",
          apogee,
          medWorldOne,
          pece,
          beto,
          influx,
          uta,
          hps
        );
        console.log("++++++++ this.alldatacount.Apogee", this.alldatacount);

        if (
          (this.alldatacount.apogee / this.sharelinkreport.leadcount) * 100 ==
          Infinity
        ) {
          console.log(
            (this.alldatacount.apogee / this.sharelinkreport.leadcount) * 100,
            "++++++"
          );
          this.alldatacountlead.apogee = 0;
        } else {
          if (!(this.alldatacount.apogee / this.sharelinkreport.leadcount)) {
            this.alldatacountlead.apogee = 0;
          } else {
            this.alldatacountlead.apogee =
              (this.alldatacount.apogee / this.sharelinkreport.leadcount) * 100;
          }
        }
        if (
          (this.alldatacount.medWorldOne / this.sharelinkreport.leadcount) *
          100 ==
          Infinity
        ) {
          console.log(
            (this.alldatacount.medWorldOne / this.sharelinkreport.leadcount) *
            100,
            "++++++"
          );
          this.alldatacountlead.medWorldOne = 0;
        } else {
          if (
            !(this.alldatacount.medWorldOne / this.sharelinkreport.leadcount)
          ) {
            this.alldatacountlead.medWorldOne = 0;
          } else {
            this.alldatacountlead.medWorldOne =
              (this.alldatacount.medWorldOne / this.sharelinkreport.leadcount) *
              100;
          }
        }
        if (
          (this.alldatacount.pece / this.sharelinkreport.leadcount) * 100 ==
          Infinity
        ) {
          console.log(
            (this.alldatacount.pece / this.sharelinkreport.leadcount) * 100,
            "++++++"
          );
          this.alldatacountlead.pece = 0;
        } else {
          if (!(this.alldatacount.pece / this.sharelinkreport.leadcount)) {
            this.alldatacountlead.pece = 0;
          } else {
            this.alldatacountlead.pece =
              (this.alldatacount.pece / this.sharelinkreport.leadcount) * 100;
          }
        }
        if (
          (this.alldatacount.beto / this.sharelinkreport.leadcount) * 100 ==
          Infinity
        ) {
          console.log(
            (this.alldatacount.beto / this.sharelinkreport.leadcount) * 100,
            "++++++"
          );
          this.alldatacountlead.beto = 0;
        } else {
          if (!(this.alldatacount.beto / this.sharelinkreport.leadcount)) {
            this.alldatacountlead.beto = 0;
            console.log("beto++++++++++++++++");
          } else {
            this.alldatacountlead.beto =
              (this.alldatacount.beto / this.sharelinkreport.leadcount) * 100;
          }
        }
        if (
          (this.alldatacount.influx / this.sharelinkreport.leadcount) * 100 ==
          Infinity
        ) {
          console.log(
            (this.alldatacount.influx / this.sharelinkreport.leadcount) * 100,
            "++++++"
          );
          this.alldatacountlead.influx = 0;
        } else {
          if (!(this.alldatacount.influx / this.sharelinkreport.leadcount)) {
            this.alldatacountlead.influx = 0;
          } else {
            this.alldatacountlead.influx =
              (this.alldatacount.influx / this.sharelinkreport.leadcount) * 100;
          }
        }
        if (
          (this.alldatacount.uta / this.sharelinkreport.leadcount) * 100 ==
          Infinity
        ) {
          console.log(
            (this.alldatacount.uta / this.sharelinkreport.leadcount) * 100,
            "++++++"
          );
          this.alldatacountlead.uta = 0;
        } else {
          if (!(this.alldatacount.uta / this.sharelinkreport.leadcount)) {
            this.alldatacountlead.uta = 0;
          } else {
            this.alldatacountlead.uta =
              (this.alldatacount.uta / this.sharelinkreport.leadcount) * 100;
          }
        }
        if (
          (this.alldatacount.hps / this.sharelinkreport.leadcount) * 100 ==
          Infinity
        ) {
          console.log(
            (this.alldatacount.hps / this.sharelinkreport.leadcount) * 100,
            "++++++"
          );
          this.alldatacountlead.hps = 0;
        } else {
          if (!(this.alldatacount.hps / this.sharelinkreport.leadcount)) {
            this.alldatacountlead.hps = 0;
            console.log("hps***************");
          } else {
            this.alldatacountlead.hps =
              (this.alldatacount.hps / this.sharelinkreport.leadcount) * 100;
          }
        }
      });
  }
  trainingdata() {
    this.progressSpinner.loading = true;
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
    console.log(this.user_details.productsarray, "user_detailss");

    const data: any = {
      source: "",
      condition: {
        associated_training: "60922437a133f500088e46ae",
        products: this.user_details.productsarray,
      },
      user_id: this.userdetails._id,
      type: this.userdetails.type,
      associated_training: "60922437a133f500088e46ae",
    };
    this.apiservice
      .getDataWithoutToken(data, "api1/gettrainingcenterlist")
      .subscribe((res: any) => {
        console.log(res.results, "job-ticket");

        this.lessonData = [];
        this.trainingCategoryName = res.results.trainingcenterlist;
        this.lessonData = res.results.alllessondata;
        if (
          res.results.done_lesson_by_user != null &&
          typeof res.results.done_lesson_by_user != "undefined" &&
          res.results.done_lesson_by_user != ""
        ) {
          this.dividend = res.results.done_lesson_by_user;
        }
        this.doneTraininglesson = res.results.donetraininglessondata;
        this.divisor = res.results.total_lesson;
        this.reportPercentage = Math.floor(
          (this.dividend / this.divisor) * 100
        );
        for (const key in this.trainingCategoryName) {
          for (const iterator of res.results.done_lesson_by_cat_by_user) {
            if (
              iterator.associated_training != null &&
              typeof iterator.associated_training != undefined &&
              iterator.associated_training != "" &&
              this.trainingCategoryName[key]._id == iterator.associated_training
            ) {
              console.log("LLLLLLLLLLLLLLLLLL");
              this.totalComplete[iterator.associated_training] = Math.floor(
                (iterator.lessonsdone / this.trainingCategoryName[key].count) *
                100
              );
            }
          }
        }

        for (const it in this.lessonData) {
          if (this.doneTraininglesson.length > 0) {
            for (const key in this.doneTraininglesson) {
              if (
                this.lessonData[it]._id ==
                this.doneTraininglesson[key].lesson_id
              ) {
                this.lessonData[it].donetime =
                  this.doneTraininglesson[key].createdon_datetime;
              }
            }
          }
        }
        // console.log(this.lessonData)
        this.progressSpinner.loading = false;

        if (this.trainingCategoryName != null && this.trainingCategoryName[0] != null) this.setStep(0, this.trainingCategoryName[0]._id);
      });
  }
  setStep(val, id) {
    this.progressSpinner.loading = true;

    this.stepval = val;
    const data: any = {
      source: "",
      condition: {
        associated_training: id,
        products: this.user_details.productsarray,
      },
      user_id: this.userdetails._id,
      type: this.userdetails.type,
      associated_training: id,
    };
    this.lessonData = [];
    this.apiservice
      .getDataWithoutToken(data, "api1/gettrainingcenterlist")
      .subscribe((res: any) => {
        console.log(res.results, "hjxfgjxfghh");
        this.progressSpinner.loading = false;

        this.trainingCategoryName = res.results.trainingcenterlist;
        this.lessonData = res.results.alllessondata;
        this.doneTraininglesson = res.results.donetraininglessondata;

        console.log(val);

        for (const key in this.trainingCategoryName) {
          for (const iterator of res.results.done_lesson_by_cat_by_user) {
            if (
              iterator.associated_training != null &&
              typeof iterator.associated_training != undefined &&
              iterator.associated_training != "" &&
              this.trainingCategoryName[key]._id == iterator.associated_training
            ) {
              console.log("LLLLLLLLLLLLLLLLLL");
              this.totalComplete[iterator.associated_training] = Math.floor(
                (iterator.lessonsdone / this.trainingCategoryName[key].count) *
                100
              );
            }
          }
        }
        for (const it in this.lessonData) {
          if (this.doneTraininglesson.length > 0) {
            for (const key in this.doneTraininglesson) {
              if (
                this.lessonData[it]._id ==
                this.doneTraininglesson[key].lesson_id
              ) {
                this.lessonData[it].donetime =
                  this.doneTraininglesson[key].createdon_datetime;
              }
            }
          }
        }
        console.log(this.lessonData, "kkkkkkkkkkkk");
      });
  }

  editlead() {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
    // this.progressSpinner.loading = true;
    this.formdata = {
      successmessage: "Update Successfully !!",
      redirectpath: "",
      submitactive: true, // optional, default true
      submittext: "Update",
      resettext: "",
      canceltext: "Cancel",
      apiUrl: this.apiservice.api_url,
      endpoint: "api/addusers", //
      jwttoken: "",

      fields: [
        {
          label: "First Name",
          name: "firstname", //
          value: this.userdetails.firstname,
          type: "text",
          validations: [
            { rule: "required", message: "First Name is Required" },
          ],
        },
        {
          heading: "",
          label: "Last Name",
          name: "lastname",
          value: this.userdetails.lastname,
          type: "text",
          validations: [
            { rule: "required", message: "Last Name is Required" },
            {
              rule: "pattern",
              value: this.textregex,
              message: "only use alphabet",
            },
          ],
        },
        {
          label: "Telephone",
          name: "phone",
          value: this.userdetails.phone,
          type: "number",
          validations: [
            { rule: "required", message: "Telephone is Required" },
            {
              rule: "minLength",
              value: 10,
              message: "The Phone Number must be 10 digits",
            },
            {
              rule: "maxLength",
              value: 15,
              message: "Please enter the valid number",
            },
          ],
        },

        {
          label: "Email",
          name: "email",
          type: "email",
          hint: "",
          disabled: true,
          value: this.userdetails.email,
          validations: [
            { rule: "required", message: "Email is required" },
            {
              rule: "pattern",
              value: this.emailregex,
              message: "Must be a valid Email",
            },
          ],
        },
        {
          label: "Address",
          name: "address",
          value: this.userdetails.address,
          type: "textarea",
          validations: [{ rule: "required", message: "Address is Required" }],
        },
        {
          label: "State",
          name: "state",
          val: this.stateVal,
          value: this.userdetails.state,
          type: "select",
          validations: [{ rule: "required", message: "State is Required" }],
        },
        {
          label: "type",
          name: "type",
          type: "hidden",
          value: "lead",
        },
        {
          label: "parentid",
          name: "parentid",
          type: "hidden",
          value: this.userservice.userid,
        },
        {
          label: "City",
          name: "city",
          value: this.userdetails.city,
          type: "text",
          validations: [{ rule: "required", message: "City is Required" }],
        },

        {
          label: "Zip",
          name: "zip",
          value: this.userdetails.zip,
          type: "number",
          validations: [
            { rule: "required", message: "Zip is Required" },
            {
              rule: "maxLength",
              value: 5,
              message: "Please enter the valid Zip ID",
            },
          ],
        },
        {
          label: "Active",
          name: "status",
          hint: "",
          type: "checkbox",
          val: this.status,
          value: this.userdetails.status,
        },
        {
          label: "id",
          name: "id",
          value: this.activatedRoute.snapshot.params._id,
          type: "hidden",
        },
      ],
    };
    if (
      this.activatedRoute.snapshot.params.type == "technological-consultant"
    ) {
      this.formdata.fields[6].value = "technological-consultant";
    }
    if (this.activatedRoute.snapshot.params.type == "distributor") {
      this.formdata.fields[6].value = "distributor";
    }
  }

  changepassword() {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
    // this.progressSpinner.loading = true;
    this.formdata = {
      successmessage: "Updated Successfully",
      // redirectpath: '',
      submitactive: true,
      submittext: "Update",
      resettext: "Reset",
      canceltext: "Cancel",
      apiUrl: this.apiservice.api_url,
      endpoint: "api/changepasswordforadmin", //
      jwttoken: this.cookieService.get("jwtToken"),
      // cancelroute: '',

      fields: [
        {
          label: "New Password",
          name: "password",
          type: "password",
          value: "",

          validations: [
            {
              rule: "required",
              message:
                "Your provided Password must contain at-least one Uppercase letter, one Lower case letter, and one Number.",
            },
            {
              rule: "pattern",
              value: this.passwordregex,
              message: "Must contain a Capital Letter and a Number",
            },
          ],
        },

        {
          label: "Confirm Password",
          name: "confirmpassword",
          type: "password",
          value: "",
          validations: [
            {
              rule: "required",
              message:
                "Your provided Password must contain at-least one Uppercase letter, one Lower case letter, and one Number.",
            },
            {
              rule: "match",
              message: "Confirm Password field Needs to  match Password",
            },
            {
              rule: "pattern",
              value: this.passwordregex,
              message: "Must contain a Capital Letter and a Number",
            },
          ],
        },
        {
          label: "id",
          name: "id",
          type: "hidden",
          value: this.userservice.userid,
        },
      ],
    };
  }

  listenFormFieldChangeAttachment(val: any) {
    // console.log(val, '++=------------------------++');

    if (val.fieldval == "success") {
      // this.progressSpinner.loading = true;
      this.showData("attachments");
      this.addattachmentflag = false;
      this.attachmentflag = false;
    }
    if (val.field == "formcancel") {
      // this.progressSpinner.loading = true;
      this.showData("attachments");
      this.addattachmentflag = false;
      this.attachmentflag = false;
    }
  }

  addAttachment() {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
    // this.progressSpinner.loading = true;
    this.attachmentFormdata = {
      successmessage: "Attachment Added Successfully",
      // redirectpath: '',
      submitactive: true,
      submittext: "Save",
      // resettext: null,
      canceltext: "Cancel",
      apiUrl: this.apiservice.api_url,
      endpoint: "api/add-practice-attachment", //
      jwttoken: this.cookieService.get("jwtToken"),
      cancelroute:
        "user-details/lead/" + this.activatedRoute.snapshot.params._id,
      fields: [
        {
          label: "Attachment:",
          name: "attachment_file",
          type: "file",
          prefix: "image-" + Date.now(),
          path: "betoparedes/attachment/",
          baseurl: "betoparedes-dev-attachment",
          bucket: "awsbackend-dev-patient-files-test",
          apiurl:
            "https://tge24bc2ne.execute-api.us-east-1.amazonaws.com/dev/requestUploadURL",
          apideleteurl:
            "https://tge24bc2ne.execute-api.us-east-1.amazonaws.com/dev/deletefilefromBucket",
        },
        {
          label: "Description",
          name: "description",
          value: "",
          type: "editor",
          validations: [{ rule: "required", message: " Template is required" }],
          ckeConfig: this.ckeConfig,
        },
        {
          label: "id",
          name: "leadID",
          type: "hidden",
          value: this.activatedRoute.snapshot.params._id,
        },
        {
          label: "",
          name: "parentID",
          type: "hidden",
          value: this.userservice.userid,
        },
      ],
    };
  }

  jobticketlist() {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
    this.progressSpinner.loading = true;
    const data: any = {
      condition: {
        user_id: this.userdetails._id,
      },
      sort: { skip: 0, limit: 10 },
      secret: this.userservice.secret,
      token: this.userservice.jwttoken,
    };
    this.apiservice
      .getDataWithoutToken(data, "api/getjobticketdataforuser")
      .subscribe((res: any) => {
        console.log(res, "job-ticket");
        this.progressSpinner.loading = false;
        this.jobticketdata = res.res;
        console.log(this.jobticketdata, "hhjhj");
      });
  }

  tabClick(tab) {
    console.log(tab);
    if (tab.index == 0) {
      this.appointmentlist("upcoming");
    }
    if (tab.index == 1) {
      this.appointmentlist("past");
    }
  }

  tabClickgenarelcall(tab) {
    this.progressSpinner.loading = true;
    console.log(tab);
    if (tab.index == 0) {
      this.bookappoinmentgenarelcall("upcoming");
    }
    if (tab.index == 1) {
      this.bookappoinmentgenarelcall("past");
    }
  }
  appointmentlist(val: any) {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
    this.progressSpinner.loading = true;
    this.appointmentdata = [];
    this.norecordfoundfor_discovery_appointments = false;
    const data: any = {
      condition: {
        user_id: this.userdetails._id,
        event_type_val: 2,
      },
      skip: 0,
      limit: 2,
    };
    if (val == "upcoming") {
      data.condition.start_datetime_unix = {
        $gte: Math.round(new Date().getTime()),
      };
    }
    if (val == "past") {
      data.condition.start_datetime_unix = {
        $lt: Math.round(new Date().getTime()),
      };
    }
    this.apiservice
      .getDataWithoutToken(data, "api/getappointmentdataforuser")
      .subscribe((res: any) => {
        console.log(res, "appointmentdata");
        this.appointmentdata = res.res;
        if (this.appointmentdata.length == 0) {
          this.norecordfoundfor_discovery_appointments = true;
        }
        // console.log('hhjhj', this.appointmentdata);
        if (res.res != null && typeof res.res != undefined && res.res != "") {
          this.shwmrflg = "true";
        }
        this.progressSpinner.loading = false;
        this.appointmentdiscoverydata = true;
      });
  }
  markasdone(val: any, flag: any) {
    const dialogRef2 = this.matDialog.open(DialogMarkAsDoneAlert);
    dialogRef2.afterClosed().subscribe((result) => {
      // console.log(`Dialog result: ${result}`);
      // 'mentee_id', 'mentor_id', 'lesson_id', 'training_id', 'lesson_title', 'training_category_name', 'mentor_name', 'mentee_name', 'mentee_email'
      const data: any = {
        token: this.userservice.jwttoken,
        _id: val._id,
      };
      if (result === "true") {
        this.apiservice
          .customRequest(
            data,
            "update-status-done",
            environment["api_calender_url"]
          )
          .subscribe((response: any) => {
            // console.log('response', response);
            // this.showData('worksheet');
            if (flag == "ganeral") {
              this.bookappoinmentgenarelcall("past");
            }
            if (flag == "discovery") {
              this.appointmentlist("past");
            }
          });
      }
    });
  }
  send_contract() {
    this.showData("contract");
  }

  viewModal(val) {
    console.log(val);
    let videourl: any;
    if (val.lesson_attachements != null && val.lesson_attachements != "") {
      for (const key in val.lesson_attachements) {
        if (val.lesson_attachements[key].type == "video") {
          let url =
            "https://www.youtube.com/embed/" +
            val.lesson_attachements[key].video_url +
            "?HD=1&rel=0";
          videourl = this.sanitizer.bypassSecurityTrustResourceUrl(url);
        }
      }
    }
    const dialogRef = this.matDialog.open(ViewContentComponent, {
      data: {
        data: val.lesson_attachements,
        video_url: videourl,
        html_content: val.typeHtml,
      },
      panelClass: "worksheet-modal2",
    });
    dialogRef.afterClosed().subscribe((result) => { });
  }
  showmr(val) {
    this.shwmrflg = "false";
    this.progressSpinner.loading = true;

    const data: any = {
      condition: {
        user_id: this.userdetails._id,
        event_type_val: 2,
      },
      skip: 0,
      limit: 10,
    };

    if (val == "upcoming") {
      data.condition.start_datetime_unix = {
        $gte: Math.round(new Date().getTime()),
      };
    }
    if (val == "past") {
      data.condition.start_datetime_unix = {
        $lt: Math.round(new Date().getTime()),
      };
    }

    this.apiservice
      .getDataWithoutToken(data, "api/getappointmentdataforuser")
      .subscribe((res: any) => {
        console.log(res, "appointmentdata");
        this.appointmentdata = res.res;
        console.log("hhjhj", this.appointmentdata);
        this.progressSpinner.loading = false;
      });
  }
  public allproductflag: boolean = false;
  public tmflowcntractflag: any = [];
  public tmflowcntractflags: boolean = false;
  public resendcount: any = 0;
  public resendcount1: any = 0;
  public resendcount2: any = 0;

  contractProductdata() {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
    this.progressSpinner.loading = true;
    let contractarray = [];

    console.log(this.product_list, "contract");
    const data: any = {
      condition: {
        id: this.activatedRoute.snapshot.params._id,
        status: 1,
      },
      secret: this.userservice.secret,
      token: this.userservice.jwttoken,
      limit: 0,
      skip: 0,
    };
    this.apiservice
      .getDataWithoutToken(data, "api/firstcontractrequest")
      .subscribe((res: any) => {
        console.log(res, "google");
        // this.viewcontractflag = false;
        if (res.status == "success") {
          this.contractproducts = res.res;
          for (const key in this.contractproducts[0].product_data) {
            if (
              this.contractproducts[0].product_data[key]._id ==
              "604a0b6e40962e00088f0d79" &&
              this.contractproducts[0].product_data[key].productname ==
              "TM-FLOW ANS MEDICAL DEVICE"
            ) {
              this.tmflowflag = true;
              this.createdApprove = true;
            }
            if (this.contractproducts[0].contracts.length > 0) {
              for (const keys in this.contractproducts[0].contracts) {
                if (
                  this.contractproducts[0].product_data[key]._id ==
                  this.contractproducts[0].contracts[keys].product_id
                ) {
                  console.log(this.contractproducts[0].product_data[key]._id);
                  this.viewcontractflag[
                    this.contractproducts[0].product_data[key]._id
                  ] = "true";
                }
                if (
                  this.contractproducts[0].contracts[keys].product_id == "" &&
                  this.contractproducts[0].contracts[keys].contractflag ==
                  "warrenty"
                ) {
                  this.forwarrentyonly = true;
                  if (
                    typeof this.contractproducts[0].contracts[keys]
                      .contract_complete_pdf_flag != "undefined" &&
                    this.contractproducts[0].contracts[keys]
                      .contract_complete_pdf_flag != null &&
                    this.contractproducts[0].contracts[keys]
                      .contract_complete_pdf_flag == 1
                  ) {
                    this.download_pdf_warranty = true;
                    this.download_pdf_link_warranty =
                      this.contractproducts[0].contracts[
                        keys
                      ].contract_complete_pdf;
                  }
                  // console.warn(this.download_pdf_warranty,'ppppppppppppppppppppppp');
                  // console.warn(this.download_pdf_link,'ppppppppppppppppppppppp');
                  for (let i in this.contractproducts[0].contracts[keys]
                    .contracts) {
                    console.log(
                      this.contractproducts[0].contracts[keys].contracts[i]
                    );
                    if (
                      this.contractproducts[0].contracts[keys].contracts[i]
                        .status == "Sent for signature"
                    ) {
                      this.sent_for_signature["warranty"] = true;
                    }
                  }
                }
                if (
                  this.contractproducts[0].contracts[keys].contractflag ==
                  "Pece Contract"
                ) {
                  if (
                    typeof this.contractproducts[0].contracts[keys]
                      .contract_complete_pdf_flag != "undefined" &&
                    this.contractproducts[0].contracts[keys]
                      .contract_complete_pdf_flag != null &&
                    this.contractproducts[0].contracts[keys]
                      .contract_complete_pdf_flag == 1
                  ) {
                    this.download_pdf_pece = true;
                    this.download_pdf_link_pece =
                      this.contractproducts[0].contracts[
                        keys
                      ].contract_complete_pdf;
                  }
                  // console.warn(this.download_pdf_pece,'ppppppppppppppppppppppp');
                  // console.warn(this.download_pdf_link,'ppppppppppppppppppppppp');
                  for (let i in this.contractproducts[0].contracts[keys]
                    .contracts) {
                    console.log(
                      this.contractproducts[0].contracts[keys].contracts[i]
                    );
                    if (
                      this.contractproducts[0].contracts[keys].contracts[i]
                        .status == "Sent for signature"
                    ) {
                      this.sent_for_signature[
                        this.contractproducts[0].contracts[keys].product_id
                      ] = true;
                    }
                    if (
                      this.contractproducts[0].contracts[keys].contracts[i]
                        .status == "Signature In Progress"
                    ) {
                      this.awaiting_for_hps_signature = true;
                    }
                  }
                  console.log(this.sent_for_signature);
                  console.log(this.contractproducts[0].contracts, "{}{}{}{}{}");
                  this.resendcount_pece =
                    this.contractproducts[0].contracts[keys].resendcount;
                  this.resendproduct[
                    this.contractproducts[0].contracts[keys].product_id
                  ] = this.resendcount_pece;
                }
                // else{
                //   this.forwarrentyonly = false;

                // }
              }
            }
          }
          if (this.tmflowflag == true) {
            if (this.contractproducts[0].contracts.length > 0) {
              for (const key in this.contractproducts[0].contracts) {
                // console.log('llllllllllllllllllllllllllllll');

                if (
                  this.contractproducts[0].contracts[key].product_id ==
                  "604a0b6e40962e00088f0d79"
                ) {
                  if (
                    this.contractproducts[0].contracts[key].contracts[
                      this.contractproducts[0].contracts[key].contracts.length -
                      1
                    ].status == "approved"
                  ) {
                    this.allproductflag = true;
                    break;
                  } else {
                    this.allproductflag = false;
                  }
                }
                // if (this.contractproducts[0].contracts[key].contractflag == 'tmflow') {
                //   this.tmflowcntractflag[this.contractproducts[0].contracts[key].product_id] = this.contractproducts[0].contracts[key].contractflag;
                //   console.log();

                // }
                if (
                  this.contractproducts[0].contracts[key].contractflag ==
                  "credit"
                ) {
                  for (const k in this.contractproducts[0].createdApprove) {
                    if (
                      this.contractproducts[0].createdApprove[k].contractflag ==
                      "credit"
                    ) {
                      this.contract_approved_credit = true;
                    }
                  }
                  if (
                    this.contractproducts[0].contracts[key].contracts[0]
                      .status == "Sent for signature"
                  ) {
                    this.isresendcredit = true;
                    console.warn("abcd");
                  }
                  if (
                    typeof this.contractproducts[0].contracts[key]
                      .contract_complete_pdf_flag != "undefined" &&
                    this.contractproducts[0].contracts[key]
                      .contract_complete_pdf_flag != null &&
                    this.contractproducts[0].contracts[key]
                      .contract_complete_pdf_flag == 1
                  ) {
                    this.download_pdf_credit = true;
                    this.download_pdf_link_credit =
                      this.contractproducts[0].contracts[
                        key
                      ].contract_complete_pdf;
                  }
                  this.tmflowcntractflag[
                    this.contractproducts[0].contracts[key].product_id
                  ] = this.contractproducts[0].contracts[key].contractflag;
                  console.warn(
                    this.contractproducts[0].contracts[key].resendcount
                  );
                  this.resendcount =
                    this.contractproducts[0].contracts[key].resendcount;
                }

                if (
                  this.contractproducts[0].contracts[key].contractflag ==
                  "warrenty"
                ) {
                  this.tmflowcntractflag[
                    this.contractproducts[0].contracts[key].product_id
                  ] = this.contractproducts[0].contracts[key].contractflag;
                  console.warn(
                    this.contractproducts[0].contracts[key].resendcount
                  );
                  this.resendcount1 =
                    this.contractproducts[0].contracts[key].resendcount;
                }
                if (
                  this.contractproducts[0].contracts[key].contractflag ==
                  "tmflow_contract"
                ) {
                  if (
                    typeof this.contractproducts[0].contracts[key]
                      .contract_complete_pdf_flag != "undefined" &&
                    this.contractproducts[0].contracts[key]
                      .contract_complete_pdf_flag != null &&
                    this.contractproducts[0].contracts[key]
                      .contract_complete_pdf_flag == 1
                  ) {
                    this.download_pdf_tmflow = true;
                    this.download_pdf_link_tmflow =
                      this.contractproducts[0].contracts[
                        key
                      ].contract_complete_pdf;
                  }
                  this.tmflowcntractflag[
                    this.contractproducts[0].contracts[key].product_id
                  ] = this.contractproducts[0].contracts[key].contractflag;
                  for (let i in this.contractproducts[0].contracts[key]
                    .contracts) {
                    if (
                      this.contractproducts[0].contracts[key].contracts[i]
                        .status == "Sent for signature"
                    ) {
                      this.sent_for_signature[
                        this.contractproducts[0].contracts[key].product_id
                      ] = true;
                    }
                  }
                  console.warn(
                    this.contractproducts[0].contracts[key].resendcount
                  );
                  this.resendcount2 =
                    this.contractproducts[0].contracts[key].resendcount;
                }
              }
            }
          } else {
            this.allproductflag = true;
          }
          if (this.contractproducts[0].contracts.length > 0) {
            for (const key in this.contractproducts[0].contracts) {
              if (
                this.contractproducts[0].contracts[key].contractflag ==
                "tmflow_contract"
              ) {
                this.tmflowcntractflags = true;
              }
              console.log();
              this.cntractflags_approved[
                this.contractproducts[0].contracts[key].product_id
              ] = false;
              if (
                this.contractproducts[0].contracts[key].contractflag ==
                "tmflow_contract" &&
                this.contractproducts[0].contracts[key].contracts[0].status ==
                "approved"
              ) {
                this.cntractflags_approved[
                  this.contractproducts[0].contracts[key].product_id
                ] = true;
                console.warn(
                  this.cntractflags_approved[
                  this.contractproducts[0].contracts[key].product_id
                  ],
                  this.contractproducts[0].contracts[key].product_id
                );
              }
            }
          }
        }
        this.progressSpinner.loading = false;
      });
  }
  filterarray(arr: any, flag: any) {
    let data = arr.filter((itemInArray) => itemInArray.contractflag == flag);
    // console.log(data[0].contracts[0], "+++++++++++++filterarrayfilterarray");
    return data[0].contracts[0];
  }
  public formdata3: any;
  public surname_status: any = [
    { val: "Mr", name: "Mr" },
    { val: "Ms", name: "Ms" },
    { val: "Mrs", name: "Mrs" },
    { val: "Dr", name: "Dr" },
    { val: "Prof", name: "Prof" },
  ];
  contactdata() {
    // this.listcontractview = false;
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
    this.formdata3 = {
      successmessage: "Contact Added Successfully Submitted",
      redirectpath: "",
      submitactive: true, // optional, default true
      submittext: "Save",
      // resettext: 'Reset',
      canceltext: "Cancel",
      apiUrl: this.apiservice.api_url,
      // endpoint: 'api/add-practice-contracts',
      jwttoken: this.cookieService.get("jwtToken"),
      cancelroute:
        "user-details/lead/" + this.activatedRoute.snapshot.params._id,
      fields: [
        {
          label: "Salutation",
          name: "Salutation",
          value: "",
          // multiple:true,
          val: this.surname_status,
          type: "select",
          validations: [
            { rule: "required", message: "Salutation is Required" },
          ],
          prefix: "*",
        },

        {
          label: "First Name",
          name: "First_Name",
          value: "",
          // multiple:true,

          type: "text",
          validations: [
            { rule: "required", message: "First Name is Required" },
          ],
          prefix: "*",
        },
        {
          label: "Last Name",
          name: "Last_Name",
          value: "",
          type: "text",
          validations: [{ rule: "required", message: "Last Name is Required" }],
          prefix: "*",
        },
        {
          label: "Phone",
          name: "Phone",
          value: " ",
          type: "number",
          validations: [
            { rule: "required", message: "Phone is Required" },
            {
              rule: "minLength",
              value: 10,
              message: "The Phone Number must be 10 digits",
            },
          ],
          prefix: "*",
        },
        {
          label: "Other Phone",
          name: "Other_Phone",
          value: "",
          type: "number",
          validations: [
            // { rule: 'required', message: 'Practice Name is Required' },
          ],
        },

        {
          label: "Email",
          name: "Email",
          value: "",
          val: "",
          type: "text",
          validations: [{ rule: "required", message: "Email is Required" }],
          prefix: "*",
        },
        {
          label: "Secondary Email",
          name: "Secondary_Email",
          value: "",
          type: "text",
          // validations: [
          //   { rule: 'required', message: 'Fax is Required' },
          // ],
        },
        {
          label: "Fax",
          name: "fax",
          value: "",
          type: "text",
          validations: [
            // {
            //   rule: 'maxLength',
            //   value: 9,
            //   message: 'Please enter the valid 9 digit EIN Number of ',
            // },
          ],
        },
        {
          label: "Contact Notes",
          name: "Contact_Notes",
          value: "",
          type: "text",
          validations: [
            // {
            //   rule: 'maxLength',
            //   value: 9,
            //   message: 'Please enter the valid 9 digit EIN Number of ',
            // },
          ],
        },
        {
          heading: "Address Information",
          name: "statusgroup",
          hint: ",0000",
          type: "group",
          fields: [
            // {
            //   label: 'Age1',
            //   name: 'age1',
            //   type: 'number',
            //   hint: 'Age ??',
            //   validations: [
            //       // { rule: 'required' },
            //       // { rule: 'min', value: 5 },
            //       // { rule: 'max', value: 50, message: 'Age can't be more than 50 ' }
            //   ]
            // },
          ],
        },
        {
          label: "Mailing Street",
          name: "Mailing_Street",
          value: "",

          type: "text",
          // validations: [
          //   { rule: 'required', message: 'Practice Type is Required' },
          // ],
        },
        {
          label: "Mailing City",
          name: "Mailing_City",
          value: "",
          // hint:'None',
          // val: this.pecepracticestatus,
          type: "text",
        },
        {
          label: "Mailing State",
          name: "Mailing_State",
          value: "",

          type: "text",
          // validations: [
          //  g { rule: 'required', message: 'Exchange Rate is Required' },
          // ],
        },
        {
          label: "Mailing Zip",
          name: "Mailing_Zip",
          value: "",
          type: "number",
          // validations: [
          //   { rule: 'required', message: 'Practice EMR is Required' },
          // ],
        },
        {
          label: "Mailing Country",
          name: "Mailing_Country",
          value: "",
          type: "text",
          // validations: [
          //   { rule: 'required', message: 'Practice Email is Required' },
          // ],
        },
        {
          label: "Other Street",
          name: "Other_Street",
          value: "",
          type: "text",
          validations: [
            // { rule: 'required', message: 'Website is Required' },
          ],
        },
        {
          label: "Other City",
          name: "Other_City",
          value: "",
          type: "textarea",
          labelPosition: "after",
          // validations: [
          //   { rule: 'required', message: 'Practice Notes is Required' },
          // ],
        },

        {
          label: "Other State",
          name: "Other_State",
          value: "",
          type: "text",

          // validations: [
          //   { rule: 'required', message: 'Practice Notes is Required' },
          // ],
        },
        {
          label: "Other Zip",
          name: "Other_Zip",
          value: "",
          type: "text",
        },

        {
          label: "Other Country",
          name: "Other_Country",
          value: "",
          type: "text",
        },
        {
          label: "Decision Maker",
          name: "isSignee",
          value: false,
          type: "checkbox",
        },
        {
          label: "",
          name: "leadID",
          value: this.activatedRoute.snapshot.params._id,
          type: "hidden",
        },
      ],
    };
  }
  listenFormFieldChangescontact(val: any) {
    console.log(val, "++");
    if (val.field == "fromsubmit") {
      if (val.fieldval == "success") {
        let send_data = {
          data: val.fromval,
          secret: this.cookieService.get("secret"),
          token: this.cookieService.get("jwtToken"),
        };
        this.apiservice
          .getDataWithoutToken(send_data, "api/add-practice-contracts")
          .subscribe((res: any) => {
            console.log(res);
            if (
              typeof res.errormessage != "undefined" &&
              res.errormessage != null
            ) {
              if (
                res.errormessage.match(
                  "E11000 duplicate key error collection: betoparedes-backend-v2.practicecontracts index: Email_1 dup key:"
                ) != null
              ) {
                this.snackBar.open("Email Already Exsist", "Dismiss", {
                  duration: 6000,
                });
                window.scrollTo({
                  top: 0,
                  left: 0,
                  behavior: "smooth",
                });
              }
            } else {
              this.snackBar.open(val.formdataval.successmessage, "Dismiss", {
                duration: 3000,
              });
              // this.showData('personalinfo');
              this.showData("contact");
              this.addcontact = false;
              // this.flag = false;
            }
          });
      }
    }
    if (val.field == "formcancel") {
      this.showData("contact");
      this.addcontact = false;
      this.flag = false;
    }
  }
  GetChildData(val: any) {
    console.log(val.action, "GetChildData");
    if (val && val.action == "Work Sheet Add Success Fully") {
      console.log("ppppppppppppppppppppppp");
      this.showData("questionnaire");
    }
    if (val && val.action == "Work Sheet Update Success Fully") {
      console.log("ppppppppppppppppppppppp");
      this.showData("questionnaire");
    }
  }
  // contractpage(p_id, c_id, flag, val) {
  //   if (flag == 'normal') {
  //     this.router.navigateByUrl(
  //       'contract-manager/contract/create/' +
  //       p_id +
  //       '/' +
  //       this.userdetails._id +
  //       '/' +
  //       c_id
  //     );
  //   }
  //   if (flag == 'req') {
  //     console.log(val);

  //     if (typeof (val.contact_sing != 'undefined') && val.contact_sing != null && val.contact_sing != '' && val.contact_sing.length > 0) {
  //       // window.open(
  //       //   'contract-manager/contract/create/' +
  //       //   p_id +
  //       //   '/' +
  //       //   this.userdetails._id +
  //       //   '/' +
  //       //   c_id,
  //       //   'Independent Window'
  //       // );
  //       this.progressSpinner.loading = true;

  //       let data = {
  //         'lead_id': this.activatedRoute.snapshot.params._id,
  //         'tech_id': this.userservice.userid,
  //         'product_id': val.product_id,
  //         'contracts': [{ 'status': 'request', 'added_by_id': this.userservice.userid, 'addedby': this.userservice.fullname, 'addedondatetime': Math.round((new Date()).getTime()) }],
  //         'contact_id': val.contact_sing[0]._id
  //       }
  //       console.log(data);
  //       this.apiservice
  //         .getDataWithoutToken(data, 'api/request-contracts')
  //         .subscribe((res: any) => {
  //           if (res.status == 'success') {
  //             let data2 = { 'condition': { 'id': this.activatedRoute.snapshot.params._id, 'status': 1 }, 'secret': this.userservice.secret, 'token': this.userservice.jwttoken, 'limit': 0, 'skip': 0 }
  //             this.contractproducts = [];
  //             this.apiservice
  //               .getDataWithoutToken(data2, 'api/firstcontractrequest')
  //               .subscribe((res1: any) => {
  //                 if (res1.status == 'success') {
  //                   this.contractproducts = res1.res;
  //                 }

  //                 this.progressSpinner.loading = false;
  //               })
  //           }

  //         });

  //     }
  //     else {
  //       const dialogRef = this.matDialog.open(viewcontactssing, {
  //         data: {
  //           data: '',
  //         },
  //         panelClass: 'worksheet-modal',
  //       });

  //       dialogRef.afterClosed().subscribe((result) => {
  //         if (result && result.flag != null && result.flag == 'addsingee') {
  //           this.showData('contact')

  //           this.addcontact = true;
  //           console.log(result, 'addcontact', this.addcontact);

  //           this.listcontractview = false;
  //           this.flag = false;
  //         }
  //       });
  //     }

  //   }
  //   if (flag == 'view') {
  //     window.open(
  //       'contract-manager/contract/edit/' + p_id + '/' + this.userdetails._id,
  //       'Independent Window'
  //     );
  //   }
  //   if (flag == 'see') {
  //     window.open(
  //       'contract-manager/contract/view/' + p_id + '/' + this.userdetails._id,
  //       'Independent Window'
  //     );
  //   }
  // }
  viewattachments() {
    this.showData("attachments");
  }
  viewfollowup_call(val: any) {
    console.log(val, "view follow up call");
    // setTimeout(() => {
    const dialogRef = this.matDialog.open(viewfollowup, {
      // height: '700px',
      // width: '1000px',
      data: val,
      panelClass: "viewfollowupcall-modal",
    });
    // }, 1000);
  }
  contractflagdiscovery(val: any, productflg) {
    console.log(val, "discovery call contract", productflg);
    console.log(val.product_id, "************", this.userdetails);

    // for pece product

    if (this.userdetails.singeealldata != 0) {
      if (val.product_id == "604aff3691b7c80008ca11a8") {
        console.log(val.product_id, "************", this.userdetails);
        let pececontractdata: any = {};
        pececontractdata.leadname = this.userdetails.fullname;
        pececontractdata.address = this.userdetails.address;
        pececontractdata.Serial = this.userdetails.Serial_Number;
        let data = {
          lead_id: this.activatedRoute.snapshot.params._id,
          tech_id: JSON.parse(this.cookieService.get("userid")),
          product_id: "604aff3691b7c80008ca11a8",
          contractdata: pececontractdata,
          contractflag: "Pece Contract",
          contracts: [
            {
              status: "request",
              added_by_id: JSON.parse(this.cookieService.get("userid")),
              addedby: this.userservice.fullname,
              addedondatetime: Math.round(new Date().getTime()),
              type: JSON.parse(this.cookieService.get("type")),
            },
          ],
          contact_id: this.userdetails.singeealldata[0]._id,
        };

        this.loaderFlag = "Pece Contract";
        console.log(data);
        this.apiservice
          .getDataWithoutToken(data, "api/request-contracts")
          .subscribe((res: any) => {
            setTimeout(() => {
              this.loaderFlag = "";
            }, 200);
            this.openSnackBar(res.successmsg, "ok");
            // this.showData('contract');
          });
        // window.open(
        //   '/pece/contract/' +
        //   this.activatedRoute.snapshot.params._id +
        //   '/' +
        //   product_id +
        //   '/' +
        //   sing[0]._id,
        //   '_blank'
        // );
        // return;
        // let data = {
        //   lead_id: this.activatedRoute.snapshot.params._id,
        //   tech_id: this.userservice.userid,
        //   product_id: product_id,
        //   contractdata: {
        //     practicename: this.userdetails.firstname,
        //     address: this.userdetails.address,
        //     serial_Number: this.userdetails.Serial_Number,
        //     addedondatetime: Math.round(new Date().getTime()),
        //   },
        //   'contractflag': 'Pece Contract',
        //   contracts: [
        //     {
        //       status: 'request',
        //       added_by_id: this.userservice.userid,
        //       addedby: this.userservice.fullname,
        //       addedondatetime: Math.round(new Date().getTime()),
        //     },
        //   ],
        //   contact_id: sing[0]._id,
        // };
        // console.log(data);
        // this.apiservice
        //   .getDataWithoutToken(data, 'api/request-contracts')
        //   .subscribe((res: any) => {
        //     this.openSnackBar(res.successmsg, 'ok');
        //     this.showData('contract')
        //   });
      }
      // else{
      //   this.showData('contract')
      // }
      // if (this.userdetails.singeealldata == null && this.userdetails.singeealldata == ''){
      //   this.showData('contract')
      // }
    } else {
      const dialogRef = this.matDialog.open(viewcontactssing, {
        data: {
          // data_event: event,
          // data_sing: sing,
          flag: productflg,
        },
        panelClass: "worksheet-modal",
      });
      dialogRef.afterClosed().subscribe((result) => {
        console.log(result, "flag");
        if (result.flag == "signee") {
          this.showData("contact");
        }
      });
      // this.showData('contact');
    }
  }
  reqcontractpage(event, sing, product_id, flag) {
    if (flag == "product") {
      console.log(
        event,
        "event ==",
        sing,
        "sing ==",
        product_id,
        "product_id ==",
        flag
      );
      let signee_available = false;
      if (sing.length == 0) {

      }
      let right_event = false;
      for (const key in event) {
        if (
          event[key].product_id == product_id &&
          event[key].event_type == 2 &&
          event[key].status != 2
        ) {
          right_event = true;
        }
      }

      if (
        event != "" &&
        event.length > 0 &&
        sing != "" &&
        sing.length > 0 &&
        right_event == true
      ) {
        if (product_id == "604a0b6e40962e00088f0d79") {
          window.open(
            "/admin/credit/" +
            this.activatedRoute.snapshot.params._id +
            "/" +
            product_id +
            "/" +
            sing[0]._id,
            "_blank"
          );
          // const dialogRef = this.matDialog.open(Warrentycallfromcontract, {
          //   data: {
          //     data: this.userdetails, flag: 'tmflow'
          //   },
          //   panelClass: 'worksheet-modal',
          // });

          // dialogRef.afterClosed().subscribe((result) => { });
        }
        if (product_id == "604aff3691b7c80008ca11a8") {
          console.log(event, "************", this.userdetails);
          let pececontractdata: any = {};
          pececontractdata.leadname = this.userdetails.fullname;
          pececontractdata.address = this.userdetails.address;
          pececontractdata.Serial = this.userdetails.Serial_Number;
          let data = {
            lead_id: this.activatedRoute.snapshot.params._id,
            tech_id: this.userservice.userid,
            product_id: environment.product_pece,
            contractdata: pececontractdata,
            contractflag: "Pece Contract",
            contracts: [
              {
                status:
                  "requested by " + JSON.parse(this.cookieService.get("type")),
                added_by_id: JSON.parse(this.cookieService.get("userid")),
                addedby:
                  JSON.parse(this.cookieService.get("firstname")) +
                  " " +
                  JSON.parse(this.cookieService.get("lastname")),
                addedondatetime: Math.round(new Date().getTime()),
                type: JSON.parse(this.cookieService.get("type")),
              },
            ],
            // contact_id: this.userdetails.singeealldata[0]._id,
            contact_id: sing[0]._id,
          };
          this.loaderFlag = data.contractflag;
          console.log(data);
          this.apiservice
            .getDataWithoutToken(data, "api/request-contracts")
            .subscribe((res: any) => {
              setTimeout(() => {
                this.loaderFlag = "";
              }, 200);
              this.openSnackBar(res.successmsg, "ok");
              this.showData("contract");
            });
          // window.open(
          //   '/pece/contract/' +
          //   this.activatedRoute.snapshot.params._id +
          //   '/' +
          //   product_id +
          //   '/' +
          //   sing[0]._id,
          //   '_blank'
          // );
          // return;
          // let data = {
          //   lead_id: this.activatedRoute.snapshot.params._id,
          //   tech_id: this.userservice.userid,
          //   product_id: product_id,
          //   contractdata: {
          //     practicename: this.userdetails.firstname,
          //     address: this.userdetails.address,
          //     serial_Number: this.userdetails.Serial_Number,
          //     addedondatetime: Math.round(new Date().getTime()),
          //   },
          //   'contractflag': 'Pece Contract',
          //   contracts: [
          //     {
          //       status: 'request',
          //       added_by_id: this.userservice.userid,
          //       addedby: this.userservice.fullname,
          //       addedondatetime: Math.round(new Date().getTime()),
          //     },
          //   ],
          //   contact_id: sing[0]._id,
          // };
          // console.log(data);
          // this.apiservice
          //   .getDataWithoutToken(data, 'api/request-contracts')
          //   .subscribe((res: any) => {
          //     this.openSnackBar(res.successmsg, 'ok');
          //     this.showData('contract')
          //   });
        }
      } else {
        if (right_event == false) {
          const dialogRef = this.matDialog.open(viewcontactssing, {
            data: {
              data_event: event,
              data_sing: sing,
              flag: right_event,
            },
            panelClass: "worksheet-modal",
          });

          dialogRef.afterClosed().subscribe((result) => {
            console.log(result);

            if (result && result.flag != null && result.flag == "signee") {
              this.showData("contact");

              this.addcontact = true;
              console.log(result, "addcontact", this.addcontact);

              this.listcontractview = false;
              this.flag = false;
            }
            if (result && result.flag != null && result.flag == "Discovery") {
              let send_data = {
                _id: product_id,
              };
              this.bookNow(send_data, 1, "discovery");
              // this.showData('worksheet');
            }
          });
        }
        if (
          event != "" &&
          event.length > 0 &&
          sing.length == 0 &&
          right_event == true
        ) {
          const dialogRef = this.matDialog.open(DialogOverviewSigneeDialog, {
            data: "",
            panelClass: "worksheet-modal",
          });

          dialogRef.afterClosed().subscribe((result) => {
            console.log(result);

            if (result == "contact") {
              this.showData("contact");
            }
          });
        }
      }
    }
    if (flag == "Discovery") {
      let right_event = false;
      console.log(event, "event Discovery call contract");

      for (const key in event) {
        // if (event[key].product_id == product_id) {
        if (event[key].status == 2 && event[key].event_type != 5) {
          right_event = true;
        }
        // }
      }
      if (right_event == true && sing != "" && sing.length > 0) {
        // const dialogRef = this.matDialog.open(Warrentycallfromcontract, {
        //   data: {
        //     data: this.userdetails,
        //     flag: 'warrenty',
        //   },
        //   panelClass: 'worksheet-modal',
        // });

        // dialogRef.afterClosed().subscribe((result) => {
        //   this.showData('contract');
        // });
        window.open(
          "/tmflowfive/contract/" +
          this.activatedRoute.snapshot.params._id +
          "/" +
          sing[0]._id
        );
      } else {
        const dialogRef = this.matDialog.open(viewcontactssing, {
          data: {
            data_event: event,
            data_sing: sing,
            flag: right_event,
            flag_check: "Discovery",
          },
          panelClass: "worksheet-modal",
        });

        dialogRef.afterClosed().subscribe((result) => {
          console.log(result);

          if (result && result.flag != null && result.flag == "signee") {
            this.showData("contact");

            this.addcontact = true;
            console.log(result, "addcontact", this.addcontact);

            this.listcontractview = false;
            this.flag = false;
          }
          if (result && result.flag != null && result.flag == "Discovery") {
            this.showData("worksheet");
          }
          if (result && result.flag != null && result.flag == "Warranty") {
            this.showData("warrenty_call_block");
          }
        });
      }
    }
    if (flag == "warranty") {
      let right_event = false;
      console.log(event, "event warranty call contract");

      for (const key in event) {
        // if (event[key].product_id == product_id) {
        if (event[key].status != 2 && event[key].event_type == 5) {
          right_event = true;
        }
        // }
      }
      if (right_event == true && sing != "" && sing.length > 0) {
        // const dialogRef = this.matDialog.open(Warrentycallfromcontract, {
        //   data: {
        //     data: this.userdetails,
        //     flag: 'warrenty',
        //   },
        //   panelClass: 'worksheet-modal',
        // });

        // dialogRef.afterClosed().subscribe((result) => {
        //   this.showData('contract');
        // });
        console.log(
          event,
          "*****************event************userdetails**********",
          this.userdetails
        );
        let all_tmflow_data: any = {};
        all_tmflow_data.PracticeName = this.userdetails.fullname;
        all_tmflow_data.Street = this.userdetails.street;
        all_tmflow_data.City = this.userdetails.city;
        all_tmflow_data.State = this.userdetails.state;
        all_tmflow_data.Zip = this.userdetails.zip;
        all_tmflow_data.Phone = this.userdetails.phone;
        // all_tmflow_data.auth_signatory =
        //   this.userdetails.singeealldata[0].First_Name +
        //   " " +
        //   this.userdetails.singeealldata[0].Last_Name;
        // all_tmflow_data.printed_name =
        //   this.userdetails.singeealldata[0].First_Name +
        //   " " +
        //   this.userdetails.singeealldata[0].Last_Name;
        all_tmflow_data.equipment = "TM-FLOW ANS MEDICAL DEVICE";
        let data = {
          lead_id: this.activatedRoute.snapshot.params._id,
          tech_id: this.userservice.userid,
          product_id: "",
          contractdata: all_tmflow_data,

          contractflag: "warrenty",
          contracts: [
            {
              status:
                "requested by " + JSON.parse(this.cookieService.get("type")),
              added_by_id: JSON.parse(this.cookieService.get("userid")),
              addedby:
                JSON.parse(this.cookieService.get("firstname")) +
                " " +
                JSON.parse(this.cookieService.get("lastname")),
              addedondatetime: Math.round(new Date().getTime()),
              type: JSON.parse(this.cookieService.get("type")),
            },
          ],
          contact_id: this.userdetails.singeealldata[0]._id,
        };
        console.log(data, "+++++++++++");
        this.loaderFlag = data.contractflag;
        this.apiservice
          .getDataWithoutToken(data, "api/update-new_contract")
          .subscribe((res: any) => {
            setTimeout(() => {
              this.loaderFlag = "";
            }, 200);
            this.openSnackBar(res.successmsg, "ok");
            this.showData("contract");
            // this.router.navigateByUrl('/user-details/lead/' + this.activatedRoute.snapshot.params._id);
          });
        // window.open(
        //   '/tmflowfive/contract/' +
        //   this.activatedRoute.snapshot.params._id +
        //   '/' +
        //   sing[0]._id
        // );
      } else {
        if (right_event == false) {
          const dialogRef = this.matDialog.open(viewcontactssing, {
            data: {
              data_event: event,
              data_sing: sing,
              flag: right_event,
              flag_check: "warrenty",
            },
            panelClass: "worksheet-modal",
          });

          dialogRef.afterClosed().subscribe((result) => {
            console.log(result);

            if (result && result.flag != null && result.flag == "signee") {
              this.showData("contact");

              this.addcontact = true;
              console.log(result, "addcontact", this.addcontact);

              this.listcontractview = false;
              this.flag = false;
            }
            if (result && result.flag != null && result.flag == "Discovery") {
              this.showData("worksheet");
            }
            if (result && result.flag != null && result.flag == "Warranty") {
              // this.showData("warrenty_call_block");
              this.bookNow_warrenty(1, "warranty");
            }
          });
        }
        if (
          event != "" &&
          event.length > 0 &&
          sing.length == 0 &&
          right_event == true
        ) {
          const dialogRef = this.matDialog.open(DialogOverviewSigneeDialog, {
            data: "",
            panelClass: "worksheet-modal",
          });

          dialogRef.afterClosed().subscribe((result) => {
            console.log(result);

            if (result == "contact") {
              this.showData("contact");
            }
          });
        }
      }
    }
    if (flag == "Resend") {
      // window.open(
      //   '/admin/credit/' +
      //   this.activatedRoute.snapshot.params._id +
      //   '/' +
      //   product_id +
      //   '/' +
      //   sing[0]._id +
      //   '?request=true',
      //   '_blank'
      // );
      if (product_id == "604a0b6e40962e00088f0d79") {
        let tmflowAlldata = {};
        for (let keys in this.contractproducts[0].contracts) {
          if (
            this.contractproducts[0].contracts[keys].product_id == product_id &&
            this.contractproducts[0].contracts[keys].contractflag ==
            "tmflow_contract"
          ) {
            tmflowAlldata =
              this.contractproducts[0].contracts[keys].contractdata;
          }
        }
        let data: {};
        data = {
          lead_id: this.activatedRoute.snapshot.params._id,
          tech_id: this.userservice.userid,
          product_id: product_id,

          contracts: [
            {
              status: "request",
              added_by_id: this.userservice.userid,
              addedby: this.userservice.fullname,
              addedondatetime: Math.round(new Date().getTime()),
              type: this.userservice.type,
            },
          ],
          contractflag: "tmflow_contract",
          contractdata: tmflowAlldata,
          contact_id: sing[0]._id,
        };
        this.apiservice
          .getDataWithoutToken(data, "api/update-new_contract")
          .subscribe((res: any) => {
            this.openSnackBar("Contract Submitted ", "ok");
            this.showData("contract");
            // this.router.navigateByUrl(
            //   "/user-details/lead/" + this.activatedRoute.snapshot.params._id
            // );
            // window.close();
          });
        // window.open(
        //   '/admin/credit/' +
        //   this.activatedRoute.snapshot.params._id +
        //   '/' +
        //   product_id +
        //   '/' +
        //   sing[0]._id +
        //   '?request=true',
        //   '_blank'
        // );
      }
      if (product_id == "604aff3691b7c80008ca11a8") {
        let id = "";
        for (let keys in this.contractproducts[0].contracts) {
          if (
            this.contractproducts[0].contracts[keys].product_id == product_id &&
            this.contractproducts[0].contracts[keys].contractflag ==
            "Pece Contract"
          ) {
            id = this.contractproducts[0].contracts[keys]._id;
          }
        }
        // let data: {};
        // data = {
        //   lead_id: this.activatedRoute.snapshot.params._id,
        //   tech_id: this.userservice.userid,
        //   product_id: product_id,

        //   contracts: [
        //     {
        //       status: "request",
        //       added_by_id: this.userservice.userid,
        //       addedby: this.userservice.fullname,
        //       addedondatetime: Math.round(new Date().getTime()),
        //       type: this.userservice.type,
        //     },
        //   ],
        //   contractflag: "Pece Contract",
        //   contractdata: peceAlldata,
        //   contact_id: sing[0]._id,
        // }
        // console.warn(this.contractproducts[0]);
        this.progressSpinner.loading = true;
        this.apiservice
          .customRequest_temporary(
            {},
            `zoho/send_for_signature_castom?id=${id}`,
            environment["api_url2"]
          )
          .subscribe((res: any) => {
            console.log(res);
            this.openSnackBar("Contract Resend Successfully ", "ok");
            this.showData("contract");
            // this.router.navigateByUrl(
            //   "/user-details/lead/" + this.activatedRoute.snapshot.params._id
            // );
            // window.close();
          });
        setTimeout(() => {
          this.openSnackBar("Contract Resend Successfully ", "ok");
          this.showData("contract");
          this.progressSpinner.loading = false;
        }, 5000);
      }
    }
    if (flag == "creditapplicationResend") {
      window.open(
        "/admin/credit/" +
        this.activatedRoute.snapshot.params._id +
        "/" +
        product_id +
        "?request=true",
        "_blank"
      );
    }
    if (flag == "view") {
      window.open(
        "/admin/credit/" +
        this.activatedRoute.snapshot.params._id +
        "/" +
        product_id +
        "/" +
        sing[0]._id +
        "?flag=view",
        "_blank"
      );
    }
    if (flag == "creditapplicationview") {
      window.open(
        "/admin/credit/" +
        this.activatedRoute.snapshot.params._id +
        "/" +
        product_id +
        "?flag=view",
        "_blank"
      );
    }
    if (flag == "tmflowcontract") {
      console.log(
        event,
        "lllllllllllllll",
        sing,
        "lllllllllllll",
        product_id,
        "lllllllllll",
        flag
      );
      let right_event = false;
      for (const key in event) {
        if (
          event[key].product_id == product_id &&
          event[key].event_type == 2 &&
          event[key].status != 2
        ) {
          right_event = true;
        }
      }
      if (
        event != "" &&
        event.length > 0 &&
        sing != "" &&
        sing.length > 0 &&
        right_event == true
      ) {
        if (product_id == "604a0b6e40962e00088f0d79") {
          window.open(
            "/tmflow/contract/" +
            this.activatedRoute.snapshot.params._id +
            "/" +
            product_id +
            "/" +
            sing[0]._id,
            "_blank"
          );
          // const dialogRef = this.matDialog.open(Warrentycallfromcontract, {
          //   data: {
          //     data: this.userdetails, flag: 'tmflow'
          //   },
          //   panelClass: 'worksheet-modal',
          // });

          // dialogRef.afterClosed().subscribe((result) => { });
        }
        if (product_id == "604aff3691b7c80008ca11a8") {
          window.open(
            "/pece/contract/" +
            this.activatedRoute.snapshot.params._id +
            "/" +
            product_id +
            "/" +
            sing[0]._id,
            "_blank"
          );
          // return;
          // let data = {
          //   lead_id: this.activatedRoute.snapshot.params._id,
          //   tech_id: this.userservice.userid,
          //   product_id: product_id,
          //   contractdata: {
          //     practicename: this.userdetails.firstname,
          //     address: this.userdetails.address,
          //     serial_Number: this.userdetails.Serial_Number,
          //     addedondatetime: Math.round(new Date().getTime()),
          //   },
          //   'contractflag': 'Pece Contract',
          //   contracts: [
          //     {
          //       status: 'request',
          //       added_by_id: this.userservice.userid,
          //       addedby: this.userservice.fullname,
          //       addedondatetime: Math.round(new Date().getTime()),
          //     },
          //   ],
          //   contact_id: sing[0]._id,
          // };
          // console.log(data);
          // this.apiservice
          //   .getDataWithoutToken(data, 'api/request-contracts')
          //   .subscribe((res: any) => {
          //     this.openSnackBar(res.successmsg, 'ok');
          //     this.showData('contract')
          //   });
        }
      } else {
        if (right_event == false) {
          const dialogRef = this.matDialog.open(viewcontactssing, {
            data: {
              data_event: event,
              data_sing: sing,
              flag: right_event,
            },
            panelClass: "worksheet-modal",
          });

          dialogRef.afterClosed().subscribe((result) => {
            console.log(result);

            if (result && result.flag != null && result.flag == "signee") {
              this.showData("contact");

              this.addcontact = true;
              console.log(result, "addcontact", this.addcontact);

              this.listcontractview = false;
              this.flag = false;
            }
            if (result && result.flag != null && result.flag == "Discovery") {
              let send_data = {
                _id: product_id,
              };
              this.bookNow(send_data, 1, "discovery");
              // this.showData('worksheet');
            }
          });
        }
        if (
          event != "" &&
          event.length > 0 &&
          sing.length == 0 &&
          right_event == true
        ) {
          const dialogRef = this.matDialog.open(DialogOverviewSigneeDialog, {
            data: "",
            panelClass: "worksheet-modal",
          });

          dialogRef.afterClosed().subscribe((result) => {
            console.log(result);

            if (result == "contact") {
              this.showData("contact");
            }
          });
        }
      }
    }
    if (flag == "warrantyview") {
      window.open(
        "/tmflowfive/contract/" +
        this.activatedRoute.snapshot.params._id +
        "/" +
        sing[0]._id +
        "?flag=tmflowfive",
        "_blank"
      );
    }
    if (flag == "tmflowmedicalcontract") {
      window.open(
        "/tmflow/contract/" +
        this.activatedRoute.snapshot.params._id +
        "/" +
        product_id +
        "/" +
        sing[0]._id +
        "?flag=tmflowmedical",
        "_blank"
      );
    }
    if (flag == "peceviewcontract") {
      window.open(
        "/pece/contract/" +
        this.activatedRoute.snapshot.params._id +
        "/" +
        product_id +
        "/" +
        sing[0]._id +
        "?flag=peceviewcontract",
        "_blank"
      );
    }
    if (flag == "peceresendcontract") {
      window.open(
        "/pece/contract/" +
        this.activatedRoute.snapshot.params._id +
        "/" +
        product_id +
        "/" +
        sing[0]._id +
        "?flag=peceresendcontract",
        "_blank"
      );
    }
    if (flag == "warrantyresend") {
      // window.open(
      //   "/tmflowfive/contract/" +
      //   this.activatedRoute.snapshot.params._id +
      //   "/" +
      //   sing[0]._id +
      //   "?request=true",
      //   "_blank"
      // );
      let id = "";
      for (let keys in this.contractproducts[0].contracts) {
        if (
          this.contractproducts[0].contracts[keys].contractflag == "warrenty"
        ) {
          id = this.contractproducts[0].contracts[keys]._id;
        }
      }
      this.progressSpinner.loading = true;
      this.apiservice
        .customRequest_temporary(
          {},
          `zoho/send_for_signature_castom?id=${id}`,
          environment["api_url2"]
        )
        .subscribe((res: any) => {
          console.log(res);
          this.openSnackBar("Contract Resend Successfully ", "ok");
          this.showData("contract");
        });
      setTimeout(() => {
        this.openSnackBar("Contract Resend Successfully ", "ok");
        this.showData("contract");
        this.progressSpinner.loading = false;
      }, 5000);
    }
    if (flag == "creditapplication") {
      console.log(this.userdetails);

      let creditdata: any = {};
      console.log(
        "9874654231111111111111111111111222222222222222222222222210000"
      );
      creditdata.Legal_Company_Name = this.userdetails.fullname;
      creditdata.address = this.userdetails.address;
      creditdata.city = this.userdetails.city;
      creditdata.state = this.userdetails.state;
      creditdata.zip = this.userdetails.zip;
      creditdata.website = this.userdetails.Website;
      creditdata.email = this.userdetails.email;
      creditdata.phonetwo = this.userdetails.phone;
      creditdata.compane_name = this.userdetails.company;
      creditdata.preson_name =
        this.userdetails.singeealldata.length > 0
          ? this.userdetails.singeealldata[0].First_Name +
          " " +
          this.userdetails.singeealldata[0].Last_Name
          : "";
      creditdata.homeaddress =
        this.userdetails.singeealldata.length > 0
          ? this.userdetails.singeealldata[0].Mailing_Street +
          "," +
          this.userdetails.singeealldata[0].Mailing_City +
          "," +
          this.userdetails.singeealldata[0].Mailing_State +
          "," +
          this.userdetails.singeealldata[0].Mailing_Country
          : "";
      creditdata.city_two =
        this.userdetails.singeealldata.length > 0
          ? this.userdetails.singeealldata[0].Mailing_City
          : "";
      creditdata.state_two =
        this.userdetails.singeealldata.length > 0
          ? this.userdetails.singeealldata[0].Mailing_State
          : "";
      creditdata.zip_two =
        this.userdetails.singeealldata.length > 0
          ? this.userdetails.singeealldata[0].Mailing_Zip
          : "";
      creditdata.home_phone =
        this.userdetails.singeealldata.length > 0
          ? this.userdetails.singeealldata[0].Phone
          : "";
      creditdata.signer =
        this.userdetails.singeealldata.length > 0
          ? this.userdetails.singeealldata[0].First_Name +
          " " +
          this.userdetails.singeealldata[0].Last_Name
          : "";
      creditdata.compane_name =
        this.userdetails.singeealldata.length > 0
          ? this.userdetails.firstname
          : "";
      let data = {
        lead_id: this.activatedRoute.snapshot.params._id,
        tech_id: this.userservice.userid,
        product_id: environment.product_tmflow,

        contracts: [
          {
            status:
              "requested by " + JSON.parse(this.cookieService.get("type")),
            added_by_id: JSON.parse(this.cookieService.get("userid")),
            addedby:
              JSON.parse(this.cookieService.get("firstname")) +
              " " +
              JSON.parse(this.cookieService.get("lastname")),
            addedondatetime: Math.round(new Date().getTime()),
            type: JSON.parse(this.cookieService.get("type")),
          },
        ],
        contractflag: "credit",
        contractdata: creditdata,
        contact_id:
          this.userdetails.singeealldata.length > 0
            ? this.userdetails.singeealldata[0]._id
            : "",
      };
      console.log(data);

      this.apiservice
        .getDataWithoutToken(data, "api/request-contracts")
        .subscribe((res: any) => {
          this.openSnackBar("Contract Submitted ", "ok");
          this.showData("contract");
          // window.close();
        });
      // window.open(
      //   '/admin/credit/' +
      //   this.activatedRoute.snapshot.params._id +
      //   '/' +
      //   product_id,
      //   '_blank'
      // );
    }

    if (flag == "warrenty_approved") {
    }
  }
  download_contract(val: any) {
    if (val == "pece") {
      window.open(this.download_pdf_link_pece, "_blank");
    }
    if (val == "warranty") {
      window.open(this.download_pdf_link_warranty, "_blank");
    }
    if (val == "tmflow") {
      window.open(this.download_pdf_link_tmflow, "_blank");
    }
    if (val == "credit") {
      window.open(this.download_pdf_link_credit, "_blank");
    }
  }
  contractstatus(val, i, flag) {
    console.log(val, "row.newcontract_status", flag);

    let val_data = "";
    for (const key in val) {
      // val_data='';
      if (val && val[key].product_id == i) {
        console.log(
          val[key],
          "llllllllllkkkkkkkkkkkkkkkkkkkkkkkllllllllll",
          val_data
        );

        if (
          (val[key].contractflag == "credit" && flag == "credit") ||
          flag == "all" ||
          flag == "Warranty" ||
          flag == ""
        ) {
          // val_data = '';
          val_data = val[key].contracts;
          console.log(val[key], val_data);
        }
        // val_data = '';
        // if (flag == 'all' || flag == 'Warranty' || flag == '') {
        //   // val_data = '';
        //   console.log(val[key], 'llllllllllllllllllll', val_data);

        //   val_data = val[key].contracts;
        // }
      }
    }
    const dialogRef = this.matDialog.open(viewcontractstatusview, {
      data: {
        data: val_data,
      },
      panelClass: "worksheet-modal",
    });

    dialogRef.afterClosed().subscribe((result) => {
      this.showData("contract");
    });
  }
  getProductData() {
    console.log("user_details products ==>", this.user_details.products)
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
    this.progressSpinner.loading = true;
    let endpoint = "api/worksheet-async-data";
    this.product_list = [];
    let google_events = [];
    let worksheets = [];
    this.apiservice
      .customRequest(
        {
          user_id: this.activatedRoute.snapshot.params._id,
          searchcondition: {
            event_type: 2,
            status: 2,
            products: this.user_details.products
          },
        },
        endpoint,
        environment["api_url"]
      )
      .subscribe((resp: any) => {
        this.progressSpinner.loading = false;
        if (resp.status == "success") {
          let products = resp.result.products;

          resp = JSON.stringify(resp);
          resp = JSON.parse(resp);
          google_events = resp.result.google_events;

          worksheets = resp.result.worksheets;
          let product_details = resp.result.products;
          // console.log(this.userdetails,'5060');

          this.product_list = [];
          this.userdetails = this.userdetails;
          let product_merge_id = "";
          let product_merge_name = "";
          let product_arry_name = "";
          let pecetmflow = 0;
          let pecebioneo = 0;
          let biorst = 0;
          let pecetmflow_obj = {
            id: "",
            name: "",
          };
          let pecebioneo_obj = {
            id: "",
            name: "",
          };
          for (const i in product_details) {
            if (typeof this.userdetails.products != "undefined") {
              this.userdetails.products.forEach((element) => {
                // console.log(element,'element');
                if (product_details[i]._id === element) {
                  // console.log(product_details[i]._id,'product_details[i]._id');
                  this.product_list.push(JSON.parse(JSON.stringify(product_details[i])));

                  // // // // commented section as medigrade and bio$rst are no longer required // // // //

                  // if (product_details[i]._id == "604aff3691b7c80008ca11a8") {
                  //   this.product_list.push(
                  //     JSON.parse(JSON.stringify(product_details[i]))
                  //   );
                  //   pecetmflow++;
                  //   pecebioneo++;
                  // }
                  // if (product_details[i]._id == "604a0b6e40962e00088f0d79") {
                  //   this.product_list.push(
                  //     JSON.parse(JSON.stringify(product_details[i]))
                  //   );
                  //   pecetmflow++;
                  //   pecebioneo++;
                  // }
                  // if (product_details[i]._id == "612c883f118c110009f02820") {
                  //   // donot push data for bioenergetics or RST
                  //   pecebioneo++;
                  //   biorst++;
                  // }
                  // if (product_details[i]._id == "612c89242005f400082d5fb1") {
                  //   // donot push data for bioenergetics or RST
                  //   pecebioneo++;
                  //   biorst++;
                  // }
                  // if (pecebioneo == 4) {
                  //   pecebioneo_obj.id =
                  //     "604aff3691b7c80008ca11a8/604a0b6e40962e00088f0d79/612c883f118c110009f02820/612c89242005f400082d5fb1";
                  //   pecebioneo_obj.name = "Medigrade/BioEnergetics/RST Sanexas";
                  // }
                  // if (pecetmflow == 2) {
                  //   pecetmflow_obj.id =
                  //     "604aff3691b7c80008ca11a8/604a0b6e40962e00088f0d79";
                  //   pecetmflow_obj.name = "Medigrade";
                  // }
                  // if (biorst == 2) {
                  //   pecetmflow_obj.id =
                  //     "612c883f118c110009f02820/612c89242005f400082d5fb1";
                  //   pecetmflow_obj.name = "BioEnergetics/RST Sanexas";
                  // }
                  // if(i != '0'){
                  //   product_merge_id = product_merge_id +','+ product_details[i]._id;
                  //   product_merge_name = product_merge_name +', '+ product_details[i].productname;
                  // }else{
                  //   product_merge_id = product_details[i]._id;
                  //   product_merge_name = product_details[i].productname;
                  // }
                }
              });
            }
          }
          if (pecetmflow_obj.id != "" && pecetmflow_obj.name != "") {
            this.product_list.push({
              productname: pecetmflow_obj.name,
              _id: pecetmflow_obj.id,
            });
          }
          if (pecebioneo_obj.id != "" && pecebioneo_obj.name != "") {
            this.product_list.push({
              productname: pecebioneo_obj.name,
              _id: pecebioneo_obj.id,
            });
          }
          // removing bioenergertic and rst sensxas from product list
          console.log(this.product_list);
          this.product_list = JSON.parse(JSON.stringify(this.product_list));
          // console.log(JSON.parse(JSON.stringify(this.product_list)), '+++++=product_listproduct_list');
          // console.log(this.product_list, '+++++=product_listproduct_list');
          if (google_events.length > 0 || worksheets.length > 0) {
            if (google_events.length > 0) {
              for (let index = 0; index < this.product_list.length; index++) {
                // console.log(index);
                let flag = false;
                for (const j in google_events) {
                  console.log(
                    "event loop ==>",
                    this.product_list[index]._id.split("/"),
                    google_events[j].product_id
                  );
                  if (
                    this.product_list[index]._id != "" &&
                    String(this.product_list[index]._id.split("/")) ==
                    String(google_events[j].product_id)
                  ) {
                    console.log("event loop ==> Found !!!");

                    // this.product_list[i].booking_done = true;
                    // console.log(this.product_list);
                    flag = true;
                    this.product_list.splice(index, 1);
                    // console.log(this.product_list.length);
                    break;
                  }
                }
                // console.log(this.product_list.length);
                if (this.product_list.length == 0) {
                  index = 0;
                } else {
                  if (flag) {
                    index--;
                  }
                }
                // console.log(index);
              }
            }
            console.log(this.product_list, "listttttt");
            if (worksheets.length > 0) {
              // for (let i in this.product_list) {
              //   for (let j in worksheets) {
              //     if (this.product_list[i]._id == worksheets[j].product_id) {
              //       this.product_list[i].worksheets_done = true;
              //       this.product_list[i].worksheet_data =
              //         worksheets[j].worksheet_data;
              //       this.product_list[i].worksheet_val =
              //         worksheets[j].worksheet_val;
              //     }
              //   }
              // }
              this.discovery_worksheet_flag = true;
            } else {
              this.discovery_worksheet_flag = false;
            }
            //console.log(this.product_list, '++++++++')
          }
          // else {
          //   this.product_list = resp.result.products;
          // }
        } else {
          this.openSnackBar("Something went wrong. Please try again.", null);
        }
      });
  }
  warrenty_call_block() {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
    this.progressSpinner.loading = true;
    this.warranty_btn_show = false;
    let endpoint = "api/worksheet-async-data";
    let data = {
      user_id: this.activatedRoute.snapshot.params._id,
      searchcondition: {
        event_type: 5,
        status: 2,
      },
    };
    this.product_list = [];
    let google_events = [];
    let worksheets = [];
    this.apiservice
      .customRequest(data, endpoint, environment["api_url"])
      .subscribe((resp: any) => {
        this.progressSpinner.loading = false;
        if (resp.status == "success") {
          let products = resp.result.products;
          google_events = resp.result.google_events;
          worksheets = resp.result.worksheets;
          let flag = false;
          if (google_events.length > 0) {
            for (let j in google_events) {
              if (
                google_events[j].event_type == 5 ||
                google_events[j].event_type == "5"
              ) {
                flag = true;
              }
            }
            if (flag) {
              this.google_event_warranty_call = true;
            } else {
              this.google_event_warranty_call = false;
            }
          } else {
            this.google_event_warranty_call = false;
          }
          if (worksheets.length > 0) {
            this.worksheet_warrant_call = true;
          } else {
            this.worksheet_warrant_call = false;
          }
          this.warranty_btn_show = true;
        } else {
          this.openSnackBar("Something went wrong. Please try again.", null);
        }
      });
  }
  book_appointmentwarrenty() {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
    this.progressSpinner.loading = true;
    this.no_record_found_book_appointment_data = false;
    let endpoint = "api10/get-warranty-call-details-lead";
    let data = {
      user_id: this.activatedRoute.snapshot.params._id,
      searchcondition: {
        event_type_val: 5,
      },
      condition: {
        // condition for how much data show in one time
        limit: 2,
        skip: 0,
      },
      sort: {
        type: "desc", // data sort by decreasing order
        field: "_id", // data sort by first name
      },
    };
    this.product_list = [];
    let google_events = [];
    let worksheets = [];
    this.apiservice
      .getDataWithoutToken(data, endpoint)
      .subscribe((resp: any) => {
        this.progressSpinner.loading = false;
        if (resp.status == "success") {
          this.book_appointment_data = resp.results.res;
          if (this.book_appointment_data.length == 0) {
            this.no_record_found_book_appointment_data = true;
          }
        }
      });
  }
  warranty_cancel(val: any, flag: any) {
    const dialogRef = this.matDialog.open(DialogCancelAlert);
    dialogRef.afterClosed().subscribe((result) => {
      // console.log(`Dialog result: ${result}`);
      console.log(result, "testingggggggggggggggggggggggggggggggggggg");
      if (result.flag == true) {
        // google_events
        this.progressSpinner.loading = true;
        let note = result.data;
        let google_events_data: any = {
          userid: JSON.parse(this.cookieService.get("userid")),
          source: "google_events",
          token: this.cookieService.get("jwtToken"),
          note: note,
        };
        this.apiservice
          .customRequest(
            google_events_data,
            "get-refresh-token",
            environment["api_calender_url"]
          )
          .subscribe((response: any) => {
            console.log("responseresponseresponse", response);
            let r_token = response.refresh_token;
            console.log(val, "valllllllllllllllllll");
            const data: any = {
              token: this.cookieService.get("jwtToken"),
              _id: val._id,
              attende_email: val.attende_email,
              attende_name: val.attende_name,
              attende_type: val.attende_type,
              useremail: val.useremail,
              appointment_date: val.startdate,
              username: val.username,
              slot: val.slot,
              event_type: val.event_type,
              event_title: val.event_title,
              // 'appointment_date': '01/27/2021',
              refresh_token: r_token,
              eid: val.eid,
              slotTimeActual: val.slotTimeActual,
              googleevent: val.googleevent,
              note: note,
            };
            // console.log(data, 'data')
            this.apiservice
              .customRequest(
                data,
                "google-calendar-event/delete-booked-event",
                environment["google_calendar_api"]
              )
              .subscribe((response: any) => {
                // console.log('response', response);
                setTimeout(() => {
                  this.progressSpinner.loading = false;
                  if (flag == "warranty") {
                    // this.showData('book_appointmentwarrenty');
                    this.showData("warrenty_call_block");
                  }
                  if (flag == "discovery") {
                    this.showData("worksheet");
                  }
                  if (flag == "ganeral") {
                    this.showData("appoinmentgenarelcall");
                  }
                }, 10);
              });

            this.apiservice
              .customRequest(
                data,
                "delete-booked-event",
                environment["api_calender_url"]
              )
              .subscribe((response: any) => {
                console.log(response, "responsedelete");
              });
          });
      }
    });
  }
  warranty_reschedule(val: any) {
    window.open(
      "/calendar-management/manage-appointment/reschedule-appointments/" +
      val._id +
      "/" +
      val.event_type_val,
      "_blank"
    );
  }
  warranty_view() { }
  getcontractlist() {
    // this.flag = false;
    this.listcontractview = true;
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
    this.progressSpinner.loading = true;
    this.contractListdatatable = [];
    const endpointc = "api/practiceContracts-list-count";
    const endpoint = "api/practiceContracts-list";
    const data: any = {
      // How many data showing in first page
      secret: this.cookieService.get("secret"),
      token: this.cookieService.get("jwtToken"),
      source: "",
      condition: {
        // condition for how much data show in one time
        limit: 10,
        skip: 0,
      },
      sort: {
        type: "desc", // data sort by decreasing order
        field: "_id", // data sort by first name
      },
      searchcondition: { leadID: this.activatedRoute.snapshot.params._id },
    };
    this.apiservice
      .getDataWithoutToken(data, endpoint)
      .subscribe((res: any) => {
        this.contractListdatatable = res.results.res;
        if (this.contractListdatatable.length == 0) {
          this.contactnorecordfound = true;
          this.addcontact = true;
          this.listcontractview = false;
          this.flag = true;
          this.flag1 = true;
        } else {
          this.flag = false;
        }
        this.initial = true;
        console.log(this.contractListdatatable, "leadlist contact");

        this.apiservice
          .getDataWithoutToken(data, endpointc)
          .subscribe((res: any) => {
            this.contract_datesearchsourcecount = 0;
            this.contract_datesearchsourcecount = res.count;
            console.log(res.count, "qqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqq");
            this.progressSpinner.loading = false;
          });
      });
    // this.datesearchsourcecount = 0;
  }
  show_more_warranty_call() {
    window.open(
      "/calendar-management/manage-appointment/warranty-appointments",
      "_blank"
    );
  }
  show_more_discovery_call() {
    window.open(
      "/calendar-management/manage-appointment/lead-appointments",
      "_blank"
    );
  }
  show_more_ganeral_call() {
    window.open(
      "/calendar-management/manage-appointment/my-appointments",
      "_blank"
    );
  }
  attachmentlist() {
    this.listattachmentview = true;
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
    this.progressSpinner.loading = true;
    this.attachmentListdatatable = [];
    const endpointc = "api8/practice-attachment-list-count";
    const endpoint = "api8/practice-attachment-list";
    let attachment_data = {
      // How many data showing in first page
      secret: this.cookieService.get("secret"),
      token: this.cookieService.get("jwtToken"),
      source: "",
      condition: {
        // condition for how much data show in one time
        limit: 10,
        skip: 0,
      },
      sort: {
        type: "desc", // data sort by decreasing order
        field: "createdon_datetime", // data sort by first name
      },
      searchcondition: { leadID: this.activatedRoute.snapshot.params._id },
    };
    this.apiservice
      .getDataWithoutToken(attachment_data, endpoint)
      .subscribe((res: any) => {
        console.log(res, "attachment");
        this.attachmentListdatatable = res.results.res;
        this.apiservice
          .getDataWithoutToken(attachment_data, endpointc)
          .subscribe((res: any) => {
            this.attachment_datesearchsourcecount = 0;
            this.attachment_datesearchsourcecount = res.count;
            console.log(res.count, "attachment count");
            this.progressSpinner.loading = false;
          });
      });
  }
  openSnackBar(message: string, action: string = null) {
    this.snackBar.open(message, action, {
      duration: 5000,
    });
  }

  bookNowcall() { }
  // booking fun
  bookNow(val, flag, msg) {
    // if (flag == 1) {
    //   this.progressSpinner.loading = true;

    //   //console.log(val, this.activatedRoute.snapshot.params)

    //   let dataVal: any = {
    //     user_id: this.activatedRoute.snapshot.params._id,
    //     product_id: val._id,
    //   };

    //   //console.log(dataVal);

    //   this.apiservice
    //     .customRequest(
    //       dataVal,
    //       'api/work-sheet-data-count',
    //       environment['api_url']
    //     )
    //     .subscribe((response: any) => {
    //       if (response.status == 'success') {
    //         this.progressSpinner.loading = false;

    //         if (
    //           response.count > 0 &&
    //           response.result[0] != null &&
    //           (response.result[0].worksheet_val == 1 ||
    //             response.result[0].worksheet_val == 2)
    //         ) {
    //           window.open(
    //             '/calendar-management/manage-appointment/discovery/book-appointment/' +
    //             val._id +
    //             '/' +
    //             response.result[0].worksheet_val +
    //             '/' +
    //             this.userdetails._id +
    //             '/' +
    //             this.userdetails.email +
    //             '/' +
    //             this.userdetails.type,
    //             '_blank'
    //           );
    //         } else {
    //           this.completeWorksheet(val, 3, 'add');
    //         }
    //       } else {
    //         this.openSnackBar('Something went wrong. Please try again.', null);
    //       }
    //     });
    // } else {
    //   window.open(
    //     '/calendar-management/manage-appointment/lead-appointments',
    //     '_blank'
    //   );
    // }
    console.log(val, "val", flag);

    if (flag == 1) {
      this.progressSpinner.loading = true;
      let data = {
        user_id: this.activatedRoute.snapshot.params._id,
        searchcondition: {
          leadID: this.activatedRoute.snapshot.params._id,
          isSignee: true,
        },
      };
      this.apiservice
        .getDataWithoutToken(data, "api10/contact_questioner_list")
        .subscribe((res: any) => {
          console.log(res);
          // delete res.result.contact;
          this.progressSpinner.loading = false;
          if (res.result.questioner == 0) {
            const dialogRef = this.matDialog.open(
              DialogOverviewWarrentyCallBlockDialog,
              {
                // width: '250px',
                data: {
                  data: res.result,
                  flag: "contact_questionnaire_cancel",
                  msg: msg,
                },
              }
            );

            dialogRef.afterClosed().subscribe((result) => {
              console.log("The dialog was closed", result);
              if (result == "questionnaire") {
                this.showData("questionnaire");
                // this.completeWorksheet_warrenty('', 1,'add');
              }
              if (result == "contact") {
                this.showData("contact");
              }
              if (result == "cancel") {
                console.log("discovery_appointment_endpoint val ==>", val);
                this.discovery_appointment_endpoint(val);

                // const dialogRefcancle = this.matDialog.open(
                //   DialogOverviewWarrentyCallBlockDialog,
                //   {
                //     // width: '250px',
                //     data: {
                //       data: res.result,
                //       flag: 'cancel',
                //     },
                //   }
                // );
                // dialogRefcancle.afterClosed().subscribe((result) => {
                //   if (result == 'ok') {
                //     this.discovery_appointment_endpoint(val);
                //   }
                // });
                // let snackBarRef = this.snackBar.open('You must complete questionnaire and add contact within 48 hours else your appointment can even get cancelled.', 'Ok');
                // snackBarRef.onAction().subscribe(() => {
                //   this.discovery_appointment_endpoint(val);
                // });
              }
            });
          } else {
            this.discovery_appointment(val);
          }
        });
    }
    if (flag == 2) {
      window.open(
        "/calendar-management/manage-appointment/lead-appointments",
        "_blank"
      );
    }
  }
  discovery_appointment(val: any) {
    console.log(val, "discovery_appointment");
    window.open(
      "/calendar-management/manage-appointment/discovery/book-appointment/" +
      val._id +
      "/" +
      1 +
      "/" +
      this.userdetails._id
    );
  }
  discovery_appointment_endpoint(val: any) {
    this.progressSpinner.loading = true;
    let dataVal: any = {
      user_id: this.activatedRoute.snapshot.params._id,
      product_id: val._id,
    };
    this.apiservice
      .customRequest(
        dataVal,
        "api/work-sheet-data-count",
        environment["api_url"]
      )
      .subscribe((response: any) => {
        console.log(response, "response discovery");

        if (response.status == "success") {
          this.progressSpinner.loading = false;

          // changing product1/product2.. to product1_product2.. because of param problem
          val._id = String(val._id).replace("/", "_");
          console.log(val, "discovery_appointment_endpoint");

          window.open(
            "/calendar-management/manage-appointment/discovery/book-appointment/" +
            val._id +
            "/" +
            1 +
            "/" +
            this.userdetails._id +
            "/" +
            this.userdetails.email +
            "/" +
            this.userdetails.type,
            "_blank"
          );
        } else {
          this.openSnackBar("Something went wrong. Please try again.", null);
        }
      });
  }
  bookNow_warrenty(flag, msg) {
    if (flag == 1) {
      this.progressSpinner.loading = true;
      let data = {
        user_id: this.activatedRoute.snapshot.params._id,
        searchcondition: {
          leadID: this.activatedRoute.snapshot.params._id,
          isSignee: true,
        },
      };
      this.apiservice
        .getDataWithoutToken(data, "api10/contact_questioner_list")
        .subscribe((res: any) => {
          console.log(res);
          this.progressSpinner.loading = false;
          if (res.result.questioner == 0) {
            const dialogRef = this.matDialog.open(
              DialogOverviewWarrentyCallBlockDialog,
              {
                // width: '250px',
                data: {
                  data: res.result,
                  flag: "contact_questionnaire_cancel",
                  msg: msg,
                },
              }
            );

            dialogRef.afterClosed().subscribe((result) => {
              console.log("The dialog was closed");
              if (result == "contact") {
                this.showData("contact");
                this.addcontract();
              }
              if (result == "questionnaire") {
                this.showData("questionnaire");
                // this.completeWorksheet_warrenty('', 1,'add');
              }
              if (result == "cancel") {
                this.progressSpinner.loading = true;
                let dataVal: any = {
                  user_id: this.activatedRoute.snapshot.params._id,
                };
                this.apiservice
                  .customRequest(
                    dataVal,
                    "api8/skipwarranty",
                    environment["api_url"]
                  )
                  .subscribe((response: any) => {
                    console.log(response, "response warranty call");
                    if (response.status == "success") {
                      this.progressSpinner.loading = false;
                      window.open(
                        "calendar-management/manage-appointment/warranty/book-appointment/" +
                        1 +
                        "/" +
                        this.activatedRoute.snapshot.params._id,
                        "_blank"
                      );
                    } else {
                      this.openSnackBar(
                        "Something went wrong. Please try again.",
                        null
                      );
                    }
                  });
                // window.open(
                //   'calendar-management/manage-appointment/warranty/book-appointment/' +
                //   this.activatedRoute.snapshot.params._id,
                //   '_blank'
                // );
                // const dialogRefcancle = this.matDialog.open(
                //   DialogOverviewWarrentyCallBlockDialog,
                //   {
                //     // width: '250px',
                //     data: {
                //       data: res.result,
                //       flag: 'cancel',
                //     },
                //   }
                // );
                // dialogRefcancle.afterClosed().subscribe((result) => {
                //   if (result == 'ok') {
                //     this.progressSpinner.loading = true;
                //     let dataVal: any = {
                //       user_id: this.activatedRoute.snapshot.params._id,
                //     };
                //     this.apiservice
                //       .customRequest(
                //         dataVal,
                //         'api8/skipwarranty',
                //         environment['api_url']
                //       )
                //       .subscribe((response: any) => {
                //         console.log(response, 'response warranty call');

                //         if (response.status == 'success') {
                //           this.progressSpinner.loading = false;
                //           window.open(
                //             'calendar-management/manage-appointment/warranty/book-appointment/' +
                //             1 +
                //             '/' +
                //             this.activatedRoute.snapshot.params._id,
                //             '_blank'
                //           );
                //         } else {
                //           this.openSnackBar(
                //             'Something went wrong. Please try again.',
                //             null
                //           );
                //         }
                //       });
                //     // window.open(
                //     //   'calendar-management/manage-appointment/warranty/book-appointment/' +
                //     //   this.activatedRoute.snapshot.params._id,
                //     //   '_blank'
                //     // );
                //   }
                // });
                // let snackBarRef = this.snackBar.open('You must complete questionnaire and add contact within 48 hours else your appointment can even get cancelled.', 'Ok');
                // snackBarRef.onAction().subscribe(() => {
                //   window.open('calendar-management/manage-appointment/warranty/book-appointment/' +
                //     this.activatedRoute.snapshot.params._id, '_blank');
                // });
              }
            });
          } else {
            window.open(
              "calendar-management/manage-appointment/warranty/book-appointment/" +
              this.activatedRoute.snapshot.params._id,
              "_blank"
            );
          }
        });
    }
    if (flag == 2) {
      // window.open('/calendar-management/manage-appointment/warranty-appointments', '_blank');
      this.showData("book_appointmentwarrenty");
    }
  }

  bookNowcalluniersaltech() {
    window.open(
      "calendar-management/manage-appointment/discovery/book-appointment/61adb3204f4bdb00081b2872/1/" +
      this.activatedRoute.snapshot.params._id
    );
  }
  bookNowcallTmFlow() {
    window.open(
      "calendar-management/manage-appointment/discovery/book-appointment/604a0b6e40962e00088f0d79/1/" +
      this.activatedRoute.snapshot.params._id
    );
  }
  // complete Worksheet fun
  completeWorksheet(val, flag, formtype) {
    // this.progressSpinner.loading = true;
    console.log(val);
    // return;

    // this.questionnaireflag = true;
    // this.worksheetflag = false;
    this.myinput = val;
    this.myinput.user_details = this.userdetails;
    this.myinput.user_details_email = this.userdetails.email;
    this.myinput.user_details_type = this.userdetails.type;
    this.myinput.flag = flag;
    this.myinput.product_id = this.product_array;

    this.myinput.formtype = formtype;

    console.log(this.myinput);
    this.showData("questionnaire");
    // let countryJson = [];
    // this.apiservice.getCountry().subscribe((res) => {
    //   for (let i in res) {
    //     countryJson.push({ val: res[i].name, name: res[i].name });
    //   }
    // });

    // //console.log(val, flag);

    // let product_data: any = {
    //   user_id: this.activatedRoute.snapshot.params._id,
    //   product_id: val._id,
    //   productname: val.productname,
    // };

    // this.apiservice
    //   .customRequest(
    //     product_data,
    //     'api/work-sheet-data-count',
    //     environment['api_url']
    //   )
    //   .subscribe((response: any) => {
    //     if (response.status == 'success') {
    //       this.progressSpinner.loading = false;

    //       let worksheetSaveVAl = [];

    //       if (response.result != null && response.result.length > 0) {
    //         worksheetSaveVAl = response.result;
    //       }

    //       //console.log(product_data, '++++++');
    //       const dialogRef = this.matDialog.open(WorkSheetComponent, {
    //         data: {
    //           product_data: product_data,
    //           countryJson: countryJson,
    //           worksheetSaveVAl: worksheetSaveVAl,
    //           falgVal: flag,
    //         },
    //         panelClass: 'worksheet-modal',
    //       });
    //       dialogRef.disableClose = true;
    //       dialogRef.afterClosed().subscribe((result) => {
    //         if (
    //           result.flag != null &&
    //           (result.flag == 'submit' ||
    //             result.flag == 'skip' ||
    //             result.flag == 'save')
    //         ) {
    //           console.log('close work sheet', result);
    //           if (result.falgVal == 3) {
    //             this.router.navigateByUrl(
    //               '/calendar-management/manage-appointment/discovery/book-appointment/' +
    //               val._id +
    //               '/' +
    //               result.worksheet_val +
    //               '/' +
    //               this.userdetails._id +
    //               '/' +
    //               this.userdetails.email +
    //               '/' +
    //               this.userdetails.type
    //             );
    //           } else {
    //             this.getProductData();
    //           }
    //         }
    //         // else {
    //         //   this.openSnackBar('Worksheet Submission is Incomplete.', null);
    //         // }
    //       });
    //     }
    //   });
  }
  completeWorksheet_warrenty(flag, formtype) {
    this.progressSpinner.loading = true;
    // this.questionnaireflag = true;
    // this.warrenty_call_block_flag = false;
    this.myinput = {
      user_details: this.userdetails,
    };
    this.myinput.product_id = this.product_array;
    this.myinput.user_details = this.userdetails;
    this.myinput.user_details_email = this.userdetails.email;
    this.myinput.user_details_type = this.userdetails.type;
    this.myinput.flag = flag;
    this.myinput.formtype = formtype;
    console.log(this.myinput);
    setTimeout(() => {
      this.progressSpinner.loading = false;
    }, 2000);
    this.showData("questionnaire");
  }
}
//*****************************communication-modal**********************************//

@Component({
  selector: "communication",
  templateUrl: "./communication-modal.html",
  styleUrls: ["./folder-view.component.css"],
})
export class CommunicationComponent implements OnInit {
  public formdata: any;
  // formdata
  public formfieldrefresh: boolean = true;
  public formfieldrefreshdata: any = null;
  public progressSpinner: any = {
    mode: "indeterminate",
    loading: false,
  };
  public lead_list: any = [];
  constructor(
    public dialogRef: MatDialogRef<CommunicationComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public apiservice: ApiService,
    public snackBar: MatSnackBar
  ) {
    console.log(data, "data");
    this.lead_list = data.lead_data;
    // this.formdata = {                                        // from start
    //   successmessage: 'Added Successfully !!', // success message
    //   submittext: 'Send',
    //   apiUrl: this.apiservice.api_url,
    //   endpoint: '',
    //   jwttoken: this.apiservice.jwttoken,
    //   // cancelroute: '', // use for redirect after cancel the form

    //   fields: [
    //     {
    //       label: 'Send To',
    //       name: 'sent_to',
    //       val: this.lead_list,
    //       type: 'select',
    //       multiple: true,
    //       validations: [
    //         { rule: 'required', message: ' Send to is required' },
    //       ]
    //     },
    //     {
    //       label: 'Subject',
    //       name: 'subject',
    //       value: '',
    //       type: 'textarea',
    //       validations: [
    //         { rule: 'required', message: ' subject is required' },
    //       ]
    //     },
    //     {
    //       label: 'Description',
    //       name: 'template',
    //       value: '',
    //       type: 'textarea',
    //       validations: [
    //         { rule: 'required', message: ' template is required' },
    //       ]
    //     },
    //     {
    //       label: 'Priority',
    //       name: 'priority',
    //       value: '',
    //       type: 'number',
    //       validations: [

    //       ]
    //     },
    //   ]

    // };
  }

  ngOnInit(): void { }

  closedModal() {
    this.data.flag = "no";
    this.dialogRef.close(this.data.flag);
  }

  openSnackBar(message: string, action: string = null) {
    this.snackBar.open(message, action, {
      duration: 3000,
    });
  }

  continue() { }

  listenFormFieldChange(val: any) {
    console.log(val, "++=------------------------++");

    if (val.field == "fromsubmit" && val.fieldval == "success") {
      this.progressSpinner.loading = true;

      this.data.flag = "submit";
      this.data.val = val;
      // this.data.user_name = ;

      let form_data = {
        email_data: val.fromval,
        user_id: this.data.user_id,
      };
      // return;
      this.apiservice
        .customRequest(
          form_data,
          "api/send-communication-email",
          environment["api_url"]
        )
        .subscribe((response: any) => {
          if (response.status == "success") {
            this.progressSpinner.loading = false;
            this.openSnackBar("Email Send Successfully.", null);
            this.dialogRef.close(this.data);
          } else {
            this.openSnackBar("Something went wrong. Please try again.", null);
          }
        });
    }
  }
}

//*****************************work-sheet-modal**********************************//

@Component({
  selector: "work-sheet",
  templateUrl: "./work-sheet-modal.html",
  styleUrls: ["./folder-view.component.css"],
})
export class WorkSheetComponent implements OnInit {
  isLinear = true; // Stepper

  public formdata: any = {};
  public formfieldrefresh: boolean = true;
  formfieldrefreshdata: any = null;
  public header_text: any = "Universal Tech Associates";

  public progressSpinner: any = {
    mode: "indeterminate",
    loading: false,
    bookingStatus: "Sending request",
  };
  public formflag: boolean = false;

  public skipWorksheetflag: boolean = false;
  public skipWorksheetVal: any = "";
  public error_msg: any = "";

  symptomArray;

  constructor(
    public dialogRef: MatDialogRef<WorkSheetComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public apiservice: ApiService,
    public cookieService: CookieService,
    public snackBar: MatSnackBar
  ) {
    this.header_text = data.product_data.productname;

    console.log(data, "++++++Work Sheet++++++");

    // MedWorldOne Questionnaire:
    if (data.product_data.product_id == "5ff567e8bad2240008b33c9f") {
      this.formflag = true;
      this.formdata = {
        successmessage: "Submitted Successfully !!",
        submitactive: true, // optional, default true
        submittext: "Submit",
        jwttoken: this.cookieService.get("jwtToken"),
        // cancelroute: '',
        custombuttons: [
          {
            name: "save",
            label: "Save",
            class: "savecls",
            type: "button",
          },
        ],
        fields: [
          {
            label: "What was your revenue the last complete year of business?",
            name: "complete_revenue",
            value: "",
            type: "text",
            validations: [{ rule: "required", message: "field is required" }],
          },
          {
            label: "Have they registered a company for at least 2 years?",
            name: "company_registered",
            type: "radio",
            validations: [{ rule: "required", message: "field is required" }],
            val: [
              { key: 1, val: "Yes" },
              { key: 0, val: "No" },
            ],
            value: "",
          },
          {
            label:
              "If they have had investments, what dollar amount have they raised?",
            name: "investments  ",
            value: "",
            type: "text",
            validations: [{ rule: "required", message: "field is required" }],
          },
          {
            label:
              "Is the person getting on the call able to make a financial decision for the company?",
            name: "company_financial",
            value: "",
            type: "radio",
            validations: [{ rule: "required", message: "field is required" }],
            val: [
              { key: 1, val: "Yes" },
              { key: 0, val: "No" },
            ],
          },
          {
            label:
              "Give all links to relevant websites, studies or white papers that we may be able to review.",
            name: "relevant_website",
            value: "",
            type: "text",
            validations: [{ rule: "required", message: "field is required" }],
          },
          {
            label: " Is this technology?",
            name: "technology",
            type: "radio",
            validations: [{ rule: "required", message: "field is required" }],
            val: [
              { key: 1, val: "Yes" },
              { key: 0, val: "No" },
            ],
            value: 0,
          },
          {
            label: " What countries are they currently doing business in?",
            name: "country",
            type: "select",
            validations: [{ rule: "required", message: "filed is required" }],
            val: data.countryJson,
          },
          {
            label:
              "Do they have a minimum of $50,000 to invest in the development of international partnerships?",
            name: "partnerships_development",
            value: "",
            type: "text",
            validations: [{ rule: "required", message: "filed is required" }],
          },
          {
            label: "Give a brief description of what they are looking for:",
            name: "description",
            value: "",
            type: "textarea",
            validations: [{ rule: "required", message: "filed is required" }],
          },
        ],
      };

      if (
        data.worksheetSaveVAl != null &&
        data.worksheetSaveVAl.length > 0 &&
        data.worksheetSaveVAl[0].worksheet_data != null
      ) {
        this.formdata.fields[0].value =
          data.worksheetSaveVAl[0].worksheet_data.complete_revenue;
        this.formdata.fields[1].value =
          data.worksheetSaveVAl[0].worksheet_data.company_registered;
        this.formdata.fields[2].value =
          data.worksheetSaveVAl[0].worksheet_data.investments;
        this.formdata.fields[3].value =
          data.worksheetSaveVAl[0].worksheet_data.company_financial;
        this.formdata.fields[4].value =
          data.worksheetSaveVAl[0].worksheet_data.relevant_website;
        this.formdata.fields[5].value =
          data.worksheetSaveVAl[0].worksheet_data.technology;
        this.formdata.fields[6].value =
          data.worksheetSaveVAl[0].worksheet_data.country;
        this.formdata.fields[7].value =
          data.worksheetSaveVAl[0].worksheet_data.partnerships_development;
        this.formdata.fields[8].value =
          data.worksheetSaveVAl[0].worksheet_data.description;
      }

      if (data.falgVal == 3) {
        delete this.formdata.custombuttons;
      }
    }
    // InfluxIQ Marketing Systems Questionnaire:
    if (data.product_data.product_id == "604a0b6e40962e00088f0d79") {
      this.formflag = true;
      this.formdata = {
        successmessage: "Submitted Successfully !!",
        submitactive: true, // optional, default true
        submittext: "Submit",
        jwttoken: this.cookieService.get("jwtToken"),
        // cancelroute: '',
        custombuttons: [
          {
            name: "save",
            label: "Save",
            class: "savecls",
            type: "button",
          },
        ],
        fields: [
          {
            label: "What was your revenue the last complete year of business?",
            name: "complete_revenue",
            value: "",
            type: "text",
            validations: [{ rule: "required", message: "field is required" }],
          },
          {
            label: "Have they registered a company for at least 2 years?",
            name: "company_registered",
            type: "radio",
            validations: [{ rule: "required", message: "field is required" }],
            val: [
              { key: 1, val: "Yes" },
              { key: 0, val: "No" },
            ],
            value: "",
          },
          {
            label:
              "If they have had investments, what dollar amount have they raised?",
            name: "investments  ",
            value: "",
            type: "text",
            validations: [{ rule: "required", message: "field is required" }],
          },
          {
            label:
              "Is the person getting on the call able to make a financial decision for the company?",
            name: "company_financial",
            value: "",
            type: "radio",
            validations: [{ rule: "required", message: "field is required" }],
            val: [
              { key: 1, val: "Yes" },
              { key: 0, val: "No" },
            ],
          },
          {
            label:
              "Give all links to relevant websites, studies or white papers that we may be able to review.",
            name: "relevant_website",
            value: "",
            type: "text",
            validations: [{ rule: "required", message: "field is required" }],
          },
          {
            label: " Is this technology?",
            name: "technology",
            type: "radio",
            validations: [{ rule: "required", message: "field is required" }],
            val: [
              { key: 1, val: "Yes" },
              { key: 0, val: "No" },
            ],
            value: 0,
          },
          {
            label: " What countries are they currently doing business in?",
            name: "country",
            type: "select",
            validations: [{ rule: "required", message: "filed is required" }],
            val: data.countryJson,
          },
          {
            label:
              "Do they have a minimum of $50,000 to invest in the development of international partnerships?",
            name: "partnerships_development",
            value: "",
            type: "text",
            validations: [{ rule: "required", message: "filed is required" }],
          },
          {
            label: "Give a brief description of what they are looking for:",
            name: "description",
            value: "",
            type: "textarea",
            validations: [{ rule: "required", message: "filed is required" }],
          },
        ],
      };

      if (
        data.worksheetSaveVAl != null &&
        data.worksheetSaveVAl.length > 0 &&
        data.worksheetSaveVAl[0].worksheet_data != null
      ) {
        this.formdata.fields[0].value =
          data.worksheetSaveVAl[0].worksheet_data.complete_revenue;
        this.formdata.fields[1].value =
          data.worksheetSaveVAl[0].worksheet_data.company_registered;
        this.formdata.fields[2].value =
          data.worksheetSaveVAl[0].worksheet_data.investments;
        this.formdata.fields[3].value =
          data.worksheetSaveVAl[0].worksheet_data.company_financial;
        this.formdata.fields[4].value =
          data.worksheetSaveVAl[0].worksheet_data.relevant_website;
        this.formdata.fields[5].value =
          data.worksheetSaveVAl[0].worksheet_data.technology;
        this.formdata.fields[6].value =
          data.worksheetSaveVAl[0].worksheet_data.country;
        this.formdata.fields[7].value =
          data.worksheetSaveVAl[0].worksheet_data.partnerships_development;
        this.formdata.fields[8].value =
          data.worksheetSaveVAl[0].worksheet_data.description;
      }

      if (data.falgVal == 3) {
        delete this.formdata.custombuttons;
      }
    }
    // Universal Tech Associates Questionnaire:

    if (data.product_data.product_id == "604afd0f4bb55500082eb18d") {
      this.formflag = true;
      this.formdata = {
        successmessage: "Submitted Successfully !!",
        submitactive: true, // optional, default true
        submittext: "Submit",
        jwttoken: this.cookieService.get("jwtToken"),
        // cancelroute: '',
        custombuttons: [
          {
            name: "save",
            label: "Save",
            class: "savecls",
            type: "button",
          },
        ],
        fields: [
          {
            label: "What was your revenue the last complete year of business?",
            name: "complete_revenue",
            value: "",
            type: "text",
            validations: [{ rule: "required", message: "field is required" }],
          },
          {
            label: "Have they registered a company for at least 2 years?",
            name: "company_registered",
            type: "radio",
            validations: [{ rule: "required", message: "field is required" }],
            val: [
              { key: 1, val: "Yes" },
              { key: 0, val: "No" },
            ],
            value: "",
          },
          {
            label:
              "If they have had investments, what dollar amount have they raised?",
            name: "investments  ",
            value: "",
            type: "text",
            validations: [{ rule: "required", message: "field is required" }],
          },
          {
            label:
              "Is the person getting on the call able to make a financial decision for the company?",
            name: "company_financial",
            value: "",
            type: "radio",
            validations: [{ rule: "required", message: "field is required" }],
            val: [
              { key: 1, val: "Yes" },
              { key: 0, val: "No" },
            ],
          },
          {
            label:
              "Give all links to relevant websites, studies or white papers that we may be able to review.",
            name: "relevant_website",
            value: "",
            type: "text",
            validations: [{ rule: "required", message: "field is required" }],
          },
          {
            label: " Is this technology?",
            name: "technology",
            type: "radio",
            validations: [{ rule: "required", message: "field is required" }],
            val: [
              { key: 1, val: "Yes" },
              { key: 0, val: "No" },
            ],
            value: 0,
          },
          {
            label: " What countries are they currently doing business in?",
            name: "country",
            type: "select",
            validations: [{ rule: "required", message: "filed is required" }],
            val: data.countryJson,
          },
          {
            label:
              "Do they have a minimum of $50,000 to invest in the development of international partnerships?",
            name: "partnerships_development",
            value: "",
            type: "text",
            validations: [{ rule: "required", message: "filed is required" }],
          },
          {
            label: "Give a brief description of what they are looking for:",
            name: "description",
            value: "",
            type: "textarea",
            validations: [{ rule: "required", message: "filed is required" }],
          },
        ],
      };

      if (
        data.worksheetSaveVAl != null &&
        data.worksheetSaveVAl.length > 0 &&
        data.worksheetSaveVAl[0].worksheet_data != null
      ) {
        this.formdata.fields[0].value =
          data.worksheetSaveVAl[0].worksheet_data.complete_revenue;
        this.formdata.fields[1].value =
          data.worksheetSaveVAl[0].worksheet_data.company_registered;
        this.formdata.fields[2].value =
          data.worksheetSaveVAl[0].worksheet_data.investments;
        this.formdata.fields[3].value =
          data.worksheetSaveVAl[0].worksheet_data.company_financial;
        this.formdata.fields[4].value =
          data.worksheetSaveVAl[0].worksheet_data.relevant_website;
        this.formdata.fields[5].value =
          data.worksheetSaveVAl[0].worksheet_data.technology;
        this.formdata.fields[6].value =
          data.worksheetSaveVAl[0].worksheet_data.country;
        this.formdata.fields[7].value =
          data.worksheetSaveVAl[0].worksheet_data.partnerships_development;
        this.formdata.fields[8].value =
          data.worksheetSaveVAl[0].worksheet_data.description;
      }

      if (data.falgVal == 3) {
        delete this.formdata.custombuttons;
      }
    }
    // ApogeeInvent Questionnaire:
    if (data.product_data.product_id == "602e3966022d8d000841ca46") {
      this.formflag = true;
      this.formdata = {
        successmessage: "Submitted Successfully !!",
        submitactive: true, // optional, default true
        submittext: "Submit",
        jwttoken: this.cookieService.get("jwtToken"),
        // cancelroute: '',
        custombuttons: [
          {
            name: "save",
            label: "Save",
            class: "savecls",
            type: "button",
          },
        ],
        fields: [
          {
            label:
              "Projects all cost differently, but we require a minimum of $100k on all potential custom software builds.  Can your potential client afford this?",
            name: "cost",
            value: "",
            type: "text",
            validations: [{ rule: "required", message: "field is required" }],
          },
          {
            label:
              "Is the person getting on the call able to make a financial decision for the company?",
            name: "company_financial",
            value: 1,
            type: "radio",
            validations: [{ rule: "required", message: "field is required" }],
            val: [{ key: 1, val: "Yes" }],
          },
          {
            label: "Give a brief description of what they are looking for: ",
            name: "description",
            value: "",
            type: "textarea",
            validations: [{ rule: "required", message: "field is required" }],
          },
        ],
      };

      if (
        data.worksheetSaveVAl != null &&
        data.worksheetSaveVAl.length > 0 &&
        data.worksheetSaveVAl[0].worksheet_data != null
      ) {
        this.formdata.fields[0].value =
          data.worksheetSaveVAl[0].worksheet_data.cost;
        this.formdata.fields[1].value =
          data.worksheetSaveVAl[0].worksheet_data.company_financial;
        this.formdata.fields[2].value =
          data.worksheetSaveVAl[0].worksheet_data.description;
      }

      if (data.falgVal == 3) {
        delete this.formdata.custombuttons;
      }
    }

    // Beto Paredes Joint Venture Opportunity Questionnaire:
    if (data.product_data.product_id == "602e39bd15c7ce0008f2456f") {
      this.formflag = true;

      this.formdata = {
        successmessage: "Submitted Successfully !!",
        submitactive: true, // optional, default true
        submittext: "Submit",
        jwttoken: this.cookieService.get("jwtToken"),
        // cancelroute: '',
        custombuttons: [
          {
            name: "save",
            label: "Save",
            class: "savecls",
            type: "button",
          },
        ],
        fields: [
          {
            label:
              "Projects all cost differently, but we require a minimum of $500k on all potential Joint Venture Opportunities.  Can your potential client afford this?",
            name: "cost",
            value: "",
            type: "text",
            validations: [{ rule: "required", message: "field is required" }],
          },
          {
            label:
              "Is the person getting on the call able to make a financial decision for the company?",
            name: "company_financial",
            value: "",
            type: "radio",
            validations: [{ rule: "required", message: "field is required" }],
            val: [
              { key: 1, val: "Yes" },
              { key: 0, val: "No" },
            ],
          },
          {
            label:
              "If they have investments, what dollar amount have they raised?",
            name: "investments",
            value: "",
            type: "text",
            validations: [{ rule: "required", message: "field is required" }],
          },
          {
            label:
              "Give all links to relevant websites, studies or white papers that we may be able to review.",
            name: "relevant_website",
            value: "",
            type: "text",
            validations: [{ rule: "required", message: "field is required" }],
          },
          {
            label: "Give a brief description of what they are looking for: ",
            name: "description",
            value: "",
            type: "textarea",
            validations: [{ rule: "required", message: "field is required" }],
          },
        ],
      };

      if (
        data.worksheetSaveVAl != null &&
        data.worksheetSaveVAl.length > 0 &&
        data.worksheetSaveVAl[0].worksheet_data != null
      ) {
        this.formdata.fields[0].value =
          data.worksheetSaveVAl[0].worksheet_data.cost;
        this.formdata.fields[1].value =
          data.worksheetSaveVAl[0].worksheet_data.company_financial;
        this.formdata.fields[2].value =
          data.worksheetSaveVAl[0].worksheet_data.investments;
        this.formdata.fields[3].value =
          data.worksheetSaveVAl[0].worksheet_data.relevant_website;
        this.formdata.fields[4].value =
          data.worksheetSaveVAl[0].worksheet_data.description;
      }

      if (data.falgVal == 3) {
        delete this.formdata.custombuttons;
      }
    }
    // HPS Physicians Questionnaire:

    if (data.product_data.product_id == "604afd6a4bb55500082eb18e") {
      this.formflag = true;

      this.formdata = {
        successmessage: "Submitted Successfully !!",
        submitactive: true, // optional, default true
        submittext: "Submit",
        jwttoken: this.cookieService.get("jwtToken"),
        // cancelroute: '',
        custombuttons: [
          {
            name: "save",
            label: "Save",
            class: "savecls",
            type: "button",
          },
        ],
        fields: [
          {
            label:
              "Projects all cost differently, but we require a minimum of $500k on all potential Joint Venture Opportunities.  Can your potential client afford this?",
            name: "cost",
            value: "",
            type: "text",
            validations: [{ rule: "required", message: "field is required" }],
          },
          {
            label:
              "Is the person getting on the call able to make a financial decision for the company?",
            name: "company_financial",
            value: "",
            type: "radio",
            validations: [{ rule: "required", message: "field is required" }],
            val: [
              { key: 1, val: "Yes" },
              { key: 0, val: "No" },
            ],
          },
          {
            label:
              "If they have investments, what dollar amount have they raised?",
            name: "investments",
            value: "",
            type: "text",
            validations: [{ rule: "required", message: "field is required" }],
          },
          {
            label:
              "Give all links to relevant websites, studies or white papers that we may be able to review.",
            name: "relevant_website",
            value: "",
            type: "text",
            validations: [{ rule: "required", message: "field is required" }],
          },
          {
            label: "Give a brief description of what they are looking for: ",
            name: "description",
            value: "",
            type: "textarea",
            validations: [{ rule: "required", message: "field is required" }],
          },
        ],
      };

      if (
        data.worksheetSaveVAl != null &&
        data.worksheetSaveVAl.length > 0 &&
        data.worksheetSaveVAl[0].worksheet_data != null
      ) {
        this.formdata.fields[0].value =
          data.worksheetSaveVAl[0].worksheet_data.cost;
        this.formdata.fields[1].value =
          data.worksheetSaveVAl[0].worksheet_data.company_financial;
        this.formdata.fields[2].value =
          data.worksheetSaveVAl[0].worksheet_data.investments;
        this.formdata.fields[3].value =
          data.worksheetSaveVAl[0].worksheet_data.relevant_website;
        this.formdata.fields[4].value =
          data.worksheetSaveVAl[0].worksheet_data.description;
      }

      if (data.falgVal == 3) {
        delete this.formdata.custombuttons;
      }
    }
    // PECE Questionnaire:

    if (data.product_data.product_id == "604aff3691b7c80008ca11a8") {
      this.formflag = true;

      this.formdata = {
        successmessage: "Submitted Successfully !!",
        submitactive: true, // optional, default true
        submittext: "Submit",
        jwttoken: this.cookieService.get("jwtToken"),
        // cancelroute: '',
        custombuttons: [
          {
            name: "save",
            label: "Save",
            class: "savecls",
            type: "button",
          },
        ],
        fields: [
          {
            label:
              "Projects all cost differently, but we require a minimum of $500k on all potential Joint Venture Opportunities.  Can your potential client afford this?",
            name: "cost",
            value: "",
            type: "text",
            validations: [{ rule: "required", message: "field is required" }],
          },
          {
            label:
              "Is the person getting on the call able to make a financial decision for the company?",
            name: "company_financial",
            value: "",
            type: "radio",
            validations: [{ rule: "required", message: "field is required" }],
            val: [
              { key: 1, val: "Yes" },
              { key: 0, val: "No" },
            ],
          },
          {
            label:
              "If they have investments, what dollar amount have they raised?",
            name: "investments",
            value: "",
            type: "text",
            validations: [{ rule: "required", message: "field is required" }],
          },
          {
            label:
              "Give all links to relevant websites, studies or white papers that we may be able to review.",
            name: "relevant_website",
            value: "",
            type: "text",
            validations: [{ rule: "required", message: "field is required" }],
          },
          {
            label: "Give a brief description of what they are looking for: ",
            name: "description",
            value: "",
            type: "textarea",
            validations: [{ rule: "required", message: "field is required" }],
          },
        ],
      };

      if (
        data.worksheetSaveVAl != null &&
        data.worksheetSaveVAl.length > 0 &&
        data.worksheetSaveVAl[0].worksheet_data != null
      ) {
        this.formdata.fields[0].value =
          data.worksheetSaveVAl[0].worksheet_data.cost;
        this.formdata.fields[1].value =
          data.worksheetSaveVAl[0].worksheet_data.company_financial;
        this.formdata.fields[2].value =
          data.worksheetSaveVAl[0].worksheet_data.investments;
        this.formdata.fields[3].value =
          data.worksheetSaveVAl[0].worksheet_data.relevant_website;
        this.formdata.fields[4].value =
          data.worksheetSaveVAl[0].worksheet_data.description;
      }

      if (data.falgVal == 3) {
        delete this.formdata.custombuttons;
      }
    }
  }

  ngOnInit(): void { }

  listenFormFieldChange(val: any) {
    console.log(val, "++=++=====================================");
    // return;
    if (
      val.field.name != null &&
      val.field.name == "technology" &&
      val.fieldval != "" &&
      val.fieldval == 0
    ) {
      this.formfieldrefreshdata = {
        field: "addfromcontrol",
        value: {
          label: "is it full MVP?",
          name: "mvp",
          type: "radio",
          after: "technology",
          val: [
            { key: 1, val: "Yes" },
            { key: 0, val: "No" },
          ],
          value: "",
          validations: [{ rule: "required", message: "filed is required" }],
        },
      };
    }

    //for submit worksheet

    if (
      val.field.name != null &&
      val.field.name == "technology" &&
      val.fieldval == 1
    ) {
      this.formfieldrefreshdata = {
        field: "removefromcontrol",
        value: { name: "mvp" },
      };
    }

    if (val.field == "fromsubmit" && val.fieldval == "success") {
      this.progressSpinner.loading = true;

      this.data.flag = "submit";
      this.data.val = val;
      this.data.worksheet_val = 1;
      let form_data = {
        worksheet_data: val.fromval,
        worksheet_val: 1,
        user_id: this.data.product_data.user_id,
        product_id: this.data.product_data.product_id,
        token: this.cookieService.get("jwtToken"),
        secret: this.cookieService.get("secret"),
      };

      this.apiservice
        .customRequest(form_data, "api/work-sheet", environment["api_url"])
        .subscribe((response: any) => {
          if (response.status == "success") {
            this.progressSpinner.loading = false;

            this.openSnackBar("Worksheet Submit Successfully.", null);

            this.dialogRef.close(this.data);
          } else {
            this.openSnackBar("Something went wrong. Please try again.", null);
          }
        });
    }

    //for save worksheet
    if (val.field == "formdata" && val.fieldval == "formdataval") {
      this.progressSpinner.loading = true;

      this.data.flag = "save";
      this.data.val = val;

      let form_data = {
        worksheet_data: val.fromval,
        worksheet_val: 3,
        user_id: this.data.product_data.user_id,
        product_id: this.data.product_data.product_id,
        token: this.cookieService.get("jwtToken"),
        secret: this.cookieService.get("secret"),
      };

      this.apiservice
        .customRequest(form_data, "api/work-sheet", environment["api_url"])
        .subscribe((response: any) => {
          if (response.status == "success") {
            this.progressSpinner.loading = false;
            this.openSnackBar("Worksheet Save Successfully.", null);
            this.dialogRef.close(this.data);
          } else {
            this.openSnackBar("Something went wrong. Please try again.", null);
          }
        });
    }
  }
  closedModal() {
    this.data.flag = "no";
    this.dialogRef.close(this.data.flag);
  }

  openSnackBar(message: string, action: string = null) {
    this.snackBar.open(message, action, {
      duration: 3000,
    });
  }

  setSkipData(val) {
    //console.log(val)
    this.skipWorksheetflag = val;
  }

  //for skip worksheet
  continue() {
    this.error_msg = "";

    if (
      this.skipWorksheetVal != null &&
      this.skipWorksheetVal != "" &&
      typeof this.skipWorksheetVal != "undefined"
    ) {
      //console.log(this.skipWorksheetVal, 'worksheet_val++')

      let form_data = {
        worksheet_data: {},
        worksheet_val: 2,
        user_id: this.data.product_data.user_id,
        product_id: this.data.product_data.product_id,
        token: this.cookieService.get("jwtToken"),
        secret: this.cookieService.get("secret"),
      };
      this.progressSpinner.loading = true;

      this.apiservice
        .customRequest(form_data, "api/work-sheet", environment["api_url"])
        .subscribe((response: any) => {
          if (response.status == "success") {
            this.progressSpinner.loading = false;
            this.data.flag = "skip";
            this.data.worksheet_val = 2;
            this.dialogRef.close(this.data);
          } else {
            this.openSnackBar("Something went wrong. Please try again.", null);
          }
        });
    } else {
      this.error_msg = "Please choose option";
    }
  }
}
@Component({
  selector: "view-content",
  templateUrl: "./viewcontent.html",
  styleUrls: ["./folder-view.component.css"],
})
export class ViewContentComponent implements OnInit {
  public viewdata: any = [];
  public video_time: any;
  public videourl: any;
  public file_url: any;
  public html_content: any;
  public bucket_url: any =
    "https://beto-paredes-training-centre.s3.amazonaws.com/lesson-files/";

  constructor(
    public dialogRef: MatDialogRef<WorkSheetComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public apiservice: ApiService,
    public snackBar: MatSnackBar,
    public sanitizer: DomSanitizer
  ) {
    console.log(data);

    this.viewdata = data.data;
    if (data.video_url != null && typeof data.video_url != undefined) {
      this.videourl = this.sanitizer.bypassSecurityTrustResourceUrl(
        data.video_url.changingThisBreaksApplicationSecurity
      );
    }
    for (const key in this.viewdata) {
      if (this.viewdata[key].type == "file") {
        this.file_url =
          this.bucket_url + this.viewdata[key].file.fileservername;
      }
    }
    this.html_content = data.html_content;
    console.log(this.file_url);
  }
  ngOnInit(): void { }
  onStateChange(event) {
    // console.log(this.data.data.video_skippable, 'data_video')

    console.log(event, "state chn");
    console.log(
      event.target.playerInfo.duration,
      "///)",
      event.target.playerInfo.currentTime
    );

    //duration calculation
    var sec_num = parseInt(event.target.playerInfo.duration, 10);
    var hours: any = Math.floor(sec_num / 3600);
    var minutes: any = Math.floor((sec_num - hours * 3600) / 60);
    var seconds: any = sec_num - hours * 3600 - minutes * 60;

    if (hours < 10) {
      hours = "0" + hours;
    }
    if (minutes < 10) {
      minutes = "0" + minutes;
    }
    if (seconds < 10) {
      seconds = "0" + seconds;
    }
    // console.log(hours + ':' + minutes + ':' + seconds);
    this.video_time = hours + ":" + minutes + ":" + seconds;

    // this.startTimer(event.target.playerInfo.duration);

    // console.log(event.target.playerInfo, 'change 1', event.data)
  }
  closedModals() {
    this.dialogRef.close();
  }
  savePlayer(event) {
    // console.log(event, 'save', this.playerVars)
  }
}

@Component({
  selector: "add-tags-dialog",
  templateUrl: "add-tags-dialog.html",
})
export class AddTagsDialog {
  public addtag: any = "";
  filteredOptions: any[] = [];
  newtag: boolean = false;
  public sendMailStatus: any = false;
  constructor(
    public dialogRef: MatDialogRef<AddTagsDialog>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    public apiservice: ApiService,
    public cookieService: CookieService
  ) {
    this.search();
  }
  addtagdilog() {
    this.dialogRef.close(this.addtag);
  }

  search() {
    const endpoint = "api/exitsinglisttags";
    const data: any = {
      search_str: this.addtag,
      token: this.cookieService.get("jwtToken"),
      secret: this.cookieService.get("secret"),
    };
    this.apiservice
      .getDataWithoutToken(data, endpoint)
      .subscribe((res: any) => {
        this.filteredOptions = res.res;
      });
  }

  sendMail() {
    console.log("FFFFFFFF");
    //this.sendMailStatus = true
  }
}




@Component({
  selector: "clipboard",
  templateUrl: "clipboard.html",
})
export class clipboardFolderview {
  public modalData: any;
  constructor(public dialogRef: MatDialogRef<clipboardFolderview>) {
    setTimeout(() => {
      this.dialogRef.close();
    }, 3000);
    // window.scroll(0,0);
  }

  onNoClick(): void {
    this.dialogRef.close();
  }
}



@Component({
  selector: "snackbarmodal",
  templateUrl: "snackbarmodal.html",
})
export class snackbarmodalFolderview {
  public modalData: any;
  constructor(public dialogRef: MatDialogRef<snackbarmodalFolderview>) {
    setTimeout(() => {
      this.dialogRef.close();
    }, 3000);
    // window.scroll(0,0);
  }

  onNoClick(): void {
    this.dialogRef.close();
  }
}





@Component({
  selector: "viewcontractstatus",
  templateUrl: "viewcontractstatus.html",
})
export class viewcontractstatusview {
  public cstatusdata: any;
  constructor(
    public dialogRef: MatDialogRef<viewcontractstatusview>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData3,
    public apiservice: ApiService,
    public activatedroute: ActivatedRoute,
    public userservice: UserserviceService
  ) {
    console.log(data.data, "+++++");

    this.cstatusdata = data.data;

    console.log(this.cstatusdata);
  }

  onNoClick(): void {
    this.dialogRef.close();
  }
}

@Component({
  selector: "viewcontractstatus",
  templateUrl: "viewcontactssing.html",
})
export class viewcontactssing {
  public cstatusdata: any;
  public flag_check: any = "";
  constructor(
    public dialogRef: MatDialogRef<viewcontactssing>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData2,
    public apiservice: ApiService,
    public activatedroute: ActivatedRoute,
    public userservice: UserserviceService
  ) {
    console.log(data, "+++++");
    this.cstatusdata = data;
    if (
      typeof data.flag_check != "undefined" &&
      data.flag_check != null &&
      data.flag_check != ""
    ) {
      this.flag_check = data.flag_check;
      // console.error(this.flag_check);
    }
    console.log(this.cstatusdata);
  }

  onNoClick(): void {
    this.dialogRef.close();
  }
  chosseSingee(flag) {
    console.log(flag, "flag booking call");

    if (flag == "signee") {
      this.cstatusdata = {
        flag: flag,
      };
    }
    if (flag == "Discovery") {
      this.cstatusdata = {
        flag: flag,
      };
    }
    if (flag == "Warranty") {
      this.cstatusdata = {
        flag: flag,
      };
    }
    this.dialogRef.close(this.cstatusdata);
  }
}
@Component({
  selector: "viewcontractstatus",
  templateUrl: "warrentycallfromcontract.html",
})
export class Warrentycallfromcontract {
  public cstatusdata: any;
  public formdata: any;
  public progressSpinner: any = {
    mode: "indeterminate",
    loading: false,
    bookingStatus: "Sending request",
  };
  public printedName: any = "";
  constructor(
    public dialogRef: MatDialogRef<Warrentycallfromcontract>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData2,
    public apiservice: ApiService,
    public activatedroute: ActivatedRoute,
    public userservice: UserserviceService,
    public cookieService: CookieService,
    public snackBar: MatSnackBar
  ) {
    console.log(data, "+++++ ----========");

    if (
      data.data.singeealldata &&
      data.data.singeealldata.length > 0 &&
      data.data.singeealldata[0].First_Name
    ) {
      this.printedName = data.data.singeealldata[0].First_Name;
    }
    if (
      data.data.singeealldata &&
      data.data.singeealldata.length > 0 &&
      data.data.singeealldata[0].Last_Name
    ) {
      this.printedName += " " + data.data.singeealldata[0].Last_Name;
    }
    this.formdata = {
      successmessage: "Contact Edit Successfully Submitted",
      redirectpath: "user-details/lead/" + this.data.data._id,
      submitactive: true, // optional, default true
      submittext: "Save",
      // resettext: 'Reset',
      canceltext: "Cancel",
      // apiUrl: this.apiservice.api_url,
      // endpoint: 'api/add-practice-contracts',
      jwttoken: this.cookieService.get("jwtToken"),
      cancelroute: "user-details/lead/" + this.data.data._id,

      fields: [
        {
          label: "Equipment Being Warrantied",
          name: "equipmentwarrentied",
          value: "",
          val: "",
          type: "text",
          validations: [
            { rule: "required", message: "Equipment Being Warrantied" },
          ],
        },

        {
          label: "Serial#",
          name: "serialno",
          value: this.data.data.Serial_Number,
          // multiple:true,

          type: "text",
          validations: [{ rule: "required", message: "Serial# is Required" }],
        },
        {
          label: "Date Originally Purchased",
          name: "purchesed_date",
          value: this.data.data.Status_Date,
          type: "date",
          validations: [
            {
              rule: "required",
              message: "Date Originally Purchased is Required",
            },
          ],
        },
        {
          label: "Practice Name",
          name: "practice",
          value: this.data.data.firstname,
          type: "text",
          validations: [
            { rule: "required", message: "Practice Name is Required" },
          ],
        },
        {
          label: "Street Address:",
          name: "address",
          value: this.data.data.address,
          type: "textarea",
          validations: [
            // { rule: 'required', message: 'Practice Name is Required' },
          ],
        },

        //  _______________________ Printed Name: _______________________
        {
          label: "City",
          name: "City",
          value: this.data.data.city,

          type: "text",
          validations: [{ rule: "required", message: "City is Required" }],
        },
        {
          label: "State",
          name: "state",
          value: this.data.data.state,
          type: "text",
          validations: [{ rule: "required", message: "State is Required" }],
        },
        {
          label: "Zip",
          name: "zip",
          value: this.data.data.zip,
          type: "text",
          validations: [{ rule: "required", message: "Zip is Required" }],
        },
        {
          label: "Phone",
          name: "phone",
          value: this.data.data.phone,
          type: "text",
          validations: [{ rule: "required", message: "Phone is Required" }],
        },
        {
          label: "",
          name: "lead_id",
          value: this.data.data._id,
          type: "hidden",
          // validations: [
          //   { rule: 'required', message: 'Authorized Signatory is Required' },

          // ],
        },

        {
          label: "Authorized Signatory:",
          name: "authorized_signatory",
          value: this.data.data.firstname,
          type: "text",
          validations: [
            { rule: "required", message: "Authorized Signatory is Required" },
          ],
        },
        {
          label: "Printed Name",
          name: "printed_name",
          value: this.printedName,
          type: "text",
          validations: [
            { rule: "required", message: "Printed Name is Required" },
          ],
        },
      ],
    };
  }

  closedModal() {
    this.dialogRef.close();
  }
  listenFormFieldChange(val: any) {
    console.log(val);
    if (val.field == "fromsubmit") {
      let data = {
        contractflag: "warrenty",
        lead_id: this.data.data._id,
        tech_id: this.data.data.parentid,

        product_id: "",

        contact_id: this.data.data.singeedata,
        contracts: [
          {
            status: "request",
            added_by_id: this.userservice.userid,
            addedby: this.userservice.fullname,
            addedondatetime: Math.round(new Date().getTime()),
          },
        ],
        contractdata: val.fromval,
      };
      this.apiservice
        .getDataWithoutToken(data, "api/update-new_contract")
        .subscribe((res: any) => {
          this.openSnackBar(res.successmsg, "ok");
          this.dialogRef.close();
        });
    }
  }
  openSnackBar(message: string, action: string = null) {
    this.snackBar.open(message, action, {
      duration: 3000,
    });
  }
}

/*********** training data  ************/
// api/techdetails
// {'secret':'5soDN#bCx','token':'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJleHAiOjE2MjQ3MjAwNzgsImRhdGEiOiJUbGhPZGxKRk5HcFphMDQwIiwiaWF0IjoxNjI0NjMzNjc4fQ.UPIyB1xrh5RECE5w6qnJFMXJAkcoxoNQxNMLlovWeDU','id':'6007c189885e5b00081dfc1d'}
@Component({
  selector: "dialog-overview-warrenty-call-block-dialog",
  templateUrl: "dialog-overview-warrenty-call-block-dialog.html",
})
export class DialogOverviewWarrentyCallBlockDialog {
  public typeforwarranty: any = {};
  public flag_to_show: any = "";
  public msg: any = "";
  constructor(
    public dialogRef: MatDialogRef<DialogOverviewWarrentyCallBlockDialog>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData
  ) {
    this.typeforwarranty = data.data;
    this.flag_to_show = data.flag;
    console.log(this.typeforwarranty, ",,,,,,,,,");
    this.msg = data.msg;
  }
  openLink(event: any, val: any) {
    this.dialogRef.close(val);
  }
  onNoClick(): void {
    this.dialogRef.close();
  }
}
@Component({
  selector: "dialog-overview-signee-dialog",
  templateUrl: "dialog-overview-signee-dialog.html",
})
export class DialogOverviewSigneeDialog {
  constructor(
    public dialogRef: MatDialogRef<DialogOverviewSigneeDialog>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData
  ) { }
  openLink(val: any) {
    this.dialogRef.close(val);
  }
  onNoClick(): void {
    this.dialogRef.close();
  }
}

// ++++++++++++++++++++++++++++ FOLLOW UP MODAL+++++++++++++++++++++++
@Component({
  selector: "followup",
  templateUrl: "followup.html",
})
export class DialogFollowUpComponent {
  formfieldrefresh: any = false;
  formfieldrefreshdata: any = null;
  public followupformdata: any;
  // public showData: any;
  public timezone: any = [
    { name: "Alaska Standard Time", val: "-08:00|America/Anchorage" },
    { name: "India Standard Time", val: "+05:30|Asia/Calcutta" },
    { name: "Pacific Standard Time", val: "-07:00|America/Los_Angeles" },
    // {
    //   name: 'Mountain Standard Time (GMT-06:00)',
    //   val: '-06:00|America/Denver',
    // },
    {
      name: "Mountain Standard Time On DTS (GMT-07:00)",
      val: "-07:00|America/Phoenix",
    },
    { name: "Central Standard Time", val: "-05:00|America/Chicago" },
    { name: "Eastern Standard Time", val: "-04:00|America/New_York" },
    { name: "Hawaii Standard Time", val: "-10:00|Pacific/Honolulu" },
  ];
  public timeSpan: any = [
    { name: "15 Mins", val: 15 },
    { name: "30 Mins", val: 30 },
    { name: "60 Mins", val: 60 },
    { name: "90 Mins", val: 90 },
    { name: "120 Mins", val: 120 },
  ];
  public flag_followup: any;
  public flag: any;
  public contractperson: any = [];
  public formdata3: any;
  public surname_status: any = [
    { val: "Mr", name: "Mr" },
    { val: "Ms", name: "Ms" },
    { val: "Mrs", name: "Mrs" },
    { val: "Dr", name: "Dr" },
    { val: "Prof", name: "Prof" },
  ];
  public pm_am: any = [
    { val: "AM", name: "AM" },
    { val: "PM", name: "PM" },
  ];
  public product: any = [
    { val: "AM", name: "AM" },
    { val: "PM", name: "PM" },
  ];
  public disposition_arr = [
    { val: "Not contacted", name: "Not contacted" },
    { val: "Left a voice mail", name: "Left a voice mail", className: "text" },
    { val: "Contacted no decision-maker", name: "Contacted no decision-maker" },
    {
      val: "Contacted info sent no decision-maker",
      name: "Contacted info sent no decision-maker",
    },
    { val: "Decision-maker reached", name: "Decision-maker reached" },
    {
      val: "Decision-maker reached info sent",
      name: "Decision-maker reached info sent",
    },
    { val: "Hot lead chasing to close", name: "Hot lead chasing to close" },
    { val: "Chasing to close", name: "Chasing to close" },
    { val: "Pushed out chasing to close", name: "Pushed out chasing to close" },
    { val: "Killed", name: "Killed" },
  ];

  public contractpersonval = [];
  public progressSpinner: any = {
    mode: "indeterminate",
    loading: false,
    loading1: false,
    loading4: false,
    bookingStatus: "Sending request",
  };
  public contractfollowupflag: boolean = false;
  public product_category: any = [];
  public product_list_data: any = [];
  emailregex: RegExp =
    /^(([^<>()\[\]\\.,;:\s@']+(\.[^<>()\[\]\\.,;:\s@']+)*)|('.+'))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  // public timezonevalue = [
  //   { name: Intl.DateTimeFormat().resolvedOptions().timeZone, val: Intl.DateTimeFormat().resolvedOptions().timeZone }]
  constructor(
    public dialogRef: MatDialogRef<DialogFollowUpComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    public apiservice: ApiService,
    public http: HttpClient,
    public activatedroute: ActivatedRoute,
    public cookies: CookieService,
    public router: Router,
    public userservice: UserserviceService,
    public ObservableService: ObservableService
  ) {


    // var h2 = document.querySelector('a');
    // h2.className = "test";

    this.progressSpinner.loading = true;
    let lead_id = this.activatedroute.snapshot.params._id
    // console.log(data, 'data follow up');
    // this.ObservableService.setlocationsData(1);
    // this.contactperson();
    this.productlist(data);
    let d = new Date();
    let n = d.getTimezoneOffset();
    let g = Math.abs(n);
    console.log(n, "n");
    // calculation for current time zome ++++++++start
    var offset = new Date().getTimezoneOffset(),
      o = Math.abs(offset);
    var time =
      (offset < 0 ? "+" : "-") +
      ("00" + Math.floor(o / 60)).slice(-2) +
      ":" +
      ("00" + (o % 60)).slice(-2);
    let timezonename = new Date()
      .toLocaleDateString(undefined, { day: "2-digit", timeZoneName: "long" })
      .substring(4);
    let timezonevalue = [
      {
        name: timezonename,
        val: time + "|" + Intl.DateTimeFormat().resolvedOptions().timeZone,
      },
    ];
    console.log(timezonevalue, "timezonevalue");
    console.log(timezonename, "timezonename");
    // for current time zome ++++++++end
    // console.log(String(n).split('-').map(Number));
    console.log(n, "data follow up", g, time);
    let userid = JSON.parse(this.cookies.get("userid"));
    // if( this.contractperson.length>0){
    setTimeout(() => {
      this.progressSpinner.loading = false;
      this.followupformdata = {
        // custombuttons: [
        //   {
        //     label: 'New',
        //     type: 'listner',
        //     id: 'w1',
        //     name: 'wikipage',
        //     tooltip: 'Wiki Page',
        //   },],
        successmessage: "Added Successfully !!",
        redirectpath: "",
        submitactive: true,
        cancelactive: false, // optional, default true
        // optional, default true
        submittext: "Submit",
        resettext: "Reset",
        canceltext: "",
        apiUrl: this.apiservice.api_url,
        endpoint: "api8/add-followupfrom", //
        jwttoken: this.userservice.jwttoken,
        cancelroute: "",
        fields: [
          {
            label: "Appointment Title",
            name: "event_title",
            value: "",
            type: "text",
            validations: [
              { rule: "required", message: "Appointment date is Required" },
            ],
          },
          {
            label: "Appointment date",
            name: "booking_date", //
            // val: new Date().toISOString(),
            minDate: new Date(),
            // maxDate: new Date(2024, 8, 31),
            value: "",
            type: "date",
            validations: [
              { rule: "required", message: "Appointment date is Required" },
            ],
          },
          {
            label: "slot duration",
            name: "timespan",
            value: "",
            val: this.timeSpan,
            type: "select",
            validations: [
              { rule: "required", message: "slot duration is Required" },
            ],
          },
          // {
          //   label: 'Time',
          //   name: 'slot',
          //   value: '',
          //   // labelPosition: 'after',
          //   type: 'number',
          //   validations: [{ rule: 'required', message: 'Time is Required' }],
          //   // dependent: [{
          //   //   condval: true,
          //   //   field: {
          //   //     label: 'Launched Date',
          //   //     name: 'launcheddate',
          //   //     type: 'date',
          //   //   }
          //   // }],
          // },
          {
            label: "Timepicker",
            name: "slot",
            type: "timepicker",
            value: "",
            // hint: 'Timepicker .... ',
            validations: [
              {
                rule: "required",
                message: "timepicker field Needs to be required",
              },
            ],
          },
          // {
          //   label: '',
          //   name: 'PM_AM',
          //   type: 'select',
          //   value: 'AM',
          //   val: this.pm_am,
          //   validations: [{ rule: 'required', message: 'PM_AM is Required' }],
          // },
          {
            label: "Time zone",
            name: "timezone",
            type: "select",
            value: timezonevalue[0].val,
            val: this.timezone,
            validations: [
              { rule: "required", message: "Time zone is Required" },
            ],
          },
          {
            label: "Notes",
            name: "notes",
            hint: "",
            type: "textarea",
            // validations: [{ rule: 'required', message: 'Notes is Required' }],
            // value: 0,
          },
          {
            label: "Product",
            name: "product",
            multiple: true,
            hint: "",
            type: "select",
            val: this.product_category,
            // value: this.product_list_data,
            validations: [{ rule: "required", message: "Product is Required" }],
            // value: 0,
          },
          {
            label: "Contact Person",
            name: "contact_person",
            hint: "",
            type: "select",
            // value: "<span class=\"contactname\">Mr Soham Mitra</span> (<span class=\"contactphone\">09876543215</span>)",
            // value: '',
            val: this.contractperson,
          },
          {
            label: "New Contact",
            name: "extr",
            hint: "",
            type: "externaldata",
            // val: this.contractperson,
            // value: [{ 'key': 'img', 'label': 'Profile Image', }]
          },
          {
            label: "Disposition",
            name: "disposition",
            type: "select",
            value: "",
            val: this.disposition_arr,
            validations: [
              { rule: "required", message: "Disposition is required" },
            ],
          },
          // {
          //   label: "Attendee Email",
          //   name: "atten_email",
          //   type: "email",
          //   value: '',
          //   validations: [
          //     { rule: 'required', message: 'Email is required' },
          //     {
          //       rule: 'pattern',
          //       value: this.emailregex,
          //       message: 'Must be a valid Email',
          //     },
          //   ],
          // },

          {
            label: "followupflag",
            name: "flag",
            hint: "",
            type: "hidden",
            value: 0,
          },
          {
            label: "eventType",
            name: "event_type",
            hint: "",
            type: "hidden",
            value: 7,
          },
          {
            label: "leadid",
            name: "id",
            hint: "",
            type: "hidden",
            value: data,
          },
          {
            label: "bookinguser",
            name: "bookinguser",
            hint: "",
            type: "hidden",
            value: userid,
          },
          // {
          //   label: 'Offsettype',
          //   name: 'offsettype',
          //   hint: '',
          //   type: 'hidden',
          //   value: '',
          // },
          {
            label: "Offsetvalue",
            name: "offsetvalue",
            hint: "",
            type: "hidden",
            value: g,
          },
          {
            label: "callcounts",
            name: "callcounts",
            type: "hidden",
            value: 1,
          },
          {
            label: "Status",
            name: "status",
            hint: "",
            type: "hidden",
            value: 0,
          },
        ],
      };
      console.log(" this.followupformdata.fields", this.contractpersonval);
      if (this.contractpersonval.length > 0 && this.contractpersonval != null) {
        console.log(
          this.followupformdata.fields[7],
          " this.followupformdata.fields"
        );
        this.followupformdata.fields[7].value = this.contractpersonval[0].val;
      }
    }, 2000);
    // let formdata_edit = {
    //   timezone: timezonevalue[0].val,
    // }
    // setTimeout(() => {
    //   this.formfieldrefreshdata = { formvaldata: formdata_edit };
    // }, 2500);
    // // }
    if (data.flag_followup == true) {
      console.log("data.flag_followup==>", data.flag_followup);
      this.flag = true;
      console.log("edit if", 1631706776686 + 86400);
      // console.log(data.result, "resultresult madhumita ");
      // console.log(data.data.result[0]._id, 'resultresult');
      this.followupformdata = {
        successmessage: "Added Successfully !!",
        redirectpath: "",
        submitactive: true,
        cancelactive: false, // optional, default true
        // optional, default true
        submittext: "Submit",
        resettext: "Reset",
        canceltext: "",
        apiUrl: this.apiservice.api_url,
        endpoint: "api8/add-followupfrom", //
        jwttoken: this.userservice.jwttoken,
        cancelroute: "",
        fields: [
          {
            label: "Appointment Title",
            name: "event_title", //
            value: "",
            type: "text",
            validations: [
              { rule: "required", message: "Appointment date is Required" },
            ],
          },
          {
            label: "Appointment date",
            name: "booking_date", //
            value: "",
            minDate: new Date(),
            type: "date",
            validations: [
              { rule: "required", message: "Appointment date is Required" },
            ],
          },
          {
            label: "slot duration",
            name: "timespan",
            value: "",
            val: this.timeSpan,
            type: "select",
            validations: [
              { rule: "required", message: "slot duration is Required" },
            ],
          },
          {
            label: "Timepicker",
            name: "slot",
            type: "timepicker",
            value: "",
            // hint: 'Timepicker .... ',
            validations: [
              {
                rule: "required",
                message: "timepicker field Needs to be required",
              },
            ],
          },
          // {
          //   label: 'Time',
          //   name: 'slot',
          //   value: '',
          //   // suffix: 'PM/AM',
          //   type: 'number',
          //   validations: [{ rule: 'required', message: 'Time is Required' }],
          // },
          // {
          //   label: '',
          //   name: 'PM_AM',
          //   type: 'select',
          //   value: 'AM',
          //   val: this.pm_am,
          //   validations: [{ rule: 'required', message: 'PM_AM is Required' }],
          // },
          {
            label: "Time zone",
            name: "timezone",
            type: "select",
            value: "",
            val: this.timezone,
            validations: [
              { rule: "required", message: "Time zone is Required" },
            ],
          },
          {
            label: "Notes",
            name: "notes",
            hint: "",
            value: "",
            type: "textarea",
            validations: [{ rule: "required", message: "Notes is Required" }],
            // value: 0,
          },
          {
            label: "Product",
            name: "product",
            multiple: true,
            hint: "",
            type: "select",
            val: this.product_category,
            // value: this.product_list_data,
            validations: [{ rule: "required", message: "Product is Required" }],
            // value: 0,
          },
          {
            label: "Contact Person",
            name: "contact_person",
            hint: "",
            type: "select",
            value: "",
            val: this.contractperson,
          },
          {
            label: "New Contact",
            name: "extr",
            hint: "",
            type: "externaldata",
            // val: this.contractperson,
            // value: [{ 'key': 'img', 'label': 'Profile Image', }]
          },
          {
            label: "Disposition",
            name: "disposition",
            type: "select",
            value: "",
            val: this.disposition_arr,
            validations: [
              { rule: "required", message: "Disposition is required" },
            ],
          },
          // {
          //   label: "Attendee Email",
          //   name: "atten_email",
          //   type: "email",
          //   value: '',
          // validations: [
          //   { rule: 'required', message: 'Email is required' },
          //   {
          //     rule: 'pattern',
          //     value: this.emailregex,
          //     message: 'Must be a valid Email',
          //   },
          // ],
          // },

          {
            label: "followupflag",
            name: "flag",
            hint: "",
            type: "hidden",
            value: 0,
          },
          {
            label: "eventType",
            name: "event_type",
            hint: "",
            type: "hidden",
            value: 7,
          },
          {
            label: "leadid",
            name: "id",
            hint: "",
            type: "hidden",
            value: data.result.id,
          },
          {
            label: "bookinguser",
            name: "bookinguser",
            hint: "",
            type: "hidden",
            value: userid,
          },
          {
            label: "Offsetvalue",
            name: "offsetvalue",
            hint: "",
            type: "hidden",
            value: data.result.offsetvalue,
          },
          {
            label: "Status",
            name: "status",
            hint: "",
            type: "hidden",
            value: 0,
          },
        ],
      };

      // this.ObservableService.getlocationsData().subscribe(res => {
      //   console.log("getmultilingualData*************", res);

      // });
      this.contactpersonedit(data.result.id);
    } else {
      console.log("edit else");
      this.contactperson();
    }
  }

  listenFormEditFieldChange(val: any) {
    console.log(val, "val follow up listenr");
    // this.dialogRef.close(val);
    // this.progressSpinner.loading = true;
    if (val.fieldval == "success") {
      console.log(
        "hjjjjjjjjjjjjj",
        this.ObservableService.setlocationsData(val.fromval)
      );
      this.ObservableService.setlocationsData(val.fromval);
      this.dialogRef.close(val);
      // this.showData('personalinfo');
    }
    if (val.field == "formcancel") {
      console.log("hjjjjjjjjjjjjj");
      this.dialogRef.close();
      // this.showData('personalinfo');
    }
    if (val.fieldVal != null && val.fieldVal.label == "New Contact") {
      console.log("external data _____++++++++++++++++++++");
      setTimeout(() => {
        this.formfieldrefreshdata = {
          field: "addfromcontrol",
          value: [
            // label: 'Patient Name',
            // {
            //   name: 'doctor_id',
            //   value: '',
            //   type: 'text',
            //   after: 'extr',
            //   custombuttonflag: false
            // },
            // {
            //   name: 'tryrtr',
            //   value: '',
            //   type: 'text',
            //   after: 'doctor_id',
            //   custombuttonflag: false
            // }

            {
              label: "",
              name: "Salutation",
              value: "",
              // multiple:true,
              val: this.surname_status,
              type: "select",
              validations: [
                { rule: "required", message: "Salutation is Required" },
              ],
              after: "extr",
            },

            {
              label: "First Name",
              name: "First_Name",
              value: "",
              // multiple:true,

              type: "text",
              validations: [
                { rule: "required", message: "First Name is Required" },
              ],
              after: "Salutation",
            },
            {
              label: "Last Name",
              name: "Last_Name",
              value: "",
              type: "text",
              // validations: [
              //   { rule: 'required', message: 'Last Name is Required' },
              // ],
              after: "First_Name",
            },
            {
              label: "Phone",
              name: "Phone",
              value: " ",
              type: "number",
              validations: [
                { rule: "required", message: "Phone is Required" },
                {
                  rule: "minLength",
                  value: 10,
                  message: "The Phone Number must be 10 digits",
                },
              ],
              after: "Last_Name",
            },
            {
              label: "Other Phone",
              name: "Other_Phone",
              value: "",
              type: "number",
              validations: [
                // { rule: 'required', message: 'Practice Name is Required' },
              ],
              after: "Phone",
            },

            {
              label: "Email",
              name: "Email",
              value: "",
              val: "",
              type: "text",
              validations: [{ rule: "required", message: "Email is Required" }],
              after: "Other_Phone",
            },
            {
              label: "Secondary Email",
              name: "Secondary_Email",
              value: "",
              type: "text",
              // validations: [
              //   { rule: 'required', message: 'Fax is Required' },
              // ],
              after: "Email",
            },
            {
              label: "Fax",
              name: "fax",
              value: "",
              type: "text",
              validations: [
                // {
                //   rule: 'maxLength',
                //   value: 9,
                //   message: 'Please enter the valid 9 digit EIN Number of ',
                // },
              ],
              after: "Secondary_Email",
            },
            {
              label: "Contact Notes",
              name: "Contact_Notes",
              value: "",
              type: "text",
              validations: [
                // {
                //   rule: 'maxLength',
                //   value: 9,
                //   message: 'Please enter the valid 9 digit EIN Number of ',
                // },
              ],
              after: "fax",
            },
            {
              heading: "Address Information",
              name: "statusgroup",
              hint: ",0000",
              type: "group",
              fields: [
                // {
                //   label: 'Age1',
                //   name: 'age1',
                //   type: 'number',
                //   hint: 'Age ??',
                //   validations: [
                //       // { rule: 'required' },
                //       // { rule: 'min', value: 5 },
                //       // { rule: 'max', value: 50, message: 'Age can't be more than 50 ' }
                //   ]
                // },
              ],
              after: "Contact_Notes",
            },
            {
              label: "Mailing Street",
              name: "Mailing_Street",
              value: "",

              type: "text",
              // validations: [
              //   { rule: 'required', message: 'Practice Type is Required' },
              // ],
              after: "Contact_Notes",
            },
            {
              label: "Mailing City",
              name: "Mailing_City",
              value: "",
              // hint:'None',
              // val: this.pecepracticestatus,
              type: "text",
              after: "Mailing_Street",
            },

            {
              label: "Mailing State",
              name: "Mailing_State",
              value: "",

              type: "text",
              // validations: [
              //  g { rule: 'required', message: 'Exchange Rate is Required' },
              // ],
              after: "Mailing_City",
            },
            {
              label: "Mailing Zip",
              name: "Mailing_Zip",
              value: "",
              type: "number",
              // validations: [
              //   { rule: 'required', message: 'Practice EMR is Required' },
              // ],
              after: "Mailing_State",
            },

            {
              label: "Mailing Country",
              name: "Mailing_Country",
              value: "",
              type: "text",
              // validations: [
              //   { rule: 'required', message: 'Practice Email is Required' },
              // ],
              after: "Mailing_Zip",
            },
            {
              label: "Other Street",
              name: "Other_Street",
              value: "",
              type: "text",
              validations: [
                // { rule: 'required', message: 'Website is Required' },
              ],
              after: "Mailing_Country",
            },
            {
              label: "Other City",
              name: "Other_City",
              value: "",
              type: "textarea",
              labelPosition: "after",
              // validations: [
              //   { rule: 'required', message: 'Practice Notes is Required' },
              // ],
              after: "Other_Street",
            },

            {
              label: "Other State",
              name: "Other_State",
              value: "",
              type: "text",

              // validations: [
              //   { rule: 'required', message: 'Practice Notes is Required' },
              // ],
              after: "Other_City",
            },
            {
              label: "Other Zip",
              name: "Other_Zip",
              value: "",
              type: "text",
              after: "Other_State",
            },

            {
              label: "Other Country",
              name: "Other_Country",
              value: "",
              type: "text",
              after: "Other_Zip",
            },
            {
              label: "Is signee",
              name: "isSignee",
              value: true,
              type: "checkbox",
              after: "Other_Country",
            },
            {
              label: "",
              name: "leadID",
              value: this.data,
              type: "hidden",
              after: "Email",
            },
          ],
        };
      }, 600);
    }
  }
  contactperson() {
    const data: any = {
      token: this.cookies.get("jwtToken"),
      secret: this.cookies.get("secret"),
      leadID: this.data,
    };

    this.apiservice
      .getDataWithoutToken(data, "api8/contactdata-view")
      .subscribe((response: any) => {
        console.warn('api response of contractperson==>', response.res);
        for (const key in response.res) {
          console.warn("response.res.key==>", response.res[key]);
          this.contractperson.push({
            val: response.res[key].contactperson,
            name: response.res[key].contactperson.replace(/<\/?span[^>]*>/g, "")
          });
          console.log("RESSSSS=====>", response.res[key]);
          if (response.res[key].isSignee == true) {
            this.contractpersonval.push({
              val: response.res[key].contactperson,
              name: response.res[key].contactperson.replace(
                /<\/?span[^>]*>/g,
                ""
              ),
            });
            // console.log('RESSSSS=====>contact ', this.contractpersonval[0].val);
          }
        }
        console.log('final contractperson==>', this.contractperson);

      });
  }
  contactpersonedit(val: any) {
    const data: any = {
      token: this.cookies.get("jwtToken"),
      secret: this.cookies.get("secret"),
      leadID: val,
    };

    this.apiservice
      .getDataWithoutToken(data, "api8/contactdata-view")
      .subscribe((response: any) => {
        console.log("RESSSSS=====>", response);
        // this.contractperson = response.res
        // console.log('RESSSSS=====>', this.contractperson);
        for (const key in response.res) {
          this.contractperson.push({
            val: response.res[key].contactperson,
            name: response.res[key].contactperson.replace(
              /<\/?span[^>]*>/g,
              ""
            ),
          });
          console.log("RESSSSS=====>", this.contractperson);
        }
      });
  }
  productlist(leadid) {
    let data1: any = { lead_id: leadid };
    console.log("api8/get_product data1 reqboby==>", data1)
    console.log("this.activatedroute.snapshot productlist==>", this.activatedroute.snapshot)
    this.apiservice
      .getDataWithoutToken(data1, "api8/get_product")
      .subscribe((res: any) => {
        console.log("api8/get_product res. ==>", res);
        console.log("api8/get_product res.res ==>", res.res);
        // this.product_category=res.res
        let apires: any = res.res;
        for (let i in apires) {
          this.product_category.push({ "val": apires[i].val, "name": apires[i].name });
        }
        console.log("product_category==>", this.product_category);

        // this.product_list_data=res.res
        // for (const key in res.res) {
        //   this.product_list_data[key] = res.res[key].disableproduct;
        // }
      });
  }

  ngOnInit() { }
  // openLink(event: any, val: any) {
  //   this.dialogRef.close(val);
  // }
  onNoClick(): void {
    this.dialogRef.close();
  }
}

// follow up view modal //

@Component({
  selector: "viewfollowup",
  templateUrl: "viewfollowup.html",
})
export class viewfollowup {
  public followupdatatable: any = [];
  // use for Table Detail Field Skip
  public followup_datatableskip: any = [
    "_id",
    "slotTimeActual",
    "notes",
    "eid",
    "start_datetime_unix",
    "event_type_val",
    "googleevent",
    "event_title_s",
    "eventid",
    "is_google_event",
    "lesson_id",
    "id",
    "attende_email_s",
    "attende_name_s",
    "status",
    "user_id",
    "username_s",
    "product_name",
    "product_name_s",
    "product_id",
    "slot_end_time",
    "training_id",
    "parent_id",
  ];
  // tabel headerarr data modify
  public modifyheaderarray_followup: any = {
    event_title: "Appointment Title",
    // event_type: 'Event Type',
    datetimeslot: "follow up time",
    timespan: "Appointment Duration",
    username: "Organizer Name",
    useremail: "Organizer email",
    attende_name: "Lead Name",
    attende_email: "Lead Email",
    attende_type: "Phone Number",
    followup: "No of calls",
    attende_phone: "Phone",
    contact_person: "Contact Person",
    dispositionfollow: "Disposition",
  };
  //
  followup_deleteendpoint: any = "";
  public followup_statusarray: [
    { val: 0; name: "Pending" },
    { val: 1; name: "Done" },
    { val: 2; name: "Canceled" }
  ];
  followup_editroute = "";

  // use for Table Detail Field Skip
  public followup_datatabledetailskip: any = [
    "_id",
    "slotTimeActual",
    "notes",
    "eid",
    "start_datetime_unix",
    "event_type_val",
    "googleevent",
    "event_title_s",
    "eventid",
    "is_google_event",
    "lesson_id",
    "id",
    "attende_email_s",
    "attende_name_s",
    "status",
    "user_id",
    "username_s",
    "product_name",
    "product_name_s",
    "product_id",
    "slot_end_time",
    "training_id",
    "parent_id",
    "markasdoneflag",
    "flag_for_followup",
    "flag_for_show",
    "pendingflag",
    "event_type_val_s",
    "appoinmentStatus",
    "event_type_s",
    "start_time",
    "bookinguser",
    "organizer_email",
    "organizer_name",
    "attende_phone",
    "attende_name",
    "event_type",
    "createdon_datetime",
    "datetimeslot",
    "lid",
    "products",
    "ss",
    "product",
    "timeflag",
    "timenow",
    "unow",
    "datefld",
    "date2",
    "unixtoday",
    "qa",
    "dateDifference",
    "difftype",
    "followups",
    "followup",
    "disposition",
    "dispositionfollow_s",
    "offsetvalue",
    "callcounts",
    "dispo",
    "dispositionlead",
    "contact_person_s",
  ];
  public sortdata_followup: any = {
    type: "asc",
    field: "datetimeslot",
    options: ["attende_name", "attende_phone", "ss", "event_type"],
  };
  followup_datacollection: any = "api8/list-followup-data";
  public followup_limitcond: any = {
    limit: 10,
    skip: 0,
    pagecount: 1,
  };
  public libdata_followup: any = {
    basecondition: {},
    detailview_override: [
      { key: "event_title", val: "Event Title" },
      { key: "contact_person", val: "Contact Person" },
      { key: "description", val: "Description" },
      { key: "username", val: "Organiser Name" },
      { key: "useremail", val: "Organiser Email" },
      { key: "dispositionfollow", val: "Disposition" },
      { key: "booking_date", val: "Booked On" },
      { key: "startdate", val: "Appointment Date" },
      { key: "slot", val: "Slot" },
      { key: "timespan", val: "Time span" },
      { key: "timezoneName", val: "Time zone" },
      { key: "product_name", val: "Product Name" },
      { key: "attende_name", val: "Attende Name" },
      { key: "attende_email", val: "Lead Email" },
      { key: "attende_type", val: "Attende Type" },
    ],
    // table headline
    tableheaders: [
      "attende_name",
      "attende_phone",
      "contact_person",
      "datetimeslot",
      "followup",
      "dispositionfollow",
    ],
    customselectbuttons: [
      {
        label: "Update Disposition",
        type: "listner",
        // cond: 'status',
        // condval: 0,
        classname: "disposition_warranty",
        tooltip: "Disposition for Follow up call ",
      },
    ],
    custombuttons: [
      // {
      //   label: 'Edit',
      //   type: 'listner',
      //   cond: 'status',
      //   condval: 0,
      //   id: 'i1',
      //   classname: 'userEdit',
      //   tooltip: 'Reschedule',
      // },
      {
        label: "Folder View",
        // route: '/user-details/',
        type: "listner",
        // param: ['type', '_id'],
        tooltip: "Folder View",
        classname: "FolderView",
      },
      {
        label: "Follow Up Call",
        type: "listner",
        id: "follow_up_call",
        classname: "follow_up_call",
        tooltip: "Follow Up Calls",
        refreshdata: true,
      },
      {
        label: "Disposition",
        type: "listner",
        // cond: 'status',
        // condval: 0,
        classname: "disposition_warranty",
        tooltip: "Disposition for Follow up call ",
        // refreshdata: true,
      },
      {
        label: "View All Disposition",
        type: "listner",
        // cond: 'status',
        // condval: 0,
        classname: "disposition",
        tooltip: "View All Disposition",
        // refreshdata: true,
      },
    ],
    notes: {
      label: "Add Notes",
      addendpoint: "api8/addnoteforfollowup",
      deleteendpoint: "api8/deletenotedataforfollowup",
      listendpoint: "api8/listnotesfor-followupcalls",
      user: this.userservice.userid,
      currentuserfullname:
        this.userservice.firstname + " " + this.userservice.lastname,
      header: "attende_name_s",
      classname: "followupcallnotes",
      tooltip: "Add Notes",
    },
    // hidestatustogglebutton: true,
    hidedeletebutton: true,
    updateendpointmany: "", // update multiple user
    updateendpoint: "", // update status
    deleteendpointmany: "", // delete multiple user
    hidemultipleselectbutton: true, // hide multiple selection button (false)
    hidecounter: null, // hide counter (false)
    hideaction: true, // hide action (false)
    hideviewbutton: false, // (hide view button)
    hideeditbutton: true, // all these button options are optional not mandatory
    hidestatustogglebutton: true,
    hideupdatemany: true,
    hidedeletemany: true,
  };
  // public followup_data: [

  // ]
  followup_datesearchsourcecount: any = 0;
  public followup_datatableflag: boolean = false;
  // searchsettings_followups: any = {
  //   textsearch: [
  //     { label: 'Search By  Lead Phone', field: 'attende_phone' },
  //     { label: 'Search By  Contact Phone', field: 'contact_person_s' },
  //   ],
  //   search: [
  //     {
  //       label: 'Search By Product',
  //       field: 'products',
  //       values: '',
  //       serversearchdata: {
  //         endpoint:
  //           'api8/autocomplete-followup-product'
  //       },
  //     },
  //     {
  //       label: 'Search By Disposition',
  //       field: 'dispositionfollow_s',
  //       values: '',
  //       serversearchdata: {
  //         endpoint:
  //           'api8/autocompletedispositon'
  //       },
  //     },
  //     {
  //       label: 'Search By Lead Name',
  //       field: 'attende_name_s',
  //       values: '',
  //       serversearchdata: {
  //         endpoint:
  //           'api8/autocomplete-followup-attendee'
  //       },
  //     },
  //   ],
  //   datesearch: [
  //     {
  //       startdatelabel: ' Start Date',
  //       enddatelabel: 'End Date',
  //       submit: 'Search',
  //       field: 'datetimeslot',
  //     },

  //   ],
  //   // this is use for  select search
  //   // this is use for  Autocomplete search

  // }

  public progressSpinner: any = {
    mode: "indeterminate",
    loading: false,
    loading1: false,
    loading4: false,
    bookingStatus: "Sending request",
  };
  constructor(
    public dialogRef: MatDialogRef<viewfollowup>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    public router: Router,
    public Apiservice: ApiService,
    public http: HttpClient,
    public cookieservice: CookieService,
    public activatedRoute: ActivatedRoute,
    public userservice: UserserviceService,
    public snackBar: MatSnackBar,
    public formBuilder: FormBuilder,
    public dialog: MatDialog
  ) {
    // this.progressSpinner.loading = true;
    // this.onTabClickappoinment(val);
    console.log(
      data,
      "uuuuuuuuuuuuuuuuuuuuuuuuuuuuuuuuuuuuuuuuuuuuuuuuuuuuuuuuuuuuuuuu"
    );
    this.followupdatatable = [];
    this.progressSpinner.loading = true;

    let followup_data = {
      secret: this.cookieservice.get("secret"),
      token: this.cookieservice.get("jwtToken"),
      source: "",
      condition: {
        limit: 10,
        skip: 0,
      },
      sort: {
        type: "asc",
        field: "datetimeslot",
      },
      searchcondition: {
        id: data,
      },
    };
    console.log(data);
    this.Apiservice.getDataWithoutToken(
      followup_data,
      "api8/list-followup-data"
    ).subscribe((res: any) => {
      console.log(res, "res follow up");
      if (res.status == "success") {
        this.followupdatatable = res.results.res;
        this.progressSpinner.loading = false;
        this.followup_datatableflag = false;
      }
      if (res.status != "success" && res.results.res == 0) {
        this.followup_datatableflag = true;
      }
    });
    this.Apiservice.getDataWithoutToken(
      followup_data,
      "api8/list-followup-data-count"
    ).subscribe((res: any) => {
      console.log(res, "res follow up count");
    });
  }
  listenLiblistingChangefollowup() { }
  openLink(event: any, val: any) {
    this.dialogRef.close(val);
  }
  onNoClick(): void {
    this.dialogRef.close();
  }
}


// notes delete confirmation modal
@Component({
  selector: 'note-del-modal',
  templateUrl: 'note_del_modal.html'
})
export class notedeletemodal {
  constructor(
    public dialogRef: MatDialogRef<notedeletemodal>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData
  ) {

  }
  yesclick(clickvalue: any): void {
    console.log("clickvalue==>", clickvalue);
    this.dialogRef.close(clickvalue);
  }
  noclick(clickvalue: any) {
    console.log("clickvalue==>", clickvalue);
    this.dialogRef.close(clickvalue);
  }
}







