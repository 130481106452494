import { Component,Inject, OnInit } from '@angular/core';
import { HttpClient } from "@angular/common/http";
import { ActivatedRoute, Router } from "@angular/router";
import { CookieService } from "ngx-cookie-service";
import { ApiService } from 'src/app/services/api.service';
import { UserserviceService } from 'src/app/services/userservice.service';
import {
  MatBottomSheet,
  MatBottomSheetRef,
  MatDialog,
  MatDialogRef,
  MatSnackBar,
  MAT_BOTTOM_SHEET_DATA,
  MAT_DIALOG_DATA,
} from "@angular/material";

export interface DialogData {
  data: any;
  message: any;
  secret: any;
  token: any;
}
@Component({
  selector: 'app-admin-email-settings',
  templateUrl: './admin-email-settings.component.html',
  styleUrls: ['./admin-email-settings.component.css']
})
export class AdminEmailSettingsComponent implements OnInit {

  
  adminemailsettingslisttable: any = [];

  public status: any = [
    { val: 1, name: 'Active' },
    { val: 0, name: 'Inactive' },
  ];
  // use for status search
  statusarray: any = [
    { val: 1, name: 'Active' },
    { val: 0, name: 'Inactive' },
  ];

  // editrouting
  // editroute: any = 'admin/faqcategory/edit/';

  // modify header(key : modify header name)
  modifyheaderarray: any = {
    email: 'Admin Email',
    createdon_datetime:'Added on',
    status: 'Status',
  };

  // use for table field skip
  adminemailsettingslisttableskip: any = [];

  // use for Table Detail Field Skip
  adminemailsettingslisttabledetailskip: any = ['_id','categoryname_s', 'status',  'description_s', 'priority', 'createdon_datetime'];

  // updateendpoint is use for data update endpoint
  // updateendpoint = "api/addfaqcategory"; 

  // deleteendpoint is use for data delete endpoint
  deleteendpoint = 'api10/deleteadminemailsettings';

  // this is a database collection name
  tablename = 'admin_email_settings';

  // searchendpoint is use for data search endpoint
  // searchendpoint = 'api/faqcategorssearch';

  // use for click to another page routing path
  clicktoaddananotherpage = '';

  // date_search_endpoint is use for date search endpoint
  datesearchendpoint: any = '';

  // send basic limit data
  limitcond: any = {
    limit: 10,
    skip: 0,
  };

  // other data
  libdata: any = {
    // basecondition: { type: '' },
    // detailview_override: [
    //   { key: 'categoryname', val: ' Category Name' },
    //   { key: 'description', val: 'Description' },
     
    //   { key: 'status', val: 'Status' },
    //   { key: 'createdon_datetime', val: 'Added on' },

    //   { key: 'priority', val: 'Priority' },
    // ],
    tableheaders: [
      'email',
      'createdon_datetime',
      'status'
    ],
    // searchBarFlagVal:true,
    // tableheaders name [key]
    // optional
    // updateendpointmany: 'api/faqcategorystatusupdate',
    updateendpoint: 'api10/adminemailstatusupdate',
    // deleteendpointmany: 'api/deletefaqcategory',
    hidemultipleselectbutton: true,
    hidecounter: null,
    hideaction: null,
    hideviewbutton: true,  
    hideeditbutton: true,                      
    // actioncolname: 'Actn',
  };

  // send basic sort data
  sortdata: any = {
    type: 'desc',
    field: '_id',
    options: [
    ],
  };
  
  // this is a database collection or view name
  // datesearchsource: any = 'admin_blog_list';
  // datacollection
  datacollection: any = 'api10/adminsettings-email-list';
  // source count
  datesearchsourcecount: any = 0;

  searchsettings: any = {
    datesearch: [
      {
        startdatelabel: 'Added on start Date',
        enddatelabel: 'Added On End Date',
        submit: 'Search',
        field: 'createdon_datetime',
      },
    ],
    selectsearch: [
      { label: 'Search By Status', field: 'status', values: this.status },
    ]
  };
  public userid: any;
  public userFullName: any;
  constructor(
    public router: Router,
    public Apiservice: ApiService,
    public http: HttpClient,
    public cookieservice: CookieService,
    public activatedRoute: ActivatedRoute,
    public userservice: UserserviceService,
    public dialog: MatDialog
  ) { 
    this.userid = this.userservice.userid;
    this.userFullName =
      this.userservice.firstname +
      ' ' +
      this.userservice.lastname; // user dtls ( cookieservice)
    const endpoint_count = 'api10/adminsettings-email-list-count';
    const data: any = { // How many data showing in first page
      secret: this.userservice.secret,
      token: this.userservice.jwttoken,
      source: '',
      condition: {  // condition for how much data show in one time
        limit: 10,
        skip: 0,
      },
      sort: {
        type: 'desc',  // data sort by decreasing order
        field: '_id',  // data sort by first name
      },
      searchcondition: {

      }
    };
    this.Apiservice
      .getDataWithoutToken(data, endpoint_count)
      .subscribe((res: any) => {
        this.datesearchsourcecount = res.count; // sarch count  (How many data there)
      });
  }

  ngOnInit() {
    this.activatedRoute.data.forEach((res: any) => {
      this.adminemailsettingslisttable = res.resolvedata.results; // data store from backend
       
    });
  }
  addemailsmodal(){
    console.log("modal hit success ")
    const dialogRef = this.dialog.open(Addemailmodal, {
      panelClass: ['AddUserComponent','addemailsttngsmodal'],
      // height: '500px',
      // width: '500px',
      data: {},
    });

    dialogRef.afterClosed().subscribe(result => {
      // console.log("result of dialogref==>",result);
      console.log('The dialog was closed');
    });
  }
}

@Component({
  selector: "addemailmodal",
  templateUrl: "add-emails-modal.html",
})
export class Addemailmodal {
  emailregex: RegExp = /^(([^<>()\[\]\\.,;:\s@']+(\.[^<>()\[\]\\.,;:\s@']+)*)|('.+'))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  // formdata
  formfieldrefresh: any = false; 
  updatetable: any = false;
  formfieldrefreshdata: any = null;
  datasource: any;
  public flag: any;
  public formdata: any;
  public stateVal: any = [];
  public status: any = [
    { val: 1, name: 'Active' },
    { val: 0, name: 'Inactive' },
  ];
  public userData: any = [];
  public resolveval: any;
  public categoryname = [];
  // formdata
  public valdata: any;
  constructor(
    public dialogRef: MatDialogRef<Addemailmodal>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    public apiservice: ApiService,
    public activatedroute: ActivatedRoute,
    public http: HttpClient, 
    public cookies: CookieService, 
    public router: Router, 
    private _snackBar: MatSnackBar,
    public userservices: UserserviceService,
  ) {
    console.log("Addemailmodal data==>",this.valdata);
    this.formdata = {
      successmessage: 'Added Successfully !!',
      redirectpath: '/email-settings',
      submitactive: true, // optional, default true
      submittext: 'Submit',
      // resettext: 'Reset',
      hidereset:true,
      canceltext: 'Cancel',
      apiUrl: this.apiservice.api_url,
      endpoint: 'api10/adminsettings-email-add', //
      // jwttoken: this.cookies.get('jwtToken'),
      jwttoken: this.userservices.jwttoken,
      cancelroute: '/email-settings',

      fields: [
        {
          label: 'Email',
          name: 'email',
          value: '',
          type: 'text',
          validations: [{ rule: 'required', message: 'Email is Required' }],
        },
        {
          label: 'Active',
          name: 'status',
          hint: '',
          type: 'checkbox',
          val: this.status,
          value: 0,
        },
      ],
    };
    // console.log("this.formdata==>",this.formdata);
  }

  onNoClick(): void {
    this.dialogRef.close();
  }
  listenFormFieldChange(val: any) {
    console.log("listenFormFieldChange val==>",val);
    if (val.field === 'fromsubmit' || val.field === 'formcancel') {
      let data = {
        data: {
        },
      };
      this.dialogRef.close();
    }
  }
}