import { HttpClient } from "@angular/common/http";
import { Component, Inject, OnInit } from "@angular/core";
import {
  MatDialog,
  MatDialogRef,
  MatSnackBar,
  MAT_DIALOG_DATA,
} from "@angular/material";
import { DomSanitizer, SafeHtml } from "@angular/platform-browser";
import { ActivatedRoute, Router } from "@angular/router";
import { CookieService } from "ngx-cookie-service";
import { ApiService } from "src/app/services/api.service";
import { HttpObservableService } from "src/app/services/http-observable.service";
import { UserserviceService } from "src/app/services/userservice.service";
export interface DialogData {
  val: any;
}

@Component({
  selector: "app-pending-contract-list",
  templateUrl: "./pending-contract-list.component.html",
  styleUrls: ["./pending-contract-list.component.css"],
})
export class PendingContractListComponent implements OnInit {
  contracttable: any = [];
  public updatetable: any = false;
  public contractnorecordfound: boolean = false;
  public status: any = [
    {
      val: "requested by technological-consultant",
      name: "Requested By Associate Director",
    },
    { val: "requested by lead", name: "Requested By Lead" },
    { val: "approved", name: "Approved" },
    { val: "draft", name: "Draft" },
    { val: "sent for signature", name: "Sent For Signature" },
  ];

  // use for status search
  statusarray: any = [
    { val: 1, name: "Active" },
    { val: 0, name: "Inactive" },
  ];

  // editrouting
  editroute: any = "";

  // modify header(key : modify header name)
  modifyheaderarray: any = {
    tech_fullname: "Tech Name",
    createdon_datetime: "Created On",
    product_name: "Product Name",
    lead_fullname: "Lead Name",
    contractflag_s: "Contract",
    contract_status_view: " Contract Status",
  };

  // use for table field skip
  contactListdatatableskip: any = [];

  // use for Table Detail Field Skip
  contactListdatatabledetailskip: any = ["_id", "products"];

  // updateendpoint is use for data update endpoint
  updateendpoint = "api/contractstatusupdate";

  // deleteendpoint is use for data delete endpoint
  deleteendpoint = "api/deletecontract";

  // this is a database collection name
  tablename = "users";

  // searchendpoint is use for data search endpoint
  searchendpoint = "";

  // date_search_endpoint is use for date search endpoint
  datesearchendpoint: any = "";

  // send basic limit data
  limitcond: any = {
    limit: 10,
    skip: 0,
    pagecount: 1,
  };

  // other data
  libdata: any = {
    detailview_override: [
      { key: "createdon_datetime", val: "Created On" },
      { key: "tech_fullname", val: "Tech Name" },
      { key: "lead_fullname", val: "Lead Name" },
      { key: "product_name", val: "Product Name" },
      { key: "contractflag_s", val: "Contract" },
      { key: "contract_status_view", val: "Contract Status" },
    ],
    tableheaders: [
      "tech_fullname",
      "lead_fullname",
      "contractflag_s",
      "contract_status_view",
      "product_name",
      "createdon_datetime",
    ],
    hidedeletebutton: true,
    updateendpointmany: "api/contractupdate",
    updateendpoint: "api/contractstatusupdate",
    deleteendpointmany: "api/deletecontract",
    hidemultipleselectbutton: true,
    hidecounter: null,
    hideaction: null,
    hideviewbutton: true,
    hidestatustogglebutton: true, // (hide status toggle button)
    hideeditbutton: true,
    // hideviewbutton: true, // (hide view button)
    custombuttons: [
      {
        label: "Contract Preview And Submit",
        type: "listner",
        id: "li",
        name: "contarctPreview And Submit",
        tooltip: "Contarct Preview And Submit",
        classname: "ContarctPreview And Submit",
        cond: "iscontarctpending",
        condval: true,
      },
      {
        label: "Approved Contract Preview And Submit",
        type: "listner",
        id: "li",
        name: "contarctPreview",
        tooltip: "Contarct Preview",
        classname: "ContarctPreview And Submit",
        cond: "iscontarctpending",
        condval: false,
      },
    ],
  };

  // send basic sort data
  sortdata: any = {
    type: "desc",
    field: "createdon_datetime",
    options: [
      "tech_fullname",
      "lead_fullname",
      "contractflag_s",
      "contract_status_view",
      "product_name",
      "createdon_datetime",
    ],
  };

  datacollection: any = "api10/pending-contract-list";
  // source count
  datesearchsourcecount: any = 0;

  searchsettings: any = {
    datesearch: [
      {
        startdatelabel: "Created On Start Date",
        enddatelabel: "Created On End Date",
        submit: "Search",
        field: "createdon_datetime",
      },
    ],
    selectsearch: [
      {
        label: "Search By Contract Status",
        field: "contract_status",
        values: this.status,
      },
    ],
    search: [
      {
        label: "Search By Tech Name",
        field: "tech_fullname_s",
        values: "",
        serversearchdata: {
          endpoint: "api10/autocomplete-newcontracts-tech-name",
        },
      },
      {
        label: "Search By Lead Name",
        field: "lead_fullname_s",
        values: "",
        serversearchdata: {
          endpoint: "api10/autocomplete-newcontracts-lead-name",
        },
      },
      {
        label: "Search By Product Name",
        field: "product_name_s",
        values: "",
        serversearchdata: {
          endpoint: "api10/autocomplete-newcontracts-product-name",
        },
      },
      {
        label: "Search By Contract Name",
        field: "contractflag_search",
        values: "",
        serversearchdata: {
          endpoint: "api10/autocomplete-newcontracts-contract-name",
        },
      },
    ],

    textsearch: [
      // { label: 'Search By Tech Name', field: 'tech_fullname' },
      // { label: 'Search By Lead Name', field: 'lead_fullname' },
      // { label: 'Search By Contract', field: 'contractflag' },
      // { label: 'Search By Product', field: 'product_name' },
    ], // this is use for  Autocomplete search
  };
  public userType: any = "";
  public progressSpinner: boolean = false;
  constructor(
    public router: Router,
    public Apiservice: ApiService,
    public http: HttpClient,
    public cookieservice: CookieService,
    public activatedRoute: ActivatedRoute,
    public userservice: UserserviceService,
    public dialog: MatDialog,
    public observableservice: HttpObservableService
  ) {
    // console.log(this.activatedRoute.snapshot.queryParams)

    this.userType = JSON.parse(this.cookieservice.get("type"));
    // console.log(this.userType,'llllllllllllllllllll')
    const endpointc = "api10/pending-contract-list-count";
    const data: any = {
      // How many data showing in first page
      secret: this.userservice.secret,
      token: this.cookieservice.get("jwtToken"),
      source: "",
      condition: {
        // condition for how much data show in one time
        limit: 10,
        skip: 0,
      },
      sort: {
        type: "desc", // data sort by decreasing order
        field: "_id", // data sort by first name
      },
    };

    if (
      this.activatedRoute.snapshot.queryParams &&
      this.activatedRoute.snapshot.queryParams.flag
    ) {
      data["searchcondition"] = {
        contract_status: (this.activatedRoute.snapshot.queryParams.flag === "pending")
        ? "requested by technological-consultant"
        : this.activatedRoute.snapshot.queryParams.flag === "complete"
        ? "sign by lead"
        : this.activatedRoute.snapshot.queryParams.flag === "waiting"
        ? "sent for signature"
        : null,
      };
    }
    this.Apiservice.getDataWithoutToken(data, endpointc).subscribe(
      (res: any) => {
        this.datesearchsourcecount = res.count; // sarch count  (How many data there)
        if (res.count == 0) {
          this.contractnorecordfound = true;
        }
      }
    );
  }

  ngOnInit() {
    console.log(this.activatedRoute.snapshot.queryParams.tab);
    if (
      typeof this.activatedRoute.snapshot.queryParams.tab != "undefined" &&
      this.activatedRoute.snapshot.queryParams.tab != null
    ) {
      this.onTabClick({ index: this.activatedRoute.snapshot.queryParams.tab });
    } else {
      this.activatedRoute.data.forEach((res: any) => {
        this.contracttable = res.resolvedata.results.res; // data store from backend
        // console.log(this.contracttable, 'product list');
        if('reqdata' in res.resolvedata.results){
          console.log("reqdata found +++")
          this.createcontract(res.resolvedata.results.reqdata);
        }
        if (
          this.activatedRoute.snapshot.queryParams &&
          this.activatedRoute.snapshot.queryParams.flag &&
          this.activatedRoute.snapshot.queryParams.flag == "pending"
        ) {
          this.contracttable = res.resolvedata.results.res.filter(
            (itemInArray) =>
              itemInArray.contract_status_view =="REQUESTED BY TECHNOLOGICAL-CONSULTANT"
          );
        }
        if (
          this.activatedRoute.snapshot.queryParams &&
          this.activatedRoute.snapshot.queryParams.flag &&
          this.activatedRoute.snapshot.queryParams.flag == "waiting"
        ) {
          this.contracttable = res.resolvedata.results.res.filter(
            (itemInArray) =>
              itemInArray.contract_status_view =="SENT FOR SIGNATURE"
          );
        }
        if (
          this.activatedRoute.snapshot.queryParams &&
          this.activatedRoute.snapshot.queryParams.flag &&
          this.activatedRoute.snapshot.queryParams.flag == "complete"
        ) {
          this.contracttable = res.resolvedata.results.res.filter(
            (itemInArray) =>
              itemInArray.contract_status_view =="SIGN BY LEAD"
          );
        }
        console.log(this.contracttable,'_+_');
        
      });
    }
    // console.log((this.activatedRoute.snapshot.queryParams.flag === "pending")
    // ? "REQUESTED BY TECHNOLOGICAL-CONSULTANT":(this.activatedRoute.snapshot.queryParams.flag === "complete")
    // ? "SIGN BY LEAD"
    // : (this.activatedRoute.snapshot.queryParams.flag === "waiting")
    // ? "SENT FOR SIGNATURE"
    // : null,'+++++++++++++');
    // console.log("REQUESTED BY TECHNOLOGICAL-CONSULTANT",'+++++++++++++');
    
    this.observableservice.gethttpData().subscribe((res: any) => {
      this.progressSpinner = res;
    });
  }

  listenLiblistingChange(val: any) {
    console.log(val, "listening");
    //   if(val.custombuttonclick.data._id == "618b7fc373e50000097b6324"){
    //     // this.router.navigateByUrl('/new-contract/pece',{state:  val.custombuttonclick.data })
    //     window.open('/new-contract/pece','_blank');
    //     // this.router.navigateByUrl('/new-contract/pece');
    //   }
    //   if(val.custombuttonclick.data._id == "618cf5bf43dbf00009e6c6c2"){
    //     // this.router.navigateByUrl('/new-contract/tm-flow',{state:  val.custombuttonclick.data })
    //     window.open('/new-contract/tm-flow','_blank');
    //     // this.router.navigateByUrl('/new-contract/tm-flow')
    //   }
    //   if(val.custombuttonclick.data._id == "618d0cc24206a40008e814dc"){
    //     // this.router.navigateByUrl('/new-contract/warranty',{state:  val.custombuttonclick.data })
    //     window.open('/new-contract/warranty','_blank');
    //     // this.router.navigateByUrl('/new-contract/warranty')
    //   }
    //   if(val.custombuttonclick.data._id == "618d0c934206a40008e814d7"){
    //     // this.router.navigateByUrl('/new-contract/credit',{state:  val.custombuttonclick.data })
    //     window.open('/new-contract/credit','_blank');
    //     // this.router.navigateByUrl('/new-contract/credit')
    //   }
    //   if(val.custombuttonclick.data._id == "618e1468bcba120009fdac1c"){
    //     // this.router.navigateByUrl('/new-contract/bio-energetics',{state:  val.custombuttonclick.data })
    //     window.open('/new-contract/bio-energetics','_blank');
    //     // this.router.navigateByUrl('/new-contract/bio-energetics')
    //   }
    //   // const dialogRef = this.dialog.open(ContactPreview And SubmitComponent, {

    //   //   panelClass:'contactPreview And Submit',
    //   //   data: { val: val.custombuttonclick.data },
    //   // });
    //   // dialogRef.afterClosed().subscribe(result => {

    //   // });
    if (
      typeof val.custombuttonclick != "undefined" &&
      val.custombuttonclick != null
    ) {
      if (
        val.custombuttonclick.btninfo.label == "Contract Preview And Submit"
      ) {
        console.log("Flag found ++>++")
        this.createcontract(val.custombuttonclick.data);
      }
      if (
        val.custombuttonclick.btninfo.label ==
        "Approved Contract Preview And Submit"
      ) {
        if (val.custombuttonclick.data.contractflag)
          if (val.custombuttonclick.data.contractflag == "Pece Contract") {
            // this.router.navigateByUrl('/new-contract/pece',{state:  val.custombuttonclick.data })
            window.open(
              "/new-contract/pece/" +
                val.custombuttonclick.data._id +
                "?id=view",
              "_blank"
            );
            // this.router.navigateByUrl('/new-contract/pece/'+val.custombuttonclick.data._id);
          }
        if (val.custombuttonclick.data.contractflag == "tmflow_contract") {
          // this.router.navigateByUrl('/new-contract/tm-flow',{state:  val.custombuttonclick.data })
          window.open(
            "/new-contract/tm-flow/" + val.custombuttonclick.data._id,
            "_blank"
          );
          // this.router.navigateByUrl('/new-contract/tm-flow/'+val.custombuttonclick.data._id)
        }
        if (val.custombuttonclick.data.contractflag == "warrenty") {
          // this.router.navigateByUrl('/new-contract/warranty',{state:  val.custombuttonclick.data })
          window.open(
            "/new-contract/warranty/" +
              val.custombuttonclick.data._id +
              "?id=view",
            "_blank"
          );

          // this.router.navigateByUrl('/new-contract/warranty/'+val.custombuttonclick.data._id)
        }
        if (val.custombuttonclick.data.contractflag == "credit") {
          // this.router.navigateByUrl('/new-contract/credit',{state:  val.custombuttonclick.data })
          window.open(
            "/new-contract/credit/" +
              val.custombuttonclick.data._id +
              "?id=view",
            "_blank"
          );
          // this.router.navigateByUrl('/new-contract/credit/'+val.custombuttonclick.data._id)
        }
        if (
          val.custombuttonclick.data.contractflag == "BioEntergetics Contract"
        ) {
          // this.router.navigateByUrl('/new-contract/bio-energetics',{state:  val.custombuttonclick.data })
          window.open(
            "/new-contract/bio-energetics/" + val.custombuttonclick.data._id,
            "_blank"
          );
          // this.router.navigateByUrl('/new-contract/bio-energetics/'+val.custombuttonclick.data._id)
        }
      }
    }
  }
  createcontract(val: any) {
    const dialogRef = this.dialog.open(DialogPendingContractDialog, {
      // width: '250px',
      data: val,
      panelClass: ["contractAadd", "newcontractmodal"],
    });

    dialogRef.afterClosed().subscribe((result) => {
      console.log("The dialog was closed");
      var form: any = document.getElementsByTagName("input");
      // console.log(form);
      var elements = form;
      // console.log(elements);
      for (var i = 0, len = elements.length; i < len; ++i) {
        elements[i].disabled = false;
      }
    });
  }
  onTabClick(val: any) {
    this.progressSpinner = true;
    this.contracttable = [];
    this.datesearchsourcecount = 0;
    console.log(val);
    if (val.index == 1) {
      this.libdata.custombuttons = [];
      this.libdata.custombuttons = [
        {
          label: "Approved Contract Preview And Submit",
          type: "listner",
          id: "li",
          name: "contarctPreview And Submit",
          tooltip: "Contarct Preview And Submit",
          classname: "ContarctPreview And Submit",
        },
      ];
      console.log(1);
      const endpoint = "api10/approved-contract-list";
      const endpointc = "api10/approved-contract-list-count";
      const data: any = {
        // How many data showing in first page
        secret: this.userservice.secret,
        token: this.cookieservice.get("jwtToken"),
        source: "",
        condition: {
          // condition for how much data show in one time
          limit: 10,
          skip: 0,
        },
        sort: {
          type: "desc", // data sort by decreasing order
          field: "_id", // data sort by first name
        },
      };
      this.Apiservice.getDataWithoutToken(data, endpoint).subscribe(
        (res: any) => {
          this.contracttable = res.results.res;
          this.progressSpinner = false;
        }
      );
      this.Apiservice.getDataWithoutToken(data, endpointc).subscribe(
        (res: any) => {
          this.datesearchsourcecount = res.count; // sarch count  (How many data there)
        }
      );
    }
    if (val.index == 0) {
      this.libdata.custombuttons = [];
      this.libdata.custombuttons = [
        {
          label: "Contract Preview And Submit",
          type: "listner",
          id: "li",
          name: "contarctPreview And Submit",
          tooltip: "Contarct Preview And Submit",
          classname: "ContarctPreview And Submit",
        },
      ];
      console.log(0);
      const endpoint = "api10/pending-contract-list";
      const endpointc = "api10/pending-contract-list-count";
      const data: any = {
        // How many data showing in first page
        secret: this.userservice.secret,
        token: this.cookieservice.get("jwtToken"),
        source: "",
        condition: {
          // condition for how much data show in one time
          limit: 10,
          skip: 0,
        },
        sort: {
          type: "desc", // data sort by decreasing order
          field: "_id", // data sort by first name
        },
      };
      this.Apiservice.getDataWithoutToken(data, endpoint).subscribe(
        (res: any) => {
          this.contracttable = res.results.res;
          this.progressSpinner = false;
        }
      );
      this.Apiservice.getDataWithoutToken(data, endpointc).subscribe(
        (res: any) => {
          this.datesearchsourcecount = res.count; // sarch count  (How many data there)
        }
      );
    }
  }
}
// @Component({
//   selector: 'app-contact-Preview And Submit',
//   templateUrl: './contract-Preview And Submit.html',
//   styleUrls: ['./contact-list.component.css']
// })
// export class ContactPreview And SubmitComponent implements OnInit {
// public alldata: any;
//   constructor(public dialogRef: MatDialogRef<ContactPreview And SubmitComponent>, @Inject(MAT_DIALOG_DATA) public data: DialogData, public apiservice: ApiService, public activatedroute: ActivatedRoute,private sanitizer: DomSanitizer) {
//     // console.log(val, 'gfdfgdfgdghfghf');
//     data.val = JSON.parse(JSON.stringify(data.val));
//     this.alldata=data.val
//   }
//   public get topcontract() : SafeHtml {
//     return this.sanitizer.bypassSecurityTrustHtml(this.alldata.topcontract);
//   }
//   public get tarmsspecification() : SafeHtml {
//     return this.sanitizer.bypassSecurityTrustHtml(this.alldata.tarmsspecification);
//   }
//   public get buttomcontract() : SafeHtml {
//     return this.sanitizer.bypassSecurityTrustHtml(this.alldata.buttomcontract);
//   }
//   ngOnInit(): void {
//   }
//   closedModals() {
//     this.dialogRef.close()
//   }
// }
@Component({
  selector: "dialog-new-contract-dialog",
  templateUrl: "dialog-new-contract-dialog.html",
})
export class DialogPendingContractDialog {
  public formfieldrefresh: boolean = true;
  public formfieldrefreshdata: any = null;
  public formdata: any;
  public product_details: any = [
    { val: "604aff3691b7c80008ca11a8", name: "PECE" },
    { val: "604a0b6e40962e00088f0d79", name: "TM-FLOW ANS MEDICAL DEVICE" },
    { val: "612c883f118c110009f02820", name: "BioEnergetics" },
    { val: "612c89242005f400082d5fb1", name: "RST Sanexas" },
  ];
  public product_depeneds_on_tech: any = [];
  public template_details_with_product: any = [
    {
      val: "618b7fc373e50000097b6324",
      name: "Pece Contract",
      product_id: "604aff3691b7c80008ca11a8",
    },
    {
      val: "618cf5bf43dbf00009e6c6c2",
      name: "tmflow_contract",
      product_id: "604a0b6e40962e00088f0d79",
    },
    {
      val: "618d0c934206a40008e814d7",
      name: "credit",
      product_id: "604a0b6e40962e00088f0d79",
    },
    {
      val: "618d0cc24206a40008e814dc",
      name: "warrenty",
      product_id: "604a0b6e40962e00088f0d79",
    },
    {
      val: "618e1468bcba120009fdac1c",
      name: "BioEntergetics Contract",
      product_id: "612c883f118c110009f02820",
    },
  ];
  public progressSpinner: boolean = false;
  public template_details: any = [];
  public lead_id: any = "";
  public condition: any = 0;
  public loader: boolean = false;
  public loader1: boolean = false;
  public signee_id: any = "";
  public pending_contract_data: any = {};
  public template_id: any = "";
  public onload: boolean = false;
  public alldata: any = {};
  public mandatorydata: any = {};
  public mandatory_data: any = [];
  constructor(
    public dialogRef: MatDialogRef<DialogPendingContractDialog>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    public apiService: ApiService,
    private snackBar: MatSnackBar,
    public userservice: UserserviceService,
    public router: Router,
    public observableservice: HttpObservableService
  ) {
    this.loader = true;
    this.loader1 = true;
    this.pending_contract_data = data;
    this.template_id = this.template_details_with_product.find(
      (e) => e.name == this.pending_contract_data.contractflag
    );
    this.formdata = {
      successmessage: "Added Successfully !!",
      redirectpath: "/pending/contract/list",
      submitactive: true,
      submittext: "Preview And Submit",
      resettext: "Reset",
      canceltext: "Cancel",
      apiUrl: this.apiService.api_url,
      // endpoint: 'api/',
      jwttoken: this.apiService.jwttoken,
      cancelroute: "/pending/contract/list",

      fields: [
        {
          label: "Tech Name",
          name: "tech_id",
          type: "hidden",
          value: this.pending_contract_data.tech_details[0]._id,
        },
        {
          label: "Lead/Practice Name",
          name: "lead_id",
          type: "hidden",
          value: this.pending_contract_data.lead_details[0]._id,
        },
        {
          label: "Product Name",
          name: "product_id",
          type: "hidden",
          value:
            this.pending_contract_data.product_details.length != 0
              ? this.pending_contract_data.product_details[0]._id
              : "",
        },
        {
          label: "Template Name",
          name: "template_id",
          type: "hidden",
          value: this.template_id.val,
        },
      ],
    };
    this.add_contract_field(
      this.template_id.val,
      this.pending_contract_data.contractdata
    );
    let send_data = {
      secret: this.userservice.secret,
      token: this.userservice.jwttoken,
      contract_id: this.template_id.val,
      template_id: this.pending_contract_data._id,
    };
    this.apiService
      .getDataWithoutToken(send_data, "api10/get-contract-details")
      .subscribe((res: any) => {
        console.log(res);
        this.loader = false;
        this.loader1 = false;
        this.onload = true;
        this.alldata = res.result.contract_template[0];
        this.mandatorydata = res.result.mandatory_contract_data[0];
        this.mandatory_data = res.result.mandatory_contract_data;
        document.querySelector(".topcontractcls").innerHTML =
          this.alldata.topcontract;
        document.querySelector(".tarmsspecificationcls").innerHTML =
          this.alldata.tarmsspecification;
        document.querySelector(".buttomcontractcls").innerHTML =
          this.alldata.buttomcontract;
        var form: any = document.getElementsByTagName("input");
        // console.log(form);
        var elements = form;
        // console.log(elements);
        for (var i = 0, len = elements.length; i < len; ++i) {
          elements[i].disabled = true;
        }
        if (this.mandatory_data.length > 0) {
          if (this.mandatory_data[0].contractflag == "credit") {
            setTimeout(() => {
              document
                .querySelector("#company_address")
                .setAttribute(
                  "value",
                  this.mandatorydata.lead_details.Location_Name
                );
              document
                .querySelector("#company_city")
                .setAttribute("value", this.mandatorydata.lead_details.city);
              document
                .querySelector("#company_state")
                .setAttribute("value", this.mandatorydata.lead_details.state);
              document
                .querySelector("#company_zip")
                .setAttribute("value", this.mandatorydata.lead_details.zip);
              document
                .querySelector("#authorized_signer")
                .setAttribute(
                  "value",
                  this.mandatorydata.contact_details.full_Name
                );
              document
                .querySelector("#Cell_Phone")
                .setAttribute("value", this.mandatorydata.lead_details.Phone);
              document
                .querySelector("#Website")
                .setAttribute("value", this.mandatorydata.lead_details.Website);
              document
                .querySelector("#Email")
                .setAttribute(
                  "value",
                  this.mandatorydata.lead_details.Practice_Email
                );
              document
                .querySelector("#company_address")
                .setAttribute("readonly", "true");
              document
                .querySelector("#company_city")
                .setAttribute("readonly", "true");
              document
                .querySelector("#company_state")
                .setAttribute("readonly", "true");
              document
                .querySelector("#company_zip")
                .setAttribute("readonly", "true");
              document
                .querySelector("#authorized_signer")
                .setAttribute("readonly", "true");
              document
                .querySelector("#Cell_Phone")
                .setAttribute("readonly", "true");
              document
                .querySelector("#Website")
                .setAttribute("readonly", "true");
              document.querySelector("#Email").setAttribute("readonly", "true");
              Object.entries(this.mandatorydata.contractdata).forEach(
                ([key, value]: any) => {
                  console.log(key, "-------------", value);
                  if (key == "Business_Structure") {
                    const ele = document.getElementById(
                      `${key}_${value}`
                    ) as HTMLInputElement;
                    ele.checked = true;
                    let s_corp = document.getElementById(
                      `${key}_S_Corp`
                    ) as HTMLInputElement;
                    s_corp.disabled = true;
                    let corp = document.getElementById(
                      `${key}_Corp`
                    ) as HTMLInputElement;
                    corp.disabled = true;
                    let lls = document.getElementById(
                      `${key}_LLs`
                    ) as HTMLInputElement;
                    lls.disabled = true;
                    let sol_prop = document.getElementById(
                      `${key}_Sol_Prop`
                    ) as HTMLInputElement;
                    sol_prop.disabled = true;
                    ele.disabled = false;
                  } else {
                    if (key == "Term_Requested") {
                      const ele = document.getElementById(
                        `${key}_${value}`
                      ) as HTMLInputElement;
                      ele.checked = true;
                      let t24 = document.getElementById(
                        `${key}_24`
                      ) as HTMLInputElement;
                      t24.disabled = true;
                      let t26 = document.getElementById(
                        `${key}_26`
                      ) as HTMLInputElement;
                      t26.disabled = true;
                      let t38 = document.getElementById(
                        `${key}_38`
                      ) as HTMLInputElement;
                      t38.disabled = true;
                      let t60 = document.getElementById(
                        `${key}_60`
                      ) as HTMLInputElement;
                      t60.disabled = true;
                      ele.disabled = false;
                    } else {
                      if (key == "Years_in_Business") {
                        let Quotation_Date = value.split("T");
                        document
                          .querySelector(`#${key}`)
                          .setAttribute("value", Quotation_Date[0]);
                        document
                          .querySelector(`#${key}`)
                          .setAttribute("readonly", "true");
                      } else {
                        if (key == "bankruptcies_month_year") {
                          let Quotation_Date = value.split("T");
                          document
                            .querySelector(`#${key}`)
                            .setAttribute("value", Quotation_Date[0]);
                          document
                            .querySelector(`#${key}`)
                            .setAttribute("readonly", "true");
                        } else {
                          if (key == "Date_Closed") {
                            let Date_Closed = value.split("T");
                            console.log(Date_Closed);

                            document
                              .querySelector(`#${key}`)
                              .setAttribute("value", Date_Closed[0]);
                            document
                              .querySelector(`#${key}`)
                              .setAttribute("readonly", "true");
                          } else {
                            if (key == "address") {
                              document
                                .querySelector(`#State_personal_address`)
                                .setAttribute("value", value);
                              document
                                .querySelector(`#State_personal_address`)
                                .setAttribute("readonly", "true");
                            } else {
                              if (key == "city") {
                                document
                                  .querySelector(`#City_personal_address`)
                                  .setAttribute("value", value);
                                document
                                  .querySelector(`#City_personal_address`)
                                  .setAttribute("readonly", "true");
                              } else {
                                if (key == "zip") {
                                  document
                                    .querySelector(`#Zip_personal_address`)
                                    .setAttribute("value", value);
                                  document
                                    .querySelector(`#Zip_personal_address`)
                                    .setAttribute("readonly", "true");
                                } else {
                                  if (key == "state") {
                                  } else {
                                    if (key == "website") {
                                      document
                                        .querySelector(`#Website`)
                                        .setAttribute("value", value);
                                      document
                                        .querySelector(`#Website`)
                                        .setAttribute("readonly", "true");
                                    } else {
                                      if (key == "email") {
                                        document
                                          .querySelector(`#Email`)
                                          .setAttribute("value", value);
                                        document
                                          .querySelector(`#Email`)
                                          .setAttribute("readonly", "true");
                                      } else {
                                        if (key == "phonetwo") {
                                          document
                                            .querySelector(`#Phone2`)
                                            .setAttribute("value", value);
                                          document
                                            .querySelector(`#Phone2`)
                                            .setAttribute("readonly", "true");
                                        } else {
                                          if (key == "compane_name") {
                                          } else {
                                            if (key == "preson_name") {
                                              document
                                                .querySelector(`#Name1`)
                                                .setAttribute("value", value);
                                              document
                                                .querySelector(`#Name1`)
                                                .setAttribute(
                                                  "readonly",
                                                  "true"
                                                );
                                            } else {
                                              if (key == "homeaddress") {
                                                document
                                                  .querySelector(
                                                    `#Home_Address`
                                                  )
                                                  .setAttribute("value", value);
                                                document
                                                  .querySelector(
                                                    `#Home_Address`
                                                  )
                                                  .setAttribute(
                                                    "readonly",
                                                    "true"
                                                  );
                                              } else {
                                                if (key == "city_two") {
                                                  document
                                                    .querySelector(`#City_2`)
                                                    .setAttribute(
                                                      "value",
                                                      value
                                                    );
                                                  document
                                                    .querySelector(`#City_2`)
                                                    .setAttribute(
                                                      "readonly",
                                                      "true"
                                                    );
                                                } else {
                                                  if (key == "state_two") {
                                                    document
                                                      .querySelector(`#State_2`)
                                                      .setAttribute(
                                                        "value",
                                                        value
                                                      );
                                                    document
                                                      .querySelector(`#State_2`)
                                                      .setAttribute(
                                                        "readonly",
                                                        "true"
                                                      );
                                                  } else {
                                                    if (key == "zip_two") {
                                                      document
                                                        .querySelector(`#Zip2`)
                                                        .setAttribute(
                                                          "value",
                                                          value
                                                        );
                                                      document
                                                        .querySelector(`#Zip2`)
                                                        .setAttribute(
                                                          "readonly",
                                                          "true"
                                                        );
                                                    } else {
                                                      if (key == "home_phone") {
                                                        document
                                                          .querySelector(
                                                            `#Home_Phone`
                                                          )
                                                          .setAttribute(
                                                            "value",
                                                            value
                                                          );
                                                        document
                                                          .querySelector(
                                                            `#Home_Phone`
                                                          )
                                                          .setAttribute(
                                                            "readonly",
                                                            "true"
                                                          );
                                                      } else {
                                                        if (key == "signer") {
                                                        } else {
                                                          if (
                                                            key ==
                                                            "Equipment_Cost"
                                                          ) {
                                                            document
                                                              .querySelector(
                                                                `#Equipment_Cost`
                                                              )
                                                              .setAttribute(
                                                                "value",
                                                                `$${value}`
                                                              );
                                                            document
                                                              .querySelector(
                                                                `#Equipment_Cost`
                                                              )
                                                              .setAttribute(
                                                                "readonly",
                                                                "true"
                                                              );
                                                          } else {
                                                            document
                                                              .querySelector(
                                                                `#${key}`
                                                              )
                                                              .setAttribute(
                                                                "value",
                                                                value
                                                              );
                                                            document
                                                              .querySelector(
                                                                `#${key}`
                                                              )
                                                              .setAttribute(
                                                                "readonly",
                                                                "true"
                                                              );
                                                          }
                                                        }
                                                      }
                                                    }
                                                  }
                                                }
                                              }
                                            }
                                          }
                                        }
                                      }
                                    }
                                  }
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              );
              const submit = document.getElementById(
                "submit"
              ) as HTMLButtonElement;
              submit.style.display = "none";
              const cancel = document.getElementById(
                "cancel"
              ) as HTMLButtonElement;
              cancel.style.display = "none";
              // document.getElementById('submit').onclick = this.submit;
              // document.getElementById('cancel').onclick = this.cancel;
            }, 10);
          }
          if (this.mandatory_data[0].contractflag == "warrenty") {
            setTimeout(() => {
              Object.entries(this.mandatorydata.contractdata).forEach(
                ([key, value]: any) => {
                  if (key == "Shipped") {
                    console.log(key, "-------------", value);
                    let Shipped_Date = value.split("T");
                    document
                      .querySelector(`#${key}`)
                      .setAttribute("value", Shipped_Date[0]);
                    document
                      .querySelector(`#${key}`)
                      .setAttribute("readonly", "true");
                  } else {
                    document
                      .querySelector(`#${key}`)
                      .setAttribute("value", value);
                    document
                      .querySelector(`#${key}`)
                      .setAttribute("readonly", "true");
                  }
                }
              );
              const submit = document.getElementById(
                "submit"
              ) as HTMLButtonElement;
              submit.style.display = "none";
              const cancel = document.getElementById(
                "cancel"
              ) as HTMLButtonElement;
              cancel.style.display = "none";
              // document.getElementById('submit').onclick = this.submit;
              // document.getElementById('cancel').onclick = this.cancel;
            }, 10);
          }
          if (this.mandatory_data[0].contractflag == "Pece Contract") {
            setTimeout(() => {
              Object.entries(this.mandatorydata.contractdata).forEach(
                ([key, value]: any) => {
                  if (key == "Shipped") {
                    let shipped_date = value.split("T");
                    let Shippedarr = shipped_date[0].split("-");
                    let shipp3 = Shippedarr[0] % 10;
                    document
                      .querySelector(`#${key}1`)
                      .setAttribute("value", Shippedarr[2]);
                    document
                      .querySelector(`#${key}2`)
                      .setAttribute("value", Shippedarr[1]);
                    document
                      .querySelector(`#${key}3`)
                      .setAttribute("value", String(shipp3));
                    document
                      .querySelector(`#${key}1`)
                      .setAttribute("readonly", "true");
                    document
                      .querySelector(`#${key}2`)
                      .setAttribute("readonly", "true");
                    document
                      .querySelector(`#${key}3`)
                      .setAttribute("readonly", "true");
                  } else {
                    if (key == "Serial") {
                    } else {
                      document
                        .querySelector(`#${key}`)
                        .setAttribute("value", value);
                      document
                        .querySelector(`#${key}`)
                        .setAttribute("readonly", "true");
                    }
                  }
                }
              );
              const submit = document.getElementById(
                "submit"
              ) as HTMLButtonElement;
              submit.style.display = "none";
              const cancel = document.getElementById(
                "cancel"
              ) as HTMLButtonElement;
              cancel.style.display = "none";
              // document.getElementById('submit').onclick = this.submit;
              // document.getElementById('cancel').onclick = this.cancel;
            }, 10);
          }
        }
      });
  }
  listenFormFieldChange(val: any) {
    val = JSON.parse(JSON.stringify(val));
    let key = "";
    console.log(val);
    if (typeof val.field != "undefined" && val.field != null) {
      if (val.field == "formcancel") {
        this.onNoClick();
      }
      if (val.field == "fromsubmit") {
        this.observableservice.sethttpData(true);
        this.loader = true;
        let contractflag: any = "";
        let product_name: any = this.template_details_with_product.find(
          (e) => e.val == val.fromval.template_id
        );
        let contract = val.fromval;
        let lead_id = val.fromval.lead_id;
        let tech_id = val.fromval.tech_id;
        let product_id = val.fromval.product_id;
        let template_id = val.fromval.template_id;
        delete contract.product_id;
        delete contract.lead_id;
        delete contract.tech_id;
        delete contract.template_id;
        if (product_name.name == "PECE Contract") {
          contractflag = "Pece Contract";
        }
        if (product_name.name == "TM-Flow Contract") {
          contractflag = "tmflow_contract";
        }
        if (product_name.name == "Credit Contract") {
          contractflag = "credit";
        }
        if (product_name.name == "Warranty Contract") {
          contractflag = "warrenty";
        }
        let data = {
          _id: this.pending_contract_data._id,
          lead_id: lead_id,
          tech_id: tech_id,
          product_id: product_id,
          template_id: template_id,
          contractdata: contract,
          contractflag: contractflag,
          contact_id: this.signee_id,
        };
        this.apiService
          .getDataWithoutToken(data, "api10/update-pending-contract-list")
          .subscribe((res: any) => {
            console.log(res);
            this.loader = false;
            this.observableservice.sethttpData(false);
            // this.snackBar.open('Send Contract Successfully', 'Dismiss', {
            //   duration: 5000
            // });
            if (template_id == "618b7fc373e50000097b6324") {
              // this.router.navigateByUrl('/new-contract/pece',{state:  val.custombuttonclick.data })
              window.open("/new-contract/pece/" + res.result._id, "_blank");
              // this.router.navigateByUrl('/new-contract/pece/'+res.result._id);
            }
            if (template_id == "618cf5bf43dbf00009e6c6c2") {
              // this.router.navigateByUrl('/new-contract/tm-flow',{state:  val.custombuttonclick.data })
              window.open("/new-contract/tm-flow/" + res.result._id, "_blank");
              // this.router.navigateByUrl('/new-contract/tm-flow/'+res.result._id)
            }
            if (template_id == "618d0cc24206a40008e814dc") {
              // this.router.navigateByUrl('/new-contract/warranty',{state:  val.custombuttonclick.data })
              window.open("/new-contract/warranty/" + res.result._id, "_blank");

              // this.router.navigateByUrl('/new-contract/warranty/'+res.result._id)
            }
            if (template_id == "618d0c934206a40008e814d7") {
              // this.router.navigateByUrl('/new-contract/credit',{state:  val.custombuttonclick.data })
              window.open("/new-contract/credit/" + res.result._id, "_blank");
              // this.router.navigateByUrl('/new-contract/credit/'+res.result._id)
            }
            if (template_id == "618e1468bcba120009fdac1c") {
              // this.router.navigateByUrl('/new-contract/bio-energetics',{state:  val.custombuttonclick.data })
              window.open(
                "/new-contract/bio-energetics/" + res.result._id,
                "_blank"
              );
              // this.router.navigateByUrl('/new-contract/bio-energetics/'+res.result._id)
            }
            this.onNoClick();
          });
      }
      if (val.field.name == "Federal_Tax_ID") {
        document
          .querySelector("#Federal_Tax_ID")
          .setAttribute("value", val.fieldval);
      }
      if (val.field.name == "Medical_Business_License") {
        document
          .querySelector("#Medical_Business_License")
          .setAttribute("value", val.fieldval);
      }
      if (val.field.name == "Title") {
        document.querySelector("#Title").setAttribute("value", val.fieldval);
      }
      if (val.field.name == "Bus_Phone") {
        document
          .querySelector("#Bus_Phone")
          .setAttribute("value", val.fieldval);
      }
      if (val.field.name == "Business_Structure") {
        let s_corp = document.getElementById(
          `${val.field.name}_S_Corp`
        ) as HTMLInputElement;
        s_corp.disabled = true;
        s_corp.checked = false;
        let corp = document.getElementById(
          `${val.field.name}_Corp`
        ) as HTMLInputElement;
        corp.disabled = true;
        corp.checked = false;
        let lls = document.getElementById(
          `${val.field.name}_LLs`
        ) as HTMLInputElement;
        lls.disabled = true;
        lls.checked = false;
        let sol_prop = document.getElementById(
          `${val.field.name}_Sol_Prop`
        ) as HTMLInputElement;
        sol_prop.disabled = true;
        sol_prop.checked = false;
        const ele = document.getElementById(
          `${val.field.name}_${val.fieldval}`
        ) as HTMLInputElement;
        ele.checked = true;
        ele.disabled = false;
      }
      if (val.field.name == "Term_Requested") {
        let t24 = document.getElementById(
          `${val.field.name}_24`
        ) as HTMLInputElement;
        t24.disabled = true;
        t24.checked = false;
        let t26 = document.getElementById(
          `${val.field.name}_26`
        ) as HTMLInputElement;
        t26.disabled = true;
        t26.checked = false;
        let t38 = document.getElementById(
          `${val.field.name}_38`
        ) as HTMLInputElement;
        t38.disabled = true;
        t38.checked = false;
        let t60 = document.getElementById(
          `${val.field.name}_60`
        ) as HTMLInputElement;
        t60.disabled = true;
        t60.checked = false;
        const ele = document.getElementById(
          `${val.field.name}_${val.fieldval}`
        ) as HTMLInputElement;
        ele.checked = true;
        ele.disabled = false;
      }
      if (val.field.name == "Social_Security") {
        document
          .querySelector("#Social_Security")
          .setAttribute("value", val.fieldval);
      }
      if (val.field.name == "ownership_percentage") {
        document
          .querySelector("#ownership_percentage")
          .setAttribute("value", val.fieldval);
      }
      if (val.field.name == "Own_Rent_Home") {
        document
          .querySelector("#Own_Rent_Home")
          .setAttribute("value", val.fieldval);
      }
      if (val.field.name == "Name_2") {
        document.querySelector("#Name_2").setAttribute("value", val.fieldval);
      }
      if (val.field.name == "Social_Security_2") {
        document
          .querySelector("#Social_Security_2")
          .setAttribute("value", val.fieldval);
      }
      if (val.field.name == "Ownership1") {
        document
          .querySelector("#Ownership1")
          .setAttribute("value", val.fieldval);
      }
      if (val.field.name == "Own_Rent_Home_2") {
        document
          .querySelector("#Own_Rent_Home_2")
          .setAttribute("value", val.fieldval);
      }
      if (val.field.name == "Home_Address_2") {
        document
          .querySelector("#Home_Address_2")
          .setAttribute("value", val.fieldval);
      }
      if (val.field.name == "Home_Phone_2") {
        document
          .querySelector("#Home_Phone_2")
          .setAttribute("value", val.fieldval);
      }
      if (val.field.name == "Name_of_Bank_Branch") {
        document
          .querySelector("#Name_of_Bank_Branch")
          .setAttribute("value", val.fieldval);
      }
      if (val.field.name == "Bank_Branch_Phone") {
        document
          .querySelector("#Bank_Branch_Phone")
          .setAttribute("value", val.fieldval);
      }
      if (val.field.name == "Checking_Acct") {
        document
          .querySelector("#Checking_Acct")
          .setAttribute("value", val.fieldval);
      }
      if (val.field.name == "Contact_Name") {
        document
          .querySelector("#Contact_Name")
          .setAttribute("value", val.fieldval);
      }
      if (val.field.name == "Phone1") {
        document.querySelector("#Phone1").setAttribute("value", val.fieldval);
      }
      if (val.field.name == "How_Long_Has_This_Account_Been_Open") {
        document
          .querySelector("#How_Long_Has_This_Account_Been_Open")
          .setAttribute("value", val.fieldval);
      }
      if (val.field.name == "Equipment_Cost") {
        document
          .querySelector("#Equipment_Cost")
          .setAttribute("value", `$${val.fieldval}`);
      }
      if (val.field.name == "Vendor_Supplier") {
        document
          .querySelector("#Vendor_Supplier")
          .setAttribute("value", val.fieldval);
      }
      if (val.field.name == "Contact_Name1") {
        document
          .querySelector("#Contact_Name1")
          .setAttribute("value", val.fieldval);
      }
      if (val.field.name == "Equipment_Type") {
        document
          .querySelector("#Equipment_Type")
          .setAttribute("value", val.fieldval);
      }
      if (val.field.name == "Date_Closed") {
        document
          .querySelector("#Date_Closed")
          .setAttribute("value", val.fieldval);
      }
      if (val.field.name == "Experian") {
        document.querySelector("#Experian").setAttribute("value", val.fieldval);
      }
      if (val.field.name == "Equifax") {
        document.querySelector("#Equifax").setAttribute("value", val.fieldval);
      }
      if (val.field.name == "TransUnion") {
        document
          .querySelector("#TransUnion")
          .setAttribute("value", val.fieldval);
      }
      if (val.field.name == "Credit") {
        document.querySelector("#Credit").setAttribute("value", val.fieldval);
      }
      if (val.field.name == "Serial_Number") {
        document
          .querySelector("#Serial_Number")
          .setAttribute("value", val.fieldval);
      }
      if (val.field.name == "Pick_List_2") {
        document
          .querySelector("#Pick_List_2")
          .setAttribute("value", val.fieldval);
      }
    }
  }
  add_contract_field(key, val) {
    // this.remove_contract_field();
    switch (key) {
      case "618d0c934206a40008e814d7": // Credit application
        setTimeout(() => {
          this.formfieldrefreshdata = {
            field: "addfromcontrol",
            value: [
              {
                label: "Federal Tax ID",
                name: "Federal_Tax_ID",
                type: "text",
                value: val.Federal_Tax_ID,
                validations: [
                  { rule: "required", message: "Federal Tax ID is Required" },
                ],
                after: "template_id",
              },
              {
                label: "Legal Company Name:",
                name: "Legal_Company_Name",
                type: "hidden",
                // validations: [{ rule: 'required', message: 'Legal Company Name is Required' }],
                value: val.Legal_Company_Name,
                after: "Federal_Tax_ID",
              },
              {
                label: "Medical / Business License #:",
                name: "Medical_Business_License",
                type: "text",
                value: val.Medical_Business_License,
                validations: [
                  {
                    rule: "required",
                    message: "Medical / Business License is Required",
                  },
                ],
                after: "Legal_Company_Name",
              },
              {
                label: "Title",
                name: "Title",
                type: "text",
                value: val.Title,
                validations: [
                  { rule: "required", message: "Title is Required" },
                ],
                after: "Medical_Business_License",
              },
              {
                label: "Bus Phone",
                name: "Bus_Phone",
                type: "number",
                value: val.Bus_Phone,
                validations: [
                  { rule: "required", message: "Bus Phone is Required" },
                ],
                after: "Title",
              },
              {
                label: "Business Structure",
                name: "Business_Structure",
                type: "select",
                value: val.Business_Structure,
                val: [
                  { name: "S Corp", val: "S_Corp" },
                  { name: "Corp", val: "Corp" },
                  { name: "LLs", val: "LLs" },
                  { name: "Sol Prop", val: "Sol_Prop" },
                ],
                validations: [
                  {
                    rule: "required",
                    message: "Business Structure is Required",
                  },
                ],
                after: "Bus_Phone",
              },
              {
                label: "Term Requested",
                name: "Term_Requested",
                type: "select",
                value: val.Term_Requested,
                val: [
                  { name: "24", val: "24" },
                  { name: "26", val: "26" },
                  { name: "38", val: "38" },
                  { name: "60", val: "60" },
                ],
                validations: [
                  { rule: "required", message: "Term Requested is Required" },
                ],
                after: "Business_Structure",
              },
              {
                label: "Years in Business",
                name: "Years_in_Business",
                type: "date",
                value: val.Years_in_Business,
                validations: [
                  {
                    rule: "required",
                    message: "Years in Business is Required",
                  },
                ],
                after: "Term_Requested",
              },
              {
                heading: "Personal Information",
                label: "Name1",
                name: "Name1",
                type: "hidden",
                // validations: [{ rule: 'required', message: 'Personal Information is Required' }],
                value: val.preson_name ? val.preson_name : val.Name1,
                after: "Years_in_Business",
              },

              {
                label: "Social Security",
                name: "Social_Security",
                type: "text",
                value: val.Social_Security,
                validations: [
                  { rule: "required", message: "Social Security is Required" },
                ],
                after: "Name1",
              },
              {
                label: "Ownership Percentage",
                name: "ownership_percentage",
                type: "number",
                value: val.ownership_percentage,
                suffix: "%",
                validations: [
                  {
                    rule: "required",
                    message: "Ownership Percentage is Required",
                  },
                ],
                after: "Social_Security",
              },
              {
                label: "Own Rent Home",
                name: "Own_Rent_Home",
                type: "select",
                val: [
                  { val: "Own", name: "Own" },
                  { val: "Rent", name: "Rent" },
                ],
                value: val.Own_Rent_Home,
                validations: [
                  { rule: "required", message: "Own Rent Home is Required" },
                ],
                after: "ownership_percentage",
              },
              {
                label: "Home Address",
                name: "Home_Address",
                type: "hidden",
                // validations: [{ rule: 'required', message: 'Home Address is Required' }],
                value: val.homeaddress ? val.homeaddress : val.Home_Address,
                after: "Own_Rent_Home",
              },

              {
                label: "City personal address",
                name: "City_personal_address",
                type: "hidden",
                // validations: [{ rule: 'required', message: 'City personal address is Required' }],
                value: val.city ? val.city : val.City_personal_address,
                after: "Home_Address",
              },
              {
                label: "State personal address",
                name: "State_personal_address",
                type: "hidden",
                // validations: [{ rule: 'required', message: 'State personal address is Required' }],
                value: val.address ? val.address : val.State_personal_address,
                after: "City_personal_address",
              },
              {
                label: "Zip personal address",
                name: "Zip_personal_address",
                type: "hidden",
                // validations: [{ rule: 'required', message: 'Zip personal address is Required' }],
                value: val.zip ? val.zip : val.Zip_personal_address,
                after: "State_personal_address",
              },
              {
                label: "Home Phone",
                name: "Home_Phone",
                type: "hidden",
                // validations: [{ rule: 'required', message: 'Home Phone is Required' }],
                value: val.home_phone ? val.home_phone : val.Home_Phone,
                after: "Zip_personal_address",
              },
              {
                label: "Name 2",
                name: "Name_2",
                type: "text",
                value: val.Name_2,
                validations: [
                  { rule: "required", message: "Name 2 is Required" },
                ],
                after: "Home_Phone",
              },
              {
                label: "Social Security 2",
                name: "Social_Security_2",
                type: "text",
                value: val.Social_Security_2,
                validations: [
                  {
                    rule: "required",
                    message: "Social Security 2 is Required",
                  },
                ],
                after: "Name_2",
              },

              {
                label: "Ownership 1",
                name: "Ownership1",
                type: "number",
                value: val.Ownership1,
                suffix: "%",
                validations: [
                  { rule: "required", message: "Ownership 1 is Required" },
                ],
                after: "Social_Security_2",
              },
              {
                label: "Own Rent Home 2",
                name: "Own_Rent_Home_2",
                type: "select",
                val: [
                  { val: "Own", name: "Own" },
                  { val: "Rent", name: "Rent" },
                ],
                value: val.Own_Rent_Home_2,
                validations: [
                  { rule: "required", message: "Own Rent Home 2 is Required" },
                ],
                after: "Ownership1",
              },
              {
                label: "Home Address 2",
                name: "Home_Address_2",
                type: "text",
                value: val.Home_Address_2,
                validations: [
                  { rule: "required", message: "Home Address 2 is Required" },
                ],
                after: "Own_Rent_Home_2",
              },
              {
                label: "City 2",
                name: "City_2",
                type: "hidden",
                // validations: [{ rule: 'required', message: 'City 2 is Required' }],
                value: val.city_two ? val.city_two : val.City_2,
                after: "Home_Address_2",
              },
              {
                label: "State 2",
                name: "State_2",
                type: "hidden",
                // validations: [{ rule: 'required', message: 'State 2 is Required' }],
                value: val.state_two ? val.state_two : val.State_2,
                after: "City_2",
              },
              {
                label: "Zip 2",
                name: "Zip2",
                type: "hidden",
                // validations: [{ rule: 'required', message: 'Zip 2 is Required' }],
                value: val.zip_two ? val.zip_two : val.Zip2,
                after: "State_2",
              },
              {
                label: "Home Phone 2",
                name: "Home_Phone_2",
                type: "number",
                value: val.Home_Phone_2,
                validations: [
                  { rule: "required", message: "Home Phone 2 is Required" },
                ],
                after: "Zip2",
              },
              {
                label: "Name of Bank Branch",
                name: "Name_of_Bank_Branch",
                type: "text",
                value: val.Name_of_Bank_Branch,
                validations: [
                  {
                    rule: "required",
                    message: "Name of Bank Branch is Required",
                  },
                ],
                after: "Home_Phone_2",
              },
              {
                label: "Bank Branch Phone",
                name: "Bank_Branch_Phone",
                type: "number",
                value: val.Bank_Branch_Phone,
                validations: [
                  {
                    rule: "required",
                    message: "Bank Branch Phone is Required",
                  },
                ],
                after: "Name_of_Bank_Branch",
              },
              {
                label: "Checking Acct",
                name: "Checking_Acct",
                type: "number",
                value: val.Checking_Acct,
                validations: [
                  { rule: "required", message: "Checking Acct is Required" },
                ],
                after: "Bank_Branch_Phone",
              },
              {
                label: "Contact Name",
                name: "Contact_Name",
                type: "text",
                value: val.Contact_Name,
                validations: [
                  { rule: "required", message: "Contact Name is Required" },
                ],
                after: "Checking_Acct",
              },
              {
                label: "Phone 1",
                name: "Phone1",
                type: "number",
                value: val.Phone1,
                validations: [
                  { rule: "required", message: "Phone 1 is Required" },
                ],
                after: "Contact_Name",
              },
              {
                label: "How Long Has This Account Been Open?",
                name: "How_Long_Has_This_Account_Been_Open",
                type: "text",
                value: val.How_Long_Has_This_Account_Been_Open,
                validations: [
                  {
                    rule: "required",
                    message: "How Long Has This Account Been Open is Required",
                  },
                ],
                after: "Phone1",
              },
              {
                label: "Equipment Cost",
                name: "Equipment_Cost",
                type: "number",
                value: val.Equipment_Cost,
                prefix: "$",
                validations: [
                  { rule: "required", message: "Equipment Cost is Required" },
                ],
                after: "How_Long_Has_This_Account_Been_Open",
              },
              {
                label: "Vendor Supplier",
                name: "Vendor_Supplier",
                type: "text",
                value: val.Vendor_Supplier,
                validations: [
                  { rule: "required", message: "Vendor Supplier is Required" },
                ],
                after: "Equipment_Cost",
              },

              {
                label: "Contact Name 1",
                name: "Contact_Name1",
                type: "text",
                value: val.Contact_Name1,
                validations: [
                  { rule: "required", message: "Contact Name 1 is Required" },
                ],
                after: "Vendor_Supplier",
              },
              {
                label: "Phone 2",
                name: "Phone2",
                type: "hidden",
                // validations: [{ rule: 'required', message: 'Phone 2 is Required' }],
                value: val.phonetwo ? val.phonetwo : val.Phone2,
                after: "Contact_Name1",
              },
              {
                label: "Equipment Type",
                name: "Equipment_Type",
                type: "text",
                value: val.Equipment_Type,
                validations: [
                  { rule: "required", message: "Equipment Type is Required" },
                ],
                after: "Phone2",
              },
              {
                label: "Bankruptcies Month Year",
                name: "bankruptcies_month_year",
                type: "date",
                value: val.bankruptcies_month_year,
                validations: [
                  {
                    rule: "required",
                    message: "Bankruptcies Month Year is Required",
                  },
                ],
                after: "Equipment_Type",
              },
              {
                label: "Date Closed",
                name: "Date_Closed",
                type: "date",
                value: val.Date_Closed,
                validations: [
                  { rule: "required", message: "Date Closed is Required" },
                ],
                after: "bankruptcies_month_year",
              },
              {
                label: "Experian",
                name: "Experian",
                type: "text",
                value: val.Experian,
                validations: [
                  { rule: "required", message: "Experian is Required" },
                ],
                after: "Date_Closed",
              },
              {
                label: "Equifax",
                name: "Equifax",
                type: "text",
                value: val.Equifax,
                validations: [
                  { rule: "required", message: "Equifax is Required" },
                ],
                after: "Experian",
              },
              {
                label: "Trans Union",
                name: "TransUnion",
                type: "text",
                value: val.TransUnion,
                validations: [
                  { rule: "required", message: "Trans Union is Required" },
                ],
                after: "Equifax",
              },
              {
                label: "Credit",
                name: "Credit",
                type: "text",
                value: val.Credit,
                validations: [
                  { rule: "required", message: "Credit is Required" },
                ],
                after: "TransUnion",
              },
            ],
          };
        }, 50);
        break;
      case "618b7fc373e50000097b6324": // Pece contract
        setTimeout(() => {
          this.formfieldrefreshdata = {
            field: "addfromcontrol",
            value: [
              {
                label: "Shipped",
                name: "Shipped",
                type: "date",
                value: val.Shipped,
                validations: [
                  { rule: "required", message: "Shipped is Required" },
                ],
                after: "template_id",
              },
              {
                label: "Equipment Model",
                name: "Pick_List_2",
                type: "text",
                value: val.Pick_List_2,
                validations: [
                  { rule: "required", message: "Pick List 2 is Required" },
                ],
                after: "Shipped",
              },
              {
                label: "Serial Number",
                name: "Serial_Number",
                type: "number",
                value: val.Serial_Number,
                validations: [
                  { rule: "required", message: "Serial Number is Required" },
                ],
                after: "Pick_List_2",
              },
              {
                label: "",
                name: "leadname",
                type: "hidden",
                value: val.leadname,
                after: "Serial_Number",
              },
              {
                label: "",
                name: "address",
                type: "hidden",
                value: val.address,
                after: "leadname",
              },
            ],
          };
        }, 200);
        break;
      case "618cf5bf43dbf00009e6c6c2": // Tm-Flow contract
        setTimeout(() => {
          this.formfieldrefreshdata = {
            field: "addfromcontrol",
            value: [
              {
                label: "Quotation Date",
                name: "Quotation_Date",
                type: "date",
                after: "template_id",
              },
              {
                label: "Ship Via",
                name: "Ship_Via",
                type: "text",
                after: "Quotation_Date",
              },
              {
                label: "Sales Type",
                name: "Sales_Type",
                type: "select",
                val: [
                  { val: "Wholesale", name: "Wholesale" },
                  { val: "Retail", name: "Retail" },
                ],
                value: "",
                after: "Ship_Via",
              },
              {
                label: "TM-Flow QTY",
                name: "TM_Flow_QTY",
                type: "number",
                after: "Sales_Type",
              },
              {
                label: "TM-Flow Item",
                name: "TM_Flow_Item",
                type: "text",
                after: "TM_Flow_QTY",
              },
              {
                label: "TM-Flow Description",
                name: "TM_Flow_Description",
                type: "text",
                after: "TM_Flow_Item",
              },
              {
                label: "Unit Volume",
                name: "Unit_Volume",
                type: "text",
                after: "TM_Flow_Description",
              },
              {
                label: "TM-Flow Unit Price",
                name: "TM_Flow_Unit_Price",
                type: "number",
                after: "Unit_Volume",
              },
              {
                label: "TM-Flow Amount",
                name: "TM_Flow_Amount",
                type: "number",
                after: "TM_Flow_Unit_Price",
              },
              {
                label: "TM-Flow Subtotal",
                name: "TM_Flow_Subtotal",
                type: "number",
                after: "TM_Flow_Amount",
              },
              {
                label: "YEAR EXTENDED WARRANTY WITH 30 DAY TRIAL OF PECE1",
                name: "YEAR_EXTENDED_WARRANTY_WITH_30_DAY_TRIAL_OF_PECE1",
                type: "number",
                after: "TM_Flow_Subtotal",
              },
              {
                label: "TM-Flow Tax",
                name: "TM_FLow_Tax",
                type: "number",
                after: "YEAR_EXTENDED_WARRANTY_WITH_30_DAY_TRIAL_OF_PECE1",
              },
              {
                label: "TM-Flow Shipping",
                name: "TM_Flow_Shipping",
                type: "number",
                after: "TM_FLow_Tax",
              },
              {
                label: "TM-Flow Total",
                name: "TM_Flow_Total",
                type: "number",
                after: "TM_Flow_Shipping",
              },
            ],
          };
        }, 200);
        break;
      case "618d0cc24206a40008e814dc": // Warranty contract
        setTimeout(() => {
          this.formfieldrefreshdata = {
            field: "addfromcontrol",
            value: [
              {
                label: "Serial Number",
                name: "Serial_Number",
                type: "number",
                value: val.Serial_Number,
                validations: [
                  { rule: "required", message: "Serial Number is Required" },
                ],
                after: "template_id",
              },
              {
                label: "Shipped",
                name: "Shipped",
                type: "date",
                value: val.Shipped,
                validations: [
                  { rule: "required", message: "Shipped is Required" },
                ],
                after: "Serial_Number",
              },
              {
                label: "",
                name: "City",
                type: "hidden",
                value: val.City,
                after: "Shipped",
              },
              {
                label: "",
                name: "Phone",
                type: "hidden",
                value: val.Phone,
                after: "City",
              },
              {
                label: "",
                name: "PracticeName",
                type: "hidden",
                value: val.PracticeName,
                after: "Phone",
              },
              {
                label: "",
                name: "State",
                type: "hidden",
                value: val.State,
                after: "PracticeName",
              },
              {
                label: "",
                name: "Street",
                type: "hidden",
                value: val.Street,
                after: "State",
              },
              {
                label: "",
                name: "Zip",
                type: "hidden",
                value: val.Zip,
                after: "Street",
              },
              {
                label: "",
                name: "auth_signatory",
                type: "hidden",
                value: val.auth_signatory,
                after: "Zip",
              },
              {
                label: "",
                name: "printed_name",
                type: "hidden",
                value: val.printed_name,
                after: "auth_signatory",
              },
              {
                label: "",
                name: "equipment",
                type: "hidden",
                value: val.equipment,
                after: "printed_name",
              },
            ],
          };
        }, 200);
        break;
      case "618e1468bcba120009fdac1c": // BioEnergetics contract
        setTimeout(() => {
          this.formfieldrefreshdata = {
            field: "addfromcontrol",
            value: [
              {
                label: "Agreement On",
                name: "agreement_on",
                type: "date",
                after: "template_id",
              },
            ],
          };
        }, 200);
        break;
      default:
        this.remove_contract_field();
        break;
    }
    setTimeout(() => {
      // this.loader = false;
      // this.onload = true;
    }, 1000);
    this.formfieldrefresh = true;
  }
  remove_contract_field() {
    this.formfieldrefreshdata = {
      field: "removefromcontrol",
      value: [
        "Federal_Tax_ID",
        "Legal_Company_Name",
        "Medical_Business_License",
        "Title",
        "Bus_Phone",
        "Business_Structure",
        "Term_Requested",
        "Years_in_Business",
        "Name1",
        "Social_Security",
        "ownership_percentage",
        "Own_Rent_Home",
        "Home_Address",
        "City_personal_address",
        "State_personal_address",
        "Zip_personal_address",
        "Home_Phone",
        "Name_2",
        "Social_Security_2",
        "Ownership1",
        "Own_Rent_Home_2",
        "Home_Address_2",
        "City_2",
        "State_2",
        "Zip2",
        "Home_Phone_2",
        "Name_of_Bank_Branch",
        "Bank_Branch_Phone",
        "Checking_Acct",
        "Contact_Name",
        "Phone1",
        "How_Long_Has_This_Account_Been_Open",
        "Equipment_Cost",
        "Vendor_Supplier",
        "Contact_Name1",
        "Phone2",
        "Equipment_Type",
        "bankruptcies_month_year",
        "Date_Closed",
        "Experian",
        "Equifax",
        "TransUnion",
        "Credit",
        "Shipped",
        "Pick_List_2",
        "Serial_Number",
        "Quotation_Date",
        "Ship_Via",
        "Sales_Type",
        "TM_Flow_QTY",
        "TM_Flow_Item",
        "TM_Flow_Description",
        "Unit_Volume",
        "TM_Flow_Unit_Price",
        "TM_Flow_Amount",
        "TM_Flow_Subtotal",
        "YEAR_EXTENDED_WARRANTY_WITH_30_DAY_TRIAL_OF_PECE1",
        "TM_FLow_Tax",
        "TM_Flow_Shipping",
        "TM_Flow_Total",
        // 'Serial_Number',
        // 'Shipped',
      ],
    };
  }
  onNoClick(): void {
    this.dialogRef.close();
  }
}
