import { NgModule } from "@angular/core";
import { filter } from "rxjs/operators";
import {
  Routes,
  RouterModule,
  ActivatedRoute,
  Router,
  RouterEvent,
  NavigationEnd,
} from "@angular/router";
import { CookieService } from "ngx-cookie-service";
import { environment } from "src/environments/environment";
import { ForgetPasswordComponent } from "./common/forget-password/forget-password.component";
import { LoginComponent } from "./common/login/login.component";
import { MyAccountComponent } from "./common/my-account/my-account.component";
import { ResetPasswordComponent } from "./common/reset-password/reset-password.component";
import { ContractViewComponent } from "./components/contract-view/contract-view.component";
import { ContractComponent } from "./components/contract/contract.component";
import { FolderViewComponent } from "./components/folder-view-management/folder-view/folder-view.component";
import { FullDetailsComponent } from "./components/full-details/full-details.component";
import { LeadFolderViewComponent } from "./components/lead-folder-view/lead-folder-view.component";

import { SalesOpportunityOverviewComponent } from "./components/sales-opportunity-overview/sales-opportunity-overview.component";

import { VideoGalleryComponent } from "./components/video-gallery/video-gallery/video-gallery.component";
import { ApiService } from "./services/api.service";
import { AuthGuard } from "./services/auth.guard";
// import { TraningAddEditComponent } from './components/manage-traning/traning-add-edit/traning-add-edit.component';
import { ResolveService } from "./services/resolve.service";

import { ErrorComponent } from "./common/error/error.component";

import { CommunicationWithEmailComponent } from "./common/communication-with-email/communication-with-email.component";

import { LeadcontractComponent } from "./leadcontract/leadcontract.component";

import { FaqaddeditComponent } from "./components/faq_managment/faqaddedit/faqaddedit.component";

import { FaqcategoryaddeditComponent } from "./components/faq_managment/faqcategoryaddedit/faqcategoryaddedit.component";

import { FaqcategorylistingComponent } from "./components/faq_managment/faqcategorylisting/faqcategorylisting.component";
import { FaqlistingComponent } from "./components/faq_managment/faqlisting/faqlisting.component";

import { FaqviewComponent } from "./components/faq_managment/faqview/faqview.component";
import { LeadpracticeComponent } from "./components/lead/leadpractice/leadpractice.component";
import { AddleadcontractComponent } from "./components/lead/addleadcontract/addleadcontract.component";
import { PrcticeQuestionnaireComponent } from "./components/prctice-questionnaire/prctice-questionnaire.component";
import { CreditComponent } from "./components/credit/credit.component";
import { PeceContractComponent } from "./components/pece-contract/pece-contract.component";
import { TmflowContractComponent } from "./components/tmflow-contract/tmflow-contract.component";
import { TmflowfiveyearContractComponent } from "./components/tmflowfiveyear-contract/tmflowfiveyear-contract.component";
import { CommissionListingComponent } from "./components/commission/commission-listing/commission-listing.component";
import { CommissionAddComponent } from "./components/commission/commission-add/commission-add.component";
import { CommissionPaymentComponent } from "./components/commission/commission-payment/commission-payment.component";
import { CornGoogleEventsComponent } from "./components/corn-google-events/corn-google-events.component";
import { TemporaryTechSignupStopComponent } from "./common/temporary-tech-signup-stop/temporary-tech-signup-stop.component";
import { PendingContractListComponent } from "./components/pending-contract-list/pending-contract-list.component";
import { TrainingproxymanagementComponent } from "./components/trainingproxymanagement/trainingproxymanagement/trainingproxymanagement.component";
import{AllemailsquedReportsComponent} from "./components/allemailsqued-reports/allemailsqued-reports.component"
import { AdminEmailSettingsComponent } from "./components/admin-email-settings/admin-email-settings.component";

const routes: Routes = [
  {
    path: "faqview",
    component: FaqviewComponent,

    resolve: { resolvedata: ResolveService }, // resolve servuce for load data
    data: {
      requestcondition: {
        secret: "secret",
        token: "token",
        source: "",
        condition: {
          // condition for how much data show in one time
        },
        sort: {
          type: "desc", // data sort by decreasing order
          field: "_id", // data sort by first name
        },
        searchcondition: {
          // "status": 1
          user_role: "user_role",
        },
      },
      endpoint: "api/allfaqlist", // endpoint of userlist
      api_url: environment.api_url, // base url of database
    },
  },

  {
    canActivate: [AuthGuard],
    path: "admin/addfaqcategory",
    component: FaqcategoryaddeditComponent,
  },

  {
    path: "admin/faqcategory/edit/:id",
    canActivate: [AuthGuard],
    component: FaqcategoryaddeditComponent,
    resolve: { resolvedata: ResolveService }, // resolve servuce for load data
    data: {
      requestcondition: {
        secret: "secret",
        token: "token",
        id: "_id",
        condition: {},
      },
      endpoint: "api/getonefaqcategory", // for single user
      api_url: environment.api_url, // base url call
    },
  },

  { path: "admin/addfaq", component: FaqaddeditComponent },
  {
    path: "admin/faq/edit/:_id",
    canActivate: [AuthGuard],
    component: FaqaddeditComponent,
    resolve: { resolvedata: ResolveService }, // resolve servuce for load data
    data: {
      requestcondition: {
        secret: "secret",
        token: "token",
        id: "_id",
        condition: {},
      },
      endpoint: "api/getonefaq", // for single user
      api_url: environment.api_url, // base url call
    },
  },
  {
    path: "admin/prctice",
    component: PrcticeQuestionnaireComponent,
  },

  {
    path: "tmflowfive/contract/:_id/:sing_id",
    component: TmflowfiveyearContractComponent,
    resolve: { resolvedata: ResolveService }, // resolve servuce for load data
    data: {
      requestcondition: {
        secret: "secret",
        token: "token",
        source: "",
        condition: {
          // condition for how much data show in one time
          id: "_id",
          limit: 1,
          skip: 0,
          type: "lead",
          _id: "id",
        },
        sort: {
          type: "desc", // data sort by decreasing order
          field: "_id", // data sort by first name
        },
        searchcondition: {
          // "status": 1
        },
      },
      endpoint: "api/getoneleadfolderview",// endpoint of userlist
      api_url: environment.api_url, // base url of database
    },
  },
  {
    path: "pece/contract/:_id/:p_id/:sing_id",
    component: PeceContractComponent,
    resolve: { resolvedata: ResolveService }, // resolve servuce for load data
    data: {
      requestcondition: {
        secret: "secret",
        token: "token",
        source: "",
        condition: {
          // condition for how much data show in one time
          id: "_id",
          limit: 1,
          skip: 0,
          type: "lead",
          _id: "id",
        },
        sort: {
          type: "desc", // data sort by decreasing order
          field: "_id", // data sort by first name
        },
        searchcondition: {
          // "status": 1
        },
      },
      endpoint: "api/getoneleadfolderview", // endpoint of userlist
      api_url: environment.api_url, // base url of database
    },
  },
  {
    path: "tmflow/contract/:_id/:p_id/:sing_id",
    component: TmflowContractComponent,
    resolve: { resolvedata: ResolveService }, // resolve servuce for load data
    data: {
      requestcondition: {
        secret: "secret",
        token: "token",
        source: "",
        condition: {
          // condition for how much data show in one time
          id: "_id",
          limit: 1,
          skip: 0,
          type: "lead",
          _id: "id",
        },
        sort: {
          type: "desc", // data sort by decreasing order
          field: "_id", // data sort by first name
        },
        searchcondition: {
          // "status": 1
        },
      },
      endpoint: "api/getoneleadfolderview", // endpoint of userlist
      api_url: environment.api_url, // base url of database
    },
  },
  {
    path: "admin/credit/:_id/:p_id/:sing_id",
    component: CreditComponent,
    resolve: { resolvedata: ResolveService }, // resolve servuce for load data
    data: {
      requestcondition: {
        secret: "secret",
        token: "token",
        source: "",
        condition: {
          // condition for how much data show in one time
          id: "_id",
          limit: 1,
          skip: 0,
          type: "lead",
          _id: "id",
        },
        sort: {
          type: "desc", // data sort by decreasing order
          field: "_id", // data sort by first name
        },
        searchcondition: {
          // "status": 1
        },
      },
      endpoint: "api/getoneleadfolderview", // endpoint of userlist
      api_url: environment.api_url, // base url of database
    },
  },
  {
    path: "admin/credit/:_id/:p_id",
    component: CreditComponent,
    resolve: { resolvedata: ResolveService }, // resolve servuce for load data
    data: {
      requestcondition: {
        secret: "secret",
        token: "token",
        source: "",
        condition: {
          // condition for how much data show in one time
          id: "_id",
          limit: 1,
          skip: 0,
          type: "lead",
          _id: "id",
        },
        sort: {
          type: "desc", // data sort by decreasing order
          field: "_id", // data sort by first name
        },
        searchcondition: {
          // "status": 1
        },
      },
      endpoint: "api/getoneleadfolderview", // endpoint of userlist
      api_url: environment.api_url, // base url of database
    },
  },
  {
    path: "lead/credit/:_id/:p_id/:sing_id",
    component: CreditComponent,
    resolve: { resolvedata: ResolveService }, // resolve servuce for load data
    data: {
      requestcondition: {
        secret: "secret",
        token: "token",
        source: "",
        condition: {
          // condition for how much data show in one time
          id: "_id",
          limit: 1,
          skip: 0,
          type: "lead",
          _id: "id",
        },
        sort: {
          type: "desc", // data sort by decreasing order
          field: "_id", // data sort by first name
        },
        searchcondition: {
          // "status": 1
        },
      },
      endpoint: "api/getoneleadfolderview", // endpoint of userlist
      api_url: environment.api_url, // base url of database
    },
  },
  {
    path: "admin/faq",
    component: FaqlistingComponent,

    resolve: { resolvedata: ResolveService }, // resolve servuce for load data
    data: {
      requestcondition: {
        secret: "secret",
        token: "token",
        source: "",
        condition: {
          // condition for how much data show in one time
          limit: 10,
          skip: 0,
        },
        sort: {
          type: "desc", // data sort by decreasing order
          field: "_id", // data sort by first name
        },
        searchcondition: {
          // "status": 1
        },
      },
      endpoint: "api/faqlistview", // endpoint of userlist
      api_url: environment.api_url, // base url of database
    },
  },

  {
    path: "admin/faqcategory",
    component: FaqcategorylistingComponent,
    resolve: { resolvedata: ResolveService }, // resolve servuce for load data
    data: {
      requestcondition: {
        secret: "secret",
        token: "token",
        source: "",
        condition: {
          // condition for how much data show in one time
          limit: 10,
          skip: 0,
        },
        sort: {
          type: "desc", // data sort by decreasing order
          field: "_id", // data sort by first name
        },
        searchcondition: {
          // "status": 1
        },
      },
      endpoint: "api/faqcategorylistview", // endpoint of userlist
      api_url: environment.api_url, // base url of database
    },
  },

  {
    path: "communicationwithemail",
    component: CommunicationWithEmailComponent,
  },
  {
    path: 'pending/contract/list',
    component: PendingContractListComponent,
    resolve: { resolvedata: ResolveService }, // resolve servuce for load data
    data: {
      requestcondition: {
        secret: "secret",
        token: "token",
        source: "",
        condition: {
          // condition for how much data show in one time
          limit: 10,
          skip: 0,
        },
        sort: {
          type: "desc", // data sort by decreasing order
          field: "_id", // data sort by first name
        },
        searchcondition: {
          // "status": 1
        },
      },
      endpoint: "api10/pending-contract-list", // endpoint of userlist
      api_url: environment.api_url, // base url of database
    },
  },
  {
    path: 'pending/contract/list/:_id',
    component: PendingContractListComponent,
    resolve: { resolvedata: ResolveService }, // resolve servuce for load data
    data: {
      requestcondition: {
        secret: "secret",
        token: "token",
        source: "",
        condition: {
          // condition for how much data show in one time
          limit: 10,
          skip: 0,
          id:"_id"
        },
        sort: {
          type: "desc", // data sort by decreasing order
          field: "_id", // data sort by first name
        },
        searchcondition: {
          // "status": 1
        },
      },
      endpoint: "api10/pending-contract-list", // endpoint of userlist
      api_url: environment.api_url, // base url of database
    },
  },
  {
    // https://backoffice.betoparedes.com/lead/contract/60dd9ccbd5501f0008b13c63/60e2b173e3b0ad0b4fedf506/60e2d5939dbe740008e85389
    path: "lead/contract/:leadid/:_id/:contractid",
    component: LeadcontractComponent,
    // resolve: { resolvedata: ResolveService },
    // data: {
    //   requestcondition: {

    //     condition: {},
    //   },
    //   endpoint: 'api/contractdata',
    //   api_url: environment.api_url,
    // }
  },

  { path: "login", component: LoginComponent },
  // { path: "lead/contract/:_id", component: AddleadcontractComponent },
  {
    path: "lead/contract/edit/:_id",
    component: AddleadcontractComponent,
    resolve: { resolvedata: ResolveService }, // resolve servuce for load data
    data: {
      requestcondition: {
        secret: "secret",
        token: "token",
        id: "_id",
        condition: {},
      },
      endpoint: "api/getonepracticecontact", // for single user
      api_url: environment.api_url, // base url call
    },
  },
  {
    path: "contract/:randomstring",
    component: ContractComponent,
    // canActivate: [AuthGuard],
    resolve: { resolvedata: ResolveService },
    data: {
      requestcondition: {
        randomstring: "randomstring",
        secret: "secret",
        token: "token",
        condition: {},
      },
      endpoint: "api/getoneuserforautologin",
      api_url: environment.api_url,
    },
  },
  { path: "forgot-password", component: ForgetPasswordComponent },
  { path: "reset-password/:token", component: ResetPasswordComponent },
  {
    path: "my-account/:_id",
    canActivate: [AuthGuard],
    component: MyAccountComponent,
    resolve: { resolvedata: ResolveService },
    data: {
      requestcondition: {
        id: "_id",
        secret: "secret",
        token: "token",
        condition: {},
      },
      endpoint: "api/getoneuser",
      api_url: environment.api_url,
    },
  },
  {
    path: "contract-view/:_id",
    component: ContractViewComponent,
    resolve: { resolvedata: ResolveService },
    data: {
      requestcondition: {
        id: "_id",
        secret: "secret",
        token: "token",
        condition: {},
      },
      endpoint: "api/getoneuserview",
      api_url: environment.api_url,
    },
  },
  {
    path: "full-details/:type/:_id",
    component: FullDetailsComponent,
    resolve: { resolvedata: ResolveService },
    data: {
      requestcondition: {
        id: "_id",
        secret: "secret",
        token: "token",
        condition: {},
      },
      endpoint: "api/getoneuserview",
      api_url: environment.api_url,
    },
  },
  {
    path: "video-gallery",
    component: VideoGalleryComponent,
    resolve: { resolvedata: ResolveService },
    data: {
      requestcondition: {
        condition: {
          limit: 10,
          skip: 0,
        },
        sort: {
          type: "asc",
          field: "video_title",
        },
      },
      endpoint: "api1/allcategoryvideosasyncdata",
      api_url: environment.api_url,
    },
  },
  {
    path: "video-gallery/:_prodid",
    component: VideoGalleryComponent,
    resolve: { resolvedata: ResolveService },
    data: {
      requestcondition: {
        condition: {
          limit: 10,
          skip: 0,
        },
        sort: {
          type: "asc",
          field: "video_title",
        },
        searchcondition: {
          product_id: "_prodid",
        },
      },
      endpoint: "api1/getallproductvideodata",
      api_url: environment.api_url,
    },
  },
  {
    path: 'commission/list', component: CommissionListingComponent,
    resolve: { resolvedata: ResolveService },
    data: {
      requestcondition: {
        condition: {
          limit: 10,
          skip: 0,
        },
        sort: {
          type: "desc",
          field: "_id",
        },
        searchcondition: {
          type: "type",
          techordistributor: 'techordistributor'
        },
      },
      endpoint: "api10/get-commission-list",
      api_url: environment.api_url,
    }
  },
  {
    path: 'commission/sales/add',
    canActivate: [AuthGuard],
    component: CommissionAddComponent,
    resolve: { resolvedata: ResolveService },
    data: {
      requestcondition: {
        condition: {
          limit: 10,
          skip: 0,
        },
        sort: {
          type: "asc",
          field: "_id",
        },
        searchcondition: {
          commission_type: "sales_commission"
        },
      },
      endpoint: "api10/commission-tech-product",
      api_url: environment.api_url,
    }
  },
  {
    path: 'commission/event/add',
    canActivate: [AuthGuard],
    component: CommissionAddComponent,
    resolve: { resolvedata: ResolveService },
    data: {
      requestcondition: {
        condition: {
          limit: 10,
          skip: 0,
        },
        sort: {
          type: "asc",
          field: "_id",
        },
        searchcondition: {
          commission_type: "event_commission"
        },
      },
      endpoint: "api10/commission-tech-product",
      api_url: environment.api_url,
    }
  },
  {
    path: 'commission/payment/partial/:_id', component: CommissionPaymentComponent,
    resolve: { resolvedata: ResolveService },
    data: {
      requestcondition: {
        condition: {
          limit: 10,
          skip: 0,
        },
        sort: {
          type: "asc",
          field: "_id",
        },
        searchcondition: {
          _id: "commission_id"
        },
      },
      endpoint: "api10/get-one-commission-details",
      api_url: environment.api_url,
    }
  },
  {
    path: 'commission/payment/full/:_id', component: CommissionPaymentComponent,
    resolve: { resolvedata: ResolveService },
    data: {
      requestcondition: {
        condition: {
          limit: 10,
          skip: 0,
        },
        sort: {
          type: "asc",
          field: "_id",
        },
        searchcondition: {
          _id: "commission_id"
        },
      },
      endpoint: "api10/get-one-commission-details",
      api_url: environment.api_url,
    }
  },


  {
    path: 'cron/google-events', component: CornGoogleEventsComponent,
    resolve: { resolvedata: ResolveService },
    data: {
      requestcondition: {
        condition: {
          limit: 10,
          skip: 0,
        },
        sort: {
          type: "asc",
          field: "_id",
        },
        searchcondition: {
          insert_google_event: true
        },
      },
      endpoint: "api8/google_event_cron_report",
      api_url: environment.api_url,
    }
  },

  {
    path: 'allemailsqued-reports', component: AllemailsquedReportsComponent,
    resolve: { resolvedata: ResolveService },
    data: {
      requestcondition: {
        condition: {
          limit: 10,
          skip: 0,
        },
        sort: {
          type: 'desc', // data sort by decreasing order
          field: 'createdon_datetime', // data sort by first name
        },
        searchcondition: {
          actual_sender:'betoparedes',
          email_sent:1
        },
      },
      endpoint: "api/allemailsqued-success-emails",
      api_url: environment.api_url,
    }
  },

  {
    path: 'trainingproxymanagement', component: TrainingproxymanagementComponent,
    // resolve: { resolvedata: ResolveService },
    // data: {
    //   requestcondition: {
    //     condition: {
    //       limit: 10,
    //       skip: 0,
    //     },
    //     sort: {
    //       type: "asc",
    //       field: "_id",
    //     },
    //     searchcondition: {
    //       insert_google_event: true
    //     },
    //   },
    //   endpoint: "api8/google_event_cron_report",
    //   api_url: environment.api_url,
    // }
  },

  {
    path: 'block_onbordingcall', component: TemporaryTechSignupStopComponent,

    // canActivate: [AuthGuard], 
  },
  // { path: 'traning/add', component: TraningAddEditComponent },
  // { path: 'traning/edit/:id', component: TraningAddEditComponent },

  {
    path: "admin",
    canActivate: [AuthGuard],
    loadChildren: () =>
      import("./components/admin/admin.module").then((m) => m.AdminModule),
  },
  {
    path: "closer",
    canActivate: [AuthGuard],
    loadChildren: () =>
      import("./components/user-module/closer/closer.module").then(
        (m) => m.CloserModule
      ),
  },
  {
    path: "contract-manager",
    // canActivate: [AuthGuard],
    loadChildren: () =>
      import(
        "./components/user-module/contact-manager/contact-manager.module"
      ).then((m) => m.ContactManagerModule),
  },
  {
    path: "distributor",
    canActivate: [AuthGuard],
    loadChildren: () =>
      import("./components/user-module/distributor/distributor.module").then(
        (m) => m.DistributorModule
      ),
  },
  {
    path: "lead",
    canActivate: [AuthGuard],
    loadChildren: () =>
      import("./components/user-module/lead/lead.module").then(
        (m) => m.LeadModule
      ),
  },
  {
    path: "managecommisson",
    // canActivate: [AuthGuard],
    loadChildren: () =>
      import("./components/managecommisson/managecommison.module").then(
        (m) => m.ManageCommissonModule
      ),
  },
  {
    path: "sales-person",
    canActivate: [AuthGuard],
    loadChildren: () =>
      import(
        "./components/user-module/sales-manager/sales-manager.module"
      ).then((m) => m.SalesManagerModule),
  },
  {
    path: "technological-consultant",
    canActivate: [AuthGuard],
    loadChildren: () =>
      import(
        "./components/user-module/technological-consultant/technological-consultant.module"
      ).then((m) => m.TechnologicalConsultantModule),
  },
  {
    path: "resources",
    canActivate: [AuthGuard],
    loadChildren: () =>
      import(
        "./components/resources/resource-module/resource-module.module"
      ).then((m) => m.ResourceModuleModule),
  },
  {
    path: "jobticket",
    canActivate: [AuthGuard],
    loadChildren: () =>
      import(
        "./components/jobticket/jobticket-module/jobticket-module.module"
      ).then((m) => m.JobticketModuleModule),
  },
  {
    path: "traning",
    canActivate: [AuthGuard],
    loadChildren: () =>
      import("../app/components/training-managment/traning-module.module").then(
        (m) => m.TraningModuleModule
      ),
  },
  {
    path: "video-management",
    // canActivate: [AuthGuard],
    loadChildren: () =>
      import("./components/video-management/video-management.module").then(
        (m) => m.VideoManagementModule
      ),
  },
  // {
  //   path: 'lesson',
  //   loadChildren: () =>
  //     import('./components/manage-lession/lession-module/lession-module.module').then((m) => m.LessionModuleModule)
  // },

  {
    path: "calendar-management",
    loadChildren: () =>
      import(
        "./components/calendar-management/calendar-management.module"
      ).then((m) => m.CalendarManagementModule),
  },

  {
    path: "banner",
    canActivate: [AuthGuard],
    loadChildren: () =>
      import("./components/banners/banners.module").then(
        (m) => m.BannersModule
      ),
  },
  { path: 'media-center', loadChildren: () => import('./media-center/media-center.module').then(m => m.MediaCenterModule) },
  {
    path: "new-contract",
    loadChildren: () =>
      import(
        "./components/new-contracts/new-contract.module"
      ).then((m) => m.NewContractModule)
  },
  {
    path: "user-details/:type/:_id",
    // canActivate: [AuthGuard],
    component: FolderViewComponent,
    resolve: { resolvedata: ResolveService },
    data: {
      requestcondition: {
        secret: "secret",
        token: "token",
        condition: { id: "_id", limit: 1, skip: 0 },
      },
      endpoint: "api/getoneuserfolderview",
      api_url: environment.api_url,
    },
  },
  {
    path: "user-details/:type/:_id/:flag",
    // canActivate: [AuthGuard],
    component: FolderViewComponent,
    resolve: { resolvedata: ResolveService },
    data: {
      requestcondition: {
        secret: "secret",
        token: "token",
        condition: { id: "_id", limit: 1, skip: 0 },
      },
      endpoint: "api/getoneuserfolderview",
      api_url: environment.api_url,
    },
  },
  {
    path: "email-settings",
    // canActivate: [AuthGuard],
    component: AdminEmailSettingsComponent,
    resolve: { resolvedata: ResolveService }, // resolve servuce for load data
    data: {
      requestcondition: {
        secret: "secret",
        token: "token",
        source: "",
        condition: {
          // condition for how much data show in one time
          limit: 10,
          skip: 0,
        },
        sort: {
          type: "desc", // data sort by decreasing order
          field: "_id", // data sort by first name
        },
        searchcondition: {
          // "status": 1
        },
      },
      endpoint: "api10/adminsettings-email-list", // endpoint of emaillist
      api_url: environment.api_url, // base url of database
    },
  },
  {
    path: "lead/folder-view",
    component: LeadFolderViewComponent,
  },
  {
    // canActivate: [AuthGuard],
    path: "link-library",
    component: SalesOpportunityOverviewComponent,
  },
  { path: "error", component: ErrorComponent },
  { path: "**", pathMatch: "full", redirectTo: "login" },

];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
  providers: [ResolveService],
})
export class AppRoutingModule { }
