import { Injectable } from '@angular/core';
import {
  Router,
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
} from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { Observable } from 'rxjs';
import { ApiService } from './api.service';

@Injectable()
export class
  AuthGuard implements CanActivate {
  constructor(private router: Router, private cookie: CookieService, public apiservice: ApiService) { }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {
    console.log('This is auth guard', state.url);
    console.log('This is auth guard next.url', next);
    // console.log(next.snapshot.routeConfig.path);
    // console.log(state.url.split('/'),'+++++++');

    const url: string = state.url;
    this.cookie.set('redirectUrl', url, undefined, '/');
    const urlArr = state.url.split('/');
    console.log(urlArr, 'urlArrurlArr');

    if (
      (!this.cookie.check('jwtToken') &&
        this.cookie.get('jwtToken') === null) ||
      this.cookie.get('jwtToken') === 'undefined' ||
      this.cookie.get('jwtToken').length < 10
    ) {
      this.router.navigate(['login']); //  Jwt Token checking exist or not
    } else {
      console.log(next.url[0].path, '++++');

      const userType: any = JSON.parse(this.cookie.get('type'));
      /* Login User */
      // // console.log(next.url[0].path, '++++++++++', next);
      // if(state.url === '/technological-consultant/dashboard' && userType === 'technological-consultant'){
      //   console.log('++++++');
      //   if(this.cookie.check('recent_tech')){
      //   console.log('++++++');
      //     this.router.navigateByUrl('/block_onbordingcall');
      //     return true;
      //   }else{
      //   console.log('++++++');
      //     return true;
      //   }
      // }

      //  for making abstraction when user is not done with contract signing and onboarding call booking 
      // if (state.url === '/technological-consultant/dashboard' && userType === 'technological-consultant') {

      //   console.log('tech dashboard block .....');
      //   let allcookei: any = {};
      //   allcookei = this.cookie.getAll();
      //   console.log(allcookei, 'allcookei', allcookei.onbordingcall);
      //   // signature
      //   if (allcookei.signature == null && allcookei.onbordingcall != 'true') {
      //     console.log('in contract check block ');
      //     this.router.navigateByUrl('/contract/' + allcookei.randomstring);
      //     return true;
      //   }

      //   if (allcookei.onbordingcall != null && allcookei.onbordingcall != 'true') {
      //     console.log('in onboarding  check block ');

      //     this.router.navigateByUrl('/calendar-management/manage-appointment-after-sign/on-boarding/book-appointment');
      //     return true;
      //     // /calendar-management/manage-appointment-after-sign/on-boarding/book-appointment

      //   }
      //   return true;


      // }
      if (next.url[0].path === 'resources' && userType === 'admin') {
        return true;
      }

      if (next.url[0].path === 'resources') {
        return true;
      }

      if (next.url[0].path === 'jobtickte-category') {
        return true;
      }
      if (next.url[0].path === 'block_onbordingcall') {
        if (
          (!this.cookie.check('jwtToken') &&
            this.cookie.get('jwtToken') === null) ||
          this.cookie.get('jwtToken') === 'undefined' ||
          this.cookie.get('jwtToken').length < 10
        ) {
          this.router.navigate(['login']); //  Jwt Token checking exist or not
        }
        return true;
      }
      // if (next.url[0].path === 'manage-appointment-after-sign' && next.url[1].path === 'on-boarding' && next.url[2].path === 'book-appointment') {
      //   console.warn('dvwvrvrve')
      //   let send_data = {
      //     data:{
      //       signup_process: 'find',
      //     }
      //   }
      //   this.apiservice.getDataWithoutToken(send_data,'api10/change-signup-process').subscribe((res:any)=>{
      //       if(res.result[0].signup_process == 'Off'){          
      //         this.router.navigateByUrl('/block_onbordingcall');
      //         return true;
      //       }
      //   })
      // }
      // for training category and lesson list
      if (
        next.url[0].path === 'traning' &&
        this.cookie.check('type') &&
        JSON.parse(this.cookie.get('type')) === 'admin' &&
        urlArr[1] === next.url[0].path &&
        (urlArr[2] === 'category' || urlArr[2] === 'lesson')
      ) {
        return true;
      }

      // for contract manager list
      if (
        state.url === '/contract-manager/list' &&
        this.cookie.check('type') &&
        (JSON.parse(this.cookie.get('type')) === 'admin' ||
          JSON.parse(this.cookie.get('type')) === 'contract-manager')
      ) {
        return true;
      }

      // for contract listing
      if (
        state.url === '/contract-manager/contract/list' &&
        this.cookie.check('type') &&
        JSON.parse(this.cookie.get('type')) === 'admin'
      ) {
        return true;
      }

      // for admin training category and lesson list
      if (
        next.url[0].path === 'traning' &&
        this.cookie.check('type') &&
        JSON.parse(this.cookie.get('type')) === 'admin' &&
        urlArr[1] === next.url[0].path &&
        (urlArr[2] === 'category' || urlArr[2] === 'lesson')
      ) {
        return true;
      }
      // for ! admin training category and lesson list

      if (
        next.url[0].path === 'traning' &&
        urlArr[1] === next.url[0].path &&
        urlArr[2] !== 'category' &&
        urlArr[2] !== 'lesson'
      ) {
        return true;
      }

      // for video management list
      if (
        state.url === '/video-management/listing ' &&
        this.cookie.check('type') &&
        JSON.parse(this.cookie.get('type')) === 'admin'
      ) {
        return true;
      }

      // for job ticket admin
      if (
        next.url[0].path === 'jobticket' &&
        this.cookie.check('type') &&
        JSON.parse(this.cookie.get('type')) === 'admin' &&
        urlArr[1] === next.url[0].path &&
        (urlArr[2] === 'category' || urlArr[3] === 'admin')
      ) {
        return true;
      }
      // for job ticket !admin

      if (
        next.url[0].path === 'jobticket' &&
        urlArr[1] === next.url[0].path &&
        urlArr[2] !== 'category' &&
        urlArr[3] !== 'admin'
      ) {
        return true;
      }

      // for my account

      if (
        next.url[0].path === 'my-account' &&
        this.cookie.check('type') &&
        this.cookie.check('userid') &&
        JSON.parse(this.cookie.get('userid')) === urlArr[2]
      ) {
        return true;
      }

      if (state.url === '/commission/sales/add' && this.cookie.check('type')) {
        if (JSON.parse(this.cookie.get('type')) === 'admin') {
          console.log('++++++++++');
          return true;
        }
      }
      if (state.url === '/commission/event/add' && this.cookie.check('type')) {
        if (JSON.parse(this.cookie.get('type')) === 'admin') {
          console.log('++++++++++');
          return true;
        }
      }
      // if (this.cookie.check('redirectUrl')) {
      //   console.log(this.cookie.get('redirectUrl'))
      //   this.router.navigateByUrl(this.cookie.get('redirectUrl'));
      //   return ;
      // }
      // console.log(next.url[0].path,'+++next.url[0].path',userType);
      if (state.url === '/technological-consultant/dashboard' && userType === 'technological-consultant') {
        let send_data = {
          data: {
            signup_process: 'find',
          }
        }
        this.apiservice.getDataWithoutToken(send_data, 'api10/change-signup-process').subscribe((res: any) => {
          if (res.result[0].signup_process == 'Off') {
            // console.log(this.cookie.check('recent_tech'));
            if (this.cookie.check('recent_tech')) {
              console.log('++++++');
              this.router.navigateByUrl('/block_onbordingcall');
              return true;
            }
            else {
              return true;
            }

          } else {
            return true;
          }
        })
      }
      switch (next.url[0].path) {
        case 'login':
          break;
        case 'forget-password':
          break;
        default:
          if (userType === next.url[0].path) {
            // checking route and user type
            return true;
          } else {
            this.router.navigateByUrl(userType + '/dashboard'); // if user type and route are not eq redirect to dashboard
          }
      }
    }
  }
}
