import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { ApiService } from 'src/app/services/api.service';
import { UserserviceService } from 'src/app/services/userservice.service';

@Component({
  selector: 'app-contract-view',
  templateUrl: './contract-view.component.html',
  styleUrls: ['./contract-view.component.css']
})
export class ContractViewComponent implements OnInit {
  public alldata: any;
  public fullname = '';
  public flAds = '';
  public product_details: any = [];
  public commission_data_flag = false;
  public parent_product_info: any = [];
  public commission_data: any = [];
  constructor(private router: Router,
    private cookeiservice: CookieService,
    public activatedroute: ActivatedRoute,
    public apiservice: ApiService,
    public userservice: UserserviceService
  ) {
    this.getproductdetails();
  }

  ngOnInit() {

    this.activatedroute.data.forEach((res: any) => {
      // console.log('res')
      // console.log(res.resolvedata.result[0]);
      this.alldata = res.resolvedata.result[0];
      this.fullname = this.alldata.firstname + " " + this.alldata.lastname;
      this.flAds = this.alldata.address + ", " + this.alldata.state + ", " + this.alldata.city + ", " + this.alldata.zip;
    });

  }

  downloadcontract() {
    if (this.userservice.type == 'admin') {
      window.open("https://awsbackend-dev-patient-files-test.s3.amazonaws.com/beto-paredes-contracts-pdf/contract_" + this.activatedroute.snapshot.params._id + ".pdf", "_blank");
    } else {
      window.open("https://awsbackend-dev-patient-files-test.s3.amazonaws.com/beto-paredes-contracts-pdf/contract_" + this.userservice.userid + ".pdf", "_blank");
    }
  }
  getproductdetails() {
    this.apiservice
      .getDataWithoutToken(
        {
          token: this.userservice.jwttoken,
          secret: this.userservice.secret,
          searchcondition: {
            user_type: "distributor",
          },
        },
        "api10/get-commission-rule"
      )
      .subscribe((response: any) => {
        this.product_details = response.res;
        this.parent_data_details();
      });
  }
  parent_data_details() {
    this.commission_data_flag = true;
    if (this.alldata.parentid != null && this.alldata.parentid != "") {
      let send_data = {
        parent_id: this.alldata.parentid,
      };
      this.apiservice
        .getDataWithoutToken(send_data, "api10/get-parent-product")
        .subscribe((res: any) => {
          console.log(res);
          this.parent_product_info = res.res[0];
          for (const iterator of res.res[0].disableproduct) {
            for (const key in this.product_details) {
              if (this.product_details[key].product_id == iterator) {
                this.commission_data.push(this.product_details[key]);
              }
            }
          }
          for (let key in this.commission_data) {
            let check_name =
              this.commission_data[key].product_name.replace(/[-" "]/g, "_") +
              this.commission_data[key].commission_type;
            console.log(check_name);
            if (
              check_name == "PECEevent_commission" &&
              this.parent_product_info.PECEevent_commission != null &&
              this.parent_product_info.PECEevent_commission != ""
            ) {
              this.commission_data[key].commission_value =
                this.parent_product_info.PECEevent_commission;
            }
            if (
              check_name == "PECEsales_commission" &&
              this.parent_product_info.PECEsales_commission != null &&
              this.parent_product_info.PECEsales_commission != ""
            ) {
              this.commission_data[key].commission_value =
                this.parent_product_info.PECEsales_commission;
            }
            if (
              check_name == "TM_FLOW_ANS_MEDICAL_DEVICEsales_commission" &&
              this.parent_product_info
                .TM_FLOW_ANS_MEDICAL_DEVICEsales_commission != null &&
              this.parent_product_info
                .TM_FLOW_ANS_MEDICAL_DEVICEsales_commission != ""
            ) {
              this.commission_data[key].commission_value =
                this.parent_product_info.TM_FLOW_ANS_MEDICAL_DEVICEsales_commission;
            }
            if (
              check_name == "RST_Sanexussales_commission" &&
              this.parent_product_info.RST_Sanexussales_commission != null &&
              this.parent_product_info.RST_Sanexussales_commission != ""
            ) {
              this.commission_data[key].commission_value =
                this.parent_product_info.RST_Sanexussales_commission;
            }
            if (
              check_name == "Bio_Energeticsevent_commission" &&
              this.parent_product_info.Bio_Energeticsevent_commission != null &&
              this.parent_product_info.Bio_Energeticsevent_commission != ""
            ) {
              this.commission_data[key].commission_value =
                this.parent_product_info.Bio_Energeticsevent_commission;
            }
            if (
              check_name == "Bio_Energeticssales_commission" &&
              this.parent_product_info.Bio_Energeticssales_commission != null &&
              this.parent_product_info.Bio_Energeticssales_commission != ""
            ) {
              this.commission_data[key].commission_value =
                this.parent_product_info.Bio_Energeticssales_commission;
            }
          }
          this.commission_data_flag = false;
        });
    } else {
      this.commission_data_flag = false;
    }
  }
}
