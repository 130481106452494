import { HttpClient } from "@angular/common/http";
import { Component, Inject, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { CookieService } from "ngx-cookie-service";
import { ApiService } from "src/app/services/api.service";
import { UserserviceService } from "src/app/services/userservice.service";
import {
  MatDialog,
  MatDialogRef,
  MatSnackBar,
  MAT_DIALOG_DATA,
} from "@angular/material";
import { DistributorRoutingModule } from "../../user-module/distributor/distributor-routing.module";

export interface DialogData {
  data: any;
  val: any;
}

@Component({
  selector: "app-contract-request-list",
  templateUrl: "./contract-request-list.component.html",
  styleUrls: ["./contract-request-list.component.css"],
})
export class ContractRequestListComponent implements OnInit {
  productListdatatable: any = [];
  // public sortdata: any;
  // public datacollection: any;
  public datesearchsourcecount: any = 0;
  // datesearchsourcecount: any = 0;
  public userid: any;
  public product_array: any = [];
  public status: any = [
    { val: "Drafting", name: "Drafting" },
    { val: "Requested contract Approval", name: "Requested contract Approval" },
    { val: "Approved", name: "Approved" },
    { val: "Contract Pending Signature", name: "Contract Pending Signature" },
    { val: "Pending Approval", name: "Pending Approval" },
    { val: "Send To Lead", name: "Send To Lead" },
    { val: "Contract Approved ", name: "Contract Approved" },
    {
      val: "Lead Request For Contract Modify",
      name: "Lead Request For Contract Modify",
    },
    {
      val: "Contract modification approval asked by Rep",
      name: " Contract modification approval asked by Rep",
    },
  ];
  datacollection: any = "api/contractrequestslistviews";
  // data list
  public refreshbtnflag = false;

  // use for status search
  statusarray: any = [
    { val: "Drafting", name: "Drafting" },
    { val: "Requested contract Approval", name: "Requested contract Approval" },
    { val: "Approved", name: "Approved" },
    { val: "Contract Pending Signature", name: "Contract Pending Signature" },
    { val: "Pending Approval", name: "Pending Approval" },
    { val: "Send To Lead", name: "Send To Lead" },
    { val: "Contract Approved ", name: "Contract Approved" },
    {
      val: "Contract modification approval asked by Rep",
      name: " Contract modification approval asked by Rep",
    },
  ];
  // public product_array: any = [
  //   { val: '5ff567e8bad2240008b33c9f', name: 'MedWorldOne' },
  //   { val: '602e3966022d8d000841ca46', name: 'ApogeeInvent' },
  //   { val: '602e39bd15c7ce0008f2456f', name: 'Beto Paredes Joint Venture Opportunity' },
  //   { val: '604a0b6e40962e00088f0d79', name: 'InfluxIQ Marketing Systems' },
  //   { val: '604afd0f4bb55500082eb18d', name: 'Universal Tech Associates' },
  //   { val: '604afd6a4bb55500082eb18e', name: 'HPS Physicians' },
  //   { val: '604aff3691b7c80008ca11a8', name: 'PECE' },
  // ];
  // this is use for search drop down

  // this is use for modify header name
  modifyheaderarray: any = {
    contractfullname: "Contract Manager Name",
    userfullname: "Added For",
    leadfullname: "Lead Name",
    userparentfullname: "Distributor Name",
    productname: "Product Name",
    createdon_datetime: "Created on",
    currentstatus: "Status",
  };
  // this is use delete  data
  deleteendpoint = "api/deletecontractrequests";
  updateendpoint = "api/bannersupdate";
  // this is use redirect edit page
  editroute: any = "contract-manager/contract-request/edit";
  searchendpoint = "";
  tablename = "";
  // this is use for datatable skip
  productListdatatableskip: any = [
    "description",
    "_id",
    "distributor",
    "contractfullname",
  ];
  limitcond: any = {
    limit: 10,
    skip: 0,
    pagecount: 1,
  };
  // this is use  for searchdata
  searchsettings: any = {
    search: [
      {
        label: "Search By Lead Name",
        field: "leadfullname_s",
        values: "",
        serversearchdata: {
          endpoint: "api/exitsinglistnameforlead",
        },
      },
      {
        label: "Search By Tech Name",
        field: "userfullname_s",
        values: "",
        serversearchdata: { endpoint: "api/exitsinglistname" },
      },
    ],
    datesearch: [
      {
        startdatelabel: "Created On Start Date",
        enddatelabel: "Created On End Date",
        submit: "Search",
        field: "createdon_datetime",
      },
    ],
    selectsearch: [
      {
        label: "Search By Status",
        field: "currentstatus",
        values: this.status,
      },
      {
        label: "Search By Products",
        field: "products",
        values: this.product_array,
      },
    ],
    // textsearch: [
    //   { label: 'Search By Techname', field: 'userfullname_s' }
    // ],
  };

  productListdatatabledetailskip: any = [
    "_id",
    "uploadbanner",
    "category_id",
    "category_title_s",
    "category",
    "image",
    "file",
    "socialsharing_title",
    "socialsharing_link",
    "socialsharing_products",
    "is_socialsharing",
    "title_search",
    "products_s",
    "createdon_datetime",
    "distributor",
    "status",
    "leadfullname_s",
    "parentid",
    "products",
    "userfullname_s",
  ];
  public type: any;
  libdata: any = {
    //basecondition: { user_id:this.userservice.userid },
    basecondition: {
      user_id: this.userservice.userid,
      type: this.userservice.type,
    },
    updateendpoint: "api/bannerstatusupdate",
    hideeditbutton: true, // (hide edit button)
    hidedeletebutton: false, // (hide delete button)
    hideviewbutton: true, // (hide view button)
    hidestatustogglebutton: true, // (hide status toggle button)
    hideaction: false,
    updateendpointmany: "api/bannersupdate",
    deleteendpointmany: "api/deletecontractrequests",
    hidemultipleselectbutton: true,
    hidedeletemany: true,
    hideupdatemany: true, // (hide action column)
    detailview_override: [
      { key: "createdon_datetime", val: "Created on" },
      { key: "title", val: "Title" },
      { key: "description", val: "Description" },
      { key: "priority", val: "Priority" },
      { key: "category_title", val: "Category Title" },
      { key: "productname", val: "Product Name" },
      { key: "currentstatus", val: "Status" },
    ],
    custombuttons: [],

    tableheaders: [
      "userfullname",
      "leadfullname",
      "productname",
      "currentstatus",
      "createdon_datetime",
    ],
  };

  sortdata: any = {
    type: "desc", //  default sort data ascend and descend (desc)
    field: "createdon_datetime", // default field for sorting
    options: ["priority", "status","userparentfullname","userfullname","leadfullname","productname","currentstatus","createdon_datetime"],
  };
  public jwttoken: any = "";
  constructor(
    public router: Router,
    public Apiservice: ApiService,
    public http: HttpClient,
    public cookieservice: CookieService,
    public activatedRoute: ActivatedRoute,
    public userservice: UserserviceService,
    public snackBar: MatSnackBar,
    public dialog: MatDialog
  ) {
    if (this.cookieservice.check("jwtToken")) {
      this.jwttoken = this.cookieservice.get("jwtToken");
    }

    this.type = JSON.parse(this.cookieservice.get("type"));
    this.userid = JSON.parse(this.cookieservice.get("userid"));
    const endpoint = "api/contractrequestslistviews";
    const endpointc = "api/contractrequestslistviews-count";
    const data: any = {
      condition: {
        limit: 10,
        skip: 0,
      },
      sort: {
        type: "desc", // defalut field sort type
        field: "createdon_datetime", // default sort field
      },
      secret: this.userservice.secret,
      token: this.userservice.jwttoken,
      searchcondition: { user_id: this.userid, type: this.userservice.type },
    };
    if (this.type == "contract-manager") {
      data.searchcondition = {
        products: JSON.parse(this.cookieservice.get("products")),
        type: this.userservice.type,
      };
    } else {
      if (
        this.type == "technological-consultant" ||
        this.type == "sales-person"
      ) {
        data.searchcondition = {
          user_id: this.userservice.userid,
          type: this.userservice.type,
        };
      }
      if (this.type == "admin") {
        data.searchcondition = {};
      }
    }
    console.log(data);

    this.Apiservice.getDataWithoutToken(data, endpointc).subscribe(
      (res: any) => {
        this.datesearchsourcecount = res.count; // sarch count  (How many data there)
      }
    );
    console.log(this.userservice.type, "++++");
  }

  setLibData() {
    let target = {};
    let source = {};

    if (JSON.parse(this.cookieservice.get("type")) == "contract-manager") {
      console.log(this.modifyheaderarray);
      this.modifyheaderarray.userfullname = "Tech Name";
      source = {
        //basecondition: { user_id:this.userservice.userid },
        basecondition: {
          products: JSON.parse(this.cookieservice.get("products")),
          type: this.userservice.type,
        },
        updateendpoint: "api/bannerstatusupdate",
        hideeditbutton: true, // (hide edit button)
        hidedeletebutton: true, // (hide delete button)
        hideviewbutton: true, // (hide view button)
        hidestatustogglebutton: true, // (hide status toggle button)
        hideaction: false,
        updateendpointmany: "api/bannersupdate",
        deleteendpointmany: "api/deletecontractrequests",
        hidemultipleselectbutton: true,
        hidedeletemany: true,
        hideupdatemany: true, // (hide action column)
        detailview_override: [
          { key: "createdon_datetime", val: "Created on" },
          { key: "title", val: "Title" },
          { key: "description", val: "Description" },
          { key: "priority", val: "Priority" },
          { key: "category_title", val: "Category Title" },
          { key: "productname", val: "Product Name" },
          { key: "currentstatus", val: "Status" },
        ],
        custombuttons: [
          {
            label: "Contract Status",
            type: "listner",
            id: "i1",
            classname: "contractstatus",
            tooltip: "Contract Status",
          },
          {
            label: "View",
            type: "internallink",
            route: "contract-manager/contract/edit/",
            id: "i1",
            cond: "currentstatus",
            condval: "Pending Approval",
            tooltip: "Edit Contract",
            classname: "editlistencls",
            param: ["_id", "leadid"],
          },
          {
            label: "View",
            type: "internallink",
            route: "contract-manager/contract/edit/",
            id: "i1",
            cond: "currentstatus",
            condval: "Drafting as Contract Manager",
            tooltip: "Edit Contract",
            classname: "editlistencls",
            param: ["_id", "leadid"],
          },

          {
            label: "Edit",
            type: "internallink",
            route: "contract-manager/contract/edit/",
            id: "i1",
            cond: "currentstatus",
            condval: "Contract modification approval asked by Rep",
            tooltip: "Edit Contract",
            classname: "editlistencls",
            param: ["_id", "leadid"],
          },
        ],
        tableheaders: [
          "userfullname",
          "leadfullname",
          "productname",
          "currentstatus",
          "createdon_datetime",
        ],
      };
    }
    if (
      JSON.parse(this.cookieservice.get("type")) ==
        "technological-consultant" ||
      JSON.parse(this.cookieservice.get("type")) == "sales-person"
    ) {
      console.log(this.searchsettings);
      this.searchsettings.search.splice(1, 1);
      this.searchsettings.search[0].serversearchdata.endpoint =
        "api/exitsinglistnameforlead?id=" +
        JSON.parse(this.cookieservice.get("userid"));
      source = {
        basecondition: {
          user_id: this.userservice.userid,
          type: this.userservice.type,
        },
        //basecondition: { products:JSON.parse(this.cookieservice.get("products"))},
        updateendpoint: "api/bannerstatusupdate",
        hideeditbutton: true, // (hide edit button)
        hidedeletebutton: true, // (hide delete button)
        hideviewbutton: true, // (hide view button)
        hidestatustogglebutton: true, // (hide status toggle button)
        hideaction: false,
        updateendpointmany: "api/bannersupdate",
        deleteendpointmany: "api/deletecontractrequests",
        hidemultipleselectbutton: true,
        hidedeletemany: true,
        hideupdatemany: true, // (hide action column)
        detailview_override: [
          { key: "createdon_datetime", val: "Created on" },
          { key: "title", val: "Title" },
          { key: "description", val: "Description" },
          { key: "priority", val: "Priority" },
          { key: "category_title", val: "Category Title" },
          { key: "productname", val: "Product Name" },
          { key: "currentstatus", val: "Status" },
        ],
        custombuttons: [
          {
            label: "Contract Status",
            type: "listner",
            id: "i1",
            classname: "contractstatus",
            tooltip: "Contract Status",
          },
          {
            label: "Edit Contract",
            type: "internallink",
            cond: "currentstatus",
            condval: "Lead Request For Contract",
            id: "li",
            name: "send_to_contract_manager",
            tooltip: "Send to contract manager",
            route: "contract-manager/contract/edit/",
            param: ["_id", "leadid"],
            //param: ['_id','leadid','userid'],
            classname: "editlistencls",
          },
          {
            label: "Edit Contract",
            type: "internallink",
            cond: "currentstatus",
            condval: "Drafting as Tech consultant",
            id: "li",
            name: "send_to_contract_manager",
            tooltip: "Drafting as Tech consultant",
            route: "contract-manager/contract/edit/",
            param: ["_id", "leadid"],
            //param: ['_id','leadid','userid'],
            classname: "editlistencls",
          },

          {
            label: "View",
            type: "internallink",
            route: "contract-manager/contract/edit/",
            id: "i1",
            cond: "currentstatus",
            condval: "Send To Lead",
            tooltip: "Edit Contract",
            classname: "editlistencls",
            param: ["_id", "leadid"],
          },
          {
            label: "View",
            type: "internallink",
            route: "contract-manager/contract/view/",
            id: "i1",
            cond: "currentstatus",
            condval: "Contract Signed",
            tooltip: "View Contract",
            classname: "ContarctPreview",
            param: ["_id", "leadid"],
          },
          {
            label: "View",
            type: "internallink",
            route: "contract-manager/contract/view/",
            id: "i1",
            cond: "currentstatus",
            condval: "Pending Approval",
            tooltip: "Edit Contract",
            classname: "ContarctPreview",
            param: ["_id", "leadid"],
          },
          {
            label: "Edit",
            type: "internallink",
            route: "contract-manager/contract/edit/",
            id: "i1",
            cond: "currentstatus",
            condval: "Contract Approved",
            tooltip: "Edit Contract",
            classname: "editlistencls",
            param: ["_id", "leadid"],
          },
        ],

        tableheaders: [
          "leadfullname",
          "productname",
          "contractfullname",
          "currentstatus",
          "createdon_datetime",
        ],
      };
    }
    if (JSON.parse(this.cookieservice.get("type")) == "distributor") {
      this.modifyheaderarray.userfullname = "Tech Name";

      source = {
        basecondition: {
          user_id: this.userservice.userid,
          type: this.userservice.type,
        },
        //basecondition: { products:JSON.parse(this.cookieservice.get("products"))},
        updateendpoint: "api/bannerstatusupdate",
        hideeditbutton: true, // (hide edit button)
        hidedeletebutton: true, // (hide delete button)
        hideviewbutton: false, // (hide view button)
        hidestatustogglebutton: true, // (hide status toggle button)
        hideaction: false,
        updateendpointmany: "api/bannersupdate",
        deleteendpointmany: "api/deletecontractrequests",
        hidemultipleselectbutton: true,
        hidedeletemany: true,
        hideupdatemany: true, // (hide action column)
        detailview_override: [
          { key: "createdon_datetime", val: "Created on" },
          { key: "title", val: "Title" },
          { key: "description", val: "Description" },
          { key: "priority", val: "Priority" },
          { key: "category_title", val: "Category Title" },
          { key: "productname", val: "Product Name" },
          { key: "currentstatus", val: "Status" },
          { key: "userfullname", val: "Tech Name" },
          { key: "leadfullname", val: "Lead Name" },
          { key: "contractfullname", val: "Contract Manager Name" },
        ],
        custombuttons: [
          {
            label: "View Contract",
            type: "internallink",
            cond: "status",
            condval: "Lead Request For Contract",
            id: "li",
            name: "send_to_contract_manager",
            tooltip: "Send to contract manager",
            route: "contract-manager/contract/view/",
            param: ["_id", "leadid"],
            //param: ['_id','leadid','userid'],
            // classname: 'ContarctPreview'
          },
          {
            label: "Contract Status",
            type: "listner",
            id: "i1",
            classname: "contractstatus",
            tooltip: "Contract Status",
          },
          {
            label: "View Contract",
            type: "internallink",
            cond: "status",
            condval: "Lead Request For Contract Modify",
            id: "li",
            name: "send_to_contract_manager",
            tooltip: "Send to contract manager",
            route: "contract-manager/contract/view/",
            param: ["_id", "leadid"],
            // param: ['_id','leadid','userid'],
            // classname: 'ContarctPreview'
          },
          {
            label: "Edit",
            type: "internallink",
            route: "contract-manager/contract/view/",
            id: "i1",
            cond: "status",
            condval: "Contract Approved ",
            tooltip: "Edit Contract",
            classname: "editlistencls",
            param: ["_id", "leadid"],
          },
        ],

        tableheaders: [
          "userfullname",
          "leadfullname",
          "productname",
          "contractfullname",
          "currentstatus",
          "createdon_datetime",
        ],
      };
    }
    if (JSON.parse(this.cookieservice.get("type")) == "admin") {
      this.modifyheaderarray.userfullname = "Tech Name";
      this.searchsettings.search[2] = {
        label: "Search By Distributor Name",
        field: "userparentid_s",
        values: "",
        serversearchdata: { endpoint: "api/autocomplete_distributor_name" },
      },
      source = {
        basecondition: {},
        //basecondition: { products:JSON.parse(this.cookieservice.get("products"))},
        updateendpoint: "api/bannerstatusupdate",
        hideeditbutton: true, // (hide edit button)
        hidedeletebutton: true, // (hide delete button)
        hideviewbutton: true, // (hide view button)
        hidestatustogglebutton: true, // (hide status toggle button)
        hideaction: false,
        updateendpointmany: "api/bannersupdate",
        deleteendpointmany: "api/deletecontractrequests",
        hidemultipleselectbutton: true,
        hidedeletemany: true,
        hideupdatemany: true, // (hide action column)
        detailview_override: [
          { key: "createdon_datetime", val: "Created on" },
          { key: "title", val: "Title" },
          { key: "description", val: "Description" },
          { key: "priority", val: "Priority" },
          { key: "category_title", val: "Category Title" },
          { key: "productname", val: "Product Name" },
          { key: "currentstatus", val: "Status" },
          { key: "userfullname", val: "Tech Name" },
          { key: "leadfullname", val: "Lead Name" },
          { key: "contractfullname", val: "Contract Manager Name" },
        ],
        custombuttons: [
          {
            label: "View Contract",
            type: "internallink",
            cond: "status",
            condval: "Lead Request For Contract",
            id: "li",
            name: "send_to_contract_manager",
            tooltip: "Send to contract manager",
            route: "contract-manager/contract/view/",
            param: ["_id", "leadid"],
            //param: ['_id','leadid','userid'],
            // classname: 'ContarctPreview'
          },
          {
            label: "Contract Status",
            type: "listner",
            id: "i1",
            classname: "contractstatus",
            tooltip: "Contract Status",
          },
       
          {
            label: "Edit",
            type: "internallink",
            route: "contract-manager/contract/view/",
            id: "i1",
            cond: "currentstatus",
            condval: "Awating response from system",
            tooltip: "Edit Contract",
            classname: "ContarctPreview",
            param: ["_id", "leadid"],
          },
        ],

        tableheaders: [
          "userfullname",
          "leadfullname",
          "productname",
          "contractfullname",
          "userparentfullname",
          "currentstatus",
          "createdon_datetime",
        ],
      };
    }
    Object.assign(target, source);
    return target;
  }

  public nowDate: any = new Date();
  public allnotesdata: any;
  public notesdata: any;
  public alldata: any;
  public topcontract: any;
  public tarmsspecification: any;
  public buttomcontract: any;
  public disabledcheck: any;
  public types: any;

  getContractRequestList() {
    console.log("getContractRequestList=====>");
    this.activatedRoute.data.forEach((res: any) => {
      console.log("res", res.resolvedata.results);
      this.productListdatatable = res.resolvedata.results.res; // resolvedata
      // console.log(' testing', this.productListdatatable);
    });
    this.libdata = this.setLibData();
  }

  onLiblistingButtonChange(val) {
    // console.log(val.custombuttonclick.data.status);
    if (
      val.action == "custombuttonclick" &&
      val.custombuttonclick.btninfo.label == "Contract Status"
    ) {
      const dialogRef = this.dialog.open(contractstatus, {
        panelClass: "ContractLife_modal",
        // height: '500px',
        // width: '500px',
        data: { val: val.custombuttonclick.data.status },
      });
      dialogRef.afterClosed().subscribe((result: any) => {
        console.log(result, "tttttttttttttttt");
      });
    }
    // if (val.action == "search") {
    //   let data = {
    //     "secret": this.userservice.secret,
    //     "token": this.userservice.jwttoken,
    //     "source": "",
    //     "condition": {
    //       "limit": 10,
    //       "skip": 0
    //     },
    //     "sort": {
    //       "type": "desc",
    //       "field": "createdon_datetime"
    //     },
    //     "searchcondition": {
    //       "user_id": this.userservice.userid,
    //       "type": this.userservice.type
    //     }
    //   }
    //   this.Apiservice
    //   .getDataWithoutToken(data, "api/contractrequestslistviews")
    //   .subscribe((res: any) => {
    //     this.productListdatatable=null;
    //     setTimeout(() => {
    //       this.productListdatatable = res.results.res;

    //     }, 50);
    //   })
    // }
    //   console.log("IIIIII======>",e.custombuttonclick.data)
    //   let status ='Pending Approval'
    //   let flag = 'view'
    //   let dataResult = e.custombuttonclick.data
    //   let allnotesdata = {
    //     updatedatetime: Math.round(new Date().getTime()),
    //     notes: dataResult.notes,
    //     username: dataResult.userfullname,
    //     userId: dataResult.userid
    //   };

    //  let data = {
    //     date: this.nowDate,
    //     data: {
    //       leadid: dataResult.leadid,
    //       userid: dataResult.userid,
    //       notes: allnotesdata,
    //       products: dataResult.products,
    //       status: {
    //         "satatus": status,
    //         "updated_datetime": Math.round(new Date().getTime())
    //       },
    //       topcontract: dataResult.topcontract,
    //       tarmsspecification: dataResult.tarmsspecification,
    //       buttomcontract: dataResult.buttomcontract,
    //       id: dataResult._id
    //     },
    //     secret: this.userservice.secret,
    //     token: this.userservice.jwttoken
    //   }

    //   console.log("Data =======>",data)

    //   this.Apiservice
    //   .getDataWithoutToken(data, 'api/addcontractrequests')
    //   .subscribe((res: any) => {
    //     this.getContractRequestList()
    //     // console.log(res, 'asdfghjklzxcvbn')
    //     // this.snackBar.open('Contract Send Successfully to contract manager', 'Ok', {
    //     //   duration: 3000,
    //     // });
    //   })
  }

  ngOnInit() {
    let productendpointdata = {
      token: this.userservice.jwttoken,
      secret: this.userservice.secret,
    };
    this.Apiservice.getDataWithoutToken(
      productendpointdata,
      "api/productsearch"
    ).subscribe((data: any) => {
      // console.log(data,"+++++++++++++++++");
      for (let i = 0; i < data.res.length; i++) {
        let array = {
          val: data.res[i].val,
          name: data.res[i].name,
        };
        this.product_array.push(array);
      }
      // this.product_array = data.res;
      console.log(this.product_array, "+++++++++++++++++++++++++++");
    });
    // if (this.userservice.type == 'distributor') {
    //   this.libdata.hideeditbutton = true;                          // (hide edit button)
    //   this.libdata.hidedeletebutton = true;                      // (hide delete button)
    //   // this.libdata.hideviewbutton = true;                          // (hide view button)
    //   this.libdata.hidestatustogglebutton = true;
    // }
    this.getContractRequestList();
    // this.activatedRoute.data.forEach((res: any) => {
    //   console.log('res', res.resolvedata.results);
    //   this.productListdatatable = res.resolvedata.results.res;           // resolvedata
    //   // console.log(' testing', this.productListdatatable);

    // });
    // this.libdata = this.setLibData()
    //console.log("libdata 1111=====> ",this.libdata)
    // console.log(JSON.parse(this.cookieservice.get('type')))

    ///////====Previous===== //////
    // if (JSON.parse(this.cookieservice.get('type')) == 'technological-consultant' || JSON.parse(this.cookieservice.get('type')) == 'sales-person') {
    //   this.libdata.custombuttons.push(
    //     {
    //       label: "Edit",
    //       type: 'internallink', route: "contract-manager/contract/view/",
    //       id: 'i1',
    //       cond: 'status',
    //       condval: 'Contract Approved ',
    //       tooltip: 'Edit Contract',
    //       classname: 'editlistencls',
    //       param: ['_id','leadid'],
    //     },
    //   )
    // } if (JSON.parse(this.cookieservice.get('type')) == 'contract-manager') {
    //   this.libdata.custombuttons.push(
    //     {
    //       label: "View",
    //       type: 'internallink', route: "contract-manager/contract/view/",
    //       id: 'i1',
    //       cond: 'status',
    //       condval: 'Pending Approval',
    //       tooltip: 'Edit Contract',
    //       classname: 'listencls',
    //       param: ['_id','leadid'],
    //     },
    //     {
    //       label: "Edit",
    //       type: 'internallink', route: "contract-manager/contract/view/",
    //       id: 'i1',
    //       cond: 'status',
    //       condval: 'Contract modification approval asked by Rep',
    //       tooltip: 'Edit Contract',
    //       classname: 'listencls',
    //       param: ['_id','leadid'],
    //     },
    //   )

    // }
  }
}

@Component({
  selector: "adduser",
  templateUrl: "contractstatus.html",
})
export class contractstatus {
  public cstatusdata: any;
  constructor(
    public dialogRef: MatDialogRef<contractstatus>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    public apiservice: ApiService,
    public activatedroute: ActivatedRoute,
    public userservice: UserserviceService
  ) {
    console.log(data, "+++++");
    this.cstatusdata = data.val;
  }

  onNoClick(): void {
    this.dialogRef.close();
  }
}
