import { Injectable } from '@angular/core';
import { Observable, Subject, Observer } from 'rxjs';
import { ApiService } from './api.service';
import { environment } from "src/environments/environment";

@Injectable({
  providedIn: 'root'
})
export class NavAccessService {
  private subject = new Subject<any>();
  constructor(
    public apiservice: ApiService
  ) {
  }
  setData(val: any) {
    this.apiservice
      .customRequest({'user_id':val},'getaccesableproductstech', environment['api_url_py'])
      .subscribe((res: any) => {
        // console.log(res);
        this.subject.next(res);
      });
    
  }
  getData(): Observable<any> {
    return this.subject.asObservable();
  }


}
