import { Component, Inject, Input, OnInit } from '@angular/core';
import { environment } from '../../../../../environments/environment';
import { CookieService } from 'ngx-cookie-service';
import { ApiService } from '../../../../services/api.service';
// import {DialogMarkAsDoneAlert} from '../past-appointment/past-appointment.component';
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from '@angular/material/dialog';
import { UserserviceService } from 'src/app/services/userservice.service';
import { DialogMarkAsDoneAlert } from '../past-appointment/past-appointment.component';
import { ActivatedRoute } from '@angular/router';
import { bottomsheetstatus } from 'src/app/components/lead/lead-list/lead-list.component';
import { MatBottomSheet } from '@angular/material';
// import { DialogData5 } from 'src/app/components/admin/admindashboard/admindashboard.component';

export interface DialogData {
  data: any;
}

@Component({
  selector: 'app-upcoming-appointment',
  templateUrl: './upcoming-appointment.component.html',
  styleUrls: ['./upcoming-appointment.component.css'],
})
export class UpcomingAppointmentComponent implements OnInit {
  public bashcondvalue: any = '';
  @Input()
  set beshcond(beshcondval: any) {
    this.bashcondvalue = beshcondval;
  }

  private tableHeaderFields: any = [];
  public updatetable: boolean = false;
  public discoverycallflg: boolean = false;
  public progressSpinner: any = {
    mode: 'indeterminate',
    loading: false,
    bookingStatus: 'Sending request',
  };

  public configData: any = {
    jwtToken: this.cookieService.get('jwt_for_calendar'),
    baseUrl: environment['api_calender_url'],
    endPoint: {
      // listBookedEvents: 'list-booked-events',
      // listBookedEventsCount: 'list-booked-events-count',
      listUpcomingBookedEvents: 'list-upcoming-booked-events',
      listUpcomingBookedEventsCount: 'list-upcoming-booked-events-count',
      deleteBookedEvent: 'delete-booked-event',
      rescheduleBookedEvent: 'reschedule',
    },
    responseData: [],
    primaryCondition: {
      userid: { $in: JSON.parse(this.cookieService.get('userid')) },
    },

    // lib-listing inputs
    skipFields: [],
    modify_header_array: {
      event_title: 'Event Title',
      event_type: 'Event Type',
      product_name: 'Product Name',
      username: ' Organiser Name',
      attende_name: 'Attendees Name',
      attende_email: 'Attendees Email',
      attende_type: 'Attendees Type',
      training_category_name: 'Training Category Name',
      mentee_name: 'Mentee Name',
      mentee_email: 'Mentee Email',
      lesson_title: 'Lesson Title',
      appointment_timing: 'Appointment Timing',
      event_start_datetime_unix: 'Appointment Date',
      timezoneName: 'Timezone',
      status: 'Status',
      is_google_event: 'Calendar Event',
    },
    source: 'google_events',
    date_search_source_count: 0,
    libdata: {
      basecondition: {},
      detailview_override: [
        { key: 'event_title', val: 'Event Title' },
        { key: 'event_type', val: 'Event Type' },
        { key: 'description', val: 'Description' },
        { key: 'username', val: 'Organiser Name' },
        { key: 'useremail', val: 'Organiser Email' },
        { key: 'notes', val: 'Notes' },
        { key: 'booking_date', val: 'Booked On' },
        { key: 'event_start_datetime_unix', val: 'Appointment Date' },
        // { key: 'slot', val: 'Slot' },
        { key: 'timespanmins', val: 'Time span' },
        // { key: 'timezone', val: 'Time zone' },
        { key: 'product_name', val: 'Product Name' },
        { key: 'attende_name', val: 'Attendee Name' },
        { key: 'attende_email', val: 'Attendee Email' },
        // { key: 'attende_type', val: 'Attendee Type' },
        { key: 'appoinment_date_timezone', val: 'Appoinment Date Timezone' },
        { key: 'Appointmentedstatus', val: 'Appointment Status' },
      ],
      updateendpoint: 'statusupdate',
      hidestatustogglebutton: true,
      hidedeletebutton: true,
      hideeditbutton: true,
      hidedeletemany: true,
      hideupdatemany: true,
      hidemultipleselectbutton: true,

      tableheaders: this.tableHeaderFields,
      custombuttons: [],
    },
    updatetable: false,
    limitcond: {
      limit: 10,
      skip: 0,
      pagecount: 1,
    },
    sortdata: {
      type: 'desc',
      field: 'booking_date',
      options: ['booking_date', 'startdate', 'event_title', 'event_type', 'timezoneName', 'attende_name'],
    },
    custom_link: [],
    search_settings: {
      // this is use for  date search
      datesearch: [
        {
          startdatelabel: 'Appointments After',
          enddatelabel: 'Appointments Before',
          submit: 'Search',
          field: 'event_start_datetime_unix',
        },
        // {
        //   startdatelabel: 'Booking After', enddatelabel: 'Booking Before',
        //   submit: 'Search', field: 'booking_date'
        // }
      ],

      // this is use for  select search
      selectsearch: [
        {
          label: 'Search By Status',
          field: 'status',
          values: [
            { val: 0, name: 'Pending' },
            { val: 1, name: 'Done' },
            { val: 2, name: 'Canceled' },
          ],
        },
      ],

      // this is use for text search
      textsearch: [],

      // this is use for Autocomplete search
      search: [],
    },
    statusarray: [
      { val: 0, name: 'Pending' },
      { val: 1, name: 'Done' },
      { val: 2, name: 'Canceled' },
    ],
    detail_skip_array: [
      '_id',
      'slotTimeActual',
      'notes',
      'eid',
      'event_type_val',
      'googleevent',
      'event_title_s',
      'eventid',
      'is_google_event',
      'lesson_id',
      'id',
      'attende_email_s',
      'attende_name_s',
      'status',
      'user_id',
      'username_s',
      'product_name',
      'product_name_s',
      'product_id',
      'slot_end_time',
      'training_id',
      'parent_id',
      'flag_for_show',
      'bookinguser',
      'event_type_val_s',
      'appoinment_date_timezone',
      'attende_phone',
      'event_type_s',
      'appointment_date',
      'flag_for_followup',
      'remove_butt_flag',
      'endDateTime',
      'start_time',
      'timespan',
      'ss',
      'slot',
      'timezone',
      'attende_type',
      'timezoneName',
      'startdate',
      'atten_email'
    ],
  };

  constructor(
    public cookieService: CookieService,
    private apiService: ApiService,
    public dialog: MatDialog,
    public userService: UserserviceService,
    public activatedRoute: ActivatedRoute,
    public bottomSheet: MatBottomSheet,
  ) {
    if (this.bashcondvalue != null && this.bashcondvalue != '') {
    }
  }

  ngOnInit() {

    console.log(this.activatedRoute.snapshot.routeConfig.path, 'this.activatedRoute.snapshot.routeConfig.path');

    if (
      JSON.parse(this.cookieService.get('type')) ==
      'technological-consultant' &&
      this.activatedRoute.snapshot.routeConfig.path ==
      'manage-appointment/lead-appointments'
    ) {
      this.configData.libdata.basecondition = {
        parent_id: JSON.parse(this.cookieService.get('userid')),
      };
      this.configData.libdata.tableheaders = [
        'event_title',
        'event_type',
        'product_name',
        'username',
        'attende_name',
        'attende_email',
        // 'attende_type',
        'product_name',
        'event_start_datetime_unix',
        // 'timezoneName',
        'status',
      ];
      // this.configData.libdata.tableheaders = this.tableHeaderFields;
      // this.configData.libdata.custombuttons.push(
      //   {
      //     label: 'Disposition',
      //     type: 'listner',
      //     // cond: 'status',
      //     // condval: 0,
      //     classname: 'disposition_warranty',
      //     tooltip: 'Disposition for Discovery call ',
      //     // refreshdata: true,
      //   },
        //   {
        //     label: 'Reschedule',
        //     route:
        //       '/calendar-management/manage-appointment/reschedule-appointments',
        //     type: 'internallink',
        //     cond: 'status',
        //     condval: 0,
        //     param: ['_id', 'event_type_val'],
        //     classname: 'rescheduleBTN',
        //     tooltip: 'Reschedule',
        //   }
        // );
        // this.configData.search_settings.textsearch.push(
        //   { label: 'Search By  Product  Name', field: 'product_name_s' },
        //   { label: 'Search By  Attendees Name', field: 'attende_name_s' },
        // { label: 'Search By  Organiser Name', field: 'username_s' }
      // );
    }


    if (
      JSON.parse(this.cookieService.get('type')) ==
      'distributor' &&
      this.activatedRoute.snapshot.routeConfig.path ==
      'manage-appointment/closer-appointments'
    ) {
      this.configData.libdata.basecondition = {
        parentid: JSON.parse(this.cookieService.get('userid')),
      };
      this.tableHeaderFields = [
        'event_title',
        'event_type',
        'product_name',
        'username',
        'attende_name',
        'attende_email',
        // 'attende_type',
        'product_name',
        'event_start_datetime_unix',
        // 'timezoneName',
        'status',
      ];
      this.configData.libdata.tableheaders = this.tableHeaderFields;
      this.configData.libdata.custombuttons.push(
        // {
        //   label: 'Cancel', type: 'action', datatype: 'api',
        //   endpoint: 'delete-booked-event', otherparam: [],
        //   cond: 'status', condval: 0,
        //   param: '_id', refreshdata: true,
        // },
        {
          label: 'Reschedule',
          route:
            '/calendar-management/manage-appointment/reschedule-appointments',
          type: 'internallink',
          cond: 'status',
          condval: 0,
          param: ['_id', 'event_type_val'],
          classname: 'rescheduleBTN',
          tooltip: 'Reschedule',
        }
      );
      this.configData.search_settings.textsearch.push(
        { label: 'Search By  Product  Name', field: 'product_name_s' },
        { label: 'Search By  Attendees Name', field: 'attende_name_s' },
        { label: 'Search By  Organiser Name', field: 'username_s' }
      );
    }
    if (
      JSON.parse(this.cookieService.get('type')) === 'admin' ||
      JSON.parse(this.cookieService.get('type')) === 'closer' || JSON.parse(this.cookieService.get('type')) === 'technological-consultant'
    ) {
      this.tableHeaderFields = [
        'event_title',
        'event_type',
        'username',
        'attende_name',
        'attende_email',
        // 'attende_type',
        'product_name',
        'event_start_datetime_unix',
        // 'timezoneName',
        'status',
        // 'Appointmentedstatus'
      ];
      this.configData.libdata.tableheaders = this.tableHeaderFields;

      // this.configData.libdata.basecondition = { id: JSON.parse(this.cookieService.get('userid')) };

      // this.configData.libdata.custombuttons.push(
      //   {
      //     label: 'Mark as Done',
      //     type: 'listner',
      //     id: 'btn_mark_as_done',
      //     cond: 'status', condval: 0,
      //     refreshdata: true,
      //     classname: 'markasdone'
      //   }
      // );
      this.configData.libdata.custombuttons.push(
        // {
        //   label: 'Cancel', type: 'action', datatype: 'api',
        //   endpoint: 'delete-booked-event', otherparam: [],
        //   cond: 'status', condval: 0,
        //   param: '_id', refreshdata: true,
        // },
        {
          label: 'Cancel',
          type: 'listner',
          id: 'btn_cancel',
          cond: 'status',
          condval: 0,
          // refreshdata: true,
          classname: 'cancelBTN',
          tooltip: 'Cancel',
        },
        {
          label: 'Reschedule',
          route:
            '/calendar-management/manage-appointment/reschedule-appointments',
          type: 'internallink',
          cond: 'status',
          condval: 0,
          param: ['_id', 'event_type_val'],
          classname: 'rescheduleBTN',
          tooltip: 'Reschedule',
        }
      );
      this.configData.search_settings.textsearch.push(
        { label: 'Search By  Attendees Name', field: 'attende_name_s' },
        { label: 'Search By  Organiser Name', field: 'username_s' },
        { label: 'Search By  Event Title', field: 'event_title_s' }
      );

      // // console.log(this.configData.libdata.basecondition)
    }
    // if (JSON.parse(this.cookieService.get('type')) != 'admin') {
    //   this.configData.libdata.basecondition = { user_id: JSON.parse(this.cookieService.get('userid')) };

    // }
    if (JSON.parse(this.cookieService.get('type')) == 'closer') {
      this.configData.libdata.basecondition = {
        user_id: JSON.parse(this.cookieService.get('userid')),
      };
    }

    if (
      JSON.parse(this.cookieService.get('type')) ==
      'technological-consultant' &&
      this.activatedRoute.snapshot.routeConfig.path ==
      'manage-appointment/warranty-appointments'
    ) {
      this.configData.libdata.basecondition = {
        bookinguser: JSON.parse(this.cookieService.get('userid')),
        event_type_val: 5
      };
      this.tableHeaderFields = [
        'event_title',
        'event_type',
        'username',
        'attende_name',
        'attende_email',
        // 'attende_type',
        'event_start_datetime_unix',
        // 'timezoneName',
        'status',
      ];
      this.configData.libdata.tableheaders = this.tableHeaderFields;
    }

    // if (JSON.parse(this.cookieService.get('type')) == 'technological-consultant') {
    //   this.configData.libdata.basecondition = {
    //     user_id: JSON.parse(this.cookieService.get('userid')),
    //   };
    // }
    // set jwtToken
    // this.configData.jwtToken = this.cookieService.get('jwt_for_calendar');
    // if (
    //   (
    //     JSON.parse(this.cookieService.get('type')) ===
    //     'technological-consultant') &&
    //   this.activatedRoute.snapshot.routeConfig.path ==
    //   'manage-appointment/genarelcall-appointments'
    // ) {
    //   this.tableHeaderFields = [
    //     'event_title',
    //     'start_datetime_unix',
    //     'timezone',
    //     'booking_date',
    //     // 'status',
    //     'Appointmentedstatus'
    //   ];
    //   this.configData.libdata.tableheaders = this.tableHeaderFields;
    //   this.configData.libdata.basecondition = {
    //     user_id: JSON.parse(this.cookieService.get('userid')),
    //   };
    //   // // console.log(this.configData.libdata.basecondition)

    //   this.configData.libdata.custombuttons.push(
    //     // {
    //     //   label: 'Cancel', type: 'action', datatype: 'api',
    //     //   endpoint: 'delete-booked-event', otherparam: [],
    //     //   cond: 'status', condval: 0,
    //     //   param: '_id', refreshdata: true,
    //     // },
    //     // {
    //     //   label: 'Cancel',
    //     //   type: 'listner',
    //     //   id: 'btn_cancel',
    //     //   cond: 'status', condval: 0,
    //     //   // refreshdata: true,
    //     //   classname: 'cancelBTN',
    //     //   tooltip: 'Cancel',
    //     // },
    //     {
    //       label: 'Reschedule',
    //       route:
    //         '/calendar-management/manage-appointment/reschedule-appointments',
    //       type: 'internallink',
    //       cond: 'status',
    //       condval: 0,
    //       param: ['_id', 'event_type_val'],
    //       classname: 'rescheduleBTN',
    //       tooltip: 'Reschedule',
    //     }
    //   );
    // }
    // set table headers fields for different users

    if (
      (JSON.parse(this.cookieService.get('type')) == 'sales-person') &&
      this.activatedRoute.snapshot.routeConfig.path !=
      'manage-appointment/lead-appointments'
    ) {
      this.tableHeaderFields = [
        'event_title',
        'event_start_datetime_unix',
        // 'timezoneName',
        'booking_date',
        'status',

      ];
      this.configData.libdata.tableheaders = this.tableHeaderFields;

      this.configData.libdata.basecondition = {
        id: JSON.parse(this.cookieService.get('userid')),
      };
      // // console.log(this.configData.libdata.basecondition)

    }
    if (
      (JSON.parse(this.cookieService.get('type')) ===
        'distributor') &&
      this.activatedRoute.snapshot.routeConfig.path !=
      'manage-appointment/closer-appointments'
    ) {
      this.tableHeaderFields = [
        'event_title',
        'event_start_datetime_unix',
        'timezoneName',
        'booking_date',
        // 'status',
        'Appointmentedstatus'
      ];
      this.configData.libdata.tableheaders = this.tableHeaderFields;

      this.configData.libdata.basecondition = {
        id: JSON.parse(this.cookieService.get('userid')),
      };
      // // console.log(this.configData.libdata.basecondition)

      this.configData.libdata.custombuttons.push(
        // {
        //   label: 'Cancel', type: 'action', datatype: 'api',
        //   endpoint: 'delete-booked-event', otherparam: [],
        //   cond: 'status', condval: 0,
        //   param: '_id', refreshdata: true,
        // },
        // {
        //   label: 'Cancel',
        //   type: 'listner',
        //   id: 'btn_cancel',
        //   cond: 'status', condval: 0,
        //   // refreshdata: true,
        //   classname: 'cancelBTN',
        //   tooltip: 'Cancel',
        // },
        {
          label: 'Reschedule',
          route:
            '/calendar-management/manage-appointment/reschedule-appointments',
          type: 'internallink',
          cond: 'status',
          condval: 0,
          param: ['_id', 'event_type_val'],
          classname: 'rescheduleBTN',
          tooltip: 'Reschedule',
        }
      );
    }

    this.loadUpcomingAppointments();
  }

  loadUpcomingAppointments(): void {
    this.configData.responseData = null;
    this.progressSpinner.loading = true;
    const requestData: any = {
      token: this.cookieService.get('jwt_for_calendar'),
      condition: {
        limit: 10,
        skip: 0,
      },
      sarchconditions: {},
      sort: {
        type: 'desc',
        field: 'booking_date',
      },
    };

    if (
      JSON.parse(this.cookieService.get('type')) ==
      'technological-consultant' &&
      this.activatedRoute.snapshot.routeConfig.path ==
      'manage-appointment/lead-appointments'
    ) {
      requestData.condition.parent_id = JSON.parse(
        this.cookieService.get('userid')
      );
    }

    if (
      JSON.parse(this.cookieService.get('type')) ==
      'technological-consultant' &&
      this.activatedRoute.snapshot.routeConfig.path ==
      'manage-appointment/warranty-appointments'
    ) {
      requestData.sarchconditions.bookinguser = JSON.parse(this.cookieService.get('userid'))
      requestData.sarchconditions.event_type_val = 5

    }
    if (
      JSON.parse(this.cookieService.get('type')) ==
      'technological-consultant' &&
      this.activatedRoute.snapshot.routeConfig.path ==
      'manage-appointment/warranty-appointments' && this.cookieService.check('userid')
    ) {
      requestData.condition.bookinguser = JSON.parse(this.cookieService.get('userid'));
      requestData.condition.event_type_val = 5

    }
    // if (
    //   (
    //     JSON.parse(this.cookieService.get('type')) ===
    //     'technological-consultant') &&
    //   this.activatedRoute.snapshot.routeConfig.path ==
    //   'manage-appointment/genarelcall-appointments'
    // ) {
    //   requestData.condition.id = JSON.parse(
    //     this.cookieService.get('userid')
    //   );
    // }

    if (JSON.parse(this.cookieService.get('type')) == 'distributor') {
      this.activatedRoute.snapshot.routeConfig.path == 'manage-appointment/closer-appointments'
      requestData.condition.parentid = JSON.parse(
        this.cookieService.get('userid'))

    }
    if (
      (JSON.parse(this.cookieService.get('type')) === 'sales-person') &&
      this.activatedRoute.snapshot.routeConfig.path !=
      'manage-appointment/lead-appointments'
    ) {
      requestData.condition.id = JSON.parse(this.cookieService.get('userid'));
    }

    if (
      (JSON.parse(this.cookieService.get('type')) === 'sales-person') &&
      this.activatedRoute.snapshot.routeConfig.path !=
      'calendar-management/manage-appointment/my-appointments'
    ) {
      requestData.condition.id = JSON.parse(this.cookieService.get('userid'));
      this.configData.search_settings.textsearch.push(
        { label: 'Search By  Event Type', field: 'event_type' },
        { label: 'Search By  Attendees Email', field: 'attende_email' }
      );
    }
    if (
      JSON.parse(this.cookieService.get('type')) ==
      'technological-consultant' &&
      this.activatedRoute.snapshot.routeConfig.path ==
      'manage-appointment/my-appointments' && this.cookieService.check('userid')
    ) {
      requestData.condition.bookinguser = JSON.parse(this.cookieService.get('userid'));
      this.configData.libdata.basecondition.bookinguser = JSON.parse(this.cookieService.get('userid'));
    }
    if (JSON.parse(this.cookieService.get('type')) === 'closer') {
      requestData.condition.user_id = JSON.parse(
        this.cookieService.get('userid')
      );
    }

    this.apiService
      .customRequest(
        requestData,
        this.configData.endPoint.listUpcomingBookedEvents,
        this.configData.baseUrl
      )
      .subscribe((response: any) => {
        // Set dataset in responseData
        if (response.status === 'success') {
          console.log(response, 'testing');
          this.progressSpinner.loading = false;

          this.configData.responseData = response.results.res;
          console.log(
            'this.configData.responseData',
            this.configData.responseData
          );
          // Create skipFields array(first save all the keys from the dataset)
          if (response.results.res > 0) {
            this.configData.skipFields = Object.keys(response.results.res[0]);
          }
          // console.log(this.configData.responseData[0].start_datetime_unix, 'this.configData.responseData')


          // let time = Math.round((new Date()).getTime()) + 172800000


          // // Math.round((new Date()).getTime())
          // console.log(time, '172800172800172800172800172800172800')
          // console.log(Math.round((new Date()).getTime()), '172800172800172800172800172800172800')

          // for (let i in this.configData.responseData) {
          //   console.log(this.configData.responseData, ' this.configData.responseData');
          //   // let cn
          //   let last_updateedtime = this.configData.responseData[i].start_datetime_unix;
          //   let current_time = time;
          //   // console.log(last_updateedtime,'qqqqqqqqqqqqqqqqqqqq',current_time);

          //   let interval = (current_time - last_updateedtime) / 3600000;
          //   interval = Math.round(interval);
          //   console.log(interval,'intervalintervalinterval')
          //   if (this.configData.responseData[i].status == 0 && interval<=48 && interval>0) {
          //     // console.log('+++++++++++++++++++++++++++++madhumita');
          //     this.configData.responseData[i].Appointmentedstatus = '<div class ='pendingappoiment'>Pending</div><div class ='blankdivconYellow'></div>'
          //     // console.log(this.configData.responseData[i].Appointmentedstatus, 'this.configData.responseData[i].Appointmentedstatus')

          //   }
          //   else{
          //     console.log('+++++++++++++++++++++++++++++madhumita');
          //     this.configData.responseData[i].Appointmentedstatus = '<div class ='pendinggggggggggg'>Pending</div>'
          //     // console.log(this.configData.responseData[i].Appointmentedstatus, 'this.configData.responseData[i].Appointmentedstatus')

          //   }
          // }

        }

        const requiredFields = this.tableHeaderFields;

        // Modify the skipFields array(splicing the keys which is in the requiredFields)
        for (let i = 0; i < requiredFields.length; i++) {
          this.configData.skipFields.splice(
            this.configData.skipFields.indexOf(requiredFields[i]),
            1
          );
        }
      });

    setTimeout(() => {
      this.apiService
        .customRequest(
          requestData,
          this.configData.endPoint.listUpcomingBookedEventsCount,
          this.configData.baseUrl
        )
        .subscribe((response: any) => {
          if (response.status === 'success') {
            this.configData.date_search_source_count = response.count;
          }
        });
    }, 500);
  }

  listenLiblistingChange(event: any) {
    console.log('eventtt', event);
    if (event.custombuttonclick != undefined) {
      if (event.custombuttonclick.btninfo.id === 'btn_cancel') {
        const dialogRef = this.dialog.open(DialogCancelAlert);

        dialogRef.afterClosed().subscribe((result) => {
          // console.log(`Dialog result: ${result}`);
          console.log(result, 'testingggggggggggggggggggggggggggggggggggg');
          if (result.flag == true) {
            // google_events
            let note = result.data
            let google_events_data: any = {
              userid: event.custombuttonclick.data.user_id,
              source: 'google_events',
              token: this.configData.jwtToken,
              note: note
            };
            this.apiService
              .customRequest(
                google_events_data,
                'get-refresh-token',
                environment['api_calender_url']
              )
              .subscribe((response: any) => {
                console.log('responseresponseresponse', response);
                let r_token = response.refresh_token;
                // console.log(r_token, 'r_token')
                const data: any = {
                  token: this.configData.jwtToken,
                  _id: event.custombuttonclick.data._id,
                  attende_email: event.custombuttonclick.data.attende_email,
                  attende_name: event.custombuttonclick.data.attende_name,
                  attende_type: event.custombuttonclick.data.attende_type,
                  useremail: event.custombuttonclick.data.useremail,
                  appointment_date: event.custombuttonclick.data.startdate,
                  username: event.custombuttonclick.data.username,
                  slot: event.custombuttonclick.data.slot,
                  event_type: event.custombuttonclick.data.event_type,
                  event_title: event.custombuttonclick.data.event_title,
                  // 'appointment_date': '01/27/2021',
                  refresh_token: r_token,
                  eid: event.custombuttonclick.data.eid,
                  slotTimeActual: event.custombuttonclick.data.slotTimeActual,
                  googleevent: event.custombuttonclick.data.googleevent,
                  note: note
                };
                // console.log(data, 'data')
                this.apiService
                  .customRequest(
                    data,
                    'google-calendar-event/delete-booked-event',
                    environment['google_calendar_api']
                  )
                  .subscribe((response: any) => {
                    // console.log('response', response);
                    setTimeout(() => {
                      this.updatetable = true;
                    }, 10);
                  });
                this.apiService.customRequest(data, 'delete-booked-event', environment['api_calender_url']).subscribe((response: any) => {
                  console.log(response, 'responsedelete')
                })
              });
          }
        });
      }
      if (event.custombuttonclick != undefined) {
        if (event.custombuttonclick.btninfo.id === 'btn_mark_as_done') {
          const dialogRef = this.dialog.open(DialogMarkAsDoneAlert);
          dialogRef.afterClosed().subscribe((result) => {
            const mark_data: any = {
              _id: event.custombuttonclick.data._id,
            };
            if (result === 'true') {
              this.apiService
                .customRequest(
                  mark_data,
                  'update-status-done',
                  environment['api_calender_url']
                )
                .subscribe((response: any) => {
                  console.log('response', response);
                  // this.configData.updatetable = !this.configData.updatetable;
                  setTimeout(() => {
                    this.updatetable = true;
                  }, 10);
                });
            }
          });
        }
      }

      // if (event.custombuttonclick.btninfo.id === 'btn_mark_as_done') {
      //   console.log(event.custombuttonclick.btninfo.id, 'mark')

      //   const mark_data: any = {
      //     _id: event.custombuttonclick.data._id
      //   }

      //   this.apiService.customRequest(mark_data, 'update-status-done', environment['api_calender_url']).subscribe((response: any) => {
      //     console.log(response, 'response')
      //     setTimeout(() => {
      //       this.updatetable =true;
      //       }, 10);
      //   })
      // }
      // if (event.custombuttonclick.btninfo.classname === 'disposition_warranty') {
      //   console.log(event.custombuttonclick.btninfo.classname, 'disposition_warranty')
      //   // this.discoverycallflg = true;
      //   const bottomSheetRef = this.bottomSheet.open(bottomsheetstatus, {

      //   });
      // }

    };

  }
}

@Component({
  selector: 'cancel-alert',
  templateUrl: 'cancel-alert.html',
})
export class DialogCancelAlert {
  public addreason: any;
  public addreasoncancel = {};
  public cancelflag: boolean = false;
  public seubmitreasonsflag: boolean = false;
  constructor(
    public dialogRef: MatDialogRef<DialogCancelAlert>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData
  ) { }
  cancelyes() {
    this.addreasoncancel = {
      flag: (this.cancelflag = true),
      data: this.addreason,
    };
    console.log(this.addreasoncancel, 'addreasoncancel', this.addreason);
    if (
      this.addreason != null &&
      typeof this.addreason != 'undefined' &&
      this.addreason != ''
    ) {
      this.dialogRef.close(this.addreasoncancel);
    } else {
      this.seubmitreasonsflag = true;
    }
  }
}