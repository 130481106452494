import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { ApiService } from 'src/app/services/api.service';
import { UserserviceService } from 'src/app/services/userservice.service';

@Component({
  selector: 'app-faqaddedit',
  templateUrl: './faqaddedit.component.html',
  styleUrls: ['./faqaddedit.component.css']
})
export class FaqaddeditComponent implements OnInit {


  public ckeConfig: any = {
    toolbar: [
      {
        name: "editing",
        items: ["Scayt", "Find", "Replace", "SelectAll"],
      },
      {
        name: "clipboard",
        items: [
          "Cut",
          // "Copy",
          // "Paste",
          // "PasteText",
          // "PasteFromWord",
          // "-",
          // "Undo",
          // "Redo"
        ],
      },
      {
        name: "links",
        items: [
          "Link",
          //  "Unlink",
          //  "Anchor"
        ],
      },
      // {
      //     name: "tools",
      //     items: ["Maximize", "ShowBlocks", "Preview", "Print", "Templates"]
      // },
      { name: "document", items: ["Source"] },
      {
        name: "insert",
        items: [
          "Image",
          // "Table",
          // "HorizontalRule",
          // "SpecialChar",
          // "Iframe",
          // "imageExplorer"
        ],
      },
      "/",
      {
        name: "basicstyles",
        items: [
          "Bold",
          "Italic",
          "Underline",
          // "Strike",
          // "Subscript",
          // "Superscript",
          // "-",
          // "RemoveFormat"
        ],
      },
      // {
      //     name: "paragraph",
      //     items: [
      //         "NumberedList",
      //         "BulletedList",
      //         "-",
      //         "Outdent",
      //         "Indent",
      //         "CreateDiv",
      //         "-",
      //         "Blockquote"
      //     ]
      // },
      // {
      //     name: "justify",
      //     items: [
      //         "JustifyLeft",
      //         "JustifyCenter",
      //         "JustifyRight",
      //         "JustifyBlock"
      //     ]
      // },

      {
        name: "styles",
        items: ["Styles", "Format", "FontSize", "-", "TextColor", "BGColor"],
      },
    ],
  };

  public faqcategoryname = [];

  emailregex: RegExp = /^(([^<>()\[\]\\.,;:\s@']+(\.[^<>()\[\]\\.,;:\s@']+)*)|('.+'))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  passwordregex: RegExp = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})/;
  textregex: RegExp = /^[a-zA-Z]*$/;

  temtdata: any = '';
  // formdata
  public flag: any = false;
  formfieldrefresh: any = false; 
  updatetable: any = false;
  formfieldrefreshdata: any = null;
  datasource: any;

  public formdata: any;
  public stateVal: any = [];

  
  public status: any = [
    { val: 1, name: 'Active' },
    { val: 0, name: 'Inactive' },
  ];
  public userData: any = [];
  public resolveval: any;
  public categoryname = [];


  public userrole = [
    {  val:'admin', name:"Admin"},
    {  val:'closer', name:"Closer"},
    {  val:'distributor', name:"Distributor"},
    {  val:'contract-manager', name:"Contract Manager"},
    {  val:'technological-consultant', name:"Associate Director"},
     
   ];


  constructor(public apiservice: ApiService, public http: HttpClient, public activatedroute: ActivatedRoute, public cookies: CookieService, public router: Router, public userservices: UserserviceService) {

    this.apiservice.getState().subscribe((res: any) => {
      for (const i in res) {
        if (Object.prototype.hasOwnProperty.call(res, i)) {
          this.stateVal.push({ val: res[i].abbreviation, name: res[i].name });
        }
      }
    });

    this.faqcategorylist();

    this.formdata = {
      successmessage: 'Added Successfully !!',
      redirectpath: '/admin/faq',
      submitactive: true, // optional, default true
      submittext: 'Submit',
      resettext: 'Reset',
      canceltext: 'Cancel',
      apiUrl: this.apiservice.api_url,
      endpoint: 'api/addfaq', //
      // jwttoken: this.cookies.get('jwtToken'),
      jwttoken: this.userservices.jwttoken,
      cancelroute: '/admin/faq',

      fields: [
        
     
        {
          label: 'Question',
          name: 'question',
          value: '',
          type: 'text',
          validations: [{ rule: 'required', message: 'Question is Required' }],
        },
 
        {
          label: 'Answer ',
          name: 'answer',
          value: '',
          type: 'editor',
          validations: [{ rule: 'required', message: 'Answer is Required' }],
          ckeConfig: this.ckeConfig,
        },


        {
          label: 'Category',
          name: 'questioncategory',
          value: '',
          val: this.faqcategoryname,
          type: 'select',
          validations: [{ rule: 'required', message: 'category is required' }],
        },
        {
          label: "User Role",
          name: "user_role",
          value: "",
          type: "select",
          val: this.userrole,
          validations: [{ rule: 'required', message: 'User Role is Required' }],
        },
        {
          label: 'Youtube ID',
          name: 'Youtubeid',
          type: 'text',
          value: '',
          // hint:"Desc .... ",
          // validations:[
          //     {rule:'required',message:"Youtube Link field Needs to be required"},
          //     ],
            prefix: 'https://www.youtube.com/watch?v='
          },


        {
          label: 'Priority',
          name: 'priority', 
          value: '',
          type: 'number',
          validations: [{ rule: 'required', message: 'Priority is Required' }],
        },


        {
          label: 'type',
          name: 'type',
          type: 'hidden',
          value: 'admin',
        },
       
         
         
  

        {
          label: 'Active',
          name: 'status',
          hint: '',
          type: 'checkbox',
          val: this.status,
          value: 0,
        },
      ],
      
    };


    if (this.activatedroute.snapshot.params._id != null) {

      this.editadmin();
      this.flag = true;
    }

   }


   
  ngOnInit() {
  }


  
 

  faqcategorylist() {
    this.apiservice

      .getDataWithoutToken(
        {
          token: this.apiservice.jwttoken,
          secret: this.apiservice.secret,
        },
        "api/faqcategorssearch"
      )
      .subscribe((response: any) => {
        console.log("faqcategoryname==>", response.res);
        for (const i in response.res) {
          if (response) {
            this.faqcategoryname.push({ val: response.res[i].val,   name: response.res[i].name });
          }
        }
      });
  }

  

  editadmin(){
    

      this.activatedroute.data.subscribe((res: any) => {
        console.log(res.resolvedata.result, '++++++++++');
        this.userData = res.resolvedata.result[0];
        this.resolveval = this.userData;

        this.formdata = {
          successmessage: 'Edit Successfully !!',
          redirectpath: '/admin/faq',
          submitactive: true, // optional, default true
          submittext: 'Update',
          resettext: 'Reset',
          canceltext: 'Cancel',
          apiUrl: this.apiservice.api_url,
          endpoint: 'api/addfaq', //
          // jwttoken: this.cookies.get('jwtToken'),
          jwttoken: this.userservices.jwttoken,
          cancelroute: '/admin/faq',

          fields: [
            


            {
              label: 'Question',
              name: 'question',
              value: this.resolveval.question,
              type: 'text',
              validations: [{ rule: 'required', message: 'Question is Required' }],
            },
             


            {
              label: 'Answer ',
              name: 'answer',
              value: this.resolveval.answer,
              type: 'editor',
              validations: [{ rule: 'required', message: 'Answer is Required' }],
              ckeConfig: this.ckeConfig,
            },
    
            {
              label: 'Category',
              name: 'questioncategory',
              value: this.resolveval.questioncategory,
              val: this.faqcategoryname,
              type: 'select',
              validations: [{ rule: 'required', message: 'category is Required' }],
            },
    

            
    
    
            {
              label: "User Role",
              name: "user_role",
              value: this.resolveval.user_role,
              type: "select",
              val: this.userrole,

              validations: [{ rule: 'required', message: 'User Role is Required' }],
              
            },
      

            {
              label: 'Youtube ID',
              name: 'Youtubeid',
              type: 'text',
              value: this.resolveval.Youtubeid,
              // hint:"Desc .... ",
              // validations:[
              //     {rule:'required',message:"Youtube Link field Needs to be required"},
              //     ],
                prefix: 'https://www.youtube.com/watch?v='
              },
    
    
            {
              label: 'Priority',
              name: 'priority',
              value: this.resolveval.priority,
              type: 'number',
              validations: [{ rule: 'required', message: 'Priority is Required' }],
            },




             
            {
              label: 'type',
              name: 'type',
              type: 'hidden',
              value: 'admin',
            },
     
 
 

            {
              label: 'Active',
              name: 'status',
              hint: '',
              type: 'checkbox',
              value: this.resolveval.status,
            },
            {
              label: 'id',
              name: 'id',
              value: this.activatedroute.snapshot.params._id,
              type: 'hidden',
            },
          ],
        };
      });
  }

}
 