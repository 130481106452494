import { HttpClient } from '@angular/common/http';
import { ConstantPool } from '@angular/compiler';
import { Component, Inject, OnInit } from '@angular/core';
import {
  MatDialog,
  MatDialogRef,
  MatSnackBar,
  MAT_DIALOG_DATA,
} from '@angular/material';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { title } from 'process';
import { ApiService } from 'src/app/services/api.service';
import { UserserviceService } from 'src/app/services/userservice.service';
import { LoaderService } from '../../../services/loader.service';

export interface DialogData {
  data: any;
  lesson_id: any;
  training_id: any;
  flag: any;
  video_url: any;
  title: any;
  description: any;
}
@Component({
  selector: 'app-video-gallery',
  templateUrl: './video-gallery.component.html',
  styleUrls: ['./video-gallery.component.css'],
})
export class VideoGalleryComponent implements OnInit {
  public currentThing: any;
  public video_data: any = [];
  public category_data: any = [];
  public active_cls_id: any = 'active';
  public activeclsflag: any = [];
  public productId: any = '';
  // public loaderService:LoaderService;
  public allactive: boolean = false;
  constructor(
    public router: Router,
    public Apiservice: ApiService,
    public http: HttpClient,
    public cookieservice: CookieService,
    public activatedRoute: ActivatedRoute,
    public userservice: UserserviceService,

    public dialog: MatDialog,
    public sanitizer: DomSanitizer,
    public loaderService: LoaderService
  ) {
    this.activatedRoute.data.forEach((res: any) => {
      if (this.activatedRoute.snapshot.params._prodid != null) {
        console.log('wara=====>', res.resolvedata.results)

        this.video_data = res.resolvedata.results.res;
        this.category_data = null
        this.allactive = false;
      }
      else {
        this.video_data = res.resolvedata.results.getallvideodata;
        let catdata = res.resolvedata.results.getallvideocategorydata;
        let category_id_array_unique: any = [];
        let category_id_array: any = this.video_data && this.video_data.length > 0 && this.video_data.map((val: any) =>
          val.category_id
        )
        category_id_array_unique = category_id_array.filter((item: any, pos: any, self: any) => self.indexOf(item) == pos)

        let catDat: any = []
        for (let i = 0; i < catdata.length; i++) {
          if (category_id_array_unique.includes(catdata[i]['_id'])) {
            catDat.push(catdata[i])
          }
        }

        this.category_data = catDat
        // this.allactive = true;
      }

      // this.loaderService = loaderService

      // this.loaderService.isLoading.next(false);
    });
    if (this.activatedRoute.snapshot.params._prodid != null) {
      this.productId = this.activatedRoute.snapshot.params._prodid
      //  let data:any = {
      //   // How many data showing in first page
      //   condition: {
      //     // condition for how much data show in one time
      //     limit: 10,
      //     skip: 0,
      //   },
      //   sort: {
      //     type: 'asc', // data sort by decreasing order
      //     field: 'video_title', // data sort by first name
      //   },
      //   searchcondition: {
      //     category_id: this.productId,
      //   },
      // };


      //  console.log('ID======>',this.activatedRoute.snapshot.params._id)
    }

  }

  ngOnInit() {
    // this.active_cls_id = '12345';
    // this.allSelected
    this.active_cls_id = '1234556787';
  }

  public loderflag = false;
  category_video(id, flag) {
    this.active_cls_id = id;
    this.activeclsflag = [];

    this.currentThing = 'thing';
    this.activeclsflag[id] = false;
    this.allactive = false;
    // // console.log(id);
    const endpoint = 'api1/allcategoryvideosasyncdata';
    let data: any = {};
    // this.loderflag = true;
    if (flag == 'notall') {
      console.log('FLAG======>', flag);
      this.activeclsflag[id] = 'true';
      this.allactive = false;
      // console.log(this.activeclsflag[id])
      data = {
        // How many data showing in first page
        condition: {
          // condition for how much data show in one time
          limit: 10,
          skip: 0,
        },
        sort: {
          type: 'asc', // data sort by decreasing order
          field: 'video_title', // data sort by first name
        },
        searchcondition: {
          category_id: id,
        },
      };
    }
    if (flag == 'all') {
      console.log('FLAG======>', flag);
      this.allactive = true;
      this.activeclsflag[id] = 'false';

      data = {
        // How many data showing in first page
        condition: {
          // condition for how much data show in one time
          limit: 10,
          skip: 0,
        },
        sort: {
          type: 'asc', // data sort by decreasing order
          field: 'video_title', // data sort by first name
        },
      };
    }

    this.Apiservice.getDataWithoutToken(data, endpoint).subscribe(
      (res: any) => {
        // this.loderflag = false;
        if (res.status == 'success') {
          this.video_data = res.results.getallvideodata;
        this.loderflag = false;

        }
        // console.log(res, 'jjjjjjjjjjjj');
        //   this.videolisttable = res.results.res; // sarch count  (How many data there)
        //   for (const key in res.results.res) {
        //     this.product_nameSearch.push({ val: res.results.res[key]._id, name: res.results.res[key].product_name_s })
        //   }
      }
    );
  }
  playvideo(val, flag) {
    // // console.log(flag, 'jjjjjjjj',val)
    if (flag == 'video') {
      let video_url: any;
      let url =
        'https://www.youtube.com/embed/' + val.video + '?HD=1&rel=0&autoplay=1';
      // let url = val.video
      video_url = this.sanitizer.bypassSecurityTrustResourceUrl(url);
      // video_url =  url;
      // let video = val.video.split('/')
      // console.log(video, 'jjjjjjjj', val)

      // let url =
      //   'https://www.youtube.com/watch?v=' + video[3];
      // let url = val.video
      // // console.log(video_url,'llllllllllllllllyigfiuyfiuyfi+++++++');
      const dialogRef = this.dialog.open(VideoModalComponent, {
        // width: '250px',
        // height:'500px',
        panelClass: 'videogallerymodal',
        data: {
          video_url: video_url,
          title: val.video_title,
          description: val.video_description,
          flag: flag,
        },
      });
      dialogRef.afterClosed().subscribe((result) => {
        // // console.log('The dialog was closed');
      });
    }
    if (flag == 'desc') {
      const dialogRef = this.dialog.open(VideoModalComponent, {
        // width: '250px',
        panelClass: 'videogallerymodal',
        data: { description: val.video_description, flag: flag },
      });
      dialogRef.afterClosed().subscribe((result) => {
        // // console.log('The dialog was closed');
      });
    }
  }
}
@Component({
  selector: 'video-modal-gallery',
  templateUrl: './videoPlaymodal.html',
  styleUrls: ['./video-gallery.component.css'],
})
export class VideoModalComponent implements OnInit {
  constructor(
    public dialogRef: MatDialogRef<VideoModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    public snakBar: MatSnackBar,
    public apiService: ApiService,
    public router: Router,
    public activatedRoute: ActivatedRoute
  ) {
    console.log(data, 'data_video')
  }
  ngOnInit(): void { }

  closedModals() {
    this.dialogRef.close();
  }
}
