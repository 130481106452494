import { HttpClient } from '@angular/common/http';
import { Component, Inject, OnInit } from '@angular/core';
import {
  MatBottomSheet,
  MatBottomSheetRef,
  MatDialog,
  MatDialogRef,
  MatSnackBar,
  MAT_BOTTOM_SHEET_DATA,
  MAT_DIALOG_DATA,
} from '@angular/material';
import { ActivatedRoute, Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { ApiService } from 'src/app/services/api.service';
import { UserserviceService } from 'src/app/services/userservice.service';

export interface DialogData {
  data: any;
}

@Component({
  selector: 'app-admin-list',
  templateUrl: './admin-list.component.html',
  styleUrls: ['./admin-list.component.css'],
})
export class AdminListComponent implements OnInit {
  public adminlistarray: any = [];

  public updatetable: boolean = false;

  public searchendpoint: any = '';
  //for listing

  // status for account
  public status: any = [
    { val: 1, name: 'Active' },
    { val: 0, name: 'Inactive' }
  ];
  public typeval: any = [
    { val: 'admin', name: 'Admin' },
    { val: 'distributor', name: 'Distributor' },
    // { val: 'sales-person', name: 'Sales Person' },
    { val: 'contract-manager', name: 'Contract Manager' },
    { val: 'closer', name: 'Closer' },
    { val: 'technological-consultant', name: 'Associate Director' },
  ];

  // status for account
  statusarray: any = [
    { val: 1, name: 'Active' },
    { val: 0, name: 'Inactive' }
  ];

  public refreshbtnflag = false;
  public clearsearchflag = false;
  //for listing
  public customlistenbutton: any = {
    flag: true,
    tooltipflag: true,
    buttons: [
      {
        label: 'ADD USER',
        type: 'button',
        name: 'add_user',
        tooltip: 'Add User',
        classname: 'add_adminBTN',
      },
      // {
      //   label: 'Refresh',
      //   type: 'button',
      //   name: 'refreshbtn',
      //   tooltip: 'Refresh',
      //   classname: 'refreshbtn'
      // }
    ],
  };
  // editroute: any = 'admin/edit/';
  // edit

  // table header
  modifyheaderarray: any = {
    fullname: 'Full Name',
    email: 'Email',
    phone: 'Contact Number',
    status: 'Status',
    lastlogin_datetime: 'Last Login',
    createdon_datetime: 'Joind On',
    type_show: 'Type',
    children: 'Leads',
  };

  // skip list header key
  adminListingarrayskip: any = [];

  // list details skip key
  adminListarraydetailskip: any = [
    '_id',
    'distributor',
    'firstname',
    'lastname',
    'accesscode',
    'firstname_s',
    'fullname_s',
    'address',
    'state',
    'city',
    'zip',
    'location_s',
    'address_search',
    'lastlogin_datetime',
    'login_data_val',
    'notescount',
    'contactInfo',
    'email_s',
    'related_info',
    'login_details',
    'event_type',
    'location_ar',
    'is_contractmanager',
    'products',
    'zip',
    'location',
    'children',
    'zip_s',
    'signaturestatus',
    'parentid_s',
    'signature_date',
    'signature',
    'parentid',
    'salesrep_f_name',
    'salesrep_l_name',
    'salesrep_phone',
    'salesrep_email',
    'status',
    'type',
    'type_s',
    'gameplancall',
    '_id_s',
    'custombuttonflag',
    'productsarray'
  ];

  updateendpoint = 'api/userstatusupdate'; // update status
  deleteendpoint = 'api/deleteuser'; // delete single user
  tablename = 'users'; // table name
  // clicktoaddananotherpage = '/admin/add';
  datesearchendpoint: any = '';
  // how many data show in list
  limitcond: any = {
    limit: 10,
    skip: 0,
  };

  // full lib listing data
  libdata: any = {
    preview_header: {

      header: "Preview Details",
      class: 'preheadercls'
  },
    detailview_override: [
      { key: 'createdon_datetime', val: 'Joined On' },
      { key: 'type_show', val: 'Type' },
      { key: 'status_show', val: 'Status' },
      { key: 'fullname', val: 'Name' },
      { key: 'phone', val: 'Phone Number' },
      { key: 'fulladdress', val: 'Address' },
      { key: 'first_login', val: 'First Login' },
      { key: 'lastlogincity', val: 'Last Login City' },
      { key: 'lastloginregion', val: 'Last Login Region' },
      { key: 'logincounts', val: 'Login Counts' },
      { key: 'productname', val: 'Product user can manage' },
      { key: 'discovery_call', val: 'Discovery Call' }, 
      { key: 'discovery_call_status', val: 'Discovery Call Status' },
      { key: 'onbordingcall', val: 'Tech able to Make Onboarding Call' },
      { key: 'gameplancall_s', val: 'Tech able to Make Game Plan Call' },
      { key: 'viewcommissionflag', val: 'Tech able to View Commission' },
      { key: 'first_login_datetime', val: 'First Login' },
      { key: 'TM_FLOW_ANS_MEDICAL_DEVICEsales_commission', val: 'TM-FLOW ANS MEDICAL DEVICE SALES COMMISSION' },
      { key: 'Bio_Energeticsevent_commission', val: 'BIO ENERGETICS EVENT COMMISSION' },
      { key: 'RST_Sanexussales_commission', val: 'RST SANEXUS SALES COMMISSION' },
      

    ],
    // table headline
    tableheaders: [
      'fullname',
      'email',
      'phone',
      'createdon_datetime',
      'lastlogin_datetime',
      'type_show',
      'status',
      'children',
    ],
    notes: {
      label: 'Add Notes',
      addendpoint: 'api/addnotedata',
      deleteendpoint: 'api/deletenotedata',
      listendpoint: 'api/listnotedata',
      user: this.userservice.userid,
      currentuserfullname:
        this.userservice.firstname + ' ' + this.userservice.lastname,
      header: 'fullname',
      classname: 'AddNotes',
      tooltip: 'Add Notes',
    },
    updateendpointmany: 'api/userupdate', // update multiple user
    updateendpoint: 'api/userstatusupdate', // update status
    deleteendpointmany: 'api/deleteuser', // delete multiple user
    hidemultipleselectbutton: true, // hide multiple selection button (false)
    hidecounter: null, // hide counter (false)
    hideaction: null, // hide action (false)
    hideeditbutton: true,
    hideviewbutton: null, // (hide view button)
    hidedeletebutton: false,

    // custom buttons in action
    custombuttons: [
      {
        label: 'Resend Welcome Mail',
        type: 'action',
        datatype: 'api',
        endpoint: 'api/resendemail',
        otherparam: ['type', '_id'],
        headermessage: 'Api Info',
        classname: 'Resendmail',
        tooltip: 'Resend Welcome Mail',
      },
      {
        label: ' Send Reset Password Mail',
        type: 'action',
        datatype: 'api',
        endpoint: 'api/forgetpassword',
        otherparam: ['email', '_id'],
        classname: 'ResetPasswordMail',
        tooltip: 'Send Reset Password Mail',
      },

      {
        label: 'Login As User',
        type: 'listner',
        id: 'i1',
        classname: 'LogMe',
        tooltip: 'Login As User',
        cond: 'custombuttonflag',
        condval: 0
      },

      // {
      //   label: 'View User Details',
      //   type: 'listner',
      //   id: 'i1',
      //   classname: 'viewuserdetails',
      //   tooltip: 'View User Details',
      // },
      {
        label: 'Edit User',
        type: 'listner',
        id: 'i1',
        classname: 'userEdit',
        tooltip: 'Edit User',
      },

      {
        label: 'View Contract',
        type: 'listner',
        id: 'i1',
        classname: 'viewContract',
        tooltip: 'View Contract',
        cond: 'signaturestatus',
        condval: 1,
      },
      {
        label: 'Folder View',
        route: 'user-details/',
        type: 'internallink',
        param: ['type', '_id'],
        tooltip: 'Folder View',
        classname: 'FolderView',
        cond: 'type',
        condval: 'technological-consultant',
      },
      {
        label: 'Folder View',
        route: 'user-details/',
        type: 'internallink',
        param: ['type', '_id'],
        tooltip: 'Folder View',
        classname: 'FolderView',
        cond: 'type',
        condval: 'distributor',
      },
      {
        label: 'Update Status',
        type: 'listner',
        id: 'updatestatus',
        tooltip: 'Discovery Call Enable',
        classname: 'UpdateStatus',
        cond: 'type',
        condval: 'technological-consultant'
      },

      // {
      //   label: 'Contract Manager',
      //   type: 'listner',
      //   id: 'a2',
      //   tooltip: 'Contract Manager',
      //   refreshdata: true,
      //   classname: 'isContractmanager'
      // }
    ],
  };

  // sorting in list
  sortdata: any = {
    type: 'desc',
    field: 'lastlogin_datetime',
    options: [
      'createdon_datetime',
      'fullname',
      'email',
      'phone',
      'contactInfo',
      'lastlogin_datetime',
      'status',
      'children',
    ],
  };

  datesearchsource: any = 'admin_blog_list';
  datacollection: any = 'api/userlistview'; // data list
  datasearchsourcecount: any = 0; // count how many data is exist
  // searching in linting
  searchsettings: any = {
    datesearch: [
      {
        startdatelabel: 'Joined On Start Date',
        enddatelabel: 'Joined On End Date',
        submit: 'Search',
        field: 'createdon_datetime',
        // value: {}
      },
    ],
    selectsearch: [
      {
        label: 'Search By Status',
        field: 'status',
        values: this.status,
        value: '',
      },
      {
        label: 'Search By Type',
        field: 'type',
        values: this.typeval,
        value: '',
      },
    ],
    textsearch: [
      // { label: 'Search By Name', field: 'fullname_s', value: '' },
      // { label: 'Search By Email', field: 'email_s', value: '' },
      // { label: 'Search By Location', field: 'location_s', value: '' },
    ],
    search: [
      {
        label: 'Search By Name',
        field: 'fullname_s',
        values: '',
        serversearchdata: { endpoint: 'api/exitsinglistname' },
      },
      {
        label: 'Search By Email',
        field: 'email_s',
        values: '',
        serversearchdata: { endpoint: 'api/exitsinglistemail' },
      },
      {
        label: 'Search By Location',
        field: 'location_ar',
        values: '',
        serversearchdata: { endpoint: 'api/exitsinglistlocation' },
      },
    ],
  };

  public userid: any;
  public userFullName: any;

  public jwttoken: any = '';
  constructor(
    public activatedroute: ActivatedRoute,
    public userservice: UserserviceService,
    public router: Router,
    public apiservice: ApiService,
    public http: HttpClient,
    public cookieservice: CookieService,
    public bottomSheet: MatBottomSheet,
    private _snackBar: MatSnackBar,
    public dialog: MatDialog
  ) {
    this.jwttoken = this.cookieservice.get('jwtToken');
    this.userid = this.userservice.userid;
    this.userFullName =
      this.userservice.firstname + ' ' + this.userservice.lastname;

    this.userlistcount();
  }

  ngOnInit() {
    // fetch data from database using resolve
    this.activatedroute.data.forEach((res: any) => {
      // console.log(res, 'adminlist...');
      this.adminlistarray = null;
      this.adminlistarray = res.resolvedata.results.res;
      console.log(this.adminlistarray, 'adminlistarray...++++++');

      const body = document.getElementsByTagName('body')[0];
      body.classList.add('userlistpage');
    });
  }

  listenLiblistingChange(val: any) {
    console.log(val, '------------');
    if (
      val.custombuttonclick != null &&
      val.custombuttonclick.btninfo != null &&
      val.custombuttonclick.btninfo.label === 'Update Status'
    ) {

      let update_status = {
        source: this.tablename,
        data: val.custombuttonclick.data,
        secret: this.cookieservice.get('secret'),
        token: this.cookieservice.get('jwttoken'),
      };
      const bottomSheetRef = this.bottomSheet.open(BottonSheetAdmin, {
        data: update_status,
      });
      bottomSheetRef.afterDismissed().subscribe(() => {

        console.log('Bottom sheet has been dismissed.');
        let data1 = {
          'secret': this.userservice.secret,
          'token': this.userservice.jwttoken,
          'source': '',
          'condition': {
            'limit': 10,
            'skip': 0
          },
          'sort': {
            'type': 'desc',
            'field': 'lastlogin_datetime'
          },
          'searchcondition': {
            'type': {
              '$ne': 'lead'
            }
          }
        }
        this.apiservice
          .getDataWithoutToken(data1, 'api/userlistview')
          .subscribe((res: any) => {
            this.adminlistarray = null;
            setTimeout(() => {
              this.adminlistarray = res.results.res;

            }, 50);
            // let currentUrl = this.router.url;
            // this.router.routeReuseStrategy.shouldReuseRoute = () => false;
            // this.router.onSameUrlNavigation = 'reload';
            // this.router.navigate([currentUrl]);
          })
      });

    }
    if (val.action == 'search') {
      this.refreshbtnflag = true;
    }
    console.log('val.action======>', val.action);
    if (
      val.action == 'custombuttonclick' &&
      val.custombuttonclick.btninfo.label == 'View User Details'
    ) {
      // console.log('View User Details', val.custombuttonclick.data);
      // this.router.navigateByUrl('/contract-view/' + val.custombuttonclick.data._id)
    }

    if (
      val.action == 'custombuttonclick' &&
      val.custombuttonclick.btninfo.label == 'Login As User' && val.custombuttonclick.data.type !='admin'
    ) {
      const dialogRef = this.dialog.open(DialogBoxComponent, {
        panelClass: 'DialogBoxComponent',

        data: { val: val.custombuttonclick.data },
      });
      dialogRef.afterClosed().subscribe((result: any) => {
        // // console.log('The dialog was closed', result);
        if (result == 'Yes') {
          let main_user = this.cookieservice.getAll();
          sessionStorage.clear();
          localStorage.clear();
          this.cookieservice.deleteAll();
          this.cookieservice.deleteAll('/');
          setTimeout(() => {
            this.userservice.cookieset('main_user', JSON.stringify(main_user));
            this.userservice.cookieset(
              'type',
              JSON.stringify(val.custombuttonclick.data.type)
            );
            this.userservice.cookieset(
              'firstname',
              JSON.stringify(val.custombuttonclick.data.firstname)
            );
            this.userservice.cookieset(
              'lastname',
              JSON.stringify(val.custombuttonclick.data.lastname)
            );
            this.userservice.cookieset(
              'userid',
              JSON.stringify(val.custombuttonclick.data._id)
            );
            this.userservice.cookieset(
              'lastlogindate',
              JSON.stringify(val.custombuttonclick.data.lastlogin_datetime)
            );
            this.userservice.cookieset(
              'useremail',
              JSON.stringify(val.custombuttonclick.data.email)
            );
            this.userservice.cookieset(
              'status',
              JSON.stringify(val.custombuttonclick.data.status)
            );
            this.userservice.cookieset(
              'lastlogincity',
              JSON.stringify(val.custombuttonclick.data.lastlogincity)
            );
            this.userservice.cookieset(
              'lastloginregion',
              JSON.stringify(val.custombuttonclick.data.lastloginregion)
            );
            this.userservice.cookieset(
              'jwtToken',
              main_user.jwtToken
            );
            this.userservice.cookieset(
              'secret',
              main_user.secret
            );
            this.userservice.cookieset(
              'parentid',
              JSON.stringify(val.custombuttonclick.data.parentid)
            );
            this.userservice.cookieset(
              'return_url',
              JSON.stringify(this.router.url)
            );
          }, 100);
          setTimeout(() => {
            this.router.navigateByUrl(
              '/' + val.custombuttonclick.data.type + '/dashboard'
            );
          }, 700);
        }
      });
    }
    if (
      val.action == 'custombuttonclick' &&
      val.custombuttonclick.btninfo.label == 'Edit User'
    ) {
      // console.log('Edit User');
      // return;
      if (val.custombuttonclick.data.type == 'contract-manager') {
        this.router.navigateByUrl(

          '/contract-manager/edit/' +
          val.custombuttonclick.data._id
        );
      }
      else {
        this.router.navigateByUrl(
          '/admin/' +
          val.custombuttonclick.data.type +
          '/edit/' +
          val.custombuttonclick.data._id
        );
      }

    }
    if (
      val.action == 'custombuttonclick' &&
      val.custombuttonclick.btninfo.label == 'View Contract'
    ) {
      // console.log('View Contract');

      window.open('/contract-view/' + val.custombuttonclick.data._id, '_blank');

      // this.router.navigateByUrl('/contract-view/' + val.custombuttonclick.data._id);
    }

    if (this.clearsearchflag) {
      val.limitdata = {};
      val.searchcondition = {};
      val.sortdata = {};
      setTimeout(() => {
        this.updatetable = true;
      }, 10);
      // console.log(val, '++++_+_+_')
    }
    // if (val.custombuttonclick != null && val.custombuttonclick.btninfo != null && val.custombuttonclick.btninfo.label == 'Contract Manager') {

    //   const dialogRef = this.dialog.open(iscontractmanagerDialog, {
    //     width: '250px',
    //     data: val
    //   });

    //   dialogRef.afterClosed().subscribe(result => {
    //     // console.log('The dialog was closed', result);
    //   });

    // let btnSheet = this.bottomSheet.open(BottonSheetAdmin, {
    //   data: val
    // })
    // btnSheet.afterDismissed().subscribe(res => {
    //   if (res == 1) {
    //     // console.log('++++', res)
    //     this.updatetable = true;
    //   }
    // })
    // }
  }

  onLiblistingButtonChange(val: any) {
    console.log(val,'onLiblistingButtonChange');

    if (
      val.action == 'customlistenbutton' &&
      val.buttondata.name == 'add_user'
    ) {
      // this.router.navigateByUrl('/admin/add');
      // console.log(val, 'valllll')
      const dialogRef = this.dialog.open(AddUserComponent, {
        panelClass: 'AddUserComponent',
        // height: '500px',
        // width: '500px',
        data: { val: val.data },
      });
      //       dialogRef.afterClosed().subscribe((result:any) => {
      // // console.log(result,'tttttttttttttttt')

      //       })
    }
  }

  userlistcount() {
    // console.log('userlistcount')
    this.datasearchsourcecount = 0;
    const endpointc = 'api/userlistview-count';
    const data: any = {
      // How many data showing in first page
      condition: {
        limit: 10,
        skip: 0,
      },
      searchcondition: {},
      sort: {
        type: 'desc',
        field: 'lastlogin_datetime',
      },
      secret: this.cookieservice.get('secret'),
      token: this.jwttoken,
    };

    this.apiservice
      .getDataWithoutToken(data, endpointc)
      .subscribe((res: any) => {
        this.datasearchsourcecount = res.count; // search count  (How many data there)
        this.refreshbtnflag = false;
      });
  }

  userlistdata() {
    // console.log('userlistdata')

    this.adminlistarray = [];
    const endpoint = 'api/userlistview';
    const data: any = {
      // How many data showing in first page
      condition: {
        limit: 10,
        skip: 0,
      },
      sort: {
        type: 'desc',
        field: 'logincounts',
      },
      secret: 'secret',
      token: 'token',
    };

    this.apiservice
      .getDataWithoutToken(data, endpoint)
      .subscribe((res: any) => {
        // console.log(res);
        this.userlistcount();
        this.adminlistarray = res.results.res; // search count  (How many data there)
      });
  }

  refreshbtn() {
    this.searchsettings.selectsearch[0].value = '';
    this.searchsettings.selectsearch[1].value = '';
    this.searchsettings.textsearch[0].value = '';
    this.searchsettings.textsearch[1].value = '';
    this.searchsettings.textsearch[2].value = '';
    // return;
    this.userlistdata();
    // setTimeout(() => {
    // this.updatetable = true;
    // this.clearsearchflag = true;
    // }, 500);
  }
}

@Component({
  selector: 'bottonsheet',
  templateUrl: './bottomsheet.html',
})
export class BottonSheetAdmin {
  public deleteflag: boolean = false;
  constructor(
    public cookieservice: CookieService,
    public bottomSheetRef: MatBottomSheetRef<BottonSheetAdmin>,
    public apiservice: ApiService,
    public userservice: UserserviceService,
    public router: Router,
    @Inject(MAT_BOTTOM_SHEET_DATA) public data,
    private _snackBar: MatSnackBar,
    public dialog: MatDialog
  ) {
    console.log(data, '777777777777777');
  }
  isContractmanagerfun(val: any) {
    // console.log(val, '+_+');

    this.data.data['discoverystatus'] = val;
    this.data.token = this.cookieservice.get('jwtToken')
    console.log(this.data);

    this.apiservice
      .getDataWithoutToken(this.data, 'api/userdiscoverystatusupdate')
      .subscribe((res: any) => {
        if (res.status == 'success') {
          this._snackBar.open('Updated successfully!!', 'Ok', {
            duration: 2000,
          });
          //   setTimeout(() => {
          // 
          //   }, 500);

        }
        this.bottomSheetRef.dismiss();
      });
  }
  // resendMail() {
  //   let data = {
  //     token: this.userservice.jwttoken,
  //     secret: this.userservice.secret,
  //     type: this.data.custombuttonclick.data.type,
  //     _id: this.data.custombuttonclick.data._id
  //   };
  //   // console.log('resendMail');
  //   this.apiservice.getDataWithoutToken(data, 'api/resendemail').subscribe((res: any) => {
  //     // console.log(res, 'resendMail');
  //     if (res.status == 'success') {
  //       this._snackBar.open('Resend Mail Successfull.', 'Ok', {
  //         duration: 2000
  //       });
  //     }
  //     this.bottomSheetRef.dismiss();
  //   })
  // }

  // sendResetPassword() {
  //   let data = {
  //     token: this.userservice.jwttoken,
  //     secret: this.userservice.secret,
  //     _id: this.data.custombuttonclick.data._id,
  //     email: this.data.custombuttonclick.data.email
  //   };
  //   this.apiservice.getDataWithoutToken(data, 'api/forgetpassword').subscribe((res: any) => {
  //     if (res.status == 'success') {
  //       this._snackBar.open('Request for Password Reset is successful, Please check your registered mail.', 'Ok', {
  //         duration: 2000
  //       });
  //     }
  //     this.bottomSheetRef.dismiss();
  //   })
  // }

  // deleteConfirmBtn() {
  //   let data = {
  //     token: this.userservice.jwttoken,
  //     secret: this.userservice.secret,
  //     id: this.data.custombuttonclick.data._id,
  //   };
  //   let flag = '';

  //   this.apiservice.getDataWithoutToken(data, 'api/deleteuser').subscribe((res: any) => {
  //     if (res.status == 'success') {
  //       this._snackBar.open('Record Delete Successfully', 'Ok', {
  //         duration: 2000
  //       })
  //       flag = 'yes';
  //       this.bottomSheetRef.dismiss(flag);
  //     } else {
  //       flag = 'no';
  //       this.bottomSheetRef.dismiss(flag);
  //     }
  //   })
  // }

  // deleteCancelBtn() {
  //   this.deleteflag = false;
  // }

  // deleteBtn() {
  //   this.deleteflag = true;

  //   // console.log('=====', this.deleteflag)
  // }

  // ViewDetails(val: any) {
  //   // console.log(val, 'divicesInfo');
  //   const dialogRef = this.dialog.open(ViewDetailsAdmin, {
  //     // height: '500px',
  //     // width: '500px',
  //     panelClass: 'ViewDetailsmodal',
  //     data: { val: this.data }

  //   })
  // }
}
@Component({
  selector: 'iscontractmanager',
  templateUrl: 'iscontractmanager.html',
})
export class iscontractmanagerDialog {
  public allData: any;

  formfieldrefresh: any = false;
  updatetable: any = false;
  formfieldrefreshdata: any = null;
  datasource: any;
  public flag: any;
  public formdata: any;
  public stateVal: any = [];
  public status: any = [
    { val: 1, name: 'Active' },
    { val: 0, name: 'Inactive' },
    { val: 2, name: 'Inactive1' },
  ];
  public userData: any = [];
  public productlist: any = [];

  constructor(
    public dialogRef: MatDialogRef<iscontractmanagerDialog>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    public apiservice: ApiService,
    private _snackBar: MatSnackBar,
    public userService: UserserviceService,
    public cookieservice: CookieService
  ) {
    this.allData = JSON.stringify(data);
    this.allData = JSON.parse(this.allData);
    // console.log(this.allData, '+++++++');
    let dataval = {
      token: this.cookieservice.get('jwtToken'),
      secret: this.cookieservice.get('secret'),
    };

    this.apiservice
      .getDataWithoutToken(dataval, 'api/productsearch')
      .subscribe((res: any) => {
        // console.log(res.res, '+++-------------')
        // this.productlist = res.res;
        for (const key in res.res) {
          this.productlist.push({
            val: res.res[key].val,
            name: res.res[key].name,
          });
        }
        // if (res.status == 'success') {
        //   this._snackBar.open('Updated successfully!!', 'Ok', {
        //     duration: 2000
        //   });
        // }
        // this.dialogRef.close();
      });

    this.formdata = {
      successmessage: 'Added Successfully !!',
      // redirectpath: 'admin/list',
      submitactive: true, // optional, default true
      submittext: 'Submit',
      // resettext: 'Reset',
      // canceltext: 'Cancel',
      // apiUrl: this.apiservice.api_url,
      // endpoint: 'api/userupdate', //
      // jwttoken: '',
      // cancelroute: 'admin/list',

      fields: [
        {
          label: 'Select Product',
          name: 'products',
          type: 'select',
          val: this.productlist,
          // multiple: true,
          value: this.allData.custombuttonclick.data.products,
          validations: [{ rule: 'required' }],
        },
        {
          label: 'Active',
          name: 'is_contractmanager',
          hint: '',
          type: 'checkbox',
          val: this.status,
          value: this.allData.custombuttonclick.data.is_contractmanager,
        },
      ],
    };
  }

  onNoClick(): void {
    this.dialogRef.close();
  }
  listenFormFieldChange(val: any) {
    if (val.field === 'fromsubmit') {
      let data = {
        data: {
          id: this.allData.custombuttonclick.data._id,
          is_contractmanager: val.fromval.is_contractmanager,
          products: val.fromval.products,
        },
      };
      this.apiservice
        .getDataWithoutToken(data, 'api/userupdate')
        .subscribe((res: any) => {
          if (res.status == 'success') {
            this._snackBar.open('Updated successfully!!', 'Ok', {
              duration: 2000,
            });
          }
          this.dialogRef.close();
        });
    }
  }
}

@Component({
  selector: 'viewdetails',
  templateUrl: 'viewdetails.html',
})
export class ViewDetailsAdmin {
  public valdata: any;
  constructor(
    public dialogRef: MatDialogRef<ViewDetailsAdmin>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    public apiservice: ApiService,
    public activatedroute: ActivatedRoute,
    public userservice: UserserviceService
  ) {
    this.valdata = JSON.stringify(data);
    this.valdata = JSON.parse(this.valdata);
    // console.log(data, '+++++');
    // console.log(this.valdata, '+++data++');
  }

  onNoClick(): void {
    this.dialogRef.close();
  }
}

@Component({
  selector: 'dialogbox',
  templateUrl: 'dialogbox.html',
})
export class DialogBoxComponent {
  public valdata: any;
  constructor(
    public dialogRef: MatDialogRef<DialogBoxComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    public apiservice: ApiService,
    public activatedroute: ActivatedRoute,
    public userservice: UserserviceService
  ) {
    this.valdata = JSON.stringify(data);
    this.valdata = JSON.parse(this.valdata);
    // console.log(data, '+++++');
    // console.log(this.valdata, '+++data++');
  }

  onNoClick(val: any): void {
    this.dialogRef.close(val);
  }
}
//  ADD USER
@Component({
  selector: 'adduser',
  templateUrl: 'adduser.html',
})
export class AddUserComponent {
  constructor(
    public dialogRef: MatDialogRef<AddUserComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    public apiservice: ApiService,
    public activatedroute: ActivatedRoute,
    public userservice: UserserviceService
  ) {
    // console.log(data, '+++++');
  }

  onNoClick(): void {
    this.dialogRef.close();
  }
}
