import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { ApiService } from 'src/app/services/api.service';
import { UserserviceService } from 'src/app/services/userservice.service';


@Component({
  selector: 'app-faqcategoryaddedit',
  templateUrl: './faqcategoryaddedit.component.html',
  styleUrls: ['./faqcategoryaddedit.component.css']
})
export class FaqcategoryaddeditComponent implements OnInit {

  public ckeConfig: any = {
    toolbar: [
      {
        name: "editing",
        items: ["Scayt", "Find", "Replace", "SelectAll"],
      },
      {
        name: "clipboard",
        items: [
          "Cut",
          // "Copy",
          // "Paste",
          // "PasteText",
          // "PasteFromWord",
          // "-",
          // "Undo",
          // "Redo"
        ],
      },
      {
        name: "links",
        items: [
          "Link",
          //  "Unlink",
          //  "Anchor"
        ],
      },
      // {
      //     name: "tools",
      //     items: ["Maximize", "ShowBlocks", "Preview", "Print", "Templates"]
      // },
      { name: "document", items: ["Source"] },
      {
        name: "insert",
        items: [
          "Image",
          // "Table",
          // "HorizontalRule",
          // "SpecialChar",
          // "Iframe",
          // "imageExplorer"
        ],
      },
      "/",
      {
        name: "basicstyles",
        items: [
          "Bold",
          "Italic",
          "Underline",
          // "Strike",
          // "Subscript",
          // "Superscript",
          // "-",
          // "RemoveFormat"
        ],
      },
      // {
      //     name: "paragraph",
      //     items: [
      //         "NumberedList",
      //         "BulletedList",
      //         "-",
      //         "Outdent",
      //         "Indent",
      //         "CreateDiv",
      //         "-",
      //         "Blockquote"
      //     ]
      // },
      // {
      //     name: "justify",
      //     items: [
      //         "JustifyLeft",
      //         "JustifyCenter",
      //         "JustifyRight",
      //         "JustifyBlock"
      //     ]
      // },

      {
        name: "styles",
        items: ["Styles", "Format", "FontSize", "-", "TextColor", "BGColor"],
      },
    ],
  };

  emailregex: RegExp = /^(([^<>()\[\]\\.,;:\s@']+(\.[^<>()\[\]\\.,;:\s@']+)*)|('.+'))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  passwordregex: RegExp = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})/;
  textregex: RegExp = /^[a-zA-Z]*$/;
  temtdata: any = '';
  // formdata
  formfieldrefresh: any = false; 
  updatetable: any = false;
  formfieldrefreshdata: any = null;
  datasource: any;
  public flag: any;
  public formdata: any;
  public stateVal: any = [];
  public status: any = [
    { val: 1, name: 'Active' },
    { val: 0, name: 'Inactive' },
  ];
  public userData: any = [];
  public resolveval: any;
  public categoryname = [];

  constructor(public apiservice: ApiService, public http: HttpClient, public activatedroute: ActivatedRoute, public cookies: CookieService, public router: Router, public userservices: UserserviceService) {
    this.apiservice.getState().subscribe((res: any) => {
      for (const i in res) {
        if (Object.prototype.hasOwnProperty.call(res, i)) {
          this.stateVal.push({ val: res[i].abbreviation, name: res[i].name });
        }
      }
    });

    this.formdata = {
      successmessage: 'Added Successfully !!',
      redirectpath: '/admin/faqcategory',
      submitactive: true, // optional, default true
      submittext: 'Submit',
      resettext: 'Reset',
      canceltext: 'Cancel',
      apiUrl: this.apiservice.api_url,
      endpoint: 'api/addfaqcategory', //
      // jwttoken: this.cookies.get('jwtToken'),
      jwttoken: this.userservices.jwttoken,
      cancelroute: '/admin/faqcategory',

      fields: [
        {
          label: 'Category Name',
          name: 'categoryname',
          value: '',
          type: 'text',
          validations: [{ rule: 'required', message: 'Category Name is Required' }],
        },
        {
          label: 'Description',
          name: 'description',
          value: '',
          type: 'editor',
          validations: [{ rule: 'required', message: 'Answer is Required' }],
          ckeConfig: this.ckeConfig,
        },
        {
          label: 'Priority',
          name: 'priority',
          value: '',
          type: 'number',
          validations: [{ rule: 'required', message: 'Priority is Required' }],
        },
        {
          label: 'type',
          name: 'type',
          type: 'hidden',
          value: 'admin',
        },
        {
          label: 'Active',
          name: 'status',
          hint: '',
          type: 'checkbox',
          val: this.status,
          value: 0,
        },
      ],
    };

    if (this.activatedroute.snapshot.params.id != null) {

      this.editadmin();
      this.flag = true;
    }

   }

  ngOnInit() {   
  }
  editadmin(){
    console.log(this.activatedroute.snapshot.params)
    if (this.activatedroute.snapshot.params.id != null) {
      this.activatedroute.data.subscribe((res: any) => {
      //  console.log(res.resolvedata.result, '++++++++++');
        this.userData = res.resolvedata.result[0];
        this.resolveval = this.userData;
 

        this.formdata = {
          successmessage: 'Edit Successfully !!',
          redirectpath: '/admin/faqcategory',
          submitactive: true, // optional, default true
          submittext: 'Update',
          resettext: 'Reset',
          canceltext: 'Cancel',
          apiUrl: this.apiservice.api_url,
          endpoint: 'api/addfaqcategory', //
          jwttoken: this.userservices.jwttoken,
          cancelroute: '/admin/faqcategory',

          fields: [
            {
              label: 'Category Name',
              name: 'categoryname',
              value: this.resolveval.categoryname,
              type: 'text',
              validations: [{ rule: 'required', message: 'Category Name is Required' }],
            },
            {
              label: 'Description',
              name: 'description',
              value: this.resolveval.description,
              type: 'editor',
              validations: [{ rule: 'required', message: 'Answer is Required' }],
              ckeConfig: this.ckeConfig,
            },
            {
              label: 'Priority',
              name: 'Priority',
              value: this.resolveval.priority,
              type: 'number',
              validations: [{ rule: 'required', message: 'Priority is Required' }],
            },           
            {
              label: 'type',
              name: 'type',
              type: 'hidden',
              value: 'admin',
            },
            {
              label: 'Active',
              name: 'status',
              hint: '',
              type: 'checkbox',
              value: this.resolveval.status,
            },
            {
              label: 'id',
              name: 'id',
              value: this.activatedroute.snapshot.params.id,
              type: 'hidden',
            },
          ],
        };
      });
    }
  }

}
