import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'detailspipe'
})
export class DetailspipePipe implements PipeTransform {

  transform(value: any): any {
    if (value == null || typeof (value) === 'undefined') {
      return 'N/A';
    }

    return value.length > 10 ? value.substr(0, 10) + '...' : value;
  }

}
