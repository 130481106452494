import { Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { Observable, Subject, Observer } from 'rxjs';
import { ApiService } from './api.service';

@Injectable({
  providedIn: 'root'
})
export class HttpObservableService {
  private subject = new Subject<any>();
  constructor(
    public apiservice: ApiService,
    public cookieService: CookieService,
    public activatedRoute: ActivatedRoute,
    public router: Router,
    // public sanitizer: DomSanitizer,
  ) { }
  sethttpData(data) { // this is use to set the data
    this.subject.next(data);
  }
  gethttpData(): Observable<any> { // this is use to get the data
    return this.subject.asObservable();
  }


}
