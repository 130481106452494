import { Component, OnInit } from '@angular/core';
import { HttpClient } from "@angular/common/http";
import { ActivatedRoute, Router } from "@angular/router";
import { CookieService } from "ngx-cookie-service";
import { ApiService } from "src/app/services/api.service";
import { UserserviceService } from "src/app/services/userservice.service";
import { MatSnackBar } from '@angular/material';

@Component({
  selector: 'app-commission-payment',
  templateUrl: './commission-payment.component.html',
  styleUrls: ['./commission-payment.component.css']
})
export class CommissionPaymentComponent implements OnInit {
  public ckeConfig: any = {
    toolbar: [
      {
        name: "editing",
        items: ["Scayt", "Find", "Replace", "SelectAll"],
      },
      {
        name: "clipboard",
        items: [
          "Cut",
          // "Copy",
          // "Paste",
          // "PasteText",
          // "PasteFromWord",
          // "-",
          // "Undo",
          // "Redo"
        ],
      },
      {
        name: "links",
        items: [
          "Link",
          //  "Unlink",
          //  "Anchor"
        ],
      },
      // {
      //     name: "tools",
      //     items: ["Maximize", "ShowBlocks", "Preview", "Print", "Templates"]
      // },
      { name: "document", items: ["Source"] },
      {
        name: "insert",
        items: [
          "Image",
          // "Table",
          // "HorizontalRule",
          // "SpecialChar",
          // "Iframe",
          // "imageExplorer"
        ],
      },
      "/",
      {
        name: "basicstyles",
        items: [
          "Bold",
          "Italic",
          "Underline",
          // "Strike",
          // "Subscript",
          // "Superscript",
          // "-",
          // "RemoveFormat"
        ],
      },
      // {
      //     name: "paragraph",
      //     items: [
      //         "NumberedList",
      //         "BulletedList",
      //         "-",
      //         "Outdent",
      //         "Indent",
      //         "CreateDiv",
      //         "-",
      //         "Blockquote"
      //     ]
      // },
      // {
      //     name: "justify",
      //     items: [
      //         "JustifyLeft",
      //         "JustifyCenter",
      //         "JustifyRight",
      //         "JustifyBlock"
      //     ]
      // },

      {
        name: "styles",
        items: ["Styles", "Format", "FontSize", "-", "TextColor", "BGColor"],
      },
    ],
  };
  public flag: boolean;
  public formdata: any;
  formfieldrefresh: any;
  formfieldrefreshdata: any;
  public resolveval: any;
  public userData: any;
  public status: any = [
    { val: 1, name: "Active" },
    { val: 0, name: "Inactive" },
  ];
  public UserType: any = [
    { name: "Distributor", val: "distributor" },
    { name: "Technological-Consultant", val: "technological-consultant" },
    { name: "Closer", val: "closer" },
    { name: "Lead", val: "lead" },
    { name: "Sales-Person", val: "sales-person" },
    { name: "Contract-Manager", val: "contract-manager" },
  ];
  public payment_details:any
  constructor(
    public apiservice: ApiService,
    public http: HttpClient,
    public activatedRoute: ActivatedRoute,
    public cookies: CookieService,
    public router: Router,
    public userService: UserserviceService,
    public _snackBar: MatSnackBar
  ) {
    this.activatedRoute.data.subscribe((data: any) => {
      console.log(data);
      this.payment_details = data.resolvedata.results.res[0];
      if(typeof (this.payment_details.remaining_tech_paid) != 'undefined' && this.payment_details.remaining_tech_paid != null){
        this.payment_details.tech_commission = this.payment_details.remaining_tech_paid
      }
      if(typeof (this.payment_details.remaining_distributor_paid) != 'undefined' && this.payment_details.remaining_distributor_paid != null){
        this.payment_details.distributor_commission = this.payment_details.remaining_distributor_paid
      }
      console.log(data);
      
    })
    this.formdata = {
      successmessage: "Added Successfully !!",
      redirectpath: "/commission/list",
      submitactive: true, // optional, default true
      submittext: "Pay",
      // resettext: "Reset",
      hidereset: true,
      canceltext: "Cancel",
      apiUrl: this.apiservice.api_url,
      // endpoint: "", //
      jwttoken: this.apiservice.jwttoken,
      cancelroute: "/commission/list",

      fields: [
        {
          label: "Payable amount for Tech",
          name: "tech_commission",
          value: this.payment_details.tech_commission,
          type: "number",
          prefix: '$',
          disabled: true,
        },
        {
          label: "Payable amount for Distributor",
          name: "distributor_commission",
          value: this.payment_details.distributor_commission,
          type: "number",
          prefix: '$',
          disabled: true,
        },
        {
          label: "Notes",
          name: "notes",
          type: "editor",
          hint: "Desc .... ",
          value: "",
          ckeConfig: this.ckeConfig,
        },
        {
          label: "Transaction Attachment",
          name: "transaction_attachments",
          type: "file",
          multiple: true,
          prefix: "image-" + Date.now(),
          path: "betoparedes/commission-transaction-attachments/",
          baseurl: "betoparedes-dev-screenshots",
          bucket: "awsbackend-dev-patient-files-test",
          apiurl:
            "https://tge24bc2ne.execute-api.us-east-1.amazonaws.com/dev/requestUploadURL",
          apideleteurl:
            "https://tge24bc2ne.execute-api.us-east-1.amazonaws.com/dev/deletefilefromBucket",
        },
        {
          label: "Payment Record",
          name: "payment_record",
          value: [{
            added_by_id:JSON.parse(this.cookies.get('userid')),
            added_by_name:JSON.parse(this.cookies.get('firstname')) +" "+ JSON.parse(this.cookies.get('lastname')),
            added_datetime:new Date().toISOString()
          }],
          type: "hidden",
        },
        {
          label: "Commission Id",
          name: "_id",
          value: this.activatedRoute.snapshot.params._id,
          type: "hidden",
        },
      ],
    };
    if (this.activatedRoute.snapshot.url[2].path == 'full') {
      this.flag = false;
      this.formfieldrefreshdata = {
        field: 'addfromcontrol',
        value: [
          {
            label: "Pay",
            name: "tech_paid_view",
            value: this.payment_details.tech_commission,
            type: "number",
            prefix: '$',
            disabled: true,
            after:'tech_commission'
          },
          {
            label: "Pay",
            name: "tech_paid",
            value: this.payment_details.tech_commission,
            type: "hidden",
            after:'tech_paid_view'
          },
          {
            label: "Pay",
            name: "distributor_paid_view",
            value: this.payment_details.distributor_commission,
            type: "number",
            prefix: '$',
            disabled: true,
            after:'distributor_commission'
          },
          {
            label: "Pay",
            name: "distributor_paid",
            value: this.payment_details.distributor_commission,
            type: "hidden",
            after:'distributor_paid_view'
          },
        ]
      };
    }
    if (this.activatedRoute.snapshot.url[2].path == 'partial') {
      this.flag = true;
      this.formfieldrefreshdata = {
        field: 'addfromcontrol',
        value: [
          {
            label: "Pay",
            name: "tech_paid",
            value: "",
            type: "number",
            prefix: '$',
            validations: [
              { rule: 'max', value: this.payment_details.tech_commission, message: "You cant't pay more than the payable amount" },
              { rule: 'min', value: 1, message: "You cant't pay less than $1" }
            ],
            after:'tech_commission'
          },
          {
            label: "Pay",
            name: "distributor_paid",
            value: "",
            type: "number",
            prefix: '$',
            validations: [
              { rule: 'max', value: this.payment_details.distributor_commission, message: "You cant't pay more than the payable amount" },
              { rule: 'min', value: 1, message: "You cant't pay less than $1" }
            ],
            after:'distributor_commission'
          },
        ]
      };
    }
    if(this.payment_details.tech_commission == 0){
      setTimeout(() => {
        this.formfieldrefreshdata = { field: 'removefromcontrol', value: ['tech_commission', 'tech_paid_view', 'tech_paid'] };
      }, 100);
    }
    if(this.payment_details.distributor_commission == 0){
      setTimeout(() => {
        this.formfieldrefreshdata = { field: 'removefromcontrol', value: ['distributor_commission', 'distributor_paid_view', 'distributor_paid'] };
      }, 100);
    }
  }

  ngOnInit() { }
  listenFormFieldChanges(val: any) {
    if(val.field == 'fromsubmit' && val.fieldval == 'success'){ //modify extra data to update payment details
      let data = val.fromval;
      if(data.tech_paid != '' || data.distributor_paid != ''){
        if(typeof (data.tech_paid) != 'undefined' && data.tech_paid != null && data.tech_paid != ''){
          data.payment_record[0]['tech_paid'] = data.tech_paid
          data['remaining_tech_paid'] = (Number(this.payment_details.tech_commission) - Number(data.tech_paid)).toFixed(2);
          if(typeof (this.payment_details.tech_paid) != 'undefined' && this.payment_details.tech_paid != null && this.payment_details.tech_paid != ''){
            data.tech_paid = (Number(data.tech_paid) + Number(this.payment_details.tech_paid)).toFixed(2);
          }
        }else{
          delete data.tech_paid;
        }
        if(typeof (data.distributor_paid) != 'undefined' && data.distributor_paid != null && data.distributor_paid != ''){
          data.payment_record[0]['distributor_paid'] = data.distributor_paid
          data['remaining_distributor_paid'] = (Number(this.payment_details.distributor_commission) - Number(data.distributor_paid)).toFixed(2);
          if(typeof (this.payment_details.distributor_paid) != 'undefined' && this.payment_details.distributor_paid != null && this.payment_details.distributor_paid != ''){
            data.distributor_paid = (Number(data.distributor_paid) + Number(this.payment_details.distributor_paid)).toFixed(2);
          }
        }else{
          delete data.distributor_paid;
        }
        if(typeof (data.notes) != 'undefined' && data.notes != null && data.notes != ''){
            data.payment_record[0]['notes'] = data.notes;
            delete data.notes;
        }
        if(typeof (data.transaction_attachments) != 'undefined' && data.transaction_attachments != null && data.transaction_attachments != ''){
            data.payment_record[0]['transaction_attachments'] = data.transaction_attachments;
            delete data.transaction_attachments;
        }
        if(typeof (this.payment_details.payment_record) != 'undefined' && this.payment_details.payment_record != null && this.payment_details.payment_record != ''){
          for (const key in this.payment_details.payment_record) {
            data.payment_record.push(this.payment_details.payment_record[key]);
          }
        }
        console.log(data);
        let send_data = {
          secret:this.userService.secret,
          token:this.userService.jwttoken,
          data:data
        }
        this.apiservice.getDataWithoutToken(send_data,"api10/update-payment-details-commision").subscribe((data:any)=>{
          if(data.status == 'success'){
            this._snackBar.open('Payment done successfully', 'Dismiss', {
              duration: 3000
            });
            this.router.navigateByUrl('/commission/list');
          }
        })
      }else{
        this._snackBar.open('Please enter an amount', 'Dismiss', {
          duration: 3000
        });
        window.scrollTo({
          top: 0,
          left: 0,
          behavior: "smooth",
        });
      }
    }
  }
}
