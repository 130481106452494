import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { ApiService } from 'src/app/services/api.service';
import { UserserviceService } from 'src/app/services/userservice.service';

@Component({
  selector: 'app-closer-add-edit',
  templateUrl: './closer-add-edit.component.html',
  styleUrls: ['./closer-add-edit.component.css']
})
export class CloserAddEditComponent implements OnInit {

  emailregex: RegExp = /^(([^<>()\[\]\\.,;:\s@']+(\.[^<>()\[\]\\.,;:\s@']+)*)|('.+'))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  passwordregex: RegExp = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})/;
  // textregex: RegExp = /^[a-zA-Z]*$/;

  temtdata: any = '';
  public flag: any;
  formfieldrefresh: any = false;
  updatetable: any = false;
  formfieldrefreshdata: any = null;
  datasource: any;
  public productVal = [];
  public typeVal = [];
  public formdata: any;
  public stateVal: any = [];
  public status: any = [
    { val: 1, name: 'Active' },
    { val: 0, name: 'Inactive' },
  ];
  public userData: any = [];
  public resolveval: any;
  public token: any = '';
  public secret: any = '';
  constructor(
    public apiservice: ApiService,
    public http: HttpClient,
    public activatedRoute: ActivatedRoute,
    public cookies: CookieService,
    public router: Router,
    public userservice: UserserviceService
  ) {
    this.token = this.cookies.get('jwtToken');
    this.secret = this.cookies.get('secret');
    this.getproductlist();
    this.userselection();
    this.apiservice.getState().subscribe((res: any) => {
      for (const i in res) {
        if (Object.prototype.hasOwnProperty.call(res, i)) {
          this.stateVal.push({ val: res[i].abbreviation, name: res[i].name });
        }
      }
    });
    if (this.userservice.type == "distributor") {
      this.formdata = {
        successmessage: 'Added Successfully !!',
        redirectpath: 'distributor/users',
        submitactive: true, // optional, default true
        submittext: 'Submit',
        resettext: 'Reset',
        canceltext: 'Cancel',
        apiUrl: this.apiservice.api_url,
        endpoint: 'api/addusers', //
        jwttoken: '',
        cancelroute: 'distributor/users',

        fields: [
          {
            label: 'First Name',
            name: 'firstname', //
            value: '',
            type: 'text',
            validations: [
              { rule: 'required', message: 'First Name is Required' },
              // {
              //   rule: 'pattern',
              //   value: this.textregex,
              //   message: 'only use alphabet',
              // },
            ],
          },

          {
            heading: '',
            label: 'Last Name',
            name: 'lastname',
            value: '',
            type: 'text',
            validations: [
              { rule: 'required', message: 'Last Name is Required' },
              // {
              //   rule: 'pattern',
              //   value: this.textregex,
              //   message: 'only use alphabet',
              // },
            ],
          },

          {
            label: 'Telephone',
            name: 'phone',
            value: '',
            type: 'number',
            validations: [
              { rule: 'required', message: 'Telephone is Required' },
              {
                rule: 'minLength',
                value: 10,
                message: 'The Phone Number must be 10 digits',
              },
              {
                rule: 'maxLength',
                value: 15,
                message: 'Please enter the valid number',
              },
            ],
          },

          {
            label: 'Email',
            name: 'email',
            type: 'email',
            hint: '',

            validations: [
              { rule: 'required', message: 'Email is required' },
              {
                rule: 'pattern',
                value: this.emailregex,
                message: 'Must be a valid Email',
              },
            ],
          },

          {
            label: 'Company Name',
            name: 'company',
            value: '',
            type: 'text',
            validations: [{ rule: 'required', message: 'Company Name is Required' }],
          },


          {
            label: 'Address',
            name: 'address',
            value: '',
            type: 'textarea',
            validations: [{ rule: 'required', message: 'Address is Required' }],
          },




          {
            label: 'type',
            name: 'type',
            type: 'hidden',
            value: 'closer',
          },
          {
            label: 'State',
            name: 'state',
            val: this.stateVal,
            value: '',
            type: 'select',
            validations: [{ rule: 'required', message: 'State is Required' }],
          },

          {
            label: 'City',
            name: 'city',
            value: '',
            type: 'text',
            validations: [{ rule: 'required', message: 'City is Required' }],
          },

          {
            label: 'Zip',
            name: 'zip',
            value: '',
            type: 'number',
            validations: [
              { rule: 'required', message: 'Zip is Required' },
              {
                rule: 'maxLength',
                value: 5,
                message: 'Please enter the valid Zip ID',
              },
            ],
          },

          {
            label: 'Password',
            name: 'password',
            type: 'password',
            passwordflag: true,
            value: '',

            validations: [
              {
                rule: 'required',
                message:
                  'Your provided Password must contain at-least one Uppercase letter, one Lower case letter, and one Number.',
              },
              {
                rule: 'pattern',
                value: this.passwordregex,
                message: 'Must contain a Capital Letter and a Number',
              },
            ],
          },

          {
            label: 'Confirm Password',
            name: 'confirmpassword',
            type: 'password',
            passwordflag: false,
            value: '',
            validations: [
              {
                rule: 'required',
                message:
                  'Your provided Password must contain at-least one Uppercase letter, one Lower case letter, and one Number.',
              },
              {
                rule: 'match',
                message: 'Confirm Password field Needs to  match Password',
              },
              {
                rule: 'pattern',
                value: this.passwordregex,
                message: 'Must contain a Capital Letter and a Number',
              },
            ],
          },
          {
            label: 'Products',
            name: 'productsarray',
            val: this.productVal,
            type: 'select',
            multiple: true,
            validations: [
              { rule: 'required', message: 'Products  is Required' },
            ],
          },
          {
            label: 'Select Distributor',
            name: 'parentid',
            value: this.userservice.userid,
            type: 'hidden',

          },
          {
            label: 'Active',
            name: 'status',
            hint: '',
            type: 'checkbox',
            val: this.status,
            value: 0,
          },
        ],
      };
    }
    if (this.userservice.type != "distributor") {
      console.log('inelsepart');
      this.formdata = {
        successmessage: 'Added Successfully !!',
        redirectpath: 'admin/list',
        submitactive: true, // optional, default true
        submittext: 'Submit',
        resettext: 'Reset',
        canceltext: 'Cancel',
        apiUrl: this.apiservice.api_url,
        endpoint: 'api/addusers', //
        jwttoken: '',
        cancelroute: 'admin/list',

        fields: [
          {
            label: 'First Name',
            name: 'firstname', //
            value: '',
            type: 'text',
            validations: [
              { rule: 'required', message: 'First Name is Required' },
              // {
              //   rule: 'pattern',
              //   value: this.textregex,
              //   message: 'only use alphabet',
              // },
            ],
          },

          {
            heading: '',
            label: 'Last Name',
            name: 'lastname',
            value: '',
            type: 'text',
            validations: [
              { rule: 'required', message: 'Last Name is Required' },
              // {
              //   rule: 'pattern',
              //   value: this.textregex,
              //   message: 'only use alphabet',
              // },
            ],
          },



          {
            label: 'Telephone',
            name: 'phone',
            value: '',
            type: 'number',
            validations: [
              { rule: 'required', message: 'Telephone is Required' },
              {
                rule: 'minLength',
                value: 10,
                message: 'The Phone Number must be 10 digits',
              },
              {
                rule: 'maxLength',
                value: 15,
                message: 'Please enter the valid number',
              },
            ],
          },




          {
            label: 'Email',
            name: 'email',
            type: 'email',
            hint: '',

            validations: [
              { rule: 'required', message: 'Email is required' },
              {
                rule: 'pattern',
                value: this.emailregex,
                message: 'Must be a valid Email',
              },
            ],
          },
          {
            label: 'Company Name',
            name: 'company',
            value: '',
            type: 'text',
            validations: [{ rule: 'required', message: 'Company Name is Required' }],
          },

          {
            label: 'Address',
            name: 'address',
            value: '',
            type: 'textarea',
            validations: [{ rule: 'required', message: 'Address is Required' }],
          },


          {
            label: 'type',
            name: 'type',
            type: 'hidden',
            value: 'closer',
          },
          {
            label: 'State',
            name: 'state',
            val: this.stateVal,
            value: '',
            type: 'select',
            validations: [{ rule: 'required', message: 'State is Required' }],
          },

          {
            label: 'City',
            name: 'city',
            value: '',
            type: 'text',
            validations: [{ rule: 'required', message: 'City is Required' }],
          },

          {
            label: 'Zip',
            name: 'zip',
            value: '',
            type: 'number',
            validations: [
              { rule: 'required', message: 'Zip is Required' },
              {
                rule: 'maxLength',
                value: 5,
                message: 'Please enter the valid Zip ID',
              },
            ],
          },

          {
            label: 'Password',
            name: 'password',
            type: 'password',
            passwordflag: true,
            value: '',

            validations: [
              {
                rule: 'required',
                message:
                  'Your provided Password must contain at-least one Uppercase letter, one Lower case letter, and one Number.',
              },
              {
                rule: 'pattern',
                value: this.passwordregex,
                message: 'Must contain a Capital Letter and a Number',
              },
            ],
          },

          {
            label: 'Confirm Password',
            name: 'confirmpassword',
            type: 'password',
            passwordflag: false,
            value: '',
            validations: [
              {
                rule: 'required',
                message:
                  'Your provided Password must contain at-least one Uppercase letter, one Lower case letter, and one Number.',
              },
              {
                rule: 'match',
                message: 'Confirm Password field Needs to  match Password',
              },
              {
                rule: 'pattern',
                value: this.passwordregex,
                message: 'Must contain a Capital Letter and a Number',
              },
            ],
          },
          {
            label: 'Products',
            name: 'productsarray',
            val: this.productVal,
            type: 'select',
            multiple: true,
            validations: [
              { rule: 'required', message: 'Products  is Required' },
            ],
          },
          {
            label: 'Distributor',
            name: 'parentid',
            val: this.typeVal,
            type: 'select',

          },
          {
            label: 'Active',
            name: 'status',
            hint: '',
            type: 'checkbox',
            val: this.status,
            value: 0,
          },
        ],
      };
    }
    if (this.activatedRoute.snapshot.routeConfig.path == 'closer/add') {

      this.formdata.cancelroute = 'admin/list';
      this.formdata.redirectpath = 'admin/list';
    }

    if (this.activatedRoute.snapshot.params._id != null) {
      this.flag = true;
      this.editcloser();
    }
    if (this.userservice.type == "distributor") {
      this.formdata.cancelroute = 'distributor/users';
      this.formdata.redirectpath = 'distributor/users';
    }
  }
  userselection() {
    this.apiservice.getDataWithoutToken({
      "type": "distributor"
    },
      'api/alltypeusers')
      .subscribe((response: any) => {
        console.log(response);

        // this.productVal=response.res
        // // console.log(this.productVal,'madhumitaaaaa')
        for (const i in response.results.res) {
          if (Object.prototype.hasOwnProperty.call(response.results.res, i)) {
            this.typeVal.push({ val: response.results.res[i]._id, name: response.results.res[i].fullname });
          }
        }
      });
  }

  ngOnInit() {
    if (this.userservice.type == 'admin') {
      this.formdata.cancelroute = 'admin/list';
      this.formdata.redirectpath = 'admin/list';
    }
  }
  editcloser() {
    this.userselection();
    if (this.activatedRoute.snapshot.params._id != null) {
      this.activatedRoute.data.subscribe((res: any) => {
        // console.log(res.resolvedata.result, '+++++++++++++++');
        this.userData = res.resolvedata.result[0];
        this.resolveval = this.userData;
        if (this.userservice.type == 'distributor') {
          this.formdata = {
            successmessage: 'Edit Successfully !!',
            redirectpath: 'distributor/users',
            submitactive: true, // optional, default true
            submittext: 'Update',
            resettext: 'Reset',
            canceltext: 'Cancel',
            apiUrl: this.apiservice.api_url,
            endpoint: 'api/addusers', //
            jwttoken: this.apiservice.jwttoken,
            cancelroute: 'distributor/users',

            fields: [
              {
                label: 'First Name',
                name: 'firstname',
                value: this.resolveval.firstname,
                type: 'text',
                // parttern:/^[a-zA-Z\s]*$/,
                validations: [
                  { rule: 'required', message: 'First Name is Required' },
                  // {
                  //   rule: 'pattern',
                  //   value: this.textregex,
                  //   message: 'only use alphabet',
                  // },
                ],
              },

              {
                heading: '',
                label: 'Last Name',
                name: 'lastname',
                value: this.resolveval.lastname,
                type: 'text',
                validations: [
                  { rule: 'required', message: 'Last Name is Required' },
                  // {
                  //   rule: 'pattern',
                  //   value: this.textregex,
                  //   message: 'only use alphabet',
                  // },
                ],
              },

              {
                label: 'Telephone',
                name: 'phone',
                value: this.resolveval.phone,
                type: 'number',
                validations: [
                  { rule: 'required', message: 'Telephone is Required' },
                  {
                    rule: 'minLength',
                    value: 10,
                    message: 'The Phone Number must be 10 digits',
                  },
                  {
                    rule: 'maxLength',
                    value: 15,
                    message: 'Please enter the valid number',
                  },
                ],
              },



              {
                label: 'Email',
                name: 'email',
                type: 'email',
                disabled: true,
                hint: '',
                value: this.resolveval.email,
                //  disabled: true,
                validations: [
                  { rule: 'required', message: 'Email is required' },
                  {
                    rule: 'pattern',
                    value: this.emailregex,
                    message: 'Must be a valid Email',
                  },
                ],
              },

              {
                label: 'Company Name',
                name: 'company',
                value: this.resolveval.company,
                type: 'text',
              },
              {
                label: 'Address',
                name: 'address',
                value: this.resolveval.address,
                type: 'textarea',
                validations: [
                  { rule: 'required', message: 'Address is Required' },
                ],
              },
              {
                label: 'type',
                name: 'type',
                type: 'hidden',
                value: 'closer',
              },
              {
                label: 'State',
                name: 'state',
                val: this.stateVal,
                value: this.resolveval.state,
                type: 'select',
                validations: [{ message: 'State is Required' }],
              },

              {
                label: 'City',
                name: 'city',
                value: this.resolveval.city,
                type: 'text',
                validations: [{ rule: 'required', message: 'City is Required' }],
              },

              {
                label: 'Zip',
                name: 'zip',
                value: this.resolveval.zip,
                type: 'number',
                validations: [
                  { rule: 'required', message: 'Zip is Required' },
                  {
                    rule: 'maxLength',
                    value: 5,
                    message: 'Please enter the valid Zip ID',
                  },
                ],
              },




              {
                label: 'Select Distributor',
                name: 'distributor',
                value: this.userservice.userid,
                type: 'hidden',
                // validations: [
                //   { rule: 'required', message: 'Distributor is Required' },
                // ],
              },

              {
                label: 'Active',
                name: 'status',
                hint: '',
                type: 'checkbox',
                value: this.resolveval.status,
              },

              {
                label: 'id',
                name: 'id',
                value: this.activatedRoute.snapshot.params._id,
                type: 'hidden',
              },
            ],
          };
        }
        else {
          this.formdata = {
            successmessage: 'Edit Successfully !!',
            redirectpath: 'admin/list',
            submitactive: true, // optional, default true
            submittext: 'Update',
            resettext: 'Reset',
            canceltext: 'Cancel',
            apiUrl: this.apiservice.api_url,
            endpoint: 'api/addusers', //
            jwttoken: this.apiservice.jwttoken,
            cancelroute: 'admin/list',

            fields: [
              {
                label: 'First Name',
                name: 'firstname',
                value: this.resolveval.firstname,
                type: 'text',
                // parttern:/^[a-zA-Z\s]*$/,
                validations: [
                  { rule: 'required', message: 'First Name is Required' },
                  // {
                  //   rule: 'pattern',
                  //   value: this.textregex,
                  //   message: 'only use alphabet',
                  // },
                ],
              },

              {
                heading: '',
                label: 'Last Name',
                name: 'lastname',
                value: this.resolveval.lastname,
                type: 'text',
                validations: [
                  { rule: 'required', message: 'Last Name is Required' },
                  // {
                  //   rule: 'pattern',
                  //   value: this.textregex,
                  //   message: 'only use alphabet',
                  // },
                ],
              },

              {
                label: 'Telephone',
                name: 'phone',
                value: this.resolveval.phone,
                type: 'number',
                validations: [
                  { rule: 'required', message: 'Telephone is Required' },
                  {
                    rule: 'minLength',
                    value: 10,
                    message: 'The Phone Number must be 10 digits',
                  },
                  {
                    rule: 'maxLength',
                    value: 15,
                    message: 'Please enter the valid number',
                  },
                ],
              },


              {
                label: 'Email',
                name: 'email',
                type: 'email',
                disabled: true,
                hint: '',
                value: this.resolveval.email,
                //  disabled: true,
                validations: [
                  { rule: 'required', message: 'Email is required' },
                  {
                    rule: 'pattern',
                    value: this.emailregex,
                    message: 'Must be a valid Email',
                  },
                ],
              },

              {
                label: 'Company Name',
                name: 'company',
                value: this.resolveval.company,
                type: 'text',
              },
              {
                label: 'Address',
                name: 'address',
                value: this.resolveval.address,
                type: 'textarea',
                validations: [
                  { rule: 'required', message: 'Address is Required' },
                ],
              },
              {
                label: 'type',
                name: 'type',
                type: 'hidden',
                value: 'closer',
              },
              {
                label: 'State',
                name: 'state',
                val: this.stateVal,
                value: this.resolveval.state,
                type: 'select',
                validations: [{ message: 'State is Required' }],
              },

              {
                label: 'City',
                name: 'city',
                value: this.resolveval.city,
                type: 'text',
                validations: [{ rule: 'required', message: 'City is Required' }],
              },

              {
                label: 'Zip',
                name: 'zip',
                value: this.resolveval.zip,
                type: 'number',
                validations: [
                  { rule: 'required', message: 'Zip is Required' },
                  {
                    rule: 'maxLength',
                    value: 5,
                    message: 'Please enter the valid Zip ID',
                  },
                ],
              },

              {
                label: 'Products',
                name: 'productsarray',
                val: this.productVal,
                value: this.resolveval.productsarray,
                type: 'select',
                multiple: true,
                validations: [
                  { rule: 'required', message: 'Products  is Required' },
                ],
              },


              {
                label: 'Distributor',
                name: 'parentid',
                val: this.typeVal,
                type: 'select',
                value: this.resolveval.parentid,
                validations: [
                  { rule: 'required', message: 'Distributor is Required' },
                ],
              },

              {
                label: 'Active',
                name: 'status',
                hint: '',
                type: 'checkbox',
                value: this.resolveval.status,
              },

              {
                label: 'id',
                name: 'id',
                value: this.activatedRoute.snapshot.params._id,
                type: 'hidden',
              },
            ],
          };
        }

      });
    }
  }
  getproductlist() {
    this.apiservice.getDataWithoutToken({
      token: this.token,
      secret: this.secret
    },
      'api/productsearch')
      .subscribe((response: any) => {
        // this.productVal=response.res
        // // console.log(this.productVal,'madhumitaaaaa')
        for (const i in response.res) {
          if (Object.prototype.hasOwnProperty.call(response.res, i)) {
            this.productVal.push({ val: response.res[i].val, name: response.res[i].name });
          }
        }
      });
  }
}
