import { HttpClient } from '@angular/common/http';
import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MatSnackBar, MAT_DIALOG_DATA } from '@angular/material';
import { ActivatedRoute, Router } from '@angular/router';
// import { DialogData } from 'login-lib-influxiq/lib/sign-up/sign-up.component';
import { CookieService } from 'ngx-cookie-service';
import { ApiService } from 'src/app/services/api.service';
import { UserserviceService } from 'src/app/services/userservice.service';

export interface DialogData {
  data: any;
  editorconfig: any;
  topcontract: any;
  custombuttonclick: any;
}

@Component({
  selector: 'app-product-list',
  templateUrl: './product-list.component.html',
  styleUrls: ['./product-list.component.css']
})
export class ProductListComponent implements OnInit {
  productlisttable: any = [];

  public status: any = [
    { val: 1, name: 'Active' },
    { val: 0, name: 'Inactive' },
  ];
  public launched: any = [
    { val: true, name: 'Yes' },
    { val: null, name: 'No' },
  ];

  // use for status search
  statusarray: any = [
    { val: 1, name: 'Active' },
    { val: 0, name: 'Inactive' },
  ];

  // editrouting
  editroute: any = 'resources/product/edit/';

  // modify header(key : modify header name)
  modifyheaderarray: any = {
    image: 'Logo',
    productname: 'Name',
    launcheddate: 'Launch Date',
    launched: 'Launched',
    createdon_datetime: 'Added on',
    status: 'Status',
  };

  // use for table field skip
  productlisttableskip: any = [];

  // use for Table Detail Field Skip
  productlisttabledetailskip: any = ['_id', 'productname_s', 'image', 'status', 'commisonsugggetions'];

  // updateendpoint is use for data update endpoint
  updateendpoint = 'api/addproducte';

  // deleteendpoint is use for data delete endpoint
  deleteendpoint = 'api/deleteproduct';

  // this is a database collection name
  tablename = 'users';

  // searchendpoint is use for data search endpoint
  searchendpoint = '';

  // use for click to another page routing path
  clicktoaddananotherpage = '';

  // date_search_endpoint is use for date search endpoint
  datesearchendpoint: any = '';

  // send basic limit data
  limitcond: any = {
    limit: 10,
    skip: 0,
  };
  // other data
  libdata: any = {

    preview_header: {
      header: "Preview Data for Details",
      class: 'preheadercls'
  },
    // basecondition: { type: '' },
    detailview_override: [
      { key: 'productname', val: 'Product Name' },
      { key: 'description', val: 'Description' },
      { key: 'launcheddate', val: 'Launche Date' },
      { key: 'status', val: 'Status' },
      { key: 'createdon_datetime', val: 'Added on' },
    ],
    tableheaders: [
      'image',
      'productname',
      'launcheddate',
      'launched',
      'createdon_datetime',
      'status',
    ],
    // searchBarFlagVal:true,
    // tableheaders name [key]
    // optional
    updateendpointmany: 'api/productupdate',
    updateendpoint: 'api/productstatusupdate',
    deleteendpointmany: 'api/deleteproduct',
    hidemultipleselectbutton: null,
    hidecounter: null,
    hideaction: null,
    hidestatustogglebutton: false,                  // (hide status toggle button)
    hideviewbutton: false,                          // (hide view button)
    // actioncolname: 'Actn',
    custombuttons: [
      {
        label: "Add Commison Suggetions",
        type: "listner",
        id: "i1",
        classname: "addcommison",
        tooltip: "Add Commison Suggetions",
      },
      {
        label: "Commission View",
        type: "listner",
        id: "i1",
        classname: "viewcommison",
        tooltip: "Commission View",
      }
    ],
  };

  // send basic sort data
  sortdata: any = {
    type: 'desc',
    field: 'productname',
    options: [
      'launcheddate',
      'status',
      'productname'
    ],
  };

  // this is a database collection or view name
  // datesearchsource: any = 'admin_blog_list';
  // datacollection
  datacollection: any = 'api/productlistview';
  // source count
  datesearchsourcecount: any = 0;

  searchsettings: any = {


    datesearch: [
      {
        startdatelabel: 'Added on start Date',
        enddatelabel: 'Added On End Date',
        submit: 'Search',
        field: 'createdon_datetime',
      },
    ],
    selectsearch: [
      { label: 'Search By Status', field: 'status', values: this.status },
    ],
    textsearch: [
      { label: 'Search By Name', field: 'productname_s' },
      // { label:'Search By Email', field:'verifiedemail'}
    ],
  };

  public userid: any;
  public userFullName: any;
  constructor(
    public router: Router,
    public Apiservice: ApiService,
    public http: HttpClient,
    public cookieservice: CookieService,
    public activatedRoute: ActivatedRoute,
    public userservice: UserserviceService,
    public dialog: MatDialog
  ) {
    this.userid = this.userservice.userid;
    this.userFullName =
      this.userservice.firstname +
      ' ' +
      this.userservice.lastname; // user dtls ( cookieservice)
    const endpointc = 'api/productlistview-count';
    const endpoint = 'api/productlistview';
    const data: any = { // How many data showing in first page
      secret: this.userservice.secret,
      token: this.userservice.jwttoken,
      source: '',
      condition: {  // condition for how much data show in one time
        limit: 10,
        skip: 0,
      },
      sort: {
        type: 'desc',  // data sort by decreasing order
        field: '_id',  // data sort by first name
      },
    };

    this.Apiservice
      .getDataWithoutToken(data, endpointc)
      .subscribe((res: any) => {
        this.datesearchsourcecount = res.count; // sarch count  (How many data there)
      });
  }

  ngOnInit() {
    this.activatedRoute.data.forEach((res: any) => {
      this.productlisttable = res.resolvedata.results.res; // data store from backend
      // console.log(this.productlisttable, 'madhumita khutia');
    });
  }

  onLiblistingButtonChange(val: any) {
    console.log(val);
    if (val.custombuttonclick.btninfo.label == 'Add Commison Suggetions') {
      const dialogRef = this.dialog.open(Addcommison, {
        // panelClass: "AddUserComponent",
        height: '500px',
        width: '500px',
        data: val
      });
      dialogRef.afterClosed().subscribe((result: any) => {
        // console.log(result,'tttttttttttttttt')
        const endpoint = 'api/productlistview';
        const data: any = { // How many data showing in first page
          secret: this.userservice.secret,
          token: this.userservice.jwttoken,
          source: '',
          condition: {  // condition for how much data show in one time
            limit: 10,
            skip: 0,
          },
          sort: {
            type: 'desc',  // data sort by decreasing order
            field: '_id',  // data sort by first name
          },
        };

        this.Apiservice
          .getDataWithoutToken(data, endpoint)
          .subscribe((res: any) => {
            this.productlisttable = [];
            setTimeout(() => {
              this.productlisttable = res.results.res; // sarch count  (How many data there)

            }, 50);
          });

      })
    }
    if (val.custombuttonclick.btninfo.label == 'Commission View') {
      // console.log("Commission View******************", val.custombuttonclick.data.commisonsugggetions.commisson);
      const dialogRef = this.dialog.open(CommissionViewDialogOverviewExampleDialog, {
        width: '500px',
        data: { data: val.custombuttonclick.data.commisonsugggetions }
      });

      dialogRef.afterClosed().subscribe(result => {
        console.log('The dialog was closed');

      });
    }

  }

}


@Component({
  selector: "adduser",
  templateUrl: "addcommison.html",
})
export class Addcommison {

  public editorconfig: any = {};
  public topcontract: any;
  public formdata: any;
  public ckeConfig: any = {
    toolbar: [
      {
        name: "editing",
        items: ["Scayt", "Find", "Replace", "SelectAll"]
      },
      {
        name: "clipboard",
        items: [
          "Cut",
        ]
      },
      {
        name: "links",
        items: [
          "Link",
        ]
      },
      { name: "document", items: ["Source"] },
      {
        name: "insert",
        items: [
          "Image",
        ]
      },
      "/",
      {
        name: "basicstyles",
        items: [
          "Bold",
          "Italic",
          "Underline",
        ]
      },

      {
        name: "styles",
        items: ["Styles", "Format", "FontSize", "-", "TextColor", "BGColor"]
      }
    ]
  }
  constructor(
    public dialogRef: MatDialogRef<Addcommison>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    public apiservice: ApiService,
    public activatedroute: ActivatedRoute,
    public userservice: UserserviceService,
    public snackBar: MatSnackBar,
  ) {
    console.log(data);

    this.formdata = {
      successmessage: 'Added Successfully !!',
      submittext: 'Submit',
      canceltext: 'Cancel',
      fields: [
        {
          label: 'Commison Suggestions',
          name: 'commisonsugggetions',
          value: '',
          type: 'editor',
          validations: [
            { rule: 'required', message: ' Commison Suggestions is required' },
          ],
          ckeConfig: this.ckeConfig
        },

      ],
    };
  }
  listenFormFieldChange(val) {
    console.log(val);
    if (val.field == "fromsubmit") {
      let data = {
        secret: this.userservice.secret,
        token: this.userservice.jwttoken,
        id: this.data.custombuttonclick.data._id,
        // productid:_id
        commisonsugggetions: { commisson: val.fromval.commisonsugggetions, productid: this.data.custombuttonclick.data._id, userid: this.userservice.userid, time: Math.round((new Date()).getTime()) }
      }
      console.log(data);

      this.apiservice
        .getDataWithoutToken(data, 'api/add-commison')
        .subscribe((res: any) => {
          if (res.status == 'success') {
            this.snackBar.open('Commition suggetion added Succesfully', 'ok', {
              duration: 3000,
            });
            this.dialogRef.close();
          }
        });
    } if (val.field == "formcancel") {
      this.dialogRef.close();
    }


  }

}
@Component({
  selector: 'commission-view-example-dialog',
  templateUrl: 'commission-view-example-dialog.html',
  styleUrls: ['./product-list.component.css']
})
export class CommissionViewDialogOverviewExampleDialog {
  public commissiondata: any = '';
  constructor(
    public dialogRef: MatDialogRef<CommissionViewDialogOverviewExampleDialog>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData) {
    console.log(data);

    this.commissiondata = data.data;
    // console.log(this.commissiondata);

  }

  onNoClick(): void {
    this.dialogRef.close();
  }

}