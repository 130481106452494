import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { ApiService } from 'src/app/services/api.service';

@Component({
  selector: 'app-category-add-edit',
  templateUrl: './add-edit.component.html',
  styleUrls: ['./add-edit.component.css']
})
export class CategoryAddEditComponent implements OnInit {
  textregex: RegExp = /^[a-zA-Z]*$/;

  public flag: any;
  public formdata: any;
  public stateVal: any = [];
  public status: any = [
    { val: 1, name: 'Active' },
    { val: 0, name: 'Inactive' },
  ];
  public userData: any = [];
  public parentCategory: any = [];
  public resolveval: any;
  public endpointc: any = 'api1/getallvideodcategorydata'
  constructor(public apiservice: ApiService, public http: HttpClient, public activatedroute: ActivatedRoute, public cookies: CookieService, public router: Router) {

    let data = {

      "condition": {
        "status": 1
      },
      "sort": {
        "type": "asc",
        "limit": 50
      }

    }
    this.apiservice
      .getDataWithoutToken(data, this.endpointc)
      .subscribe((res: any) => {
        // console.log(res.results.res, 'nnnnnnnnnnnnnnnnnn');
        for (const key in res.results.res) {
          this.parentCategory.push({ val: res.results.res[key]._id, name: res.results.res[key].category_name })

        }

      });

    this.formdata = {
      successmessage: 'Added Successfully !!',
      redirectpath: '/video-management/listing',
      submitactive: true, // optional, default true
      submittext: 'Submit',
      resettext: 'Reset',
      canceltext: 'Cancel',
      apiUrl: this.apiservice.api_url,
      endpoint: 'api1/addorupdatevideocategory', //
      jwttoken: '',
      cancelroute: '/video-management/listing',

      fields: [
        {
          label: 'Title',
          name: 'category_name', //
          value: '',
          type: 'text',
          validations: [
            { rule: 'required', message: 'Title is Required' },
            // {
            //   rule: 'pattern',
            //   value: this.textregex,
            //   message: 'only use alphabet',
            // },
          ],
        },

        {

          label: 'Priority',
          name: 'category_priority',
          value: '',
          type: 'number',
          validations: [
            { rule: 'required', message: 'Priority is Required' },

          ],
        },
        {
          label: 'Description',
          name: 'category_description',
          value: '',
          type: 'editor',
          validations: [{ rule: 'required', message: 'Description is Required' }],
        },

        {
          label: 'Parent Category',
          name: 'parent_category_id',
          val: this.parentCategory,
          value: '',
          type: 'select',
          // validations: [{ rule: 'required', message: 'Parent Category is Required' }],
        },
        // {
        //   label: 'Product',
        //   name: 'product_id',
        //   val: this.product_category,
        //   value: '',
        //   type: 'select',
        //   validations: [{ rule: 'required', message: 'Parent Category is Required' }],
        // },
        {
          label: 'Active',
          name: 'status',
          hint: '',
          type: 'checkbox',
          val: this.status,
          value: 0,
        },
      ],
    };


  }
  ngOnInit() {
    this.editresourcescategory()
  }
  editresourcescategory() {
    if (this.activatedroute.snapshot.params._id != null) {
      this.activatedroute.data.subscribe((res: any) => {
        this.userData=res.resolvedata.res[0];
        // console.log(this.userData.category_name, '++++++++++++++++++++++++++++++++');
        this.formdata = {
          successmessage: 'Updated Successfully !!',
          redirectpath: '/video-management/listing',
          submitactive: true, // optional, default true
          submittext: 'Update',
          resettext: 'Reset',
          canceltext: 'Cancel',
          apiUrl: this.apiservice.api_url,
          endpoint: 'api1/addorupdatevideocategory', //
          jwttoken: '',
          cancelroute: '/video-management/listing',
    
          fields: [
            {
              label: 'Title',
              name: 'category_name', //
              value: this.userData.category_name,
              type: 'text',
              validations: [
                { rule: 'required', message: 'Title is Required' },
                 // {
                //   rule: 'pattern',
                //   value: this.textregex,
                //   message: 'only use alphabet',
                // },
              ],
            },
    
            {
    
              label: 'Priority',
              name: 'category_priority',
              value: this.userData.category_priority,
              type: 'number',
              validations: [
                { rule: 'required', message: 'Priority is Required' },
    
              ],
            },
            {
              label: 'Description',
              name: 'category_description',
              value: this.userData.category_description,
              type: 'editor',
              validations: [{ rule: 'required', message: 'Description is Required' }],
            },
    
            {
              label: 'Parent Category',
              name: 'parent_category_id',
              val: this.parentCategory,
              value: this.userData.parent_category_id,
              type: 'select',
              validations: [{ rule: 'required', message: 'Parent Category is Required' }],
            },
            {
              label: 'Active',
              name: 'status',
              hint: '',
              type: 'checkbox',
              val: this.status,
              value:this.userData.status ,
            },
            {
              name: 'id',
              type: 'hidden',
              value: this.activatedroute.snapshot.params._id,
            },
          ],
        };
      })
    }
  }
}
