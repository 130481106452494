import { HttpClient } from '@angular/common/http';
import { Component, Inject, OnInit } from '@angular/core';
import {
  MatBottomSheet,
  MatBottomSheetRef,
  MatDialog,
  MatDialogRef,
  MatSnackBar,
  MAT_BOTTOM_SHEET_DATA,
  MAT_DIALOG_DATA,
} from '@angular/material';
import { ActivatedRoute, Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { ApiService } from 'src/app/services/api.service';
// import { UserserviceService } from 'src/app/services/userservice.service';

export interface DialogData {
  data: any;
}
export interface DialogData1 {
  data: any;
}

@Component({
  selector: 'app-leadcontract',
  templateUrl: './leadcontract.component.html',
  styleUrls: ['./leadcontract.component.css'],
})
export class LeadcontractComponent implements OnInit {
  public contactInfo: any;
  public congratsflag: boolean;
  public modificationflag: boolean;
  constructor(
    public activatedroute: ActivatedRoute,
    // public userservice: UserserviceService,
    public router: Router,
    public apiservice: ApiService,
    public http: HttpClient,
    public cookieservice: CookieService,
    public bottomSheet: MatBottomSheet,
    private _snackBar: MatSnackBar,
    public dialog: MatDialog
  ) { }

  ngOnInit() {
    // this.activatedroute.data.forEach((res: any) => {
    //   console.log(res.resolvedata.result[0], "adminlist...");
    //   this.contactInfo = res.resolvedata.result[0];
    // });
    // console.log(this.activatedroute.snapshot.params);

    this.apiservice
      .getDataWithoutToken(
        {
          condition: {
            leadid: this.activatedroute.snapshot.params.leadid,
            contractid: this.activatedroute.snapshot.params.contractid,
          },
        },
        'api/contractdata'
      )
      .subscribe((res: any) => {
        if (res.status === 'success') {
          this.contactInfo = res.result[0];
          // this.congratsflag = true;
          // this._snackBar.open("Contract Singed", "ok");
        }
      });
  }
  singedcontract(val) {
    const dialogRef = this.dialog.open(LeadsignmodalComponent, {
      data: val,
      panelClass: 'signmodal',
    });
    dialogRef.disableClose = true;
    dialogRef.afterClosed().subscribe((result) => {
      console.log('The dialog was closed', result);
      const data = result;
      (data.status = {
        satatus: 'Contract Signed',
        addedby: result.sing.singed,
        updated_datetime: Math.round(new Date().getTime()),
      }),
        (data.requestid = this.activatedroute.snapshot.params.contract_id);

      console.log(data);

      this.apiservice
        .getDataWithoutToken(data, 'api/updatecontract')
        .subscribe((res: any) => {
          if (res.status == 'success') {
            this.congratsflag = true;
            this._snackBar.open('Contract Singed', 'ok');
          }
        });
    });
  }
  declined(val) {
    console.log(val);
    const dialogRef = this.dialog.open(MessagemodalComponent, {
      data: val,
      panelClass: 'signmodal',
    });
    dialogRef.disableClose = true;
    dialogRef.afterClosed().subscribe((result) => {
      console.log('The dialog was closed', result);
      const data = result;
      data.status = {
        satatus: 'Contract Declined',
        updated_datetime: Math.round(new Date().getTime()),
      };
      data.requestid = this.activatedroute.snapshot.params.contract_id;
      // console.log(data);

      this.apiservice
        .getDataWithoutToken(data, 'api/updatecontract')
        .subscribe((res: any) => {
          if (res.status === 'success') {
            this._snackBar.open('Contract Declined', 'ok');
            this.modificationflag = true;
          }
        });
    });

    // }
  }
  modifiedcontract(val) {
    const dialogRef = this.dialog.open(MessagemodalComponent, {
      data: val,
      panelClass: 'signmodal',
    });
    dialogRef.disableClose = true;
    dialogRef.afterClosed().subscribe((result) => {
      console.log('The dialog was closed', result);
      const data = result;
      (data.status = {
        satatus: 'Lead Request For Contract Modify',
        updated_datetime: Math.round(new Date().getTime()),
      }),
        (data.requestid = this.activatedroute.snapshot.params.contract_id);
      // console.log(data);

      this.apiservice
        .getDataWithoutToken(data, 'api/updatecontract')
        .subscribe((res: any) => {
          if (res.status === 'success') {
            this._snackBar.open('Contract Request Modify sucessfull', 'ok');
            this.modificationflag = true;
          }
        });
    });
  }
}

@Component({
  selector: 'signmodal-component',
  templateUrl: 'leadsignmodal.html',
})
export class LeadsignmodalComponent {
  public date = Date.now();
  public errorflag = false;
  public singed: any;
  public data1: any;
  public congratsflag = false;
  constructor(
    public dialogRef: MatDialogRef<LeadsignmodalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData
  ) {
    this.data1 = this.data;
  }

  onNoClick(val: any): void {
    if (val === '') {
      this.errorflag = true;
    } else {
      this.data1.sing = { singed: this.singed, time: this.date };

      console.log(this.data, '+++++', this.singed);
      this.errorflag = false;
      this.dialogRef.close(this.data);
    }
  }
}

@Component({
  selector: 'Messagemodal-Component',
  templateUrl: 'messageDilog.html',
})
export class MessagemodalComponent {
  public date = Date.now();
  public errorflag = false;
  public singed: any;
  public data1: any;
  public message_data: any;
  constructor(
    public dialogRef: MatDialogRef<MessagemodalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData1
  ) {
    this.data1 = this.data;
  }

  onNoClick(): void {
    console.log(this.message_data);
    if (
      this.message_data != null &&
      typeof this.message_data !== 'undefined' &&
      this.message_data !== ''
    ) {
      this.data1.message = this.message_data;
      // console.log(this.data1);
      this.dialogRef.close(this.data1);
    } else {
      this.errorflag = true;
    }
  }
}
