import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';


@Component({
  selector: 'app-sales-opportunity-overview',
  templateUrl: './sales-opportunity-overview.component.html',
  styleUrls: ['./sales-opportunity-overview.component.css']
})
export class SalesOpportunityOverviewComponent implements OnInit {
  public tab_id : any=1;
  public isShow:any={
    "firstblock":1,
    "secondblock":0,
    "thirdblock":0,
    "forthblock":0,
    "fifthblock":0,
    "sixthblock":0,
  }

  constructor(public ActivatedRoute: ActivatedRoute) { }

  ngOnInit() {
  }
  scrollToTop(){
    (function smoothscroll() {
      var currentScroll = document.documentElement.scrollTop || document.body.scrollTop;  
      if (currentScroll > 0) {
          window.requestAnimationFrame(smoothscroll);
          window.scrollTo(0, currentScroll - (currentScroll / 8));
      }
  })();      
  }
scroll(elem: string){
  if(elem =='#csoo'){
        this.tab_id = 1; 
        this.isShow.firstblock=1;   
        this.isShow.secondblock=0;   
        this.isShow.thirdblock=0; 
        this.isShow.forthblock=0; 
        this.isShow.fifthblock=0; 
        this.isShow.sixthblock=0; 
        this.scrollToTop();
        
      }
      else if(elem =='#csor'){
        this.tab_id = 2;  
        this.isShow.secondblock=1;  
        this.isShow.firstblock=0;   
        this.isShow.thirdblock=0;  
        this.isShow.forthblock=0;  
        this.isShow.fifthblock=0;  
        this.isShow.sixthblock=0;  
        this.scrollToTop();
         
      }
      else if(elem =='#cpp'){
        this.tab_id = 3;
        this.isShow.thirdblock=1;  
        this.isShow.firstblock=0;   
        this.isShow.secondblock=0;   
        this.isShow.forthblock=0;   
        this.isShow.fifthblock=0;   
        this.isShow.sixthblock=0;   
        this.scrollToTop();
        
      }
      else if(elem =='#csp'){
        this.tab_id = 4;
        this.isShow.forthblock=1;  
        this.isShow.firstblock=0;   
        this.isShow.secondblock=0;   
        this.isShow.thirdblock=0;   
        this.isShow.fifthblock=0;   
        this.isShow.sixthblock=0;   
        this.scrollToTop();
        
      }
      else if(elem =='#bow'){
        this.tab_id = 5;
        this.isShow.fifthblock=1;  
        this.isShow.firstblock=0;   
        this.isShow.secondblock=0;   
        this.isShow.thirdblock=0;   
        this.isShow.forthblock=0;   
        this.isShow.sixthblock=0;   
        this.scrollToTop();
        
      }
      else if(elem =='#obw'){
        this.tab_id = 6;
        this.isShow.sixthblock=1;  
        this.isShow.firstblock=0;   
        this.isShow.secondblock=0;   
        this.isShow.thirdblock=0;   
        this.isShow.forthblock=0;   
        this.isShow.fifthblock=0;   
        this.scrollToTop();
        
      }
      else{
        this.tab_id = 1;      
      }
    

    }
  


}
