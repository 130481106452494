import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'checkedflag'
})
export class CheckedflagPipe implements PipeTransform {

  transform(val: string, limit: any) {
    console.log('+checkedflag', val.slice(0,200), limit);

    if (val.length>limit) {
      return val+ 'READ MORE'
    }
    return val
    // return arr.find(x=>x == val) == val;
  }

}
