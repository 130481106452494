import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { ApiService } from 'src/app/services/api.service';
import { UserserviceService } from 'src/app/services/userservice.service';

@Component({
  selector: 'app-sales-manager-add-edit',
  templateUrl: './sales-manager-add-edit.component.html',
  styleUrls: ['./sales-manager-add-edit.component.css']
})
export class SalesManagerAddEditComponent implements OnInit {

  emailregex: RegExp = /^(([^<>()\[\]\\.,;:\s@']+(\.[^<>()\[\]\\.,;:\s@']+)*)|('.+'))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  passwordregex: RegExp = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})/;
  textregex: RegExp = /^[a-zA-Z]*$/;

  temtdata: any = '';
  // formdata
  formfieldrefresh: any = false;
  updatetable: any = false;
  formfieldrefreshdata: any = null;
  datasource: any;
  public formdata: any;
  public stateVal: any = [];
  public status: any = [
    { val: 1, name: 'Active' },
    { val: 0, name: 'Inactive' },
  ];
  public parentidVal: any = [];
  public flag: any;
  public userData: any = [];
  public resolveval: any;
  constructor(
    public apiservice: ApiService,
    public http: HttpClient,
    public activatedRoute: ActivatedRoute,
    public cookies: CookieService,
    public router: Router,
    public userservice: UserserviceService

  ) {

    this.apiservice.getState().subscribe((res: any) => {
      for (const i in res) {
        if (Object.prototype.hasOwnProperty.call(res, i)) {
          this.stateVal.push({ val: res[i].abbreviation, name: res[i].name });
        }
      }
    });
    this.formdata = {
      successmessage: 'Added Successfully !!',
      redirectpath: 'sales-person/list',
      submitactive: true, // optional, default true
      submittext: 'Submit',
      resettext: 'Reset',
      canceltext: 'Cancel',
      apiUrl: this.apiservice.api_url,
      endpoint: 'api/addusers', //
      jwttoken: '',
      cancelroute: 'sales-person/list',

      fields: [
        {
          label: 'First Name',
          name: 'firstname', //
          value: '',
          type: 'text',
          validations: [
            { rule: 'required', message: 'First Name is Required' },
            {
              rule: 'pattern',
              value: this.textregex,
              message: 'only use alphabet',
            },
          ],
        },

        {
          heading: '',
          label: 'Last Name',
          name: 'lastname',
          value: '',
          type: 'text',
          validations: [
            { rule: 'required', message: 'Last Name is Required' },
            {
              rule: 'pattern',
              value: this.textregex,
              message: 'only use alphabet',
            },
          ],
        },

        {
          label: 'Telephone',
          name: 'phone',
          value: '',
          type: 'number',
          validations: [
            { rule: 'required', message: 'Telephone is Required' },
            {
              rule: 'minLength',
              value: 10,
              message: 'The Phone Number must be 10 digits',
            },
            {
              rule: 'maxLength',
              value: 15,
              message: 'Please enter the valid number',
            },
          ],
        },

        {
          label: 'Email',
          name: 'email',
          type: 'email',
          hint: '',

          validations: [
            { rule: 'required', message: 'Email is required' },
            {
              rule: 'pattern',
              value: this.emailregex,
              message: 'Must be a valid Email',
            },
          ],
        },
        {
          label: 'Company Name',
          name: 'company',
          type: 'text',
          validations: [
            { rule: 'required', message: 'Company Name is Required' },
          ],
        },
        {
          label: 'Address',
          name: 'address',
          value: '',
          type: 'textarea',
          validations: [{ rule: 'required', message: 'Address is Required' }],
        },
        {
          label: 'type',
          name: 'type',
          type: 'hidden',
          value: 'sales-person',
        },
        {
          label: 'State',
          name: 'state',
          val: this.stateVal,
          value: '',
          type: 'select',
          validations: [{ rule: 'required', message: 'State is Required' }],
        },

        {
          label: 'City',
          name: 'city',
          value: '',
          type: 'text',
          validations: [{ rule: 'required', message: 'City is Required' }],
        },

        {
          label: 'Zip',
          name: 'zip',
          value: '',
          type: 'number',
          validations: [
            { rule: 'required', message: 'Zip is Required' },
            {
              rule: 'maxLength',
              value: 5,
              message: 'Please enter the valid Zip ID',
            },
          ],
        },

        {
          label: 'Password',
          name: 'password',
          type: 'password',
          passwordflag: true,
          value: '',

          validations: [
            {
              rule: 'required',
              message:
                'Your provided Password must contain at-least one Uppercase letter, one Lower case letter, and one Number.',
            },
            {
              rule: 'pattern',
              value: this.passwordregex,
              message: 'Must contain a Capital Letter and a Number',
            },
          ],
        },

        {
          label: 'Confirm Password',
          name: 'confirmpassword',
          type: 'password',
          passwordflag: false,
          value: '',
          validations: [
            {
              rule: 'required',
              message:
                'Your provided Password must contain at-least one Uppercase letter, one Lower case letter, and one Number.',
            },
            {
              rule: 'match',
              message: 'Confirm Password field Needs to  match Password',
            },
            {
              rule: 'pattern',
              value: this.passwordregex,
              message: 'Must contain a Capital Letter and a Number',
            },
          ],
        },
        {
          label: "Parent name",
          name: "parentid",
          val: this.parentidVal,
          value: null,
          type: 'select',
          // validations: [
          //     { rule: 'required' }
          // ]
        },

        {
          label: 'Active',
          name: 'status',
          hint: '',
          type: 'checkbox',
          val: this.status,
          value: 0,
        },
      ],
    };
    if (this.activatedRoute.snapshot.routeConfig.path == 'sales-person/add') {

      this.formdata.cancelroute = 'admin/list';
      this.formdata.redirectpath = 'admin/list';
    }
    if (this.activatedRoute.snapshot.params._id != null) {
      this.flag = true;
      this.editsalesmanager();
    }
    setTimeout(() => {
      this.distributordata();
    }, 200);
  }

  ngOnInit() {
    if (this.userservice.type == 'admin') {
      this.formdata.cancelroute = 'admin/list';
      this.formdata.redirectpath = 'admin/list';
    }
  }


  distributordata() {
    const data = {
      secret: this.userservice.secret,
      token: this.userservice.jwttoken,
      type: 'distributor'
    };
    this.apiservice.getDataWithoutToken(data, 'api/getalluser').subscribe((res: any) => {
      for (let y in res.result) {
        if (Object.prototype.hasOwnProperty.call(res.result, y)) {
          this.parentidVal.push({ val: res.result[y]._id, name: res.result[y].firstname + ' ' + res.result[y].lastname });
        }
      }
    })


  }
  editsalesmanager() {
    if (this.activatedRoute.snapshot.params._id != null) {
      this.activatedRoute.data.subscribe((res: any) => {
        // console.log(res.resolvedata.result, '++++++++++++++++++++++++++++++++');
        this.userData = res.resolvedata.result[0];
        this.resolveval = this.userData;

        this.formdata = {
          successmessage: 'Edit Successfully !!',
          redirectpath: 'sales-person/list',
          submitactive: true, // optional, default true
          submittext: 'Update',
          resettext: 'Reset',
          canceltext: 'Cancel',
          apiUrl: this.apiservice.api_url,
          endpoint: 'api/addusers', //
          jwttoken: this.apiservice.jwttoken,
          cancelroute: 'sales-person/list',

          fields: [
            {
              label: 'First Name',
              name: 'firstname',
              value: this.resolveval.firstname,
              type: 'text',
              // parttern:/^[a-zA-Z\s]*$/,
              validations: [
                { rule: 'required', message: 'First Name is Required' },
                 // {
                //   rule: 'pattern',
                //   value: this.textregex,
                //   message: 'only use alphabet',
                // },
              ],
            },

            {
              heading: '',
              label: 'Last Name',
              name: 'lastname',
              value: this.resolveval.lastname,
              type: 'text',
              validations: [
                { rule: 'required', message: 'Last Name is Required' },
                 // {
                //   rule: 'pattern',
                //   value: this.textregex,
                //   message: 'only use alphabet',
                // },
              ],
            },

            {
              label: 'Email',
              name: 'email',
              type: 'email',
              disabled: true,
              hint: '',
              value: this.resolveval.email,
              //  disabled: true,
              validations: [
                { rule: 'required', message: 'Email is required' },
                {
                  rule: 'pattern',
                  value: this.emailregex,
                  message: 'Must be a valid Email',
                },
              ],
            },
            {
              label: 'Company Name',
              name: 'company',
              value: this.resolveval.company,
              type: 'text',
              validations: [
                { rule: 'required', message: 'Company Name is Required' },
              ],
            },
            {
              label: 'Address',
              name: 'address',
              value: this.resolveval.address,
              type: 'textarea',
              validations: [
                { rule: 'required', message: 'Address is Required' },
              ],
            },
            {
              label: 'type',
              name: 'type',
              type: 'hidden',
              value: 'sales-person',
            },
            {
              label: 'State',
              name: 'state',
              val: this.stateVal,
              value: this.resolveval.state,
              type: 'select',
              validations: [{ message: 'State is Required' }],
            },

            {
              label: 'City',
              name: 'city',
              value: this.resolveval.city,
              type: 'text',
              validations: [{ rule: 'required', message: 'City is Required' }],
            },

            {
              label: 'Zip',
              name: 'zip',
              value: this.resolveval.zip,
              type: 'number',
              validations: [
                { rule: 'required', message: 'Zip is Required' },
                {
                  rule: 'maxLength',
                  value: 5,
                  message: 'Please enter the valid Zip ID',
                },
              ],
            },

            {
              label: "Parent name",
              name: "parentid",
              val: this.parentidVal,
              value: this.resolveval.parentid,
              type: 'select',
              // validations: [
              //     { rule: 'required' }
              // ]
            },

            {
              label: 'Active',
              name: 'status',
              hint: '',
              type: 'checkbox',
              value: this.resolveval.status,
            },

            {
              label: 'id',
              name: 'id',
              value: this.activatedRoute.snapshot.params._id,
              type: 'hidden',
            },
          ],
        };
      });
    }
  }
}
